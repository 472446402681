import { gql, useMutation } from '@apollo/client'

export default (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
    mutation (
        $userIdDestiny: ID!
        $userIdOrigin: ID!
    ) {
        transportPaymentMethods (
            userIdDestiny: $userIdDestiny
            userIdOrigin: $userIdOrigin
            ) {
                id
            }
      }`,
      {
          onCompleted: onSuccess,
          onError: onError
      }
  )
}
