import React, {useMemo} from 'react'
import {
    ModalLayout, 
    useWindowDimensions,
    DropDownItem
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

import useSelectPdfTemplate from './useSelectPdfTemplate'

// CreatePdfTemplate
import useCreatePdfTemplate from '../CreatePdfTemplate/useCreatePdfTemplate'
import CreatePdfTemplate from '../CreatePdfTemplate'

import useGetPdfTemplates from '../../Model/getPdfTemplates'
import RenderTemplateItem from '../../../CounselingTemplates/Components/RenderTemplateItem'
import { useHistory } from "react-router-dom"
import { useEffect } from 'react'

const SelectPdfTemplate = () => {
    const history = useHistory()
    const {isOpen, toggle, close: closePdfSelect} = useSelectPdfTemplate()
    //modal para crear template
    const {open: openCreatePdfTemplate, close: closeCreatePdf} = useCreatePdfTemplate()
    const {data, refetch: refetchPdfTemplates, loading: loadTemplates} = useGetPdfTemplates({
        limit: 0
    })
    useEffect(
        () => {
            refetchPdfTemplates()
        }, [isOpen, refetchPdfTemplates]
    )

    const templates = useMemo(
        () => {
            if(data?.getPdfTemplates?.docs?.length > 0) return data.getPdfTemplates.docs
            return []
        },
        [data]
    )
    const {breakWidth} = useWindowDimensions()

    const onCreateTemplate = (template = {}, isEdit = false) => {
        openCreatePdfTemplate(
            () => {
                refetchPdfTemplates()
                closeCreatePdf()
            }, template, true /**useMutation**/, isEdit /**isEdit**/
        )
    }
    return (
        <>
            <ModalLayout
                className={`modal-sidebar modal-desktop-half white-modal`}
                isOpen={isOpen}
                toggle={toggle}
                topHead={<span className={`h3 text-title`}>PDF Templates</span>}
                underHead={null}
                options={[
                    {component: <span onClick={() => {
                        onCreateTemplate()
                    }}>New PDF template</span>}
                ]}
                minHead={<span className={`h3 text-title`}>PDF Templates</span>}
                useRightSection={false}  
                disabledScrollAnimation
                contentClassName={breakWidth === 'SM' ? 'w-auto' : ''} 
            >
                <TabContent activeTab={
                    loadTemplates ? 'loading' : 'show'
                }>
                    <TabPane tabId={'loading'}>
                        <Skeleton count={5} className={'w-100'} height={40} />
                    </TabPane>
                    <TabPane tabId={'show'}>
                        {
                            templates.map(
                                ({id, name, ...others}, index) => {
                                    return (
                                        <RenderTemplateItem
                                            key={index}
                                            showBottomLine
                                            templateData={{
                                                templateName: name
                                            }} 
                                            isNew={false}
                                            unsaved={false}
                                            isEdit={false}
                                            onClick={() => {
                                                // history.push(`/pdf-editor/${id}`)
                                                // onCreateTemplate({...others, id, name}, true)
                                            }} 
                                            isCurrentTemplate={false} 
                                            taskCount={-1}
                                            subtitle={others?.key}
                                            options={[
                                                {component: <DropDownItem onClick={() => {
                                                    onCreateTemplate({...others, id, name}, true)
                                                }}><span>Edit</span></DropDownItem>},
                                                {component: <DropDownItem onClick={() => {
                                                    history.push(`/pdf-editor/${id}`)
                                                    closePdfSelect()
                                                }}><span>Edit PDF</span></DropDownItem>},
                                                {component: <DropDownItem onClick={() => {
                                                    onCreateTemplate({...others, name}, false)
                                                }}><span>Duplicate</span></DropDownItem>},
                                            ]}
                                        />
                                    )
                                }
                            )
                        }
                    </TabPane>
                </TabContent>
            </ModalLayout>
            <CreatePdfTemplate/>
        </>
    )
}

export default SelectPdfTemplate