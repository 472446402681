import { useMutation, gql } from "@apollo/client"

const useDeleteCheckIn = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $checkInId: String!
            ) {
                deleteCheckIn (
                    checkInId: $checkInId
                )
            }
        `, {onCompleted, onError}
    )
}

export default useDeleteCheckIn