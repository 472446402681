import React from 'react'
import { ConfirmModal, Activity } from '@Knowledge-OTP/znk-ui-components'

const ConfirmToggleUser = (props) => {
    const { isOpen, toggle, onConfirm = () => {}, loading = false } = props
    return (
        <ConfirmModal
            className=''
            classNameActionDiv='flex-column'
            classNameConfirm='text-right text-error'
            classNameCancel='mx-3 text-right'
            isOpen={isOpen}
            toggle={toggle}
            title={"Are you sure?"}
            body={"Se deshabilitará al usuario sin importar si tiene lecciones o pagos pendientes."}
            confirmAction={() => {
                onConfirm()
            }}
            cancelAction={toggle}
            confirmText={loading ? <Activity /> : 'Confirm'}
            cancelText={'Cancel'}

        />
    )
}

export default ConfirmToggleUser