import useCalendar from '../../../../Calendar/Sections/Calendar/useCalendar'
import useGetItems from './items'
import {useSelector} from 'react-redux'
const useOptionsByStatus = (lesson, refetch = () => {}) => {
    const {getLessonStatus} = useCalendar()
    const {status} = getLessonStatus(lesson)
    const services = useSelector((state) => state.user.services)
    const orientation = services.find(({isOrientation}) => isOrientation)
    //scheduled, incomplete, finished, draft, upcoming, attended, missed
    const {
        viewRecordingLessonItem,
        emailAlertLessonItem,
        wspAlertLessonItem,
        rescheduledLessonItem,
        setPendingLessonItem,
        duplicateLessonItem,
        makeupLessonItem,
        penalizeLessonItem,
        editLessonItem,
        editPaymentLessonItem,
        cancelLessonItem
    } = useGetItems(lesson, refetch)
    

    

    const getOptions = (status) => {
        const isOrientation = lesson?.service?.id === orientation?.id
        if(['scheduled'].includes(status)) {
            const options = isOrientation ? 
            [
                //rescheduled
                rescheduledLessonItem,
                cancelLessonItem
            ]
            : [
                emailAlertLessonItem,
                wspAlertLessonItem,
                rescheduledLessonItem,
                setPendingLessonItem,
                duplicateLessonItem,
                makeupLessonItem,
                penalizeLessonItem,
                editLessonItem,
                editPaymentLessonItem,
                cancelLessonItem
            ] 
            return options
        }
        if(['incomplete', 'missed'].includes(status)) {
            return isOrientation ? [
                //send lesson notes
            ] : [
                duplicateLessonItem,
                makeupLessonItem,
                penalizeLessonItem,
                editPaymentLessonItem
            ]
        }
        if(['finished', 'attended'].includes(status)) {
            return isOrientation ? 
            []
            : [
                viewRecordingLessonItem,
                duplicateLessonItem,
                makeupLessonItem,
                penalizeLessonItem,
                editPaymentLessonItem
            ]
        }
        if(['draft'].includes(status)) {//pending
            return isOrientation ? 
            [
                //rescheduled
                rescheduledLessonItem,
                cancelLessonItem
            ]
            : [
                rescheduledLessonItem,
                duplicateLessonItem,
                editLessonItem,
                editPaymentLessonItem,
                cancelLessonItem
            ]
        }
        return []
    }
    return {
        options: getOptions(status)
    }
}

export default useOptionsByStatus