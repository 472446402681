import {useMutation, gql} from '@apollo/client'

const useCreateTimeline = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $timelineInput: TimelineInput!
                ) {
                createTimeline (
                    timelineInput: $timelineInput
                ) {
                    id
                }
            }
        `,
        {
            onCompleted: onSuccess,
            onError: onError
        }
    )
}

export default useCreateTimeline