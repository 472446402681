export const phasesQueryResult = `
    phaseName
    steps {
        id
        stepName
        deleteAt
        tasks {
            id
            name
            instructions
            type
            dueTime
            buttonLabel
            linkToShow
            accountsPlatform
            isEspecific
            isReviewable
            selectedExam {
                id: _id
                name
                serviceId
            }
            deleteAt
        }
    }
`

export const templateQueryResult = `
    id
    name
    deleteAt
    phases {
        ${phasesQueryResult}
    }
`

export default {templateQueryResult, phasesQueryResult}