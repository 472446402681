import React, {useCallback, useState, useRef} from "react"
import {
    ModalLayout, IconArrowLeft
} from "@Knowledge-OTP/znk-ui-components"
import LoadingPage from "./Pages/Loading"
import useAssignMultipleTasks from "./useAssignMultipleTasks"
import { TabContent, TabPane } from "reactstrap"
import WelcomePage from "./Pages/Welcome"
import TasksSection from "./Pages/Tasks"
import FinishPage from "./Pages/Finish"
import ExecutePage from "./Pages/Execute"
import useAssignMultipleTasksMutation from "./Model/useAssignMultipleTasks"
import { showAlert } from "../../../../../../common/Alert/util"
import { useDispatch } from "react-redux"
import ErrorPage from "./Pages/Error"
import useTranslation from "../../../../../../i18n/useTranslation"
const WizardPages = {
    welcome: 'welcome',
    assignTasks: 'assign-tasks',
    execute: 'execute',
    finish: 'finish',
    loading: 'loading',
    error: 'error'
}
const AssignMultipleTasksModal = () => {
    const { isOpen, toggle, selectedTasks, onChange = () => {}, close: closeAssignTaskModal, unselectAll } = useAssignMultipleTasks()
    const {translate} = useTranslation()
    const [inputTasks, setInputTasks] = useState([])
    const [currentTaskIndex, setCurrentTaskIndex] = useState(-1)
    const dispatch = useDispatch()
    const [page, setPage] = useState(WizardPages.welcome)
    const bodyRef = useRef(null)
    
    const [assignTasks, { loading }] = useAssignMultipleTasksMutation(
        () => {
            if(onChange) onChange()
            unselectAll()
            setPage(WizardPages.finish)
            showAlert({ text: translate('workspace.tasks.assignMultiTask.modal.onSuccess'), status: 'success' }, dispatch)
        },
        (err) => {
            showAlert({ text: err.toString(), status: 'error' }, dispatch)
            setPage(WizardPages.error)
            console.error(err)
        }
    )


    // GOTO task logic
    const gotoTask = useCallback((taskIndex) => {
        setCurrentTaskIndex(taskIndex)
        setPage(WizardPages.assignTasks)
        // eslint-disable-next-line
    }, [currentTaskIndex, setCurrentTaskIndex, selectedTasks.length])

    const setPageSafe = useCallback((newIndex) => {
        //cambia de pagina
        if(newIndex === -1) setPage(WizardPages.welcome)
        else if (newIndex === selectedTasks.length) setPage(WizardPages.execute)
        else setPage(WizardPages.assignTasks)
    }, [setPage, selectedTasks])

    // NEXT task logic
    const nextIndex = useCallback(() => {
        if(currentTaskIndex > selectedTasks.length+1) return
        const newIndex = currentTaskIndex+1
        setPageSafe(newIndex)
        setCurrentTaskIndex(newIndex)

    }, [currentTaskIndex, setCurrentTaskIndex, selectedTasks.length, setPageSafe])

    // PREVIOUS task logic
    const previousIndex = useCallback(() => {
        if(currentTaskIndex <= -1) return
        const newIndex = currentTaskIndex-1 
        setPageSafe(newIndex)
        setCurrentTaskIndex(newIndex)
        // eslint-disable-next-line
    }, [currentTaskIndex, setCurrentTaskIndex, setPageSafe])
    const RenderPrevious = useCallback(
        () => {
            const disabled = currentTaskIndex < 1 || loading
            return (
                !disabled && 
                <div className={`hover-icon ${disabled ? 'cursor-not-allowed' : ''}`} onClick={previousIndex}>
                    <IconArrowLeft className={'text-gray'} size={12}/>
                </div>
            )
        }, 
        // eslint-disable-next-line
        [currentTaskIndex, previousIndex, loading]
    )


    const addTask = (newTask = undefined, taskIndex) => {
        if(!newTask) return
        //revisa si la tarea ya esta en el arreglo inputTasks
        const currentTask = inputTasks[taskIndex]
        if(currentTask) { //la actualiza
            inputTasks[taskIndex] = {...inputTasks[taskIndex], ...newTask}
            setInputTasks(inputTasks) 
        } else {
            // no se encontro la tarea
            setInputTasks(inputTasks.concat(newTask)) // agrega la tarea al arreglo
        }
    }

    
    

    return (
        <ModalLayout
            className={`modal-colored modal-sidebar modal-desktop-half white-modal`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h3 text-title d-flex flex-row align-items-center`}>
                    <span className="d-flex flex-row align-items-center">
                        <RenderPrevious/>
                        {/* <RenderNext/> */}
                    </span>
                    {translate('workspace.tasks.assignMultiTask.modal.title')}
                </span>
            }
            minHead={
                <span className={`h3 text-title`}>
                    <span className="d-flex flex-row align-items-center">
                        <RenderPrevious/>
                        {/* <RenderNext/> */}
                    </span>
                    {translate('workspace.tasks.assignMultiTask.modal.title')}
                </span>
            }
            bodyRef={bodyRef}
        >
            <TabContent className={`${page === WizardPages.execute ? 'h-100' : ''}`} activeTab={loading ? WizardPages.loading : page}>
                <TabPane tabId={WizardPages.welcome}>
                    <WelcomePage onContinue={() => {nextIndex()}} bodyRef={bodyRef} />
                </TabPane>
                <TabPane tabId={WizardPages.assignTasks}>
                    {<TasksSection 
                        onContinue={(inputTask, taskIndex, taskData) => {
                            addTask({...taskData, ...inputTask}, taskIndex)
                            nextIndex()
                        }} 
                        currentIndex={currentTaskIndex} selectedTasks={selectedTasks} />}
                </TabPane>
                <TabPane className={`${page === WizardPages.execute ? 'h-100' : ''}`} tabId={WizardPages.execute}>
                    <ExecutePage
                        tasks={inputTasks}
                        onContinue={() => {
                            assignTasks({
                                variables: {
                                    assignTaskParams: inputTasks.map((task) => {
                                        return {
                                            planId: task.planId,
                                            taskId: task.taskId,
                                            dueTime: task.dueTime,
                                            userId: task.userId,
                                            universities: task.universities,
                                            userMeetingId: task.userMeetingId,
                                        }
                                    })
                                }
                            })
                        }}
                        onClickTask={(taskIndex) => {
                            gotoTask(taskIndex)
                        }}
                    />
                </TabPane>
                <TabPane tabId={WizardPages.error}>
                    <ErrorPage onContinue={() => {
                        setPage(WizardPages.execute)
                    }} bodyRef={bodyRef} />
                </TabPane>
                <TabPane tabId={WizardPages.loading}>
                    <LoadingPage bodyRef={bodyRef} />
                </TabPane>
                <TabPane tabId={WizardPages.finish}>
                    <FinishPage onFinish={() => {
                        unselectAll()
                        closeAssignTaskModal()
                    }} bodyRef={bodyRef} />
                </TabPane>
            </TabContent>
        </ModalLayout>
    )
}

export default AssignMultipleTasksModal