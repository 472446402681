import React from 'react'
import {
    ListItem, 
} from '@Knowledge-OTP/znk-ui-components'
import Options from './Options'
import LazyAvatar from '../../../../../../../../common/LazyAvatar'
const EducatorItem = (props) => {
    const {
        educatorsBase, educator, setForm, 
        form, type, topic, disabled = false, 
        onClickLeft= () => {}, ...otherProps} = props
    return (
        <ListItem
            className='py-1'
            id='x-0'
            label={null}
            Left={
                <div className={`d-flex align-items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer' }`} onClick={onClickLeft}>
                    <LazyAvatar
                        userId={educator?.id || educator?.educatorUserId}
                        name={`${educator.firstName} ${educator.lastName}`}
                        named={false}
                        uri={null}
                        size='avatar-medium'
                    />
                    <label className={`ml-2 h4 ${disabled ? '' : 'btn-link text-info cursor-pointer'}`}>
                        {`${educator.firstName} ${educator.lastName}`} <span className='text-muted'>{`(${topic.name})`}</span>
                    </label>
                </div>
            }
            Right={
                disabled ? null :
                <Options 
                educator={educator} type={type} topic={topic}
                form={form} setForm={setForm} educatorsBase={educatorsBase}/>
            }
            {...otherProps}
        />
    )
}

export default EducatorItem