import React from 'react'
import {
    ProcessItem,
    IconMessages
} from '@Knowledge-OTP/znk-ui-components'
import {useQuery, gql} from '@apollo/client'
import useTranslation from "../../../../../../i18n/useTranslation"
import { useHistory } from 'react-router'


const CheckPendingOrientation = (props) => {
    const {planId} = props
    const {translate} = useTranslation()
    const history = useHistory()
    //retorna id de la lección de orientación para redirigir a reagendar lección 
    const {data: pendingLesson} = useQuery(
        gql`
        query ($planId: ID!) {
            checkIfPlanHavePendingCounseling (planId: $planId) {
                id
            }
        }`,
        {variables: {planId}}
    )
    return (
        pendingLesson?.checkIfPlanHavePendingCounseling?.id ? 
        
        <ProcessItem
            className={`w-90`}
            onClick={() => {
                //const url = `https://admin.zinkerz.com/calendar?lessonId=${pendingLesson.checkIfPlanHavePendingCounseling.id}`
                //window.open(url, '_blank');
                history.push(`/calendar?lessonId=${pendingLesson.checkIfPlanHavePendingCounseling.id}`)
            }}
            icon={<IconMessages />}
            title={translate("program.plan.counselingPending.title")}//Lección de orientación pendiente
            content={translate("program.plan.counselingPending.content")} //Agendamiento pendiente
            status='upcoming'
        /> : null
    )
}
export default CheckPendingOrientation