import React from 'react'
import {
  IconCountry,
  IconCreditCard,
  IconUser,
  IconProgram,
  IconExam,
  IconSettings,
  IconPayslip,
  IconLearn,
  IconCircleDolar,
  IconTranslations,
  IconArrowRight,
  IconEducator,
  IconNotes,
  IconInfo,
  IconEmail,
  IconLesson,
  IconClose,
  IconBank,
  IconCounselingPlan,
  IconDashboard
} from '@Knowledge-OTP/znk-ui-components'
import { useHistory } from 'react-router'
import useTranslation from '../../../i18n/useTranslation'
import firebase from 'firebase/app'
import 'firebase/auth'
import {useCookies} from 'react-cookie'
import useCounselingTemplates from '../../../Pages/CounselingTemplates/useCounselingTemplates'
import useSelectPdfTemplate from '../../../Pages/PdfEditor/Modal/SelectPdfTemplate/useSelectPdfTemplate'
import useSendWeeklyInsightModal from '../../../Pages/Dashboard/Dashboard/Components/SendWeeklyInsightModal/useSendWeeklyInsightModal'

import getEnv from '../../../helpers/getEnv'
import "./index.css"
import { useSelector } from 'react-redux'

const enviroment = getEnv()

const iconSize = 20
const navItemClassName = 'mr-1'
const navLinkClassName = 'py-2 w-100 nav-link-option'

//dropdown elements
const iconSizeDropdownItem = 15
const classNameDropdownItem = 'mr-2'
const classNameDropdownItemRight = 'ml-6 text-muted'
const classNameDropdownSpan = 'd-flex flex-row align-items-center h45 mr-4'


export const useItems = () => {
  const { staffInfo } = useSelector((state) => state.user)
  const permissions  = staffInfo?.permissions?.filter(e => e.isPermited).map(ele => {return ele.tag}) ?? [];
  const [, , removeCookie] = useCookies(['znk-signup'])
  const history = useHistory()
  const {translate} = useTranslation()
  const {open: openTemplatesList} = useCounselingTemplates()
  const {open: openTemplatesPdfList} = useSelectPdfTemplate()
  const {open: openWeeklyInsight} = useSendWeeklyInsightModal()
  const RenderDropdownOption = (IconComponent, text, arrow = false, onClick = () => {}) => {
    return (
      <span className={`${classNameDropdownSpan} justify-content-between`} onClick={onClick}>
        <div className={'m-0 p-0 d-flex flex-row align-items-center w-100'} style={{whiteSpace: 'nowrap'}}>
          <IconComponent size={iconSizeDropdownItem} className={classNameDropdownItem}/> 
          {text}
        </div> 
        {
          arrow && <IconArrowRight size={iconSizeDropdownItem} className={classNameDropdownItemRight} />
        }
      </span>
    )
  }
  const toolOptions = [
    {
      Icon: IconEmail, 
      label: translate("menu.tools.emailsLogs"), 
      onClick: () => {window.open('https://mandrillapp.com/activity', '_blank');}
    },
    {
      Icon: IconEmail, 
      label: translate("menu.tools.notificationsTemp"),
      onClick: () => {window.open('https://docs.google.com/spreadsheets/d/1qmBYZqJXCWED9Iqe6ha3UaXyO3Noa_bWnKlvht6HJfM/edit?usp=sharing', '_blank');}
    },
    {
      Icon: IconEducator,
      label: translate("menu.tools.inviteEdu"), 
      onClick: () => {window.open('https://form.jotform.com/222714028695863', '_blank');}
    },
    {
      Icon: IconLesson, 
      label: translate("menu.tools.lessonObs"), 
      onClick: () => {window.open('https://form.jotform.com/72276054742154', '_blank');}
    },
    {
      Icon: IconNotes, 
      label: translate("menu.tools.assessmentSender"), 
      onClick: () => {window.open('https://form.jotform.com/72844540742155', '_blank');}
    },
    {
      Icon: IconNotes, 
      label: translate("misc.weeklyInsight.optionToolsLabel") || "Send Weekly Insight", 
      onClick: () => {
        openWeeklyInsight(
          () => {}, true
        )
      }
    },
  ]
  let settingOption = [
    {
      Icon: IconCircleDolar,
      label: translate("menu.settings.services"), 
      onClick: () => {history.push('/constants/services')}},
    {
      Icon: IconLearn,
      label: translate("menu.settings.topics"), 
      onClick: () => {history.push('/constants/topics')}
    },
    {
      Icon: IconProgram,
      label: translate("menu.settings.activities"), 
      onClick: () => {history.push('/constants/activities')}
    },
    {
      Icon: IconCountry,
      label: translate("menu.settings.countries"), 
      onClick: () => {history.push('/constants/countries')}
    },
    {
      Icon: IconBank,
      label: translate("menu.settings.universities"), 
      onClick: () => {history.push('/constants/universities')}
    },
    {
      Icon: IconTranslations,
      label: translate("menu.settings.translations"), 
      onClick: () => {history.push('/i18n')}
    },
    // {
    //   Icon: IconTranslations,
    //   label: translate("menu.settings.liveprepplans"), 
    //   onClick: () => {history.push('/constants/planslp')}
    // },
    {
      Icon: IconUser,
      label: translate("menu.settings.counselors"), 
      onClick: () => {history.push('/constants/counselors')}
    },
    {
      Icon: IconUser,
      label: translate("menu.settings.timezones"), 
      onClick: () => {history.push('/constants/timezones')}
    }
  ]
  if(['beta', 'local'].includes(enviroment) || true) {
    settingOption.push(
      {
        Icon: IconTranslations,
        label: 'Templates', 
        onClick: () => { openTemplatesList() }
      }
    )
    settingOption.push(
      {
        Icon: IconTranslations,
        label: 'PDF Templates', 
        onClick: () => { openTemplatesPdfList() }
      }
    )
    
    settingOption.push(
      {
        Icon: IconTranslations,
        label: 'LP Timelines', 
        onClick: () => { history.push('/timelines') }
      }
    )
  } 
  settingOption.push({
    Icon: IconClose, 
    label: translate("menu.settings.signOut"), 
    onClick: () => {
      removeCookie('znk-signup')
      firebase.auth().signOut()
    }
  })
  const items = []
  if (permissions?.includes('dashboard')) {
    items.push({
      href: '/dashboard/students',
      Body: <IconDashboard size={iconSize} />,
      label: 'Dashboard',
      linkClass: navLinkClassName,
      itemClass: navItemClassName
    })
  }
  if (permissions?.includes('users')) {
    items.push({
      href: '/users',
      Body: <IconUser size={iconSize} />,
      label: 'Users',
      linkClass: navLinkClassName,
      itemClass: navItemClassName
    })
  }
  if (permissions?.includes('liveprep-plans')) {
    items.push({
      href: '/plans',
      Body: <IconExam size={iconSize} />,
      label: 'Live Prep Plans',
      linkClass: navLinkClassName,
      itemClass: navItemClassName
    })
  }
  if (permissions?.includes('counseling-plans')) {
    items.push({
      href: '/counseling-plans',
      Body: <IconCounselingPlan size={iconSize} />,
      label: 'Counseling Plans',
      linkClass: navLinkClassName,
      itemClass: navItemClassName
    })
  }
  if (permissions?.includes('invoices')) {
    items.push({
      href: '/payments',
      Body: <IconCreditCard size={iconSize} />,
      label: 'Payments',
      linkClass: navLinkClassName,
      itemClass: navItemClassName
    })
  }
  if (permissions?.includes('payslips')) {
    items.push({
      href: '/payslips',
      Body: <IconPayslip size={iconSize} />,
      label: 'Paylips',
      linkClass: navLinkClassName,
      itemClass: navItemClassName
    })
  }
  if (permissions?.includes('calendar')) {
    items.push({
      href: '/calendar',
      Body: <IconProgram size={iconSize} />,
      label: 'Lessons',
      linkClass: navLinkClassName,
      itemClass: navItemClassName
    })
  }
  if (permissions?.includes('workspace')) {
    items.push({
      href: '/workspace/tasks',
      Body: <IconLearn size={iconSize} />,
      label: 'Workspace',
      linkClass: navLinkClassName,
      itemClass: navItemClassName
    })
  }
  return {
    items,
    secondaryItems: [
      {
        type: 'dropdown', //opción despliega un menu de opciones (dropdown)
        Body: <span className={'d-flex flex-row align-items-center h45'}><IconInfo className={'mr-2'} size={iconSize} /> {translate("menu.tools.tools")}</span>,
        linkClass: `m-0 w-100 nav-link-option`,
        itemClass: navItemClassName,
        options: toolOptions.map(({Icon, label, onClick = () => {}}) => {
          return {
            component:  RenderDropdownOption(Icon, label, false, onClick) 
          }
        })
      },
      {
        type: 'dropdown', //opción despliega un menu de opciones (dropdown)
        Body: <span className={'d-flex flex-row align-items-center h45'}><IconSettings className={'mr-2'} size={iconSize} /> {translate("menu.settings.settings")}</span>,
        linkClass: `m-0 w-100 nav-link-option`,
        itemClass: navItemClassName,
        options: settingOption.map(({Icon, label, onClick = () => {}}) => {
          return {
            component:  RenderDropdownOption(Icon, label, false, onClick) 
          }
        })
      }
    ],
    toolOptions,
    settingOption
  }
} 
