import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import { IconClose, Modal, useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import RecurrentAvailability from './Recurrent'
import TemporalUnavailability from './Temporal'
import useTranslation from '../../../../../i18n/useTranslation'

const SetEducatorUnavailabilityModal = () => {
  const { isOpen, ...formData } = useSelector((state) => state.modal.user.setEducatorUnavailability)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.setEducatorUnavailability', data: { isOpen: !isOpen, ...formData } })
  }
  const onChange = (setFormFunc) => {
    const newVal = setFormFunc(formData)
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.setEducatorUnavailability', data: { isOpen: true, ...newVal } })
  }
  const [isRecurring, setIsRecurring] = useState(false)
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  return (
    <Modal
      buttonLabel={null}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered modal-unavailability'}`}
      contentClassName='px-md-7 pt-0'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div
          className='modal-header px-0 pt-7 d-flex justify-content-between'
        >
          <span className='h2 font-weight-bold flex-grow-1'>
            {translate('Educator Unavailability')}
          </span>
          <IconClose size={20} onClick={toggle} />
        </div>
      }
    >
      {
        isRecurring ? (
          <RecurrentAvailability onChange={onChange} formData={formData} />
        ) : <TemporalUnavailability onChange={onChange} formData={formData} />
      }
      <span className='btn btn-link' onClick={() => setIsRecurring(val => !val)}>{isRecurring ? 'Set Unavailability by hours' : 'Set Recurring Availability'}</span>
    </Modal>
  )
}

export default SetEducatorUnavailabilityModal
