// String con modelo de objeto que será utilizado para
// obtener una Task de un estudiante.
const taskQueryResult = `
id
idReference
name
position
instructions
type
dueTime
buttonLabel
isEspecific
startDate
dueDate
reviewDate
completedDate
canceledDate
linkToShow
selectedExam {
    id: _id
    name
    serviceId
    results {
        name
        scale
        values {
            name
            scale
        }
    }
}
accountsPlatform
universities
status
userId
resolveTask {
    link {
        lastProcessedAt
        accessedCount
    }
    loginInfo {
        platform{
            name
            username
            password
        }
    }
    score {
        name
        examDate
        overall
        results { 
            name
            scale
            value
            values {
                name
                scale
                value
            }
        }
    }
    meeting
    uploadFiles {
        url
        uploadDate
    }
    completion
}
phase
stepId
isReviewable
showInDocs
uploadDocs
userMeetingId
deleteAt
meetingStartDate
chatNotification {
    councelor
    student
}
`

export default taskQueryResult
