export const addPaymentMethodToChildrens = (students = [], paymentMethods= []) => {
    if(!Array.isArray(students) || !Array.isArray(paymentMethods)) return
    if(students?.length < 1 || paymentMethods?.length < 1) return
    let studentsWithPaymentMethods = []
    let pmFound = ''
    // eslint-disable-next-line
    students.map(stu => {
        pmFound = paymentMethods.find(pm => pm.studentId === stu.studentUserId)
        
        studentsWithPaymentMethods.push({...stu, 
            paymentMethod: pmFound?.paymentMethod || null,
            registeredPaymentMethods: pmFound?.registeredPaymentMethods || null,
            id: stu.studentUserId,
        })
    })
    return studentsWithPaymentMethods
  } 