import React from 'react'
import {
    IconClose,
    Modal,
    Form,
  } from '@Knowledge-OTP/znk-ui-components'
  import useTranslation from '../../../../../i18n/useTranslation'
  
  const ViewTeachTopics = (props) => {
    const {isOpen, setIsOpen, canTeach} = props
    const {translate} = useTranslation()

    const toggle = () => {
      setIsOpen(!isOpen)
    }

    return (
      <>
        <div className='m-0 p-0 w-100' onClick={toggle}>
          {props.children}
        </div>
        <Modal
          buttonLabel={null}
          className='modal-centered'
          isOpen={isOpen}
          toggle={toggle}
          Head={
            <div className='modal-header d-flex align-items-center pb-0 my-3'>
              <span className='flex-grow-1 text-title font-weight-bold h2'>
                {translate('profile.actions.teachtopics.title')}
              </span>
              <IconClose size={20} onClick={toggle} />
            </div>
          }
        >
          <div className='m-0 p-0 mb-2 px-3'>
            <Form>
              <label className='text-gray font-weight-light mt-3 mb-2'>
                {translate('profile.actions.teachtopics.subtitle')}
              </label>
              <textarea disabled
                value={canTeach}
                placeholder={translate('profile.actions.teachtopics.notext')}
                className='znk-input w-100 p-3'
                style={{height: '150px', resize: 'none'}}
              />

            </Form>
          </div>
        </Modal>
      </>
    )
  }
  
  export default ViewTeachTopics
  