import React from 'react'

const RenderInputOption = ({label, children, className, footLabel = null}) => {
    return ( 
        <div className={`d-flex flex-column my-3 ${className}`}>
            <span className={`h45 text-gray mb-2`}>{label}</span>
            {children}
            <span className={`h45 text-gray mb-2 align-self-end`}>{footLabel}</span>
        </div>
    )    
}

export default RenderInputOption