import React from 'react'
import { IconArrowDown, CheckboxSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'

const StatusFilters = (props) => {
    const {filters = [], setFilters = () => {}} = props
    const statuses = [
        {id: 'unassigned', name: 'Unassigned'},
        {id: 'pending', name: 'Pending'},
        {id: 'review', name: 'Review'},
        {id: 'completed', name: 'Completed'},
    ]
    return (
        <CheckboxSelector
            setDataFilters={setFilters}
            direction='bottom'
            dataFilters={filters}
            data={statuses}
            typeFilter='status'
            strings={{
                viewAll: 'View all',
                buttonCancel: 'Cancel',
                buttonApply: 'Apply',
                search: 'Search'
            }}
        >
            <Badge className='mr-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                Status
                <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default StatusFilters