import React, {useEffect, useState, useMemo} from 'react'
import {UserBubble} from '@Knowledge-OTP/znk-ui-components'
import {db} from '../../fireBase'

const UserItems = (props) => {
  const {usersList, eventID} = props
  const visibleUsers = useMemo(() => {return usersList}, [usersList])
  const [onlineStatus, setOnlineStates] = useState([])

  //This use state brings the users status from Firebase
  useEffect(() => {
    setOnlineStates([])
    // eslint-disable-next-line
    visibleUsers?.map(user => {
      let userID = user?.id
      db.collection('usersStatus')
        .doc(eventID).collection(userID).doc('status')
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setOnlineStates((data) => {
              return {...data, [userID]: snapshot.data().online}
            })
          } else {
            setOnlineStates((data) => {
              return {...data, [userID]: false}
            })
          }
        })

      return {...user, id: userID}
    })
    // eslint-disable-next-line
  }, [visibleUsers]);


  return (
    <>
      {visibleUsers instanceof Array &&
        visibleUsers.map((user) => (
          <>
            <UserBubble
              toolTipProperties={{
                referenceID: `userBubble${user.id}`,
                toolTipText: `${user.firstName} ${user.lastName}`,
                alignment: 'top',
              }}
              name={`${user.firstName} ${user.lastName}`}
              online={onlineStatus[user.id]}
              className='mr-2'
            />
          </>
        ))}
      {/* {visibleUsers instanceof Array && visibleUsers.length >= 5 && (
        <div
          className='ml-3 mt-1 text-secondary'
          onClick={() => console.log('click')}
        >
          {translate('virtualChat.usersItems.usersConnected', {
            message: usersList.length,
          })}
        </div>
      )} */}
    </>
  )
}

export default UserItems
