import React from 'react'
import Skeleton from 'react-loading-skeleton'
import useGetPaymentMethod from '../../../../../Payments/Components/GetPaymentMethod/useGetPaymentMethod'

const ShowPaymentMethod = (props) => {
    const {userId} = props
    const {label, loading: loadPayer} = useGetPaymentMethod(userId)
    return (
        loadPayer ? 
            <Skeleton count={1} height={10} width={70} /> : 
            <span className={`text-gray h45`}>
                {label}
            </span>
    )
}

export default ShowPaymentMethod