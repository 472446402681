import React, {useEffect, useState} from 'react'
import useTranslation from '../../../i18n/useTranslation'
import SocialSignin from './Social'
import EmailSignin from './Email'
import {TabContent, TabPane} from 'reactstrap'
import {useLocation} from 'react-router-dom'
import FullPassword from '../FullSignin'
import Signup from '../Signup'
import RecoveryPasswordPage from '../RecoveryPassword'

const Signin = () => {
  const {translate} = useTranslation()
  const [state, setState] = useState(1)
  useEffect(() => {
    const storage = global.localStorage.getItem('loggedUser')
    global.localStorage.removeItem('signupEmail')
    global.localStorage.removeItem('foundUser')
    if (storage) {
      setState(2)
    }
  }, [])
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isEducator = searchParams.get('isEducator')
  const action = searchParams.get('action')
  useEffect(() => {
    if (isEducator === 'true') setState(4)
  }, [isEducator])
  useEffect(() => {
    if (action === 'recovery') setState(5)
    else if (action === 'signup') setState(3)
  }, [action])
  const [defaultEmail, setDefaultEmail] = useState(null)

  return (
    <TabContent style={{minWidth: '66%'}} activeTab={state}>
      <TabPane tabId={1}>
        <div className='h1 font-weight-light text-logo'>
          {translate('login.signin.welcome')}
        </div>
        <div className='h4 my-2 font-weight-light text-secondary'>
          {translate('login.signin.description')}
        </div>
        <SocialSignin />
        <div className='d-flex align-items-center'>
          <div style={{ flexGrow: 1, borderBottom: '1px solid #d3d3d3', height: '1px' }}></div>
          <div className='px-2 text-center'>
            <span>{translate('login.signin.orWithEmail')}</span>
          </div>
          <div style={{ flexGrow: 1, borderBottom: '1px solid #d3d3d3', height: '1px' }}></div>
        </div>
        <EmailSignin
          goSignin={() => {
            setState(2)
          }}
          goSignup={() => {
            setState(3)
          }}
        />
        <div
          className='mt-4 d-flex btn-link text-underline text-gray font-weight-light justify-content-center'
          onClick={() => setState(4)}
        >
          <span>{translate('login.signin.notRegistered')}</span>
        </div>
      </TabPane>
      <TabPane tabId={4}>
        <div className='h1 font-weight-light text-logo'>
          {translate('login.signup.welcome')}
        </div>
        <div className='h4 my-2 font-weight-light text-secondary'>
          {translate('login.signup.description')}
        </div>
        <SocialSignin isEducator={isEducator === 'true'} isSignup />
        <div className='d-flex align-items-center'>
          <div style={{ flexGrow: 1, borderBottom: '1px solid #d3d3d3', height: '1px' }}></div>
          <div className='px-2 text-center'>
            <span>{translate('login.signup.orWithEmail')}</span>
          </div>
          <div style={{ flexGrow: 1, borderBottom: '1px solid #d3d3d3', height: '1px' }}></div>
        </div>
        <EmailSignin
          goSignin={() => setState(2)}
          goSignup={() => {
            setState(3)
          }}
        />
        <div
          className='mt-4 d-flex btn-link text-underline text-gray font-weight-light justify-content-center'
          onClick={() => setState(1)}
        >
          <span>&lt;&nbsp;{translate('login.signup.back')}</span>
        </div>
      </TabPane>
      <TabPane tabId={2}>
        <FullPassword
          isEducator={isEducator === 'true'}
          back={() => setState(1)}
          onForgot={(email) => {
            setDefaultEmail(email)
            setState(5)
          }}
        />
      </TabPane>
      <TabPane tabId={3}>
        <Signup isEducator={isEducator === 'true'} back={() => setState(1)} />
      </TabPane>
      <TabPane tabId={5}>
        <RecoveryPasswordPage
          isEducator={isEducator === 'true'}
          back={() => setState(1)}
          defaultEmail={defaultEmail}
        />
      </TabPane>
    </TabContent>
  )
}

export default Signin
