import React, { useState } from 'react'

import {
  Modal,
  useWindowDimensions,
  ListItem,
  IconAdd
} from '@Knowledge-OTP/znk-ui-components'

const Menu = (props) => {
  const { newAproveInvoice } = props
  const { breakWidth } = useWindowDimensions()
  const [isOpen, setOpen] = useState(false)
  const toggle = () => {
    setOpen((open) => !open)
  }
  // const newSave = (value) => {
  //   newSaveInvoice(value)
  //   toggle()
  // }
  const newAprove = (value) => {
    newAproveInvoice(value)
    toggle()
  }
  return (
    <span>
      <div onClick={toggle}>{props.children}</div>
      <Modal
        buttonLabel={null}
        className={`modal-float modal-${
          breakWidth === 'SM' ? 'botbar mb-1' : 'corner mr-4 mb-8'
        } canceled-modal`}
        backdropClassName={`${breakWidth !== 'SM' && 'no-op'}`}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className='row m-0 p-0'>
          <div
            className='m-0 p-0 w-100'
            style={{
              listStyleType: 'none'
            }}
          >
            {/* <ListItem
              hasHover
              onClick={() => {
                newSave('pendingPayment')
              }}
              className='listItem-secondary px-4'
              Left={
                <div className='d-flex align-items-center text-muted'>
                  <IconAdd size={16} className='mr-4' />
                  <label>Save new invoice</label>
                </div>
              }
            /> */}
            <ListItem
              hasHover
              onClick={() => {
                newAprove('pendingPayment')
              }}
              className='listItem-secondary px-4'
              Left={
                <div className='d-flex align-items-center text-muted'>
                  <IconAdd size={16} className='mr-4' />
                  <label>Aprove invoice</label>
                </div>
              }
            />
          </div>
        </div>
      </Modal>
    </span>
  )
}

export default Menu
