import React, {useEffect} from 'react'
import {useLazyQuery, gql} from '@apollo/client' 
import { useLocation } from 'react-router'
import usePlansQueryResult from "../../../Model/QueryResult/usePlansQueryResult"

const RedirectFromUrl = ({onCompleted = () => {}}) => {
    const [getPlan] = useLazyQuery(
        gql`
                query($planId: ID!){
                  plan(planId: $planId){
                    ${usePlansQueryResult}
                  }
                }
              `,
        {
          onCompleted
        }
      )
      const location = useLocation()
      const searchParams = new URLSearchParams(location.search)
      const planId = searchParams.get('planId')
      useEffect(() => {
        if (planId) {
          getPlan({
            variables: {
              planId
            }
          })
        }
      }, [planId, getPlan])

    return (<></>)
}

export default RedirectFromUrl