import React, { useEffect, useState } from 'react'
import {
    ModalLayout,
} from '@Knowledge-OTP/znk-ui-components'
import useAssingTaskModal from './useAssignTaskModal'
import InnerAssingTaskModal from './inner'
const AssingTaskModal = () => {
    const { isOpen, toggle, taskData, close, onChange, useMutation, loading, validation } = useAssingTaskModal()
    
    const [taskState, setTaskState] = useState(taskData)
    
    useEffect(() => { 
        setTaskState(taskData)
    }, [taskData])
    const {
        taskName,
        name
    } = taskState


 
    return (
        <ModalLayout
            className={`modal-colored modal-sidebar modal-desktop-half white-modal`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h3 text-title`}>
                    {taskName || name}
                </span>
            }
            minHead={
                <span className={`h3 text-title`}>
                    {taskName || name}
                </span>
            }
        >
            <InnerAssingTaskModal
                taskData={taskData} 
                close={close} 
                onChange={onChange} 
                useMutation={useMutation} 
                loading={loading} 
                validation={validation}
            />
        </ModalLayout>
    )
}

export default AssingTaskModal