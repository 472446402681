import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Button,
  IconClose,
  IconArrowRight,
  IconIllustratedLesson,
  //Avatar,
  ListItem,
  IconProgram,
  IconSignMinus,
  IconRepeat,
  IconEdit,
  IconCreditCard,
  IconDuplicate,
  DropDown,
  IconOptions,
  IconArrowDown,
  IconArrowUp,
  IconSignPlus,
  IconSignCheck,
  IconPenalize,
  Countdown,
  Activity,
  IconWspAlert,
  IconComment,
  ToolTipInfo
} from '@Knowledge-OTP/znk-ui-components'
import Avatar from '../../../../../common/LazyAvatar'
import moment from 'moment'
import { Badge, Collapse } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { TabContent, TabPane } from 'reactstrap'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'
import sendLessonAttendance from '../../../../../Model/Lessons/SendLessonAttendance'
import SendLessonNotes from '../../SendLessonNotes'
import useLinkLesson from '../useLinkLesson'
import { hasHttp } from '../../utils'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import BackupLinkButton from '../Components/BackupLinkButton'
import VirtualChat from '../../../../VirtualChat'
import collectionType from '../../../../VirtualChat/utils/collectionType'

const InProgressHeaderModal = (props) => {
  const { dataLesson, toggle } = props
  const separateCamelCase = (str) => {
    if (str) str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) { return str.toUpperCase() })
    return str
  }

  const { translate } = useTranslation()
  const makeup = dataLesson?.isMakeUp || false
  const madeup = dataLesson?.makeUpInfo !== null || false

  const penalizationInfo = dataLesson?.penalizationInfo

  const topic = dataLesson.groupTopic || dataLesson.individualTopic || dataLesson.planTopic || {}
  const service = dataLesson.service

  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1'>
        <IconSignMinus size={20} className='ml-auto' />
        <IconClose className='ml-auto' size={20} onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 py-3'>
        <IconIllustratedLesson size={80} />
        <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
          <h2 className='my-1'>
            <b>{dataLesson && service && `${dataLesson.title}`}</b>
          </h2>
          <h4 className='my-1'>
            {
              (dataLesson && dataLesson.startDate && dataLesson.endDate) &&
                                `${moment(dataLesson.startDate).format('hh:mm a')} to ${moment(dataLesson.endDate).format('hh:mm a')} | ${separateCamelCase(moment(dataLesson.startDate).format('MMM'))} ${moment(dataLesson.startDate).format('DD, YYYY')}`
            }
          </h4>
          <h5 className='h45 my-1 pr-2'>
            {
              (dataLesson && dataLesson.__typename && service) &&
                                `${separateCamelCase(service.name)} | ${separateCamelCase(topic?.name)} | ${separateCamelCase(dataLesson?.location?.type || 'No defined')} | ${separateCamelCase(dataLesson.__typename?.replace('Lesson', ''))}`
            }
          </h5>
          <div className='d-flex flex-row m-0 p-0'>
            {
              makeup && (
                <Badge
                  className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                  pill
                >
                  {translate('modal.infolesson.makeup')}
                </Badge>
              )
            }
            {
              madeup && (
                <Badge
                  className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                  pill
                >
                  {translate('modal.infolesson.madeup')}
                </Badge>
              )
            }
            {
              penalizationInfo && (
                <Badge
                  className='mx-0 mt-3 px-2 mr-1 badge badge-pill badge-secondary p-0'
                  pill
                >
                  <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                    <DropDown
                      className='d-flex flex-row align-items-center'
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      options={[
                        {
                          component:
  <div className='d-flex flex-row h45 px-2 py-1'>
    <div
      title={translate(`modal.penalizelesson.${penalizationInfo?.specificIssueId}`)}
      className='text-title mr-3 ellipsis-text'
      style={{ width: '124px' }}
    >{translate(`modal.penalizelesson.${penalizationInfo?.specificIssueId}`)}
    </div>
    <div className='text-title'>{`-$${parseFloat(penalizationInfo?.amount).toFixed(2)}`}</div>
  </div>
                        }
                      ]}
                    >
                      <IconPenalize color='red' size={15} />
                    </DropDown>
                  </div>
                </Badge>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

const InProgressBodyModal = (props) => {
  const {
    dataLesson,
    student,
    educator,
    createdDate,
    updateDate,
    RenderStudents,
    setDataLessonToRedux,
    educatorAttendanceState,
    studentAttendanceState,
    checkAttendanceChanges,
    resetAttendance,
    //addSubstituteMutation,
    setStatusOfInfoLessonState,
    refetchCalendarQuery,
    studentOptions,
    educatorOptions,
    virtualChatProp = {}
  } = props
  const {toggle} = InProgressHeaderModal
  const { modalType } = useSelector((state) => state.modal.plan.infoLessonModal)
  

  const [educatorAttendance, setEducatorAttendance] = educatorAttendanceState
  const [studentAttendance, setStudentAttendace] = studentAttendanceState
  const currentUserId = useSelector(state => state.user.id)
  const [setAttendanceMutation, { loading }] = sendLessonAttendance(
    (result) => { setDataLessonToRedux(result.data.sendLessonAttendance) },
    (error) => { console.error(error) }
  )

  const { translate } = useTranslation()
  const [showTab, setShowTab] = useState('lesson')
  useEffect(() => {
    if(modalType) setShowTab(modalType)
  }, [modalType])


  const [substituteEducator, setSubstituteEducator] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (dataLesson?.substitute) setSubstituteEducator(dataLesson?.substitute)
    else setSubstituteEducator(null)
  }, [dataLesson])

  const lessonPast = dataLesson?.endDate ? moment() >= moment(dataLesson?.endDate) : false

  const rateStudent = Array.isArray(student)
    ? `$${student.reduce((minRate, stu) => {
      if (minRate > stu?.rate) return stu?.rate
      return minRate
    }, Number.POSITIVE_INFINITY).toFixed(2)} - 
    $${student.reduce((maxRate, stu) => {
      if (maxRate < stu?.rate) return stu?.rate
      return maxRate
    }, Number.NEGATIVE_INFINITY).toFixed(2)}`
    : (student) && `$${(student?.rate || 0).toFixed(2)}`
  const ignorePaymentStudent = Array.isArray(student)
    ? false
    : student?.ignorePaymentItem

  const makeup = dataLesson?.isMakeUp || false

  const [collapseGroupStudent, setCollapseGroupStudent] = useState(false)
  const toggleCollapseGroupStudent = () => {
    setCollapseGroupStudent(!collapseGroupStudent)
  }

  const getvclink = useLinkLesson()
  const [goToClassLoad, setGoToClassLoad] = useState(false)
  const isGroup = dataLesson.__typename === "GroupLesson"

  const [userArray] = useMemo(
    () => {
      let studentArray = Array.isArray(student) ? student : student ? [student] : []
      studentArray = (studentArray || []).map((item) => {return {...item, id: item?.id || item?.studentUserId}})
      const educatorArray = [educator].map((item) => {return {...item, id: item?.id || item?.educatorUserId}})
      return [
        studentArray.concat(educatorArray)
      ]
    }, [student, educator]
  )
  const auxRef = useRef(null)
  useEffect(() => {
    if(auxRef?.current?.parentElement?.parentElement?.parentElement){
      const parentRef = auxRef.current.parentElement.parentElement.parentElement
      if(showTab === 'chat') parentRef.classList.add('h-100')
      else parentRef.classList.remove('h-100')
    }
  }, [auxRef, showTab])
  return (
    <>    
    <span ref={auxRef}></span>               
    <TabContent
      className='h-100'
      activeTab={showTab}
    >
     <TabPane className={'h-100'} tabId="lesson">
      <div className='d-flex flex-row justify-content-between my-2'>
        <span className='font-weight-bold'>{translate('modal.infolesson.price')}</span>
        <span className={`font-weight-bold ${ignorePaymentStudent ? 'text-gray' : ''}`} style={ignorePaymentStudent ? { textDecoration: 'line-through' } : {}}>{rateStudent}</span>
      </div>
      <div className='d-flex flex-row justify-content-between mt-2 mb-6'>
        <span className='font-weight-bold'>{translate('modal.newlesson.educatorwage')}</span>
        <span className={`font-weight-bold ${educator?.ignorePaymentItem ? 'text-gray' : ''}`} style={educator?.ignorePaymentItem ? { textDecoration: 'line-through' } : {}}>{`$${educator && educator.rate.toFixed(2)}`}</span>
      </div>
      <div className='m-0 p-0 d-flex flex-column fragment-students-educator'>
        <Collapse isOpen={checkAttendanceChanges}>
          <ListItem
            showTopLine
            showBottomLine={false}
            Left={<span className='btn-link text-error h45' onClick={resetAttendance}>{loading ? <Activity /> : 'Reset'}</span>}
            Right={
              <span
                className='btn-link h45'
                onClick={() => {
                  setAttendanceMutation({
                    lessonId: dataLesson?.id,
                    educatorAttendance: educatorAttendance,
                    attendance:
                      Object.entries(studentAttendance).reduce(
                        (arrayAttendance, [idStudent, atte]) => {
                          arrayAttendance.push({
                            studentId: idStudent,
                            status: atte
                          })
                          return arrayAttendance
                        }, [])
                  })
                }}
              >{loading ? <Activity /> : translate('modal.infolesson.saveAttendance')}
              </span>
            }
          />
        </Collapse>
        <ListItem
          showTopLine
          showBottomLine={collapseGroupStudent}
          Left={<RenderStudents student={student} />}
          Right={
            Array.isArray(student)
              ? <div className='hover-icon py-1 px-2' onClick={toggleCollapseGroupStudent}>
                {
                  collapseGroupStudent
                    ? <IconArrowUp />
                    : <IconArrowDown />
                }
                </div>
              : <div className={'d-flex flex-row m-0 p-0'}>
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      closeMenuWhenClick={false}
                      options={[
                        {
                          component:
                    <div className='d-flex flex-row m-0 py-0 px-1'>
                      <div
                        className='hover-icon' onClick={() => {
                          setStudentAttendace({ ...studentAttendance, [student.studentUserId]: 'missed' })
                        }}
                      ><IconSignPlus color='red' />
                      </div>
                      <div
                        className='hover-icon' onClick={() => {
                          setStudentAttendace({ ...studentAttendance, [student.studentUserId]: 'pending' })
                        }}
                      ><IconSignMinus color='#72809D' />
                      </div>
                      <div
                        className='hover-icon' onClick={() => {
                          setStudentAttendace({ ...studentAttendance, [student.studentUserId]: 'attended' })
                        }}
                      ><IconSignCheck color='#8FC813' />
                      </div>
                    </div>
                        }
                      ]}
                    >
                      <div className='hover-icon py-1 px-2'>
                        {
                          studentAttendance[student.studentUserId] === 'missed'
                            ? <IconSignPlus color='red' />
                            : studentAttendance[student.studentUserId] === 'attended'
                              ? <IconSignCheck color='#8FC813' />
                              : <IconSignMinus color='#72809D' />

                        }
                      </div>
                    </DropDown>
                  </div>
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      closeMenuWhenClick={false}
                      options={studentOptions(student)}
                    >
                      <div className={'hover-icon py-1 px-2'}>
                        <IconOptions style={{transform: 'rotate(90deg)'}}/>
                      </div>
                    </DropDown>
                  </div>
                </div>
          }
        />
        <Collapse isOpen={collapseGroupStudent}>
          {
            Array.isArray(student) &&
            student.map((stu) => {
              return (
                <div>
                  <ListItem
                    className='py-2'
                    Left={
                      <div className='d-flex flex-row my-0 py-0 mr-7'>
                        <Avatar
                          userId={stu?.studentUserId || stu?.id}
                          name={stu && `${stu.firstName} ${stu.lastName}`}
                          named={false}
                          uri={null}
                          innerDiv
                          size='avatar-small mr-1'
                        />
                        <div
                          className='h45 align-self-center text-dark' style={{
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {stu && `${stu.firstName} ${stu.lastName} `}
                        </div>
                      </div>
                    }
                    Right={
                      <div className='d-flex flex-row align-items-center'>
                        <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                          <DropDown
                            direction='bottom'
                            fixedWidth
                            hoverEnabled={false}
                            closeMenuWhenClick={false}
                            options={[
                              {
                                component:
  <div className='d-flex flex-row m-0 py-0 px-1'>
    <div
      className='hover-icon' onClick={() => {
        setStudentAttendace({ ...studentAttendance, [stu.studentUserId]: 'missed' })
      }}
    ><IconSignPlus color='red' />
    </div>
    <div
      className='hover-icon' onClick={() => {
        setStudentAttendace({ ...studentAttendance, [stu.studentUserId]: 'pending' })
      }}
    ><IconSignMinus color='#72809D' />
    </div>
    <div
      className='hover-icon' onClick={() => {
        setStudentAttendace({ ...studentAttendance, [stu.studentUserId]: 'attended' })
      }}
    ><IconSignCheck color='#8FC813' />
    </div>
  </div>
                              }
                            ]}
                          >
                            <div className='hover-icon py-1 px-2'>
                              {
                                studentAttendance[stu.studentUserId] === 'missed'
                                  ? <IconSignPlus color='red' />
                                  : studentAttendance[stu.studentUserId] === 'attended'
                                    ? <IconSignCheck color='#8FC813' />
                                    : <IconSignMinus color='#72809D' />
                              }
                            </div>
                          </DropDown>
                        </div>
                        <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                          <DropDown
                            direction='bottom'
                            fixedWidth
                            hoverEnabled={false}
                            closeMenuWhenClick={false}
                            options={[
                              {
                                component:
                                  <div className='d-flex flex-row justify-content-between align-items-center m-0 py-2 px-5'>
                                    <div className='text-title h45 mr-2'>{translate("modal.infolesson.rate")}: </div>
                                    <div 
                                      style={stu?.ignorePaymentItem ? { textDecoration: 'line-through' } : {}}
                                      className={`font-weight-bold mr-2 h45 ${stu?.ignorePaymentItem ? 'text-gray' : ''}`}>{`$${stu?.rate.toFixed(2)}`}</div>
                                  </div>
                              }
                            ].concat(studentOptions(student) || [])}
                          >
                            <div className='hover-icon py-1 px-2'>
                              <IconOptions style={{ transform: 'rotate(90deg)' }} />
                            </div>
                          </DropDown>
                        </div>
                      </div>
                    }
                  />
                </div>
              )
            })
          }
        </Collapse>
        {
          <ListItem
            showBottomLine
            showTopLine
            classNameLeft='ellipsis-text'
            classNameRight='flex-grow-2'
            Left={
              <div className='d-flex flex-row'>
                <Avatar
                  userId={dataLesson?.educator?.educatorUserId || dataLesson?.educator?.id}
                  name={(dataLesson && dataLesson.educator) && `${dataLesson.educator.firstName} ${dataLesson.educator.lastName} `}
                  named={false}
                  uri={dataLesson?.aditionalInfo?.educatorAvatar}
                  size='avatar-medium mr-1'
                />
                <span
                  title={(dataLesson && dataLesson.educator) && `${dataLesson.educator.firstName} ${dataLesson.educator.lastName} (${translate('modal.infolesson.educator')})`}
                  className='align-self-center ellipsis-text'
                >
                  {(dataLesson && dataLesson.educator) && `${dataLesson.educator.firstName} ${dataLesson.educator.lastName} `}
                  <small className='text-muted'>{`(${translate('modal.infolesson.educator')})`}</small>
                </span>
              </div>
            }
            Right={
              <div className='d-flex flex-row m-0 p-0'>
                {
                  !substituteEducator &&
                  (
                    <div className='d-flex flex-row align-items-center'>
                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick={false}
                          options={[
                            {
                              component:
    <div className='d-flex flex-row m-0 py-0 px-1'>
      <div className='hover-icon' onClick={() => { setEducatorAttendance('missed') }}><IconSignPlus color='red' /></div>
      <div className='hover-icon' onClick={() => { setEducatorAttendance('pending') }}><IconSignMinus color='#72809D' /></div>
      <div className='hover-icon' onClick={() => { setEducatorAttendance('attended') }}><IconSignCheck color='#8FC813' /></div>
    </div>
                            }
                          ]}
                        >
                          <div className='hover-icon py-1 px-2'>
                            {
                              educatorAttendance === 'missed'
                                ? <IconSignPlus color='red' />
                                : educatorAttendance === 'attended'
                                  ? <IconSignCheck color='#8FC813' />
                                  : <IconSignMinus color='#72809D' />
                            }
                          </div>
                        </DropDown>
                      </div>
                      <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                        <DropDown
                          direction='bottom'
                          fixedWidth
                          hoverEnabled={false}
                          closeMenuWhenClick={false}
                          options={educatorOptions(dataLesson?.educator) || []}
                        >
                          <div className='hover-icon py-1 px-2'>
                            <IconOptions style={{ transform: 'rotate(90deg)' }} />
                          </div>
                        </DropDown>
                      </div>
                    </div>      
                  )
                }
              </div>
            }
          />
        }
        {substituteEducator &&
          (
            <ListItem
              showBottomLine
              showTopLine={false}
              classNameLeft='ellipsis-text'
              classNameRight='flex-grow-2'
              Left={
                <div className='d-flex flex-row'>
                  <Avatar
                    userId={substituteEducator?.educatorUserId || substituteEducator?.id}
                    name={`${substituteEducator?.firstName} ${substituteEducator?.lastName} `}
                    named={false}
                    uri={dataLesson?.aditionalInfo?.substituteAvatar}
                    size='avatar-medium mr-1'
                  />
                  <span
                    title={`${substituteEducator?.firstName} ${substituteEducator?.lastName} (${translate('modal.infolesson.substitute')})`}
                    className='align-self-center ellipsis-text'
                  >
                    {`${substituteEducator?.firstName} ${substituteEducator?.lastName} `}
                    <small className='text-muted'>{`(${translate('modal.infolesson.substitute')})`}</small>
                  </span>
                </div>
              }
              Right={
                <div className='d-flex flex-row align-items-center'>
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      closeMenuWhenClick={false}
                      options={[
                        {
                          component:
    <div className='d-flex flex-row m-0 py-0 px-1'>
      <div className='hover-icon' onClick={() => { setEducatorAttendance('missed') }}><IconSignPlus color='red' /></div>
      <div className='hover-icon' onClick={() => { setEducatorAttendance('pending') }}><IconSignMinus color='#72809D' /></div>
      <div className='hover-icon' onClick={() => { setEducatorAttendance('attended') }}><IconSignCheck color='#8FC813' /></div>
    </div>
                        }
                      ]}
                    >
                      <div className='hover-icon py-1 px-2'>
                        {
                          educatorAttendance === 'missed'
                            ? <IconSignPlus color='red' />
                            : educatorAttendance === 'attended'
                              ? <IconSignCheck color='#8FC813' />
                              : <IconSignMinus color='#72809D' />
                        }
                      </div>
                    </DropDown>
                  </div>
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      closeMenuWhenClick={false}
                      options={educatorOptions(substituteEducator) || []}
                    >
                      <div className='hover-icon py-1 px-2'>
                        <IconOptions style={{ transform: 'rotate(90deg)' }} />
                      </div>
                    </DropDown>
                  </div> 
                </div> 
              }
            />
          )}
      </div>
      <div className='d-flex flex-row mb-3 mt-6'>
        <BackupLinkButton
          className={"w-50 mr-1"} 
          educatorId={dataLesson?.substitute?.educatorUserId || dataLesson?.educator?.educatorUserId}
        />
        <Button
          style={
            dataLesson.location.type === 'virtualClassroom' ? 
            { borderRadius: '16px 0px 0px 16px', backgroundColor: '#696CFF', width: '40%' } : 
            { backgroundColor: '#696CFF', width: '50%' }
          }
          className='ml-1'
          size='large'
          onClick={() => {
            // si location es VCR es launchURL, sino es location.linkStudent!
            setGoToClassLoad(true)
            getvclink(dataLesson?.id, null)
              .then(({launchUrl, location}) => {
                if(location.type === 'virtualClassroom') window.open(hasHttp(launchUrl), '_blank')
                else window.open(hasHttp(location.linkStudent), '_blank')
                setGoToClassLoad(false)
              }, (error) => {
                console.error(error)
                //setVcLinkError(true)
                setGoToClassLoad(false)
              })
              .catch((error) => {
                console.error(error)
                //setVcLinkError(true)
                setGoToClassLoad(false)
              })
          }}
          label={
            goToClassLoad
            ? <div className={'w-100 d-flex flex-row justify-content-center'}><Activity inverse /></div>
            : <span className='text-center'>{translate('modal.infolesson.gotoclassroom')}</span>
          }
        />
        {
          dataLesson.location.type === 'virtualClassroom' && (
          <ToolTipInfo
            tooltipId='reset-vc'
            disable={false}
            innerElement={
                <div>
                    <span>{translate('modal.infolesson.resetvctooltip')}</span>
                </div>
            }
            place='bottom'
         >
          <div className='h-100'>
          <Button
              style={{ backgroundColor: '#696CFF', borderRadius: '0px 16px 16px 0px', marginLeft: '1px', width: '80%' }}
              size='large'
              className={'d-flex flex-row justify-content-center align-items-center h-100'}
              onClick={() => {
                dispatch({
                  type: SET_DATA_MODAL_FORM_LIST,
                  payload: 'plan.setBraincertToLesson',
                  data: {
                    isOpen: true,
                    lessonId: dataLesson.id,
                    isOrientation: false
                  }
                })
              }}
              label={<IconRepeat size={15} />}
            />
            </div>
            </ToolTipInfo>
          )
        }
      </div>
      <div className='zkn-btn znk-size-small w-100 mb-3 btn' style={{ backgroundColor: '#F0F1FF' }}>
        <div className='d-flex flex-row justify-content-between'>
          <span className='h45 text-primary'>{translate('modal.infolesson.startat')}</span>
          <Countdown
            endTime={dataLesson?.startDate ? moment(dataLesson?.startDate) : moment()}
            iconColor='#696CFF'
            timeOverLabel={translate('modal.infolesson.timeisover')}
            spanTimeClassName='h45 text-primary'
            spanSecondsClassName='h45 text-primary'
          />
        </div>
      </div>
      <br />
      <SendLessonNotes
        setStatusOfInfoLessonState={setStatusOfInfoLessonState}
        dataLesson={dataLesson}
        refetchCalendarQuery={refetchCalendarQuery}
      >
        <ListItem
          showTopLine
          className='py-4'
          onClick={() => {

          }}
          Left={
            <div className='d-flex flex-row'>
              <IconSignCheck color='#696CFF' className='align-self-center mr-2' />
              <span className='align-self-center text-primary'>
                End Lesson
              </span>
            </div>
          }
          Right={<IconArrowRight />}
        />
      </SendLessonNotes>
      <ListItem
        showTopLine
        className='py-4'
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'lesson.wspAlert',
            data: {
              isOpen: true,
              dataLesson: dataLesson,
              studentsId: [],
              educatorId: null
            }
          })
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconWspAlert color='#696CFF' className='align-self-center mr-2' />
            <span className='align-self-center text-primary'>
              {translate('modal.infolesson.sendwspalert')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <ListItem
        showTopLine
        className='py-4'
        onClick={() => {
          setShowTab('chat')
          // dispatch({
          //   type: 'SET_DATA_MODAL_FORM_LIST',
          //   payload: 'lesson.wspAlert',
          //   data: {
          //     isOpen: true,
          //     dataLesson: dataLesson,
          //     studentsId: [],
          //     educatorId: null
          //   }
          // })
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconComment color='#696CFF' className='align-self-center mr-2' />
            {/* <IconWspAlert color='#696CFF' className='align-self-center mr-2' /> */}
            <span className='align-self-center text-primary'>
              {/* {translate('modal.infolesson.sendwspalert')} */}
              Chat with student
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      {
        !lessonPast &&
            (
              <ListItem
                showTopLine
                className='py-4'
                Left={
                  <div className='d-flex flex-row'>
                    <IconProgram color='#696CFF' className='align-self-center mr-2' />
                    <span className='align-self-center text-primary'>
                      {translate('modal.infolesson.scheduledoptions')}
                    </span>
                  </div>
                }
                Right={
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='down'
                      fixedWidth
                      hoverEnabled={false}
                      options={[
                        {
                          component: <span onClick={() => {
                            dispatch({
                              type: 'SET_DATA_MODAL_FORM_LIST',
                              payload: 'lesson.rescheduledLesson',
                              data: {
                                isOpen: true,
                                dataLesson: dataLesson
                              }
                            })
                          }}
                          >{translate('modal.infolesson.rescheduling')}</span>
                        },
                      ].concat((!isGroup && false) ? [{
                        component: 
                          <span 
                            onClick={() => {
                              dispatch({
                                type: 'SET_DATA_MODAL_FORM_LIST',
                                payload: 'plan.setPendingLesson',
                                data: {
                                  isOpen: true,
                                  lessonId: dataLesson?.id || ''
                                }
                              })
                            }}>{translate('modal.infolesson.leavepending')}</span>
                        }] : [])}
                    >
                      <IconArrowRight />
                    </DropDown>
                  </div>
                }
              />
            )
      }
      <ListItem
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.duplicateLessonModal',
            data: {
              isOpen: true,
              dataLesson: dataLesson
            }
          })
        }}
        showTopLine
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconDuplicate color='#696CFF' className='align-self-center mr-2' />
            <span className='align-self-center text-primary'>
              {translate('modal.infolesson.duplicatelesson')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <ListItem
        onClick={() => {
          if (!makeup) {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.makeUpLesson',
              data: {
                isOpen: true,
                dataLesson: dataLesson
              }
            })
          }
        }}
        showTopLine
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconRepeat color={makeup ? '#D6DAE3' : '#696CFF'} className='align-self-center mr-2' />
            <span className={`align-self-center ${makeup ? 'text-disabled' : 'text-primary'}`}>
              {translate('modal.infolesson.makeuplesson')}
            </span>
          </div>
        }
        Right={
          makeup
            ? <IconArrowRight color='#D6DAE3' />
            : <IconArrowRight />
        }
      />
      <ListItem
        showTopLine
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconEdit color='#696CFF' className='align-self-center mr-2' />
            <span className='align-self-center text-primary'>
              {translate('modal.infolesson.editlesson')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <ListItem
        showTopLine
        onClick={
          () => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.editPaymentLesson',
              data: {
                isOpen: true,
                dataLesson
              }
            })
          }
        }
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconEdit color='#696CFF' className='align-self-center mr-2' />
            <span className='align-self-center text-primary'>
              {translate('modal.infolesson.editpayment')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <ListItem
        showBottomLine
        showTopLine
        onClick={
          () => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.penalizeLesson',
              data: {
                isOpen: true,
                dataLesson: dataLesson
              }
            })
          }
        }
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconCreditCard color='#696CFF' className='align-self-center mr-2' />
            <span className='align-self-center text-primary'>
              {translate('modal.infolesson.penalizelesson')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <ListItem
        showBottomLine={false}
        showTopLine={false}
        className='py-4'
        Left={
          <div className='d-flex flex-column'>
            <span className='h5 align-self-left text-gray my-2'>
              <b>{translate('modal.infolesson.created')}</b> {translate('modal.infolesson.at')} {`
                                            ${createdDate &&
                                                moment(createdDate).format('MMM DD, YYYY hh:mma')
                                            }
                                        `}
            </span>
            <span className='h5 align-self-left text-gray my-2'>
              <b>{translate('modal.infolesson.update')}</b> {translate('modal.infolesson.at')} {`
                                            ${updateDate &&
                                                moment(updateDate).format('MMM DD, YYYY hh:mma')
                                            }
                                        `}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <div
        className='pb-4'
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.cancelLesson',
            data: {
              isOpen: true,
              lessonId: dataLesson?.id || '',
              isOrientation: false,
              dataLesson
            }
          })
        }}
      >
        <span className='h5 text-gray'>{translate('modal.infolesson.youneedremove')}</span>
      </div>
      </TabPane>
      <TabPane tabId='chat' className={`h-100 ${showTab === 'chat' ? 'd-flex flex-column' : ''}`}>
        {/* <div className={'w-100 d-flex flex-row justify-content-start'}>
          <div className='ml-2 hover-icon' onClick={() => { setShowTab('lesson') }}>
            <IconArrowLeft size={20} color={'#818ea7'} />
          </div>
        </div> */}
    {/* <div 
onClick={()=>{setShowTab('menu')}}
className='hover-icon'>
    <IconArrowLeft size={12} />
</div> */}
        <VirtualChat
          // {...taskData}}
          active={showTab === 'chat'}
          collectionType={collectionType[dataLesson?.__typename] || 'messagesTask'}
          usersList={{usersList: userArray || []}}
          collection={dataLesson}
          actualUserId={currentUserId}
          staffCanRead={true}
          onBack={() => {setShowTab('lesson')}}
          toggle={() => {
              toggle()
          }}
          {...virtualChatProp}
        />
        </TabPane>
      </TabContent>
    </>
  )
}

export {
  InProgressHeaderModal,
  InProgressBodyModal
}
