import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../../i18n/useTranslation'
import removeAccountFromUser from '../../../../../../../Model/Users/AccountsFromUser/DeleteAccount/removeAccountFromUser'
import {useDispatch} from 'react-redux'
import { showAlert } from '../../../../../../../common/Alert/util'

const AccountRemove = (props) => {
  const {isOpen, toggle, refetch = () => {}, user, account} = props
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  const {
    submit: removeAccountMutation,
    loading: removeAccountLoading,
  } = removeAccountFromUser(
    () => {
      showAlert({text: 'Success!', status: 'success'}, dispatch)
      toggle()
      refetch()
    },
    (err) => {
      showAlert({text: err.toString(), status: 'error'}, dispatch)
      console.error('Error on delete account : ', err)
    },
  )

  return (
    <ConfirmModal
      className='m-4'
      isOpen={isOpen}
      toggle={toggle}
      loading={removeAccountLoading}
      title={translate('profile.actions.accounts.deleteAccountTitle')}
      body={translate('profile.actions.accounts.deleteAccountText')}
      classNameActionDiv='d-flex flex-column justify-content-end w-80'
      confirmAction={() => {
        removeAccountMutation({
          userId: user?.id, 
          platformName: account?.platform,
        })
      }}
      cancelAction={toggle}
      confirmText={translate('profile.actions.accounts.confirmButton')}
      cancelText='Cancel'
    />
  )
}

export default AccountRemove
