import React from 'react'
import DinamicHeightPage from '../../Components/DinamicHeightPage'
import { IconIllustratedPending } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const ErrorPage = (props) => {
    const { bodyRef, onContinue = () => {} } = props
    const {translate} = useTranslation()
    return (
        <DinamicHeightPage
            bodyRef={bodyRef}
        >
            <div className='p-0 m-0 d-flex flex-column justify-content-center align-items-center'>
                <IconIllustratedPending size={120} />
                <label className="font-weight-bold h3 text-center mt-4">
                    {translate('workspace.tasks.assignMultiTask.modal.pages.error.text')} <span className='btn-link text-primary' onClick={onContinue}>{translate('workspace.tasks.assignMultiTask.modal.pages.error.tryAgain')}</span>
                </label>
            </div>
        </DinamicHeightPage>
    )
}

export default ErrorPage