import React from 'react'
import moment from 'moment-timezone'
import {
    ModalLayout,
    IconDate,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useShowUploadFileModal from './useShowUploadFileModal'
import UploadFile from './Components/uploadFile'
import IsSpecific from '../../Components/IsSpecific'

const UploadFileModal = () => {

    const { isOpen, toggle, task } = useShowUploadFileModal()



    return (
        <>
            <ModalLayout
                isOpen={isOpen}
                toggle={toggle}
                className='modal-colored modal-sidebar modal-desktop-half white-modal'
                topHead={
                    <span className='h2' style={{ color: '#485a70' }}>
                        {task?.name}
                    </span>
                }
                minHead={
                    <span className='h2' style={{ color: '#485a70' }}>
                        {task?.name}
                    </span>
                }
                useRightSection={false}
                disabledScrollAnimation={false}
            >
                <div className='d-flex flex-row justify-content-between my-4'>
                    <div className='d-flex flex-row align-items-center m-0 p-0'>
                        <IconDate
                            size={15}
                            color={`${moment(task?.dueDate) < moment()
                                    ? '#FF3E1D'
                                    : // : moment(task?.dueDate) > moment()
                                    // ? '#6c757d'
                                    '#2C3F78'
                                }`}
                        />
                        <span
                            className={`h45 ml-2 ${moment(task?.dueDate) < moment()
                                    ? 'text-error'
                                    : // : moment(task?.dueDate) > moment()
                                    //   ? 'date-ontime'
                                    'text-info'
                                }`}
                        >
                            {`${moment(task?.dueDate).fromNow()}`}
                        </span>
                    </div>

                    {task?.isEspecific && (
                        <IsSpecific universitiesList={task?.universities || []} findInDB />
                    )}
                </div>

                <UploadFile key='1'/>


            </ModalLayout>

        </>
    )
}

UploadFileModal.defaultProps = {
    isOpen: false,
    toggle: () => { },
}

UploadFileModal.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
}

export default UploadFileModal
