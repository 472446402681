import React from 'react'
import { ListItem, IconDuplicate } from '@Knowledge-OTP/znk-ui-components'

const EmailItemList = (props) => {

    const {item, index, onClick} = props

    return (
        <div>
            <ListItem
                className={'py-1'}
                key={index}
                hasHover
                showTopLine={index === 0 }
                showBottomLine
                classNameLeft={'white-space-nowrap text-truncate'}
                Left={
                    <div className={`d-flex flex-row ml-2`}>
                        <span className={`align-self-center ml-4 text-info`}
                        >{item}</span>
                    </div>
                }
                Right={
                <IconDuplicate size={15} color={'gray'}
                title={'Copy'}
                onClick={()=> {onClick()}}
                />
                }
            />
        </div>
    )
}

export default EmailItemList

