import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  IconRemove,
  Task,
} from '@Knowledge-OTP/znk-ui-components'
import useUploadFileToTask from '../Models/useUploadFileToTask'
import useRemoveFilesFromTask from '../Models/useRemoveFilesFromTasks'
import './index.css'
import useTranslation from '../../../../../../../i18n/useTranslation'
import useOperationTaskMutation from '../../../../Model/useOperationTask'
import dragAndDropIcon from '../../../../../../../assets/drag-and-drop.png'
import phaseNames from '../Utils/phaseNames'
import { UseGetUserTask } from '../../ShowScheduledMeeting/Models/useGetUserTask'
import { showAlert } from '../../../../../../../common/Alert/util'
import useViewTaskModal from '../../../useViewTaskModal'
import useShowUploadFileModal from '../useShowUploadFileModal'

const UploadFile = () => {
  // Si se cambia el máximo de archivos que se pueden subir, cambiar esta constante
  const maxNumOfFiles =
    process.env.MAX_QUANTITY_FILES ||
    process.env.REACT_APP_MAX_QUANTITY_FILES ||
    10 // Queda 10 por defecto, para evitar errores si no están las variables de entorno
  const maxSize = process.env.MAX_SIZE || process.env.REACT_APP_MAX_SIZE || 300 // Poner la cantidad máxima de mb
  const byteInMb = 1048576 // cuantos bytes son 1 mb
  const { translate } = useTranslation()
  const { close: closeUploadFile } = useShowUploadFileModal()
  const task = useSelector(
    (state) =>
      state?.modal?.counseling?.uploadFileModal?.task,
  )
  const planId = task?.planId

  const { taskReducer: { setData } } = useViewTaskModal()
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')

  const [filesUploadedByUser, setFilesUploadedByUser] = useState([])

  const dropArea = useRef(null)
  const dragImage = useRef(null)
  const dragSpan = useRef(null)
  const filesUploadedOnTask = useMemo(() => {
    return task?.resolveTask?.uploadFiles
  }, [task])
  const [showFilesToUpload, setShowFilesToUpload] = useState(true)
  const checkBox = document.querySelectorAll('.checkbox-input')

  useEffect(() => {
    if (dropArea && dropArea.current) {
      // If user Drag File Over DropArea
      dropArea.current.addEventListener('dragover', (event) => {
        event.preventDefault() // preventing from default behaviour
        dropArea.current.classList.add('active')
        dragImage.current.style.visibility = 'visible'
        dragSpan.current.style.visibility = 'hidden'
      })

      // If user leave dragged File from DropArea
      dropArea.current.addEventListener('dragleave', () => {
        dropArea.current.classList.remove('active')
        dragImage.current.style.visibility = 'hidden'
        dragSpan.current.style.visibility = 'visible'
      })
    }
  }, [])

  // eslint-disable-next-line
  const [getOneTask, { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask }] = UseGetUserTask(
    (resp) => {
      const taskToSave = resp?.getUserTask
      setData(taskToSave)
      showAlert({
        text: 'Files uploaded',
        status: 'success'
      }, dispatch)
      setShowFilesToUpload(false)
      closeUploadFile()
    },
    (err) => {
      console.error('Error on getting task : ', err)
    },
  )

  // Mutation para subir archivos a la task
  const {
    submit: submitUpload,
    loading: uploadLoading,
    data: uploadData,
  } = useUploadFileToTask(
    () => {
      sendToReviewFunction()
    },
    () => { },
  )

  // Mutation para borrar archivos subidos a la task
  const [
    removeFileMutation,
    // eslint-disable-next-line
    { errors: removeErrors, loading: removeLoading },
  ] = useRemoveFilesFromTask(
    () => {
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase: phaseNames[task?.phase] || phaseNames[task?.phaseName] || phaseNames[task?.phaseId],
          planId,
        },
      })
    },
    (errors) => { },
  )

  // Mutation para enviar a review la task
  const [
    operationMutation,
    // eslint-disable-next-line
    { loading: loadMutation, data: dataMutation },
  ] = useOperationTaskMutation(
    () => {
      getOneTask({
        variables: {
          userId: task?.userId,
          taskId: task?.id,
          stepId: task?.stepId,
          phase: phaseNames[task?.phase] || phaseNames[task?.phaseName] || phaseNames[task?.phaseId],
          planId,
        },
      })
    },
    (err) => {
      console.error('Error on complete task : ', err)
    },
  )

  const sendToReviewFunction = () => {
    operationMutation({
      variables: {
        universities: task?.universities,
        operation: 'review',
        userId: task?.userId,
        taskId: task?.id,
        stepId: task?.stepId,
        phase: phaseNames[task?.phase] || phaseNames[task?.phaseName] || phaseNames[task?.phaseId],
        planId,
      },
    })
  }

  // Función que maneja los cambios en el Drag and Drop de los files
  const handleFileChange = (e) => {
    // If user drop File on DropArea
    dropArea.current.classList.remove('active')
    dragImage.current.style.visibility = 'hidden'
    dragSpan.current.style.visibility = 'visible'
    let fullSize = 0
    if (!e.target.files) return
    if (e.target.files?.length > maxNumOfFiles) {
      setMessage(translate('counseling.maxFilesError') + maxNumOfFiles)
      showAlert({
        text: message,
        status: 'error'
      }, dispatch)
    }

    if (e?.target?.files) {
      fullSize = Array.from(e?.target?.files).reduce((fullSize, file) => {
        return fullSize + file.size
      }, 0)
    }

    if (fullSize > maxSize * byteInMb) {
      setMessage(translate('counseling.maxSizeError') + `${maxSize} mb`)
      showAlert({
        text: message,
        status: 'error'
      }, dispatch)
    }
    setFilesUploadedByUser(
      filesUploadedByUser.concat(Array.from(e?.target?.files)),
    )
    if (showFilesToUpload === false) setShowFilesToUpload(true)
  }

  // Función que es llamada para subir archivos a una task
  const uploadFileFunction = () => {
    if (filesUploadedByUser?.length === 0) return
    try {
      submitUpload({
        files: filesUploadedByUser,
        taskId: task?.id,
        stepId: task?.stepId,
        phase: phaseNames[task?.phase] || phaseNames[task?.phaseName] || phaseNames[task?.phaseId],
        planId,
      })
    } catch (error) {
      setMessage(error.toString())
      showAlert({
        text: message,
        status: 'error'
      }, dispatch)
    }
  }

  // Función que es llamada al intentar borrar archivos de una task
  // eslint-disable-next-line
  const removeFileFunction = (urlsArray = []) => {
    // Busca las urls de los files checkeados
    if (urlsArray?.length === 0) {
      Array.from(checkBox).forEach((x) => {
        if (x.checked) {
          urlsArray.push(filesUploadedOnTask[x.id].url)
        }
      })
    }
    if (urlsArray?.length === 0) return

    removeFileMutation({
      variables: {
        files: urlsArray,
        taskId: task?.id,
        stepId: task?.stepId,
        phase: phaseNames[task?.phase] || phaseNames[task?.phaseName] || phaseNames[task?.phaseId],
        planId,
      },
    }).then((resp) => {
      if (resp?.data?.removeFilesFromTask === true) {
        setShowFilesToUpload(false)
      } else {
        setMessage(removeErrors)
        showAlert({
          text: message,
          status: 'error'
        }, dispatch)
      }
    })
  }

  // Función para borrar un archivo del array de archivos que se va a subir
  const removeFromListFunction = (itemRemove) => {
    setFilesUploadedByUser(filesUploadedByUser.filter(elem => elem !== itemRemove))
  }

  /* eslint-disable */
  useEffect(() => {
    if (uploadData) {
      setShowFilesToUpload(false)
      setFilesUploadedByUser([])
    }
  }, [uploadData])
  /* eslint-enable */

  return (
    <>
      <div
        id='dropZone'
        ref={dropArea}
        className='file-select mb-4 d-flex flex-column align-items-center justify-content-center'
      >
        <img
          className='d-flex align-items-center justify-content-center'
          style={{ visibility: 'hidden' }}
          ref={dragImage}
          id='dragImage'
          src={dragAndDropIcon}
          alt='Drag And Drop Icon'
          color='#696bff'
          width='100'
          height='100'
        />
        <span
          className='d-flex flex-column align-items-center justify-content-center'
          style={{
            visibility: 'visible',
            textAlign: 'center',
          }}
          ref={dragSpan}
          id='dragSpan'
          color='#696bff'
          width='100'
          height='100'
        >
          {' '}
          Drag and Drop here or <br />
          <span className='cursor-pointer'>
            {' '}
            <u>Browse files </u>{' '}
          </span>
        </span>
        <input
          className='d-flex justify-content-center py-2 mb-4 input-drop'
          type='file'
          style={{ backgroundColor: 'transparent' }}
          id='input'
          name='input'
          onChange={handleFileChange}
          multiple
        />
      </div>
      <div
        id='files-info'
        className='d-flex flex-column mb-4'
        style={{
          display: showFilesToUpload ? 'block' : 'none',
        }}
      >
        {filesUploadedByUser?.length > 0 && (
          <span className='h4 mb-1'>
            Files to upload
          </span>
        )}
        {filesUploadedByUser?.length > 0 &&
          filesUploadedByUser?.map((x, index) => (
            <div className='d-flex flex-row align-items-center justify-content-between'>
              <Task
                noIcon
                taskClassName='my-1'
                labelLeft={
                  <div>
                    <span style={{ lineHeight: '1.6', color: '#4a6270' }}>
                      {x.name}
                    </span>
                  </div>
                }
                labelRight={
                  <IconRemove
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      removeFromListFunction(x)
                    }}
                  />
                }
              />
            </div>
          ))}
      </div>
      <div>
        <Button
          label={task?.buttonLabel}
          activity={uploadLoading || loadingOneTask || loadMutation}
          style={{ backgroundColor: '#696CFF' }}
          className='w-100 mb-4'
          size='large'
          onClick={() => uploadFileFunction()}
          disabled={filesUploadedByUser?.length === 0}
        />
      </div>
    </>
  )
}

export default UploadFile
