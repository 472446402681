import React, { useState, useEffect } from 'react'
import {
    ListItem,
    IconArrowRight,
    IconDate
} from '@Knowledge-OTP/znk-ui-components'
import * as moment from 'moment'

const SelectDate = (props) => {
    const { task, value, onChange, isEdit = true, ...otherProps } = props
    const today = moment().format("YYYY-MM-DD")

    const [taskState, setTaskState] = useState(task)
    useEffect(() => { setTaskState(task) }, [task])
    const {
        dueDate,
    } = taskState


    return (
        <>
            <ListItem
                Left={
                    <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                        <IconDate className={`text-info`}/>
                        <span 
                        id="dueDateText" name="dueDateText"
                        className='ml-4 text-info'
                        >
                            {moment(dueDate).format("MMM Do, YYYY")}
                        </span>
                        <input type="date" id="dueDate" name="dueDate"
                            min={today}
                            defaultValue={value}
                            style={{
                                border: '0',
                                outline: '0',
                                position: 'absolute',
                                top: '200',
                                right: '0',
                                marginRight: '40px',
                                opacity: '0'
                            }}
                            onChange={
                                (e) => { onChange(e?.target.value) }
                            }
                        ></input>
                    </div>
                }
                Right={
                    isEdit &&
                    <div className={'d-flex flex-row'}
                    >
                        <div className={`hover-icon mr-2`}
                        >
                            <IconArrowRight
                            />
                        </div>
                    </div>
                }
                showBottomLine={true}
                {...otherProps}
            />
        </>
    )
}

export default SelectDate