import React, { useState, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  ModalLayout,
  useWindowDimensions,
  ListOption,
  StickyButton,
  Input,
  IconSearch,
  IconOutLine,
  IconArrowRight,
  // InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import AllActivities from '../../AllActivities'
import useCreateTestModal from '../../../../../Timelines/Modal/CreateTestModal/useCreateTestModal'
import moment from 'moment'
import HandleCreateTestDate from './HandleCreateTestDate'

const SelectActivity = (props) => {
  const { 
    setTestDate, 
    idService, 
    nameService, 
    statusPlan/** , selectService = false**/, 
    useCompletedForm = false/*se usa en false desde formulario para la creación de un plan de liveprep*/ 
  } = props
  const [isOpen, setOpen] = useState(false)
  const [selectTestDate, setSelectTestDate] = useState({})
  const toggle = () => { setOpen(!isOpen) }
  const { breakWidth } = useWindowDimensions()
  const now = useMemo(() => moment().toISOString(), [])
  const { data: allActivities, loading, refetch } = AllActivities({
    serviceIds: useCompletedForm ? [] : [idService],
    kinds: ['test'],
    from: new Date(now)
  })

  const {open: openCreateTestModal, close: closeCreateTestModal} = useCreateTestModal()
  const RenderNewTestItem = ({...otherProps}) => {
    return (
      <ListOption
        className='mb-2'
        id='x-0'
        IconRight={<IconArrowRight size={15} color='#696CFF' />}
        childClassName='d-flex'
        label={<span className='m-0 p-0' style={{ color: '#696CFF' }}>Create new test date</span>}
        icon={
          <IconOutLine
            className='p-3 mr-1'
            backgroundColor='#696CFF'
            color='#FFFFFF'
            textChildren={<span className='m-0 font-weight-bold' style={{ color: 'white' }}>+</span>}
            sizePx={25}
          />
        }
        {...otherProps}
      />
    )
  }
  const [comment, setComment] = useState(null)


  const [refetchLoading, setRefetchLoading] = useState(false)
  const refetchFromHeader = () => {
    setRefetchLoading(true)
    refetch().then(
      () => {setRefetchLoading(false)},
      () => {setRefetchLoading(false)}
    )
  }

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={statusPlan ? statusPlan !== 'scheduled' ? toggle : () => {} : toggle}>
        {props.children}
      </div>
      <ModalLayout
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        options={[]}
        loadingRepeat={refetchLoading}
        onRepeat={refetchFromHeader}
        topHead={
          <span className='text-title font-weight-bold h3'>
            Select test date {nameService && <small className='text-muted'>{`(${nameService})`}</small>}
          </span>
        }
        disabledScrollAnimation
      >
        <div className='m-0 p-0'>

          <Input
            className='w-100 input-xs p-0 mb-3'
            placeholder='Buscar test date'
            addOnComponent={<IconSearch size={16} />}
            type='addon'
            onChange={(e) => {
              // setTestDateSearch(e.target.value)
            }}
          />
          <div className='m-0 p-0 px-0'>
            {
              useCompletedForm ?
                <RenderNewTestItem onClick={() => {
                  openCreateTestModal((newTestDate) => {
                    // ACA EL TEST DATE YA FUE CREADO
                    setSelectTestDate({
                      ...newTestDate,
                      id: newTestDate?.eventId || newTestDate?.id,
                      endDate: moment(newTestDate?.date).toISOString(),
                      startDate: moment(newTestDate?.date).toISOString()
                    })
                    
                    refetch()
                    closeCreateTestModal()
                  })
                }} /> : 
                <HandleCreateTestDate
                  serviceId={idService}
                  refetch={(res) => {
                    setSelectTestDate(res)
                    refetch()
                  }}
                >
                  <RenderNewTestItem/>
                </HandleCreateTestDate>
            }
            <div className='m-0 p-0' style={{ maxHeight: '250px', overflowY: 'scroll' }}>
              {!loading ? allActivities && allActivities.allActivities.filter((activity) => activity.isDisabled === false || !activity.isDisabled).map((activity) => {
                return (
                  <ListOption
                    className='mb-2'
                    childClassName='d-flex'
                    selectedInput={selectTestDate.id === activity.id}
                    id='x-0'
                    label={`${activity.name}`}
                    icon={
                    null
                    }
                    onClick={() => { setSelectTestDate(activity) }}
                  />
                )
              })
                : <Skeleton count={6} height={40} />}
            </div>

            {
              // useCompletedForm 
              false && (
                <div className={`m-0 my-4 p-0 w-100 d-flex flex-column`}>
                  <label className={`text-gray`}>Comment</label>
                  <Input type={'textarea'} value={comment} onChange={(e) => {setComment(e.target.value)}} />
                </div>
              )
            }
          </div>
          <StickyButton
            color='#696CFF'
            condition={selectTestDate.id}
            label='Select test date'
            onClickButtonFunction={() => {
              setTestDate({...selectTestDate, comment})
              toggle()
            }}
          />
        </div>
      </ModalLayout>
    </>
  )
}

SelectActivity.propTypes = {
}

SelectActivity.defaultProps = {
}
export default SelectActivity
