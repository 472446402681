import { useDispatch, useSelector } from "react-redux"

const useNewStepModal = () => {
    const reduxData = useSelector((state) => state.modal.counseling.newStepModal)
    const {isOpen, onChange, defaultValue, currentPhase} = reduxData
    const dispatch = useDispatch()    
    const setOpen = (open, others) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.newStepModal',
            data: {
                ...reduxData,
                ...others,
                isOpen: open,
            }
        })
    }
    return {
        isOpen,
        open: (onChange = () => {}, defaultValue = null, currentPhase = null) => {
            setOpen(
                true, 
                {
                    onChange, 
                    defaultValue,
                    currentPhase
                }
            )
        },
        close: () => {setOpen(false, {})},
        toggle: () => {setOpen(!isOpen, {})},
        onChange,
        defaultValue,
        currentPhase
    }
}

export default useNewStepModal