import React, { Fragment } from 'react'
import * as moment from 'moment'
import { IconArrowRight, IconLesson, ListItem, ToolTipInfo } from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../../../store/modal/const'
import { nanoid } from 'nanoid'

const PartSchedule = (props) => {
  const { groupPart, idx, currentService, where, educatorsBase, form, disabled = false } = props
  const { startdate, enddate, partname, ...topics } = groupPart
  const dispatch = useDispatch()
  const scheduleGroup = (data) => {
    if (!data.educator) return
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: where ? 'groupPlans.schedulePartEdit' : 'groupPlans.schedulePart',
      data: {
        isOpen: true,
        ...data
      }
    })
  }

  return (
    <div className='d-flex flex-column'>
      <span className='h5 font-weight-light text-gray my-2 text-uppercase'>
        {`Part ${idx + 1}: from ${moment(startdate).format('MMMM Do')} to ${moment(enddate).format('MMMM Do')}`}
      </span>
      {
        currentService?.topics && Object.entries(topics).map(([topicId, lpw]) => {
          const topic = currentService.topics.find(topic => topic.id === topicId)
          const eduTemp = form.educatorsGroupFull.find(ele => ele.topicId === topicId && ele.partIndex === idx)
          const dates = (lpw?.groupBlocks || []).map(
            ({ val }) => {
              return moment().isoWeekday(val.day).hour(val.hour).minutes(0).format('dddd hh:mm a')
            }
          )
          console.log("lpw?.groupBlocks", lpw?.groupBlocks)
          console.log("dates", dates)
          const toolId = nanoid()
          return (
            <Fragment key={`${topicId}-${idx}`}>
              {
                lpw.valueGroup ? (
                  <ToolTipInfo
                    tooltipId={toolId}
                    place={'left'}
                    innerElement={
                      <div className={`d-flex flex-column`}>
                        {
                          dates.map(
                            (date) => {
                              return (<span>{date}</span>)

                            }
                          )
                        }
                      </div>
                    }
                  >
                    <ListItem
                      className={`${(lpw.groupBlocks && lpw.groupBlocks.length === lpw.valueGroup) || disabled ? '' : 'btn-link'} py-2`}
                      onClick={() => {
                        if (disabled) return
                        scheduleGroup({
                          startdate,
                          enddate,
                          num: lpw.valueGroup,
                          educator: eduTemp ? educatorsBase.filter(t => t.id === eduTemp.educatorUserId)[0] : undefined,
                          topicId,
                          idx,
                          topic,
                          service: currentService,
                          groupBlocks: lpw.groupBlocks || [],
                          statusPlan: form.statusPlan
                        })
                      }}
                      Left={
                        <>
                          {lpw.groupBlocks && lpw.groupBlocks.length === lpw.valueGroup ? (
                            <span className={`text-info ${disabled ? `cursor-not-allowed` : `btn-link cursor-pointer`}`}>
                              <IconLesson size={16} className='text-logo' />
                              <span className='ml-2'>
                                {`${lpw.valueGroup} group ${topic ? topic.name : 'No Found'} lessons`}
                              </span>
                            </span>
                          ) : <span className='btn-link'>{`Schedule ${lpw.valueGroup} group ${topic ? topic.name : ''} lessons`}</span>}
                        </>
                      }
                      Right={
                        !disabled && (
                          <div className={`hover-icon`}>
                            <IconArrowRight size={20} />
                          </div>
                        )
                      }
                    />
                  </ToolTipInfo>
                ) : null
              }
              {
                lpw.valueIndividual ? (
                  <span className='text-info my-1'>
                    <IconLesson size={16} />
                    <span className='ml-2'>
                      {`${lpw.valueIndividual} individual ${topic ? topic.name : 'No Found'} lessons`}
                    </span>
                  </span>
                ) : null
              }
            </Fragment>
          )
        })
      }
    </div>
  )
}

export default PartSchedule
