import {useMutation, gql} from '@apollo/client'

const useSendWspAlertForCheckIn = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation(
                $studentId: String!
                $reason: IssueCheckInReason!
                $messages: [CheckInMessage!]!
                $checkInId: String
            ) {
                sendWspAlertForCheckIn (
                    studentId: $studentId
                    reason: $reason
                    messages: $messages
                    checkInId: $checkInId
                )
            }
        `,
        {
            onCompleted: onSuccess, onError
        }
    )
}

export default useSendWspAlertForCheckIn