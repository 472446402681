import { gql, useQuery } from '@apollo/client'
export const queryUserResult = `
id
isActive
emails {
	address
	verified
}
countryId
gender
spokenLanguages
address
city
state
zipCode
firstName
lastName
paymentMethod {
	kind
}
phone
timezone
birthdate
studentInfo {
	notRegistrationFeePaid
	isFirtsPlan
	grade
	institution
	graduationDate
	studentType
	defaultRate
	gpa
	servicesRate {
		defaultRate
		serviceId
		name
	}
	parents {
		parentUserId
		managePayments
		manageApprovals
		firstName
		lastName
		email
		phone
	}
	universities {
		id
		collegeId
		name
		status
		early
	}
}
`
export const queryUserString = 
	`query(
		$roles: [Roles!], 
		$limit: Int, 
		$sortField: String
		$search: String
		$previous: String
		$next: String
	) {
		allUsers(
			roles: $roles, 
			limit: $limit, 
			sortField: $sortField
			search: $search,
			previous: $previous,
			next: $next 
		) {
			hasNext
			hasPrevious
			next
			previous
			totalDocs
			docs {
				${queryUserResult}
				avatar
			}
		}
	}`
export const UseAllUsers = (vars = {}, options={}) => {
  return useQuery(
    gql`${queryUserString}`,
	{
		variables: vars,
		...options
	}
  )
}
