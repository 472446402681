import axios from "axios"

// Función para completar la información necesaria del College para el modal
const CompleteUniversityInfo = async (college, infoToObtain = []) => {
  const CollegeURL = process.env.COLLEGE_URL || process.env.REACT_APP_COLLEGE_URL
  const CollegeAPI = process.env.COLLEGE_API || process.env.REACT_APP_COLLEGE_API

  if (!college.collegeUnitId && !college.collegeId) return;
  const idForSearch = college.collegeUnitId || college.collegeId
  //Convertir el arreglo a una string para pasar por params a la API
  let params = infoToObtain.join('%2C')

  const url =  `${CollegeURL}/college/info?api_key=${CollegeAPI}&college_unit_ids=${idForSearch}&info_ids=${params}`
  const respuesta = await axios.get(url)
  let university = respuesta?.data?.colleges?.length > 0 ? respuesta.data?.colleges[0] : {}

    university.collegeUnitId = idForSearch
    university.id = college.id
    university.status = college.status
    university.early = college.early
    university.isActive = college.isActive
    university.isPrincipal = college.isPrincipal
    university.deletedAt = college.deletedAt || null
    university.logo = college.logo

return university
}

export default CompleteUniversityInfo