import React from 'react'
import {IconBank, ListItem} from '@Knowledge-OTP/znk-ui-components'

const TransferMethod = (props) => {
  const {accountInfo, isClickable, isParent} = props
  return (
    <>
      <ListItem
        className='px-4'
        showTopLine={!isParent}
        showBottomLine={!isParent}
        hasColorHover={isClickable}
        hasHover={isClickable}
        Left={
          <span className='d-flex align-items-center text-gray'>
            <IconBank className='mr-2' />
            ACH Credit
          </span>
        }
        Right={<span>{`${accountInfo.bankName}`}</span>}
      />
    </>
  )
}

export default TransferMethod
