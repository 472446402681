import React, { useEffect, useMemo, useState } from 'react';
import {
    useWindowDimensions,
    ModalLayout,
    IconArrowLeft,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import useCompleteGroupPlan from '../../../Model/useCompleteGroupPlan.js';
import { TabContent, TabPane } from 'reactstrap'
import useCompleteGroupPlanModal from './useCompleteGroupPlanModal.js'
import { showAlert } from '../../../../../common/Alert/util.js';
import { useDispatch } from 'react-redux';
import SelectLessonsForPlanSection from './Sections/SelectLessonsForPlan/index.js'
import ConfirmCompleteSection from './Sections/ConfirmComplete/index.js';
import CannotCompleteSection from './Sections/CannotComplete/index.js';
import { useLazyQuery, gql } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import useTranslation from '../../../../../i18n/useTranslation.js';

const CompleteGroupPlanModal = () => {
    const { isOpen, toggle, dataPlan, onChange, close: closeModal } = useCompleteGroupPlanModal()
    const {translate} = useTranslation()
    const childPlans = useMemo(() => {
        return dataPlan?.childPlans
    }, [dataPlan])
    const groupPlanId = useMemo(() => {
        return dataPlan?.id
    }, [dataPlan])

    const [currentIndex, setCurrentIndex] = useState(0)
    useEffect(() => {
        if (isOpen) {
            setPendingLessons([])
            setCurrentIndex(0)
        }
    }, [isOpen])

    const { breakWidth } = useWindowDimensions()
    const dispatch = useDispatch()
    const [completeGroupPlan, { loading }] = useCompleteGroupPlan(
        (res) => {
            showAlert({
                text: 'Success',
                status: 'success'
            }, dispatch)
            if (onChange) onChange()
            closeModal()
        },
        (err) => {
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
        }
    )

    const [activeTab, setActiveTab] = useState('blank')
    const [getGroupLessons, {loading: loadGroupLessons}] = useLazyQuery(gql`query($planId: String, $limit: Int, $status: [LessonStatus!]!){lessons(planId: $planId, limit: $limit, status: $status){docs {id status}}}`)
    useEffect(
        () => {
            if (childPlans && groupPlanId) {
                getGroupLessons({
                    variables: {
                        planId: groupPlanId,
                        limit: 0,
                        status: ['pending', 'scheduled']
                    }
                }).then(
                    (res) => {
                        const groupLesson = res?.data?.lessons?.docs || []
                        //el plan no se puede completar cuando 
                        //1.- tiene al menos un plan individual que no tenga status 'scheduled', 'draft' o 'cancel' 
                        //2.- tiene al menos una lección en pending o scheduled
                        const cannotComplete =
                            (childPlans?.length > 0 &&
                            childPlans.map(({ status }) => status).filter(
                                (status) => !['scheduled', 'draft', 'cancel', 'finished'].includes(status)
                            )?.length > 0) || groupLesson?.length > 0

                        if (cannotComplete) {
                            setActiveTab('cannot-complete')
                        } else {
                            setActiveTab(`${childPlans[currentIndex]?.id}`)
                        }
                    },
                    (err) => { console.error(err.toString()) }
                )

            }
        }, [childPlans, currentIndex, getGroupLessons, groupPlanId]
    )
    const [pendingLessons, setPendingLessons] = useState([])


    const showBackButton = currentIndex > 0 || activeTab === 'finish'
    return (
        <ModalLayout
            isOpen={isOpen}
            toggle={toggle}
            className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'
                } modal-colored h-auto`}
            topHead={
                <span className={`h2 font-weight-bold text-title`}>
                    {"Complete Group Plan"}
                </span>
            }
        >
            {
                childPlans && (
                    <>
                        <TabContent activeTab={loadGroupLessons ? 'loading' : activeTab}>
                            {
                                childPlans.map((currentPlan) => {
                                    return (
                                        <>
                                            <TabPane tabId={`${currentPlan?.id}`}>
                                                <SelectLessonsForPlanSection
                                                    pendingLessons={pendingLessons}
                                                    currentPlan={currentPlan}
                                                    onPrevious={
                                                        () => {
                                                            if (currentIndex <= 0) return
                                                            setCurrentIndex(currentIndex - 1)
                                                        }
                                                    }
                                                    onNext={() => {
                                                        if (currentIndex > childPlans?.length - 1) return
                                                        else if (currentIndex === childPlans?.length - 1) {
                                                            setActiveTab('finish')
                                                        } else {
                                                            setCurrentIndex(currentIndex + 1)
                                                        }
                                                    }}
                                                    onChecked={(lessons, bool) => {
                                                        if (bool) {
                                                            const appendLessons = (lessons || []).reduce((newArray, lesson) => {
                                                                if (!pendingLessons.map(({ id }) => id).includes(lesson.id)) {
                                                                    //la lección no está seleccionada
                                                                    newArray.push(lesson)
                                                                }
                                                                return newArray
                                                            }, [])
                                                                || []
                                                            setPendingLessons(pendingLessons.concat(appendLessons))
                                                        }
                                                        else setPendingLessons(
                                                            (pendingLessons || []).filter(({ id }) => !lessons.map(({ id }) => id).includes(id)))
                                                    }}
                                                />

                                            </TabPane>

                                        </>
                                    )
                                })
                            }
                            <TabPane tabId={`cannot-complete`}>
                                <CannotCompleteSection toggle={toggle} />
                            </TabPane>
                            <TabPane tabId={`finish`}>
                                <ConfirmCompleteSection pendingLessons={pendingLessons} />
                            </TabPane>
                            <TabPane tabId={`blank`}>
                                BLANK
                            </TabPane>

                            <TabPane tabId={`loading`}>
                                <Skeleton count={3} height={30} className="w-100" />
                            </TabPane>
                        </TabContent>


                        {!['blank', 'cannot-complete'].includes(activeTab) && (
                            <div className={`d-flex flex-row align-items-center w-100`}>
                                {
                                    showBackButton && (
                                        <Button
                                            color='primary'
                                            className={'w-20'}
                                            disabled={loading}
                                            onClick={() => {
                                                if (activeTab === 'finish') {
                                                    setActiveTab(`${childPlans[currentIndex]?.id}`)
                                                    return
                                                }
                                                if (currentIndex <= 0) return
                                                setCurrentIndex(currentIndex - 1)
                                            }}
                                            label={
                                                <IconArrowLeft />
                                            }
                                            style={{ borderRadius: '16px 0px 0px 16px', marginRight: '1px' }}
                                        />
                                    )
                                }
                                <Button
                                    color='primary'
                                    activity={loading}
                                    className={showBackButton ? `w-80` : `w-100`}
                                    label={activeTab === 'finish' ? translate("modal.completeGroupPlan.completeGroupPlan") : translate("modal.completeGroupPlan.nextPlan")}
                                    style={{ borderRadius: showBackButton ? '0px 16px 16px 0px' : '16px' }}
                                    disabled={false}
                                    onClick={() => {
                                        if (currentIndex > childPlans?.length - 1) return
                                        else if (activeTab === 'finish') {
                                            completeGroupPlan({
                                                variables: {
                                                    pendingLessonsId: pendingLessons.map(({ id }) => id),
                                                    groupId: dataPlan?.id
                                                }
                                            })
                                        }
                                        else if (currentIndex === childPlans?.length - 1) {
                                            setActiveTab('finish')
                                        } else {
                                            setCurrentIndex(currentIndex + 1)
                                        }
                                    }}
                                />
                            </div>
                        )}

                    </>
                )
            }
        </ModalLayout>
    )
}


export default CompleteGroupPlanModal