import React, {useMemo} from 'react'
import {Task, Action, IconTask} from "@Knowledge-OTP/znk-ui-components"
import useViewTaskModal from '../../../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import moment from 'moment'
import IsSpecific from '../../../../../CounselingTemplates/Task/viewTaskModal/Components/IsSpecific'
// import useViewPlanModal from '../../useViewPlanModal'
import { examColors } from '../../../../../Student/modal/InfoStudent/Learn/Sections/Exams/examColors'

const Tasks = (props) => {
    const {plan, onChangeTask, isEdit = true, allTasks } = props
    const tasksCategories = useMemo(() => {
        let tasks = []
        // const isStudentProfile = allTasks
        if(allTasks && allTasks?.length >= 0) tasks = allTasks
        else if(plan?.tasks) {
            tasks = (plan?.tasks || []).reduce((array, {steps, phaseId}) => {
                return array.concat(
                    steps?.reduce((array2, {tasks, stepId}) => {
                        return array2.concat(
                            tasks.map(
                                (task) => {return {...task, stepId, phaseId, planId: plan?.id}}
                            )
                        )
                    }, [])
                )
            }, [])
        }
        const sections = []
        if(tasks?.length > 0) {
            /**pendingTask: **/
            sections.push({
                tasks: tasks.filter(({status}) => ['pending'].includes(status)),
                title: 'Pending Tasks'
            })
            /**reviewTask: **/
            // if(!isStudentProfile) 
            sections.push({
                tasks: tasks.filter(({status}) => ['review'].includes(status)),
                title: 'Review tasks'
            })
            /**unasignedTask: **/
            // if(!isStudentProfile) 
            sections.push({
                tasks: tasks.filter(({status}) => status === 'unassigned'),
                title: 'Unassigned tasks'
            })
            /**completedTask: **/
            sections.push({
                tasks: tasks.filter(({status}) => ['completed'].includes(status)),
                title: 'Completed tasks'
            })
        } else {
            sections.push({tasks: [], title: 'Tasks'})
            sections.push({tasks: [], title: 'Unassigned tasks'})
            sections.push({tasks: [], title: 'Completed tasks'})
        }
        return sections
    // eslint-disable-next-line
    }, [plan, allTasks])

    const {openForCreatedTask} = useViewTaskModal()
    // const {dataPlan} = useViewPlanModal()
    
    return (
        <div className={`d-flex flex-column m-0 p-0`}>
            {/* <IconCircle size={12} className={`mr-2 mt-1`} color={'sat'}/>
            <IconCalendar size={12} className={`mr-2 mt-1`} color={'primary'}/> */}
            {
                tasksCategories.map(({tasks, title}) => {
                    return (
                        <div className={`my-3`}>
                            <label className={`h4 font-weight-bold text-title my-3`}>{title}</label>
                            {
                                tasks?.length > 0 ?
                                tasks?.map((task) => {
                                    const {name, isEspecific, dueDate, status, universities} = task
                                    const isUnasigned = status === "unassigned"
                                    const isCompleted = status === "completed"
                                    const late = moment(dueDate) < moment()
                                    return (
                                        <Task
                                            onClick={() => {
                                                openForCreatedTask({
                                                    ...task, 
                                                    isEdit, 
                                                    planId: plan?.id || plan?.planId,
                                                    dataPlan: plan
                                                    // userId: task?.userId || dataPlan?.studentUser?.studentUserId
                                                }, onChangeTask, true/**useMutation**/)
                                            }} 
                                            taskClassName={`mb-1 cursor-pointer`} 
                                            leftClassName={`${!isUnasigned ? `w-70` : `w-100`}`}
                                            labelLeft={
                                                <span title={name || 'No defined'} className={`text-truncate w-100 ${isCompleted ? 'text-line-through text-gray' : ''}`}>
                                                    {name}
                                                </span>
                                            } 
                                            className={`${!isUnasigned ? 'w-30' : ''} ${late ? 'text-error' : ''}`}
                                            showLabelRight={!isUnasigned}
                                            labelRight={
                                                <>
                                                    {isEspecific && 
                                                        <IsSpecific iconColor={`counseling`} taskId={task?.id || task?.taskId} className={`mr-2`} findInDB universitiesList={universities} showLabel={false} showTooltip />
                                                    }
                                                    {
                                                        isCompleted ? 
                                                            <label className={`text-gray white-space-nowrap m-0 p-0`}>{moment(dueDate).format('MMM Do')}</label> :
                                                            moment(dueDate).format('MMM Do')
                                                    }
                                                    {
                                                        isCompleted &&
                                                            <IconTask 
                                                                size={16} 
                                                                color='#9fda1f'
                                                                fill='#ffffff'
                                                                className={`ml-1`} />
                                                    }
                                                </>
                                            }
                                            titleLabelRight={null /** `Due date for ${moment(dueDate).format('Do MMMM')}`*/}
                                            // eslint-disable-next-line
                                            color={plan === undefined && examColors[task?.selectedExam?.name] || 'purple'}                         
                                            isExamScore={true}/>
                                    )
                                }) : 
                                <Action
                                    color='transparent'
                                    textColor='#7079FF'
                                    border='dashed 1px #7079FF'
                                    className='my-1'
                                    labelLeft={
                                        <span className='w-100 d-flex flex-column my-3'>
                                        <label className='m-0'>
                                            No tasks
                                        </label>
                                        </span>
                                    }
                                    labelRight={null}
                                    action={null}
                                />
                            }
                        </div>            
                    )
                })
            }
        </div>
    )
}

export default Tasks