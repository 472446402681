import React, { useMemo, useState } from 'react'
import {
    ModalLayout,
    IconIllustratedReminder,
    EarlyActionBadge,
    EarlyDecisionBadge,
    IconArrowRight,
    LogoItem,
    ListItem,
    IconBank,
    Button,
    DropDownNew,
    DropDownItem,
    IconStarFilled,
} from '@Knowledge-OTP/znk-ui-components'
import { Collapse } from 'reactstrap'
import useSetCompletedPlanStatusModal from './useSetCompletedStatusModal'
import useUser from '../../Model/useUser'
import useSetPlanStatus from '../../Model/useSetPlanStatus'
import StudentAndParent from '../viewPlanModal/Tabs/Resume/Sections/Student/Components/StudentAndParent'
import { showAlert } from '../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import useSetUniversityStatus from '../../../University/Model/useSetUniversityStatus'
import useSetAttendingUniversity from '../../Model/useSetAttendingUniversity'
import GoingStarField from './Components/GoingStar'
import {CompleteUniversitiesStatus} from '../../../University/Constants/universitiesStatus'
import { useEffect } from 'react'

const SetCompletedPlanStatusModal = () => {
    const { isOpen, toggle, dataPlan, onChange } = useSetCompletedPlanStatusModal()
    const { data } = useUser({ id: dataPlan?.studentUser?.studentUserId })
    const dispatch = useDispatch()
    const { translate } = useTranslation()
    const universities = useMemo(
        () => {
            return data?.user?.studentInfo?.universities
        }, [data]
    )

    const selector = true
    const [selected, selectUniversity] = useState('')
    useEffect(() => {
        if(universities?.length > 0) {
            const selectedUniv = universities.find(({attending}) => attending)
            if(selectedUniv) selectUniversity(selectedUniv.id)
        }
    }, [universities])

    const { submit: setStatus, loading: loadingStatus } = useSetUniversityStatus((resp) => {
    })

    const { submit: setAttending, loading: loadingAttending } = useSetAttendingUniversity((resp) => {
        setPlanStatus({
            variables: {
                planId: dataPlan?.id,
                status: "completed",
            }
        })
    })

    const changeStatus = (status, university) => {
        if (!university?.id && !data) return
        setStatus({
            universityId: university?.id,
            studentId: data?.user?.id,
            status: status
        })
    }

    const [setPlanStatus, { loading }] = useSetPlanStatus(
        (resp) => {
            onChange(resp?.updateStatusPlan)
            showAlert({
                text: 'Plan has been updated successfully!',
                status: 'success'
            }, dispatch)
        },
        (err) => {
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
        }
    )

    return (
        <ModalLayout
            className={`modal-${false ? 'botbar' : 'centered'
                } modal-colored h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={`d-flex flex-column`}>
                    <IconIllustratedReminder size={80} />
                    <span className={`h2 text-title font-weight-bold mt-2`}>Post-Application to Completed</span>
                </div>
            }
            underHead={
                <div className={`d-flex flex-column`}>
                    <span className={`h45 text-info mt-2`}> {translate('modal.completedplanmodal.infotext')}</span>
                   <div className={`d-flex flex-row align-items-center`}>
                    <IconStarFilled 
                        color={'#2c3f58'}
                        size='16' />   <span className={`d-flex h45 text-info`}> Attending</span>
                    </div> 
                </div>
            }
        >
            <div className='m-0 p-0'>
                {/* mostrar avatar de estudiante */}

                <div className={`d-flex flex-column m-0 p-0`}>
                    <StudentAndParent className={`my-2`} plan={dataPlan} />
                </div>

                {/* selector de universidades */}
                {/* <div className={`borderTop w-100 mt-2 mb-0 p-0`} /> */}
                <div className={`d-flex flex-column m-0 p-0`} style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                    {
                        universities.map(
                            (item, index) => {
                                return (
                                    <ListItem
                                        className={'py-1 px-2 hover-no-border'}
                                        hasHover
                                        showTopLine={index === 0}
                                        showBottomLine
                                        Left={
                                            <div className={`d-flex flex-row ml-2`}>
                                                {/* eslint-disable-next-line */}
                                                <div className={`d-flex flex-row justify-content-center my-2`} style={{
                                                    width: selector ? '25px' : '40px',
                                                    height: selector ? '25px' : '40px'
                                                }}>

                                                    {selector ?
                                                        // eslint-disable-next-line
                                                        item?.logo ?
                                                                <img style={{ alignSelf: 'center', width: '25px', height: '25px' }} src={item?.logo} alt={item?.name || ""} />
                                                            :
                                                            <div className={`d-flex flex-row justify-content-center`} style={{
                                                                width: '25px',
                                                                height: '25px'
                                                            }}>
                                                                <IconBank className={`align-self-center`} color={'gray'} size={25} />
                                                            </div>
                                                        : <>
                                                            <LogoItem
                                                                className='ml-4 mr-2 bg-transparent'
                                                                size={40}
                                                                early={item?.early}
                                                                image={item?.logo}
                                                                status={item?.status}
                                                                showTolTip={true}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                                <span className={`align-self-center ml-4 text-info`}
                                                // style={{color: '#2C3F78'}}
                                                >{item?.name}</span>
                                            </div>
                                        }
                                        Right={
                                            selector ?
                                                <div className='d-flex flex-row mr-1'>
                                                    <DropDownNew
                                                        className={`ml-3 mr-2 align-self-center`}
                                                        options={
                                                            // ['wishlist', 'inProgress', 'applied', 'admitted', 'rejected']
                                                            CompleteUniversitiesStatus.map(
                                                                e => {
                                                                    return {
                                                                        component:
                                                                            <DropDownItem>
                                                                                <div className='d-flex flex-row align-items-center' onClick={() => { changeStatus(e, item) }}>
                                                                                    <div className={`circle bg-${e}`} />
                                                                                    <div className='ml-2'>{translate(`universities.${e}`)}</div>
                                                                                </div>
                                                                            </DropDownItem>
                                                                    }
                                                                }
                                                            )
                                                        }
                                                        direction='bottomLeft'
                                                    >
                                                        <div className={`circle bg-${item?.status}`} />
                                                    </DropDownNew>
                                                    <GoingStarField selected={selected} selectUniversity={selectUniversity} item={item} />
                                                </div>
                                                :
                                                <div className={`hover-icon`}>{item?.early === 'action' ? <EarlyActionBadge /> :
                                                ['decision', 'decision2'].includes(item?.early) ? <EarlyDecisionBadge edVersion={item?.early}/> : <IconArrowRight className='mr-4' />}</div>
                                        }
                                    />
                                )
                            }
                        )
                    }
                </div>

                <Collapse isOpen={!selected}>
                    <span className='w-100 h45 text-gray my-2'>{translate('modal.completedplanmodal.goingCollegeText')}</span>
                </Collapse>
                {/* envia mail */}

                <Button
                    className={`w-100 mb-2 mt-4`}
                    color={`primary`}
                    size={`normal`}
                    label={`Send`}
                    disabled={!selected}
                    activity={loading || loadingStatus || loadingAttending}
                    onClick={() => {
                        setAttending({
                            universityId: selected,
                            studentId: data?.user?.id,
                            attending: true
                        })

                    }}
                />
            </div>
        </ModalLayout>
    )
}

export default SetCompletedPlanStatusModal