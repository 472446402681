import moment from "moment";
import { nanoid } from "nanoid";
import { gradesOptions } from "../../CounselingPlans/Modal/newPlanModal/Sections/Student/grades";
import useTemplatePdfReducer from "../../../reducers/template-pdf";
import usePaymentScheduledReducer from "../../../reducers/payment-scheduled";

const PAYMENTS_PAGE = 2; // + avatar
const PAYMENTS_SECTION = "body[0].childrens[4]";
const AVATAR_SECTION = "body[0].childrens[3].childrens[1]";
const TIMELINE_PAGE = 3;
const TIMELINE_SECTION = "body[0].childrens[3]";
// const STUDENT_SIGN_PAGE = 6
// const PARENT_SIGN_PAGE = 7
// const STUDENT_SIGN_SECTION = 'body[0].childrens[23].childrens[0].childrens[1]'
// const PARENT_SIGN_SECTION = 'body[0].childrens[14].childrens[0].childrens[1]'

const usePdfViewer = () => {
  const { pushOnPath, replaceText } = useTemplatePdfReducer();
  const { formatNumber } = usePaymentScheduledReducer();
  const generatePaymentItem = (
    { amountLabel, dateLabel },
    index = -1,
    total,
    props = {}
  ) => {
    let sub = null;
    if (index >= 0) {
      const idx = index + 1;
      sub = {
        id: nanoid(),
        type: "paragraph",
        text: `(${idx}${
          index <= 2 ? ["st", "nd", "rd"][index] : "th"
        } out of ${total})`,
        props: { fontSize: "8px", marginTop: "1px" },
      };
    }
    return {
      type: "section",
      id: nanoid(),
      props: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        color: "#2C3F58",
        lineHeight: "2",
        marginBottom: "0px",
        // maxHeight: '7px',
        ...props,
      },
      childrens: [
        {
          id: nanoid(),
          type: "section",
          props: { display: "flex", flexDirection: "row" },
          childrens: [
            {
              id: nanoid(),
              type: "paragraph",
              text: `${dateLabel}`,
              props: { marginRight: "2px" },
            },
            sub,
          ],
        },
        {
          id: nanoid(),
          type: "paragraph",
          text: `${amountLabel}`,
          props: {},
        },
      ],
    };
  };
  const generateAvatar = (name, initials, label) => {
    return {
      id: nanoid(),
      type: "section",
      childrens: [
        {
          type: "section",
          childrens: [
            {
              type: "div",
              childrens: [
                {
                  type: "paragraph",
                  text: `${initials}`,
                  props: {
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    color: "#ffffff",
                    fontSize: "10px",
                  },
                },
              ],
              props: {
                height: "20px",
                width: "20px",
                backgroundColor: "#696CFF",
                borderRadius: "5px",
                marginRight: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
            },
            {
              type: "paragraph",
              text: name,
              props: {
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignSelf: "center",
                color: "#2c3f58",
                fontSize: "10px",
              },
            },
          ],
          props: { display: "flex", flexDirection: "row", width: "100%" },
        },
        {
          type: "paragraph",
          text: `(${label})`,
          props: {
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignSelf: "center",
            fontSize: "10px",
            marginRight: "10px",
            color: "#778699",
          },
        },
      ],
      props: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #f0f1f1",
        paddingBottom: "8px",
        marginTop: "6px",
      },
    };
  };
  const inyectPaymentItems = (
    documentArray = [],
    paymentItems = [],
    currentPage = 0,
    key = ""
  ) => {
    if (paymentItems?.length > 10) {
      // largo del arreglo es impar
      let impar = false;
      if (!(paymentItems?.length % 2 === 0)) impar = true;
      const halfIndex = Math.ceil(paymentItems?.length / 2);
      const half1 = paymentItems.filter((_, index) => index < halfIndex);
      const half2 = paymentItems.filter((_, index) => index >= halfIndex);
      // 2 columnas
      documentArray = pushOnPath(
        documentArray,
        {
          id: nanoid(),
          type: "section",
          props: { width: "100%", display: "flex", flexDirection: "row" },
          childrens: [
            {
              id: nanoid(),
              type: "section",
              props: {
                width: "50%",
                display: "flex",
                flexDirection: "column",
                paddingRight: "10px",
                marginRight: "10px",
                borderRight: "1px solid #f0f1f1",
              },
              childrens: half1.map(({ amount, date, type }, index) => {
                return generatePaymentItem(
                  {
                    amountLabel: `$${formatNumber(amount)}`,
                    dateLabel:
                      type === "others-fixed"
                        ? "At Signing"
                        : moment(date).format("MMMM, YYYY"),
                  },
                  index,
                  paymentItems.length
                );
              }),
            },
            {
              id: nanoid(),
              type: "section",
              props: { width: "50%", display: "flex", flexDirection: "column" },
              childrens: half2
                .map(({ amount, date, type }, index) => {
                  return generatePaymentItem(
                    {
                      amountLabel: `$${formatNumber(amount)}`,
                      dateLabel:
                        type === "others-fixed"
                          ? "At Signing"
                          : moment(date).format("MMMM, YYYY"),
                    },
                    index + half1.length,
                    paymentItems.length
                  );
                  // para hacer espacio
                })
                .concat(
                  impar
                    ? [
                        generatePaymentItem(
                          {
                            amountLabel: " ",
                            dateLabel: " ",
                            color: "#ffffff",
                          },
                          -1,
                          0
                        ),
                      ]
                    : []
                ),
            },
          ],
        },
        currentPage,
        key
      );
    } else {
      paymentItems.forEach(({ amount, date, type }, index) => {
        documentArray = pushOnPath(
          documentArray,
          generatePaymentItem(
            {
              amountLabel: `$${formatNumber(amount)}`,
              dateLabel:
                type === "others-fixed"
                  ? "At Signing"
                  : moment(date).format("MMMM, YYYY"),
            },
            index,
            paymentItems.length
          ),
          currentPage,
          key
        );
      });
    }

    documentArray = pushOnPath(
      documentArray,
      generatePaymentItem(
        {
          amountLabel: `$${formatNumber(
            paymentItems.reduce((total, { amount }) => {
              return total + amount;
            }, 0)
          )}`,
          dateLabel: "Total",
        },
        -1,
        paymentItems.length,
        { borderTop: "1px solid #f0f1f1", paddingTop: "7px", marginTop: "7px" }
      ),
      currentPage,
      key
    );

    return documentArray;
  };

  return {
    generatePaymentItem,
    generateAvatar,
    inyectPaymentItems,
    embedVarInDocument: (myPlan, json, timelineUrl) => {
      let documentObj = null;
      if (true) {
        const studentIsFamily = myPlan?.aditionalInfo?.studentType === "family";

        const studentNames = [
          myPlan?.studentUser?.firstName,
          myPlan?.studentUser?.lastName,
        ];

        // TEAM
        const counselorNames = [
          myPlan?.councelor?.firstName ||
            (myPlan?.counselorData || myPlan?.councelorData)?.firstName,
          myPlan?.councelor?.lastName ||
            (myPlan?.counselorData || myPlan?.councelorData)?.lastName,
        ];

        const startTime = myPlan?.from ? moment(myPlan.from) : null;
        const endTime = myPlan?.to ? moment(myPlan.to) : null;
        const studentGradeLabel = gradesOptions.find(
          ({ id }) => id === myPlan?.studentUser?.grade
        ).label;
        const jsonDocumentWithVars = replaceText(json, {
          STUDENT_NAME: studentNames.join(" "),
          STUDENT_FIRST_NAME: studentNames[0],
          INITIALS_STUDENT_NAME: `${studentNames[0].substring(
            0,
            1
          )}${studentNames[1].substring(0, 1)}`,

          // COUNSELOR
          COUNSELOR_NAME: counselorNames.join(" "),
          INITIALS_COUNSELOR_NAME: `${counselorNames[0].substring(
            0,
            1
          )}${counselorNames[1].substring(0, 1)}`,
          // ADVISOR
          // ADVISOR_NAME: advisorNames.join(' '),
          // INITIALS_ADVISOR_NAME: `${advisorNames[0].substring(0, 1)}${advisorNames[1].substring(0, 1)}`,

          COUNSELING_PLAN_NAME: myPlan?.name,
          FROM: startTime
            ? startTime.clone().format("MMMM DD, YYYY")
            : "[NO_DEFINED]",
          TO: endTime
            ? endTime.clone().format("MMMM DD, YYYY")
            : "[NO_DEFINED]",
          PARENT_NAME: studentIsFamily
            ? `${myPlan?.aditionalInfo?.parentFirstName} ${myPlan?.aditionalInfo?.parentLastName}`
            : "-",
          PARENT_FIRST_NAME: myPlan?.aditionalInfo?.parentFirstName,
          CONTRACT_LENGTH: `${
            startTime
              ? startTime.clone().format("MMMM DD, YYYY")
              : "[NO_DEFINED]"
          } - ${
            endTime ? endTime.clone().format("MMMM DD, YYYY") : "[NO_DEFINED]"
          }`,
          GRADE: studentGradeLabel,
          COUNTRY: myPlan?.aditionalInfo?.studentCountryName,
          DEAR: studentIsFamily
            ? `${myPlan?.aditionalInfo?.parentFirstName} and ${studentNames[0]}`
            : `${studentNames[0]}`,
          PLAN_TYPE: myPlan?.type
            ? `${myPlan?.type
                .substring(0, 1)
                .toUpperCase()}${myPlan?.type.substring(
                1,
                myPlan?.type.length
              )}`
            : "-",
        });
        documentObj = JSON.parse(jsonDocumentWithVars);
      }

      if (true) {
        const advisorNames =
          myPlan?.advisor || myPlan?.advisorData
            ? [
                myPlan?.advisor?.firstName || myPlan?.advisorData?.firstName,
                myPlan?.advisor?.lastName || myPlan?.advisorData?.lastName,
              ]
            : null;
        const headNames =
          myPlan?.head || myPlan?.headData
            ? [
                myPlan?.head?.firstName || myPlan?.headData?.firstName,
                myPlan?.head?.lastName || myPlan?.headData?.lastName,
              ]
            : null;
        if (advisorNames) {
          // coloca avatar del advisor en el documento
          documentObj = pushOnPath(
            documentObj,
            generateAvatar(
              advisorNames.join(" "),
              `${advisorNames[0].substring(0, 1)}${advisorNames[1].substring(
                0,
                1
              )}`,
              "Advisor"
            ),
            PAYMENTS_PAGE,
            AVATAR_SECTION
          );
        }

        if (headNames) {
          // coloca avatar del head en el documento
          documentObj = pushOnPath(
            documentObj,
            generateAvatar(
              headNames.join(" "),
              `${headNames[0].substring(0, 1)}${headNames[1].substring(0, 1)}`,
              "Head"
            ),
            PAYMENTS_PAGE,
            AVATAR_SECTION
          );
        }

        // inyecta payment items en el documento
        documentObj = inyectPaymentItems(
          documentObj,
          myPlan?.paymentItemsConfig
            ?.map(({ amount, date, type }) => {
              return { amount, date, type };
            })
            .map(({ amount, date, type }) => {
              return { amount, date, type };
            }),
          // new Array(11).fill({amount: 100, date: moment().toISOString()})
          // new Array(5).fill({amount: 100, date: moment().toISOString()})
          PAYMENTS_PAGE,
          PAYMENTS_SECTION
        );

        // renderiza la imagen de la linea de tiempo
        // CANTIDAD DE STEPS
        let steps = (myPlan?.tasks || []).reduce((totalSteps, { steps }) => {
          return totalSteps + (steps?.length || 0);
        }, 0);
        if (steps <= 0) steps = 8;
        documentObj = pushOnPath(
          documentObj,
          {
            id: nanoid(),
            type: "image",
            src: timelineUrl,
            props: {
              width:
                steps <= 8 ? `57%` : steps >= 9 && steps <= 10 ? "52%" : "47%", //47% para 11 steps o más
              //52% para 9 o 10 steps
              //57% para 8 o menos steps
              height: `auto`,
              margin: "20px 50px",
            },
          },
          TIMELINE_PAGE,
          TIMELINE_SECTION
        );

        // documentObj = pushOnPath(
        //   documentObj,
        //   //bubbles
        //   {
        //     id: nanoid(),
        //     type: 'image',
        //     src: "https://s3.eu-west-1.amazonaws.com/media.zinkerz.com/counseling/pdf-editor-images/BGPEd_ruRuS0twl63sBnW.png",
        //     props: {
        //       position: 'absolute',
        //       top: 0,
        //       left: 0,
        //       height: '100%',
        //       width: '100%'
        //     },
        //   },
        //   TIMELINE_PAGE,
        //   TIMELINE_SECTION,
        // )
        return documentObj;
        // console.log("timelineUrl", timelineUrl)
      }
      // return documentObj
    },
  };
};

export default usePdfViewer;
