import React, {useMemo, useEffect, useState} from 'react'
import {
    IconTime,
    IconSignPlus,
    IconSignCheck,
    IconProgram,
    Countdown
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'

const ICONSIZE = 16
const StatusField = (props) => {
    const {lesson, realStatus, ...otherProps} = props
    //missed,incomplete,noshow,finished,attended,upcoming,pending,inprogress
    const {translate} = useTranslation()
    const {zone} = useSelector(state => state.timezone)
    const [timeToStart, setTimeToStart] = useState(
        moment.duration(moment(lesson.startDate).diff(moment())).asMinutes(),
    )
    useEffect(() => {
        const initTime = moment.duration(moment(lesson?.startDate).subtract(10, 'minutes').diff(moment()),).asMilliseconds()
        const timer = setTimeout(() => {
            const time = moment.duration(moment(lesson?.startDate).diff(moment())).asMinutes()
            setTimeToStart(time)
        }, initTime)
        return () => {
            clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lesson])

    const [htmlStatus] = useMemo(
        () => {
            const completeLesson = lesson
            let htmlStatus = ''
            const studentAux = completeLesson ? (completeLesson.student || completeLesson.studentPlan || completeLesson.studentGroup) : null
            // let statusOfInfoLesson = 'scheduled'
            if (completeLesson.status === 'finished') {
                if (completeLesson?.notes?.status === "draft") {
                    // statusOfInfoLesson = 'incomplete'
                    htmlStatus = <span>{translate('calendar.sections.filters.incomplete')}<IconTime className="ml-2" size={ICONSIZE}/></span>
                } else if (Array.isArray(studentAux)) {
                    // statusOfInfoLesson = 'finished-group'
                    const allMissed = studentAux.filter(({attendanceStatus}) => attendanceStatus === 'missed' || attendanceStatus === 'pending').length === studentAux.length
                    const educatorNoShow = lesson.educator.attendanceStatus === 'missed' || lesson.educator.attendanceStatus === 'pending'
                    if (educatorNoShow){
                        htmlStatus = <span>{translate('modal.infolesson.noshow')}<IconSignPlus className="ml-2" size={ICONSIZE}/></span>
                    } else if (allMissed){
                        htmlStatus = <span>{translate('calendar.sections.filters.missed')}<IconSignPlus className="ml-2" size={ICONSIZE}/></span>
                    } else if (educatorNoShow && allMissed) {
                        htmlStatus = <span>{translate('calendar.sections.filters.missed')}<IconSignPlus className="ml-2" size={ICONSIZE}/></span>
                    } else {
                        htmlStatus = <span>{translate('calendar.sections.filters.completed')}<IconSignCheck className="ml-2" size={ICONSIZE}/></span>
                    }
                } else {
                    // statusOfInfoLesson = `finished-${studentAux?.attendanceStatus || 'missed'}`
                    const educatorNoShow = lesson.educator.attendanceStatus === 'missed' || lesson.educator.attendanceStatus === 'pending'
                    const studentNoShow = studentAux.attendanceStatus === 'missed' || studentAux.attendanceStatus === 'pending'
                    if (educatorNoShow){
                        htmlStatus = <span>{translate('modal.infolesson.noshow')}<IconSignPlus className="ml-2" size={ICONSIZE}/></span>
                    } else if (studentNoShow){
                        htmlStatus = <span>{translate('calendar.sections.filters.missed')}<IconSignPlus className="ml-2" size={ICONSIZE}/></span>
                    } else if (educatorNoShow && studentNoShow){
                        htmlStatus = <span>{translate('calendar.sections.filters.missed')}<IconSignPlus className="ml-2" size={ICONSIZE}/></span>
                    } else {
                        htmlStatus = <span>{translate('calendar.sections.filters.completed')}<IconSignCheck className="ml-2" size={ICONSIZE}/></span>
                    }
                }
            } else if (completeLesson.status === 'pending') {
                // statusOfInfoLesson = 'pending'
                htmlStatus = <span>Pending<IconTime className="ml-2" size={ICONSIZE}/></span>
            } else {
                if (moment(completeLesson.startDate) < moment() && moment() < moment(completeLesson.endDate)) {
                    // lección en curso
                    // statusOfInfoLesson = 'inprogress'
                    htmlStatus = <span>{translate('calendar.sections.filters.inProgress')}<IconTime className="ml-2" size={ICONSIZE}/></span>
                } else if (moment() > moment(completeLesson.endDate)) {
                    // lección ya pasó y no está finished
                    // statusOfInfoLesson = 'incomplete'
                    htmlStatus = <span>{translate('calendar.sections.filters.incomplete')}<IconTime className="ml-2" size={ICONSIZE}/></span>
                } else if (moment() < moment(completeLesson.startDate)) {
                    // lección aún no sucede
                    // statusOfInfoLesson = 'scheduled'
                    htmlStatus =
                        timeToStart < 10 && timeToStart > 0 
                            ?
                            <span>
                            <div className='d-flex flex-column'>
                               <Countdown
                                   endTime={
                                       completeLesson?.startDate
                                           ? moment(completeLesson?.startDate).tz(zone)
                                           : moment().tz(zone)
                                   }
                                   iconColor='#FFFFFF'
                                   timeOverLabel={<span>{translate('calendar.sections.filters.inProgress')}</span>}
                                   spanTimeClassName='h4 text-white d-flex flex-row align-items-center'
                                   spanSecondsClassName='h4 text-white'
                                   size={ICONSIZE}
                               />
                            </div>
                        </span>
                            :
                            <span>{translate('calendar.sections.filters.upcoming')}<IconProgram className="ml-2" size={ICONSIZE}/></span>
                }
            }
            return [
                htmlStatus
            ]
            // eslint-disable-next-line
        }, [lesson, timeToStart])
  
    return (
        <span {...otherProps}>{htmlStatus}</span>
    )
}

export default StatusField