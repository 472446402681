import React, {useState, useEffect} from 'react'
import {
    ModalLayout,
    Button,
    ListItem,
    BlankSpace,
    IconIllustratedReminder,
    ToolTipInfo,
    TimelinePlan,
    IconOptions,
    DropDownNew,
    DropDownItem,
    IconEdit,
    IconErase,
    IconSignPlus,
    IconExam
} from '@Knowledge-OTP/znk-ui-components'
import PDFViewerTimeline from '../../../CounselingPlans/Modal/newPlanModal/Sections/Timeline/PDFViewerTimeline'
import { nanoid } from 'nanoid'
import {TabContent, TabPane} from 'reactstrap'
import useCreateTimelineModal from './useCreateTimelineModal'
//reducers
import useTimelineReducer from '../../../../reducers/timeline'
//sections
import Student from '../../../CounselingPlans/Modal/newPlanModal/Sections/Student'
//create activity
import CreateEventModal from '../CreateEventModal'
import useCreateEventModal from '../CreateEventModal/useCreateEventModal'
import CreatePlanModal from '../CreatePlanModal'
import useCreatePlanModal from '../CreatePlanModal/useCreatePlanModal'
import DeleteEventModal from '../DeleteEventModal'
import useDeleteEventModal from '../DeleteEventModal/useDeleteEventModal'
import CreateTestModal from '../CreateTestModal'
import useCreateTestModal from '../CreateTestModal/useCreateTestModal'

import moment from 'moment-timezone'

//model: mutations
import useCreateTimeline  from '../../Model/useCreateTimeline'
import useEditTimeline  from '../../Model/useEditTimeline'

import {showAlert} from '../../../../common/Alert/util'
import {useDispatch} from 'react-redux'

import NewActivity from '../../../Plans/modal/newPlan/Components/NewActivity'

import useUser from '../../Model/useUser'
import { openNewPlanIndividualModal } from '../../../Plans/Fields/utils'
import NewPlan from '../../../Plans/modal/newPlan'
//to do
//ACORTAR FORMULARIO DE CREACIÓN DE PLAN 
// Y DESGRANAR PLANES EN LINEA DE TIEMPO
 
const CreateTimeline = () => {
    //formulario para acumular planes liveprep incompletos (kind: timeline)
    //no ejecuta mutación solo retorna objeto
    const {isOpen, toggle, userData, onChange, timelineData: plansData, parseToTimeline, useMutation, timelineId} = useCreateTimelineModal()
    const {open: openEvent, close: closeEvent} = useCreateEventModal()
    const {open: openPlan, close: closePlan} = useCreatePlanModal()
    const {open: openTest, close: closeTest} = useCreateTestModal()
    //redux
    const dispatch = useDispatch()

    //timeline reducer
    const {parseTimelineToMutation} = useTimelineReducer()

    //model
    const [createTimeline, {loading: loadCreateTimeline}] = useCreateTimeline(
        (result) => {
            showAlert({
                text: "Timeline created successfully!",
                status: 'success'
            }, dispatch)
            onChange(result?.createTimeline)
        },
        (err) => {
            //alerta de error
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
            console.error(err)}
    )
    const [editTimeline, {loading: loadEditTimeline}] = useEditTimeline(
        (result) => {
            showAlert({
                text: "Timeline edited successfully!",
                status: 'success'
            }, dispatch)
            onChange(result?.editTimeline)},
        (err) => {
            //alerta de error
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
            console.error(err)
        }
    )

    const {data} = useUser({id: userData?.studentUserId})
    const dataUser = data?.user || null
    //crear y editar plan
    const openNewPlanModal = (plan = null) => {
        openPlan((newPlan) => {
            if(newPlan?.planId) {
                //sobreescribe un plan de la lista
                setTimelineData({
                    ...timelineData,
                    plans: plans.map((plan) => {
                        if(plan?.planId === newPlan?.planId) return {
                            ...newPlan, 
                            statusBuild: newPlan?.statusBuild === 'new' ? 'new' : 'edit'
                        }
                        return plan
                    }) 
                })
            } else {
                //agrega un nuevo plan a la lista
                setTimelineData({
                    ...timelineData,
                    plans: plans.concat([{
                        ...newPlan, planId: nanoid(), type: 'plan',
                        statusBuild: 'new'
                    }]) 
                })
            }
            closePlan()
        }, plan || {pricingRate: dataUser?.studentInfo?.defaultRate || null})
    }

    //crear y editar evento
    const createTestCallBack = (newTest) => {
        if(newTest?.isEdit) {
            setTimelineData({
                ...timelineData, 
                plans: plans.map(
                    (event) => {
                        if(event?.eventId === newTest.eventId) return {
                            ...newTest, type: 'test', 
                            statusBuild: newTest?.statusBuild === 'new' ? 'new' : 'edit'}
                        return event
                    }
                )
            })
        } else {
            setTimelineData({...timelineData, plans: plans.concat([
                {...newTest, eventId: newTest?.eventId || newTest?.id, type: 'test', statusBuild: 'new'}
            ])})
        }
        // closeTest()
    }
    const openNewEvent = (event = null) => {
        openEvent((newEvent) => {
            createTestCallBack(newEvent)
            closeEvent()
        }, event)
    }
    

    //crear y editar test
    const openNewTest = (test = null) => {
        openTest((newTest) => {
            if(newTest?.isEdit) {
                setTimelineData({
                    ...timelineData, 
                    plans: plans.map(
                        (event) => {
                            if(event?.eventId === newTest.eventId) return {...newTest, type: 'test', statusBuild: 'edit'}
                            return event
                        }
                    )
                })
            } else {
                setTimelineData({...timelineData, plans: plans.concat([
                    {...newTest, type: 'test', statusBuild: 'new'}
                ])})
            }
            closeTest()
        }, test)
    }

    //planes/eventos y estudiante adjuntos al timeline
    const [timelineData, setTimelineData] = useState({
        plans: [],
        studentUser: userData,
        isEdit: false
    })
    const {plans, studentUser, isEdit, isAdd = false} = timelineData
    //setea usuario y planes enviados desde useCreateTimelineModal.open
    useEffect(() => {
        setTimelineData({
            ...timelineData,
            studentUser: userData || null,
            plans: plansData || [],
            isEdit: !!(userData || plansData),
            isAdd: userData?.isAdd || false
        })
        // eslint-disable-next-line
    }, [userData, plansData])
    

    const [activeTab, setActiveTab] = useState('list')

    //elimina evento con determinado id del listado de planes
    const onDelete = (eventId) => {
        setTimelineData({
            ...timelineData,
            plans: plans?.map((event) => {
                if(event?.eventId === eventId || event?.planId === eventId) return {...event, statusBuild: 'delete'}
                return event
            })
        })

    }

    const {open: openToDelete, close: closeToDelete} = useDeleteEventModal()


    const createIndividualPlan = (item) => {
        const completePlan = {
            ...item, 
            isTimelinePlan: true,
            student: {
                ...timelineData?.studentUser,
                id: timelineData?.studentUser?.studentUserId || timelineData?.studentUser?.id
            },
            typePlan: 'date',
            timelinePlanId: item?.planId,
            timelineId,
            amount: item?.pricingRate
        }
        openNewPlanIndividualModal(completePlan, dispatch)
    }
    
    const getPlanOptions = (plan) => {
        const editPlan = {
            component: 
                <DropDownItem className='text-info d-flex flex-row align-items-center' onClick={() => {openNewPlanModal(plan)}}><IconEdit className={`mr-1`} size={16}/> Edit</DropDownItem>
        }
        const createPlan = {
            component: <DropDownItem className={`text-info d-flex flex-row align-items-center`} onClick={() => {
                createIndividualPlan(plan)                                                                                            
            }}>
                <IconSignPlus className={`mr-1 rotate-45`} size={16}/>
                Create Plan
            </DropDownItem>
        } 
        const deletePlan = {
            component: <DropDownItem className={`text-error danger-option d-flex flex-row align-items-center`} onClick={() => {
                openToDelete(() => {
                    onDelete(plan?.planId)
                    closeToDelete()
                }, plan?.planId)
            }}><IconErase className={`mr-1 text-error text-danger`} size={16}/> Delete</DropDownItem>
        }
        return plan?.kind === "lessonsPerWeek" ? [deletePlan] : [editPlan, createPlan, deletePlan]
    }

    const getEventOptions = (event) => {
        return [
            {component: <DropDownItem className={'d-flex flex-row aling-items-center text-info'} onClick={() => {
                if(event?.type === 'event') openNewEvent(event)
                else openNewTest(event)
            }}><IconEdit className={`mr-1`} size={16}/> Edit</DropDownItem>},
            {component: <DropDownItem className={'d-flex flex-row aling-items-center text-error danger-option'} onClick={() => {
                openToDelete(() => {
                    onDelete(event?.eventId)
                    closeToDelete()
                }, event?.eventId)
            }}><IconErase className={`mr-1`} size={16}/> Delete</DropDownItem>},
        ]
    }

    return (
        <>
            <ModalLayout
                // style={bubblesStyle}
                backdrop='static'
                className={`modal-colored modal-sidebar modal-desktop-half scheduled-modal`}
                isOpen={isOpen}
                toggle={toggle}
                topHead={
                    <span className={`h3 text-white`}>
                        {`${isAdd ? 'Add' : isEdit ? 'Edit' : 'Create'} Timeline`}
                        {/* {`${timelineId}`} */}
                    </span>
                }
                minHead={
                    <span className={`h3 text-white`}>
                        {`${isAdd ? 'Add' : isEdit ? 'Edit' : 'Create'} Timeline`}
                        {/* {`${timelineId}`} */}
                    </span>
                }
            >
                <div className={`d-flex flex-column m-0 p-0 justify-content-between h-100`}>
                    <div className={`d-flex flex-column h-100`}>
                        <Student 
                            disabled={studentUser?.lock}
                            className={`pb-5 pt-5`} 
                            plan={{studentUser}} 
                            setData={({studentUser}) => {
                                setTimelineData({
                                    ...timelineData,
                                    studentUser
                                })
                            }} 
                        />
                        <TabContent className={plans?.length > 0 ? '' : `h-100`} activeTab={plans?.length > 0 ? 'data' : 'blank'}>
                            <TabPane className={`h-100`} tabId={`blank`}>
                                <BlankSpace
                                    className={`w-100 h-100`}
                                    icon={<IconIllustratedReminder 
                                        className={`cursor-pointer`}
                                        onClick={() => {openNewPlanModal()}}
                                        size={80} />}
                                    mainText={
                                        <div className={`d-flex flex-column m-0 p-0`}>
                                            <span className={`btn-link`} onClick={() => {openNewPlanModal()}}>Create plan</span> or
                                            <span className={`btn-link`} onClick={() => {openNewEvent()}} >Create event</span> or
                                            {/* <span className={`btn-link`} onClick={() => {openNewTest()}} >Create exam</span> or  */}
                                            <NewActivity
                                                placeholder={'TEST DATE'}
                                                fieldName='testdate'
                                                setTestDate={createTestCallBack}
                                                useCompletedForm>
                                                <span className={`btn-link`}>Create test date</span>
                                            </NewActivity>
                                        </div>
                                    }
                                />
                            </TabPane>
                            <TabPane tabId={`data`}>
                                <div className={`d-flex flex-column m-0 p-0`}>
                                    <div className={`d-flex flex-row justify-content-between w-100 pb-3 pt-5`}>
                                        <div className={`d-flex flex-row m-0 p-0`}>
                                            <span className={`h4 cursor-pointer ${activeTab === 'list' ? 'font-weight-bold' : ''} text-title align-self-center mr-2`} onClick={() => {setActiveTab('list')}}>List</span>
                                            <span className={`h4 cursor-pointer ${activeTab === 'timeline' ? 'font-weight-bold' : ''} text-title align-self-center mr-2`} onClick={() => {setActiveTab('timeline')}}>Timeline</span>
                                            <span className={`h4 cursor-pointer ${activeTab === 'pdf-viewer' ? 'font-weight-bold' : ''} text-title align-self-center`} onClick={() => {setActiveTab('pdf-viewer')}}>PDF</span>
                                        </div>
                                        <div className={`d-flex flex-row m-0 p-0 white-space-nowrap`}>
                                            <span className={`h45 btn-link align-self-center`} onClick={() => {openNewPlanModal()}}>Plan +</span>
                                            <span className={`h45 align-self-center mx-2`}>/</span>
                                            <span className={`h45 btn-link align-self-center`} onClick={() => {openNewEvent()}}>Event +</span>
                                            <span className={`h45 align-self-center mx-2`}>/</span>
                                            {/* <span className={`h45 btn-link align-self-center`} onClick={() => {openNewTest()}}>Test +</span> */}
                                            {/* <span className={`h45 align-self-center mx-2`}>/</span> */}
                                            <NewActivity
                                                placeholder={'TEST DATE'}
                                                fieldName='testdate'
                                                setTestDate={createTestCallBack}
                                                useCompletedForm>
                                                <span className={`h45 btn-link align-self-center`}>Test +</span>
                                            </NewActivity>
                                        </div>
                                    </div>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={`list`}>
                                            {
                                                plans?.filter(({statusBuild}) => statusBuild !== 'delete')?.map((plan) => {
                                                    if(plan.type === 'plan') {
                                                        let from = plan?.lpw[0]?.startdate
                                                        from = from ? moment(from).format(`DD MMM, YYYY`) : 'No defined'
                                                        const largo = Object.keys(plan?.lpw)?.length
                                                        let to = plan?.lpw[largo - 1]?.enddate
                                                        to = to ? moment(to).format(`DD MMM, YYYY`) : 'No defined'
                                                        return (
                                                            // PLAN
                                                            <ToolTipInfo
                                                                tooltipId={plan?.planId}
                                                                innerElement={'Plan'}
                                                            >
                                                                <ListItem
                                                                    className={`border-radius-0`}
                                                                    showBottomLine
                                                                    Left={
                                                                        <div className={`d-flex flex-column ml-2`}>
                                                                            <span>{plan?.planname}</span>
                                                                            <span className={`h45 text-gray`}>{from} to {to}</span>
                                                                        </div>
                                                                    }
                                                                    Right={
                                                                        <div className={`d-flex flex-row align-items-center`}>
                                                                            {
                                                                                plan?.kind === "lessonsPerWeek" ? (
                                                                                    <div className={`hover-icon mr-2`} onClick={
                                                                                        () => {
                                                                                            window.open(`${process.env.ADMIN_URL || 'https://admin.zinkerz.com'}/plans?planId=${plan?.planId || plan?.id}`, '_blank')
                                                                                        }
                                                                                    }><IconExam size={12}/></div>
                                                                                ) : (
                                                                                    <DropDownNew
                                                                                        direction='bottomLeft' 
                                                                                        options={getPlanOptions(plan)}
                                                                                    >
                                                                                        <div className={`hover-icon`}>
                                                                                            <IconOptions className={`rotate-90`}/>
                                                                                        </div>
                                                                                    </DropDownNew>
                                                                                )
                                                                            }
                                                                            
                                                                        </div>
                                                                    }
                                                                />
                                                            </ToolTipInfo>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            // EVENT & TEST
                                                            <ToolTipInfo
                                                                tooltipId={plan?.eventId}
                                                                innerElement={plan?.type || 'Event'}
                                                            >
                                                                <ListItem
                                                                    className={`border-radius-0`}
                                                                    showBottomLine
                                                                    Left={
                                                                        <div className={`d-flex flex-column ml-2`}>
                                                                            <span>{plan?.name}</span>
                                                                        </div>
                                                                    }
                                                                    Right={
                                                                        <DropDownNew 
                                                                            direction='bottomLeft'
                                                                            options={getEventOptions(plan)}
                                                                        >
                                                                            <div className={`hover-icon`}>
                                                                                <IconOptions className={`rotate-90`}/>
                                                                            </div>
                                                                        </DropDownNew>
                                                                    }
                                                                />
                                                            </ToolTipInfo>
                                                        )    
                                                    }
                                                })
                                            }
                                        </TabPane>
                                        <TabPane tabId={`timeline`}>
                                            {/* MUESTRA LOS PLANES Y EVENTOS EN FORMATO TIMELINE */}
                                            <TimelinePlan events={parseToTimeline(
                                                plans.filter(({statusBuild}) => statusBuild !== 'delete'), 
                                                (event) => {openNewPlanModal(event)},
                                                (event) => {openNewEvent(event)}
                                                )}/>
                                        </TabPane>
                                        <TabPane tabId={`pdf-viewer`}>
                                            <PDFViewerTimeline plan={{...timelineData, timeline: timelineData?.plans}} isOpen={true}/>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                    <div    
                        className={`d-flex flex-row w-100 justify-content-center opacity-white-bottom`}>
                        <Button
                            className={`w-90 mb-2 mt-4`}
                            color={`primary`}
                            disabled={!(timelineData?.plans?.length > 0)}
                            label={`${isAdd ? 'Add' : isEdit ? 'Edit' : 'Create'} timeline`}
                            activity={loadCreateTimeline || loadEditTimeline}
                            onClick={() => {
                                if(useMutation) {
                                    //ejecuta mutación para crear o editar timeline con timelineId
                                    const timelineInput = parseTimelineToMutation(timelineData?.plans, timelineData?.studentUser)
                                    if(timelineId && isEdit) {
                                        //isEdit: edita timeline
                                        editTimeline(
                                            {
                                                variables: {
                                                    timelineInput,
                                                    timelineId        
                                                }
                                            }
                                        )

                                    } else {
                                        //crear timeline
                                        createTimeline({variables: {timelineInput}})
                                    }
                                }
                                else {
                                    onChange(timelineData)
                                }
                            }}
                        />
                    </div>
                </div>
            </ModalLayout>
            <CreateEventModal/>
            <CreatePlanModal/>
            <DeleteEventModal/>
            <CreateTestModal/>

            <NewPlan refetch={() => {
                onChange({id: timelineId})
            }} educators={[]} />

        </>
    )
}   

export default CreateTimeline