import { gql, useQuery } from '@apollo/client'

export default (vars) => {
  const { data, error, loading, refetch } = useQuery(
    gql`query($roles: [Roles!], $limit: Int, $sortField: String) {
		allUsers(roles: $roles, limit: $limit, sortField: $sortField) {
			hasNext
			hasPrevious
			next
			previous
			totalDocs
			docs {
				id
				isActive
				emails {
				address
				verified
				}
				countryId
				gender
				spokenLanguages
				address
				city
				state
				zipCode
				firstName
				lastName
				paymentMethod {
					kind
				}
				phone
				timezone
				birthdate
				studentInfo {
					institution
					graduationDate
					studentType
					defaultRate
					gpa
					servicesRate {
						defaultRate
						serviceId
						name
					}
					parents {
						parentUserId
						managePayments
						manageApprovals
						firstName
						lastName
						email
						phone
					}
					notifications {
						missedLesson
						lessonsNotesReady
						lessonReminder {
						isAvailable
						duration
						}
					}
					upcomingLesson {
						id
					}
				}
				parentInfo {
				children {
					studentUserId
					managePayments
					manageApprovals
					firstName
					lastName
					email
				}
				}
				educatorInfo {
					id
					services {
					serviceId
					name
					defaultRate
					topics {
						topicId
						teachingTypes
						name
						individualRate
						groupRate
						isWaiveIndividual
						isWaiveGroup
					}
					}
				availabilityBlocks {
					day
					hour
				}
					backupLink
				}
			}
		}
	}
	`,
    {
      variables: vars
    }
  )
  return { loading, data: data ? data.allUsers : {}, error, refetch: () => refetch(vars) }
}
