import { gql, useQuery } from '@apollo/client'
import validate from './validation'

export default (vars, options = {}) => {
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return { loading: false, error: errors, data: {} }
  }
  const { data, error, loading, refetch } = useQuery(
    gql`query(
        $involveMyself: Boolean!
        $to: DateTime!
        $from: DateTime!
        $educatorUserId: ID
        $minDurationInMinutes: Int
        $studentIds: [ID!]
        ) {
    myFreeTimeFrames(
        involveMyself: $involveMyself
        educatorUserId: $educatorUserId
        minDurationInMinutes: $minDurationInMinutes
        to: $to
        from: $from
        studentIds: $studentIds
    )
  }`,
    {
      ...options,
      variables: vars,
      
    }
  )
  return { loading, data: data ? data.myFreeTimeFrames : [], error, refetch }
}
