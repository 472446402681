import React from 'react'
import {
    ListItem,
    IconArrowRight,
    IconArrowLeft,
    IconTime
} from '@Knowledge-OTP/znk-ui-components' 

const InputDueDate = (props) => {
    const {value, onChange, isEdit = true, ...otherProps} = props
    return (
        <ListItem
            Left={
                <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                    <IconTime className={`text-info`}/>
                    <span className={`ml-4 text-info`}>{`${value >= 0 ? value : 0} days to complete`}</span>
                </div>
            }
            Right={
                isEdit && 
                <div className={'d-flex flex-row'}>
                    <div className={`hover-icon mr-2`} onClick={() => {onChange(value-1)}}>
                        <IconArrowLeft/>
                    </div>
                    <div className={`hover-icon mr-2`} onClick={() => {onChange(value+1)}}>
                        <IconArrowRight/>
                    </div>
                </div>
            }
            {...otherProps}
        />
    )
}

export default InputDueDate