import validate from 'validate.js'
import moment from 'moment'

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function(value, options) {
    return +moment.utc(value)
  },
  // Input is a unix timestamp
  format: function(value, options) {
    var format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss'
    return moment.utc(value).format(format)
  }
})

const constraints = {
  'paymentInput.userId': {
    presence: {
      allowEmpty: false
    }
  },
  'paymentInput.category': {
    presence: {
      allowEmpty: false
    }
  },
  'paymentInput.type': {
    presence: {
      allowEmpty: false
    }
  },
  'paymentInput.discount': {
    numericality: {
      onlyInteger: false,
      greaterThanOrEqualTo: 0
    }
  },
  'paymentInput.amount': {
    numericality: {
      onlyInteger: false,
      greaterThanOrEqualTo: 0
    }
  },
  'paymentInput.description': {
    presence: {
      allowEmpty: false
    }
  },
  'paymentInput.date': {
    datetime: {
      dateOnly: true
    }
  }
}

export default (data) => {
  return validate(data, constraints)
}
