import {gql, useMutation} from '@apollo/client'

const useSetPaymentStripeMethodsToUser = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $paymentMethodIds: [String!]!
                $userId: String!
                $stripeId: String!
            ) {
                setPaymentStripeMethodsToUser (
                    paymentMethodIds: $paymentMethodIds
                    userId: $userId
                    stripeId: $stripeId
                )
            }
        `,
        {
            onCompleted, onError
        }
    )
}

export default useSetPaymentStripeMethodsToUser