import React, { useMemo } from 'react'
import styles from './styles.module.css'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import ToggleReminder from './ToggleReminder'
import omit from 'lodash/omit'
import ToggleTimedReminder from './ChangeReminder'
import useModalState from '../../../../../utils/useModalState'
import ChangeNotificationsInner from './innerModal/index'

const ChangeNotificationsModal = (props) => {
  const {toggleFull} = props
  const { modalData, onChange } = useModalState('user.notificationHub')
  const { studentInfo, id } = modalData
  const refetch = (studentInfo) => onChange((f) => ({ ...f, studentInfo }))
  const notificationsSettings = useMemo(() => {
    return studentInfo && studentInfo.notifications
      ? omit(studentInfo.notifications, [
        '__typename',
        'lessonReminder.__typename'
      ])
      : {
        missedLesson: true,
        lessonsNotesReady: true,
        lessonReminder: { isAvailable: true, duration: 30 }
      }
  }, [studentInfo])
  // const { breakWidth } = useWindowDimensions()
  const dispatch = useDispatch()
  const editReminder = (reminderType, reminderKey, reminderValue) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.notificationToggle',
      data: {
        isOpen: true,
        reminderType,
        reminderValue,
        reminderKey,
        prevNotifs: notificationsSettings
      }
    })
  }
  const editTimedReminder = (reminderType, reminderKey, reminderValue) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.notificationChange',
      data: {
        isOpen: true,
        reminderType,
        reminderValue,
        reminderKey,
        prevNotifs: notificationsSettings
      }
    })
  }
  return (
    <>
      {/**<div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>**/}
      <ChangeNotificationsInner
        notificationsSettings={notificationsSettings}
        editReminder={editReminder}
        editTimedReminder={editTimedReminder}
        styles={styles}
        toggle={toggleFull}
      />
      <ToggleReminder refetch={refetch} userId={id} />
      <ToggleTimedReminder refetch={refetch} userId={id} />
    </>
  )
}

export default ChangeNotificationsModal
