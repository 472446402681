import {useSelector, useDispatch} from 'react-redux'

const useCounselingPlanList = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.counselingPlanList)
    const {refetch} = reduxData 
    
    const setData = (data) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.counselingPlanList',
            data: {
                ...reduxData,
                ...data
            }
        })
    }

    return {
        ...reduxData,
        refetch,
        setRefetch: (newRefetch = () => {}) => {
            setData({refetch: newRefetch})
        }
    }
}

export default useCounselingPlanList