import {useState} from 'react'
import axios from 'axios'
import {showAlert} from '../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import useUploadPdfBase64 from '../Model/useUploadPdfBase64'


function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
}
const URL = 'media.zinkerz.com/counseling'

const usePdfUpload = (onSuccess, onError) => {
    const dispatch = useDispatch()
    // sube documento pdf a s3
    const [uploadToS3, {loading: loadingUploadS3}] = useUploadPdfBase64(onSuccess, onError)
    const [loading, setLoading] = useState(false)

    const loadFileToS3 = (url, studentUserId, planId) => {
        // recibe el url temporal que viene desde
        // la intancia del documento (usando usePDF)
        setLoading(true)
        //convierte pdf a base64
        axios.get(url, {responseType: 'blob'}).then(
            (result) => {
              getBase64(result?.data).then((data) => {
                setLoading(false)
                // subir pdf a S3
                uploadToS3({
                  variables: {
                    file: data,
                    bucket: `${URL}/${studentUserId}/${planId}`,
                    fileName: 'proposal-non-sign',
                    type: 'pdf'
                  },
                })
              })
            },
            (err) => {
              console.error(err)
              showAlert({text: err?.toString(), status: 'error'}, dispatch)
              setLoading(false)
              onError(err)
            },
        )
        
    }
    return [
        loadFileToS3,
        {loading: loading || loadingUploadS3}
    ]
}

export default usePdfUpload