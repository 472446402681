import React, {useMemo} from 'react'
import { CheckboxSelectorInner, Avatar } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import useUsers from '../../Model/UseUsers'

const EducatorsFilter = (props) => {
  const { translate } = useTranslation()
  const { educatorFilters, setEducatorFilters, className, style = {} } = props

  const { data: allUsers } = useUsers({ roles: ['EDUCATOR'], limit: 0 })
  const educators = useMemo(() => allUsers?.docs || [], [allUsers])

  return (
    <CheckboxSelectorInner
      className={className}
      setDataFilters={setEducatorFilters}
      direction='right'
      dataFilters={educatorFilters}
      data={educators.map(user => ({
        name: `${user.firstName} ${user.lastName}`,
        id: user.id,
        Component: (
          <Avatar
            name={`${user.firstName} ${user.lastName}`}
            named={false}
            uri={null}
            size='avatar-small'
          />
        )
      })).sort((a, b) => a.name.localeCompare(b.name))}
      typeFilter='users'
      strings={{
        viewAll: translate('filters.plans.viewAll'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search')
      }}
      style={style}
    />
  )
}
export default EducatorsFilter
