import React from 'react'
import PropTypes from 'prop-types'
import { CookiesProvider } from 'react-cookie'
import FirebaseProvider from './FirebaseProvider'
import ApolloProvider from './ApolloProvider'
import { Provider as ReduxProvider } from 'react-redux'
import store from '../../store/store'
import I18nProvider from './I18nProvider'

const propTypes = {
  children: PropTypes.node
}
const defaultProps = {}

function Providers(props) {
  return (
    <FirebaseProvider>
      <CookiesProvider>
        <ApolloProvider>
          <ReduxProvider store={store}>
            <I18nProvider>
              {props.children}
            </I18nProvider>
          </ReduxProvider>
        </ApolloProvider>
      </CookiesProvider>
    </FirebaseProvider>
  )
}

Providers.propTypes = propTypes
Providers.defaultProps = defaultProps

export default Providers
