import React from 'react'
import {
  Field,
  SelectOption,
  PlainTextInput,
  InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import Services from '../../../../Model/Services/List'
import {escapeRegExp} from 'lodash'

const LessonDetails = (props) => {
  const { form, setForm } = props
  const { translate } = useTranslation()
  const { data: dataServices } = Services({ limit: 0 })
  const topics = (form.service && dataServices?.docs) ? dataServices.docs.reduce((arrayTopics, serv) => {
    if (serv.id === form?.service?.id) return arrayTopics.concat(serv.topics)
    return arrayTopics
  }, []) : []
  return (
    <>
      <div className='m-0 p-0'>
        <h3><b>{translate('modal.newlesson.lessondetails')}</b></h3>
        <div className='row pl-3'>
          <label className='col-12 text-muted mb-2 mt-3 pl-0'>
            {translate('modal.newlesson.lessontype')}
          </label>
          <Field
            className='col-12 row inline'
            type={SelectOption}
            fieldName='typeLesson'
            options={[
              { id: 'individual', text: translate('modal.newlesson.individual') },
              { id: 'group', text: translate('modal.newlesson.group') }
            ]}
            value={form.typeLesson}
            StyleOptionClassName={{
              width: '49%',
              whiteSpace: 'nowrap'
            }}
            StyleSelect={{
              justifyContent: 'space-between'
            }}
          />
          <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
            {translate('modal.newlesson.service')}
          </label>
          <Field
            InputClassName='col-12 p-0'
            type={InputSelect}
            fieldName='service'
            placeholder={translate('modal.newlesson.selectservice')}
            value={form?.service?.id}
            options={
                            dataServices?.docs && dataServices.docs.map((serv) => {
                return { value: serv.id, label: serv.name }
              })
            }
            filterOption={(obj, string) => {
    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
    return false
  }}
            onChange={(e) => {
              const serv = dataServices?.docs &&
              dataServices.docs.reduce((obj, serv) => {
                if (serv.id === e) return { id: e, name: serv.name }
                return obj
              }, {})
              setForm({
                ...form,
                service: serv
              })
            }}
          />
          <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
            {translate('modal.newlesson.topic')}
          </label>
          <Field
            InputClassName='col-12 p-0'
            type={InputSelect}
            filterOption={(obj, string) => {
              if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
              return false
            }}
            fieldName='topic'
            placeholder={translate('modal.newlesson.selecttopic')}
            value={form?.topic?.id}
            options={
              topics.map((top) => {
                return { value: top.id, label: top.name }
              })
            }
            onChange={(e) => {
              const top = topics.reduce((obj, top) => {
                if (top.id === e) return { id: e, name: top.name }
                return obj
              }, {})
              setForm({
                ...form,
                topic: top,
                title: `${form?.service?.name} ${top?.name}`
              })
            }}
          />
          <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
            {translate('modal.newlesson.titlelabel')}
          </label>
          <Field
            className='col-12 w-100'
            type={PlainTextInput}
            fieldName='title'
            placeholder={translate('modal.newlesson.titlelabel')}
            value={form.title}
          />
          <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
            {translate('modal.newplan.location')}
          </label>
          <Field
            className='col-12'
            type={SelectOption}
            fieldName='locationLesson'
            showNotSelected
            changeFunction={() => {
              setForm({ ...form, locationLesson: null })
            }}
            changeLabel={translate('modal.newplan.changelocation')}
            options={[
              { id: 'virtualClassroom', text: 'Virtual Classroom' },
              { id: 'zinkerzPlatform', text: translate('modal.newplan.plandetails.zinkerzp') },
              { id: 'hangouts', text: 'Hangouts' },
              { id: 'zoom', text: 'Zoom' },
              { id: 'inPerson', text: translate('modal.newplan.plandetails.inperson') }
            ]}
            value={form.locationLesson}
          />
          {['inPerson', 'hangouts', 'zoom'].indexOf(form.locationLesson) !== -1 && (
            <>
              <label className='col-12 text-muted mt-2 pl-0'>
                {form.locationLesson === 'inPerson' ? translate('modal.newplan.address') : translate('modal.newplan.locationlink')}
              </label>
              <Field
                className='col-12'
                type={PlainTextInput}
                fieldName='locationLessonLink'
                placeholder={form.locationLesson === 'inPerson' ? translate('modal.newplan.address') : 'http://zoom.com/ASDF'}
                value={form.locationLessonLink}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default LessonDetails
