import React, { useEffect, useState } from 'react'
import ShowLessonsPending from '../../../../../../../../Users/Modal/Profile/ShowLessonsPending'
import useCheckUsersLessonsMatch from './Model/useCheckUsersLessonsMatch'

const HandleShowLessonsPending = (props) => {
    const { studentId, isOpen = false, toggle = () => {}, onChange = () => { } } = props
    const [getUserPending, { loading: loadQuery }] = useCheckUsersLessonsMatch()

    const [studentResult, setStudentResult] = useState([])
    useEffect(
        () => {
            getUserPending({
                variables: { userId: studentId, isStudent: true }
            }).then(
                (res) => {
                    setStudentResult(res.data?.checkUsersLessonsMatch || [])
                },
                (err) => { alert(err.toString()) }
            )
        }, 
        // eslint-disable-next-line
        [studentId]
    )
    return (
        <ShowLessonsPending
            isOpen={isOpen && studentResult}
            toggle={toggle}
            students={studentResult}
            handletAction={onChange}
            loading={loadQuery}
            type={'student'}
        />
    )
}

export default HandleShowLessonsPending