import React, { useContext, useState } from 'react'
import { Action, useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import { useHistory, useLocation } from 'react-router'
import EditKey from '../../../modals/EditKey'
import i18nContext from '../../../../../contexts/i18n'
import { Collapse } from 'reactstrap'

const ChildLang = (props) => {
  const { setter, platform } = useContext(i18nContext)
  const history = useHistory()
  const location = useLocation()
  const { breakWidth } = useWindowDimensions()
  const { myPath, myKey, myValues } = props
  const [isOpen, setOpen] = useState(false)
  const showLang = () => {
    history.push(`${location.pathname}?key=${myPath}&platform=${platform}`)
  }
  return (
    <>
      <Action action='primary' labelLeft={myKey} onClick={breakWidth === 'SM' ? () => setOpen((prev) => !prev) : showLang} />
      {
        breakWidth === 'SM'
          ? <Collapse isOpen={isOpen}>
            <div className='d-flex flex-column justify-content-around'>
              {
                Object.keys(myValues).map(key => {
                  return (
                    <EditKey
                      path={`${myPath}.${key}`}
                      value={myValues[key]}
                      setter={setter}
                      className='modal-botbar'
                    >
                      <span className='btn btn-link w-100 text-center'>{key}</span>
                    </EditKey>
                  )
                })
              }
            </div>
            </Collapse> : null
      }
    </>
  )
}

export default ChildLang
