import React from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import isEmpty from 'lodash/isEmpty'
import * as moment from 'moment'
import { IconLesson } from '@Knowledge-OTP/znk-ui-components'

const WeeklySchedule = (props) => {
  const { lpw } = props
  const { translate } = useTranslation()
  return (
    <div className='d-flex flex-column w-100'>
      <h3 className='font-weight-bold text-dark my-3'>{translate('plans.modal.weeklyScheduleDetails')}</h3>
      {
        lpw && !isEmpty(lpw) ? (
          Object.entries(lpw).map(([partIdx, partInfo]) => {
            const individual = Object.entries(partInfo.valueIndividual)
            const groupal = Object.entries(partInfo.valueGroup)
            return (
              <div className='d-flex flex-column w-100'>
                <span className='h5 font-weight-light text-gray my-2 text-uppercase'>
                  {`Part ${parseInt(partIdx, 10) + 1}: ${translate('program.plan.infoModal.partDuration', { from: moment(partInfo.from).utc().format('MMMM Do'), to: moment(partInfo.to).utc().format('MMMM Do') })}`}
                </span>
                {
                  groupal.length ? (
                    groupal.map(([topicId, values]) => {
                      return (
                        <span className='my-1' key={topicId}>
                          <IconLesson size={16} />
                          <span className='ml-2'>
                            {translate('program.plan.infoModal.groupalTopicLessons', { num: values.lessons, topic: values.name })}
                          </span>
                          <span className='ml-2 text-gray font-weight-light'>
                            {`(${values.totalLessons})`}
                          </span>
                        </span>
                      )
                    })
                  ) : null
                }
                {
                  individual.length ? (
                    individual.map(([topicId, values]) => {
                      return (
                        <span className='my-1' key={topicId}>
                          <IconLesson size={16} />
                          <span className='ml-2'>
                            {translate('program.plan.infoModal.individualTopicLessons', { num: values.lessons, topic: values.name })}
                          </span>
                          <span className='ml-2 text-gray font-weight-light'>
                            {`(${values.totalLessons})`}
                          </span>
                        </span>
                      )
                    })
                  ) : null
                }
              </div>
            )
          })
        ) : null
      }
      <div className='borderTop my-2' />
    </div>
  )
}

export default WeeklySchedule
