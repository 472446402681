import { gql, useLazyQuery } from '@apollo/client'

export default () => {
	return useLazyQuery(
		gql`query($roles: [Roles!], $limit: Int, $sortField: String) {
			allUsers(roles: $roles, limit: $limit, sortField: $sortField) {
				docs {
					id
					isActive
					firstName
					lastName
					avatar
					roles
					educatorInfo {
						isActive
					}
				}
			}
		}`,
		{
			fetchPolicy: 'no-cache'
		}
	)
}
