const mean = (rawFields = []) => {
  const fields = rawFields?.filter(({ isOnMethod }) => isOnMethod)
  if (!fields || fields?.length <= 0) return 0
  return Math.round(fields.reduce((sum, item = { value: 0 }) => sum + (item?.value || 0), 0) / fields.length)
}

const sum = (rawFields = []) => {
  const fields = rawFields?.filter(({ isOnMethod }) => isOnMethod)
  return fields.reduce((sum, item = { value: 0 }) => sum + (item?.value || 0), 0)
}

const roundIELTS = (rawFields = []) => {
  const fields = rawFields?.filter(({ isOnMethod }) => isOnMethod)
  const overall = mean(fields)
  const decimal = overall - Math.floor(overall)
  let result = 0
  const pivot = (parseInt(overall) + (parseInt(overall) + 1)) / 2
  switch (true) {
    case overall === parseInt(overall):
      // Caso 1: El promedio es un número entero
      result = overall
      break
    case overall === pivot:
      // Caso 2: El promedio es 1.5 , 2.5, etc.
      result = pivot
      break
    case overall < pivot:
      // Caso 3: El promedio es un decimal mayor a X.0 y menor a X.5
      if (decimal < 0.25) {
        result = Math.floor(overall)
      } else {
        result = pivot
      }
      // result = pivot
      break
    case overall > pivot:
      // Caso 4: El promedio es un decimal mayor a X.5 y menor al entero próximo más grande
      if (decimal < 0.75) {
        result = pivot
      } else {
        result = Math.ceil(overall)
      }
      break
    default:
      console.error('case not found')
  }
  return result
}


export const ScoreMethods = {
  'mean': mean, 'sum': sum, 'default': mean, 'roundIELTS': roundIELTS
}