import React, {useEffect, useMemo, useReducer, useState} from 'react'
import { Row } from 'reactstrap'
import { Button, ConfirmModal, DropDown, IconOptions, IconSearch, InputSearch } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import './index.css'
import Pagination from '../../../common/Pagination'
import useDebounce from '../../../utils/useDebounce'
import { gql, useLazyQuery } from '@apollo/client'
import UniversityModal from './Modals/new'
import UniversityEditModal from './Modals/edit'
import UniversityView from './Modals/view'
import useTranslation from '../../../i18n/useTranslation'
import useDeleteUniversity from '../../../Model/Universities/Delete/delete'

const initialState = {
    operation: '',
    isOpen: false,
    id: null,
    data: null,
    isOwn: false,
    unitId: null,
    refetch: () => {}
}   

const UniversitiesView = () => {
    const [pageSize, setPageSize] = useState(20)
    const [pageCount, setPageCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [pageIndexPrevious, setPageIndexPrevious] = useState('')
    const [pageIndexNext, setPageIndexNext] = useState('')
    const [hasPrevious, setHasPrevious] = useState(false)
    const [hasNext, setHasNext] = useState(false)
    const [loading, setLoading] = useState(false)
    const [txtSearch, setTxtSearch] = useState('')
    const [universities, setUniversities] = useState([])
    const debouncedSearchTerm = useDebounce(txtSearch, 500)
    const [state, dispatchModal] = useReducer(reducer, initialState)
    const {translate} = useTranslation()
    const [getUniversities, { data, error, refetch: refetchList }] = useLazyQuery(
        gql`query($limit: Int, $sortAscending: Boolean, $sortField: String, $previous: String, $next: String, $search: String) {
            getUniversities(limit: $limit, sortAscending: $sortAscending, sortField: $sortField, previous: $previous, next: $next, search: $search) {
                hasNext
                hasPrevious
                next
                previous
                totalDocs
                docs {
                    id
                    name
                    collegeId
                    collegeUnitId
                    isActive
                    createdAt
                    updatedAt
                    deleteAt
                    isPrincipal
                }
            }
        }`
    )
    const { submit: submitDelete, loading: loadingDelete} = useDeleteUniversity((result) => {
        getUniversities()
        dispatchModal({type: 'toggle'})
    }, console.log)  
    const query = useMemo(() => {
        let query = {
          limit: pageSize,
          next: '',
          previous: '',
          sortField: 'name',
          search: debouncedSearchTerm !== '' ? debouncedSearchTerm : undefined,
        }
        return query
    }, [debouncedSearchTerm, pageSize])
    useEffect(() => {
        if (query) {
          setActivePage(1)
          setUniversities([])
          query.next = ''
          query.previous = ''
          
          setLoading(true)
          getUniversities({ variables: query })
        }
        // eslint-disable-next-line
    }, [query, getUniversities])
    useEffect(() => {
        if (data) {
            setPageIndexNext(data.getUniversities.next)
            setPageIndexPrevious(data.getUniversities.previous)
            setHasPrevious(data.getUniversities.hasPrevious)
            setHasNext(data.getUniversities.hasNext)
            setPageCount(Math.ceil(data.getUniversities.totalDocs / pageSize))
            setUniversities(data.getUniversities.docs)
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [data])
    const clickNext = () => {
        if (hasNext) {
          setLoading(true)
          query.next = pageIndexNext
          query.previous = ''
          setActivePage((activePage + 1))
          getUniversities({ variables: query })
        }
    }
    const clickPrevious = () => {
        if (hasPrevious) {
          setLoading(true)
          query.next = ''
          query.previous = pageIndexPrevious
          setActivePage((activePage - 1))
          getUniversities({ variables: query })
        }
    }
    const changeSize = (value) => {
        setActivePage(1)
        setPageSize(value)
    }
    const hanledSearch = (value) => {
        setTxtSearch(value)
    }
    const handledDelete = () => {
        if (!state.id) return
        submitDelete({variables: {universityId: state.id}})
    }
    return (
        <div className='d-flex flex-column'>
            {
                state.isOpen && (state.operation === 'delete' || state.operation === 'deleteInside') && (
                    <ConfirmModal
                        className='m-4'
                        toggle={() => dispatchModal({type: state.operation !== 'deleteInside' ? 'toggle' : 'toogleInside'})}
                        isOpen={state.isOpen}
                        title={translate('universities.confirmTitle')}
                        body={translate('universities.bodyConfirm')}
                        confirmAction={handledDelete}
                        cancelAction={() => dispatchModal({type: state.operation !== 'deleteInside' ? 'toggle' : 'toogleInside'})}
                        confirmText={translate('universities.actionConfirm')}
                        cancelText={translate('universities.cancel')}
                        loading={loadingDelete}
                    />
                )
            }
            <Row className='d-flex flex-column mx-0'>
                <div className='d-flex justify-content-end'>
                    <span>
                        <InputSearch
                            placeholder='Search'
                            className='m-0 p-3 h-25 trans flex-nowrap'
                            addOnComponent={<IconSearch size={18} className='text-gray' />}
                            value={txtSearch}
                            onChange={(value) => { hanledSearch(value) }}
                        />
                    </span>
                    <span><Button label='Add University' className='btn-table' color='primary' onClick={() => {dispatchModal({type: 'new', isOpen: true })}} /></span>
                </div>
            </Row>
            <Row className='d-flex flex-column mx-0 mt-4'>
                {
                    loading
                        ? <Skeleton height={25} count={5} className='my-2' />
                        : (error
                            ? 
                                <div className='alert alert-danger'>Error en la petición</div>
                            :   universities.map((item) => {
                                    let options = [{
                                        component:
                                        <span
                                        className='p-3'
                                        onClick={() => {
                                            dispatchModal({type: 'view', id: item.id, isOwn: item.isPrincipal, unitId: item.collegeUnitId})
                                        }}
                                        >View University</span>
                                    }]
                                    if (item.isPrincipal) {
                                        options = options.concat([{
                                            component:
                                            <span
                                            className='p-3'
                                            onClick={() => {
                                                dispatchModal({type: 'edit', id: item.id})
                                            }}
                                            >Edit University
                                            </span>
                                            }, {
                                                component:
                                                <span
                                                className='p-3'
                                                onClick={() => {
                                                    dispatchModal({type: 'delete', isOpen: true, id: item.id})
                                                }}
                                                >Delete University
                                                </span>
                                            }
                                        ])
                                    }
                                    return (
                                        <div
                                        key={item.id}
                                        className={`znk-invoice-row draft}`}
                                        style={{ paddingTop: 0, paddingBottom: 0, maxHeight: '30px'}}
                                        >
                                            <span className='font-weight-bold text-color'>{item.name}</span>
                                            <span className='text-color text-gray'>{item.collegeId}</span>
                                            <span className='text-color text-gray'>{item.active}</span>
                                            <span className='text-color text-gray'>{moment(item.createdAt).format('MMMM do, hh:mm')}</span>
                                            <span className='text-color text-gray'>{moment(item.updatedAt).format('MMMM do, hh:mm')}</span>
                                            <span className='flex-grow-0'>
                                                <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                                                    <DropDown
                                                        direction='down'
                                                        fixedWidth
                                                        hoverEnabled={false}
                                                        options={options}
                                                    >
                                                        <div className='d-flex flex-row'>
                                                            <IconOptions style={{ transform: 'rotate(90deg)', marginRight: 10 }} color='rgba(37, 64, 80, 0.6)' />
                                                        </div>
                                                    </DropDown>
                                                </div>
                                            </span>
                                        </div>
                                    )
                            })
                        )
                }
                {
                    !loading &&
                    <Pagination
                        pageSize={pageSize}
                        changeSize={changeSize}
                        clickNext={clickNext}
                        clickPrevious={clickPrevious}
                        hasPrevious={hasPrevious}
                        hasNext={hasNext}
                        activePage={activePage}
                        pageCount={pageCount}
                    />
                }
            </Row>
            {state.isOpen && state.operation === 'new' && <UniversityModal state={state} refetchList={refetchList} toggle={() => {dispatchModal({ type: "toggle" })}} />}
            {state.isOpen && ['edit', 'editInside'].includes(state.operation) && <UniversityEditModal state={state} refetchList={refetchList} toggle={() => {dispatchModal({ type: "toggle" })}} dispatchModal={dispatchModal}/>}
            {state.isOpen && ['view', 'deleteInside', 'editInside'].includes(state.operation) && <UniversityView state={state} refetchList={refetchList} toggle={() => {dispatchModal({ type: "toggle" })}} dispatchModal={dispatchModal}/>}
        </div>
    )
}

function reducer(state, action) {
    switch (action.type) {
        case "toggle":
            return {...state, isOpen: !state.isOpen}
        case "new":
            return {...state, isOpen: true, operation: 'new'}
        case "edit":
            return {...state, isOpen: true, operation: 'edit', id: action.id}
        case "editInside":
            return {...state, isOpen: true, operation: 'editInside', id: action.id, refetch: action.refetch}
        case "view":
            return {...state, isOpen: true, operation: 'view', isOwn: action.isOwn, unitId: action.unitId, id: action.id}
        case "delete":
            return {...state, isOpen: true, operation: 'delete', id: action.id}
        case "deleteInside":
            return {...state, isOpen: true, operation: 'deleteInside', id: action.id}
        case "toogleInside":
            return {...state, operation: 'view'}
        case "toggleEditInside":
            return {...state, operation: 'view'}
        default:
            throw new Error('')
    }
}

export default UniversitiesView

