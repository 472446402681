import React, { useState } from 'react'
import { IconAdd, IconRemove, Input } from '@Knowledge-OTP/znk-ui-components'

const ServiceItem = (props) => {
  const { addItem, service } = props
  const [defaultRate, setDefaultRate] = useState(15)
  const [isOpen, setOpen] = useState(false)
  const { id } = service
  return (
    <div>
      <span className='d-flex justify-content-between align-items-center'>
        <span>{service.name}</span>
        <span className='d-flex align-items-center justify-content-around'>
          {
            isOpen ? (
              <>
                <Input
                  type='number'
                  value={defaultRate}
                  onChange={({ target: { value } }) => {
                    setDefaultRate(parseInt(value, 10))
                  }}
                />
                <IconAdd size={20} onClick={() => addItem({ serviceId: id, defaultRate })} />
                <IconRemove size={20} onClick={() => setOpen(false)} />
              </>
            ) : (
              <IconAdd size={20} onClick={() => setOpen(true)} />
            )
          }
        </span>
      </span>
    </div>
  )
}

export default ServiceItem
