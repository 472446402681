import React, {useEffect, useState, useRef} from 'react'
import {
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import {IconLoad} from '@Knowledge-OTP/znk-ui-components'

import {db} from '../../fireBase'
import {useSelector} from 'react-redux'
import './index.css'

import moment from 'moment-timezone'
import {useChatNotification} from '../../Model/useChatNotification'

const SendMessage = (props) => {

  const {
    collection,
    collectionType = 'messages',
    attendanceStatus,
    planId,
    staffCanWrite = false,
    onSend = () => {},
    ref = null,
    className = ''
  } = props
  const { id: collectionID } = collection
  const type = collection?.__typename ? collection?.__typename : 'UserTasksOut'

  const [message, setMessage] = useState('')
  const finishTime = collection.endDate
    ? moment() >= moment(collection?.endDate)
    : collection?.status === 'completed'
  const {firstName, lastName, id: userID} = useSelector(
    (state) => state.user,
  )
  const [messageData, setMessageData] = useState({
    error: false,
    loading: null,
  })
  const {loading, error} = messageData
  const sendMessage = async () => {
    let variablesToSend = {}
    const regexPhone = /((?:\+|00)[17](?: |)?|(?:\+|00)[1-9]\d{0,2}(?: |)?|(?:\+|00)1\d{3}(?: |)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |)[0-9]{3}(?: |)[0-9]{4})|([0-9]{7}))/g
    // eslint-disable-next-line
    const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    if (!message || message.match(regexPhone) || message.match(regexEmail))
      return
    setMessageData({
      ...messageData,
      loading: true,
    })
    const snapshot = await db.collection(collectionType).doc(collectionID).get()
    const numberKey = Object.keys(snapshot.data()).length
    // .map(
    //   (key) => snapshot.data()[key],
    // ).length
    const keyMessage = `message${numberKey}`
    const objectMessage = {
      [keyMessage]: {
        text: message,
        userName: `${firstName} ${lastName}`,
        userID,
        createdAt: new Date().toISOString(),
        index: numberKey,
      },
    }
    try {
      await db
        .collection(collectionType)
        .doc(collectionID)
        .update(objectMessage)
      setMessageData({
        ...messageData,
        loading: false,
      })

      //mensaje aparece en ChatBox
      if(onSend) onSend(inputRef)
      
      // console.log("inputRef =>", inputRef)

      if (type.toLowerCase().includes('lesson')) {
        variablesToSend = {
          lessonId: collection?.id,
          chanel: 'lessons',
        }
      } else {
        variablesToSend = {
          taskId: collection?.id,
          stepId: collection?.stepId,
          phase: collection?.phaseId?.replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase()
          }) || collection?.phase,
          planId,
          chanel: 'counselingTasks',
        }
      }
      chatNotification({
        variables: variablesToSend,
      })
      setMessage('')
    } catch (error) {
      setMessageData({
        ...messageData,
        error: true,
        loading: false,
      })
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const fireStoreDocument = db.collection(collectionType).doc(collectionID)
    fireStoreDocument.get().then((doc) => {
      if (doc.exists) {
        sendMessage()
      } else {
        fireStoreDocument.set({}).then(() => {
          sendMessage()
        })
      }
    })
  }
  // eslint-disable-next-line
  const [chatNotification, { data: dataChatNotification, loading: loadignChatNotification, refetch: refetchChatNotification }] = useChatNotification(   
    () => {},
    (err) => {
      console.error('Error with chat notification : ', err)
    },
  )
  useEffect(() => {
      const timer = setTimeout(
        () =>
          setMessageData({
            ...messageData,
            error: false,
          }),
        3000,
      )
      return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const inputRef = useRef(null)

  return (
    <div ref={ref} className={className} style={{position: 'sticky', bottom: '0px', paddingBottom: '10px', backgroundColor: 'white'}}>
      {((attendanceStatus === 'attended' && !finishTime) || staffCanWrite ) && (
        <form onSubmit={handleSubmit}>
          {error && (
            <Alert
              style={{
                backgroundColor: '#f8d7da',
                color: '#842029',
                borderColor: '#f5c2c7',
              }}
            >
              Error sending message.
            </Alert>
          )}
          <InputGroup>
            <input
              ref={inputRef} 
              placeholder={"Type something"} 
              type={"text"} 
              className={"znk-input trans form-control"} 
              value={message}
              disabled={loading || error}
              onChange={(e) => setMessage(e.target.value)}
            />
            <InputGroupAddon addonType='prepend' className='icon'>
              <InputGroupText type='text' className='znk-no-border'>
                {loading ? (
                  <IconLoad />
                ) : (
                  <span
                    className='text-primary'
                    onClick={handleSubmit}
                    style={{cursor: 'pointer'}}
                  >
                    Send
                  </span>
                )}
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </form>
      )}

    </div>
  )
}

export default SendMessage
