import React from 'react'
import {
    InputSelect,
} from '@Knowledge-OTP/znk-ui-components'
import useGetAccounts from '../../../Model/useGetAccount'
import useCreateAccount from '../../../Model/useCreateAccount'

const SelectAccount = (props) => {
    const {onChange = () => {}, value = null} = props
    const {data, refetch: refetchAccounts, loading: loadAccount} = useGetAccounts()
    const [createAccount, {loading: loadCreateAccount}] = useCreateAccount(
        (result) => {
            onChange(result?.createAccount?.id /**is a new element**/)
            refetchAccounts()
        },
        () => {}
    )
    const accounts = data?.getAccounts || []

    return (
        <InputSelect
            menuPlacement={'top'}
            options={accounts.map(({id, name}) => {
                return {
                    value: id, label: name
                }
            })}
            onChange={(newAccount) => {
                if(!accounts.map(({id}) => id).includes(newAccount)) {
                    createAccount({
                        variables: {accountName: newAccount}
                    })
                }  
                else onChange(newAccount)
            }}
            value={value}
            isLoading={loadAccount || loadCreateAccount}
            creatable
        />
    )
}

export default SelectAccount