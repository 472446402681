import React from 'react'
import {
    TabContent,
    TabPane
} from 'reactstrap'
import { useParams } from "react-router-dom";
import TasksSection from './Sections/Tasks'
import UniversitiesSection from './Sections/Universities'
import Scores from './Sections/Scores'
import { useHistory } from "react-router-dom";
import "./index.css"
import NoAuthorize from '../../common/NoAuthorize';
import { useSelector } from 'react-redux';

const Workspace = () => {
    let { section } = useParams()
    const history = useHistory()
    const { staffInfo } = useSelector((state) => state.user)
    const isPermited  = staffInfo?.permissions?.filter(e => e.isPermited).map(per => {return per.tag}).includes('workspace');
  
    if (!isPermited) {
        return <NoAuthorize />
    }

    const PanelSection = () => {
        return (
            <div className='container-fluid mt-2 mt-md-5 d-flex px-2 flex-column-reverse flex-md-row'>
                <div className='d-flex flex-grow-1 mt-2 pr-0 pr-md-2 mt-md-0 align-items-center znk-scroll-horizontal'>
                    {/* SHORTCUTS */}
                    {/* <span onClick={() => { }} className={`nav-title ${section === 'students' ? 'active' : ''} m-0 mr-3`}>Student</span> */}
                    <span onClick={() => { history.push(`tasks`) }} className={`cursor-pointer nav-title ${section === 'tasks' ? 'active' : ''} m-0 mr-3`}>Tasks</span>
                    {/* <span onClick={() => { history.push(`exam`) }} className={`cursor-pointer nav-title ${section === 'exam' ? 'active' : ''} m-0 mr-3`}>Exam</span> */}
                    <span onClick={() => { history.push(`colleges`) }} className={`cursor-pointer nav-title ${section === 'colleges' ? 'active' : ''} m-0 mr-3`}>Colleges</span> 
                    <span onClick={() => {history.push(`scores`)}} className={`cursor-pointer nav-title ${section === 'scores' ? 'active' : ''} m-0 mr-3`}  >Scores</span>

                </div>
            </div>
        )
    }
    return (
        <TabContent activeTab={section} className={`h-100`}>
            <TabPane tabId={`tasks`} className={`h-100`}>
                <TasksSection PanelSection={PanelSection} />
            </TabPane>
            <TabPane tabId={`colleges`} className={`h-100`}>
                <UniversitiesSection PanelSection={PanelSection} />
            </TabPane>
            <TabPane tabId={`scores`} className={`h-100`}>
                <Scores PanelSection={PanelSection} />
            </TabPane>

        </TabContent>
    )
}

export default Workspace