import React, {useMemo} from 'react'
import {
    AmountInput,
    IconOutLine,
    ListItem
} from '@Knowledge-OTP/znk-ui-components'
import RenderInputOption from '../../Components/RenderInputOption'
import useAddDiscountModal from '../../Components/AddDiscountModal/useAddDiscountModal'
import {TabContent, TabPane} from 'reactstrap'
import usePaymentScheduledReducer from '../../../../../../reducers/payment-scheduled'

const PlanBreakdown = (props) => {
    const {className, plan, setData} = props
    const {open: openAddDiscount, close: closeAddDiscount} = useAddDiscountModal()
    const addDiscount = () => {
        openAddDiscount((discount) => {
            setData({discount})
            closeAddDiscount()
        })
    }
    const { formatNumber } = usePaymentScheduledReducer()
    const {discount, pricingRate} = plan
    const activeTab = useMemo(() => {
        if(discount > 0)  return 'set'
        return 'noset'
    }, [discount])
    const discountAmount = ((pricingRate || 0)*(discount/100) || 0)
    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
        <span className={`h4 font-weight-bold text-title`}>Plan Breakdown</span>
            <RenderInputOption key={'amount'} label={`Amount`}>
                <AmountInput 
                value={pricingRate} 
                onChange={(e) => {
                    setData({pricingRate: e})
                }} />
            </RenderInputOption>
            <ListItem
                className='w-100 mt-2'
                showBottomLine={false}
                showTopLine={false}
                Left={
                    <span>Total price</span>
                }
                Right={
                    <span>{`$${formatNumber(parseFloat(pricingRate || 0))}`}</span>
                }
            />
            <TabContent activeTab={activeTab} className={'mt-2'}>
                <TabPane tabId={'set'}>
                    <ListItem
                        className='w-100'
                        showBottomLine={false}
                        showTopLine={false}
                        Left={
                            <div className='d-flex' onClick={() => {setData({discount: 0})}}>
                                <IconOutLine
                                    className='p-3 mr-1'
                                    backgroundColor='#FF3E1D'
                                    color='#FFFFFF'
                                    textChildren={<span className='m-0' style={{ color: 'white' }}>x</span>}
                                    onClick={() => {
                                    }}
                                    sizePx={10}
                                />
                                <span>
                                    Discount
                                </span>
                            </div>
                        }
                        Right={
                            <span 
                                onClick={addDiscount}
                            >{`${formatNumber(parseInt(discount), 0)}%`}</span>
                        }
                    />
                </TabPane>
                <TabPane tabId={'noset'}>
                    <ListItem
                        className='w-100'
                        showBottomLine={false}
                        showTopLine={false}
                        Left={
                            <span 
                                className={`btn-link`}
                                onClick={addDiscount}
                            >Add discount</span>
                        }
                        Right={null}
                    />
                </TabPane>
            </TabContent>
            {
                discount > 0 && (
                    <ListItem
                        className='w-100 mt-2'
                        showBottomLine={false}
                        showTopLine={false}
                        Left={
                            <span>Total price</span>
                        }
                        Right={
                            <span>{`$${formatNumber(parseFloat((pricingRate || 0) - (discountAmount || 0)))}`}</span>
                        }
                    />
                )
            }
        </div>
    )
}

export default PlanBreakdown