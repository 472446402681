import React, { useState, useEffect } from 'react'
import {
  IconClose,
  IconArrowRight,
  IconSignCheck,
  //Avatar,
  ListItem,
  IconSignMinus,
  IconRepeat,
  IconDuplicate,
  DropDown,
  Rating,
  IconCreditCard,
  IconVideo,
  IconPenalize,
  IconArrowDown,
  IconArrowUp,
  IconSignPlus,
  IconTask,
  IconOptions,
  Button,
  Activity
} from '@Knowledge-OTP/znk-ui-components'
import Avatar from '../../../../../common/LazyAvatar'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Badge, Collapse } from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import SendLessonNotes from '../../SendLessonNotes'
import sendLessonAttendance from '../../../../../Model/Lessons/SendLessonAttendance'
import {hasHttp} from '../../utils'
import RecreatePaymentItemForEducator from '../Components/RecreatePaymentItemForEducator'

const AttendedHeaderModal = (props) => {
  const { dataLesson, toggle, lessonStatus } = props
  const { translate } = useTranslation()
  const separateCamelCase = (str) => {
    if (str) str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) { return str.toUpperCase() })
    return str
  }
  const attended = lessonStatus.split('-').length > 1
    ? lessonStatus.split('-')[1] : 'missed'

  const makeup = dataLesson?.isMakeUp || false
  const madeup = dataLesson?.makeUpInfo !== null || false

  const topic = dataLesson.groupTopic || dataLesson.individualTopic || dataLesson.planTopic || {}
  const service = dataLesson.service

  const penalizationInfo = dataLesson?.penalizationInfo

  const haveHomeWork = dataLesson?.notes?.educatorNotes?.newHw || false

  let newHomeWork = (null)
  if (haveHomeWork) {
    /**
      hadHw: Boolean
      didHw: Boolean
      score: Float!
      studentEffort: String
      acqKnowledge: String!
      newHw: Boolean!
      descriptionHw: String!
      dueDateHw: DateTime!
      others: String!
     **/
    newHomeWork = (
      <div className='d-flex flex-column my-2 ml-3' style={{ width: '200px' }}>
        <div className='h4 text-gray'>{translate('modal.infolesson.hwassigned')}</div>
        <div className='h4 text-title my-2'>{dataLesson.notes.educatorNotes.descriptionHw}</div>
        <div className='h4 text-gray'>{translate('modal.sendnotes.duedate')}</div>
        <div className='h4 text-title my-2'>{moment(dataLesson.notes.educatorNotes.dueDateHw).format('YYYY/MM/DD')}</div>
      </div>
    )
  }
  const noshow = dataLesson?.educator?.attendanceStatus === 'missed' // falto el educador
  
  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1'>
        <IconSignMinus size={20} className='ml-auto' />
        <IconClose className='ml-auto' size={20} onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 pt-3 pb-1'>
        <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
          <span className='my-1 h2 font-weight-bold text-title'>
            {dataLesson && `${dataLesson.title}`}
          </span>
          <span className='my-1 h4 text-title'>
            {
              (dataLesson && dataLesson.startDate && dataLesson.endDate) &&
                                `${moment(dataLesson.startDate).format('hh:mm a')} to ${moment(dataLesson.endDate).format('hh:mm a')} | ${separateCamelCase(moment(dataLesson.startDate).format('MMM'))} ${moment(dataLesson.startDate).format('DD, YYYY')}`
            }
          </span>
          <span className='h45 my-1 pr-2 text-gray'>
            {
              (dataLesson && dataLesson.__typename && service) &&
                                    `${separateCamelCase(service?.name)} | ${separateCamelCase(topic?.name)} | ${separateCamelCase(dataLesson?.location?.type || 'No defined')} | ${separateCamelCase(dataLesson.__typename?.replace('Lesson', ''))}`
            }
          </span>
          <div className='d-flex flex-row m-0 p-0'>
            {
              attended !== 'group' &&
                            (
                              <Badge
                                className={`mx-0 mt-3 px-3 mr-1 badge badge-pill badge-${(attended === 'attended' && !noshow) ? 'success' : 'danger'} p-0`}
                                pill
                              >
                                {
                                  noshow
                                  ? translate('modal.infolesson.noshow')
                                  : translate(`modal.infolesson.${attended}`)
                                }
                              </Badge>
                            )
            }
            {
              makeup && (
                <Badge
                  className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                  pill
                >
                  {translate('modal.infolesson.makeup')}
                </Badge>
              )
            }
            {
              madeup && (
                <Badge
                  className='mx-0 mt-3 px-3 mr-1 badge badge-pill badge-secondary p-0'
                  pill
                >
                  {translate('modal.infolesson.madeup')}
                </Badge>
              )
            }
            {
              haveHomeWork &&
                <Badge
                  className='mx-0 mt-3 px-2 mr-1 badge badge-pill badge-secondary p-0'
                  pill
                >
                  <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                    <DropDown
                      className='d-flex flex-row align-items-center'
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      options={[
                        {
                          component: newHomeWork
                        }
                      ]}
                    >
                      <IconTask size={15} />
                    </DropDown>
                  </div>
                </Badge>
            }
            {
              penalizationInfo && (
                <Badge
                  className='mx-0 mt-3 px-2 mr-1 badge badge-pill badge-secondary p-0 d-flex flex-row align-items-center'
                  pill
                >
                  <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                    <DropDown
                      className='d-flex flex-row align-items-center'
                      direction='bottom'
                      fixedWidth
                      hoverEnabled={false}
                      options={[
                        {
                          component:
  <div className='d-flex flex-row h45 px-2 py-1'>
    <div
      title={translate(`modal.penalizelesson.${penalizationInfo?.specificIssueId}`)}
      className='text-title mr-3 ellipsis-text'
      style={{ width: '124px' }}
    >{translate(`modal.penalizelesson.${penalizationInfo?.specificIssueId}`)}
    </div>
    <div className='text-title'>{`-$${parseFloat(penalizationInfo?.amount).toFixed(2)}`}</div>
  </div>
                        }
                      ]}
                    >
                      <IconPenalize color='red' size={15} />
                    </DropDown>
                  </div>
                </Badge>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

const AttendedBodyModal = (props) => {
  const {
    dataLesson,
    student,
    educator,
    createdDate,
    updateDate,
    RenderStudents,
    lessonStatus,
    refetchCalendarQuery,
    setStatusOfInfoLessonState,
    
    setDataLessonToRedux,
    educatorAttendanceState,
    studentAttendanceState,
    checkAttendanceChanges,
    resetAttendance,

  } = props

  const [educatorAttendance, setEducatorAttendance] = educatorAttendanceState
  const [studentAttendance, setStudentAttendace] = studentAttendanceState
  const [setAttendanceMutation, { loading }] = sendLessonAttendance(
    (result) => { setDataLessonToRedux(result.data.sendLessonAttendance) },
    (error) => { console.error(error) }
  )

  const [substituteEducator, setSubstituteEducator] = useState(null)
  const dispatch = useDispatch()
  const { translate } = useTranslation()

  useEffect(() => {
    if (dataLesson?.substitute) setSubstituteEducator(dataLesson?.substitute)
    else setSubstituteEducator(null)
  }, [dataLesson])

  const attended = lessonStatus.split('-').length > 1
    ? lessonStatus.split('-')[1] : 'missed'

  const rating = (Array.isArray(student))
    ? student.reduce((totalRating, stu) => {
      return totalRating + (stu.rating === null ? 0 : stu.rating)
    }, 0) / (student.length > 0 ? student.length : 1)
    : (student?.rating === null ? 0 : student.rating)

  const rateStudent = Array.isArray(student)
    ? `$${student.reduce((minRate, stu) => {
      if (minRate > stu?.rate) return stu?.rate
      return minRate
    }, Number.POSITIVE_INFINITY).toFixed(2)} - 
    $${student.reduce((maxRate, stu) => {
      if (maxRate < stu?.rate) return stu?.rate
      return maxRate
    }, Number.NEGATIVE_INFINITY).toFixed(2)}`
    : (student) && `$${(student?.rate || 0).toFixed(2)}`
  const ignorePaymentStudent = Array.isArray(student)
    ? false
    : student?.ignorePaymentItem

  const makeup = dataLesson?.isMakeUp || false

  const [collapseGroupStudent, setCollapseGroupStudent] = useState(false)
  const toggleCollapseGroupStudent = () => {
    setCollapseGroupStudent(!collapseGroupStudent)
  }

  const notes = dataLesson?.notes?.educatorNotes
  const notesSentDate = dataLesson?.notes?.sentTime

  const allStudentMissed =
    Array.isArray(student)
      ? student.reduce((allMissed, stu) => {
        if (stu?.attendanceStatus !== 'missed') return false
        return allMissed
      }, true)
      : student?.attendanceStatus === 'missed'

  return (
    <>
      <div className='d-flex flex-row justify-content-between my-2'>
        <span className='font-weight-bold'>{translate('modal.infolesson.price')}</span>
        <span className={`font-weight-bold ${ignorePaymentStudent ? 'text-gray' : ''}`} style={ignorePaymentStudent ? { textDecoration: 'line-through' } : {}}>{rateStudent}</span>
      </div>
      <div className='d-flex flex-row justify-content-between mt-2 mb-6'>
        <span className='font-weight-bold'>{translate('modal.newlesson.educatorwage')}</span>
        <span className={`font-weight-bold ${educator?.ignorePaymentItem ? 'text-gray' : ''}`} style={educator?.ignorePaymentItem ? { textDecoration: 'line-through' } : {}}>{`$${educator && educator.rate.toFixed(2)}`}</span>
      </div>
      <div className='m-0 p-0 d-flex flex-column fragment-students-educator'>
      <Collapse isOpen={checkAttendanceChanges}>
          <ListItem
            showTopLine
            showBottomLine={false}
            Left={<span className='btn-link text-error h45' onClick={resetAttendance}>{loading ? <Activity /> : 'Reset'}</span>}
            Right={
              <span
                className='btn-link h45'
                onClick={() => {
                  setAttendanceMutation({
                    lessonId: dataLesson?.id,
                    educatorAttendance: educatorAttendance,
                    attendance:
                      Object.entries(studentAttendance).reduce(
                        (arrayAttendance, [idStudent, atte]) => {
                          arrayAttendance.push({
                            studentId: idStudent,
                            status: atte
                          })
                          return arrayAttendance
                        }, [])
                  })
                }}
              >{loading ? <Activity /> : translate('modal.infolesson.saveAttendance')}
              </span>
            }
          />
        </Collapse>
        <ListItem
          showTopLine
          Left={<RenderStudents student={student} />}
          Right={Array.isArray(student)
            ? <div className='hover-icon py-1 px-2' onClick={toggleCollapseGroupStudent}>
              {
                collapseGroupStudent
                  ? <IconArrowUp />
                  : <IconArrowDown />
              }
              </div>
            : <div className={'d-flex flex-row m-0 p-0'}>
                <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                  <DropDown
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    closeMenuWhenClick={false}
                    options={[
                      {
                        component:
                          <div className='d-flex flex-row m-0 py-0 px-1'>
                            <div
                              className='hover-icon' onClick={() => {
                                setStudentAttendace({ ...studentAttendance, [student.studentUserId]: 'missed' })
                              }}
                            ><IconSignPlus color='red' />
                            </div>
                            <div
                              className='hover-icon' onClick={() => {
                                setStudentAttendace({ ...studentAttendance, [student.studentUserId]: 'pending' })
                              }}
                            ><IconSignMinus color='#72809D' />
                            </div>
                            <div
                              className='hover-icon' onClick={() => {
                                setStudentAttendace({ ...studentAttendance, [student.studentUserId]: 'attended' })
                              }}
                            ><IconSignCheck color='#8FC813' />
                            </div>
                          </div>
                      }
                    ]}
                  >
                    <div className='hover-icon py-1 px-2'>
                      {
                        studentAttendance[student.studentUserId] === 'missed'
                          ? <IconSignPlus color='red' />
                          : studentAttendance[student.studentUserId] === 'attended'
                            ? <IconSignCheck color='#8FC813' />
                            : <IconSignMinus color='#72809D' />

                      }
                    </div>
                  </DropDown>
                </div>
              </div>}
        />
        <Collapse isOpen={collapseGroupStudent}>
          {
            Array.isArray(student) &&
            student.map((stu) => {
              return (
                <div>
                  <ListItem
                    className='py-2'
                    Left={
                      <div className='d-flex flex-row my-0 py-0 mr-7'>
                        <Avatar
                          userId={stu?.id || stu?.studentUserId}
                          name={stu && `${stu.firstName} ${stu.lastName}`}
                          named={false}
                          uri={null}
                          innerDiv
                          size='avatar-small mr-1'
                        />
                        <div
                          className='h45 align-self-center text-dark' style={{
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {stu && `${stu.firstName} ${stu.lastName} `}
                        </div>
                      </div>
                    }
                    Right={
                      <div className='d-flex flex-row align-items-center'>
                        <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                          <DropDown
                            direction='bottom'
                            fixedWidth
                            hoverEnabled={false}
                            closeMenuWhenClick={false}
                            options={[
                              {
                                component:
  <div className='d-flex flex-row m-0 py-0 px-1'>
    <div
      className='hover-icon' onClick={() => {
        setStudentAttendace({ ...studentAttendance, [stu.studentUserId]: 'missed' })
      }}
    ><IconSignPlus color='red' />
    </div>
    <div
      className='hover-icon' onClick={() => {
        setStudentAttendace({ ...studentAttendance, [stu.studentUserId]: 'pending' })
      }}
    ><IconSignMinus color='#72809D' />
    </div>
    <div
      className='hover-icon' onClick={() => {
        setStudentAttendace({ ...studentAttendance, [stu.studentUserId]: 'attended' })
      }}
    ><IconSignCheck color='#8FC813' />
    </div>
  </div>
                              }
                            ]}
                          >
                            <div className='hover-icon py-1 px-2'>
                              {
                                studentAttendance[stu.studentUserId] === 'missed'
                                  ? <IconSignPlus color='red' />
                                  : studentAttendance[stu.studentUserId] === 'attended'
                                    ? <IconSignCheck color='#8FC813' />
                                    : <IconSignMinus color='#72809D' />
                              }
                            </div>
                          </DropDown>
                        </div>
                        <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                          <DropDown
                            direction='bottom'
                            fixedWidth
                            hoverEnabled={false}
                            closeMenuWhenClick
                            options={[
                              {
                                component:
    <div className='d-flex flex-row align-items-center m-0 p-2'>
      <div className='text-title h45 mr-2'>{translate("modal.infolesson.rate")}: </div>
      <div
        style={stu?.ignorePaymentItem ? { textDecoration: 'line-through' } : {}}
        className={`font-weight-bold mr-2 h45 ${stu?.ignorePaymentItem ? 'text-gray' : ''}`}>{`$${stu?.rate.toFixed(2)}`}</div>
    </div>
                              }
                            ]}
                          >
                            <div className='hover-icon py-1 px-2'>
                              <IconOptions style={{ transform: 'rotate(90deg)' }} />
                            </div>
                          </DropDown>
                        </div>
                      </div>
                    }
                  />
                </div>
              )
            })
          }
        </Collapse>
        <ListItem
          showBottomLine
          showTopLine
          Left={
            <div className='d-flex flex-row'>
              <Avatar
                userId={dataLesson.educator?.id || dataLesson.educator?.educatorUserId}
                name={(dataLesson && dataLesson.educator) && `${dataLesson.educator.firstName} ${dataLesson.educator.lastName} `}
                named={false}
                uri={dataLesson?.aditionalInfo?.educatorAvatar}
                size='avatar-medium mr-1'
              />
              <span className='align-self-center'>
                {(dataLesson && dataLesson.educator) && `${dataLesson.educator.firstName} ${dataLesson.educator.lastName} `}
                <small className='text-muted'>{`(${translate('modal.infolesson.educator')})`}</small>
              </span>
            </div>
          }
          Right={
            !substituteEducator && (
              <div className={`d-flex flex-row m-0 p-0`}>
                <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                  <DropDown
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    closeMenuWhenClick={false}
                    options={[
                      {
                        component:
                          <div className='d-flex flex-row m-0 py-0 px-1'>
                            <div className='hover-icon' onClick={() => { setEducatorAttendance('missed') }}><IconSignPlus color='red' /></div>
                            <div className='hover-icon' onClick={() => { setEducatorAttendance('pending') }}><IconSignMinus color='#72809D' /></div>
                            <div className='hover-icon' onClick={() => { setEducatorAttendance('attended') }}><IconSignCheck color='#8FC813' /></div>
                          </div>
                      }
                ]}>
                    <div className='hover-icon py-1 px-2'>
                      {
                        educatorAttendance === 'missed'
                          ? <IconSignPlus color='red' />
                          : educatorAttendance === 'attended'
                            ? <IconSignCheck color='#8FC813' />
                            : <IconSignMinus color='#72809D' />
                      }
                    </div>
                  </DropDown>
                </div>
                <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                  <DropDown
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    closeMenuWhenClick={false}
                    options={[
                      {
                        component: <RecreatePaymentItemForEducator lessonId={dataLesson.id}/>
                      }
                    ]}
                  >
                    <div className='hover-icon py-1 px-2'>
                      <IconOptions style={{ transform: 'rotate(90deg)' }} />
                    </div>
                  </DropDown>
                </div>
              </div>
            )
          }
        />
        {substituteEducator && (
          <ListItem
            showBottomLine
            showTopLine={false}
            Left={
              <div className='d-flex flex-row'>
                <Avatar
                  userId={substituteEducator?.id || substituteEducator?.educatorUserId}
                  name={`${substituteEducator?.firstName} ${substituteEducator?.lastName} `}
                  named={false}
                  uri={dataLesson?.aditionalInfo?.substituteAvatar}
                  size='avatar-small mr-1'
                />
                <span className='align-self-center'>
                  {`${substituteEducator?.firstName} ${substituteEducator?.lastName} `}
                  <small className='text-muted'>{`(${translate('modal.infolesson.substitute')})`}</small>
                </span>
              </div>
            }
            Right={
              <div className={`d-flex flex-row m-0 p-0`}>
                <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                  <DropDown
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    closeMenuWhenClick={false}
                    options={[
                      {
                        component:
                          <div className='d-flex flex-row m-0 py-0 px-1'>
                            <div className='hover-icon' onClick={() => { setEducatorAttendance('missed') }}><IconSignPlus color='red' /></div>
                            <div className='hover-icon' onClick={() => { setEducatorAttendance('pending') }}><IconSignMinus color='#72809D' /></div>
                            <div className='hover-icon' onClick={() => { setEducatorAttendance('attended') }}><IconSignCheck color='#8FC813' /></div>
                          </div>
                      }
                    ]}
                  >
                    <div className='hover-icon py-1 px-2'>
                      {
                        educatorAttendance === 'missed'
                          ? <IconSignPlus color='red' />
                          : educatorAttendance === 'attended'
                            ? <IconSignCheck color='#8FC813' />
                            : <IconSignMinus color='#72809D' />
                      }
                    </div>
                  </DropDown>
                </div>
                <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                  <DropDown
                    direction='bottom'
                    fixedWidth
                    hoverEnabled={false}
                    closeMenuWhenClick={false}
                    options={[
                      {
                        component: <RecreatePaymentItemForEducator lessonId={dataLesson.id}/>
                      }
                    ]}
                  >
                    <div className='hover-icon py-1 px-2'>
                      <IconOptions style={{ transform: 'rotate(90deg)' }} />
                    </div>
                  </DropDown>
                </div>
              </div>
            }
          />
        )}
      </div>
      {
        (attended === 'attended' || attended === 'group') && (
          <>
            <div className='borderTop' />
            <div className='my-4'>
              <span className='h4 font-weight-bold'>{translate('modal.infolesson.studentrating')}</span>
              <Rating className='mt-3' mode='results' initialRating={rating} />
            </div>
          </>
        )
      }
      {
        /**
         *<ListItem
            showTopLine
            className='py-4'
            onClick={toggleCollapseLessonNotes}
            Left={
              <div className='d-flex flex-row'>
                <IconEdit color='#72809D' className='align-self-center mr-2' />
                <span className='align-self-center text-title'>
                  {translate('modal.infolesson.viewlessonnotes')}
                </span>
              </div>
            }
            Right={<IconArrowRight onClick={toggleCollapseLessonNotes}/>}
          />
         * **/
      }
      {
        dataLesson?.notes === null ?
        <>
          <div className='d-flex flex-row my-4'>
            <span className='font-weight-bold'>
              {translate("modal.infolesson.waitingeducatornotes")}
            </span>
          </div>
          <SendLessonNotes
            setStatusOfInfoLessonState={setStatusOfInfoLessonState}
            dataLesson={dataLesson}
            refetchCalendarQuery={refetchCalendarQuery}
          >
            <Button
              style={{ backgroundColor: '#E7AD00' }}
              className='w-100 my-3'
              size='large'
              label={
                <div className='d-flex flex-row justify-content-between'>
                  <span>{translate('modal.infolesson.completelessonnotes')}</span>
                  <IconArrowRight />
                </div>
              }
            />
          </SendLessonNotes>
        </> : 
        !allStudentMissed &&
          (
            <div className='d-flex flex-column'>
                <span className='text-title h4'>{translate('modal.sendnotes.title')}</span>
                <span className='text-title h45'>{`${translate('modal.sendnotes.sentOn')} ${moment(notesSentDate).format('DD MMMM, YYYY')}`}</span>
                {
                  notes?.hadHw && (
                    <div className='d-flex flex-row my-3 h45'>
                      <div className='d-flex flex-column mr-2' style={{ width: '60%' }} flex={1}>
                        <span className='text-gray' style={{ whiteSpace: 'nowrap' }}>{translate('modal.sendnotes.hwCompleted')}</span>
                        <span>{notes?.didHw ? translate('modal.sendnotes.yes') : 'No'}</span>
                      </div>
                      <div className='d-flex flex-column'>
                        <span className='text-gray'>{translate('modal.sendnotes.score')}</span>
                        <span>{notes?.scoreAlphanumeric !== null ? notes?.scoreAlphanumeric : notes?.score || '-'}</span>
                      </div>
                    </div>
                  )
                }
                {
                  notes?.newHw && (
                    <div className='d-flex flex-row my-3 h45'>
                      <div className='d-flex flex-column mr-2' style={{ width: '60%' }} flex={1}>
                        <span className='text-gray' style={{ whiteSpace: 'nowrap' }}>{translate('modal.sendnotes.hwAssigned')}</span>
                        <span>{notes?.descriptionHw}</span>
                      </div>
                      <div className='d-flex flex-column'>
                        <span className='text-gray'>{translate('modal.sendnotes.duedate')}</span>
                        <span>{moment(notes?.dueDateHw).format('DD-MM-YYYY')}</span>
                      </div>
                    </div>
                  )
                }
                <div className='d-flex flex-column my-3 h45'>
                  <span className='text-gray'>{translate('modal.sendnotes.acqknowledge')}</span>
                  <span>{notes?.acqKnowledge}</span>
                </div>
                <div className='d-flex flex-column my-3 h45'>
                  <span className='text-gray'>{translate('modal.sendnotes.studenteffort')}</span>
                  <span>{notes?.studentEffort}</span>
                </div>
                {
                  notes?.others && (
                    <div className='d-flex flex-column my-3 h45'>
                      <span className='text-gray'>{translate('modal.sendnotes.othercomments')}</span>
                      <span>{notes?.others}</span>
                    </div>
                  )
                }
              </div>
          )
      }
      {
        dataLesson.recordingUrl && (
          <ListItem
            showTopLine
            className='py-4'
            onClick={() => {
              window.open(hasHttp(dataLesson.recordingUrl), '_blank')
            }}
            Left={
              <div className='d-flex flex-row'>
                <IconVideo color='#72809D' className='align-self-center mr-2' />
                <span className='align-self-center text-title'>
                  {translate('modal.infolesson.viewlessonrecording')}
                </span>
              </div>
            }
            Right={<IconArrowRight />}
          />
        )
      }
      <ListItem
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.duplicateLessonModal',
            data: {
              isOpen: true,
              dataLesson: dataLesson
            }
          })
        }}
        showTopLine
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconDuplicate color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {translate('modal.infolesson.duplicatelesson')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <ListItem
        onClick={() => {
          if (!makeup) {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.makeUpLesson',
              data: {
                isOpen: true,
                dataLesson: dataLesson
              }
            })
          }
        }}
        showTopLine
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconRepeat color={makeup ? '#D6DAE3' : '#72809D'} className='align-self-center mr-2' />
            <span className={`align-self-center ${makeup ? 'text-disabled' : 'text-title'}`}>
              {translate('modal.infolesson.makeuplesson')}
            </span>
          </div>
        }
        Right={
          makeup
            ? <IconArrowRight color='#D6DAE3' />
            : <IconArrowRight />
        }
      />
      <ListItem
        showTopLine
        onClick={
          () => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.penalizeLesson',
              data: {
                isOpen: true,
                dataLesson: dataLesson
              }
            })
          }
        }
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconPenalize color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {translate('modal.infolesson.penalizelesson')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <ListItem
        showTopLine
        showBottomLine
        onClick={
          () => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.editPaymentLesson',
              data: {
                isOpen: true,
                dataLesson
              }
            })
          }
        }
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconCreditCard color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {translate('modal.infolesson.editpayment')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <ListItem
        showBottomLine={false}
        showTopLine={false}
        className='py-4'
        Left={
          <div className='d-flex flex-column'>
            <span className='h5 align-self-left text-gray my-2'>
              <b>{translate('modal.infolesson.created')}</b> {translate('modal.infolesson.at')} {`
                                            ${createdDate &&
                                                moment(createdDate).format('MMM DD, YYYY hh:mma')
                                            }
                                        `}
            </span>
            <span className='h5 align-self-left text-gray my-2'>
              <b>{translate('modal.infolesson.update')}</b> {translate('modal.infolesson.at')} {`
                                            ${updateDate &&
                                                moment(updateDate).format('MMM DD, YYYY hh:mma')
                                            }
                                        `}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      <div
        className='pb-4'
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.cancelLesson',
            data: {
              isOpen: true,
              lessonId: dataLesson?.id || '',
              isOrientation: false
            }
          })
        }}
      >
        <span className='h5 text-gray'>{translate('modal.infolesson.youneedremove')}</span>
      </div>
    </>
  )
}

export {
  AttendedHeaderModal,
  AttendedBodyModal
}
