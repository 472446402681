import React, { useEffect, useMemo } from 'react'
import {
  Avatar,
  ListItem,
  useWindowDimensions,
  Activity,
  StickyButton,
  IconLesson,
  IconOptions, BulletPoint
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import { parsePlanToEdit } from '../../../Fields/utils.js'
import moment from 'moment'
import 'moment/locale/es'
import startCase from 'lodash/startCase'
import { gql, useLazyQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import CheckInSection from '../../scheduled/Resume/Sections/CheckInSection'
import usePaymentScheduledReducer from '../../../../../reducers/payment-scheduled'
import Big from 'big.js'

const Resume = (props) => {
  const { info, topicKeys, groupInfo, services } = props
  const dispatch = useDispatch()
  const {formatNumber} = usePaymentScheduledReducer()
  const planParts = (info.parts || info.fixedParts || info.PartsSchedule)
  const totalGroupLesson = (info.kind !== 'group' ? groupInfo?.PartsSchedule : planParts)?.reduce((total, part) => {
    return part.lessonsPerWeekGroup.reduce((sum, item) => item.totalLessons + sum, 0) + total
  }, 0)
  const totalIndividualLesson = planParts.reduce((total, part, idx) => {
    return info.kind === 'group' ? part.lessonsPerWeekIndividual.reduce((sum, item) => item.totalLessons + sum, 0) + total : part.totalLessons + total
  }, 0)
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const [getPayerUser, { data, loading }] = useLazyQuery(
    gql`query ($userId: String!) {
        getPayerUser(
            userId: $userId
        ){
            gender
            emails {
                address
            }
            firstName
            lastName
            paymentMethod {
                kind
                ... on ManualPaymentMethod {
                    kind
                }
                ... on CardPaymentMethod{
                    kind
                    status
                    last4
                    brand
                    expMonth
                    expYear
                }
            }
        }
    }`
  )
  useEffect(() => {
    if (info.studentUser) {
      const { studentUserId } = info.studentUser
      getPayerUser({ variables: { userId: studentUserId } })
    }
  }, [info.studentUser, getPayerUser])
  const otherFixedPayment = info.paymentItemsConfig.filter((pay) => pay.type === 'others-fixed')
  const paymentsNames = {
    'Payment registration': translate('modal.newplan.registrationfee'),
    'Payment exam': translate('modal.newplan.examregistrationfee'),
    'Payment pro': 'Zinkerz Pro'
  }
  const discountToLessons =
    info.paymentItemsConfig.filter((pay) => pay.type !== 'others-fixed').length > 0
      ? info.paymentItemsConfig.filter((pay) => pay.type !== 'others-fixed')[0].percentDiscount : 0
   
  
  const studentInfo = useMemo(() => {
    return info?.getStudentInfo
  }, [info]) 

  const totalPayment  = info.paymentItemsConfig.reduce((total, pay) => {
    return total.plus(parseFloat(pay.amount.toFixed(2)))
  }
  , new Big(0))

  return (
    <div className='d-flex flex-column pb-4'>
      {
        info.kind !== 'group' && (
          <>
            <div className='py-4'>
              <label className='h3 font-weight-bold mb-4 mt-2"'>{translate('modal.newplan.student')}</label>
              <ListItem
                className='py-1'
                id='x-0'
                label={null}
                Left={
                  <div className='d-flex align-items-center'>
                    <Avatar
                      name={`${info.studentUser.firstName} ${info.studentUser.lastName}`}
                      named={false}
                      uri={info?.getStudentInfo?.avatar || null}
                      size='avatar-medium'
                    />
                    <span className='ml-2'>
                      {`${info.studentUser.firstName} ${info.studentUser.lastName}`}
                      <span style={{ fontSize: 14 }} className='font-weight-light ml-2 text-gray'>
                        ({translate('modal.newplan.student')})
                      </span>
                    </span>
                  </div>
                }
                Right={
                  <IconOptions size={18} className='m-0 p-0' style={{ transform: 'rotate(90deg)' }} />
                }
              />
              {
                studentInfo?.studentType === 'family' && studentInfo?.parents?.length > 0 && (
                  studentInfo?.parents?.filter((parent) => parent?.manageApprovals || parent?.managePayments).map((parent) => {
                    return (
                      <ListItem
                        className='py-2'
                        Left={
                          <div className='d-flex align-items-center text-body'>
                            {breakWidth !== 'SM' ? (
                              <Avatar
                                name={`${parent.firstName} ${parent.lastName}`}
                                named={false}
                                uri={info?.getStudentInfo?.avatarParent || null}
                                size='avatar-medium'
                              />
                            ) : null}
                            <span className='ml-2'>
                              {`${parent.firstName} ${parent.lastName}`}
                              <span style={{ fontSize: 14 }} className='font-weight-light ml-2 text-gray'>
                                ({translate('modal.newplan.parent')})
                              </span>
                            </span>
                          </div>
                        }
                        Right={<IconOptions size={16} style={{ transform: 'rotate(90deg)' }} />}
                      />
                    )
                  })
                )
              }
            </div>
            <div className='borderTop forceOverflow' />
          </>
        )
      }
      <div className='py-4'>
        <label className='h3 font-weight-bold mb-4 mt-2'>{translate('modal.titles.weekly')}</label>
        {planParts.length > 0
          ? planParts.map((part, partIdx) => {
            const groupPart = info.kind === 'group' ? info.PartsSchedule[partIdx] : groupInfo?.PartsSchedule[partIdx]
            const groupLessonPerWeekPart = groupPart ? groupPart.lessonsPerWeekGroup.reduce((topicObj, groupBlock) => {
              const blockMoment = moment(moment.utc({ hour: groupBlock.hour }).day(groupBlock.day).toISOString())
              if (topicObj[groupBlock.topicId]) {
                const prevInfo = topicObj[groupBlock.topicId]
                topicObj[groupBlock.topicId] = {
                  ...prevInfo,
                  blocks: prevInfo.blocks.concat([{
                    day: blockMoment.format('dddd'),
                    hour: blockMoment.format('hha')
                  }]),
                  lessonsPerWeek: prevInfo.lessonsPerWeek + 1,
                  totalLessons: prevInfo.totalLessons + groupBlock.totalLessons // se suma para le mismo topico
                }
              } else {
                topicObj[groupBlock.topicId] = {
                  blocks: [{
                    day: blockMoment.format('dddd'),
                    hour: blockMoment.format('hha')
                  }],
                  topicId: groupBlock.topicId,
                  lessonsPerWeek: 1,
                  totalLessons: groupBlock.totalLessons || 1
                }
              }
              return topicObj
            }, {}) : {}
            const parts = part.lessonPerWeekPerTopic || part.TotalPerTopic || part.lessonsPerWeekIndividual
            return (
              <div className='w-100 d-flex flex-column'>
                <label className='h5 text-gray pb-1 pt-3'>
                  {
                    (info.kind === 'lessonsFixed' && part.type === 'totalLesson')
                      ? `${translate('modal.draftplan.starton').toUpperCase()} ${moment(part.from.split('T')[0]).format('MMMM Do').toUpperCase()}`
                      : translate('modal.draftplan.rangeDates', {
                        from: moment(part.from.split('T')[0]).format('MMMM Do').toUpperCase(), to: moment(part.to.split('T')[0]).format('MMMM Do').toUpperCase()
                      })
                  }
                </label>
                {
                  Object.values(groupLessonPerWeekPart).map((topicPart) => {
                    return (
                      <ListItem
                        className='py-2'
                        Left={
                          <div className='d-flex align-items-center text-body'>
                            <span>
                              <IconLesson className='text-logo' /> {translate('modal.draftplan.groupalLesson', {
                                num: topicPart.lessonsPerWeek, topic: topicKeys[topicPart.topicId]?.name
                              })}
                            </span>
                            <BulletPoint
                              direction='left'
                              innerComponent={
                                <>
                                  {topicPart.blocks.map(({ day, hour }) => <span className='d-flex align-items-center'>{day}<span className='mx-2 flex-grow-1' />{hour}</span>)}
                                </>
                              }
                              theme='dark'
                            >
                              <span className='text-gray ml-2'>
                                ({topicPart.totalLessons})
                              </span>
                            </BulletPoint>
                          </div>
                        }
                        Right={
                          null
                        }
                      />
                    )
                  })
                }
                {
                  parts.map((topicPart, index) => {
                    return (
                      <ListItem
                        className='py-2'
                        Left={
                          <div className='d-flex align-items-center text-body'>
                            <span>
                              <IconLesson className='text-logo' /> {translate('modal.draftplan.individualLesson', {
                                num: topicPart.lessonsPerWeek, name: topicKeys[topicPart.topicId]?.name
                              })}
                            </span>
                            <span className='text-gray ml-2'>
                              ({topicPart.totalLessons})
                            </span>
                          </div>
                        }
                        Right={
                          <></>
                        }
                      />
                    )
                  })
                }
              </div>)
          })
          : <p className='text-gray'>{translate('modal.titles.definenumberlessons')}</p>}
      </div>
      <div className='borderTop forceOverflow' />
      <>
        <span className='font-weight-bold text-dark py-3'>
          {translate("program.plan.infoModal.checkInSection")}
        </span>
        <CheckInSection plan={info} />
      </>
      <div className='borderTop forceOverflow' />
      <div className='py-4'>
        <label className='h3 font-weight-bold mb-4 mt-2'>{translate('modal.titles.educators')}</label>
        <div className='m-0 p-0 w-100'>
          {info.recommendedEducators.map((educator) => {
            const avatarUrl = info?.aditionalInfo?.educatorsAvatar?.find(({educatorUserId}) => educatorUserId === educator.educatorUserId)?.avatar || null
            return (
              <ListItem
                className='py-1'
                Left={
                  <div className='d-flex align-items-center text-body'>
                    {
                      breakWidth !== 'SM' ? (
                        <Avatar
                          name={`${educator.firstName} ${educator.lastName}`}
                          named={false}
                          uri={avatarUrl}
                          size='avatar-medium'
                        />
                      ) : null
                    }
                    <span className='ml-2 font-weight-normal'>
                      {`${educator.firstName} ${educator.lastName.substring(0, 1)}.`}
                      <span className='text-gray h45 font-weight-light'>{`(${topicKeys[educator.topicId]?.name}, ${startCase(educator?.teachingType)})`}</span>
                    </span>
                  </div>
                }
                Right={
                  <></>
                }
              />)
          })}
        </div>
      </div>
      <div className='borderTop forceOverflow' />
      <div className='py-4'>
        <label className='h3 font-weight-bold mb-4 mt-2'>{translate('modal.titles.pricing')}</label>
        <div className='d-flex flex-column mb-3'>
          <label className='h4 font-weight-bold mt-2 mb-1'>
            {translate(`modal.infoplan.${info.typePlanPricing === 'permonth' ? 'perfeerate' : 'perlessonrate'}`)}
          </label>
          <label className='h4 font-weight-normal my-1'>
            {`${translate(`modal.infoplan.${info.typePlanPricing === 'permonth' ? 'feerate' : 'individuallessonrate'}`)}: $${formatNumber(info.pricingRate * ((100 - discountToLessons) / 100))} ${(discountToLessons > 0) ? `(-${discountToLessons}%)` : ''}`}
          </label>
          {
            (info.groupPricingRate >= 0 && (info.groupId || info.kind === 'group') && info.typePlanPricing !== 'permonth') ? (
              <label className='h4 font-weight-normal my-1'>{`${translate('modal.infoplan.groupallessonrate')}: $${formatNumber(info.groupPricingRate)}`}</label>
            ) : null
          }
        </div>
        <div className='m-0 p-0 pb-3 w-100'>
          {
            otherFixedPayment.map((pay) => {
              return (
                <ListItem
                  className='m-0 py-1 w-100'
                  Left={
                    <span>{paymentsNames[pay.description] || pay.description}</span>
                  }
                  Right={
                    <span className='text-gray'>{`$${formatNumber(pay.amount)}${
                      pay.percentDiscount > 0 ? `(-${pay.percentDiscount}%)` : ''}`}
                    </span>
                  }
                />
              )
            })
          }
          {
            totalIndividualLesson > 0 && (
              <ListItem
                className='m-0 py-1 w-100'
                Left={
                  <span>{`${totalIndividualLesson} ${totalIndividualLesson > 1 ? translate('modal.infoplan.individuallessons').toLowerCase() : translate('modal.infoplan.lesson').toLowerCase()}`}</span>
                }
                Right={
                  <span className='text-gray'>{info.typePlanPricing !== 'permonth' ? '$' + formatNumber(totalIndividualLesson * info.pricingRate * ((100 - discountToLessons) / 100)) : '-'}</span>
                }
              />
            )
          }
          {
            totalGroupLesson > 0 && (
              <ListItem
                className='m-0 py-1 w-100'
                Left={
                  <span>{`${totalGroupLesson} ${totalGroupLesson > 1 ? translate('modal.infoplan.grouplessons').toLowerCase() : translate('modal.infoplan.lesson').toLowerCase()}`}</span>
                }
                Right={
                  <span className='text-gray'>{info.typePlanPricing !== 'permonth' ? '$' + formatNumber(totalGroupLesson * info.groupPricingRate) : '-'}</span>
                }
              />
            )
          }
          <ListItem
            className='m-0 py-1 w-100'
            Left={
              <span className='font-weight-bold'>{translate('profile.payments.total')}</span>
            }
            Right={
              <span className='font-weight-bold'>{`
                $${formatNumber(Number(totalPayment))}
                `
              }
              </span>
            }
          />
        </div>
      </div>
      <div className='borderTop forceOverflow' />
      <div className='py-4'>
        <label className='h3 mb-4 font-weight-bold mb-4 mt-2'>{translate('modal.titles.paymentschedule')}</label>
        {
          loading
            ? (<Activity />)
            : data && data.paymentMethod ? (
              <ListItem
                className='m-0 py-1 mb-4'
                Left={
                  <small className='text-gray'>{translate('modal.titles.paymentmethod')}</small>
                }
                Right={
                  <small className='text-gray'>
                    {
                      data.paymentMethod.__typename === 'CardPaymentMethod'
                        ? `${data.paymentMethod.brand} ${data.paymentMethod.last4}`
                        : `${data.paymentMethod.kind}`
                    }
                  </small>
                }
              />
            ) : null
        }
        <ListItem
          className='m-0 p-0 w-100 py-1'
          Left={
            <label className='text-gray'>{translate('modal.newplan.paymentschedule.whenapproving')}</label>
          }
          Right={
            <label>{`$${
                formatNumber(info.paymentItemsConfig.filter((pay) => pay.type === 'others-fixed').reduce((accuPay, pay) => {
                  return accuPay + pay.amount
                }, 0))}`}
            </label>
          }
        />
        {info.paymentItemsConfig
          ? info.paymentItemsConfig.filter((pay) => pay.type !== 'others-fixed').map((pay, index) => {
            return (
              <ListItem
                showBottomLine={info.paymentItemsConfig.filter((pay) => pay.type === 'lessons-payment').length - 1 === index}
                className={`m-0 p-0 ${info.paymentItemsConfig.filter((pay) => pay.type === 'lessons-payment').length - 1 === index ? 'mb-2' : ''}`}
                Left={
                  <label className='text-gray'>{translate('profile.payments.endOf', moment(pay.date).utc().format('MMM'))}</label>
                }
                Right={
                  <label>{`$${formatNumber(pay.amount)}`}</label>
                }
              />
            )
          })
          : null}
      </div>
      <div className='borderTop forceOverflow' />
      <StickyButton
        condition
        label='Edit Plan'
        color='#696CFF'
        btnClassName='mb-4'
        onClickButtonFunction={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.newModal',
            data: {
              isOpen: true,
              formProps: parsePlanToEdit(
                info,
                (services && services?.docs) ? services.docs.reduce((servName, serv) => {
                  if (info.serviceId === serv.id) return serv.name
                  return servName
                }, '') : ''
              )
            }
          })
        }}
      />
    </div>
  )
}

export default Resume
