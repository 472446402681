import { useQuery, gql } from '@apollo/client'

const useGetScoresGroupByStudents = (
  vars = {},
  options = { fetchPolicy: 'cache-first' }
) => {
  return useQuery(
    gql`
      query (
        $countryIds: [String!]
        $classOf: [Int!]
        $servicesIds: [String!]
        $testDate: DateTime
        $groupBy: String
        $search: String = null
        $sortField: String = "studentInfo.firstName"
        $page: Int
        $limit: Int
      ) {
        getScoresWithFilters(
          search: $search
          limit: $limit
          sortField: $sortField
          countryIds: $countryIds
          servicesIds: $servicesIds
          testDate: $testDate
          classOf: $classOf
          groupBy: $groupBy
          page: $page
        ) {
          docs {
            _id
            studentInfo {
              firstName
              lastName
              id
              studentInfo {
                classOf
              }
            }
            exams {
              id
              name
              examDate
              totalScore
              planId
              taskId
              serviceId
              planInfo {
                name
                id
                activityTest
                serviceId
              }
              examId
              examScoreId
              results {
                name
                scale
                value
                method
                values {
                  name
                  scale
                  value
                  isOnMethod
                }
              }
            }
          }
          totalRows
        }
      },
    `,
    {
      ...vars,
      ...options
    }
  )
}

export default useGetScoresGroupByStudents
