//import queryLessonResult from '../../../../Model/QueryResult/useLessonResult'
import {useQuery, gql} from '@apollo/client'

import queryLessonResult from './queryLessonResult'
/**
    aditionalInfo {
        studentAvatar
        educatorAvatar
        substituteAvatar
      }

      * */
export default (vars = {}, otherOptions = {}) => {
    return useQuery(
        gql`
            query(
                    $kinds: [String!]
                    $userId: ID
                    $userIds: [ID!]
                    $from: DateTime!
                    $to: DateTime!
                    $serviceId: String
                    $allIncludes: Boolean
                ) {
            calendarView(
                kinds: $kinds
                userId: $userId,
                userIds: $userIds,
                from: $from,
                to: $to,
                serviceId: $serviceId
                allIncludes: $allIncludes
            ) {  
                ${queryLessonResult}
                ... on Counselor {
                    id
                    startDate
                    endDate
                    counselorId
                    CounselorCreated : createdAt
                    CounselorUpdate : updatedAt
                    busy
                }
                ... on EducatorUnavailability {
                    id
                    kind
                    startDate
                    endDate
                    busy
                    educatorId
                    userIdsInvolved
                    busy
                }
            }
        }
        `,
        {
            // fetchPolicy: 'no-cache',
            variables: vars,
            ...otherOptions
        },
    )
}