import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  IconClose,
  useWindowDimensions,
  StickyButton,
  CheckboxOption,
  AmountInput
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'

const EditRate = (props) => {
  const { defaultRate, defaultCheck, title } = props
  const { isOpen, onClickFunction } = useSelector((state) => state.modal.plan.editRateLesson)
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.editRateLesson',
      data: {
        isOpen: !isOpen,
        onClickFunction
      }
    })
  }
  const { breakWidth } = useWindowDimensions()
  const [valueInput, setValueInput] = useState(defaultRate)
  const [valueCheck, setValueCheck] = useState(false)
  useEffect(() => {
    setValueInput(defaultRate)
    setValueCheck(defaultCheck)
  }, [defaultRate, defaultCheck])
  return (
    <Modal
      buttonLabel={null}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header p-0 m-0'>
          <div className='d-flex justify-content-between w-100 px-5 py-4'>
            <h2 className='font-weight-bold'>
              {title}
            </h2>
            <IconClose onClick={toggle} />
          </div>
        </div>
      }
    >
      <div className='px-5 pt-0 m-0'>
        <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
          {translate('modal.newlesson.rate')}
        </label>
        <AmountInput
          fieldType='number'
          value={valueInput}
          onChange={(e) => {
            setValueInput(e)
          }}
        />
        <div className='d-flex flex-row py-5 pl-2'>
          <CheckboxOption
            name='waive' defaultState={valueCheck} onChange={(e) => {
              setValueCheck(e)
            }}
          />
          <span>{translate('modal.newlesson.waiverate')}</span>
        </div>
        <StickyButton className='my-2'>
          <div className='m-0 p-0 row'>
            <Button
              color='primary'
              activity={false}
              onClick={() => {
                onClickFunction(valueInput, valueCheck)
                toggle()
              }}
              className='col-12 aling-text-left'
              label={translate('modal.newlesson.savechanges')}
              style={{ borderRadius: '16px' }}
            />
          </div>
        </StickyButton>
      </div>
    </Modal>
  )
}

export default EditRate
