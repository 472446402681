import React, { useState, useMemo, Suspense, lazy, useEffect, useRef } from 'react'
import useServices from '../../Model/Services/List'
import useTopics from '../../Model/Topics/List'
import useUsers from './Model/UseUsers'
import useCountries from '../../Model/Countries/List'
import { Badge, TabContent, TabPane } from 'reactstrap'
import {
  Button,
  Table,
  IconFilter,
  IconSignPlus,
  IconSearch,
  DropDownNew,
  DropDownSub,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import useTranslation from '../../i18n/useTranslation'
import isEmpty from 'lodash/isEmpty'
import PlanModal from './modal'
import NewPlan from './modal/newPlan'
import { useDispatch, useSelector } from 'react-redux'
import ServicesFilter from './Filters/Services'
import StatusesFilter from './Filters/Statuses'
import TopicsFilter from './Filters/Topics'
import EducatorsFilter from './Filters/Educators'
import StartDateFilter from './Filters/StartDate'
import FinalizationDates from './Filters/FinalizationDates'
import CountriesFilter from './Filters/Countries'
import PendingLessonsInner from './Filters/PendingLessonsInner'
import ConfirmCancelPlan from './modal/confirmCancelPlan'
import ResumePlanModal from './modal/ResumePlanModal'
import SendAllPlansModal from './modal/SendAllPlans'
import SendOnePlanModal from './modal/SendOnePlan'
import isEqual from 'lodash/isEqual'
import useResponsivePlanColumns from './useResponsivePlanColumns'
import Skeleton from 'react-loading-skeleton'
import { getTopicIdsInPlan } from './Fields/Topics'
import NewGroupPlan from './GroupPlans/modal/New'
import useModalState from '../../utils/useModalState'
import useAllActivities from '../Constants/Activities/useActivities'
import TestDateFilter from './Filters/TestDate'
import EditGroupPlan from './GroupPlans/modal/Edit'
import EditChildPlan from './modal/editChildPlan'
import ViewGroupPlan from './GroupPlans/modal/View'
import Pagination from '../../common/Pagination'
import { gql, useLazyQuery } from '@apollo/client'
import plansQueryResult from '../../Model/QueryResult/usePlansQueryResult'
import useModalEmailsList from '../../common/ModalEmailsList/useModalEmailsList'
import ExcelDownloadComponent from '../../common/ExcelDownload'
import useExcelDownload from '../../common/ExcelDownload/useExcelDownload'
import useDownLoadExcelUsers from '../Users/DownLoadExcelUsers/downloadExcelUsers'
import OptionsEmailsAndDownloads from '../../common/ModalEmailsList/Components/Options'
import NoAuthorize from '../../common/NoAuthorize'
import RedirectFromUrl from './RedirectFromUrl'
import PlansTableSkeleton from './Skeleton'
import ModalFilterMobile from './Filters/ModalFilterMobile'
//Complete Group plan
import CompleteGroupPlanModal from './GroupPlans/modal/CompleteGroupPlan'

import useCompletaAllPlans from '../Constants/LivePrepPlans/useCompleteAllPlans'
import LivePrepPlans from '../Constants/LivePrepPlans'
import AtomicFilter from './Filters/atomicFilter'
const GroupIndividualRows = lazy(() => import('./GroupPlans/IndividualPlans'))

const baseStatuses = [
  'draft', 'sent', 'scheduled', 'pause', 'finished', 'cancel'
  //'cancel', 'finished'
]

const PlansView = () => {
  const { translate } = useTranslation()
  const {breakWidth} = useWindowDimensions()
  const [execDownload, setExecDownload] = useState(false)
  //pagination control
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(0)
  const [activePage, setActivePage] = useState(1)
  //const [pageIndexPrevious, setPageIndexPrevious] = useState('')
  //const [pageIndexNext, setPageIndexNext] = useState('')
  //const [hasPrevious, setHasPrevious] = useState(false)
  //const [hasNext, setHasNext] = useState(false)
  const [plans, setPlans] = useState([])
  const [loadingExcelInfo, setLoadingExcelInfo] = useState(false)


  const executeOnClickDownload = () => {
    openExcelDownloadModal(() => { }, "plans", query)
    setExecDownload(true) 
  }
  const [shortcuts, setShortcuts] = useState({
    finishSoon: false,
    lastApproved: false,
    previouslySent: false,
    shortRecenlySent: false
  })
  const [testDate, setTestDate] = useState({ id: '', name: '-', date: null })
  const defaultQuery = {
    search: null,
    limit: pageSize,
    next: '',
    previous: '',
    status: (baseStatuses || []).filter((status) => !['finished', 'cancel'].includes(status)),
    serviceIds: [],
    topicIds: [],
    studentsUserIds: [],
    educatorUserIds: [],
    countryIds: [],
    startDateFilter: [],
    endDateFilter: [],
    lessonsPending: 0,
    kind: ['lessonsPerWeek', 'lessonsFixed'],
    testDate: null,
    isAtomic: undefined,
    ...shortcuts
  }
  const [query, setQuery] = useState(defaultQuery)
  const queryRef = useRef(query);
  const updateQueryRef = (newQuery) => {
    queryRef.current = newQuery;
  };

  useEffect(() => {
    updateQueryRef(query);
  }, [query, defaultQuery]);

  const { data: services } = useServices({ limit: 0 })
  const { staffInfo } = useSelector((state) => state.user)
  const isPermited = staffInfo?.permissions?.filter(e => e.isPermited).map(per => { return per.tag }).includes('liveprep-plans');

  const { data: countries, loading: countriesLoading } = useCountries({ limit: 0 })
  const baseCountries = useMemo(
    () => (!countriesLoading && countries && countries.docs ? countries.docs.map((country) => country.id) : []),
    [countries, countriesLoading]
  )

  const { data: topics, loading: topicsLoading } = useTopics({ limit: 0 })

  //eslint-disable-next-line
  const [getDataUsers, { loading: loadingUsersExcel }] = useDownLoadExcelUsers()
  //TODO debe actualizarse 
  const { data: allUsers, loading: educatorsLoading } = useUsers({ roles: ['STUDENT', 'EDUCATOR'], limit: 0 })

  const { students, educators } = useMemo(
    () => {
      const arrayUsers = (allUsers?.docs || [])
      return {
        students: { ...allUsers, docs: arrayUsers.filter(({ roles }) => roles?.includes('STUDENT')) },
        educators: { ...allUsers, docs: arrayUsers.filter(({ roles }) => roles?.includes('EDUCATOR')) }
      }
    },
    [allUsers]
  )

  // Modal lista de mails
  const { open: openEmailsListModal } = useModalEmailsList()
  const { open: openCompleteAllPlanModal } = useCompletaAllPlans()
  const { open: openExcelDownloadModal } = useExcelDownload()
  //const { data: educators, loading: educatorsLoading } = useUsers({ roles: ['EDUCATOR'], limit: 0 })
  const baseEducators = useMemo(
    () => (!educatorsLoading && educators && educators.docs ? educators.docs.map((user) => user.id) : []),
    [educators, educatorsLoading]
  )
  const { data: testDates, loading: testDatesLoading } = useAllActivities({ serviceIds: query.serviceIds, kinds: ['test'], isDisabled: false })

  const setCurrentShortcut = (shortcutKey) => {
    const newShortcut = {
      finishSoon: false,
      lastApproved: false,
      previouslySent: false,
      shortRecenlySent: false
    }
    newShortcut[shortcutKey] = true
    const updateQuery = {
      ...query,
      kind: ['lessonsPerWeek', 'lessonsFixed'],
      ...newShortcut
    };

    if (["shortRecenlySent", "previouslySent"].includes(shortcutKey)) {
      updateQuery.status = baseStatuses.filter((status) => !['scheduled', 'cancel'].includes(status));
    } else if (["lastApproved", "finishSoon"].includes(shortcutKey)) {
      updateQuery.status = baseStatuses.filter((status) => ['scheduled'].includes(status));
    }
    // let updateQuery = {}
    // if (query.kind.includes('group')) {
    //   //setPlanTypes(['lessonsPerWeek', 'lessonsFixed'])
    //   updateQuery = { ...updateQuery, kind: ['lessonsPerWeek', 'lessonsFixed'] }
    // }
    setShortcuts(newShortcut)
    // if (["shortRecenlySent", "previouslySent"].includes(shortcutKey)) {
    //   // setStatusFilters(baseStatuses.reduce((newArray, status) => {
    //   //   if (!['scheduled', 'cancel'].includes(status)) newArray.push(status)
    //   //   return newArray
    //   // }, []))
    //   updateQuery = {
    //     ...updateQuery,
    //     status: baseStatuses.reduce((newArray, status) => {
    //       if (!['scheduled', 'cancel'].includes(status)) newArray.push(status)
    //       return newArray
    //     }, [])
    //   }
    // }
    setActivePage(1)
    setQuery({ ...query, ...updateQuery })

  }
  const resetShortcuts = () => {
    setShortcuts({
      finishSoon: false,
      lastApproved: false,
      previouslySent: false,
      shortRecenlySent: false
    })
  }
  const resetFilters = (option = 'allPlans') => {
    const defaultShortcuts = {
      finishSoon: false,
      lastApproved: false,
      previouslySent: false,
      shortRecenlySent: false
    }
    const options = {
      'allPlans':{ 
        kind: ['lessonsPerWeek', 'lessonsFixed']
      },
      'group': {
        kind: ['group']
      }
    }

    const resetQuery = {
      ...defaultQuery,
      status: ['draft', 'sent', 'scheduled', 'pause'],
      ...defaultShortcuts
    }
    setShortcuts(defaultShortcuts)
    setActivePage(1)
    // !query.status.includes('scheduled') ? query.status.push('scheduled') : null
    setQuery({
      ...defaultQuery,
      ...resetQuery,
      kind: options[option]?.kind || options['allPlans'].kind
    })
    setTestDate({
      id: '',
      name: '-',
      date: null
    })

  }
  const canReset = (query) => {
    return !isEqual(query, {
      limit: 0,
      status: baseStatuses,
      serviceIds: [],
      topicIds: [],
      studentsUserIds: [],
      educatorUserIds: [],
      countryIds: [],
      startDateFilter: [],
      endDateFilter: [],
      lessonsPending: 0,
      kind: query.kind,
      finishSoon: false,
      lastApproved: false,
      previouslySent: false,
      shortRecenlySent: false,
      testDate: null
    })
  }


  const isGroupPlanList = useMemo(() => {
    return plans?.docs?.length > 0 && plans?.docs[0]['__typename'] === 'GroupPLan'
  }, [plans])


  const [getPlans, { data, loading, refetch }] = useLazyQuery(
    gql`query (
      $search: String,
      $sortField: String,
      $previous: String,
      $next: String,
      $limit: Int,
      $to: DateTime,
      $from: DateTime,
      $status: [PlanStatus!]!,
      $studentsUserIds: [ID!],
      $serviceIds: [ID!],
      $kind: [PlanKind!]!,
      $topicIds: [ID!]
      $educatorUserIds: [ID!]
      $countryIds: [ID!]
      $lessonsPending: Int
      $endDateFilter: [DateTime!]
      $startDateFilter: [DateTime!]
      $finishSoon: Boolean
      $lastApproved: Boolean
      $previouslySent: Boolean
      $shortRecenlySent: Boolean
      $testDate: DateTime
      $isAtomic: Boolean
      ){
    plans (
      sortField: $sortField,
      previous: $previous,
      next: $next,
      limit: $limit,
      to: $to,
      from: $from,
      status: $status,
      studentsUserIds: $studentsUserIds,
      serviceIds: $serviceIds,
      kind: $kind,
      topicIds: $topicIds
      educatorUserIds: $educatorUserIds
      countryIds: $countryIds
      lessonsPending: $lessonsPending
      endDateFilter: $endDateFilter
      startDateFilter: $startDateFilter
      finishSoon: $finishSoon
      lastApproved: $lastApproved
      previouslySent: $previouslySent
      shortRecenlySent: $shortRecenlySent
      testDate: $testDate
      search: $search
      isAtomic: $isAtomic
    ){
      hasNext
      hasPrevious
      next
      previous
      totalDocs
      docs {
        ${plansQueryResult}
      }
    }
  }`,
    {
      variables: query,
      fetchPolicy: 'no-cache'
      //onError: (err) => {console.log(err.toString())}
    }
  )
  
  useEffect(
    () => {
      getPlans(query)
      // eslint-disable-next-line
    }, [query]
  )


  //pagination results
  const [queryPaginationResult, setQueryPaginationResult] = useState({})
  const { hasPrevious, hasNext, next: pageIndexNext, previous: pageIndexPrevious } = queryPaginationResult

  useEffect(
    () => {
      getPlans(query)
      // eslint-disable-next-line
    }, [query]
  )

  useEffect(() => {
    if (data) {
      const { docs, ...queryResult } = data.plans
      setQueryPaginationResult(queryResult)
      setPageCount(Math.ceil(data.plans.totalDocs / pageSize))
      setPlans(data?.plans)
    }
    // eslint-disable-next-line
  }, [data])
  const dispatch = useDispatch()
  const openNewPlanModal = (form) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.newModal',
      data: { isOpen: true, formProps: form, incomplete: false }
    })
  }
  const { toggle: toggleGroupModal } = useModalState('plan.newGroupModal')
  const getServiceById = (serviceId) => {
    return !isEmpty(services)
      ? services.docs.find((service) => service.id === serviceId)
      : null
  }
  const getStudentById = (studentId) => {
    //TODO arreglo de students se desactualiza
    return !isEmpty(students)
      ? students.docs.find((student) => student.id === studentId)
      : null
  }
  const columns = useResponsivePlanColumns({ getServiceById, query, plans, getStudentById, refetch })
  const openPlanModal = (planInfo) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.seeInfo',
      data: {
        isOpen: true,
        info: planInfo,
        ...{
          thisService: getServiceById(planInfo.serviceId),
          thisTopics: getTopicIdsInPlan(planInfo.parts || planInfo.fixedParts, planInfo.kind),
          queryData: plans,
          queryVar: query
        }
      }
    })
  }
  const onOpenChild = (v) => {
    refetch()
  }
  const clickNext = () => {
    if (hasNext) {
      setQuery({
        ...query,
        next: pageIndexNext,
        previous: ''
      })
      setActivePage((activePage + 1))
    }
  }
  const clickPrevious = () => {
    if (hasPrevious) {
      setQuery({
        ...query,
        next: '',
        previous: pageIndexPrevious
      })
      setActivePage((activePage - 1))
    }
  }
  const changeSize = (value) => {
    setActivePage(1)
    setPageSize(value)
    setQuery({ ...query, next: null, previous: null, limit: value })
  }

  const searchInput = useRef(null)


  const setFilter = (newQuery) => {
    setActivePage(1)
    setQuery(newQuery)
  }

  const [loadChild, setLoadChild] = useState({})
  if (!isPermited) {
    return <NoAuthorize />
  }
  return (
    <>
      <div
        className="col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between"
        style={{ minHeight: "100%" }}
      >
        <div className={`d-flex flex-column m-0 p-0`}>
          <div className="container-fluid mt-2 mt-md-5 d-flex px-2 flex-column-reverse flex-md-row">
            <div className="d-flex flex-grow-1 align-items-center znk-scroll-horizontal mt-2 pr-0 pr-md-2 mt-md-0">
              <span
                className={`nav-title ${
                  !query.kind.includes("group") &&
                  isEqual(shortcuts, {
                    finishSoon: false,
                    lastApproved: false,
                    previouslySent: false,
                    shortRecenlySent: false,
                  })
                    ? "active"
                    : null
                } m-0 mr-3`}
                onClick={() => {
                  resetShortcuts();
                  resetFilters("allPlans");
                  //setPageIndexNext('')
                  //setPageIndexPrevious('')
                  //setPlanTypes(['lessonsFixed', 'lessonsPerWeek'])
                  // setFilter({ kind: ['lessonsFixed', 'lessonsPerWeek'] })
                  // setQuery({ ...query, kind: ['lessonsFixed', 'lessonsPerWeek'] })
                }}
              >
                {translate("plans.filters.allPlans")}
              </span>
              <span
                className={`nav-title ${shortcuts.shortRecenlySent &&
                  "active"} m-0 mx-3`}
                onClick={() => setCurrentShortcut("shortRecenlySent")}
              >
                {translate("plans.filters.recentlySent")}
              </span>
              <span
                className={`nav-title ${shortcuts.previouslySent &&
                  "active"} m-0 mx-3`}
                onClick={() => setCurrentShortcut("previouslySent")}
              >
                {translate("plans.filters.previouslySent")}
              </span>
              <span
                className={`nav-title ${shortcuts.lastApproved &&
                  "active"} m-0 mx-3`}
                onClick={() => setCurrentShortcut("lastApproved")}
              >
                {translate("plans.filters.lastApproved")}
              </span>
              <span
                className={`nav-title ${shortcuts.finishSoon &&
                  "active"} m-0 mx-3`}
                onClick={() => setCurrentShortcut("finishSoon")}
              >
                {translate("plans.filters.finishSoon")}
              </span>
              <span
                className={`nav-title ${
                  query.kind.includes("group") ? "active" : null
                } m-0 mx-3`}
                onClick={() => {
                  resetShortcuts();
                  resetFilters("group");
                  //setPageIndexNext('')
                  //setPageIndexPrevious('')
                  //setPlanTypes(['group'])
                  // setFilter({ kind: ['group'] })
                }}
              >
                {translate("plans.filters.groupPlans")}
              </span>
            </div>
            <div className="d-md-flex d-none align-items-center justify-content-end justify-content-md-start flex-row-reverse flex-md-row">
              <span>
                {query.kind.includes("group") ? (
                  <Button
                    label="+ New Group Plan"
                    className="btn-table"
                    color="primary"
                    onClick={() => {
                      toggleGroupModal();
                    }}
                  />
                ) : (
                  <Button
                    label="+ New Plan"
                    className="btn-table"
                    color="primary"
                    onClick={() => {
                      openNewPlanModal({});
                    }}
                  />
                )}
              </span>
            </div>
          </div>
          <div
            className={
              "m-0 p-0 w-100 d-flex flex-row justify-content-between align-items-center"
            }
            style={{ minHeight: "68px" }}
          >
            {breakWidth === "SM" ? (
              <ModalFilterMobile
                setQuery={setFilter}
                query={query}
                canReset={canReset(query)}
                resetFilters={resetFilters}
              >
                <div className="hover-icon">
                  <IconFilter size={20} className="text-disabled" />
                </div>
              </ModalFilterMobile>
            ) : (
              <div className="container-fluid mt-2 mb-4 mt-md-5 px-0 d-flex znk-scroll-horizontal">
                <ServicesFilter
                  {...{
                    serviceFilters: query.serviceIds,
                    setServiceFilters: (newServices) => {
                      setFilter({ ...query, serviceIds: newServices });
                    },
                  }}
                />
                {query.serviceIds.length ? (
                  <>
                    {!topicsLoading && topics && topics.docs && (
                      <TopicsFilter
                        {...{
                          topics,
                          topicFilters: query.topicIds,
                          setTopicFilters: (topics) => {
                            setFilter({ ...query, topicIds: topics });
                          },
                        }}
                        serviceFilters={query.serviceIds.map((serviceId) =>
                          getServiceById(serviceId)
                        )}
                      />
                    )}
                    {!testDatesLoading && testDates && (
                      <TestDateFilter
                        {...{
                          testDate,
                          testDates,
                          setTestDate: (e) => {
                            setTestDate(e);
                            setFilter({ ...query, testDate: e.date });
                          },
                          serviceFilters: query.serviceIds,
                        }}
                      />
                    )}
                  </>
                ) : null}
                <StatusesFilter
                  {...{
                    statuses: baseStatuses,
                    statusFilters: query.status,
                    setStatusFilters: (newStatus) => {
                      setFilter({ ...query, status: newStatus });
                    },
                  }}
                />
                <DropDownNew
                  className="m-0 p-0"
                  classNameMenu="px-3 pt-3 pb-4"
                  direction="bottomCenter"
                  hoverEnabled={false}
                  trigger={["click"]}
                  menuProps={{ triggerSubMenuAction: "click" }}
                  options={[
                    {
                      component: (
                        <DropDownSub title={`Dates`}>
                          <StartDateFilter
                            startDateFilter={query.startDateFilter}
                            setStartDateFilter={(start) => {
                              setFilter({ ...query, startDateFilter: start });
                            }}
                            typeFilter="StartDate"
                          />
                        </DropDownSub>
                      ),
                    },
                    {
                      component: (
                        <DropDownSub
                          title={translate(
                            "filters.plans.finalizationDateTitle"
                          )}
                        >
                          <FinalizationDates
                            {...{
                              setEndDateFilter: (end) => {
                                setFilter({ ...query, endDateFilter: end });
                              },
                            }}
                          />
                        </DropDownSub>
                      ),
                    },
                    {
                      component: !educatorsLoading && educators && educators && (
                        <DropDownSub
                          title={translate("filters.plans.educatorsTitle")}
                        >
                          <EducatorsFilter
                            {...{
                              className: `h45`,
                              educators:
                                educators && educators.docs
                                  ? educators.docs
                                  : baseEducators,
                              educatorFilters: query.educatorUserIds,
                              setEducatorFilters: (educatorIds) => {
                                setFilter({
                                  ...query,
                                  educatorUserIds: educatorIds,
                                });
                              },
                            }}
                          />
                        </DropDownSub>
                      ),
                    },
                    {
                      component: (
                        <DropDownSub
                          title={translate("filters.plans.pendingLessonsTitle")}
                        >
                          <PendingLessonsInner
                            {...{
                              lessonsPending: query.lessonsPending,
                              setLessonsPending: (lp) => {
                                setFilter({ ...query, lessonsPending: lp });
                              },
                            }}
                          />
                        </DropDownSub>
                      ),
                    },
                    {
                      component: !countriesLoading && countries && countries && (
                        <DropDownSub
                          title={translate("filters.plans.countriesTitle")}
                        >
                          <CountriesFilter
                            {...{
                              className: "h45",
                              countries:
                                countries && countries.docs
                                  ? countries.docs
                                  : baseCountries,
                              countryFilters: query.countryIds,
                              setCountryFilters: (ids) => {
                                setFilter({ ...query, countryIds: ids });
                              },
                            }}
                          />
                        </DropDownSub>
                      ),
                    },
                    {
                      component: (
                        <DropDownSub title=" Atomic plans">
                          <AtomicFilter
                            {...{
                              atomicFilter: query.isAtomic,
                              setAtomicFilter: (id) => {
                                setFilter({ ...query, isAtomic: id });
                              },
                            }}
                          />

                          {}
                        </DropDownSub>
                      ),
                    },
                  ]}
                >
                  <Badge className="mx-1 filters" pill color="option">
                    <label style={{ cursor: "pointer" }} className="m-0 p-0">
                      <IconFilter className="mr-2" size={15} />
                      {translate("plans.filterTitle")}
                    </label>
                  </Badge>
                </DropDownNew>
                {canReset(query) ? (
                  <span
                    className="btn-link d-flex align-items-center"
                    onClick={resetFilters}
                  >
                    <IconSignPlus size={14} className="mr-1" />
                    {translate("misc.cleanFilters")}
                  </span>
                ) : null}
              </div>
            )}
            {/* <ButtonEmailsList 
              onClick={() => { openEmailsListModal(() => {}, {...query, limit: 0}, "plans") }} 
            />
            <DownLoadExcelPlans /> */}
            <InputGroup
              className={`znk-card ${
                breakWidth === "SM" ? "w-auto" : "w-25"
              } flex-nowrap`}
              title={translate("plans.filters.searchByStudentName")}
            >
              <input
                className={"znk-input noRight trans form-control"}
                placeholder={translate("plans.filters.searchByStudentName")}
                ref={searchInput}
                onKeyUp={({ key }) => {
                  if (key === "Enter") {
                    setFilter({ ...query, search: searchInput.current.value });
                  }
                }}
              />
              <InputGroupAddon addonType="prepend" className="icon">
                <InputGroupText type="text" className="znk-no-border">
                  <span className="addon">
                    <IconSearch size={18} className="text-gray" />
                  </span>
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>

            <OptionsEmailsAndDownloads
              className={`${breakWidth === "SM" ? "ml-0" : "ml-3"}`}
              options={[
                {
                  title: translate("users.actions.emails"),
                  onClick: () => {
                    openEmailsListModal(() => {}, "plans", query);
                  },
                },
                {
                  title: translate("users.actions.download"),
                  onClick: () => {
                    executeOnClickDownload();
                  },
                },
                {
                  title: translate("plans.actions.completeAllPlans"),
                  onClick: () => {
                    openCompleteAllPlanModal();
                  },
                },
              ]}
              loading={loadingExcelInfo}
            />
          </div>
          <div className={`m-0 p-0`}>
            <TabContent
              activeTab={loading || !plans.docs ? "loading" : "table"}
            >
              <TabPane tabId={`loading`}>
                <div className={`p-0 m-2`}>
                  <PlansTableSkeleton count={pageSize} />
                </div>
              </TabPane>
              <TabPane tabId={`table`}>
                {//planTypes.includes('group') ||
                isGroupPlanList ? (
                  <Table
                    loading={loading}
                    columnList={columns}
                    tableData={plans?.docs}
                    collapseRender={(props) => {
                      return loadChild[props.id] ? (
                        <div className="appears-animation p-0 mx-2 my-0">
                          <PlansTableSkeleton count={2} />
                        </div>
                      ) : (
                        <Suspense fallback={<Skeleton />}>
                          <GroupIndividualRows
                            partsSchedule={props.PartsSchedule}
                            childPlans={props.childPlans}
                            services={services}
                            openIndividual={openPlanModal}
                            individualPlanTableProps={{
                              getServiceById,
                              query,
                              plans,
                              getStudentById,
                              refetch: () => {
                                setLoadChild({
                                  ...loadChild,
                                  [props.id]: true,
                                });
                                refetch().then(
                                  () => {
                                    setLoadChild({
                                      ...loadChild,
                                      [props.id]: false,
                                    });
                                  },
                                  () => {
                                    setLoadChild({
                                      ...loadChild,
                                      [props.id]: false,
                                    });
                                  }
                                );
                              },
                            }}
                          />
                        </Suspense>
                      );
                    }}
                    onClick={(value) => {
                      onOpenChild(value);
                    }}
                  />
                ) : (
                  <Table
                    loading={loading}
                    columnList={columns}
                    tableData={plans?.docs}
                    onClick={openPlanModal}
                  />
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>

        {
          // !loading && !servicesLoading &&
          <Pagination
            style={{
              bottom: "0px",
              position: "sticky",
              backgroundImage: `linear-gradient(
                      to bottom, 
                      rgba(255, 255, 255, 0), 
                      rgba(255, 255, 255, 0.95) 25%
                  )`,
              paddingTop: "15px",
            }}
            loading={loading || !plans.docs}
            pageSize={pageSize}
            changeSize={changeSize}
            clickNext={clickNext}
            clickPrevious={clickPrevious}
            hasPrevious={hasPrevious}
            hasNext={hasNext}
            activePage={activePage}
            pageCount={pageCount}
          />
        }
      </div>
      <NewPlan
        refetch={refetch}
        educators={educators && educators.docs ? educators.docs : []}
      />
      <EditGroupPlan
        refetch={refetch}
        educators={educators && educators.docs ? educators.docs : baseEducators}
      />
      <PlanModal refetch={refetch} />
      <ConfirmCancelPlan />
      {/* <ViewPlanProgress /> */}
      <ResumePlanModal />
      <SendAllPlansModal
        getStudentById={getStudentById}
        refetchGroup={refetch}
      />
      <SendOnePlanModal getStudentById={getStudentById} />
      <NewGroupPlan
        {...{ services, educators, students, refetch }}
        educatorsBase={
          educators && educators.docs ? educators.docs : baseEducators
        }
      />
      <EditChildPlan
        getServiceById={getServiceById}
        refetch={refetch}
        educators={educators && educators.docs ? educators.docs : baseEducators}
      />
      <ViewGroupPlan
        educatorsBase={
          educators && educators.docs ? educators.docs : baseEducators
        }
        services={services && services.docs ? services.docs : []}
      />

      <CompleteGroupPlanModal />
      <ExcelDownloadComponent
        loadingExcelInfo={loadingExcelInfo}
        setLoadingExcelInfo={setLoadingExcelInfo}
        execDownload={execDownload}
        setExecDownload={setExecDownload}
      />

      <RedirectFromUrl
        onCompleted={({ plan: planInfo }) => {
          dispatch({
            type: "SET_DATA_MODAL_FORM_LIST",
            payload: "plan.seeInfo",
            data: {
              isOpen: true,
              info: planInfo,
              ...{
                thisService: getServiceById(planInfo.serviceId),
                thisTopics: getTopicIdsInPlan(
                  planInfo.parts || planInfo.fixedParts,
                  planInfo.kind
                ),
                queryData: plans,
                queryVar: query,
              },
            },
          });
        }}
      />
      <LivePrepPlans />
    </>
  );
}

export default PlansView
