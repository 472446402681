import React, {useEffect, useMemo} from 'react'
import {
    IconArrowRight,
    Input,
    ListItem
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import RenderInputOption from '../../Components/RenderInputOption'
import usePaymentScheduledReducer from '../../../../../../reducers/payment-scheduled'
import useEditPaymentModal from '../../Components/EditPaymentModal/useEditPaymentModal'
import "./index.css"
import moment from 'moment'
import useNewPlanModal from '../../useNewPlanModal'
import {nanoid} from 'nanoid'

const PaymentSchedule = (props) => {
    const {className, plan, setData} = props
    const {dataPlan} = useNewPlanModal()

    const {
        state: paymentGroup, defaultPayments, createPayment, removePayment, 
        editPayment, distributePayments, resetPayments, 
        setDefaultPayment, formatNumber
    } = usePaymentScheduledReducer(
        parseFloat(
            ((plan?.pricingRate || 0)*(1 - ((plan?.discount || 0)/100) ) || 0)
        ),  dataPlan?.payments/** default payments **/
    )
    
    useEffect(() => {
        if(dataPlan?.payments?.length > 0) {
            const payments = dataPlan.payments
            const signingItems = payments.filter(({type}) => type === "others-fixed") 
            const paymentItems = payments.filter(({type}) => type !== "others-fixed") 
            setDefaultPayment(
                [
                    //at signing
                    signingItems?.length > 0 ? 
                    {
                        atSigning: true, amount: signingItems.reduce((total, {amount}) => total+amount, 0), 
                        date: moment(signingItems[0].date).toISOString(), paymentId: nanoid()
                    } : 
                    {atSigning: true, amount: 0, date: moment().toISOString(), paymentId: nanoid()}
                ]
                .concat(
                    paymentItems.map(
                        (payment) => {return {...payment, paymentId: nanoid()}}
                    )   
                )
            )  
        } else {
            setDefaultPayment([
                //at signing
                {atSigning: true, amount: 0, date: moment().toISOString(), paymentId: nanoid()}
            ])
        }
        // eslint-disable-next-line
    }, [dataPlan])        

    //cada vez que cambian los payments en el reducer
    //se debe actualizar el listado de pagos en el objeto plan
    useEffect(() => {
        setData({payments: {...paymentGroup, payments: defaultPayments}})
        // eslint-disable-next-line
    }, [defaultPayments])

    useEffect(() => {
        //actualiza plan cada vez que cambia reducer
        setData({
            payments: {
                //no active el useEffect de arriba
                ...paymentGroup, payments: paymentGroup?.payments 
            }
        })
        // eslint-disable-next-line
    }, [paymentGroup?.payments])
    


    //`at signing`
    const {open, close, setTotalAmount} = useEditPaymentModal()
    const {totalAmount, totalPlan} = useMemo(() => {
        //recalcula el monto total del plan que luego se divide en cuotas
        const lockAmount = paymentGroup.payments.reduce(
            (total, {amount = 0, isEdit = false}) => {
                if(!isEdit || true) return parseFloat(amount) + parseFloat(total)
                return total
            }, 0
        )
        const discountAmount = (plan?.pricingRate || 0)*((plan?.discount || 0)/100) 
        const totalPlan = (plan.pricingRate || 0) - (discountAmount || 0)
        const totalAmount = totalPlan - lockAmount 
        return {
            //total a distribuir
            totalAmount,
            totalPlan
            // onlyOnePayment: paymentGroup.payments.filter(
            //     ({isEdit = false}) => !isEdit
            // )?.length === 1, 
        }
        // eslint-disable-next-line
    }, [paymentGroup, plan?.pricingRate, plan?.discount])
    


    useEffect(
        () => {
            resetPayments()
            // eslint-disable-next-line
        }, []
    )
    useEffect(() => {
        if(!!plan?.pricingRate) {
            //cada vez que cambia el monto total del plan
            // se reinicializan los pagos
            resetPayments()
        }
        // eslint-disable-next-line
    }, [plan?.pricingRate, plan?.discount])

    useEffect(
        () => {
            //guarda monto total a distribuir
            //, sirve para habilitar o no el botón de envío
            setData({totalToDistributed: totalAmount})
            
            //setea el monto total que puede 
            //llegar a tener el pago
            setTotalAmount(totalAmount, 0) 
            // eslint-disable-next-line
        }, [totalAmount]
    )


    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
        <span className={`h4 font-weight-bold text-title`}>Payment schedule</span>
            <RenderInputOption key={'paymentStart'} label={`Payment start in`}>
                <Input 
                    type={`date`} 
                    name={`paymentStart`} 
                    value={moment(plan?.paymentStart).format('YYYY-MM-DD')}
                    onChange={(e) => {
                        setData({
                            paymentStart: moment(e.target.value).toISOString()
                        })
                    }} 
                />
            </RenderInputOption>
            <div className={`d-flex flex-column pr-3 payment-items-container ${!(paymentGroup?.payments?.length > 0) && false ? 'justify-content-center' : ''}`}>
                <div className={`payment-items-list`}>
                    {
                        paymentGroup?.payments?.length > 0 ?
                        <>
                            {
                                paymentGroup.payments.filter(({atSigning}) => atSigning).map(
                                    (signing) => {
                                        return (
                                            <ListItem
                                                className={`w-100 appears-animation py-2`}
                                                classNameRight={`w-50`}
                                                classNameLeft={`w-50`}
                                                Left={
                                                    <div className={`d-flex flex-row align-items-center`}>
                                                        <span className={'text-title align-self-baseline mr-1'}>
                                                            {`At Signing`}
                                                        </span>
                                                        <span className={'h45 text-gray align-self-baseline'}>
                                                            {``}
                                                        </span>
                                                    </div>
                                                }
                                                Right={
                                                    <div className={`d-flex flex-row align-items-center justify-content-end`}>
                                                        {/* <input className={`w-50`} type={`date`} onChange={(e) => {
                                                            editPayment({...signing, date: moment(e.target.value).hour(12).toISOString(), isEdit: true})
                                                        }} value={moment(signing.date).format(`YYYY-MM-DD`)} /> */}
                                                        {/* <span className={'text-title mr-1'}>
                                                            {`$${formatNumber(parseFloat(0))}`}
                                                        </span> */}
                                                        $ <Input 
                                                            min={0} max={totalAmount} value={signing.amount}
                                                            onChange={
                                                                (e) => {
                                                                    // setSigningAmount(e.target.value)
                                                                    editPayment({...signing, amount: parseFloat(e.target.value), isEdit: true})
                                                                }
                                                            }
                                                            className={'w-50 ml-2'} style={{backgroundColor: 'white' }} 
                                                            type={'number'} disabled={!(totalPlan > 0)} />
                                                        {/* <div className={`hover-icon white`}>
                                                            <IconArrowRight size={15} />
                                                        </div> */}
                                                    </div>
                                                }
                                            />
                                        ) 
                                    }
                                )
                            }
                            {
                                paymentGroup.payments.filter(({atSigning}) => !atSigning).map((payment, index) => {
                                        return (
                                            <ListItem
                                                onClick={() => {
                                                    open(
                                                        (newPayment) => {
                                                            editPayment({...payment, ...newPayment, isEdit: true})
                                                            // distributePayments()
                                                            close()
                                                        },
                                                        () => {
                                                            removePayment(payment, false/**distributed**/)
                                                            close()
                                                        },
                                                        {amount: payment.amount, date: payment.date}
                                                    ) 
                                                }}
                                                className={`w-100 appears-animation py-2`}
                                                Left={
                                                    <div className={`d-flex flex-row align-items-center`}>
                                                        <span className={'text-title align-self-baseline mr-1'}>
                                                            {`${moment(payment?.date).format('MMMM YYYY')}`}
                                                        </span>
                                                        <span className={'h45 text-gray align-self-baseline'}>
                                                            {`(${index+1}th of ${paymentGroup?.payments?.length})`}
                                                        </span>
                                                    </div>
                                                }
                                                Right={
                                                    <div className={`d-flex flex-row align-items-center`}>
                                                        <span className={'text-title mr-1'}>{`$${formatNumber(parseFloat(payment?.amount))}`}</span>
                                                        <div className={`hover-icon white`}>
                                                            <IconArrowRight size={15} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        )
                                })
                            }
                        </>
                        :  null
                        // <RenderInputOption className={`mx-6 text-align-center`} key={'noDefine'} label={`Define an amount on plan breakdown to see the payment schedule.`}/>
                    }
                </div>
                <Collapse isOpen={totalAmount < 0} className={`payment-items-collapse`}>
                    <div className={`d-flex flex-row justify-content-end w-100`}>
                        <span className={`text-error h45`}>Exceed the maximum amount!</span>
                    </div>
                </Collapse>
            </div>
            <span className={`my-2 text-gray h45`}>{`Total to distribute: $${formatNumber(totalAmount)}`}</span>
            
            <span className={`my-2 ${totalAmount === 0 ? 'text-gray' : '' } btn-link`} onClick={() => {
                // if(totalAmount === 0) {
                    open(
                        (newPayment) => {
                            // const {amount, date} = newPayment
                            createPayment(newPayment, false)
                            close()
                        },
                        () => {
                        }, {amount: null, date: null}, 'Add payment item', true
                    )
                // }
                
            }}>Add payment</span>
            <span className={`my-2 btn-link text-error`} onClick={() => {distributePayments(true)}}>Distributed payment</span>
            <span className={`my-2 btn-link text-error`} onClick={() => {resetPayments()}}>Reset payments</span>
        </div>
    )
}

export default PaymentSchedule