import React from 'react'
import DinamicHeightPage from '../../Components/DinamicHeightPage'
import {IconIllustratedTask} from '@Knowledge-OTP/znk-ui-components'
import '../../Components/IconAnimation/index.css'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const WelcomePage = (props) => {
    const { bodyRef, onContinue = () => {} } = props
    const {translate} = useTranslation()
    return (
        <DinamicHeightPage
            bodyRef={bodyRef}
        >
            <div className='icon-animation-rotate'>
                <IconIllustratedTask size={120}/>
            </div>
            <label className="h3 text-center mt-4">                
                {translate('workspace.tasks.assignMultiTask.modal.pages.welcome.text')} <span className='btn-link text-primary' onClick={onContinue}>{translate('workspace.tasks.assignMultiTask.modal.pages.welcome.begin')}</span>
            </label>
        </DinamicHeightPage>
    )
}

export default WelcomePage