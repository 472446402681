import React, {useEffect} from 'react'
import {
    Avatar,
    IconArrowRight,
    // Button,
    // SelectUser
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import { UseAllUsers } from '../../../Plans/modal/newPlan/Model/UseAllUsersLazy'
import AddParentToStudent from '../../../../Model/Users/AddParentToStudent'
import { showAlert } from '../../../../common/Alert/util'
// import { ordenateUserArray } from '../../Utils/ordenateUsersArray'
import useTranslation from '../../../../i18n/useTranslation'

const Childrens = (props) => {
    // eslint-disable-next-line
    const { childrens, page, parentId } = props
    // const [selectUser, setSelectUser] = useState('')
    const {translate} = useTranslation()
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const {submit: addParent, loading: loadMutation} = AddParentToStudent(
        () => {
            showAlert({
                text: translate('profile.actions.editFamilyInfo.linkedParent.successAlert'),
                status: 'success'
            }, dispatch)
        },
        () => {
            showAlert({
                text: translate('profile.actions.editFamilyInfo.linkedParent.errorAlert'),
                status: 'error'
            }, dispatch)
        }
    )
    // eslint-disable-next-line
    const [getStudent, { loading: loadingStudents, data: dataStudents, refetch }] = UseAllUsers()

    // const addStudentFunction = () => {
    //     addParent({
    //         parentUserId: parentId,
    //         userId: selectUser
    //     })
    // }
    useEffect(() => {
        getStudent({
            variables: {
                limit: 0,
                roles: ['STUDENT'],
                sortField: 'firstName'
            }
        })
    }, [getStudent])
    
    return (
        <div className='d-flex flex-column m-0 p-0 pr-6 mt-2'>
            {/* {
                (loading)
                    ? <Skeleton height={190} count={1} className='w-100' />
                    : <> */}
            {childrens?.length > 0 && childrens?.map((child) => {
                return <div className='d-flex flex-row mb-2'>
                    <div className='w-90 d-flex flex-row align-items-center'>
                        <Avatar
                            name={child?.firstName + ' ' + child?.lastName}
                            size='avatar-medium'
                        />
                        <label className='ml-2 my-auto text-info' style={{ color: '#283d57' }}>
                            {`${child?.firstName} ${child?.lastName}`}
                            <span className='text-muted h45 ml-2'>(Student)</span>
                        </label>
                    </div>
                    <div className='m-2'>
                        <IconArrowRight size={20} color='#9da7bb' />
                    </div>
                </div>
            })}
            {/* Para agregar un nuevo estudiante desde esta tab */}
            {/* <span>Link new Student</span>
            {dataStudents?.allUsers?.docs.length > 0 && <SelectUser
                // className={'pr-6'}
                activeUsers={dataStudents?.allUsers?.docs}
                selectUser={selectUser}
                setSelectUser={setSelectUser}
                ordenateUserArray={ordenateUserArray}
            // refetchIn={refetchIn}
            // setRefetchIn={setRefetchIn}
            />}
            <Button
                activity={loadingStudents}
                color='primary'
                disabled={!selectUser}
                className='w-25 d-flex flex-row justify-content-center align-items-center float-right m-1 ml-0'
                label='Submit'
                type='submit'
                onClick={()=> {addStudentFunction()}}
            /> */}
        </div>
    )
}

export default Childrens
