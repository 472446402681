import { IconArrowDown, CheckboxSelector } from '@Knowledge-OTP/znk-ui-components'
import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'
import isEqual from 'lodash/isEqual'

const TopicsFilter = (props) => {
  const { translate } = useTranslation()
  const { topics, setTopicFilters, topicFilters, serviceFilters } = props
  const [servicesCache, setServicesCache] = useState(null)
  if (!isEqual(servicesCache, serviceFilters)) {
    setServicesCache(serviceFilters)
    setTopicFilters([])
  }
  // Get Valid TopicIds Array based on selected services
  const validTopicIds = serviceFilters.reduce((arr, service) => arr.concat(service.topics.map(topic => topic.id)), [])
  return (
    <CheckboxSelector
      setDataFilters={setTopicFilters}
      direction='bottom'
      dataFilters={topicFilters}
      data={topics.docs.filter(topic => validTopicIds.includes(topic.id)).map(item => ({ name: item.name, id: item.id })).sort((a, b) => a.name.localeCompare(b.name))}
      typeFilter='topics'
      strings={{
        viewAll: translate('filters.plans.viewAll'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search')
      }}
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.plans.topics.title')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default TopicsFilter
