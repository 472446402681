import React, { useMemo } from 'react'
import {
  ListItem,
  Field,
  PlainTextInput,
  IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import AddPayment from '../AddPayment'
import getPayerUser from '../../../../../Model/Users/Get/GetPayerUser'
import useTranslation from '../../../../../i18n/useTranslation'

const PaymentSchedule = (props) => {
  const {
    form,
    cuotas,
    InitPayment,
    EditPaymentItem,
    AddPaymentItem,
    DistributedPayment,
    totalPay,
    DeletePaymentItem,
    canReset,
    canDistributed
  } = props
  const canAddPayment = cuotas.length > 0
  const valueWhenPlanApr = Object.keys(form.payments.values).reduce(
    (accu, payKey) => {
      if (form.payments[payKey]) {
        return accu + (form.payments.values[payKey] - form.payments.values[payKey] * (form.payments.discount[payKey] / 100))
      } else {
        return accu
      }
    }, 0
  )
  const { translate } = useTranslation()
  const lastPay = useMemo(() => cuotas ? cuotas[cuotas.length - 1] ? cuotas[cuotas.length - 1].time : moment() : moment(), [cuotas])
  // colocar id del usuario (estudiante o padre del estudiante) para obtener el metodo de pago
  const { data: dataPaymentMethod } = getPayerUser({ userId: form.student ? form.student.id : '' })
  const discountToFee = form.payments.discount.lessons > 0 ? (1 - (form.payments.discount.lessons / 100)) : 1
  return (
    <>
      <div className='pl-0 pr-6 py-6 pl-3'>
        <h3><b>{translate('modal.newplan.paymentschedule.title')}</b></h3>
        <div className='row pl-3'>
          <label className='text-muted mb-2 mt-3 pl-0'>
            {
              `${translate('modal.newplan.paymentschedule.paymentstart')} 
              (${
                (form?.paymentstart || form?.lpw[0].startdate)
                ? translate('profile.payments.endOf', moment(form?.paymentstart || form?.lpw[0].startdate).format('MMMM'))
                : translate('modal.newplan.paymentschedule.selectdate')
              })`
            }
          </label>
          <Field
            type={PlainTextInput}
            fieldName='paymentstart'
            fieldType='date'
            InputClassName='col-12'
            value={form?.paymentstart || form?.lpw[0].startdate}
          />
          <ListItem
            className='col-12 pr-1 pl-0 my-2 py-2'
            Left={
              <label className='text-muted h45'>
                {translate('modal.newplan.paymentschedule.paymentmethod')}
              </label>
            }
            Right={
              <label className='text-muted h45'>
                {dataPaymentMethod && dataPaymentMethod.paymentMethod
                  ? `${dataPaymentMethod.paymentMethod.brand} ${dataPaymentMethod.paymentMethod.last4}`
                  : translate('modal.newplan.paymentschedule.nodefined')}
              </label>
            }
          />
          <div className='w-100'>
            <ListItem
              className='py-2 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label>{translate('modal.newplan.paymentschedule.whenapproving')}</label>
              }
              Right={
                <label>{`$${valueWhenPlanApr.toFixed(2)}`}<IconArrowRight /></label>
              }
            />
            {cuotas.length > 0 ? cuotas.map((obj, index) => {
              return (
                <ListItem
                  key={index}
                  className='py-2 w-100'
                  showBottomLine={false}
                  showTopLine={false}
                  Left={
                    <label>
                      <span>
                        {translate('modal.newplan.endof') + ' ' + moment(obj.time).format('MMM')}
                      </span>
                      <span className='text-muted h45'>
                        {`    (${translate('modal.newplan.indexFee', {
                          num1: parseInt(index + 1), num2: parseInt(cuotas.length)
                        })})`}
                      </span>
                    </label>
                  }
                  Right={
                    <AddPayment value={obj.value} customPayment={false} deleteCuota={DeletePaymentItem(index)} setCuotas={EditPaymentItem(index, form.payments.discount.lessons / 100)} maxValue={totalPay * discountToFee} time={moment(obj.time)}>
                      <label>{`$${(form.payments.lessons ? obj.value * discountToFee : 0).toFixed(2)}`} <IconArrowRight /></label>
                    </AddPayment>
                  }
                />
              )
            }) : <label>{translate('modal.newplan.paymentschedule.selectlimitdates')}</label>}
            <ListItem
              key={-1}
              className='py-2 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label>{'Total: '}</label>
              }
              Right={
                <label>{`$${((totalPay * discountToFee) + valueWhenPlanApr).toFixed(2)}`}</label>
              }
            />
          </div>
          <div className='m-0 p-0 d-flex flex-column'>
            <label
              className={`w-100 ${canAddPayment ? 'btn-link' : 'text-muted'}`} onClick={() => {
                if (canAddPayment) {
                  AddPaymentItem({ time: moment(lastPay).add(1, 'month'), value: 0, custom: false })
                }
              }}
            >
              {translate('modal.newplan.paymentschedule.addmore')}
            </label>
            <label className={`w-100 ${canDistributed ? 'btn-link text-error' : 'text-muted'}`} onClick={canDistributed ? DistributedPayment : null}>
              {translate('modal.newplan.paymentschedule.distribute')}
            </label>
            <label className={`w-100 ${canReset ? 'btn-link text-error' : 'text-muted'}`} onClick={canReset ? InitPayment : null}>
              {translate('modal.newplan.paymentschedule.reset')}
            </label>
          </div>
        </div>
      </div>
      <div className='borderTop ml-3' />
    </>

  )
}

PaymentSchedule.propTypes = {
}

PaymentSchedule.defaultProps = {
}
export default PaymentSchedule
