import {useSelector, useDispatch} from 'react-redux'

const useModalEmailsList = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.emailsList)
    const {isOpen, onChange, query, type} = reduxData
    const setOpen = (open, onChange = () => {}, type, query) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'emailsList',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                query: {...query, limit: 0},
                type,
            }
        })
    }

    const setQueryForMailslist = (query) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'emailsList',
            data: {
                ...reduxData,
                query,
            }
        })
    }

    return {
        isOpen,
        onChange,
        open: (onChange = () => {}, type, query) => {
            setOpen(true, onChange, type, query)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
        query,
        type,
        setQueryForMailslist,
    }

}

export default useModalEmailsList