import {useReducer} from 'react'
import {useSelector} from 'react-redux'
import reducer from './reducer'
import {initialState} from './initialState'
import moment from 'moment'

const useTimelineReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const services = useSelector((state) => state.user.services)
    const actionData = (
        data, 
        key, //[phase, step, task] 
        action //[add, remove]
        ) => {
        dispatch({
            type: `${action}-${key}`,
            data: data,
        })
        
    }

     //Recibe indice de la parte y formulario de plan y retorna cantidad total de lecciones
     const TotalLessonPart = (lpwInd, form) => {
        let count = 0
        if (form.lpw) {
          if (form.typePlan === 'time' && form.typeLesson === 'totalLesson') {
            Object.keys(form.lpw[lpwInd]).filter(
              (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
            ).forEach((idTopic) => {
              count += form.lpw[lpwInd][idTopic].value
            })
            return count
          } else {
            let weeks = 1
            if (form.lpw[lpwInd].startdate && form.lpw[lpwInd].enddate) {
              const partFrom = moment(form.lpw[lpwInd].startdate)
              const partTo = moment(form.lpw[lpwInd].enddate)
              // si una semana tiene menos de tres dias no contarla como una semana valida
              weeks = (partFrom.diff(partTo, 'week') * -1) + 1
              if (partTo.day() < partFrom.day() && partTo.day() !== 0 && weeks >= 2) {
                // en este caso diff cuenta una semana menos
                weeks += 1
              }
              if (partFrom.day() > 3 && partFrom.day() !== 0 && weeks >= 2) {
                weeks -= 1
              }
              if (partTo.day() < 3 && partTo.day() !== 0 && weeks >= 2) {
                weeks -= 1
              }
            }
            Object.keys(form.lpw[lpwInd]).filter(
              (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
            ).forEach((idTopic) => {
              count += form.lpw[lpwInd][idTopic].value * weeks
            })
            return count
          }
        }
        return count
    }
    const parseTimelinePlanFromAPI = (event) => {
        const thisService = services.find(({id}) => id === event?.serviceId)
        const newParts = event?.parts?.reduce(
            (parseParts, part, index) => {
                // lessonsPerWeek
                // totalLessons
                // lessonPerWeekPerTopic {
                    // topicId
                    // lessonsPerWeek
                    // totalLessons
                    // lockLessons
                // }
                // from
                // to
                // name
                let parsePart = {
                    partname: part?.name,
                    startdate: moment(part?.from).utc().format('YYYY-MM-DD'),
                    enddate: moment(part?.to).utc().format('YYYY-MM-DD'),
                }
                //puebla la parte con la cantidad de lecciones por semana (según tópico)
                for(let i=0; i < part.lessonPerWeekPerTopic?.length; i++){
                    const topicPerWeek = part.lessonPerWeekPerTopic[i]
                    parsePart[topicPerWeek?.topicId] = {
                        value: topicPerWeek?.lessonsPerWeek || 0
                    }
                }
                // part.lessonPerWeekPerTopic.forEach((topicPerWeek) => {
                //     parsePart[topicPerWeek?.topicId] = {
                //         value: topicPerWeek?.lessonsPerWeek || 0
                //     }
                // })
                return {
                    ...parseParts,
                    [index]: parsePart
                }
            }, {}
        )
        return {
            ...event,
            cuotas: [],
            idservice: event?.serviceId,
            pricingRate: event?.pricingRate,
            typePlanPricing: event?.typePlanPricing,
            planId: event?.id,
            lpw: newParts,
            statusPlan: 'draft',
            planname: event?.name,
            payments: {
              registration: false,
              exam: false,
              pro: false,
              lessons: false,
              discount: {
                registration: 0,
                exam: 0,
                pro: 0,
                lessons: 0
              },
              values: {
                registration: 200,
                exam: 50,
                pro: 50
              }
            },
            type: 'plan',
            statusBuild: 'normal',
            // testdate
            testdate: {
                name: `${thisService?.name} - ${moment(event?.testDate).format('MMM Do, YYYY')}`,
                date: event?.testDate
            },
            // testDateId: ''
            comment: event?.comment || null
        }
    }
    return {
        state, 
        dispatch,
        initialState,
        
        setData: () => {
            actionData({}, 'data', 'set')
        },

        parseTimelinePlanFromAPI,
        parseTimelineFromAPI: (timeline = null) => {
            const arrayEvents = timeline ? Object.entries(timeline).reduce((array, [key, events]) => {
                if(key === 'plans') {
                    return array.concat(events.filter(({deleteAt}) => deleteAt === null).map((event) => {
                        const parsePlan = parseTimelinePlanFromAPI(event)
                        return parsePlan
                    }))     
                }
                else if(key === 'events') {
                    return array.concat(events.map((event) => {
                        return {
                            ...event,
                            eventId: event?.id,
                            date: moment(event.startDate).utc().format(`YYYY-MM-DD`),
                            toDefine: event.tbd,
                            name: event.name,
                            description: event?.description,
                            isEdit: false,
                            type: 'event',
                            statusBuild: 'normal',
                            comment: event?.description || event?.comment || null
                        }
                    }))
                    
                }
                return array
            }, []) : []
            return arrayEvents?.length > 0 ? arrayEvents : null
        },

        parseTimelineToMutation: (timeline, studentUser) => {
            //parse to TimelineInput 
            const timelineInput = {
                timelinePlans: timeline?.filter(({type}) => type === 'plan')?.map( 
                    (plan) => {
                        const includePlanId = {} // planId: plan?.planId,
                        if(plan?.statusBuild !== 'new') { //solo incluye el planId cuando el plan no es nuevo
                            includePlanId.planId = plan?.planId
                        }
                        // name: String!
                        // serviceId: ID!
                        // typePlanPricing: TypePlanPricing!
                        // parts: [LessonsPerWeekPlanPartInput!]!
                        // statusBuild: StatusBuild
                        return {
                            statusBuild: plan?.statusBuild || 'new',
                            name: plan?.planname,
                            serviceId: plan.idservice,
                            typePlanPricing: plan.typePlanPricing,
                            studentUser: {
                                studentUserId: studentUser?.id || studentUser?.studentUserId,
                                rate: studentUser?.rate
                            },
                            // pricingRate: ,
                            pricingRate: plan?.pricingRate,
                            parts: Object.values(plan?.lpw || {}).map((part, index) => {
                                const {startdate, enddate, partname, ...partTopic} = part
                                return {
                                    lessonPerWeekPerTopic: Object.entries(partTopic).map(([topicId, {value, lock}]) => {
                                        return {
                                            topicId,
                                            lessonsPerWeek: value,
                                            lockLessons: lock || false
                                        }
                                    }),
                                    lessonsPerWeek: Object.values(partTopic).reduce((sum, {value}) => {
                                        return parseInt(value) + sum 
                                    }, 0),
                                    totalLessons: TotalLessonPart(index, plan) || 0,
                                    from: startdate && new Date(startdate),
                                    to: enddate && new Date(enddate),
                                    name: partname
                                }
                            }),
                            //testdate
                            testDate: plan?.testdate?.date ? new Date(plan?.testdate?.date) : null,
                            statusPlan: plan?.statusPlan || 'draft',
                            comment: plan?.comment || null,
                            ...includePlanId
                            // testDateId: ''

                            // [
                                // lessonPerWeekPerTopic: [
                                    // topicId: ID!
                                    // lessonsPerWeek: Int!
                                    // lockLessons: Boolean!
                                // ]
                                // lessonsPerWeek: Int!
                                // totalLessons: Int!
                                // from: DateTime!
                                // to: DateTime!
                                // name: String!
                            // ]
                        }
                    }
                ),
                activities: timeline?.filter(({type}) => ['test', 'event'].includes(type))?.map(
                    (event) => {
                        return {
                            // activityId: ID!
                            // type: EventEnum!
                            // statusBuild: StatusBuild!
                            activityId: event?.eventId,
                            statusBuild: event?.statusBuild || 'new',
                            type: 'event'
                        }
                    }
                )
            }
            return timelineInput
        }
    }
}

export default useTimelineReducer