import React from 'react'
import ServiceSelector from './Service'
import TopicTeachingType from './Service/TopicTeachingType'

const EducatorServiceConfig = (props) => {
  const { educatorInfo, id, onChange } = props
  const services = educatorInfo ? educatorInfo.services : []
  return (
    <div className='d-flex flex-column'>
      <ServiceSelector userId={id} services={services} onChange={onChange} />
      <div className='d-flex flex-column'>
        {
          services.map((educatorService) => {
            return (<span key={educatorService.serviceId} className='d-flex flex-column pl-4'>
              <span className='d-flex justify-content-between'>
                <span>
                  {educatorService.name}
                </span>
                <span>
                  {educatorService.defaultRate}
                </span>
              </span>
              <span className='d-flex flex-column pl-4'>{educatorService.topics.map((topic) => {
                return (
                  <TopicTeachingType
                    topic={topic}
                    serviceId={educatorService.serviceId}
                    userId={id}
                    onChange={onChange}
                    key={`${educatorService.serviceId}${topic.topicId}`}
                  />
                )
              })}
              </span>
            </span>)
          })
        }
      </div>
    </div>
  )
}

export default EducatorServiceConfig
