import {useLazyQuery, gql} from '@apollo/client'

const useStudentWithoutLessonsLazy = () => {
    return useLazyQuery(
        gql`
            query (
                $limit: Float
                $skip: Float
            ) {
                studentWithoutLessons (
                    limit: $limit
                    skip: $skip
                ) {
                    isActive
                    firstName
                    lastName
                    userId
                    lastLesson {
                        endDate
                    }
                }
            }
        `,
    )
}

export default useStudentWithoutLessonsLazy