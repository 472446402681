import React, { useState, Fragment, useEffect } from 'react'
import {
  Modal,
  IconClose,
  Field,
  SelectOption,
  Form,
  InputSelect,
  PlainTextInput,
  StickyButton,
  Button,
  AmountInput,
  LabeledField
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../i18n/useTranslation'
import SelectEmployee from '../Components/SelectEmployee'
import createManualPayslipItem from '../../../Model/Payments/Payslips/NewItem'
import { isEmpty } from 'validate.js'
import moment from 'moment'
import {escapeRegExp} from 'lodash'

const NewPaymentSlip = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.newPaymentSlip)
  const { isOpen } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [types] = useState([
    { id: 'lessonsPayment', name: translate('filters.payslips.lessonsPayment') },
    { id: 'reimbursement', name: translate('filters.payslips.reimbursement') },
    { id: 'content', name: translate('filters.payslips.content') },
    { id: 'training', name: translate('filters.payslips.training') }
  ])
  const [errors, setErrors] = useState({})
  const [form, setForm] = useState({
    student: null,
    chargeType: 'educatorWage',
    category: 'livePrep',
    type: 'lessonsPayment',
    amount: 0,
    percentDiscount: 0,
    date: new Date(),
    description: ''
  })
  const toggle = () => {
    setErrors({})
    setForm({
      student: null,
      chargeType: 'educatorWage',
      type: 'lessonsPayment',
      category: 'livePrep',
      amount: 0,
      percentDiscount: 0,
      date: moment().format('DD-MM-YYYY'),
      description: ''
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.newPaymentSlip',
      data: {
        isOpen: false
      }
    })
  }
  const { submit, loading, validationErrors } = createManualPayslipItem(() => {
    refetch()
    toggle()
  }, console.log)
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key.split('.')[1]
        customError[keyVal] = translate('payslip.actions.newPayslip.errors.' + keyVal)
      })
      setErrors(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])
  const onSubmit = () => {
    const newForm = { ...form }
    newForm.userId = form.student ? form.student.id : ''
    delete newForm.student
    submit({ input: newForm })
  }
  return (
    <Fragment>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>New Payslip Item</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={form} onChange={setForm} validationErrors={errors}>
          <div className='m-0 p-0 mb-2 pl-2 pr-2'>
            <hr className='m-0 p-0' />
            <SelectEmployee form={form} setForm={setForm} first={false} title={false} />
            {!isEmpty(errors) && errors.userId && <h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.userId')}</h5>}
            <hr className='m-0 p-0' />
            <div className='row d-flex m-0 p-0'>
              <div className='col-12 p-0'>
                <Field
                  className='col-12 row inline mt-4'
                  type={SelectOption}
                  fieldName='chargeType'
                  options={[
                    { id: 'educatorWage', text: 'Payment' },
                    { id: 'educatorPenalization', text: 'Penalty' }
                  ]}
                  studentCharge
                  StyleOptionClassName={{
                    width: '49%',
                    whiteSpace: 'nowrap'
                  }}
                  StyleSelect={{
                    justifyContent: 'space-between'
                  }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Type</label>
                <Field
                  className='col-12'
                  component={InputSelect}
                  type={LabeledField}
                  fieldName='type'
                  placeholder={translate('modal.newplan.selectservice')}
                  options={types && [].concat(types.map((serv) => ({ value: serv.id, label: serv.name })))}
                  InputClassName='w-100'
            filterOption={(obj, string) => {
              if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
              return false
            }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 mt-2 p-0'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Amount</label>
                <Field
                  className='col-12 placeholder-to-right'
                  component={AmountInput}
                  type={LabeledField}
                  fieldName='amount'
                  fieldType='number'
                  placeholder='0.00'
                  addOn='$'
                  min='0'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Description</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  placeholder='Description'
                  fieldName='description'
                  fieldType='text'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Payment date</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='date'
                  fieldType='date'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-3'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loading}
                      className='col-12 aling-text-left'
                      label={translate('payments.newinvoice.addnewitem')}
                      onClick={() => { onSubmit() }}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </Fragment>
  )
}

export default NewPaymentSlip
