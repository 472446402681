import {gql, useQuery} from '@apollo/client'
import taskQueryResult from '../../../../../../University/Model/queryResults/useTaskResult'

export default (vars) => {
  // Sirve para traer todas las tasks de un alumno.
  // Pero esta misma query la podemos usar para traer las tasks de una universidad,
  // solo las de un cierto estado, etc.
  const {data, loading, refetch} = useQuery(
    gql`query(
                $universityId: ID
                $status: [StatusTask!]
                $planId: ID!
                  )
                  {
            getUserTasks(universitieId: $universityId
                         status: $status
                         planId: $planId
        ) {
            ${taskQueryResult}
        }
    }`,
    {
      variables: vars,
    },
  )
  return {
    loading,
    data: data ? data.getUserTasks : [],
    refetch,
  }
}
