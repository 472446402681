import {useQuery, gql} from '@apollo/client'

const useGetLessonsForGroupPlan = (vars = {}, options = {fetchPolicy: 'cache-first'}) => {
    return useQuery(
        gql`query (
            $groupPlanId: String!
            $status: [LessonStatus!]!
        ) {
            getLessonsForGroupPlan (
                groupPlanId: $groupPlanId
                status: $status
            ) {
                id
                startDate
                endDate
            }
        }`,
        {
            variables: vars,
            options
        }
    )
}

export default useGetLessonsForGroupPlan