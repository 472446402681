import { gql, useLazyQuery } from '@apollo/client'
import validate from './validation'
import { useEffect } from 'react'

export default (vars) => {
  const [getPayerUser, { data, error, loading }] = useLazyQuery(
    gql`query ($userId: String!) {
        getPayerUser(
            userId: $userId
        ){
            gender
            emails {
              address
            }
            firstName
            lastName
            paymentMethod {
              kind
              ... on ManualPaymentMethod {
                  kind
              }
              ... on CardPaymentMethod{
                  kind
                  status
                  last4
                  brand
                  expMonth
                  expYear
              }
              ... on ZellePaymentMethod {
                kind
                zellePaymentInfo {
                  emailToSend
                }
              }
            }
        }
    }`,
    {
      fetchPolicy: 'cache-and-network'
    }
  )
  useEffect(() => {
    if (vars.userId && vars.userId !== '') {
      getPayerUser({
        variables: {
          userId: vars.userId
        }
      })
    }
  }, [vars.userId, getPayerUser])
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return { loading: false, error: errors, data: {} }
  }
  return { loading, data: data ? data.getPayerUser : {}, error }
}
