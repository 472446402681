import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import FirebaseContext from '../../contexts/FirebaseContext'
import { LoadingPage } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../i18n/useTranslation'
import AuthContext from '../../contexts/AuthContext'
import LoginPage from "../../Pages/Login";

const propTypes = {
  children: PropTypes.node
}
const ForceLogin = props => {
  const firebase = useContext(FirebaseContext)
  const logged = useContext(AuthContext)
  const [cookie, , removeCookie] = useCookies(['znk-signup'])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    async function getUserAsync(signinToken) {
      if (!firebase) {
        console.log('firebase not set yet')
        return
      }
      if(!logged) {
        if (!signinToken) {
          console.log('cookie not set')
          setLoading(false)
          return
        }
        try {
          console.log('awaiting signin')
          await firebase.auth().signInWithCustomToken(signinToken)
        } catch (e) {
          console.log(`firebase error: ${e.code}`)
          console.log(e.message)
          if (e.code === 'auth/invalid-custom-token') {
            console.log('removing cookie')
            removeCookie('znk-signup')
          }
        }
      } else {
        console.log('already logged')
      }
      setLoading(false)
    }
    getUserAsync(cookie['znk-signup'])
  }, [cookie, firebase, logged, removeCookie])
  const {translate} = useTranslation()
  return loading ? (
    <LoadingPage text={translate('loading.authenticating')} />
  ) : logged ? (
    props.children
  ) : (
    <LoginPage />
  )

}
ForceLogin.propTypes = propTypes
export default ForceLogin
