import React, {useState, Fragment} from 'react'
import {IconClose, DropDown} from '@Knowledge-OTP/znk-ui-components'
import LessonBloc from '../LessonBloc/index'
import moment from 'moment'

const LessonBlocGroup = (props) => {
  const {
    lessons,
    widthBlocPx,
    heightBlocMinutePx,
    from,
    selectedBlocks,
    selectBlockInCalendar,
    deselectBlockInCalendar,
    isDraggingOver,
    blockKey
  } = props
  // type, status, title, subtitle
  const [isOpenDropDown, setIsOpenDropDown] = useState(false)
  const toggleDropDown = () => {
    setIsOpenDropDown(!isOpenDropDown)
  }
  return (
    <Fragment>
      <div className='m-0 p-0 d-flex flex-row-reverse'>
        {lessons.map((lesson, index) => {
          if (index === 0) {
            return (
              <LessonBloc
                durationMin={lesson.durationMin}
                minutes={lesson.minutes}
                widthBlocPx={widthBlocPx - 6}
                heightBlocMinutePx={heightBlocMinutePx}
                type={lesson.type}
                status={lesson.status}
                title={lesson.title}
                subtitle={lesson.subtitle}
                onClick={lesson.onClickLesson}
                selected={selectedBlocks.indexOf(lesson.idLesson) !== -1}
                idLesson={lesson.idLesson}
                canChecked={lesson.canChecked}
                icon={lesson.icon}
                selectBlockInCalendar={selectBlockInCalendar}
                deselectBlockInCalendar={deselectBlockInCalendar}
                showCheckBoxOnLeft
                activity={lesson.loading}
                isDraggingOver={isDraggingOver}
                idEducator={lesson.idEducator}
                idStudents={lesson.student.map((stu) => stu.studentUserId || stu.id)}
                blockKey={blockKey}
                from={from}
              />
            )
          } else if (index === 1) {
            return (
              <LessonBloc
                className='position-absolute'
                durationMin={lesson.durationMin}
                minutes={lesson.minutes}
                heightBlocMinutePx={heightBlocMinutePx}
                widthBlocPx={(widthBlocPx - 6) / 2}
                type={lesson.type}
                status={lesson.status}
                title={lesson.title}
                subtitle={lesson.subtitle}
                onClick={lesson.onClickLesson}
                selected={selectedBlocks.indexOf(lesson.idLesson) !== -1}
                idLesson={lesson.idLesson}
                canChecked={lesson.canChecked}
                icon={null}
                selectBlockInCalendar={selectBlockInCalendar}
                deselectBlockInCalendar={deselectBlockInCalendar}
                isDraggingOver={isDraggingOver}
                idEducator={lesson.idEducator}
                idStudents={lesson.student.map((stu) => stu.studentUserId || stu.id)}
                blockKey={blockKey}
                from={from}
              />
            )
          } else if (index === 2) {
            return (
              <div
                onClick={() => {
                  toggleDropDown()
                }}
                style={{
                  minWidth: `${(widthBlocPx - 6) / 4}px`,
                  width: `${(widthBlocPx - 6) / 4}px`,
                  minHeight: `${heightBlocMinutePx * lesson.durationMin}px`,
                  height: `${heightBlocMinutePx * lesson.durationMin}px`,
                }}
                className={`d-flex flex-column justify-content-center position-absolute d-flex flex-column znk-bloc-lesson znk-bloc-lesson-${
                  isOpenDropDown ? 'active' : 'pending'
                } znk-bloc-lesson-size-${lesson.type}`}
              >
                <span className='h45 title align-self-center'>{`+${
                  lessons.length - 2
                }`}</span>
              </div>
            )
          } else return null
        })}
      </div>
      {lessons.length > 2 && (
        <DropDown
          isOpen={isOpenDropDown}
          hoverEnabled={false}
          fixedWidth
          className='m-0 p-0'
          direction='right'
          closeMenuWhenClick={false}
          options={[
            {
              component: (
                <div className='modal-centered m-0 p-0'>
                  <div className='modal-content m-0 p-0'>
                    <div
                      className='d-flex flex-row modal-header pt-5 px-5 pb-2'
                      style={{width: '276px'}}
                    >
                      <span className='h4 font-title pr-3'>
                        {`Lessons on ${moment(from).format(
                          'dddd DD',
                        )} around ${moment(from).format('hh a')}`}
                      </span>
                      <IconClose
                        onClick={() => {
                          setIsOpenDropDown(false)
                        }}
                      />
                    </div>
                  </div>
                </div>
              ),
            },
            {
              component: (
                <div
                  className='px-5 py-4 m-0 d-flex flex-column'
                  style={{overflowY: 'scroll', maxHeight: '280px'}}
                >
                  {lessons.map((lesson) => {
                    return (
                      <LessonBloc
                        key={lesson.id}
                        durationMin={1}
                        heightBlocMinutePx={56}
                        widthBlocPx={228}
                        type={lesson.type}
                        status={lesson.status}
                        title={lesson.title}
                        subtitle={lesson.subtitle}
                        onClick={lesson.onClickLesson}
                        selected={
                          selectedBlocks.indexOf(lesson.idLesson) !== -1
                        }
                        idLesson={lesson.idLesson}
                        canChecked={lesson.canChecked}
                        icon={null}
                        selectBlockInCalendar={selectBlockInCalendar}
                        deselectBlockInCalendar={deselectBlockInCalendar}
                        idEducator={lesson.idEducator}
                        idStudents={lesson.student.map((stu) => stu.studentUserId || stu.id)}
                        blockKey={blockKey}
                        from={from}
                      />
                    )
                  })}
                </div>
              ),
            },
          ]}
        >
          {null}
        </DropDown>
      )}
    </Fragment>
  )
}

export default LessonBlocGroup
