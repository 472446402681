import React, { useEffect } from 'react'
import { Button, ModalLayout } from '@Knowledge-OTP/znk-ui-components'
import useCompleteAllPlans from './Model/useCompleteAllPlans'
import { showAlert } from '../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../i18n/useTranslation'
import useCompletaAllPlans from './useCompleteAllPlans'
const LivePrepPlans = () => {
  const {
    isOpen,
    toggle,
  } = useCompletaAllPlans()
  const { translate } = useTranslation()

  const dispatch = useDispatch()
  const [submit, { loading }] = useCompleteAllPlans(
    () => {
      showAlert({
        text: translate("plans.modal.completeplan.onSuccessAllPlans") || 'Plan completed successfully!',
        status: 'success'
      }, dispatch)
    },
    (err) => {
      showAlert({
        text: translate(err?.message?.toString() || "plans.modal.completeplan.onErrorAllPlans"),
        status: 'error'
      }, dispatch)
      console.error(err)
    }
  )


  useEffect(() => {

    // eslint-disable-next-line
  }, [])


  return (
    <ModalLayout
      className={`modal-centered modal-colored h-auto`}
      isOpen={isOpen}
      toggle={toggle}
      topHead={
        <div className={`m-0 p-0 mt-4 mb-3 d-flex flex-column`}>
          <div className={`d-flex flex-row align-items-center mb-5`}>
            <span className={`h2 text-title m-0`}>{translate("plans.modal.completeplan.head")}</span>
          </div>
        </div>
      }
      underHead={<span className={`h4 m-0`}>{translate("plans.modal.completeplan.underHeadead")}</span>}
    >
      <div className='d-flex flex-column align-items-center'>
        <Button
          color='primary'
          activity={loading}
          className='col-6 mt-2'
          label={'Complete All Plans'}
          onClick={() => { submit() }}
          style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
        />

      </div>
    </ModalLayout>

  )
}

export default LivePrepPlans
