import React, {useState} from 'react'
import { useQuery, gql } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { CHANGE_TIMEZONE } from '../../../store/timezone/const'
import { SET_USER_ACTION } from '../../../store/user/const'
import { LoadingPage } from '@Knowledge-OTP/znk-ui-components'
import {useCookies} from 'react-cookie'
import {showAlert} from '../../../common/Alert/util'
import useTranslation from '../../../i18n/useTranslation'
import firebase from 'firebase/app'
import 'firebase/auth'
import { ordenateArrayByName } from '../../../utils/ordenateArrayByName'

const TimezoneProvider = ({ children }) => {
  const [, , removeCookie] = useCookies(['znk-signup'])
  const {translate} = useTranslation()
  const {data, loading, refetch} = useQuery(gql`query {
      me {
          id
          timezone
          roles
          firstName
          lastName
          avatar
          isActive
          staffInfo {
            permissions {
              tag
              name
              isPermited
              subpermissions {
                tag
                name
                isPermited
              }
            }
          }
      }
      paginatedServices(limit: 0) {
        docs {
          id
          name
          topics {
            id
            name
          }
          createdAt
          updatedAt
          isOrientation
        }
      }
  }`)
  const dispatch = useDispatch()
  const info = data?.me ?? null
  const services = ordenateArrayByName(data?.paginatedServices?.docs) ?? null
  const [unlogged, setUnlogged] = useState(false)
  if(info && !info?.isActive && !unlogged) {
    //cierra la sesion del usuario
    setUnlogged(true)
    showAlert({
      text: 'Your user is disabled!' || translate('login.error.adminInactive'),
      status: 'warning'
    }, dispatch)
    removeCookie('znk-signup')
    firebase.auth().signOut()
  }
  if(info) {
    dispatch({type: CHANGE_TIMEZONE, payload: info.timezone})
    dispatch({type: SET_USER_ACTION, payload: {...info, firstName: info?.firstName, lastName: info?.lastName, refetch: refetch}})
  }
  if(services?.length > 0){
    dispatch({type: SET_USER_ACTION, payload: {services}})
  }
  return loading || (!info && !info?.roles.includes('SUPER_ADMIN'))? <LoadingPage text='loading.configuringData'/> : children
}

export default TimezoneProvider
