import { useSelector, useDispatch } from 'react-redux'
import { SUBMITSCOREMODE } from '.'
import { FORMODEL } from './useHandlerExamForm'

const useSubmitScoreModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector(
        (state) => state.modal.counseling.submitScoreModal,
    )
    const { loading } = useSelector(
        (state) => state.modal.counseling.submitScoreModalLoading,
    )
    const { isOpen, onChange } = reduxData

    const setOpen = (open, onChange = () => {}, examScoreId, exam = {}, mode = SUBMITSCOREMODE.create, showPlansToAdd) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.submitScoreModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                exam,
                examScoreId,
                mode,
                showPlansToAdd,
            },
        })
    }

    const setLoading = (load = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.submitScoreModalLoading',
            data: {
                loading: load
            },
        })
    }

    return {
        ...reduxData,
        isOpen,
        onChange,
        setLoading,
        open: (onChange = () => {}, exam = {}) => {
            setOpen(true, onChange, exam)
        },
        openForCreate: (onChange = () => {}, exam = {}, examScoreId, showPlansToAdd, planId) => {
            // setOpen(true, onChange, examScoreId, null, SUBMITSCOREMODE.create)
            setOpen(true, onChange, exam, examScoreId, SUBMITSCOREMODE.create, showPlansToAdd, planId)

        },
        openForEdit: (onChange = () => {}, exam = {}, showPlansToAdd) => {
            console.log('exam', exam)
            setOpen(true, onChange, null, exam, SUBMITSCOREMODE.edit, showPlansToAdd)
        },
        openForView: (onChange = () => {}, exam = {}, showPlansToAdd) => {
            // setOpen(true, onChange, null, exam, SUBMITSCOREMODE.view, showPlansToAdd)
            setOpen(true, onChange, null, exam, SUBMITSCOREMODE.view, showPlansToAdd)
        },
        //DEPRECATED
        newOpen: (onChange = () => { }, examScoreId = null) => {
            setOpen(true, onChange, {}, true, false, FORMODEL.task, examScoreId)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
        loading
    }
}

export default useSubmitScoreModal