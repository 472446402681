import {useMutation, gql} from '@apollo/client'

const useSetZellePaymentMethod = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation setZellePaymentMethod($userId: String) {
        setZellePaymentMethod(userId: $userId) {
          paymentMethod {
            ... on ZellePaymentMethod {
              kind
              zellePaymentInfo {
                emailToSend
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: onSuccess,
      onError,
    },
  )
}

export default useSetZellePaymentMethod
