import React from 'react'
import {useQuery, gql} from '@apollo/client'

const PlanLessonPayment = () => {
    const {data} = useQuery(
        gql`
            query {
                getPlanLessonsWithPaymentItems {
                    id
                }    
            }
        `
    )
    return (
        <div className={'w-100 m-0 p-0 d-flex flex-column'}>
            <span>** {data?.getPlanLessonsWithPaymentItems?.length || 0} **</span>
            <ul>
                {
                    data?.getPlanLessonsWithPaymentItems?.map(({id}) => {
                        return (
                            <li>{`${id}`}</li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default PlanLessonPayment