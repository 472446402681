import taskQueryResult from '../../../University/Model/queryResults/useTaskResult'

const phasesQueryResult = `
    phaseName
    steps {
        id
        stepName
        idReference
        deleteAt
        tasks {
            id
            name
            idReference
            instructions
            type
            dueTime
            buttonLabel
            linkToShow
            isEspecific
            isReviewable
            status
            userId
            deleteAt
            showInDocs
            uploadDocs
            userMeetingId
            chatNotification {
                councelor
                student
            }
            accountsPlatform
            startDate
            dueDate
            reviewDate
            completedDate
            canceledDate
            ## serviceDate
            examId
            selectedExam {
                id: _id
                name
                results {
                    name
                    scale
                    values {
                        name
                        scale
                    }
                }
                serviceId
            }
            universities
            resolveTask {
                link {
                    lastProcessedAt
                    accessedCount
                }
                loginInfo {
                    platform{
                        name
                        username
                        password
                    }
                }
                score {
                    name
                    examDate
                    overall
                    results { 
                        name
                        scale
                        value
                        values {
                            name
                            scale
                            value
                        }
                    }
                }
                meeting
                uploadFiles {
                    url
                    uploadDate
                }
                completion
            }
        }
    }
`

//String con modelo de objeto que será utilizado para 
//obtener UN counseling plan.
const planQueryResult = `
    id
    status
    kind
    typePlanPricing
    studentUser {
        studentUserId
        firstName 
        lastName
        rate
        isManualRate
        grade
    }
    name
    from
    to
    pricingRate
    sentDate
    paymentItemsConfig {
        amount
        percentDiscount
        amountWithoutDiscount
        date
        description
        type
    }
    ...on CouncelingPlan {
        type
        taskTemplate
        proposalVersion
        advisor {
            teamUserId
            firstName
            lastName
        }
        head {
            teamUserId
            firstName
            lastName
        }
        councelor {
            teamUserId
            firstName
            lastName
        }
        tasks {
            ${phasesQueryResult}
        }
        timelines {
            plans {
                id
                name
                lessonIds
                from
                to
                status
                testDate
                kind
                pricingRate
                typePlanPricing
                serviceId
                parts {
                    lessonsPerWeek
                    totalLessons
                    lessonPerWeekPerTopic {
                      topicId
                      lessonsPerWeek
                      totalLessons
                      lockLessons
                    }
                    from
                    to
                    name
                }
                deleteAt
            }
            events {
                name
                tbd
                id
                kind
                startDate
            }
            tests {
                name
                tbd
                id
                kind
                startDate
            }
        }
        aditionalInfo {
            parentUserId
            parentFirstName
            parentLastName
            applicationYear
            studentCountryId
            studentCountryName
            studentType
            avatar
            avatarParent
            avatarCouncelor
            avatarAdvisor
            avatarHead
        }
        tasksMeetings {
            ${taskQueryResult}
         }
    }
`

export default planQueryResult