import React from 'react'
import {ToolTipInfo, IconStarFilled} from '@Knowledge-OTP/znk-ui-components'

const GoingStarField = (props) => {
    const {item, selected, selectUniversity = () => {}} = props
    const isAdmitted = item?.status === 'admitted' 
    return (
        <ToolTipInfo
            disable={isAdmitted}
            tooltipId={`college-tooltip-${item.id}`}
            innerElement={<span className={`text-white`}>This college is not in admitted status</span>}
        >
            <div className={`hover-icon ${!isAdmitted ? 'cursor-not-allowed' : ''}`}>
                <IconStarFilled
                    color={selected === item.id ? '#2c3f58' : '#D6DAE3'}
                    onClick={
                        () => {
                            if (isAdmitted) selectUniversity(item?.id)
                        }
                    } size='20' />
            </div>
        </ToolTipInfo>
    )
}

export default GoingStarField