import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
import {queryUserResult} from '../../../Pages/Plans/modal/newPlan/Model/UseAllUsersLazy'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation create(
            $firstName: String,
            $lastName: String,
            $email: String!,
            $gender: Gender
            $parentId: ID,
            $firstNameParent: String,
            $lastNameParent: String,
            $phoneParent: String,
            $emailParent: String,
            $countryId: ID!,
            $timezone: String,
            $isActive: Boolean,
            $studentType: StudentType!,
            $grade: Grades!,
            $sendEmail: Boolean = false,
        ){
        createUserWithParent(
            sendEmail: $sendEmail,
            userWithParent: {
                firstName: $firstName
                lastName: $lastName
                email: $email
                gender: $gender
                parentId : $parentId
                firstNameParent: $firstNameParent
                lastNameParent: $lastNameParent
                emailParent: $emailParent
                phoneParent: $phoneParent
                countryId: $countryId
                timezone: $timezone
                isActive: $isActive
                studentType: $studentType
                grade: $grade,
            }){
            ${queryUserResult}
        }}`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.createUserWithParent : null, error, validationErrors, submit }
}
