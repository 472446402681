import React from 'react'
import {
  Action,
  IconClose
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'
const ChangeNotificationsInner = (props) => {
  const {
    notificationsSettings,
    editReminder,
    editTimedReminder,
    styles,
    toggle
  } = props
  const { translate } = useTranslation()
  return (
    <div className='w-100 py-5 px-8'>
      <span className='d-flex w-100 flex-row justify-content-between'>
        <h2 className='font-weight-bold'>Notifications</h2>
        <IconClose onClick={toggle} className={'text-cancel'} />
      </span>
      <div className='container-fluid flex-column my-2'>
        <p className={`h3 font-weight-normal mb-1 ${styles.lineH24}`}>
          {translate('profile.actions.changeNotifications.lessonReminder')}
        </p>
        <p
          className={`h3 font-weight-light text-secondary ${styles.lineH24}`}
        >
          {translate(
            'profile.actions.changeNotifications.lessonReminderDesc'
          )}
        </p>
        <Action
          action='reminder'
          status={
            notificationsSettings
              ? notificationsSettings.lessonReminder.isAvailable
                ? 'reminder-active'
                : 'reminder'
              : 'reminder-active'
          }
          className='my-1'
          onClick={() =>
            editTimedReminder(
              'LessonReminder',
              'lessonReminder',
              notificationsSettings.lessonReminder.isAvailable
          )}
          labelLeft={
            notificationsSettings
              ? notificationsSettings.lessonReminder.isAvailable
                ? translate('profile.actions.changeNotifications.reminderSet')
                : translate(
                  'profile.actions.changeNotifications.reminderDisabled'
                )
              : translate('profile.actions.changeNotifications.reminderSet')
          }
          labelRight={
            notificationsSettings &&
                notificationsSettings.lessonReminder.isAvailable
              ? translate(
                'profile.actions.changeNotifications.reminderMinutes',
                notificationsSettings.lessonReminder.duration
              )
              : 'none'
          }
        />
      </div>
      <div className='container-fluid flex-column my-2'>
        <p className={`h3 font-weight-normal mb-1 ${styles.lineH24}`}>
          {translate('profile.actions.changeNotifications.lessonNotesReady')}
        </p>
        <p
          className={`h3 font-weight-light text-secondary ${styles.lineH24}`}
        >
          {translate(
            'profile.actions.changeNotifications.lessonNotesReadyDesc'
          )}
        </p>
        <Action
          action='reminder'
          status={
            notificationsSettings
              ? notificationsSettings.lessonsNotesReady
                ? 'reminder-active'
                : 'reminder'
              : 'reminder-active'
          }
          onClick={() =>
            editReminder(
              'LessonNotes',
              'lessonsNotesReady',
              notificationsSettings.lessonsNotesReady
          )}
          className='my-1'
          labelLeft={
            notificationsSettings
              ? notificationsSettings.lessonsNotesReady
                ? translate('profile.actions.changeNotifications.reminderSet')
                : translate(
                  'profile.actions.changeNotifications.reminderDisabled'
                )
              : translate('profile.actions.changeNotifications.reminderSet')
          }
          labelRight={
            notificationsSettings && notificationsSettings.lessonsNotesReady
              ? translate(
                'profile.actions.changeNotifications.reminderLessonNotesSet'
              )
              : ''
          }
        />
      </div>
      <div className='container-fluid flex-column my-2'>
        <p className={`h3 font-weight-normal mb-1 ${styles.lineH24}`}>
          {translate('profile.actions.changeNotifications.missedLessons')}
        </p>
        <p
          className={`h3 font-weight-light text-secondary ${styles.lineH24}`}
        >
          {translate('profile.actions.changeNotifications.missedLessonsDesc')}
        </p>
        <Action
          action='reminder'
          status={
            notificationsSettings
              ? notificationsSettings.missedLesson
                ? 'reminder-active'
                : 'reminder'
              : 'reminder-active'
          }
          onClick={() =>
            editReminder(
              'MissedLessons',
              'missedLesson',
              notificationsSettings.missedLesson
          )}
          className='my-1'
          labelLeft={
            notificationsSettings
              ? notificationsSettings.missedLesson
                ? translate('profile.actions.changeNotifications.reminderSet')
                : translate(
                  'profile.actions.changeNotifications.reminderDisabled'
                )
              : translate('profile.actions.changeNotifications.reminderSet')
          }
        />
      </div>
    </div>
  )
}

export default ChangeNotificationsInner
