import React from 'react'
import {
  Modal,
  IconClose,
  Button,
  Form,
  Field,
  PlainTextInput,
  LabeledField,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import { FormGroup, Label, ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import useCreateTopic from '../index.js'

const CreateTopicModal = (props) => {
  const { isOpen, ...formData } = useSelector((state) => state.modal.topic.create)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'topic.create', data: { isOpen: !isOpen, ...formData } })
  }
  const setForm = (form) => {
    const newData = form(formData)
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'topic.create', data: { isOpen: true, ...newData } })
  }
  const { breakWidth } = useWindowDimensions()
  const translate = (str) => str
  const onSuccess = () => {
    toggle()
  }
  const { submit, loading, validationErrors } = useCreateTopic(onSuccess, console.log)
  return (
    <Modal
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <ModalHeader
          toggle={toggle}
          close={<IconClose size={20} onClick={toggle} />}
          className='mt-md-4'
        >
          <span className='h2 font-weight-bold mr-4'>
            {translate('constants.topic.create')}
          </span>
        </ModalHeader>
      }
    >
      <Form value={formData} onChange={setForm} validationErrors={validationErrors}>

        <FormGroup>
          <Label for='name'>{translate('Name')}</Label>
          <Field
            id='name'
            type={LabeledField}
            fieldName='name'
            component={PlainTextInput}

          />
        </FormGroup>

        <Button
          label='misc.submit>'
          color='primary'
          check
          size='medium'
          onClick={() => submit(formData)}
          activity={loading}
          style={{ width: '100%' }}
        />
      </Form>
    </Modal>
  )
}

CreateTopicModal.propTypes = {}

export default CreateTopicModal
