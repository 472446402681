import { gql, useLazyQuery } from '@apollo/client'
import counselingPlanQueryResult from '../../../../CounselingPlans/Model/queryResults/planQueryResult'

const usePlans = () => {
  return useLazyQuery(
    gql`query (
            $sortField: String,
            $previous: String,
            $next: String,
            $limit: Int,
            $to: DateTime,
            $from: DateTime,
            $status: [PlanStatus!]!,
            $studentsUserIds: [ID!],
            $serviceIds: [ID!],
            $kind: [PlanKind!]!,
            $topicIds: [ID!]
            $educatorUserIds: [ID!]
            $countryIds: [ID!]
            $lessonsPending: Int
            $endDateFilter: [DateTime!]
            $startDateFilter: [DateTime!]
            $finishSoon: Boolean
            $lastApproved: Boolean
            $previouslySent: Boolean
            $shortRecenlySent: Boolean
            $testDate: DateTime
            ){
          plans (
            sortField: $sortField,
            previous: $previous,
            next: $next,
            limit: $limit,
            to: $to,
            from: $from,
            status: $status,
            studentsUserIds: $studentsUserIds,
            serviceIds: $serviceIds,
            kind: $kind,
            topicIds: $topicIds
            educatorUserIds: $educatorUserIds
            countryIds: $countryIds
            lessonsPending: $lessonsPending
            endDateFilter: $endDateFilter
            startDateFilter: $startDateFilter
            finishSoon: $finishSoon
            lastApproved: $lastApproved
            previouslySent: $previouslySent
            shortRecenlySent: $shortRecenlySent
            testDate: $testDate
          ){
            hasNext
            hasPrevious
            totalDocs
            docs {
              ... on CouncelingPlan {
                ${counselingPlanQueryResult}
              }
              id
              name
              cancelDate
              pauseDate
              activityTest {
                id
              }
              resumeDate
              studentUser {
                studentUserId
                firstName
                lastName
                rate
                isManualRate
              }
              serviceId
              scoreId
              lessonIds
              paymentItemIds
              paymentItemsConfig {
                amount
                percentDiscount 
                amountWithoutDiscount
                date
                description
                type
              }
              recommendedEducators {
                educatorUserId
                firstName
                lastName
                rate
                isManualRate
                topicId
                teachingType
                isLockEducator
                lessonLink
              }
              selectedEducators {
                educatorUserId
                firstName
                lastName
                rate
                isManualRate
                topicId
                lessonLink
              }
              status
              kind
              createdAt
              updatedAt  
              isFirstPlan
              typePlanPricing
              pricingRate
              groupPricingRate
              location {
                type
                linkEducator
                linkStudent
              }
              groupId
              from
              to
              ... on GroupPLan {
              PartsSchedule {
                from
                to
                lessonsPerWeekGroup {
                  topicId
                  educatorId
                  day
                  duration
                  hour
                  totalLessons
                }
                lessonsPerWeekIndividual {
                  topicId
                  lessonsPerWeek
                  lockLessons
                  totalLessons
                }
              }
              studentPlans {
                studentId
                planId
                registerDate
                planStatus
              }
              lessonGroupIds
              childPlans {
                id
                name
                cancelDate
                pauseDate
                resumeDate
                studentUser {
                  studentUserId
                  firstName
                  lastName
                  rate
                  isManualRate
                }
                serviceId
                lessonIds
                paymentItemIds
                paymentItemsConfig {
                  amount
                  percentDiscount 
                  amountWithoutDiscount
                  date
                  description
                  type
                }
                recommendedEducators {
                  educatorUserId
                  firstName
                  lastName
                  rate
                  isManualRate
                  topicId
                  teachingType
                  isLockEducator
                  lessonLink
                }
                selectedEducators {
                  educatorUserId
                  firstName
                  lastName
                  rate
                  isManualRate
                  topicId
                  lessonLink
                }
                status
                kind
                createdAt
                updatedAt  
                isFirstPlan
                typePlanPricing
                pricingRate
                groupPricingRate
                location {
                  type
                }
                groupId
                from
                to
                testDate
                ... on LessonsFixedPlan {
                  fixedParts: parts {
                    TotalPerTopic {
                      topicId
                      lessonsPerWeek
                      lockLessons
                      totalLessons
                    }
                    type
                    totalLessons
                    from
                    to
                    name
                  }
                }
                ... on LessonsPerWeekPlan {
                  testDate
                  parts {
                    lessonPerWeekPerTopic {
                      topicId
                      lessonsPerWeek
                      lockLessons
                      totalLessons
                    }
                    lessonsPerWeek
                    totalLessons
                    from
                    to
                    name
                  }
                }          
              }
              }
              ... on LessonsFixedPlan {
                fixedParts: parts {
                  TotalPerTopic {
                    topicId
                    lessonsPerWeek
                    lockLessons
                    totalLessons
                  }
                  type
                  totalLessons
                  from
                  to
                  name
                }
                groupFixedInfo{
                    PartsSchedule {
                        lessonsPerWeekGroup {
                            topicId
                            educatorId
                            day
                            duration
                            hour
                            totalLessons
                        }
                    }
                }
              }
              ... on LessonsPerWeekPlan {
                testDate
                parts {
                  lessonPerWeekPerTopic {
                    topicId
                    lessonsPerWeek
                    lockLessons
                    totalLessons
                  }
                  lessonsPerWeek
                  totalLessons
                  from
                  to
                  name
                }
                groupPerWeekInfo{
                    PartsSchedule {
                        lessonsPerWeekGroup {
                            topicId
                            educatorId
                            day
                            duration
                            hour
                            totalLessons
                        }
                    }
                }
              }
            }
          }
        }`
  )
}

export default usePlans
