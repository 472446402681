import React, {useEffect, useState} from 'react'
import StudentsFilter from "../../../../Payments/Filters/Students";
import {gql, useLazyQuery} from "@apollo/client";

const StudentFilter = (props) => {
    const {
        setUpcommingLesson = () => {},
        setLessonsState = () => {}, 
        useInner=false,
        originalLessons, studentsFilters, setStudentsFilters, roles = ['STUDENT'], label = 'Students'} = props
    const [students, setStudents] = useState([])
    const [searchStudent, setSearchStudent] = useState('')
    const [getStudents, {data: dataStudent, loading: loadingStudent}] = useLazyQuery(
        gql`query($roles: [Roles!], $search: String, $limit: Int) {
        allUsers(roles: $roles, search: $search, limit: $limit) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs
            docs {
                id
                firstName
                lastName
                studentInfo {
                    upcomingLesson {id}
                }
            }
        }
    }
    `,
        {
            variables: {limit: 0, roles: roles, search: searchStudent}
        }
    )
    useEffect(() => {
        (async () => {
            if (searchStudent && searchStudent !== '') {
                await getStudents()
            }
        })()
    }, [searchStudent, getStudents])
    useEffect(() => {
        if (searchStudent && searchStudent !== '' && dataStudent) {
            setStudents(dataStudent.allUsers.docs)
        }
    }, [dataStudent, searchStudent])

    const onChangeInputStudens = (value) => {
        setSearchStudent(value)
    }

    useEffect(() => {
        const list = []
        let userName = ''
        if (studentsFilters?.length !== 0) {
            // eslint-disable-next-line
            studentsFilters?.map(studentID => {
                const lessons = []
                // eslint-disable-next-line
                originalLessons?.map(lesson => {
                    if (lesson.student.find(user => user.studentUserId === studentID)) {
                        const {firstName, lastName} = lesson.student.find(student => student.studentUserId === studentID)
                        userName = `${firstName} ${lastName}`
                        lessons.push(lesson)
                    }
                })
                list.push({
                    name: userName,
                    collection: lessons,
                })
            })
            setLessonsState({
                listType: 'collectionGroup',
                group: list,
            })
        } else {
            setLessonsState({
                listType: 'normalList',
                group: originalLessons,
            })
        }
        // eslint-disable-next-line
    }, [studentsFilters])

    return (
        <StudentsFilter
            useInner={useInner}
            setUpcommingLesson={setUpcommingLesson}
            roles={roles}
            label={label}
            onChangeInput={onChangeInputStudens}
            {...{
                students: students,
                studentFilters: studentsFilters,
                setStudentFilters: setStudentsFilters
            }}
            loading={loadingStudent}
        />
    )
}

export default StudentFilter
