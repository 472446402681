import React from 'react'
import {
    Field,
    SelectOption,
    AmountInput
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const Pricing = (props) => {
    const { form } = props
    const {translate} = useTranslation()
    return (
        <>
            <div className='pl-0 pr-6 py-6 pl-3'>
                <h3><b>Pricing</b></h3>
                <div className='row pl-3'>
                    <Field
                        className='col-12'
                        type={SelectOption}
                        fieldName='typePlanPricing'
                        options={[
                        { id: 'perlesson', text: translate('modal.newplan.pricingplan.perlessonrate') },
                        { id: 'permonth', text: translate('modal.newplan.pricingplan.flatfee') }
                        ]}
                        value={form.typePlanPricing}
                    />
                    <label className='col-12 text-muted mb-2 mt-3 pl-0'>
                        {form.typePlanPricing ? form.typePlanPricing === 'perlesson'
                        ? translate('modal.newplan.pricingplan.rate')
                        : translate('modal.newplan.pricingplan.amount') : translate('modal.newplan.pricingplan.amount')}
                    </label>
                    <Field
                        className='col-12 placeholder-to-right'
                        type={AmountInput}
                        fieldName='pricingRate'
                        fieldType='number'
                        placeholder='$  0.00 '
                        min='0'
                        value={form.pricingRate}
                    />
                </div>
            </div>
            <div className='borderTop ml-3' />
        </>
    )
}

export default Pricing