import rolesNames from "./rolesNames"
import { db } from "../fireBase"

const parseUsersList = (actualUserId, list = {}) => {
    let usersList = []
    let onlineStatus = ''
    const rolesList = Object.keys(list).filter((role) => role !== '__typename')
    // usersList = rolesList
    db.collection('usersStatus')
      .doc(actualUserId)
      .onSnapshot((snapshot) => onlineStatus = snapshot.data())     
      usersList = rolesList?.map(rol => {
          let userID = ''
          userID = rol === 'student' ? list[rol]?.studentUserId : list[rol]?.teamUserId
          return {...list[rol], id: userID, role: rolesNames[rol] || rol}
      })
    // setUsersList((usersList) => {
    //   return usersList?.map((rol) => {
    //     let userID = ''
    //     userID = rol === 'student'? list[rol]?.studentUserId : list[rol]?.teamUserId
    //     return {...list[rol], id: userID, role: rolesNames[rol] || rol}
    //   })
    // })
  
    return {onlineStatus, usersList}
  }

  export default parseUsersList