import React, { useState } from 'react'
import { Activity, CheckboxOption, IconEdit } from '@Knowledge-OTP/znk-ui-components'
import isEqual from 'lodash/isEqual'
import useSetParentPermissionsToStudent from '../../../../../../../Model/Users/Parent/SetParentPermissionsToStudent'
import useTranslation from '../../../../../../../i18n/useTranslation'

const ParentEditor = (props) => {
  const { parentUserId, firstName, lastName, manageApprovals, managePayments, onSuccess, userId } = props
  const { submit, loading } = useSetParentPermissionsToStudent(onSuccess, console.log)
  const initialPermits = { managePayments, manageApprovals }
  const [permissions, setPermissions] = useState(initialPermits)
  const togglePermission = (permit) => (value) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permit]: value
    }))
  }
  const { translate } = useTranslation()
  const onSubmit = () => {
    submit({
      parent: {
        parentUserId,
        ...permissions
      },
      userId
    })
  }
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <span className='mr-auto'>{`${firstName} ${lastName}`}</span>
      <span>{translate('Approvals')}</span>
      <span>
        <CheckboxOption
          className='mx-2 border-right border-logo'
          name='manageApprovals'
          onChange={togglePermission('manageApprovals')}
          defaultState={initialPermits.manageApprovals}
        />
      </span>
      <span>{translate('Payments')}</span>
      <span>
        <CheckboxOption
          className='mx-2 border-right border-logo'
          name='managePayments'
          onChange={togglePermission('managePayments')}
          defaultState={initialPermits.managePayments}
        />
      </span>
      <span>
        {loading ? <Activity /> : (
          <IconEdit
            size={16}
            className={`${!isEqual(initialPermits, permissions) ? 'text-body' : 'text-white'} text-right`}
            onClick={() => onSubmit()}
            style={{ cursor: 'pointer' }}
          />
        )}
      </span>

    </div>
  )
}

export default ParentEditor
