import { ModalPushScroll } from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import FullProfile from './full'
import SimpleProfile from './simple'
import { gql, useLazyQuery } from '@apollo/client'
import PersonalModalForm from '../changePersonal'
import StudentModalForm from '../Student/changeStudentInfo'
import SetStudentServicesRateModalForm from '../Student/setStudentServicesRate'
import EditFamilyInfo from '../Student/EditFamily'

const ProfileView = (props) => {
  const { countries, refetch } = props
  const { isOpen, full, userId, type } = useSelector((state) => state.modal.user.profile)
  const { isOpen: isOpenEdit } = useSelector((state) => state.modal.user.edit)
  const { isOpen: isOpenAcadInfo } = useSelector((state) => state.modal.user.studentInfo)
  const { isOpen: servicesStudentRate } = useSelector((state) => state.modal.user.servicesRate)
  const { isOpen: familyInfo } = useSelector((state) => state.modal.user.familyInfo)
  const [id] = useState(null)
  const getxUser = (userId) => {
    if(userId || id) {
      getUser({id: userId || id})
    }
  }
  const [getUser, { data }] = useLazyQuery(
    gql`query($id: String!) {
      user(id: $id) {
        id
        emails {
          address
          verified
        }
        countryId
        country {
            id,
            name,
            defaultStudentRate
        }
        gender
        spokenLanguages
        address
        city
        state
        zipCode
        firstName
        lastName
        phone
        timezone
        birthdate
        studentInfo {
          institution
          graduationDate
          gpa
          defaultRate
          aditionalInfo
          studentType
          notifications {
            missedLesson
            lessonsNotesReady
            lessonReminder {
              isAvailable
              duration
            }
          }
          servicesRate {
            defaultRate
            name
            serviceId
          }
          parents {
            parentUserId
            managePayments
            manageApprovals
            firstName
            lastName
            email
            phone
          }
        }
        educatorInfo {
          defaultRate
          services {
            serviceId
            name
            defaultRate
            topics {
              topicId
              teachingTypes
              name
            }
          }
          backupLink
          weeklyCapHours
          isActive
          availabilityBlocks {
            day
            hour
          }
          rating
        }
        isActive
        roles
      }
    }
  `,
    {
      variables: { id: userId || id },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    if (userId || id) {
      getUser()
    }
    // eslint-disable-next-line
  }, [userId || id])
  // useEffect(() => {
  //   if (data) {

  //   }
  // }, [data])
  return (
    <ModalPushScroll
      isOpen={isOpen}
      more={full}
      minHead={null}
      sideLeft={
        <SimpleProfile user={data && data.user} type={type} />
      }
      sideRight={
        full ? <FullProfile user={data && data.user} type={type} refetch={(id) => {
          getxUser(id)}} /> : null
      }
      overRight={
        isOpenEdit
          ? <PersonalModalForm countries={countries} refetchUser={(id) => {
            getxUser(id)}} />
          : isOpenAcadInfo
            ? <StudentModalForm refetchUser={(id) => { getxUser(id) }} />
            : servicesStudentRate ? (full ? <SetStudentServicesRateModalForm /> : null)
              : familyInfo ? <EditFamilyInfo refetchUser={(id) => { getxUser(id) }} refetchList={refetch} /> : null
      }
    />
  )
}

export default ProfileView
