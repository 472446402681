import moment from 'moment'
import { validate } from 'validate.js'

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function(value, options) {
    return +moment.utc(value)
  },
  // Input is a unix timestamp
  format: function(value, options) {
    var format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss'
    return moment.utc(value).format(format)
  }
})

const constraints = {
  'newInvoice.userId': {
    presence: {
      allowEmpty: false,
      message: '^Plase select o create a student'
    }
  },
  'newInvoice.category': {
    presence: {
      allowEmpty: false
    }
  },
  'newInvoice.invoiceDate': {
    datetime: {
      dateOnly: true
    }
  },
  'newInvoice.dueDate': {
    datetime: {
      dateOnly: true
    }
  },
  'newInvoice.taxFee': {
    numericality: {
      onlyInteger: false,
      greaterThanOrEqualTo: 0,
      notGreaterThanOrEqualTo: 'it is not valid',
      notValid: '^it is not valid'
    }
  }
}

export default constraints
