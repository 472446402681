import { IconClose, Modal, Field, Form, StickyButton, Button, PlainTextInput, LabeledField, InputSelect, Select } from '@Knowledge-OTP/znk-ui-components'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import updateGeneralInfo from '../../../../Model/Users/General/updateGeneralInfo'
import useSetStafInfoToUse from '../../../Users/Components/editGeneralInfo/Model/useSetStafInfoToUse'
import { isEmpty } from 'validate.js'
import useTranslation from '../../../../i18n/useTranslation'
import { escapeRegExp } from 'lodash'
import moment from 'moment'
const EditGeneralInfo = (props) => {
  const { refetch } = props
  const { isOpen, type: typeUser, userId, ...formData } = useSelector((state) => state.modal.user.editGeneralInfo)
  const [form, setForm] = useState({})
  const [yearsList, setYearsList] = useState([])
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [errors, setErrors] = useState({})
  const toggle = () => {
    setForm({})
    setErrors({})
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.editGeneralInfo', data: { isOpen: false, type: typeUser } })
  }
  function rangeYear () {
    const max = new Date().getFullYear()
    const min = 2016
    const years = []
  
    for (let i = max; i >= min; i--) {
        years.push(i)
    }
    setYearsList(years)
    // return years
  }

  useEffect(() => {
    if(yearsList.length === 0) {
      rangeYear()
    }
  // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (userId) {
      setForm({
        backupLink: typeUser === 'educator' ? formData.backupLink : undefined,
        weeklyCapHours: typeUser === 'educator' ? formData.weeklyCapHours : undefined,
        materials: typeUser === 'student' ? formData.materials : undefined,
        googleCalendar: typeUser === 'educator' ? formData.googleCalendar ? formData.googleCalendar : '.' : undefined,
        handbook: typeUser === 'educator' ? formData.handbook : undefined,
        sinceDate: typeUser === 'educator' ? formData.sinceDate : undefined,
      })
    }
    // eslint-disable-next-line
  }, [userId])
  const { submit, loading, validationErrors } = updateGeneralInfo(() => {
    refetch()
    toggle()
  }, console.log)
  const [submitStaff, {loading: loadStaff}] = useSetStafInfoToUse(
    () => {
      refetch()
      toggle()
    }, 
    (err) => {console.error(err)}
  )
  const onSubmit = () => {
    setErrors({})
    const newVal = form
    newVal.userId = userId
    if (typeUser === 'educator') {
      newVal.weeklyCapHours = newVal.weeklyCapHours ? parseInt(newVal.weeklyCapHours) : undefined
      newVal.sinceDate = form?.sinceDate
    }
    newVal.typeUser = String(typeUser).toUpperCase()
    if (typeUser === 'staff') {
      submitStaff({
        variables: {
          staffInfo: {
            backupLink: newVal?.backupLink
          },
          userId
        }
      })
    } else submit(newVal)
  }
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key
        customError[keyVal] = translate('profile.actions.editGeneralInfo.errors.' + keyVal)
      })
      setErrors(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('profile.actions.editGeneralInfo.title')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <div className='m-0 p-0 mb-2 px-3'>
          <Form value={form} onChange={setForm} validationErrors={errors}>
            {
              typeUser === 'student'
                ? <>
                  <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editGeneralInfo.course')}</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={LabeledField}
                    component={PlainTextInput}
                    fieldName='materials'
                    fieldType='text'
                  />
                </>
                : typeUser === 'staff' ?
                <>
                  <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editGeneralInfo.backuplink')}</label>
                  <Field
                    className='col-12 placeholder-to-left'
                    type={LabeledField}
                    component={PlainTextInput}
                    fieldName='backupLink'
                    fieldType='text'
                  />
                </> :
                <>
                  <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editGeneralInfo.since')}</label>
                  <Field
                    type={InputSelect}
                    component={Select}
                    fieldName='gradYear'
                    className='h-100 pr-1'
                    options={yearsList?.map((year) => ({
                        label: year,
                        value: year
                    }))}
                    value={Number.isInteger(form?.sinceDate) ? form?.sinceDate :parseInt(moment(form?.sinceDate).format('YYYY'))}
                    listItem='small'
                    itemDivider='middle'
                    multiple={false}
                    onChange={(e) => {
                        setForm({
                          ...form,
                          sinceDate: parseInt(e)
                        })
                      }}
                    filterOption={(obj, string) => {
                        if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                        return false
                    }}
                />
                 
                  {/* <DateSelector
                    className={`h4 ml-1`}
                    value={form?.sinceDate}
                    defaultValue={form?.sinceDate ? form?.sinceDate : new Date()}
                    onChange={(newValue) => {
                      setForm({ 
                          ...form, 
                          sinceDate: newValue,
                      })
                  }}
                  /> */}
                  {/* <Field
                    className='col-12 placeholder-to-left'
                    type={DatePicker}
                    component={PlainTextInput}
                    fieldName='sinceDate'
                    fieldType='date'
                  /> */}
                  <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editGeneralInfo.googlefeed')}</label>
                  <Field
                    className='col-12 placeholder-to-left'
                    type={LabeledField}
                    component={PlainTextInput}
                    fieldName='googleCalendar'
                    fieldType='text'
                  />
                  <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editGeneralInfo.backuplink')}</label>
                  <Field
                    className='col-12 placeholder-to-left'
                    type={LabeledField}
                    component={PlainTextInput}
                    fieldName='backupLink'
                    fieldType='text'
                  />
                  <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editGeneralInfo.maxhours')}</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={LabeledField}
                    component={PlainTextInput}
                    fieldName='weeklyCapHours'
                    fieldType='text'
                    placeholder='0.00 '
                    min='0'
                  />
                  <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editGeneralInfo.handbook')}</label>
                  <Field
                    className='col-12 placeholder-to-left'
                    type={LabeledField}
                    component={PlainTextInput}
                    fieldName='handbook'
                    fieldType='text'
                  />
                </>
            }
            <div className='row d-flex m-0 p-0 mt-5'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loading || loadStaff}
                      className='col-12 aling-text-left'
                      label='Save'
                      onClick={onSubmit}
                      style={{ borderRadius: '16px' }}
                      check={true}
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  )
}

export default EditGeneralInfo
