import React, { useState, useEffect } from 'react'
import { InputSelect } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import useActivities from '../../../Model/useActivities'
import CreateActivityModal from '../../../../../Constants/Activities/Create'
import useServices from '../../../../../../Model/Services/List'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../store/modal/const'
import useModalState from '../../../../../../utils/useModalState'
import useTranslation from '../../../../../../i18n/useTranslation'

const SelectActivity = (props) => {
  // eslint-disable-next-line
  const { task, examDateSelected, setExamDateSelected, selected, setSelected } = props
  const {translate} = useTranslation()
  const { selectedExam } = task
  const modalState = useModalState('activities.create')
  const { modalData} = modalState
  const [activities, setActivities] = useState([])
  //const [selected, setSelected] = useState(null)
  const dispatch = useDispatch()

  const { data: services, loading: servicesLoading } = useServices({ limit: 0 })
  const [
    activitiesQuery,
    // eslint-disable-next-line
    { data: dataActivities, loading: loadingActivities, refetch: activitiesRefetch },
  ] = useActivities()

  const onSelect = (exam) => {
    setSelected(exam)
    const activityFound = activities.find(({ id }) => id === exam)
    setExamDateSelected(activityFound.startDate)
  }

  useEffect(() => {
    activitiesQuery({
      variables: {
        kinds: ['test'],
        serviceIds: [],
        from: null,
        to: null
      },
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (dataActivities) {
      const acitivitiesFilter = dataActivities?.allActivities?.filter(
        (activity) => activity.serviceId === selectedExam?.serviceId,
      )
      const activityToShow = acitivitiesFilter?.filter((activity) => 
        activity.id === task?.examId   
      )
      setSelected(activityToShow[0]?.id)
      acitivitiesFilter?.length === 0 ? setActivities([{ id: '000', name: translate('modal.task.activity.anotherOption')}]) : setActivities(acitivitiesFilter)
    }
    // eslint-disable-next-line
  }, [dataActivities])

  return (
    loadingActivities ? <Skeleton count={1} width={375} height={44} /> :
      <div className='mb-4 mt-1'>
        <div className='d-flex flex justify-content-between align-items-end'>
          <span className='h45 text-gray mb-2'>{translate('modal.task.activity.selectDate')}</span>
          <span className='h5 text-gray mb-2'
            style={{ cursor: 'pointer' }}
            onClick={()=> {
              dispatch({
                type: SET_DATA_MODAL_FORM_LIST,
                payload: 'activities.create',
                data: { ...modalData, isOpen: true, kind: 'test', refetch: activitiesRefetch }
              })
            } }>
              {translate('modal.task.activity.newActivity')}</span>
        </div>
        <div>

          {activities?.length > 0 &&
            <InputSelect
              style={{ color: '#2C3F58' }}
              value={selected}
              menuPlacement={'top'}
              isClearable={false}
              options={
                activities
                  .map((activity) => {
                    return {
                      value: activity.id,
                      label: activity.name
                    }
                  })
              }
              onChange={(e) => {
                //const exam = activities.find(({ id }) => id === e)
                onSelect(e)
              }}
            />
          }
          <CreateActivityModal {...{ services, servicesLoading }} />
        </div>

      </div>
  )
}

export default SelectActivity
