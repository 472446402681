const paymentResult = `
    kind
    ... on CardPaymentMethod {
        cardInfo {
            last4
            brand
            expYear
            expMonth
        }
    }
    ... on BankDebitPaymentMethod {
        validationStatus
        accountInfo {
            bankName
            last4
        }
    }
    ... on BankCreditPaymentMethod {
        status
        sourceInfo {
            bankName
            accountNumber
            swiftCode
            routingNumber
        }
    }
    ... on ManualPaymentMethod {
        manualPaymentInfo {
            bankName
            accountNumber
            routingNumber
            beneficiary
            address
            swiftCode
        }
    }
    ... on ZellePaymentMethod {
        zellePaymentInfo {
            emailToSend
          }
    }
`

export default paymentResult