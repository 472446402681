const collectionType = {
  IndividualLesson: 'messages',
  PlanLesson: 'messages',
  GroupLesson: 'messages',
  lesson: 'messages',
  task: 'messagesTask',
  UserTasksOut: 'messagesTask',
  UserTasks: 'messagesTask',
  Task: 'messagesTask'
}

export default collectionType
