import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
    IconArrowRight, ModalLayout,
    useWindowDimensions, ListItem, IconArrowLeft, IconSignPlus
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import moment from 'moment-timezone'
import useTranslation from '../../../../i18n/useTranslation'
import StateFilter from '../../../Calendar/Sections/Filters/StateFilter'
import StudentFilter from '../../../Calendar/Sections/Filters/StudentFilter'
import FilterDate from '../Date'
import ServiceFilter from '../Service'

const ModalFilterMobile = (props) => {
    const {
        setQuery: setQueryProp = () => { }, query = {},
        resetFilters = () => { }, canReset = false,
        setUpcommingLesson,
        studentsFilters,
        setStudentsFilters = () => { },
        educatorsFilters,
        setEducatorsFilters = () => { },
    } = props
    const setQuery = useCallback(
        (newQuery) => {
            setQueryProp(newQuery)
            setOpen(false)
        }, [setQueryProp]
    )
    const [isOpen, setOpen] = useState(false)
    const toggle = () => setOpen(!isOpen)
    const { breakWidth } = useWindowDimensions()

    const { translate } = useTranslation()
    const Pages = {
        filters: {key: 'filters', name: 'Filters'},
        statuses: { key: 'statuses', name: translate('filters.calendar.status.title') },
        students: { key: 'students', name: translate('filters.calendar.students.title') },
        educators: { key: 'educators', name: translate('filters.calendar.educators.title') },
        date: { key: 'date', name: translate('filters.calendar.date.title') },
        services: { key: 'services', name: translate('filters.calendar.services.title') },
    }

    const [page, setPage] = useState(Pages.filters.key)
    useEffect(() => { if (!isOpen) setTimeout(() => setPage(Pages.filters.key), 1000) }, [isOpen, Pages])

    const modalTitle = useMemo(() => Object.values(Pages).find(({ key }) => key === page)?.name, [page, Pages])
    const listItemClassName = 'w-100 hover-no-border py-2'

    // console.log("query =>", query.filterStatus)
    return (
        <>
            <div className='m-0 p-0' onClick={toggle}>
                {props.children}
            </div>
            <ModalLayout
                className={`${breakWidth === 'SM' ? 'modal-botbar' : 'modal-centered'} modal-hauto modal-noborder-bottom`}
                disabledScrollAnimation={false}
                isOpen={isOpen}
                toggle={toggle}
                topHead={
                    <div className={`d-flex flex-row align-items-center`}>
                        {
                            Pages.filters.key !== page &&
                            <div className='hover-icon' onClick={() => setPage(Pages.filters.key)}>
                                <IconArrowLeft size={20} className={'text-disabled'} />
                            </div>
                        }
                        <span className={`h2 text-title m-0`}>{modalTitle}</span>
                    </div>
                }
            >
                <TabContent activeTab={page}>
                    <TabPane tabId={Pages.filters.key}>
                        <div className='m-0 p-0 d-flex flex-column'>
                            <ListItem
                                onClick={() => { setPage(Pages.statuses.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.statuses.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.students.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.students.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.educators.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.educators.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.date.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.date.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.services.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.services.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            {
                                canReset && (
                                    <ListItem
                                        onClick={resetFilters}
                                        className={listItemClassName}
                                        hasHover
                                        Left={<span className='text-error'>{translate('misc.cleanFilters')}</span>}
                                        Right={<div className='hover-icon'><IconSignPlus className={'rotate-90 text-error'} /></div>}
                                        showBottomLine
                                    />
                                )
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId={Pages.statuses.key}>
                        <StateFilter
                            useInner
                            statusFilters={query.filterStatus}
                            setStatusFilters={(newStatus) => { 
                                setQuery({ ...query, filterStatus: newStatus }) }}
                        />
                    </TabPane>
                    <TabPane tabId={Pages.students.key}>
                    {/* {studentsFilterComponent} */}
                        <StudentFilter
                            roles={['STUDENT']}
                            label={'Students'}
                            setUpcommingLesson={setUpcommingLesson}
                            studentsFilters={studentsFilters}
                            setStudentsFilters={setStudentsFilters}
                            setLessonsState={()=>{}}
                            originalLessons={[]}
                            useInner
                            typeFilters={query.studentIds}
                            setTypeFilters={(newType) => { setQuery({ ...query, type: newType }) }}
                        />
                    </TabPane>
                    <TabPane tabId={Pages.educators.key}>
                        <StudentFilter
                            roles={['EDUCATOR']}
                            label={'Educators'}
                            setUpcommingLesson={setUpcommingLesson}
                            studentsFilters={educatorsFilters}
                            setStudentsFilters={setEducatorsFilters}
                            setLessonsState={()=>{}}
                            originalLessons={[]}
                            useInner
                            typeFilters={query.educatorIds}
                            setTypeFilters={(newType) => { setQuery({ ...query, type: newType }) }}
                        />
                    </TabPane>
                    <TabPane tabId={Pages.date.key}>
                        <FilterDate
                            useInner
                            startDateFilters={query.startDate}
                            setFilters={(newDates = []) => {
                                const [start, end] = newDates
                                setQuery({ ...query, startDate: moment(start).toISOString(), endDate: moment(end).toISOString() })
                            }}
                        />
                    </TabPane>
                    <TabPane tabId={Pages.services.key}>
                        <ServiceFilter
                            useInner
                            servicesFilters={query.serviceIds}
                            setServicesFilters={(newServicies) => { setQuery({ ...query, servicesIds: newServicies?.id }) }}
                        />
                    </TabPane>
                </TabContent>
            </ModalLayout>
        </>
    )
}


export default ModalFilterMobile