import {initialState} from './initialState'
import {ADD_ALERT, REMOVE_ALERT} from './const'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ALERT:
      return {
        ...state,
        stack: state.stack.concat([action.payload]),
      }
    case REMOVE_ALERT:
      return {
        ...state,
        stack: state.stack.filter((al) => al.id !== action.payload.id),
      }  
    default:
      return {...state}
  }
}
