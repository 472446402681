import React from 'react'
import { Row } from 'reactstrap'
import { Navbar } from '@Knowledge-OTP/znk-ui-components'
import { Redirect, Switch, useHistory, Route } from 'react-router'

import { navItems } from './navItems'
import CountriesView from './Countries'
import ServicesView from './Service'
import TopicsView from './Topics'
import ActivitiesView from './Activities'
import UniversitiesView from './Universities'
import GlobalCounselorsView from './GlobalCounselors'
import TimezoneDTS from '../Developer/Tabs/TimezoneDTS'
// import LivePrepPlans from './LivePrepPlans'
const ConstantsView = () => {
  const history = useHistory()
  return (
    <div className='d-flex h-100'>
      <div
        className='col-12 px-2 px-md-5 px-lg-7'
        style={{ overflowY: 'auto', overflowX: 'scroll' }}
      >
        <Row className='mt-md-5'>
          <Navbar
            light
            expand='sm'
            className='d-flex pr-3 pl-md-0 brandless tabTexts'
            navClass='d-flex align-items-center znk-scroll-horizontal'
            history={history}
            FootBrand={<></>}
            items={navItems}
          />
        </Row>
        <Row className='d-flex flex-column'>
          <Switch>
            <Route exact path='/constants/countries' render={(props) => <CountriesView />} />
            <Route exact path='/constants/services' render={(props) => <ServicesView />} />
            <Route exact path='/constants/topics' render={(props) => <TopicsView />} />
            <Route exact path='/constants/activities' render={(props) => <ActivitiesView />} />
            <Route exact path='/constants/universities' render={(props) => <UniversitiesView />} />
            <Route exact path='/constants/counselors' render={(props) => <GlobalCounselorsView />} />
            <Route exact path='/constants/timezones' render={(props) => <TimezoneDTS />} />
            {/* <Route exact path='/constants/planslp' render={(props) => <LivePrepPlans />} /> */}
            <Route render={() => <Redirect to='/constants/countries' />} />
          </Switch>
        </Row>
      </div>
    </div>
  )
}

export default ConstantsView
