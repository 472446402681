import React, { useMemo, useState } from 'react'
import { IconLesson } from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import PlanListItem from '../../PlanListItem'
import moment from 'moment'
import CardHeader from '../../../Components/CardHeader'
import useTranslation from '../../../../../../../i18n/useTranslation'


//ordena el arreglo
const getOrderArray = (plans = [], sort) => {
    const { sort: sortType, order } = sort
    const allPlans = plans || []
    const orderArray = [...allPlans]
    let newArray = []
    if (['status', 'name'].includes(sortType)) { // ordenamiento por string
        newArray = orderArray.sort(
            (planA, planB) => {
                if (planA[sortType] < planB[sortType]) return -1;
                if (planA[sortType] > planB[sortType]) return 1;
                return 0;
            }
        )
    }
    else if (['student'].includes(sortType)) { // custom student filter
        newArray = orderArray.sort(
            (planA, planB) => {
                const studentA = `${planA?.studentUser?.firstName}-${planA?.studentUser?.lastName}`
                const studentB = `${planB?.studentUser?.firstName}-${planB?.studentUser?.lastName}`
                if (studentA < studentB) return -1;
                if (studentA > studentB) return 1;
                return 0;

                // return studentA - studentB
            }
        )
        // console.log(newArray.map(({studentUser}) => studentUser))
    }
    else {
        newArray = orderArray.sort( //ordenamiento por fecha
            (planA, planB) => {
                return moment(planA?.updatedAt) - moment(planB?.updatedAt)
            }
        )
    }
    return order ? newArray : newArray.reverse()
}


const BodyTable = (props) => {
    const { 
        loading: loadQuery, 
        refetch: queryRefetch = async () => { }, 
        plans: queryPlans = [], filterSection = <></> } = props
    const {translate} = useTranslation()
    const [searchString, setSearchString] = useState('')
    const [sort, setSort] = useState({ sort: 'student', order: true/**ASC**/ })
    const [loadDashboardPlans, setLoadDashboardPlans] = useState(false)
    

    const plans = useMemo(
        () => {
            if (queryPlans) return getOrderArray(queryPlans, sort)
            return []
        }, [queryPlans, sort]
    )


    const filterPlans = useMemo(() => {
        return (plans || []).filter(({ studentUser }) => {
            const { firstName, lastName } = studentUser
            return `${firstName.toUpperCase()} ${lastName.toUpperCase()}`.includes(searchString.toUpperCase())
        })
    }, [searchString, plans])



    const refetchDashboardPlansFn = () => {
        setLoadDashboardPlans(true)
        queryRefetch()
            .then(() => {
                setLoadDashboardPlans(false)
            },
                () => {
                    setLoadDashboardPlans(false)
                })
    }
    const refetch = () => {
        refetchDashboardPlansFn()
    }

    const loading = useMemo(() => loadQuery || loadDashboardPlans, [loadQuery, loadDashboardPlans])
    
    
    const currentTab = useMemo(
        () => loading ? 'loading' : plans && plans.length === 0 ? 'blank' : 'show',
        [loading, plans]
    )
    // const [currentTab, setCurrentTab] = useState('blank')

    return (
        <>
            {/* <div className='d-flex flex-row'>
                <button onClick={() => {setCurrentTab('blank')}} className='w-100'>blank</button>
                <button onClick={() => {setCurrentTab('loading')}} className='w-100'>loading</button>
                <button onClick={() => {setCurrentTab('show')}} className='w-100'>show</button>
            </div> */}
            <CardHeader
                innerInfo={translate("dashboard.planList.info")}
                filterSection={
                    <div className={`text-gray ml-4 d-flex flex-grid`} style={{ gridGap: '25px' }}>
                        {filterSection}
                    </div>
                }
                subtitle={loading ? null : `/ ${filterPlans?.length} plan(s)`}
                onReload={() => {
                    refetch()
                }}
                loading={loading}
                useSearch
                onChange={(e) => {
                    setSearchString(e.target.value)
                }}
                className={`secondaryBackground`}
                title={`Plan list`}
            />
            <div className='d-flex flex-column w-100 m-0 p-0 flex-grow-1' /**style={{overflowX: 'hidden'}}**/>
                <div className={`secondaryBackground w-100 d-flex flex-column`}
                    style={{ position: 'sticky', top: '119px', zIndex: 10 }}>
                    <PlanListItem header sortData={sort} onSort={(sortInput, orderInput) => {
                        setSort({ sort: sortInput, order: orderInput })
                    }}
                    />
                    <div className={`borderTop w-100`} />
                </div>
                <TabContent className={`flex-grow-1 ${currentTab === 'blank' ? 'd-flex flex-column justify-content-center align-items-center' : ''}`} activeTab={currentTab}>
                    <TabPane tabId={`loading`}>
                        {
                            new Array(10).fill(0).map(() => {
                                return (
                                    <PlanListItem loading />
                                )
                            })
                        }
                    </TabPane>
                    <TabPane tabId={`show`}>
                        {
                            filterPlans.map(
                                (plan, index) => {
                                    return (
                                        <PlanListItem
                                            {...plan}
                                            refetch={() => {
                                                refetch()
                                            }}
                                            className={index % 2 === 0 ? `secondaryBackground` : `whiteBackground`}
                                        />
                                    )
                                }
                            )
                        }
                    </TabPane>
                    <TabPane className='h-100' tabId={'blank'}>
                        <div className={`d-flex flex-row justify-content-center w-100`} style={{ height: '200px' }}>
                            <div className={`d-flex flex-column justify-content-center align-items-center text-center`}>
                                <IconLesson size={40} className={`text-disabled`} />
                                <label className={`h45 mt-4 text-gray`}>No plans</label>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </>
    )
}

export default BodyTable