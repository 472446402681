import React from 'react'
import UniversityFilters from './Universities'

const StudentFilters = (props) => {
    const {filters = {}, setFilters = () => {}} = props
    return (
        <div>
            <UniversityFilters
                setFilters={(universitiesIds) => {
                    setFilters({
                        ...filters,
                        universitiesIds
                    })
                }}
                filters={filters?.universitiesIds || []}
            />
        </div>
    )
}


export default StudentFilters