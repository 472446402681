import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import useCounselingTemplates from '../useCounselingTemplates'

const RedirectFromUrl = ({setTemplate = () => {}}) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const templateId = searchParams.get('templateId')
    const {open} = useCounselingTemplates()
    useEffect(() => {
        //abre el modal de templates
        if(templateId) open(templateId)
        // eslint-disable-next-line
    }, [templateId])
    return (<></>)
}

export default RedirectFromUrl