import { gql, useLazyQuery } from '@apollo/client'
import {
    Modal,
    IconClose,
    useWindowDimensions,
    ListOption,
    Avatar,
    StickyButton,
    Activity,
    Input,
    IconSearch
  } from '@Knowledge-OTP/znk-ui-components'
import React, { Fragment, useEffect, useState } from 'react'
import useTranslation from '../../../../i18n/useTranslation'
import isEmpty from 'lodash/isEmpty'

const SelectCounselor = (props) => {
    const { form, setEducator } = props
    const { planId } = form
    const [isOpen, setOpen] = useState(false)
    const [educatorSearch, setEducatorSearch] = useState(null)
    const [selectEducatorArray, setSelectEducatorArray] = useState([])
    const [selectEducator, setSelectEducator] = useState({})
    const { translate } = useTranslation()
    const { breakWidth } = useWindowDimensions()
    const multipleSelection = false
    useEffect(() => {
        if (form?.educator) setSelectEducator({...form?.educator, id: form?.educator?.educatorUserId})
        // eslint-disable-next-line
    }, [form?.educator])
    const [getCounselors, { data, loading }] = useLazyQuery(
        gql`query ($planId: ID!) {
            getCounselorByPlan(planId: $planId ) {
                id
                emails {
                    address
                    verified
                }
                countryId
                firstName
                lastName
                timezone                
            }
        }`
    )
    useEffect(() => {
        if (planId) {
            getCounselors({
            variables: {
              planId
            }
          })
        }
        // eslint-disable-next-line
    }, [planId])
    const educators = loading || !data || isEmpty(data) ? [] : data.getCounselorByPlan
    if (data) {
        console.log('counselors', data)
    }
    const toggle = () => { setOpen(!isOpen) }
    const pushEducatorToArray = (edu) => {
        const {id} = edu
        if(selectEducatorArray.map(({id}) => id).includes(id)) {
          //elimina educador del arreglo selectEducatorArray
          setSelectEducatorArray(selectEducatorArray.reduce((newArray, eduObject) => {
            if(eduObject.id !== id) newArray.push(eduObject)
            return newArray
          }, []))
        }
        else setSelectEducatorArray(selectEducatorArray.concat([edu]))
      }
    return (
        <Fragment>
        <div className='m-0 p-0 w-100' onClick={toggle}>
            {props.children}
        </div>
        <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('modal.newplan.selecteducator.title')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <div className='p-0 m-4'>
            <Input
                className='w-100 input-xs p-0 mb-3'
                placeholder='Serach counselor'
                addOnComponent={<IconSearch size={16} />}
                type='addon'
                onChange={(e) => {
                setEducatorSearch(e.target.value)
                }}
            />
            <div
                className='m-0 py-2 px-0' style={{
                maxHeight: '300px',
                overflow: 'hidden scroll'
                }}
            >
            {loading
              ? <div className='d-flex justify-content-center'><Activity /></div>
              : educators.filter(
                (edu) => educatorSearch !== null && educatorSearch !== '' ? new RegExp(educatorSearch, 'i').exec(`${edu.firstName} ${edu.lastName}`) : true
              ).map((edu) => {
                return (
                  <ListOption
                    key={edu.id}
                    className='mb-2'
                    id='x-0'
                    label={null}
                    selectedInput={
                      multipleSelection ? 
                      selectEducatorArray.map(({id}) => id).includes(edu.id) :
                      selectEducator.id === edu.id
                    }
                    childClassName='w-100'
                    icon={
                      <div className='d-flex align-items-center text-muted'>
                        <Avatar
                          name={`${edu.firstName} ${edu.lastName}`}
                          named={false}
                          uri={null}
                          size='avatar-small avatar-span-white'
                        />
                        <div className='d-flex align-items-center justify-content-between w-100'>
                          <span className='ml-2 h4'>
                            {`${edu.firstName} ${edu.lastName}`}
                          </span>
                          {/* {((parseInt(edu.educatorInfo.isCapped.split('/')[0]) > 0)) &&
                            <span className='mr-2'>
                              {`${edu.educatorInfo.isCapped.split('/')[0]} ${translate('modal.newplan.selecteducator.outof')} ${edu.educatorInfo.isCapped.split('/')[1]} ${translate('modal.newplan.selecteducator.weeks')}`}
                            </span>} */}
                        </div>
                      </div>
                    }
                    onClick={() => {
                      if(multipleSelection) pushEducatorToArray(edu)
                      else setSelectEducator(edu)
                    }}
                  />
                )
              })}
            </div>
            <StickyButton
                color='#696CFF'
                condition={true}
                disabled={
                multipleSelection ?
                selectEducatorArray.length === 0 :
                JSON.stringify(selectEducator) === JSON.stringify({})
                }
                label={translate('modal.newplan.selecteducator.title')}
                onClickButtonFunction={() => {
                    setEducator(
                        multipleSelection ?
                        selectEducatorArray :
                        { ...selectEducator, recommended: true }
                    )
                    toggle()
                }}
            />
        </div>
      </Modal>
      </Fragment>
    )
}

export default SelectCounselor