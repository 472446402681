import React, { useEffect, useState } from 'react'
import { SelectUser } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import { TabContent, TabPane } from 'reactstrap'
import rolesNames from '../../../../../VirtualChat/utils/rolesNames'
import useUsersQuery from '../../../../../Student/modal/InfoStudent/useQueries/useGetStaff'
import { ordenateStaffArray, notOrdenateUserArray } from '../../../../../Parent/Utils/ordenateStaffArrayForMeetings'

const SelectCounselor = (props) => {

    const { team, loading, selectUser, setSelectUser, userMeetingId = null } = props
    const [usersQuery, { data: dataUsers }] = useUsersQuery()
    const [allStaffArray, setAllStaffArray] = useState([])

    let defaultUser = {}
    // useEffect(() => {
    //     if (team.length > 0) {
    //         if (selectUser) return
    //         // eslint-disable-next-line
    //         defaultUser = team.filter(user => user.role === rolesNames.counselor)
    //         if (defaultUser) setSelectUser(defaultUser[0].id)
    //     }
    // }, [team])

    useEffect(() => {
        if (allStaffArray?.length > 0) {
            if (selectUser) return
            if (!userMeetingId) {
                // eslint-disable-next-line
                defaultUser = team.filter(user => user.role === rolesNames.counselor)
                // return
            } else {
                // eslint-disable-next-line
                defaultUser = allStaffArray.filter(user => user?.id === userMeetingId)
            }
            if (defaultUser) setSelectUser(defaultUser[0]?.id)
        }
    }, [allStaffArray]) 

    useEffect(() => {
        usersQuery({
            variables: {
              role: 'SUPER_ADMIN', 
              limit: 0, 
              showInactive: false
            }
          })
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        ordenateStaffArray(team, dataUsers?.allUsersFilter?.docs, allStaffArray, setAllStaffArray)
    // eslint-disable-next-line
    }, [dataUsers, team])
    
    
    return (
        <TabContent activeTab={loading ? 'loading' :
        team ? 'show' : 'loading'}>
            <TabPane tabId={`loading`}>
                <Skeleton count={1} width={60} height={20} className={`w-100 my-1`} />
                <Skeleton count={1} width={100} height={40} className={`w-100 my-1`} />
            </TabPane>
            <TabPane tabId={`show`}>
                <div className='mb-4 mt-1'>
                    <span className='h45 text-gray mb-2'>Meeting with</span>
                    {allStaffArray?.length > 0 && <SelectUser
                        className={'my-2'}
                        activeUsers={allStaffArray?.filter(function(staff){return staff !== undefined}) || []}
                        selectUser={selectUser}
                        setSelectUser={setSelectUser}
                        ordenateUserArray={notOrdenateUserArray}
                    />}
                </div>
            </TabPane>
        </TabContent>

    )
}

export default SelectCounselor



