import React, { useEffect, useState } from 'react'
import useAllGlobalVars from './Models/useGlobalVars'
import SelectGlobalCounselor from './Components/SelectCounselor'
import useUsersQuery from '../../Student/modal/InfoStudent/useQueries/useGetStaff'
import counselorTypes from './Utils/counselorTypes'
const GlobalCounselorsView = (props) => {
  const { data: globalVarsData } = useAllGlobalVars()
  const [usersQuery, { data: dataUsers, loading: loadingUsers }] = useUsersQuery()
  const [counselors, setCounselors] = useState([])
  let filteredArray = []

  useEffect(() => {
    usersQuery({
      variables: {
        role: 'SUPER_ADMIN',
        limit: 0,
        showInactive: false
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (globalVarsData?.length > 0) {
      // eslint-disable-next-line
      filteredArray = globalVarsData.filter((item) => item.type === counselorTypes.counseling || item.type === counselorTypes.livePrep)
      setCounselors(filteredArray)}
  }, [globalVarsData])
  


  return (
    <div className='d-flex flex-column'>
      <SelectGlobalCounselor
        staff={dataUsers?.allUsersFilter?.docs}
        loading={loadingUsers}
        counselors={counselors}
      />

    </div>
  )
}

export default GlobalCounselorsView
