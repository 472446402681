import { gql, useQuery } from '@apollo/client'

export default (vars) => {

    const { data, loading } = useQuery(
        gql`query($limit: Int
                  $next: String
                  $previous: String
                  $search: String)
                  {
            getUniversities(limit: $limit
                            sortAscending: true
                            sortField: "name"
                            next: $next
                            previous: $previous
                            search: $search
        ) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs
            docs {
                name
                id
        }
      }
    }`,
        {
            variables: vars
        }
    )
    return { loading, 
        data: data ? data.getUniversities : {}
    }
}