import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation ($studentServiceRateInput: StudentServiceRateInput!, $userId: ID!) {
        setServiceRateToStudent(studentServiceRateInput: $studentServiceRateInput, userId: $userId) {
            id
            emails {
              address
              verified
            }
            countryId
            gender
            spokenLanguages
            address
            city
            state
            zipCode
            firstName
            lastName
            phone
            timezone
            birthdate
            studentInfo {
              institution
              graduationDate
              gpa
              servicesRate {
                defaultRate
                serviceId
                name
              }
            }
            roles
        }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.setServiceRateToStudent : null, error, validationErrors, submit }
}
