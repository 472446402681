import React, {useEffect} from 'react'
import useUsers from "../../../../../Model/Users/List";
import EducatorFilter from '../../../../Payslips/Filters/Educators'

const TeacherFilter = (props) => {
    const {setLessonsState, originalLessons, educatorsFilters, setEducatorsFilters} = props
    const {data: educators, loading: loadingEducator} = useUsers({roles: ['EDUCATOR'], limit: 0})

    useEffect(() => {
        const list = []
        if (educatorsFilters.length !== 0) {
            // eslint-disable-next-line
            educatorsFilters.map(id => {
                const items = originalLessons.filter(lesson => {
                    return lesson.idEducator === id
                })
                const collection = items ? items : []
                if (collection.length !== 0) {
                    list.push({
                        name: `${collection[0].educator.firstName} ${collection[0].educator.lastName}`,
                        collection,
                    })
                }
            })
            setLessonsState({
                listType: 'collectionGroup',
                group: list,
            })
        } else {
            setLessonsState({
                listType: 'normalList',
                group: originalLessons,
            })
        }
        // eslint-disable-next-line
    }, [educatorsFilters])

    return (
        <EducatorFilter
            {...{
                students: !loadingEducator && educators && educators.docs ? educators.docs : [],
                studentFilters: educatorsFilters,
                setStudentFilters: setEducatorsFilters
            }}
            loading={loadingEducator}
        />
    )
}

export default TeacherFilter

