export const extractTeamFromPlan = (plan) => {
    if (!plan) return []
    let dataTeam = []
    if (plan.advisorData) {dataTeam.push({...plan.advisorData, 
        id: plan.advisorData.teamUserId,
        avatar: plan?.aditionalInfo.avatarAdvisor,
        role: 'advisor'})} else if (plan.advisor) {dataTeam.push({...plan.advisor, 
        id: plan.advisor.teamUserId,
        avatar: plan?.aditionalInfo.avatarAdvisor,
        role: 'advisor'})}
    if (plan.counselorData) {dataTeam.push({...plan.counselorData,
        id: plan.counselorData.teamUserId,
        avatar: plan?.aditionalInfo.avatarCouncelor,
        role: 'counselor'})} else if (plan.councelor) {dataTeam.push({...plan.councelor, 
        id: plan.councelor.teamUserId,
        avatar: plan?.aditionalInfo.avatarCouncelor,
        role: 'counselor'})}
    if (plan.headData) {dataTeam.push({...plan.headData,
        id: plan.headData.teamUserId,
        avatar: plan?.aditionalInfo.avatarHead,
        role: 'head'})} else if (plan.head) {dataTeam.push({...plan.head, 
        id: plan.head.teamUserId,
        avatar: plan?.aditionalInfo.avatarHead,
        role: 'head'})}

    return dataTeam
  }