import {useMutation, gql} from '@apollo/client'

const useSendTaskReminderEmail = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $sendToParent: Boolean
                $sendToStudent: Boolean
                $taskId: String!
                ) {
                sendTaskReminderEmail (
                    sendToParent: $sendToParent
                    sendToStudent: $sendToStudent
                    taskId: $taskId
                )
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError
        }
    )
}

export default useSendTaskReminderEmail