const filterNames = {
    role: 'Role:',
    search: 'Contains text:',
    serviceIds: 'Service IDs:',
    topicIds: 'Topics IDs:',
    testDate: 'Test Date:',
    classYear: 'Class of:',
    countriesIds: 'Country:',
    countries: 'Country:',
    statuses: 'Status:',
    status: 'Status:',
    kind: 'Kind:',
    STUDENT: 'Student',
    EDUCATOR: 'Educator',
    PARENT: 'Parent',
    SUPER_ADMIN: 'Staff',
  }
  
  export default filterNames
  