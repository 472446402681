import {
  CheckboxSelector,
  CheckboxSelectorInner,
  IconArrowDown,
} from '@Knowledge-OTP/znk-ui-components';
import React from 'react';
import { Badge } from 'reactstrap';
import useTranslation from '../../../../../i18n/useTranslation'
// import useTranslation from "../../../i18n/useTranslation";

const GraduationFilter = (props) => {
  const {
    graduationFilters,
    setGraduationFilters,
    graduationDates,
    useInner = false,
  } = props;
  const { translate } = useTranslation();
  const capitalize = (text) => {
    const result = text?.replace(/([A-Z])/g, " $1");
    return result?.charAt(0)?.toUpperCase() + result?.slice(1);
  };

  const checkboxSelectorProps = {
    setDataFilters: setGraduationFilters,
    direction: "bottomCenter",
    dataFilters: graduationFilters,
    typeFilter: "statuses",
    data: graduationDates?.map((item) => {
      let yearGrad = parseInt(item.replace("‘", "").split(" ").slice(-1));
      return { name: capitalize(item), id: yearGrad };
    }),
    strings: {
      viewAll: translate("filters.plans.viewAll"),
      buttonCancel: translate("filters.plans.buttonCancel"),
      buttonApply: translate("filters.plans.buttonApply"),
      search: translate("filters.plans.search"),
    },
  };

  return useInner ? (
    <CheckboxSelectorInner
      style={{ maxWidth: "auto" }}
      {...checkboxSelectorProps}
    />
  ) : (
    <CheckboxSelector {...checkboxSelectorProps}>
      <Badge className="mx-1 filters" pill color="option">
        <label style={{ cursor: "pointer" }} className="m-0 p-0">
          Class
          <IconArrowDown className="ml-2" size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  );
};
export default GraduationFilter;
