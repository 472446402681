import React from 'react'
import PaymentSchedule from './Sections/PaymentSchedule'
import PlanBreakdown from './Sections/PlanBreakdown'
import ScheduleDetails from './Sections/ScheduleDetails'
import Student from './Sections/Student'
import Team from './Sections/Team'
import PlanActionStatus from './Sections/PlanActionStatus'
import LiveprepTimeline from './Sections/LiveprepTimeline'

import ListOption from '../../../../../CounselingTemplates/Task/viewTaskModal/Components/ListOption'

const Resume = ({
    plan,
    setData,
    options
}) => {
    const sectionProp = {plan, setData}
    return (
        <>
            {
                plan?.status === 'sent' && 
                <>
                    <PlanActionStatus className={`my-1`} {...sectionProp} showTopLine={false}/>
                    <div className={`borderTop w-100 m-0`} />
                </>
            }
            <Student className={`my-5`} {...sectionProp}/>
            <div className={`borderTop w-100 m-0`} />
            
            <ScheduleDetails className={`my-5`} {...sectionProp}/>
            <div className={`borderTop w-100 m-0`} />
            <Team className={`my-5`} {...sectionProp}/>
            {
                plan?.timeline?.length > 0 && (
                    <>
                        <div className={`borderTop w-100 m-0`} />
                        <LiveprepTimeline className={`my-5`} {...sectionProp} />
                    </>
                ) 
            }
            <div className={`borderTop w-100 m-0`} />
            <PlanBreakdown className={`my-5`} {...sectionProp}/>
            <div className={`borderTop w-100 m-0`} />
            <PaymentSchedule className={`my-5`} {...sectionProp}/>
            {
                options.map((option, index) => {
                    return (
                        <ListOption 
                            showBottomLine={index < options.length - 1} 
                            showTopLine={index===0}
                            {... option} 
                        />
                    )
                })
            }
        </>
    )
}

export default Resume