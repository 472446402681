import React from 'react'
import {IconIllustratedTask} from '@Knowledge-OTP/znk-ui-components'
import DinamicHeightPage from '../../Components/DinamicHeightPage'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const FinishPage = (props) => {
    const {bodyRef, onFinish = () => {}} = props
    const {translate} = useTranslation()
    return (
        <DinamicHeightPage
            bodyRef={bodyRef}
        >
            <div className='icon-animation-rotate'>
                <IconIllustratedTask size={120}/>
            </div>
            <label onClick={onFinish} className="font-weight-bold h3 text-center mt-4">
                {translate("workspace.tasks.assignMultiTask.modal.pages.finish.text")} 
            </label>
        </DinamicHeightPage>
    )
}

export default FinishPage