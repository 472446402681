export const getTopicIdsInPlan = (parts, kind = 'lessonsPerWeek') => {
  return parts ? parts.reduce((prevArr, part) => {
    if (kind === 'lessonsPerWeek') {
      return part.lessonPerWeekPerTopic.reduce((arr, lpwpt) => {
        return arr.indexOf(lpwpt.topicId) !== -1 ? arr : arr.concat(lpwpt.topicId)
      }, prevArr)
    } else {
      return part.TotalPerTopic.reduce((arr, lpwpt) => {
        return arr.indexOf(lpwpt.topicId) !== -1 ? arr : arr.concat(lpwpt.topicId)
      }, prevArr)
    }
  }, []) : []
}
const TopicsField = (props) => {
  const { thisService, item } = props
  // Get array of plan Topics based on part info
  const thisTopics = getTopicIdsInPlan(item?.parts || item?.fixedParts, item.kind)
  // Get string of topic names
  const topicsString = thisTopics.reduce((str, planTopic, idx) => {
    const topicName = thisService
      ? thisService.topics.find((topic) => topic.id === planTopic)
      : null
    return `${str}${idx > 0 ? ',' : ''}${topicName ? topicName.name : ''}`
  }, '')
  return (
    topicsString
  )
}

export default TopicsField
