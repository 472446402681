import React, { useState, useEffect, useMemo } from 'react'
import {
  Modal,
  Button,
  IconClose,
  useWindowDimensions,
  StickyButton,
  Form,
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  parseToMutation,
  parseOcurrencesToCalendar,
  parseOcurrencesToMutationNewIndividualLesson,
  parseOcurrencesToSolve,
  closeCalendarConflict,
  callBackForConflict
} from '../utils.js'
import Educator from './Educator.js'
import LessonDetails from './LessonDetails.js'
import PricingAndPayment from './PricingAndPayment.js'
import ScheduleDetails from './ScheduleDetails.js'
import Student from './Student.js'
import ScheduleNewLessonFromAdmin from '../../../../Model/Lessons/Create/ScheduleNewLessonFromAdmin/index'
import ScheduleNewLessonGroupFromAdmin from '../../../../Model/Lessons/Create/ScheduleNewLessonGroupFromAdmin/index'
import SolveOcurrences from '../../../../Model/Lessons/SolveOcurrences/index'
import useTranslation from '../../../../i18n/useTranslation'
import { showAlert } from '../../../../common/Alert/util'

const NewLesson = (props) => {
  const { refetchPendingLessons } = props
  const dispatch = useDispatch()
  const { isOpen, defaultForm, isEdit: editingLesson } = useSelector((state) => state.modal.plan.newLessonModal)
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.newLessonModal',
      data: {
        isOpen: !isOpen,
        isEdit: false,
        defaultForm
      }
    })
  }
  const [form, setForm] = useState(defaultForm)
  useEffect(() => {
    setForm(defaultForm)
  }, [defaultForm])
  
  const isGroupLesson = useMemo(
    () => {
      return form?.typeLesson === 'group'
    }, [form]
  ) 

  const { submit: solveOcurrencesMutation, loading: loadSolve } = SolveOcurrences(
    (result) => {
      const ocurrenceResult = result?.data?.solveOcurrences 
      if (ocurrenceResult?.hasOccurrences) {
        const ocu = ocurrenceResult?.occurrencesList
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.scheduledConflict',
          data: {
            ...parseOcurrencesToCalendar(
              ocu,
              (dataCalendar) => {
                callBackForConflict(() => {
                  // mutacion para resolver recurrencias
                  solveOcurrencesMutation(
                    parseOcurrencesToSolve(
                      dataCalendar,
                      { lessonInput: parseToMutation(form, isGroupLesson), isGroup: isGroupLesson }
                    )
                  )
                }, dataCalendar, dispatch)
              }
            ),
            isOpen: true
          }
        })
      } else if (ocurrenceResult?.errorKey) {
        //alerta de error
        showAlert({
          text: ocurrenceResult?.message,
          status: 'error'
        }, dispatch)
      } else {
        closeCalendarConflict(dispatch)
        toggle()
      }
      refetchPendingLessons()
    },
    (error) => { console.error(error) }
  )

  const { submit: createIndividual, loading: loadIndividual } = ScheduleNewLessonFromAdmin(
    (result) => {
      const ocurrenceResult = result?.data?.scheduleNewLessonFromAdmin 
      if (ocurrenceResult?.hasOccurrences) {
        const ocu = ocurrenceResult?.occurrencesList
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.scheduledConflict',
          data: {
            ...parseOcurrencesToCalendar(
              ocu,
              (!ocu[0]?.recurringId)
                ? (dataCalendar) => {
                  callBackForConflict(() => {
                    // dataCalendar.ocurrences siempre debe venir cargado con solo UNA lección
                    const solveLesson = dataCalendar?.ocurrences[0]
                    if (['newBlock', 'force', 'setPending'].includes(solveLesson.resolve)) {
                      createIndividual (
                        parseOcurrencesToMutationNewIndividualLesson(
                          dataCalendar,
                          {
                            lessonInput:
                              solveLesson.resolve === 'newBlock'
                                ? parseToMutation(form, false)
                                : solveLesson.resolve === 'force'
                                  ? { // scheduled anyway
                                    ...parseToMutation(form, false),
                                    ignoreEducatorUnavailability: true
                                  }
                                  : { // Dejar pendiente
                                    ...parseToMutation(form, false),
                                    setToPending: true,
                                    lessonsPending: 1
                                  }
                          }
                        )
                      )
                    }
                  }, dataCalendar, dispatch)
                }
                : (dataCalendar) => {
                  callBackForConflict(() => {
                    // mutacion para resolver recurrencias
                    solveOcurrencesMutation(
                      parseOcurrencesToSolve(
                        dataCalendar,
                        { lessonInput: parseToMutation(form, isGroupLesson /**isGroup**/), isGroup: isGroupLesson }
                      )
                    )
                  }, dataCalendar, dispatch)
                }
            ),
            isOpen: true
          }
        })
      } else if (ocurrenceResult?.errorKey) {
        //alerta de error
        showAlert({
          text: ocurrenceResult?.message,
          status: 'error'
        }, dispatch)
      } else {
        closeCalendarConflict(dispatch)
        toggle()
      }
      refetchPendingLessons()
    },
    (e) => {
      console.error(e)
      alert('error')
    }
  )

  const { submit: createGroup, loading: loadGroup } = ScheduleNewLessonGroupFromAdmin(
    (result) => {
      if (result?.data?.scheduleNewLessonGroupFromAdmin?.hasOccurrences) {
        const ocu = result?.data?.scheduleNewLessonGroupFromAdmin?.occurrencesList
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.scheduledConflict',
          data: {
            ...parseOcurrencesToCalendar(
              ocu,
              (!ocu[0]?.recurringId)
                ? (dataCalendar) => {
                  callBackForConflict(() => {
                    // dataCalendar.ocurrences siempre debe venir cargado con solo UNA lección
                    const solveLesson = dataCalendar?.ocurrences[0]
                    if (['newBlock', 'force', 'setPending'].includes(solveLesson.resolve)) {
                      createGroup (
                        parseOcurrencesToMutationNewIndividualLesson(
                          dataCalendar,
                          {
                            lessonInput:
                            solveLesson.resolve === 'newBlock'
                              ? parseToMutation(form, true)
                              : solveLesson.resolve === 'force'
                                ? { // scheduled anyway
                                  ...parseToMutation(form, true),
                                  ignoreEducatorUnavailability: true
                                }
                                : { // Dejar pendiente
                                  ...parseToMutation(form, true),
                                  setToPending: true,
                                  lessonsPending: 1
                                }
                          }
                        )
                      )
                    }
                  }, dataCalendar, dispatch)
                }
                : (dataCalendar) => {
                  callBackForConflict(() => {
                    // mutacion para resolver recurrencias
                    solveOcurrencesMutation(
                      parseOcurrencesToSolve(
                        dataCalendar,
                        { lessonInput: parseToMutation(form, isGroupLesson), isGroup: isGroupLesson }
                      )
                    )
                  }, dataCalendar, dispatch)
                }
            ),
            isOpen: true
          }
        })
      } else if (result?.data?.reScheduleLessonFromStudent?.errorKey) {
        // no hace nada
      } else {
        closeCalendarConflict(dispatch)
        toggle()
      }
      refetchPendingLessons()
    },
    (e) => {
      console.error(e)
      alert('error')
    }
  )

  // const defaultDate = moment(fromCalendar).add(day, 'day').format('YYYY-MM-DD')
  // const defaultStartTime = moment(fromCalendar).add(day, 'day').set({hour: hour, miuntes: 0}).format('HH:mm')
   
  return (
    <Modal
      buttonLabel={null}
      className={`modal-colored modal-${
            breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-half'
      }  fixed-head-modal scheduled-modal`}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header p-0 m-0'>
          <div className='d-flex justify-content-between w-100 p-7'>
            <h2 className='font-weight-bold'>
              {translate('modal.newlesson.title')}
            </h2>
            <IconClose onClick={toggle} />
          </div>
        </div>
      }
    >
      <div className='px-6 px-sm-7 pt-6 m-0'>
        <Form value={form} onChange={setForm}>
          <div className='m-0 p-0'>
            <LessonDetails form={form} setForm={setForm} isEdit={editingLesson} />
            <div className='borderTop my-5' />
            <ScheduleDetails form={form} setForm={setForm} isEdit={editingLesson} />
            <div className='borderTop my-5' />
            <Student form={form} setForm={setForm} isEdit={editingLesson} />
            <div className='borderTop my-5' />
            <Educator form={form} setForm={setForm} isEdit={editingLesson} />
            <div className='borderTop my-5' />
            <PricingAndPayment form={form} setForm={setForm} isEdit={editingLesson} />
            <div className='borderTop my-5' />
            <StickyButton>
              <div className='m-0 p-0 row'>
                <Button
                  color='primary'
                  activity={loadIndividual || loadGroup || loadSolve}
                  className='col-12 aling-text-left'
                  label={translate('modal.newlesson.createlesson')}
                  onClick={() => {
                    if (form?.typeLesson === 'individual') {
                      createIndividual(
                        {
                          lessonInput: parseToMutation(form, false)
                        }
                      )
                    } else {
                      createGroup(
                        {
                          lessonInput: parseToMutation(form, true)
                        }
                      )
                    }
                  }}
                  style={{ borderRadius: '16px' }}
                />
              </div>
            </StickyButton>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default NewLesson
