import React from 'react'
import { Avatar, IconArrowRight, ListItem } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../../i18n/useTranslation'
const PlanStudent = (props) => {
  const { student, thisPayerUser, loading } = props
  const { translate } = useTranslation()
  return (
    <div className='d-flex flex-column w-100'>
      <h3 className='font-weight-bold text-dark my-3'>{translate('plans.modal.familyDetails')}</h3>
      <ListItem
        className='py-1'
        id='x-0'
        label={null}
        Left={
          <div className='d-flex align-items-center'>
            <Avatar
              name={`${student.firstName} ${student.lastName}`}
              named={false}
              uri={null}
              size='avatar-medium'
            />
            <label className='ml-2 my-0 h4'>
              {`${student.firstName} ${student.lastName}`}
              <span className='ml-1 text-gray font-weight-light'>({translate('plans.modal.student')})</span>
            </label>
          </div>
        }
        Right={<IconArrowRight />}
      />
      {
        loading ? <Skeleton /> : (thisPayerUser ? (
          <ListItem
            className='py-1'
            id='x-0'
            label={null}
            Left={
              <div className='d-flex align-items-center'>
                <Avatar
                  name={`${thisPayerUser.firstName} ${thisPayerUser.lastName}`}
                  named={false}
                  uri={null}
                  size='avatar-medium'
                />
                <label className='ml-2 h4 my-0'>
                  {`${thisPayerUser.firstName} ${thisPayerUser.lastName}`}
                  <span className='ml-1 text-gray font-weight-light'>({translate('plans.modal.parent')})</span>
                </label>
              </div>
            }
            Right={<IconArrowRight />}
          />
        ) : null)
      }
      <div className='borderTop mb-2 mt-4' />
    </div>
  )
}

export default PlanStudent
