import useTranslation from '../../../../i18n/useTranslation'

const HeaderFields = () => {
  const { translate } = useTranslation()
  return (
    [{
      text: translate('constants.activities.name')
    }, {
      text: translate('constants.activities.tbd'),
      className: 'justify-content-center'
    }, {
      text: translate('constants.activities.eventDate'),
      className: 'justify-content-center'
    }]
  )
}

export default HeaderFields
