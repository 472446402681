import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  IconReminder,
  IconSignPlus,
  IconSignCheck,
  IconTime,
  IconDate,
  FilterListItem
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import isEmpty from 'lodash/isEmpty'

const Lessons = (props) => {
  const { translate } = useTranslation()
  const { lessons, lessonsLoading, topicKeys, currentUserId } = props
  const [topicFilters, setTopicFilters] = useState([])
  const [activeFilter, setActiveFilter] = useState('all')
  const styles = {
    scheduled: {
      borderRadius: 8,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5
    },
    Pending: {
      borderRadius: 8,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      border: '1px solid #696CFF'
    },
    canceled: {
      borderRadius: 8,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      backgroundColor: '#ffe8e3'
    },
    pending: {
      borderRadius: 8,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      backgroundColor: '#f3fcd5'
    }
  }
  const firstLesson = !isEmpty(lessons) ? lessons.sort((a, b) => a.lessonTime - b.lessonTime).find((lesson) => lesson.status === 'Scheduled') : null
  const getStudentAttendance = (lesson) => {
    return lesson.kind === 'group-lesson' ? lesson.students.find(s => s.studentUserId === currentUserId)?.attendanceStatus : lesson.student.attendanceStatus
  }
  return (
    <div className='d-flex flex-column'>
      <div className='container-fluid d-flex px-0 mb-5'>
        <FilterListItem
          topicKeys={topicKeys}
          lessons={lessons}
          topicFilters={topicFilters}
          setTopicFilters={setTopicFilters}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
          filterStrings={{
            viewAll: translate('filters.plans.viewAll'),
            buttonCancel: translate('filters.plans.buttonCancel'),
            buttonApply: translate('filters.plans.buttonApply'),
            search: translate('filters.plans.search')
          }}
          strings={{
            topics: translate('filters.lessons.topics'),
            all: translate('filters.lessons.all'),
            missed: translate('filters.lessons.missed'),
            pending: translate('filters.lessons.pending'),
            attended: translate('filters.lessons.attended'),
            scheduled: translate('filters.lessons.scheduled'),
            canceled: translate('filters.lessons.canceled'),
          }}
        />
      </div>
      {lessonsLoading ? (
        <Skeleton count={4} height={26} />
      ) : lessons ? (
        lessons
        //.filter(({__typename}) => __typename !== "GroupLesson")
          .filter((lesson) => {
            return topicFilters.length ? topicFilters.includes(lesson.topic.id) : true
          })
          .filter((lesson) => {
            switch (activeFilter) {
              case 'all':
                return lesson.status !== 'Canceled'
              case 'missed':
                return (getStudentAttendance(lesson) === activeFilter && lesson.status === 'Finished')
              case 'attended':
                return (getStudentAttendance(lesson) === activeFilter && lesson.status === 'Finished') || (lesson.status === 'Scheduled' && getStudentAttendance(lesson) === 'checkIn')
              case 'Scheduled':
              case 'Pending':
                return activeFilter === lesson.status && !(getStudentAttendance(lesson) === 'checkIn')
              case 'Canceled':
                return lesson.status === 'Canceled'
              default:
                return true
            }
          })
          .map((item, idx) =>
            item.status === 'Scheduled' && !(getStudentAttendance(item) === 'checkIn') ? (
              <div
                style={styles.scheduled}
                className={`${
                  firstLesson && firstLesson.id === item.id ? 'bg-primary' : 'bg-secondary'
                } text-white d-flex align-items-center w-100 my-1 px-4 font-weight-light`}
                key={item.id}
              >
                <span className='flex-grow-1 text-truncate'>{item.lessonTime.format('dddd, Do MMM HHa')}</span>
                <span className='text-truncate'>
                  {`${item.educator.firstName} ${item.educator.lastName.charAt(0)}.`}
                </span>
                {firstLesson && firstLesson.id === item.id ? (
                  <IconReminder size={15} className='mx-3' />
                ) : (
                  <IconDate size={15} className='mx-3 text-white' />
                )}
              </div>
            ) : item.status === 'Pending' ? (
              <div style={styles.Pending} className='d-flex w-100 my-1 px-4 text-gray font-weight-light'>
                <span className='flex-grow-1 text-truncate'>{item.lessonTime.format('dddd, Do MMM HHa')}</span>
                <span className='text-primary'>
                  {`${item.educator.firstName} ${item.educator.lastName.charAt(0)}.`}
                  <IconTime size={16} className='mx-3' style={{ color: '#696CFF' }} />
                </span>
              </div>
            ) : (['pending', 'missed'].includes(getStudentAttendance(item))) ? (
              <div style={styles.canceled} className='d-flex w-100 my-1 px-4 text-gray font-weight-light'>
                <span className='flex-grow-1 text-truncate'>{item.lessonTime.format('dddd, Do MMM HHa')}</span>
                <span className='text-primary'>
                  {`${item.educator.firstName} ${item.educator.lastName.charAt(0)}.`}
                  <IconSignPlus size={15} className='mx-3' />
                </span>
              </div>
            ) : (
              <div style={styles.pending} className='d-flex w-100 my-1 px-4 text-gray font-weight-light'>
                <span className='flex-grow-1 text-truncate'>{item.lessonTime.format('dddd, Do MMM HHa')}</span>
                <span className='text-primary'>
                  {`${item.educator.firstName} ${item.educator.lastName.charAt(0)}.`}
                  <IconSignCheck size={15} className='mx-3' style={{ color: '#A7E521' }} />
                </span>
              </div>
            )
          )
      ) : null}
    </div>
  )
}

export default Lessons
