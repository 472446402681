import {useMutation, gql} from '@apollo/client'

const useDeleteCouncelingPlanFromAdminMutation = (onSuccess = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $planId: ID!
            ) {
                deleteCouncelingPlanFromAdmin (
                    planId: $planId
                )
            }
        `,
        {
            onCompleted: () => {
                onSuccess()
            }
        }
    )
}

export default useDeleteCouncelingPlanFromAdminMutation