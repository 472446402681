import {useSelector, useDispatch} from 'react-redux'
import validate from 'validate.js'

const constraints = {
    dueTime: {
        presence: true
    },
    instructions: {
        presence: true
    },
    dueDate: {
        presence: true
    }
}

// const constraintsCollege = {
//     university: {
//         presence: true
//     }
// }
const useAssingTaskModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.assingTaskModal)
    const {isOpen, onChange, taskData} = reduxData 
    const setOpen = (open, onChange = () => {}, data = {}, useMutation = false, loading = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.assingTaskModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                taskData: data,
                useMutation,
                loading
            }
        })
    }

    const setLoad = (load = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.assingTaskModal',
            data: {
                ...reduxData,
                loading: load
            }
        })
    }

    return {
        ...reduxData,
        isOpen,
        onChange,
        taskData,
        open: (onChange = () => {}, task = {}, useMutation = false) => {setOpen(true, onChange, task, useMutation)},
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)},
        setLoad,
        validation: (state) => {
            let universitiesValid = true
            // state.universities.length
            if (state.isEspecific) {
                universitiesValid = state.universities.length > 0 ? true : false
            }
            // const validationSpecificCollege = state.isEspecific ? constraintsCollege : {}
            const invalid = !!validate(state, {...constraints }) 
            return !invalid && universitiesValid
        }
    }
}

export default useAssingTaskModal