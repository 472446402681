import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import queryScheduleResult from '../../QueryResult/useScheduleResult.js'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading}] = useMutation(
    gql`mutation (
        $startDate: DateTime!
        $lessonId: String!,
        $ignoreEducatorUnavailability: Boolean,
        $setAsPending: Boolean
    ) {
        reScheduleLessonFromAdmin(
            startDate: $startDate,
            lessonId: $lessonId,
            ignoreEducatorUnavailability: $ignoreEducatorUnavailability,
            setAsPending: $setAsPending
            ){
              ${queryScheduleResult}
        }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      //client.resetStore()
      onSuccess(result, obj)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e, obj.oldDataLesson)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.reScheduleLessonFromAdmin : null, error, validationErrors, submit }
}
