import React, {useEffect} from 'react'
import { Table } from "@Knowledge-OTP/znk-ui-components"
import useColumns from './useColumns'
import { useLazyQuery, gql } from '@apollo/client'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'

//MODAL
import SetRateToPaymentAndLessonModal from './modal/SetRateToPaymentAndLesson'

const LessonHighCost = () => {
    const dispatch = useDispatch()
    const columns = useColumns()
    const zone = useSelector((state) => state.timezone.zone)
    const [getLessons, {data, loading, refetch}] = useLazyQuery(
        gql`
        query {
            getLessonPaymentAggregation {
                lessonId
                title
                studentRate
                educatorRate
                lessonStatus
                startDate
                payments {
                  status
                  amount
                  paymentId: _id
                }
            }
        }`,
        {variables: {}}
    )
    useEffect(() => {
        getLessons({variables: {}})  
    }, [getLessons])
    const parseLesson = (data?.getLessonPaymentAggregation || []).filter(
        (les) => {
            return (les.payments || []).map(({status}) => status).includes('pendingPayment')
        }).map((lesson) => {
        return {
            ...lesson,
            title: lesson?.title,
            startDate: moment(lesson?.startDate).tz(zone).format('DD MMMM YY, HH:mm a'),
            paymentsLabel: `P ${lesson?.payments?.length}`,
            studentRate: `SR: ${lesson.studentRate}`,
            educatorRate: `ER: ${lesson.educatorRate}`
        }
    })
    return (
        <div className={'m-0 p-0 mt-3 text-title'}>
            <div className={'w-100 d-flex flex-row justify-content-between'}>
                <span>Lecciones totales : {parseLesson?.length}</span>
                <span>Timezone : {zone}</span>
            </div>
            <Table
                loading={loading}
                columnList={columns}
                tableData={parseLesson}
                onClick={(item) => {
                    dispatch({
                        type: SET_DATA_MODAL_FORM_LIST,
                        payload: 'developer.setRateToPaymentAndLesson',
                        data: {
                          isOpen: true,
                          lessonId: item.lessonId,
                          payments: item.payments
                        }
                    })
                }}
            />
            <SetRateToPaymentAndLessonModal refetch={refetch} />
        </div>
    )
}

export default LessonHighCost