import { CheckboxSelector, CheckboxSelectorInner, IconArrowDown } from '@Knowledge-OTP/znk-ui-components'
import React, { useMemo } from 'react'
import { Badge } from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'

const TypesFilter = (props) => {
  const { translate } = useTranslation()
  const { types, typesFilters, setTypesFilters, useInner = false } = props
  const selectorData = useMemo(() => types.map((item) => ({
    name: translate(`filters.payments.${item}`),
    id: item
  })), [types, translate])

  const checkboxSelectorProps = {
    setDataFilters: setTypesFilters,
    direction: 'bottom',
    dataFilters: typesFilters,
    typeFilter: 'statuses',
    data: selectorData,
    strings: {
      viewAll: translate('filters.plans.viewAll'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
      search: translate('filters.plans.search')
    }
  }

  return (
    useInner ?
    <CheckboxSelectorInner style={{maxWidth: 'auto'}} {...checkboxSelectorProps}/> :
    <CheckboxSelector {...checkboxSelectorProps}>
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.payment.type')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default TypesFilter
