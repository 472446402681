import React, { useState, useEffect } from 'react'
import {
    IconArrowDown,
    IconArrowUp,
    ListItem,
} from '@Knowledge-OTP/znk-ui-components'
import Universities from '../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/Universities'
import useUser from '../../../../../CounselingPlans/Model/useUser'
import { Collapse } from 'reactstrap'

const UniversitySelector = (props) => {
    const { onChange = () => {}, className, userId, selectedArray, ...otherProps } = props
    const [isOpenCollapse, setOpenCollapse] = useState(false)
    const {data: user} = useUser({
        id: userId || ''
    })
    const universities = user?.user?.studentInfo?.universities

    const toggleCollapse = () => { setOpenCollapse(!isOpenCollapse) }
    const [selected, setSelected] = useState(selectedArray)
    //cada vez que cambia selected ejecuta onChange
    useEffect(
        () => {onChange(selected)}, 
        // eslint-disable-next-line
        [selected]
    )

    return (
        <>
            <ListItem
                onClick={toggleCollapse}
                className={`${className}`}
                Left={
                    <span className={`text-info`}>
                        Universities
                    </span>
                }
                Right={
                    <div className={`hover-icon`}>
                        {
                            isOpenCollapse ? <IconArrowUp/> : <IconArrowDown/>
                        }
                    </div>
                }
                {...otherProps}
            />
            <Collapse isOpen={isOpenCollapse}>
                <Universities 
                    selected={selected} 
                    setSelected={setSelected}
                    universities={universities?.length > 0 ? universities : null} selector />
            </Collapse>
        </>
    )
}

export default UniversitySelector