import React from 'react'
import {IconOptions} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'

const SkeletonListItem = (props) => {
    const {className = ''} = props
    return (
        <div className={`d-flex flex-row justify-content-between align-items-center w-100 ${className}`}>
            <div className={`d-flex flex-row align-items-center`}>
                <Skeleton className='mr-2' count={1} style={{borderRadius: '12px'}} width={40} height={40} />
                <div className={`d-flex flex-column justify-content-center`}>
                    <Skeleton count={1} width={100} height={12} />
                    <Skeleton count={1} width={70} height={10} />
                </div>
            </div>
            <IconOptions className={'text-gray rotate-90'} size={16}/>
            {/* <Skeleton count={1} style={{borderRadius: '12px'}} width={35} height={35} /> */}
        </div>
    )
}

export default SkeletonListItem