import React, { useState } from 'react'
import AlertNotification from '../Components/AlertNotification'
import useStudentDashboardPlansToSend from './Model/useStudentDashboardPlansToSend'
import useStudentDashboardPlansToComplete from './Model/useStudentDashboardPlansToComplete'
import useStudentDashboardPlansEndSoon from './Model/useStudentDashboardPlansEndSoon'
// import StatusesFilter from '../../../../Plans/Filters/Statuses'
import { TabContent, TabPane } from 'reactstrap'
import PlanModal from '../../../../Plans/modal'
import NewPlan from '../../../../Plans/modal/newPlan'
import DismissPlanModal from './Modal/DismissPlan'
import PostponeRemiderModal from './Modal/PostponeRemider'
import BodyTable from './Table/Body'
import './index.css'


const COMMING_STATUS = 'comming'
const tabSections = [
    {
        key: 'draft',
        label: 'Plans to Send'
    },
    {
        key: COMMING_STATUS,
        label: 'Plans Ending Soon'
    },
    {
        key: 'scheduled',
        label: 'Plans to Complete'
    },
]
const PlanList = () => {
    const [statusFilters, setStatusFilters] = useState(tabSections[0].key)


    const { data: dataToSend, refetch: refetchDashboardPlansToSend, loading: loadQueryToSend } = useStudentDashboardPlansToSend({
        limit: 0
    })
    const { data: dataEndSoon, refetch: refetchDashboardPlansEndSoon, loading: loadQueryEndSoon } = useStudentDashboardPlansEndSoon({
        limit: 0
    })
    const { data: dataToComplete, refetch: refetchDashboardPlansToComplete, loading: loadQueryToComplete } = useStudentDashboardPlansToComplete({
        limit: 0
    })

    const RenderFilter = () => {
        return (
            <>
                {
                    tabSections.map(
                        ({ key, label }) => {
                            return (<span className={`h45 nav-title ${statusFilters === key ? 'active' : ''}`} onClick={() => { setStatusFilters(key) }}>{label}</span>)
                        }
                    )
                }
            </>
        )
    }


    const refetch = () => {
        refetchDashboardPlansToSend()
        refetchDashboardPlansEndSoon()
        refetchDashboardPlansToComplete()
    }

    return (
        <>
            <TabContent className='w-100 h-100' activeTab={statusFilters}>
                <TabPane className='w-100 h-100 no-animation' tabId={'draft'}>
                    <BodyTable filterSection={<RenderFilter />} loading={loadQueryToSend} plans={dataToSend?.studentDashboardPlansToSend} refetch={refetchDashboardPlansToSend} />
                </TabPane>
                <TabPane className='w-100 h-100 no-animation' tabId={COMMING_STATUS}>
                    <BodyTable filterSection={<RenderFilter />} loading={loadQueryEndSoon} plans={dataEndSoon?.studentDashboardPlansEndSoon} refetch={refetchDashboardPlansEndSoon} />
                </TabPane>
                <TabPane className='w-100 h-100 no-animation' tabId={'scheduled'}>
                    <BodyTable filterSection={<RenderFilter />} loading={loadQueryToComplete} plans={dataToComplete?.studentDashboardPlansToComplete} refetch={refetchDashboardPlansToComplete} />
                </TabPane>
            </TabContent>
            <PlanModal refetch={() => { }} />
            <DismissPlanModal />
            <PostponeRemiderModal />
            <NewPlan refetch={() => { refetchDashboardPlansToSend() }} educators={[]} />
            <AlertNotification loading={loadQueryToSend || loadQueryEndSoon || loadQueryToComplete} show={false} onClick={() => { refetch() }} />
        </>
    )
}
export default PlanList