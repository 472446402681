import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IconLesson } from '@Knowledge-OTP/znk-ui-components'
import CardHeader from '../Components/CardHeader'
import Skeleton from 'react-loading-skeleton'
import PlanListItem from './PlanListItem'
import { TabContent, TabPane } from 'reactstrap'
// import socketIo from 'socket.io'
// import usePendingApprovePlans from './Model/usePendingApprovePlans'
import useSocketConnection from '../../../../../common/SocketGateway/useSocketConnection'
import useGetAllOnboardingSession from './Model/useGetAllOnboardingSession'
import AlertNotification from '../Components/AlertNotification'
import useTranslation from '../../../../../i18n/useTranslation'


const PlansPendingAprove = () => {
    const { translate } = useTranslation()
    const [show, setShow] = useState(false)
    const [fakeLoading, setFakeLoading] = useState(false)
    // const {data, loading} = usePendingApprovePlans({})
    const { data: dataSessions, loading, refetch = () => { } } = useGetAllOnboardingSession({})
    const sessions = useMemo(
        () => {
            return dataSessions?.getAllOnboardingSession || []
        }, [dataSessions]
    )
    const { connection: socketConnection } = useSocketConnection()
    const updateData = useCallback(() => {
        refetch()
    }, [refetch])
    useEffect(
        () => {
            socketConnection.on('updatePendingApprovePlan', () => {
                updateData()
            })
        }, [socketConnection, updateData]
    )

    const [timer, setTimer] = useState(null)
    const [timerSwitch, setTimerSwitch] = useState(false)
    const delayInSeconds = 20
    useEffect(() => {
        setTimer(
            setTimeout(() => {
                updateData()
                setTimerSwitch(!timerSwitch)
            }, delayInSeconds * 1000),
        )
        return () => {
            clearTimeout(timer)
        }
        // eslint-disable-next-line
    }, [timerSwitch])


    //MOSTRAR SOLO ONBOARDING CON SESSIONES ACTIVAS
    return (
        <>
            {/* Currently on going lessons */}
            <CardHeader
                titleModalInfo={translate("dashboard.plansPendingApprove.infoTitleModal")}
                innerInfo={translate("dashboard.plansPendingApprove.info")}
                loading={loading} className={`secondaryBackground`} searchRef={null}
                onReload={() => { refetch() }} title={translate("dashboard.plansPendingApprove.title") || 'Plan pending approve'} />
            <div className={`w-100 d-flex flex-row justify-content-center`}>
                <div className={`w-90 znk-scroll-horizontal`}>
                    <div className='p-0 m-0'>
                        <TabContent activeTab={loading ? `loading` : sessions?.length > 0 ? `show` : `blank`}>
                            <TabPane tabId={`loading`}>
                                <div className={`d-flex flex-row`}>
                                    {
                                        new Array(5).fill(0).map(
                                            () => {
                                                return (
                                                    <Skeleton
                                                        style={{ borderRadius: '12px' }}
                                                        className={`mx-2`} width={200} height={220} count={1} />
                                                )
                                            }
                                        )
                                    }
                                </div>
                            </TabPane>
                            <TabPane tabId={`show`}>
                                <div className={`d-flex flex-row`}>
                                    {
                                        sessions.map((session) => {
                                            const plan = session?.plan
                                            return (
                                                <PlanListItem
                                                    {...plan}
                                                    refetch={refetch}
                                                    onboardingStatus={session.onboardingStatus}
                                                    // type={index%2===0 ? `liveprep` : `counseling`}
                                                    className={`m-2`}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </TabPane>
                            <TabPane tabId={`blank`}>
                                <div className={`d-flex flex-row justify-content-center w-100`} style={{ height: '200px' }}>
                                    <div className={`d-flex flex-column justify-content-center align-items-center text-center`}>
                                        <IconLesson size={40} className={`text-disabled`} />
                                        <label className={`h45 mt-4 text-gray`}>There are no pending plans</label>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>

            <AlertNotification text={'Update Plan(s)'} loading={loading || fakeLoading} show={show} onClick={
                () => {
                    setFakeLoading(true)
                    refetch().then(
                        () => {
                            setFakeLoading(false)
                            setShow(false)
                        },
                        () => {
                            setFakeLoading(false)
                            setShow(false)
                        }
                    )
                }
            } />
        </>
    )
}

export default PlansPendingAprove