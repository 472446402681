import {useMutation, gql} from '@apollo/client'

const useUploadImgToS3 = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $bucket: String!
                $file: Upload!
            ) {
                uploadImgToS3 (
                    bucket: $bucket
                    file: $file
                ) 
            }
        `,
        {
            onCompleted: onSuccess,
            onError
        }
    )
}

export default useUploadImgToS3