import {nanoid} from 'nanoid'
const set = require('lodash/set')
const get = require('lodash/get')
const omit = require('lodash/omit')
// const filter = require('lodash/filter')
const cloneDeep = require('lodash/cloneDeep')

const usePdfEditorReducer = () => {

    const getElementByPath = (currentObjKey = null, documentArray = [], currentPage = 0) => {
        if(currentObjKey && documentArray?.length > 0) {
            const newDocumentArray = documentArray
            const objKeySplitedPoints = `[${currentPage}].${currentObjKey.split('%').join('.')}`
            return get(newDocumentArray, objKeySplitedPoints)
        }
        return null
    }


    const setElementByPath = (currentObjKey = null, documentArray = [], newElement = null, currentPage = 0) => {
        if(currentObjKey && documentArray?.length > 0 && newElement) {
            console.log("currentObjKey", currentObjKey)
            // const newDocumentArray = documentArray
            let element = newElement
            let cloneDocument = cloneDeep(documentArray)
            const objKeySplitedPoints = `[${currentPage}].${currentObjKey.split('%').join('.')}`
            if(element?.childrens?.length === 0) {
                element.childrens = []
            }
            
            set(cloneDocument, objKeySplitedPoints, element)

            return documentArray.map((originalElement, index) => {
                if(index === currentPage) return cloneDocument[index]
                return originalElement
            })
        }
        return documentArray
        // newDocumentArray[currentPage][path[0]]
    }

    const omitElementByPath = (currentObjKey, documentArray = [], currentPage = 0) => {
        const objKeySplitedPoints = `[${currentPage}].${currentObjKey.split('%').join('.')}`

        let cloneDocument = cloneDeep(documentArray)

        omit(cloneDocument, objKeySplitedPoints);
        return documentArray.map((originalElement, index) => {
            if(index === currentPage) {
                return cloneDocument[index]
            }
            return originalElement
        })
    }
    const cloneElementByPath = (currentObjKey, documentArray = [], currentPage = 0) => {
        const newDocumentArray = cloneDeep(documentArray)
        const objKeySplitedPoints = `[${currentPage}].${currentObjKey.split('%').join('.')}`
        //obtiene arreglo de childrens para pushear elemento clonado
        const childrenElements = cloneDeep(get(newDocumentArray, objKeySplitedPoints.substring(0, objKeySplitedPoints?.length-3)))
        //elemento clonado
        const cloneElement = cloneDeep(get(newDocumentArray, objKeySplitedPoints))
        set(
            newDocumentArray, 
            objKeySplitedPoints.substring(0, objKeySplitedPoints?.length-3), 
            childrenElements.concat([
                {...cloneElement, id: nanoid()}
            ])
        )

        return documentArray.map((originalElement, index) => {
            if(index === currentPage) return newDocumentArray[index]
            return originalElement
        })
    }

    return {
        getElementByPath,
        setElementByPath,
        omitElementByPath,
        cloneElementByPath
    }
}

export default usePdfEditorReducer