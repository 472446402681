import { gql, useLazyQuery } from '@apollo/client'

export default () => {
	return useLazyQuery(
		gql`query($userId: String) {
			userUpcomingLesson(userId: $userId) {
				id
				startDate
			}
		}`,
		{
			fetchPolicy: 'no-cache'
		}
	)
}
