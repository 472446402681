import React, { useState, useMemo, useEffect } from 'react'
import {
  Modal,
  StickyButton,
  IconClose,
  InputSelect,
  SelectOption
} from '@Knowledge-OTP/znk-ui-components'
import { FormGroup } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import PenalizeLessonMutation from '../../../../Model/Lessons/PenalizeLesson/index'
import useTranslation from '../../../../i18n/useTranslation'
import {escapeRegExp} from 'lodash'

const PenalizeLesson = () => {
  const { isOpen, dataLesson } = useSelector((state) => state.modal.plan.penalizeLesson)
  const dispatch = useDispatch()
  const { translate } = useTranslation()

  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.penalizeLesson',
      data: {
        isOpen: !isOpen,
        dataLesson
      }
    })
  }

  const [form, setForm] = useState({
    issue: 'completion'
  })

  const setNewDataLesson = (newDataLesson) => {
    // setea nuevos datos de la lección en redux
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.infoLessonModal',
      data: {
        isOpen: true,
        dataLesson: newDataLesson
      }
    })
  }

  const { submit: penalizeSubmit, loading: loadPenalize } = PenalizeLessonMutation(
    (result) => {
      // setea nuevos datos de la lección en redux
      setNewDataLesson(result?.data?.penalizeLesson)
      toggle()
      // reinicia formulario
      setForm({ issue: 'completion' })
    },
    (e) => {
      console.error(e)
      alert('error')
    }
  )

  // reinicia el formulario
  useEffect(() => {
    if (isOpen) setForm({ issue: 'completion' })
  }, [isOpen])

  const validation = useMemo(() => {
    let valid = true
    valid =
            (form?.issue !== null && form?.issue !== undefined) &&
            (form?.specific !== null && form?.specific !== undefined)
    return valid
  }, [form])

  const educator = dataLesson?.substitute || dataLesson?.educator || {}

  const options = {
    completion: {
      lnnotsent: {
        id: 'lnnotsent',
        text: translate('modal.penalizelesson.lnnotsent'),
        comment: translate('modal.penalizelesson.msgnotmeeting') + '\n' + translate('modal.penalizelesson.msgmeeting'),
        penalty: 5
      },
      lnmeeting: {
        id: 'lnmeeting',
        text: translate('modal.penalizelesson.lnmeeting'),
        comment: translate('modal.penalizelesson.penalty5'),
        penalty: 5
      },
      attendance: {
        id: 'attendance',
        text: translate('modal.penalizelesson.attendance'),
        comment: translate('modal.penalizelesson.penalty5'),
        penalty: 5
      }
    },
    lesson: {
      noshow: {
        id: 'noshow',
        text: translate('modal.penalizelesson.noshow'),
        comment: translate('modal.penalizelesson.penaltywage'),
        penalty: educator?.rate || 5
      },
      canceled24: {
        id: 'canceled24',
        text: translate('modal.penalizelesson.canceled24'),
        comment: translate('modal.penalizelesson.penaltyhalfwage'),
        penalty: educator?.rate / 2 || 5
      },
      failedsubmit: {
        id: 'failedsubmit',
        text: translate('modal.penalizelesson.failedsubmit'),
        comment: translate('modal.penalizelesson.penalty5'),
        penalty: 5
      }
    },
    spreadsheet: {
      missingdates: {
        id: 'missingdates',
        text: translate('modal.penalizelesson.missingdates'),
        comment: translate('modal.penalizelesson.commentspread'),
        penalty: 5
      },
      missingassig: {
        id: 'missingassig',
        text: translate('modal.penalizelesson.missingassig'),
        comment: translate('modal.penalizelesson.commentspread'),
        penalty: 5
      },
      missingscore: {
        id: 'missingscore',
        text: translate('modal.penalizelesson.missingscore'),
        comment: translate('modal.penalizelesson.commentspread'),
        penalty: 5
      },
      missingevaluation: {
        id: 'missingevaluation',
        text: translate('modal.penalizelesson.missingevaluation'),
        comment: translate('modal.penalizelesson.commentspread'),
        penalty: 5
      }
    }
  }

  const penalty = useMemo(() => {
    return options[form?.issue][form?.specific]?.penalty || 5
  }, [form.specific, form.issue, options])

  const commentary = useMemo(() => {
    return options[form?.issue][form?.specific]?.comment || null
  }, [form.specific, form.issue, options])

  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header px-6 pt-6 pb-4 d-flex flex-row justify-content-between'>
          <span className='h2 font-weight-bold'>{translate('modal.penalizelesson.title')}</span>
          <IconClose onClick={toggle} />
        </div>
      }
    >
      <div className='m-0 px-6'>
        <FormGroup className='w-100 d-flex flex-column'>
          <div className='my-2 w-100'>
            <span className='h4 text-gray'>{translate('modal.penalizelesson.issue')}</span>
            <InputSelect
              InputClassName='mt-2'
              onChange={(e) => {
                setForm({ ...form, issue: e, specific: null })
              }}
            filterOption={(obj, string) => {
    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
    return false
  }}
              value={form?.issue || null}
              isSearchable={false}
              isClearable={false}
              options={[
                { value: 'completion', label: translate('modal.penalizelesson.completion') },
                { value: 'lesson', label: translate('modal.penalizelesson.lesson') },
                { value: 'spreadsheet', label: translate('modal.penalizelesson.spreadsheet') }
              ]}
            />
          </div>
          <div className='my-2 w-100'>
            <span className='h4 text-gray'>{translate('modal.penalizelesson.specific')}</span>
            <SelectOption
              className='mt-2 w-100'
              onChange={(e) => {
                setForm({ ...form, specific: e })
              }}
              value={form?.specific || null}
              options={options[form?.issue] ? Object.values(options[form?.issue]) : []}
            />
          </div>
          {
            validation && (
              <div className='my-2 w-100' style={{ textAlign: 'justify' }}>
                <div className='d-flex flex-row justify-content-between h4 font-weight-bold'>
                  <span>{translate('modal.penalizelesson.penalty')}</span>
                  <span>{`-$${penalty.toFixed(2)}`}</span>
                </div>
                <div className='my-2'>
                  <span className='h4 text-gray'>{translate('modal.penalizelesson.commentary')}</span>
                  <textarea
                    onChange={(e) => {
                      setForm({ ...form, commentary: e.target.value })
                    }}
                    value={form.commentary || commentary}
                    placeholder='Type here'
                    className='znk-input w-100 p-3 mt-2'
                    style={{ height: '200px' }}
                  />
                </div>
              </div>
            )
          }
        </FormGroup>
        <StickyButton
          disabled={!validation}
          onClickButtonFunction={() => {
            penalizeSubmit({
              lessonId: dataLesson.id,
              penaltyInput: {
                specificIssueId: form.specific,
                issue: form.issue,
                specificIssue: form.specific,
                penaltyAmount: penalty,
                additionalComments: form.commentary || commentary
              }
            })
          }}
          activity={loadPenalize}
          color='red'
          className='w-100 mb-6'
          size='large'
          label={
            <div className='d-flex flex-row justify-content-between'>
              <span>{translate('modal.penalizelesson.title')}</span>
            </div>
          }
        />
      </div>
    </Modal>
  )
}

export default PenalizeLesson
