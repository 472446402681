import React from 'react'
import {
    Field,
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import { escapeRegExp } from 'lodash'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import { ordenateArrayByName } from '../../../../../../../../utils/ordenateArrayByName'
const ServiceComponent = (props) => {

    const servicesList = ordenateArrayByName(props?.services?.docs) || []
    const { selectedService, setSelectedService } = props
    const { translate } = useTranslation()

    return (

        <div className='d-flex justify-content-between mb-7 w-100'>
            <div className='d-flex flex-column w-100'

            >
                {
                    <Field
                        InputClassName='col-12 p-0'
                        type={InputSelect}
                        fieldName='service'
                        placeholder={translate('modal.newlesson.selectservice')}
                        value={selectedService}
                        options={
                            servicesList && servicesList.map((serv) => {
                                return { value: serv.id, label: serv.name }
                            })
                        }
                        filterOption={(obj, string) => {
                            if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                            return false
                        }}
                        onChange={(e) => {
                            const serv = servicesList &&
                                servicesList.reduce((obj, serv) => {
                                    if (serv.id === e) return { id: e, name: serv.name }
                                    return obj
                                }, {})
                            setSelectedService(serv.id || e)
                        }}
                    />
                }
            </div>
        </div>

    )


}

export default ServiceComponent