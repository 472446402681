import React from 'react'
import { CheckboxOption } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'

const IncludeAllUsersBox = (props) => {
    const {setAllIncludes = () => {}, ...otherProps} = props
    const {translate} = useTranslation()
    return (
        <div className='w-100 d-flex flex-row justify-content-end'>
        <CheckboxOption
            defaultState={false}
            onChange={setAllIncludes}
            {...otherProps}
        />
        <span className='ml-1'>{translate("lessons.calendar.includeAllUser")}</span>
        </div>
    )
}

export default IncludeAllUsersBox