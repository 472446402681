const userQueryResult = `
            id
          isActive
          emails {
            address
            verified
          }
          country {
            id
            name
            defaultStudentRate
            code
          }
          gender
          spokenLanguages
          createdAt
          address
          city
          state
          zipCode
          firstName
          lastName
          avatar
          phone
          timezone
          birthdate
          notes {
            id
            ownerId
            ownerFirstName
            ownerLastName
            content
            createdAt
            updatedAt
          }
          studentInfo {
            institution
            graduationDate
          studentType
          defaultRate
            gpa
            servicesRate {
              defaultRate
              serviceId
              name
            }
            parents {
              parentUserId
              managePayments
              manageApprovals
              firstName
              lastName
              email
            }
            notifications {
              missedLesson
              lessonsNotesReady
              lessonReminder {
                isAvailable
                duration
              }
            }
            homeNotification {
              councelingPlanId
              }
          }
          parentInfo {
            children {
              studentUserId
              managePayments
              manageApprovals
              firstName
              lastName
              email
            }
          }
          childrenPaymentMethod {
            studentId     
            registeredPaymentMethods {
              id
              kind
              ... on CardPaymentMethod {
                cardInfo {
                  last4
                  brand
                  expYear
                  expMonth
                }
              }
              ... on BankDebitPaymentMethod {
                validationStatus
                accountInfo {
                  bankName
                  last4
                }
              }
              ... on BankCreditPaymentMethod {
                status
                sourceInfo {
                  bankName
                  accountNumber
                  swiftCode
                  routingNumber
                }
              }
              ... on ManualPaymentMethod {
                manualPaymentInfo {
                  bankName
                  accountNumber
                  routingNumber
                  beneficiary
                  address
                  swiftCode
                }
              }
              ... on ZellePaymentMethod {
                zellePaymentInfo {
                  emailToSend
                }
              }
            }
            paymentMethod {
              kind
              ... on CardPaymentMethod {
                brand
                last4
                cardInfo {
                  brand
                  last4
                  expYear
                  expMonth
                }
              }
              ... on ManualPaymentMethod {        
                kind
                manualPaymentInfo {
                  bankName
                  accountNumber
                  routingNumber
                  beneficiary
                  address
                  swiftCode
                }
              }
              ... on BankDebitPaymentMethod {
                kind
                validationStatus
                accountInfo {
                  bankName
                  last4
                }
              }
              ... on BankCreditPaymentMethod {
                status
                sourceInfo {
                  accountNumber
                  routingNumber
                  bankName
                  swiftCode
                }
              }
              ... on ZellePaymentMethod {
                zellePaymentInfo {
                  emailToSend
                }
              }
            }
          }
          educatorInfo {
              id
            rating
              services {
                serviceId
                name
                defaultRate
                topics {
                  topicId
                  teachingTypes
                  name
                  individualRate
                  groupRate
                  isWaiveIndividual
                  isWaiveGroup
                }
              }
              availabilityBlocks {
                day
                hour
              }
              educatorAcademicInfo {
                educationLevel
                concentration
                university
                graduationDate
              }
              backupLink
              educatorNote
            }
            staffInfo {
              backupLink
            }
`
/**
          childrenPaymentMethod {
            studentId     
            registeredPaymentMethods {
              id
              kind
              ... on CardPaymentMethod {
                cardInfo {
                  last4
                  brand
                  expYear
                  expMonth
                }
              }
              ... on BankDebitPaymentMethod {
                validationStatus
                accountInfo {
                  bankName
                  last4
                }
              }
              ... on BankCreditPaymentMethod {
                status
                sourceInfo {
                  bankName
                  accountNumber
                  swiftCode
                  routingNumber
                }
              }
              ... on ManualPaymentMethod {
                manualPaymentInfo {
                  bankName
                  accountNumber
                  routingNumber
                  beneficiary
                  address
                  swiftCode
                }
              }
            }
            paymentMethod {
              kind
              ... on CardPaymentMethod {
                brand
                last4
                cardInfo {
                  brand
                  last4
                  expYear
                  expMonth
                }
              }
              ... on ManualPaymentMethod {        
                kind
                manualPaymentInfo {
                  bankName
                  accountNumber
                  routingNumber
                  beneficiary
                  address
                  swiftCode
                }
              }
              ... on BankDebitPaymentMethod {
                kind
                validationStatus
                accountInfo {
                  bankName
                  last4
                }
              }
              ... on BankCreditPaymentMethod {
                status
                sourceInfo {
                  accountNumber
                  routingNumber
                  bankName
                  swiftCode
                }
              }
            }
          }
**/          
export default userQueryResult