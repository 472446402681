import React, {useMemo} from 'react'
import { Field, IconArrowLeft, IconArrowRight, InputSelect } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import {escapeRegExp} from 'lodash'

const Pagination = ({ 
  pageSize, 
  size='large', //large, small
  changeSize, 
  clickNext, 
  clickPrevious, 
  hasPrevious, 
  hasNext, 
  activePage, 
  pageCount,
  loading = false,
  useSelectorChangeSize = true,
  bottomFix = false,
  innerSection = <div></div>,
  style = {},
  className,
  ...otherProps
}) => {
  const handledClickPrevious = (e) => {
    e.preventDefault()
    clickPrevious()
  }
  const handledClickNext = (e) => {
    e.preventDefault()
    clickNext()
  }


  const styleFix = useMemo(() => {
    if (bottomFix) {
      return {
        bottom: '0px',
        position: 'sticky',
        backgroundImage: `linear-gradient(
                to bottom, 
                rgba(255, 255, 255, 0), 
                rgba(255, 255, 255, 0.95) 25%
            )`,
        paddingTop: '15px',
        ...style,
      }
    }
    return {
      ...style,
    }
  }, [bottomFix, style])

  return (
    <div style={styleFix} className={`w-100 d-flex flex-column align-content-center mt-3 ${className}`} {...otherProps}>
      {innerSection}
      

      <div className={`w-100 d-flex flex-row ${useSelectorChangeSize ? 'justify-content-between' : 'justify-content-end'}`}>
        <div style={
          size === 'small' ? {} : { width: '200px' }
        } 
          className='d-flex flex-row align-items-center'>
          <div className={`mr-2 hover-icon ${!hasPrevious ? 'cursor-not-allowed' : ''}`} onClick={(event) => { handledClickPrevious(event) }}>
            <IconArrowLeft size={size === 'small' ? 12 : 20} color={hasPrevious && !loading ? '#2C3F58' : '#F0F4F8'} />
          </div>
          <label className={`mb-0 ${size === 'small' ? 'h45' : ''}`}>
            {
            loading ? 
              <Skeleton count={1} width={45} height={size === 'small' ? 15 : 20} /> :
              `${activePage} of ${pageCount}`
            }
          </label>
          <div className={`m-2 hover-icon ${!hasNext ? 'cursor-not-allowed' : ''}`} onClick={(event) => { handledClickNext(event) }}>
            <IconArrowRight size={size === 'small' ? 12 : 20} color={hasNext && !loading ? '#2C3F58' : '#F0F4F8'} />
          </div>
        </div>
        {
          useSelectorChangeSize ? (
            <div style={{ width: '100px' }}>
              <div className={`m-0 p-0`}>
                {
                  loading ? 
                    <Skeleton count={1} height={40} width={100} /> : 
                    <Field
                      type={InputSelect}
                      isClearable={false}
                      placeholder=''
                      fieldName=''
                      options={[10, 20, 30, 40, 50].map((serv) => ({ value: serv, label: serv }))}
                      InputClassName='w-100 m-0'
                      value={pageSize}
                      onChange={(value) => { changeSize(value) }}
                      menuPlacement={'top'}
                      filterOption={(obj, string) => {
                        if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                        return false
                      }}
                    />
                }
              </div>
            </div>
            ) : (null) 
        }
      </div>
    </div>
  )
}

export default Pagination
