import React, {useState} from 'react'
import { DropDownNew } from '@Knowledge-OTP/znk-ui-components'
import CreateElement from './CreateElement'
import {Collapse} from 'reactstrap'
import ControlsElement from './ControlsElement'
//menu para editar documento actual
//recibe documentObj
const Controls = (props) => {
    const {
        currentPage = 0,
        onDuplicatePage = () => {},
        setCurrentPage = () => {},
        onDeletePage = () => {},
        onCreateNewPage = () => {},
        onCloneElement = () => {},
        documentObj, 
        setDocumentObj, 
        className, 
        onDeleteElement = () => {},
        onClickElement = () => {}, onCreateNewElement = () => {}, ...otherProps} = props
    const [openMenu, setOpenMenu] = useState(
        {}
    )
    return (
        <div className={`${className}`} {...otherProps}>
            {
                [documentObj[currentPage]].map(
                    (page) => {
                        return (
                            <div className={`d-flex flex-column`}>
                                <div className={`d-flex flex-row w-100 justify-content-between`}>
                                    <div>
                                        <span className={`mr-2`}>{`Page ${currentPage+1}`}</span>
                                        <span className={`mr-1 btn-link`} onClick={onCreateNewPage}>Add</span>
                                        <span className={`mr-1 btn-link`} onClick={onDuplicatePage}>Duplicate</span>
                                        <span className={`mr-1 btn-link text-error`} onClick={onDeletePage}>Delete</span>
                                    </div>
                                    <div>
                                        <span className={`btn-link mr-2`} onClick={() => {setCurrentPage(currentPage-1)}} >{`Previous`}</span>
                                        <span className={`btn-link`} onClick={() => {setCurrentPage(currentPage+1)}} >{`Next`}</span>
                                    </div>
                                </div>
                                {
                                    Object.entries(page).map(
                                        ([sectionKey, elements]) => {
                                            return (
                                                <div className={`pl-1`}>
                                                    <span onClick={() => {setOpenMenu({
                                                        ...openMenu,
                                                        [sectionKey]: !!!openMenu[sectionKey]
                                                    })}} >{sectionKey}</span>
                                                    <Collapse isOpen={openMenu[sectionKey]}>
                                                        <div className={`d-flex flex-column m-0 p-0`}>
                                                            {
                                                                elements.map((element, index) => {
                                                                    return(
                                                                        element ?
                                                                        //recursivo
                                                                        <ControlsElement 
                                                                            onCloneElement={onCloneElement}
                                                                            onDeleteElement={onDeleteElement}
                                                                            keyObj={`${sectionKey}[${index}]`} 
                                                                            onClickElement={onClickElement} 
                                                                            className={`pl-2`} 
                                                                            element={element}
                                                                            onCreateNewElement={onCreateNewElement} /> : null
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <DropDownNew
                                                            className={``}
                                                            options={
                                                                [
                                                                    {component: <CreateElement onSelect={(type) => {
                                                                        onCreateNewElement(`${sectionKey}[${elements?.length}]`, null, type)
                                                                    }}/>}
                                                                ]
                                                            }
                                                            direction='bottomLeft'
                                                        >
                                                            <span className={`btn-link h45 `}>Create element</span>
                                                        </DropDownNew>
                                                    </Collapse>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        )
                    }
                )
            }
        </div>
    )
}

export default Controls