import React, {useMemo, useEffect, useState} from 'react'
import moment from 'moment';
import ScheduledList from '../OptionsList/ScheduledList'
import InprogressList from '../OptionsList/InprogressList'
import IncompleteList from "../OptionsList/IncompleteList";
import PendingList from "../OptionsList/PendingList";
import AttendedList from "../OptionsList/AttendedList";
import {useSelector} from "react-redux";
import {IconProgram, IconTime, IconSignCheck, IconSignPlus, Countdown} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from "../../../../i18n/useTranslation";
// import useTranslation from "../../../../i18n/useTranslation";

const ListItem = ({lesson}) => {
    const {translate} = useTranslation()
    const {completeLesson} = lesson
    // const {translate} = useTranslation()
    const {zone} = useSelector(state => state.timezone)
    const [timeToStart, setTimeToStart] = useState(
        moment.duration(moment(completeLesson.startDate).diff(moment())).asMinutes(),
    )
    useEffect(() => {
        const initTime = moment.duration(moment(completeLesson?.startDate).subtract(10, 'minutes').diff(moment()),).asMilliseconds()
        const timer = setTimeout(() => {
            setTimeToStart(moment.duration(moment(completeLesson?.startDate).diff(moment())).asMinutes())
        }, initTime)
        return () => {
            clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completeLesson])
    const [createdDate, updateDate, statusOfInfoLesson, htmlStatus] = useMemo(
        () => {
            let htmlStatus = ''
            const studentAux = completeLesson ? (completeLesson.student || completeLesson.studentPlan || completeLesson.studentGroup) : null
            let statusOfInfoLesson = 'scheduled'
            if (completeLesson.status === 'finished') {
                if (completeLesson?.notes?.status === "draft") {
                    statusOfInfoLesson = 'incomplete'
                    htmlStatus = <span>{translate('calendar.sections.filters.incomplete')}<IconTime className="ml-2" size={20}/></span>
                } else if (Array.isArray(studentAux)) {
                    statusOfInfoLesson = 'finished-group'
                    const allMissed = studentAux.filter(({attendanceStatus}) => attendanceStatus === 'missed' || attendanceStatus === 'pending').length === studentAux.length
                    const educatorNoShow = lesson.educator.attendanceStatus === 'missed' || lesson.educator.attendanceStatus === 'pending'
                    if (educatorNoShow){
                        htmlStatus = <span>{translate('modal.infolesson.noshow')}<IconSignPlus className="ml-2" size={20}/></span>
                    } else if (allMissed){
                        htmlStatus = <span>{translate('calendar.sections.filters.missed')}<IconSignPlus className="ml-2" size={20}/></span>
                    } else if (educatorNoShow && allMissed) {
                        htmlStatus = <span>{translate('calendar.sections.filters.missed')}<IconSignPlus className="ml-2" size={20}/></span>
                    } else {
                        htmlStatus = <span>{translate('calendar.sections.filters.completed')}<IconSignCheck className="ml-2" size={20}/></span>
                    }
                } else {
                    statusOfInfoLesson = `finished-${studentAux?.attendanceStatus || 'missed'}`
                    const educatorNoShow = lesson.educator.attendanceStatus === 'missed' || lesson.educator.attendanceStatus === 'pending'
                    const studentNoShow = studentAux.attendanceStatus === 'missed' || studentAux.attendanceStatus === 'pending'
                    if (educatorNoShow){
                        htmlStatus = <span>{translate('modal.infolesson.noshow')}<IconSignPlus className="ml-2" size={20}/></span>
                    } else if (studentNoShow){
                        htmlStatus = <span>{translate('calendar.sections.filters.missed')}<IconSignPlus className="ml-2" size={20}/></span>
                    } else if (educatorNoShow && studentNoShow){
                        htmlStatus = <span>{translate('calendar.sections.filters.missed')}<IconSignPlus className="ml-2" size={20}/></span>
                    } else {
                        htmlStatus = <span>{translate('calendar.sections.filters.completed')}<IconSignCheck className="ml-2" size={20}/></span>
                    }
                }
            } else if (completeLesson.status === 'pending') {
                statusOfInfoLesson = 'pending'
                htmlStatus = <span>Pending<IconTime className="ml-2" size={20}/></span>
            } else {
                if (moment(completeLesson.startDate) < moment() && moment() < moment(completeLesson.endDate)) {
                    // lección en curso
                    statusOfInfoLesson = 'inprogress'
                    htmlStatus = <span>{translate('calendar.sections.filters.inProgress')}<IconTime className="ml-2" size={20}/></span>
                } else if (moment() > moment(completeLesson.endDate)) {
                    // lección ya pasó y no está finished
                    statusOfInfoLesson = 'incomplete'
                    htmlStatus = <span>{translate('calendar.sections.filters.incomplete')}<IconTime className="ml-2" size={20}/></span>
                } else if (moment() < moment(completeLesson.startDate)) {
                    // lección aún no sucede
                    statusOfInfoLesson = 'scheduled'
                    htmlStatus =
                        timeToStart < 10
                            ?
                            <span>
                            <div className='d-flex flex-column'>
                               <Countdown
                                   endTime={
                                       completeLesson?.startDate
                                           ? moment(completeLesson?.startDate).tz(zone)
                                           : moment().tz(zone)
                                   }
                                   iconColor='#FFFFFF'
                                   timeOverLabel={<span>{translate('calendar.sections.filters.inProgress')}</span>}
                                   spanTimeClassName='h45 text-white'
                                   spanSecondsClassName='h45 text-white'
                               />
                            </div>
                        </span>
                            :
                            <span>{translate('calendar.sections.filters.upcoming')}<IconProgram className="ml-2" size={20}/></span>
                }
            }
            return [
                completeLesson && (completeLesson.IndividualCreated || completeLesson.PlanCreated || completeLesson.GroupCreated || completeLesson.CounselorCreated),
                completeLesson && (completeLesson.IndividualUpdate || completeLesson.PlanUpdate || completeLesson.GroupUpdate || completeLesson.CounselorUpdate),
                statusOfInfoLesson,
                htmlStatus
            ]
            // eslint-disable-next-line
        }, [lesson])
    return (
        <div className={`znk-invoice-row lesson-${lesson.status}`}
             style={{cursor: 'pointer'}}>
            <span onClick={lesson.onClickLesson}>{lesson.title}</span>
            <span onClick={lesson.onClickLesson}>{moment(lesson.completeLesson.startDate).format('LLLL')}</span>
            <span onClick={lesson.onClickLesson}>{`${lesson?.educator?.firstName} ${lesson?.educator?.lastName}`}</span>
            <span onClick={lesson.onClickLesson}>{htmlStatus}</span>
            <span>
                {
                    statusOfInfoLesson === 'scheduled'
                        ?
                        <ScheduledList lesson={lesson.completeLesson}
                                       createdDate={createdDate}
                                       updateDate={updateDate}/>
                        : statusOfInfoLesson === 'inprogress'
                            ?
                            <InprogressList lesson={lesson.completeLesson}
                                            createdDate={createdDate}
                                            updateDate={updateDate}/>
                            : statusOfInfoLesson === 'incomplete'
                                ?
                                <IncompleteList lesson={lesson.completeLesson}
                                                createdDate={createdDate}
                                                updateDate={updateDate}/>
                                : statusOfInfoLesson === 'pending'
                                    ?
                                    <PendingList lesson={lesson.completeLesson}
                                                 createdDate={createdDate}
                                                 updateDate={updateDate}/>
                                    :
                                    <AttendedList lesson={lesson.completeLesson}
                                                  createdDate={createdDate}
                                                  updateDate={updateDate}
                                    />
                }
                            </span>
        </div>
    )
}

export default ListItem
