import validate from 'validate.js'

const constraints = {
  userId: {
    presence: true
  },
  updatedUser: {
    presence: true
  }
}

export default (data) => {
  const errs = validate(data, constraints)
  return errs
}
