import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'

const useUniveristyDirectory = () => {
  const dispatch = useDispatch()
  const reduxData = useSelector(
    (state) => state.modal.counseling.univeristyDirectory,
  )
  const {isOpen, onChange, refetch} = reduxData
  const setOpen = (open, onChange = () => {}, userId = null, planId = null, universities = [], refetch = () => {}) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.univeristyDirectory',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        userId, //usuario al cual se aplica mutación setMyUniversity
        planId,
        universities,
        refetch
      },
    })
  }
  return {
    ...reduxData,
    isOpen,
    onChange,
    open: (onChange = () => {}, userId = null, planId = null, universities = [], refetch = () => {}) => {
      setOpen(true, onChange, userId, planId, universities, refetch)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
    refetch,
  }
}
export default useUniveristyDirectory
