import React from 'react'
import {
  IconClose
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import EditProfileContent from './editProfileContent'

const PersonalModalForm = (props) => {
  const { 
    toggleRightSide,
    // countries: itemsCountry, refetchList, refetchUser, actionAfterUpdate 
  } = props
  const { isOpen } = useSelector((state) => state.modal.user.edit)
  const { isOpen: isOpenShort } = useSelector((state) => state.modal.user.editShort)
  const dispatch = useDispatch()
  
  const closeOver = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.edit', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.editShort', data: { isOpen: false } })
  }
  
  
  
  return isOpen || isOpenShort ? (
    <div className='w-100 py-5 px-8 text-title'>
      <span className='d-flex w-100 flex-row justify-content-between'>
        <h2 className='font-weight-bold'>Edit Profile Info</h2>
        <IconClose size={20} onClick={toggleRightSide || closeOver} color='rgba(119, 134, 153, .4)' />
      </span>
      <EditProfileContent {...props} />
    </div>
  ) : null
}

export default PersonalModalForm
