export const findTasksOnPlan = (planTask, taskData) => {
    //RETORNA EL ARREGLO DE TASKS CONTENIDO EN phase
    //  CON taskData?.phaseId Y step CON taskData?.stepId 
    const tasks =
        planTask
            ?.find(
                ({phaseName}) => phaseName === taskData?.phaseId
            )
            ?.steps?.find(
                ({id}) => id === taskData?.stepId
            )?.tasks
    // const task = tasks[tasks?.length - 1]
    return tasks
}

export default {
    findTasksOnPlan
}