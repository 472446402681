import React, {useMemo} from 'react'
import {
    CheckboxSelector,
    IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'
import useGetUniversitiesStudent from '../../../../../../Model/useGetUniversitiesStudent'

const UniversityFilters = (props) => {
    const {filters = [], setFilters = () => {}} = props
    const {data} = useGetUniversitiesStudent()
    const universities = useMemo(
        () => {
            return data?.getUniversitiesStudent || []
        }, [data]
    )
    return (
        <CheckboxSelector
            setDataFilters={setFilters}
            direction='bottom'
            dataFilters={filters}
            data={universities.map(
                ({id, name}) => {
                    return {name, id}
                }
            )}
            typeFilter='status'
            strings={{
                viewAll: 'View all',
                buttonCancel: 'Cancel',
                buttonApply: 'Apply',
                search: 'Search'
            }}
            >
            <Badge className='mr-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                    Universities
                    <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default UniversityFilters