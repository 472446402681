import React, {useEffect, useState} from 'react'
import {IconArrowDown, IconLesson, IconLoad} from '@Knowledge-OTP/znk-ui-components'
import CardHeader from '../../../Components/CardHeader'
import PlanListItem from '../../PlanListItem'
import useStudentWithoutLessonsLazy from '../../Model/useStudentWithoutLessonsLazy'
import useTranslation from '../../../../../../../i18n/useTranslation'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from '../../PlanListItem/Skeleton'

const LIMIT = 4

const StudentsList = (props) => {
    const {onViewPlot = () => {}} = props
    const [query, setQuery] = useState({limit: LIMIT, skip: 0})
    const [getStudents] = useStudentWithoutLessonsLazy()
    const [fakeLoading, setFakeLoading] = useState(false)
    const [showMoreLoading, setShowMoreLoading] = useState(false)

    const [students, setStudents] = useState([])
    const [hideShowMore, setHideShowMore] = useState(false)
    useEffect(() => {
        const isFirtsExecution = query.skip === 0
        if(isFirtsExecution) setFakeLoading(true) // primera carga
        else setShowMoreLoading(true)
        getStudents({variables: query}).then(
            (res) => {
                const newStudents = res?.data?.studentWithoutLessons
                setHideShowMore(newStudents.length < query.limit)
                setStudents(
                    isFirtsExecution ? 
                        newStudents || [] :
                        (students || []).concat(newStudents || [])
                )
                setFakeLoading(false)
                setShowMoreLoading(false)
            },
            (err) => {
                console.error(err)
                setFakeLoading(false)
                setShowMoreLoading(false)
            }
        )
       // eslint-disable-next-line 
    }, [query])
    
    const {translate, replaceVariables} = useTranslation()
    return (
        <>
            <CardHeader
                className={`secondaryBackground`}
                title={translate('dashboard.studentWithoutLessons.title')}
                titleModalInfo={translate('dashboard.studentWithoutLessons.infoTitleModal')}
                subtitle={`/ ${replaceVariables(translate('dashboard.studentWithoutLessons.totalStudents'), {'TOTAL': (students || [])?.length})}`}
                useControls={false}
            />
            <div className='d-flex flex-column w-100 m-0 p-0'>
                <TabContent activeTab={fakeLoading ? 'loading' : students?.length > 0 ? 'show' : 'blank'}>
                    <TabPane tabId={'loading'}>
                        {
                            new Array(10).fill(0).map(
                                () => {
                                    return (<Skeleton className={'px-4 py-2'}/>)
                                }
                            )
                        }
                    </TabPane>
                    <TabPane tabId={'show'}>
                        {
                            students.map(
                                (student, index) => {
                                    return (
                                        <PlanListItem
                                            refetch={() => {
                                                // const newLimit = query.skip+LIMIT
                                                setQuery({...query, skip: 0})
                                                // setFakeLoading(true)
                                                // refetch().then(
                                                //     () => {setFakeLoading(false)}, 
                                                //     () => {setFakeLoading(false)}
                                                // )
                                            }}
                                            isActive={student?.isActive}
                                            studentName={`${student?.firstName} ${student?.lastName}`}
                                            studentId={student?.userId}
                                            lastLessonIsoString={student?.lastLesson.endDate}
                                            onViewPlot={onViewPlot}
                                            index={index}
                                            className={index % 2 === 0 ? `` : 'secondaryBackground'}
                                        />
                                    )
                                }
                            )
                        }
                    </TabPane>

                    <TabPane className={`w-100`} tabId={`blank`}>
                            <div className={`d-flex flex-row justify-content-center w-100`} style={{height: '200px'}}>
                                <div className={`d-flex flex-column justify-content-center align-items-center text-center`}>
                                    <IconLesson size={40} className={`text-disabled`}/>
                                    <label className={`h45 mt-4 text-gray`}>No students</label>
                                </div>
                            </div>
                        </TabPane>
                </TabContent>
            </div>

            {
                !hideShowMore && (
                    <div 
                    className='w-100 d-flex flex-row justify-content-center py-2'
                    ><span className={'h45 btn-link text-gray d-flex flex-row align-items-center'} onClick={() => {
                        setQuery({...query, limit: LIMIT, skip: students?.length /**query.skip+LIMIT**/})
                    }}>Show more {showMoreLoading ? <IconLoad className={'ml-1'} size={12} /> : <IconArrowDown className={'ml-1'} size={12}/>}</span></div>
                )
            }
        </>
    )
}

export default StudentsList