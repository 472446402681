import React, { useEffect, useState, useMemo } from 'react'
import {
    ModalLayout, RedRibbon,
    Form, ScoreListItem, ScoreItem,
    IconArrowRight, Button
} from '@Knowledge-OTP/znk-ui-components'
import { ScoreMethods } from './methods'
import { FormGroup, Label } from 'reactstrap'
import useLazyGetExamScore from './Model/useLazyGetExamScore'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { TabContent, TabPane } from 'reactstrap'
import './index.css'
import useSubmitScoreModal from './useSubmitScoreModal'
import usePlans from '../../../../../../Student/modal/InfoStudent/useQueries/usePlans'
import { useSelector} from 'react-redux'
import AllStudentPlans from './Components/allStudentPlans'
import AllActivities from './Components/allActivities'
import useLazyEvent from './Model/useLazyEvent'
import useActivities from '../../../../../../CounselingTemplates/Task/Model/useActivities'
import Student from '../../../../../../Workspace/Sections/Scores/Sections/newScore/Student'
import usePaginatedServices from '../../../../../../../Model/Services/List/index'
import ServiceComponent from '../../../../../../Student/modal/InfoStudent/Learn/Sections/Exams/ServiceComponent'
import useGetExamScoreByService from './Model/useGetOneExamScoreByService'

// const calculateOverallForExam = (exam) => {
//     const principalSection = (exam?.results || []).find(({ isPrincipal }) => isPrincipal)
//     if (!principalSection) return 0
//     const process = ScoreMethods[principalSection.method] || ScoreMethods['default']
//     return process(principalSection.values)
// }
const calculateOverallForSection = (result) => {
    const process = ScoreMethods[result.method] || ScoreMethods['default']
    return process(result.values)
}
const replaceScoreOnField = (exam = {}, newScore, indexSection, indexField) => {
    return {
        ...exam,
        // examDate: moment().add(2, 'months').toISOString(),
        results: (exam?.results || []).map(
            (section, indexS) => {
                if (indexS === indexSection) {
                    return {
                        ...section,
                        values: (section?.values || []).map(
                            (field, indexF) => {
                                if (indexF === indexField) {
                                    return { ...field, value: newScore }
                                }
                                return field

                            }
                        )
                    }
                }
                return section
            }
        )
    }
}
const initExamFormObject = (exam = {}) => {
    return {
        ...exam,
        results: (exam?.results || []).map(
            (section) => {
                return {
                    ...section,
                    values: section.values.map(
                        (field = {}) => {
                            const scale = (field?.scale || '0-10').split('-')
                            return {
                                ...field,
                                value: scale[0] ? parseInt(scale[0]) : 0,
                            }
                        }
                    )
                }
            }
        )
    }
}


export const SUBMITSCOREMODE = {
    create: "create",
    view: "view",
    edit: "edit",
}


const SubmitScore = () => {

    const {
        isOpen,
        toggle = () => { },
        exam: examData,
        examScoreId,
        loading,
        onChange = () => { },
        mode = SUBMITSCOREMODE.create, //[create, view, edit]
        showPlansToAdd,
        planId = '',
    } = useSubmitScoreModal()
    const [, setText] = useState(examScoreId)
    const taskInfoRedux = useSelector(
        (state) => state?.modal?.taskOnSubmitScoreModal?.taskData || {},
    )

    useEffect(() => setText(examScoreId), [examScoreId])
    const [getExamScore, { loading: loadGetExam }] = useLazyGetExamScore()
    const { data: servicesData } = usePaginatedServices({ limit: 200 })
    const [getPlans, { refetch: refetchPlans, data: dataPlans }] = usePlans()
    const [getEvent] = useLazyEvent()
    const [eventResult, setEventResult] = useState(null)
    const [examScoreForm, setExamScoreForm] = useState(examData)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [selectedActivity, setSelectedActivity] = useState(null)
    const [activities, setActivities] = useState([])
    const [infoPlan, setInfoPlan] = useState(null)
    const [viewScore, setViewScore] = useState(false);
    const [selectedService, setSelectedService] = useState(null)
    const [getExamScoreByService] = useGetExamScoreByService();
    const [studentId, setStudentId] = useState(useSelector(
        (state) => state?.modal?.student?.infoStudentModal?.dataStudent?.id || null
    ))
    const [selectedUser, setSelectedUser] = useState(null)
    const [
        activitiesQuery,
        // eslint-disable-next-line
        // { data: dataActivities, loading: loadingActivities, refetch: activitiesRefetch },
      ] = useActivities()
    useEffect(() => {
        if (Array.isArray(selectedUser?.student)) {
            setStudentId(selectedUser?.student[0]?.studentId)
        }
    }, [selectedUser])
    //define formulatio para create
    useEffect(() => {
        if (mode === SUBMITSCOREMODE.create) {
            if (examScoreId) {
                //Usa el template
                getExamScore({
                    variables: { examScoreId: examScoreId }
                }).then((result) => {
                    setExamScoreForm(initExamFormObject(result?.data?.getOneExamScore))
                })
            }
        }
        // eslint-disable-next-line
    }, [mode, examScoreId])
    useEffect(() => {
        if (mode === SUBMITSCOREMODE.view) {
            setViewScore(true);
        }
        if (examData?.planInfo) {
            setSelectedPlan(examData?.planInfo)
        }
        if (examData?.planInfo?.activityTest) {
            setSelectedActivity({ id: examData?.planInfo?.activityTest })
        } else if (examData?.examId) {
            setSelectedActivity({ id: examData?.examId })
        }
    }, [mode, examData])
    useEffect(() => {
        activitiesQuery({
          variables: {
            kinds: ['test'],
            serviceIds: [],
            from: null,
            to: null
          },
        })
        // eslint-disable-next-line
      }, [])

    useEffect(() => {
      if(infoPlan) {
        const selectedActivityFilter = activities.find((activity) => activity?.startDate === infoPlan?.testDate)
        setSelectedActivity(selectedActivityFilter)  
    }
    //eslint-disable-next-line
    }, [infoPlan])

    useEffect(() => {
        async function fetchData() {
            if (mode === SUBMITSCOREMODE.create) {
                if (taskInfoRedux?.task?.examId) {                  
                    setEventResult((await getEvent({ variables: { activityId: taskInfoRedux?.task?.examId } })).data?.getOneEvent)
                    
                }
            }
        }
        fetchData();
        // eslint-disable-next-line
      }, [mode, taskInfoRedux])
      
    //define formulario para edit y view
    useEffect(() => {
        if ([SUBMITSCOREMODE.edit, SUBMITSCOREMODE.view].includes(mode)) {
            //usa examScore ya registrado
            setExamScoreForm(examData)
        }
    }, [mode, examData])
    useEffect(
        () => {
            if (!studentId) return
            const serviceToFilter = selectedService || examData?.examScoreId
            const variables = {
                studentsUserIds: [studentId],
                status: [
                    'completed',
                    'finished',
                    'scheduled'
                ],
                kind: [
                    'lessonsFixed',
                    'lessonsPerWeek'
                ],
            }
            if (serviceToFilter) {
                variables.serviceIds = [serviceToFilter]
            }
            getPlans(
                {
                    variables
                }
            )
            // eslint-disable-next-line
        }, [studentId, selectedService]
    )
    useEffect(() => {
        if (selectedService) {
            getExamScoreByService({
                variables: { serviceId: selectedService }
            }).then((result) => {
                setExamScoreForm(initExamFormObject(result?.data?.getOneExamScoreByService))
                // cuando es un score nuevo 
                setSelectedActivity({ serviceId: selectedService })
            })
        }
        if (studentId && selectedService) {
            refetchPlans(
                {
                    studentsUserIds: [studentId],
                    status: [
                        'completed',
                        'finished',
                        'scheduled'
                    ],
                    kind: [
                        'lessonsFixed',
                        'lessonsPerWeek'
                    ],
                    serviceIds: [selectedService]
                } 
            )

        }
        // eslint-disable-next-line
    }, [selectedService, studentId])
    const setScoreOnField = (newScore, indexSection, indexField) => {
        setExamScoreForm(replaceScoreOnField(examScoreForm, newScore, indexSection, indexField))
    }
    const onSubmit = () => {
        let principalSection = examScoreForm.results.find(({ isPrincipal }) => isPrincipal)
        if (!principalSection) principalSection = examScoreForm.results[0]
        if (!principalSection) throw new Error("Non Section !")
        const process = ScoreMethods[principalSection.method] || ScoreMethods['default']
        const examInput = {
            name: examScoreForm?.name,
            results:
                examScoreForm.results.map((section) => {
                    const { name, scale, values, isPrincipal, method } = section
                    const proccessSection = ScoreMethods[method] || ScoreMethods['default']
                    return ({
                        name: name,
                        scale: scale,
                        value: proccessSection(values.filter(({ isOnMethod = true }) => isOnMethod)),
                        // values: [ResultTemplateTaskInput!]!
                        isPrincipal: isPrincipal,
                        method: method,
                        values: values.map(
                            ({ name, scale, value, isOnMethod }) => {
                                return { name, scale, value, isOnMethod }
                            }
                        )
                    })
                }),
            examDate: selectedActivity?.startDate || examData?.examDate,
            examId: selectedActivity?.id || selectedActivity?.serviceId || null,
            examScoreId: selectedService || examData?.serviceId || null,
            overall: process(principalSection?.values.filter(({ isOnMethod = true }) => isOnMethod)),
            planId: planId || typeof (selectedPlan) === 'string' ? selectedPlan : selectedPlan?.id,
            studentId: studentId,
        }
        if (examData?.id && mode === SUBMITSCOREMODE.view) {
            examInput.id = examData.id;
        }
        onChange(examInput)
        // if (studentId) refetchScores ({ variables: { studentId: studentId } })

    }
    const examDate = eventResult?.startDate ? eventResult?.startDate : examScoreForm?.examDate

    const currentTabForm = useMemo(
        () => {
            return loadGetExam || !examScoreForm || Object.keys(examScoreForm).length <= 0 ? 'loading' : 'show'
        },
        [loadGetExam, examScoreForm]
    )

    useEffect(() => {
        if (mode === SUBMITSCOREMODE.create) {
            const planSelected = dataPlans?.plans?.docs.find( (plan) => plan.id === selectedPlan)
            setSelectedActivity({ id: planSelected?.activityTest?.id })
        }   
        // eslint-disable-next-line
    }, [mode, selectedPlan])
    return (
        <>
        <ModalLayout
            className='modal-colored modal-sidebar modal-desktop-half exam-modal'
            dimensionClassName='m-0 pr-5 py-3 pl-3'
            centerHeaderOverall='w-90'
            isOpen={isOpen}
            toggle={toggle}
            minHead={<span className={`h3 text-white`}>{`${examScoreForm?.name} - Submit Score`}</span>}
            topHead={
                <div className='background-head'>
                    <div className='modal-header mt-0 pt-8 d-flex flex-column'>
                        <RedRibbon
                            className='ml-3'
                            left='znk-ribbon-task left'
                            right='znk-ribbon-task right'
                        />
                    </div>
                </div>
            }
            underHead={
                <div className='d-flex flex-row w-100 justify-content-start'>
                    <TabContent activeTab={loadGetExam ? 'loading' : 'show'}>
                        <TabPane tabId={'show'}>
                            <div className='m-0 p-0 d-flex flex-column my-2'>
                                <span className='h2 text-white'>{examScoreForm?.name}</span>
                                {
                                    examDate &&
                                    <span className='h45 text-white'>
                                        {moment.utc(moment(examDate)).format('MMMM Do, YYYY')}
                                    </span>
                                }
                            </div>
                            <div className='d-flex d-flex justify-content-between' style={{ gap: 10 }}>
                                {
                                    (examScoreForm?.results || []).filter(
                                        ({ isOverallHidden = false }) => !isOverallHidden
                                    ).map(
                                        (result) => {
                                            const { name } = result
                                            const overall = calculateOverallForSection(result)
                                            return (
                                                <ScoreItem
                                                    className='m-0 mr-1 py-5'
                                                    serviceLabel={name}
                                                    serviceScore={null}
                                                    scoreLabel={overall}
                                                />
                                            )
                                        }
                                    )
                                }
                            </div>
                        </TabPane>
                        <TabPane tabId={'loading'}>
                            <div className='m-0 p-0 d-flex flex-column my-2'>
                                <Skeleton className='submit-score-skeleton' count={1} width={80} height={12} />
                                <Skeleton className='submit-score-skeleton' count={1} width={100} height={12} />
                            </div>
                            <div className='d-flex d-flex justify-content-between' style={{ gap: 10 }}>
                                {
                                    new Array(2).fill(0).map(
                                        () => (<Skeleton className='submit-score-skeleton' count={1} width={160} height={113} />)
                                    )
                                }
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
            }
        >
            {/* opciones para crear score desde workspace */}
            <div className='px-4 px-sm-4 mt-2 d-flex flex-column justify-content-between h-100' style={{ gap: 20 }}>
            {  mode === SUBMITSCOREMODE.create && 
                <div className='d-flex flex-column'>
                    <h3 className='mt-5'>{'Select Service'}</h3>
                    <ServiceComponent 
                        services= {servicesData}
                        selectedService={selectedService}
                        setSelectedService={setSelectedService}
                    />
                    <Student form={selectedUser} setForm={setSelectedUser} isEdit={false} />
                </div>
            }
            { showPlansToAdd && <AllStudentPlans
                                    plansList={dataPlans} 
                                    selectedPlan={selectedPlan} 
                                    setSelectedPlan={setSelectedPlan}
                                    selectedActivity={selectedActivity}
                                    setSelectedActivity={setSelectedActivity}
                                    AllActivities={activities}
                                    infoPlan={infoPlan} 
                                    setInfoPlan={setInfoPlan}
                                    isView={viewScore}
                                 />
                                 
                                 }
              { ((activities && examData?.serviceId) || (mode === SUBMITSCOREMODE.create) || (mode === SUBMITSCOREMODE.view)) && (
                <AllActivities
                    selected={selectedActivity} 
                    setSelected={setSelectedActivity} 
                    examData={examData}
                    activities={activities}
                    setActivities={setActivities}
                    isView={viewScore}
                />
              )}

                <TabContent activeTab={currentTabForm}>
                    <TabPane tabId={'show'}>
                        <Form value={examScoreForm} >
                            {
                                (examScoreForm?.results || []).map(
                                    ({ name, values = [] }, indexSection) => {
                                        return (
                                            <FormGroup className='mt-2 mb-0'>
                                                <Label className='text-gray'>{name}</Label>
                                                <div className='row pl-2'>
                                                    <div className='w-100'>
                                                        {
                                                            values.map(
                                                                ({ name, scale = '', value = 0 }, indexField) => {
                                                                    const [min, max] = scale.split('-')
                                                                    return (
                                                                        <div className='m-0 p-1 d-flex flex-column justify-content-between align-items-end'>
                                                                            <ScoreListItem
                                                                                id={name}
                                                                                className='w-100'
                                                                                label={name}
                                                                                value={value}
                                                                                onChange={(e) => {

                                                                                    setScoreOnField(parseInt(e), indexSection, indexField)
                                                                                }}
                                                                                isEditable={!viewScore}
                                                                                min={parseInt(min)}
                                                                                max={parseInt(max)}
                                                                            />
                                                                            <small className='text-gray'>{scale}</small>
                                                                        </div>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        )
                                    }
                                )
                            }
                            <div
                                className={`w-100`}
                                style={{
                                    position: 'sticky',
                                    bottom: '20px',
                                }}
                            >
                                {true && (
                                  
                                    <Button
                                        style={{ backgroundColor: 'rgb(181, 74, 226)' }}
                                        className={`w-100`}
                                        size='large'
                                        activity={loading}
                                        disabled={
                                            examScoreForm?.results?.some( ({ values = [], scale='' }) => {
                                                const [min,max] = scale.split('-')
                                                return values.some(({ value = 0 }) => value < min || value > max)
                                            })
                                        }
                                        label={
                                            <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                                                 <span>{
                                                    viewScore ? "Edit Score" :  "Submit Scores"
                                                }</span>
                                                <IconArrowRight
                                                    size={16}
                                                    style={{ verticalAlign: 'inherit' }}
                                                    className='text-white'
                                                />
                                            </div>
                                        }
                                        onClick={() => {
                                            !viewScore ? onSubmit() : setViewScore(false);
                                        }}
                                    />
                                )}
                            </div>
                        </Form>
                    </TabPane>
                    <TabPane tabId={'loading'}>
                        <div className='w-100 d-flex flex-column' style={{ gap: 20 }}>
                            {
                                new Array(2).fill(0).map(
                                    () => (
                                        <div className='w-100 d-flex flex-column'>
                                            <Skeleton count={1} height={12} className={'w-40'} />
                                            <div className='d-flex flex-column' style={{ gap: 10 }}>
                                                {new Array(3).fill(0).map(() => (<Skeleton count={1} height={45} className={'w-100'} style={{ borderRadius: 10 }} />))}
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </ModalLayout>
    </>
    )
}

export default SubmitScore