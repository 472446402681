import { useDispatch, useSelector } from "react-redux"
import {
    IconLessonPerWeek,
    IconPerLesson,
    IconPerMonth,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import { openNewPlanIndividualModal, parsePlanToEdit } from '../../../Plans/Fields/utils'
import useTimelineReducer from "../../../../reducers/timeline"
import {totalLesson, totalLpw as totalLpwFunction} from '../../../Plans/modal/newPlan/util'

const useCreateTimelineModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.createTimelineModal)
    const services = useSelector((state) => state.user.services)
    const topics = services.reduce((array, {topics}) => array.concat(topics), [])
    
    const {isOpen} = reduxData
    
    const setOpen = (open, onChange = () => {}, userData = null, timelineData = null, useMutation = false, timelineId = null) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.createTimelineModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                userData, //plan?.studentUser {studentUserId, firstName, lastName, parent}
                timelineData, //listado de eventos
                useMutation,
                timelineId //timeline a editar (si timelineId es diferente de nulo el modal está en modo edición)
            }
        })
    }
    
    const parseToTimeline = (events = [], onClickPlan = () => {}, onClickEvent = () => {}) => {
        return events.reduce((array, event) => {
            const {type} = event
            if(type === 'plan') {
                const totalMonth = Math.ceil(moment.duration(
                    Math.abs(
                        moment(event?.lpw[0].startdate).diff(
                            moment(
                                event?.lpw[
                                    Object.values(event?.lpw).length-1
                                ].enddate
                            )
                        )
                    )
                ).asMonths())
                for(let i=0; i < Object.values(event?.lpw)?.length; i++) {
                    const thisPart = event?.lpw[i]
                    const {
                        // eslint-disable-next-line
                        enddate, partname, startdate,
                        ...part
                    } = thisPart
                    
                    const from = thisPart?.startdate
                    // const largo = Object.keys(event?.lpw)?.length
                    const to = thisPart?.enddate
    
                    //total de lecciones a lo largo del plan
                    const total = totalLesson({...event, lpw: [thisPart]})
                    const totalLpw = totalLpwFunction({...event, lpw: [thisPart]})
                    const labelLpw = Object.entries(part).reduce(
                        (label, [topicId, {value}], index) => {
                            const topicName = topics.find(({id}) => id===topicId)?.name
                            const isLast = index === Object.entries(part).length-1
                            if(index === 0) return `${value} ${topicName}${isLast ? ' lesson' : ''}`
                            else {
                                return `${label}${isLast ? ' and' : ','} ${value} ${topicName}${isLast ? ' lesson(s)' : ''}`
                            } 
                        }, ``)   
                    
                    const spans = [{text: totalLpw, description: labelLpw, place: 'right', Icon: IconLessonPerWeek, iconSize: 25}]
                    if(event?.typePlanPricing === 'perlesson') {
                        spans.push({text: `$${event?.pricingRate}`, description: `rate per lesson`, Icon: IconPerLesson, iconSize: 25})
                        spans.push({text: `$${parseInt(event?.pricingRate)*parseInt(total)}`, description: 'total'})
                    }
                    //per month
                    else if(i === 0) {
                        spans.push({text: `~$${parseInt(parseInt(event?.pricingRate)/parseInt(totalMonth))}`, description: `rate per month`, Icon: IconPerMonth, iconSize: 25})
                        spans.push({text: `$${parseInt(event?.pricingRate)}`, description: 'total'})
                    } else {
                        spans.push({text: `-`})
                        spans.push({text: `-`})

                    }  

                    const totalWeek = moment.duration(
                        Math.abs(
                            moment(from).diff(moment(to))
                        )
                    ).asWeeks()

                    array.push({
                        ...event,
                        spans, //max 3 spans
                        small: `${Math.ceil(totalWeek)} week(s)`,
                        id: `part${i}-${event?.planId || event?.id}`,
                        subtitle: `From ${moment(from).format(`MMM Do`)} to ${moment(to).format(`MMM Do`)}`,
                        label: `${event?.planname} (part ${i+1})` || event?.name,
                        from,
                        to,
                        onClick: () => {
                            if(event?.kind !== 'lessonsPerWeek') onClickPlan(event)}
                    })
                }
                return array
            }
            array.push({
                ...event,
                spans: [
                    // {text: event?.name, description: 'name'},
                ], //max 3 spans
                id: event?.eventId || event?.id,
                from: event?.date,
                label: event?.name,
                subtitle: moment(event?.date).format(`MMM Do`),
                to: undefined,
                onClick: () => {
                    onClickEvent(event)
                }
            })
            return array
        }, [])
    }

    //recibe plan modelo TimelinePlan, parsea el objeto y rellena el formulario 
    //creación de plan individual
    const {parseTimelinePlanFromAPI} = useTimelineReducer()
    const createIndividualPlan = (plan, onChange = () => {}) => {
        //parsea plan desde api a formato frontend (compatible con funciones que parsean a formularios Plans/Fields/utils)
        const parseTimelinePlan = parseTimelinePlanFromAPI(plan)
        const serviceName = services.find(({ id }) => id === plan?.serviceId)?.name
        //parsea plan en formato frontend a formulario
        const item = parsePlanToEdit({
            ...parseTimelinePlan,
            kind: 'lessonsPerWeek',
            isTimelinePlan: true
        }, serviceName)
        //abre el formulario de creación de plan
        const finalPlan = {
            ...parseTimelinePlan,
            ...item,
            isTimelinePlan: true,
            timelinePlanId: plan.id,
            timelineId: plan.timelineId._id,
        }
        openNewPlanIndividualModal(finalPlan, dispatch, onChange)
    }

    return {
        ...reduxData,
        open: (onChange = () => {}, userData = null, timelineData = null, useMutation = false, timelineId = null) => {
            setOpen(true, onChange, userData, timelineData, useMutation, timelineId)
        },
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)}, 
        parseToTimeline,
        createIndividualPlan  
    }
}

export default useCreateTimelineModal