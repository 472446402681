import React, {useState} from 'react'
import {
    DropDownItem,
    LessonItem
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import useGetRecurringLessons from './useGetRecurringLessons'
import FilterStatus from './Filters/FilterStatus'
import moment from 'moment'

const RecurringLessons = (props) => {
    const {dataLesson} = props
    const dispatch = useDispatch()

    //obtiene lecciones recurrentes
    const {data, loading} = useGetRecurringLessons({recurringId: dataLesson?.recurringId || ''})
    const recurringLessons = data?.getRecurringLessons || []
  
    const [statuses, setStatuses] = useState(['scheduled', 'incomplete', 'pending'])

    const getStudentAttendance = (lesson) => {
        return lesson.__typename === 'GroupLesson' ? 
            //si al menos un estudiante está attended se marca como 'attended'
            lesson?.students?.filter(s => s?.attendanceStatus === 'attended')?.length > 0 : 
            (lesson?.studentPlan || lesson?.student)?.attendanceStatus
    }
    //parsea status de lecciones a [scheduled, finished, canceled, attended, missed]
    const parseStatuses = (lessons = []) => {
        const allLessons = lessons.map(
            (lesson) => {
                if(lesson?.status === 'finished') {
                    if((lesson?.substitute?.attendanceStatus === 'missed' || lesson?.educator?.attendanceStatus === 'missed') && lesson?.status === 'finished') return {...lesson, status: 'no-show'}
                    return {...lesson, status: getStudentAttendance(lesson)}
                    // if(getStudentAttendance(lesson) === 'missed') return {...lesson, status: 'missed'}
                    // if(getStudentAttendance(lesson) === 'attended') return {...lesson, status: 'attended'}
                }
                if( ['scheduled'].includes(lesson.status) && moment(lesson.startDate) < moment() ) return {...lesson, status: 'incomplete'}
                // if(['pending'].includes(lesson.status) && !(getStudentAttendance(lesson) === 'checkIn')) return {...lesson, status: 'scheduled'}
                return lesson
            }
        )
        return allLessons
    }


    return (
        <div className={`d-flex flex-column m-0 p-0`}>
            {
                loading ? 
                    <Skeleton count={10} height={40} /> :
                    <>
                    
                        <div className={`d-flex flex-row`}>
                            <div className={`d-flex flex-row aling-self-start`}>
                                <FilterStatus {...{statuses, setStatuses}} />
                            </div>
                            <span className={`h45 text-gray align-self-end my-2`}>{`${recurringLessons?.filter(({status}) => statuses.includes(status)).length} lessons`}</span>
                        </div>
                        {
                            parseStatuses(recurringLessons)
                            .filter(({status}) => statuses.includes(status))
                            .map((recurring) => {
                                return (
                                    <LessonItem
                                        dataLesson={recurring}
                                        currentId={dataLesson?.id}
                                        onClick={() => {
                                            dispatch({
                                                type: 'SET_DATA_MODAL_FORM_LIST',
                                                payload: `plan.${false ? 'orientationLessonModal' : 'infoLessonModal'}`,
                                                data: {
                                                  isOpen: true,
                                                  dataLesson: recurring
                                                }
                                            })
                                        }}
                                        options={[
                                            {component: 
                                                <DropDownItem>
                                                    <span
                                                        onClick={() => {
                                                            dispatch({
                                                                type: 'SET_DATA_MODAL_FORM_LIST',
                                                                payload: 'plan.cancelLesson',
                                                                data: {
                                                                    isOpen: true,
                                                                    lessonId: recurring?.id || '',
                                                                    isOrientation: false,
                                                                    dataLesson: recurring
                                                                }
                                                            })
                                                        }}
                                                    >Cancel lesson</span></DropDownItem>},
                                        ]}    
                                    />
                                )
                                /** 
                                const isCanceled = ['canceled'].includes(recurring?.status)
                                const isPending = ['pending'].includes(recurring?.status)
                                const isNoShow = ['no-show'].includes(recurring?.status)
                                const isIncomplete = ['incomplete'].includes(recurring?.status)
                                const isFinished = ['finished', 'attended', 'missed'].includes(recurring?.status) || //la lección ya finalizó
                                    //lección está en curso o ya terminó pero no se han enviado los lessons notes 
                                    (recurring?.status === 'scheduled' && moment(recurring.startDate) < moment()) 
                                const isCurrent = recurring?.id === dataLesson?.id
                                return (
                                    <div
                                        onClick={() => {
                                            dispatch({
                                                type: 'SET_DATA_MODAL_FORM_LIST',
                                                payload: `plan.${false ? 'orientationLessonModal' : 'infoLessonModal'}`,
                                                data: {
                                                  isOpen: true,
                                                  dataLesson: recurring
                                                }
                                            })
                                        }}
                                        style={styles[recurring?.status]}
                                        className={`${isCurrent && false ? 'bg-primary' : ''} d-flex align-items-center w-100 my-1 px-4 font-weight-light cursor-pointer`}
                                        key={recurring.id}
                                    >
                                        <span className={`flex-grow-1 text-truncate ${isCanceled ? 'text-line-through' : ''}`}>{moment(recurring.startDate).format('dddd, Do MMM HHa')}</span>
                                        <span className='text-truncate'>
                                            {
                                                recurring?.status === 'no-show' ? 
                                                    'No show' :
                                                    `${recurring.educator.firstName} ${recurring.educator.lastName.charAt(0)}.`
                                            }
                                        </span>
                                        {isCurrent ? (
                                        <IconReminder color={styles[recurring?.status]?.color} size={15} className='mx-3' />
                                        ) : (
                                        <IconDate color={styles[recurring?.status]?.color} size={15} className='mx-3 text-white' />
                                        )}
                                        {
                                            <DropDownNew
                                                className={isCanceled || isFinished || isIncomplete || isNoShow ? 'w-0 visibility-hidden' : ''}
                                                direction={`bottomLeft`}
                                                disabled={isCanceled || isFinished}
                                                options={[
                                                    {component: 
                                                        <DropDownItem>
                                                            <span
                                                                onClick={() => {
                                                                    dispatch({
                                                                        type: 'SET_DATA_MODAL_FORM_LIST',
                                                                        payload: 'plan.cancelLesson',
                                                                        data: {
                                                                            isOpen: true,
                                                                            lessonId: recurring?.id || '',
                                                                            isOrientation: false,
                                                                            dataLesson: recurring
                                                                        }
                                                                    })
                                                                }}
                                                            >Cancel lesson</span></DropDownItem>},
                                                ]}
                                            >
                                                <div className={`hover-icon ${isPending ? '' : 'secondary'} ${isCanceled || isFinished ? 'disabled' : ''}`}>
                                                    <IconOptions color={styles[recurring?.status]?.color} className={`rotate-90 ${isCanceled || isFinished ? 'text-gray' : ''}`} size={12}/>
                                                </div>
                                            </DropDownNew>
                                        }
                                    </div>
                                )
                                **/
                            })
                        }
                    </>
            }
        </div>
    )
}

export default RecurringLessons