import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'

const useNewLesson = () => {
    const reduxData = useSelector((state) => state.modal.plan.newLessonModal)
    const dispatch = useDispatch()
    const {isOpen} = reduxData
    const setOpen = (isOpen, defaultForm = {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.newLessonModal',
            data: {
              isOpen,
              defaultForm: {
                repeatmonth: false,
                repeat: false,
                repeatweek: false,
                timeStart: moment().set({ minutes: 0 }).format('HH:mm'),
                timeEnd: moment().set({ minutes: 50 }).format('HH:mm'),
                date: moment().format('YYYY-MM-DD'),
                paymentDate: moment().format('YYYY-MM-DD'),
                ...defaultForm
              }
            }
        })
    }
    return {
        ...reduxData,
        open: (defaultForm = {}) => {setOpen(true, defaultForm)},
        close: () => {setOpen(false)},
        toggle: (defaultForm = {}) => {setOpen(!isOpen, defaultForm)}
    }
}

 export default useNewLesson