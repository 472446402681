import validate from 'validate.js'

const constraints = {
  paymentItemId: {
    presence: {
      allowEmpty: false,
      message: '^Please enter a name'
    }
  },
  'input.amount': {
    presence: {
      allowEmpty: false,
      message: '^Please enter a name'
    }
  },
  'input.chargeType': {
    presence: {
      allowEmpty: false
    }
  },
  'input.date': {
    presence: {
      allowEmpty: false
    }
  },
  'input.description': {
    presence: {
      allowEmpty: false
    }
  },
  'input.type': {
    presence: {
      allowEmpty: false
    }
  }

}

export default (data) => {
  return validate(data, constraints)
}
