import { useQuery, gql } from "@apollo/client"
import { useMemo } from "react"

const useAllUsersAssign = (variables = {}) => {
    const {data, loading, refetch} = useQuery(
        gql`
            query {
                allUsersAssign {
                    id
                    firstName
                    lastName
                }
            }
        `, 
        {
            variables,
            fetchPolicy: 'no-cache'
        }
    )

    const counselor = useMemo(() => {
        return data?.allUsersAssign || []
    }, [data])

    return {counselor, loading, refetch}
}

export default useAllUsersAssign