import React, {useEffect, useState} from 'react'
import {
  ModalLayout,
  useWindowDimensions,
  Field,
  Form,
  AmountInput,
  PlainTextInput,
  StickyButton,
  Button, LabeledField
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import createRefund from '../../../../Model/Payments/Refund'
import { validate } from 'validate.js';
import constraints from '../../../../Model/Payments/Refund/validation'
import useTranslation from '../../../../i18n/useTranslation'
import { showAlert } from '../../../../common/Alert/util';

const MakeRefund = (props) => {
  const { refetch } = props
  const {breakWidth} = useWindowDimensions()
  const modalData = useSelector((state) => state.modal.payments.makeRefund)
  const { isOpen } = modalData
  const dispatch = useDispatch()
  const [validationErrors, setValidationErrors] = useState({})
  const { translate } = useTranslation()
  const [form, setForm] = useState({
    maxAmount: 0
  })
  useEffect(() => {
    if (modalData.invoice) {
      const maxAmount = (modalData?.issue?.amount || 0) - (modalData?.issue?.issuedCreditNotes || []).reduce((accu, curr) => { return accu + curr.amount } , 0)
      setForm({
        amountToRefund: maxAmount,
        reason: '',
        date: modalData?.issue?.lastProcessedAt?.split('T')[0],
        maxAmount
      })
    }
  }, [modalData])
  const toggle = () => {
    // setForm({
    //   student: null,
    //   type: 'studentCharge',
    //   category: 'livePrep',
    //   amount: 0,
    //   discount: 0,
    //   date: new Date(),
    //   description: ''
    // })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.makeRefund',
      data: {
        isOpen: false,
        invoiceId: ''
      }
    })
  }
  const { submit, loading } = createRefund((result) => {
    refetch()
    toggle()
    showAlert({
      text: translate('payments.refunds.successMsg'),//'Refund created successfully!',
      status: 'success'
    }, dispatch)
  }, (err) => {
    showAlert({
      text: err.toString(),
      status: 'error'
    }, dispatch)
  })
  const onSubmit = () => {
    setForm({ ...form, stripeInvoiceId: modalData.issue.stripeInvoiceId })
    let firstError = false
    const errorsVal = validate(form, constraints)
    if (errorsVal !== undefined) {
      firstError = true
    }
    let hasError = false
    if (form.amountToRefund > form.maxAmount) {
      hasError = true
      errorsVal.amountToRefund = 'The amount to refund can not been geather than ' + form.maxAmount
    }
    if (firstError || hasError) {
      setValidationErrors(errorsVal)
      return
    }
    submit(form)
  }
  
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <ModalLayout
        buttonLabel={null}
        className={`modal-${
            breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-colored h-auto`}//'modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        topHead={
          <span className='text-title font-weight-bold h2'>{translate('payments.refunds.newrefund')}</span>
        }
      >
        <Form value={form} onChange={setForm} validationErrors={validationErrors}>
          <div className='m-0 p-0 mb-2 pl-2 pr-2'>
            <div className='row d-flex m-0 p-0 mt-4'>
              <div className='col-12 p-0'>
                <label className='text-gray mb-2'>{translate('payments.refunds.maxrefund')} ${form.maxAmount}</label>
              </div>
            </div>
            <div className='w-100 p-0'>
                <label className='text-gray mb-2'>{translate('payments.refunds.amount')}</label>
                <Field
                  className='placeholder-to-right'
                  component={AmountInput}
                  type={LabeledField}
                  fieldName='amountToRefund'
                  fieldType='number'
                  placeholder='0.00 '
                  addOn='$'
                  min='0'
                  max={form.maxAmount}
                />
              </div>
            {/* <div className='row d-flex m-0 p-0 mt-4'>
              
            </div> */}
            {/* <div className='row d-flex m-0 p-0 mt-4'>
              
            </div> */}

            <div className='w-100 p-0'>
                <label className='text-gray mb-2'>{translate('payments.refunds.reason')}</label>
                <Field
                  className={`w-100`}
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='reason'
                  fieldType='text'
                />
              </div>
            {/* <div className='row d-flex m-0 p-0 mt-4'>
              
            </div> */}
            <div className='w-100 p-0'>
                <label className='text-gray mb-2'>{translate('payments.refunds.paymentdate')}</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='date'
                  fieldType='date'
                  readOnly
                />
              </div>
              <div className='w-100 p-0 '>
                <StickyButton>
                    <Button
                      color='primary'
                      activity={loading}
                      className='col-12 aling-text-left'
                      label={translate('payments.refunds.addrefund')}
                      onClick={() => { onSubmit() }}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                </StickyButton>
              </div>
            {/* <div className='row d-flex m-0 p-0 mt-6'>
              
            </div> */}
          </div>
        </Form>
      </ModalLayout>
    </>
  )
}

export default MakeRefund
