// rate
//         isManualRate
//         grade

const planQueryResult = `
    id
    name
    lessonIds
    from
    to
    status
    testDate
    kind
    pricingRate
    typePlanPricing
    serviceId
    parts {
        lessonsPerWeek
        totalLessons
        lessonPerWeekPerTopic {
        topicId
        lessonsPerWeek
        totalLessons
        lockLessons
        }
        from
        to
        name
    }

    studentUser {
        studentUserId
        firstName
        lastName
        
    }
    paymentItemIds

    
    isFirstPlan
    sentDate
    groupPricingRate
    aprovedDate
    paymentItemsConfig {
        amount
        percentDiscount
        amountWithoutDiscount
        date
        description
        type
        typeFixed
    }
    location {
        type
        linkStudent
        linkEducator
    }
    `
    // getStudentInfo: StudentField
    
    export default planQueryResult