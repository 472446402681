import React from 'react'
import { ConfirmModal } from '@Knowledge-OTP/znk-ui-components'
import useConfirmAddCollege from './useConfirmAddCollege'
import useTranslation from '../../../../i18n/useTranslation'

const ConfirmAddCollegeCharge = () => {
    const {isOpen, toggle, onChange = () => {}} = useConfirmAddCollege()
    const { translate } = useTranslation()
    return (
        <ConfirmModal
            loading={false}
            className=''
            classNameActionDiv='flex-column align-items-end'
            classNameConfirm='text-right text-error align-self-end'
            classNameCancel='mx-3 text-right align-self-end'
            isOpen={isOpen}
            toggle={toggle}
            title={translate('universities.confirmCharge.title')}
            body={translate('universities.confirmCharge.description')}
            confirmAction={() => {
                onChange(true)
            }}
            cancelAction={() => {
                toggle()
                onChange(false)
            }}
            confirmText={translate('universities.confirmCharge.confirm')}
            cancelText={translate('universities.confirmCharge.cancel')}

        />
    )
}

export default ConfirmAddCollegeCharge