import { InputAndButtons } from '@Knowledge-OTP/znk-ui-components'
import React, { useState } from 'react'
import useTranslation from '../../../i18n/useTranslation'

const PendingLessonsInner = (props) => {
    const { translate } = useTranslation()
    const { setLessonsPending } = props

    const [hover, setHover] = useState(false)
    const [input, setInput] = useState({ total: 0 })

    const cancel = () => {
        setHover(!hover)
        setLessonsPending(0)
    }
    return (
        <>
              <div className='px-5 pt-4 pb-5 h45'>
                <label className={`h45`}>{translate('filters.plans.quantityLessons')}</label>
                <InputAndButtons
                  form={input}
                  setForm={setInput}
                  inputName='total'
                  // title={translate('filters.plans.quantityLessons')}
                />
              </div>
              <div className={`borderTop w-100`} />
              <div className={`d-flex flex-row justify-content-between px-5 mb-2 mt-4`}>
                <label className={`btn-link text-gray h4`} onClick={() => cancel()}>{translate('filters.plans.buttonCancel')}</label>
                <label className={`btn-link text-primary h4`} onClick={() => setLessonsPending(input.total)}>{translate('filters.plans.buttonApply')}</label>
              </div>
              {/* <ListItem
                className='px-5 h45'
                showTopLine
                Left={
                  <Button
                    className='h45 pl-0 py-0 ml-0 w-auto button-cancel text-secondary font-weight-normal'
                    label={translate('filters.plans.buttonCancel')}
                    onClick={() => cancel()}
                  />
                }
                Right={
                  <Button
                    className='h45 pr-0 py-0 mr-0 w-auto button-submit text-primary font-weight-normal'
                    label={translate('filters.plans.buttonApply')}
                    onClick={() => setLessonsPending(input.total)}
                    type='submit'
                  />
                }
              /> */}
            </>
    )
}

export default PendingLessonsInner