import React, { useState, useEffect } from 'react'
import useTranslation from '../../../../i18n/useTranslation.js'
import { Collapse } from 'reactstrap'
import useGetEducatorLink from '../GetEducatorLink'
import RenderLocationInputs from './RenderLocationInput'
const LessonDetails = (props) => {
  /** LESSON DETAILS QUE SE MUESTRA EN MAKEUP LESSON**/
  const { form, setForm, typeAndlocation, isEdit } = props
  const { translate } = useTranslation()
  const separateCamelCase = (str) => {
    if (str) str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) { return str.toUpperCase() })
    return str
  }
  
  const [isOpenCollapse, setOpenCollapse] = useState(false)

  const [educatorLink, setEducatorLink] = useState(null)
  //get educator link for zoom location
  const [getEducatorLink]  = useGetEducatorLink(
    (res) => {
      const link = res.user.educatorInfo?.backupLink
      setEducatorLink(link) 
    },
    console.error
  )
  useEffect(() => {
    getEducatorLink({
      variables: {
        id: form?.educator?.educatorUserId
      }
    })
    //eslint-disable-next-line
  }, [form?.educator?.educatorUserId])


  return (
    <div className='d-flex flex-column'>
      <span className='h4 font-weight-bold my-2'>{translate(form?.isMeeting ? 'modal.newlesson.meetingdetails' : form?.service?.name === 'Orientation' ? 'modal.newlesson.orientationdetails' : 'modal.newlesson.lessondetails')}</span>
      <span className='h4 my-2'>{form?.isMeeting ? form?.title : `${form?.service?.name || 'No defined'}, ${form?.topic?.name || 'No defined'}`}</span>
      <span className='h45 text-gray my-2 pr-2'>
        {separateCamelCase(typeAndlocation)}
      </span>
      {
        isEdit
          ? <>
            <label className='btn-link' onClick={() => { setOpenCollapse(!isOpenCollapse) }}>{translate(form?.isMeeting ? 'modal.editlesson.editMeetingdetails' : form?.service?.name === 'Orientation' ? 'modal.editlesson.editOrientationdetails' : 'modal.editlesson.editdetails')}</label>
            <Collapse isOpen={isOpenCollapse} className='w-100'>
              <RenderLocationInputs {...{setForm, form, educatorLink}}/>
            </Collapse>
            </>
          : <RenderLocationInputs {...{setForm, form, educatorLink}}/>
      }
    </div>
  )
}

export default LessonDetails
