import { useDispatch, useSelector } from 'react-redux'

const useViewPlanModal = () => {
    const dispatch = useDispatch()
    //Para que hook funciones debe haber solo UN componente NewTaskModal 
    // renderizado en el dominio
    const reduxData = useSelector((state) => state.modal.counseling.viewPlanModal)
    const {isOpen, onChange, dataPlan} = reduxData
    const setOpen = (open, onChange = () => {}, dataPlan = {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.viewPlanModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                dataPlan
            }
        })
    }
    return {
        isOpen,
        onChange,
        dataPlan,
        setData: (newData = {}) => {
            setOpen(isOpen, onChange, newData)
        },
        open: (onChange = () => {}, dataPlan = {}) => {
            setOpen(true, onChange, dataPlan)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
    }
}

export default useViewPlanModal