import React, {useState} from 'react'
import {
    IconLoad
} from '@Knowledge-OTP/znk-ui-components'
import useLazyPlan from '../../../../CounselingPlans/Model/useLazyPlan'
import useViewPlanModal from '../../../../CounselingPlans/Modal/viewPlanModal/useViewPlanModal'
import useCounselingPlanReducer from '../../../../../reducers/counseling-plan'
import {TabContent, TabPane} from 'reactstrap'

const ViewCounselingPlanField = (props) => {
    const {counselingPlanId = null, id, ...otherProps} = props
    const [getCounselingPlanData] = useLazyPlan(
        {planId: counselingPlanId}, 
        // {fetchPolicy: 'no-cache'}
        )
    const {open} = useViewPlanModal()
    const {parsePlanFromAPI} = useCounselingPlanReducer()
    const [loading, setLoading] = useState(false)
    return (
        <span onClick={() => {
            if(!counselingPlanId) return
            setLoading(true)
            getCounselingPlanData({planId: counselingPlanId})
                .then(
                    (result) => {
                        const dataPlan = result?.data?.plan
                        setLoading(false)
                        open(() => {
                            
                        }, parsePlanFromAPI(dataPlan))
                    },
                    (error) => {
                        setLoading(false)
                        console.error(error)}
                )
          }} id={id} className={`d-flex flex-row align-items-center ${counselingPlanId ? 'cursor-pointer' : ''}`} {...otherProps}>
            <TabContent activeTab={loading ? 'loading' : 'show'} id={id}>
                <TabPane tabId={`loading`} id={id}>
                    <IconLoad className={`mr-2`} />
                </TabPane>
                <TabPane tabId={`show`} id={id}>
                    <div className={`star-shape small ${counselingPlanId ? 'counseling' : ''} mr-1`}  id={id}/>
                </TabPane>
            </TabContent>
            <span>Counseling plan</span>
        </span>
    )
}

export default ViewCounselingPlanField