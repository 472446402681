import React from 'react'
import PropTypes from 'prop-types'
import Providers from './Providers'
import ForceLogin from './ForceLogin'
import TimezoneProvider from './Providers/TimezoneProvider'
const propTypes = {
  children: PropTypes.node
}
const defaultProps = {}

function Root(props) {
  return (
    <Providers>
      <ForceLogin>
        <TimezoneProvider>
          {props.children}
        </TimezoneProvider>
      </ForceLogin>
    </Providers>
  )
}

Root.propTypes = propTypes
Root.defaultProps = defaultProps

export default Root
