import { useQuery, gql } from "@apollo/client";

const useStudentsGroupByClass = (vars = {}, options = {fetchPolicy: 'cache-first'}) => {
    return useQuery(
        gql`
            query (
                $page: Float = 0
                $limit: Int
                $search: String
                $calculateTotal: Boolean = false
                $studentIds: [String!] = []
            ) {
                studentsGroupByClass (
                    page: $page
                    limit: $limit
                    search: $search
                    calculateTotal: $calculateTotal
                    studentIds: $studentIds
                ) {
                    total
                    docs {
                        users {
                            id
                            firstName
                            lastName
                            avatar
                            currentCounselingPlan {
                                id
                            }
                            studentInfo {
                                universities {
                                    id
                                    name
                                    logo
                                    collegeId
                                    status
                                    early
                                    attending
                                }
                            }
                        }
                        _id
                    }                    
                }
            }
        `, {
            variables: vars,
            ...options
        }
    )
}

export default useStudentsGroupByClass