import { gql, useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

const QueryPaymentsAndLessons = (props) => {
  const { planId } = props
  const [getQuery, { data, loading }] = useLazyQuery(gql`
      query (
        $planId: String!
      ) {
        myPaymentsItems(planId: $planId){
            docs {
                id
                status
                amount
                percentDiscount
                amountWithoutDiscount
                type
                date
                description
            }
        }
        lessons(
            planId: $planId
            status: [
              finished
              pending
              scheduled
              canceled
            ]
            limit: 0
          ){
          docs {
            id
            status
            startDate
            endDate
            kind
          }
        }
      }
    `
  )
  useEffect(() => {
    if (planId) {
      getQuery({
        variables: {
          planId
        }
      })
    }
  }, [planId, getQuery])
  return loading ? [] : data
}

export default QueryPaymentsAndLessons
