import validate from 'validate.js'

const constraints = {
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  email: {
    presence: true
  },
  countryId: {
    presence: true
  },
  timezone: {
    presence: false
  },
  isActive: {
    presence: false
  },
  studentType: {
    presence: true
  }
}

export default (data) => {
  return validate(data, constraints)
}
