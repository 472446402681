import React, { useRef, useState, useEffect } from 'react'
import PlansPendingAprove from './Widget/PlansPendingAprove'
import StudentWithoutLessons from './Widget/StudentWithoutLessons'
import CurrentlyOngoingLesson from './Widget/CurrentlyOngoingLesson'
import IssueCheckIn from './Widget/IssueCheckIn'
import TestDates from './Widget/TestDates'
// import Plans from './Widget/Plans'
import PlansList from './Widget/PlansList'
import Box from './Widget/Components/Box'


const StudentDashboard = () => {

    const currentLessonsWidget = useRef(null)
    const [clientRect, setClientRect] = useState(currentLessonsWidget?.current?.getBoundingClientRect())
    //actualiza las dimensiones del `widget currently on going lessons`
    useEffect(() => {
        if (currentLessonsWidget?.current?.getBoundingClientRect()?.height !== clientRect?.height) {
            setClientRect(currentLessonsWidget?.current?.getBoundingClientRect())
        }
        //eslint-disable-next-line
    }, [currentLessonsWidget?.current?.getBoundingClientRect()])



    const defaultBox = { colLg: 6, colXl: 6, colMd: 6, colSm: 12 }
    // const studentWithoutLessonsBox = { colLg: 6, colXl: 6, colMd: 6, colSm: 12, onScroll: () => {console.log("ahora si")} }

    const issueCheckInBox = { colLg: 3, colXl: 3, colMd: 6, colSm: 12, height: '650px', minHeight: '100%', maxHeight: '650px' }

    // const testDateBox = { colLg: 4, colXl: 4, colMd: 12, colSm: 12, height: '500px' }
    const planListBox = { colLg: 12, colXl: 12, colMd: 12, colSm: 12, height: '500px' }
    



    return (
        <div className={`row`}>
            <div className={`col-12 col-md-12 col-lg-9 col-xl-9 row p-0 m-0`} ref={currentLessonsWidget}>
                <Box {...defaultBox}>
                    <CurrentlyOngoingLesson onLoad={() => { }} />
                </Box>
                <Box {...defaultBox}>
                    <PlansPendingAprove />
                </Box>
                <Box {...defaultBox}>
                    <TestDates />
                </Box>
                <Box {...defaultBox}>
                    <StudentWithoutLessons />
                </Box>
            </div>
            <Box {...issueCheckInBox}>
                <IssueCheckIn />
            </Box>
            <Box {...planListBox}>
                <PlansList />
            </Box>
        </div>
    )
}

export default StudentDashboard