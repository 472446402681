import React, { useState, useEffect } from 'react'
import {
  Button,
  IconArrowRight,
  IconArrowLeft,
  IconProgram,
  ToolTipInfo,
  IconWspAlert,
  IconEdit,
  IconEmail,
  CheckboxOption,
  IconErase,
  useWindowDimensions,
  IconList,
  IconLoad
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import reScheduleLessonFromAdmin from '../../../../Model/Lessons/ReScheduleLessonFromAdmin'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useApolloClient} from '@apollo/client'
import useTranslation from '../../../../i18n/useTranslation'
import CancelMultipleLessons from '../../modal/CancelMultipleLessons'
import SetPendingMultipleLessons from '../../modal/SetPendingMultipleLessons'
import CalendarLesson from '../../Components/CalendarLesson/index'
import CancelLesson from '../../modal/CancelLesson'
import { useHistory } from "react-router-dom"
import useCalendar from './useCalendar'
import List from '../List'
import './index.css'

const CalendarSection = (props) => {
  const { lessons, week, setWeek, from, to, refetchCalendarQuery, upcomming, loading, openSideLeft } = props
  const {breakWidth} = useWindowDimensions()
  let history = useHistory();
  const {parseLesson} = useCalendar()
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const zone = useSelector((state) => state.timezone.zone)
  const [showCalendar] = useState(true)

  const services = useSelector((state) => state.user.services)
  const orientation = services?.find((serv) => serv.isOrientation)

  // MUTACIÓN PARA REAGENDAR LECCIÓN
  const { submit: rescheduleSubmit } = reScheduleLessonFromAdmin(
    (result, { startDate: newTime, lessonId: draggableId, oldDataLesson }) => {
      if (result?.data?.reScheduleLessonFromAdmin?.hasOccurrences) {
        const ocu = result?.data?.reScheduleLessonFromAdmin?.occurrencesList
        // mostrar confirmación de agendamiento
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.confirmDropRescheduled',
          data: {
            isOpen: true,
            collissionKinds: ocu[0].collissionKinds,
            confirmAction: () => {
              setLessonsCalendarArray(lessonsCalendarArray.map((lesson) => {
                if (lesson.idLesson === oldDataLesson.idLesson) return oldDataLesson
                return lesson
              }))
            }, // UNDO
            cancelAction: () => {
              // EJECUTA LA MUTACIÓN
              rescheduleSubmit({
                startDate: newTime,
                lessonId: draggableId,
                ignoreEducatorUnavailability: true,
                oldDataLesson
              })
            } // SCHEDULED ANYWAY
          }
        })
      } else {
        // mostrar alerta de agendamiento exitoso
      }
    }, (e, oldDataLesson) => {
      console.error(e)
      setLessonsCalendarArray(lessonsCalendarArray.map((lesson) => {
        if (lesson.idLesson === oldDataLesson.idLesson) return oldDataLesson
        return lesson
      }))
    }
  )

  const [lessonsSelected, setLessonsSelected] = useState([])

  const appendLessonToSelected = (id) => {
    setLessonsSelected(lessonsSelected.concat([id]))
  }
  const removeLessonFromSelected = (id) => {
    setLessonsSelected(
      lessonsSelected.reduce(
        (newArray, idLesson) => {
          if (idLesson !== id) newArray.push(idLesson)
          return newArray
        }, []
      )
    )
  }
  const lessonsSelectedHasInProgress = () => {
    //retorn true cuando alguna de las lecciones seleccionadas en lessonsSelected está en proceso
    return lessonsSelected.reduce((bool, idLesson) => {
      let lesson = lessons.find((lesson) => lesson.id === idLesson)
      if(!lesson) return bool
      return bool || (moment(lesson?.startDate) < moment() && moment() < moment(lesson?.endDate))
    }, false) 
  }

  const OptionSelection = ({ IconComponent, label, onClick }) => {
    // renderiza las opciones que aparecen al seleccionar algun bloque
    return (
      <span className='mx-2' onClick={onClick} style={{ cursor: 'pointer' }}>
        <IconComponent size={15} />
        <span className='ml-1 h5'>{label}</span>
      </span>
    )
  }

  const SetMultipleLessonsInPending = (lessons) => {
    if (lessons.length === 1) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.setPendingLesson',
        data: {
          isOpen: true,
          lessonId: lessons[0] || ''
        }
      })
    } else {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'lesson.setPendingMultiple',
        data: {
          isOpen: true,
          lessonsId: lessons
        }
      })
    }
  }

  const RemoveMultipleLessons = (lessons) => {
    if (lessons.length === 1) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.cancelLesson',
        data: {
          isOpen: true,
          lessonId: lessons[0] || ''
        }
      })
    } else {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'lesson.cancelMultiple',
        data: {
          isOpen: true,
          lessonsId: lessons
        }
      })
    }
  }

  const [lessonsCalendarArray, setLessonsCalendarArray] = useState([])

  useEffect(() => {
    if (lessons && orientation?.id) setLessonsCalendarArray(parseLesson(lessons, orientation.id, upcomming))
    // eslint-disable-next-line
  }, [lessons, orientation])

  useEffect(() => {
    if (lessonsSelected.length === 1) {
      const isGroupLesson =
        lessonsCalendarArray.filter(
          (lesson) => lesson.idLesson === lessonsSelected[0]
        )[0]?.isGroup
      // si la primera lección seleccinada es grupal
      // => solo puedo continuar seleccionando grupales
      // si es individual => solo puedo continuar seleccionando individuales
      setLessonsCalendarArray(
        lessonsCalendarArray.map(
          (lesson) => {
            if (lesson?.isGroup) lesson.canChecked = isGroupLesson
            else lesson.canChecked = !isGroupLesson
            return lesson
          }
        )
      )
    } else if (lessonsSelected.length === 0 && lessonsCalendarArray.length > 0) {
      // todos se pueden seleccionar
      setLessonsCalendarArray(
        lessonsCalendarArray.map(
          (lesson) => {
            lesson.canChecked = true
            return lesson
          }
        )
      )
    }
    // eslint-disable-next-line
  }, [lessonsSelected]) 

  const client = useApolloClient()

  const parseFreeFramesToBlocks = (frames) => {
    let freeBlocks = []
    frames.forEach((fr) => {
      let [from, to] = fr
      from = moment(from)
      to = moment(to).set({minutes: 0, seconds: 0})
      while(from < to){
        freeBlocks.push(`${from.clone().day() === 0 ? 6 : from.clone().day()-1}-${from.clone().hour()}`)
        from.add(1, 'hour')
      }
    })
    return freeBlocks
  }   
  
  return (
    <div className={`d-flex flex-column m-0 py-0 pr-0 ${openSideLeft ? 'pl-6' : 'pl-0' }`}>
      <div
        className='d-flex flex-row justify-content-between w-100 py-4' style={{
          maxHeight: '10vh',
          height: '10vh',
          width: '81%',
        }}
      >
        {
          lessonsSelected.length > 0
            ? <div className='d-flex flex-row justify-content-between w-100'>
              <div className='d-flex flex-row'>
                <CheckboxOption
                  onChange={(e) => { if (!e) setLessonsSelected([]) }}
                  className='custom-control-input-calendar-admin'
                  defaultState={lessonsSelected.length > 0}
                />
                <span className='mr-2 ml-0'>
                  <span className='ml-0 h4'>{`${lessonsSelected.length} ${
                    lessonsSelected.length === 1
                    ? translate('lesson.multipleselection.lessonselec')
                    : translate('lesson.multipleselection.lessonsselec')
                  }`}
                  </span>
                </span>
              </div>
              <div className='d-flex flex-row'>
                {lessonsSelected.length === 1 && (
                  <>
                    <OptionSelection
                      IconComponent={IconWspAlert}
                      label={translate('modal.infolesson.sendwspalert')}
                      onClick={() => {
                        dispatch({
                          type: 'SET_DATA_MODAL_FORM_LIST',
                          payload: 'lesson.wspAlert',
                          data: {
                            isOpen: true,
                            dataLesson: lessons.find((lesson) => lesson.id === lessonsSelected[0])
                          }
                        })
                      }}
                    />
                    <OptionSelection
                      IconComponent={IconEmail}
                      onClick={() => {
                        dispatch({
                          type: 'SET_DATA_MODAL_FORM_LIST',
                          payload: 'lesson.sendEmailReminder',
                          data: {
                            isOpen: true,
                            dataLesson: lessons.find((lesson) => lesson.id === lessonsSelected[0])
                          }
                        })
                      }}
                      label={translate('modal.infolesson.sendreminder')}
                    />
                    <OptionSelection
                      IconComponent={IconEdit}
                      onClick={() => {
                        dispatch({
                          type: 'SET_DATA_MODAL_FORM_LIST',
                          payload: 'lesson.editLessonModal',
                          data: {
                            isOpen: true,
                            dataLesson: lessons.find((lesson) => lesson.id === lessonsSelected[0]),
                            editPending: false
                          }
                        })
                      }}
                      label={translate('modal.infolesson.editlesson')}
                    />
                  </>
                )}
                {
                  !lessonsCalendarArray.find(
                    (lesson) => lesson.idLesson === lessonsSelected[0]
                  )?.isGroup && !lessonsSelectedHasInProgress() && (
                    <OptionSelection
                      IconComponent={IconProgram}
                      label={translate('modal.infolesson.leavepending')}
                      onClick={() => { SetMultipleLessonsInPending(lessonsSelected) }}
                    />
                  )
                }
                <OptionSelection
                  IconComponent={IconErase}
                  label={translate('lesson.multipleselection.remove')}
                  onClick={() => { RemoveMultipleLessons(lessonsSelected) }}
                />
              </div>
              </div>
            : <>
              <div className='d-flex flex-row'>
                <Button className='align-self-center' onClick={() => { setWeek(0) }} style={{ width: '100px', color: '#254050', lineHeight: '22px', border: 'solid 1px #DCE1E7' }} label={translate('lessons.calendar.today').toUpperCase()} size='small' />
                <div className='week-control d-flex flex-row align-items-center px-4'>
                  <div style={{ width: '80px' }} className='d-flex flex-row justify-content-between'>
                    <ToolTipInfo
                      tooltipId={'last-week'}
                      innerElement={<span className='h45 text-pause'>Last week</span>}
                      place='bottom'
                    >
                      <div 
                        className={'ml-2 mr-0 my-0 hover-icon'}
                        onClick={() => {
                            setWeek(week - 1)
                        }}>
                        <IconArrowLeft size={20}/>
                      </div>
                    </ToolTipInfo>
                    <ToolTipInfo
                      tooltipId={'next-week'}
                      innerElement={<span className='h45 text-pause'>Next week</span>}
                      place='bottom'
                    >
                      <div 
                        className={'mx-2 mr-0 my-0 hover-icon'}
                        onClick={() => {
                          setWeek(week + 1)
                        }}
                      >
                        <IconArrowRight size={20}/>
                      </div>
                    </ToolTipInfo>
                  </div>
                  <span className='ml-4' style={{ textTransform: 'capitalize' }}>{`${moment(from).startOf('isoWeek').format('MMMM Do')} - ${moment(from).endOf('isoWeek').format('MMMM Do')}`}</span>
                </div>
              </div>
                <div className="d-flex flex-row align-items-center px-4">
                  {
                    loading && (
                      <IconLoad />
                    )
                  }
                  <div
                      className="cursor-pointer"
                      onClick={() => {
                        history.push('lessons')
                      }}>
                    <div className='hover-icon cursor-pointer'>
                      <IconList size={20}/> 
                    </div>
                  </div>
                </div>
              {/**<div className='d-flex flex-row px-4'>
                <IconProgram className='align-self-center mx-4' size={16} />
                <InputSelect
                  value={null}
                  style={{ width: '137px', minHeight: '34px', height: '34px' }}
                  options={[]}
                />
              </div>**/}
              </>
        }
      </div>

      {showCalendar === true ?
          (
              <CalendarLesson
                  selectedBlocks={lessonsSelected}
                  selectBlock={(idLesson) => { appendLessonToSelected(idLesson) }}
                  deselectBlock={(idLesson) => { removeLessonFromSelected(idLesson) }}
                  from={from}
                  heightColumnPx={72}
                  widthColumnPx={180}
                  verticalView={breakWidth === 'SM'}
                  lessons={lessonsCalendarArray}
                  style={
                    breakWidth === 'SM'
                        ? {}
                        : {
                          maxHeight: '90vh',
                          height: '90vh'
                        }
                  }
                  zone={zone}
                  changeTimezoneBullet={
                    <div className='m-0 p-0 d-flex flex-column text-center'>
                      <span className='h45 text-pause'>{zone}</span>
                      <span className='h45 text-gray'>{translate('lessons.calendar.clickToChange')}</span>
                    </div>
                  }
                  changeTimezoneClickAction={() => {
                    dispatch({
                      type: 'SET_DATA_MODAL_FORM_LIST',
                      payload: 'lesson.changeTimezoneModal',
                      data: {
                        isOpen: true
                      }
                    })
                  }}
                  onClickInFreeBloc={({ day, hour, fromCalendar }) => {
                    dispatch({
                      type: 'SET_DATA_MODAL_FORM_LIST',
                      payload: 'plan.newLessonModal',
                      data: {
                        isOpen: true,
                        defaultForm: {
                          repeatmonth: false,
                          repeat: false,
                          repeatweek: false,
                          timeStart: moment(fromCalendar).add(day, 'day').set({ hour: hour, minutes: 0 }).format('HH:mm'),
                          timeEnd: moment(fromCalendar).add(day, 'day').set({ hour: hour, minutes: 50 }).format('HH:mm'),
                          date: moment(fromCalendar).add(day, 'day').format('YYYY-MM-DD'),
                          paymentDate: moment().format('YYYY-MM-DD'),
                        }
                      }
                    })
                  }}
                  handleDragUpdateLesson={(env) => {
                    const { draggableId: draggableIdJSON } = env // draggableId:  id de lección a reagendar
                    if (
                        !draggableIdJSON
                    ) {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'lesson.dragAndDropCalendar',
                        data: {isActive: false, freeFrames: []}
                      })
                      return
                    }
                  }}
                  handleDragLesson={(env) => {
                    const { draggableId: draggableIdJSON } = env // draggableId:  id de lección a reagendar
                    if (
                        !draggableIdJSON
                    ) {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'lesson.dragAndDropCalendar',
                        data: {isActive: false, freeFrames: []}
                      })
                      return
                    }
                    const { idEducator, idStudents } = JSON.parse(draggableIdJSON)
                    client.query(
                        {
                          query: gql`
                query(
                  $involveMyself: Boolean!
                  $studentIds: [ID!]
                  $educatorUserId: ID
                  $minDurationInMinutes: Int
                  $to: DateTime!
                  $from: DateTime!
                ) {
                  myFreeTimeFrames(
                    involveMyself: $involveMyself
                    studentIds : $studentIds 
                    educatorUserId: $educatorUserId
                    minDurationInMinutes: $minDurationInMinutes
                    to: $to
                    from: $from
                  )
                }
              `,
                          variables: {
                            involveMyself: false,
                            studentIds : idStudents,
                            educatorUserId: idEducator,
                            minDurationInMinutes: 60,
                            from: from,
                            to: to
                          }
                        }
                    ).then((result) => {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'lesson.dragAndDropCalendar',
                        data: {
                          isActive: true,
                          freeFrames: parseFreeFramesToBlocks(result?.data?.myFreeTimeFrames || [])
                        }
                      })
                    })
                  }}
                  handleDropLesson={(env) => {
                    const { destination, draggableId: draggableIdJSON, source } = env // draggableId:  id de lección a reagendar
                    if (
                        destination?.droppableId === source?.droppableId ||
                        !source ||
                        !destination ||
                        !draggableIdJSON
                    ) {
                      dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'lesson.dragAndDropCalendar',
                        data: {isActive: false, freeFrames: []}
                      })
                      return
                    }
                    const {idLesson: draggableId} = JSON.parse(draggableIdJSON)
                    const [isoString, day, hour] = destination.droppableId.split('&')
                    // day es un string numerico entre el 0 y 6, 0 es Lunes y 6 Domingo
                    const newTime = moment(isoString).tz(zone).startOf('isoWeek')
                        .isoWeekday(parseInt(day) + 1).hour(hour).toISOString()
                    const oldDataLesson = lessonsCalendarArray.find((lesson) => lesson.idLesson === draggableId)
                    // EVITA QUE EL BLOQUE VUELVA A SU POSICIÓN ORIGINAL MIENTRAS SE EJECUTA LA MUTACIÓN
                    setLessonsCalendarArray(lessonsCalendarArray.map((lesson) => {
                      if (lesson.idLesson === draggableId) {
                        return {
                          ...lesson,
                          hour: parseInt(hour),
                          day: parseInt(day),
                          loading: true
                        }
                      }
                      return lesson
                    }))
                    dispatch({
                      type: 'SET_DATA_MODAL_FORM_LIST',
                      payload: 'lesson.dragAndDropCalendar',
                      data: {isActive: false, freeFrames: []}
                    })

                    // EJECUTA LA MUTACIÓN
                    rescheduleSubmit({
                      startDate: newTime,
                      lessonId: draggableId,
                      ignoreEducatorUnavailability: false,
                      oldDataLesson
                    })

                  }}
                  loading={loading}
              />
          ):
          <List lessons={lessonsCalendarArray} loading={loading}/>
      }
      <CancelMultipleLessons
        refetchCalendarQuery={refetchCalendarQuery}
        setLessonsSelected={setLessonsSelected}
      />
      <CancelLesson
        refetch={() => {refetchCalendarQuery()}}
        setLessonsSelected={setLessonsSelected}
        lessonsSelected={lessonsSelected} />
      <SetPendingMultipleLessons
        refetchCalendarQuery={refetchCalendarQuery}
        setLessonsSelected={setLessonsSelected}
      />
    </div>
  )
}

export default CalendarSection
