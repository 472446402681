import React, { useState } from 'react'
import {
    Avatar,
    IconArrowDown,
    IconArrowUp,
    IconClose
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import ChangePaymentMethod from '../../../Student/modal/InfoStudent/Profile/ChangePaymentMethod'
import PaymentMethod from '../../../Student/modal/InfoStudent/Profile/ChangePaymentMethod/PaymentMethod'

const ChangePaymentMethodPFromParent = (props) => {
    // eslint-disable-next-line
    const { childrens, toggle, parent, toggleFull, refetch } = props
    const { translate } = useTranslation()
    const [showPMStudent, setShowPMStudent] = useState(false)
    const [userSelected, setUserSelected] = useState('')

    const showPMStudentFunction = (index) => {
        setUserSelected(index)
        setShowPMStudent(!showPMStudent)
    }

    return (
        <div className='mt-6'>

            <span className='d-flex w-100 flex-row justify-content-between h2 font-weight-bold px-3 mt-2'>
                {translate('profile.actions.changeMethod.change')}
                <IconClose size={20} onClick={toggleFull} color='rgba(119, 134, 153, .4)' />
            </span>
            {childrens?.length > 1 &&
                <div className='mt-4 px-3'>
                    <span className='d-flex w-100 flex-row justify-content-between h3 mt-5 mb-3 ml-4'>
                        Students
                    </span>

                    {childrens?.map((stu, idx) => {
                        return <div className='d-flex flex-column ml-2'>

                            <div className='d-flex flex-row mb-2'>
                                <div className='w-90 d-flex flex-row align-items-center'>
                                    <Avatar
                                        name={stu?.firstName + ' ' + stu?.lastName}
                                        size='avatar-medium'
                                    />
                                    <label className='ml-2 my-auto text-info' style={{ color: '#283d57' }}>
                                        {`${stu?.firstName} ${stu?.lastName}`}
                                    </label>
                                </div>
                                <div className='d-flex flex-row w-100'>
                                    {(stu.paymentMethod !== null && !showPMStudent) && 
                                    <PaymentMethod {... stu.paymentMethod} isParent={true}/>
                                    }
                                </div>
                                <div className='m-0 my-auto'>
                                    {!showPMStudent ? (
                                        <IconArrowDown size={20} color='#9da7bb'
                                            onClick={() => showPMStudentFunction(idx)} />
                                    ) : (
                                        <IconArrowUp size={20} color='#9da7bb'
                                            onClick={() => showPMStudentFunction(idx)} />
                                    )}

                                </div>
                            </div>
                            {(showPMStudent && idx === userSelected) && <ChangePaymentMethod
                                userData={childrens[idx]}
                                toggle={toggleFull}
                                refetch={refetch}
                                isParent={true}
                            />}
                        </div>
                    })}
                </div>
            }

            {childrens?.length === 1 &&
                <ChangePaymentMethod
                    userData={childrens[0]}
                    toggle={toggleFull}
                    refetch={refetch}
                    isParent={true}
                />
            }
        </div>
    )
}

export default ChangePaymentMethodPFromParent
