import { gql, useQuery } from '@apollo/client'
import validate from './validation'

export default (vars) => {
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return { loading: false, error: errors, data: {} }
  }
  const { data, error, loading, refetch } = useQuery(
    gql`query(
        $limit: Int,
        $next: String,
        $previous: String,
        $sortField: String,
        $status: [PaymentStatus!],
        $userId: [String!],
        $parentUserId: [String!],
        $from: DateTime,
        $to: DateTime,
        $category: [PaymentCategory!],
        $searchString: String,
        $current: Boolean,
        $pending: Boolean
      ) {
        invoices(
          limit: $limit,
          next: $next,
          previous: $previous,
          sortField: $sortField,
          status: $status, 
          userId: $userId,
          parentUserId: $parentUserId,
          from: $from,
          to: $to,
          category: $category,
          searchString: $searchString
          current: $current,
          pending: $pending
        ) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs            
            docs {
              id
              status
              category
              invoiceNumber
              userId {
                id
                firstName
                lastName
                paymentMethod {
                  kind
                  ... on ManualPaymentMethod {
                      kind
                  }
                  ... on CardPaymentMethod{
                      kind
                      status
                      last4
                      brand
                      expMonth
                      expYear
                  }
                  ... on ZellePaymentMethod {
                    kind
                    zellePaymentInfo {
                      emailToSend
                    }
                  }
                }
              }
              processedTimes
              invoiceNumber
              createdAt
              updatedAt
              from
              to
              paymentItemIds
              lastProcessedAt
              amount
              isManual
              paymentItems {                
                ... on PlanPaymentItem {
                  planId
                  id
                }
                ... on LessonPaymentItem {
                  lessonId
                  id
                }
                ... on EducatorPaymentItem {
                  id
                }
                ... on CustomPaymentItem {
                  id
                }
              }      
            }
          }
        }`,
    {
      variables: vars,
      fetchPolicy: 'no-cache'
    }
  )
  return { loading, data: data ? data.invoices : {}, error, refetch }
}
