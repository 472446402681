import React from 'react'
import { RowTask, StickyButton, Avatar, ToolTipInfo } from '@Knowledge-OTP/znk-ui-components'
import {getTaskNameField} from '../../../../useColumns'
import DaysToComplete from '../../../../../../../CounselingTemplates/Task/viewTaskModal/Components/DaysToComplete'
import moment from 'moment'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const ExecutePage = (props) => {
    const { onContinue = () => {}, onClickTask = () => {}, tasks = [] } = props
    const {translate} = useTranslation()


    const AssignUser = ({task}) => {
        const userName = `${task?.dataPlan?.studentUser?.firstName} ${task?.dataPlan?.studentUser?.lastName}`
        return (
            <ToolTipInfo
                place={'left'}
                tooltipId={`task-${task?.id}-${task?.dataPlan?.studentUser?.studentUserId}`}
                innerElement={<span>{userName}</span>}
            >
                <Avatar
                    size={`avatar-small`}
                    name={userName}
                />
            </ToolTipInfo>
        )
    }


    return (
        <div className='w-100 h-100 p-0 m-0 d-flex flex-column justify-content-between align-items-center'>
            <div className='d-flex flex-column w-100 m-0 p-0'>
                {
                    tasks.map(
                        (task, index) => {
                            return (
                                <RowTask
                                    className={'w-100 cursor-pointer mb-2'}
                                    row={[
                                        getTaskNameField(task),
                                        <div className='d-flex flex-row align-items-center' style={{gap: 10}}>
                                            <DaysToComplete task={{...task, dueDate: moment().add(task.dueTime, 'days').toISOString()}} noIcon type={`date`} normalClass={`text-gray`} />
                                            <AssignUser task={task} />
                                        </div>
                                    ]}
                                    config={[
                                        { col: 8, className: 'h4 d-flex flex-row justify-content-start align-items-center text-truncate' }, 
                                        { col: 3, className: 'h45 text-gray d-flex flex-row justify-content-end align-items-center text-truncate pr-0' }
                                    ]}
                                    onClick={
                                        () => {
                                            onClickTask(index)
                                        }
                                    }
                                />
                            )
                        }
                    )
                }
            </div>
            <StickyButton
              activity={false}
              disabled={false}
              color='primary'
              condition={true}
              label={translate("workspace.tasks.assignMultiTask.modal.pages.confirm.onConfirm")}
              className={'w-100 my-4'}
              onClickButtonFunction={() => {
                if(onContinue) onContinue()
              }}
            />

        </div>
    )
}

export default ExecutePage