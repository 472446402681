import {useMutation, gql} from '@apollo/client'
import myUniversity from './queryResults/myUniversity'

    const useRemoveMyUniversity = (onSuccess = () => {}, onDelete = () => {}) => {

    return useMutation(
        gql`
            mutation(
                     $universityId: ID!
                     $userId: ID
                     ){
                         removeMyUniversity (
                            universityId: $universityId
                            userId: $userId
                         ){
                            ${myUniversity} 
                         }
                     }
        `,
        {
            onCompleted: () => {
                onSuccess()
            },
            onError: () => {
                onDelete()
            }
        }
    )
}

export default useRemoveMyUniversity