import {useEffect} from 'react'
import { useLocation } from 'react-router'
import { useLazyQuery, gql } from '@apollo/client'
import planQueryResult from '../Model/queryResults/planQueryResult'
import useCounselingPlanReducer from '../../../reducers/counseling-plan'
import useViewPlanModal from '../Modal/viewPlanModal/useViewPlanModal'
import useCounselingPlanList from '../useCounselingPlanList'
 
const RedirectToModalPlan = () => {
    //Recibe planId (counceling) por url y abre modal
    const {parsePlanFromAPI} = useCounselingPlanReducer()
    const {open} = useViewPlanModal()
    const {refetch} = useCounselingPlanList()
    const [getPlan] = useLazyQuery(
        gql`
              query($planId: ID!){
                plan(planId: $planId){
                  ${planQueryResult}
                }
              }
            `,
        {
          onCompleted: ({plan: planInfo}) => {
            if(planInfo?.kind === 'counceling') {
                open(
                    () => {
                        //refetch del listado de planes (en redux)
                        refetch()
                    }, parsePlanFromAPI(planInfo)
                )
            }
          }
        }
    )

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const planId = searchParams.get('planId')
    useEffect(() => {
        if(planId) {
            getPlan({
                variables: {
                    planId
                }
            })
        }
    }, [planId, getPlan])

    return (null)
}

export default RedirectToModalPlan