export const addIdToStudents = (students = []) => {
    if(!Array.isArray(students)) return
    if(students?.length < 1) return
    let studentsWithId = []
    // eslint-disable-next-line
    students.map(stu => {   
        studentsWithId.push({...stu, 
            id: stu?.studentUserId || stu?.id || stu?._id
        })
    })
    return studentsWithId
  }