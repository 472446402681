import {useQuery, gql} from '@apollo/client'
import planQueryResult from '../../CounselingPlans/Model/queryResults/planQueryResult'

const useMyPlan = (vars = {}) => {
  return useQuery(
    gql`
      query($isAdmin: Boolean, $planId: ID!) {
        myPlan(isAdmin: $isAdmin, planId: $planId) {
          ${planQueryResult}
        }
      }
    `,
    {variables: vars},
  )
}

export default useMyPlan
