import React, { useEffect, useState } from 'react'
import {
    IconBank,
    ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import { useGetUniversitiesById } from '../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/Universities/Model/useGetUniversitiesById'



const IsSpecific = (props) => {
    //universitiesList tiene que ser un arreglo de ids si vamos a buscar en la DB la info, y findInDB tiene que ser TRUE
    //si le pasamos un array con el nombre de la universidad y no queremos que lo busque en la DB, findInDB tiene que ser false y 
    //universitiesList tiene que tener un campo "name" con el nombre de la universidad
    const { universitiesList, findInDB = false, showLabel = true, showTooltip = false, className, iconColor = 'info', taskId, ...otherProps } = props
    const [universitiesToShow, setUniversitiesToShow] = useState([])
    // eslint-disable-next-line
    const [getUniversitiesById, { data: dataUniversities, loading: loadingUniversities }] = useGetUniversitiesById(
        (resp) => {
            setUniversitiesToShow(resp.getUniversitiesById)
        },
        (err) => {
            console.error('Error on getting lesson : ', err)
        })

    useEffect(() => {
        if (findInDB && universitiesList?.length > 0) {
            getUniversitiesById({
                variables: {
                    universityIds: universitiesList,
                }
            })
        } else if (!findInDB) {
            setUniversitiesToShow(universitiesList)
        }
        // eslint-disable-next-line
    }, [findInDB, universitiesList])

    return (
        <div className={`m-0 p-0 ${className}`} {...otherProps}>
            {
                (universitiesToShow?.length < 2 && !showTooltip) ?
                    <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                        <IconBank className={'text-info'} size={16} />
                        {
                            showLabel ?
                                universitiesToShow?.length === 1 ? 
                                    <span className={`h45 ml-2 text-info`}>{`${universitiesToShow[0].name}`}</span> :
                                    <span className={`h45 ml-2 text-info`}>{`Universities`}</span> 
                                : null        
                        }   
                    </div> :
                    <ToolTipInfo 
                        tooltipId={`universities-names-${taskId}`}
                        disable={!showTooltip}
                        innerElement={
                            <div className='d-flex flex-column m-0 pt-2 pl-1'>
                                {
                                // universitiesToShow?.length < 2 ? null :
                                    universitiesToShow?.map(univ => {
                                        return <label className={'h45 text-white'}>{univ.name}</label>
                                    })
                                }
                            </div>
                        }
                        place={`bottom`}
                    >
                        <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                            <IconBank className={`text-${iconColor}`} size={16} />
                            {
                                showLabel ?
                                    universitiesToShow?.length === 1 ? <span className={`h45 ml-2 text-info`}>{`${universitiesList[0].name}`}</span> :
                                        universitiesToShow?.length === 0 ? <span className={`h45 ml-2 text-info`}>{`Universities`}</span>
                                            : <span className={`h45 ml-2 text-info`}>{`${universitiesList?.length} Universities`}</span>
                                    : null      
                            } 
                        </div>
                    </ToolTipInfo>
            }
        </div>    

    )
}

export default IsSpecific