import {useQuery, gql} from '@apollo/client'
import planQueryResult from '../../../CounselingPlans/Model/queryResults/planQueryResult'

const usePlan = (vars = {}) => {
    return useQuery(
        gql`
            query ($planId: ID!) {
                plan (
                    planId: $planId
                ) {
                    ${planQueryResult}
                }
            }
        `,
        {
            variables: vars
        }
    )
}

export default usePlan