import moment from 'moment'

export const totalLpw = (form) => {
    let count = 0
    if (form.lpw) {
      if (form.typePlan === 'time' && form.typeLesson === 'totalLesson') {
        Object.keys(form.lpw).forEach((lpwInd) => {
          Object.keys(form.lpw[lpwInd]).filter(
            (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
          ).forEach((idTopic) => {
            count += form.lpw[lpwInd][idTopic].value
          })
        })
        //CANTIDAD TOTAL DE LECCIONES
        return count
      } else {
        Object.keys(form.lpw).forEach((lpwInd) => {
          let weeks = 1
          if (form.lpw[lpwInd].startdate && form.lpw[lpwInd].enddate) {
            const partFrom = moment(form.lpw[lpwInd].startdate).utc()
            const partTo = moment(form.lpw[lpwInd].enddate).utc()
            // si una semana tiene menos de tres dias no contarla como una semana valida
            weeks = (partFrom.diff(partTo, 'week') * -1) + 1
            if (partTo.day() < partFrom.day() && partTo.day() !== 0 && weeks >= 2) {
              // en este caso diff cuenta una semana menos
              weeks += 1
            }
            if (partFrom.day() > 3 && partFrom.day() !== 0 && weeks >= 2) {
              weeks -= 1
            }
            if (partTo.day() < 3 && partTo.day() !== 0 && weeks >= 2) {
              // la semana final es demasiado corta => no se considera
              weeks -= 1
            }
          }
          Object.keys(form.lpw[lpwInd]).filter(
            (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
          ).forEach((idTopic) => {
            count += form.lpw[lpwInd][idTopic].value// * weeks
          })
        })
        //cantidad total de lecciones por semana
        return count
      }
    }
    return count
}

export const totalLesson = (form) => {
    let count = 0
    if (form.lpw) {
      if (form.typePlan === 'time' && form.typeLesson === 'totalLesson') {
        Object.keys(form.lpw).forEach((lpwInd) => {
          Object.keys(form.lpw[lpwInd]).filter(
            (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
          ).forEach((idTopic) => {
            count += form.lpw[lpwInd][idTopic].value
          })
        })
        return count
      } else {
        Object.keys(form.lpw).forEach((lpwInd) => {
          let weeks = 1
          if (form.lpw[lpwInd].startdate && form.lpw[lpwInd].enddate) {
            const partFrom = moment(form.lpw[lpwInd].startdate).utc()
            const partTo = moment(form.lpw[lpwInd].enddate).utc()
            // si una semana tiene menos de tres dias no contarla como una semana valida
            weeks = (partFrom.diff(partTo, 'week') * -1) + 1
            if (partTo.day() < partFrom.day() && partTo.day() !== 0 && weeks >= 2) {
              // en este caso diff cuenta una semana menos
              weeks += 1
            }
            if (partFrom.day() > 3 && partFrom.day() !== 0 && weeks >= 2) {
              weeks -= 1
            }
            if (partTo.day() < 3 && partTo.day() !== 0 && weeks >= 2) {
              // la semana final es demasiado corta => no se considera
              weeks -= 1
            }
          }
          Object.keys(form.lpw[lpwInd]).filter(
            (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
          ).forEach((idTopic) => {
            count += form.lpw[lpwInd][idTopic].value * weeks
          })
        })
        return count
      }
    }
    return count
  }


  export default {
    totalLesson,
    totalLpw
  }