import { useSelector, useDispatch } from "react-redux"

const useEditPaymentModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.editPaymentModal)
    const {isOpen, onChange, onDelete, defaultValue} = reduxData
    const setOpen = (
        open, onChange = () => {}, onDelete = () => {}, 
        defaultValue = {amount: null, date: null}, 
        title = 'Edit payment item', isNew = false
        ) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.editPaymentModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                onDelete,
                defaultValue,
                title,
                isNew
            }
        })
    } 
    return {
        ...reduxData,
        isOpen,
        onChange,
        onDelete,
        defaultValue,
        open: (onChange = () => {}, onDelete = () => {}, defaultValue = {amount: null, date: null}, title = 'Edit payment item', isNew=false) => {
            setOpen(true, onChange, onDelete, defaultValue, title, isNew)
        },
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)},
        setTotalAmount: (total /**max**/, min) => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'counseling.editPaymentModal',
                data: {
                    ...reduxData,
                    totalAmount: total,
                    minAmount: min
                }
            })
        }
    }
}

export default useEditPaymentModal