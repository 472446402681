const taskTypes = {
  completion: 'completion',
  uploadFile: 'uploadFile',
  link: 'link',
  scheduledMeetting: 'scheduledMeetting',
  scoreCollection: 'scoreColletion',
  loginInfo: 'logoinInfo',
}

export default taskTypes
