import React from 'react'
import { 
    IconOptions,
    DropDownNew,
    DropDownItem
} from '@Knowledge-OTP/znk-ui-components'
import useOptionsByStatus from './useOptionsByStatus'

const OptionsField = (props) => {
    const {lesson, onClick=() => {}, refetch = () => {}, ...otherProps} = props
    const {options} = useOptionsByStatus(lesson, refetch)
    return (
        <div 
            onClick={(e) => {
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
                if(onClick) onClick()
            }}
            {...otherProps}
        >
            <DropDownNew
                direction={'bottomLeft'}
                options={
                    options.map((item) => {
                        return {
                            component: <DropDownItem 
                                className={`text-info`}
                                onClick={() => {
                                    if(item?.onClick) item.onClick()
                                }}
                            >
                                {item?.label}</DropDownItem>
                        }
                    })
                }
            >
                <div className={`hover-icon`} style={{width: 'fit-content'}}>
                    <IconOptions size={16}/>
                </div>
            </DropDownNew>
        </div>
    )
}

export default OptionsField