import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
// import useMessageModal from './useMessageModal'

const MessageModal = (props) => {
  // const {isOpen, close, toggle, message} = useMessageModal()
  const {isOpen, toggle, message = '', messageBody = ''} = props

  return (
    <ConfirmModal
      classNameActionDiv='d-flex flex-column'
      classNameConfirm='text-primary white-space-nowrap'
      isOpen={isOpen}
      toggle={toggle}
      title={message}
      body={messageBody}
      confirmAction={() => {
        toggle()
      }}
      // cancelAction={() => {close()}}
      confirmText='Ok'
      // cancelText={'Cancel'}
    />
  )
}

export default MessageModal
