import { initialState } from './initialState'
import {
  CHANGE_TIMEZONE,
  GET_TIMEZONE
} from './const'
import moment from 'moment-timezone'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_TIMEZONE:
      moment.tz.setDefault(action.payload || moment.tz.guess())
      moment.locale('en')
      return {
        ...state,
        zone: action.payload
      }
    case GET_TIMEZONE:
      return { ...state }
    default:
      return { ...state }
  }
}
