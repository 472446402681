import React from 'react'
import { Field, InputSelect, PlainTextInput, AmountInput, IconErase } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import { types } from '../../../Payments/constants'
import { escapeRegExp } from 'lodash'
import SelectPlan from '../../../../CounselingTemplates/Task/newTaskModal/Components/SelectPlan'
import { Collapse } from 'reactstrap'
import formatNumber from '../../../../../utils/formatNumber'


const PaymentItemInput = (props) => {
    const {
        showToBorder = false,
        onChangeType = () => {},
        onChangeDiscount = () => {},
        onChangeAmount = () => {},
        onChangeDescription = () => {},
        onChangePlan = () => {},
        element, index, form = {}, 
        onDelete = () => {}, invoice = {}} = props
    const {translate} = useTranslation()
    const optionTypes = types.filter(
        e => e.type.includes(form.category === 'livePrep' ? 'invoice' : 'counseling') || e.id === 'other')
        .map((serv) => ({ value: serv.id, label: translate(`payments.types.${serv.id}`) }))
    return (
        <div
            key={`item-${index}`}
            className='w-100 d-flex flex-row justify-content-between align-items-center px-7 mt-1 hover-button'
        >
            <div className=''>
                {
                    showToBorder && <hr className='mt-1 mb-4' />
                }
                <div className='row d-flex m-0'>
                    <div className='col-12 p-0 mb-2'>
                        <Field
                            className='col-12 p-0'
                            type={InputSelect}
                            menuPlacement={'auto'}
                            placeholder={translate('modal.newplan.selectservice')}
                            options={optionTypes}
                            InputClassName='w-100'
                            value={element.type}
                            onChange={onChangeType}
                            filterOption={(obj, string) => {
                                if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                                return false
                            }}
                        />
                        {element.errorType !== '' && (<h5 className='text-error'>{translate('payments.editinvoice.errors.type')}</h5>)}
                    </div>
                </div>
                <div className='row d-flex m-0'>
                    <div className='col-4 pl-0 pr-0 pl-0 mb-2 text-right'>
                        <Field
                            className='col-12 placeholder-to-right'
                            type={AmountInput}
                            fieldType='number'
                            placeholder='0.00'
                            addOn='%'
                            min='0'
                            value={element.percentDiscount}
                            onChange={onChangeDiscount}
                        />
                        {element.errorDiscount !== '' && (<h5 className='text-error'>{translate('payments.editinvoice.errors.discount')}</h5>)}
                    </div>
                    <div className='col-8 pl-1 pr-0 pl-2 mb-2 text-right'>
                        <Field
                            className='col-12 placeholder-to-right'
                            type={AmountInput}
                            fieldType='number'
                            placeholder='0.00'
                            addOn='$'
                            min='0'
                            value={element.amountWithoutDiscount}
                            onChange={onChangeAmount}
                        />
                        {element.errorAmount !== '' && (<h5 className='text-error'>{translate('payments.editinvoice.errors.amount')}</h5>)}
                    </div>
                </div>
                <Collapse isOpen={element.percentDiscount > 0}>
                    <span className='w-100 h45 text-gray d-flex flex-row justify-content-end pb-2 pt-1'>{`${translate('payments.editinvoice.amountDiscountLabel')} $${formatNumber(element?.amount)}`}</span>
                </Collapse>
                <div className='row d-flex m-0 p-0 mt-1 mb-3'>
                    <div className='col-12 p-0'>
                        <Field
                            type={PlainTextInput}
                            placeholder='Description'
                            fieldType='text'
                            value={element.description}
                            onChange={onChangeDescription}
                        />
                        {element.errorDescription !== '' && (<h5 className='text-error'>{translate('payments.editinvoice.errors.description')}</h5>)}
                    </div>
                </div>
                {
                    element.type === "studyplan" && (
                        <div className='row d-flex m-0 p-0 mt-1 mb-3'>
                            <div className='col-12 p-0'>
                                <SelectPlan
                                    userId={invoice?.userId?.id}
                                    onChange={onChangePlan}
                                    value={element.planId}
                                />
                                {element.errorPlan !== '' && (<h5 className='text-error'>{translate('payments.editinvoice.errors.plan')}</h5>)}
                            </div>
                        </div>
                    )
                }
            </div>
            <div className='hover-button--on' onClick={() => { onDelete(element) }}>
                <IconErase size={18} color='#FF3E1D' />
            </div>
        </div>
    )
}


export default PaymentItemInput