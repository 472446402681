import React, {useMemo} from 'react'
import {
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import usePaginatedServicesQuery from '../../../../LessonsList/Filters/Service/Model/usePaginatedServicesQuery'
import { ordenateArrayByName } from '../../../../../utils/ordenateArrayByName'
const ServiceSelector = (props) => {
    const {filters = null, setFilters = () => {}} = props
    const {data, loading} = usePaginatedServicesQuery({limit: 0})
    const services = useMemo(
        () => ordenateArrayByName(data?.paginatedServices?.docs) || [], [data]
    )
    return (
        <div className={`d-flex flex-column`}>
            <div className={`w-100 d-flex flex-row justify-content-start`}>
                <span className='h45 text-gray'>Service</span>
            </div>
            <InputSelect
                className={`my-3`}
                options={
                    services?.map(
                        ({id, name}) => {
                            return {value: id, label: name}
                        }
                    )
                }
                onChange={setFilters}
                value={filters} 
                menuPlacement={'top'}
                isLoading={loading}
                name={`servicesSelector`}
            />
        </div>
    )
}

export default ServiceSelector