import React, { useEffect, useState, useMemo, useCallback } from 'react'
import * as moment from 'moment'
import {
    CheckboxOption,
    Task,
    IconSignPlus,
    ListItem,
    Button,
    IconProgram,
    IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import ShowUserAvatar from '../viewTaskModal/Components/ShowUserAvatar'
import InputDueDate from '../newTaskModal/Components/InputDueDate'
import RenderInputOption from '../../../CounselingPlans/Modal/newPlanModal/Components/RenderInputOption'
// import useViewPlanModal from '../../../CounselingPlans/Modal/viewPlanModal/useViewPlanModal'
import UniversitySelector from '../viewTaskModal/Components/UniversitySelector'
import useOperationTaskMutation from '../Model/useOperationTask'
import SelectDate from '../newTaskModal/Components/SelectDate'
import usePlan from '../Model/usePlan'
import {showAlert} from '../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import taskTypes from '../../../University/Utils/taskTypes'
import SelectCounselor from './Components/SelectCounselor'
import { extractTeamFromPlan } from './Utils/extractTeamFromPlan'
import { findTasksOnPlan } from '../utils'
import useTranslation from '../../../../i18n/useTranslation'

const InnerAssingTaskModal = (props) => {
    const { taskData, close = () => {}, onChange = () => {}, useMutation = false, loading = false, validation = () => {}, useWizard = false } = props//useAssingTaskModal()
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    // const {
    //     dataPlan, 
    // } = useViewPlanModal()
    const [taskState, setTaskState] = useState(taskData)
    const [selectUser, setSelectUser] = useState('')
    // eslint-disable-next-line
    const isValidate = useMemo(() => validation(taskState), [taskState])
    useEffect(() => { 
        if(taskData?.status === 'unassigned') {
            setTaskState({
                ...taskData,
                dueDate: moment().add(taskData?.dueTime, 'days') 
                // dueTime: moment(taskData.dueDate).endOf('day').diff(moment(), 'days') 
            })
        } else {
            const days = moment.duration(moment(taskData.dueDate).startOf('day').diff(moment())).asDays()
            setTaskState({
                ...taskData,
                dueTime: Math.ceil(days) 
                // dueTime: moment(taskData.dueDate).endOf('day').diff(moment(), 'days') 
            }) 
        }
    }, [taskData])
    const {
        userId,
        instructions,
        buttonLabel,
        dueTime,
        // dueDate,
        isEspecific,
        planId,
        type,
    } = taskState
    // INFO DEL PLAN
    const {data: dataPlan, loading: loadingPlan} = usePlan({planId: planId || ''})
    const plan = dataPlan?.plan
    const callBack = (newTask = {}) => {
        //entrega nueva tarea a viewPlanModal
        onChange({ ...taskData, ...taskState, ...newTask, status: 'pending' })
    }

    const [taskOperation, { loading: loadOperation }] = useOperationTaskMutation(
        (result) => {
            callBack(findTasksOnPlan(result.operationTask.tasks, taskData).filter(task => task.id === taskData.taskId)[0])
        },
        (err) => {
            console.error("Error on assign task mutation : ", err)
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
        }
    )
    const userToAssign =  userId || plan?.studentUser?.studentUserId
    const universitiesOf = plan?.studentUser?.studentUserId || userId

    //parse formulario a InputObject de la mutación TaskOperation
    const parseTaskToMutation = useCallback(() => {
        return {
            universities: taskState.universities,
            operation: 'asign',
            userId: userToAssign,
            //taskData
            taskId: taskData?.id || taskData?.taskId,
            stepId: taskData?.stepId,
            phase: taskData?.phaseId.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }),
            planId: taskData?.planId,
            dueTime: taskState?.dueTime,
            userMeetingId: selectUser ? selectUser : null
        }
    }, [taskData, taskState, selectUser, userToAssign])

    return (
        <>
            <ShowUserAvatar
                onChange={(student) => {
                    setTaskState({
                        ...taskState,
                        userId: student?.id
                    })
                }}
                userId={userToAssign}
                showBottomLine={false}
                isEdit={false}
            />
            <InputDueDate
                className={`h4 ml-1`}
                value={dueTime || 0}
                onChange={(newValue) => {
                    if (newValue < 0) return
                    setTaskState({
                        ...taskState,
                        dueTime: newValue,
                        dueDate: moment(taskState.dueDate).add(newValue - taskState?.dueTime, 'days').toISOString(), 
                    })
                }}
                showTopLine
                showBottomLine
            />
            <SelectDate
                task={taskState}
                className={`h4 ml-1`}
                value={taskState?.dueDate}
                onChange={(newValue) => {
                    setTaskState({ 
                        ...taskState, 
                        dueDate: newValue, 
                        dueTime: moment(newValue).endOf('day').diff(moment(), 'days') 
                    })
                }}
            />

            { type === taskTypes?.scheduledMeetting &&
            <SelectCounselor 
                team={extractTeamFromPlan(plan)}
                loading={loadingPlan}
                selectUser={selectUser}
                setSelectUser={setSelectUser} 
                userMeetingId={taskState?.userMeetingId || ''}
            />
            }

            {isEspecific &&
                <>
                    <ListItem
                        Left={
                            <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                                <CheckboxOption
                                    defaultState={isEspecific}
                                    onChange={(newValue) => {
                                        setTaskState({...taskState, isEspecific: newValue})
                                    }}
                                    className={`ml-1`} />
                                <span className={`ml-2 text-info`}
                                >{`College specific task`}</span>
                            </div>
                        }
                        showBottomLine
                    />
                    <UniversitySelector
                        userId={universitiesOf}
                        showBottomLine
                        selectedArray={taskData?.universities}
                        onChange={(e) => { 
                            setTaskState({...taskState, universities: e})
                            // setUniversities(e) 
                        }}
                    />
                </>
            }

            <RenderInputOption key={'instructions'} type={`textarea`} label={`Instructions`} >
                <textarea
                    name={`instructions`}
                    placeholder={`Describe here how to complete...`}
                    value={instructions || null}
                    onChange={(e) => { 
                        setTaskState({...taskState, instructions: e.target.value})
                    }}
                    className='znk-input w-100 p-3'
                    style={{ height: '150px' }}
                />
            </RenderInputOption>
            <Task
                noIcon
                taskClassName={`my-6`}
                className={`d-flex flex-row justify-content-end`}
                labelLeft={<span className={`text-gray w-100`}>{buttonLabel}</span>}
                labelRight={<IconSignPlus size={12} style={{ verticalAlign: 'inherit' }} className={`rotate-45 text-gray`} />}
            />

            {
                useWizard ? 
                <>
                    <Button
                        onClick={() => {
                            //retorna el objeto ya parseado para entrgarse a la mutación
                            onChange(parseTaskToMutation(), taskState /**formulario**/)
                        }}
                        className={`w-100 mb-2`}
                        color={'primary'}
                        activity={false}
                        disabled={!isValidate}
                        label={
                            <div className={`d-flex flex-row justify-content-between`}>
                                <span className={`text-white py-3`}>{translate("workspace.tasks.assignMultiTask.modal.onContinue")}</span>
                                <IconArrowRight className={`align-self-center`} size={15} />
                            </div>
                        }
                    />
                </> : 
                <>

            <Button
                onClick={() => {
                    if (useMutation) {
                        taskOperation({
                            variables: parseTaskToMutation() 
                            // {
                            //     universities: taskState.universities,
                            //     operation: 'asign',
                            //     userId: userToAssign,
                            //     //taskData
                            //     taskId: taskData?.id || taskData?.taskId,
                            //     stepId: taskData?.stepId,
                            //     phase: taskData?.phaseId.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }),
                            //     planId: taskData?.planId,
                            //     dueTime: taskState?.dueTime,
                            //     userMeetingId: selectUser ? selectUser : null
                            // }
                        })
                    } else callBack()
                }}
                className={`w-100 mb-2`}
                color={'primary'}
                activity={loadOperation || loading}
                disabled={!isValidate}
                label={
                    <div className={`d-flex flex-row justify-content-between`}>
                        <span className={`text-white py-3`}>Assign task</span>
                        <IconProgram className={`align-self-center`} size={15} />
                    </div>
                }
            />
            <Button
                onClick={() => { close() }}
                className={`w-100 btn-gray-border`}
                label={
                    <div className={`d-flex flex-row justify-content-between`}>
                        <span className={`py-3`}>Don't yet</span>
                        <IconSignPlus className={`align-self-center`} size={15} />
                    </div>
                }
            />
                </>
            }
        </>
    )
}

export default InnerAssingTaskModal