import React, { useState, useEffect } from 'react'
import * as moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { IconArrowRight } from '@Knowledge-OTP/znk-ui-components'
import useInvoices from '../../../../../../Model/Payments/Invoices/List'
import {TabPane, TabContent} from 'reactstrap'
import usePaymentScheduledReducer from '../../../../../../reducers/payment-scheduled'
import PaymentsPlan from '../../../../../Plans/modal/scheduled/Payments'
import './index.css'

const Payments = (props) => {
    const {plan} = props
    const { formatNumber } = usePaymentScheduledReducer()
    const userId = plan?.studentUser?.studentUserId
    const { data: dataInvoice, loading: loadingInvoice } = useInvoices({ limit: 0, status: ['pendingPayment', 'procesingPayment', 'paid', 'unPaid'], userId })
    const [total, setTotal] = useState('')
    const [pendingPaymentArray, setPendingPaymentArray] = useState([])
    const [historyPaymentArray, setHistoryPaymentArray] = useState([])
    const [paymentMethod, setPaymentMethod] = useState({brand: '', last4: '' , kind: '', expMonth: '', expYear: '', status: ''})

    useEffect(() => {
        if (dataInvoice && dataInvoice?.docs?.length > 0) {
          let totalAmountCp = 0
          totalAmountCp = Array.from(dataInvoice?.docs).reduce((totalAmount, payitem) => {
              if (payitem?.userId?.paymentMethod && paymentMethod?.brand === '') setPaymentMethod(payitem?.userId?.paymentMethod)
            return totalAmount + payitem.amount
          }, 0)
          setTotal(totalAmountCp)
          const pendingArray = dataInvoice?.docs.filter(x => x.status === 'pendingPayment')
          setPendingPaymentArray(pendingArray)
          const historyArray = dataInvoice?.docs.filter(x => x.status === 'paid' || x.status === 'unPaid')
          setHistoryPaymentArray(historyArray)
    
        }
            // eslint-disable-next-line
      }, [dataInvoice])

      const usePaymentSection = true
      
      // console.log("historyPaymentArray", historyPaymentArray)
      return (
          usePaymentSection ? 
            (<div className={`my-5`}><PaymentsPlan info={plan} isCounseling /></div>) :
            (
              <TabContent activeTab={loadingInvoice ? 'loading' : 'show'}>
                <TabPane tabId={'loading'}>
                  <Skeleton count={1} height={150} />
                  <Skeleton count={5} height={25} />
                </TabPane>
                <TabPane tabId={'show'}>
                  <>
                          <div
                            className='d-flex flex-column p-4 my-2'
                            style={{
                              borderRadius: '16px',
                              backgroundColor: '#35425b',
                              color: '#ffffff'
                            }}
                          >
                            <div className='d-flex flex-row justify-content-between p-2'
                            >
                              <div
                                className='d-flex flex-column' >
                                <span
                                  style={{
                                    color: '#ffffff',
                                  }}
                                > TOTAL</span>
                                <span>USD</span>
                    
                              </div>
                              <span className='h1 align-self-center'
                                style={{
                                  color: '#ffffff',
                                }}>$ {formatNumber(total, 2)}</span>
                    
                            </div>
                            <div
                              className='d-flex flex-row justify-content-between borderTop my-2 p-2'
                            >
                              <span
                              > Credit Card</span>
                              <span
                              > 
                              {paymentMethod?.brand?.charAt(0)?.toUpperCase()}{paymentMethod?.brand?.slice(1)} {paymentMethod?.last4}
                              </span>
                    
                            </div>
                          </div>
                          <table className='w-100 mt-4'>
                            <div className='d-flex justify-content-between px-1 border-bottom mb-2 mt-4 pb-2 align-items-baseline'
                              style={{ color: '#35425b' }}>
                              <span className={'font-weight-bold text-title'}>
                                Payment History
                              </span>
                              <span className={`h45`}>
                              View All
                              </span>
                            </div>
                            {historyPaymentArray?.length > 0 && historyPaymentArray?.map(x => {
                              return (
                                <div className='d-flex flex-row align-items-center justify-content-between px-1 border-bottom mb-2 pb-2'
                                >
                                  <span
                                    className={`h45 ${x?.status === 'unPaid' ? 'late' :
                                      'paidOnTime'
                                      }`}
                                  >
                                    {x?.lastProcessedAt /**|| moment(x?.lastProcessedAt).format("MMMM YYYY")**/}
                                  </span>
                                  <span className={`h45 ml-2 ${x?.status === 'unPaid' ? 'late' :
                                    'paidOnTime'} d-flex flex-row align-items-center`} 
                                    >
                                    $ { formatNumber(parseFloat(x?.amount))} <IconArrowRight className={`ml-2`} size={16}
                                    color={`${x?.status === 'unPaid' ? '#ff3f1d' : '#92bb40'}`}/>
                                  </span>
                                </div>)
                    
                            })}
                    
                            <div className='d-flex justify-content-start px-1 border-bottom mb-2 mt-4 pb-2'
                              style={{ color: '#35425b' }}>
                              <span className={'font-weight-bold text-title'}>
                              Upcoming Payments
                              </span>
                            </div>
                            {pendingPaymentArray?.length > 0 && pendingPaymentArray?.map(inv => {
                    
                              return (
                                <div className='d-flex flex-row justify-content-between align-items-center h45 px-1 border-bottom mb-2 pb-2'
                                  style={{ color: '#58677c' }}
                                  onClick={() => {
                                    // openInvoiceModal(() => {
                                    
                                    // }, inv)
                                  }}
                                  >
                                  <span 
                                  className='h45'
                                  >
                                    {moment(inv?.date).format("MMMM YYYY")}
                                  </span>
                                  <span className='d-flex flex-row align-items-center'
                                  style={{ color: '#9ca6ba'}}
                                  >
                                    $ {formatNumber(parseFloat(inv?.amount))} <IconArrowRight className={`ml-2`} color={'#9ca6ba'}
                                    size={16}
                                    />
                                  </span>
                                </div>)
                    
                            })}
                        </table>
                  </>
                </TabPane>
              </TabContent>
            )
      )
}

export default Payments



// const Payments1 = (props) => {
//   const studentInfo = useSelector((state) => state.user.info)
//   const {translate} = useTranslation()
//   // eslint-disable-next-line
//   const { plan } = props
//   // eslint-disable-next-line
//   const {data: dataInvoice, loading: loadingInvoice, refetch: refetchInvoice} = useQuery(invoicesQuerys)
//   const dataPayments = dataInvoice && dataInvoice.myInvoices
//   const [total, setTotal] = useState('')
//   const [pendingPaymentArray, setPendingPaymentArray] = useState([])
//   const [historyPaymentArray, setHistoryPaymentArray] = useState([])
//   const { open: openInvoiceModal } = useInvoiceModal()
//   useEffect(() => {
    
//     if (dataPayments) {
//       let totalAmountCp = 0
//       totalAmountCp = Array.from(dataPayments?.docs).reduce((totalAmount, payitem) => {
//         return totalAmount + payitem.amount
//       }, 0)

//       setTotal(totalAmountCp)
//       const pendingArray = dataPayments?.docs.filter(x => x.status === 'pendingPayment')
//       setPendingPaymentArray(pendingArray)
//       const historyArray = dataPayments?.docs.filter(x => x.status === 'paid' || x.status === 'unPaid')
//       setHistoryPaymentArray(historyArray)

//     }
//   }, [dataPayments])

//   return (
//     <>

//       <div
//         className='d-flex flex-column p-4'
//         style={{
//           borderRadius: '16px',
//           backgroundColor: '#35425b',
//           color: '#ffffff'
//         }}
//       >
//         <div className='d-flex flex-row justify-content-between p-2'
//         >
//           <div
//             className='d-flex flex-column' >
//             <span
//               style={{
//                 color: '#ffffff',
//               }}
//             > {translate('counseling.payments.total')}</span>
//             <span>{translate('counseling.payments.money')}</span>

//           </div>
//           <span className='h1 align-self-center'
//             style={{
//               color: '#ffffff',
//             }}>$ {total}</span>

//         </div>
//         <div
//           className='d-flex flex-row justify-content-between borderTop my-2 p-2'
//         >
//           <span
//           > {translate('counseling.payments.method')}</span>
//           <span
//           > {studentInfo?.paymentMethod?.cardInfo?.brand.charAt(0).toUpperCase()}{studentInfo?.paymentMethod?.cardInfo?.brand.slice(1)} {studentInfo?.paymentMethod?.cardInfo?.last4}</span>

//         </div>
//       </div>
//       <table className='w-100 mt-4'>
//         <div className='d-flex justify-content-between h45 px-1 border-bottom mb-2 pb-2'
//           style={{ color: '#35425b' }}>
//           <span
//           >
//             {translate('counseling.payments.history')}
//           </span>
//           <span>
//           {translate('counseling.payments.viewAll')}
//           </span>
//         </div>
//         {historyPaymentArray?.length > 0 && historyPaymentArray?.map(x => {
//           return (
//             <div className='d-flex justify-content-between h45 px-1 border-bottom mb-2 pb-2'
//             >
//               <span
//                 className={`h45 ${x?.status === 'unPaid' ? 'late' :
//                   'paidOnTime'
//                   }`}
//               >
//                 {moment(x?.date).format("MMM Do")}
//               </span>
//               <span className={`h45 ml-2 ${x?.status === 'unPaid' ? 'late' :
//                 'paidOnTime'}`} 
//                 >
//                 $ { x?.amount} <IconArrowRight 
//                 color={`${x?.status === 'unPaid' ? '#ff3f1d' :
//                 '#92bb40'}`}
//                 />
//               </span>
//             </div>)

//         })}

//         <div className='d-flex justify-content-start h45 px-1 border-bottom mb-2 pb-2'
//           style={{ color: '#35425b' }}>
//           <span
//           >
//            {translate('counseling.payments.upcommingPayments')}
//           </span>
//         </div>
//         {pendingPaymentArray?.length > 0 && pendingPaymentArray?.map(inv => {

//           return (
//             <div className='d-flex justify-content-between align-self-center h45 px-1 border-bottom mb-2 pb-2'
//               style={{ color: '#58677c' }}
//               onClick={() => {
//                 openInvoiceModal(() => {
                
//                 }, inv)
//               }}
//               >
//               <span 
//               className='h45'
//               >
//                 {moment(inv?.date).format("MMM Do")}
//               </span>
//               <span className='align-self-center'
//               style={{ color: '#9ca6ba'}}
//               >
//                 $ {inv?.amount} <IconArrowRight color={'#9ca6ba'}
//                 size={16}
//                 />
//               </span>
//             </div>)

//         })}
//     </table>
//     </>
//   )
// }

// export default Payments1
