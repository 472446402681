import React, { useState } from 'react'
import useTranslation from '../../../i18n/useTranslation'
import { IconArrowDown, RadioSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import moment from 'moment-timezone'
const TestDateFilter = (props) => {
  const { translate } = useTranslation()
  const { testDate, setTestDate, testDates: { allActivities: testDates }, serviceFilters } = props
  const [servicesCache, setServicesCache] = useState(null)
  if (serviceFilters !== servicesCache) {
    setServicesCache(serviceFilters)
    setTestDate({
      id: '',
      name: '-',
      date: null
    })
  }
  const submit = (testId) => {
    const thisTest = testDates.find(test => test.id === testId)
    setTestDate({ id: testId, name: thisTest.name, date: thisTest.startDate })
  }
  return (
    <RadioSelector
      direction='down'
      setSelected={submit}
      selected={testDate}
      data={testDates
        .map((item) => ({ name: item.name, id: item.id }))
        .sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf())}
      typeFilter='testDateSelector'
      strings={{
        title: translate('filters.plans.selectTestDate'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search'),
        changingTo: translate('filters.plans.changingTo')
      }}
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('plans.testDate')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </RadioSelector>
  )
}

export default TestDateFilter
