import React, {useMemo} from 'react'
import {IconProgram} from '@Knowledge-OTP/znk-ui-components'
import PartSchedule from './PartSchedule'
import useTranslation from '../../../../../../../i18n/useTranslation'
import useReviewScheduledModal from '../../../ReviewScheduled/useReviewScheduledModal'
import moment from 'moment'

const WeeklySchedule = (props) => {
  const { form, currentService, setForm, educatorsBase, where = false, disabled = false } = props
  const {open: openReviewScheduledModal, close: closeReviewScheduledModal} = useReviewScheduledModal()
  const groupEducators = form.educatorsGroup
  const parts = Object.values(form.lpw)
  const { translate } = useTranslation()
  const canSchedule =
    (
      form.lpw['0'].startdate &&
      form.lpw[(Object.keys(form.lpw).length - 1).toString()].enddate) ||
    (form.typePlan === 'time' && form.typeLesson === 'totalLesson')


    
    const weeklyScheduledValidation = (form) => {
      let pass = false
      const topicsWithIndividualEducators = form.educatorsIndividualFull.reduce((arr, edu) => arr.includes(edu.topicId) ? arr : arr.concat(edu.topicId), [])
      Object.values(form.lpw).forEach(
        (part, idx) => {
          const { partname, startdate, enddate, ...topics } = part
          if (part.partname === '' || !part.startdate || !part.enddate) {
            pass = true
          } else {
            if (Object.entries(topics).length) {
              let hasNoLessons = true
              Object.entries(topics).forEach(([topicId, info]) => {
                if (info.valueGroup || info.valueIndividual) {
                  hasNoLessons = false
                }
                if (info.valueGroup && info.groupBlocks && info.valueGroup !== info.groupBlocks.length) {
                  // value and blocks dont match
                  pass = true
                  return
                }
                if (info.groupBlocks && info.groupBlocks.length) {
                  const blocksEducator = form.educatorsGroupFull.find(ele => ele.topicId === topicId.toString() && ele.partIndex === idx)
                  if (!blocksEducator) {
                    // No Educator for this part
                    pass = true
                    return
                  }
                }
                if (info.valueIndividual && !topicsWithIndividualEducators.includes(topicId)) {
                  // no educator for individual topic
                  pass = true
                }
              })
              if (hasNoLessons) {
                // has no lessons this part
                pass = true
              }
            } else {
              // no tiene ningún topico seteado
              pass = true
            }
          }
        }
      )
      return pass
    }
    
    const validation = useMemo(
      () => !weeklyScheduledValidation(form) , [form]
    )

  const reviewProps = useMemo(
    () => {

      // lpw:
      //   0:
      //   608744a73f496405ffea2248:
      //     groupBlocks: [
      //       {
      //         id: "4-9",
      //         val: {day: 4, hour: 9}
      //       }
      //     ]
      //     valueGroup: 2
      //     valueIndividual: 1
      //   608744ae3f496405ffea2266: {valueGroup: 1, valueIndividual: 1, groupBlocks: Array(1)}
      //   enddate: "2022-09-04"
      //   partname: "Part 1"
      //   startdate: "2022-06-13"
      const scheduledDays = Object.values(form?.lpw || {})?.map(
        ({enddate, startdate, partname, ...topics}) => {
          return {
            from: moment(startdate), to: moment(enddate),
            days: Object.values(topics).reduce((array, {groupBlocks}) => {
            
              return array.concat(
                (groupBlocks || []).map((value) => {
                  const {val} = value
                  const {day} = val
                  return parseInt(day) === 0 ? 6 : parseInt(day)-1
                })
              )
  
            }, []) 
          }
        }
      )
      return {
        datesConfig: scheduledDays,
        // showRealLessons: false
      }
    }, [form]
  )  

  return (
    <div className='d-flex flex-column px-0 py-6'>
      <div className={`d-flex flex-row w-100 ${validation ? 'justify-content-between' : 'justify-content-start'}`}>
        <h3 className='font-weight-bold py-1'><b>Weekly Schedule</b></h3>
        {validation && !disabled && (
          <span className={`h45 btn-link text-gray d-flex flex-row align-items-center cursor-pointer`} onClick={() => {
            openReviewScheduledModal(
              (res = []) => {
                setForm({...form, excludeDays: res.map((date) => moment(date).utc().format('YYYY-MM-DD'))})
                closeReviewScheduledModal()
              }, reviewProps, (form.excludeDays || [])?.map((date) => moment(date).utc().toISOString())
            )
          }}>
            <IconProgram size={12} className={`mr-2`} />Review
          </span>
        )}
      </div>
      {
        !canSchedule ? (
          <div className='w-auto h-auto'>
            {translate('plans.modal.finishParams')}
          </div>
        ) : (
          parts.map((part, idx) => {
            return (
              <PartSchedule 
                key={`part${idx}`} groupPart={part} setForm={setForm} 
                idx={idx} currentService={currentService} 
                groupEducators={groupEducators} form={form} 
                educatorsBase={educatorsBase}
                disabled={disabled}
                where={where} />
            )
          })
        )
      }
      {/* <div className='borderTop my-2' /> */}
    </div>
  )
}

export default WeeklySchedule
