import initialState from './initialState'
import {
  SET_DATA_ON_SLOT,
  EMPTY_SLOT,
  SET_ALL_DATA,
} from './const'

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ON_SLOT:
      return {
        ...state,
        [action.slot]: action.data
      }
    case EMPTY_SLOT:
      return { 
          ...state,
          [action.slot]: null 
        }
    case SET_ALL_DATA:
      return action.data
    default:
      return state
  }
}
