import { useSelector, useDispatch } from 'react-redux'

const useSelectTemplateModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.selectTemplateModal)
    const {isOpen, onChange, activeTab} = reduxData
    const setOpen = (open, onChange = () => {}, tab = 'list') => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.selectTemplateModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                activeTab: tab
            }
        })
    }
    const setTab = (tab = 'list') => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.selectTemplateModal',
            data: {
                ...reduxData,
                activeTab: tab
            }
        }) 
    }

    return {
        isOpen,
        onChange,
        activeTab,
        setTab,
        open: (onChange = () => {}, tab = 'list') => {setOpen(true, onChange, tab)},
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)},
    }
}

export default useSelectTemplateModal