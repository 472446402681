import {initialState} from './initialState'
import {COPY_TEXT, EXECUTE_COPY_AGAIN} from './const'
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case COPY_TEXT:
            return {
                ...state,
                copyText: action.data.text,
                onCopySuccess: action.data.onSuccess,
                onCopyError: action.data.onError,
                executeCopy: true
            }
        case EXECUTE_COPY_AGAIN:
            return {
                ...state,
                executeCopy: action.executeCopy || false
            }    
        default:
            return {...state}
    }
}
