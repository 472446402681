import {useLazyQuery, gql} from '@apollo/client'

const useGetEducatorLink = (onSuccess = () => {}, onError = () => {}) => {
    return useLazyQuery(
        gql`
            query ($id: String!) {
                user (id: $id) {
                    educatorInfo {
                        backupLink
                    }
                }
            }
        `,
        {
            onCompleted: onSuccess,
            onError
        }
    )
}

export default useGetEducatorLink