import { gql, useQuery } from '@apollo/client'
const queryResult = `
  id
  name
  collegeId
  collegeUnitId
  logo
  ranking
  tasks {
    planId
  }
  users {
    id
    firstName
    lastName
    avatar
    currentCounselingPlan {
      id
    }
    studentInfo {
      graduationDate
      classOf
      universities {
        universityId: id
        name
        status
        early
        collegeId
        isPrincipal
        attending
      }
    }
  }
`
const useGetStudentGroupsByUniversity = (vars = {}, options = {fetchPolicy: 'cache-first'}) => {
  return useQuery(
    gql`
            query (
              $page: Int
              $limit: Int
              $calculateTotal: Boolean = false
              $search: String = null
              $studentIds: [String!] = []
            ) {
                getStudentGroupsByUniversity (
                  page: $page
                  limit: $limit
                  calculateTotal: $calculateTotal
                  search: $search
                  studentIds: $studentIds
                ) {
                    docs {
                      ${queryResult}
                    }
                    total
                }
            }
        `,
        {
          variables: vars,
          ...options
        }
  )
}

export default useGetStudentGroupsByUniversity