import {gql, useMutation} from '@apollo/client'
import {showAlert} from '../../../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import useTranslation from '../../../../../../i18n/useTranslation'
const useSincronizeUserMailchimp = (onSuccess = () => {}, onError = () => {}) => {
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const [submit, {loading}] = useMutation(
        gql`
            mutation($userId: String!) {
                sincronizeMailchimpUser(userId: $userId)
            }
        `
    )

    const execute = (userId) => {
        submit({
            variables: {userId}
        }).then(
            (res) => {
                showAlert({status: 'success', text: translate("misc.mailchimpSincronize.onSuccess") || 'Success!'}, dispatch)
                if(onSuccess) onSuccess(res)
            },
            (err) => {
                showAlert({status: 'error', text: err.toString()}, dispatch)
                if(onError) onError(err)
            }
        )
    }
    return {submit: execute, loading}
}

export default useSincronizeUserMailchimp