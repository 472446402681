import React, { useState, useEffect } from 'react'
import {
  Task,
  IconIllustratedTask,
  Action,
  BlankSpace
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../i18n/useTranslation'
import { UseGetAllUserTasks } from '../../Model/useGetAllUserTasks'
import useViewTaskModal from '../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import useViewPlanModal from '../../../CounselingPlans/Modal/viewPlanModal/useViewPlanModal'

const TasksTab = (props) => {
  const { college, planId /** data, loading */ } = props
  const taskStatus = []

  const { openForCreatedTask } = useViewTaskModal()

  const { dataPlan } = useViewPlanModal()

  const [
    getAllUserTasks,
    { data: dataAllUserTasks, loading },
  ] = UseGetAllUserTasks({})

  // Set pending tasks
  const [pendingList, setPendingList] = useState([])
  // Set complete tasks
  const [completeList, setCompleteList] = useState([])
  // Set review tasks
  const [reviewList, setReviewList] = useState([])
  // Set unassigned tasks
  const [unassignedList, setUnassignedList] = useState([])

  const tasksToShow = [
    { title: 'Pending Tasks', list: pendingList },
    { title: 'Tasks under Review', list: reviewList },
    { title: 'Unassigned Tasks', list: unassignedList },
    { title: 'Completed Tasks', list: completeList },
  ]

  const { translate } = useTranslation()

  /* eslint-disable */
  useEffect(() => {
    //Cada vez que se terminen de actualizar las tasks (traemos todas), hacemos un filter para cada estado
    if (dataAllUserTasks?.getUserTasks?.length > 0) {
      const pendingArray = dataAllUserTasks?.getUserTasks.filter(task => task.status === 'pending')
      setPendingList(pendingArray)
      const reviewArray = dataAllUserTasks?.getUserTasks.filter(task => task.status === 'review')
      setReviewList(reviewArray)
      const unassignedArray = dataAllUserTasks?.getUserTasks.filter(task => task.status === 'unassigned')
      setUnassignedList(unassignedArray)
      const completedArray = dataAllUserTasks?.getUserTasks.filter(task => task.status === 'completed')
      setCompleteList(completedArray)

    } else {
      setPendingList([])
      setReviewList([])
      setUnassignedList([])
      setCompleteList([])
    }
  }, [loading, dataAllUserTasks])
  /* eslint-enable */

  useEffect(() => {
    if (planId && planId !== '') {
      const variables = {
        universitieId: college?.id,
        status: taskStatus,
        planId,
      }
      getAllUserTasks({variables})
    }
    // eslint-disable-next-line
  }, [planId])

  return (
    <>
      <TabContent activeTab={
        dataAllUserTasks?.getUserTasks?.length === 0 ?
          'blank' :
          loading ? 'loading' : 'show'
      }>
        <TabPane tabId={`blank`}>
          <>
            <BlankSpace
              mainText={translate('counseling.noTasksText')}
              linkProps={{ text: translate('counseling.docsExamsText'), className: `text-gray h45` }}
              icon={<IconIllustratedTask />}
              style={{height: '50vh'}}
              className={''}
              border={'border-dashed'}
              textAlign={'text-center'}
            />
            {/* <div
              className='d-flex flex-column align-self-center w-100 mt-4 py-6'
              style={{
                borderRadius: '10px',
                border: '2px dashed',
                borderColor: '#f0f2f5',
              }}
            >
              <>
                <div className='d-flex justify-content-center w-100 mt-4 mb-2'>
                  <IconIllustratedTask />
                </div>
                <div className='d-flex flex-column justify-content-center w-100 mt-2'>
                  <span className='text-muted text-center h4 mb-4'>
                    {translate('counseling.noTasksText')}
                  </span>
                  <span className='text-muted text-center h4'>
                    {translate('counseling.docsExamsText')}
                  </span>
                </div>
              </>
            </div> */}
          </>
        </TabPane>
        <TabPane tabId={`show`}>
          <div className={`d-flex flex-column m-0 p-0`}>
            {tasksToShow?.map(category => {
              return (
                <div className={`my-3`}>
                  <div className='h4 font-weight-bold text-title my-3'>
                    <span>{category.title}</span>
                  </div>
                  {category.list.length > 0 ? category.list.map((task) => {
                    return (
                      <Task
                        onClick={() => {
                          openForCreatedTask({
                            ...task,
                            isEdit: false,
                            planId,
                            userId: dataPlan?.studentUser?.studentUserId
                          })
                        }}
                        taskClassName={`mb-1 cursor-pointer`}
                        labelLeft={<span title={task.name || 'No defined'} className={'text-truncate w-100'}>{task.name}</span>}
                        isCounseling />
                    )
                  }
                  )
                    :
                    <Action
                      color='transparent'
                      textColor='#7079FF'
                      border='dashed 1px #7079FF'
                      className='my-2'
                      labelLeft={
                        <span className='w-100 d-flex flex-column my-3'>
                          <label className='m-0'>
                            No task
                          </label>
                        </span>
                      }
                      labelRight={null}
                      action={null}
                    />
                  }
                </div>
              )
            })}
          </div>
        </TabPane>
        <TabPane tabId={`loading`}>
          <Skeleton count={5} height={10} />
        </TabPane>
      </TabContent>
    </>
  )
}

export default TasksTab
