import React from 'react'
import useServices from '../../../../../Model/Services/List'
import ServicesFilter from '../../../../Plans/Filters/Services'

const TopicFilter = (props) => {
    const {serviceFilters, setServiceFilters} = props
    const {data: services} = useServices({limit: 0})
    return (
        <ServicesFilter {...{services, serviceFilters, setServiceFilters}}/>
    )
}

export default TopicFilter
