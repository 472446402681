import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../store/modal/const'

const useMyCollegeModal = () => {
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.counseling.collegeModal)
  const {isOpen, onChange, college} = reduxData
  const setUpdateUniversity = (college) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.collegeModal',
      data: {
        ...reduxData,
        college,
      },
    },
    )
  }

  const setOpen = (open, onChange = () => {}, college = {}, userId = null, planId = null, universities = []) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.collegeModal',
      data: {
        ...reduxData,
        isOpen: open,
        onChange,
        college,
        userId,
        planId,
        universities,
      },
    },
    )
  }
  return {
    ...reduxData,
    isOpen,
    onChange,
    open: (onChange = () => {}, college = {}, userId = null, planId = null, universities = []) => {
      setOpen(true, onChange, college, userId, planId, universities)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
    college,
    setUpdateUniversity
  }
}
export default useMyCollegeModal
