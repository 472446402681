import React from 'react'
import StatusField from '../../../../../../../../Plans/Fields/Status'
import Skeleton from 'react-loading-skeleton'
import HeaderField from '../../../HeaderField'

const PlanStatusField = (props) => {
    const {loading = false, plan, className,
        header = false, onSort = () => {}, sort = 'name', order = false} = props
    return (
        <>
            {
                loading ?
                    <span className={`d-flex flex-row justify-content-center align-items-center ${className}`}>
                        <Skeleton count={1} width={100} height={16} style={{borderRadius: '12px'}}/>
                    </span> : 
                    header ? 
                    <HeaderField 
                        active={sort === 'status'} order={order} 
                        onClick={() => { onSort('status', !order /**ASC**/) }} 
                        className={`h45 text-gray d-flex flex-row justify-content-start align-items-center ${className}`}>
                            Status
                    </HeaderField> :
                    <span title={plan?.status} className={`text-truncate h45 text-gray d-flex flex-row align-items-center ${className}`}>
                        <StatusField label={plan?.isComming ? 'Scheduled' : plan?.status === 'scheduled' ? 'Finished' : plan?.status === 'draft' ? 'Timeline' : plan?.status} status={plan?.status} />
                    </span>
            }
        </>
    )
}

export default PlanStatusField