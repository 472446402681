import React from 'react'
import useTranslation from '../../../../../../../i18n/useTranslation'
import RenderSelectedLessons from '../../Components/RenderSelectedLessons'
const ConfirmCompleteSection = (props) => {
    const {pendingLessons = []} = props
    const {translate} = useTranslation()
    return (
        <>
            <label className={`h45 text-gray`}>
                {translate("modal.completeGroupPlan.confirmText")}
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy */}
            </label>
            <RenderSelectedLessons selectedLessons={pendingLessons} />
        </>
    )
}

export default ConfirmCompleteSection