import React from 'react'
import {useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import {
    PlanName,
    StudentName,
    Details,
    PlanStatus,
    Options
} from './Fields'

const Body = (props) => {
    const { plan, refetch = () => {} } = props
    const {breakWidth} = useWindowDimensions()
    return (
            breakWidth === 'SM' ? 
            <>
                <div className='d-flex flex-column w-70'>
                    <span><PlanName className={'w-100'} plan={plan} /></span>
                    <span><PlanStatus className={'w-100 flex-row-reverse justify-content-end ml-2'} plan={plan} /></span>
                </div>
                <Options refetch={refetch} className={'w-30 justify-content-end'} plan={plan} />
            </> : breakWidth === 'MD' ? 
            <>
                <PlanName className={'w-40'} plan={plan} />
                <StudentName className={'w-30'} plan={plan} />
                <Options refetch={refetch} className={'w-30 justify-content-end'} plan={plan} />
            </> : 
            <>
                <PlanName className={'w-20'} plan={plan} />
                <StudentName className={'w-30'} plan={plan} />
                <Details className={'w-20'} plan={plan} />
                <PlanStatus className={'w-20 justify-content-center'} plan={plan} />
                <Options refetch={refetch} className={'w-10 justify-content-end'} plan={plan} />
            </>
    )
}

const Skeleton = () => {
    const {breakWidth} = useWindowDimensions()
    return (
        breakWidth === 'SM' ? 
            <>
                <PlanName className={'w-70'} loading />
                <Options className={'w-30 justify-content-end'} loading />
            </> : breakWidth === 'MD' ? 
            <>
                <PlanName className={'w-40'} loading />
                <StudentName className={'w-30'} loading />
                <Options className={'w-30 justify-content-end'} loading />
            </> : 
            <>
                <PlanName className={'w-20'} loading />
                <StudentName className={'w-30'} loading />
                <Details className={'w-20'} loading />
                <PlanStatus className={'w-20'} loading />
                <Options className={'w-10 justify-content-end'} loading />
            </>
    )
}
const Header = (props) => {
    const {onSort = () => {}, sort = 'name', order = false} = props
    const {breakWidth} = useWindowDimensions()
    return (
        breakWidth === 'SM' ? 
            <>
                <PlanName className={'w-70'} header onSort={onSort} sort={sort} order={order} />
                <Options className={'w-30 justify-content-end'} header />
            </> : breakWidth === 'MD' ? 
            <>
                <PlanName className={'w-40'} header onSort={onSort} sort={sort} order={order} />
                <StudentName className={'w-30'} header onSort={onSort} sort={sort} order={order} />
                {/* <Details className={'w-20'} header onSort={onSort} sort={sort} order={order} />
                <PlanStatus className={'w-20 justify-content-center'} header onSort={onSort} sort={sort} order={order} /> */}
                <Options className={'w-30 justify-content-end'} header />
            </> : 
            <>
                <PlanName className={'w-20'} header onSort={onSort} sort={sort} order={order} />
                <StudentName className={'w-30'} header onSort={onSort} sort={sort} order={order} />
                <Details className={'w-20'} header onSort={onSort} sort={sort} order={order} />
                <PlanStatus className={'w-20 justify-content-center'} header onSort={onSort} sort={sort} order={order} />
                <Options className={'w-10 justify-content-end'} header />
            </>
    )
}


const useColumns = () => {
    return {
        Body,
        Header,
        Skeleton
    }
}


export default useColumns