import React from 'react'
import { LoadingSpinner } from '@Knowledge-OTP/znk-ui-components'
import DinamicHeightPage from '../../Components/DinamicHeightPage'

const LoadingPage = (props) => {
    const { bodyRef } = props
    return (
        <DinamicHeightPage
            bodyRef={bodyRef}
        >
            <LoadingSpinner size={'large'} />
        </DinamicHeightPage>
    )
}


export default LoadingPage