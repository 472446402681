import React, {useState} from 'react'
import {
    ModalLayout,
    Input,
    Button,
    CheckboxOption
} from '@Knowledge-OTP/znk-ui-components'
import useCreateEventModal from './useCreateEventModal'
import useCreateEvent from './Model/useCreateEvent'
import useEditEvent from './Model/useEditEvent'
import { useEffect } from 'react'
import moment from 'moment'


const RenderInput = ({label, children, className = ''}) => {
    return (
        <div className={`d-flex flex-column m-0 p-0 ${className}`}>
            <span className={`mb-3`}>{label}</span>
            {children}
        </div>
    )
}

const defaultForm = {
    date: null,
    toDefine: false,
    name: null,
    description: null,
    isEdit: false
}

const CreateEventModal = () => {
    const {toggle, isOpen, onChange, event, validation} = useCreateEventModal()
    const [form, setForm] = useState(defaultForm)
    useEffect(() => {
        if(event) {setForm({...event, isEdit: true})}
        else setForm(defaultForm)
    }, [event, isOpen])

    const mutationCallback = (event) => {
        onChange({
            date: moment(event?.startDate).utc().format(`YYYY-MM-DD`),
            toDefine: event?.tbd || false,
            name: event?.name,
            description: event?.description,
            eventId: event?.id,
            isEdit: form.isEdit
        })
    }

    const [createEvent, {loading: loadCreate}] = useCreateEvent(
        ({createEvent}) => {
            mutationCallback(createEvent)
        }, 
        (err) => {
            console.error(err)
        }
    )

    const [editEvent, {loading: loadEdit}] = useEditEvent(
        ({editEvent}) => {
            mutationCallback(editEvent)
        },
        (err) => {
            console.error(err)
        }
    )
    return (
        <ModalLayout
            // style={bubblesStyle}
            className={`modal-colored modal-centered white-modal h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h3 text-title`}>
                    Create Event
                </span>
            }
            underHead={null}
            minHead={
                <span className={`h3`}>
                    Create Event
                </span>
            }
        >
            <div className={`d-flex flex-column`}>
                <div className={`d-flex flex-row m-0 p-0 row`}>
                    <RenderInput label={`Date`} className={`col-8`} >
                        <Input type={`date`} value={form.date} onChange={(e) => {setForm({...form, date: e?.target?.value})}} />
                    </RenderInput>
                    <div className={`d-flex flex-row align-self-end col-4`}>
                        <CheckboxOption value={form.toDefine} onChange={(e) => {setForm({...form, toDefine: e})}} />
                        <span className={`text-truncate`} title={'To define'}>TBD</span>
                    </div>
                </div>
                <RenderInput label={`Name`} className={`mt-5`} >
                    <Input type={`text`} placeholder={`Name`} value={form.name} onChange={(e) => {setForm({...form, name: e?.target?.value})}} />
                </RenderInput>
                <RenderInput label={`Description`} className={`mt-5`} >
                    <Input type={`textarea`} placeholder={`Description`} value={form.description} onChange={(e) => {setForm({...form, description: e?.target?.value})}} />
                </RenderInput>
                <Button
                    disabled={!!validation(form)}
                    className={`mt-5 mb-3 w-100`}
                    label={'Create event'}
                    size={`medium`}
                    color={'primary'}
                    activity={loadCreate || loadEdit}
                    onClick={() => {
                        // onChange(form)
                        const eventVariables = {
                            // startDate: DateTime!
                            // endDate: DateTime!
                            // name: String!
                            // tbd: Boolean!
                            // description: String
                            startDate: new Date(form.date),
                            endDate: new Date(form.date),
                            name: form.name,
                            tbd: form.toDefine,
                            description: form.description
                        }
                        if(!form.isEdit) {
                            //crear evento
                            createEvent({
                                variables: {
                                    newEvent: eventVariables
                                }
                            })
                        } else {
                            //editar evento
                            editEvent({
                                variables: {
                                    editEventInput: eventVariables,
                                    activityId: form?.eventId
                                }
                            })
                        }
                        
                    }}
                />
            </div>
        </ModalLayout>
    )
}

export default CreateEventModal