import { useCallback, useContext, useState } from 'react'
import { setKey } from '../../../../utils/getI18n'
import i18nContext from '../../../../contexts/i18n'

export default (errors) => {
  const { platform } = useContext(i18nContext)
  const [loading, setLoading] = useState(false)
  const submit = useCallback(async (path, newValue) => {
    setLoading(true)
    if (path && newValue) {
      try {
        const { data } = await setKey(path, newValue, platform)
        setLoading(false)
        return data
      } catch (e) {
        errors({ general: 'There was an Unknown error' })
      }
    }
    setLoading(false)
  }, [setLoading, errors, platform])
  return { loading, submit }
}
