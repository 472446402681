import { gql, useMutation } from '@apollo/client'

const useSetManualPaymentMethod = (
  onSuccess = () => {},
  onError = () => {}
) => {
  return useMutation(
    gql`
      mutation setManualPaymentMethod($userId: String!) {
        setManualPaymentMethod(userId: $userId) {
          paymentMethod {
            ... on ManualPaymentMethod {
              kind
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
          }
        }
      }
    `,
    {
      onCompleted: onSuccess,
      onError
    }
  )
}

export default useSetManualPaymentMethod
