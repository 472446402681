import {gql, useQuery} from '@apollo/client'

const useGetTasksWithExams = (variables = {}) => {
    return useQuery(
        gql`
            query {
                getTasksWithExams {
                    taskId
                    task {
                        name
                        selectedExamId
                    }
                }
            }
        `, {variables}
    )
}

export default useGetTasksWithExams