import React from 'react'
import useDeleteTaskModal from './useDeleteTaskModal'
import { ConfirmModal } from '@Knowledge-OTP/znk-ui-components'
import useOperationTaskMutation from '../Model/useOperationTask'
import {findTasksOnPlan} from '../utils'
import useTaskReducer from '../../../../reducers/task'

const DeleteTaskModal = () => {
    const {
        isOpen, 
        toggle, 
        onChange, 
        close, 
        taskData,
        loading,
        useMutation //indica si debe ejecutarse la mutación operationTask('delete') para
        // eliminar la tarea de la linea de tiempo de un plan (cualquier otra mutación debe ejecutarse externamente)
    } = useDeleteTaskModal()

    const callBack = (newTask) => {
        onChange({...newTask, phaseId: taskData?.phaseId, stepId: taskData?.stepId})
    }

    const {parseTaskFromAPI} = useTaskReducer()

    const [operationTask, {loading: loadMutation}] = useOperationTaskMutation(
        (result) => {
            const planTask = result?.operationTask?.tasks
            const tasks = findTasksOnPlan(planTask, taskData).map((task) => parseTaskFromAPI(task))
            callBack(tasks.find(({id}) => id === taskData?.id))
        },
        (err) => {
            console.error("Error on cancel task mutation : ", err)
            alert("error delete")
        }
    )
    return (
        <ConfirmModal 
            classNameActionDiv={`d-flex flex-column`}
            classNameConfirm={'text-error white-space-nowrap align-self-end'}
            classNameCancel={'align-self-end'}
            isOpen={isOpen}
            toggle={toggle}
            title={'Do you want to delete this task?'}
            body={'This action can not be undone'}
            confirmAction={() => {
                if(useMutation) {
                    const variables = {
                        // universities: [],
                        operation: 'delete',
                        // userId: taskData?.userId,
                        taskId: taskData?.id || taskData?.taskId,
                        stepId: taskData?.stepId,
                        phase: taskData?.phaseId.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }),
                        planId: taskData?.planId,
                    }
                    operationTask({
                        variables
                    })
                } else callBack(taskData)
                
            }}
            cancelAction={() => {close()}}
            confirmText={'Delete task'}
            cancelText={'Cancel'}
            loading={loading || loadMutation}
        />
    )
}

export default DeleteTaskModal