import React from 'react'
import Skeleton from 'react-loading-skeleton'
import InvoiceDetails from '../InvoiceDetails'
import useInvoices from '../../../../../Model/Payments/Invoices/List'
import useTranslation from '../../../../../i18n/useTranslation'

const PaymentHistory = (props) => {
  const { userId } = props
  const { translate } = useTranslation()
  const { data, loading } = useInvoices({ limit: 0, status: ['pendingPayment', 'procesingPayment', 'paid', 'canceled'], userId })
  const paymentsData = data && data.docs ? data.docs : []
  return (
    <div className='w-100 p-0 m-0 px-7 pb-4'>
      <div className='d-flex flex-column align-items-center mt-4 mt-md-5'>
        {loading ? (
          <Skeleton height={20} count={3} />
        ) : paymentsData.length ? (
          <>
            {paymentsData.map((element, index) => {
              return <InvoiceDetails key={index} invoice={element} />
            })}
          </>
        ) : (
          <span className='w-100 text-center'>
            {translate('misc.nothingFound')}
          </span>
        )}
      </div>
    </div>
  )
}

export default PaymentHistory
