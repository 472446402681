import { useSelector, useDispatch } from "react-redux"
import validate from 'validate.js'


// serviceId: ID
// registrationDate: DateTime
// scoreReleaseDate: DateTime
// name: String
// tbd: Boolean
// startDate: DateTime
// endDate: DateTime

const constraints = {
    name: {
        presence: true
    },
    serviceId: {
        presence: true
    },
    tbd: {
        presence: false
    },
    registrationDate: {
        presence: true
    },
    scoreReleaseDate: {
        presence: true
    },
    startDate: {
        presence: true
    },
    endDate: {
        presence: true
    },
}

const useCreateTestModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.createTestModal)
    const {isOpen} = reduxData

    const setOpen = (open, onChange = () => {}, test = null) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.createTestModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                test
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, test = null) => {setOpen(true, onChange, test)},
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)},
        validation: (form) => {
            return validate(form, {...constraints})
        }
    }
}

export default useCreateTestModal