import React from 'react'
import {Badge} from 'reactstrap'

const ShortcutFilter = (props) => {
    const {label, isSelected = false, className, ...otherProps} = props
    return (
        <Badge
            color={isSelected ? 'primary' : 'secondary'}
            className={`px-3 mr-1 p-0 ${isSelected ? '' : 'cursor-pointer'} ${className}`}
            pill
            {...otherProps}
        >
            <label style={{ cursor: isSelected ? 'not-allowed' : 'pointer' }} className='m-0 p-0 text-center'>
                {label}
            </label>
        </Badge>
    )
}

export default ShortcutFilter