import React, {useMemo} from 'react'
import LazyGroupAvatar from '../../../../../common/LazyAvatar/LazyGroupAvatar'
import LazyAvatar from '../../../../../common/LazyAvatar'

const StudentField = (props) => {
    const {lesson, ...otherProps} = props
    const student = useMemo(
        () => {
            return lesson?.student || lesson?.studentPlan || lesson?.students || lesson?.studentGroup || {}
        }, [lesson]
    )
    return (
        <span {...otherProps}>
            {
                Array.isArray(student) ? 
                    <LazyGroupAvatar 
                        direction='right' 
                        className={'mr-2'} 
                        size={`avatar-small`}
                        useHover={true || student?.length <= 4}
                        users={
                            student.map(
                                studentUser => ({
                                    ...studentUser, 
                                    id: studentUser?.id || studentUser?.studentUserId,
                                })
                            )
                        } 
                    /> : 
                    <LazyAvatar className={`mr-2`} size={`avatar-small`} userId={student?.studentUserId} name={`${student?.firstName} ${student?.lastName}`} />
            }
            {
                Array.isArray(student) ? 
                    `${student?.length || 0} students` : 
                    `${student?.firstName} ${student?.lastName?.substring(0,1)}.`}
        </span>
    )
}

export default StudentField