import React, {useMemo} from 'react'
import {
    ListItem, 
} from '@Knowledge-OTP/znk-ui-components'
import LazyAvatar from '../../../../../../common/LazyAvatar'
import CheckboxActiveUser from './Components/CheckboxActiveUser'
import DismissUser from './Components/DismissUser'
import moment from 'moment'
import useTranslation from '../../../../../../i18n/useTranslation'

const PlanListItem = (props) => {
    const {
        className = '',
        // onViewPlot = () => {},
        studentName = 'No defined',
        studentId,
        lastLessonIsoString,
        refetch = () => {},
        isActive=true,
        ...otherProps
    } = props
    const {translate, replaceVariables} = useTranslation()
    const daysSince = useMemo(
        () => {
            return parseInt(Math.abs(moment.duration(moment().diff(moment(lastLessonIsoString))).asDays()))
        }, [lastLessonIsoString]
    )
    return (
        <ListItem
            // showBottomLine
            // hasHover
            className={`px-4 py-2 hover-no-border ${className}`}
            Left={
                <div className={`d-flex flex-row align-items-center`}>
                    <LazyAvatar
                        name={studentName}
                        userId={null}
                        className={`mr-2`}
                        size={`avatar-medium`}
                    />
                    <div className={`d-flex flex-column`}>
                        <span className={`btn-link text-info`}>{studentName}</span>
                        <span className={`text-gray h45`}>{
                            lastLessonIsoString ? 
                                replaceVariables(translate('dashboard.studentWithoutLessons.listItem.daysSinceLastLesson'), {'DAYS': daysSince}) : //`${daysSince} days since last lesson` : 
                                translate('dashboard.studentWithoutLessons.listItem.neverHadLessons')
                        }</span>
                        {/* <span className={`text-gray h45`}>ID: {studentId}</span> */}
                    </div>
                </div>
            }
            Right={
                <div className={`d-flex flex-row`}>
                    <CheckboxActiveUser
                        userId={studentId}
                        isActive={isActive}
                        onChange={() => {
                            refetch()
                        }}
                    />

                    <DismissUser
                        userId={studentId}
                        onChange={() => {
                            refetch()
                        }}
                    />
                    {/* <DropDownNew
                        direction={`bottomRight`}
                        options={[
                            {
                                component: <DropDownItem>Inactive</DropDownItem> 
                            },
                            {
                                component: <DropDownItem onClick={() => {onViewPlot()}}>View plot</DropDownItem> 
                            },
                            {
                                component: <DropDownItem className={`danger-option text-error d-flex flex-row align-items-center`}>Dismiss</DropDownItem> 
                            }
                        ]}
                    >
                        <div className={`hover-icon`}>
                            <IconOptions className={`rotate-90`} />
                        </div>
                    </DropDownNew> */}
                </div>
            }
            {...otherProps}
        />
    )
}

export default PlanListItem