import {useLazyQuery, gql} from '@apollo/client'

export const examScoreResult = `
    id
    name
    results {
        name
        scale
        method
        values {
            name
            scale
            isActive
            isOnMethod
            value
        }
        isActive
        isPrincipal
        isOverallHidden
    }
    serviceId
    examDate
    overall
`

const useLazyPlan = (variables = {}) => {
    return useLazyQuery(
        gql`
            query ($planId: ID!) {
                plan (planId: $planId) {
                    id
                    serviceId
                    testDate
                    ... on LessonsPerWeekPlan {
                        examScores {
                            ${examScoreResult}
                        }
                        selectedExam {
                            scores {
                                ${examScoreResult}
                            }
                        }
                    }
                }
            }
        `,
        {
            variables
        }
    )
}

export default useLazyPlan