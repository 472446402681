import React, { useEffect, useMemo } from 'react'
import { CheckboxSelector, Avatar, IconArrowDown, CheckboxSelectorInner } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import { Badge } from 'reactstrap'
import {gql, useLazyQuery} from '@apollo/client'

const StudentsFilter = (props) => {
  const { translate } = useTranslation()
  const { 
    setUpcommingLesson = () => {}, 
    useInner = false,
  studentFilters, setStudentFilters, loading=false, roles = ['STUDENT'], label = 'Students' } = props


  const [getStudents, { data: dataStudent, loading: loadingStudent }] = useLazyQuery(
    gql`query($roles: [Roles!], $search: String, $limit: Int) {
        allUsers(roles: $roles, search: $search, limit: $limit) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs
            docs {
                id
                firstName
                lastName
                studentInfo {
                  upcomingLesson {id}
                }
            }
        }
    }
    `,
    {
      variables: { limit: 0, roles: roles, search: '' }
    }
  )
  
  useEffect(() => {
    getStudents()
  }, [getStudents])
  const students = useMemo(
    () => {
      return dataStudent?.allUsers?.docs || []
    }, [dataStudent]
  )  

  const checkboxSelectorProps = {
    setDataFilters: (idUser) => {
      const upcommings = students.filter(({id}) => idUser.includes(id) ).reduce((newArray, user) => {
        if(user?.studentInfo?.upcomingLesson?.id) newArray.push(user.studentInfo.upcomingLesson.id)
        return newArray
      }, [])
      setUpcommingLesson(upcommings)
      setStudentFilters(idUser)
    },
    direction: 'bottomLeft',
    dataFilters: studentFilters,
    loading: loading || loadingStudent,
    data: students.map(user => ({
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,
      Component: (
        <Avatar
          name={`${user.firstName} ${user.lastName}`}
          named={false}
          uri={null}
          size='avatar-small'
        />
      )
    })).sort((a, b) => a.name.localeCompare(b.name)),
    typeFilter: 'users',
    strings: {
      viewAll: translate('filters.plans.viewAll'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
      search: translate('filters.plans.search')
    }
  }

  return (
    useInner ?
    <CheckboxSelectorInner style={{maxWidth: 'auto'}} {...checkboxSelectorProps} /> :
    <CheckboxSelector {...checkboxSelectorProps}>
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {label || translate('filters.plans.studentsTitle')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default StudentsFilter
