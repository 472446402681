import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment'

const useReviewScheduledModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.plan.reviewScheduled.modalInfo)
    const loading = useSelector((state) => state.modal.plan.reviewScheduled.loading)
    const {isOpen} = reduxData 
    const setData = (open = false, onChange = () => {}, props = {}, unselected = [], useMutation = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.reviewScheduled.modalInfo',
            data: {
                ...reduxData,
                props,
                onChange,
                isOpen: open,
                unselected,
                useMutation
            }
        })
    }

    const setLoading = (load = false) => {
      dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'plan.reviewScheduled.loading',
          data: load
      })
    }

    return {
        ...reduxData,
        loading,
        setLoading,
        open: (onChange = () => {}, props = {}, unselected = [], useMutation = false) => {
            setData(true, onChange, props, unselected, useMutation)
        },
        close: () => {
            setData(false)
        },
        toggle: () => {
            setData(!isOpen)
        },
        reviewProps: (form = {}, showRealLessons = false) => {
        
            // lpw:
            //   0:
            //   608744a73f496405ffea2248:
            //     groupBlocks: [
            //       {
            //         id: "4-9",
            //         val: {day: 4, hour: 9}
            //       }
            //     ]
            //     valueGroup: 2
            //     valueIndividual: 1
            //   608744ae3f496405ffea2266: {valueGroup: 1, valueIndividual: 1, groupBlocks: Array(1)}
            //   enddate: "2022-09-04"
            //   partname: "Part 1"
            //   startdate: "2022-06-13"
            const scheduledDays = Object.values(form?.lpw || {})?.map(
              ({enddate, startdate, partname, ...topics}) => {
                return {
                  from: moment(startdate), to: moment(enddate),
                  days: Object.values(topics).reduce((array, {groupBlocks}) => {
                  
                    return array.concat(
                      (groupBlocks || []).map((value) => {
                        const {val} = value
                        const {day} = val
                        return parseInt(day) === 0 ? 6 : parseInt(day)-1
                      })
                    )
        
                  }, []) 
                }
              }
            )
            return {
              datesConfig: scheduledDays,
              planId: form?.planId || form?.id,
              showRealLessons
            }
        }
    }
}

export default useReviewScheduledModal