import React, { useEffect } from 'react'
import useEducatorFinderByFrames from './Model/useEducatorFinderByFrames'
import Search from './Pages/Search'
import { showAlert } from '../../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../../i18n/useTranslation'

export const PAGES = {
    search: 'search',
    result: 'result',
    educatorGrid: 'educator-grid'
}

const EducatorFinder = (props) => {
    const {
        className,
        defaultStart = null,//'2023-07-01',
        defaultEnd = null, //'2023-09-01',
        defaultStudentId = null,
        defaultServiceId = null,
        defaultTopicId = null,
        onSubmit = () => { },
        form = {},
        setForm = () => { },
        onBack = () => { },
        hideInputs = true,
        dateShortCuts = []
    } = props
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    

    useEffect(() => {
        setForm({
            start: defaultStart,
            end: defaultEnd,
            studentId: defaultStudentId,
            serviceId: defaultServiceId,
            topicId: defaultTopicId,
            frames: form?.frames || []
        })
        // eslint-disable-next-line
    }, [
        defaultStart,
        defaultEnd,
        defaultStudentId,
        defaultServiceId,
        defaultTopicId
    ])


    const [getEducators, { loading: loadEducatorFinder }] = useEducatorFinderByFrames()


    return (
        <Search
            defaultHideInputs={hideInputs}
            className={className}
            form={form}
            setForm={setForm}
            loading={loadEducatorFinder}
            dateShortCuts={dateShortCuts}
            onClick={() => {
                //buscar disponibilidad de educador en base a
                // start, end, selectedDays, tópico y servicio.
                getEducators({
                    variables: {
                        frames: (form?.frames || []).map(({ time }) => time),
                        serviceId: form?.serviceId,
                        topicId: form?.topicId,
                        from: new Date(form.start),
                        to: new Date(form.end)
                    }
                }).then((res) => {
                    showAlert({text: translate("modal.newplan.selecteducator.finder.onSuccess"), status: 'success'}, dispatch)
                    onSubmit(res.data.educatorFinderByFrames)
                }, (err) => {
                    showAlert({text: translate("modal.newplan.selecteducator.finder.onError"), status: 'error'}, dispatch)
                    showAlert({text: err.toString(), status: 'error'}, dispatch)
                    console.error(err)
                })
            }}
            onBack={onBack}
        />

    )
}

export default EducatorFinder