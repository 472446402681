import React, { useState } from 'react'
import {
  DropDown,
  IconInvoice,
  IconLesson,
  InfoDesktop,
  InvoiceCard,
  ResumenInvoice,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import InvoiceModal from './Modal'
import useTranslation from '../../../../../i18n/useTranslation'

const InvoiceDetails = (props) => {
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const [modalOpen, setModalOpen] = useState(false)
  const toggle = () => setModalOpen((prev) => !prev)
  const { invoice } = props
  const Invoice = (props) => (
    <ResumenInvoice
      text={'End of ' + moment(invoice.from).format('MMMM')}
      type='normal'
      amount={invoice.paymentItems
        .filter((item) => !item.draft)
        .reduce((sum, current) => sum + current.amount, 0)}
      className='mb-2'
      icon={<IconInvoice size='20' className='normal' />}
      {...props}
    />
  )
  return (
    <>
      {breakWidth === 'SM' ? (
        <Invoice onClick={toggle} />
      ) : (
        <DropDown
          onClick={toggle}
          className='w-100'
          direction='right'
          options={[
            {
              component: (
                <InfoDesktop status='invoice'>
                  <div className='p-md-1 p-xl-3 w-100 info-header'>
                    <InvoiceCard
                      type='dark'
                      text={moment(invoice.from).format('MMMM')}
                      typePayment='Card Credit'
                      className='mt-1'
                      amount={invoice.paymentItems
                        .filter((item) => !item.draft)
                        .reduce((sum, current) => sum + current.amount, 0)}
                      invoice='INV-00998372'
                    />
                  </div>
                  <div className='p-md-5 p-xl-7 w-100 info-body d-flex flex-column'>
                    <span className='h3'>
                      <IconLesson />
                      <span className='font-weight-bold'>
                        &nbsp;Live Prep&nbsp;
                      </span>
                      Vivamus sagittis lacus vel augue laoreet rutrumucibus
                      dolor auctor.
                    </span>
                    <span className='h5 text-secondary'>
                      {translate('profile.payments.paymentDetails')}
                    </span>
                  </div>
                </InfoDesktop>
              )
            }
          ]}
        >
          <Invoice />
        </DropDown>
      )}
      <InvoiceModal
        {...{
          modalOpen,
          toggle,
          invoice
        }}
      />
    </>
  )
}

InvoiceDetails.propTypes = {}

export default InvoiceDetails
