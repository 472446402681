import {useSelector, useDispatch} from 'react-redux'

const useCreatePdfTemplate = () => {
    const reduxData = useSelector((state) => state.modal.pdfEditor.createPdfTemplate)
    const {isOpen} = reduxData
    const dispatch = useDispatch()
    const setData = (obj = {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'pdfEditor.createPdfTemplate',
            data: {
                ...reduxData,
                ...obj
            }
        })
    }

    return {
        ...reduxData,
        open: (onChange = () => {}, template = {}, useMutation = true, isEdit = false) => {
            setData({
                isOpen: true,
                onChange,
                useMutation,
                isEdit,
                template
            })
        },
        close: () => {
            setData({
                isOpen: false,
            })
        },
        toggle: () => {
            setData({
                isOpen: !isOpen
            })
        }
    }
}

export default useCreatePdfTemplate