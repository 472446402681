const navItemClassName = 'm-0'
const navLinkClassName = 'pl-0 pr-2 pr-sm-4 py-2'
export const navItems = [
  {
    href: '/constants/countries',
    Body: 'Countries',
    linkClass: navLinkClassName,
    itemClass: navItemClassName
  },
  {
    href: '/constants/services',
    Body: 'Services',
    linkClass: navLinkClassName,
    itemClass: navItemClassName
  },
  {
    href: '/constants/topics',
    Body: 'Topics',
    linkClass: navLinkClassName,
    itemClass: navItemClassName
  },
  {
    href: '/constants/activities',
    Body: 'Activities',
    linkClass: navLinkClassName,
    itemClass: navItemClassName
  },
  {
    href: '/constants/universities',
    Body: 'Universities',
    linkClass: navLinkClassName,
    itemClass: navItemClassName
  },
  {
    href: '/constants/counselors',
    Body: 'Orienters',
    linkClass: navLinkClassName,
    itemClass: navItemClassName
  },
  // {
  //   href: '/constants/planslp',
  //   Body: 'Live Prep Plans',
  //   linkClass: navLinkClassName,
  //   itemClass: navItemClassName
  // }
]
