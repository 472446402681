import {useMutation, gql} from '@apollo/client'

const useSetStafInfoToUser = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`mutation (
                $staffInfo: StaffInput!
                $userId: ID!
            ) {
            setStafInfoToUser(
              staffInfo: $staffInfo
              userId: $userId
            ) {
               id 
               educatorInfo {
                   backupLink
               }
            }
        }`,
        {
            onCompleted: onSuccess, 
            onError
        }
    )
}

export default useSetStafInfoToUser