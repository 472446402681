import React from 'react'
import {
    ListItem,
    IconArrowRight,
    IconOptions,
    IconSignPlus,
    IconTask,
    FileItem,
    DropDownNew
} from '@Knowledge-OTP/znk-ui-components'

const RenderTemplateItem = ({
    templateData, 
    isNew = false, 
    unsaved, 
    isEdit = false, 
    onClick, 
    isCurrentTemplate=false, 
    taskCount,
    subtitle = null,
    options = [],
    keyItem,
    ...otherProps
}) => {
    const hasOptions = options?.length > 0
    return (
        <ListItem
            key={keyItem}
            isSelected={isCurrentTemplate}
            hasHover={!hasOptions}
            className={`list-item-hover pl-2`}
            onClick={
                () => {if(onClick) onClick()}
            }
            Left={
                <div className={`d-flex flex-row`} >
                    <FileItem icon={isNew ? <IconSignPlus className={`rotate-45`} color={'white'} /> : <IconTask/>} className={`align-self-center`} color={isNew ? 'primary' :'gray'} size={'small'}/>
                    <div className={`d-flex flex-column ml-2 ${isNew ? 'align-self-center' : ''}`}>
                        <span className={`${isNew ? 'btn-link' : 'text-title'}`}>{`${templateData?.templateName}`}</span>
                        {!isNew && <span className={`h45 text-gray`}>{subtitle || `${taskCount} tasks`}</span>}
                    </div>
                </div>
            }
            Right={
                hasOptions ? 
                    <DropDownNew
                        options={options}
                        direction={'bottomCenter'}
                    >
                        <div className={`hover-icon mr-2`}>
                            <IconOptions className={'rotate-90'}/>
                        </div>
                    </DropDownNew> :
                    <div className={`hover-icon mr-2`}>
                        <IconArrowRight/> 
                    </div> 
            }
            {...otherProps}
        />
    )
}

export default RenderTemplateItem