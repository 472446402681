import { gql, useLazyQuery } from '@apollo/client'

const usePayments = () => {
  return useLazyQuery(
    gql`
        query(
            $limit: Int,
            $sortField: String,
            $status: [PaymentStatus!],
            $userId: [String!],
            $parentUserId: [String!],
            $from: DateTime,
            $to: DateTime,
            $category: [PaymentCategory!],
            $searchString: String
        ){
        invoices(
            limit: $limit,
            sortField: $sortField,
            status: $status, 
            userId: $userId,
            parentUserId: $parentUserId,
            from: $from,
            to: $to,
            category: $category,
            searchString: $searchString
        ) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs
            docs {
              id
              status
              category
              userId {
                id
                firstName
                lastName
              }
              billedUserId
              processedTimes
              invoiceNumber
              createdAt
              updatedAt
              from
              to
              paymentItemIds
              lastProcessedAt
              amount
              realAmount
              paymentItems {
                ...on PlanPaymentItem {
                  id
                }
                ...on CustomPaymentItem {
                  id
                }
                ...on LessonPaymentItem {
                  id
                }
                ...on EducatorPaymentItem {
                  id
                }
              }     
            }
          }
        }`
  )
}

export default usePayments
