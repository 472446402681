import strings from '../../i18n/strings.json'
import getLocale from '../../i18n/getLocale'
import funcStrings from '../../i18n/strings.js'
import defaultsDeep from 'lodash/defaultsDeep'
export const initialState = {
  translationDictLoading: true,
  translationDictError: null,
  translationDictErrResponse: {},
  translationDictSuccess: null,

  selectedLang: getLocale(),
  strings: defaultsDeep(funcStrings, strings)
}
