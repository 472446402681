import React, {useMemo} from 'react'
import { ToolTipInfo, IconNotificationOn } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import { Badge } from 'reactstrap'
import HeaderField from '../../../HeaderField'
import moment from 'moment'

const DetailsField = (props) => {
    const { loading = false, className, plan, header = false } = props

    const parts = plan?.fixedParts || plan?.parts || []
    const { dateLabel } = useMemo(
        () => {
            const from = parts.reduce((minDate, { from }) => {
                if (minDate > moment(from).toDate()) return moment(from).toDate()
                return minDate
            }, Number.POSITIVE_INFINITY)

            const to = parts.reduce((maxDate, { to }) => {
                if (maxDate < moment(to).toDate()) return moment(to).toDate()
                return maxDate
            }, Number.NEGATIVE_INFINITY)
            if (from && to) {
                return {
                    from, to,
                    dateLabel: `${moment(from).format('MMM DD, YYYY')} to ${moment(to).format('MMM DD, YYYY')}`
                }
            }
            return {
                from: moment(), to: moment(),
                dateLabel: `No defined`
            }
        }, [parts]
    )
    return (
        <>
            {
                loading ?
                    <span className={`d-flex flex-row justify-content-start align-items-center ${className}`}>
                        <Skeleton count={1} width={100} height={16} style={{ borderRadius: '12px' }} />
                    </span> :
                    header ?
                        <HeaderField disableSort className={`h45 text-gray d-flex flex-row justify-content-start align-items-center ${className}`}>
                            Details
                        </HeaderField> :
                        <span className={`text-truncate h45 text-gray d-flex flex-row justify-content-${plan?.status === 'scheduled' ? 'center' : 'start'} align-items-center ${className}`}>{
                            plan?.status === 'scheduled' ?
                                plan?.pendingLessons > 0 ? <ToolTipInfo
                                    innerElement={`${plan?.pendingLessons} pending lessons`}
                                    tooltipId={'tooltipId'}
                                >
                                    <Badge className={`cursor-pointer d-flex flex-row align-items-center justify-content-center badge badge-pill badge-info`}><span className={`mr-1 text-gray`}>{plan?.pendingLessons}</span> <IconNotificationOn className={`text-gray`} size={16} /></Badge>
                                </ToolTipInfo> : ' - ' :
                                plan?.kind === 'timeLine' ?
                                    <span title={dateLabel}>{`${dateLabel}`}</span> :
                                    plan?.status === 'sent' ?
                                        <span title={`Pending approval`}>Pending approval</span> :
                                        <span title={dateLabel}>{`${dateLabel}`}</span>
                        }</span>
            }
        </>
    )
}

export default DetailsField