import React, {useState, useMemo} from 'react'
import {
    ModalLayout,
    Avatar,
    Button,
    Input
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import useTransportCostumer from './useTransportCostumer'
import useGetUsers from './Model/useGetUsers'
import useTransportPaymentMethods from './Model/useTransportPaymentMethods'
import PaymentMethod from '../../../Student/modal/InfoStudent/Profile/ChangePaymentMethod/PaymentMethod'

const TransportCostumer = () => {
    const {isOpen, toggle, userId, onChange} = useTransportCostumer()
    const {data} = useGetUsers()
    //mutation
    const [transportPaymentMethod, {loading: loadMutation}] = useTransportPaymentMethods(
        (result) => {
            onChange(result?.transportPaymentMethods)
        },
        (err) => {
            console.error(err)
        }
    )
    const [selectedUser, setSelectedUser] = useState(null)
    const [search, setSearch] = useState('')
    const userList = useMemo(() => {
        return data?.filter(({paymentMethod}) => {
            return paymentMethod
        })
    }, [data])
    return (
        <ModalLayout
            className={`modal-centered h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={<span className={`h3 text-title`}>Transport payment methods</span>}
        >
            <div 
                className={`mb-4`}
            >
                <TabContent activeTab={selectedUser ? 'selected' : 'no-selected'}>
                    <TabPane tabId={'no-selected'}>
                        <Input placeholder={`Search`} onChange={(e) => {
                            setSearch(e?.target?.value)
                        }}  />
                        <div className={`my-2 p-2`} style={{
                            maxHeight: '200px', 
                            overflowY: 'scroll',
                            backgroundImage: `linear-gradient(to bottom, 
                                rgba(255,255,255, 0), 
                                rgba(255,255,255, 1) 10%)`,
                            backgroundColor: '#e6e9ec',
                            borderRadius: '20px'   
                            }}>
                            {
                                userList?.filter(
                                    ({firstName, lastName}) => {
                                        const userName = `${firstName} ${lastName}`
                                        return userName?.toUpperCase()?.search(search?.toUpperCase()) !== -1
                                    }
                                )?.map((user, index) => {
                                    const {firstName, lastName} = user
                                    const userName = `${firstName} ${lastName}`
                                    return (
                                        <div 
                                            key={index}
                                            onClick={() => {
                                                setSelectedUser(user)
                                            }}
                                            className={`my-1 d-flex flex-row`}>
                                            <Avatar
                                                name={userName}
                                            />
                                            <span className={`ml-2 align-self-center`}>{userName}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId={'selected'}>
                        <div 
                            className={`my-1 d-flex flex-row`}>
                            <Avatar
                                name={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                            />
                            <span className={`ml-2 align-self-center`}>{`${selectedUser?.firstName} ${selectedUser?.lastName}`}</span>
                        </div>
                        <span 
                            className={`h45 btn-link`} 
                            onClick={() => {setSelectedUser(null)}}
                        >Select another user</span>
                    </TabPane>
                </TabContent>
            </div>
            <TabContent className={`mb-4`} activeTab={selectedUser && selectedUser?.paymentMethod ? 'selected' : 'no-selected'}>
                <TabPane tabId={'no-selected'}>
                    <span className={`h45 text-gray`}>User no selected or user dont have payment method</span>
                </TabPane>
                <TabPane tabId={'selected'}>
                    <span className={`text-title`}>Current payment method : </span>
                    <PaymentMethod
                        {...selectedUser?.paymentMethod}
                        className={`appears-animation mt-4`}
                        clickable={false}
                    />
                </TabPane>
            </TabContent>
            <Button
                className={`w-100`}
                color={`primary`}
                disabled={!selectedUser?.paymentMethod}
                label={`Confirm`}  
                activity={loadMutation}
                onClick={() => {
                    transportPaymentMethod({
                        variables: {
                            userIdDestiny: userId,
                            userIdOrigin: selectedUser?.id,
                        }
                    })
                }}      
            />
        </ModalLayout>
    )
}

export default TransportCostumer