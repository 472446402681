import React from 'react'
import CreateActivityModal from '../../../../../../Constants/Activities/Create'
import useServices from '../../../../../../../Model/Services/List'
import { useDispatch } from 'react-redux'

const HandleCreateTestDate = (props) => {
    const {refetch = () => {}, serviceId = ''} = props
    const dispatch = useDispatch()
    const { data: services, loading: servicesLoading } = useServices({ limit: 0 })
    return (
        <>
            <div className='m-0 p-0' onClick={
                () => {
                    dispatch({ 
                        type: 'SET_DATA_MODAL_FORM_LIST', 
                        payload: 'activities.create', 
                        data: { 
                            isOpen: true, 
                            kind: 'test', 
                            refetch,
                            serviceId
                        } 
                    })
                }
            }>
                {props.children}
            </div>
            <CreateActivityModal {...{ services, servicesLoading }} />
        </>
    )
}

export default HandleCreateTestDate