import React, { useState, useMemo, useEffect } from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  StickyButton,
  Activity,
  Form,
  SelectOption,
  PlainTextInput,
  Field,
  InputSelect,
  LabeledField,
  CheckboxOption,
  PhoneSelector,
  ListItem,
  IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import LazyAvatar from '../../../../../../common/LazyAvatar'
import {Collapse} from 'reactstrap'
import { gql, useQuery } from '@apollo/client'
import CreateUserWithParent from '../../../../../../Model/Users/CreateUserWithParent'
import useTranslation from '../../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import escapeRegExp from 'lodash/escapeRegExp'
import { gradesOptions } from '../../../../../CounselingPlans/Modal/newPlanModal/Sections/Student/grades'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../store/modal/const'
import AddParentModal from '../../../../../Users/Modal/Student/AddParent'
import { showAlert } from '../../../../../../common/Alert/util'

const NewStudent = (props) => {
  const { callback, refetch, sendEmailOption = false } = props
  const [isOpen, setOpen] = useState(false)
  const [isParentModalOpen, setIsParentModalOpen] = useState(false) // New state variable
  const toggle = () => { setOpen(!isOpen) }
  const [form, setForm] = useState({ newParents: null })
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const { submit: submitUserWithParent, loading } = CreateUserWithParent((result) => {
    // onsuccess
    refetch()
    callback({
      ...result.data.createUserWithParent,
      username: `${form.firstName} ${form.lastName}`,
      firstName: form.firstName,
      lastName: form.lastName
    })
    toggle()
    setForm({ newParents: null })
    showAlert({ text: 'Success', status: 'success' }, dispatch)
  },
  (err) => {
    const errorMessage = err.message.toString()
    showAlert({ text: errorMessage === 'PARENT_ALREADY_EXISTS' ? translate('modal.newParent.error') : errorMessage, status: 'error' }, dispatch)
  })

  const deleteParent = (type) => {
    switch (type) {
      case 'New':
        setForm({ ...form, newParents: null })
        break
      default:
        break
    }
  }
  const { data: countries } = useQuery(
    gql`
      query{
        paginatedCountries(
          limit: 250
          ){
          docs {
            id
            name
          }
        }
      }
    `
  ) 
  const ordenateCountries = useMemo(() => {
    if(countries?.paginatedCountries?.docs) {
      let array = [...countries.paginatedCountries.docs]
      return array.sort(
        ({name: nameA}, {name: nameB}) => {
          if(nameA < nameB) { return -1; }
          if(nameA > nameB) { return 1; }
          return 0;
        }
      ).map((item) => item)
    }
    return []
  }, [countries])
  
  useEffect(() => {
    setForm({
      ...form,
      phoneParent: form?.phoneDialCodeParent && form?.phoneNumberParent ? 
        `${form.phoneDialCodeParent} ${form.phoneNumberParent}` : 
        null,
    })
    //eslint-disable-next-line
  }, [form?.phoneDialCodeParent, form?.phoneNumberParent])

  useEffect(() => {
    if (form?.newParents?.id) {
      setForm({
        ...form,
        firstNameParent: null,
        lastNameParent: null,
        emailParent: null,
        phoneDialCodeParent: null,
        phoneNumberParent: null,
      })
    }
    //eslint-disable-next-line
  }, [form?.newParents])

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('modal.newplan.createstudent.title')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <div className='p-0 m-4'>
          <Form className='m-0 p-0' value={form} onChange={setForm}>
            <div className='m-0 p-0 row'>
              <label className='col-12 text-muted mt-3'>
                {translate('modal.newplan.createstudent.studenttype')}
              </label>
              <Field
                className='row col-12'
                fieldName='studentType'
                fieldType='date'
                type={SelectOption}
                options={[
                  { id: 'family', text: translate('modal.newplan.createstudent.family') },
                  { id: 'independent', text: translate('modal.newplan.createstudent.independent') }
                ]}
                OptionClassName='col-6'
              />
              <label className='col-12 text-muted mt-3'>
                {translate('modal.newplan.createstudent.studentname')}
              </label>
              <Field
                className='col-6'
                fieldName='firstName'
                fieldType='text'
                placeholder={translate('modal.newplan.createstudent.firtsname')}
                type={PlainTextInput}
              />
              <Field
                className='col-6'
                fieldName='lastName'
                fieldType='text'
                placeholder={translate('modal.newplan.createstudent.lastname')}
                type={PlainTextInput}
              />
              <label className='col-12 text-muted mt-3'>
                {translate('modal.newplan.createstudent.studentemail')}
              </label>
              <Field
                className='col-12'
                fieldName='email'
                fieldType='email'
                placeholder='name@yourmail.com'
                type={PlainTextInput}
              />
              <label className='col-12 text-muted mt-3'>
                Gender
              </label>
              <Field
                className='row col-12'
                fieldName='gender'
                fieldType='date'
                type={SelectOption}
                options={[
                  { id: 'male', text: 'Male' },
                  { id: 'female', text: 'Female' }
                ]}
                OptionClassName='col-12'
              />

              <label className='col-12 text-muted mt-3'>
                Grade
              </label>
              <Field
                type={LabeledField}
                component={InputSelect}
                className='col-12 p-0 mb-0'
                fieldName='grade'
                menuPlacement={'top'}
                options={gradesOptions}
              />
              <Collapse className={'row col-12 m-0 p-0 form-group'} isOpen={form && form.studentType && form.studentType === 'family'}>
               { ( form?.newParents === null && (
                <>
                  <span
                    className='btn-link cursor parent ml-3'
                    onClick={() => {
                    dispatch({
                      type: SET_DATA_MODAL_FORM_LIST,
                      payload: 'student.addParentModal',
                      data: {
                        ...form,
                        isOpen: true
                      }
                    })
                    setIsParentModalOpen(true)
                  }}
                >
                  {translate('profile.actions.editFamilyInfo.linkedParent.link')}
                </span>
                <label className='col-12 text-muted mt-3'>
                  {translate('modal.newplan.createstudent.parentname')}
                </label>
                <Field
                  className='col-6'
                  fieldName='firstNameParent'
                  fieldType='text'
                  placeholder={translate('modal.newplan.createstudent.firtsname')}
                  type={PlainTextInput}
                />
                <Field
                    className='col-6'
                    fieldName='lastNameParent'
                    fieldType='text'
                    placeholder={translate('modal.newplan.createstudent.lastname')}
                    type={PlainTextInput}
                  />
                  <label className='col-12 text-muted mt-3'>
                    {translate('modal.newplan.createstudent.parentemail')}
                  </label>
                  <Field
                    className='col-12'
                    fieldName='emailParent'
                    fieldType='email'
                    placeholder='name@yourmail.com'
                    type={PlainTextInput}
                  />
                  <label className='col-12 text-muted mt-3'>
                    {`Parent phone`} <span className={`h45`}>(optional)</span>
                  </label>
                  <div className='d-flex flex-row w-100'>
                    <div className='w-40 pr-1'>
                      <PhoneSelector
                        fieldName='phoneDialCodeParent'
                        defaultValue={form?.phoneDialCodeParent}
                        selectCode={form?.phoneDialCodeParent} 
                        setSelectCode={(dial) => {
                          setForm({ ...form, phoneDialCodeParent: dial })
                        }}
                      />
                    </div>
                    <div className='w-60 pl-0'>
                      <Field
                        type={LabeledField}
                        component={PlainTextInput}
                        fieldName='phoneNumberParent'
                        placeholder='12345621'
                      />
                    </div>
                  </div>
                  </>
                ))}
                {
                  form?.newParents !== null && (
                    <>
                      <label className='col-12 text-muted mt-1'>
                        {translate('modal.newplan.createstudent.parentname')}
                      </label>
                      <div className='d-flex flex-column'>
                        <ListItem
                          className='py-1 d-flex flex-row'
                          id='x-0'
                          label={null}
                          Left={
                            <div className='d-flex align-items-center'>
                              <LazyAvatar
                                userId={form?.newParents?.id}
                                name={`${form?.newParents?.name}`}
                                uri={form?.newParents?.avatar}
                                size='avatar-medium'
                              />
                              <label className='ml-2 h4'>
                                {`${form?.newParents?.name}`}
                              </label>
                            </div>
                          }
                          Right={
                            <>
                              <div onClick={() => {
                                dispatch({
                                  type: SET_DATA_MODAL_FORM_LIST,
                                  payload: 'student.addParentModal',
                                  data: {
                                    ...form,
                                    isOpen: true
                                  }
                                })
                                setIsParentModalOpen(true)
                                deleteParent('New')
                              }}>
                                <IconArrowRight />
                              </div>
                            </>
                          }
                        />
                        <div className='mt-3'>
                          <h5 className='text-error cursor' onClick={() => { deleteParent('New') }}>Remove this parent</h5>
                        </div>
                      </div>
                    </>
                  )
                }
              </Collapse>
              <label className='col-12 text-muted mt-3'>
                {translate('modal.newplan.createstudent.country')}
              </label>
              <Field
                type={LabeledField}
                component={InputSelect}
                className='col-12 p-0'
                fieldName='countryId'
                options={
                  ordenateCountries.map((countr) => {
                    return { value: countr.id, label: countr.name }
                  })
                }
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                  return false
                }}
              />
              <label className='col-12 text-muted mt-0'>
                {translate('modal.newplan.createstudent.timezone')}
              </label>
              <Field
                type={LabeledField}
                component={InputSelect}
                className='col-12 p-0'
                fieldName='timezone'
                options={moment.tz.names().map((tz) => {
                  return { value: tz, label: tz }
                })}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                  return false
                }}
              />
            </div>
          {sendEmailOption && <div className='mt-2'> 
               <div className='d-flex col-12'>
                    <Field
                          type={CheckboxOption}
                          fieldName='sendInvite'
                          defaultState={form.sendInvite}
                    />
                    <label className='text-muted h45'>Send email invite?</label>
                </div>
          </div>}
          </Form>
          <StickyButton
            className='my-2'
            condition
            color='#696CFF'
            styleText={{width: '100%', textAlign: 'start'}}
            onClickButtonFunction={() => {
              submitUserWithParent({
                ...form,
                parentId: form?.newParents?.id || null,
                isActive: true,
                sendEmail: form.sendInvite,
              })
            }}
            label={
              loading ? 
              <div className={'w-100 h-100 d-flex flex-row justify-content-center align-items-center'}>
                <Activity inverse />
              </div> : 
              translate('modal.newplan.createstudent.addnewstudent')}
          />
        </div>
      </Modal>
      {
        isParentModalOpen && <AddParentModal setParent={(parent) => {
          setForm({ ...form, newParents: parent })
          setIsParentModalOpen(true)
        }}
        />
      }
    </>
  )
}

NewStudent.propTypes = {
}

NewStudent.defaultProps = {
}
export default NewStudent
