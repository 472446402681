import React from 'react'
import {DropDownItem, DropDownSub} from '@Knowledge-OTP/znk-ui-components'

const CreateElement = (props) => {
    const {onSelect = () => {}} = props
    return (
        <>
            <DropDownItem>
                <span onClick={() => {onSelect('section')}}>
                    Section
                </span>
            </DropDownItem>
            <DropDownItem>
                <span onClick={() => {onSelect('text')}}>
                    Text
                </span>
            </DropDownItem>
            <DropDownItem>
                <span onClick={() => {onSelect('paragraph')}}>
                    Paragraph
                </span>
            </DropDownItem>
            <DropDownItem>
                <span onClick={() => {onSelect('div')}}>
                    Div
                </span>
            </DropDownItem>
            <DropDownItem>
                <span onClick={() => {onSelect('image')}}>
                    Image
                </span>
            </DropDownItem>
            <DropDownItem>
                <span onClick={() => {onSelect('span')}}>
                    Span
                </span>
            </DropDownItem>
            <DropDownSub level={0} title={'Shapes'}>
                <DropDownItem><span onClick={() => {onSelect('star')}}>Star</span></DropDownItem>
            </DropDownSub>
        </>
    )
}

export default CreateElement