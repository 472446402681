import { IconClose, Modal, Field, Form, StickyButton, Button, PlainTextInput, LabeledField } from '@Knowledge-OTP/znk-ui-components'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import updateBankInfo from '../../../../Model/Users/BankInfo'
import { isEmpty } from 'validate.js'
import useTranslation from '../../../../i18n/useTranslation'

const EditBankInfo = (props) => {
  const { refetch } = props
  const { isOpen, type: typeUser, userId, ...formData } = useSelector((state) => state.modal.user.editBankInfo)
  const [form, setForm] = useState({})
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [errors, setErrors] = useState({})
  const toggle = () => {
    setErrors({})
    setForm({})
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.editBankInfo', data: { isOpen: false, type: typeUser } })
  }
  useEffect(() => {
    if (userId) {
      setForm({
        bankNumber: formData.bankNumber,
        bankRouting: formData.bankRouting
      })
    }
    // eslint-disable-next-line
  }, [userId])
  const { submit, loading, validationErrors } = updateBankInfo(() => {
    refetch()
    toggle()
  }, console.log)
  const onSubmit = () => {
    setErrors({})
    const newVal = form
    submit({
      userId,
      educatorBankInfo: newVal
    })
  }
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key.split('.')[1]
        customError[keyVal] = translate('profile.actions.editBankInfo.errors.' + keyVal)
      })
      setErrors(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('profile.actions.editBankInfo.title')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={form} onChange={setForm} validationErrors={errors} >
          <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editBankInfo.banknumber')}</label>
          <Field
            className='col-12 placeholder-to-left'
            type={LabeledField}
            component={PlainTextInput}
            fieldName='bankNumber'
            fieldType='text'
          />
          <label className='text-gray font-weight-light mt-3 mb-2'>{translate('profile.actions.editBankInfo.bankrouting')}</label>
          <Field
            className='col-12 placeholder-to-left'
            type={LabeledField}
            component={PlainTextInput}
            fieldName='bankRouting'
            fieldType='text'
          />
          <div className='row d-flex m-0 p-0 mt-5'>
            <div className='col-12 p-0 '>
              <StickyButton>
                <div className='m-0 p-0 row'>
                  <Button
                    color='primary'
                    activity={loading}
                    className='col-12 aling-text-left'
                    label='Save'
                    onClick={onSubmit}
                    style={{ borderRadius: '16px' }}
                    check={true}
                  />
                </div>
              </StickyButton>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default EditBankInfo
