import { gql, useLazyQuery } from '@apollo/client'
import { Field, SelectCheck, StickyButton, Button } from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import setPermisionsToStaff from '../../../Model/Users/setPermisionsToStaff'
import { showAlert } from '../../../common/Alert/util'
import Skeleton from 'react-loading-skeleton'

const SetUserPermissions = ({userId}) => {
  const dispatch = useDispatch()
  const { staffInfo } = useSelector((state) => state.user)
  const isPermited  = staffInfo?.permissions?.filter(e => e.isPermited).map(per => {return per.tag}).includes('permissions');
  console.log(isPermited, staffInfo?.permissions)
  const [selectPermissions, setSelectPermissions] = useState([])
  const [getPermissions, {data: dataPermissions}] = useLazyQuery(
    gql`query($role: Roles!) {
      getPermissions(role: $role) {
          id
          name
          tag
      }
    }
    `,
    {
      variables: { role: 'SUPER_ADMIN' },
      fetchPolicy: 'network-only'
    }
  )
  const [getUser, { data }] = useLazyQuery(
    gql`query($id: String!) {
      user(id: $id) {
          staffInfo {
              backupLink
              permissions {
                name
                tag
                isPermited
              }
          }
      }
    }
    `,
    {
      variables: { id: userId },
      fetchPolicy: 'network-only'
    }
  )

  useEffect(() => {
    if (userId && userId !== '') {
      getUser()
      getPermissions()
    }
    // eslint-disable-next-line
  }, [userId])

  const userPermissions = useMemo(() => {
    if (data && dataPermissions) {
      const allPermisions = dataPermissions.getPermissions.map(
        e => ({
          name: e.name,
          tag: e.tag,
          isPermited: false
        })
      )
      if (data.user.staffInfo?.permissions?.length) {
        setSelectPermissions(data.user.staffInfo?.permissions.filter(e => e.isPermited).map(ele => {return ele.tag}))
        return allPermisions || data.user.staffInfo?.permissions        
      } else {
        setSelectPermissions([])
        return allPermisions
      }
    }
  }, [data, dataPermissions])

  
  const { submit, loading } = setPermisionsToStaff(() => {
    showAlert({
      text: 'Permissions changed successfully',
      status: 'success'
    }, dispatch)
  }, console.log)
  const onSubmit = () => {
     const form = {
        userId: userId,
        permissions: dataPermissions?.getPermissions?.map(e => {return { name: e.name, tag: e.tag, isPermited: selectPermissions.includes(e.tag) }}) ?? []
      }
      submit(form)
  }
  return (
    <div className='mt-7'>
        {
          !userPermissions
            ?
              <Skeleton count={10} height={43} style={{ borderRadius: '10px' }} />
            :
              <Field
                className='col-12'
                type={SelectCheck}
                fieldName='typePlan'
                options={!userPermissions ? [] : userPermissions.map(e => ({id: e.tag, text: e.name}))}
                StyleOptionClassName={{
                  width: '100%',
                  whiteSpace: 'nowrap'
                }}
                StyleSelect={{
                  justifyContent: 'space-between'
                }}
                value={selectPermissions}
                onChange={
                  (value) => {
                    //debe haber al menos un teachingType seleccionado
                    if(value?.length === 0) return
                    console.log('^^*', value)
                    // setSerForm(pre => ({
                    //   ...pre,
                    //   teachingTypes: value
                    // }))
                    setSelectPermissions(value)
                  }
                }
                isDisabled={!isPermited}
              />
              }
        {
          isPermited && <div className='row d-flex m-0 p-0 mt-5'>
            <div className='col-12 p-0 '>
              <StickyButton>
                <div className='m-0 p-0 row'>
                  <Button
                    color='primary'
                    activity={loading}
                    className='col-12 aling-text-left'
                    label='Save'
                    onClick={onSubmit}
                    style={{ borderRadius: '16px' }}
                    check='yes'
                  />
                </div>
              </StickyButton>
            </div>
          </div>
        }
    </div>
  )
}

export default SetUserPermissions