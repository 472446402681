import React, { useEffect, useMemo, useState } from 'react'
import { IconNotes, DropDownNew, DropDownItem } from '@Knowledge-OTP/znk-ui-components'
import CreateEducatorNote from '../../../Notes/Components/createNote'
import useUsersQuery from '../../../Student/modal/InfoStudent/useQueries/useGetStaff'

const NotesField = (props) => {
  const { item, onSave } = props
  const [usersQuery, { data: dataUsers }] = useUsersQuery()
  const [users, setUsers] = useState([])
  const { note, userId } = useMemo(
    () => {
     return {
        note: item?.educatorInfo?.educatorNote || null,
        userId: item?.id
      }
    }, [item]
  )
  useEffect(() => {
    usersQuery({
      variables: {
        role: 'SUPER_ADMIN', 
        $limit: 0, 
        previous: '', 
        next: '', 
        search: undefined, 
        serviceIds: [], 
        topicIds: [], 
        testDate: undefined, 
        classYear: [], 
        countriesIds: [], 
        statuses: true, 
        showInactive: false
      }
    })
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (!dataUsers?.allUsersFilter) return
    setUsers(dataUsers.allUsersFilter.docs.map(user => ({id: user.id, display: `${user.firstName} ${user.lastName}`})))
    // eslint-disable-next-line
  }, [dataUsers?.allUsersFilter])
  return (
    <div className={`d-flex flex-row m-0 p-0 align-items-center text-title cursor-pointer`}
    onClick={(e) => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()}}>

      <DropDownNew
        isEducatorNote={true}
        // trigger={['click']}
        color={'note'}
        options={[{
          component: <DropDownItem
            className={'p-0 m-0'}
            // style={{ backgroundColor: '#FFF5CB' }}
            onClick={(e) => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }}
          >
            <div className='d-flex flex-row align-items-center'>
              <CreateEducatorNote
                data={users}
                userId={userId}
                text={note}
                onSave={()=>{onSave()}}
              />
              <div className='d-flex flex-row align-items-center'>
              </div>
            </div>
          </DropDownItem>
        }]}
        direction={`bottomLeft`}
      >
        {note ? <div className={`hover-icon ml-2`}><IconNotes size={20} color={'#696CFF'} /></div> :
          <div className={`hover-icon ml-2`}><IconNotes size={20} color={'#D6DAE3'} /></div>}
      </DropDownNew>
    </div>
  )
}

export default NotesField