import React, {useEffect, useState} from 'react'
import {
    Modal,
    InputSelect,
    CheckboxOption,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import { useLazyQuery, gql, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../store/modal/const'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import {escapeRegExp} from 'lodash'

const SetPartAttributeModal = (props) => {
    const {refetch} = props
    const reduxData = useSelector((state) => state.modal.developer.setIndexPartModal)
    const dispatch = useDispatch()
    const {isOpen, planId, lessonData} = reduxData
    const {topic, startDate: startLesson, recurringId, id: lessonId} = lessonData
    const toggle = () => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'developer.setIndexPartModal',
            data: {
              ...reduxData,
              isOpen: !isOpen
            }
        })
    }
    const [getPlanInfo, {data, loading}] = useLazyQuery(
        gql`
            query ($planId: ID!) {
                plan (planId: $planId) 
                {
                    id
                    from
                    to
                    name
                    ... on LessonsPerWeekPlan {
                        partsPerWeek : parts {
                            from
                            to
                            lessonPerWeekPerTopic {
                                topicId
                            }
                        }
                    }
                    ... on LessonsFixedPlan {
                        partsFixed : parts {
                            from
                            to
                            TotalPerTopic {
                                topicId
                            }
                        }
                    }
                    ... on GroupPLan {
                        PartsSchedule {
                            from
                            to
                            lessonsPerWeekIndividual {
                                topicId
                            }
                        }
                    }
                }
            }  
        `
    )

    const [setPartMutation, {loading: loadMutation}] = useMutation(
        gql`
            mutation (
                $includeRecurring: Boolean!,
                $part: Float!,
                $recurringId: String,
                $lessonId: String!
            ) {
                setPlanLessonsPartIndex (
                    includeRecurring: $includeRecurring,
                    part: $part,
                    recurringId: $recurringId,
                    lessonId: $lessonId
                )
            }
        `,
        {
            update (__, {data: {setPlanLessonsPartIndex}}) {
                alert(`Lecciones actualizadas ${setPlanLessonsPartIndex}`)
                toggle()
                setForm({})
                refetch()
            }
        }
    )
    useEffect(() => {
        if(planId) getPlanInfo({variables: {planId}})
    }, [planId, getPlanInfo])
    const dataPlan = data?.plan || {}
    const parts = dataPlan?.partsPerWeek || dataPlan?.partsFixed || []
    
    const [form, setForm] = useState({}) 
    useEffect(() => {
        setForm({
            ...form, 
            checkRecurring: recurringId ? true : false
        })
        // eslint-disable-next-line 
    }, [recurringId])

    return (
        <Modal
            className={'modal-centered'}
            isOpen={isOpen}
            toggle={toggle}
            Head={
                <div className={'d-flex flex-column'}>
                    <span className={'h2 font-weight-bold text-title'}>Definir índice</span>
                </div>
            }
        >
            <div className={'d-flex flex-column p-3'}>
                {
                    loading ?
                        <Skeleton count={5} height={15}/> : 
                        <>
                            <div className={'plan-info d-flex flex-column'}>
                                <span>{dataPlan?.name}</span>
                                <span className={'h45 text-gray'}>
                                    {`${moment(dataPlan.from).format('DD MMMM YY, HH:mm a')} - ${moment(dataPlan.to).format('DD MMMM YY, HH:mm a')}`}
                                </span>
                                <span className={'h45 text-gray'}>Plan id: {planId}</span>
                            </div>
                            <div className={'parts d-flex flex-column'}>
                                {
                                    parts.map((part, index) => {
                                        const {from, to} = part
                                        let partTopics = part?.lessonPerWeekPerTopic || part.TotalPerTopic || []
                                        let range = `${moment(from).format('DD MMMM YY, HH:mm a')} - ${moment(to).format('DD MMMM YY, HH:mm a')}`
                                        return (
                                            <div className={'d-flex flex-column mt-2'}>
                                                <span className={'text-title'}>{`Parte ${index+1}`}</span>
                                                <span title={range} className={'text-gray h45'} style={{whiteSpace: 'nowrap'}}>
                                                    {range}
                                                </span>
                                                {
                                                    partTopics?.length > 0 ?
                                                    partTopics.map(({topicId}) => {
                                                        let isTopic = topic?.id === topicId
                                                        return (
                                                            <span className={`h45 text-${isTopic ? 'error' : 'gray'}`}>
                                                                {topicId}
                                                            </span>
                                                        )
                                                    }) :
                                                    <span className={'h45 text-gray'}>No hay lecciones</span>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                }
                {
                    <>
                        <div className={'borderTop w-100 my-2'}/>
                        <span style={{whiteSpace: 'nowrap'}}>Datos de la lección : </span>
                        <span className={'h45 text-gray'}>Lesson id: {lessonId}</span>
                        <span className={'text-title h45'} style={{whiteSpace: 'nowrap'}}>{`${moment(startLesson).format('DD MMMM YY, HH:mm a')}`}</span>
                        <InputSelect 
                            className={'mt-4'}
                            options={
                                new Array(parts.length).fill(0).map((_, index) => {
                                    return {
                                        value: index,
                                        label: `Parte ${index+1}`
                                    }
                                })
                            }
                            value={form?.part}
                            onChange={(value) => {
                                setForm({
                                    ...form,
                                    part: value,
                                })
                            }}
            filterOption={(obj, string) => {
              if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
              return false
            }}
                        />
                        {
                            recurringId && (
                                <div className='d-flex flex-row mt-4 pl-2'>
                                    <CheckboxOption
                                        name='checkRecurring'
                                        value={form?.checkRecurring}
                                        defaultState={form?.checkRecurring}
                                        onChange={(value) => {
                                            setForm({
                                                ...form,
                                                checkRecurring: value
                                            })
                                        }}
                                    />
                                    <span>Incluir recurrentes</span>
                                </div>
                            )
                        }
                    </>
                }
                <Button 
                    activity={loadMutation}
                    color={"primary"}
                    className={'w-100 mt-4'}
                    size={'xs'}
                    label={'Enviar'}
                    disabled={!(form?.part >= 0)}
                    onClick={() => {
                        
                        let variables = {
                            includeRecurring: form.checkRecurring,
                            part: form.part,
                            lessonId
                        }
                        if (recurringId) variables.recurringId = recurringId
                        
                        setPartMutation({variables})
                    }}
                />
            </div>
        </Modal>
    )
}

export default SetPartAttributeModal