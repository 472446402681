import React, { Suspense, lazy, useEffect, useState, useMemo, useRef } from "react";
import { Table } from "@Knowledge-OTP/znk-ui-components";
import { TabContent, TabPane } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import Pagination from "../../../../../../common/Pagination";
import useGetScoresGroupByClassOf from "../../../../Model/useGetScoresGroupByClassOf";
import useDesktopColumns from "./Columns/useDesktopColumns";

const ROWSLIMIT = 10;

const ClassOfScoresGroups = (props) => {
  const { search: searchClassOf = null, filters: filterByClassOf = {}, setGraduationDates, refetchQuery } = props;
  // const [data, setData] = useState(null);
  const ChildRow = lazy(() => import("./ChildRow"));
  const [query, setQuery] = useState({
    limit: ROWSLIMIT,
    page: 0,
    search: null,
  });

  const columns = useDesktopColumns();
  // const [rows, setRows] = useState([])
  const variables = {
    ...query,
    groupBy: 'classOf',
  };

  const {
    loading,
    data,
    refetch: refetchData
  } = useGetScoresGroupByClassOf({ variables }, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (refetchQuery) {
      refetchData();
    }
    // eslint-disable-next-line
  }, [refetchQuery]);

  const hasSetGraduationDates = useRef(false);
  useEffect(() => {
    if (data?.getScoresWithFilters?.docs && !hasSetGraduationDates.current) {
      setGraduationDates(() => {
        return Array.from(new Set(data.getScoresWithFilters.docs.map(({ _id }) => `Class of ${_id}`)));
      });
      hasSetGraduationDates.current = true;
    }
    // eslint-disable-next-line
  }, [data?.getScoresWithFilters]);


  const { rows } = useMemo(() => {
    if (data?.getScoresWithFilters) {
      const { docs } = data?.getScoresWithFilters;
      return { rows: docs };
    }
    return { rows: [] };
  }, [data]);

  //cantidad total de documentos
  const [total, setTotal] = useState(-1);
  useEffect(() => {
    const totalAux = data?.getScoresWithFilters?.totalRows;
    if (totalAux !== -1 && totalAux) {
      setTotal(totalAux);
    }
  }, [data]);

  useEffect(() => {
    //reejecuta la query
    setQuery({
      ...query,
      ...filterByClassOf,
      search: searchClassOf,
      page: 0,
    });
    // eslint-disable-next-line
  }, [searchClassOf, filterByClassOf]);

  const totalPages = useMemo(() => {
    return Math.ceil(total / (query.limit || ROWSLIMIT));
    //eslint-disable-next-line
  }, [total, query?.limit]);

  // const graduationDates = ['Class from classOfScoresGroups'];
  return (
    <div className={`h-100 d-flex flex-column justify-content-between`}>
      <div>
        <TabContent activeTab={loading ? "loading" : "show"}>
          <TabPane tabId={`show`}>
            <Table
              className={`cursor-pointer`}
              loading={loading}
              columnList={columns}
              tableData={rows}
              collapseRender={(props) => {
                return (
                  <Suspense fallback={<Skeleton />}>
                    <ChildRow
                      refetch={() => {
                        refetchData();
                      }}
                      items={props}
                    />
                  </Suspense>
                );
              }}
              onClick={(value) => {}}
            />
          </TabPane>
          <TabPane tabId={`loading`}>
            <Skeleton count={query?.limit || ROWSLIMIT} height={40} />
          </TabPane>
        </TabContent>
      </div>

      <Pagination
        bottomFix={true}
        pageSize={query.limit}
        size="large" //large, small
        changeSize={(newLimit) => {
          setQuery({ ...query, limit: newLimit || query?.limit || ROWSLIMIT });
        }}
        clickNext={() => {
          if (query.page + 1 < totalPages) {
            setQuery({ ...query, page: query.page + 1 });
          }
        }}
        clickPrevious={() => {
          if (query.page > 0) {
            setQuery({ ...query, page: query.page - 1 });
          }
        }}
        hasPrevious={query.page > 0}
        hasNext={query.page + 1 < totalPages}
        activePage={(query?.page || 0) + 1}
        pageCount={totalPages}
        loading={loading}
      />
    </div>
  );
};

export default ClassOfScoresGroups;
