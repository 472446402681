import React, {useRef, useEffect, useMemo} from 'react'
import {toPng} from 'html-to-image'
import {
    TemplateTimeline
} from '@Knowledge-OTP/znk-ui-components'
import useTemplateReducer from '../../../../../reducers/template'

const HideTimeline = (props) => {
    const {tasks = [], setTimelineUrl = () => {}, setTimelineRef = () => {}, generateImage = true} = props
    const {parseTemplateFromApi, filterDelete} = useTemplateReducer()
    const timelineRef = useRef(null)
    
    useEffect(() => {
        if(timelineRef?.current) {
          setTimelineRef(timelineRef)
          if(generateImage) {
            toPng(
              timelineRef?.current,
              // , {width: 500, height: 500}
            )
              .then((dataUrl) => {
                setTimelineUrl(dataUrl)
              })
              .catch((error) => {
                console.error('oops, something went wrong!', error)
              })
          }
        }
        // eslint-disable-next-line
    }, [timelineRef, tasks])
    


    const stages = useMemo(
      () => {
        return parseTemplateFromApi({
          templateName: 'Template',
          phases: tasks || [],
        })?.phases || []
      }, 
      // eslint-disable-next-line
      [tasks]
    ) 
    
    
    return (
        <div
            style={{
              height: '0px',
              overflow: 'hidden',
              position: 'relative',
            }}
        >
            <div
              className='m-0 p-0'
              style={{
                backgroundColor: 'transparent',
                width: 'fit-content'
              }}
              ref={timelineRef}
            >
              <TemplateTimeline
                isEdit={false}
                stages={
                  filterDelete({phases: stages}).phases
                }
              />
            </div>
        </div>
    )
}

export default HideTimeline