import React,{ useMemo } from 'react'
import {useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import UseSelectorField from './useSelectorField'
import AvatarField from './Components/AvatarField'
import AvatarCompactField from './Components/AvatarCompactField'
import moment from 'moment'

const UseColumnsStaffs = (refetch = () => {}) => {
  const {breakWidth} = useWindowDimensions()
  return useMemo(() => {
    if(['MD'].includes(breakWidth)) {
      return [
        {
          render: (item) => <AvatarCompactField item={item}/>, id: 'name', colSpan: 5, className: ''
        },
        {
          render: (item) => `${item.phone || 'No defined'}`, id: 'phone', colSpan: 5, className: 'justify-content-start align-items-center text-gray'
        },
        {
          optionsParser: (item) => {
            return UseSelectorField(item, 'staff', refetch)
          },
          type: 'selector',
          id: 'buttons',
          colSpan: 2,
          className: 'justify-content-end align-items-center'
        }
      ]
    }
    else if ( ['SM'].includes(breakWidth) ) {
      return [
        {
          render: (item) => <AvatarCompactField item={item} />, id: 'name', colSpan: 8, className: ''
        },
        {
          optionsParser: (item) => {
            return UseSelectorField(item, 'staff', refetch)
          },
          type: 'selector',
          id: 'buttons',
          colSpan: 2,
          className: 'justify-content-end align-items-center'
        } 
      ]
    }
    return [
      {
        render: item => <AvatarField item={item} />, id: 'name', colSpan: 2, className: 'justify-content-start align-items-center text-title'
      }, {
        render: (item) => item.emails[0].address, id: 'email', colSpan: 2, className: 'justify-content-start align-items-center text-gray'
      }, {
        render: (item) => `${item.phone || 'No defined'}`, id: 'phone', colSpan: 2, className: 'justify-content-start align-items-center text-gray'
      }, {
        render: (item) => moment.duration(moment().diff(moment(item.createdAt))).humanize(), id: (item) => {return `updatedAt-${item.id}`}, colSpan: 1, className: 'justify-content-start align-items-center text-gray'
      }, {
        optionsParser: (item) => {
          return UseSelectorField(item, 'staff', refetch)
        },
        type: 'selector',
        id: 'buttons',
        colSpan: 2,
        className: 'justify-content-end align-items-center'
      }
    ]
  // eslint-disable-next-line
  }, [breakWidth, refetch])
}

export default UseColumnsStaffs
