import React, {useMemo} from 'react'
import {
    ListItem, Avatar, IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import SelectStudentModal from '../../../../../Plans/modal/newPlan/SelectStudent'

const SelectStudent = (props) => {
    const {onChange = () => {}, value = null, loading = false, disabled = false, ...otherProps} = props
    console.log("VALUE", value)
    const studentName = useMemo(() => {
        return value ? `${value?.firstName} ${value?.lastName}` : null
    }, [value])
    return (
        <SelectStudentModal
            setStudent={onChange} 
            student={value || null}
            disabled={disabled}
            {...otherProps}
        >
            <TabContent activeTab={loading ? 'loading' : value ? 'show' : 'blank'}>
                <TabPane tabId={`loading`}>
                    <Skeleton count={1} height={40} className={`w-100`} />
                </TabPane>
                <TabPane tabId={`show`}>
                    <ListItem
                        className='py-1 appears-animation'
                        id='x-0'
                        label={null}
                        Left={
                            <div className='d-flex align-items-center'>
                                {
                                    studentName && (
                                        <>
                                            <Avatar
                                                name={studentName}
                                                named={false}
                                                uri={null}
                                                size='avatar-medium'
                                            />
                                            <label className='ml-2 h4'>
                                                {studentName}
                                            </label>       
                                        </>
                                    )
                                }
                            </div>
                        }
                        Right={
                            !disabled && (<div className={`hover-icon`}><IconArrowRight/></div>)
                        }
                    />
                </TabPane>
                <TabPane tabId={`blank`}>
                    <label className={`btn-link text-primary`}>Select student</label>
                </TabPane>
            </TabContent>
        </SelectStudentModal>
    )
}

export default SelectStudent