import { useCallback } from "react";
import useLazyPlan from "./Model/useLazyPlan";
import useLazyTask from "./Model/useLazyTask";
import useSetExamScore from "./Model/useSetExamScore";
import useSubmitScoreModal from "./useSubmitScoreModal";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../../../../common/Alert/util";
import useGetExamScoreByService from "./Model/useGetOneExamScoreByService";
import useLazyScoreByTask from "./Model/useGetScoresByTaskId";
import useLazyScoreByPlanId from "./Model/useGetScoresByPlanId";
import useAddNewScore from "./Model/useAddNewScore";
//indica a que modelo se le adjutará el score
export const FORMODEL = {
  task: "task",
  lessonPerWeekPlan: "lessonPerWeekPlan",
  // plan: 'plan',
  // student: 'student'
};

const snakeToCamel = (str) => {
  return str.replace(/-([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
};

const useHandlerExamForm = (forModel = FORMODEL.task) => {
  const dispatch = useDispatch();
  const [addNewScore] = useAddNewScore()
  const reduxData = useSubmitScoreModal();
  const {
    setLoading = () => {},
    close,
    openForCreate,
    openForEdit,
  } = reduxData;

  const [getPlan] = useLazyPlan();
  const [getTask] = useLazyTask();
  const [getScore] = useLazyScoreByTask();
  const [getScoreFromPlan] = useLazyScoreByPlanId();
  const [setExamScore] = useSetExamScore();
  
  const [getExamScoreByService] = useGetExamScoreByService();
  //crea o edita score en task (resolveTask.score)
  const createScoreForTask = async (examInput = {}, task = {}) => {
    setLoading(true);
    const variables = {
      examInput,
      taskId: task?.taskId,
      stepId: task?.stepId,
      phase: snakeToCamel(task?.phaseId),
      planId: task?.planId,
    };
    return setExamScore({ variables });
  };
  //crea o edita score en plan (selectedExam)
  const createScoreForPlan = async (examInput = {}, plan = {}, planId) => {
    // si no hay student id, que siga igual. Si hay, hay que buscar todos los planes del estudiante
    setLoading(true);
    const variables = {
      examScore: examInput,
      //TODO: revisar ese ID
      planId: planId,
    };
    return addNewScore({ variables }) 

  };

  const onSuccess = () => {
    showAlert({ text: "Success!", status: "success" }, dispatch);
  };
  const onError = (txt) => {
    showAlert({ text: txt || "Error!", status: "error" }, dispatch);
  };

  const setTaskInfo = (task) => {
    dispatch({
      type: "SET_DATA_MODAL_FORM_LIST",
      payload: "taskOnSubmitScoreModal",
      data: {
        taskData: task,
      },
    });
  };

  const openScoreFormCreate = useCallback(
    (examData, examScoreId, onChange = () => {}, data = {}) => {
      setTaskInfo(data);
      //TODO Grabar data en redux. Es la info de la task
      if (!examScoreId) {
        onError("No se encontró examen. ¡Falta información!");
        return;
      }
      openForCreate((examInput) => {
        if (forModel === FORMODEL.task) {
          createScoreForTask(examInput, data)
            .then(() => {
              if (onChange) onChange(examInput);
              onSuccess();
              close();
            })
            .catch(() => {
              onError();
            })
            .finally(() => {
              setLoading(false);
            });
        } else if (forModel === FORMODEL.lessonPerWeekPlan) {
          createScoreForPlan(examInput, data)
            .then(() => {
              if (onChange) onChange(examInput, examInput?.planId);
              onSuccess();
              close();
            })
            .catch(() => {
              onError();
            })
            .finally(() => {
              setLoading(false);
            });
        } else setLoading(false);
      },
      examData,
      examScoreId
      );
      // eslint-disable-next-line
    },
    // eslint-disable-next-line
    [forModel, setLoading]
  );
  const openScoreFormEdit = useCallback(
    (examData = {}, onChange = () => {}, data = {}, showPlansToAdd) => {
      if (!examData) {
        alert("No se encontró examen. ¡Falta información!");
        return;
      }
      openForEdit(
        (examInput) => {
          setLoading(true);
          if (forModel === FORMODEL.task) {
            createScoreForTask(examInput, data)
              .then(() => {
                if (onChange) onChange(examInput);
                onSuccess();
                close();
              })
              .catch(() => {
                onError();
              })
              .finally(() => {
                setLoading(false);
              });
          } else if (forModel === FORMODEL.lessonPerWeekPlan) {
            createScoreForPlan(examInput, data)
              .then(() => {
                if (onChange) onChange(examInput, examInput?.planId);
                onSuccess();
                close();
              })
              .catch(() => {
                onError();
              })
              .finally(() => {
                setLoading(false);
              });
          } else setLoading(false);
        },
        examData,
        showPlansToAdd
      );
    },
    // eslint-disable-next-line
    [forModel, setLoading]
  );

  const asyncOpen = useCallback(
    async (onChange = () => {}, modelId, serviceId = "") => {
      let data;
      let examData;
      // eslint-disable-next-line
      let examScoreId;
      let showPlansToAdd;
      let planId = "";
      if (!modelId) {
        examScoreId = serviceId || null;
        const data = await getExamScoreByService({
          variables: { serviceId: serviceId },
        });
        examData = data?.data?.getOneExamScoreByService;
        showPlansToAdd = true;
      } else if (forModel === FORMODEL.lessonPerWeekPlan) {
        const planResult = await getPlan({ variables: { planId: modelId } });
        data = planResult?.data?.plan;
        //hay que buscar el exam score con el serviceId
        const serviceInfo = await getExamScoreByService({
          variables: { serviceId: serviceId },
        });
        examScoreId = serviceInfo?.data?.getOneExamScoreByService?.id;
        //examData = data?.selectedExam?.scores
        // traer las notas del examen si existe y guardarlos condicionalmente
        const scoreResult = await getScoreFromPlan({
          variables: { planId: modelId },
        });
        planId = modelId;
        examData = scoreResult?.data?.getScoresByPlanId[0];
      } else if (forModel === FORMODEL.task) {
        const taskResult = await getTask({ variables: { taskId: modelId } });
        data = taskResult.data.getTask;
        //TODO: guardar esta info en redux para acceder desde SubmitScore
        // traer las notas del examen si existe
        const scoreResult = await getScore({ variables: { taskId: modelId } });
        examScoreId = data?.task?.selectedExamId;
        examData = scoreResult?.data?.getScoresByTaskId[0];
      }
      //ya tiene score registrado. Se usará dicho score para armar el formulario y no el template
      if (examData || examData?.planId) {
        openScoreFormEdit(examData, onChange, data, showPlansToAdd);
      } else {
        openScoreFormCreate(onChange, data, planId);
      }
      return true;
    },
    // eslint-disable-next-line
    [forModel, getTask, getPlan]
  );
  return {
    ...reduxData,
    open: asyncOpen,
  };
};

export default useHandlerExamForm;
