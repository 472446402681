import {useEffect} from 'react'
import useViewPlanModal from '../useViewPlanModal'
import useTemplateReducer from '../../../../../reducers/template'
/**
 * Sirve para comunicar a planViewModal cualquier actualización
 * 
 * Actualiza información de tasks steps y phases en objeto plan.tasks 
 * **/
const useChangeListener = (plan) => {
    const {
        setData: setDataOnModalPlan, 
        onChange: onChangePlan
    } = useViewPlanModal()
    const {state: timeline, setData, ...templateReducerOtherFunctions} = useTemplateReducer() 
    
    
    // const [initExecution, setInitExecution] = useState(true)
    useEffect(() => {
        if(
            plan?.tasks?.length > 0 && 
            //solo actualiza cuando plan es diferente a timeline
            //evita loop infinito 
            JSON.stringify(plan?.tasks) !== JSON.stringify(timeline?.phases) 
        ) {
            // setInitExecution(false)
            setData({phases: plan?.tasks})
        }
        // eslint-disable-next-line
    }, [plan])

    // const [notSetTimeline, setNotSetTimeline] = useState(false)
    // useEffect(() => {
    //     if(plan?.tasks?.length > 0 && !initExecution) {
    //         setNotSetTimeline(true)
    //         setData({phases: plan?.tasks})
    //     }
    // }, [plan])
    
    useEffect(() => {
        if(timeline) {
            setDataOnModalPlan({...plan, tasks: timeline.phases})
        }
        // else if(notSetTimeline) setNotSetTimeline(false)
        // eslint-disable-next-line
    }, [timeline])
    return {
        ...templateReducerOtherFunctions,
        onChangePlan,
        timeline
    }
}

export default useChangeListener