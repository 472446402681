import {gql, useMutation} from '@apollo/client'

const useAssignMultipleTasks = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $assignTaskParams: [AssignTaskParams!] = []
            ) {
                assignMultipleTasks (
                    assignTaskParams: $assignTaskParams
                )
            }
        `,
        {
            onCompleted, onError
        }
    )
}

export default useAssignMultipleTasks