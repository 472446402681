
import {useSelector, useDispatch} from 'react-redux'

const useCompleteGroupPlanModal = () => {
    const dispatch = useDispatch()
        const reduxData = useSelector((state) => state.modal.plan.completeGroupPlan)
        const {isOpen} = reduxData
        const setData = (open = false, onChange = () => {}, dataPlan = {}, useMutation = false) => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'plan.completeGroupPlan',
                data: {
                    ...reduxData,
                    onChange,
                    dataPlan,
                    isOpen: open,
                    useMutation
                }
            })
        }
        
        return {
            ...reduxData,
            open: (onChange = () => {}, dataPlan = {}, useMutation = true) => {
                setData(true, onChange, dataPlan, useMutation)
            },
            close: () => {
                setData(false)
            },
            toggle: () => {
                setData(!isOpen)
            }
        }
}


export default useCompleteGroupPlanModal