import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation createRefund(
        $stripeInvoiceId: String!,
        $amountToRefund: Float!,
        $reason: String!
       ){
        createRefund(
          stripeInvoiceId: $stripeInvoiceId,
          amountToRefund: $amountToRefund,
          reason: $reason
        ) {
          creditNoteId
          amount
          stripeInfo {
            created
            currency
            discount_amount
            number
            out_of_band_amount
            pdf
            subtotal
            total
          }
        }
       }`
  )
  const submit = useCallback((obj) => {
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      // setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.createRefund : null, error, submit }
}
