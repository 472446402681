import { AmountInput, Button, Field, Form, IconClose, IconErase, IconOptions, InputSelect, LabeledField, Modal, PlainTextInput, StickyButton } from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useMemo, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, validate } from 'validate.js'
import useTranslation from '../../../i18n/useTranslation'
import SelectEmployee from '../Components/SelectEmployee'
import createManualPayslip from '../../../Model/Payments/Payslips/New'
import constraints from '../../../Model/Payments/Payslips/New/validation'

import './index.css'
import { gql, useLazyQuery } from '@apollo/client'
import formatNumber from '../../../utils/formatNumber'
import Menu from './Menu'
import moment from 'moment'
import {escapeRegExp} from 'lodash'

const types = [
  { id: 'lessonsPayment', name: 'Lesson' },
  { id: 'reimbursement', name: 'Reimbursement' },
  { id: 'content', name: 'Content' },
  { id: 'training', name: 'Training' }
]
const NewPayslip = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.newPayslip)
  const { isOpen } = modalData
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const [errors, setError] = useState({})
  const [validationErrors, setValidationErrors] = useState({})
  const [form, setForm] = useState({
    educator: undefined,
    category: 'livePrep',
    payslipDate: moment().format('YYYY-MM-DD'),
    total: 0,
    number: '',
    newPayments: {
      0: {
        type: 'lessonsPayment',
        amount: 0,
        description: '',
        errorAmount: '',
        errorDescription: '',
        errorType: '',
        hover: false
      }
    }
  })
  const [getNumber, { data: dataNumber }] = useLazyQuery(
    gql`query($name: ID!) {
    getGlobalVar(name: $name) {
      name
      id
      isActive
      value
    }
  }`,
    {
      variables: { name: 'payslipNumber' },
      fetchPolicy: 'network-only'
    })
  useMemo(() => {
    getNumber()
  }, [getNumber])
  const toggle = () => {
    setError({})
    setForm({
      educator: undefined,
      category: 'livePrep',
      payslipDate: moment().format('YYYY-MM-DD'),
      newPayments: {
        0: {
          type: 'lessonsPayment',
          amount: 0,
          description: '',
          errorAmount: '',
          errorDescription: '',
          errorType: '',
          hover: false
        }
      },
      total: 0,
      number: ''
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.newPayslip',
      data: {
        isOpen: false
      }
    })
  }
  const { submit, loading } = createManualPayslip((result) => {
    refetch()
    toggle()
  }, console.log)
  useEffect(() => {
    const total = Object.values(form.newPayments).reduce(
      (sum, current) => sum + (current.amount ? current.amount !== '' ? !isNaN(current.amount) ? current.amount : 0 : 0 : 0)
      , 0)
    setForm({
      ...form,
      total: total
    })
    // eslint-disable-next-line
  }, [form.newPayments])
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key.split('.')[1]
        customError[keyVal] = translate('payslip.actions.newPayslip.errors.' + keyVal)
      })
      setError(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])
  const createItem = () => {
    setForm({
      ...form,
      newPayments: {
        ...form.newPayments,
        [Object.values(form.newPayments).length]: {
          type: 'lessonsPayment',
          amount: 0,
          description: '',
          errorAmount: '',
          errorDescription: '',
          errorType: '',
          hover: false
        }
      }
    })
  }
  const onSubmit = (status) => {
    setError({})
    const newVal = {
      userId: form.student ? form.student !== '' ? form.student.id : '' : '',
     	payslipDate: form.payslipDate === undefined ? '' : form.payslipDate,
      status: status,
      paymentItems: Object.values(form.newPayments).map(
        element => {
          return {
            amount: element.amount,
            description: element.description,
            type: element.type
          }
        }
      )
    }
    let firstError = false
    const errorsVal = validate({ input: newVal }, constraints)
    if (errorsVal !== undefined) {
      setValidationErrors(errorsVal)
      firstError = true
    }
    let hasError = false
    const itemVal = Object.values(form.newPayments).reduce(
      (items, current, index) => {
        if (!hasError && (isNaN(current.amount) || parseFloat(current.amount) <= 0 || isEmpty(current.description) || current.type === undefined || isEmpty(current.description))) hasError = true
        return {
          ...items,
          [index]: {
            ...current,
            errorAmount: isNaN(current.amount) ? 'amount' : parseFloat(current.amount) <= 0 ? 'amount' : '',
            errorDescription: isEmpty(current.description) ? 'description' : '',
            errorType: current.type === undefined || isEmpty(current.type) ? 'type' : ''
          }
        }
      }
      , {})

    if (hasError || firstError) {
      setForm({
        ...form,
        newPayments: itemVal
      })
      return
    }
    submit({ input: newVal })
  }
  const removeItem = (index) => {
    setForm({
      ...form,
      newPayments: Object.values(form.newPayments).filter((_, i) => i !== index)
    })
  }
  return (
    <>
      <div className='m-0 p-0' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        className='modal-sidebar modal-colored white-modal modal-desktop-half pl-3 full-payslip apply-width-content'
        isOpen={isOpen}
        toggle={toggle}
        backdrop='static'
        Head={
          <div className='modal-header p-0 m-0'>
            <div className='d-flex justify-content-between w-100 p-7'>
              <h2 className='font-weight-bold'>
                {
                  translate('payslips.addNewPayslip')
                }
              </h2>
              <IconClose onClick={toggle} className='text-gray' />
            </div>
          </div>
        }
      >
        <Form value={form} onChange={setForm} validationErrors={errors}>
          <div className='d-flex flex-column'>
            <div className='px-7'>
              <hr className='' />
              <SelectEmployee form={form} setForm={setForm} first={false} title={false} />
              {!isEmpty(errors) && errors.userId && <h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.userId')}</h5>}
              <hr className='' />
            </div>
            <div className='row d-flex m-0 mt-5 px-7'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.category')}</label>
                <Field
                  className='col-12'
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='payslipDate'
                  fieldType='date'
                />
              </div>
              <div className='col-6 pl-1 pr-0 pl-2'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.number')}</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='number'
                  fieldType='text'
                  value={dataNumber ? 'PAY-' + String(dataNumber.getGlobalVar.value).padStart(5, 0) : ''}
                  readOnly
                />
              </div>
            </div>
            <div className='px-7'>
              <hr className='mt-1 mb-4' />
            </div>
            <div className='row d-flex m-0 px-7'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>Item</label>
              </div>
              <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                <label className='text-gray font-weight-light mb-2'>Amount</label>
              </div>
            </div>
            <div className='px-7'>
              <hr className='mt-1 mb-4' />
            </div>
            {
              Object.values(form.newPayments).map(
                (item, index) => {
                  return (
                    <Fragment
                      key={`item-${index}`}
                    >
                      <div
                        key={`item-${index}`}
                        className='d-flex flex-row justify-content-between align-items-center'
                        onMouseEnter={() => {
                          setForm({
                            ...form,
                            newPayments: { ...form.newPayments, [index]: { ...item, hover: true } }
                          })
                        }}
                        onMouseLeave={() => {
                          setForm({
                            ...form,
                            newPayments: { ...form.newPayments, [index]: { ...item, hover: false } }
                          })
                        }}
                      >
                        <div className='w-100 pl-7 mr-7'>
                          {index > 0 && (
                            <div>
                              <hr className='mt-1 mb-4' />
                            </div>)}
                          <div className='row d-flex m-0'>
                            <div className='col-6 p-0 pr-2'>
                              <Field
                                className='col-12'
                                type={InputSelect}
                                placeholder={translate('modal.newplan.selectservice')}
                                options={types && [].concat(types.map((serv) => ({ value: serv.id, label: serv.name })))}
                                InputClassName='w-100'
                                value={item.type}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    newPayments: { ...form.newPayments, [index]: { ...item, type: e } }
                                  })
                                }}
                                filterOption={(obj, string) => {
                                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                                  return false
                                }}
                              />
                              {item.errorType !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.type')}</h5>)}
                            </div>
                            <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                              <Field
                                className='col-12 placeholder-to-right'
                                type={AmountInput}
                                fieldType='number'
                                placeholder='0.00'
                                addOn='$'
                                min='0'
                                value={item.amount}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    newPayments: { ...form.newPayments, [index]: { ...item, amount: e } }
                                  })
                                }}
                              />
                              {item.errorAmount !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.amount')}</h5>)}
                            </div>
                          </div>
                          <div className='row d-flex m-0 p-0 mt-1 mb-3'>
                            <div className='col-12 p-0'>
                              <Field
                                type={PlainTextInput}
                                placeholder='Description'
                                fieldType='text'
                                value={item.description}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    newPayments: { ...form.newPayments, [index]: { ...item, description: e } }
                                  })
                                }}
                              />
                              {item.errorDescription !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.description')}</h5>)}
                            </div>
                          </div>
                        </div>
                        <div className={`${item.hover && Object.values(form.newPayments).length > 1 ? 'icodelhover' : 'icodel'}`} onClick={() => { removeItem(index) }}>
                          <IconErase size={18} color='#FF3E1D' />
                        </div>
                      </div>
                    </Fragment>
                  )
                }
              )
            }
            <div className='px-7'>
              <hr className='mt-1' />
              <h4 className='btn-link cursor my-3' onClick={createItem}>{translate('payments.newinvoice.addnewitem')}</h4>
              <hr className='mt-1' />
            </div>
            <div className='row d-flex m-0 mt-5 px-7'>
              <div className='col-6 p-0 pr-2'>
                <h2 className='font-weight-light mb-2'>Total</h2>
              </div>
              <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                <h2 className='font-weight-light mb-2'>${formatNumber(form.total)}</h2>
              </div>
            </div>
            <div className='px-7'>
              <hr className='m-0 mt-3' />
            </div>
            <div className='px-3 px-lg-5 px-xl-7 d-flex flex-column my-8'>
              <StickyButton>
                <div className='m-0 p-0 row'>
                  <Button
                    color='secondary'
                    activity={loading}
                    className='col-9'
                    label='Save in draft'
                    onClick={() => {
                      onSubmit('draft')
                    }}
                    style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                    check
                  />
                  <Menu newAproveInvoice={(value) => { onSubmit(value) }}>
                    <Button
                      className='ml-3'
                      color='secondary'
                      style={{ bottom: '10px', right: '25px', width: '50px' }}
                      label={<IconOptions style={{ transform: 'rotate(90deg)' }} />}
                    />
                  </Menu>
                </div>
              </StickyButton>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default NewPayslip
