import React, { useEffect, useState, useMemo } from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  Avatar,
  StickyButton,
  Activity,
  Input,
  IconSearch,
  IconOutLine,
  IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import GetStudentLazy from '../../../../../../../../Model/Users/List/lazy'
// import NewStudent from '../NewStudent'
import NewStudent from '../../../../../../modal/newPlan/Components/NewStudent'
import useModalState from '../../../../../../../../utils/useModalState'

const SelectStudent = (props) => {
  const { isOpen, toggle, modalData } = useModalState('groupPlans.selectStudents')
  const { setStudent, selectedStudents } = modalData
  const [selectStudent, setSelectStudent] = useState([])
  useEffect(() => { setSelectStudent(selectedStudents) }, [selectedStudents, setSelectStudent])
  const { breakWidth } = useWindowDimensions()
  const [getStudents, { loading, data, refetch }] = GetStudentLazy({fetchPolicy: 'no-cache'})
  useEffect(() => {
    if(isOpen) {
      getStudents({
        variables: {
          limit: 0,
          roles: ['STUDENT'],
          sortField: 'firstName'
        }
      })
    }
    // eslint-disable-next-line
  }, [isOpen])

  const students = useMemo(
    () => {
      return loading ? [] : data ? data?.allUsers?.docs ? data.allUsers.docs.filter(e => e.isActive === true) : [] : []
    },
    [loading, data]
  )
  const [studentSearch, setStudentSearch] = useState(null)

  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>Seleccionar estudiante</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <div className='m-0 p-0'>
          <Input
            className='w-100 input-xs p-0 mb-3'
            placeholder='Buscar estudiante'
            addOnComponent={<IconSearch size={16} />}
            type='addon'
            onChange={(e) => {
              setStudentSearch(e.target.value)
            }}
          />
          <div className='m-0 py-2 px-0'>
            <NewStudent 
            // dataQuery={data}
              callback={(newStudent) => {
                setSelectStudent(selectStudent.concat([newStudent]))
              }}
              refetch={refetch}
            >
              <ListOption
                className='mb-2'
                id='x-0'
                IconRight={<IconArrowRight size={15} color='#696CFF' />}
                childClassName='d-flex'
                label={<span className='m-0 p-0' style={{ color: '#696CFF' }}>Create new student</span>}
                icon={
                  <IconOutLine
                    className='p-3 mr-1'
                    backgroundColor='#696CFF'
                    color='#FFFFFF'
                    textChildren={<span className='m-0 font-weight-bold' style={{ color: 'white' }}>+</span>}
                    sizePx={25}
                  />
                }
              />
            </NewStudent>
            {(loading /**|| JSON.stringify(students) === JSON.stringify([])**/ )
              ? <div className='d-flex justify-content-center'><Activity /></div>
              : (studentSearch !== null && studentSearch !== '')
                ? students.filter(
                  (stu) => new RegExp(studentSearch, 'i').exec(`${stu.firstName} ${stu.lastName}`)
                ).map((stu) => {
                  const isSelected = (selectStudent || []).find((user) => user.id === stu.id)
                  return (
                    <ListOption
                      className={`mb-2 ${isSelected ? 'selected-secondary' : ''}`}
                      childClassName='d-flex'
                      id='x-0'
                      label={`${stu.firstName} ${stu.lastName}`}
                      icon={
                        <Avatar
                          name={`${stu.firstName} ${stu.lastName}`}
                          named={false}
                          uri={null}
                          size='avatar-small mr-1'
                        />
                      }
                      onClick={() => {
                        if (isSelected) {
                          setSelectStudent(students => students.filter(item => item.id !== stu.id))
                        } else {
                          setSelectStudent(students => students.concat(stu))
                        }
                      }}
                    />
                  )
                })
                : students.map((stu) => {
                  const isSelected = selectStudent.find((user) => user.id === stu.id)
                  return (
                    <ListOption
                      className={`mb-2 ${isSelected ? 'selected-secondary' : ''}`}
                      childClassName='d-flex'
                      id='x-0'
                      label={`${stu.firstName} ${stu.lastName}`}
                      icon={
                        <Avatar
                          name={`${stu.firstName} ${stu.lastName}`}
                          named={false}
                          uri={null}
                          size='avatar-small mr-1'
                        />
                      }
                      onClick={() => {
                        if (isSelected) {
                          setSelectStudent(students => students.filter(item => item.id !== stu.id))
                        } else {
                          setSelectStudent(students => students.concat(stu))
                        }
                      }}
                    />
                  )
                })}
          </div>
          <StickyButton
            condition={JSON.stringify(selectStudent) !== JSON.stringify({})}
            label='Select student'
            onClickButtonFunction={() => {
              setStudent(selectStudent)
              toggle()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

SelectStudent.propTypes = {
}

SelectStudent.defaultProps = {
}
export default SelectStudent
