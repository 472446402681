import useTranslation from '../../../../i18n/useTranslation'

const HeaderFields = () => {
  const { translate } = useTranslation()
  return (
    [ {
      text: 'Service',
      className: 'justify-content-start',
      colSpan: 2
    },{
      text: translate('constants.activities.name'),
      colSpan: 3
    }, {
      text: translate('constants.activities.tbd'),
      className: 'justify-content-center',
      colSpan: 2
    }, {
      text: translate('constants.activities.testDate'),
      className: 'justify-content-center',
      colSpan: 2
    }, {
      text: translate('constants.activities.registrationDate'),
      className: 'justify-content-center',
      colSpan: 2
    }, {
      text: translate('constants.activities.scoreReleaseDate'),
      className: 'justify-content-center',
      colSpan: 2
    }]
  )
}

export default HeaderFields
