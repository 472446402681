import {useLazyQuery, gql} from '@apollo/client'

const useEducatorFinderByFrames = () => {
    return useLazyQuery(
        gql`
            query (
                $frames: [DateTime!]!
                $serviceId: String!
                $topicId: String!
                $from: DateTime!
                $to: DateTime!
            ) {
                educatorFinderByFrames (
                    frames: $frames
                    serviceId: $serviceId
                    topicId: $topicId
                    from: $from
                    to: $to
                ) {
                    id
                    avatar
                    lastName
                    firstName
                    freeFramesBlocs
                }
            }
        `
    )
}

export default useEducatorFinderByFrames