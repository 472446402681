import React from 'react'
import {IconLoad, IconRepeat} from '@Knowledge-OTP/znk-ui-components'

import useGenerateGoogleCalendarDocument from './Model/useGenerateGoogleCalendarDocument'
import useGetGoogleCalendarDocument from './Model/useGetGoogleCalendarDocument'

import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../../i18n/useTranslation'
import { showAlert } from '../../../../../../common/Alert/util'

const EducatorCalendarLink = (props) => {
    const { user = {}, refetch = () => {}, role = 'educator' } = props
    const {translate} = useTranslation()
    const dispatch = useDispatch()
    const {data, loading, refetch: refetchQuery} = useGetGoogleCalendarDocument({userId: user.id, role})
    const {getGoogleCalendarDocument} = data || {}
    const googleCalendarString = getGoogleCalendarDocument || translate('profile.actions.educatorInfo.calendarLinkNoDefined')
    const [onSuccess, onError] = [
        () => {
            refetchQuery()
            refetch()
            showAlert({text: translate('profile.actions.educatorInfo.onSuccess'), status: 'success'}, dispatch)
        }, 
        (err) => {
            console.error(err)
            showAlert({text: err.toString(), status: 'error'}, dispatch)
        }
    ]
    const [generateLink, {loading: loadMutation}] = useGenerateGoogleCalendarDocument(onSuccess, onError)

    return (
        <div className='d-flex flex-row justify-content-between align-items-center mt-4'>
          <span className='text-title w-40'>{translate('profile.actions.editGeneralInfo.googlefeed'/**'profile.actions.educatorInfo.calendarLinkTitle'**/)}</span>
          <span className='text-gray d-flex flex-row align-items-center justify-content-end w-50'>
            {loading ? <IconLoad size={16} /> : <span onClick={() => {
                if(getGoogleCalendarDocument) {
                    navigator.clipboard.writeText(getGoogleCalendarDocument).then(
                        () => {showAlert({text: "Copy!", status: 'success'}, dispatch)}
                    )
                    showAlert({text: getGoogleCalendarDocument, status: 'success'}, dispatch)
                }
            }} className='text-truncate cursor-pointer' title={googleCalendarString}>{googleCalendarString}</span>}
            <div
                onClick={() => {if(!loadMutation)generateLink({variables: {userId: user.id, role}})}} 
                className={'hover-icon ml-1'}>
                {loadMutation ? <IconLoad size={16}/> : <IconRepeat size={16}/>}
            </div>
          </span>
        </div>
    )
}

export default EducatorCalendarLink