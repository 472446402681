import React, { useEffect, useState } from 'react'
import {
  InvoiceCard,
  ListItem,
  IconRight,
  IconEdit,
  StickyButton,
  Button,
  IconOptions,
  DropDownNew,
  IconErase,
  ConfirmModal,
  IconSuccess,
  IconPayslip,
  IconSignPlus,
  ModalLayout,
  DropDownItem,
  IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { gql, useLazyQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import './index.css'
import formatNumber from '../../../utils/formatNumber'
import useTranslation from '../../../i18n/useTranslation'
import SelectEmployee from '../Components/SelectEmployee'
import approvedPayslip from '../../../Model/Payments/Payslips/Approved'
import EditPaymentSlip from '../EditPayment'
import waivePayslipItem from '../../../Model/Payments/Payslips/WaiveItem'
import removePayslipItem from '../../../Model/Payments/Payslips/RemoveItem'
import removePayslip from '../../../Model/Payments/Payslips/Remove'
import processOnePayslip from '../../../Model/Payments/Payslips/ProcessOne'

const ViewPayslip = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.viewPayslip)
  const { isOpen, payslipId, onChange = () => { } } = modalData
  const [state, setState] = useState({ id: '', state: '', isOpen: false })
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [form, setForm] = useState({
    student: undefined,
    items: []
  })
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.viewPayslip',
      data: {
        isOpen: false,
        payslipId: ''
      }
    })
  }
  const [mePayslip, { data }] = useLazyQuery(
    gql`query($payslipId: String!){
      payslip(payslipId: $payslipId) {
        id
        userId {
          id
          firstName
          lastName
          avatar
        }
        from
        to
        category
        payslipDate
        payslipNumber
        paymentItemIds
        payslipDate
        isManual
        createdAt
        updatedAt
        status
        lastProcessedAt
        payslipProps {
          amount
          isPenalized
        }
        paymentItemsPayslip {
          generatedDescription
          id
          amount
          date
          description
          chargeType
          type
          lessonId
          isWaive
        }
      }
    }`,
    {
      variables: { payslipId: payslipId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (payslipId) {
        await mePayslip({ payslipId })
      }
    })()
    // eslint-disable-next-line
  }, [payslipId])
  useEffect(() => {
    if (data) {
      setForm({
        student: data.payslip.userId,
        items: data.payslip.paymentItemsPayslip.map(
          e => {
            return {
              ...e,
              hover: false
            }
          }
        )
      })
    }
    // eslint-disable-next-line
  }, [data])
  const getStatus = (invoice, witch) => {
    let modalState
    switch (invoice.status) {
      case 'draft':
        modalState = 'draft'
        break
      case 'pendingPayment':
        if (invoice.category === 'livePrep') {
          modalState = 'live'
        } else {
          modalState = 'counceling'
        }
        break
      case 'procesingPayment':
        modalState = 'live'
        break
      case 'unPaid':
        modalState = 'pendingPayment'
        break
      case 'paid':
        modalState = 'paid'
        break
      case 'canceled':
        modalState = 'canceled'
        break
      default:
        modalState = 'scheduled'
        break
    }
    if (witch === 'modal') {
      switch (modalState) {
        case 'draft':
          return 'scheduled'
        case 'live':
          return 'upcoming'
        case 'counceling':
          return 'counceling'
        case 'pendingPayment':
          return 'error'
        case 'paid':
          return 'paid'
        case 'canceled':
          return 'canceled'
        default:
          return ''
      }
    } else {
      return modalState
    }
  }
  const getTop = (status) => {
    switch (status) {
      case 'canceled':
        return ' - CANCELED'
      case 'paid':
        return ' - PAID'
      case 'unPaid':
        return ' - ERROR'
      default:
        return ''
    }
  }
  // const handletHover = (item, value) => {
  //   const items = form.items
  //   items.forEach(
  //     e => {
  //       if (e.id === item.id) {
  //         e.hover = value
  //       }
  //     }
  //   )
  //   setForm({ ...form, items: items })
  // }
  const editPayment = (id) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.editPaymentSlip',
      data: {
        isOpen: true,
        paymentItemId: id
      }
    })
  }
  const { submit: submitWaive, data: dataWaive, loading: loadingWaive } = waivePayslipItem(() => {
  }, console.log)
  useEffect(() => {
    if (dataWaive) {
      if (dataWaive.isPayslipEmpty) {
        toggle()
        refetch()
        if (onChange) onChange()
      } else {
        if (!payslipId) return
        mePayslip({ payslipId })
      }
      setState({ ...state, isOpen: false })
    }
    // eslint-disable-next-line
  }, [dataWaive])
  const { submit: submitRemove, data: dataRemove, loading: loadingRemove } = removePayslipItem(() => {
    if (onChange) onChange()
    // refetch()
    // mePayslip({ payslipId })
    // setState({ ...state, isOpen: false })
  }, console.log)
  useEffect(() => {
    if (dataRemove) {
      if (dataRemove.isPayslipEmpty) {
        toggle()
        if (onChange) onChange()
        refetch()
      } else {
        if (!payslipId) return
        mePayslip({ payslipId })
      }
      setState({ ...state, isOpen: false })
    }
    // eslint-disable-next-line
  }, [dataRemove])
  const { submit: submitRemovePayslip, loading: loadingRemovePayslip } = removePayslip(() => {
    setState({ ...state, isOpen: false })
    if (onChange) onChange()
    refetch()
    toggle()
  }, console.log)
  const optionsPayment = (id, type) => {
    setState({ id: id, state: type, isOpen: true })
  }
  const onExecute = () => {
    if (state.state === 'remove') submitRemove({ paymentItemId: state.id, payslipId })
    else if (state.state === 'waive') submitWaive({ paymentItemId: state.id, payslipId: payslipId })
    else submitRemovePayslip({ payslipId: payslipId })
  }
  const editPayslip = () => {
    toggle()
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.editPayslip',
      data: {
        isOpen: true,
        payslipId: payslipId
      }
    })
  }
  const { submit, loading } = approvedPayslip(() => {
    if (onChange) onChange()
    refetch()
    toggle()
  })
  const onSubmit = () => {
    submit({ payslipId })
  }
  const { submit: submitOne, loading: loadingOne } = processOnePayslip(() => {
    if (onChange) onChange()
    refetch()
    toggle()
  })
  const processPayment = (id) => {
    submitOne({ payslipId: id })
  }
  const isEdit = false


  const RenderOption = (props) => {
    const {
      status = 'primary', IconComponent = IconEdit,
      leftText = 'Text left', ...otherProps
    } = props
    return (
      <ListItem
        {...otherProps}
        Left={
          <div className={`d-flex flex-row align-items-center pl-2`}>
            <IconComponent className={`text-${status}`} />
            <span className={`ml-2 text-${status}`}>{leftText}</span>
          </div>
        }
        Right={
          <div className={`hover-icon`}>
            <IconArrowRight />
          </div>
        }
      />
    )
  }

  return (
    <>
      {
        state.isOpen && (
          <ConfirmModal
            className='m-4'
            toggle={() => setState({ ...state, isOpen: false })}
            isOpen={state.isOpen}
            title={state.state === 'remove' ? translate('payslips.modal.removepayment.title') : state.state === 'waive' ? translate('payslips.modal.ignorepayment.title') : translate('payslips.modal.removepayslip.title')}
            body={state.state === 'remove' ? translate('payslips.modal.removepayment.bodyconfirm') : state.state === 'waive' ? translate('payslips.modal.ignorepayment.bodyconfirm') : translate('payslips.modal.removepayslip.bodyconfirm')}
            confirmAction={onExecute}
            cancelAction={() => setState({ ...state, isOpen: false })}
            confirmText={state.state === 'remove' ? translate('payments.modal.removeitem.confirm') : state.state === 'waive' ? translate('payments.modal.ignorepayment.confirm') : translate('payments.modal.removepayslip.confirm')}
            cancelText={translate('plans.modal.cancelplan.cancel')}
            loading={loadingWaive || loadingRemove || loadingRemovePayslip}
          />
        )
      }
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <ModalLayout
        className={`modal-sidebar modal-colored ${data ? getStatus(data.payslip, 'modal') : 'scheduled'}-modal modal-desktop-half`}
        isOpen={isOpen}
        toggle={toggle}
        topHead={
          <span>{data ? 'End of ' + moment(data.payslip.to).format('MMMM') : ''}</span>
        }
        minHead={
          <div className={`d-flex flex-row`}>
            <span>{`${data ? 'PAY-' + String(data.payslip.payslipNumber).padStart(5, 0) : ''}`}</span>
          </div>
        }
        underHead={
          <div className='d-flex flex-column w-100'>
            <InvoiceCard
              type={data ? getStatus(data.payslip, 'invoice') : 'draft'}
              showHeader={false}
              textTop={null}
              text={`${data ? getTop(data.payslip.status) : ''}`}
              typePayment={<></>}
              className='mt-1 px-0 pt-0 w-100 useBodyBorder'
              amount={data ? data.payslip.payslipProps.amount : 0}
              of=' '
              invoice={`${data ? 'PAY-' + String(data.payslip.payslipNumber).padStart(5, 0) : ''}`}
            />
            {data && data.payslip.status === 'paid' && (<ListItem
              className='mb-2 border-top w-100'
              Left={<h3 style={{ color: '#81B023' }}>Payslip paid on {moment(data.payslip.lastProcessedAt).format('MMM DD, YYYY hh:mm a')}</h3>}
              Right={
                <span>
                  <IconSuccess className='ml-1' color='#A7E521' size={24} />
                </span>
              }
            />)}
          </div>
        }
      >
        {/* <span>{`Payslips id: ${payslipId}`}</span> */}
        {
          data ? (
            <div className='d-flex flex-column px-0'>
              <SelectEmployee className={`p-0 mt-4`} form={form} setForm={setForm} first={false} title={false} edit={false} />
              <div className={`borderTop w-100 my-4`} />
              <div className='d-flex flex-row w-100 align-items-center'>
                <div className={`w-10 d-flex flex-row justify-content-center`}>
                  <IconPayslip className={`text-info`} size={20} />
                </div>
                <span className='ml-2 text-info w-90'>{translate('payslips.description')}</span>
              </div>
              <div className={`borderTop w-100 my-4`} />
              <div className='d-flex flex-row justify-content-between px-0 mb-4'>
                <h3 className='text-gray h4'>Item</h3>
                <h3 className='text-gray h4'>Amount</h3>
              </div>
              {
                data && form.items.filter(e => !e.isWaive).map(
                  (element, index) => {
                    const options =
                      element.type === 'lessonsPayment' ? [
                        {
                          component:
                            <DropDownItem onClick={() => { editPayment(element.id) }}>
                              <div className={`d-flex flex-row align-items-center text-primary`}>
                                <IconEdit size={14} />
                                <div className='ml-2'>Edit Payment</div>
                              </div>
                            </DropDownItem>
                        },
                        {
                          component:
                            <DropDownItem className={`danger-option text-error`} onClick={() => {
                              optionsPayment(element.id, element?.lessonId ? 'waive' : 'remove')
                            }}>
                              <div className={`d-flex flex-row align-items-center`}>
                                {
                                  element.lessonId
                                    ? (<>
                                      <IconSignPlus size={14} />
                                      <div className='ml-2'>Waive payment</div></>)
                                    : (<>
                                      <IconErase size={14} />
                                      <div className='ml-2'>Remove payment</div></>)
                                }
                              </div>
                            </DropDownItem>
                        }
                      ] : [
                        {
                          component:
                            <DropDownItem onClick={() => { editPayment(element.id) }}>
                              <div className='d-flex flex-row align-items-center text-primary'>
                                <IconEdit size={14} className={`text-info`} />
                                <div className='ml-2'>Edit Payment</div>
                              </div>
                            </DropDownItem>
                        },
                        {
                          component:
                            <DropDownItem className={`danger-option text-error`} onClick={() => { optionsPayment(element.id, 'remove') }}>
                              <div className='d-flex flex-row align-items-center'>
                                <IconErase size={14} />
                                <div className='ml-2'>Remove payment</div>
                              </div>
                            </DropDownItem>
                        }
                      ]
                    // const opciones =
                    //   element.type === 'lessonsPayment'
                    //     ? (
                    //       <div className='d-flex flex-column my-2 ml-3' style={{ width: '200px' }}>
                    //         <span className='primary d-flex flex-row align-items-center' onClick={() => { editPayment(element.id) }}>
                    //           <IconEdit size={14} />
                    //           <div className='ml-2 text-secondaryy'>Edit Payment</div>
                    //         </span>
                    //         {
                    //           element.lessonId
                    //             ? (<span className='warning d-flex flex-row align-items-center cursor' onClick={() => { optionsPayment(element.id, 'waive') }}>
                    //               <IconSignPlus size={14} />
                    //               <div className='ml-2 text-secondaryy'>Waive payment</div></span>)
                    //             : (<span className='warning d-flex flex-row align-items-center cursor' onClick={() => { optionsPayment(element.id, 'remove') }}>
                    //               <IconErase size={14} />
                    //               <div className='ml-2 text-secondaryy'>Remove payment</div></span>)
                    //         }
                    //       </div>
                    //     )
                    //     : (
                    //       <div className='d-flex flex-column my-2 ml-3' style={{ width: '200px' }}>
                    //         <span className='primary d-flex flex-row align-items-center' onClick={() => { editPayment(element.id) }}>
                    //           <IconEdit size={14} />
                    //           <div className='ml-2 text-secondaryy'>Edit Payment</div>
                    //         </span>
                    //         <span className='warning d-flex flex-row align-items-center cursor' onClick={() => { optionsPayment(element.id, 'remove') }}>
                    //           <IconErase size={14} />
                    //           <div className='ml-2 text-secondaryy'>Remove payment</div>
                    //         </span>
                    //       </div>
                    //     )
                    return (
                      <>
                        {/* {index === 0 && (<div className={`borderTop w-100 my-4`}/>)} */}
                        {/* // key={`item-${element.id}`}
                        // className='d-flex flex-row justify-content-between align-items-center'
                        // onMouseEnter={() => { handletHover(element, true) }}
                        // onMouseLeave={() => { handletHover(element, false) }} */}

                        <ListItem
                          showTopLine={index === 0}
                          showBottomLine={index < form.items.filter(e => !e.isWaive)?.length - 1}
                          className={`w-100 ${index === 0 ? 'mt-0' : ''}`}
                          Left={
                            <div className='d-flex flex-column'>
                              <div className={`d-flex flex-row align-items-center`}>
                                <span className='text-bold'>{
                                element?.chargeType === "educatorPenalization" ? 
                                  translate("filters.payslips.educatorPenalization") : 
                                  translate(`filters.payslips.${element.type}`)
                                }</span>
                                <span className='text-gray ml-1 h45'>{element.percentDiscount > 0 ? ' (' + (element?.percentDiscount || 0).toFixed(2) + '% Discount)' : ''}</span>
                              </div>
                              <span className='text-gray'>{element.generatedDescription ?? element.description}</span>
                            </div>
                          }
                          Right={
                            <div className={`d-flex flex-row align-items-center`}>
                              <span
                                className='text-gray text-muted mr-1'
                                style={element?.isWaive ? { textDecoration: 'line-through' } : {}}
                              >${formatNumber(element.amount)}</span>
                              {
                                data && data.payslip.status !== 'paid' && !element?.isWaive &&
                                (
                                  <DropDownNew
                                    direction='bottomLeft'
                                    hoverEnabled={false}
                                    options={options}
                                  >
                                    <div className={`hover-icon`}>
                                      <IconOptions className={`text-gray`} size={12} style={{ transform: 'rotate(90deg)' }} />
                                    </div>
                                  </DropDownNew>
                                )}
                            </div>
                          }
                        />
                      </>
                    )
                  }
                )
              }
              {<div className={`borderTop w-100 mb-4`} />}
              <div className='d-flex flex-row justify-content-between'>
                <div>
                  <h2 className='font-weight-bold m-0'>Total</h2>
                </div>
                <h2 className='font-weight-bold m-0'>$ {data && formatNumber(data.payslip.payslipProps.amount)}</h2>
              </div>
              <div className={`borderTop w-100 mt-4`} />
              {data && ['draft', 'pendingPayment'].includes(data.payslip.status) && (
                <RenderOption
                  status={'primary'}
                  IconComponent={IconEdit}
                  leftText={'Edit payslip'}
                  onClick={editPayslip}
                  showBottomLine
                  hasHover
                  className={`hover-no-border`}
                />
                // <div>
                //   <div className='d-flex flex-row justify-content-between' onClick={editPayslip}>
                //     <div className='d-flex flex-row align-items-center'>
                //       <IconEdit size={16} color='#778699' className='mr-2' />
                //       <span className='text-primary'>Edit payslip</span>
                //     </div>
                //     <IconRight size={16} color='#778699' />
                //   </div>
                //   <div className='px-7'>
                //     <div className='borderTop mt-4' />
                //   </div>
                // </div>
              )}
              {data && ['draft', 'pendingPayment'].includes(data.payslip.status) && (

                <RenderOption
                  status={'error'}
                  IconComponent={IconErase}
                  leftText={'Remove payslip'}
                  onClick={() => { optionsPayment('', 'payslip') }}
                  showBottomLine
                  hasHover
                  className={`hover-no-border`}
                />
                // <div>
                //   <div className='d-flex flex-row justify-content-between mt-3 px-7 cursor' onClick={() => { optionsPayment('', 'payslip') }}>
                //     <div className='d-flex flex-row align-items-center'>
                //       <IconErase size={16} color='#FF3E1D' className='mr-2' />
                //       <span className='text-error'>Remove payslip</span>
                //     </div>
                //     <IconRight size={16} />
                //   </div>
                //   <div className='px-7'>
                //     <div className='borderTop mt-4' />
                //   </div>
                // </div>
              )}
              {data && data.payslip.status !== 'paid' && (
                <StickyButton>
                  <div className='m-0 p-0 my-5 w-100'>
                    <Button
                      color='primary'
                      activity={loading || loadingOne}
                      className={`w-100`}
                      // className={
                      //   isEdit ? 'col-12' : 'col-9'
                      // }
                      label={data.payslip.status === 'draft' ? 'Approve Payslip' : 'Process Payslip'}
                      onClick={() => {
                        data.payslip.status === 'draft' ? onSubmit() : processPayment(data.payslip.id)
                      }}
                      style={{ borderRadius: `${isEdit ? '16px' : '16px 16px 16px 16px'}` }}
                    />
                  </div>
                </StickyButton>
              )}
              <div className='mt-6'>
                <div className='d-flex flex-row justify-content-between align-items-center mt-3'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-row'>
                      <span className='font-weight-bold h14 text-gray'>Created <span className='font-weight-light h14 text-gray'>{data ? ' at ' + moment(data.payslip.createdAt).format('MMM DD, YYYY hh:mm a') : ''}</span></span>
                    </div>
                    <div className='d-flex flex-row'>
                      <span className='font-weight-bold h14 text-gray'>Updated <span className='font-weight-light h14 text-gray'>{data ? ' at ' + moment(data.payslip.updatedAt).format('MMM DD, YYYY hh:mm a') : ''}</span></span>
                    </div>
                  </div>
                  <div className={`hover-icon`}>
                    <IconRight size={16} className={`text-gray`} />
                  </div>
                </div>
              </div>
              <div className='mb-6' />
            </div>)
            : (
              <div className='mt-5'>
                <Skeleton count={10} height={40} />
              </div>
            )
        }
      </ModalLayout>
      <EditPaymentSlip refetchList={(e) => {
        if (onChange) onChange()
        refetch(e)
      }} refetch={(e) => {
        if (onChange) onChange()
        mePayslip(e)
      }} />
    </>
  )
}

export default ViewPayslip
