import React from 'react'
import {
  IconClose,
  IconArrowRight,
  IconIllustratedLesson,
  ListItem,
  IconSignMinus,
  IconSignPlus,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'

const DraftHeaderModal = (props) => {
  const { dataLesson, toggle } = props
  const separateCamelCase = (str) => {
    if (str) str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase() })
    return str
  }
  const topic = dataLesson.groupTopic || dataLesson.individualTopic || dataLesson.planTopic || {}
  const service = dataLesson.service

  return (
    <>
      <div className='w-100 d-flex justify-content-center mt-1'>
        <IconSignMinus size={20} className='ml-auto' />
        <IconClose className='ml-auto' size={20} onClick={toggle} />
      </div>
      <div className='w-100 pr-3 pl-0 py-3'>
        <IconIllustratedLesson size={80} />
        <div className='d-flex mt-1 justify-content-around flex-column h-auto'>
          <h2 className='my-1'>
            <b>{dataLesson && service && `${dataLesson.title}`}</b>
          </h2>
          <h4 className='my-1'>
            {
              (dataLesson && dataLesson.startDate && dataLesson.endDate) &&
              `${moment(dataLesson.startDate).format('hh:mm a')} to ${moment(dataLesson.endDate).format('hh:mm a')} | ${separateCamelCase(moment(dataLesson.startDate).format('MMM'))} ${moment(dataLesson.startDate).format('DD, YYYY')}`
            }
          </h4>
          <h5 className='h45 my-1 pr-2'>
            {
              (dataLesson && dataLesson.__typename && service) &&
              `${separateCamelCase(service.name)} | ${separateCamelCase(topic?.name)} | ${separateCamelCase(dataLesson?.location?.type || 'No defined')} | ${separateCamelCase(dataLesson.__typename?.replace('Lesson', ''))}`
            }
          </h5>
          <div className='d-flex flex-row m-0 p-0'>
          </div>
        </div>
      </div>
    </>
  )
}

const DraftBodyModal = (props) => {
  const {
    dataLesson,
    createdDate,
    updateDate,
  } = props

  const { translate } = useTranslation()
  const dispatch = useDispatch()

  return (
    <>
      {/* <div className='d-flex flex-row justify-content-between mt-2 mb-6'> */}
      <ListItem
        showTopLine
        showBottomLine
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.cancelLesson',
            data: {
              isOpen: true,
              lessonId: dataLesson?.id || '',
              isOrientation: false,
              dataLesson
            }
          })
        }}
        className='py-4'
        Left={
          <div className='d-flex flex-row'>
            <IconSignPlus color='#696CFF' className='align-self-center mr-2' />
            <span className='align-self-center text-primary'>
              {translate('modal.infolesson.removeReserve')}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
      {/* </div> */}

      <ListItem
        showBottomLine={false}
        showTopLine={false}
        className='py-4'
        Left={
          <div className='d-flex flex-column'>
            <span className='h5 align-self-left text-gray my-2'>
              <b>{translate('modal.infolesson.created')}</b> {translate('modal.infolesson.at')} {`
                                ${createdDate &&
                moment(createdDate).format('MMM DD, YYYY hh:mma')
                }
                            `}
            </span>
            <span className='h5 align-self-left text-gray my-2'>
              <b>{translate('modal.infolesson.update')}</b> {translate('modal.infolesson.at')} {`
                                ${updateDate &&
                moment(updateDate).format('MMM DD, YYYY hh:mma')
                }
                            `}
            </span>
          </div>
        }
        Right={<IconArrowRight />}
      />
    </>
  )
}

export {
  DraftHeaderModal,
  DraftBodyModal
}
