import React, { useState, useEffect, useMemo, Fragment } from 'react'
import {
  Modal,
  Button,
  IconClose,
  useWindowDimensions,
  StickyButton,
  Form
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import Educator from '../NewLesson/Educator.js'
import LessonDetails from '../../Components/LessonDetails'
import PricingAndPayment from '../NewLesson/PricingAndPayment.js'
import ScheduleDetails from '../NewLesson/ScheduleDetails'
import Student from '../NewLesson/Student.js'
import useTranslation from '../../../../i18n/useTranslation'
import {
  parseDataToModalNewLesson,
  parseToMutationEdit,
  parseOcurrencesToCalendar,
  callBackForConflict,
  parseOcurrencesToMutationEditLesson,
  parseOcurrencesToSolve,
  closeCalendarConflict
} from '../utils.js'
import EditLessonFromAdmin from '../../../../Model/Lessons/EditLessonFromAdmin'
import EditMeetingFromAdmin from '../../../../Model/Lessons/EditMeetingFromAdmin'
import SolveOcurrences from '../../../../Model/Lessons/SolveOcurrences'
import useCheckPaymentItemForLesson from './useCheckPaymentItemForLesson'
import {showAlert} from '../../../../common/Alert/util'
import Counselor from '../NewLesson/Counselor'
import moment from 'moment-timezone'
import useScheduledMeetingModal from '../ScheduledMeeting/useScheduledMeeting' 
import ScheduledMeetingModal from '../ScheduledMeeting'
//la clase de las 6 pm mier se le edito para colocarla a las 5 pm (se grabo y se crearon reservaciones)

const EditLesson = (props) => {
  const {refetchCalendar = () => {}} = props
  const [hours, setHours] = useState([])
  const [openSchedul, setOpenSchedul] = useState(false) 


  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.lesson.editLessonModal)
  const { isOpen, dataLesson, editPending, refetch } = reduxData
  const { isOpen: isOpenInfoModal } = useSelector((state) => state.modal.plan.infoLessonModal)
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'lesson.editLessonModal',
      data: {
        ...reduxData,
        isOpen: !isOpen
      }
    })
  }
  const [form, setForm] = useState({})

  //checkea payment items de estudiantes para eliminar los pagos 
  // de aquellos usuarios que hayan sido sacados de la lección 
  //(edición de lección individual o grupal no perteneciente a un plan)
  const checkPaymentItems = useCheckPaymentItemForLesson(dataLesson.id, dataLesson?.recurringId || null)

  const { submit: solveOcurrencesMutation, loading: loadSolve } = SolveOcurrences(
    (result) => {
      const ocurrenceResult = result?.data?.solveOcurrences 
      if (ocurrenceResult?.hasOccurrences) {
        const ocu = ocurrenceResult?.occurrencesList
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.scheduledConflict',
          data: {
            ...parseOcurrencesToCalendar(
              ocu,
              (dataCalendar) => {
                callBackForConflict(() => {
                  // mutacion para resolver recurrencias
                  let parse = parseOcurrencesToSolve(
                    dataCalendar,
                    { lessonInput: parseToMutationEdit(form) }
                  )
                  solveOcurrencesMutation(parse)
                }, dataCalendar, dispatch)
              }
            ),
            isOpen: true
          }
        })
      } else if (ocurrenceResult?.errorKey) {
        //alerta de error
        showAlert({
          text: ocurrenceResult?.message,
          status: 'error'
        }, dispatch)
      } else {
        //vuelve a ejecutar al mutación editLesson pero sin conflictos (y sin aplicar los cambios de fecha y hora)
        editLesson({
          lessonInput: parseToMutationEdit(form, true),
          lessonId: dataLesson?.id || '',
          noDateChanges: true
        })
      }
    },
    (error) => { console.error(error) }
  )
  const { submit: editLesson, loading: loadEdit } = EditLessonFromAdmin(
    (result) => {
      if (result?.data?.editLessonFromAdmin?.hasOccurrences) {
        const ocu = result?.data?.editLessonFromAdmin?.occurrencesList
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.scheduledConflict',
          data: {
            ...parseOcurrencesToCalendar(
              ocu,
              (!ocu[0]?.recurringId) || form?.current
                ? (dataCalendar) => {
                  callBackForConflict(() => {
                    // dataCalendar.ocurrences siempre debe venir cargado con solo UNA lección
                    const solveLesson = dataCalendar?.ocurrences[0]
                    if (['newBlock', 'force', 'setPending'].includes(solveLesson.resolve)) {
                      editLesson(
                        parseOcurrencesToMutationEditLesson(
                          dataCalendar,
                          {
                            lessonId: dataLesson?.id || '',
                            lessonInput:
                              solveLesson.resolve === 'newBlock'
                                ? parseToMutationEdit(form, true)
                                : solveLesson.resolve === 'force'
                                  ? { // scheduled anyway
                                    ...parseToMutationEdit(form, true),
                                    ignoreEducatorUnavailability: true
                                  }
                                  : { // Dejar pendiente
                                    ...parseToMutationEdit(form, true),
                                    setToPending: true,
                                    //lessonsPending: 1
                                  }
                          }
                        )
                      )
                    } else {
                      // ignore
                    }
                  }, dataCalendar, dispatch)
                }
                : (dataCalendar) => {
                  callBackForConflict(() => {
                    // mutacion para resolver recurrencias
                    let parse = parseOcurrencesToSolve(
                      dataCalendar,
                      {
                        lessonId: dataLesson?.id || '',
                        lessonInput: parseToMutationEdit(form)
                      }
                    )
                    solveOcurrencesMutation(parse)
                  }, dataCalendar, dispatch)
                }
            ),
            isOpen: true
          }
        })
      } else if (result?.data?.editLessonFromAdmin?.errorKey) {
        //alerta de error
        showAlert({
          text: result?.data?.editLessonFromAdmin?.message,
          status: 'error'
        }, dispatch)
      } else {
        const lesson = result?.data?.editLessonFromAdmin?.newLessons[0]
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'plan.infoLessonModal',
          data: {
            isOpen: lesson.status === 'pending' ? false : isOpenInfoModal,
            dataLesson: lesson
          }
        })

        closeCalendarConflict(dispatch)
        //Ejecuta proceso que verifica que no hayan payments
        // items asignados a estudiantes que no están en la lección (solo para grupales)
        checkPaymentItems()
        if(refetchCalendar) refetchCalendar()
        toggle()
      }
    },
    (error) => {
      console.error(error)
    }
  )

  const {parseFrames} = useScheduledMeetingModal()
  const { submit: editMeeting, loading: loadEditMeet } = EditMeetingFromAdmin((response) =>{
    if (!response.data.editMeetingFromAdmin.success) {
      const orientationHours = parseFrames(
        response.data.editMeetingFromAdmin.blocks,
        response.data.editMeetingFromAdmin.studentTimeZone, 
        response.data.editMeetingFromAdmin.educatorTimeZone
      )
      setHours(orientationHours)
      setOpenSchedul(true)
    } else {
      const lesson = JSON.parse(response.data.editMeetingFromAdmin.lesson)
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.orientationLessonModal',
        data: {
          isOpen: true,
          dataLesson: lesson
        }
      })
      refetch()
      setOpenSchedul(false)
      if(refetchCalendar) refetchCalendar()
      toggle()
    }
  })

  const noDateChanges = useMemo(() => {
    if (form?.date && form?.timeStart && form.timeEnd && dataLesson?.startDate) {
      const lessonDate = moment(dataLesson?.startDate).format("YYYY-MM-DD")
      const lessonStart = moment(dataLesson?.startDate).format("HH:mm")
      const lessonEnd = moment(dataLesson?.startDate).format("HH:mm")
      if (form.date !== lessonDate || form?.timeStart !== lessonStart || form.timeEnd !== lessonEnd) {
        return true
      } else return false
    }
    return null
    // eslint-disable-next-line
  }, [form?.date, form?.timeStart, form.timeEnd])
  useEffect(() => {
    if (dataLesson) {
      setForm({
        ...parseDataToModalNewLesson(dataLesson),
        educator: dataLesson?.educator || {},
        //student: dataLesson?.student || dataLesson?.studentPlan || dataLesson?.studentGroup || {},
        service: dataLesson?.service || {},
        topic: dataLesson?.individualTopic || dataLesson?.planTopic || dataLesson?.groupTopic || {},
        locationLesson: dataLesson?.location?.type || null,
        locationLessonLink: dataLesson?.location?.linkStudent || dataLesson?.location?.linkEducator || null,
        isPlanLesson: dataLesson?.__typename === 'PlanLesson',
        current: true,
        setPending: editPending || false,
        recurringId: dataLesson?.recurringId,
        applyChangeDate: true,
        id: dataLesson.id,
        isMeeting: dataLesson?.isMeeting || dataLesson?.service?.name === 'Orientation' ? true : false,
        planId: dataLesson.planId
      })
    }
  // eslint-disable-next-line
  }, [dataLesson, dispatch, isOpen])
  return (
    <Fragment>
      <Modal
        buttonLabel={null}
        className={`modal-colored modal-${
              breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-half'
        }  fixed-head-modal scheduled-modal`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header p-0 m-0'>
            <div className='d-flex justify-content-between w-100 p-7'>
              <h2 className='font-weight-bold'>{translate(dataLesson?.isMeeting ? 'modal.editlesson.titleMeeting' : dataLesson?.service?.name === 'Orientation' ? 'modal.editlesson.titleOrientation' : 'modal.editlesson.title')}</h2>
              <IconClose onClick={toggle} />
            </div>
          </div>
        }
      >
        <div className='px-6 px-sm-7 pt-6 m-0'>
          <Form value={form} onChange={setForm}>
            <div className='m-0 p-0'>
              <LessonDetails form={form} setForm={setForm} typeAndlocation={false} isEdit />
              <div className='borderTop my-5' />
              <ScheduleDetails setForm={setForm} form={form} isEdit editPending={editPending} />
              <div className='borderTop my-5' />
              <Student setForm={setForm} form={form} isEdit={dataLesson?.isMeeting ? true : false} />
              <div className='borderTop my-5' />
              {
                form?.isMeeting
                  ?
                    <Counselor setForm={setForm} form={form} isEdit />
                  :
                    <Educator setForm={setForm} form={form} isEdit />
              }
              {
                !form?.isMeeting ?(
                  <>
                    <div className='borderTop my-5' />
                    <PricingAndPayment setForm={setForm} form={form} isEdit />
                    <div className='borderTop my-5' />
                  </>
                ) : <div className='borderTop my-5' />
              }
              <StickyButton>
                <div className='m-0 p-0 row'>
                  <Button
                    color='primary'
                    activity={loadEdit || loadSolve || loadEditMeet}
                    className='col-12 aling-text-left'
                    label={translate('modal.editlesson.savelesson')}
                    onClick={() => {
                      if (!dataLesson?.isMeeting) {
                        editLesson({
                          lessonInput: parseToMutationEdit(form, true),
                          lessonId: dataLesson?.id || ''
                        })
                      } else {
                        editMeeting({
                          lessonInput: parseToMutationEdit(form, true),
                          lessonId: dataLesson?.id || '',
                          noDateChanges
                        })
                      }
                    }}
                    style={{ borderRadius: '16px' }}
                  />
                </div>
              </StickyButton>
            </div>
          </Form>
        </div>
      </Modal>
      {openSchedul && <ScheduledMeetingModal
                        hours={hours}
                        open={openSchedul}
                        toggle={setOpenSchedul}
                        onClick={() => {
                          editMeeting({
                            lessonInput: parseToMutationEdit(form),
                            lessonId: dataLesson?.id || '',
                            noDateChanges
                          })
                        }}
                        form={form}
                        setForm={setForm}
                        loading={loadEditMeet}
                      />}
    </Fragment>

  )
}

export default EditLesson
