// import { gql, useQuery } from '@apollo/client'
// import validate from './validation'

// export default (vars) => {
//   const { data, error, loading } = useQuery(
//     gql`query ($invoiceId: String!) {
//         getInvoiceLink(invoiceId: $invoiceId)
//     }`,
//     {
//       variables: vars
//     }
//   )
//   const errors = validate(vars)
//   if (errors && Object.keys(errors).length) {
//     return { loading: false, error: errors, data: '' }
//   }
//   return { loading, data: data ? data.getInvoiceLink : '', error }
// }

import { gql, useLazyQuery } from '@apollo/client'

const useGetInvoiceLink = () => {
  return useLazyQuery(
    gql`query ($invoiceId: String!) {
        getInvoiceLink(invoiceId: $invoiceId)
    }`,
    {
      fetchPolicy: 'network-only'
    }
  )
}

export default useGetInvoiceLink
