import {useMutation, gql} from '@apollo/client'

const useUploadImageBase64Mutation = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $bucket: String!
                $file: String!
            ) {
                uploadImageBase64 (
                    bucket: $bucket
                    file: $file
                )
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError
        }
    )
}

export default useUploadImageBase64Mutation  