import React from 'react'
import {
  IconLesson,
  ListItem,
  IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import PropTypes from 'prop-types'
import useTranslation from '../../../../../i18n/useTranslation'
import AddPayment from '../../newPlan/AddPayment'

const propTypes = {
  lessonsToText: PropTypes.string,
  paymentsItems: PropTypes.array,
  action: PropTypes.string,
  noLesson: PropTypes.bool
}
const defaultProps = {
  lessonsToText: '0 lessons to cancel',
  paymentsItems: [],
  action: 'cancel',
  noLesson: false
}

const BreakdownAndPayment = (props) => {
  const { lessonsToText, paymentsItems, action, setCuotas, noLesson } = props
  const { translate } = useTranslation()
  const totalAmount = paymentsItems.reduce((total, pay) => {
    return total + pay.amount
  }, 0)
  const paymentsNames = {
    'Payment registration': translate('modal.newplan.registrationfee'),
    'Payment exam': translate('modal.newplan.examregistrationfee'),
    'Payment pro': 'Zinkerz Pro'
  }

  const pendingPaymentsArray = paymentsItems.filter((pay) => pay.status === 'pendingPayment')
  const paidPaymentsArray = paymentsItems.filter((pay) => pay.status !== 'pendingPayment')
  const reverseArr = (input) => {
    let ret = [];
    for(let i = input.length-1; i >= 0; i--) {
        ret.push(input[i]);
    }
    return ret;
  }

  return (
    <>
      {
        !noLesson && (
          <>
            <label className='font-weight-bold text-title'>{`${translate('plans.modal.cancelplan.lessonbreakdown')}`}: </label>
            <ListItem
              showBottomLine={false}
              showTopLine={false}
              className='w-100 mb-4'
              Left={<span><IconLesson /> {lessonsToText}</span>}
              Right={<IconArrowRight />}
            />
          </>
        )
      }
      
      <div className='borderTop my-4' />
      <label className='mb-4 font-weight-bold text-title'>{`${translate('program.plan.approvalModal.paymentSchedule')}`}: </label>
      <label className={'w-100 text-title'}>{translate('program.plan.approvalModal.pendingPayment')}</label>
      {pendingPaymentsArray?.length > 0 &&
          reverseArr(pendingPaymentsArray).map((pay, index) => {
            if (pay.status === 'pendingPayment') {
              return (
                <ListItem
                  showBottomLine={false}
                  showTopLine={false}
                  className='w-100 py-1'
                  Left={
                  <span className={`text-info`}>{
                    pay.type === 'otherFixed'
                      ? `${paymentsNames[pay.description]}`
                      : `${moment(pay.date).utc().format('MMMM YYYY')}`

                  } <label className='text-muted'>(Remaining)</label>
                  </span>}
                  Right={
                    action === 'pause' || action === 'cancel'
                      ? <span className={`text-info`}>
                        {`${pay.amount.toFixed(2)}`}
                        </span>
                      : <AddPayment
                        value={pay.amount}
                        customPayment={false}
                        deleteCuota={() => {
                          setCuotas(paymentsItems.reduce((arrayAccu, payAux, indexReduce) => {
                            if (indexReduce !== index) arrayAccu.push(payAux)
                            return arrayAccu
                          }, []))
                        }}
                        setCuotas={(newAmount, date) => {
                          setCuotas(paymentsItems.reduce((arrayAccu, payAux, indexReduce) => {
                            if (indexReduce === index) {
                              if (date === null) {
                                arrayAccu.push({
                                  ...payAux,
                                  amount: newAmount,
                                  percentDiscount: payAux.percentDiscount,
                                  amountWithoutDiscount: newAmount * (100 / (100 - payAux.percentDiscount))
                                })
                              } else {
                                arrayAccu.push({
                                  ...payAux,
                                  amount: newAmount,
                                  date: moment(date).toISOString(),
                                  percentDiscount: payAux.percentDiscount,
                                  amountWithoutDiscount: newAmount * (100 / (100 - payAux.percentDiscount))
                                })
                              }
                            } else arrayAccu.push(payAux)
                            return arrayAccu
                          }, []))
                        }}
                        maxValue={totalAmount}
                      >
                        {`${pay.amount.toFixed(2)}`} <IconArrowRight />
                      </AddPayment>
                  }
                />
              )
            } else {
              return null
            }
          })}
      <label className={'w-100 text-title'}>{translate('program.plan.approvalModal.paidPayment')}</label>
      {paidPaymentsArray.length > 0 &&
          reverseArr(paidPaymentsArray).filter((pay) => (pay.status !== 'pendingPayment')).map((pay, index) => {
            return (
              <ListItem
                showBottomLine={false}
                showTopLine={false}
                className='w-100 py-1'
                Left={<span className={`text-info`} style={{ textDecoration: 'line-through' }}>{`${moment(pay.date).utc().format('MMMM YYYY')}`} <label style={{ textDecoration: 'line-through' }} className='text-muted'>
                  {`(${paymentsItems.filter((pay) => (pay.status === 'pendingPayment')).length + index + 1} of out ${paymentsItems.length})`}
                </label>
                </span>}
                Right={<><span className={`text-info`} style={{ textDecoration: 'line-through' }}>{`${pay.amount.toFixed(2)}`}</span></>}
              />
            )
          })}
    </>
  )
}

BreakdownAndPayment.propTypes = propTypes
BreakdownAndPayment.defaultProps = defaultProps

export default BreakdownAndPayment
