import React, { useEffect, useState } from 'react'
import useModalState from '../../../../../../utils/useModalState'
import { gql, useLazyQuery } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import useTranslation from '../../../../../../i18n/useTranslation'
import {
  Avatar,
  IconSearch,
  Input,
  ListOption,
  StickyButton,
  useWindowDimensions,
  ToolTipInfo, IconLesson,
  ModalLayout
} from '@Knowledge-OTP/znk-ui-components'
import { nanoid } from 'nanoid'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

const SelectEducator = (props) => {
  const { isOpen, toggle, modalData } = useModalState('plan.changeTopicEducator')
  const { topic, setEducator, currentEducators, type, serviceId, prospectiveParts } = modalData
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const [selectedEducators, setSelectedEducators] = useState([])
  const [educatorSearch, setEducatorSearch] = useState(null)
  const [getEducators, { data, loading }] = useLazyQuery(
    gql`query ($topicId: ID!, $serviceId: ID!,  $teachingTypes: [TeachingType!], $prospectiveParts: [PartCapInfo!]!) {
        allEducatorByTopic(topicId: $topicId, teachingTypes: $teachingTypes, serviceId: $serviceId ) {
            id
            emails {
                address
                verified
            }
            countryId
            firstName
            lastName
            timezone
            educatorInfo {
                id
                defaultRate
                services {
                    serviceId
                    name
                    defaultRate
                    topics {
                        topicId
                        teachingTypes
                        name
                        individualRate
                        groupRate
                        isWaiveIndividual
                        isWaiveGroup
                    }
                }
                isCapped (
                    parts: $prospectiveParts
                )
                backupLink
            }
        }
    }`
  )
  useEffect(() => {
    if (topic && topic.id && serviceId && type && prospectiveParts) {
      getEducators({
        variables: {
          topicId: topic.id,
          serviceId,
          teachingTypes: [type],
          prospectiveParts
        }
      })
    }
  }, [topic, type, prospectiveParts, getEducators, serviceId])
  const educators = loading || !data || isEmpty(data) ? [] : data.allEducatorByTopic
  useEffect(() => {
    if (currentEducators && educators.length) {
      setSelectedEducators(currentEducators)
    }
  }, [currentEducators, educators])
  const selectedIds = selectedEducators.map(edu => edu.educatorUserId)
  const toggleEducators = (eduToChange) => {
    const topicEducator = eduToChange.educatorInfo.services.find(ele => ele.serviceId === serviceId).topics.filter(e => e.topicId === topic.id)[0]
    if (selectedIds.includes(eduToChange.id)) {
      setSelectedEducators(pre => pre.filter(edu => edu.educatorUserId !== eduToChange.id))
    } else {
      const newArrayEducators = selectedEducators.concat([{
        educatorUserId: eduToChange.id,
        firstName: eduToChange.firstName,
        lastName: eduToChange.lastName,
        rate: topicEducator?.individualRate,
        isManualRate: false,
        topicId: topic.id,
        teachingType: 'individual',
        isLockEducator: true,
        topic,
        isWaiveIndividual: topicEducator?.isWaiveIndividual
      }])
      setSelectedEducators(newArrayEducators)
    }
  }
  const submitEducators = () => {
    setEducator(selectedEducators)
    toggle()
  }
  return (
    <ModalLayout
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'} h-auto`}
      isOpen={isOpen}
      toggle={toggle}
      topHead={
        <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('modal.newplan.selecteducator.title')}</span>
      }
    >
      <div className='p-0 mt-0 mb-4 mx-3'>
        <Input
          className='w-100 input-xs p-0 mb-3'
          placeholder={`${translate('modal.newplan.selecteducator.search')} ${topic ? topic.name : ''}`}
          addOnComponent={<IconSearch size={16} />}
          type='addon'
          onChange={(e) => {
            setEducatorSearch(e.target.value)
          }}
        />
        <div
          className='m-0 py-2 px-0' style={{
            maxHeight: '300px',
            overflow: 'hidden scroll'
          }}
        >
          <TabContent activeTab={loading ? `loading` : `show`}>
            <TabPane tabId={`loading`}>
              <Skeleton style={{ borderRadius: '12px' }} count={5} height={44} className={`w-100 mb-2`} />
            </TabPane>
            <TabPane tabId={`show`}>
              {
                educators
                  .filter((edu) => educatorSearch !== null && educatorSearch !== '' ? new RegExp(educatorSearch, 'i').exec(`${edu.firstName} ${edu.lastName}`) : true)
                  .map((edu) => {
                    const tooltipId = nanoid()
                    return (
                      <ListOption
                        key={edu.id}
                        className='mb-2'
                        id='x-0'
                        label={null}
                        selectedInput={selectedIds.includes(edu.id)}
                        childClassName='w-100'
                        icon={
                          <div className='d-flex align-items-center text-muted'>
                            <Avatar
                              name={`${edu.firstName} ${edu.lastName}`}
                              named={false}
                              uri={null}
                              size='avatar-small avatar-span-white'
                            />
                            <div className='d-flex flex-row align-items-center justify-content-between w-100'>
                              <span className='ml-2 h4'>
                                {`${edu.firstName} ${edu.lastName}`}
                              </span>
                              {parseInt(edu.educatorInfo.isCapped.split('/')[0]) > 0 && (
                                <ToolTipInfo
                                  innerElement={
                                    <span className='mr-2 text-white'>
                                      {`${edu.educatorInfo.isCapped.split('/')[0]} ${translate('modal.newplan.selecteducator.outof')} ${edu.educatorInfo.isCapped.split('/')[1]} ${translate('modal.newplan.selecteducator.weeks')}`}
                                    </span>
                                  }
                                  place={'top'}
                                  tooltipId={tooltipId}
                                >
                                  <IconLesson className={`mr-2`} size={16} />
                                </ToolTipInfo>
                              )}
                            </div>
                          </div>
                        }
                        onClick={() => {
                          toggleEducators(edu)
                        }}
                      />
                    )
                  })
              }
            </TabPane>
          </TabContent>
        </div>
        <StickyButton
          color='#696CFF'
          condition={true}
          disabled={!(selectedEducators.length > 0)}
          label={translate('modal.newplan.selecteducator.title')}
          onClickButtonFunction={submitEducators}
        />
      </div>
    </ModalLayout>

  )
}

export default SelectEducator
