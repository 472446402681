import React, { useCallback, useEffect, useState } from "react"
import { ModalLayout, Input, CheckboxOption, Button, IconErase } from "@Knowledge-OTP/znk-ui-components"
import moment from 'moment'
import DeleteCheckIn from "../Delete"
import { CheckInType } from "../../../../newPlan/Components/CheckIns"
import useEditCheckIn from "./Model/useEditCheckIn"
import useAddCheckIn from "./Model/useAddCheckIn"
import {showAlert} from '../../../../../../../common/Alert/util'
import { useDispatch } from "react-redux"
import useTranslation from "../../../../../../../i18n/useTranslation"

const EditCheckIn = (props) => {
    const { refetch = () => {}, checkin = {}, isEdit = true /**si es false agrega checkin al plan**/, planId = undefined } = props
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const [checkInData, setCheckInData] = useState({})
    useEffect(() => {
        if(checkin) setCheckInData(checkin)
    },
    // eslint-disable-next-line
    [JSON.stringify(checkin)])

    const {type = CheckInType.regular, date, id} = checkInData
    
    const [isOpen, setOpen] = useState(false)
    const toggle = () => { setOpen(!isOpen) }

    const onSuccess = (res) => {
        if(refetch) refetch(res)
        toggle()
        showAlert({text: translate('program.plan.checkIns.edit.success'), status: 'success'}, dispatch)
    }
    const onError = (err) => {
        showAlert({text: err.toString(), status: 'error'}, dispatch)
    }

    const [editCheckin, {loading}] = useEditCheckIn(onSuccess, onError)
    const [addCheckin, {loading: loadAdd}] = useAddCheckIn(onSuccess, onError)
    const onSubmit = useCallback(
        () => {
            if(isEdit) {
                editCheckin({
                    variables: {
                        checkInInput: {type, date},
                        checkInId: id
                    }
                })
            } else {
                //agrega checkin
                addCheckin({
                    variables: {
                        checkInInput: {type, date},
                        planId: planId
                    }
                })
            }
        }, 
        // eslint-disable-next-line
        [id, date, type, isEdit, planId]
    )
    return (
        <>
            <div className="m-0 p-0" onClick={toggle}>{props.children}</div>
            <ModalLayout
                className={'modal-centered'}
                contentClassName={'h-auto'}
                isOpen={isOpen}
                toggle={toggle}
                topHead={<span className='h2 font-weight-bold'>{translate(isEdit ? 'program.plan.checkIns.edit.title' : 'program.plan.checkIns.create.title')}</span>}
            >
                <div className="m-0 p-0 d-flex flex-column">
                    <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                        <Input
                            className={'w-80'}
                            type={'date'}
                            value={date ? moment(date).format('YYYY-MM-DD') : null}
                            onChange={(e) => {
                                setCheckInData({...checkInData, date: e.target.value})
                            }}
                        />
                        <DeleteCheckIn>
                            <div className="hover-icon"><IconErase size={16} className={'text-error'} /></div>
                        </DeleteCheckIn>
                    </div>

                    {
                        true &&
                        <span
                            onClick={() => {}}
                            className='d-flex flex-row h45 text-gray w-100 mt-2'>
                            <CheckboxOption
                                onChange={(check) => {
                                    setCheckInData({...checkInData, type: check ? CheckInType.scoreExpectation : CheckInType.regular})
                                }}
                                value={type === CheckInType.scoreExpectation}
                                defaultState={type === CheckInType.scoreExpectation}
                            />
                            {/* Score Expectation */}
                            {translate('program.plan.checkIns.edit.scoreExpectationCheck')}
                        </span>
                    }

                    <Button
                        activity={loading || loadAdd}
                        disabled={!type || !date}
                        className={'w-100 mt-4'}
                        color={'primary'}
                        label={translate(isEdit ? 'program.plan.checkIns.edit.submit' : 'program.plan.checkIns.create.submit')}
                        onClick={() => onSubmit()}
                    />
                </div>
            </ModalLayout>
        </>
    )
}

export default EditCheckIn