import React from 'react'
import {IconOptions} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'

const SkeletonPlanList = (props) => {
    const { className = '' } = props
    return (
        <div className={`d-flex flex-row justify-content-between align-items-center w-100 ${className}`}>
            <div className={`d-flex flex-row align-items-center`}>
                <Skeleton className='mr-2' count={1} style={{borderRadius: '12px'}} width={40} height={40} />
                <div className={`d-flex flex-column justify-content-between`}>
                    <Skeleton className={`d-flex flex-column mb-1`} count={1} width={100} height={12} />
                    <Skeleton className={`d-flex flex-column mt-1`} count={1} width={180} height={10} />
                </div>
            </div>
            <IconOptions size={16} className={`rotate-90 text-disabled h45`} />
            {/* <Skeleton count={1} style={{borderRadius: '12px'}} width={35} height={35} /> */}
        </div>
    )
}

export default SkeletonPlanList