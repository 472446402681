import React from 'react'
import {
    CheckboxOption,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import {useQuery, gql} from '@apollo/client'
import Skeleton from 'react-loading-skeleton'

const SelectStudents = (props) => {
    const {timezone, setStudentIds, studentIds, toggle, onContinue} = props 
    const {data, loading} = useQuery(
        gql`
            query ($timezone: String!) {
                allUserByTimezone (timezone: $timezone) {
                    isActive
                    firstName
                    lastName
                    id
                    roles
                    educatorInfo {
                        isActive
                    }
                }
            }
        `,
        {variables: {timezone}}
    )
    
    const users = data?.allUserByTimezone || []
    const activeUsers = users.filter(({isActive}) => isActive)
    const usersToShow = activeUsers.filter(({roles}) => roles.includes('EDUCATOR'))
    
    return (
        <div className={`d-flex flex-column`}>
            <div className={`d-flex flex-column m-2`}>
                {
                    loading ?
                        <Skeleton count={5} /> :
                        usersToShow.filter(({isActive}) => isActive).map((user) => {
                            const {firstName, lastName, id, roles,/* educatorInfo*/} = user
                            // const {isActive} = educatorInfo
                            if(!roles.includes('EDUCATOR')) return null
                            // if(!educatorInfo?.isActive && roles.includes('EDUCATOR')) return null
                            return (
                                <div className={`d-flex flex-row`}>
                                    <CheckboxOption value={studentIds.map(({id:key}) => key).includes(id)} onChange={(e) => {
                                            if( studentIds.map(({id:key}) => key).includes(id) ) setStudentIds(studentIds.reduce(
                                                (array, studentUser) => {
                                                    const {id: studentId} = studentUser
                                                    if(studentId !== id) array.push(studentUser)
                                                    return array
                                                }, []
                                                ))
                                            else setStudentIds(studentIds.concat([{firstName, lastName, id}]))
                                        }} />
                                    <span>{`${firstName} ${lastName} - ${roles.includes('EDUCATOR') ? '(Educator)' : roles.includes('STUDENT') ? '(Student)' : '(Other User)'}`}</span>
                                </div>
                            )
                        })
                }
            </div>
            <div className={'borderTop w-100'} />
            <div className={'d-flex flex-row justify-content-end mt-2'}>
                <Button
                    className={'w-auto mr-1'}
                    color={'secondary'}
                    label={'Cancelar'}
                    onClick={toggle}
                    size={'small'}
                />
                <Button
                    className={'w-auto'}
                    color={'primary'}
                    label={'Enviar'}
                    size={'small'}
                    onClick={onContinue}
                />
            </div>
        </div>
    )
}
export default SelectStudents