import React, {useMemo} from 'react'

const ProgressPlanField = (props) => {
    const {item} = props
    const progressArray = useMemo(() => {
        const tasks = item?.tasks.reduce((allTask, {steps}) => {
            return allTask.concat(
                steps.reduce((allTask2, {tasks, deleteAt}) => {
                    if(deleteAt === null || !deleteAt) return allTask2.concat(tasks)
                    return allTask2
                }, [])
            )
        }, [])
        const completedTasks = tasks?.filter(({status}) => status === 'completed')
        const allTasks = tasks?.filter(({deleteAt}) => deleteAt === null || !deleteAt)
        return [
            {
                completed: Math.floor((completedTasks.length/allTasks.length)*100),
                progressColor: 'yellow'
            }
        ]
    }, [item])
    return (
        <span>{`${progressArray[0]?.completed}%`}</span>
    )
}

export default ProgressPlanField