import React from 'react'
import { PlatformLabel } from '@Knowledge-OTP/znk-ui-components'
import {useSelector, useDispatch} from 'react-redux'
import {ACTIVE_PLATFORM , DESACTIVE_PLATFORM} from '../../../store/platform/const'
const SwitchPlatform = () => {
    const platforms = useSelector((state) => state.platform.platform)
    const dispatch = useDispatch()
    const toggle = (platform) => {
        if(platforms.includes(platform) && platforms.length === 1) return
        dispatch({
            type: platforms.includes(platform) ? DESACTIVE_PLATFORM : ACTIVE_PLATFORM,
            payload: 'program.program',
            platform
        })
    }
    return (
        <div
            className='d-flex flex-column'
            style={{position: 'absolute', left: 0, bottom: '35%'}}>
            <PlatformLabel onClick={() => {toggle('liveprep')}} label='Live prep' active={platforms.includes('liveprep')} />
            <PlatformLabel onClick={() => {toggle('counseling')}} label='Counseling' active={platforms.includes('counseling')} className='my-2' />
        </div>
    )
}

export default SwitchPlatform