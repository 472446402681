import React from 'react'
import {
    ListItem,
    IconWspAlert,
    IconErase,
    Avatar,
    DropDownNew,
    IconOptions,
    DropDownItem
} from '@Knowledge-OTP/znk-ui-components'
// import LazyAvatar from '../../../../../../common/LazyAvatar'
import useCheckInStudentModal from '../Modal/CheckInStudent/useCheckInStudentModal'
import useDismissNotification from '../Modal/DismissNotification/useDismissNotification'
import useTranslation from '../../../../../../i18n/useTranslation'

const PlanListItem = (props) => {
    const {open: openCheckInStudent, close} = useCheckInStudentModal()
    const {open: openDismissNotification, close: closeDismissNotification} = useDismissNotification()
    const {translate} = useTranslation()
    
    const {
        className = '',
        text = <>Daniel Admin</>,
        reason = 'homework',
        description = '',
        refetch = () => {},
        studentId, studentName, studentData = {}, 
        ...otherProps
    } = props
    return (
        <ListItem
            // showBottomLine
            hasHover={false}
            className={`px-4 py-3 hover-no-border ${className}`}
            classNameLeft={'w-60'}
            Left={
                <div className={`d-flex flex-row align-items-center`}>
                    <Avatar
                        name={studentName}
                        avatar={studentData?.avatar}
                        className={`mr-2`}
                        size={`avatar-medium`}
                    />
                    <div className={`d-flex flex-column w-100`}>
                        <span title={studentName} className={`text-info`}>{studentName}</span>
                        <span title={text} className={`text-gray h45 text-truncate`}>
                            {text}
                        </span>
                        {/* <span className={`text-gray h45`}>
                            {studentId}
                        </span> */}
                    </div>
                </div>
            }
            Right={
                <DropDownNew
                    direction={`bottomLeft`}
                    options={
                        [].concat(reason === 'pending' ? [] : [
                            {
                                component: 
                                    <DropDownItem 
                                        onClick={() => {
                                            openCheckInStudent(() => {
                                                close()
                                                refetch()
                                            }, studentData, reason, description, text)
                                        }} 
                                        className={'text-info align-items-center'}>
                                        <IconWspAlert size={16}/> {translate("dashboard.issueCheckIn.listItem.sendWspAlert")}
                                    </DropDownItem>
                            }
                        ]).concat(
                            [
                                {
                                    component: 
                                        <DropDownItem
                                            onClick={() => {
                                                openDismissNotification(() => {
                                                    closeDismissNotification()
                                                    refetch()
                                                }, studentData, reason)
                                            }} 
                                            className={'text-error danger-option align-items-center'}>
                                            <IconErase size={16}/> {translate("dashboard.issueCheckIn.listItem.dismissNotification")}
                                        </DropDownItem>
                                }
                            ]
                        )
                    }
                >
                    <div className='hover-icon'>
                        <IconOptions className={'rotate-90'} />
                    </div>
                </DropDownNew>
            }
            {...otherProps}
        />
    )
}

export default PlanListItem