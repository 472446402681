import React, { useMemo } from 'react'
import {
    AprovePlanItem, ToolTipInfo, IconLoad,
    IconLesson, IconStarActive, IconErase,
    IconProgram, IconOptions, DropDownNew, DropDownItem
} from '@Knowledge-OTP/znk-ui-components'
import LazyAvatar from '../../../../../../common/LazyAvatar'
import moment from 'moment'
import useBubblesModalHeader from '../../../../../../bubbles/useBubblesModalHeader'
import useTranslation from '../../../../../../i18n/useTranslation'
import useDelOnboardingSession from '../Model/useDelOnboardingSession'
import { showAlert } from '../../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'

const OnboardingStatuses = {
    //LIVEPREP
    welcome: 'welcome', //welcome page
    summary: 'summary', //summary/information page
    selecting: 'selecting', // seleccionando horarios
    confirming: 'confirming', //confirmando horarios
    scheduling: 'scheduling', //agendando lecciones
    payments: 'payments', //confirmando pagos
    confirm: 'confirm', //procesando pagos 
    profile: 'profile', //completando perfil 
    approved: 'approved', //plan aprobado (tambien se usa para counseling)
    //COUNSELING
    proposal: 'proposal',
    orientation: 'orientation',
    approving: 'approving'
}



const PlanListItem = (props) => {
    const { className, onboardingStatus, refetch = () => {}, ...plan } = props
    const {translate, replaceVariables} = useTranslation()
    const dispatch = useDispatch()
    const {
        planId, studentName, studentId, type, subtitle1, planName
    } = useMemo(
        () => {
            const sendDate = plan?.sentDate ? moment(plan?.sentDate).format('MM/DD/YYYY') : undefined
            return {
                ...plan,
                planId: plan?.idPlan || plan?.id || plan?._id || plan?.planId,
                planName: plan?.name,
                type: ['counceling'].includes(plan?.kind) ? 'counceling' : 'liveprep',
                studentId: plan?.studentUser?.studentUserId || plan?.studentUser?.id,
                studentName: `${plan?.studentUser?.firstName} ${plan?.studentUser?.lastName}`,
                subtitle1: sendDate ? 
                    replaceVariables(translate("dashboard.plansPendingApprove.listItem.sentOn"), {'DATE': sendDate}) /**`Sent on ${sendDate}`**/ : 
                    translate("dashboard.plansPendingApprove.listItem.planNotSent") //`Plan not sent to student`
            }
        }, [plan, translate, replaceVariables]
    )

    const [statusLabel, pointColor] = useMemo(
        () => {
            const baseKey = 'dashboard.plansPendingApprove.listItem.steps'
            if([OnboardingStatuses.approving].includes(onboardingStatus)) {
                return [translate(`${baseKey}.${onboardingStatus}`), 'success']
            } else if (Object.values(OnboardingStatuses).includes(onboardingStatus)) {
                return [translate(`${baseKey}.${onboardingStatus}`), 'primary']
            } else return [onboardingStatus, 'error']
        }, [onboardingStatus, translate]
    )

    const bubblesStyle = useBubblesModalHeader(type === 'liveprep' ? 'cancel' : 'scheduled', 0, 'SM-custom')
    
    
    const [delOnboardingSession, {loading: loadDeleteSession}] = useDelOnboardingSession(
        () => {
            showAlert({text: 'Success!', status: 'success'}, dispatch)
            if(refetch) refetch()
        }, 
        (err) => {
            showAlert({text: err.toString(), status: 'error'}, dispatch)
            console.error(err)
        }
    )
    // counseling-plan 
    return (
        <AprovePlanItem
            {...plan}
            className={`${className}  ${type === 'liveprep' ? 'liveprep' : 'counseling'}-plan`}
            styleTopSection={bubblesStyle}
            topClassName={`d-flex flex-column justify-content-center align-items-start px-3`}
            topSection={
                <>
                    <div className={`w-100 d-flex flex-row justify-content-between`}>
                        <ToolTipInfo
                            tooltipId={`typePlanTooltip-${plan?.id}`}
                            innerElement={
                                type === 'liveprep' ?
                                    <span>{translate("dashboard.plansPendingApprove.listItem.liveprepPlan")}</span> :
                                    <span>{translate("dashboard.plansPendingApprove.listItem.counselingPlan")}</span>
                            }
                        >
                            {
                                type === 'liveprep' ?
                                <IconLesson className={`cursor-pointer type-plan-icon text-white mr-1`} size={15} /> :
                                <IconStarActive className={`cursor-pointer type-plan-icon text-white mr-1`} size={15} />
                            }
                        </ToolTipInfo>
                        <DropDownNew
                            direction='bottomRight'
                            options={[
                                {
                                    component: <DropDownItem 
                                    onClick={() => {
                                        delOnboardingSession({variables: {planId}})
                                    }}
                                    className={`text-error danger-option d-flex flex-row align-items-center`}>
                                            <span className='d-flex flex-row align-items-center'>
                                                {
                                                    loadDeleteSession ? 
                                                        <IconLoad size={15} className={'mr-1'} /> : 
                                                        <IconErase size={15} className={'text-error mr-1'}/> 
                                                }
                                                {translate("dashboard.plansPendingApprove.listItem.deleteSession")}
                                            </span>
                                        </DropDownItem>
                                }
                            ]}
                        >
                            <div className={`hover-icon small-circle ${type === 'liveprep' ? 'primary' : 'secondary'}`}>
                                <IconOptions className={`cursor-pointer text-white rotate-90`} size={15} />
                            </div>
                        </DropDownNew>
                    </div>
                    <span className={`text-truncate w-100`} title={planName}>
                        {planName}
                    </span>
                    {/* <span>{plan?.id}</span> */}
                </>
            }
            bottomSection={
                <>
                    <div className={`mb-2 w-100 d-flex flex-row align-items-center justify-content-start text-truncate`}>
                        <div className={`d-flex flex-row justify-content-center p-0 m-0 w-20`}>
                            <LazyAvatar userId={studentId} size={`avatar-small`} name={studentName} />
                        </div>
                        <span className={`w-80 ml-1 text-truncate`}>{studentName}</span>
                    </div>

                    <div className={`mb-2 w-100 d-flex flex-row align-items-center justify-content-start text-truncate`}>
                        <div className={`d-flex flex-row justify-content-center p-0 m-0 w-20`}>
                            <IconProgram size={16} className={`text-gray mx-1 w-100`} />
                        </div>
                        <span className={`h45 text-gray mb-0 w-80 text-truncate`} title={subtitle1}>{subtitle1}</span>
                    </div>

                    <div className={`mb-2 w-100 d-flex flex-row align-items-center justify-content-start text-truncate`}>
                        <div className={`d-flex flex-row justify-content-center m-0 p-0 w-20`}>
                            <span className={`mx-0 mr-2 mx-xl-0 ml-xl-2 ml-2 badge badge-pill point badge-${pointColor}`}>&nbsp;</span>
                        </div>
                        <span className={`h45 text-gray mb-0 w-80 text-truncate`} title={statusLabel}>{statusLabel}</span>
                    </div>
                </>
            }
        />
    )
}

export default PlanListItem