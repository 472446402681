import React, { useState, useEffect } from 'react'
import { FileItem, IconArrowRight } from '@Knowledge-OTP/znk-ui-components'
import useGetUserDocsAndExams from '../../Model/useGetUserDocsAndExams'
import taskTypes from '../../Utils/taskTypes'
import NoDocsComponent from './NoDocs'

const DocumentsTab = (props) => {
  // eslint-disable-next-line
  const { college, planId } = props
  // eslint-disable-next-line
  const [docsAndExamsData, setDocsAndExamsData] = useState()
  // eslint-disable-next-line
  const  {getDocsAndExams, loading, data, refetch }  = useGetUserDocsAndExams()

useEffect(() => {
if(planId){
  getDocsAndExams({
      planId
  })
}
  // eslint-disable-next-line
}, [])

  return docsAndExamsData?.length > 0 ? (
    docsAndExamsData
      .filter(
        (x) =>
          (x.type === taskTypes.uploadFile && x.showInDocs) ||
          x.type === taskTypes.scoreCollection,
      )
      // eslint-disable-next-line
      .map((x) => {
        if (x.type === taskTypes.uploadFile) {
          return (
            /* eslint-disable */
            <div className='d-flex justify-content-between mb-5'>
              <a href={x?.resolveTask?.uploadFiles[0]?.url}
                target="_blank">
                <div
                  className='d-flex flex-row'>
                  <FileItem
                    lineWidth='d-flex flex-column w-100 mt-6 mb-2'
                    fileText={
                      <>
                        <hr
                          style={{
                            display: 'block',
                            margin: 'auto',
                            overflow: 'hidden',
                            borderWidth: '3px',
                            backgroundColor: '#aacbff',
                            width: '60%',
                          }}
                        />
                        <hr
                          style={{
                            display: 'block',
                            margin: 'auto',
                            overflow: 'hidden',
                            borderWidth: '3px',
                            backgroundColor: '#aacbff',
                            width: '60%',
                          }}
                        />
                        <hr
                          style={{
                            display: 'block',
                            margin: 'auto',
                            overflow: 'hidden',
                            borderWidth: '3px',
                            backgroundColor: '#aacbff',
                            width: '40%',
                          }}
                        />
                      </>
                    }
                    className='align-items-center'
                    color='#247cff'
                    size='small-plus'
                  />
                  <div className='d-flex flex-column mx-4 my-auto'>

                    <div
                      className='d-flex flex-column ml-4 my-auto'
                    >
                      <span>{x?.name}</span>
                      <span
                        className='h6 text-truncate'
                        style={{
                          color: '#a8b2be',
                          maxWidth: '40vh',
                        }}
                      >
                        {x?.resolveTask?.uploadFiles[0]?.url}
                      </span>
                    </div>
                  </div>
                  <div className='d-flex align-self-center justify-content-end mr-4'>
                    <IconArrowRight size={12} className='text-gray' />
                  </div>
                </div>
              </a>
            </div>
            /* eslint-enable */
          )
        } else if (x.type === taskTypes.scoreCollection) {
          return (
            <div className='d-flex justify-content-between mb-5'>
              <div
                className='d-flex flex-row'
              // onClick={
              //   () =>
              //   // openTask(() => { }, x)
              // }
              >
                <FileItem
                  lineWidth='d-flex flex-column w-100 mt-6 mb-2'
                  fileText={
                    <>
                      <hr
                        style={{
                          display: 'block',
                          margin: 'auto',
                          overflow: 'hidden',
                          borderWidth: '3px',
                          backgroundColor: '#aacbff',
                          width: '60%',
                        }}
                      />
                      <hr
                        style={{
                          display: 'block',
                          margin: 'auto',
                          overflow: 'hidden',
                          borderWidth: '3px',
                          backgroundColor: '#aacbff',
                          width: '60%',
                        }}
                      />
                      <hr
                        style={{
                          display: 'block',
                          margin: 'auto',
                          overflow: 'hidden',
                          borderWidth: '3px',
                          backgroundColor: '#aacbff',
                          width: '40%',
                        }}
                      />
                    </>
                  }
                  className='align-items-center'
                  color='#247cff'
                  size='small-plus'
                />
                <div className='d-flex flex-column mx-4 my-auto'>
                  <div className='d-flex flex-column ml-4 my-auto'>
                    <span>{x?.name}</span>
                    <span
                      className='h6 text-truncate'
                      style={{
                        color: '#a8b2be',
                        maxWidth: '40vh',
                      }}
                    >
                      {x?.resolveTask?.uploadFiles[0]?.url}
                    </span>
                  </div>
                </div>
                <div className='d-flex align-self-center justify-content-end mr-4'>
                  <IconArrowRight size={12} className='text-gray' />
                </div>
              </div>
            </div>
          )
        }
      },
        /* eslint-enable */
      )
  ) : (
    <NoDocsComponent />
  )
}

export default DocumentsTab
