import {useSelector, useDispatch} from 'react-redux'
import validate from 'validate.js'

const constraints = {
    planname: {
        presence: true
    },
    idservice: {
        presence: true
    },
    typePlanPricing: {
        presence: true
    },
    lpw: {
        presence: true
    },
    pricingRate: {
        presence: true
    }
}
const useCreatePlanModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.createPlanModal)
    const {isOpen} = reduxData

    const setOpen = (open, onChange = () => {}, plan = null) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.createPlanModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                plan
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, plan = null) => {setOpen(true, onChange, plan)},
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)},
        validation: (form) => {
            // verifica si el plan tiene al menos una parte
            //y esta parte posee startdate y enddate
            let topics = []
            let parts = Object.entries(form?.lpw)
            for(let p=0; p < parts.length; p++){
                let [, part] = parts[p]
                let topicFromPart = []
                if(
                    !(part?.partname?.length > 0) || 
                    !part?.startdate
                ) return true
                else if (
                    form?.typePlan === 'date' && !part?.enddate
                ) return true
                let topicsIds = Object.keys(part).reduce((topics, key) => {
                    if(!["partname", "startdate", "enddate"].includes(key)) topics.push(key)
                    return topics
                }, [])
                for(let t=0; t < topicsIds.length; t++){
                    if(part[topicsIds[t]].value > 0) topicFromPart.push(topicsIds[t])
                }
                if(topicFromPart.length === 0) return true //no hay tópicos seleccionados para esta parte
                topics = topics.concat(topicFromPart)
            }

            if(!(form.pricingRate >= 0)) return true
            
            return validate(form, {...constraints})
        }
    }
}

export default useCreatePlanModal