import {useSelector, useDispatch} from 'react-redux'

const useCancelPlanProgressModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.plan.viewPlanProgress)
    const {isOpen} = reduxData
    const setOpen = (isOpen = false, others = {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.viewPlanProgress',
            data: {
                ...reduxData,
                isOpen,
                ...others
            }
        })
    }
    return {
        open: (plan = {}, refetch = () => {}, noLessonProgress = false) => {
            //recibe plan en formato estandar y lo parsea
            setOpen(true, {
                dataPlan: {
                    planId: plan.id,
                    kind: plan.kind,
                    paymentData: {
                        typePlanPricing: plan?.typePlanPricing,
                        pricingRate: plan?.pricingRate,
                        groupPricingRate: plan?.groupPricingRate
                    },
                    newStatus: 'cancel'
                },
                refetch,
                noLessonProgress
            })
        },
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)},
    }
}

export default useCancelPlanProgressModal