import getEnv from '../../../utils/getEnv'

const optionsByEnv = {
  production: {
    apiKey: 'AIzaSyA7Ksk6fDS8ekruwGS_4qcE-EyAEraux9E',
    authDomain: 'znk-prod.firebaseapp.com',
    databaseURL: 'https://znk-prod.firebaseio.com',
    projectId: 'znk-prod',
    storageBucket: 'znk-prod.appspot.com',
    messagingSenderId: '475446610642'
  },
  beta: {
    apiKey: 'AIzaSyBbQ9RCZQeuusZfruA2txFRrooQsOgnH14',
    authDomain: 'znk-dev.firebaseapp.com',
    databaseURL: 'https://znk-dev.firebaseio.com',
    projectId: 'znk-dev',
    storageBucket: 'znk-dev.appspot.com',
    messagingSenderId: '903894177597',
    appId: '1:903894177597:web:c5301590342e9f08161754'
  },
  local: {
    apiKey: 'AIzaSyBbQ9RCZQeuusZfruA2txFRrooQsOgnH14',
    authDomain: 'znk-dev.firebaseapp.com',
    databaseURL: 'https://znk-dev.firebaseio.com',
    projectId: 'znk-dev',
    storageBucket: 'znk-dev.appspot.com',
    messagingSenderId: '903894177597',
    appId: '1:903894177597:web:c5301590342e9f08161754'
  }
}

const env = getEnv()

export default optionsByEnv[env] || optionsByEnv.beta
