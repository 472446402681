import React, { useState } from "react";
import { Button, Input, Activity } from "@Knowledge-OTP/znk-ui-components";
// import PaymentMethodsModal from "./PaymentMethodsModal";
import useListPaymentMethodsFromCustomer from "./Model/useListPaymentMethodsFromCustomer";
import useSetPaymentStripeMethodsToUser from "./Model/useSetPaymentStripeMethodsToUser";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../../../../common/Alert/util";
import useCreateUserInStripe from "./Model/useCreateUserInStripe";

const SearchByStripeId = (props) => {
  const { userId, refetch = () => {} } = props;
  // const [stripeId, setStripeId] = useState("");
  const [currentStripeId, setCurrentStripeId] = useState("");
  // const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const showAlertError = (err) =>
    showAlert({ text: err.toString(), status: "error" }, dispatch);
  const [
    getListPaymentMethod,
    { loading: loadPaymentMethods },
  ] = useListPaymentMethodsFromCustomer((res) => {
    const { listPaymentMethodsFromCustomer } = res || [];
    if (listPaymentMethodsFromCustomer) {
      const updatedPaymentMethods = listPaymentMethodsFromCustomer.map(
        (pm) => pm?.paymentMethodId || pm?.sourceId || pm?.id
      );
      setPaymentMethods({
        variables: {
          paymentMethodIds: updatedPaymentMethods,
          userId,
          stripeId: currentStripeId,
        },
      });
    }
  }, showAlertError);

  const onSuccessMutation = () => {
    showAlert({ text: "Success", status: "success" }, dispatch);
    if (refetch) refetch();
  };

  const [
    setPaymentMethods,
    { loading: loadingSetPaymentMethods },
  ] = useSetPaymentStripeMethodsToUser(onSuccessMutation, showAlertError);

  const [
    createUserInStripe,
    { loading: loadingCreateUserInStripe },
  ] = useCreateUserInStripe(onSuccessMutation, showAlertError);

  const handleListsPaymentMethods = () => {
    if (!currentStripeId) return;
    getListPaymentMethod({
      variables: {
        stripeId: currentStripeId,
        page: 0,
      },
    }).catch(showAlertError);
  };

  const handleCreateUserInStripe = () => {
    if (!userId) return;
    if (loadingCreateUserInStripe) return;
    createUserInStripe({
      variables: {
        userId,
      },
    }).catch(showAlertError);
  };

  return (
    <>
      <div className="w-100 mb-4 pt-6">
        <span className="text-title h3">Search by Stripe ID:</span>
      </div>
      <div className="d-flex flex-row align-items-center w-100 mb-1 pt-1 border-bottom">
        <Input
          className="mb-2 mr-2"
          disabled={false}
          // type='number'
          name="user"
          placeholder={"Stripe Id"}
          value={currentStripeId}
          onChange={(e) => {
            setCurrentStripeId(e.target.value);
          }}
        />
        <Button
          label="Apply"
          color="primary"
          className={"mb-2 w-30"}
          size="medium"
          onClick={handleListsPaymentMethods}
          activity={
            loadPaymentMethods ||
            loadingSetPaymentMethods ||
            loadingCreateUserInStripe
          }
          disabled={loadPaymentMethods || loadingSetPaymentMethods}
        />
      </div>

      {!loadingCreateUserInStripe &&
      !loadingSetPaymentMethods &&
      !loadPaymentMethods ? (
        <span
          className="btn-link"
          onClick={handleCreateUserInStripe}
          activity={loadingCreateUserInStripe}
        >
          Create Stripe ID
        </span>
      ) : (
        <Activity />
      )}
      {/* <PaymentMethodsModal
        stripeId={stripeId}
        userId={userId}
        refetch={refetch}
        {...{ isOpen, setOpen }}
      /> */}
    </>
  );
};

export default SearchByStripeId;
