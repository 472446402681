import React from 'react';
import useColumns from './useColumns';

const ListItem = (props) => {
    const {user, onClick = () => {}, isSelected} = props
    const columns = useColumns({user, isSelected})
    return (
        <div className={`w-100 d-flex flex-row align-items-center`} onClick={onClick} >
            {
                columns.map((inner) => {
                    return (
                        <>
                            {inner}
                        </>
                    )
                })
            }
        </div>
    )
}

export default ListItem