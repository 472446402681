import React, {useEffect, useMemo} from 'react'
import useTranslation from '../../../i18n/useTranslation'
import { IconArrowDown, RadioSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import moment from 'moment-timezone'
import { useLazyQuery, gql } from '@apollo/client' 

const TestDateFilter = (props) => {
  const { translate } = useTranslation()
  const { setTestDate, testDate, servicesFilters = [] } = props
  const [getActivities, { data: dataActivities }] = useLazyQuery(
    gql`
      query (
          $serviceIds: [String!]
          $kinds: [String!]
          $from: DateTime
          $to: DateTime
      ) {
        allActivities(
            kinds: $kinds,
            serviceIds: $serviceIds
            from: $from,
            to: $to
        ){
            ... on Test {
              id
              name
              kind
              tbd
              startDate
              endDate
              busy
              serviceId
              registrationDate
              scoreReleaseDate
              isDisabled
            }
            ... on Event {
                id
                name
                kind
                tbd
                startDate
                endDate
                busy
                description
            }
        }
      }
    `,
    { variables: { serviceIds: servicesFilters, kinds: ['test'] } }
  )

  useEffect(() => {
    getActivities()
    // eslint-disable-next-line
  }, [])
  
  const testDates = useMemo(
    () => {
      return dataActivities?.allActivities || []
    }, [dataActivities]
  ) 
  
  // const [servicesCache, setServicesCache] = useState(null)
  // if (serviceFilters !== servicesCache) {
  //   setServicesCache(serviceFilters)
  //   setTestDate({
  //     id: '',
  //     name: '-',
  //     date: null
  //   })
  // }
  const submit = (testId) => {
    const thisTest = testDates.find(test => test.id === testId)
    setTestDate({ id: testId, name: thisTest.name, date: thisTest.startDate })
  }
  return (
    <RadioSelector
      direction='down'
      setSelected={submit}
      selected={testDate}
      data={testDates ? testDates
        .map((item) => ({ name: item.name, id: item.id }))
        .sort((a, b) => moment(a.startDate).valueOf() - moment(b.startDate).valueOf()) : []}
      typeFilter='testDateSelector'
      strings={{
        title: translate('filters.plans.selectTestDate'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search'),
        changingTo: translate('filters.plans.changingTo')
      }}
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('plans.testDate')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </RadioSelector>
  )
}

export default TestDateFilter
