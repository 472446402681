import {db} from '../fireBase'

const collectionName = 'usersStatus'

export const createOnlineStatus = async (userID, setLoading, onlineStatus, eventID) => {
  if (!userID || !eventID) return
  const document = db.collection(collectionName).doc(eventID)
  const doc = await document.collection(userID).doc('status').get()
  if (!!doc.exists) {
    return createUserStatusOnEvent(document, userID, setLoading, onlineStatus)
  } else {
    await document.collection(userID).doc('status').set({})
    return createUserStatusOnEvent(document, userID, setLoading, onlineStatus)
  }
}

const createUserStatusOnEvent = async (document, userID, setLoading, onlineStatus) => {
  await document.collection(userID).doc('status').update({online: onlineStatus})
  setLoading(true)
  return `Online status set to ${onlineStatus}`
}
