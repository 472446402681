import React, { useMemo, useState } from 'react'
import {
  Modal,
  IconClose,
  InputSelect,
  LabeledField,
  PlainTextInput,
  Field,
  useWindowDimensions,
  IconSeen,
  ListItem,
  IconRemove,
  IconRight,
  IconEdit,
} from '@Knowledge-OTP/znk-ui-components'
import { ModalHeader } from 'reactstrap'
import useTranslation from '../../../../../../../i18n/useTranslation'
import { useDispatch } from 'react-redux'

import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../../store/modal/const'
import getAllAccountsFromUser from '../../../../../../../Model/Users/AccountsFromUser/AllAccountsFromUser/getAllAccountsFromUser'
import AccountEdit from '../accountEdit'
import AccountRemove from '../accountRemove'

const AccountInfo = (props) => {
  const { isOpen, toggle, user, account, setAccount } = props

  const dispatch = useDispatch()

  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  // eslint-disable-next-line
  const { data: allAccountsData, loading: allAccountsLoading, refetch: allAccountsRefetch, } = getAllAccountsFromUser()

  const [accountEditModalOpen, setAccountEditModalOpen] = useState(false)
  const [accountRemoveModalOpen, setAccountRemoveModalOpen] = useState(false)

  function passwordVisibility() {
    const passVisibility = document.getElementById('pass')
    if (passVisibility.type === 'password') {
      passVisibility.type = 'text'
    } else {
      passVisibility.type = 'password'
    }
  }

  const accountsCompleted = useMemo(() => {
    if (allAccountsData) {
      return allAccountsData.filter(
        (account) =>
          account?.originAccount !== '' &&
          account?.password !== '' &&
          account?.username !== '',
      )
    }
  }, [allAccountsData])

  const onChangeFunction = (value) => {
    const filterItem = accountsCompleted.filter((item) => {
      return item?.platform === value
    })
    const object = filterItem[0]
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'profile.account.info',
      data: {
        account: object,
      },
    })
  }

  if (user) {
    return (
      <>
        <Modal
          className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-medium'
            } d-flex flex-column`}
          backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
          isOpen={isOpen}
          toggle={toggle}
          Head={
            <ModalHeader
              className='px-0 py-1 mb-1 w-80'
              toggle={toggle}
              close={
                <div className='flex-grow-1 d-flex flex-row justify-content-end'>
                  <div className='hover-icon w-min-content'>
                    <IconClose size={20} onClick={toggle} />
                  </div>
                </div>
              }
            >
              <div className='d-flex flex-column'>
                <span className='h2 font-weight-bold'>
                  {translate('profile.actions.accounts.view')}
                </span>
                <span className='h4 text-muted'>
                  {translate('profile.actions.accounts.view.text')}
                </span>
              </div>
            </ModalHeader>
          }
        >
          <div className='px-0 py-1 mb-1 w-80'>
            <div className='w-100 pr-2 mb-2 mt-2 h45 text-gray'>Account</div>
            <Field
              type={InputSelect}
              className='w-100'
              component={PlainTextInput}
              fieldName='account'
              placeholder={translate('profile.actions.accounts.select')}
              value={account?.platform}
              options={accountsCompleted?.length > 0 ? accountsCompleted.map(optionsAccount => {
                return {
                  value: optionsAccount?.platform,
                  label: optionsAccount?.platform
                }
              }) : [
                {
                  value: account?.platform,
                  label: account?.platform
                }
              ]}
              label={translate('profile.actions.accounts.account')}
              onChange={(value) => {
                onChangeFunction(value)
              }}
              isDisabled
            />
            <div className='w-100 pr-2 mb-2 mt-4 h45 text-gray'>
              {translate('profile.actions.accounts.user')}
            </div>
            <Field
              className='mt-1 mb-1'
              type={LabeledField}
              component={PlainTextInput}
              fieldName='user'
              placeholder=''
              value={account?.username}
              fieldType='text'
              isDisabled
            // label={translate('profile.actions.accounts.user')}
            />

            <div className='w-100 pr-2 mb-2 mt-4 h45 text-gray'>
              {translate('counseling.password')}
            </div>
            <div
              className='w-100 pr-2 pl-3 d-flex flex-row'
              style={{
                border: 'none',
                backgroundColor: '#f0f4f8',
                borderRadius: '14px',
                height: '44px',
              }}
            >
              <input
                className='w-100 d-flex flex-row'
                style={{
                  border: 'none',
                  backgroundColor: '#f0f4f8',
                  width: '90%',
                  height: '100%',
                  borderWidth: '0px',
                  outline: 'none',
                }}
                type='password'
                id='pass'
                name='pass'
                defaultValue={account?.password}
                readOnly='readonly'
              />
              <IconSeen
                style={{ cursor: 'pointer' }}
                className='align-self-center'
                color='#73819e'
                onClick={() => passwordVisibility()}
              />
            </div>

            <div className='d-flex flex-column mt-5 justify-content-between'>
              <ListItem
                className='borderTop w-100 ml-1 pr-3 pl-2'
                hasHover
                Left={
                  <div className='d-flex flex-row justify-content-center'>
                    <IconEdit size={60} color='#696CFF' />
                    <span className='ml-5 h4 text-primary'>
                      {translate('profile.actions.accounts.editKey')}
                    </span>
                  </div>
                }
                Right={<IconRight size={20} />}
                onClick={() => {
                  setAccountEditModalOpen(true)
                }}
              />
              <ListItem
                className='borderTop w-100 ml-1 pr-3 pl-2'
                hasHover
                Left={
                  <div className='d-flex flex-row justify-content-center'>
                    <IconRemove size={60} color='#FF3E1D' />
                    <span className='ml-5 h4 text-error'>
                      {translate('profile.actions.accounts.removeKey')}
                    </span>
                  </div>
                }
                Right={<IconRight size={20} />}
                onClick={() => {
                  setAccountRemoveModalOpen(true)
                }}
              />
            </div>

            <AccountEdit
              isOpen={accountEditModalOpen}
              toggle={() => setAccountEditModalOpen(!accountEditModalOpen)}
              user={user}
              account={account}
              setAccount={setAccount}
              refetch={() => {
                allAccountsRefetch()
                setAccountEditModalOpen(false)
              }}
            />
            <AccountRemove
              isOpen={accountRemoveModalOpen}
              toggle={() => {
                setAccountRemoveModalOpen(!accountRemoveModalOpen)
              }}
              user={user}
              account={account}
              refetch={() => {
                allAccountsRefetch()
              }}
            />
          </div>
        </Modal>
      </>
    )
  } else return null
}

export default AccountInfo
