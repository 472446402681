import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import queryLessonResult from '../../QueryResult/useLessonResult.js'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading}] = useMutation(
    gql`mutation ($lessonId: String!) {
        setLessonToPending(lessonId: $lessonId){
          ${queryLessonResult}
        }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      //client.resetStore()
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.setLessonToPending : null, error, validationErrors, submit }
}
