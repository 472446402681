import { CheckboxSelector, IconArrowDown } from '@Knowledge-OTP/znk-ui-components'
import React, { useMemo } from 'react'
import { Badge } from 'reactstrap'
import {useSelector} from 'react-redux'
import useTranslation from '../../../i18n/useTranslation'

const ServicesFilter = (props) => {
  const { translate } = useTranslation()
  const { servicesFilters = [], setServicesFilters = () => {} } = props

  const services = useSelector((state) => state.user.services)
  const selectorData = useMemo(() =>
    services.slice().sort((a, b) => a.name.localeCompare(b.name)).map((item) => ({ name: item.name, id: item.id })
    ), [services]
  )

  return (
    <CheckboxSelector
      setDataFilters={setServicesFilters}
      direction='bottom'
      dataFilters={servicesFilters}
      typeFilter='services'
      data={selectorData}
      strings={{
        viewAll: translate('filters.plans.viewAll'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search')
      }}
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.plans.services.title')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default ServicesFilter
