import React, { useMemo, useState, useEffect } from 'react'
import {
    ModalLayout,
    Avatar,
    Modal,
    IconClose,
} from '@Knowledge-OTP/znk-ui-components'
import { useSelector } from 'react-redux'
import { Collapse, TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import Profile from './Profile'
import useStaffModal from './useStaffModal'
// import EditProfile from '../Users/Modal/changePersonal'
import EditProfileContent from '../Users/Modal/changePersonal/editProfileContent'
// import StudentProfile from '../Users/Modal/Profile/full'
import ContentFull from '../Users/Modal/Profile/contentFull'
import SetStudentServicesRateContent from '../Users/Modal/Student/setStudentServicesRate/setStudentRateContent'
import useGetUser from '../Calendar/modal/ResumeOrientation/useUserQuery'
import EditAvatar from '../../common/EditAvatar'
import useTranslation from '../../i18n/useTranslation'
import SetUserPermissions from './Permissions/setUserPermissions'
import AvailabilityRecurring from '../Users/Modal/Educator/SetAvailability/Recurring'
import AvailabilityTemporal from '../Users/Modal/Educator/SetAvailability/Temporal'

const Staff = () => {
    const { isOpen, toggle, onChange, setDataUser } = useStaffModal()
    const reduxData = useSelector((state) => state.modal.staff.infoStaffModal)
    const dataUser = reduxData?.dataUser
    const {
        userName,
        email,
        userId
    } = useMemo(
        () => {
            return {
                userName: `${dataUser?.firstName} ${dataUser?.lastName}`,
                email: dataUser?.emails[0]?.address,
                userId: dataUser?.id
            }
        }, [dataUser]
    )
    const { translate } = useTranslation()
    // eslint-disable-next-line
    const [getUser, { data: educatorData, loading: loadEducatorData }] = useGetUser()
    const [profilePage, setProfilePage] = useState('viewProfile')
    const [isOpenRight, setOpenRight] = useState(false)
    const [isReset, setIsReset] = useState(null)
    const [openCrop, setOpenCrop] = useState(false)
    const toggleCrop = () => {
        setOpenCrop(!openCrop)
    }
    const onChangeAvatar = (file) => {
        setOpenCrop(true)
    }
    const fetch = async () => {
        await getUser({
            variables: {
                id: userId
            }
        })
        setIsReset(isReset === null ? true : !isReset)
    }

    useEffect(() => {
        if (educatorData) {
            setDataUser({ ...dataUser, avatar: educatorData?.user?.avatar })
        }
        // eslint-disable-next-line
    }, [educatorData])
    return (
        <>
            {
                <Modal
                    className='modal-centered medium modal-colored white-modal pr-3 pl-3 '
                    isOpen={openCrop}
                    toggle={toggleCrop}
                    backdrop='static'
                    style={{ height: '95%' }}
                    StyleModalBody={{ height: '100%' }}
                    classModalBody='contentHeight'
                    Head={
                        <div className='modal-header p-0 m-0'>
                            <div className='d-flex justify-content-between w-100 pt-7 pb-4 px-7'>
                                <h2 className='font-weight-bold'>{translate('profile.actions.changeAvatar.title')}</h2>
                                <IconClose onClick={toggleCrop} className='text-gray' />
                            </div>
                        </div>
                    }
                >
                    <EditAvatar userId={userId} fetch={fetch} toogle={toggleCrop} />
                </Modal>
            }
            <ModalLayout
                className={`modal-colored modal-sidebar modal-desktop-half fixed-head-modal input-modal`}
                topHead={
                    <>
                        <div
                            ref={null
                                // (el) => {
                                //     if (!el) return
                                //     modalHeaderDimensions = el.getBoundingClientRect()
                                // }
                            }
                            className='d-flex flex-row justify-content-between w-100'>
                            <TabContent activeTab={!dataUser ? 'loading' : 'show'}>
                                <TabPane tabId={`loading`}>
                                    <div className='d-flex flex-column w-100'>
                                        <Skeleton duration={2} circle count={1} height={120} width={120} />
                                        <Skeleton duration={2} count={1} height={40} className='w-100' />
                                        <Skeleton duration={2} count={4} height={20} className='w-100' />
                                    </div>
                                </TabPane>
                                <TabPane tabId={`show`}>
                                    <div className='d-flex flex-column'>
                                        <Avatar
                                            name={userName}
                                            uri={dataUser?.avatar || null}
                                            innerDiv
                                            size='avatar-extra'
                                            activateChange={true}
                                            onClickImage={() => onChangeAvatar()}
                                            isReset={false}
                                        />
                                        <div className='mt-5 mb-3 d-flex flex-column'>
                                            <span className='h2 font-weight-bold text-title'>{userName}</span>
                                            <span className='h45 text-gray mt-2'>{email}</span>
                                            <span className='h45 text-gray my-2'>
                                                {
                                                    `${dataUser?.isActive ? 'Active' : 'Inactive'}`
                                                }
                                            </span>
                                            <span className='h45 d-flex flex-row mb-2' style={{ whiteSpace: 'nowrap' }}>
                                                {/* <span className='d-flex flex-row align-items-center text-gray'><IconStudent className='mr-2' size={12} color='#35425B' />{getValues(userData?.studentInfo?.graduationDate, 'grade')}°</span> */}
                                                {/* {
                                                userData?.studentInfo?.studentType === 'family' &&
                                                (<span className='d-flex flex-row align-items-center text-gray mx-3'><IconUser className='mr-2' size={12} />{parentName}</span>)
                                            } */}
                                                {/* <span className='d-flex flex-row align-items-center text-gray'><IconCountry className='mr-2' size={12} color='#35425B' />{countries && userData ? countries.docs.filter(e => e.id === userData?.countryId)[0]?.name : ''}</span> */}
                                            </span>
                                            {/* <div className='d-flex flex-row mt-4'>
                                            <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-1'>
                                                <IconUser className='mr-1' size={12} />Profile
                                            </Badge>
                                            <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-1 mx-2'>
                                                <IconUser className='mr-1' size={12} />Course material
                                            </Badge>
                                            <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-0 font-weight-bold'>+</Badge>
                                        </div> */}
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                            {/* <div className='d-flex flex-row'>
                        <IconOptions color='#D7E1EA' onClick={() => { }} className='mr-2' style={{ transform: 'rotate(90deg)' }} />
                        <IconClose color='#D7E1EA' onClick={toggle} />
                    </div> */}
                        </div>
                    </>
                }
                underHead={
                    <div className={`m-0 py-0 px-4 w-100`}>
                        <Collapse isOpen={true}>
                            <Profile
                                style={{}}
                                openSideModal={isOpenRight}
                                toggleSideModal={() => { setOpenRight(!isOpenRight) }}
                                setActiveTabRight={setProfilePage}
                                userData={dataUser}
                            />
                        </Collapse>
                    </div>
                }
                minHead={
                    <div>
                        min head
                    </div>
                }
                isOpenRight={isOpenRight}
                sideRight={
                    <TabContent activeTab={profilePage} className='px-2'>
                        <TabPane tabId='editProfile'>
                            <div className='w-100 text-title'>
                                <span className='d-flex w-100 flex-row justify-content-between'>
                                    <h2 className='font-weight-bold'>Edit Profile Info</h2>
                                </span>
                                <EditProfileContent
                                    countries={[]}
                                    type='staff'
                                    toggleRightSide={() => { setOpenRight(!isOpenRight) }}
                                    actionAfterUpdate={(res) => {
                                        const newUser = res?.data?.updateUser
                                        //acción que se ejecuta luego de actualizar la información del usuario

                                        //actualiza listado de usuarios
                                        onChange(newUser)
                                        setDataUser(newUser)

                                        setProfilePage('viewProfile')
                                    }} />
                            </div>
                        </TabPane>
                        <TabPane tabId='viewProfile'>
                            {
                                dataUser && (
                                    <div className='w-100 text-title'>
                                        <span className='d-flex w-100 flex-row justify-content-between'>
                                            <h2 className='font-weight-bold'>Profile Info</h2>
                                            {/* <IconClose size={20} onClick={toggleRightSide || closeFull} color='rgba(119, 134, 153, .4)' /> */}
                                        </span>
                                        <ContentFull
                                            user={dataUser}
                                            type='staff'
                                            toggleRightSide={() => { setOpenRight(!isOpenRight) }}
                                            afterEditPersonalAction={() => { setProfilePage('editProfile') }}
                                            afterEditStudentInfoAction={() => { }}
                                            afterEditFamilyInfoAction={() => { }}
                                            // refetch={() => refetchUser(dataUser?.id)}
                                            refetchList={() => {
                                                onChange()
                                            }}
                                        />
                                    </div>
                                )
                            }
                        </TabPane>
                        <TabPane tabId='av-recurring'>
                             <AvailabilityRecurring  educator={dataUser} isStaff={true}/>
                        </TabPane>
                        <TabPane tabId='av-temporal'>
                            <AvailabilityTemporal educator={dataUser} isStaff={true} page={'av-temporal'}/>
                        </TabPane>
                        <TabPane tabId={`editEmploymentDetails`}>
                            <div className='w-100 text-title' >
                                <span className='d-flex w-100 flex-row justify-content-between'>
                                    <h2 className='font-weight-bold'>Employment Details</h2>
                                </span>
                                <SetStudentServicesRateContent
                                    toggleRightSide={() => { setOpenRight(!isOpenRight) }}
                                    userId={dataUser?.id} />
                            </div>
                        </TabPane>
                        <TabPane tabId={`editAvailability`}>
                            <div className='w-100 text-title' >
                                <>
 
                                </>
                                {/* <span className='d-flex w-100 flex-row justify-content-between'>
                                    <h2 className='font-weight-bold'>Availability 1123141</h2>
                                </span> 
                                <SetAvailableRecurring toggleRightSide={() => {setOpenRight(!isOpenRight)}}
                                 educator={dataUser}/> */}
                            </div>
                        </TabPane>
                        <TabPane tabId={'editPermissions'}>
                            <div className='w-100 text-title' >
                                <span className='d-flex w-100 flex-row justify-content-between'>
                                    <h2 className='font-weight-bold'>Permissions Access</h2>
                                </span>
                                {
                                    profilePage === 'editPermissions' && <SetUserPermissions
                                        toggleRightSide={() => { setOpenRight(!isOpenRight) }}
                                        userId={dataUser?.id}

                                    />
                                }
                            </div>
                        </TabPane>
                    </TabContent>
                }
                useRightSection
                toggleRight={() => { setOpenRight(!isOpenRight) }}
                isOpen={isOpen}
                toggle={toggle}
            >
            </ModalLayout>
        </>
    )
}

export default Staff