import React, {useState, useRef, useEffect, useMemo} from 'react'
import ReactDOMServer from 'react-dom/server';
import {TabContent, TabPane} from 'reactstrap'
import { PDFViewer } from '@react-pdf/renderer'
// ELEMENTS
import useElementsHandler from './Elements/useElementsHandler';
import Controls from './Controls'
import {nanoid} from 'nanoid'

import usePdfEditorReducer from '../../reducers/pdf-editor'
import StylesControlsForElement from './Controls/StylesControlsForElement'
// import useCreatePdfTemplate from './Model/createPdfTemplate'
import useEditPdfTemplate from './Model/editPdfTemplate'
import useGetPdfTemplate from './Model/getPdfTemplate'
import { useLocation } from 'react-router'
import {
    useParams
  } from "react-router-dom";



const PdfEditor = () => {

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const hideMenu = searchParams.get('hideMenu')


    let { pdfTemplateId } = useParams();
    const {RenderHtmlDocument, elementTypes} = useElementsHandler()
    //controlador para documentObj (edita, agrega, elimina elementos, etc)
    const {getElementByPath, setElementByPath, omitElementByPath, cloneElementByPath} = usePdfEditorReducer()
    
    
    


    const pageInitialState = {//PAGE
        header: [],
        body: [],
        footer: [],
    }
    const [documentObj, setDocumentObj] = useState([
        // CREAR REDUCE PARA ESTRUCTURA DE DATOS
        pageInitialState
    ])

    //obtiene pdf template desde BD
    const {data: pdfTemplate} = useGetPdfTemplate(
        {templateId: pdfTemplateId || ''}
    )
    useEffect(
        () => {
            if(pdfTemplate?.getPdfTemplate?.json) {
                setDocumentObj(
                    JSON.parse(pdfTemplate.getPdfTemplate.json)
                )
            }
        }, [pdfTemplate]
    )

    const [editPdf, {loading: loadEditPdf}] = useEditPdfTemplate(
        () => {alert("success")},
        (err) => {
            console.error(err)
        }
    )


    const codeRef = useRef(null)

    //página actual
    const [currentPage, setCurrentPage] = useState(0)
    const [currentObjKey, setCurrentObjKey] = useState(null)

    const [timesAppliesActions, setTimesAppliesActions] = useState(0)
    const {currentElement, Html} = useMemo(
        () => {
            return {
                currentElement: getElementByPath(currentObjKey, documentObj, currentPage),
                Html: RenderHtmlDocument(documentObj)
            }
            // eslint-disable-next-line
        }, [currentObjKey, documentObj, timesAppliesActions, currentPage]
    )
    // const [currentElement, setCurrentElement] = useState(null)
    const newCssCommand = useRef(null)
    const newCssValue = useRef(null)
    return (
        <div className={`w-100 h-100 d-flex flex-row`}>
            <div className={`${hideMenu === 'true' ? 'w-100' : 'w-70'}`}>
                <PDFViewer className={'w-100 h-100'}>
                    {Html}
                </PDFViewer>
            </div>
            <div className={`${hideMenu === 'true' ? 'w-0' : 'w-30'} d-flex flex-row`}>
                <div className={`h-100 w-0`} style={{overflowY: 'scroll'}}>
                    <TabContent activeTab={'html'}>
                        <TabPane tabId={'html'}>
                            <textarea ref={codeRef} className={'w-100 h-inherit'}>
                                {ReactDOMServer.renderToString(Html)}
                            </textarea>
                        </TabPane>
                        {/* <TabPane tabId={'design'}>
                            <div className={`h-100 p-3`}>
                                {Html}
                            </div>
                        </TabPane> */}
                    </TabContent>
                </div>
                <div className={`controls h-100 w-100`}>
                    <div className={`current-document h-100`}>

                        <Controls 
                            className={`h-60  py-2 pl-3 pr-0`} 
                            style={{backgroundColor: 'rgb(240,244,248)', overflowY: 'scroll'}}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage} 
                            onCreateNewPage={() => {
                                setDocumentObj(
                                    documentObj.concat([pageInitialState])
                                )
                            }}
                            onClickElement={(objKey) => {
                                setCurrentObjKey(objKey)
                            }} 
                            onCreateNewElement={(objKey,/** , element**/_, type) => {
                                //diseño del elemento por defecto
                                const elementDefault = elementTypes[type].initialState
                                const newDocument = setElementByPath(
                                    objKey,
                                    documentObj,
                                    {
                                        // nuevo elemento
                                        ...elementDefault,
                                        id: nanoid()
                                    }, currentPage
                                )
                                
                                setDocumentObj(newDocument)
                                setTimesAppliesActions(timesAppliesActions+1)
                            }} 
                            onDeleteElement={(objKey/** , element**/) => {
                                // omitElementByPath(objKey, documentObj)
                                const newDocument = omitElementByPath(objKey, documentObj, currentPage)
                                
                                setDocumentObj(newDocument)
                                setTimesAppliesActions(timesAppliesActions+1)
                            }} 
                            setDocumentObj={setDocumentObj} 
                            documentObj={documentObj} 
                            onDuplicatePage={() => {
                                //duplica la página actual
                                setDocumentObj(
                                    documentObj.concat([documentObj[currentPage]])
                                )
                            }}
                            onDeletePage={() => {
                                if(documentObj.length <= 1) return
                                const erasePage = currentPage
                                setCurrentPage(0)
                                setDocumentObj(documentObj.filter((_, index) => index !== erasePage))
                            }}
                            onCloneElement={
                                (objKey) => {
                                    setDocumentObj(cloneElementByPath(objKey, documentObj, currentPage))
                                }
                            }
                        />

                        <div className={`h-30 d-flex flex-column m-0 py-2 pl-3 pr-0`} style={{backgroundColor: 'white', overflowY: 'scroll'}}>
                            {
                                currentElement &&
                                <StylesControlsForElement element={currentElement} setCurrentElement={
                                    (newElement) => {
                                        const newDocument = setElementByPath(
                                            currentObjKey,
                                            documentObj,
                                            newElement,
                                            currentPage
                                        )
                                        setDocumentObj(newDocument)
                                        setTimesAppliesActions(timesAppliesActions+1)
                                    }
                                } />
                                // Object.entries(currentElement?.props).map(
                                //     ([cssCommand, cssValue]) => {
                                //         return (
                                //             <div className={`d-flex flex-row w-100 justify-content-between`}>
                                //                 <label className={`w-50`}>{cssCommand}</label>
                                //                 <input className={`w-50`} type={'text'} value={cssValue} />
                                //             </div>
                                //         )
                                //     }
                                // )
                            }
                            <div className={`d-flex flex-row w-100 justify-content-between`}>
                                <input ref={newCssCommand} className={`w-40`} type={'text'} name={'css-command'} value={null} />
                                <input ref={newCssValue} className={`w-40`} type={'text'} name={'css-value'} value={null} />
                                <button onClick={() => {
                                    if(!newCssCommand?.current?.value || !newCssValue?.current?.value) return
                                    //edita el estilo del elemento actual
                                    const newDocument = setElementByPath(
                                        currentObjKey,
                                        documentObj,
                                        {
                                            //elemento modificado
                                            ...currentElement,
                                            props: {
                                                ...currentElement.props,
                                                [newCssCommand.current.value]: newCssValue.current.value
                                            }
                                        },
                                        currentPage
                                    )
                                    setDocumentObj(newDocument)
                                    setTimesAppliesActions(timesAppliesActions+1)
                                    // setDocumentObj(setElementByPath(documentObj, ))
                                }}>Create</button>
                            </div>
                        </div>
                        <div className={`h-10 d-flex flex-column m-0 py-2 pl-3 pr-0 position-fixed`}>
                                <button
                                    onClick={() => {
                                        editPdf(
                                            {
                                                variables: {
                                                    templateId: pdfTemplateId,
                                                    templateInput: {
                                                        name: pdfTemplate?.getPdfTemplate?.name,
                                                        json: JSON.stringify(documentObj),
                                                        key: pdfTemplate?.getPdfTemplate?.key
                                                    }
                                                }
                                            }
                                        ) 
                                    }}
                                >{loadEditPdf ? 'Loading' : 'Save'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PdfEditor