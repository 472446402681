import { useQuery, gql } from "@apollo/client"

const useGetCheckInsForPlan = (vars = {}) => {
    return useQuery(
        gql`
            query ($planId: String!) {
                getCheckInsForPlan (
                    planId: $planId
                ) {
                    id
                    date
                    type
                }
            }
        `, {
            variables: vars
            
        }
    )
}

export default useGetCheckInsForPlan