import React from 'react'
import Skeleton from 'react-loading-skeleton'
import HeaderField from '../../../HeaderField'

const PlanNameField = (props) => {
    const {
        plan, className, loading = false,
        header = false, onSort = () => {}, sort = 'name', order = false
    } = props
    return (
        loading ? 
            <span className={`d-flex flex-row justify-content-start align-items-center ${className}`}>
                <Skeleton count={1} width={100} height={16} style={{borderRadius: '12px'}}/>
            </span> :
            header ? 
            <HeaderField 
                active={sort === 'name'} order={order} 
                onClick={() => { onSort('name', !order /**ASC**/) }} 
                className={`h45 text-gray d-flex flex-row justify-content-start align-items-center ${className}`}>
                    Plan Name
            </HeaderField> :
            <span 
                title={plan?.name} 
                className={`text-truncate h45 text-gray d-flex flex-row justify-content-start align-items-center ${className}`}>{
                    plan?.name
                }
            </span> 
    )
}

export default PlanNameField