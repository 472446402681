import {gql, useMutation} from '@apollo/client'
const useSwitchDisabledForStudent = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $educatorId: ID!
            ) {
                switchDisabledForStudent (educatorId: $educatorId) {
                    id
                    firstName
                    lastName
                }
            }
        `,
        {
            onCompleted, onError
        }
    )
}

export default useSwitchDisabledForStudent