import React, {useState, useMemo, useEffect} from 'react'
import {Avatar} from '@Knowledge-OTP/znk-ui-components'
import useNewPopulateGoogleCalendar from '../../../Model/useNewPopulateGoogleCalendar'
import moment from 'moment'
const ListItem = (props) => {
    const {user, isCurrentElement = true, onCompleted = () => {}, defaultProccess} = props
    const [execGoogleCalendar] = useNewPopulateGoogleCalendar(
        () => {},
        console.error,
    )
    const [isOpen, setOpen] = useState(false)
    const toggle = () => {setOpen(!isOpen)}
    const userName = `${user?.firstName} ${user?.lastName}`
    const [status, setStatus] = useState('pending')
    const calendarOperation = useMemo(
        () => {
            // if(defaultProccess) return defaultProccess
            if(!user?.educatorInfo) return 'create'
            if(!user.educatorInfo?.googleCalendarId) return 'rebuild'
            return 'populate' // ya tiene google calendar
            //eslint-disable-next-line
        }, [user, defaultProccess]
    )

    const defaultVariables = {
        lessonId: null,
        startDate: new Date(moment().startOf('day').toISOString()),
        endDate: null,//new Date(moment().add(1, 'month').toISOString()),
        sendOneLesson: false,
        populateCalendar: false,
        cleanCalendar: false,
        createCalendar: false,
        userId: user?.id,

    }
    const onError = (err) => {
        console.error(err)
        setStatus('error')
        onCompleted()
    }
    const populateCalendar = (onSuccess = () => {}) => {
        execGoogleCalendar({variables: {...defaultVariables, populateCalendar: true}})
        .then(onSuccess, onError)
    }
    const createCalendar = (onSuccess = () => {}) => {
        execGoogleCalendar({variables: {...defaultVariables, createCalendar: true}})
        .then(onSuccess, onError)
    }
    const cleanCalendar = (onSuccess = () => {}) => {
        execGoogleCalendar({variables: {...defaultVariables, cleanCalendar: true}})
        .then(onSuccess, onError)
    }

    useEffect(() => {
        if (isCurrentElement) {
            //ejecuta la mutación
            setStatus('in progress')
            if(calendarOperation === 'create') {
                createCalendar(() => {
                    setStatus('completed')
                    onCompleted()
                })
            }
            else if(calendarOperation === 'rebuild') {
                createCalendar(() => {
                    populateCalendar(() => {
                        setStatus('completed')
                        onCompleted()
                    })
                })
            }
            else if (calendarOperation === 'populate') {
                cleanCalendar(() => {
                    populateCalendar(() => {
                        setStatus('completed')
                        onCompleted()
                    })
                })
            }
        }
        //eslint-disable-next-line
    }, [isCurrentElement])
    return (
        <>
            <div onClick={toggle} className={`cursor-pointer w-100 my-2 d-flex flex-row justify-content-between align-items-center`}>
                <div className={`d-flex flex-row align-items-center text-truncate`}>
                    <Avatar
                        className={`mr-2`}
                        name={userName}
                        avatar={user?.avatar}
                    /> <span>{`${userName} (${calendarOperation})`}</span>
                </div>
                <div>
                    <span>{status}</span>
                </div>
            </div>
        </>
    )
}


export default ListItem