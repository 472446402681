import moment from 'moment'

export const getStatus = (lesson, completeLesson, translate) => {
    console.log('lesson ', lesson)
    let status = ''
    const studentAux = completeLesson ? (completeLesson.student || completeLesson.studentPlan || completeLesson.studentGroup) : null
    if (completeLesson.status === 'finished') {
        if (completeLesson?.notes?.status === "draft") {
            status = translate('calendar.sections.filters.incomplete')
        } else if (Array.isArray(studentAux)) {
            const allMissed = studentAux.filter(({attendanceStatus}) => attendanceStatus === 'missed' || attendanceStatus === 'pending').length === studentAux.length
            const educatorNoShow = lesson.educator.attendanceStatus === 'missed' || lesson.educator.attendanceStatus === 'pending'
            if (educatorNoShow){
                status = translate('modal.infolesson.noshow')
            } else if (allMissed){
                status = translate('calendar.sections.filters.missed')
            } else if (educatorNoShow && allMissed) {
                status = translate('calendar.sections.filters.missed')
            } else {
                status = translate('calendar.sections.filters.completed')
            }
        } else {
            const educatorNoShow = lesson.educator.attendanceStatus === 'missed' || lesson.educator.attendanceStatus === 'pending'
            const studentNoShow = studentAux.attendanceStatus === 'missed' || studentAux.attendanceStatus === 'pending'
            if (educatorNoShow){
                status = translate('modal.infolesson.noshow')
            } else if (studentNoShow){
                status = translate('calendar.sections.filters.missed')
            } else if (educatorNoShow && studentNoShow){
                status = translate('calendar.sections.filters.missed')
            } else {
                status = translate('calendar.sections.filters.completed')
            }
        }
    } else if (completeLesson.status === 'pending') {
        status = 'Pending'
    } else {
        if (moment(completeLesson.startDate) < moment() && moment() < moment(completeLesson.endDate)) {
            status = translate('calendar.sections.filters.inProgress')
        } else if (moment() > moment(completeLesson.endDate)) {
            status = translate('calendar.sections.filters.incomplete')
        } else if (moment() < moment(completeLesson.startDate)) {
            status = translate('calendar.sections.filters.upcoming')
        }
    }
    return status
}
