import React from 'react'
import {
    Input
} from '@Knowledge-OTP/znk-ui-components'

const Comment = (props) => {
    const { form, setForm } = props
    return (
        <>
            <div className='pl-0 pr-6 py-6 pl-3'>
                <h3><b>Comments</b></h3>
                <div className='row pl-3'>
                    <Input
                        className={`col-12`}
                        type={'textarea'}
                        value={form?.comment}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                comment: e?.target?.value
                            })
                        }}
                    />
                </div>
            </div>
            {/* <div className='borderTop ml-3' /> */}
        </>
    )
}

export default Comment