import validate from 'validate.js'

const constraints = {
  'input.name': {
    presence: true
  },
  'input.shortDescription': {
    presence: true
  },
  'input.collegeUnitId': {
    presence: true
  },
  'input.ranking': {
    presence: true
  },
  'input.stateAbbr': {
    presence: true
  },
  'input.city': {
    presence: true
  },
  'input.typeCategory': {
    presence: true
  },
  'input.typeYear': {
    presence: true
  },
  'input.undergraduateSize': {
    presence: true
  },
  'input.acceptanceRate': {
    presence: true
  },
  'input.fourYearGraduationRate': {
    presence: true
  },
  'input.actCumulativeMidpoint': {
    presence: true
  },
  'input.satAverage': {
    presence: true
  },
  'input.avgCostOfAttendance': {
    presence: true
  },
  'input.rankingsBestTotal': {
    presence: true
  },
  'input.rankingsBestValue': {
    presence: true
  },
  'input.logo': {
    presence: true
  }
}
  

export default (data) => {
  return validate(data, constraints)
}
