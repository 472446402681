import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  Modal,
  IconLesson,
  useWindowDimensions,
  Button,
  ListItem,
  Input,
  PaymentProgressDetails,
  IconSignPlus,
  IconClose,
  Stats,
  BulletPoint,
  AmountInput,
  RadioOption,
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse, TabContent, TabPane } from 'reactstrap'
import QueryPaymentsAndLessons from '../queryPaymentsAndLessons/index'
import useTranslation from '../../../../../i18n/useTranslation'
import BreakdownAndPayment from '../BreakdownAndPayment'
import moment from 'moment'
import SetToPauseOrCancelPlan from '../../../../../Model/Plan/SetToPauseOrCancelPlan/index'
import {showAlert} from '../../../../../common/Alert/util'


const PaymentStatus = {
  pendingPayment: 'pendingPayment', // When it hasn't been processed //NO PAGADO
  procesingPayment: 'procesingPayment', // Processing payment information //NO PAGADO
  paid: 'paid', // Successfully paid //PAGADO
  unPaid: 'unpaid', // Unsuccessfully paid //NO PAGADO
  canceled: 'canceled', // When we need to omit or cancel some one //QUITAR DE LA QUERY
  draft: 'draft', // For admin purposes //QUITAR DE LA QUERY 
  void: 'void', //when it was totally or partially voided //QUITAR DE LA QUERY
}

const tabIds = {
  details: 'details',
  breakdown: 'breakdown'
}

const typePaymentItemCancel = {
  refound: 'refound',
  collect: 'collect'
}
const ViewPlanProgress = (props) => {
  const { isOpen, dataPlan, refetch, noLessonProgress } = useSelector((state) => state.modal.plan.viewPlanProgress)
  
  const isCounselingPlan = useMemo(() => noLessonProgress, [noLessonProgress])

  const {paymentData} = dataPlan
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)
  useEffect(() => {if(!isOpen) setIsOpenCollapse(false)}, [isOpen])
  //const toggleCollapse = () => setIsOpenCollapse(!isOpenCollapse)
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.viewPlanProgress',
      data: { isOpen: !isOpen, dataPlan: dataPlan, refetch: refetch }
    })
  }
  const { submit, loading } = SetToPauseOrCancelPlan((result) => {
    // onsuccess
    refetch()
    toggle()
    showAlert({
      text: 'Success',
      status: 'success'
    }, dispatch)
    // solo se puede pausar o cancelar el plan si esta en estado sent o scheduled
  },
  (err) => {
    // eslint-disable-next-line
    showAlert({
      text: 'Error',
      status: 'error'
    }, dispatch)
    console.error(err)
  })
  const data = QueryPaymentsAndLessons({
    planId: dataPlan.planId
  })

  const {allLessons, closeLessons, openLessons} = useMemo(() => {
    if(data?.lessons && data?.lessons?.docs.length > 0) {
      const allLessons = data?.lessons?.docs?.filter(({kind}) => kind === 'plan-lesson')
      const {closeLessons, openLessons} = allLessons.reduce(
        (
          {closeLessons, openLessons}, 
          lesson
        ) => {
          const {startDate, status} = lesson
          if(
            ['finished'].includes(status) || 
            (status === 'scheduled' && moment() > moment(startDate)) 
            ) closeLessons.push(lesson)
          else if(['scheduled', 'pending'].includes(status)) openLessons.push(lesson)
          return {closeLessons, openLessons}
      }, {closeLessons: [], openLessons: []})
      return {allLessons: openLessons.concat(closeLessons), closeLessons, openLessons}
    }
    return {}
  }, [data])
  const valuePerLesson = useMemo(() => {
    if(paymentData?.typePlanPricing && paymentData?.pricingRate && allLessons) {
      if(paymentData?.typePlanPricing === 'perlesson') return paymentData?.pricingRate
      else if (paymentData?.typePlanPricing === 'permonth') return paymentData?.pricingRate/allLessons.length
    }
    return 0
  }, [paymentData, allLessons])
  

  const {
    allPayments,
    paidPayments, 
    refoundPayments,
    toCollectPayments,
    unPaidPayments,
    percentToMark
  } = useMemo(() => {
    if (data?.myPaymentsItems && data?.myPaymentsItems?.docs.length > 0 && paymentData?.typePlanPricing) {
      //const {typePlanPricing, pricingRate} = paymentData

      //let openLessonsInMonth = openLessons?.filter(({startDate}) => moment().startOf('month') <= moment(startDate) && moment(startDate) <= moment().endOf('month')).length || 0
      //let closeLessonsInMonth = closeLessons?.filter(({startDate}) => moment().startOf('month') <= moment(startDate) && moment(startDate) <= moment().endOf('month')).length || 0
      //porcentaje del mes que se debe reembolsar (cuando es permonth)
      //const percentOfRefound = openLessonsInMonth/(openLessonsInMonth+closeLessonsInMonth)
      //porcentaje del mes que hay que cobrar
      //const percentOfCollect = 1 - percentOfRefound 
      
      const allPayments = data?.myPaymentsItems?.docs?.filter(({type}) => type !== 'otherFixed')
      const otherFixed = data?.myPaymentsItems?.docs?.filter(({type}) => type === 'otherFixed')
      //pago fijo solo aplica para counseling-plans
      const realAllPayments = allPayments.concat(isCounselingPlan ? otherFixed : []) 
      let paid = (realAllPayments || []).filter(({status}) => [PaymentStatus.paid].includes(status))
      let unPaid = (realAllPayments || []).filter(({status}) => [PaymentStatus.unPaid, PaymentStatus.pendingPayment].includes(status))
      let refound = []
      let toCollect = []
      //Calculo de reembolso o pago se hace en base a cantidad pagada menos cantidad consumida
      
      //valor lección por cantidad de lecciones consumidas
      let consumido = (valuePerLesson || 0)*(closeLessons?.length || 0)
      
      //monto pagado en payments
      let pagado = paid?.reduce((total, {amount}) => total + amount, 0) || 0
      let excess = pagado - consumido
      if (excess > 0) {
        refound = [{amount: excess}]
        toCollect = []
      } else if (excess < 0) {
        refound = []
        toCollect = [{amount: excess*-1}]
      }

      if(openLessons?.length === 0) { //el plan ya se completó
        //se cobra todo lo que falta por pagar
        toCollect = unPaid
      }
      if (noLessonProgress) {
        refound = []
        toCollect = []
      }
      const percentPaid = (paid.length > 0 ?
        (paid.reduce((total, {amount}) => amount+total, 0))/allPayments.reduce((total, {amount}) => total+amount, 0) : //porcentaje de pago menos porcentaje de reembolso
        0)*100
      const percentToRefound = (refound.length > 0 ?
        (refound.reduce((total, {amount}) => amount+total, 0))/allPayments.reduce((total, {amount}) => total+amount, 0) : //porcentaje de pago menos porcentaje de reembolso
        0)*100

      const percentUnpaid = (unPaid.length > 0 ?
        (unPaid.reduce((total, {amount}) => amount+total, 0))/allPayments.reduce((total, {amount}) => total+amount, 0) : //porcentaje de pago menos porcentaje de reembolso
        0)*100
      const percentToCollect = (toCollect.length > 0 ?
        (toCollect.reduce((total, {amount}) => amount+total, 0))/allPayments.reduce((total, {amount}) => total+amount, 0) : //porcentaje de pago menos porcentaje de reembolso
        0)*100
        
      const percentToMark = percentToRefound > 0 ? 
        (percentPaid - percentToRefound) : 
          percentToCollect > 0 ? 
            (100 - (percentUnpaid - percentToCollect)) : 0
      return {
        paidPayments: paid, 
        refoundPayments: refound, 
        unPaidPayments: unPaid, 
        toCollectPayments: toCollect, 
        allPayments: realAllPayments,
        percentToMark: percentToMark,
      }
    }
    return {}
  }, [isCounselingPlan, data, paymentData, closeLessons, openLessons, valuePerLesson, noLessonProgress])

  //genera elemento que contiene el array de pagos para el componente PaymentProgressDetails
  const generateArrayProgressElement = useCallback((totalArray, color, title, total, zIndex) => {
    return {
      completed: parseFloat(parseFloat((totalArray / total)*100).toFixed(2)), 
      progressColor: color,
      title,
      zIndex
    }
  }, [])

  const paymentsArray = useMemo(() => {
    if(allPayments){
      let arrayResult = []
      const total = allPayments.reduce((total, {amount}) => total + amount, 0)
      if(paidPayments?.length > 0) 
        arrayResult.push(
          generateArrayProgressElement(
            paidPayments.reduce((total, {amount}) => total + amount, 0), 
            'green', 
            `Pagado $${paidPayments.reduce((sum, {amount}) => sum+amount, 0).toFixed(2)}`,
            total,
            2
          )
        )
      if(unPaidPayments?.length > 0)
        arrayResult.push(
          generateArrayProgressElement(
            unPaidPayments.reduce((total, {amount}) => total + amount, 0), 
            'red', 
            `No Pagado $${unPaidPayments.reduce((sum, {amount}) => sum+amount, 0).toFixed(2)}`,
            total,
            1
          )
        )

      //if(refoundPayments?.length > 0) 
      //  arrayResult.push(
      //    generateArrayProgressElement(
      //      refoundPayments.reduce((total, {amount}) => total + amount, 0), 
      //      'black', 
      //      `Reembolso $${refoundPayments.reduce((sum, {amount}) => sum+amount, 0).toFixed(2)}`,
      //      total,
      //      1
      //    )
      //  )
  //
      //if(toCollectPayments?.length > 0) {
      //  arrayResult.push(
      //    generateArrayProgressElement(
      //      toCollectPayments.reduce((total, {amount}) => total + amount, 0), 
      //      'green', 
      //      `Por pagar $${toCollectPayments.reduce((sum, {amount}) => sum+amount, 0).toFixed(2)}`,
      //      total,
      //      0
      //    )
      //  )
      //}
      
      return arrayResult
    }
    return []
  }, [paidPayments, unPaidPayments, allPayments, generateArrayProgressElement])
  const lessonsArray = useMemo(() => {
    if (allLessons) {
      let arrayResult = []
      if (closeLessons.length > 0) 
        arrayResult.push(
          generateArrayProgressElement(
            closeLessons.length, 
            'green', 
            `Close ${closeLessons.length}`,
            allLessons.length,
            2
          )
        )
      if (openLessons.length > 0) {
        arrayResult.push(
          generateArrayProgressElement(
            openLessons.length, 
            'red', 
            `Open ${openLessons.length}`,
            allLessons.length,
            1
          )
        )
      }  
      return arrayResult
    }
    return []
  }, [allLessons, closeLessons, openLessons, generateArrayProgressElement])

  const [activeTab, setActiveTab] = useState(tabIds.details)
  
  const [paymentDate, setPaymentDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
  const [paymentAmount, setPaymentAmount] = useState(0)
  useEffect(() => {
    if(toCollectPayments?.length > 0 || refoundPayments?.length > 0) {
      setPaymentAmount(
        toCollectPayments?.length > 0 ? 
          toCollectPayments.reduce((sum, {amount}) => sum+amount, 0) :
          refoundPayments?.length > 0 ?
            refoundPayments.reduce((sum, {amount}) => sum+amount, 0) :
            0  
      )
    } else setPaymentAmount(0)
  }, [toCollectPayments, refoundPayments])
  
  const [isRefound, setIsRefound] = useState(true)
  const [isCollect, setIsCollect] = useState(false)
  const [isSettled, setIsSettled] = useState(true)

  useEffect(() => {
    if(refoundPayments?.length > 0) {
      setIsRefound(true)
      setIsCollect(false)
      setIsSettled(false)
    }
    else if (toCollectPayments?.length > 0) {
      setIsRefound(false)
      setIsCollect(true)
      setIsSettled(false)
    }
    else {
      setIsRefound(false)
      setIsCollect(false)
      setIsSettled(true)
    }
  }, [refoundPayments, toCollectPayments])
  const total = allPayments?.reduce((sum, {amount}) => sum+amount, 0) || 0
  //const remaining = 
  //  (total - 
  //    (
  //      ((paidPayments || []).concat(toCollectPayments || []).concat(refoundPayments || []))?.reduce(
  //        (sum, {amount}) => sum+amount,0) || 0
  //    )
  //  ) || 0
   
  const refoundAmount = refoundPayments?.reduce((total, {amount}) => amount+total, 0) || 0 
  const collectAmount = toCollectPayments?.reduce((total, {amount}) => amount+total, 0) || 0  

  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-colored`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header mx-5 mt-7 mb-1 d-flex flex-row'>
            <span className='h2 font-weight-bold text-title'>
              {
                dataPlan.newStatus === 'cancel'
                  ? translate('plans.modal.cancelplan')
                  : translate('plans.modal.pauseplan')
              }
            </span>
            <div className={`hover-icon`}>
              <IconClose size={24} onClick={toggle} />
            </div>
          </div>
        }
      >
        <div className='row mt-1 mx-4 mb-4 p-0'>
          <div className='m-0 p-0 w-100 mt-1 mx-5 mb-5'>
            <span className='d-flex justify-content-between aling-items-center my-2'>
              <label className={`text-title font-weight-bold`}>{translate('plans.modal.cancelplan.usage')}</label>
              {
                !noLessonProgress && (
                  <BulletPoint
                    BulletPointClassName='p-4 znk-bullet-point-stats'
                    className='znk-bullet-point-container-white'
                    innerComponent={
                      <Stats className='znk-stats-font-small' items={[]} />
                    }
                  >
                    <span className='text-muted h5'>{translate('plans.modal.cancelplan.lessonstatus')} <IconLesson size={15} /></span>
                  </BulletPoint>
                )
              }
            </span>
            <label className='text-muted'>{translate('plans.modal.cancelplan.payments')}: </label>
            <PaymentProgressDetails
              loading={loading}
              progressArray={paymentsArray} 

              arrowSign={percentToMark}
              arrowSignTitle={
                refoundPayments?.length > 0 ? 
                `${translate('plan.modal.cancelplan.refound')}: $${refoundPayments?.reduce((total, {amount}) => total+amount, 0).toFixed(2)} (${percentToMark?.toFixed(2)}%)` : 
                `${translate('plan.modal.cancelplan.charge')}: $${toCollectPayments?.reduce((total, {amount}) => total+amount, 0).toFixed(2)} (${percentToMark?.toFixed(2)}%)`}
              showArrowSign={percentToMark > 0}
              
              bgColor={'red'}
              barTitle={`Total: $${allPayments && allPayments.reduce((sum, {amount}) => sum+amount, 0).toFixed(2)}`}
              leftLabel={
                <div style={{color: 'green'}}>
                  {
                    paidPayments && 
                    <>
                      <span className={'font-weight-bold'}>
                        {paidPayments.length}
                      </span>
                      <>{` ($${paidPayments.reduce((sum, {amount}) => sum + amount, 0).toFixed(2)})`}</>
                    </>
                  }
                </div>
              }
              rightLabel={
                <div style={{color: 'red'}}>
                  {
                    unPaidPayments && 
                    <>
                      <span className={'font-weight-bold'}>
                        {unPaidPayments.length}
                      </span>
                      <>{` ($${unPaidPayments.reduce((sum, {amount}) => sum + amount, 0).toFixed(2)})`}</>
                    </>
                  }
                </div>
              }
            />
            {
              !noLessonProgress && (
                <>
                  <label className='text-muted'>{translate('plans.modal.cancelplan.lessons')}: </label>
                  <PaymentProgressDetails
                    loading={loading}
                    progressArray={lessonsArray} 
                    bgColor={'red'}
                    barTitle={openLessons && `Open ${openLessons?.length}`}
                    leftLabel={
                      <div style={{color: 'green'}}>
                        {
                          closeLessons && 
                          <>
                            <span className={'font-weight-bold'}>
                              {closeLessons.length}
                            </span>
                            <>{` ($${((closeLessons.length)*valuePerLesson).toFixed(2)})`}</>
                          </> 
                        }
                      </div>
                    }
                    rightLabel={
                      <div style={{color: 'red'}}>
                        {
                          openLessons && 
                          <>
                            <span className={'font-weight-bold'}>
                              {openLessons.length}
                            </span>
                            <>{` ($${((openLessons.length)*valuePerLesson).toFixed(2)})`}</>
                          </>
                        }
                      </div>
                    }
                  />
                </>
              ) 
            }
            
            
            <ListItem
              showBottomLine
              showTopLine
              className='w-100'
              Left={<span className={`text-info`}>{translate('plans.modal.cancelplan.total')}</span>}
              Right={<span className={`text-info`}>{`$${(total).toFixed(2)}`}</span>}
            />
            
            {
              !noLessonProgress && (
                <ListItem
                  showBottomLine
                  className='w-100'
                  Left={
                  <span className='text-mustard'>
                    {
                      refoundAmount > 0 ? 
                        translate('plan.modal.cancelplan.refound') : 
                        collectAmount > 0 ? 
                          translate('plan.modal.cancelplan.charge') : 
                          translate('plan.modal.cancelplan.settled')
                    }
                  </span>}
                  Right={
                    <span className='text-mustard'>
                      {
                        refoundAmount > 0 || collectAmount > 0 ? 
                        `$${
                          (refoundAmount > 0 ? refoundAmount : collectAmount > 0 ? collectAmount : 0).toFixed(2)
                          }` :
                        `-`  
                      }
                    </span>
                  }
                /> 
              )
            }
            
            
            <div className={'m-0 p-0 d-flex flex-row justify-content-between'}>
              <label className='btn-link mt-3' onClick={() => {
                if(!isOpenCollapse) setIsOpenCollapse(true)
                else {
                  if(activeTab === tabIds.breakdown) setIsOpenCollapse(false)
                }
                setActiveTab(tabIds.breakdown)
              }}>
                {'Ver detalles'}
                {/**isOpenCollapse ? translate('plans.modal.cancelplan.hidedetails') : translate('plans.modal.cancelplan.seedetails')**/}
              </label>
              <label className='btn-link my-3 pl-3' onClick={() => {
                if(!isOpenCollapse) setIsOpenCollapse(true)
                else {
                  if(activeTab === tabIds.details) setIsOpenCollapse(false)
                }
                setActiveTab(tabIds.details)
              }}>
                  {translate('plan.modal.cancelplan.paymentLabel')}
              </label>
            </div>
            <Collapse isOpen={isOpenCollapse}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={tabIds.breakdown}>
                  <BreakdownAndPayment
                    noLesson={noLessonProgress}
                    lessonsToText={` ${openLessons?.length} Lessons to cancel`}
                    paymentsItems={data && data.myPaymentsItems && data.myPaymentsItems.docs}
                    action={dataPlan.newStatus}
                  />
                </TabPane>
                <TabPane tabId={tabIds.details}>
                  <div className={'m-0 p-0 d-flex flex-column'}>
                    <label className='font-weight-bold text-title'>
                      {translate('plan.modal.cancelplan.paymentDetailTitle')} : 
                    </label>
                    <label className='text-gray'>
                      {translate('plan.modal.cancelplan.amountLabel')} :
                      <AmountInput
                        className='placeholder-to-right'
                        placeholder='$  0.00 '
                        min='0'
                        type='number' 
                        value = {paymentAmount}
                        disabled={isSettled}
                        onChange={(amount) => {
                          setPaymentAmount(amount)
                        }}
                      />
                    </label>
                    <label className='text-gray'>
                      {translate('plan.modal.cancelplan.dateLabel')} :
                      <Input
                        type={'date'}
                        value = {paymentDate}
                        disabled={isSettled}
                        onChange={({target: {value}}) => {
                          setPaymentDate(value)
                        }}
                      />
                    </label>
                    <div className={'m-0 p-0 my-2 pl-2 d-flex flex-column'}>
                      <div className={'m-0 p-0 d-flex flex-row'}>
                        <RadioOption value={isRefound ? 'isRefoundId' : null} isChecked={isRefound} onChange={(bool) => {
                          if(bool) {
                            //se activa
                            setIsRefound(true)
                            setIsCollect(false)
                            setIsSettled(false)
                          }
                        }} />
                        <span className={'text-gray'}>{translate('plan.modal.cancelplan.refound')}</span>
                      </div>
                      <div className={'m-0 p-0 d-flex flex-row'}>
                        <RadioOption value={isCollect ? 'isCollectId' : null} isChecked={isCollect} onChange={(bool) => {
                          if(bool) {
                            //se activa
                            setIsRefound(false)
                            setIsCollect(true)
                            setIsSettled(false)
                          }
                        }}/>
                        <span className={'text-gray'}>{translate('plan.modal.cancelplan.charge')}</span>
                      </div>
                      <div className={'m-0 p-0 d-flex flex-row'}>{/** 
                        <CheckboxOption value={isSettled} defaultState={isSettled} onChange={(bool) => {
                          setIsSettled(bool)
                        }}/>**/}
                        <RadioOption value={isSettled ? 'isSettledId' : null} isChecked={isSettled} onChange={(bool) => {
                          if(bool) {
                            //se activa
                            setIsRefound(false)
                            setIsCollect(false)
                            setIsSettled(true)
                          }
                        }}/>
                        <span className={'text-gray'}>{translate('plan.modal.cancelplan.settled')}</span>
                      </div>
                    </div>
                    <div className={'d-flex flex-row justify-content-end text-error'}>
                      {
                        refoundPayments?.length > 0 && `${translate('plan.modal.cancelplan.refound')} *`
                      }
                      {
                        toCollectPayments?.length > 0 && `${translate('plan.modal.cancelplan.charge')} *`
                      }
                      {
                        refoundPayments?.length === 0 &&
                        toCollectPayments?.length === 0 && `${translate('plan.modal.cancelplan.settled')} *`
                      } 
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </Collapse>
            <Button
              color='#FF3E1D'
              style={{ backgroundColor: '#FF3E1D' }}
              className='w-100 mt-3'
              activity={loading}
              label={
                <span className='w-100 d-flex' style={{ justifyContent: 'space-between' }}>
                  {
                    dataPlan.newStatus === 'cancel'
                      ? translate('plans.modal.cancelplan')
                      : translate('plans.modal.pauseplan')
                  }
                  <IconSignPlus size={20} />
                </span>
              }
              onClick={() => {
                let paymentDetails = {}
                if(!isSettled) {
                  paymentDetails.amount = parseFloat(paymentAmount || 0)
                  paymentDetails.date = paymentDate
                  if(isRefound) paymentDetails.type = typePaymentItemCancel.refound
                  else paymentDetails.type = typePaymentItemCancel.collect 
                }
                submit({
                  statusPlan: dataPlan.newStatus,
                  planId: dataPlan.planId,
                  paymentDetails: !isSettled ? paymentDetails : null
                })
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ViewPlanProgress
