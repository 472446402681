import {gql, useQuery} from '@apollo/client'
import queryLessonResult from '../../../../../../Model/QueryResult/useLessonResult'

const useGetRecurringLessons = (vars) => {
    return useQuery(
        gql`
        query (
            $recurringId: String!
        ) {
            getRecurringLessons (
                recurringId: $recurringId
            ) {
                ${queryLessonResult}
            }
        }
        `,
        {
            variables: vars
        }
    )
}

export default useGetRecurringLessons