import {useQuery, gql} from '@apollo/client'
// import userQueryResult from '../../../Model/Users/QueryResults/userQueryResult'

const useUsers = (vars = {}) => {
    return useQuery(
        gql`
            query {
                users {
                    id
                    isActive
                    firstName
                    lastName
                }
            }
        `,
        {
            variables: vars
        }
    )
}

export default useUsers