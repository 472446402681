import {gql, useMutation} from '@apollo/client'

const useGetFreeStatics = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $week: DateTime!
                $recipients: [String!] = []
            ) {
                getFreeStatics (
                    recipients: $recipients
                    week: $week
                )
            }
        `, {onCompleted, onError}
    )
}

export default useGetFreeStatics