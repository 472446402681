import { BlankSpace } from '@Knowledge-OTP/znk-ui-components'
import React from 'react'

const NoAuthorize = () => {
  return (
    <BlankSpace
        className={`w-100 h-100`}
        mainText='You do not have access to this section'
    />
  )
}

export default NoAuthorize