import { useQuery, gql } from "@apollo/client"
import { useMemo } from "react"
import {templateQueryResult} from "./queryResults/templateQueryResult"

const useGetTasksTemplate = (variables = {limit: 10}) => {
    const {data, loading, refetch} = useQuery(
        gql`
            query {
                getTasksTemplate (limit: 0) {
                    docs {
                        ${templateQueryResult}
                    }
                }
            }
        `, 
        {
            variables,
            fetchPolicy: 'no-cache'
        }
    )

    const templates = useMemo(() => {
        return data?.getTasksTemplate?.docs || []
    }, [data])

    return {templates, loading, refetch}
}

export default useGetTasksTemplate