import React, {useCallback, useMemo} from 'react'
import {CheckboxOption} from '@Knowledge-OTP/znk-ui-components'
import useAssignMultipleTasks from '../../../Components/AssignMultipleTasks/useAssignMultipleTasks'

const SelectMultipleTask = (props) => {
    const {task} = props
    const {pushTask, removeTask, getIsSelected, selectedTasks = []} = useAssignMultipleTasks()

    const appendTask = (currentTask) => {
        const includeTask = getIsSelected(currentTask)
        //si la tarea ya esta seleccionada la elimina
        if(includeTask) removeTask(currentTask)
        //en caso contrario agrega la tarea al arreglo de seleccionados
        else pushTask(currentTask)   
    }
    

    const isSelected = useMemo(
        () => getIsSelected(task),
        // eslint-disable-next-line
        [task, selectedTasks]
    )

    const RenderCheckBox = useCallback(
        () => {
            return (
                <CheckboxOption
                    defaultState={isSelected}
                    onChange={() => {
                        appendTask(task)
                    }}
                />
            )
        },
        // eslint-disable-next-line
        [isSelected, task, selectedTasks]
    )

    return (
        <RenderCheckBox/>
    )
}

export default SelectMultipleTask