import { gql, useLazyQuery } from '@apollo/client'
export const queryUserString = 
	`query($roles: [Roles!], $ids: [ID!], $limit: Int, $sortField: String) {
		allUsers(roles: $roles, ids: $ids, limit: $limit, sortField: $sortField) {
			hasNext
			hasPrevious
			next
			previous
			totalDocs
			docs {
				id
				firstName
				lastName
			}
		}
	}`

export const useGetOwners = () => {
  return useLazyQuery(
    gql`${queryUserString}`
  )
}