import {useMutation, gql} from '@apollo/client'
import testQueryResult from './queryResult/useTestQueryResult'

const useEditTestMutation = (onSuccess = () => {}, onDelete = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $editTestInput: TestEditInput!
                $activityId: String!
                ) {
                editTest (
                    editTestInput: $editTestInput
                    activityId: $activityId
                )  {
                    ${testQueryResult}
                }
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError: () => {
                onDelete()
            }
        }
    )
}

export default useEditTestMutation