import { useDispatch, useSelector } from 'react-redux' 
import {
    parseOcurrencesToCalendar,
    callBackForConflict,
} from '../../modal/utils'

const useScheduledConflict = () => {
    const reduxData = useSelector((state) => state.modal.lesson.scheduledConflict)
    const dispatch = useDispatch()
    const setOpen = (open, ocurrences = [], onChange = () => {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'lesson.scheduledConflict',
            data: {
                ...reduxData,
                ...parseOcurrencesToCalendar(
                    ocurrences,
                    (dataCalendar) => {
                    callBackForConflict(() => {
                        onChange(dataCalendar)
                    }, dataCalendar, dispatch)
                    }
                ),
                isOpen: open
            }
          })
    }
    return {
        open: (ocurrences = [], onChange = () => {}) => {
            setOpen(true, ocurrences, onChange)
        },
        handleMutationResponse: (
            result,
            onOcurrencies = () => {},
            onError = () => {},
            onCompleted = () => {}
        ) => {
            if (result?.hasOccurrences) {
                onOcurrencies(result?.occurrencesList)                
            } else if (result?.errorKey) {
                onError(result?.errorKey)
            } else {
                onCompleted(result?.newLessons)
            }
        }
    }
}

export default useScheduledConflict