import React, {useState} from 'react'
import {
    Modal,
    Input,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import { gql, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../store/modal/const'

const SetRateToPaymentAndLesson = (props) => {
    const {refetch} = props
    const reduxData = useSelector((state) => state.modal.developer.setRateToPaymentAndLesson)
    const dispatch = useDispatch()
    const {isOpen, lessonId, payments} = reduxData
    const toggle = () => {
        setNewRate(0)
        setPayment(null)
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'developer.setRateToPaymentAndLesson',
            data: {
              ...reduxData,
              isOpen: !isOpen
            }
        })
    }
    

    const [setRateMutation, {loading: loadMutation}] = useMutation(
        gql`
            mutation (
                $newRate: Float!,
                $paymentId: String!,
                $lessonId: String!
            ) {
                setRateOnLessonAndHisPayment (
                    newRate: $newRate
                    paymentId: $paymentId
                    lessonId: $lessonId
                )
            }
        `,
        {
            update (__, update) {
                toggle()
                refetch()
            }
        }
    )
    
    const [newRate, setNewRate] = useState(0) 
    const [payment ,setPayment] = useState(null)

    return (
        <Modal
            className={'modal-centered'}
            isOpen={isOpen}
            toggle={toggle}
            Head={
                <div className={'d-flex flex-column'}>
                    <span className={'h2 font-weight-bold text-title'}>Payments</span>
                    <span className={'h45 text-gray'}>Lesson Id: {lessonId}</span>
                </div>
            }
        >
            <div className={'d-flex flex-column p-3'}>           
                {
                    payments.map(({paymentId, status, amount}) => {
                        return (
                            <>
                                <div 
                                    className={'d-flex flex-column m-0 p-0 my-2'}
                                    onClick={() => {
                                        setPayment(paymentId)
                                    }}
                                >
                                    <span className={`${payment === paymentId ? 'font-weight-bold text-title' : 'text-gray'} h45`}>PaymentId: {paymentId}</span>
                                    <span className={"text-gray h45"}>Status: {status}</span>
                                    <span className={"text-gray h45"}>Amount: {amount}</span>
                                </div>
                                <div className={'borderTop w-100'}/>
                            </>    
                        )
                    })
                }
                <Input 
                    className={'mt-2'}
                    type={'number'}
                    onChange={(e) => {setNewRate(e?.target?.value || 0)}}  
                />
                <Button 
                    activity={loadMutation}
                    color={"primary"}
                    className={'w-100 mt-4'}
                    size={'xs'}
                    label={'Enviar'}
                    disabled={payment === null}
                    onClick={() => {
                        let mutObj = {
                            paymentId: payment,
                            newRate: parseFloat(newRate),
                            lessonId
                        }
                        setRateMutation({variables: mutObj})
                    }}
                />
            </div>
        </Modal>
    )
}

export default SetRateToPaymentAndLesson