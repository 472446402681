import React, {useState, useEffect} from 'react'
import {
  ListItem,
  IconClose,
  AvailabilityCalendar,
  IconCountry,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment-timezone'
import BulkUpdateAvailability from '../../../../../../Model/CalendarEvent/BulkUpdateAvailability/index'
import useTranslation from '../../../../../../i18n/useTranslation'
import queryLessonResult from '../../../../../../Model/QueryResult/useLessonResult'
import { useLazyQuery, gql } from '@apollo/client'
import UpdatedAtBy from '../../UpdatedByAt'
import { showAlert } from '../../../../../../common/Alert/util'
import ChangeTimezone from '../../../../../Calendar/modal/ChangeTimezone'
const SetAvailable = (props) => {
  const {toggleFull, page, educator, isStaff} = props 
  const id = educator?.id 
  const {zone: timezone} = useSelector((state) => state.timezone)
  const {translate} = useTranslation()
  const dispatch = useDispatch()
  //const toggle = () => {
  //  dispatch({
  //    type: 'SET_DATA_MODAL_FORM_LIST', 
  //    payload: 'user.availabilityTemporal',
  //    data: {
  //      isOpen: !isOpen,
  //      dataUser
  //    }
  //  })
  //}
  const [now, setNow] = useState(moment().toISOString())
  // cambiar query por alguna que obtenga las lecciones de mi usuario como educador
  //const {data} = CalendarView({
  // kinds: [
  //   'educator-unavailability',
  //   'individual-lesson',
  //   'plan-lesson',
  //   'group-lesson',
  //   'counselor',
  // ],
  // userId: id || '',
  // from: moment(now).startOf('isoWeek').toISOString(),
  // to: moment(now).endOf('isoWeek').toISOString(),
  //})
  const [queryCalendarView, { data, loading: loadCalendarView }] = useLazyQuery(
    gql`query(
      $kinds: [String!]
      $userId: ID
      $from: DateTime!
      $to: DateTime!
    ) {
      calendarView(
          kinds: $kinds
          userId: $userId,
          from: $from,
          to: $to
      ) {  
          ${queryLessonResult}
          ... on Counselor {
            id
          }
          ... on EducatorUnavailability {
            id
            startDate
            endDate
          }
        }
      }`,
      {
        fetchPolicy: 'network-only'
      }
  )
  const refetch = (now , id) => {
    if(now && id){
      queryCalendarView({
        variables: {
          kinds: [
            'educator-unavailability',
            'individual-lesson',
            'plan-lesson',
            'group-lesson',
            'counselor',
          ],
          userId: id || '',
          from: moment(now).startOf('isoWeek').toISOString(),
          to: moment(now).endOf('isoWeek').toISOString(),
        }
      })
    }
  }

  useEffect(() => {
    if(page === 'av-temporal' && now && id) {
      refetch(now, id)
    } 
    else if(page !== 'av-temporal'){
      //reinicio
      setSelectedBlocks([])
      setUnavailableBlocks([])
    }
    // eslint-disable-next-line
  }, [page, now, id])
  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [unavailableBlocks, setUnavailableBlocks] = useState([])
  const {submit: bulkUpdate, loading: loadSave} = BulkUpdateAvailability(
    () => {
      setSelectedBlocks([])
      setUnavailableBlocks([])
      refetch(now, id)
      showAlert({
        text:  translate(`profile.availability.temporary.success`),
        status: 'success'
      }, dispatch)
    },
    (err) => {
      showAlert({
        text: err.toString(),
        status: 'error'
      }, dispatch)
    },
  )

  const parseToMutation = () => {
    return {
      blocks: selectedBlocks // nuevos bloques disponibles
        .map((block) => {
          return {
            time: block,
            isAdding: false,
          }
        })
        .concat(
          unavailableBlocks.map((block) => {
            // nuevos bloques no disponibles
            return {
              time: block,
              isAdding: true,
            }
          }),
        ),
    }
  }
  
  return (
    <>
      <div className='m-0 px-8 py-3' style={loadCalendarView ? {cursor: 'wait'} : {}}>
        <div className={'d-flex flex-row justify-content-between w-100'}>
          <span className={'h2 text-title font-weight-bold mb-3'}>Temporal</span>
          {!isStaff && <IconClose className='text-cancel' onClick={() => {
            toggleFull()
            setSelectedBlocks([])
            setUnavailableBlocks([])
            refetch(now, id)
          }} />}
        </div>
        <AvailabilityCalendar
          calendarClassName={'pt-7 pb-0 px-6 mt-3'}
          loading={loadCalendarView}
          setMoment={(newMoment) => {
            setNow(newMoment.toISOString())
          }}
          moment={moment(now)}
          lessons={
            data?.calendarView.filter(
              (lesson) => ![
                'canceled',
                'draft',
                'finished',
                'pending',
                'draftPending',
                'occurrence',
                'paused'
              ].includes(lesson.status))
               || []
          }
          setSelectedBlocks={setSelectedBlocks}
          selectedBlocks={selectedBlocks}
          setUnavailableBlocks={setUnavailableBlocks}
          unavailableBlocks={unavailableBlocks}
          loadSave={loadSave}
          saveLabel={translate('profile.actions.save')}
          onSave={() => {
            bulkUpdate({
              educatorId: id,
              recurrentAvailability: parseToMutation(),
            })
          }}
          zone={timezone}
          changeTimezone={
            <>
            <ListItem
              showTopLine
              showBottomLine
              onClick={
                () => {
                  dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'lesson.changeTimezoneModal',
                    data: {
                      isOpen: true
                    }
                  })
                }
              }
              Left={
                <div className='m-0 p-0 d-flex flex-row align-items-center'>
                  <IconCountry className='mr-2' />
                  <span>{timezone}</span>
                </div>
              }
              Right={
                <div className='m-0 p-0 d-flex flex-row align-items-center'>
                  <div className='hover-icon px-2 py-1'>
                    <IconArrowRight />
                  </div>
                </div>
              }
            />
            {educator?.educatorInfo?.availabilityUpdated && <UpdatedAtBy educator={educator} />}
            </>
          }
        />
      </div>
      <ChangeTimezone />
    </>
  )
}
export default SetAvailable
