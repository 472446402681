import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  GroupLessonsCalendar,
  IconClose,
  ListItem,
  useWindowDimensions,
  Modal, StickyButton, IconSignCheck
} from '@Knowledge-OTP/znk-ui-components'
import * as moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import useFreeTimeFrames from '../New/Components/WeeklySchedule/GroupLessonScheduling/myFreeTimeFrames'
import isEmpty from 'lodash/isEmpty'
import useModalState from '../../../../../utils/useModalState'
import useTranslation from '../../../../../i18n/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'

const GroupLessonSchedulingModalEdit = (props) => {
  const { setForm, form } = props
  const timeLocal = useSelector((state) => state.timezone.zone)
  const { isOpen, modalData, toggle, onChange } = useModalState('groupPlans.schedulePartEdit')
  const { educator: gottenEducator, topicId, topic, service, num, idx, groupBlocks, startdate, enddate } = modalData
  const { breakWidth } = useWindowDimensions()
  const { translate, selectedLang } = useTranslation()
  const [from, to] = useMemo(() => {
    if (startdate && enddate) {
      const st = moment(startdate).toISOString()
      const sT = st.split('T')[0] + 'T00:00:00Z'
      const et = moment(enddate).toISOString()
      const eT = et.split('T')[0] + 'T23:59:59Z'
      return [sT, eT]
    }
    return [moment(), moment()]
  }, [startdate, enddate])
  const [selectedBlocks, setSelectedBlocks] = useState([])
  useEffect(() => {
    setSelectedBlocks(groupBlocks || [])
  }, [groupBlocks, setSelectedBlocks])
  const educator = gottenEducator || {}
  const { data, loading } = useFreeTimeFrames({
    educatorId: educator.educatorUserId || educator.id,
    from,
    to,
    minDurationInMinutes: 50,
    involveMyself: false
  })
  const dispatch = useDispatch()
  const openEducatorModal = (topic) => {
    if (form.statusPlan && form.statusPlan === 'scheduled') return
    const type = 'Group'
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.selectTopicEducator',
      data: {
        isOpen: true,
        idTopic: topic.id,
        topicName: topic.name,
        setEducator: (edu) => {
          onChange(pre => ({ ...pre, educator: edu }))
          setForm(pre => ({
            ...pre,
            [`educators${type}`]: { ...pre[`educators${type}`], [topic.id]: edu }
          }))
        },
        form,
        educator,
        serviceId: service.id,
        type
      }
    })
  }
  const submit = () => {
    toggle()
    setForm(form => {
      return {
        ...form,
        lpw: {
          ...form.lpw,
          [idx]: {
            ...form.lpw[idx],
            [topicId]: {
              ...form.lpw[idx][topicId],
              groupBlocks: selectedBlocks
            }
          }
        }
      }
    })
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={`modal-${
        breakWidth === 'SM' ? 'botbar' : 'centered'
      }`}//modal-unavailability
      Head={
        <>
          <div className='w-100 d-flex'>
            <div className='rounded bg-primary' style={{ width: `${(selectedBlocks.length / num) * 100}%`, height: '4px', minWidth: '3%', transition: '.5s' }} />
          </div>
          <div className='modal-header d-flex flex-column'>
            <div className='w-100 d-flex'>
              <div className='flex-grow-1 znk-scroll-horizontal d-flex'>
                {
                  new Array(num).fill(0).map((_, idx) => {
                    return (
                      <span className='mr-2' key={idx}>
                        <Avatar
                          key={idx}
                          className='m-0'
                          named={false}
                          size={
                            `avatar-medium ${idx === selectedBlocks.length ? 'set-avatar-manual' : idx < selectedBlocks.length ? 'scheduled-avatar-manual' : 'noset-avatar-manual'} `
                          }
                          name={((topic && topic.name) || '-').substring(0, 1).toUpperCase()}
                          IconComponent={idx + 1 <= selectedBlocks.length && IconSignCheck}
                          uri={null}
                        />
                      </span>
                    )
                  })
                }
              </div>
              <div>
                <IconClose className='text-gray' size={20} onClick={toggle} />
              </div>
            </div>
            <div className='d-flex flex-column justify-content-around w-100 mt-5 h-auto'>
              <h2 className='font-weight-bold text-title'>
                Choose your lesson
              </h2>
              <h3 className='font-weight-light text-gray'>
                {
                  `${service && service.name} - ${topic && topic.name}`
                }
              </h3>
            </div>
          </div>
        </>
      }
    >
      <div className='w-100 pl-4'>
        {
          educator ? (
            <ListItem
              onClick={() => openEducatorModal(topic)}
              showTopLine
              Left={
                <div className='d-flex align-items-center text-muted'>
                  {
                    educator.educatorUserId || educator.id ? (
                      <>
                        <Avatar
                          name={`${educator.firstName} ${educator.lastName}`}
                          named={false}
                          uri={null}
                          size='avatar-medium'
                        />
                        <span className='ml-2'>
                          {educator.firstName} {educator.lastName}
                        </span>
                      </>
                    ) : (
                      <label className='btn-link' onClick={() => openEducatorModal(topic)}>{translate('modal.newplan.recommendEducators.Group', { name: (topic && topic.name) || '-' })}</label>
                    )
                  }
                </div>
              }
            />
          ) : null
        }
        {
          loading ? <Skeleton /> : 
          !educator || !educator.id ? (
            <div className='d-flex align-items-center justify-content-center'>
              {translate('misc.nothingFound')}
            </div>
          ) : 
          (
            <GroupLessonsCalendar
              part={[from, to]}
              availabilityBlocks={!isEmpty(data) ? data : []}
              selectedBlocks={selectedBlocks}
              loading={loading}
              addSelected={(block) => setSelectedBlocks(blocks => blocks.length < num ? blocks.concat(block) : blocks.slice(1).concat(block))}
              removeSelected={(blockId) => {
                setSelectedBlocks((blocks) => blocks.filter(item => item.id !== blockId))
              }}
              preloadedBlocks={(groupBlocks || []).map(b => b.id)}
              timeLocal={timeLocal}
              timeEducator={gottenEducator.timezone}
              lang={selectedLang}
              statusPlan={form.statusPlan}
            />
          )
        }
        <StickyButton
          condition={selectedBlocks.length === num}
          btnClassName='bg-primary'
          onClickButtonFunction={form.statusPlan && form.statusPlan === 'scheduled' ? () => {} : submit}
        />
        {/* <label className='mt-8'>xcxc</label> */}
      </div>
    </Modal>

  )
}

export default GroupLessonSchedulingModalEdit
