
import React, {useEffect, useState} from 'react'
import {
    Field,
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import { escapeRegExp} from 'lodash'
import useTranslation from '../../../../../../../../i18n/useTranslation'
const AllStudentPlans = (props) => {

    const plansList = props?.plansList?.plans?.docs || []
    //eslint-disable-next-line
    const { selectedPlan, setSelectedPlan, selectedActivity, setSelectedActivity, allActivities, infoPlan, setInfoPlan, isView } = props
    const { translate } = useTranslation()
    const [detailPlan, setDetailPlan] = useState({})
    // const [infoPlan, setInfoPlan] = useState(null)
    useEffect(() => {
        typeof selectedPlan === 'string' ? setDetailPlan(selectedPlan) : setDetailPlan(selectedPlan?.id)
    }, [selectedPlan])
    
    return (

        <div className='d-flex justify-content-between mb-2 w-100'>
            <div className='d-flex flex-column w-100' >
            <div className='d-flex flex justify-content-between align-items-end'>
          <span className='h45 text-gray mb-2'>{translate('modal.task.plans.selectPlan')}</span>
        </div>
                {
                    <Field
                        InputClassName='col-12 p-0'
                        isDisabled={isView}
                        type={InputSelect}
                        isMulti={false}
                        fieldName='service'
                        placeholder={translate('modal.task.plans.selectPlanPlaceholder')}
                        value={detailPlan}
                        options={
                            Array.isArray(plansList) && 
                            plansList.map((serv) => ({ value: serv.id, label: serv.name })) 
                        }
                        filterOption={(obj, string) => {
                            if (new RegExp(escapeRegExp(string), 'i').test(obj?.label)) return true
                            return false
                        }}
                        onChange={(e) => {
                            const serv = plansList &&
                            plansList.reduce((obj, serv) => {
                                    if (serv.id === e) return { id: e, name: serv.name }
                                    return obj
                                }, {})
                            setSelectedPlan(serv.id || e)
                        }}
                    />
                }
            </div>
        </div>

    )


}

export default AllStudentPlans