import {useMutation, gql} from '@apollo/client'

const useToggleActiveStatus = () => {
    return useMutation(
        gql`
            mutation (
                $userId: ID!
                $force: Boolean
            ) {
                toggleActiveStatus (
                    userId: $userId
                    force: $force
                )
            }
        `
    )
}

export default useToggleActiveStatus