import React, {useMemo} from 'react'
import {
    IconEmail,
    IconEdit,
    IconErase,
    IconSignCheck,
    IconNotes
} from '@Knowledge-OTP/znk-ui-components'
import useNewPlanModal from '../../../newPlanModal/useNewPlanModal'
import useSendOnePlanModal from '../../../SendPlanModal/useSendOnePlanModal'
import useCancelPlanModal from '../../../cancelPlanModal/useCancelPlanModal'
import useCancelPlanProgressModal from '../../../cancelPlanProgressModal/useCancelPlanProgressModal'

const useOptionsByStatus = (status, plan, onChangeProp = () => {}, onChangeStatus = () => {}) => {
    //status: oneOf [scheduled, draft, sent]
    //actions: edit, sent, cancel, approve
    const {setOpenToEdit} = useNewPlanModal()
    const {open: openSendPlan, close: closeSendModal} = useSendOnePlanModal()
    const {open: openProgressPlan, close: closeProgressPlan} = useCancelPlanProgressModal()
    const {open: openCancelConfirm, close: closeCancelConfirm} = useCancelPlanModal()
    
    const getOptions = (status, plan, onChange = () => {}, more, refetch = () => {}/**refetch list plans query**/ ) => {
        let baseOptions = []
        if(['draft', 'sent'].includes(status)) {
            baseOptions = baseOptions.concat([
                {
                    Icon: IconEmail,
                    left: 'Send plan',
                    onClick: () => {
                        openSendPlan(plan, () => {
                            onChange('send', {...plan, status: 'sent'})
                            closeSendModal()
                        }, true /**useMutation**/, refetch)
                    },
                    iconClass: '',
                    status: 'primary',
                    subMenu: null
                },
                {
                    Icon: IconEdit,
                    left: 'Edit',
                    onClick: () => {
                        setOpenToEdit((newPlan) => {
                            onChange('edit', {...newPlan})
                        }, plan)
                    },
                    iconClass: '',
                    status: 'primary',
                    subMenu: null
                },
                // {
                //     Icon: IconDuplicate,
                //     left: 'Duplicate draft',
                //     onClick: () => {},
                //     iconClass: '',
                //     status: 'primary'   
                // },
            ])
        }
        if(!['cancel', 'scheduled', "application", "postApplication", "completed"].includes(status)) {
            baseOptions = baseOptions.concat([
                {
                    Icon: IconSignCheck,
                    left: 'Approve plan',
                    onClick: () => {
                        //redirige a counseling onboarding
                        window.open(`${process.env.ADMIN_URL || 'http://liveprep.zinkerz.com'}/counseling-onboarding/${plan?.id}?isAdmin=true`, '_blank');
                    },
                    iconClass: '',
                    status: 'primary',
                    subMenu: null
                }
            ])
        }
        const changeStatusesOption = {
            Icon: IconErase,
            left: 'Statuses',
            onClick: () => {
            },
            iconClass: '',
            status: 'danger',
            subMenu: {
                menu: [
                    {
                        level: 1, 
                        title: 'Change Status', 
                        subMenu: [
                            {title: 'Pre-Application', onClick: () => {onChangeStatus('scheduled')}, selected: plan?.status === 'scheduled', color: 'warning'}, 
                            {title: 'Application', onClick: () => {onChangeStatus('application')}, selected: plan?.status === 'application', color:'inProgress'}, 
                            {title: 'Post-Application', onClick: () => {onChangeStatus('postApplication')}, selected: plan?.status === 'postApplication', color: 'primary'},
                            {title: 'Completed', onClick: () => {onChangeStatus('completed')}, selected: plan?.status === 'completed', color: 'success'}
                        ]
                    }
                ],
                subMenu: true
            },
        }
        if(!['cancel'].includes(status)) {
            baseOptions = baseOptions.concat([
                changeStatusesOption,
                {
                    Icon: IconNotes,
                    left: 'View PDF proposal',
                    onClick: () => {
                        if(['scheduled', 'preApplication', 'postApplication', 'application'].includes(status)) {
                            window.open(`${process.env.AMAZONS3_MEDIA_URL || 'https://s3.eu-west-1.amazonaws.com/media.zinkerz.com/counseling'}/${plan?.studentUser?.studentUserId}/${plan?.id}/proposal-sign.pdf`)
                        } else {
                            // plan
                            window.open(`${process.env.AMAZONS3_MEDIA_URL || 'https://s3.eu-west-1.amazonaws.com/media.zinkerz.com/counseling'}/${plan?.studentUser?.studentUserId}/${plan?.id}/proposal-non-sign.pdf`)                            

                        }
                    },
                    iconClass: '',
                    status: 'danger',
                    subMenu: null
                },
                {
                    Icon: IconErase,
                    left: 'Cancel plan',
                    onClick: () => {
                        if(['scheduled', 'preApplication', 'postApplication', 'application'].includes(status)) {
                            openProgressPlan(plan, (newPlan) => {
                                onChange('cancel', {...newPlan, status: 'cancel'})
                                closeProgressPlan()
                            }, true)
                        } else {
                            openCancelConfirm((newPlan) => {
                                //cancela plan en reduce
                                onChange('cancel', {...newPlan, status: 'cancel'})
                                closeCancelConfirm()
                            }, plan, true /**useMutation**/)
                        }
                    },
                    iconClass: '',
                    status: 'danger',
                    subMenu: null
                }
            ])
        }
        if(['completed'].includes(status)) {
            baseOptions = [changeStatusesOption]
        }
        
        return baseOptions
    }
    const options = useMemo(() => {
        return getOptions(status, plan, onChangeProp)
        // eslint-disable-next-line
    }, [status, plan, onChangeProp])

    return {
        options,
        parseToDropdown: (options, more = false) => {
            let auxOptions = options
            if (!more) {
                auxOptions = options.filter(e => e.subMenu === null)
            }
            return auxOptions.map(({left, onClick, subMenu}) => {
                return subMenu === null ? {
                    component: <span
                            onClick={() => {
                                if(onClick) onClick()
                            }} 
                            className={`h4 text-logo`}
                            style={{fontSize: '0.875rem'}}
                        >
                            {left}
                        </span>
                } :
                {
                    component: <span
                    onClick={() => {
                        if(onClick) onClick()
                    }} 
                    className={`h4 text-logo`}
                    style={{fontSize: '0.875rem'}}
                >
                    {left}
                    </span>,
                    subMenu: subMenu.menu
                }
            })
        },
        getOptions
    }
}

export default useOptionsByStatus