import {useQuery, gql} from '@apollo/client'

const useGetPdfTemplates = (vars = {}) => {
    return useQuery(
        gql`
            query (
                $sortAscending: Boolean
                $sortField: String
                $previous: String
                $next: String
                $limit: Int
            ) {
                getPdfTemplates (
                    sortAscending: $sortAscending
                    sortField: $sortField
                    previous: $previous
                    next: $next
                    limit: $limit
                ) {
                    docs {
                        id
                        name
                        json
                        key
                    }
                }
            }
        `,
        {
            variables: vars
        }
    )
}

export default useGetPdfTemplates