import {useQuery, gql} from '@apollo/client'


const useAllTimelinesActivities = (variables = {}) => {
    return useQuery(
        gql`
            query (
                $servicesIds: [String!] = []
            ) {
                allTimelinesActivities (
                    servicesIds: $servicesIds
                ) {
                    id
                    name
                    ... on Test {
                        serviceId
                        service {
                            id
                            name
                        }
                    }
                }
            }
        `,
        {
            variables
        }
    )
}

export default useAllTimelinesActivities