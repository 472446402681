import {useSelector, useDispatch} from 'react-redux'

const useCreateStudentModal = () => {
    const reduxData = useSelector((state) => state.modal.student.createStudentModal)
    const {isOpen} = reduxData
    const dispatch = useDispatch()

    const setData = (isOpen = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'student.createStudentModal',
            data: {
                ...reduxData,
                isOpen,
            }
        })
    }
    return {
        ...reduxData,
        setDataUser: (newDataUser = null) => {
            setData(isOpen, newDataUser)
        },
        open: (dataUser = null) => {
            setData(true, dataUser)
        },
        close: () => {
            setData(false)
        },
        toggle: () => {
            setData(!isOpen)
        }
    }
}

export default useCreateStudentModal