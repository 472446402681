import React from 'react'
import {
  Modal,
  IconClose,
  Button,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import { ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import useDeleteCountry from '../index.js'

const DeleteCountryModal = (props) => {
  const { isOpen, ...otherProps } = useSelector((state) => state.modal.country.delete)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'country.delete', data: { isOpen: !isOpen } })
  }
  const { breakWidth } = useWindowDimensions()
  const translate = (str) => str
  const onSuccess = (result) => {
    toggle()
  }
  const onError = (err) => {
    console.log(err)
  }
  const { submit, loading, validationErrors } = useDeleteCountry(onSuccess, onError)
  return (
    <Modal
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'center'}`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <ModalHeader
          toggle={toggle}
          close={<IconClose size={20} onClick={toggle} />}
          className='mt-md-4'
        >
          <span className='h2 font-weight-bold mr-4'>
            {translate('constants.country.delete')}
          </span>
        </ModalHeader>
      }
    >
      <span className='p-4 d-flex justify-content-center'>{translate('misc.sureAbout')}</span>
      {Object.keys(validationErrors).length ? <span className='p-4 pt-0 d-flex justify-content-center text-error'>{validationErrors.general}</span> : null}
      <span className='d-flex justify-content-around'>
        <Button
          label='misc.no'
          size='medium'
          onClick={toggle}
          activity={loading}
          color='white'
          className='text-logo'
        />
        <Button
          label='misc.yes'
          size='medium'
          color='white'
          onClick={() => submit(otherProps)}
          activity={loading}
          className='text-error'
        />
      </span>
    </Modal>
  )
}

DeleteCountryModal.propTypes = {}

export default DeleteCountryModal
