import {useReducer} from 'react'
import reducer from './reducer'
import {initialState, initialStateMulti} from './initialState'
import {nanoid} from 'nanoid'
import validate from 'validate.js'

const constraints = {
    taskId: {
        presence: true
    },
    taskName: {
        presence: true
    },
    dueTime: {
        presence: true
    },
    dueDate: {
        presence: true
    },
    type: {
        presence: true
    },
    instructions: {
        presence: true
    },
    buttonLabel: {
        presence: true
    }
}

const constraintsForPlan = {
    planId: {
        presence: true
    },
    phaseId: {
        presence: true
    },
    stepId: {
        presence: true
    }
}

const typeTask = {
    completion: 'completion',
    uploadFile: 'uploadFile',
    link: 'link',
    scheduledMeetting: 'scheduledMeetting',
    scoreColletion: 'scoreColletion',
    logoinInfo: 'logoinInfo',
}
const constraintsForType = {
    [typeTask.completion]: {},
    [typeTask.uploadFile]: {
        showInDocs: {
            presence: false
        }
    },
    [typeTask.link]: {
        linkToShow: {
            presence: true
        }
    },
    [typeTask.scheduledMeetting]: {},
    [typeTask.scoreColletion]: {
        selectedExam: {
            presence: true
        }
    },
    [typeTask.logoinInfo]: {},
    'default': {}
}



const useTaskReducer = (multi = false) => { //multi: una task o un arreglo de tasks
    const [state, dispatch] = useReducer(reducer, multi ? initialStateMulti : initialState)
    const actionData = (
        data, 
        key, //[status, ...] 
        action //[set]
        ) => {
        dispatch({
            type: `${action}-${key}`,
            data,
            multi: !!multi
        })
    }
    return {
        state, 
        dispatch,
        setData: (data) => {
            //if(!multi && typeof data !== 'object') throw new Error("Invalid data") 
            //recibe arreglo de datos que será montado en state
            //LA FUNCION SET DATA DEBE RECIBIR LA ENTIDAD COMPLETA PARA EVITAR QUE 
            // EL COMPONENTE SE VUELVA A RENDERIZAR
            const info = multi ? data : {...data, taskId: data?.id || state?.id || data?.taskId || state?.taskId || nanoid()}
            actionData(
                info, 
                'data', 
                'set'
            )
        },
        overwriteTask: (data) => {
            //sobreescribe la tasks con id data.taskId (solo necesario para multi true)
            //conservando las llaves no afectadas
            if(!multi && typeof data !== 'object') throw new Error("Invalid data") 
            actionData(data, 'data', 'overwrite')
        },
        resetData: () => {
            actionData(multi ? initialStateMulti : initialState, 'data', 'reset')
        },
        setToPending: (taskId) => {
            actionData({taskId, status: 'pending'}, 'status', 'set')
        },
        setToCanceled: (taskId) => {
            actionData({taskId, status: 'canceled'}, 'status', 'set')
        },
        addTask: ({taskId = nanoid(), ...task }) => { //solo en multi
            if(multi) actionData({...task, taskId}, 'task', 'add')
        },
        validation: (isCompleted = false) => {
            const completedValidation = isCompleted ? constraintsForPlan : {}
            return validate(state, {...constraints, ...constraintsForType[state?.type || 'default'], ...completedValidation })
        },
        parseToTaskInput: (task) => {
            return {
                // ...task,
                name: task?.taskName,
                instructions: task?.instructions,
                type: task?.type,
                buttonLabel: task?.buttonLabel,
                linkToShow: task?.linkToShow,
                selectedExam: task?.selectedExam?.id,
                accountsPlatform: task?.accountsPlatform,
                status: task?.status,
                userId: task?.userId,
                statusSave: task?.statusSave,
                position: task?.position,
                isEspecific: task?.isEspecific || false,
                isReviewable: false,
                //for uploadFile type
                showInDocs: task?.showInDocs || false, //para mostrar en documentos
                uploadDocs: task?.uploadDocs || false, //Para permitir uplodear files en tasks de cualquier tipo
                startDate: task?.startDate, //fecha de inicio (fecha asignación de tarea)
                dueTime: task?.dueTime, //días de plazo
                dueDate: task?.dueDate, //fecha de entrega
                universities: task?.universities,
                serviceDate: task?.serviceDate || null, //fecha de servicio
                examDate: task?.examDate || null, //fecha de examen
                examId: task?.examId, //id de examen en calendar events, ya que no vamos a usar mas la fecha
            }
        },
        parseTaskFromAPI: (task) => {
            return {
                ...task,
                idReference: task?.id,
                taskId: task?.taskId || task?.id || nanoid(),
                taskName: task.name,
                title: task.name,
                statusBuild: 'normal' 
            }
        }
    }
}

export default useTaskReducer