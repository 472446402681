import { gql, useLazyQuery } from "@apollo/client"

const useLazyEvent = (variables = {}) => {
    return useLazyQuery(
        gql`
            query (
                $activityId: String!
            ) {
                getOneEvent (
                    activityId: $activityId
                ) {
                    name
                    kind
                    startDate

                }
            }
        `,
        {variables}
    )
}

export default useLazyEvent