import React from 'react'
import {
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import useGetExamScores from '../../../../Model/useGetExamScores'

const SelectExamScore = (props) => {
    const {
        onChange = () => {},
        value,
        ...otherProps
    } = props
    const {examScores, loading} = useGetExamScores()
    
    return (
        loading ? <Skeleton count={1} width={375} height={44} /> :
        <InputSelect
            value={value?.id}
            menuPlacement={'top'}
            isClearable={false}
            options={
                examScores?.map(({id, name}) => {
                    
                    return {
                        value: id,
                        label: name
                    }
                })
            } 
            onChange={(e) => {
                //busca el score en examScore
                const exam = examScores.find(({id}) => id === e)
                const {__typename, ...realExam} = exam
                //Guarda score en onChange
                //quita los typename
                const notype = {
                    ...realExam,
                    results: realExam?.results?.map(({__typename: resultType, ...realResult}) => {
                        return {
                            ...realResult,
                            values: realResult?.values?.map(({__typename: valueType, ...realValue}) => {
                                return realValue
                            })
                        }
                    })
                }
                onChange(notype)
            }}
            {...otherProps}
        />
    )    
}

export default SelectExamScore