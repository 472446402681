import React, {useMemo} from 'react'
import { CheckboxSelector, CheckboxSelectorInner, IconArrowDown } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import { Badge } from 'reactstrap'
import usePaginatedServicesQuery from './Model/usePaginatedServicesQuery'
import { ordenateArrayByName } from '../../../../utils/ordenateArrayByName'
const ServiceFilter = (props) => {
    const {label, setFilters = () => {}, filters = [], useInner = false} = props 
    const {translate} = useTranslation()
    const {data, loading} = usePaginatedServicesQuery(
        {limit: 0}
    )
    const services = useMemo(
        () => ordenateArrayByName(data?.paginatedServices?.docs) || [], [data]
    )

    const checkboxSelectorProps = {
      // setDataFilters={setStudentFilters}
      setDataFilters: setFilters,
      dataFilters: filters,
      direction: 'bottomLeft',
      loading: loading,
      data: {/**name, id , Component**/ services},
      typeFilter: 'services',
      strings: {
        viewAll: translate('filters.plans.viewAll'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search')
      }
    }
    return (
      useInner ?
      <CheckboxSelectorInner style={{maxWidth: 'auto'}} {...checkboxSelectorProps}/> :
      <CheckboxSelector {...checkboxSelectorProps}>
        <Badge className='mx-1 filters' pill color='option'>
          <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
            {label || 'Services'}
            <IconArrowDown className='ml-2' size={15} />
          </label>
        </Badge>
      </CheckboxSelector>
    )
}

export default ServiceFilter