import React from 'react'
import {
  ListItem,
  Avatar,
  IconOptions,
  DropDown,
  Input,
  IconSignPlus,
  IconArrowRight,
  IconChecboxSelected,
  IconCheckboxFilled
} from '@Knowledge-OTP/znk-ui-components'
import SelectEducator from '../SelectEducator'
import useTranslation from '../../../../../i18n/useTranslation'

const EducatorsPlan = (props) => {
  const { setForm, form, currentService, topicsToShow, educators: baseEducators } = props
  const { translate } = useTranslation()
  const educators = form.educators || {}
  const allVarAreDefined =
    (
      form.lpw['0']?.startdate &&
      form.lpw[(Object.keys(form.lpw).length - 1).toString()]?.enddate) ||
    (form.typePlan === 'time' && form.typeLesson === 'totalLesson')
  const getBackupLink = (educatorId) => {
    const foundEducator = baseEducators.find((edu) => edu.id === educatorId)
    if (foundEducator) {
      return foundEducator.educatorInfo?.backupLink
    }
  }
  
  return (
    <div className='p-0 mx-0 w-100 mt-6 mb-0'>
      <div className='w-100'>
        <div className='pl-3 pr-6 w-100'>
          <h3><b>{translate('program.plan.infoModal.educators')}</b></h3><br />
          {(currentService && allVarAreDefined) ? currentService.topics.map((topic) => {
            return (
              topicsToShow.indexOf(topic.id) !== -1
                ? <ListItem
                  className='py-1 w-100'
                  id='x-0'
                  label={null}
                  Left={
                    <SelectEducator
                      useFinder
                      multipleSelection
                      idTopic={topic.id}
                      topicName={topic.name}
                      idService={form.idservice || ''}
                      form={form}
                      setEducator={(eduArray) => {
                        const newEduArray = eduArray.map((edu) => {
                          const hasServiceRate =
                            edu?.educatorInfo?.services.filter(
                              (serv) => currentService.id === serv.serviceId
                            )[0]?.topics?.filter(top => top.topicId === topic.id)
                          return {
                            ...edu,
                            educatorUserId: edu.id,
                            rate: hasServiceRate ? hasServiceRate[0].individualRate : 25,
                            isWaiveIndividual: hasServiceRate[0].isWaiveIndividual
                          }
                        })
                        setForm({
                          ...form,
                          educators: {
                            ...form.educators,
                            [topic.id]: newEduArray
                          }
                        })
                      }}
                      educator={educators[topic.id] || {}}
                    >
                      {
                        educators[topic.id]?.length > 0
                          ? 
                            educators[topic.id].map((educator) => {
                              return (
                                <div className='d-flex align-items-center my-1'>
                                  <Avatar
                                    name={`${educator.firstName} ${educator.lastName}`}
                                    named={false}
                                    uri={null}
                                    size='avatar-medium'
                                  />
                                  <span className='ml-2 h4'>
                                    {`${educator.firstName} ${educator.lastName}`} <span className='text-muted'>{`(${topic.name})`}</span>
                                  </span>
                                </div>
                              )
                            })
                          : <span className='btn-link'>{`Recommend ${topic.name} educator`}</span>
                      }
                    </SelectEducator>
                  }
                  Right={
                    educators[topic.id]?.length > 0
                      ? educators[topic.id].map((educator) => {
                        return (
                          <div className='znk-table-dropdown p-0 m-0'>
                            <DropDown
                              classNameMenu='pt-4 px-4 pb-2'
                              direction='bottom'
                              fixedWidth
                              hoverEnabled={false}
                              options={[
                                {
                                  component: ['hangouts', 'zoom'].includes(form.locationPlan)
                                    ? <div className='py-4 px-1'>
                                        <div>
                                          <label className='h4 text-gray mb-3'>Location Link:</label>
                                          <Input
                                            placeholder='http://zoom.com/ASDF'
                                            type='text'
                                            value={form.locationPlan === 'hangouts' ? getBackupLink(educator.educatorUserId) : educator.lessonLink}
                                            onChange={(e) => {
                                              setForm({
                                                ...form,
                                                educators: {
                                                  ...form.educators,
                                                  [topic.id]: form.educators[topic.id].map((edu) => {
                                                    if ( edu.id === educator.id ) return {...edu, lessonLink: e.target.value}
                                                    return edu
                                                  })
                                                }
                                              })
                                            }}
                                            readOnly={form.locationPlan === 'hangouts'}
                                          />
                                        </div>
                                      </div>
                                    : null
                                },
                                {
                                  component:
                                    <div className='py-4 px-1'>
                                      {
                                        !educator.isWaiveIndividual
                                          ? <div>
                                            <label className='h4 text-gray mb-3'>{translate('modal.newplan.educatorwage')} :</label>
                                            <Input
                                              placeholder={translate('modal.newplan.wage')}
                                              type='number'
                                              value={educator.rate}
                                              onChange={(e) => {
                                                setForm({
                                                  ...form,
                                                  educators: {
                                                    ...form.educators,
                                                    [topic.id]: form.educators[topic.id].map((edu) => {
                                                      if ( edu.id === educator.id ) return {...edu, rate: e.target.value}
                                                      return edu
                                                    })
                                                  }
                                                })
                                              }}
                                            />
                                          </div>
                                          : null
                                      }
                                      <label
                                        className='h4 mt-3 btn-link text-gray d-flex flex-row'
                                        onClick={() => {
                                          setForm({
                                            ...form,
                                            educators: {
                                              ...form.educators,
                                              [topic.id]: form.educators[topic.id].map((edu) => {
                                                if ( edu.id === educator.id ) return {...edu, isWaiveIndividual: !educator.isWaiveIndividual}
                                                return edu
                                              })
                                            }
                                          })
                                        }}
                                      >
                                        {
                                          educator.isWaiveIndividual
                                            ? <IconChecboxSelected flex={1} color='#696CFF' className='icon-list-option mr-2' />
                                            : <IconCheckboxFilled flex={1} color='#F0F4F8' className='icon-list-option mr-2' />
                                        } Waive this payments
                                      </label>
                                      <div className='borderTop mt-3 mb-2' />
                                      <label
                                        className='h4 mt-4 btn-link text-error d-flex flex-row'
                                        onClick={() => {
                                          setForm({
                                            ...form,
                                            educators: {
                                              ...form.educators,
                                              [topic.id]: form.educators[topic.id].reduce((newArray, edu) => {
                                                if ( edu.id !== educator.id ) newArray.push(edu)
                                                return newArray
                                              }, [])
                                            }
                                          })
                                        }}
                                      ><IconSignPlus className='mr-2' color='#FF3E1D' />{translate('modal.newplan.removeeducator')}
                                      </label>
                                    </div>
                                }
                              ]}
                            >
                              <div className={'m-0 p-0 hover-icon'}>
                                <IconOptions style={{ transform: 'rotate(90deg)' }} />
                              </div>
                            </DropDown>
                          </div>
                        )
                      })
                      : <SelectEducator
                          useFinder
                          multipleSelection
                          idTopic={topic.id}
                          topicName={topic.name}
                          idService={form.idservice || ''}
                          form={form}
                          setEducator={(edu) => {
                            setForm({
                              ...form, educators: { ...form.educators, [topic.id]: edu }
                            })
                          }}
                          educator={educators[topic.id] || {}}
                        ><IconArrowRight />
                        </SelectEducator>
                  }
                  /> : null
            )
          }) : <label>{translate('plans.modal.finishParams')}</label>}
        </div>
      </div>
      <div className='borderTop ml-3 mt-6' />
    </div>
  )
}

export default EducatorsPlan
