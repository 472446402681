import React, {useState} from 'react'
import { ConfirmModal } from "@Knowledge-OTP/znk-ui-components"
import useDeleteCheckIn from './Model/useDeleteCheckIn'
import { showAlert } from '../../../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../../../i18n/useTranslation'

const DeleteCheckIn = (props) => {
    const {refetch = () => {}, checkinId} = props
    const [isOpen, setOpen] = useState(false)
    const {translate} = useTranslation()
    const toggle = () => {setOpen(!isOpen)}
    const dispatch = useDispatch()
    const onSuccess = () => {
        if(refetch) refetch()
        showAlert({text: translate("program.plan.checkIns.delete.success"), status: 'success'}, dispatch)
        toggle()
    }
    const onError = (err) => {
        showAlert({text: err.toString(), status: 'error'}, dispatch)
    }
    const [deleteCheckIn, {loading}] = useDeleteCheckIn(onSuccess, onError)

    return (
        <>
            <div className='m-0 p-0' onClick={toggle}>{props.children}</div>
            <ConfirmModal
                className=''
                loading={loading}
                classNameActionDiv='flex-column'
                classNameConfirm='text-right text-error'
                classNameCancel='mx-3 text-right'
                isOpen={isOpen}
                toggle={toggle}
                title={translate("program.plan.checkIns.delete.title")}
                body={translate("program.plan.checkIns.delete.body")}
                confirmAction={() => {
                    if(checkinId) {
                        deleteCheckIn({variables: {checkInId: checkinId}})
                    }
                }}
                cancelAction={toggle}
                confirmText={translate("program.plan.checkIns.delete.confirm")}
                cancelText={translate("program.plan.checkIns.delete.cancel")}
            />
        </>
    )
}

export default DeleteCheckIn