import {useLazyQuery, gql} from '@apollo/client'

const useGetExamScoreByService = (variables={}) => {
    return useLazyQuery(
        gql`
            query (
                $serviceId: ID!
            ) {
                getOneExamScoreByService (
                    serviceId: $serviceId
                ) {
                    serviceId
                    examDate
                    overall
                    id
                    _id
                    name
                    results {
                        name
                        scale
                        method
                        isOverallHidden
                        isPrincipal
                        values {
                            name
                            scale
                            isActive
                            isOnMethod
                        }

                    }
                }
            }
        `,
        {
            variables
        }
    )
}

export default useGetExamScoreByService