import React, { useMemo, useState } from 'react'
import moment from 'moment-timezone'
import useMyFreeTimes from '../../../../../../Model/Users/Student/myFreeTimeFrames'
import { Button, UnavailabilityCalendar } from '@Knowledge-OTP/znk-ui-components'
import useBulkAvailabilityUpdate from '../../../../../../Model/Users/Educators/BulkUpdateAvailability'
import isEmpty from 'lodash/isEmpty'
const TemporalUnavailability = (props) => {
  const { formData } = props
  const [calendarMoment, setCalendarMoment] = useState(() => moment().utc().day(0).hours(7).minutes(0).seconds(0).milliseconds(0))
  const [selectedBlocks, setSelectedBlocks] = useState([])
  const freeTimesInput = useMemo(() => {
    return {
      educatorId: formData.educatorUserId,
      from: calendarMoment.toISOString(),
      to: calendarMoment.clone().add(1, 'w').toISOString(),
      involveMyself: false
    }
  }, [calendarMoment, formData])
  const { data, loading } = useMyFreeTimes(freeTimesInput)
  const { submit, loading: submitLoading } = useBulkAvailabilityUpdate(() => setSelectedBlocks([]), console.log)
  const onSubmit = () => {
    submit({
      educatorId: formData.educatorUserId,
      blocks: {
        blocks: selectedBlocks.map(block => ({ time: block.date, isAdding: block.setUnavailable }))
      }
    })
  }
  return (
    <>
      <UnavailabilityCalendar
        loading={loading}
        moment={calendarMoment}
        setMoment={setCalendarMoment}
        selectedBlocks={selectedBlocks}
        addSelected={(item) => setSelectedBlocks((blocks) => blocks.concat(item))}
        removeSelected={(blockId) => setSelectedBlocks((blocks) => blocks.filter(item => item.id !== blockId))}
        availabilityBlocks={!isEmpty(data) ? data : []}
      />
      <Button
        color='primary'
        check
        className='w-100 my-2'
        label={`Save ${selectedBlocks.length} changes`}
        disabled={selectedBlocks.length === 0}
        activity={submitLoading}
        onClick={onSubmit}
      />
    </>
  )
}

export default TemporalUnavailability
