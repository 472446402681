import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ListItem,
  IconCountry,
  IconClose,
  IconArrowRight,
  AvailabilityCalendarRecurring,
  Button,
  CheckboxOption,
  Input
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import { useQuery, gql } from '@apollo/client'
import { showAlert } from '../../../../../../common/Alert/util'
import useTranslation from '../../../../../../i18n/useTranslation'
import SetRecurrentAvailability from '../../../../../../Model/CalendarEvent/SetRecurrentAvailability/index'
import UpdatedAtBy from '../../UpdatedByAt'
import ChangeTimezone from '../../../../../Calendar/modal/ChangeTimezone'

const SetAvailableRecurring = (props) => {
  const { toggleFull, page, educator, isStaff = false } = props
  //const {dataUser} = useSelector((state) => state.modal.user.availabilityRecurring)
  const { userId: idFromState } = useSelector((state) => state.modal.educator.infoEducatorModal)
  const id = educator?.id || idFromState
  const { zone: timezone } = useSelector((state) => state.timezone)
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const [now, setNow] = useState(moment().tz(timezone).toISOString())

  const [query, setQuery] = useState({ id })

  // cambiar query por alguna que obtenga las lecciones de mi usuario como educador
  // const {data} = {data: []}
  //const {data: meData} = useQuery(me)
  const { data: userDataQuery, loading: loadUser, refetch } = useQuery(
    gql`
    query($id: String!) {
        user(id: $id) {
          educatorInfo {
            availabilityBlocks {
                day
                hour
            }
          }
        }
      }`,
    {
      variables: query,
      fetchPolicy: 'network-only'
    }
  )
  const parseAvailable = (lessons) => {
    /**
        {
            day: Float!
            hour: Float!
        }
         * **/
    // parsea el arreglo de availabilitys a un arreglo de
    // objetos con llaves startDate y endDate
    if (lessons?.length === 0) return []
    return lessons.map(({ day, hour }) => {
      const momento = moment()
        .utc()
        .startOf('isoWeek')
        .add(day, 'days')
        .hour(hour)
        .set({ minutes: 0, seconds: 0, milliseconds: 0 })
      return {
        startDate: momento
          .tz(timezone)
          .toISOString(),
        endDate: momento
          .add(1, 'hour')
          .tz(timezone)
          .toISOString(),
        __typename: 'EducatorAvailability',
      }
    })
  }


  useEffect(() => {
    if (page === 'av-recurring' && id && id !== '') {
      //reinicia
      //setNow(moment().tz(timezone).toISOString())
      // queryUser({ variables: { id } })
      setQuery({ id })
    }
    else if (page !== 'av-recurring') {
      //reinicio
      setSelectedBlocks([])
      setUnavailableBlocks([])
    }
  }, [page, id, timezone])
  const availableBlocks = useMemo(() => {
    return parseAvailable(userDataQuery?.user?.educatorInfo?.availabilityBlocks || [])
    // eslint-disable-next-line
  }, [userDataQuery])

  const {
    submit: setRecurringAvailability,
    loading: loadMutation,
  } = SetRecurrentAvailability(
    () => {
      setSelectedBlocks([])
      setUnavailableBlocks([])
      refetch()
      showAlert({
        text: startDate ? 
          translate(`profile.availability.permanent.successMessagePartial`) :
          // 'Recurring availability has been updated partialy, please check the user temporary availability.' : 
          translate(`profile.availability.permanent.successMessage`),
          // 'Recurring availability has been updated',
        status: 'success'
      }, dispatch)

      setApplyParcial(false)
      setStartDate(null)
      // queryUser({ variables: { id: id || '' } })
    },
    (err) => {
      showAlert({
        text: err.toString(),
        status: 'error'
      }, dispatch)
    },
  )

  const [selectedBlocks, setSelectedBlocks] = useState([])
  const [unavailableBlocks, setUnavailableBlocks] = useState([])
  const parseToMutation = () => {
    const originalBlocks = availableBlocks
    return {
      blocks: originalBlocks
        .reduce((newArray, { startDate }) => {
          if (
            unavailableBlocks.reduce((bool, isoDate) => {
              if (
                moment(isoDate).day() === moment(startDate).day() &&
                moment(isoDate).hour() === moment(startDate).hour()
              )
                return false
              return bool
            }, true)
          ) {
            let day = moment(startDate).utc().day()
            newArray.push({
              day: parseFloat(day === 0 ? 6 : day - 1),
              hour: parseFloat(moment(startDate).utc().hour()),
            })
            // se elimina de la lección original
          }
          return newArray
        }, [])
        .concat(
          selectedBlocks.map((block) => {
            let day = moment(block).utc().day()
            return {
              day: parseFloat(day === 0 ? 6 : day - 1),
              hour: parseFloat(moment(block).utc().hour()),
            }
          }),
        ),
    }
  }

  const [applyParcial, setApplyParcial] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const validateFields = useMemo(
    () => {
      return !applyParcial || (applyParcial && startDate)
    }, [applyParcial, startDate]
  )
  return (
    <>
      <div className='m-0 px-8 py-3' style={loadUser ? { cursor: 'wait' } : {}} >
        <div className={'d-flex flex-row justify-content-between'}>
          <span className={'h2 text-title font-weight-bold mb-3'}>{translate(`profile.availability.permanent.title`)}</span>
          {!isStaff && <IconClose color='#D7E1EA' onClick={() => {
            setSelectedBlocks([])
            setUnavailableBlocks([])
            refetch()
            // queryUser({ variables: { id: id || '' } })
            toggleFull()
          }} />}
        </div>
        <AvailabilityCalendarRecurring
          loading={loadUser}
          calendarClassName={'pt-7 pb-0 px-6 mt-3'}
          setMoment={(newMoment) => {
            setNow(newMoment.toISOString())
          }}
          moment={moment(now)}
          isRecurring
          lessons={availableBlocks}
          setSelectedBlocks={setSelectedBlocks}
          selectedBlocks={selectedBlocks}
          setUnavailableBlocks={setUnavailableBlocks}
          unavailableBlocks={unavailableBlocks}
          loadSave={loadMutation}
          saveLabel={translate('profile.actions.save')}
          onSave={() => {
            setRecurringAvailability({
              educatorId: id,
              recurrentAvailability: parseToMutation(),
            })
          }}
          zone={timezone}
          changeTimezone={
            <>
            <ListItem
              showTopLine
              showBottomLine
              onClick={
                () => {
                  dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'lesson.changeTimezoneModal',
                    data: {
                      isOpen: true
                    }
                  })
                }
              }
              Left={
                <div className='m-0 p-0 d-flex flex-row align-items-center'>
                  <IconCountry className='mr-2' />
                  <span>{timezone}</span>
                </div>
              }
              Right={
                <div className='m-0 p-0 d-flex flex-row align-items-center'>
                  <div className='hover-icon px-2 py-1'>
                    <IconArrowRight />
                  </div>
                </div>
              }
            />
            {educator?.educatorInfo?.availabilityUpdated && <UpdatedAtBy educator={educator} />}
            </>
          }
        />
        <div className={`borderTop w-100 my-2`} />
        <div className={`w-100 d-flex flex-row align-items-center justify-content-start my-2`}>
          {/* <div className={`d-flex flex-row align-items-center my-2`}> */}
            <CheckboxOption defaultState={applyParcial} value={applyParcial} onChange={(e) => { setApplyParcial(e) }} className={`mr-2`} /> 
          {/* </div> */}
          <div className={`w-50 d-flex flex-column`}>
            <span className={`text-gray mb-1`}>{translate(`profile.availability.permanent.applyFrom`)}</span>
            <Input onChange={(e) => {
              setStartDate(e?.target?.value)
            }} value={startDate} disabled={!applyParcial} type={'date'} />
          </div>
        </div>

        <div className='d-flex flex-row w-100 justify-content-end pt-3 pb-5'>
          <Button
            activity={loadMutation}
            onClick={() => {
              const variables = {
                educatorId: id,
                recurrentAvailability: parseToMutation(),
              }
              if (applyParcial) {
                if (!startDate) {
                  showAlert({
                    text: translate('profile.availability.permanent.pleaseSelectDate'),
                    status: 'warning',
                  }, dispatch)
                  return
                }
                variables.startDate = new Date(startDate)
              }
              setRecurringAvailability(variables)
            }}
            color='primary'
            disabled={
              (selectedBlocks.length === 0 && unavailableBlocks.length === 0) ||
              !validateFields
            }
            label={translate('profile.actions.save')}
          />
        </div>
      </div>
      <ChangeTimezone />
    </>
  )
}
export default SetAvailableRecurring
