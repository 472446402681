import {useMutation, gql} from '@apollo/client'

const useCreateNote = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`mutation createNote(
            $userId: String!, 
            $content:String!
            ) {
            addNotesToUser(
                userId: $userId, 
                content: $content, 
            ){
                id
            }
        }`,
        {
            onCompleted: onSuccess, onError
        }
    )
}

export default useCreateNote