import React from 'react'
import { 
  ListItem, Input, 
  DropDownNew,
  IconOptions, 
  CheckboxOption
} from '@Knowledge-OTP/znk-ui-components'
import LazyAvatar from '../../../../../../../../common/LazyAvatar'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../../../store/modal/const'
import { useDispatch } from 'react-redux'
import './index.css'

const TypeIndividualEducators = (props) => {
  const { type, form, setForm, currentService, educators: individualEducators, educatorsBase, topics } = props
  const thisTopics = Object.keys(topics)
  const openEducatorModal = (topic, educators, prospectiveParts) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.changeTopicEducator',
      data: {
        isOpen: true,
        topic,
        setEducator: (edus) => {
          setForm(pre => ({
            ...pre,
            educatorsIndividualFull: individualEducators.filter(edu => edu.topicId !== topic.id).concat(edus)
          }))
        },
        serviceId: form.idservice,
        prospectiveParts: prospectiveParts,
        currentEducators: educators,
        type: 'individual'
      }
    })
  }
  const allVarAreDefined =
    (
      form.lpw['0'].startdate &&
      form.lpw[(Object.keys(form.lpw).length - 1).toString()].enddate) ||
    (form.typePlan === 'time' && form.typeLesson === 'totalLesson')
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const removeEducator = (element) => {
    const { educatorUserId: educatorId, topicId } = element
    setForm(pre => ({
      ...pre,
      educatorsIndividualFull: pre.educatorsIndividualFull.filter(edu => edu.educatorUserId !== educatorId || (edu.educatorUserId === educatorId && edu.topicId !== topicId))
    }))
  }
  const getLink = (educatorId) => {
    if (educatorsBase && educatorsBase.length === 0) {
      return ''
    }
    const base = educatorsBase.filter(element => element.id === educatorId)
    if (base.length === 0) {
      return ''
    }
    const link = base[0].educatorInfo ? base[0].educatorInfo.backupLink ? base[0].educatorInfo.backupLink : '' : ''
    return link
  }
  return (
    <>
      <h5 className='font-weight-light text-gray text-uppercase my-2'>{type}</h5>
      {!allVarAreDefined || !currentService ? (
        <label>{translate('plans.modal.finishParams')}</label>
      ) : !thisTopics.length ? (

        <label>{translate('modal.newplan.recommendEducators.empty')}</label>
      ) : (
        currentService.topics.map((topic) => {
          const filter = form.educatorsIndividualFull.filter(e => e.topicId === topic.id)
          const openModal = () => openEducatorModal(topic, filter, topics[topic.id].prospectiveHours)
          return (
            thisTopics.includes(topic.id)
              ? filter.length > 0
                ? <div className='d-flex flex-column'>
                  {
                    filter.map(
                      item => {
                        return (
                          <div className='flex-grow-1' key={item.id}>
                            <ListItem
                              className='py-1'
                              id='x-0'
                              Left={
                                <div className='d-flex align-items-center' onClick={openModal}>
                                  <LazyAvatar
                                    userId={item?.id || item?.educatorUserId}
                                    name={`${item.firstName} ${item.lastName}`}
                                    named={false}
                                    uri={null}
                                    size='avatar-medium'
                                  />
                                  <label className='ml-2 h4 btn-link text-info cursor-pointer'>
                                    {`${item.firstName} ${item.lastName}`} <span className='text-muted'>{`(${topic.name})`}</span>
                                  </label>
                                </div>
                              }
                              Right={
                                <div className='znk-table-dropdown p-0 m-0'>
                                  <DropDownNew
                                    classNameMenu='pt-4 px-4 pb-2'
                                    direction='bottomRight'
                                    fixedWidth
                                    hoverEnabled={false}
                                    options={[
                                      {
                                        component: ['hangouts', 'zoom'].includes(form.locationPlan)
                                          ? <div className='py-4 px-2'>
                                            <div>
                                              <label className='h4 text-gray mb-3'>Location Link:</label>
                                              <Input
                                                placeholder={form.locationPlan === 'hangouts' ? 'http://hangouts.com/ASDF' : 'http://zoom.com/ASDF'}
                                                type='text'
                                                value={form.locationPlan === 'hangouts' ? getLink(item.educatorUserId) : item.backupLink}
                                                readOnly={form.locationPlan === 'hangouts'}
                                                onChange={(e) => {
                                                  const educators = form.educatorsIndividualFull
                                                  educators.forEach(
                                                    ele => {
                                                      if (ele.educatorUserId === item.educatorUserId) {
                                                        ele.backupLink = e.target.value
                                                      }
                                                    }
                                                  )
                                                  setForm({
                                                    ...form,
                                                    educatorsIndividualFull: educators
                                                  })
                                                }}
                                              />
                                            </div>
                                          </div>
                                          : null
                                      },
                                      {
                                        component:
                  <div className='py-1 px-2'>
                    {
                      !form.educatorsIndividualFull.filter(e => e.educatorUserId === item.educatorUserId)[0].isWaiveIndividual
                        ? <div>
                          <label className='h4 text-gray mb-2'>{translate('modal.newplan.educatorwage')} :</label>
                          <Input
                            placeholder={translate('modal.newplan.wage')}
                            type='number'
                            value={item.rate}
                            onChange={(e) => {
                              const educators = form.educatorsIndividualFull
                              educators.forEach(
                                ele => {
                                  if (ele.educatorUserId === item.educatorUserId) {
                                    ele.rate = e.target.value
                                  }
                                }
                              )
                              setForm({
                                ...form,
                                educatorsIndividualFull: educators
                              })
                            }}
                          />
                        </div>
                        : null
                    }
                    <label
                      className='h4 mt-3 btn-link text-gray d-flex flex-row'
                    >
                      <CheckboxOption
                        defaultState={form.educatorsIndividualFull.filter(e => e.educatorUserId === item.educatorUserId)[0].isWaiveIndividual}
                        onChange={() => {
                          const educators = form.educatorsIndividualFull
                        educators.forEach(
                          ele => {
                            if (ele.educatorUserId === item.educatorUserId) {
                              ele.isWaiveIndividual = !ele.isWaiveIndividual
                            }
                          }
                        )
                        setForm({
                          ...form,
                          educatorsIndividualFull: educators
                        })
                        }}
                      />
                      Waive this payments
                    </label>
                  </div>
                                      },
                                      {
                                        component:
                  <div className='py-1 px-1'>
                    <div className='borderTop my-2' />
                    <label className='h4 text-center btn-link text-error cursor' onClick={() => removeEducator(item)}>Remove educator</label>
                  </div>
                                      }
                                    ]}
                                  >
                                    <div className={`hover-icon`}>
                                      <IconOptions className='rotate-90' />
                                    </div>
                                  </DropDownNew>
                                </div>
                              }
                            />
                          </div>
                        )
                      }
                    )
                  }
                  <span className='btn-link text-primary mt-2 mb-0' onClick={openModal}>Add more recommended {topic.name} educator</span>
                </div>
                : <label className='btn-link' onClick={openModal}>{translate(`modal.newplan.recommendEducators.${type}`, { name: topic.name })}</label>
              : null
          )
        })
      )}
    </>
  )
}

export default TypeIndividualEducators
