import { useLazyQuery, gql } from "@apollo/client"
import {templateQueryResult} from './queryResults/templateQueryResult'

const useGetTaskTemplate = (fetchPolicy = 'no-cache') => {
    return useLazyQuery(
        gql`
            query ($templateId: String!) {
                getTaskTemplate (templateId: $templateId) {
                    ${templateQueryResult}
                }
            }
        `
    )
}

// id
//                     name
//                     deleteAt
//                     phases {
//                         phaseName
//                         steps {
//                             id
//                             stepName
//                             deleteAt
//                             tasks {
//                                 id
//                                 name
//                                 instructions
//                                 type
//                                 dueTime
//                                 buttonLabel
//                                 linkToShow
//                                 accountsPlatform
//                                 isEspecific
//                                 isReviewable
//                                 deleteAt
//                             }
//                         }
//                     }

export default useGetTaskTemplate