import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonListItem = (props) => {
    const {className=''} = props
    return (
        <Skeleton className={`w-100 ${className}`} style={{borderRadius: '12px'}} height={125} />
    )
}

export default SkeletonListItem