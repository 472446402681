import { IconArrowLeft } from '@Knowledge-OTP/znk-ui-components'
import React from 'react'

const Plot = (props) => {
    const {onBack = () => {}} = props
    return (
        <div>
            <div className={`d-flex flex-row w-100 justify-content-start m-2`}>
                <div className={`hover-icon`} onClick={onBack}><IconArrowLeft size={16} className={`text-gray`}/></div>
            </div>
            <span>PLOT</span>
        </div>
    )
}

export default Plot