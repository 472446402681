import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
import userQueryResult from '../QueryResults/userQueryResult'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation update($updatedUser: UpdateUserInput!, $userId: ID!){
    updateUser(input: $updatedUser, id: $userId) {
      ${userQueryResult}
    }
  }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    delete obj.updatedUser.phoneDialCode
    delete obj.updatedUser.phoneNumber
    delete obj.updatedUser.birthYear
    delete obj.updatedUser.birthMonth
    delete obj.updatedUser.birthDay
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      const errorMessage = e.message || 'Unknown error occurred';
      setValidationErrors({ general: 'misc.apiError' })
      onError(errorMessage)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.updateUser : null, error, validationErrors, submit }
}
