export const getUsersIdsFromPlan = (usersListFromPlan) => {

    if (!Array.isArray(usersListFromPlan)) return []
    const studentsIds = []

    if (usersListFromPlan?.length < 1) return
    // eslint-disable-next-line
    usersListFromPlan.map((plan) => {
        studentsIds.push(plan.studentUser.studentUserId)
    }
    )
    return [...new Set(studentsIds)]
}