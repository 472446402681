import React from 'react'
import './index.css'

const Switch = ({ isOn, handleToggle, onColor, idComponent }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className='react-switch-checkbox'
        id={idComponent}
        type='checkbox'
      />
      <label
        style={{ background: isOn && onColor }}
        className='react-switch-label'
        htmlFor={idComponent}
      >
        <span className='react-switch-button' />
      </label>
    </>
  );
};

export default Switch;