import { useDispatch, useSelector } from "react-redux"

const useConfirmCloseModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.confirmCloseModal)
    const {isOpen, onChange} = reduxData
    const setOpen = (open, onChange = () => {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.confirmCloseModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange
            }
        })
    }
    return {
        onChange,
        isOpen,
        open: (onChange = () => {}) => {
            setOpen(true, onChange)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        }
    }
}

export default useConfirmCloseModal