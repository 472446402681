import {useMutation, gql} from '@apollo/client'
// import planQueryResult from './queryResults/planQueryResult'

const useCompletePlan = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
        mutation (
            $planId: String!
            $pendingLessonsId: [String!]!
            ) {
            completePlan (
                planId: $planId
                pendingLessonsId: $pendingLessonsId 
            )  {
                id
            }
        }
        `,
        {
            onCompleted: (res) => {
                onSuccess(res)
            },
            onError
        }
    )
}

export default useCompletePlan