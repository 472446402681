import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { totalLessonPart } from '../../../GroupPlans/modal/New/utils'
import useTranslation from '../../../../../i18n/useTranslation'
const PlanIndividualSchedule = (props) => {
  const { lpw, setForm } = props
  const change = (val, part, topicId) => {
    setForm(form => ({
      ...form,
      lpw: {
        ...form.lpw,
        [part]: {
          ...form.lpw[part],
          valueIndividual: {
            ...form.lpw[part].valueIndividual,
            [topicId]: {
              ...form.lpw[part].valueIndividual[topicId],
              lessons: val,
              totalLessons: totalLessonPart({ startdate: form.lpw[part].from, enddate: form.lpw[part].to, [topicId]: { valueIndividual: val } })
            }
          }
        }
      }
    }))
  }
  const { translate } = useTranslation()
  return (
    <div className='d-flex flex-column w-100'>
      <h3 className='font-weight-bold text-dark my-3'>{translate('plans.modal.scheduleDetails')}</h3>
      {
        lpw && !isEmpty(lpw) ? (
          Object.entries(lpw).map(([partIdx, partInfo]) => {
            const individual = Object.entries(partInfo.valueIndividual)
            return (
              <div className='d-flex flex-column'>
                <h4 className='font-weight-bold my-2'>{partInfo.name}</h4>
                {
                  individual.length ? (
                    individual.map(([topicId, values]) => {
                      return (
                        <>
                          <div className='font-weight-light text-gray mb-1 mt-2'>{translate('modal.newplan.scheduledetails.individualPerWeek', { name: values.name })}</div>
                          <div className='row m-0'>
                            <div
                              className='col-3 znk-input text-center d-flex justify-content-center align-items-center' onClick={() => {
                                change(
                                  values.lessons
                                    ? values.lessons - 1 : 0, partIdx, topicId)
                              }} style={{ userSelect: 'none', borderRadius: '12px 0px 0px 12px' }}
                            >-
                            </div>
                            <input
                              id={topicId}
                              className='znk-input col-5'
                              type='number'
                              name={`lpw['${topicId}']`}
                              placeholder='0'
                              min={0}
                              onChange={(e) => {
                                change(parseInt(e.target.value), partIdx, topicId)
                              }}
                              style={{ textAlignLast: 'center', borderRadius: '0px', margin: '0px 3px' }}
                              value={values.lessons}
                            />
                            <div
                              className='col-3 znk-input text-center d-flex justify-content-center align-items-center' onClick={() => {
                                change(
                                  values.lessons
                                    ? values.lessons + 1 : 1, partIdx, topicId
                                )
                              }} style={{ userSelect: 'none', borderRadius: '0px 12px 12px 0px' }}
                            >+
                            </div>
                          </div>
                          <div className='font-weight-light text-gray my-2'>{translate('modal.newplan.scheduledetails.willHaveLessons', { total: values.totalLessons })}</div>
                        </>
                      )
                    })
                  ) : <span className='font-weight-light text-gray align-items-center my-4'>{translate('plans.modal.noLessonsThisPart')}</span>
                }
              </div>
            )
          })
        ) : null
      }
      <div className='borderTop my-2' />
    </div>
  )
}

export default PlanIndividualSchedule
