import { gql, useLazyQuery } from '@apollo/client'
import { IconClose } from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useState } from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import useRemoveWeek from '../../../../../Model/Users/RemoveWeek'
import queryLessonResult from '../../../../Calendar/Model/useQueryCalendarView/queryLessonResult'
import ShowWeek from './ShowWeek'

export const WeeksBlock = (props) => {
    const {toggleFull, userId, timezone} = props
    const [weeks, setWeeks] = useState([])    
    const [getWeeks, { data }] = useLazyQuery(
        gql`query($userId: ID!){
          getWeeksBlock(userId: $userId) {
            startDate
            endDate
            isActive
            lessons {
                lessons {
                    ${queryLessonResult}
                }
            }
          }
        }`,
        {
          variables: { userId },
          fetchPolicy: 'network-only'
        }
      )
    const {translate} = useTranslation()
    useEffect(() => {
        if (userId) {
            getWeeks(userId)
        }
        // eslint-disable-next-line 
    }, [])
    useEffect(() => {
        if (data) {
            setWeeks(data.getWeeksBlock)
        }
    }, [data])
    const {submit} = useRemoveWeek(
        (data) => {
            const response = data.data.removeWeek
            document.getElementById(`wek-${moment(response.startDate).valueOf()}`).remove()            
        },
        console.log
    )
    return (
        <>
            <div className='m-0 px-8 py-3'>
                <div className={'d-flex flex-column'}>
                    <div className='d-flex flex-row justify-content-between'>
                        <span className={'h2 text-title font-weight-bold mb-3'}>{translate(`profile.infoeducator.weeksblock`)}</span>
                        <IconClose color='#D7E1EA' onClick={() => {
                            toggleFull()
                        }} />
                    </div>
                    <span className={'h3 font-weight-light text-secondary mb-4'}>{translate(`profile.infoeducator.weeksblockinfo`)}</span>

                </div>
                <div className='pt-2'>
                    {
                        weeks.map((element, index) => (
                            <ShowWeek week={element} timezone={timezone} userId={userId} submit={submit} />
                        ))
                    }
                </div>
            </div>
        </>
    )
}
