export const types = [
  { id: 'studyplan', name: 'Study Plan', type: ['invoice'] },
  { id: 'lessonsPayment', name: 'Lesson Payment', type: ['invoice', 'payslip'] },
  { id: 'otherFixed', name: 'Other Fixed', type: ['invoice', 'counseling'] },
  { id: 'marathon', name: 'Marathon', type: ['invoice'] },
  { id: 'examFee', name: 'Exam Fee', type: ['invoice'] },
  { id: 'other', name: 'Others', type: ['all'] },
  { id: 'content', name: 'Content', type: ['payslip'] },
  { id: 'reimbursement', name: 'Reimbursement', type: ['payslip'] },
  { id: 'counselingPayment', name: 'Counseling Plan', type: ['counseling'] },
  { id: 'applicationFee', name: 'Application Fee', type: ['counseling'] },
  { id: 'scoreSendingFee', name: 'Score Sending Fee', type: ['counseling'] },
  { id: 'additionalApplication', name: 'Additional Application', type: ['counseling'] },
  { id: 'zinkerzCamp', name: 'Zinkerz Camp', type: ['invoice'] },  
]

export const categories = [
  { id: 'livePrep', name: 'livePrep' },
  { id: 'counseling', name: 'Counseling' }
]
