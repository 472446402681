import { useQuery, gql } from "@apollo/client"

const useGetUserStatus = (vars = {}) => {
    return useQuery(
        gql`
            query (
                $id: String!
            ) {
                user(id: $id) {
                    isActive
                }
            }
        `, {variables: vars}
    )
}

export default useGetUserStatus