import React from 'react'
import { Avatar } from '@Knowledge-OTP/znk-ui-components'
import StatusField from './Status'
import ServiceField from './Service'

const CompactPlanInfoField = props => {
  const { studentUser, status, thisService, item = {} } = props
  return (
    <span className='d-flex w-100'>
      <Avatar
        name={`${studentUser.firstName} ${studentUser.lastName}`}
        named={false}
        uri={studentUser?.avatar || null}
        size='avatar-medium'
        className='d-flex align-items-center'
      />
      <div className='d-flex flex-column justify-content-between flex-grow-1 ml-4'>
        <div className='font-weight-normal text-dark'>{`${studentUser.firstName} ${studentUser.lastName}`}</div>
        <div className='d-flex align-items-center'>
          <span className='d-flex flex-row-reverse align-items-center text-gray font-weight-light'>
            <StatusField plan={item} status={status} />
          </span>
          <span className='text-gray font-weight-light ml-2'>
            <ServiceField thisService={thisService} />
          </span>
        </div>
      </div>
    </span>
  )
}

CompactPlanInfoField.propTypes = {

}

export default CompactPlanInfoField
