import { useLazyQuery, gql } from "@apollo/client"
import planQueryResult from "./queryResults/planQueryResult"
const useLazyPlan = (vars = {}, options = {}) => {
    return useLazyQuery(
        gql`
            query ($planId: ID!) {
                plan (planId: $planId) {
                    ${planQueryResult} 
                }
            }
        `,
        {
            variables: vars,
            ...options,
        }
    )
}

export default useLazyPlan