import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation (
            $payslipId: ID!,
            $input: CreateManualPayslipInput!
        ) {
            editPayslip(
                payslipId: $payslipId,
                input: $input
            ){
              resultMutation,
              isPayslipEmpty
            }
        }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.editPayslip : null, error, validationErrors, submit }
}
