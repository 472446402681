import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {
    useWindowDimensions,
    ToolTipInfoLight, IconSeen, IconOptions,
    IconSignPlus, IconReminder, IconErase, IconSignCheck
} from '@Knowledge-OTP/znk-ui-components'
import useCreateTimelineModal from '../../../../../../../../Timelines/Modal/CreateTimelineModal/useCreateTimelineModal'
import useDismissPlan from '../../../../Modal/DismissPlan/useDismissPlan'
import usePostponeReminder from '../../../../Modal/PostponeRemider/usePostponeReminder'
import { getTopicIdsInPlan } from '../../../../../../../../Plans/Fields/Topics'
import Skeleton from 'react-loading-skeleton'
import {showAlert} from '../../../../../../../../../common/Alert/util'
import useCompletePlanModal from '../../../../../../../../Plans/modal/CompletePlan/useCompletePlan'

const OptionsField = (props) => {
    const {className, plan, loading = false, refetch = () => {}, header = false} = props
    const services = useSelector((state) => state.user.services)
    const {open: openDismissPlan} = useDismissPlan()
    const {open: openPostponeReminder} = usePostponeReminder()
    const {createIndividualPlan} = useCreateTimelineModal()
    const {open: openCompletePlan, close: closeCompletePlan} = useCompletePlanModal()
    const {breakWidth} = useWindowDimensions()
    
    const dispatch = useDispatch()
    const getServiceById = (serviceId) => {
        return (services || [])?.find((service) => service.id === serviceId) || null
    }
    const openViewPlanModal = () => {
        const service = getServiceById(plan.serviceId)
        const topics = getTopicIdsInPlan(plan.parts || plan.fixedParts, plan.kind)
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'plan.seeInfo',
            data: {
              isOpen: true,
              info: plan,
              ...{
                thisService: service,
                thisTopics: topics,
                queryData: [],
                queryVar: {}
              }
            }
        })
    }
    return (
        <>
            {
                loading ?
                    <span className={`d-flex flex-row align-items-center ${className}`}>
                        <Skeleton count={1} width={16} height={16} style={{borderRadius: '50%'}}/>
                    </span> : 
                    header ? 
                    <span className={`h45 text-gray d-flex flex-row align-items-center ${className}`}></span> : 
                    
                    <span className={`h45 text-gray d-flex flex-row align-items-center ${className}`}>
                        {
                            breakWidth === 'SM' ? 
                            <div className='hover-icon'><IconOptions className={`rotate-90`}/></div>:
                            <>
                            {
                            ['scheduled', 'sent'].includes(plan?.status) ? //planes finalizados o enviados tiene modal
                                <div className={`d-flex flex-row align-items-center`}>
                                    <ToolTipInfoLight text={`Complete plan`}>
                                        <div className='hover-icon' onClick={() => {
                                            openCompletePlan(() => {
                                                refetch()
                                                closeCompletePlan()
                                            }, plan, true /**useMutation**/)
                                            }}>
                                            <IconSignCheck className={'text-primary'} size={16}/>
                                        </div>
                                    </ToolTipInfoLight>
                                    <ToolTipInfoLight text={`View plan`}>
                                        <div className={`hover-icon`} onClick={() => {
                                            openViewPlanModal()
                                        }}>
                                            <IconSeen size={16} />  
                                        </div>
                                    </ToolTipInfoLight>
                                </div>
                            :
                            <div className={`d-flex flex-row align-items-center`}>
                                <ToolTipInfoLight text={`Create plan`}>
                                    <div className={`hover-icon mr-1`} onClick={() => {
                                        createIndividualPlan(plan, () => {
                                            showAlert({
                                                text: 'LivePrep Plan created successfully!',
                                                status: 'success'
                                            }, dispatch)
                                            refetch()
                                        })
                                    }}>
                                        <IconSignPlus className={`text-primary rotate-45`} size={16}/>
                                    </div>
                                </ToolTipInfoLight>
                                <ToolTipInfoLight text={`Postpone reminder`}>
                                    <div className={`hover-icon`} onClick={() => {
                                        openPostponeReminder(() => {
                                            refetch()
                                        }, plan)
                                    }}>
                                        <IconReminder className={`text-gray`} size={16}/>
                                    </div>
                                </ToolTipInfoLight>
                                <ToolTipInfoLight text={`Dismiss plan`}>
                                    <div className={`hover-icon`} onClick={() => {openDismissPlan(() => {
                                        refetch()
                                    }, plan)}}>
                                        <IconErase className={`text-error`} size={16}/>
                                    </div>
                                </ToolTipInfoLight>
                                {/* </ToolTipInfo> */}
                            </div>
                            }
                            </>
                            

                        }
                    </span>
            }
        </>
    )
}

export default OptionsField