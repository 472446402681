import React from 'react'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../store/modal/const'
import moment from 'moment'
import { IconCircleDolar, IconComment, IconEdit, IconEmail, IconUser } from '@Knowledge-OTP/znk-ui-components'
import useStaffModal from '../Staff/useStaffModal'

const UseSelectorField = (item, type, refetch) => {
  const dispatch = useDispatch()
  const { open: openStaffModal } = useStaffModal()
  const ViewProfile = (user) => {
    if (type === 'educator') {
      //ABRIR MODAL DE EDUCADOR
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'educator.infoEducatorModal',
        data: {
          isOpen: true,
          full: false,
          userId: item.id,
          type: type,
          initPage: 'profile',
          pageProfile: 'full-profile',
        }
      })
    } else if (type === 'student') {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'student.infoStudentModal',
        data: {
          isOpen: true,
          dataStudent: user,
          initPage: 'profile',
          full: false,
          profilePage: 'viewProfile'
        }
      })
    } else if (type === 'parent') {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'parent.infoParentModal',
        data: {
          isOpen: true,
          userId: user.id,
          type: type,
          dataParent: user,
          initPage: 'profile',
          full: false,
          profilePage: 'viewProfile'
        }
      })
    } else if (type === 'staff') {
      openStaffModal(user, () => {
        refetch()
      })
    }
  }

  const editUser = (user) => {
    const { phone, birthdate } = user
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.edit',
      data: {
        isOpen: true,
        ...user,
        currentIsActive: user.isActive,
        phoneDialCode: phone ? phone.split(' ')[0] : '',
        phoneNumber: phone ? phone.split(' ')[1] : '',
        birthDay: birthdate ? moment(birthdate).date() : '',
        birthMonth: birthdate ? moment(birthdate).month() : '',
        birthYear: birthdate ? moment(birthdate).year() : '',
        email: user.emails[0].address,
        timezone: { value: user.timezone, label: user.timezone },
        country: { value: user.country.id, label: user.country.name, defaultStudentRate: user.country.defaultStudentRate }
      }
    })
    if (type === 'educator') {
      //ABRIR MODAL DE EDUCADOR
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'educator.infoEducatorModal',
        data: {
          isOpen: true,
          userId: item.id,
          type: type,
          initPage: 'profile',
          pageProfile: 'edit-profile',
          full: true
        }
      })
    } else {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'student.infoStudentModal',
        data: {
          isOpen: true,
          dataStudent: user,
          initPage: 'profile',
          full: true,
          profilePage: 'editProfile'
        }
      })
    }
  }

  const setStudentServicesRate = (user, type) => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRateShort', data: { isOpen: true } })
    dispatch(
      {
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'user.servicesRate',
        data: {
          isOpen: true,
          typeUser: type,
          userId: user.id
        }
      }
    )
    if (type === 'educator') {
      //ABRIR MODAL DE EDUCADOR
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'educator.infoEducatorModal',
        data: {
          isOpen: true,
          userId: item.id,
          type: type,
          initPage: 'profile',
          pageProfile: 'student-form',
          full: true
        }
      })
    } else {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'student.infoStudentModal',
        data: {
          isOpen: true,
          dataStudent: user,
          initPage: 'profile',
          full: true,
          profilePage: 'setServiceRate'
        }
      })
    }
  }

  const sendAddPaymentMethod = (user) => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.addPaymentMethod', data: { isOpen: true, userId: user.emails[0].address, type: 'payment' } })
  }

  const setActivate = item => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.setActivate',
      data: {
        isOpen: true,
        dataUser: item
      }
    })
  }
  // *
  // const editUserEducatorUnavailability = (user) => {
  //   dispatch(
  //     {
  //       type: SET_DATA_MODAL_FORM_LIST,
  //       payload: 'user.setEducatorUnavailability',
  //       data: {
  //         isOpen: true,
  //         educatorUserId: user.id,
  //         availabilityBlocks: user.educatorInfo.availabilityBlocks || []
  //       }
  //     }
  //   )
  // }
  // const editUserEducatorInfo = (user) => {
  //   const { educatorInfo } = user
  //   dispatch(
  //     {
  //       type: SET_DATA_MODAL_FORM_LIST,
  //       payload: 'user.setEducatorInfo',
  //       data: {
  //         isOpen: true,
  //         id: user.id,
  //         currentIsActive: educatorInfo ? educatorInfo.isActive : false,
  //         educatorInfo,
  //         ...(educatorInfo ? pick(educatorInfo, ['defaultRate', 'backupLink', 'isActive']) : {
  //           defaultRate: 0,
  //           backupLink: '',
  //           isActive: false
  //         })
  //       }
  //     }
  //   )
  // }
  // *
  // const editUserNotificationInfo = (user) => {
  //   const { studentInfo } = user
  //   dispatch(
  //     {
  //       type: SET_DATA_MODAL_FORM_LIST,
  //       payload: 'user.notificationHub',
  //       data: {
  //         isOpen: true,
  //         id: user.id,
  //         studentInfo
  //       }
  //     }
  //   )
  // }
  // const setParentsInfo = (user) => {
  //   const { studentInfo } = user
  //   dispatch(
  //     {
  //       type: SET_DATA_MODAL_FORM_LIST,
  //       payload: 'user.addParentToStudent',
  //       data: {
  //         isOpen: true,
  //         userId: user.id,
  //         parents: studentInfo && studentInfo.parents ? studentInfo.parents : []
  //       }
  //     }
  //   )
  // }
  // const seeInvoicesHistory = (user) => {
  //   dispatch(
  //     {
  //       type: SET_DATA_MODAL_FORM_LIST,
  //       payload: 'user.invoices',
  //       data: {
  //         isOpen: true,
  //         userId: user.id
  //       }
  //     }
  //   )
  // }

  // const userOptions = [{
  //   component: <div className='separator'>All Users</div>
  // }, {
  //   component: <span onClick={() => editUser(item)}>Edit Personal</span>
  // }]
  // userOptions.push({
  //   component: (
  //     <>
  //       <hr className='m-0' />
  //       <div className='separator'>Educator</div>
  //     </>
  //   )
  // })
  // userOptions.push({
  //   component: <span onClick={() => editUserEducatorInfo(item)}>Edit Educator Info</span>
  // })
  // userOptions.push({
  //   component: <span onClick={() => editUserEducatorUnavailability(item)}>Edit Educator Availability</span>
  // })
  // userOptions.push({
  //   component: (
  //     <>
  //       <hr className='m-0' />
  //       <div className='separator'>Student</div>
  //     </>
  //   )
  // })
  // userOptions.push({
  //   component: <span onClick={() => editUserStudentInfo(item)}>Edit Academic Info</span>
  // })
  // userOptions.push({
  //   component: <span onClick={() => editUserNotificationInfo(item)}>Edit Notifications Info</span>
  // })
  // userOptions.push({
  //   component: <span onClick={() => setStudentServicesRate(item)}>Edit specific Service Rates</span>
  // })
  // userOptions.push({
  //   component: <span onClick={() => setParentsInfo(item)}>Edit Parents Info</span>
  // })
  // userOptions.push({
  //   component: (
  //     <>
  //       <hr className='m-0' />
  //       <div className='separator'>Parent</div>
  //     </>)
  // })
  // userOptions.push({
  //   component: <span onClick={() => seeInvoicesHistory(item)}>See Invoices History</span>
  // })

  let userOptions = [{
    component:
      <span>
        <IconEdit size={16} color='gray' className='mr-2' />
        <div style={{ whiteSpace: 'nowrap' }} onClick={() => { editUser(item) }}>Edit Personal</div>
      </span>
  }]
  switch (type) {
    case 'student':
      userOptions.push({
        component:
          <span>
            <IconComment size={16} color='gray' className='mr-2' />
            <div style={{ whiteSpace: 'nowrap' }} onClick={() => { }}>Add Note</div>
          </span>
      })
      userOptions.push({
        component:
          <span>
            <IconCircleDolar size={16} color='gray' className='mr-2' />
            <div style={{ whiteSpace: 'nowrap' }} onClick={() => { setStudentServicesRate(item, 'student') }}>Services Rates</div>
          </span>
      })
      //     userOptions.push({
      //       component:
      // <span>
      //   <IconCreditCard size={16} color='gray' className='mr-2' />
      //   <div style={{ whiteSpace: 'nowrap' }} onClick={() => {}}>Change payment method</div>
      // </span>
      //     })
      //     userOptions.push({
      //       component:
      // <span>
      //   <IconCountry size={16} color='gray' className='mr-2' />
      //   <div style={{ whiteSpace: 'nowrap' }} onClick={() => {}}>Change time zone</div>
      // </span>
      //     })
      userOptions.push({
        component:
          <span>
            <IconEmail size={16} color='gray' className='mr-2' />
            <div style={{ whiteSpace: 'nowrap' }} onClick={() => { sendAddPaymentMethod(item) }}>Request Payment Method</div>
          </span>
      })
      userOptions.push({
        component:
          <span>
            <IconUser size={16} color={item.isActive ? '#FF3E1D' : '#696CFF'} className='mr-2' />
            <div className={item.isActive ? 'text-error' : 'text-primary'} style={{ whiteSpace: 'nowrap' }} onClick={() => setActivate(item)}>{item.isActive ? 'Inactivate User' : 'Activate User'}</div>
          </span>
      })
      break
    case 'educator':
      userOptions.push({
        component:
          <span>
            <IconCircleDolar size={16} color='gray' className='mr-2' />
            <div style={{ whiteSpace: 'nowrap' }} onClick={() => { setStudentServicesRate(item, 'educator') }}>Employment Details</div>
          </span>
      })
      break
    case 'staff':
      userOptions = []
      break;
    default:
      break
  }
  return ({
    mainBtnConfig: {
      text: 'View Profile',
      onClick: () => ViewProfile(item)
    },
    options: userOptions
  })
}

export default UseSelectorField
