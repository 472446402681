import moment from 'moment'
import { lastGradeEnum } from '../Modal/newPlanModal/Sections/Student/grades'

export const getValues = (date, type) => {
    const month = moment(date).month()
    let year = moment(date).year()
    if (month >= 7 && month <= 11) {
      year += 1
    }
    const difY = year - moment().year()
    switch (type) {
      case 'class':
        return year.toString().substring(2, 4)
      default:
        return lastGradeEnum - difY + 1
    }
}

export default {
    getValues
}