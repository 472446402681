import {useMutation, gql} from '@apollo/client'

const useDismissStudent = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation ($studentId: ID!) {
                dismissStudent (studentId: $studentId)
            }
        `,
        {
            onCompleted: onSuccess, onError
        }
    )
}

export default useDismissStudent