import { useDispatch, useSelector } from "react-redux"

const useDeleteTaskModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.deleteTaskModal)
    const {isOpen, onChange} = reduxData

    const setOpen = (open, onChange = () => {}, taskData = {}, useMutation = false, loading = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.deleteTaskModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                useMutation,
                taskData,
                loading
            }
        })
    }
    const setLoad = (load) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.deleteTaskModal',
            data: {
                ...reduxData,
                loading: load
            }
        })
    }

    return {
        ...reduxData,
        onChange,
        isOpen,
        open: (onChange = () => {}, taskData = {}, useMutation = false) => {
            setOpen(true, onChange, taskData, useMutation)
            // setLoad(false)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
        setLoad
    }
}

export default useDeleteTaskModal