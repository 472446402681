import React, {useState, useEffect} from 'react'
import {
  Modal,
  IconClose,
  Button,
  LabeledField,
  PlainTextInput,
  Field,
  useWindowDimensions,
  IconSeen,
} from '@Knowledge-OTP/znk-ui-components'
import {ModalHeader} from 'reactstrap'
import useTranslation from '../../../../../../../i18n/useTranslation'
import updateAccountFromUser from '../../../../../../../Model/Users/AccountsFromUser/EditAccount/updateAccountFromUser'
import {useDispatch} from 'react-redux'
import getAllAccountsFromUser from '../../../../../../../Model/Users/AccountsFromUser/AllAccountsFromUser/getAllAccountsFromUser'
import { showAlert } from '../../../../../../../common/Alert/util'

const AccountEdit = (props) => {
  const {isOpen, toggle, refetch = () => {}, user, account} = props
  const dispatch = useDispatch()

  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  // eslint-disable-next-line
    const { data: allAccountsData, loading: allAccountsLoading } = getAllAccountsFromUser()

  function passwordVisibility() {
    const passVisibility = document.getElementById('pass')
    if (passVisibility.type === 'password') {
      passVisibility.type = 'text'
    } else {
      passVisibility.type = 'password'
    }
  }
  const {
    submit: updateAccountMutation,
    loading: updateAccountLoading,
    // eslint-disable-next-line
    // data: updateAccountData
  } = updateAccountFromUser(
    () => {
      showAlert({text: 'Success!', status: 'success'}, dispatch)
      refetch()
    },
    (err) => {
      showAlert({text: err.toString(), status: 'error'}, dispatch)
      console.error(err.toString())
    },
  )

  // const onChangeFunction = (value) => {
  //   const filterItem = allAccountsData.filter((item) => {
  //     return item?.platform === value
  //   })
  //   const object = filterItem[0]
  //   setAccount(object)
  // }
  const [accountName, setAccountName] = useState(null)
  useEffect(
    () => {
      setAccountName(account?.username)
    },
    // eslint-disable-next-line
  [account?.username])

  if (user) {
    return (
      <>
        <Modal
          className={`modal-${
            breakWidth === 'SM' ? 'botbar' : 'sidebar'
          } d-flex flex-column`}
          backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
          isOpen={isOpen}
          toggle={toggle}
          Head={
            <ModalHeader
              className='px-0 py-1 mb-1'
              toggle={toggle}
              close={
                <div className='flex-grow-1 d-flex flex-row justify-content-end'>
                  <div className='hover-icon w-min-content'>
                    <IconClose size={20} onClick={toggle} />
                  </div>
                </div>
              }
            >
              <div className='d-flex flex-column'>
                <span className='h2 font-weight-bold'>
                  {translate('profile.actions.accounts.editKey')}
                </span>
              </div>
            </ModalHeader>
          }
        >
          <>
            <div className='w-100 pr-2 mb-2 mt-2 h45 text-gray'>Account</div>
            <Field
              id='platform'
              className='w-100'
              type={LabeledField}
              component={PlainTextInput}
              fieldName='account'
              placeholder={translate('profile.actions.accounts.select')}
              value={account?.platform}
              label={translate('profile.actions.accounts.account')}
              isDisabled
            />

            <div className='w-100 pr-2 mb-2 mt-4 h45 text-gray'>
              {translate('profile.actions.accounts.user')}
            </div>
            <Field
              style={{
                outline: 'none',
              }}
              id='user'
              className='my-1 w-100'
              type={LabeledField}
              component={PlainTextInput}
              fieldName='user'
              placeholder=''
              value={accountName}
              fieldType='text'
              label={null}
              onChange={(value) => {
                setAccountName(value)
              }}
            />

            <div className='w-100 pr-2 mb-2 mt-4 h45 text-gray'>
              {translate('counseling.password')}
            </div>
            <div
              className='w-100 pr-2 pl-3 d-flex flex-row mb-3'
              style={{
                border: 'none',
                backgroundColor: '#f0f4f8',
                borderRadius: '14px',
                height: '44px',
                outline: 'none',
              }}
            >
              <input
                className='w-100 d-flex flex-row'
                style={{
                  border: 'none',
                  borderWidth: '0px',
                  backgroundColor: '#f0f4f8',
                  width: '90%',
                  height: '100%',
                  outline: 'none',
                }}
                type='password'
                id='pass'
                name='pass'
                defaultValue={account?.password}
              />
              <IconSeen
                style={{cursor: 'pointer'}}
                className='align-self-center'
                color='#73819e'
                onClick={() => passwordVisibility()}
              />
            </div>
            <Button
              label='Save'
              color='primary'
              activity={updateAccountLoading}
              check
              size='large'
              onClick={() =>
                updateAccountMutation({
                  userId: user?.id,
                  accountInput: {
                    platformName: account?.platform,
                    username:
                      accountName || document.getElementById('user')?.value,
                    password: document.getElementById('pass')?.value,
                  },
                })
              }
            />
          </>
        </Modal>
      </>
    )
  } else return null
}

export default AccountEdit
