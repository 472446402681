import React, { useMemo, useState, useEffect, useRef } from 'react'
import Pagination from '../../../../common/Pagination'
import {
    IconSearch,
    TableTask,
    IconArrowRight,
    IconArrowLeft,
    IconClose,
    IconSignPlus,
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Badge
} from 'reactstrap'
import { Collapse } from 'reactstrap'
import LayoutSideRightSection from '../../Components/LayoutViewRightSection'
import "../../index.css"
import StatusFilters from './Filters/Status'
import PhasesFilters from './Filters/Phases'
import StudentsFilters from './Filters/Students'
import UniversitiesFilters from './Filters/Universities'
import AssignByFilters from './Filters/AssignBy'
import usePaginatedTasksForCounselor from '../../Model/usePaginatedTasksForCounselor'
import useViewTaskModal from '../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import useNewTaskModal from '../../../CounselingTemplates/Task/newTaskModal/useNewTaskModal'
import useAssignTaskModal from '../../../CounselingTemplates/Task/assingTaskModal/useAssignTaskModal'
import useDeleteTaskModal from '../../../CounselingTemplates/Task/deleteTaskModal/useDeleteTaskModal'
import lodash from 'lodash'
import useColumns from './useColumns'

import StudentWithoutTask from './Sections/StudentWithoutTasks'
// import WorkspaceSteps from './Sections/WorkspaceSteps'
// import NextTimelinePlan from './Sections/NextTimelinePlan'

import NewPlan from '../../../Plans/modal/newPlan'

import SearchByUrl from './Components/SearchByUrl'
import { useSelector } from 'react-redux'
import useAssignMultipleTasks from './Components/AssignMultipleTasks/useAssignMultipleTasks'
import useTranslation from '../../../../i18n/useTranslation'

const TaskOperation = {
    edit: 'edit',
    new: 'new',
    duplicate: 'duplicate',
    assign: 'assign',
    delete: 'delete',
    multiAssign: 'multi-assign'
}

const TasksSection = (props) => {
    const { PanelSection } = props
    const {translate} = useTranslation()
    const {selectedTasks = [], unselectAll, open: openMultiAssignTask} = useAssignMultipleTasks()
    const { breakWidth } = useWindowDimensions()
    const meUserId = useSelector((state) => state.user.id)
    const [isOpen, setOpen] = useState(true)
    useEffect(() => {
        //parte con la sección derecha cerrada
        if (breakWidth === 'SM') setOpen(false)
    }, [breakWidth])
    const toggle = () => { setOpen(!isOpen) }
    const mobile = false
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [status, setStatus] = useState(['review', 'pending'])
    const [phases, setPhases] = useState([])
    const [students, setStudents] = useState([])
    const [assignBy, setAssignBy] = useState([meUserId])
    const [universities, setUniversities] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    const searchInput = useRef(null)

    const { data, loading, refetch } = usePaginatedTasksForCounselor({
        limit: limit,
        page: page,
        status,
        phases,
        users: students,
        universities,
        search: searchValue,
        assignBy
    })
    useEffect(
        () => {
            refetch({
                limit: limit,
                page: page,
                status,
                phases,
                users: students,
                universities,
                search: searchValue,
                assignBy
            })
            // eslint-disable-next-line
        }, [limit, page, status, phases, searchValue, students, universities, assignBy]
    )
    useEffect(
        () => {
            setPage(0)
        }, [status, phases, students, universities, assignBy]
    )

    const paginatedTasks = useMemo(() => {
        return data?.paginatedTasksForCounselor
    }, [data])

    const allTasks = useMemo(() => {
        // setPage(0)
        return paginatedTasks?.docs?.map(({ task, ...other }) => {
            return {
                ...task,
                ...other, //planId, phaseId, stepId, taskId, dataPlan, user
            }
        }) || []
    }, [paginatedTasks])

    const { config, renderRow } = useColumns(refetch)

    // eslint-disable-next-line
    const { open: openViewTaskModal, openForCreatedTask: openCreatedTaskModal, close: closeViewTaskModal } = useViewTaskModal()
    const { close: closeNewTaskModal, open: openNewTaskModal } = useNewTaskModal()
    const { close: closeAssignTaskModal } = useAssignTaskModal()
    const { close: closeDeleteTaskModal } = useDeleteTaskModal()

    const onChangeTask = (action) => {
        switch (action) {
            case TaskOperation.edit:
                closeNewTaskModal()
                break
            case TaskOperation.new:
                closeNewTaskModal()
                break
            case TaskOperation.duplicate:
                break
            case TaskOperation.assign: //ejecuta mutación en modal assignTaskModal
                //luego de asignar la tarea
                //setea info de task en taskViewModal
                closeAssignTaskModal()
                break
            case TaskOperation.delete: //ejecuta mutación en modal deleteTaskModal
                closeDeleteTaskModal()
                closeViewTaskModal()
                break
            case TaskOperation.multiAssign: //ejecuta mutación en modal deleteTaskModal
                //unselectAll()
                break
            default:
                break
        }
        refetch()
    }


    const canReset = () => {
        return !lodash.isEqual({ status, students, universities, assignBy }, {
            status: ['review', 'pending'],
            students: [],
            universities: [],
            assignBy: [meUserId]
            // phases,
        })
    }
    const resetFilters = () => {
        setStatus(['review', 'pending'])
        setStudents([])
        setUniversities([])
        setPage(0)
        setAssignBy([meUserId])
    }


    return (
        <>
            <LayoutSideRightSection
                leftSide={
                    <>
                        <div className={`col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between`} style={{ minHeight: '100%' }}>
                            {!mobile && (
                                <div
                                    className='znk-option-item'
                                    style={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                    }}
                                    onClick={toggle}
                                >
                                    {isOpen ? (
                                        <IconArrowRight size={15} />
                                    ) : (
                                        <IconArrowLeft size={15} />
                                    )}
                                </div>
                            )}
                            <div className={`d-flex flex-column m-0 p-0`}>
                                <PanelSection />
                                <div className={`d-flex ${breakWidth === 'SM' ? 'flex-column' : 'flex-row'} my-4 justify-content-between`}>
                                    <div className={`${breakWidth === 'SM' ? 'w-100' : 'w-75'} horizontal-scroll d-flex flex-row`} style={{ alignItems: 'flex-end' }}>
                                        <div className={`d-flex flex-column m-0 p-0`}>
                                            <div style={{ width: '0px' }} className={`d-flex flex-row m-0 p-0 align-items-center pr-4`}>
                                                {/* FILTERS */}
                                                <StatusFilters filters={status} setFilters={setStatus} />
                                                <StudentsFilters filters={students} setFilters={setStudents} />
                                                <UniversitiesFilters filters={universities} setFilters={setUniversities} />
                                                <AssignByFilters filters={assignBy} setFilters={setAssignBy} />
                                                {
                                                    canReset()
                                                        ? <Badge
                                                            className='mx-1 filters cursor-pointer' pill color='option'
                                                            onClick={() => { resetFilters() }}
                                                        >
                                                            <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                                                                <IconSignPlus size={15} className='mr-1' />
                                                                Clear filters
                                                            </label>
                                                        </Badge>
                                                        : null
                                                }
                                            </div>
                                            <div className={`p-0 mx-0 mb-0 d-flex flex-row mt-2`}>
                                                <PhasesFilters filters={phases} setFilters={setPhases} />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div
                                        style={{ alignSelf: 'flex-end' }}
                                        className={`d-flex flex-row justify-content-end m-0 p-0 ${breakWidth === 'SM' ? 'w-80' : 'w-20'} left-gradient`}>
                                        <InputGroup className={`znk-card w-100 flex-nowrap p-0`} title={'Search'} >
                                            <input
                                                className={"znk-input noRight trans form-control"}
                                                placeholder={'Search'}
                                                ref={searchInput}
                                                onKeyUp={({ key }) => {
                                                    if (key === 'Enter') {
                                                        //buscar
                                                        setSearchValue(searchInput.current.value)
                                                    }
                                                }}
                                            />
                                            <InputGroupAddon addonType='prepend' className='icon'>
                                                <InputGroupText type='text' className='znk-no-border'>
                                                    <span className='addon'><IconSearch size={18} className='text-gray' /></span>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </div>
                                <Collapse isOpen={selectedTasks.length > 0}>
                                    <div className='w-100 d-flex flex-row justify-content-between align-items-center'>
                                        <span className='h4 text-info'>
                                            {`${selectedTasks.length} selected tasks`}
                                        </span>
                                        <div className='d-flex flex-row align-items-center justify-content-end'>
                                            <span 
                                                onClick={() => {
                                                    openMultiAssignTask(() => {
                                                        onChangeTask(TaskOperation.multiAssign)
                                                    })
                                                }}
                                            className='h45 btn-link text-primary'>
                                                {translate("workspace.tasks.assignMultiTask.assignTasksOption")}
                                            </span>
                                            <span onClick={unselectAll} className='ml-2 h45 btn-link text-error'>
                                                {translate("workspace.tasks.assignMultiTask.unSelectAllOption")}
                                            </span>
                                        </div>
                                    </div>
                                </Collapse>
                                <div className={`m-0 p-0`}>
                                    <TableTask
                                        canCreated
                                        onClick={(task) => {
                                            openCreatedTaskModal(
                                                { ...task, isEdit: true, taskName: task?.name },
                                                onChangeTask,
                                                true /**useMutation**/)
                                        }
                                        }
                                        onClickCreate={() => {
                                            openNewTaskModal(() => {
                                                onChangeTask(TaskOperation.new)
                                            }, {}, true /**useMutation**/, true /**completeTask**/)
                                        }}
                                        loading={loading}
                                        className={`my-2 cursor-pointer`}
                                        config={config}
                                        rows={allTasks.map(renderRow)}
                                    />
                                </div>
                            </div>
                        </div>
                        <Pagination
                            className={`fix-element-bottom w-100 d-flex flex-row justify-content-between px-2`}
                            pageSize={allTasks?.length}
                            changeSize={(limit) => { setLimit(limit) }}
                            clickPrevious={() => {
                                if (paginatedTasks?.hasPrevious) setPage(page - 1)
                            }}
                            clickNext={() => {
                                if (paginatedTasks?.hasNext) setPage(page + 1)

                            }}
                            loading={loading}
                            hasPrevious={paginatedTasks?.hasPrevious}
                            hasNext={paginatedTasks?.hasNext}
                            activePage={page + 1}
                            pageCount={Math.ceil((paginatedTasks?.totalDocs || 0) / limit)}
                        />
                        <SearchByUrl onChange={
                            (searchUrl) => {
                                if (searchInput?.current) {
                                    searchInput.current.value = searchUrl
                                    setSearchValue(searchInput.current.value)
                                }
                            }} />
                    </>
                }
                rightSide={
                    <div className='m-0 p-0 d-flex flex-column justify-content-start p-5 pl-6 h-100'>
                        <div className={`w-100 d-flex flex-row justify-content-end`}>
                            <div onClick={() => { setOpen(false) }} className={`hover-icon`}>
                                <IconClose className={`text-gray`} size={20} />
                            </div>
                        </div>
                        {/* <WorkspaceSteps className={`my-2 pl-4`}/> */}
                        {/* <NextTimelinePlan limit={5} className={`my-2 pl-0`} /> */}
                        <StudentWithoutTask className={`pl-4`} onClickStudent={(res) => {
                            setStudents([res.user.id])
                        }} />
                    </div>
                }
                isOpen={isOpen}
                // styleLeftSection={{ overflowY: 's' }}
            />

            {/**usado para crear planes desde listado de timelines (scción derecha o modal)**/}
            <NewPlan refetch={() => {
                //onChange se ejecuta dentro de NextTimelinePlan por redux
            }} educators={[]} />
        </>
    )
}



export default TasksSection