import React from 'react'
import {
  TimezoneSelector,
  IconClose
} from '@Knowledge-OTP/znk-ui-components'
import UpdateUser from '../../../../../../Model/Users/Edit/index'

const ChangeTimezone = (props) => {
  const { toggle, currentTimezone, userId } = props
  const { submit: updateUserMutation } = UpdateUser(
    () => {
      // alert("success")
    },
    (error) => {
      console.error(error)
      // alert("error")
    }
  )
  return (
    <div className='w-100 py-5 px-8'>
      <span className='d-flex w-100 flex-row justify-content-between'>
        <h2 className='font-weight-bold'>Change Timezone</h2>
        <IconClose size={20} onClick={toggle} color='rgba(119, 134, 153, .4)' />
      </span>
      <div className='container-fluid flex-column my-2'>
        <TimezoneSelector
          selected={currentTimezone}
          mutation={(newTimezone) => {
            updateUserMutation({
              updatedUser: { timezone: newTimezone },
              userId: userId
            })
          }}
          className='text-title'
        />
      </div>
    </div>
  )
}

export default ChangeTimezone
