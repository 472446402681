import React, {useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {
    IconClose,
    ListItem,
    IconRight,
    IconAdd,
    IconOptions,
    DropDownNew,
    DropDownItem,
    IconKey,
  } from '@Knowledge-OTP/znk-ui-components'
  import {TabContent, TabPane} from 'reactstrap'
  import useTranslation from '../../../../../../i18n/useTranslation'
  import getAllAccountsFromUser from '../../../../../../Model/Users/AccountsFromUser/AllAccountsFromUser/getAllAccountsFromUser'
  import AccountInfo from '../AccountsModal/accountInfo'
  import AccountEdit from '../AccountsModal/accountEdit'
  import AccountRemove from '../AccountsModal/accountRemove'
  import AccountCreate from '../AccountsModal/accountCreate'

const ViewAccounts = (props) => {
  const { toggle, user } = props
  const {translate} = useTranslation()
  const [account, setAccount] = useState({})
  const [accountInfoModalOpen, setAccountInfoModalOpen] = useState(false)
  const [accountCreateModalOpen, setAccountCreateModalOpen] = useState(false)
  const [accountEditModalOpen, setAccountEditModalOpen] = useState(false)
  const [accountRemoveModalOpen, setAccountRemoveModalOpen] = useState(false)

  const {
    data: allAccountsData,
    loading: allAccountsLoading,
    refetch: allAccountsRefetch,
  } = getAllAccountsFromUser({
    userId: user?.id
  })

  return (
    <div className='w-100 py-5 px-8'>
      <span className='d-flex w-100 flex-row justify-content-between'>
        <h2 className='font-weight-bold'>View Accounts</h2>
        <IconClose size={20} onClick={toggle} color='rgba(119, 134, 153, .4)' />
      </span>
      <div className='container-fluid flex-column my-2'>
      {false ? null : (
              <div>
                <ListItem
                  className='borderTop w-100 m-0 px-2'
                  hasHover
                  Left={
                    <div>
                      <IconAdd size={20} color='#696bff' />
                      <span className='ml-5 h4 text-primary pl-1'>
                        {translate('profile.actions.accounts.addKey')}
                      </span>
                    </div>
                  }
                  Right={
                    <div className='hover-icon'>
                      <IconRight size={20} />
                    </div>
                  }
                  onClick={() => {
                    setAccountCreateModalOpen(true)
                  }}
                />
                <TabContent activeTab={allAccountsLoading ? 'loading' : 'show'}>
                  <TabPane tabId='loading'>
                    <Skeleton count={5} height={60} className='my-1' />
                  </TabPane>
                  <TabPane tabId='show'>
                    {(allAccountsData || []).map((account) => {
                      return (
                        <ListItem
                          className='borderTop w-100 m-0 px-2'
                          Left={
                            <div>
                                <IconKey size={16}/>
                              <span
                                className='ml-5 h4'
                                style={{color: '#293e58'}}
                                onClick={() => {
                                  setAccount(account)
                                  setAccountInfoModalOpen(true)
                                }}
                              >
                                {account.platform}
                              </span>
                            </div>
                          }
                          Right={
                            <DropDownNew
                              hoverEnabled
                              direction='bottomCenter'
                              classNameMenu='m-0 p-0'
                              options={[
                                {
                                  component: (
                                    <DropDownItem
                                      onClick={() => {
                                        setAccount(account)
                                        setAccountEditModalOpen(true)
                                      }}
                                    >
                                      Edit Account
                                    </DropDownItem>
                                  ),
                                },
                                {
                                  component: (
                                    <DropDownItem
                                      onClick={() => {
                                        setAccount(account)
                                        setAccountRemoveModalOpen(true)
                                      }}
                                      className='text-error'
                                    >
                                      Remove Account
                                    </DropDownItem>
                                  ),
                                },
                              ]}
                            >
                              <div className='hover-icon'>
                                <IconOptions
                                  className='rotate-90'
                                  size={20}
                                />
                              </div>
                            </DropDownNew>
                          }
                        />
                      )
                    })}
                  </TabPane>
                </TabContent>
              </div>
            )}
        <AccountInfo
          isOpen={accountInfoModalOpen}
          toggle={() => setAccountInfoModalOpen(!accountInfoModalOpen)}
          user={user}
          account={account}
          setAccount={setAccount}
        />
        <AccountEdit
          isOpen={accountEditModalOpen}
          toggle={() => setAccountEditModalOpen(!accountEditModalOpen)}
          user={user}
          account={account}
          setAccount={setAccount}
          refetch={() => {
            allAccountsRefetch()
            setAccountEditModalOpen(false)
          }}
        />
        <AccountRemove
          isOpen={accountRemoveModalOpen}
          toggle={() => {
            setAccountRemoveModalOpen(!accountRemoveModalOpen)
          }}
          user={user}
          account={account}
          refetch={() => {
            allAccountsRefetch()
          }}
        />
        <AccountCreate
          isOpen={accountCreateModalOpen}
          toggle={() => setAccountCreateModalOpen(!accountCreateModalOpen)}
          user={user}
          account={account}
          setAccount={setAccount}
          refetch={() => {
            allAccountsRefetch()
            setAccountCreateModalOpen(false)
          }}
        />
      </div>
    </div>
  )
}

export default ViewAccounts
