import React from 'react'
import {
  IconClose,
  IconLesson,
  IconRight,
  InvoiceCard,
  ListItem,
  Modal,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useTranslation from '../../../../../../i18n/useTranslation'

const InvoiceModal = (props) => {
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const { modalOpen, toggle, invoice } = props
  return (
    <Modal
      buttonLabel={null}
      className={`modal-colored modal-${
        breakWidth === 'SM' ? 'botbar' : 'sidebar'
      } upcoming-modal`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={modalOpen}
      toggle={toggle}
      Head={
        <div className='modal-header p-0 px-lg-8 px-xl-10 d-flex flex-column'>
          <InvoiceCard
            type='normal'
            text={moment(invoice.from).format('MMMM')}
            typePayment={<IconClose size={20} onClick={toggle} />}
            className='mt-1'
            amount={invoice.paymentItems
              .filter((item) => !item.draft)
              .reduce((sum, current) => sum + current.amount, 0)}
            invoice='INV-00998372'
          />
          <ListItem
            className='w-100 mx-4 border-top border-white'
            Left={
              <span className='text-white'>
                {translate('misc.paymentMethod')}
              </span>
            }
            Right={
              <span className='text-white'>
                <IconRight className='text-white' />
              </span>
            }
          />
        </div>
      }
    >
      <div className='px-4 pt-4 px-sm-6 pt-md-6 px-lg-8 pt-lg-7 px-xl-10 d-flex flex-column'>
        <span className='h3'>
          <IconLesson />
          <span className='font-weight-bold'>&nbsp;Live Prep&nbsp;</span>
          Vivamus sagittis lacus vel augue laoreet rutrumucibus dolor auctor.
        </span>
        <span className='w-100 p-0 py-4'>
          <ListItem
            Left={
              <span className='h4'>{translate('profile.payments.item')}</span>
            }
            Right={
              <span className='h4'>{translate('profile.payments.amount')}</span>
            }
          />
          {invoice.paymentItems.map((item, key) => {
            return (
              <ListItem
                key={key}
                Left={
                  <span className='h4 font-weight-light text-secondary'>
                    {item.description}
                  </span>
                }
                Right={
                  <span className='h4 font-weight-light text-secondary'>
                    ${item.amount}
                  </span>
                }
              />
            )
          })}
          <ListItem
            Left={
              <span className='h4 font-weight-bold'>
                {translate('profile.payments.total')}
              </span>
            }
            Right={
              <span className='h4 font-weight-bold'>
                $
                {invoice.paymentItems
                  .filter((item) => !item.draft)
                  .reduce((sum, current) => sum + current.amount, 0)}
              </span>
            }
          />
        </span>
        <span className='h5 text-secondary'>
          {translate('profile.payments.paymentDetails')}
        </span>
        <span />
      </div>
    </Modal>
  )
}

export default InvoiceModal
