import { useSelector, useDispatch } from 'react-redux'

const useSendOnePlanModal = () => {
    const reduxData = useSelector((state) => state.modal.counseling.sendOnePlanModal)
    const { isOpen, dataPlan, onChange } = reduxData
    const dispatch = useDispatch()
    const setOpen = (open, dataPlan, onChange = () => {}, useMutation = false, loading = false, refetch = () => {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.sendOnePlanModal',
            data: {
                ...reduxData,
                isOpen: open,
                dataPlan: dataPlan,
                onChange: onChange,
                useMutation,
                loading,
                refetch
            }
        })
    }
    const setLoad = (load = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.sendOnePlanModal',
            data: {
                ...reduxData,
                loading: load
            }
        })
    }

    return {
        ...reduxData,
        isOpen,  
        dataPlan, 
        onChange,
        setLoad,
        open: (plan = {}, onChange = () => {}, useMutation = false, refetch = () => {}) => {
            setOpen(true, plan, onChange, useMutation, false, refetch)
        },
        close: () => {
            setOpen(false, dataPlan)
        },
        toggle: () => {
            setOpen(!isOpen, dataPlan)
        }
    }
}

export default useSendOnePlanModal