import {useMutation, gql} from '@apollo/client'

const useCreateAccount = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation ($accountName: String!) {
                createAccount (
                    accountName: $accountName
                ) {
                    id
                    name
                }
            }
        `,
        {
            onCompleted: onSuccess,
            onError
        }
    )
}

export default useCreateAccount