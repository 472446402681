import {gql, useQuery} from '@apollo/client'
import userQueryResult from '../../../Model/Users/QueryResults/userQueryResult'

const useGetUsers = (vars = {}) => {
    return useQuery(
        gql`query(
            $role: Roles!, 
            $limit: Int, 
            $previous: String, 
            $next: String, 
            $search: String, 
            $serviceIds: [ID!], 
            $topicIds: [ID!], 
            $testDate: DateTime, 
            $classYear: [Int!], 
            $countriesIds: [ID!], 
            $statuses: Boolean, 
            $showInactive: Boolean
        ) {
          allUsersFilter(role: $role, limit: $limit, previous: $previous, next: $next, search: $search, serviceIds: $serviceIds, topicIds: $topicIds, testDate: $testDate, classYear: $classYear, countriesIds: $countriesIds, statuses: $statuses, showInactive: $showInactive) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs
            docs {
              ${userQueryResult}  
            }
          }
        }
      `,
      {
        variables: vars,
        fetchPolicy: 'cache-first'
      }
    ) 
}

export default useGetUsers