import React, {useState} from 'react'
import {
    ModalLayout,
    InputSelect,
    useWindowDimensions,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import usePostponeReminder from './usePostponeReminder'
import usePostponeReminderTimelinePlan from '../../Model/usePostponeReminderTimelinePlan'
import { showAlert } from '../../../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import './index.css'
const PostponeReminderModal = () => {
    const {isOpen, toggle, plan, close, onChange = () => {}} = usePostponeReminder()
    const dispatch = useDispatch()
    const {breakWidth} = useWindowDimensions()
    const [postponeReminder, {loading}] = usePostponeReminderTimelinePlan(
        (res) => {
            showAlert({text: 'Success!', status: 'success'}, dispatch)
            if(onChange) onChange()
            close()
        }, (err) => {
            showAlert({text: 'Error!', status: 'error'}, dispatch)
            console.error(err)
        }
    )
    const [timeKey, setTimeKey] = useState(null)
    return (
        <ModalLayout
            className={`modal-colored modal-centered fixed-head-modal ${['SM'].includes(breakWidth) ? 'bottom-modal' : ''}`}
            isOpen={isOpen}
            toggle={toggle}
            disabledScrollAnimation
            topHead={<span className='text-title h2 font-weight-bold'>Postpone Reminder</span>}
        >
            <label className={`text-gray h45`}>Reminder me on:</label>
            <InputSelect
                value={timeKey}
                menuPlacement={['SM'].includes(breakWidth) ? 'top' : 'bottom'}
                options={[
                    {value: '2-days', label: '2 days'},
                    {value: '7-days', label: '1 weeks'},
                    {value: '1-month', label: '1 months'},
                ]}
                onChange={setTimeKey}
            />

            <Button 
                disabled={!timeKey}
                activity={loading}
                onClick={() => {
                    const [cant, key] = timeKey.split('-')
                    const variables = {
                        planId: plan.id, 
                        newRemiderDate: moment().add(parseInt(cant), key).startOf('day').toISOString()
                    }
                    postponeReminder({variables})
                }}
                color={'primary'} className={`w-100 px-4 mt-6 mb-4`} label={'Set reminder'}/>

        </ModalLayout>
    )
}

export default PostponeReminderModal