import React, { useState } from 'react'
import {
  Activity,
  Modal,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import useSetUserNotificationInfo from '../../../../../../Model/Users/Student/setUserNotificationInfo'
import useModalState from '../../../../../../utils/useModalState'
import useTranslation from '../../../../../../i18n/useTranslation'

const ToggleTimedReminder = (props) => {
  const { userId, refetch } = props
  const { isOpen, modalData, toggle: modalToggle } = useModalState(
    'user.notificationChange'
  )
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const [isChanging, setChanging] = useState(false)
  const toggle = () => {
    setChanging(false)
    modalToggle()
  }
  const onSuccess = ({ data: { setUserNotificationInfo: { studentInfo } } }) => {
    toggle()
    refetch(studentInfo)
  }
  const { submit, loading } = useSetUserNotificationInfo(onSuccess, console.log)
  const { reminderValue, reminderKey, reminderType, prevNotifs } = modalData
  const onSubmit = (num) => {
    submit({
      notificationInfo: {
        ...prevNotifs,
        [reminderKey]: { isAvailable: true, duration: num }
      },
      userId
    })
  }
  const onSubmitRemove = () => {
    submit({
      notificationInfo: {
        ...prevNotifs,
        [reminderKey]: { isAvailable: false, duration: 30 }
      },
      userId
    })
  }
  return (
    <Modal
      buttonLabel={null}
      className={`modal-float modal-${
        breakWidth === 'SM' ? 'botbar' : 'center'
      } modal-hauto`}
      StyleModalBody={{ backgroundColor: 'white' }}
      isOpen={isOpen}
      toggle={toggle}
      Foot={
        <span
          className='bg-white py-5 text-muted font-weight-light text-center w-100'
          style={{ borderRadius: '12px', border: 'transparent' }}
          onClick={toggle}
        >
          {translate('misc.cancel')}
        </span>
      }
    >
      <div className='d-flex flex-column align-items-center pt-2'>
        <span className='h3 font-weight-bold py-4 px-7'>
          {translate('profile.actions.changeNotifications.reminderSet')}
        </span>
        <span className='h3 font-weight-light text-secondary text-center pb-5 px-7'>
          {translate(
            `profile.actions.changeNotifications.reminderSet${reminderType}`
          )}
        </span>
        {isChanging ? (
          <>
            <span
              className='modal-selectable primary'
              onClick={() => onSubmit(15)}
            >
              {translate(
                'profile.actions.changeNotifications.reminderMinutes',
                15
              )}
            </span>
            <span
              className='modal-selectable primary'
              onClick={() => onSubmit(30)}
            >
              {translate(
                'profile.actions.changeNotifications.reminderMinutes',
                30
              )}
            </span>
            <span
              className='modal-selectable primary'
              onClick={() => onSubmit(60)}
            >
              {translate(
                'profile.actions.changeNotifications.reminderMinutes',
                60
              )}
            </span>
          </>
        ) : reminderValue ? (
          <>
            <span
              className='modal-selectable primary'
              onClick={() => setChanging(true)}
            >
              {translate('profile.actions.changeNotifications.reminderChange')}
            </span>
            <span
              className='modal-selectable error'
              onClick={() => onSubmitRemove()}
            >
              {loading ? (
                <Activity />
              ) : (
                translate('profile.actions.changeNotifications.reminderRemove')
              )}
            </span>
          </>
        ) : (
          <span
            className='modal-selectable primary'
            onClick={() => setChanging(true)}
          >
            {translate('profile.actions.changeNotifications.reminderActivate')}
          </span>
        )}
      </div>
    </Modal>
  )
}

export default ToggleTimedReminder
