import React, {useMemo} from 'react'
import {
    ModalLayout
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import StudentList from '../../Components/StudentList'
import StudentBlank from '../../Components/StudentBlank'
import useAllStudentModal from './useAllStudentModal'
import useStudentWithoutTasks from '../../../../../../Model/useStudentWithoutTasks'

const AllStudentModal = () => {
    const {isOpen, toggle, close, onChange} = useAllStudentModal()
    const {data, loading} = useStudentWithoutTasks()

    const {students, tab} = useMemo(
        () => {
            return {
                students: data?.studentWithoutTasks || [],
                tab: loading ? 'loading' : data?.studentWithoutTasks?.length > 0 ? 'show' : 'blank'
            } 
        }, [data, loading]
    )

    return (
        <ModalLayout
            className={`modal-colored modal-sidebar modal-desktop-half white-modal`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h3 text-title`}>Students without tasks</span>
            }
            disabledScrollAnimation
        >
            <TabContent className={`${tab === 'blank' ? 'h-100' : ''}`} activeTab={tab}>
                <TabPane tabId={`loading`}>
                    <Skeleton count={10} className={`w-100 my-1`} height={40} />
                </TabPane>
                <TabPane tabId={`show`}>
                    <StudentList students={students} onClickStudent={(res) => {
                        onChange(res)
                        close()
                    }}/>
                </TabPane>
                <TabPane className={`h-100`} tabId={`blank`}>
                    <StudentBlank className={`h-100`} />
                </TabPane>
            </TabContent>
        </ModalLayout>
    )
}

export default AllStudentModal