import React, {useState} from 'react'
import {IconBank, ListItem} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const ManualMethod = (props) => {
  const {accountInfo, isParent} = props
  const {__typename, ...info} = accountInfo
  const [isOpen, setOpen] = useState(false)
  const {translate} = useTranslation()

  return (
    <>
      <ListItem
        className='px-4'
        showTopLine={!isParent}
        showBottomLine={!isParent}
        hasColorHover={true}
        hasHover={true}
        onClick={() => {setOpen(!isOpen)}}
        Left={
          <span className='d-flex align-items-center text-gray'>
            <IconBank className='mr-2' />
            {`${translate("profile.actions.addManualPaymentMethod.manualPaymentMethod")}`}
          </span>
        }
        Right={<span>{`${translate('profile.actions.addManualPaymentMethod.wire')}`}</span>}
      />
      <Collapse isOpen={isOpen}>
        <div className={'m-0 p-0 d-flex flex-column h45 text-gray px-3 py-1'}>
          {
            accountInfo && 
            Object.entries(info).map(([key, value]) => {
              return (
                <span>
                  <b>{
                    translate(`profile.actions.addManualPaymentMethod.data.${key}`)
                  } : </b>
                  {value}
                </span>
              )
            })
          }
        </div> 
      </Collapse>
    </>
  )
}

export default ManualMethod
