import { CheckboxOption } from '@Knowledge-OTP/znk-ui-components'
import React from 'react'

const useColumns = (props) => {
    const {user, onChange = () => {}, isSelected = false } = props
    
    return [
        <span className={`text-truncate d-flex flex-row align-items-center col-4`}>
            <CheckboxOption 
            onChange={onChange} defaultState={false} value={isSelected} 
            className={`mr-2`} />
            <span>{`${user?.firstName} ${user?.lastName}`}</span>
        </span>,
        <span className={`text-truncate d-flex flex-row align-items-center col-4`}>{user?.educatorInfo?.googleCalendarId || 'No defined'}</span>,
        <span className={`text-truncate d-flex flex-row align-items-center col-4`}>{user?.educatorInfo?.googleCalendar || 'No defined'}</span>,
    ]
}

export default useColumns