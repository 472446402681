import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useGetTaskTemplate from './Model/useGetTaskTemplate'

const useCounselingTemplates = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.listTemplatesModal)
    const {isOpen, templateId, templateData} = reduxData

    
    const [getTaskTemplate, {data: template}] = useGetTaskTemplate()
    
 

    //obtiene datos del template
    useEffect(() => {
        if(templateId) getTaskTemplate({variables: {templateId}})
        // eslint-disable-next-line
    }, [templateId])

    const setOpen = (open, templateId = null) => {
        let obj = {isOpen: open}
        if(templateId) obj.templateId = templateId
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.listTemplatesModal',
            data: {
                ...reduxData,
                ...obj
            }
        })
    }

    const setTemplateData = (data) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.listTemplatesModal',
            data: {
                ...reduxData,
                templateData: data
            }
        })
    }

   

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    //si incDelete es 'true' muestra template sin importar si está eliminado 
    const incDelete = searchParams.get('incDelete')
    useEffect(() => {
        if(template?.getTaskTemplate && (template?.getTaskTemplate?.deleteAt === null || incDelete?.toString() === 'true')) {
            //setea templateData en redux
            setTemplateData(template?.getTaskTemplate)
        }
        // eslint-disable-next-line
    }, [template, incDelete])

    return {
        templateId,
        templateData,
        isOpen,
        open: (templateId) => {
            setOpen(true, templateId)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
        setTemplateData

    }
}

export default useCounselingTemplates