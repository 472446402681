import React, { useMemo } from 'react'
import { IconArrowDown, CheckboxSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import useAllUniversitiesOnPlan from '../../../../Model/useAllUniversitiesOnPlan'

const Universities = (props) => {
    const {filters = [], setFilters = () => {}} = props
    const {data} = useAllUniversitiesOnPlan()
    const universities = useMemo(
        () => {
            const auxUniversities = [...(data?.allUniversitiesOnPlan || [])]
            return auxUniversities.sort((a, b) => a.name.localeCompare(b.name))
        }, [data]
    )

    return (
        <CheckboxSelector
            setDataFilters={setFilters}
            direction='bottom'
            dataFilters={filters}
            data={universities}
            typeFilter='status'
            strings={{
                viewAll: 'View all',
                buttonCancel: 'Cancel',
                buttonApply: 'Apply',
                search: 'Search'
            }}
            >
            <Badge className='mr-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                Universities
                <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default Universities