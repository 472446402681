import React from 'react'
import omit from 'lodash/omit'
import { gql, useMutation } from '@apollo/client'
import { StickyButton } from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import { showAlert } from '../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import { TYPEFIXED } from '../../../const'


const SubmitPlan = (props) => {
  const { toggle, form, refetch } = props
  const dispatch = useDispatch()
  const [editPlanTest, { loading: editLoadTest }] = useMutation(
    gql`mutation (
        $planId: ID!
        $name: String!,
        $serviceId: ID!,
        $groupId: String,
        $statusPlan: PlanStatus,
        $studentUser: PlanStudentInput,
        $enforceRecommendedEducators: Boolean!,
        $isFirstPlan: Boolean!,
        $typePlanPricing: TypePlanPricing!,
        $pricingRate: Float,
        $groupPricingRate: Float,
        $testDate: DateTime,
        $recommendedEducators: [PlanEducatorInput!]!,
        $parts: [LessonsPerWeekPlanPartInput!]!,
        $paymentItemsConfig: [PaymentsItemsConfigInput!]
        $location: LocationLessonInput!
    ){
        editLessonsPerWeekPlan(
            input: {
                name: $name,
                statusPlan: $statusPlan,
                serviceId: $serviceId,
                studentUser: $studentUser,
                recommendedEducators: $recommendedEducators,
                enforceRecommendedEducators: $enforceRecommendedEducators,
                testDate: $testDate,
                parts: $parts,
                paymentItemsConfig: $paymentItemsConfig,
                location: $location,
                isFirstPlan: $isFirstPlan,
                typePlanPricing: $typePlanPricing,
                pricingRate: $pricingRate,
                groupPricingRate: $groupPricingRate,
                groupId: $groupId,
            }
            planId: $planId
        ){id, studentUser{firstName, lastName}}
    }`
  )
  const [editPlanFixed, { loading }] = useMutation(
    gql`mutation (
        $planId: ID!
        $name: String!,
        $serviceId: ID!,
        $groupId: String,
        $studentUser: PlanStudentInput,
        $statusPlan: PlanStatus,
        $enforceRecommendedEducators: Boolean!,
        $isFirstPlan: Boolean!,
        $typePlanPricing: TypePlanPricing!,
        $pricingRate: Float,
        $groupPricingRate: Float,
        $recommendedEducators: [PlanEducatorInput!]!,
        $paymentItemsConfig: [PaymentsItemsConfigInput!],
        $parts: [TimelessPlanPartInput!]!,
        $totalCost: Float!,
        $location: LocationLessonInput!
    ){
        editFixedPlansService(
            input: {
                name: $name,
                studentUser: $studentUser,
                statusPlan: $statusPlan,
                serviceId: $serviceId,
                recommendedEducators: $recommendedEducators,
                enforceRecommendedEducators: $enforceRecommendedEducators,
                paymentItemsConfig: $paymentItemsConfig,
                parts: $parts,
                totalCost: $totalCost,
                location: $location,
                isFirstPlan: $isFirstPlan,
                typePlanPricing: $typePlanPricing,
                pricingRate: $pricingRate,
                groupId: $groupId,
                groupPricingRate: $groupPricingRate,
            }
            planId: $planId
        ){id, studentUser{firstName, lastName}}
    }`
  )

  const submit = () => {
    const parts = []
    const validTopics = []
    let globalTotalLessons = 0
    Object.values(form.lpw).forEach((part) => {
      const lpwTopic = []
      let totalLpw = 0
      let totalLessons = 0
      Object.entries(part.valueIndividual).forEach(([idTopic, values]) => {
        if (form.typePlan === 'date') {
          if (values.lessons > 0) {
            if (!validTopics.includes(idTopic)) {
              validTopics.push(idTopic)
            }
            lpwTopic.push({
              topicId: idTopic,
              lessonsPerWeek: values.lessons,
              lockLessons: !!values.lock
            })
            totalLpw += values.lessons
            totalLessons += values.totalLessons
          }
        } else {
          if (values.lessons > 0) {
            if (!validTopics.includes(idTopic)) {
              validTopics.push(idTopic)
            }
            lpwTopic.push({
              topicId: idTopic,
              lessonsPerWeek: values.lessons,
              lockLessons: !!values.lock
            })
            totalLpw += values.lessons
            totalLessons += values.totalLessons
          }
        }
        globalTotalLessons += totalLessons 
      })
      if (form.typePlan === 'date') {
        parts.push({
          lessonPerWeekPerTopic: lpwTopic,
          lessonsPerWeek: totalLpw,
          totalLessons: totalLessons,
          from: part.from,
          to: part.to,
          name: part.name
        })
      } else {
        parts.push({
          TotalPerTopic: lpwTopic,
          type: form.typeLesson,
          totalLessons: totalLessons,
          from: part.from,
          to: part.to,
          name: part.name
        })
      }
    })
    let totalCost = 0
    const fee = form.cuotas.map((cuota, index) => {
      const finalCuotaValue = cuota.value * (1 - (parseFloat(form.payments.discount.lessons || 0) / 100))
      totalCost += finalCuotaValue
      return {
        amount: finalCuotaValue,
        date: cuota.time,
        description: `Payment ${index}`,
        type: 'lessonsPayment',
        percentDiscount: parseFloat(form.payments.discount.lessons || 0),
        amountWithoutDiscount: parseFloat(cuota.value)
      }
    })
    const otherFixed = [
      { key: 'registration', value: 200 },
      { key: 'exam', value: 50 },
      { key: 'pro', value: 50 }
    ]
    otherFixed.forEach((obj) => {
      if (form.payments[obj.key]) {
        const finalCuotaValue = obj.value * (1 - (parseFloat(form.payments.discount[obj.key] || 0) / 100))
        totalCost += finalCuotaValue
        fee.push({
          amount: finalCuotaValue,
          date: moment().endOf('month'),
          description: `Payment ${obj.key}`,
          type: 'otherFixed',
          percentDiscount: parseFloat(form.payments.discount[obj.key] || 0),
          amountWithoutDiscount: parseFloat(obj.value),
          typeFixed: TYPEFIXED[obj.key] || TYPEFIXED.nothing
        })
      }
    })

    //SE CALCULA EL RATE DEL ESTUDIANTE, EN CASO QUE LA LECCIÓN SE DUPLIQUE
    let studentRate = 0
    if(form.typePlanPricing === 'permonth') {
      studentRate = form.amount/globalTotalLessons //valor por lección CALCULADO 
    } else {
      studentRate = form.amount
    }

    const payload = {
      planId: form.planId,
      groupId: form.groupId,
      name: form.name,
      serviceId: form.service.id,
      recommendedEducators: form.educatorsGroup.concat(form.educatorsIndividual.filter(edu => validTopics.includes(edu.topicId))).map(edu => omit(edu, ['topic', '__typename', 'firstName', 'lastName', 'isManualRate'])),
      enforceRecommendedEducators: false,
      testDate: form.testDate,
      typePlanPricing: form.typePlanPricing,
      pricingRate: form.amount,
      groupPricingRate: form.groupAmount,
      location: omit(form.location, ['__typename']),
      isFirstPlan: !!form.isFirstPlan,
      paymentItemsConfig: fee,
      studentUser: {...form.studentUser, rate: studentRate},
      status: form.status,
      parts,
      statusPlan: form.status
    }
    if (form.typePlan === 'date') {
      editPlanTest({ variables: payload }).then(() => {
        toggle()
        showAlert({
          text: translate('plans.modal.editChildPlan.successAlert'),
          status: 'success'
        }, dispatch)
        refetch()
      }, () => {
        showAlert({
          text: translate('plans.modal.editChildPlan.errorAlert'),
          status: 'error'
        }, dispatch)
      })
    } else {
      editPlanFixed({ variables: { ...payload, totalCost } }).then(() => {
        toggle()
        showAlert({
          text: translate('plans.modal.editChildPlan.successAlert'),
          status: 'success'
        }, dispatch)
        refetch()
      }, () => {
        showAlert({
          text: translate('plans.modal.editChildPlan.errorAlert'),
          status: 'error'
        }, dispatch)
      })
    }
  }
  const { translate } = useTranslation()
  return (
    <StickyButton
      onClickButtonFunction={() => {
        submit()
      }}
      btnClassName='btn-primary mb-2'
      disabled={editLoadTest || loading}
      activity={editLoadTest || loading}
      color='#696CFF'
      label={translate('misc.saveChanges')}
    />
  )
}

export default SubmitPlan
