import React, { useRef, useEffect } from 'react'
import './index.css'

const ResponsiveSpan = (props) => {
    const {
        innerInfo = <>Lorem Ipsum</>
    } = props
    const spanInfoRef = useRef(null)  
    useEffect(() => {
        if(spanInfoRef.current) {
            if(spanInfoRef.current.innerHTML.length > 100) spanInfoRef.current.classList.add('maxWidthContent-350') 
            else spanInfoRef.current.classList.remove('maxWidthContent-350') 
        }
    }, [spanInfoRef])

    return (
        <p ref={spanInfoRef} className={`span-info-header`}>
            {innerInfo}
        </p>
    )
}

export default ResponsiveSpan