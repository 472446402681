import React, { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import { LessonCard, Action, EventItem, IconSignCheck, IconTime } from '@Knowledge-OTP/znk-ui-components'

const Mettings = (props) => {
    const { plan } = props
    const counselor = plan?.counselorData

    const [pastMeetings, setPastMeetings] = useState([])
    const [upcomingMeeting, setUpcomingMeeting] = useState({})
    const [scheduledMeetings, setScheduledMeetings] = useState([])
    const [scheduledMeetingsToShow, setScheduledMeetingsToShow] = useState([])
    const [pendingScheduling, setPendingScheduling] = useState([])

    const allMeetings = useMemo(() => {
        if (plan?.tasksMeetings) {
          return plan?.tasksMeetings.filter(({ type, deleteAt }) => ['scheduledMeetting'].includes(type) && deleteAt === null )
        }
        return {}
    }, [plan])

    useEffect(() => {
        // Cada vez que se terminen de actualizar las meetings (traemos todas), hacemos un filter para cada estado

        if (allMeetings?.length > 0) {
          //Filtra las meetings pasadas
          const pastArray = allMeetings?.filter(
            (meeting) =>
              meeting.meetingStartDate !== null && moment(meeting.meetingStartDate) < moment()
          )
          setPastMeetings(pastArray)
          //Filtra las meetings agendadas que aún no pasaron
          const scheduledArray = allMeetings?.filter(
            (meeting) =>
              meeting.meetingStartDate !== null && moment(meeting.meetingStartDate) > moment(),
            )
            setScheduledMeetings(scheduledArray)
            //Filtra las meetings no agendadas.
            const pendingArray = allMeetings?.filter(
                (meeting) => meeting.meetingStartDate === null,
            )
            setPendingScheduling(pendingArray)
        }

        // eslint-disable-next-line
    }, [allMeetings])

    useEffect(() => {
        let nextMeeting = {}
        if (scheduledMeetings?.length > 0) {
            nextMeeting = scheduledMeetings[0]
            // eslint-disable-next-line
            scheduledMeetings.map((meet) => {
                if (meet.meetingStartDate < nextMeeting?.meetingStartDate) {
                    return nextMeeting = meet
                }
            })
            setScheduledMeetingsToShow(scheduledMeetings?.filter(meet => meet.id !== nextMeeting?.id))
        }
        setUpcomingMeeting(nextMeeting)
    }, [scheduledMeetings])

    // Upcoming Meeting, Future Meetings, Past Meetings
    return (
        <div>
            <>
                <div className={`my-5`}>
                    <label className='h4 font-weight-bold text-title my-3'>
                        {' '}
                        Upcoming Meeting
                    </label>
                    {allMeetings?.length > 0 && upcomingMeeting?.meetingStartDate ? (
                        <>
                            <LessonCard
                                style={{
                                    backgroundColor: '#32405b',
                                    border: 'none',
                                }}
                                title={upcomingMeeting?.name}
                                status='upcoming'
                                preLabelTime={null}
                                lessonTime={upcomingMeeting?.meetingStartDate && moment(upcomingMeeting?.meetingStartDate).clone()}
                                lessonEndTime={upcomingMeeting?.meetingStartDate && moment(upcomingMeeting?.meetingStartDate).add(30, 'minutes').clone()}
                                named={false}
                                attendants={[
                                    {
                                        name: `${counselor?.firstName} ${counselor?.lastName}`,
                                        avatar_url: counselor?.avatar || null,
                                    },
                                ]}
                                showCountdown
                                className='w-100 mb-1'
                                onClick={() => {
                                }}
                            />
                        </>
                    ) :
                        <Action
                            color='transparent'
                            textColor='#7079FF'
                            border='dashed 1px #7079FF'
                            className='my-1'
                            labelLeft={
                                <span className='w-100 d-flex flex-column my-3'>
                                    <label className='m-0'>
                                        No upcoming meetings
                                    </label>
                                </span>
                            }
                            labelRight={null}
                            action={null}
                        />}
                </div>

                {allMeetings?.length > 0 ? (
                    <>
                        <div className={`my-5`}>
                            <label className='h4 font-weight-bold text-title my-3'>
                                {' '}
                                Future Meetings
                            </label>
                            {scheduledMeetingsToShow?.length > 0 &&
                                scheduledMeetingsToShow?.map((meet) => {
                                    return (
                                        <EventItem
                                            // Agendadas pero que no son la proxima
                                            className={`my-1`}
                                            key={meet.id}
                                            status={meet.status === 'review' ? 'secondary' : 'blank'}
                                            Icon={IconTime}
                                            // action='scheduled'
                                            // color='#73819e'
                                            // textColor='#ffffff'
                                            title={`${moment(meet?.meetingStartDate).format(
                                                'dddd, MMMM Do h:mm a',
                                            )}`}
                                            info={`${counselor?.firstName} ${counselor?.lastName.charAt(0)}.`}
                                        />
                                    )
                                })
                            }
                            {pendingScheduling?.length > 0 &&
                                pendingScheduling.map((meet) => {
                                    return (
                                        <EventItem
                                            // pending scheduling
                                            className={`my-1`}
                                            key={meet.id}
                                            status={'blank'}
                                            Icon={IconTime}
                                            // action='pending'
                                            // border='solid 1px #696bff'
                                            // className='py-4 h-100'
                                            title={meet.name}
                                            info={
                                                `${counselor?.firstName} ${counselor?.lastName.charAt(0)}.`
                                            }
                                        />
                                    )
                                })
                            }
                            {(pendingScheduling?.length === 0 && scheduledMeetingsToShow === 0) &&

                                <Action
                                    color='transparent'
                                    textColor='#7079FF'
                                    border='dashed 1px #7079FF'
                                    className='my-1'
                                    labelLeft={
                                        <span className='w-100 d-flex flex-column my-3'>
                                            <label className='m-0'>No scheduled meetings</label>
                                        </span>
                                    }
                                    labelRight={null}
                                    action={null}
                                />
                            }
                        </div>
                        <div className={`my-5`}>
                            <label className='h4 font-weight-bold text-title my-3'>
                                {' '}
                                Past Meetings
                            </label>
                            {pastMeetings?.length > 0 ?
                                pastMeetings.map((meet) => {
                                    return (
                                        <EventItem
                                            // Meeting pasadas
                                            className={`my-1`}
                                            status={'success'}
                                            Icon={IconSignCheck}
                                            // action='completed'
                                            // color='#f3fcd5'
                                            // textColor='#a9e61b'
                                            key={meet.id}
                                            title={`${moment(meet?.startDate).format(
                                                'dddd, MMMM Do h:mm a',
                                            )}`}
                                            info={`${counselor?.firstName} ${counselor?.lastName.charAt(0)}.`}
                                        />
                                    )
                                }) :
                                <Action
                                    color='transparent'
                                    textColor='#7079FF'
                                    border='dashed 1px #7079FF'
                                    className='my-1'
                                    labelLeft={
                                        <span className='w-100 d-flex flex-column my-3'>
                                            <label className='m-0'>
                                                No past meetings
                                            </label>
                                        </span>
                                    }
                                    labelRight={null}
                                    action={null}
                                />
                            }
                        </div>

                        {/* {upcomingMeeting && (
                            <MeetingItem
                                // Próxima meeting
                                key={upcomingMeeting.id}
                                action='upcoming'
                                color='#696bff'
                                textColor='#ffffff'
                                labelLeft={`${moment(upcomingMeeting?.startDate).format(
                                    'dddd, MMMM Do h:mm a',
                                )}`}
                                labelRight={
                                    <span className='ml-2'>
                                        {counselor?.firstName} {counselor?.lastName.charAt(0)}.
                                    </span>
                                }
                            />
                        )} */}
                    </>
                ) : null}
            </>

        </div>
    )
}

export default Mettings
