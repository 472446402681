import React from 'react'
import { IconArrowDown, CheckboxSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'

const StatusFilter = (props) => {
    const {statusFilters = [], setStatusFilters = () => {}} = props
    const statuses = [
        {id: '1', name: 'Active'},
        {id: '0', name: 'Inactive'},
    ]
    return (
        <CheckboxSelector
            setDataFilters={setStatusFilters}
            direction='bottom'
            dataFilters={statusFilters}
            data={statuses}
            typeFilter='status'
            strings={{
                viewAll: 'View all',
                buttonCancel: 'Cancel',
                buttonApply: 'Apply',
                search: 'Search'
            }}
            >
            <Badge className='mr-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                Status
                <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default StatusFilter
// import { IconArrowDown, ListOption } from '@Knowledge-OTP/znk-ui-components'
// import React, { useState } from 'react'
// import { Badge, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
// import useTranslation from '../../../i18n/useTranslation'

// const StatusFilter = (props) => {
//   const { translate } = useTranslation()
//   const [dropdownOpen, setOpen] = useState(false)
//   const { statusFilters, setStatusFilters } = props

//   const toggle = () => setOpen(!dropdownOpen)
//   const setValue = (value, prev, witch) => {
//     if (witch === 0) {
//       if (value === 0 && prev === 0) {
//         setStatusFilters(2)
//       } else {
//         setStatusFilters(0)
//       }
//     } else {
//       if (value === 1 && prev === 1) {
//         setStatusFilters(2)
//       } else {
//         setStatusFilters(1)
//       }
//     }
//   }
//   return (
//     <Dropdown
//       direction='down'
//       className='d-inline-block filter-selector dropbottom dropdown'
//       isOpen={dropdownOpen}
//       toggle={toggle}
//     >
//       <DropdownToggle className='w-100 m-0 p-0'>
//         <Badge className='mx-1 filters' pill color='option'>
//           <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
//             {translate('filters.users.status')}
//             <IconArrowDown className='ml-2' size={15} />
//           </label>
//         </Badge>
//       </DropdownToggle>
//       <DropdownMenu
//         className='znk-data dropdown-menu'
//         // onClick={() => {
//         //   setDropdownOpen(false)
//         // }}
//       >
//         <DropdownItem key='s1'>
//           <ListOption
//             key='l1'
//             label={translate('filters.users.active')}
//             id={1}
//             onClick={(value) => { setValue(value, statusFilters, 1) }}
//             style={{ height: 35 }}
//             selected={statusFilters === 1}
//             value={1}
//           />
//         </DropdownItem>
//         <DropdownItem key='s2'>
//           <ListOption
//             key='l2'
//             label={translate('filters.users.inactive')}
//             id={0}
//             onClick={(value) => { setValue(value, statusFilters, 0) }}
//             style={{ height: 35 }}
//             selected={statusFilters === 0}
//             value={0}
//           />
//         </DropdownItem>
//       </DropdownMenu>
//     </Dropdown>
//   )
// }

// export default StatusFilter
