import { gql, useQuery } from '@apollo/client'

const useAllActivities = (props) => {
  const { serviceIds, kinds, from, to } = props
  const dataQuery = useQuery(gql`
      query (
          $serviceIds: [String!]
          $kinds: [String!]
          $from: DateTime
          $to: DateTime
      ) {
        allActivities(
            kinds: $kinds,
            serviceIds: $serviceIds
            from: $from,
            to: $to
        ){
            ... on Test {
              id
              name
              kind
              tbd
              startDate
              endDate
              busy
              serviceId
              registrationDate
              scoreReleaseDate
              isDisabled
            }
            ... on Event {
                id
                name
                kind
                tbd
                startDate
                endDate
                busy
                description
            }
        }
      }
    `,
  { variables: { serviceIds, kinds, from, to } }
  )
  return { ...dataQuery }
}

export default useAllActivities
