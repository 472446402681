import React, { useEffect, useState } from 'react'
import { Button, Field, Form, IconClose, LabeledField, Modal, PlainTextInput } from '@Knowledge-OTP/znk-ui-components'
import isEmpty from 'lodash/isEmpty'
import useEditKey from './useEditKey'
import validate from './validate'

const EditKey = (props) => {
  const { path, value, setter, className } = props
  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen(open => !open)
  const [validationErrors, setValidationErrors] = useState({})
  const { loading, submit } = useEditKey(setValidationErrors)
  const [form, setForm] = useState({
    value
  })
  useEffect(() => {
    setForm({ value })
  }, [value, setForm])
  const trySubmit = () => {
    const errors = validate(form)
    if (!isEmpty(errors)) {
      setValidationErrors(errors)
      return
    }
    submit(path, form.value)
      .then((strings) => {
        setter(strings)
        toggle()
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <>
      <div onClick={toggle}>
        {props.children}
      </div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={className}
        Head={
          <div className='modal-header'>
            <h2>Change Key</h2>
            <span className='float-right' onClick={toggle}>
              <IconClose
                size={20}
                className='text-secondary'
                style={{ cursor: 'pointer', filter: 'opacity(0.2)' }}
              />
            </span>
          </div>
        }
      >
        <Form value={form} onChange={setForm} validationErrors={validationErrors}>
          <Field
            type={LabeledField}
            component={PlainTextInput}
            fieldName='value'
            label={`Key Value: ${path}`}
          />
          <Button check color='primary' className='float-right' label='Modify' onClick={trySubmit} activity={loading} />
        </Form>
      </Modal>
    </>
  )
}

export default EditKey
