import React, { useEffect, useState } from 'react'
import { IconClose, Modal, Form, Field, InputSelect, AmountInput, StickyButton, Button, CheckboxOption } from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import { FormGroup, Label } from 'reactstrap'
import { gql, useLazyQuery } from '@apollo/client'
import useToCustomGlobalRate from '../../../../Model/Countries/SetCustomGlobalRate'
import setToCustomGlobalRateEducator from '../../../../Model/Countries/SetCustomGlobalRateEducator'
import escapeRegExp from 'lodash/escapeRegExp'

const EditGlobalRate = (props) => {
  const { isOpen, typeUser, userId, refetch } = useSelector((state) => state.modal.user.globalRate)
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const toggle = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.globalRate', data: { isOpen: false } })
  }
  const [form, setForm] = useState({})
  const [isDisabled, setIsDisabled] = useState(false)
  const [getUser, { data }] = useLazyQuery(
    gql`query($id: String!) {
      user(id: $id) {
        id
        emails {
          address
          verified
        }
        countryId
        country {
            id,
            name,
            defaultStudentRate
            defaultEducatorRate
        }
        gender
        spokenLanguages
        address
        city
        state
        zipCode
        firstName
        lastName
        phone
        timezone
        birthdate
        studentInfo {
          institution
          graduationDate
          gpa
          defaultRate
          aditionalInfo
          isWaivePayments
          notifications {
            missedLesson
            lessonsNotesReady
            lessonReminder {
              isAvailable
              duration
            }
          }
          servicesRate {
            defaultRate
            name
            serviceId
          }
          parents {
            parentUserId
            managePayments
            manageApprovals
            firstName
            lastName
            email
          }
        }
        educatorInfo {
          defaultRate
          services {
            serviceId
            name
            defaultRate
            topics {
              topicId
              teachingTypes
              name
            }
          }
          backupLink
          weeklyCapHours
          isActive
          availabilityBlocks {
            day
            hour
          }
          rating
        }
        isActive
        roles
      }
    }
  `,
    {
      variables: { id: userId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    if (userId && userId !== '') {
      getUser()
    }
    // eslint-disable-next-line
  }, [userId])
  useEffect(() => {
    if (data) {
      if (typeUser === 'student') {
        setForm({
          label: data.user.studentInfo.defaultRate ? (data.user.studentInfo.defaultRate !== data.user.country.defaultStudentRate ? 'custom' : 'global') : 'global',
          customAmount: data.user.studentInfo.defaultRate ? data.user.studentInfo.defaultRate : 0,
          globalAmount: data.user.country ? data.user.country.defaultStudentRate : 0,
          isWaivePayments: data.user.studentInfo.isWaivePayments
        })
      } else {
        setForm({
          label: data.user.educatorInfo.defaultRate && data.user.country.defaultEducatorRate ? (data.user.educatorInfo.defaultRate !== data.user.country.defaultEducatorRate ? 'custom' : 'global') : 'custom',
          customAmount: data.user.educatorInfo.defaultRate ? data.user.educatorInfo.defaultRate : 0,
          globalAmount: data.user.country && data.user.country.defaultEducatorRate ? data.user.country.defaultEducatorRate : 0,
          isWaivePayments: data.user.educatorInfo.isWaivePayments ? data.user.educatorInfo.isWaivePayments : false
        })
      }
    }
  }, [data, typeUser])
  const { submit, loading } = useToCustomGlobalRate(() => {
    refetch()
    toggle()
  }, console.log)
  const { submit: submitEducator, loading: loadingEducator } = setToCustomGlobalRateEducator(() => {
    refetch()
    toggle()
  }, console.log)
  const onSubmit = () => {
    let appendInput = {}
    if(form.label === 'global') appendInput.globalRate = form.globalAmount
    else appendInput.customRate = form.customAmount || form.globalAmount
    const newVal = {
      countryId: data?.user?.country?.id,
      userId: userId,
      ...appendInput
      // globalRate: form.globalAmount,
    }
    typeUser === 'student' ? submit(newVal) : submitEducator(newVal)
  }
  useEffect(() => {
    if (form.label) {
      setIsDisabled(form.label === 'global')
    }
  }, [form.label])
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>Edit Global Rates</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={form}>
          <div className='m-0 p-0 mb-2 px-3'>
            <label className='text-gray font-weight-light mb-2'>Global Rate</label>
            <Field
              className='col-12 p-0'
              type={InputSelect}
              fieldName='label'
              placeholder={translate('modal.newplan.selectservice')}
              options={[{ value: 'global', label: 'Global' }, { value: 'custom', label: 'Custom' }]}
              InputClassName='w-100'
              onChange={(value) => {
                setForm(pre => ({
                  ...pre,
                  label: value
                }))
              }}
            filterOption={(obj, string) => {
              if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
              return false
            }}
              value={form.label}
            />
            <label className='text-gray font-weight-light mt-3 mb-2'>Amount</label>
            {
              form.label === 'custom'
                ? <Field
                  className='col-12 placeholder-to-right'
                  type={AmountInput}
                  fieldName='customAmount'
                  fieldType='number'
                  placeholder='$  0.00 '
                  min='0'
                  onChange={(value) => {
                    setForm(pre => ({
                      ...pre,
                      customAmount: value
                    }))
                  }}
                />
                : <Field
                  className='col-12 placeholder-to-right'
                  type={AmountInput}
                  fieldName='globalAmount'
                  disabled={isDisabled}
                  fieldType='number'
                  placeholder='$  0.00 '
                  min='0'
                  onChange={(value) => {
                    setForm(pre => ({
                      ...pre,
                      globalAmount: value
                    }))
                  }}
                />
            }
            <FormGroup check className='mt-5'>
              <Label className={'d-flex flex-row align-items-center'} check>
                <CheckboxOption
                  value={form.isWaivePayments}
                  onChange={(e) => {
                    setForm(pre => ({
                      ...pre,
                      isWaivePayments: e
                    }))
                  }}
                />
                {/* <Input
                  type='checkbox'
                /> */}
                {' '}
                Waive all payments
              </Label>
            </FormGroup>
            <div className='row d-flex m-0 p-0 mt-3'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loading || loadingEducator}
                      className='col-12 aling-text-left'
                      label='Save'
                      onClick={onSubmit}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default EditGlobalRate
