import React from "react";

const useDesktopColumns = () => {

  return [
    {
      render: (item) => {
        const examName = `${item?.examName}`
        return (
          <div className={`d-flex flex-row align-items-center m-0 p-0`}>
            <span className={`ml-2`}>{examName}</span>
          </div>
        );
      },
      colSpan: 4,
      id: "examName",
    },
    {
      render: (item) => {
        const totalStudents = item?.studentsByTest?.length;
        return <span className={`text-gray`}>{`Total Students ${totalStudents}`}</span>;
      },
      className: `d-flex flex-row justify-content-end m-0 p-0`,
      colSpan: 4,
      id: "totalStudents",
    },
  ];
};

export default useDesktopColumns;
