import React from 'react'
import {
  ListItem,
  Field,
  SelectOption,
  CheckboxOption,
  IconOutLine,
  AmountInput
} from '@Knowledge-OTP/znk-ui-components'
import AddDiscount from './AddDiscount'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import { marathonPrice } from '../../../Fields/utils'
const PricingPlan = (props) => {
  const { form, setForm, TotalLesson, dontInitPayment } = props
  const { translate } = useTranslation()
  const dispatch = useDispatch()

  const checkBoxObject = {
    registration: {
      value: form.payments.values.registration,
      valueWithDiscount: form.payments.values.registration - (form.payments.values.registration * (form.payments.discount.registration / 100)),
      discount: (form.payments.values.registration * (form.payments.discount.registration / 100)),
      label: translate('modal.newplan.registrationfee')
    },
    exam: {
      value: form.payments.values.exam,
      valueWithDiscount: form.payments.values.exam - (form.payments.values.exam * (form.payments.discount.exam / 100)),
      discount: (form.payments.values.exam * (form.payments.discount.exam / 100)),
      label: translate('modal.newplan.examregistrationfee')
    },
    pro: {
      value: marathonPrice,
      valueWithDiscount: marathonPrice - (marathonPrice * (form.payments.discount.pro / 100)),
      discount: (marathonPrice * (form.payments.discount.pro / 100)),
      label: 'Zinkerz Pro'
    },
    // atSigning: {
    //   value: form.payments.values.atSigning,
    //   valueWithDiscount: form.payments.values.atSigning - (form.payments.values.atSigning * (form.payments.discount.atSigning / 100)),
    //   discount: (form.payments.values.atSigning * (form.payments.discount.atSigning / 100)),
    //   label: 'atSigning'
    // },
    lessons: { 
      value: 
        (form.typePlanPricing === 'permonth' ? 
          (form.amount && form.amount > 0 ? form.amount : 0) : 
          (TotalLesson() * (form.amount && form.amount > 0 ? form.amount : 0))) -  
          (form.payments.atSigning ? 
            (form.payments.values.atSigning - (form.payments.values.atSigning * (form.payments.discount.atSigning / 100))) : 0)
    }
  }
  const registrationValue = form.payments.registration ? checkBoxObject.registration.value : 0
  const examValue = form.payments.exam ? checkBoxObject.exam.value : 0
  //const proValue = form.payments.pro ? checkBoxObject.pro.value : 0
  const proValue = form.payments.pro ? checkBoxObject.pro.value : 0
  // const atSigningValue = form.payments.atSigning ? checkBoxObject.atSigning.value : 0

  const registrationValueWhithDiscount = form.payments.registration ? checkBoxObject.registration.valueWithDiscount : 0
  const examValueWhithDiscount = form.payments.exam ? checkBoxObject.exam.valueWithDiscount : 0
  const proValueWhithDiscount = form.payments.pro ? checkBoxObject.pro.valueWithDiscount : 0
  // const atSigningValueWhithDiscount = form.payments.atSigning ? checkBoxObject.atSigning.valueWithDiscount : 0

  // VALOR TOTAL DE LECCIONES O CUOTAS
  const lessonsValue = form.payments.lessons ? checkBoxObject.lessons.value : 0

  // VALOR TOTAL CON OTROS PAYMENTS
  // const totalValue = (registrationValue + examValue + proValue + lessonsValue + atSigningValue).toFixed(2)
  const totalValue = (registrationValue + examValue + proValue + lessonsValue).toFixed(2)

  // DESCUENTO A TOTAL DE LECCIONES O CUOTAS
  const discount = form.payments.discount.lessons
    ? (lessonsValue * (form.payments.discount.lessons / 100)).toFixed(2) : (0).toFixed(2)

  const totalValueWhitDiscount = (registrationValueWhithDiscount + examValueWhithDiscount + proValueWhithDiscount /**+ atSigningValueWhithDiscount**/ + (lessonsValue - discount)).toFixed(2)

  const openAddDiscount = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.addDiscount',
      data: { isOpen: true }
    })
  }
  if (form.amount === undefined) {
    // verifica si el servicio seleccionado tiene defaultRate, sino usa el del estudiante
    const defaultRateOfStudent =
      form.typePlanPricing === 'perlesson'
        ? form?.student?.studentInfo?.servicesRate.reduce((serviceRate, service) => {
          if (form?.idservice === service.serviceId) return service.defaultRate
          return serviceRate
        }, false) || form?.student?.studentInfo?.defaultRate
        : false
    if (defaultRateOfStudent && defaultRateOfStudent > 0) {
      setForm(pre => ({ ...pre, amount: defaultRateOfStudent }))
    }
  }

  // const setAtSigningAmount = (newAmount) => {
  //   const atSigning = newAmount?.length > 0 ? parseInt(newAmount) : 0
  //   // const newLessonsAmount = form.payments.values.lessons - atSigning
  //   setForm({
  //     ...form,
  //     payments: {
  //       ...form.payments,
  //       values: {
  //         ...form.payments.values,
  //         atSigning,
  //       }
  //     }
  //   })
  // }

  return (
    <>
      <div className='pl-0 pr-6 py-6 pl-3'>
        <h3><b>{translate('modal.newplan.pricingplan.pricing')}</b></h3><br />
        <div className='row pl-3'>
          <Field
            className='col-12'
            type={SelectOption}
            fieldName='typePlanPricing'
            options={[
              { id: 'perlesson', text: translate('modal.newplan.pricingplan.perlessonrate') },
              { id: 'permonth', text: translate('modal.newplan.pricingplan.flatfee') }
            ]}
            value={form.typePlanPricing}
          />
          <label className='col-12 text-muted mb-2 mt-3 pl-0'>
            {form.typePlanPricing ? form.typePlanPricing === 'perlesson'
              ? translate('modal.newplan.pricingplan.rate')
              : translate('modal.newplan.pricingplan.amount') : translate('modal.newplan.pricingplan.amount')}
          </label>
          <Field
            className='col-12 placeholder-to-right'
            type={AmountInput}
            fieldName='amount'
            fieldType='number'
            placeholder='$  0.00 '
            min='0'
          />
          <div className='mt-3 w-100'>
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.registration'
                    checked={form.payments.registration}
                    onChange={(e) => {
                      dontInitPayment()
                      setForm({
                        ...form,
                        payments: {
                          ...form.payments,
                          registration: e
                        }
                      })
                      // if (form.isFirstPlan) {

                    }}
                  />
                  <label>{translate('modal.newplan.registrationfee')}</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.registration ? 'text-muted' : ''}`} style={!form.payments.registration ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.registration.value}`}
                </label>
              }
            />
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.exam'
                    checked={form.payments.exam}
                    onChange={(e) => {
                      dontInitPayment()
                      setForm({
                        ...form,
                        payments: {
                          ...form.payments,
                          exam: e
                        }
                      })
                    }}
                  />
                  <label>{translate('modal.newplan.examregistrationfee')}</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.exam ? 'text-muted' : ''}`} style={!form.payments.exam ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.exam.value}`}
                </label>
              }
            />
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.pro'
                    checked={form.payments.pro}
                    onChange={(e) => {
                      dontInitPayment()
                      setForm({
                        ...form,
                        payments: {
                          ...form.payments,
                          pro: e
                        }
                      })
                    }}
                  />
                  <label> Marathon</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.pro ? 'text-muted' : ''}`} style={!form.payments.pro ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.pro.value}`}
                </label>
              }
            />
            {/* <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              classNameRight={'d-flex flex-row justify-content-end align-items-center'}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.atSigning'
                    checked={form.payments.atSigning}
                    onChange={(e) => {
                      dontInitPayment()
                      setForm({
                        ...form,
                        payments: {
                          ...form.payments,
                          atSigning: e
                        }
                      })
                    }}
                  />
                  <label> At Signing</label>
                </div>
              }
              Right={
                <input
                  className='znk-input pl-2 pr-3 w-50'
                  style={{textAlign: 'end'}}
                  type={'number'}
                  value={checkBoxObject?.atSigning?.value}
                  disabled={!(form.payments.atSigning)}
                  onChange={(e) => {setAtSigningAmount(e.target.value)}}
                />
                // <label className={`${!form.payments.atSigning ? 'text-muted' : ''}`} style={!form.payments.atSigning ? { textDecoration: 'line-through' } : {}}>
                //   {`$${checkBoxObject?.atSigning?.value}`}
                // </label>
              }
            /> */}
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.lessons'
                    checked={form.payments.lessons}
                  />
                  <label>{form.typePlanPricing === 'permonth' ? translate('modal.newplan.monthlyfee') : `${TotalLesson()} ${translate('modal.newplan.lessons')}`}</label>
                </div>
              }
              Right={
                form.payments.lessons
                  ? <label>{`$${checkBoxObject.lessons.value}`}</label>
                  : <label className='text-muted' style={{ textDecoration: 'line-through' }}>{`$${checkBoxObject.lessons.value}`}</label>
              }
            />
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label className='font-weight-bold'>{Object.keys(form.payments.discount).reduce(
                  (hasDiscount, key) => {
                    return hasDiscount || (form.payments.discount[key] > 0 && form.payments[key])
                  }, false) > 0 ? 'Subtotal' : 'Total price'}
                </label>
              }
              Right={
                <label className='font-weight-bold'>{`$${totalValue}`}</label>
              }
            />
            {
              Object.keys(form.payments.discount).reduce(
                (totalDiscount, discKey) => {
                  if (form.payments[discKey]) {
                    return form.payments.discount[discKey] + totalDiscount
                  } else return totalDiscount
                }, 0) > 0
                ? <>
                  {
                    Object.keys(form.payments.discount).map((discKey) => {
                      if (form.payments.discount[discKey] > 0 && form.payments[discKey]) {
                        return (
                          <ListItem
                            className='py-3 w-100'
                            showBottomLine={false}
                            showTopLine={false}
                            Left={
                              <div className='d-flex'>
                                <IconOutLine
                                  className='p-3 mr-1'
                                  backgroundColor='#FF3E1D'
                                  color='#FFFFFF'
                                  textChildren={<span className='m-0' style={{ color: 'white' }}>x</span>}
                                  onClick={() => {
                                    setForm({
                                      ...form,
                                      payments: {
                                        ...form.payments,
                                        discount: {
                                          ...form.payments.discount,
                                          [discKey]: 0
                                        }
                                      }
                                    })
                                  }}
                                  sizePx={10}
                                />
                                <label onClick={openAddDiscount}>{
                                  discKey === 'lessons'
                                    ? `${translate('modal.newplan.discount')} (${form.payments.discount[discKey]}%)`
                                    : `${translate('modal.newplan.discountfor')} ${checkBoxObject[discKey].label} (${form.payments.discount[discKey]}%)`
                                }
                                </label>
                              </div>
                            }
                            Right={
                              <label onClick={openAddDiscount}>{`$${discKey === 'lessons' ? discount : checkBoxObject[discKey].discount}`}</label>
                            }
                          />
                        )
                      } else {
                        return null
                      }
                    })
                  }
                  </>
                : <label onClick={openAddDiscount} className='btn-link'>{translate('modal.newplan.adddiscount')}</label>
            }
            {Object.keys(form.payments.discount).reduce(
              (totalDiscount, discKey) => {
                if (form.payments[discKey]) {
                  return form.payments.discount[discKey] + totalDiscount
                } else return totalDiscount
              }, 0) > 0 && (
              <ListItem
                  className='py-0 w-100'
                  showBottomLine={false}
                  showTopLine={false}
                  Left={
                  <label className='font-weight-bold'>{translate('modal.newplan.totalprice')}</label>
                }
                  Right={
                  <label className='font-weight-bold'>{`$${totalValueWhitDiscount}`}</label>
                }
                />
            )}
          </div>
        </div>
      </div>
      <AddDiscount
        paymentLabel={form.typePlanPricing === 'permonth' ? translate('modal.newplan.monthlyfee') : `${TotalLesson()} ${translate('modal.newplan.lessons')}`}
        discount={form.payments.discount}
        setDiscount={(newObjectDiscount) => {
          setForm({
            ...form,
            payments: {
              ...form.payments,
              discount: newObjectDiscount
            }
          })
        }}
      />
      <div className='borderTop ml-3' />
    </>
  )
}

PricingPlan.propTypes = {
}

PricingPlan.defaultProps = {
}

export default PricingPlan
