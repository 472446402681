import React, {useEffect} from 'react'
import { useLocation } from 'react-router'

const SearchByUrl = (props) => {
    const {onChange = () => {}} = props

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const search = searchParams.get('search-swt-planid')
    useEffect(() => {
        if(search) onChange(search)
        // eslint-disable-next-line
    }, [search])
    return (
        <></>
    )
}


export default SearchByUrl