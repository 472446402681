import React, {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'
import {PlaidLink} from 'react-plaid-link'
import Skeleton from 'react-loading-skeleton'
import showAlert from '../../../../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import useTranslation from '../../../../../../../i18n/useTranslation'

const AddBankDebit = (props) => {
  const {keyPlaid, refetch} = props
  const dispatch = useDispatch()
  // MUTACIÓN PARA CAMBIAR EL MÉTODO DE PAGO BANK DEBIT
  const [mutationSetBankDebitData, {loading: loadDebitBank}] = useMutation(
    gql`
      mutation($accountId: String!, $plaidPublicToken: String!) {
        setBankDebitPaymentMethod(
          accountId: $accountId
          plaidPublicToken: $plaidPublicToken
        ) {
          kind
          validationStatus
          accountInfo {
            bankName
            last4
          }
        }
      }
    `,
  )
  const onSuccess = useCallback(
    (token, metadata) => {
      // send token to server
      mutationSetBankDebitData({
        variables: {
          accountId: metadata.accounts[0].id,
          plaidPublicToken: token,
        },
      })
        .then(() => {
          showAlert(
            {
              text: translate('profile.actions.addBankDebit.successAlert'),
              status: 'success',
            },
            dispatch,
          )
          refetch()
        })
        .catch((error) => {
          showAlert(
            {
              text: translate('profile.actions.addBankDebit.errorAlert'),
              status: 'error',
            },
            dispatch,
          )
          // eslint-disable-next-line
          console.error(error)
        })
    },
    // eslint-disable-next-line
    [mutationSetBankDebitData, refetch],
  )

  const {translate} = useTranslation()
  return keyPlaid ? (
    <PlaidLink
      className='btn-link ml-1'
      token={keyPlaid}
      onSuccess={onSuccess}
      style={{
        padding: '0',
        outline: 'none',
        background: 'none',
        border: 'none',
      }}
    >
      {loadDebitBank ? (
        <Skeleton height={60} />
      ) : (
        translate('profile.actions.changeMethod.addBankDebit')
      )}
    </PlaidLink>
  ) : (
    <span className='btn-link text-error disabled ml-1'>
      {loadDebitBank ? (
        <Skeleton height={60} />
      ) : (
        translate('profile.actions.changeMethod.addCard')
      )}
    </span>
  )
}

export default AddBankDebit
