import React, {useState} from 'react'
import {
    DropDownNew, IconErase
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import CreateElement from '../CreateElement'
const ControlsElement = (props) =>  {
    const {
        //ayuda a encontrar ubicación del elemento dentro de documentObj
        keyObj, 
        element, 
        className, 
        onClickElement = () => {}, 
        onCreateNewElement = () => {}, 
        onDeleteElement = () => {},
        onCloneElement = () => {}
    } = props
    const [openSubMenu, setSubMenu] = useState(false)
    // const keyForElement = useMemo(
    //     //ayuda a encontrar ubicación del elemento dentro de documentObj
    //     () => {
    //         // console.log("keyObj", keyObj)
    //         return keyObj
    //     }, [keyObj]
    // )
    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <div className={`d-flex flex-row justify-content-between w-100`} >
                <div className={`d-flex flex-row m-0 p-0`}>
                    <span 
                        className={`mr-2`} 
                        onContextMenu={() => {alert(`id: ${element?.id}, ${keyObj}`)}} 
                        onClick={() => {
                                setSubMenu(!openSubMenu)
                            }} style={{cursor: 'pointer'}} title={element?.id}>{element?.type || 'Not found'}</span>
                    <span className={`h45 btn-link text-error`} style={{cursor: 'pointer'}} onClick={() => {
                            // console.log(keyObj, element)
                            onDeleteElement(keyObj, element)
                        }
                    }><IconErase size={12}/></span>
                </div>
                <div className={`d-flex flex-row m-0 p-0`}>
                    <span className={`h45 btn-link`} onClick={() => {
                        onClickElement(keyObj, element)
                        }
                    }>Edit</span>
                    <span className={`h45 btn-link`} onClick={() => {
                        onCloneElement(keyObj, element)
                        }
                    }>Clone</span>
                </div>
            </div>
            {
                
                    <Collapse isOpen={openSubMenu}>
                        <div className={`w-100 m-0 p-0 d-flex flex-column`}>
                            {
                                element?.childrens ?
                                    <>
                                        <div className={`d-flex flex-column m-0 p-0`}>
                                            {
                                                element?.childrens.map((childElement, index) => {
                                                    return (
                                                        childElement ?
                                                        <ControlsElement 
                                                            onCreateNewElement={onCreateNewElement} 
                                                            keyObj={`${keyObj}%childrens[${index}]`} 
                                                            onClickElement={onClickElement}
                                                            onDeleteElement={onDeleteElement} 
                                                            className={`pl-2`} 
                                                            onCloneElement={onCloneElement}
                                                            element={childElement}/> : null)
                                                })
                                            }
                                        </div> 
                                        <DropDownNew
                                            className={``}
                                            options={
                                                [
                                                    {component: <CreateElement onSelect={(type) => {
                                                        onCreateNewElement(`${keyObj}%childrens[${element.childrens.length}]`, element, type)
                                                    }}/>}
                                                ]
                                            }
                                            direction='bottomLeft'
                                        >
                                            <span className={`btn-link h45`} onClick={
                                                () => {
                                                    // onCreateNewElement(`${keyObj}%childrens[${element.childrens.length}]`, element)
                                                }}>Create element</span>
                                        </DropDownNew>    
                                    </>       
                                    : <span className={`pl-2`}>{`"${element?.text}"` || 'No Childrens'}</span>
                            }
                        </div>
                    </Collapse>
            }
        </div>
    )
}

export default ControlsElement