import React, {useMemo} from 'react'
import {Avatar} from '@Knowledge-OTP/znk-ui-components'

const AvatarField = (props) => {
    const {item, className} = props
    const {userName, avatar} = useMemo(
        () => {
            return {
                userName: item ? `${item?.firstName} ${item?.lastName}` : null,
                avatar: item?.avatar || null
            }
        }, [item]
    )
    return (
        <div className={`d-flex flex-row m-0 p-0 align-items-center text-title ${className}`}>
            <Avatar
                name={userName || 'No Defined'}
                named={false}
                uri={avatar}
                size='avatar-medium'
            />
            <span className={`ml-2`}>{userName}</span>
        </div>
    )
}

export default AvatarField