import {useMutation, gql} from '@apollo/client'
import {templateQueryResult} from './queryResults/templateQueryResult'

const useEditTaskTemplate = (onSuccess = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $templateInput: CreateTemplateInput!
                $templateId: ID!
                ) {
                editTaskTemplate (
                    input: $templateInput
                    templateId: $templateId
                )  {
                    ${templateQueryResult}
                }
            }
        `,
        {
            onCompleted: (res) => {
                onSuccess(res)
            }
        }
    )
}

export default useEditTaskTemplate