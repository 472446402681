import React from 'react'
import {
    IndividualRadioSelector,
    IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
const GroupBy = (props) => {
    const {
        setSelected = () => {},
        selected = {},
        defaultGroups = []
    } = props
    return (
        <IndividualRadioSelector
            setSelected={setSelected}
            direction='bottom'
            selected={selected}
            data={defaultGroups}
            typeFilter='orderBy'
            strings={{
                viewAll: 'View all',
                buttonCancel: 'Cancel',
                buttonApply: 'Apply',
                search: 'Search'
            }}
        >
            <span className={`h45 text-gray d-flex flex-row m-0 p-0 align-items-center`}>
                {`GROUP: ${selected?.name ? selected.name : '-'}`} 
                <div className={`hover-icon ml-1`}>
                    <IconArrowDown size={12} className={`text-gray`}/>
                </div>
            </span>
        </IndividualRadioSelector>
    )
}


export default GroupBy