import React, {useMemo} from 'react'
import {
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import usePlans from '../../../Model/usePlans'

const SelectPlan = (props) => {
    const {userId, onChange = () => {}, value, loading = false} = props
    const {data, loading: loadPlans} = usePlans({
        limit: 0,
        kind: ['counceling'],
        status: ['scheduled', 'application', 'postApplication'],
        studentsUserIds: userId ? [userId] : []
    })
    const plans = useMemo(() => data?.plans?.docs || [], [data])
    return (
        <InputSelect 
            className={`m-0 p-0`}
            name={`plan`}
            value={value}
            type={`text`} 
            placeholder={'Select plan'}
            onChange={(planId) => {
                const selectPlan = plans?.find(({id}) => id === planId)
                onChange(selectPlan)
            }} 
            options={plans?.map((plan) => {
                return {value: plan?.id, label: plan?.name}
            })}
            isLoading={loadPlans || loading}
        />
    )
}

export default SelectPlan