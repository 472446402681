import React from 'react'
import InputText from './Inputs/InputText'
import InputColor from './Inputs/InputColor'
import InputNumber from './Inputs/InputNumber'
import InputLabel from './Inputs/InputLabel'
import InputImage from './Inputs/InputImage'

import useElementsHandler from '../../Elements/useElementsHandler'
import useUploadImgToS3 from '../../Model/useUploadImgToS3'

const inputTypes = {
    image: ['src'],
    label: ['label'],
    text: [],
    color: ['backgroundColor', 'color'],
    justify: ['justifyContent'],
    number: ['width', 'height', 'marginTop', 'marginBottom', 'marginLeft', 'marginRight', 'fontSize', 'borderRadius'],
    orientation: ['flexDirection'],
}

const StylesControlsForElement = (props) => {
    const {element, setCurrentElement} = props
    //retorna objeto con los comandos que deben estar disponibles
    const {getElementControlByType} = useElementsHandler()

    const replaceCssCommand = (newValue, cssCommand) => {
        setCurrentElement(
            {
                ...element,
                props: {
                    ...element.props,
                    [cssCommand]: newValue
                }
            }
        )
    }

    const [uploadImage] = useUploadImgToS3(
        (res) => {
            setCurrentElement(
                {
                    ...element,
                    src: res?.uploadImgToS3 || element?.src
                }
            )
        },
        () => {}
    )

    return (
        <div className={`m-0 p-1`}>
            {
                Object.entries(getElementControlByType(element)).map(
                    ([cssCommand, cssValue]) => {
                        //tipo de input
                        const inputType = Object.entries(inputTypes).reduce((type, [newType, included]) => {
                            if(type !== null) return type
                            if(included.includes(cssCommand)) return newType
                            return type
                        }, null)
                        
                        switch(inputType) {
                            case 'label': 
                                return(
                                    <InputLabel
                                        className={`pb-2`} 
                                        value={element?.text || cssValue} 
                                        label={cssCommand} 
                                        onChange={(e) => {
                                            setCurrentElement(
                                                {
                                                    ...element,
                                                    text: e?.target?.value || element?.text
                                                }
                                            )
                                        }} />
                                    ) 
                            case 'color': 
                                return (
                                    <InputColor
                                        onChange={(e) => {
                                            const newColor = e?.target?.value
                                            replaceCssCommand(newColor, cssCommand)
                                        }}
                                        onDelete={() => {
                                            replaceCssCommand('transparent', cssCommand)
                                        }}
                                        className={`pb-2`} 
                                        label={cssCommand} 
                                        value={cssValue}/>
                                )
                            // case 'text': 
                            case 'justify': 
                            case 'number': 
                                return (
                                    <InputNumber
                                        label={cssCommand}
                                        value={cssValue}
                                        onChange={(e, type /**% or px**/) => {
                                            replaceCssCommand(`${e.target.value}${type}`, cssCommand)
                                        }}
                                        className={`pb-2`} 
                                    />
                                )
                            case 'image':  
                                return (
                                    <>
                                        <InputImage
                                            src={cssValue}
                                            className={`pb-2`}
                                            onChange={(e) => {
                                                //subi imagen a S3 y retorna url
                                                uploadImage(
                                                    {
                                                        variables: {
                                                            file: e?.target?.files[0],
                                                            bucket: 'media.zinkerz.com/counseling/pdf-editor-images'
                                                        }
                                                    }
                                                )
                                            }}
                                        />
                                        <InputText
                                            onChange={(e) => {
                                                setCurrentElement(
                                                    {
                                                        ...element,
                                                        src: e.target.value || element?.src
                                                    }
                                                )
                                            }}
                                            className={`pb-2`} value={cssValue} label={cssCommand} />
                                    </>    
                                )        
                            case 'orientation':
                            default: 
                                return(<InputText
                                    onChange={(e) => {
                                        replaceCssCommand(e.target.value, cssCommand)
                                    }}
                                    className={`pb-2`} value={cssValue} label={cssCommand} />) 
                        }
                    }
                )
            }
        </div>
    )
}

export default StylesControlsForElement