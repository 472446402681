import React from 'react'
import Section from './Section'
import Paragraph from './Paragraph'
import Canvas from './Canvas'
import Div from './Div'
import Span from './Span'
import Image from './Image'
import { Page, Document } from '@react-pdf/renderer'

const elementTypes = {
    paragraph: {
        key: 'paragraph',
        label: 'Paragraph',
        initialState: {
            // id: nanoid(),
            type: 'paragraph',
            props: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                textAlign: 'center',
                alignContent: 'center',
                alignSelf: 'center', 
                'color': '#FFFFFF',
            },
            text: 'Nuevo parrafo'
        }
    },
    section: {
        key: 'section',
        label: 'Section',
        initialState: {
            // id: nanoid(),
            type: 'section',
            props: {
                'width': '100%',
                'display': 'flex',
                'flexDirection': 'row',
                // 'backgroundColor': '#000000FF'
            },
            childrens: []
        }
    },
    text: {
        key: 'text',
        label: 'Text',
        initialState: {
            type: 'text',
            props: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                textAlign: 'center',
                alignContent: 'center',
                alignSelf: 'center', 
                'color': '#FFFFFF'
            },
            text: 'Nuevo texto'
        }
    },
    div: {
        key: 'div',
        label: 'Div',
        initialState: {
            type: 'div',
            props: {},
            childrens: []
        }
    },
    star: {
        key: 'star',
        label: 'Star',
        initialState: {
            type: 'star',
            props: {},
            childrens: [],
            painter: (painter) => painter
                .moveTo(100, 150)
                .lineTo(100, 250)
                .lineTo(200, 250)
                .fill('#FF3300')
        }
    },
    span: {
        key: 'span',
        label: 'Span',
        initialState: {
            type: 'span',
            props: {},
            childrens: []
        }
    },
    image: {
        key: 'image',
        label: 'Image',
        initialState: {
            type: 'image',
            props: {},
            src: null
        }
    }
}

const selectComponentByType = (elementType) => {
    switch (elementType) {
        case elementTypes.section.key: 
            return Section
        case elementTypes.paragraph.key:
            return Paragraph
        case elementTypes.text.key:
            return Paragraph
        case elementTypes.div.key:
            return Div  
        case elementTypes.star.key:
            return Canvas    
        case elementTypes.span.key:
            return Span    
        case elementTypes.image.key:
            return Image        
        default:
            return null        
    }
}


const getElementControlByType = (element) => {
    //retorna comando css ncesarios para cada tipo de elemento
    switch (element?.type) {
        case elementTypes.section.key: 
            return {
                'width': 'auto',
                'display': 'flex',
                'flexDirection': 'row',
                ...element.props
            }
        case elementTypes.paragraph.key:
            return {
                'label': element?.text, //texto renderizado
                'textAlign': 'center',
                ...element.props
            }
        case elementTypes.text.key:
            return {
                'label': element?.text,
                'textAlign': 'center',
                ...element.props
            }
        case elementTypes.image.key:
            return {
                'src': element?.src,
                ...element.props
            }    
        default:
            //span star & div
            return {
                ...element.props
            }         
    }
}

const RenderHtmlElement = (element) => {
    if(!element) return null
    const Component = selectComponentByType(element.type)
    return (
        <Component 
            style={element?.props || {}} 
            // for image element
            src={element?.src} 
            // for canvas element
            painter={element?.painter}>
            {
                element.childrens ? 
                    element.childrens.map(RenderHtmlElement) :
                    element.text
            }
        </Component>
    )
}

const RenderHtmlDocument = (htmlObj) => {
    return (
        <Document>
            {
                htmlObj.map(
                    (page) => {
                        const {header, body, footer} = page
                        return (
                            <Page size="A4" style={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', width: '100%'
                            }}>
                                {
                                    header.map(
                                        RenderHtmlElement
                                        // (element) => {
                                        //     const Component = selectComponentByType(element.type)
                                        //     return (
                                        //         <Component style={element?.props || {}}>
                                        //             {
                                        //                 element.childrens || element.text
                                        //             }
                                        //         </Component>
                                        //     )
                                        // }
                                    )
                                }
                                {
                                    body.map(RenderHtmlElement)
                                }
                                {
                                    footer.map(RenderHtmlElement)
                                }
                            </Page>
                        )
                    }
                )
            }
        </Document> 
    )
}
const useElementsHandler = () => {
    return {
        RenderHtmlDocument,
        elementTypes,
        getElementControlByType
    }
}

export default useElementsHandler