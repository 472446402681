import {gql, useQuery} from '@apollo/client'

const useMyFreeTimesFrames = (variables = {}) => {
    return useQuery(
        gql`
            query (
                $involveMyself: Boolean!
                $studentIds: [ID!] = []
                $educatorUserId: ID
                $minDurationInMinutes: Int
                $to: DateTime!
                $from: DateTime!
            ) {
                myFreeTimeFrames(
                    involveMyself: $involveMyself
                    studentIds: $studentIds
                    educatorUserId: $educatorUserId
                    minDurationInMinutes: $minDurationInMinutes
                    to: $to
                    from: $from
                )
            }
        `,
        {variables}
    )
}

export default useMyFreeTimesFrames