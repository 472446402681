import {useMutation, gql} from '@apollo/client'

const useSendInvoiceProcessingSummaryMail = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation sendInvoiceProcessingSummaryMail (
                $category: PaymentCategory!
                $to: DateTime!
                $from: DateTime!
            ) {
                sendInvoiceProcessingSummaryMail (
                    category: $category
                    to: $to
                    from: $from
                )
            }
        `,
        {onCompleted, onError}
    )
}

export default useSendInvoiceProcessingSummaryMail