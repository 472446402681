import React, { useEffect, useState } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import { useParams, useHistory } from "react-router-dom"

import StudentDashboard from './Student'
import './index.css'
import NoAuthorize from '../../common/NoAuthorize'
import { useSelector } from 'react-redux'
import DashboardDashboard from './Dashboard'
import DashboardLiveprep from './LivePrep'
import DashboardEducators from './Educators'

const Sections = {
    students: 'students',
    kpi: 'kpi',
    educators: 'educators',
    counseling: 'counseling',
    liveprep: 'liveprep'
}

const KEY_PAGE = 'dashboard'
const DashboardPage = () => {
    let { section } = useParams()
    const history = useHistory()
    const { staffInfo } = useSelector((state) => state.user)
    const isPermited = staffInfo?.permissions?.filter(e => e.isPermited).map(per => { return per.tag }).includes(KEY_PAGE)

    const [activeTab, setActiveTab] = useState(section)

    // Sincroniza activeTab con el parámetro de la URL
    useEffect(() => {
        setActiveTab(section)
    }, [section])

    if (!isPermited) {
        return <NoAuthorize />
    }

    return (
        <div className={`col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between`} style={{ minHeight: '100%' }}>
            <div className={`d-flex flex-column m-0 p-0`}>
                <div className='container-fluid mt-2 mt-md-5 d-flex px-2 flex-column-reverse flex-md-row'>
                    <div className='d-flex flex-grow-1 align-items-center znk-scroll-horizontal mt-2 pr-0 pr-md-2 mt-md-0'>
                        <span onClick={() => { history.push(`/dashboard/${Sections.students}`) }} className={`nav-title ${activeTab === Sections.students ? 'active' : ''} m-0 mr-3 cursor-pointer`}>Students</span>
                        <span onClick={() => { history.push(`/dashboard/${Sections.educators}`) }} className={`nav-title ${activeTab === Sections.educators ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Educator</span>
                        <span onClick={() => { history.push(`/dashboard/${Sections.kpi}`) }} className={`nav-title ${activeTab === Sections.kpi ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Counseling</span>
                        <span onClick={() => { history.push(`/dashboard/${Sections.liveprep}`) }} className={`nav-title ${activeTab === Sections.liveprep ? 'active' : ''} m-0 mx-3 cursor-pointer`}>Liveprep</span>
                    </div>
                </div>
                <div className={`m-0 p-0`}>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={`loading`}>
                            LOADING
                        </TabPane>
                        <TabPane tabId={`students`}>
                            <StudentDashboard />
                        </TabPane>
                        <TabPane tabId={`educators`}>
                            <DashboardEducators activeTab={activeTab}/>
                        </TabPane>
                        <TabPane tabId={`kpi`}>
                            <DashboardDashboard activeTab={activeTab}/>
                        </TabPane>
                        <TabPane tabId={`liveprep`}>
                            <DashboardLiveprep activeTab={activeTab}/>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default DashboardPage
