export default function reducer(state, action) {
    switch (action.type) {
        case 'set-status':
            return !action.multi ?
            {
                ...state,
                status: action.data.status 
            } :
            state.map((task) => {
                if(task.taskId === action.data.taskId) return {...task, status: action.data.status}
                return task
            })
        case 'overwrite-data':
            return !action.multi ?
            {
                ...state,
                ...action.data
            } :
            state.map((task) => {
                if(task.taskId === action.data.taskId) return {...task, ...action.data}
                return task
            })
        case 'set-data':
            return action.multi ? action.data : {...state, ...action.data} 
        case 'reset-data':
            return action.data 
        case 'add-task':
            return state.concat([action.data]) 
        default:
            throw new Error();
    }
}