import React, { useEffect, useState, useMemo } from 'react'
import {
  ModalScroll,
  useWindowDimensions,
  //Avatar,
  ListAvatars,
  IconClose,
  IconWspAlert,
  IconEmail,
  IconLink,
  IconLoad,
  IconEducator
} from '@Knowledge-OTP/znk-ui-components'
import Avatar from '../../../../common/LazyAvatar'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import GetCourseMaterials from './Components/GetCourseMaterials'
import {
  PendingHeaderModal,
  PendingBodyModal
} from './pending/index.js'
import {
  AttendedHeaderModal,
  AttendedBodyModal
} from './attended/index.js'
import {
  IncompleteHeaderModal,
  IncompleteBodyModal
} from './incomplete/index.js'
import {
  RescheduledHeaderModal,
  RescheduledBodyModal
} from './rescheduled/index.js'
import {
  InProgressBodyModal
} from './inprogress/index.js'
import {
  DraftHeaderModal,
  DraftBodyModal
} from './draft/index.js'
import moment from 'moment'
import AddSubstitute from '../../../../Model/Lessons/AddSubstitute'
import {hasHttp} from '../utils'
import useLinkLesson from './useLinkLesson'
import LessonPayments from './Components/LessonPayments'
import RecurringLessons from './Components/RecurringLessons'
import {showAlert} from '../../../../common/Alert/util'
import useCopyText from '../../../../common/CopyText/useCopyText'

const sectionTab = {
  resume: "resume",
  payments: "payments",
  recurring: "recurring"
}

const InfoLesson = (props) => {
  const { refetchCalendarQuery } = props
  const dispatch = useDispatch()
  const { isOpen, dataLesson } = useSelector((state) => state.modal.plan.infoLessonModal)
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.infoLessonModal',
      data: {
        isOpen: !isOpen,
        dataLesson
      }
    })
  }
  const [student, educator, createdDate, updateDate, statusOfInfoLesson, dataLessonProp] = useMemo(
    () => {
      /**
      *->Incomplete (SCHEDULED y posterior al enddate): solo cuando la lección ya terminó => incomplete
      ->Current/inprogress (SCHEDULED y entre startdate y enddate): => inprogress
      ---> para el estudiante es la siguiente
      ---> Cuando quedan menos de 10 minuts para el start time
      ---> Está en progreso
      ->scheduled (SCHEDULED)(azul oscuro o gris):  Aun no comienza / es futura => sheduled
      ->Attended/missed (FINISHED): Cuando status es igual a finished (para que pase a finished tiene que haber completado la asistencia) => finished
      * **/
      const studentAux = dataLesson ? (dataLesson.student || dataLesson.studentPlan || dataLesson.studentGroup) : null
      let statusOfInfoLesson = 'scheduled'

      if (dataLesson.status === 'finished') {
        if (dataLesson?.notes?.status === "draft") {
          statusOfInfoLesson = 'incomplete'
        }
        else if (Array.isArray(studentAux)) {
          statusOfInfoLesson = 'finished-group'
        } else {
          statusOfInfoLesson = `finished-${studentAux?.attendanceStatus || 'missed'}`
        }
      } else if (dataLesson.status === 'pending') {
        statusOfInfoLesson = 'pending'
      } else if (dataLesson.status === 'draft') {
        statusOfInfoLesson = 'draft'
      } else {
        if (moment(dataLesson.startDate) < moment() && moment() < moment(dataLesson.endDate)) {
          // lección en curso
          statusOfInfoLesson = 'inprogress'
        } else if (moment() > moment(dataLesson.endDate)) {
          // lección ya pasó y no está finished
          statusOfInfoLesson = 'incomplete'
        } else if (moment() < moment(dataLesson.startDate)) {
          // lección aún no sucede
          statusOfInfoLesson = 'scheduled'
        }
      }
      return [
        studentAux,
        dataLesson ? (dataLesson?.substitute ? dataLesson.substitute : dataLesson.educator) : null,
        dataLesson && (dataLesson.IndividualCreated || dataLesson.PlanCreated || dataLesson.GroupCreated || dataLesson.CounselorCreated),
        dataLesson && (dataLesson.IndividualUpdate || dataLesson.PlanUpdate || dataLesson.GroupUpdate || dataLesson.CounselorUpdate),
        statusOfInfoLesson,
        dataLesson
      ]
    }, [dataLesson])
  const [statusOfInfoLessonState, setStatusOfInfoLessonState] = useState(statusOfInfoLesson)
  useEffect(() => {
    setStatusOfInfoLessonState(statusOfInfoLesson)
  }, [statusOfInfoLesson])

  const RenderStudents = ({ student }) => {
    return (
      <div className='d-flex flex-row'>
        {
          Array.isArray(student)
            ? <>
              <ListAvatars direction='right' avatars={student.filter((_, index) => index < 10).map(studentUser => ({ 
                avatar: dataLesson?.aditionalInfo?.studentsAvatar?.find(({studentUserId}) => studentUserId === studentUser?.studentUserId)?.avatar, 
                name: studentUser ? `${studentUser.firstName} ${studentUser.lastName}` : 'Not Found' }))} />
              <span className='align-self-center ml-2 text-title'>{student.length} {translate('modal.infolesson.students')} </span>
              </>
            : <>
              <Avatar
                userId={student.id || student?.studentUserId}
                name={student && `${student.firstName} ${student.lastName}`}
                named={false}
                uri={dataLesson?.aditionalInfo?.studentAvatar}
                size='avatar-medium mr-1'
              />
              <span className='align-self-center'>
                {student && `${student.firstName} ${student.lastName} `}
                <small className='text-muted'>{`(${translate('modal.infolesson.student')})`}</small>
              </span>
            </>
        }
      </div>
    )
  }

  // marcar attendance
  const [educatorAttendance, setEducatorAttendance] = useState(dataLesson?.substitute?.attendanceStatus || educator?.attendanceStatus)
  const [studentAttendance, setStudentAttendace] = useState(null)
  useEffect(() => {
    if (student && dataLesson && educator) {
      setStudentAttendace(
        Array.isArray(student)
          ? student.reduce((objStu, stu) => {
            return { ...objStu, [stu.studentUserId]: stu.attendanceStatus }
          }, {})
          : { [student.studentUserId]: student.attendanceStatus }
      )
      setEducatorAttendance(educator.attendanceStatus)
    }
  }, [student, dataLesson, educator])

  // verifica si cambio el attendaceStatus original de la lección
  const checkAttendanceChanges = useMemo(() => {
    let val = false // ¿hay cambios?
    val = educatorAttendance !== (educator?.attendanceStatus)
    if (!val) {
      val = studentAttendance && Object.entries(studentAttendance).reduce((validationAccu, [idStudent, attendance]) => {
        const stu = Array.isArray(student) ? student.filter((stu) => stu.studentUserId === idStudent)[0] : student
        return validationAccu || stu?.attendanceStatus !== attendance
      }, false)
    }
    return val
  }, [educatorAttendance, studentAttendance, student, educator])
  const resetAttendance = () => {
    setStudentAttendace(
      Array.isArray(student)
        ? student.reduce((objStu, stu) => {
          return { ...objStu, [stu.studentUserId]: stu.attendanceStatus }
        }, {})
        : { [student.studentUserId]: student.attendanceStatus }
    )
    setEducatorAttendance(educator.attendanceStatus)
  }

  const setDataLessonToRedux = (newDataLesson) => {
    // setea lección modificada en redux, se usa luego de ejecutar una mutación
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.infoLessonModal',
      data: {
        isOpen: true,
        dataLesson: newDataLesson
      }
    })
  }

  const { submit: addSubstituteMutation, load: loadAddSubstituteMutation } = AddSubstitute(
    (res) => {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.addSustituteEducator',
        data: {
          isOpen: false,
          idTopic: '',
          setEducator: () => {},
          topicName: '',
          educator: {},
          idService: ''
        }
      })
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.infoLessonModal',
        data: {
          isOpen: true,
          dataLesson: { ...dataLesson, substitute: res?.data?.addSubstitute?.substitute || null }
        }
      })
      // setSubstituteEducator(res?.data?.addSubstitute?.substitute || null)
    }, (error) => {
      console.error(error)
      alert(error)
    })


  const getvclink = useLinkLesson()  
  const [copyText, setCopyText] = useCopyText()

  const [loadCopy, setLoadCopy] = useState(false)
  const studentOptions = (student) => {
    const [copySuccess, copyError] = [
      (text) => {
        //success
        showAlert({text: translate("modal.infolesson.copy"), status: 'success'}, dispatch)
        showAlert({text: `Link: ${text}`, status: 'success', onClick: () => {window.open(text, '_blank')}}, dispatch)
        setLoadCopy(false)
      },
      (error, text) => {
        //error
        showAlert({text: `${translate("modal.infolesson.copyError")} \n ${copyText}`, status: 'error'}, dispatch)
        showAlert({text: `Link: ${text}`, status: 'success', onClick: () => {window.open(text, '_blank')}}, dispatch)
        setLoadCopy(false)
      }
    ]
    return [
      {
        component: <span onClick={() => {
          if(!loadCopy) {
            setLoadCopy(true)
            // si location es VCR es launchURL, sino es location.linkStudent!
            getvclink(dataLesson?.id, student?.studentUserId || student?.id)
              .then(({launchUrl, location}) => {
                if(location.type === 'virtualClassroom'){
                  //navigator.clipboard.writeText(hasHttp(launchUrl))
                  //.then(copySuccess).catch(copyError)
                  setCopyText(hasHttp(launchUrl), copySuccess, copyError)
                } 
                else setCopyText(hasHttp(location.linkStudent), copySuccess, copyError)//navigator.clipboard.writeText(hasHttp(location.linkStudent))
              }, (error) => {
                console.error(error)
                //navigator.clipboard.writeText(hasHttp(dataLesson.location.linkStudent))
                //.then(copySuccess).catch(copyError)
                setCopyText(hasHttp(dataLesson.location.linkStudent), copySuccess, copyError)
              })
              .catch((error) => {
                console.error(error)
                //navigator.clipboard.writeText(hasHttp(dataLesson.location.linkStudent))
                //.then(copySuccess).catch(copyError)
                setCopyText(hasHttp(dataLesson.location.linkStudent), copySuccess, copyError)
              })
          }
        }}>
          {loadCopy ? <IconLoad className={'mr-2'}/> : <IconLink className={'mr-2'}/>} {translate('modal.infolesson.copylocationlink')}</span>
      },
      {
        component: <span onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'lesson.sendEmailReminder',
            data: {
              isOpen: true,
              dataLesson: dataLesson,
              studentsId: student?.studentUserId || student?.id ? [student?.studentUserId || student?.id] : [],
              educatorId: null 
            }
          })
        }}><IconEmail className={'mr-2'}/> {translate('modal.infolesson.sendreminder')}</span>
      },
      {
        component: <span onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'lesson.wspAlert',
            data: {
              isOpen: true,
              dataLesson: dataLesson,
              studentsId: student?.studentUserId || student?.id ? [student?.studentUserId || student?.id] : [],
              educatorId: null 
            }
          })
        }}><IconWspAlert className={'mr-2'}/> {translate('modal.infolesson.sendwspalert')}</span>
      },
      {
        component: (
          <GetCourseMaterials 
            serviceId={dataLesson?.service?.id} topicId={(dataLesson?.individualTopic || dataLesson?.planTopic || dataLesson?.groupTopic)?.id}
            studentId={student?.studentUserId}/>
        )
      }
    ]
  } 
  const educatorOptions = (educator) => {
    const [copySuccess, copyError] = [
      (text) => {
        //success
        showAlert({text: `${translate("modal.infolesson.copy")}`, status: 'success'}, dispatch)
        showAlert({text: `Link: ${text}`, status: 'success', onClick: () => {window.open(text, '_blank')}}, dispatch)
        setLoadCopy(false)
      },
      (error, text) => {
        //error
        showAlert({text: `${translate("modal.infolesson.copyError")}`, status: 'error'}, dispatch)
        showAlert({text: `Link: ${text}`, status: 'success', onClick: () => {window.open(text, '_blank')}}, dispatch)
        setLoadCopy(false)
      }
    ]
    return [
      {
        component: <span
                      onClick={() => {
                        const topic = dataLesson?.individualTopic || dataLesson?.planTopic || dataLesson?.groupTopic
                        dispatch({
                          type: 'SET_DATA_MODAL_FORM_LIST',
                          payload: 'plan.addSustituteEducator',
                          data: {
                            isOpen: true,
                            idTopic: topic?.id,
                            setEducator: (edu) => {
                              addSubstituteMutation({
                                educatorUserId: edu?.id,
                                lessonId: dataLesson?.id
                              })
                            },
                            topicName: topic?.name,
                            educator: {},
                            idService: dataLesson?.service?.id
                          }
                        })
                      }}><IconEducator className={'mr-2'}/> {translate('modal.infolesson.addsubstitute')}</span>
      },
      {
        component: <span onClick={() => {
          if(!loadCopy) {
            setLoadCopy(true)
            // si location es VCR es launchURL, sino es location.linkEducator!
            getvclink(dataLesson?.id, educator?.educatorUserId || educator?.id)
              .then(({launchUrl, location}) => {
                if(location.type === 'virtualClassroom') {
                  //navigator.clipboard.writeText(hasHttp(
                  //  launchUrl
                  //))
                  //.then(copySuccess).catch(copyError)
                  setCopyText(hasHttp(launchUrl), copySuccess, copyError)
                }
                else {
                  setCopyText(hasHttp(location.linkEducator), copySuccess, copyError)
                  //navigator.clipboard.writeText(hasHttp(location.linkEducator))
                  //  .then(copySuccess).catch(copyError)
                }
              }, (error) => {
                console.error(error)
                //navigator.clipboard.writeText(hasHttp(dataLesson.location.linkEducator))
                //.then(copySuccess).catch(copyError)
                setCopyText(hasHttp(dataLesson.location.linkEducator), copySuccess, copyError)
              })
              .catch((error) => {
                console.error(error)
                setCopyText(hasHttp(dataLesson.location.linkEducator), copySuccess, copyError)
              })
          }
        }}>
          {loadCopy ? <IconLoad className={'mr-2'}/> : <IconLink className={'mr-2'}/>} {translate('modal.infolesson.copylocationlink')}
        </span>
      },
      {
        component: <span onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'lesson.sendEmailReminder',
            data: {
              isOpen: true,
              dataLesson: dataLesson,
              studentsId: [],
              educatorId: educator?.educatorUserId || educator?.id || null
            }
          })
        }}>
          <IconEmail className={'mr-2'}/> {translate('modal.infolesson.sendreminder')}</span>
      },
      {
        component: <span onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'lesson.wspAlert',
            data: {
              isOpen: true,
              dataLesson: dataLesson,
              studentsId: [],
              educatorId: educator?.educatorUserId || educator?.id || null
            }
          })
        }}><IconWspAlert className={'mr-2'}/> {translate('modal.infolesson.sendwspalert')}</span>
      },
    ]
  }   

  const [activeTab, setActiveTab] = useState(sectionTab.resume)
  const [troubleBool, setTroubleBool] = useState(false)
  useEffect(() => {setActiveTab(sectionTab.resume)}, [dataLesson])

  return (
    <ModalScroll
      buttonLabel={null}
      className={`modal-colored modal-${
            breakWidth === 'SM'
              ? 'botbar'
              : statusOfInfoLessonState === 'pending'
                ? 'sidebar modal-centered'
                : 'sidebar modal-desktop-half'
            }  fixed-head-modal ${
              statusOfInfoLessonState === 'scheduled'
                ? 'scheduled'
                : ['finished-attended', 'finished-missed', 'finished-group', 'incomplete'].includes(statusOfInfoLessonState)
                ? 'white'
                : ['pending'].includes(statusOfInfoLessonState)
                ? 'whiteblue' : 'upcoming'
      }-modal`}
      isOpen={isOpen}
      toggle={toggle}
      modalScrollClassName={'h-100'}
      MinHead={
        <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
          <div className='d-flex flex-row align-items-center'>
            {
              dataLessonProp && <span>{`${dataLessonProp?.title}`}</span>
            }
          </div>
          <div className='d-flex flex-row align-items-center'>
            <IconClose color='#D7E1EA' onClick={toggle} />
          </div>
        </div>
      }
      Head={
        <div
          className={`modal-header px-6 px-sm-7 d-flex flex-column ${statusOfInfoLessonState === 'pending' ? 'pb-0 pt-5' : ''}`}
          style={
            statusOfInfoLessonState === 'pending'
              ? { zIndex: 10, minHeight: 'unset' }
              : { zIndex: 10 }
          }
        >
          {
            ['scheduled', 'inprogress'].includes(statusOfInfoLessonState)
              ? <PendingHeaderModal dataLesson={dataLessonProp} toggle={toggle} />
              : statusOfInfoLessonState === 'draft'
                ? <DraftHeaderModal dataLesson={dataLessonProp} toggle={toggle} />
              : statusOfInfoLessonState === 'incomplete'
                ? <IncompleteHeaderModal dataLesson={dataLessonProp} toggle={toggle} />
                : statusOfInfoLessonState === 'pending'
                  ? <RescheduledHeaderModal dataLesson={dataLessonProp} toggle={toggle} />
                  : <AttendedHeaderModal lessonStatus={statusOfInfoLessonState} dataLesson={dataLessonProp} toggle={toggle} />
          }
          <h5 className='my-0'>ID: {dataLesson?.id ?? 'No Id'}</h5>
        </div>
      }
    >
      <div className={`px-6 px-sm-7 m-0 ${statusOfInfoLessonState === 'pending' ? 'pt-2' : 'pt-4'} d-flex flex-column`}>
        {statusOfInfoLessonState !== 'draft' && <Nav className='w-100 znk-scroll-horizontal flex-nowrap'>
          <NavItem className='nav-title m-0 mb-2' active={activeTab === sectionTab.resume}>
            <NavLink
              onClick={() => {setActiveTab("resume")}}
            >
              Summary
            </NavLink>
          </NavItem>
          {
            ["IndividualLesson", "GroupLesson", "PlanLesson"].includes(dataLesson.__typename) &&
            <NavItem className='nav-title mr-2' active={activeTab === sectionTab.payments}>
              <NavLink
                onClick={() => {setActiveTab(sectionTab.payments)}}
              >
                Payment(s) {(troubleBool && dataLesson.__typename === "IndividualLesson") ? '*' : ''}
              </NavLink>
            </NavItem> 
          }
          {
            dataLessonProp?.recurringId && (
              <NavItem className='nav-title m-0 mb-2' active={activeTab === sectionTab.recurring}>
                <NavLink
                  onClick={() => {
                    setActiveTab("recurring")}}
                >
                  Recurring
                </NavLink>
              </NavItem>
            )
          }
        </Nav>}
        <TabContent style={{flexGrow: 1}} activeTab={activeTab}>
          <TabPane tabId={sectionTab.resume} className={'text-title h-100'}>
            {
              statusOfInfoLessonState === 'draft' 
               ? <DraftBodyModal
               dataLesson={dataLessonProp}
               toggle={toggle}
               student={student}
               educator={educator}
               createdDate={createdDate}
               updateDate={updateDate}
               RenderStudents={RenderStudents}
               setDataLessonToRedux={setDataLessonToRedux}
               educatorAttendanceState={[educatorAttendance, setEducatorAttendance]}
               studentAttendanceState={[studentAttendance, setStudentAttendace]}
               checkAttendanceChanges={checkAttendanceChanges}
               resetAttendance={resetAttendance}
               addSubstituteMutation={addSubstituteMutation}
               loadAddSubstituteMutation={loadAddSubstituteMutation}
               studentOptions={studentOptions}
               educatorOptions={educatorOptions}
               refetchCalendarQuery={refetchCalendarQuery}
             /> :
              statusOfInfoLessonState === 'scheduled'
                ? <PendingBodyModal
                    dataLesson={dataLessonProp}
                    toggle={toggle}
                    student={student}
                    educator={educator}
                    createdDate={createdDate}
                    updateDate={updateDate}
                    RenderStudents={RenderStudents}
                    setDataLessonToRedux={setDataLessonToRedux}
                    educatorAttendanceState={[educatorAttendance, setEducatorAttendance]}
                    studentAttendanceState={[studentAttendance, setStudentAttendace]}
                    checkAttendanceChanges={checkAttendanceChanges}
                    resetAttendance={resetAttendance}
                    addSubstituteMutation={addSubstituteMutation}
                    loadAddSubstituteMutation={loadAddSubstituteMutation}
                    studentOptions={studentOptions}
                    educatorOptions={educatorOptions}
                    refetchCalendarQuery={refetchCalendarQuery}
                  />
                : statusOfInfoLessonState === 'inprogress'
                  ? <InProgressBodyModal
                      dataLesson={dataLessonProp}
                      toggle={toggle}
                      student={student}
                      educator={educator}
                      createdDate={createdDate}
                      updateDate={updateDate}
                      RenderStudents={RenderStudents}
                      setDataLessonToRedux={setDataLessonToRedux}
                      educatorAttendanceState={[educatorAttendance, setEducatorAttendance]}
                      studentAttendanceState={[studentAttendance, setStudentAttendace]}
                      checkAttendanceChanges={checkAttendanceChanges}
                      resetAttendance={resetAttendance}
                      addSubstituteMutation={addSubstituteMutation}
                      loadAddSubstituteMutation={loadAddSubstituteMutation}
                      setStatusOfInfoLessonState={setStatusOfInfoLessonState}
                      refetchCalendarQuery={refetchCalendarQuery}
                      studentOptions={studentOptions}
                      educatorOptions={educatorOptions}
                    />
                  : statusOfInfoLessonState === 'incomplete'
                    ? <IncompleteBodyModal
                        dataLesson={dataLessonProp}
                        toggle={toggle}
                        student={student}
                        educator={educator}
                        createdDate={createdDate}
                        updateDate={updateDate}
                        RenderStudents={RenderStudents}
                        setStatusOfInfoLessonState={setStatusOfInfoLessonState}
                        refetchCalendarQuery={refetchCalendarQuery}
                        setDataLessonToRedux={setDataLessonToRedux}
                        educatorAttendanceState={[educatorAttendance, setEducatorAttendance]}
                        studentAttendanceState={[studentAttendance, setStudentAttendace]}
                        checkAttendanceChanges={checkAttendanceChanges}
                        resetAttendance={resetAttendance}
                        addSubstituteMutation={addSubstituteMutation}
                        loadAddSubstituteMutation={loadAddSubstituteMutation}
                        studentOptions={studentOptions}
                        educatorOptions={educatorOptions}
                      />
                    : statusOfInfoLessonState === 'pending'
                      ? <RescheduledBodyModal
                          dataLesson={dataLessonProp}
                          toggle={toggle}
                          student={student}
                          educator={educator}
                          createdDate={createdDate}
                          updateDate={updateDate}
                          RenderStudents={RenderStudents}
                          lessonStatus={statusOfInfoLessonState}
                          setDataLessonToRedux={setDataLessonToRedux}
                          addSubstituteMutation={addSubstituteMutation}
                          loadAddSubstituteMutation={loadAddSubstituteMutation}
                          studentOptions={studentOptions}
                          educatorOptions={educatorOptions}
                        />
                      : <AttendedBodyModal
                          dataLesson={dataLessonProp}
                          toggle={toggle}
                          student={student}
                          educator={educator}
                          createdDate={createdDate}
                          updateDate={updateDate}
                          RenderStudents={RenderStudents}
                          lessonStatus={statusOfInfoLessonState}
                          setDataLessonToRedux={setDataLessonToRedux}
                          addSubstituteMutation={addSubstituteMutation}
                          loadAddSubstituteMutation={loadAddSubstituteMutation}
                          studentOptions={studentOptions}
                          educatorOptions={educatorOptions}
                          educatorAttendanceState={[educatorAttendance, setEducatorAttendance]}
                          studentAttendanceState={[studentAttendance, setStudentAttendace]}
                          checkAttendanceChanges={checkAttendanceChanges}
                          resetAttendance={resetAttendance}
                        />
            }
          </TabPane>
          <TabPane tabId={sectionTab.payments}>
            <LessonPayments 
              lessonId={dataLesson?.id} 
              setTroubleBool={setTroubleBool} 
              isPlanLesson={["PlanLesson"].includes(dataLesson.__typename)}
              show={activeTab === sectionTab.payments} 
            />
          </TabPane>
          {
            dataLessonProp?.recurringId && (
              <TabPane tabId={sectionTab.recurring}>
                <RecurringLessons dataLesson={dataLessonProp}/>
              </TabPane>
            )
          }
        </TabContent>
      </div>
    </ModalScroll>
  )
}

export default InfoLesson
