import React, { useMemo } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import StudentAndParent from './Components/StudentAndParent'

const Student = (props) => {
    const { className, plan } = props
    //nombre del estudiante seleccionado
    const { activeTab } = useMemo(() => {
        if (plan?.studentUser) return {
            activeTab: 'selected'
        }
        return {
            activeTab: 'no-selected'
        }
        // eslint-disable-next-line
    }, [plan?.studentUser])

    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <span className={`h4 font-weight-bold text-title pb-3`}>Student</span>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={'selected'}>
                    <StudentAndParent plan={plan} />
                </TabPane>
                <TabPane tabId={`no-selected`}>
                    <span className={`btn-link`}>Select or create new student</span>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default Student