import React, {useEffect, useState} from 'react'
import {
    ListItem,
    IconArrowDown,
    IconArrowUp,
} from '@Knowledge-OTP/znk-ui-components'
import { Collapse } from 'reactstrap'
import usePaymentsAndLessonData from './usePaymentsAndLessonData'
import useTranslation from '../../../../../../i18n/useTranslation'
import formatNumber from '../../../../../../utils/formatNumber'
import moment from 'moment-timezone'

const LessonPayments = (props) => {
    const {lessonId, setTroubleBool, isPlanLesson, show} = props
    const {translate} = useTranslation()
    const {
        students,
        educator,
        substitute,
        refetch
    } = usePaymentsAndLessonData(lessonId)

    useEffect(() => {
        if(students?.length > 0) {
            //trouble bool es true cuando algún estudiante no tiene al menos un payment
            setTroubleBool(
                students.reduce((bool, {payments}) => {
                    return bool || payments?.length === 0
                }, false)
            )
        }
    }, [students, setTroubleBool])
    useEffect(() => {
        if(refetch) refetch()
        // eslint-disable-next-line
    }, [show])
    
    const RenderPayment = ({payments, user}) => {
        const {firstName, lastName} = user
        const [isOpen, setOpen] = useState(false)
        return (
            <>
                <ListItem
                    className='py-3 font-weight-light'
                    style={{ fontSize: 14 }}
                    showBottomLine
                    forceBorderOverflow
                    onClick={() => {setOpen(!isOpen)}}
                    Left={
                        <div className='d-flex align-items-center'>
                            <span className='h45 text-dark font-weight-light mr-1'>
                                {`${firstName} ${lastName}`}
                            </span>
                        </div>
                    }
                    Right={
                        <div className={'hover-icon'}>
                            {
                                isOpen ?
                                    <IconArrowUp/> :
                                    <IconArrowDown/>
                            }
                        </div>
                    }
                />
                <Collapse isOpen={isOpen}>
                    {
                        payments?.length > 0 ?
                        payments.map(({date, amount, status, description}) => {
                            return (
                                <ListItem
                                    className='py-3 font-weight-light'
                                    style={{ fontSize: 14 }}
                                    showBottomLine
                                    forceBorderOverflow
                                    Left={
                                        <div className='d-flex align-items-center' title={description}>
                                            <span className='h45 text-dark font-weight-light mr-1'>
                                            {translate(
                                                'profile.payments.endOf',
                                                moment(date).utc().format('MMMM')
                                            )}
                                            </span>
                                        </div>
                                    }
                                    Right={
                                        <span title={status} className={`text-${status === 'paid' ? 'success' : 'error'}`}>
                                            ${formatNumber(amount)}
                                        </span>
                                    }
                                />
                            )
                        }) :
                        <span className={'w-100 d-flex flex-row justify-content-center h45 text-dark font-weight-light'}>
                            No payment items
                        </span>
                    }
                </Collapse>
            </>
        )
    }
    return (
        <div>
            {
                !isPlanLesson && (
                    <>
                        <span className={'font-weight-bold text-title'}>Student(s)</span>
                        {
                            students?.length > 0 ? 
                            students.map((stu) => {
                                return (
                                <RenderPayment payments={stu.payments} user={stu} /> 
                                )
                            }) : 
                            <span className={'w-100 d-flex flex-row justify-content-center h45 text-dark font-weight-light'}>
                                No students
                            </span>
                        }
                        <br/>
                    </>
                )
            }
            <span className={'font-weight-bold text-title'}>Educator</span>
            {
                educator && <RenderPayment payments={educator.payments} user={educator} />
            }
            <br/>
            {
                substitute && 
                <>
                    <span className={'font-weight-bold text-title'}>Substitute</span>
                    <RenderPayment payments={substitute.payments} user={substitute} />
                </>
            }
        </div>
    )
}
export default LessonPayments