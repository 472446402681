import React, { useEffect, useState } from 'react'
import {
  ConfirmModal
} from '@Knowledge-OTP/znk-ui-components'
import CancelLessonFromAdmin from '../../../../Model/Lessons/CancelLessonFromAdmin/index'
import CancelOrientationFromAdmin from '../../../../Model/Lessons/CancelOrientationFromAdmin'
import useTranslation from '../../../../i18n/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { showAlert } from '../../../../common/Alert/util'

const SureModal = (props) => {
  const { 
    open, 
    setOpenFunction, 
    allFuture, 
    idLesson, 
    isOrientation,
    isMeeting,
    textMeeting,
    optionMeeting,
    refetchList,
    //lessonsSelected,
    setLessonsSelected ,
    refetch = () => {}
  } = props
  const [isOpen, setOpen] = useState(false)
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const toggle = () => {
    setOpen(!isOpen)
    setOpenFunction(!isOpen)
  }
  useEffect(() => {
    setOpen(open)
  }, [open])

  const modalData = useSelector((state) => state.modal.plan.infoLessonModal)
  const modalDataOrientation = useSelector((state) => state.modal.plan.orientationLessonModal)
  const { submit: cancelLessonMutation, loading } = CancelLessonFromAdmin(
    () => {
      
      //deselecciona lecciones
      if(setLessonsSelected) setLessonsSelected([])
      
      toggle()
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.infoLessonModal',
        data: {
          ...modalData,
          isOpen: false
        }
      })
      showAlert({
        text: 'Success',
        status: 'success'
      }, dispatch)
      refetch()
    },
    (error) => {
      showAlert({
        text: error?.toString(),
        status: 'error'
      }, dispatch)
    })
  const { submit: cancelOrientationLessonMutation, loading: loadingOrientation } = CancelOrientationFromAdmin(
      () => {
        
        //deselecciona lecciones
        if(setLessonsSelected) setLessonsSelected([])

        toggle()
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'plan.orientationLessonModal',
          data: {
            ...modalDataOrientation,
            isOpen: false
          }
        })
        refetchList()
      },
      (error) => {
        showAlert({
          text: error?.error,
          status: 'error'
        }, dispatch)
        console.error(error)
      })  
  return (
    <ConfirmModal
      className=''
      loading={loading || loadingOrientation}
      classNameActionDiv='flex-column'
      classNameConfirm='text-right text-error'
      classNameCancel='mx-3 text-right'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('modal.cancellesson.sure')}
      body={translate('modal.cancellesson.cannotbeundone')}
      confirmAction={() => {
        if(isOrientation || isMeeting) cancelOrientationLessonMutation({lessonId: idLesson, option: optionMeeting})
        else {
          cancelLessonMutation({
            cancelFuture: allFuture,
            lessonId: idLesson
          })
        }
      }}
      cancelAction={toggle}
      confirmText={isOrientation ? 'Remove Orientation' : isMeeting ? textMeeting : translate('modal.cancellesson.yesremove')}
      cancelText={translate('modal.cancellesson.cancel')}
    />
  )
}

export default SureModal
