import { gql, useQuery } from '@apollo/client'
import { planQueryResult } from './QueryResults/planQueryResult'

const useGetTestRegistrationQuery = (vars = {}) => {
    return useQuery(
        gql`
            query {
                getTestRegistrationQuery {
                    ${planQueryResult}
                }
            }
        `, {
            // fetchPolicy: 'cache-first',
            variables: vars}
    )
}

export default useGetTestRegistrationQuery