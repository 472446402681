import { useDispatch, useSelector } from 'react-redux'

const useSendEmailReminder = () => {
    const dispatch = useDispatch()
    //Para que hook funciones debe haber solo UN componente NewTaskModal 
    // renderizado en el dominio
    const reduxData = useSelector((state) => state.modal.counseling.sendTaskEmailReminder)
    const {isOpen} = reduxData
    const setOpen = (isOpen = false, onChange = () => {}, taskData = {planId: null, id: null}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.sendTaskEmailReminder',
            data: {
                ...reduxData,
                isOpen,
                onChange,
                taskData
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, taskData = {planId: null, id: null}) => {
            setOpen(true, onChange, taskData)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
    }
}

export default useSendEmailReminder