import React from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import { ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import StudentServiceSelector from './StudentServiceRates'
import useTranslation from '../../../../../i18n/useTranslation'

const SetServicesRateModalForm = (props) => {
  const { isOpen, ...formData } = useSelector((state) => state.modal.country.servicesRate)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'country.servicesRate', data: { isOpen: !isOpen, ...formData } })
  }
  const updateModalData = (newCountryInfo) => {
    const { data: { setServiceRateToCountry: { servicesRate } } } = newCountryInfo
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'country.servicesRate', data: { isOpen: true, ...formData, servicesRate } })
  }
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const { countryId, servicesRate } = formData
  return (
    <Modal
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <ModalHeader
          toggle={toggle}
          close={<IconClose size={20} onClick={toggle} />}
          className='mt-md-4'
        >
          <span className='h2 font-weight-bold mr-4'>
            {translate('country.setServicesRate')}
          </span>
        </ModalHeader>
      }
    >
      {servicesRate && <StudentServiceSelector countryId={countryId} services={servicesRate} onSuccess={updateModalData} />}
    </Modal>
  )
}

SetServicesRateModalForm.propTypes = {}

export default SetServicesRateModalForm
