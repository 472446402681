import React, { useState, useMemo } from 'react'
import { IconAdd, Table } from '@Knowledge-OTP/znk-ui-components'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import moment from 'moment-timezone'
import useAllActivities from '../useActivities'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import isEmpty from 'lodash/isEmpty'
import HeaderFields from './HeaderFields'
import ActivitiesFilters from '../Filters'
import omit from 'lodash/omit'
import usePopupDisableTest from '../PopUps/DisableTest/usePopupDisableTest'
import PopupDisableTest from '../PopUps/DisableTest/popUpDisableTest'
const TestTable = (props) => {
  const { services, servicesLoading } = props
  const allServices = services?.docs
  const [filters, setFilters] = useState({
    serviceIds: [],
    from: moment().toISOString(),
    to: null
  })
  const { data, loading, refetch } = useAllActivities({
    kinds: ['test'],
    isDisabled: false,
    ...filters
  })
  const { open: openDisableTest } = usePopupDisableTest()
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const docs = useMemo(() => {
    return data && !isEmpty(data) ? 
      data.allActivities.map((activity) => {
        return {
          ...activity,
          service: allServices?.find(({id}) => id === activity.serviceId) || null
        }
      }): 
      []
  }, [data, allServices]) 
  

  const getEditInfo = (item) => {
    return {
      ...omit(item, '__typename'),
      startDate: item.startDate.split('T')[0],
      registrationDate: item.registrationDate ? item.registrationDate.split('T')[0] : null,
      scoreReleaseDate: item.scoreReleaseDate ? item.scoreReleaseDate.split('T')[0] : null
    }
  }
  const editTest = (item) => dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'activities.edit', data: { isOpen: true, kind: 'test', refetch, ...(getEditInfo(item)) } })
  return (
    <>
      <span className='px-2 my-3 d-flex justify-content-between align-items-center'>
        <span className='h2 d-flex justify-content-between align-items-center'>
          {translate('constants.activities.testTitle')}
          <ActivitiesFilters
            kind='test' {...{ filters, setFilters, services, servicesLoading }} baseFilter={{
              serviceIds: [],
              from: null,
              to: null
            }}
          />
        </span>
        <span><IconAdd className='text-primary' onClick={() => dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'activities.create', data: { isOpen: true, kind: 'test', refetch } })} /></span>
      </span>
      <Table
        loading={loading}
        tableData={docs.filter(item => item.kind === 'test' && !item.isDisabled)}
        headerColumns={HeaderFields()}
        columnList={[
          {
            render: (item) => item?.service ? item?.service?.name : '-',
            id: 'serviceName',
            colSpan: 2,
            className: 'text-secondary align-items-center justify-content-start'
          },
          {
            render: (item) => (<span title={item.name}>{`${item.name}`}</span>), id: 'name', colSpan: 3, className: 'text-title'
          }, {
          render: (item) => {
            return !item.tbd ? '✗' : '✓'
          },
          id: 'tbd',
          colSpan: 2,
          className: 'text-secondary align-items-center justify-content-center'
        }, {
          render: (item) => item.startDate ? moment(item.startDate).utc().format('MMMM Do, YYYY') : '-',
          id: 'testDate',
          colSpan: 2,
          className: 'text-secondary align-items-center justify-content-center'
        }, {
          render: (item) => item.registrationDate ? moment(item.registrationDate).utc().format('MMMM Do, YYYY') : '-',
          id: 'registration',
          colSpan: 2,
          className: 'text-secondary align-items-center justify-content-center'
        }, {
          render: (item) => item.scoreReleaseDate ? moment(item.scoreReleaseDate).utc().format('MMMM Do, YYYY') : '-',
          id: 'scoreRelease',
          colSpan: 2,
          className: 'text-secondary align-items-center justify-content-center'
        }, {
          optionsParser: (item) => {
            return { mainBtnConfig: { text: translate('misc.edit'), onClick: () => editTest(item) }, options: [
              { component: <span onClick={() => editTest(item)}>{translate('misc.edit')}</span> },
              { component: <span onClick={() => openDisableTest(item.id, refetch)}>{translate('misc.disable')}</span> }] }
          },
          colSpan: 1,
          type: 'selector',
          className: 'justify-content-end align-items-center text-secondary',
          id: 'buttons'
        }]}
      />
      <PopupDisableTest />
    </>
  )
}

export default TestTable
