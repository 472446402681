import {useQuery, gql} from '@apollo/client'

const useGetPdfTemplate = (vars = {}) => {
    return useQuery(
        gql`
            query (
                $templateId: ID
                $templateKey: ID
            ) {
                getPdfTemplate (
                    templateId: $templateId
                    templateKey: $templateKey
                ) {
                    id
                    name
                    json
                    key
                }
            }
        `,
        {
            variables: vars
        }
    )
}

export default useGetPdfTemplate