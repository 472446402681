import {useMutation, gql} from '@apollo/client'
import planQueryResult from './queryResults/planQueryResult'

const useCreateCouncelingPlanMutation = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $timelineInput: TimelineInput
                $councelingPlanInput: CouncelingPlanInput!
                $isAtomic: Boolean
                ) {
                createCouncelingPlan (
                    councelingPlanInput: $councelingPlanInput
                    timelineInput: $timelineInput
                    isAtomic: $isAtomic
                )  {
                    ${planQueryResult}
                }
            }
        `,
        {
            onCompleted: (res) => {
                onSuccess(res)
            },
            onError
        }
    )
}

export default useCreateCouncelingPlanMutation