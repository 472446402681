import React, { useState, useMemo } from "react";
import { IconLesson } from "@Knowledge-OTP/znk-ui-components";
import PlanListItem from "./PlanListItem";
import AlertNotification from "../Components/AlertNotification";
import CardHeader from "../Components/CardHeader";
import useGetScoreQuery from "./Model/useGetScoreQuery";
import useGetTestRegistrationQuery from "./Model/useGetTestRegistrationQuery";
import { TabContent, TabPane } from "reactstrap";
import SkeletonPlanList from "./PlanListItem/Skeleton";
import SendWspAlertModal from "./Modal/SendWspAlert";
import DismissNotificationModal from "./Modal/DismissNotification";
import useTranslation from "../../../../../i18n/useTranslation";

const GETSCORE_TYPE = "getScore";
const TESTREGISTRATION_TYPE = "testRegistration";

const TestDates = () => {
  const [show] = useState(false);
  const { translate } = useTranslation();
  // const [loading, setLoading] = useState(false)
  const {
    data: dataGetScore,
    loading: loadGetScoreQuery,
    refetch: refetchScoreQuery,
  } = useGetScoreQuery();
  const {
    data: dataTestRegistration,
    loading: loadTestRegistrationQuery,
    refetch: refetchTestRegistrationQuery,
  } = useGetTestRegistrationQuery();
  // const dataGetScore = {}
  // const dataTestRegistration = {}
  // console.log("dataGetScore =>", dataGetScore)
  // console.log("dataTestRegistration =>", dataTestRegistration)
  const [loadGetScoreState, setLoadGetScore] = useState(false);
  const [loadTestRegistrationState, setLoadTestRegistration] = useState(false);

  const loadGetScore = loadGetScoreQuery || loadGetScoreState;
  const loadTestRegistration =
    loadTestRegistrationQuery || loadTestRegistrationState;
  const loading = loadGetScore || loadTestRegistration;
  const refetchTestRegistration = () => {
    setLoadTestRegistration(true);
    refetchTestRegistrationQuery().then(
      () => {
        setLoadTestRegistration(false);
      },
      () => {
        setLoadTestRegistration(false);
      }
    );
  };

  const refetchScore = () => {
    setLoadGetScore(true);
    refetchScoreQuery().then(
      () => {
        setLoadGetScore(false);
      },
      () => {
        setLoadGetScore(false);
      }
    );
  };

  const refetch = () => {
    refetchTestRegistration();
    refetchScore();
  };
  const [tab, setTab] = useState(GETSCORE_TYPE); // [testRegistration, getScore]

  const [getScoreNotfications, testRegistrationNotification] = useMemo(() => {
    return [
      dataGetScore?.getScoreQuery || [],
      dataTestRegistration?.getTestRegistrationQuery || [],
    ];
  }, [dataGetScore, dataTestRegistration]);

  return (
    <>
      <CardHeader
        innerInfo={translate("dashboard.testDates.info")}
        title={`Test dates`}
        className={`secondaryBackground`}
        onReload={() => {
          refetch();
        }}
        filterSection={
          <div className={`m-0 p-0`}>
            <div className={`d-flex flex-row justify-content-start w-100`}>
              <span
                className={`h45 ${
                  tab === "getScore"
                    ? "font-weight-bold text-info"
                    : "text-gray"
                } cursor-pointer m-0 mx-3 text-truncate`}
                onClick={() => {
                  setTab(GETSCORE_TYPE);
                }}
              >
                {`Get score ${
                  !loadGetScore && getScoreNotfications?.length > 0
                    ? `(${getScoreNotfications.length})`
                    : ""
                }`}
              </span>
              <span
                className={`h45 ${
                  tab === "testRegistration"
                    ? "font-weight-bold text-info"
                    : "text-gray"
                } cursor-pointer m-0 mx-3 text-truncate`}
                onClick={() => {
                  setTab(TESTREGISTRATION_TYPE);
                }}
              >
                {`Test registration ${
                  !loadTestRegistration &&
                  testRegistrationNotification?.length > 0
                    ? `(${testRegistrationNotification.length})`
                    : ""
                }`}
              </span>
            </div>
          </div>
        }
      />
      <div
        className="d-flex flex-column w-100 m-0 p-0"
        // style={{backgroundColor: '#f8f9fa'}}
      >
        <TabContent activeTab={tab}>
          <TabPane tabId={GETSCORE_TYPE}>
            <TabContent
              activeTab={
                loadGetScore
                  ? "loading"
                  : getScoreNotfications.length > 0
                  ? "show"
                  : "blank"
              }
            >
              <TabPane tabId={"show"}>
                {getScoreNotfications.map((plan, index) => {
                  return (
                    <PlanListItem
                      type={GETSCORE_TYPE}
                      refetch={refetchScore}
                      plan={plan}
                      className={index % 2 === 0 ? `secondaryBackground` : ""}
                    />
                  );
                })}
              </TabPane>
              <TabPane tabId={"loading"}>
                {new Array(5).fill(0).map(() => (
                  <SkeletonPlanList className={`px-4 py-3 mb-2`} />
                ))}
              </TabPane>
              <TabPane className={`w-100`} tabId={`blank`}>
                <div
                  className={`d-flex flex-row justify-content-center w-100`}
                  style={{ height: "200px" }}
                >
                  <div
                    className={`d-flex flex-column justify-content-center align-items-center text-center`}
                  >
                    <IconLesson size={40} className={`text-disabled`} />
                    <label className={`h45 mt-4 text-gray`}>
                      No notifications
                    </label>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </TabPane>
          <TabPane tabId={TESTREGISTRATION_TYPE}>
            <TabContent
              activeTab={
                loadTestRegistration
                  ? "loading"
                  : testRegistrationNotification?.length > 0
                  ? "show"
                  : "blank"
              }
            >
              <TabPane tabId={"show"}>
                {testRegistrationNotification.map((plan, index) => {
                  return (
                    <PlanListItem
                      refetch={refetchTestRegistration}
                      plan={plan}
                      type={TESTREGISTRATION_TYPE}
                      className={index % 2 === 0 ? `secondaryBackground` : ""}
                    />
                  );
                })}
              </TabPane>
              <TabPane tabId={"loading"}>
                {new Array(5).fill(0).map(() => (
                  <SkeletonPlanList className={`px-4 py-3 mb-2`} />
                ))}
              </TabPane>
              <TabPane className={`w-100`} tabId={`blank`}>
                <div
                  className={`d-flex flex-row justify-content-center w-100`}
                  style={{ height: "200px" }}
                >
                  <div
                    className={`d-flex flex-column justify-content-center align-items-center text-center`}
                  >
                    <IconLesson size={40} className={`text-disabled`} />
                    <label className={`h45 mt-4 text-gray`}>
                      No notifications
                    </label>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </TabPane>
        </TabContent>
      </div>
      {/* <button onClick={() => {setShow(!show)}}>click</button> */}
      <AlertNotification
        loading={loading}
        show={show}
        onClick={() => {
          refetch();
        }}
      />
      <DismissNotificationModal
        title={
          tab === "getScore"
            ? translate("dashboard.testDates.dismissModal.title")
            : translate("dashboard.testRegistration.dismissModal.title")
        }
        textBody={
          tab === "getScore"
            ? translate("dashboard.testDates.dismissModal.textBody")
            : translate("dashboard.testRegistration.dismissModal.textBody")
        }
        confirmText={
          tab === "getScore"
            ? translate("dashboard.testDates.dismissModal.confirmText")
            : translate("dashboard.testRegistration.dismissModal.confirmText")
        }
        cancelText={
          tab === "getScore"
            ? translate("dashboard.testDates.dismissModal.cancelText")
            : translate("dashboard.testRegistration.dismissModal.cancelText")
        }
      />
      <SendWspAlertModal />
    </>
  );
};

export default TestDates;
