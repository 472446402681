import React from 'react'
import { Badge } from 'reactstrap'

const UniversitiesQuantity = (props) => {
const freeLimit = 10
const {quantity} = props

    return (
        <div
        className='d-flex justify-content-end w-100'>
                <Badge
                  className={`mx-0 mt-0s px-3 mr-1 badge badge-pill p-0 ${quantity < freeLimit ? 'badge-secondary' : 'badge-warning'} `}
                  pill
                >
                  {quantity}/10
                </Badge>
        </div>
    )
}

export default UniversitiesQuantity