import React, { useState, useEffect } from 'react'
import {
    ModalLayout,
    IconLoad,
    IconDuplicate
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import { useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import useModalEmailsList from './useModalEmailsList'
import usePlans from '../../Pages/Student/modal/InfoStudent/useQueries/usePlans'
import useGetEmailsFromInvoices from './Model/useGetEmailsFromInvoices'
import useGetEmailsFromPayslips from './Model/useGetEmailsFromPayslips'
import useGetEmailsFromUsers from './Model/useGetEmailsFromUsers'
import './toogle.css'
import { getEmailsListFromUsers } from './Utils/getEmailsListFromUsers'
import { getEmailsListFromParents } from './Utils/getEmailsFromParents'
import EmailItemList from './Components/EmailItem'
import useCopyText from '../CopyText/useCopyText'
import { showAlert } from '../Alert/util'
import useTranslation from '../../i18n/useTranslation'
import { showFiltersList } from './Utils/showFiltersList'
import { useGetUsersById } from './Model/useGetUsers'
import { getUsersIdsFromPlan } from './Utils/getUsersIdsFromPlan'

const EmailsListModal = () => {
    const {
        isOpen,
        toggle,
        query: variables,
        type,
    } = useModalEmailsList()
    const dispatch = useDispatch()
    const { translate } = useTranslation()
    const [activeTab, setActiveTab] = useState(true)
    const [role, setRole] = useState('student')
    const [listFromPlan, setListFromPlan] = useState([])
    const [emailsList, setEmailsList] = useState([])
    const [copyText, setCopyText] = useCopyText()
    const [filtersList, setFiltersList] = useState([])
    const [newState, setNewState] = useState(false)
    const copyAllFn = () => {
        const copiedMails = activeTab ? emailsList?.studentsEmails.join() : emailsList?.parentsEmails.join()
        setCopyText(copiedMails, copySuccess, copyError)
    }

    const copyOneFn = (email) => {
        setCopyText(email, copySuccess, copyError)
    }

    const [copySuccess, copyError] = [
        (text) => {
            //success
            showAlert({ text: translate("modal.emailsList.copySuccess"), status: 'success' }, dispatch)
        },
        (error, text) => {
            //error
            showAlert({ text: `${translate("modal.emailsList.copyError")} \n ${copyText}`, status: 'error' }, dispatch)
        }
    ]
    const [getPlans, {data, loading}] = usePlans()
    const [getInvoices, { data: dataInvoices, loading: loadingInvoices }] = useGetEmailsFromInvoices()
    const [getPayslips, { data: dataPayslips, loading: loadingPayslips }] = useGetEmailsFromPayslips()
    const [getUsers, { data: dataUsers, loading: loadingUsers }] = useGetEmailsFromUsers()
    const [getUsersById, { data: dataUsersById, loading: loadingUsersById }] = useGetUsersById()
    const chooseQuery = (type) => {
        let roleToQuery = ''
        switch (true) {
            case typeof type === 'object':
                roleToQuery = type?.students ? 'STUDENT' :
                type?.parents ? 'PARENT' :
                type?.educators ? 'EDUCATOR' :
                'SUPER_ADMIN'
                variables.role = roleToQuery
                variables.testDate = roleToQuery === 'EDUCATOR' ? null : variables.testDate?.date
                getUsers({ variables })
                return
            case type === 'counselingPlan' || type === 'plans':
                getPlans({ variables })
                return
            case type === 'payslips':
                getPayslips({ variables })
                return
            case type === 'payments':
                getInvoices({ variables })
                return
            default:
                console.error('No type found')
        }
    }

    useEffect(() => {
        if (isOpen && variables) {
            chooseQuery(type)
        }
        setFiltersList(showFiltersList(variables))
        // eslint-disable-next-line
    }, [variables])

    useEffect(() => {
        //Obtener los ids de los estudiantes
        const studentsIds = getUsersIdsFromPlan(data?.plans?.docs)
        if (studentsIds?.length < 1) return
        getUsersById({
            variables: {
                userIds: studentsIds,
            }
        })
        // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        getEmailsListFromUsers(dataUsersById?.users)
        setNewState(getEmailsListFromUsers(dataUsersById?.users))
        // eslint-disable-next-line
    }, [dataUsersById]) 

    useEffect(() => {
        setListFromPlan(dataInvoices?.invoices?.docs)
    }, [dataInvoices])

    useEffect(() => {
        setListFromPlan(dataPayslips?.payslips?.docs)
    }, [dataPayslips])

    useEffect(() => {
        setListFromPlan(dataUsers?.allUsersFilter?.docs)
    }, [dataUsers])

    useEffect(() => {
        setEmailsList(newState)
    }, [newState])

    useEffect(() => {
        if (type === 'plans' || type === 'counselingPlan') setEmailsList(getEmailsListFromUsers(dataUsersById?.users))
        if (type === 'payments') setEmailsList(getEmailsListFromUsers(listFromPlan))
        if (type === 'payslips') setEmailsList(getEmailsListFromUsers(listFromPlan))
        if (typeof type === 'object' && !type?.parents) setEmailsList(getEmailsListFromUsers(listFromPlan))
        if (typeof type === 'object' && type?.parents) setEmailsList(getEmailsListFromParents(listFromPlan))
        // eslint-disable-next-line
    }, [listFromPlan, role])

    return (
        <ModalLayout
            className={`modal-centered modal-colored h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={`m-0 p-0 mt-4 mb-3 d-flex flex-column`}>
                    <div className={`d-flex flex-row align-items-center mb-5`}>
                        <span className={`h2 text-title m-0`}>Emails Addresses</span>
                    </div>
                </div>
            }
            underHead={
                <div className='d-flex flex-column w-100 mb-2'>
                    <div>
                        {filtersList?.length > 0 && 
                        filtersList.map((f, index) => {
                            return f.filterName !== undefined ? <span className='text-gray'>  { index !== 0 && index !== filtersList?.length - 1 ? ' | ' : null} {f.filterName} {f.filterValues} </span> : null
                        })
                        }
                    </div>
                    {(type?.students || type?.parents || type === "plans" || type === "counselingPlan") && <div>
                        <span className='text-primary'>Students</span>
                        <label class="form-switch my-auto mx-3">
                            <input type="checkbox"
                                onClick={() => { setActiveTab(!activeTab) 
                                if (type === "plans") setRole(!activeTab ? 'student' : 'parent')
                                }}>
                            </input>
                            <i></i>
                        </label>
                        <span className='text-primary'>Parents</span>
                    </div>}
                    <div className='d-flex flex-row w-100 justify-content-end'>
                        {(loading || loadingInvoices || loadingPayslips || loadingUsers || loadingUsersById) ? <IconLoad />
                            :
                            emailsList?.studentsEmails?.length > 0 ?
                            <span title={'Copy All'} className='text-primary'>
                                <IconDuplicate size={24} color={'gray'}
                                title={'Copy All'}
                                    onClick={() => { copyAllFn() }} />
                            </span> : null

                        }
                    </div>
                </div>
            }
        >
            <div className={`m-0 p-0 d-flex flex-column`}>
                <div className={`borderTop w-100`} />
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={false} key={'parents'}>
                        <div className='d-flex flex-column'>
                            {emailsList?.parentsEmails?.length > 0 &&
                                emailsList?.parentsEmails.map((email, index) => (
                                    <EmailItemList
                                        item={email}
                                        index={index}
                                        onClick={() => { copyOneFn(email) }}
                                    />
                                ))
                            }
                        </div>
                    </TabPane>

                    <TabPane tabId={true} key={'students'}>
                        <div className='d-flex flex-column'>
                            {emailsList?.studentsEmails?.length > 0 &&
                                emailsList?.studentsEmails.map((email, index) => (
                                    <EmailItemList
                                        item={email}
                                        key={index}
                                        onClick={() => { copyOneFn(email) }}
                                    />
                                ))
                            }
                        </div>
                    </TabPane>

                    <TabPane tabId={'loading'} key={'students'}>
                        <div className='d-flex flex-column'>
                            <Skeleton height={44} width={320} count={10} />
                        </div>
                    </TabPane>

                </TabContent>
            </div>
        </ModalLayout>
    )
}

export default EmailsListModal