import {useMutation, gql} from '@apollo/client'

const useEditEventMutation = (onSuccess = () => {}, onDelete = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $editEventInput: EventEditInput!
                $activityId: String!
                ) {
                editEvent (
                    editEventInput: $editEventInput
                    activityId: $activityId
                )  {
                    name
                    tbd
                    id
                    kind
                    startDate
                    endDate
                    busy
                    userIdsInvolved
                    createdAt
                    updatedAt
                    googleCalendarEventId
                    description
                }
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError: () => {
                onDelete()
            }
        }
    )
}

export default useEditEventMutation