import React from 'react'
import { Text } from '@react-pdf/renderer'

const Paragraph = (props) => {
    return (
        <Text style={props?.style || {}}>
            {props.children}
        </Text>
    )
}

export default Paragraph