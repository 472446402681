import React, { useEffect, useMemo, useState } from 'react'
import {
    Input
} from '@Knowledge-OTP/znk-ui-components'
import {InputGroup, InputGroupAddon} from 'reactstrap'

const InputNumber = (props) => {
    const {className, label, value = null, onChange = () => {}} = props


    const {
        numberValue,
        typeValue
    } = useMemo(
        () => {
            let patt = /[a-zA-Z%]/g;
            let letters = value.match(patt);
            return {
                numberValue: parseInt(value),
                typeValue: letters.join('')
            }
        }, [value]
    )
    const [currentType, setCurrentType] = useState('px')
    useEffect(() => {if(typeValue) setCurrentType(typeValue)}, [typeValue])

    const inputTypes = ['px', '%']
    

    return (
        <div className={`d-flex flex-row ${className}`}>
            <span className={`align-self-center pr-2`}>{`${label}`}</span>
            
            <InputGroup className='znk-input-group-amount'>
                <Input className={`znk-input znk-input-amount flex-grow-1`} type={`number`} value={numberValue} onChange={(e) => {
                    onChange(e, typeValue)
                    }} />

                <InputGroupAddon 
                    onClick={() => {
                        if(inputTypes.indexOf(currentType) === inputTypes.length-1) {
                            //primero de la lista
                            setCurrentType(inputTypes[0])   
                        } else {
                            //siguiente en la lista
                            setCurrentType(inputTypes[inputTypes.indexOf(currentType)+1])
                        }
                    }}
                    className='znk-pre-input-amount cursor-pointer' addonType='append'>
                    {currentType}
                </InputGroupAddon>    
            </InputGroup>
            
        </div>
    )
}

export default InputNumber