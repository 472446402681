import React, { useState } from 'react'
import {
    ModalLayout,
    useWindowDimensions,
    Button,
    Input,
    InputSelect,
} from '@Knowledge-OTP/znk-ui-components'
import { Form, TabContent, TabPane } from 'reactstrap'
import { showAlert } from '../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import useSendSummaryEmail from './useSendSummaryEmail'
import moment from 'moment-timezone'
import useSendInvoiceProcessingSummaryMail from './Model/useSendInvoiceProcessingSummaryMail'

const SendSummaryEmailModal = () => {
    const { breakWidth } = useWindowDimensions()
    const dispatch = useDispatch()
    const { isOpen, toggle, useMutation, onChange, close } = useSendSummaryEmail()
    const [form, setForm] = useState({ category: 'livePrep' })
    const { translate } = useTranslation()
    const [sendSummary, { loading }] = useSendInvoiceProcessingSummaryMail(
        (res) => {
            showAlert({text: translate("misc.summaryInvoice.onSuccess"), status: 'success'}, dispatch)
            close()
        },
        (err) => {
            console.error(err)
            showAlert({ text: err.toString(), status: 'error' }, dispatch)

        }
    )

    const [inputTab, setInputTab] = useState('selector')
    const toggleTab = () => {
        setInputTab(inputTab === 'selector' ? 'custom' : 'selector')
    }

    const currentMonth = moment()
    const monthsArray = new Array(24).fill(0).map(
        (_, index) => {
            const thisMonth = currentMonth.clone().subtract(index, 'months').set('date', 15)
            return { value: thisMonth.clone().format('YYYY-MM-DD'), label: thisMonth.clone().format(`MMMM, YYYY`) }
        }
    )
    return (
        <ModalLayout
            className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'
                } modal-hauto modal-noborder-bottom`}
            isOpen={isOpen}
            toggle={toggle}
            disabledScrollAnimation={true}
            topHead={
                <div className={`d-flex flex-column`}>
                    <span className={`h2 text-title font-weight-bold mt-0`}>{translate("misc.summaryInvoice.titleModal") || "Send Summary Invoice"}</span>
                </div>
            }
        >
            <Form value={form} 
            // onChange={(res) => {
            //     setForm({ ...form, [res.target.name]: res.target.value })
            // }}
            >
                <div className='d-flex flex-column'>
                    <label>{translate("misc.summaryInvoice.typeLabel")}</label>
                    <InputSelect
                        options={[
                            { value: 'livePrep', label: 'Liveprep' },
                            { value: 'counseling', label: 'Counseling' }
                        ]}
                        name='type'
                        value={form?.category}
                        onChange={(e) => { setForm({ ...form, category: e }) }}
                        isLoading={false}
                    />
                </div>
                <TabContent activeTab={inputTab}>
                    {/* misc.summaryInvoice.selectMonth
                    misc.summaryInvoice.custom */}
                    <TabPane tabId={'selector'}>
                        <div className='w-100 d-flex flex-column justify-content-between mt-2'>
                            <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                                <label>{translate("misc.summaryInvoice.selectMonth")}</label>
                            </div>
                            <InputSelect 
                            name='month'
                            value={form?.month}
                            onChange={(isoString) => {
                                if(!isoString) {
                                    setForm({...form, month: isoString, from: "", to: ""})
                                    return       
                                }
                                const startMonth = moment(isoString).startOf('month').format('YYYY-MM-DD')
                                const endMonth = moment(isoString).endOf('month').format('YYYY-MM-DD')
                                setForm({ 
                                    ...form, 
                                    month: isoString,
                                    from: startMonth, to: endMonth})
                            }}
                            options={monthsArray}/>
                        </div>
                    </TabPane>
                    <TabPane tabId={'custom'}>
                        <div className='row d-flex flex-row justify-content-between mt-2'>
                            <div className='col-6 pr-0'>
                                <label>{translate("misc.summaryInvoice.startLabel")}</label>
                                <Input 
                                    onChange={(res) => {setForm({...form, month: null, from: res.target.value})}}
                                    type='date' name='from' defaultValue={form?.from} value={form?.from} id='from' />
                            </div>
                            <div className='col-6 pl-0'>
                                <label>{translate("misc.summaryInvoice.endLabel")}</label>
                                <Input onChange={(res) => {setForm({...form, month: null, to: res.target.value})}}
                                    type='date' name='to' defaultValue={form?.to} value={form?.to} id='to' />
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
                <label className='h45 btn-link w-100 text-right' onClick={() => {toggleTab()}}>{translate(`misc.summaryInvoice.${inputTab === 'custom' ? 'back' : "custom"}`)}</label>
                <Button
                    className={'w-100 mt-4'}
                    size={'medium'}
                    color={'primary'}
                    label={translate("misc.summaryInvoice.sendMail") || 'Send Mail'}
                    disabled={!(form?.from?.length > 0) || !(form?.to?.length > 0) || !form?.category}
                    activity={loading}
                    onClick={() => {
                        if (useMutation) {
                            const parseFormDates = (form) => {
                                return {
                                    ...form, 
                                    from: moment(form?.from).utc().startOf('day').toISOString(),
                                    to: moment(form?.to).utc().endOf('day').toISOString(),
                                }
                            }
                            const parseForm = parseFormDates(form)
                            sendSummary({ variables: parseForm })
                        }
                        else onChange(form)
                    }}
                />
            </Form>

        </ModalLayout>
    )
}

export default SendSummaryEmailModal