import React, { useEffect, useMemo, useState } from 'react'
import { Button, IconSearch, IconOptions, InputSearch, IconSignPlus, DropDown, IconPayslip, IconPenalize, IconFilter, useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import StatusesFilter from '../Payments/Filters/Statuses'
import TypesFilter from './Filters/Types'
import StartDateBadge from '../Plans/Filters/StartDateBadge'
import EducatorFilter from './Filters/Educators'
import useUsers from '../../Model/Users/List'
import moment from 'moment'
import formatNumber from '../../utils/formatNumber'
import Menu from './Menu'
import NewPayslip from './New'
import ViewPayslip from './View'
import NewPaymentSlip from './NewPayment'
import EditPayslip from './Edit'
import ProcessPayslip from './Process'
import { isEqual } from 'lodash'
import { Badge, TabContent, TabPane } from 'reactstrap'
import useTranslation from '../../i18n/useTranslation'
import Pagination from '../../common/Pagination'
//MAILS LIST
import useModalEmailsList from '../../common/ModalEmailsList/useModalEmailsList'
import OptionsEmailsAndDownloads from '../../common/ModalEmailsList/Components/Options'
import {
  // getStatusByShortcuts,
  getQueryByShortcut as rawGetQueryByShortcut,
  getNewShortcuts
} from './util'

import NoAuthorize from '../../common/NoAuthorize'
import usePayslipsQuery from './Model/usePayslipsQuery'
import useTotalPayslipsQuery from './Model/useTotalPayslipsQuery'
import ModalFilterMobile from './Filters/ModalFilterMobile'

const baseStatuses = ['draft', 'pendingPayment', 'procesingPayment', 'paid', 'canceled']
export const baseTypes = ['lessonsPayment', 'reimbursement', 'training', 'content']
const DEFAULTQUERY = {
  limit: 10,
  category: undefined,
  current: true,
  pending: false,
  searchString: undefined,
  sortField: 'from',
  status: ['pendingPayment', 'procesingPayment', 'paid', 'unPaid'],
  userId: undefined,

  from: moment().clone().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z',
  to: moment().clone().endOf('month').format('YYYY-MM-DD') + 'T23:59:59.999Z',

  next: null,
  previous: null,
}
const PayslipsViews = props => {
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [query, setQuery] = useState(DEFAULTQUERY)
  const [txtSearch, setTxtSearch] = useState('')
  const { breakWidth } = useWindowDimensions()

  const { data: educators, loading: loadingEducator } = useUsers({ roles: ['EDUCATOR'], limit: 0 })
  // const debouncedSearchTerm = useDebounce(txtSearch, 500)
  const [activePage, setActivePage] = useState(1)
  const { staffInfo } = useSelector((state) => state.user)
  const isPermited = staffInfo?.permissions?.filter(e => e.isPermited).map(per => { return per.tag }).includes('payslips');

  const [shortcuts, setShortcuts] = useState({
    currentCicle: true,
    pending: false,
    upcomming: false,
    paidOut: false,
    cancelled: false,
    draft: false,
    all: false
  })
  const getQueryByShortcut = (shortcuts) => {
    return rawGetQueryByShortcut(shortcuts, DEFAULTQUERY)
  }

  //query defecto en base al shortcut seleccionado
  const defaultQuery = useMemo(() => {
    const newQuery = getQueryByShortcut(shortcuts)
    return {
      ...DEFAULTQUERY,
      ...newQuery
    }
    //desencadena actualización de query con 
    //defaultQuery ya actualizado al shortcut actual
  }, [shortcuts])

  useEffect(() => { setCurrentShortcut('currentCicle') }, [])

  const setCurrentShortcut = (shortcutKey) => {
    const newShortcut = getNewShortcuts(shortcutKey)
    setShortcuts(newShortcut)
    setActivePage(1)
    //desencadena useMemo de defaultQuery
  }

  useEffect(() => {
    const shortcutKey = Object.entries(shortcuts).find(([key, value]) => value)[0]?.toString()
    const newShortcut = getNewShortcuts(shortcutKey)
    //obtiene query en base a shortcut activos
    const shortQuery = getQueryByShortcut(newShortcut)
    setQuery({
      ...defaultQuery,
      ...shortQuery
    })
    //eslint-disable-next-line
  }, [defaultQuery])


  const canReset = useMemo(() => {
    // const defaultQuery = getDefaultQuery()
    const bool = !isEqual(query, defaultQuery)
    return bool
  }, [query, defaultQuery])


  //TODO : params category
  const { data, loading: loadPayslips, refetch: refetchPayslips } = usePayslipsQuery(query)
  const { data: dataTotal, loading: loadTotal, refetch: refetchTotal } = useTotalPayslipsQuery(query)

  const { totalAmount, totalEducators } = useMemo(
    () => {
      return dataTotal?.totalPayslips || {}
    }, [dataTotal]
  )
  const refetchQuery = () => {
    refetchPayslips()
    refetchTotal()
  }

  const {
    hasNext, hasPrevious, payslips,
    pageIndexPrevious, pageIndexNext, pageCount
  } = useMemo(
    () => {
      return {
        hasNext: data?.payslips?.hasNext || false,
        hasPrevious: data?.payslips?.hasPrevious || false,
        payslips: data?.payslips?.docs || [],
        pageIndexNext: data?.payslips?.next,
        pageIndexPrevious: data?.payslips?.previous,
        pageCount: Math.ceil((data?.payslips?.totalDocs || 0) / (query?.limit || 1))
      }
      //eslint-disable-next-line
    }, [data]
  )

  const processInvoices = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.processPayslip',
      data: {
        isOpen: true,
        isMultiple: true,
        invoiceId: ''
      }
    })
  }
  const viewPayslip = (id) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.viewPayslip',
      data: {
        isOpen: true,
        payslipId: id,
        onChange: () => {
          setFilter({ ...defaultQuery })
        }
      }
    })
  }
  const processPayslip = (id) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.processPayslip',
      data: {
        isOpen: true,
        isMultiple: false,
        payslipId: id,
        onChange: () => {
          setFilter({ ...defaultQuery })
        }
      }
    })
  }

  const setFilter = (newQuery) => {
    //reinicia la paginación
    setActivePage(1)
    const finalQuery = {
      ...query,
      ...newQuery,
      next: null, previous: null

    }
    if (isEqual(finalQuery, query)) refetchQuery()
    else setQuery(finalQuery)
  }

  const clickNext = () => {
    if (hasNext) {
      setActivePage((activePage + 1))
      setQuery({
        ...query,
        next: pageIndexNext,
        previous: ''
      })
    }
  }
  const clickPrevious = () => {
    if (hasPrevious) {
      setActivePage((activePage - 1))
      setQuery({
        ...query,
        next: '',
        previous: pageIndexPrevious
      })
    }
  }
  const changeSize = (value) => {
    setActivePage(1)
    setQuery({ ...query, limit: value })
  }
  const updateList = () => {
    setFilter({ ...defaultQuery })
    // setQuery(query)
  }
  // Modal lista de mails
  const { open: openEmailsListModal } = useModalEmailsList()
  if (!isPermited) {
    return <NoAuthorize />
  }
  return (
    <>
      <div
        className='col-12 col-md-12 d-flex flex-column justify-content-between'
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className={`d-flex flex-column px-md-6 px-lg-7`}>
          <div className='container-fluid mt-2 mt-md-5 d-flex px-0 flex-column-reverse flex-md-row'>
            <div className='d-flex flex-grow-1 mt-2 mt-md-0 flex-row align-items-center'>
              <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.currentCicle ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('currentCicle')}>Current Cycle</span>
              <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.pending ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('pending')}>Pending</span>
              <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.draft ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('draft')}>Draft</span>
              <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.paidOut ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('paidOut')}>Paid</span>
              <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.all ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('all')}>All Payslips</span>
            </div>
            <div className='d-flex align-items-center justify-content-end justify-content-md-start flex-row-reverse flex-md-row'>
              {/* <ButtonEmailsList 
                onClick={() => { openEmailsListModal(() => {}, {...query, limit: 0}, 'payslips') }} 
              /> */}
              <span>
                <InputSearch
                  placeholder='Search'
                  className='m-0 p-3 h-25 trans flex-nowrap'
                  addOnComponent={<IconSearch size={18} className='text-gray' />}
                  value={txtSearch}
                  onKeyUp={({ key }) => {
                    if (key === 'Enter') {
                      // hanledSearch(txtSearch) 
                      setFilter({ ...query, searchString: txtSearch })
                    }
                  }}
                  onChange={(value) => {
                    setTxtSearch(value)
                  }}
                />
              </span>
              <span><Button label='Process Payslips' className='btn-table' color='primary' onClick={processInvoices} /></span>
              <OptionsEmailsAndDownloads
                options={[{ title: translate('users.actions.emails'), onClick: () => { openEmailsListModal(() => { }, { ...query, limit: 0 }, 'payslips') } },
                ]}
                loading={false}
              />
            </div>
          </div>
          {
            breakWidth === 'SM' ?
              <ModalFilterMobile setQuery={setQuery} query={query}
              educators= {!loadingEducator && educators && educators.docs ? educators.docs : []}
              setFilter={setFilter}
              loading={loadingEducator}
              >
                <div className='hover-icon'><IconFilter size={20} className='text-disabled' /></div>
              </ModalFilterMobile> :
              <div className='container-fluid mt-2 mt-md-5 d-flex px-0'>
                <EducatorFilter
                  {...{
                    students: !loadingEducator && educators && educators.docs ? educators.docs : [],
                    studentFilters: query?.userId || [],
                    setStudentFilters: (ids) => {
                      setFilter({ ...query, userId: ids })
                    }
                  }}
                  loading={loadingEducator}
                />
                <StartDateBadge
                  startDateFilter={[query?.from, query?.to]}
                  setStartDateFilter={(range) => {
                    const [from, to] = range
                    setFilter({ ...query, from, to })
                  }}
                  typeFilter='Date'
                />
                {(shortcuts.all || shortcuts.currentCicle || shortcuts.upcomming) &&
                  <StatusesFilter {...{
                    statuses: baseStatuses, statusFilters: query?.status || [], setStatusFilters: (newStatus) => {
                      setFilter({ ...query, status: newStatus })
                    }
                  }} />}
                <TypesFilter {...{
                  types: baseTypes, typesFilters: query?.category || [], setTypesFilters: (typesFilters) => {
                    setFilter({ ...query, category: typesFilters })
                  }
                }} />
                <TabContent activeTab={canReset ? 'show' : 'hidden'}>
                  <TabPane tabId={`show`}>
                    <Badge className='mx-1 filters' pill color='option' onClick={() => {
                      setFilter({ ...defaultQuery })
                    }}>
                      <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                        <IconSignPlus size={15} className='mr-1' />
                        {translate('misc.cleanFilters')}
                      </label></Badge>
                  </TabPane>
                  <TabPane tabId={`hidden`}>
                    {null}
                  </TabPane>
                </TabContent>
              </div>}
          <div className='container-fluid d-flex flex-column mx-0 mt-4 px-0'>
            <TabContent activeTab={loadPayslips ? 'loading' : 'show'}>
              <TabPane tabId={`loading`}>
                <Skeleton className={`my-1`} count={query?.limit || 10} height={45} style={{ borderRadius: '12px' }} />
              </TabPane>
              <TabPane tabId={`show`}>
                {
                  payslips.map(invoice => {
                    return (
                      <div
                        key={invoice.id}
                        // className={`znk-invoice-row ${invoice.status === 'paid' ? 'paid' : shortcuts.upcomming ? 'upcomming' : 'livePrep'}`}
                        className={`znk-invoice-row ${['paid', 'unPaid', 'canceled', 'draft'].includes(invoice.status) ? invoice.status : shortcuts.currentCicle ? invoice.category : 'upcomming'}`}
                      >
                        <div className={`d-flex flex-row justify-content-start align-items-center w-20`} onClick={() => { viewPayslip(invoice.id) }}>
                          <IconPayslip className={'icon-pay'} size={16} />
                          {/* <span className='text-truncate d-flex flex-row justify-content-start' style={{justifyContent: 'flex-start !important'}}> */}
                          <label className='ml-3 mb-0 text-truncate'>
                            End Of {moment(invoice.to).format('MMMM')}{invoice.isManual ? ' (Manual)' : ''}
                          </label>
                          {/* </span> */}
                        </div>
                        <span className='font-weight-bold text-color' onClick={() => { viewPayslip(invoice.id) }}>${formatNumber(invoice.payslipProps.amount)}</span>
                        <span className='text-color text-truncate' onClick={() => { viewPayslip(invoice.id) }}>{invoice.userId.firstName} {invoice.userId.lastName}</span>
                        <span className='text-color opacity' onClick={() => { viewPayslip(invoice.id) }}>Bank Transfer</span>
                        <span className='text-color opacity justify-content-between' onClick={() => { viewPayslip(invoice.id) }}>
                          PAY-{String(invoice.payslipNumber).padStart(5, 0)}
                          {invoice && invoice.payslipProps.isPenalized && (<IconPenalize className='icon-pay' />)}
                        </span>
                        <span className='flex-grow-0'>
                          <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                            {invoice.status === 'pendingPayment'
                              ? <DropDown
                                direction='down'
                                fixedWidth
                                hoverEnabled={false}
                                options={[{
                                  component:
                                    <span
                                      className='p-3'
                                      onClick={() => {
                                        viewPayslip(invoice.id)
                                      }}
                                    >View Payslip
                                    </span>
                                }, {
                                  component:
                                    <span
                                      className='p-3'
                                      onClick={() => {
                                        processPayslip(invoice.id)
                                      }}
                                    >Process Payslip
                                    </span>
                                }]}
                              >
                                <div className='hover-icon'>
                                  <IconOptions style={{ transform: 'rotate(90deg)' }} className={`icon-pay`} />
                                </div>
                              </DropDown> :
                              <div className='d-flex flex-row cursor-not-allowed hover-icon'>
                                <IconOptions
                                  style={{ transform: 'rotate(90deg)' }}
                                  className='icon'
                                // onClick={() => { viewPayslip(invoice.id) }}
                                />
                              </div>
                            }
                          </div>
                        </span>
                      </div>
                    )
                  })
                }
              </TabPane>
            </TabContent>
            <Menu>
              <Button
                className='position-fixed p-0'
                color='primary'
                size={'small'}
                style={{ bottom: '10px', right: '25px', width: 'fit-content', zIndex: '1' }}
                label={<IconSignPlus size={15} className={`m-3`} style={{ transform: 'rotate(133deg)' }} />}
              />
            </Menu>
          </div>
        </div>
        <Pagination
          bottomFix
          className={`w-100 px-4 pt-4 d-flex flex-row justify-content-between align-items-center`}
          pageSize={query?.limit}
          changeSize={changeSize}
          clickNext={clickNext}
          clickPrevious={clickPrevious}
          hasPrevious={hasPrevious}
          hasNext={hasNext}
          activePage={activePage}
          pageCount={pageCount}
          loading={loadPayslips}
          innerSection={
            <TabContent className={`w-100`} activeTab={loadTotal ? 'loading' : 'show'}>
              <TabPane
                className={`w-100`}
                tabId={'loading'}>
                <div className={`d-flex flex-row justify-content-end align-items-center px-2 pt-2 pb-2`}>
                  {
                    new Array(2).fill(0).map(
                      () => {
                        return (
                          <div className={`mx-2 d-flex flex-column align-items-center`}>
                            <Skeleton count={1}
                              width={50} height={12}
                            />
                            <Skeleton count={1}
                              width={70} height={12}
                            />
                          </div>
                        )
                      }
                    )
                  }
                </div>
              </TabPane>
              <TabPane
                className={`w-100`}
                tabId={'show'}>
                <div
                  className={`d-flex flex-row justify-content-end align-items-center px-2 pt-2 pb-2`}
                >
                  <div className='d-flex flex-column align-items-center px-2'>
                    <span className={`h4 text-info font-weight-bold`}>Educators</span>
                    <span className={`h4 text-gray`}>{totalEducators}</span>
                  </div>
                  <div className='d-flex flex-column align-items-center px-2'>
                    <span className={`h4 text-info font-weight-bold`}>Total</span>
                    <span className={`h4 text-gray`}>{`$${formatNumber(totalAmount)}`}</span>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          }
        />
      </div>
      <NewPayslip refetch={updateList} />
      <EditPayslip refetch={updateList} />
      <ViewPayslip refetch={updateList} />
      <NewPaymentSlip refetch={updateList} />
      <ProcessPayslip refetch={updateList} />
    </>
  )
}

export default PayslipsViews
