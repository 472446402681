import React, {useMemo} from 'react'
import {
    CheckboxSelector, 
    IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import {Badge} from 'reactstrap'
import useAllTimelinesServices from '../../../Model/useAllTimelinesServices'

const ServicesFilter = (props) => {
    const {
        setFilters = () => {},
        filters = []
    } = props
    const {translate} = useTranslation()
    const {data} = useAllTimelinesServices()
    const services = useMemo(
        () => {
            return data?.allTimelinesServices || []
        }, [data]
    )
    return (
        <CheckboxSelector
            setDataFilters={setFilters}
            direction='bottomCenter'
            dataFilters={filters}
            typeFilter='statuses'
            data={
                services.map((item) => {
                return { name: item.name, id: item.id}
                }).sort((a, b) => {
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })
            }
            strings={{
                viewAll: translate('filters.plans.viewAll'),
                buttonCancel: translate('filters.plans.buttonCancel'),
                buttonApply: translate('filters.plans.buttonApply'),
                search: translate('filters.plans.search')
            }}
            >
            <Badge className='mx-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                    Services
                    <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default ServicesFilter