import {useMutation, gql} from '@apollo/client'

const useMoveStudentFromGroupPlan = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
        mutation (
            $studentId: ID!
            $destinyPlanId: ID!
            $originPlanId: ID!
            ) {
            moveStudentFromGroupPlan (
                studentId: $studentId
                destinyPlanId: $destinyPlanId
                originPlanId: $originPlanId
            ) {
                id
            }
        }
        `,
        {
            onCompleted, onError
        }
    )
}

export default useMoveStudentFromGroupPlan