import React from 'react'
import {
    IconArrowDown,
    IconArrowUp,
} from '@Knowledge-OTP/znk-ui-components'
const HeaderField = (props) => {
    const {className = '', active=false, onClick = () => {}, order = true, disableSort = false} = props
    return (
        <div onClick={disableSort ? ()=>{} : onClick} className={`header-plan-field ${disableSort ? 'disable-sort' : 'cursor-pointer'} ${active ? 'visible-icon' : ''} d-flex flex-row align-items-center ${className}`}>
            <span>{props.children}</span>
            {
                !order ? 
                    <IconArrowDown className={`ml-1 text-gray`} size={12}/> :
                    <IconArrowUp className={`ml-1 text-gray`} size={12}/>
            }
        </div>
    )
}

export default HeaderField