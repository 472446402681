import React, { useEffect, useMemo } from 'react'
import {
    IconOptions,
    ListItem,
    BlankSpace,
    IconExam,
    DropDownNew,
    DropDownItem,
    IconSignPlus,
    IconErase
} from '@Knowledge-OTP/znk-ui-components'
import {
    TabContent, TabPane
} from 'reactstrap'
import moment from 'moment'
import TimelinePlanSkeleton from './Skeleton'
import { useSelector } from 'react-redux'
import LazyAvatar from '../../../../../../../common/LazyAvatar'
import useCreateTimelineModal from '../../../../../../Timelines/Modal/CreateTimelineModal/useCreateTimelineModal'
import useGetFirtsPlanOnTimeline from '../../../../../Model/useGetFirtsPlanOnTimeline'
import useDismissPlan from '../../../../../../Dashboard/Student/Widget/PlansList/Modal/DismissPlan/useDismissPlan'
import DismissPlan from '../../../../../../Dashboard/Student/Widget/PlansList/Modal/DismissPlan'


const NextTimelinePlanList = (props) => {
    const { 
        className, limit = 3, onChange = () => {}, showLabel = false,
        //al cambiar su valor ejecuta función refetch
        executeRefetch = 0,
        avatarSize = 'avatar-medium'
    } = props
    // const translate = (str) => str
    const services = useSelector((state) => state.user.services)
    const {createIndividualPlan} = useCreateTimelineModal()

    const parsePlan = (timelinePlan) => {
        const startDate = timelinePlan?.parts.reduce(
            (start, { from }) => {
                if (start > moment(from).toDate()) return moment(from).toDate()
                return start
            }, Number.POSITIVE_INFINITY
        )


        const endDate = timelinePlan?.parts.reduce(
            (end, { to }) => {
                if (end < moment(to).toDate()) return moment(to).toDate()
                return end
            }, Number.NEGATIVE_INFINITY
        )

        const totalLessons = timelinePlan?.parts.reduce(
            (total, { totalLessons }) => {
                return total + (totalLessons || 0)
            }, 0
        )

        return {
            ...timelinePlan,
            startDate, endDate, totalLessons
        }
    }

    // const [plan, setPlan] = useState(null)
    // const [planList, setPlanList] = useState(null)
    const { data, loading, refetch } = useGetFirtsPlanOnTimeline({limit})
    const planList = useMemo(() => {
        const timelinePlans = data?.getFirtsPlanOnTimeline || []
        const parse = timelinePlans.map((timelinePlan) => parsePlan(timelinePlan))
        if (parse) return parse
        return []
    }, [data])
    

    useEffect(() => {
        refetch()
        //eslint-disable-next-line
    }, [executeRefetch])


    const onCreate = (plan) => {
        createIndividualPlan(plan, () => {
            refetch()
            onChange()
        })
    }

    const {open: openDismissModal} = useDismissPlan()
    const onDismiss = (plan) => {
        openDismissModal(() => {
            refetch()
            onChange()
        }, plan)
    }

    return (
        <>

            <div className={`m-0 p-0 d-flex flex-column ${className}`}>
                <TabContent activeTab={loading ? 'loading' : planList?.length > 0 ? 'show' : 'blank'}>
                    <TabPane tabId={`loading`}>
                        {
                            new Array(limit > 0 ? limit : 10).fill(0).map(
                                () => {
                                    return (
                                        <TimelinePlanSkeleton className={'w-100 my-2'} />
                                    )
                                }
                            )
                        }
                    </TabPane>
                    <TabPane tabId={`show`}>
                        {showLabel && (
                            <label className={`h45 text-gray`}>{`${planList?.length || 0} plan(s)`}</label>
                        )}

                        {
                            (planList || []).map(
                                (plan, index) => {
                                    const userName = `${plan?.studentUser?.firstName} ${plan?.studentUser?.lastName}`
                                    const dateLabel = `${moment(plan.startDate).format('Do MMMM')} - ${moment(plan.endDate).format('Do MMMM YYYY')}`
                                    const serviceName = services.find(({ id }) => id === plan?.serviceId)?.name
                                    return (
                                        <ListItem 
                                            hasHover
                                            className={`hover-no-border py-2 w-100 d-flex flex-row justify-content-between`}
                                            classNameLeft={`w-85`}
                                            showBottomLine={index < planList?.length - 1}
                                            // classNameRight={`d-flex flex-row justify-content-end align-items-center`}
                                            Left={
                                                <div className='d-flex flex-row align-items-center w-100 ml-2'>
                                                    <LazyAvatar
                                                        userId={plan?.studentUser?.studentUserId}
                                                        name={userName}
                                                        size={avatarSize}
                                                    />
                                                    <div className='d-flex flex-column text-truncate ml-2'>
                                                        <span className={`text-truncate text-info w-100 ml-0`} title={plan?.name}>
                                                            {userName}
                                                        </span>
                                                        <span className={`text-truncate w-100 h45 text-gray ml-1`} title={userName}>
                                                            {serviceName}
                                                        </span>
                                                        <span className={`text-truncate w-100 h45 text-gray ml-1`} title={dateLabel}>
                                                            {dateLabel}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            Right={
                                                <DropDownNew
                                                    direction={'bottomRight'}
                                                    options={[
                                                        {component: 
                                                            <DropDownItem 
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    e.nativeEvent.stopImmediatePropagation()
                                                                    onCreate(plan)
                                                                }} className={`d-flex flex-row aling-items-center text-primary`}>
                                                                <IconSignPlus className={`rotate-90`} size={16}/> Create
                                                            </DropDownItem>
                                                        },
                                                        {component: 
                                                            <DropDownItem 
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    e.stopPropagation()
                                                                    e.nativeEvent.stopImmediatePropagation()
                                                                    onDismiss(plan)
                                                                }}
                                                                className={`d-flex flex-row aling-items-center danger-option text-error`}>
                                                                <IconErase size={16} /> Dismiss
                                                            </DropDownItem>
                                                        }
                                                    ]}
                                                >
                                                    <div className={`hover-icon mr-1`}>
                                                        <IconOptions className={`rotate-90`} />
                                                    </div>
                                                </DropDownNew>    
                                            }
                                            onClick={() => {onCreate()}}
                                        />
                                    )
                                }
                            )
                        }
                    </TabPane>
                    <TabPane tabId={`blank`}>
                        <BlankSpace 
                            style={{height: '200px'}}
                            iconClassName={`mb-0 mt-4`}
                            textClassName={'pt-3 px-5 pb-5'}
                            className={`w-100`} icon={<IconExam className={`text-gray`} />} mainText={'No plans to create.'}/>
                    </TabPane>
                </TabContent>
                <DismissPlan/>
            </div>
        </>
    )
}

export default NextTimelinePlanList