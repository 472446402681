import React, { useState } from 'react'
import {
  ListItem,
  IconProfile,
  IconArrowDown,
  IconArrowUp,
  IconArrowRight,
  IconCircleDolar,
  IconEdit,
  IconDate,
} from '@Knowledge-OTP/znk-ui-components'
import { Collapse } from 'reactstrap'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../store/modal/const'
import useTranslation from '../../../i18n/useTranslation'

const Profile = (props) => {
  const {
    style,
    openSideModal,
    toggleSideModal,
    setActiveTabRight,
    userData
  } = props
  const [openCollapse, setOpenCollapse] = useState(false)
  const [openCollapseAv, setOpenCollapseAv] = useState(false)
  const [isOpenAvailable, setOpenAvailable] = useState(false)
  const dispatch = useDispatch()
  const { translate } = useTranslation()

  const toggleOpenEdit = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.edit',
      data: {
        isOpen: true,
        ...userData,
        currentIsActive: userData.isActive,
        phoneDialCode: userData.phone ? userData.phone.split(' ')[0] : '',
        phoneNumber: userData.phone ? userData.phone.split(' ')[1] : '',
        birthDay: userData.birthdate ? moment(userData.birthdate).date() : '',
        birthMonth: userData.birthdate ? moment(userData.birthdate).month() : '',
        birthYear: userData.birthdate ? moment(userData.birthdate).year() : '',
        email: userData.emails[0].address,
        timezone: { value: userData.timezone, label: userData.timezone },
        country: { value: userData.country.id, label: userData.country.name }
      }
    })
  }

  const toggleOpenSetServiceRate = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.servicesRate',
      data: {
        isOpen: true,
        typeUser: "staff",
        userId: userData?.id
      }
    })
  }

  const toggleOpenEditPermissions = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.Permissions',
      data: {
        isOpen: true,
        typeUser: "staff",
        userId: userData?.id
      }
    })
  }

  const toggleAvailabilityCollapse = () => {
    setOpenAvailable(!isOpenAvailable)
  }
  const toggleAvailabilityTemporal = () => {
    dispatch(
      {
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'user.availabilityTemporal',
        data: {
          dataUser: {
            id: userData.id
          }
        }
      }
    )
  }

  const toggleAvailabilityRecurring = () => {
    dispatch(
      {
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'user.availabilityRecurring',
        data: {
          dataUser: {
            id: userData.id
          }
        }
      }
    )
  }
  return (
    <div className='m-0 p-0 d-flex flex-column justify-content-start' style={style}>
      <ListItem
        showTopLine
        showBottomLine
        className='py-4'
        onClick={() => {
        }}
        Left={
          <div
            onClick={() => {
              setActiveTabRight('viewProfile')
              if (!openSideModal) toggleSideModal()
            }}
            className='d-flex flex-row'>
            <IconProfile color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              View Profile
            </span>
          </div>
        }
        Right={
          <div onClick={() => {
            setOpenCollapse(!openCollapse)
          }} className={'hover-icon m-0 px-1 py-0'}>
            {
              openCollapse
                ? <IconArrowUp className={'m-0 p-0 text-gray'} />
                : <IconArrowDown className={'m-0 p-0 text-gray'} />
            }
          </div>
        }
      />
      <Collapse isOpen={openCollapse} className={'m-0 pl-3 pr-0 py-0'}>
        <ListItem
          showTopLine={false}
          showBottomLine={false}
          className='py-4'
          onClick={() => {
            setActiveTabRight('editProfile')
            toggleOpenEdit()
            if (!openSideModal) toggleSideModal()
          }}
          Left={
            <div className='d-flex flex-row'>
              <IconEdit color='#72809D' className='align-self-center mr-2' />
              <span className='align-self-center text-title'>
                Edit Profile
              </span>
            </div>
          }
          Right={<IconArrowRight className={`text-gray`} />}
        />
      </Collapse>
      <ListItem
        showTopLine={openCollapse}
        showBottomLine
        className='py-4'
        onClick={() => {
          setActiveTabRight('editEmploymentDetails')
          toggleOpenSetServiceRate()
          if (!openSideModal) toggleSideModal()
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconCircleDolar color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              Employment Details
            </span>
          </div>
        }
        Right={<IconArrowRight className={`text-gray`} />}
      />
      <ListItem
        showTopLine={false}
        showBottomLine
        className='py-4'
        onClick={() => { 
          toggleAvailabilityCollapse() 
        }}
        Left={
          <div className='d-flex flex-row align-items-center'>
            <IconDate size={16} color='#72809D' className='mr-3' />
            <span className='text-title'>{translate('profile.availability')}</span>
          </div>
        }
        Right={
          <div onClick={() => {
            setOpenCollapseAv(!openCollapseAv)
          }} className={'hover-icon m-0 px-1 py-0'}>
            {
              openCollapseAv
                ? <IconArrowUp className={'m-0 p-0 text-gray'} />
                : <IconArrowDown className={'m-0 p-0 text-gray'} />
            }
          </div>
        }
      />
      <Collapse isOpen={isOpenAvailable}>
        <ListItem
          showTopLine={false}
          showBottomLine
          className='py-4'
          onClick={() => {
            setActiveTabRight('av-temporal')
            toggleAvailabilityTemporal()
            if (!openSideModal) toggleSideModal()
          }}
          Left={
            <div className='d-flex flex-row align-items-center'>
              <span className='text-title ml-4'>{translate('profile.availabilitytemp')}</span>
            </div>
          }
          Right={
            <IconArrowRight className={`text-gray`} />
          }
        />
        <ListItem
          showTopLine={false}
          showBottomLine
          className='py-4'
          onClick={() => {
            setActiveTabRight('av-recurring')
            toggleAvailabilityRecurring()
            if (!openSideModal) toggleSideModal()
          }}
          Left={
            <div className='d-flex flex-row align-items-center'>
              <span className='text-title ml-4'>{translate('profile.availabilityperm')}</span>
            </div>
          }
          Right={
            <IconArrowRight className={`text-gray`} />
          }
        />
      </Collapse>
      <ListItem
        showTopLine={openCollapse}
        showBottomLine
        className='py-4'
        onClick={() => {
          setActiveTabRight('editPermissions')
          toggleOpenEditPermissions()
          if (!openSideModal) toggleSideModal()
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconEdit color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              Edit Permissions
            </span>
          </div>
        }
        Right={<IconArrowRight className={`text-gray`} />}
      />
    </div>

  )
}


export default Profile