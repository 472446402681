import React, {useMemo} from 'react'
import {
    AvatarBlank, Avatar
} from '@Knowledge-OTP/znk-ui-components'

const Student = (props) => {
    const {task} = props
    const userName = useMemo(
        () => {
            if(task?.user) return `${task.user.firstName} ${task.user.lastName}`
            return null
        }, [task]
    ) 

    const studentName = useMemo(
        () => {
            if(task?.dataPlan?.studentUser) 
                return `${task?.dataPlan?.studentUser?.firstName} ${task?.dataPlan?.studentUser?.lastName}`
            return null
        }, [task]
    ) 
    
                                                
    return (
        <>
        {
            userName ? 
            <>
                <Avatar
                    size={'avatar-small'}
                    name={userName}
                    uri={task?.user?.avatar}
                    className={'mr-2'}
                    />
                <span className={`h45 text-info font-weight-light`}>{userName}</span>
            </>
            :
            <>
                <AvatarBlank
                    className={'mr-2'}
                />
                <span className={`h45 text-gray font-weight-light`}>{`${studentName}` || 'Not assigned'}</span>
            </>
        }
            
        </>
    )
}

export default Student