import {useQuery, gql} from '@apollo/client'
import queryLessonResult from '../../../Model/QueryResult/useLessonResult'

const useLessons = (vars = {}) => {
    return useQuery(
        gql`query(
            $studentIds: [String!]
            $sortField: String
            $previous: String
            $next: String
            $limit: Int
            $serviceIds: [ID!]
            $endDate: DateTime
            $startDate: DateTime
            $planId: String
            $status: [LessonStatus!]!
            ){
            lessons(
                studentIds: $studentIds
                sortField: $sortField
                previous: $previous
                next: $next
                limit: $limit
                serviceIds: $serviceIds
                endDate: $endDate
                startDate: $startDate
                planId: $planId
                status: $status
            ) {
                docs {
                    ${queryLessonResult}
                }
            }
        }`,
        {
            variables: vars
        }
    )
}

export default useLessons