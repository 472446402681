import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'

const EducatorAcademicInfo = (props) => {
    const universitiesList = useSelector((state) => state?.modal?.user?.educatorAcademicInfo?.universitiesList)
    const reduxData = useSelector((state) => state.modal.educator.infoEducatorModal)
    const { 
        user, 
        // eslint-disable-next-line
        refetch: update, 
        // eslint-disable-next-line
        refetchList = () => {} 
    } = props


    const dispatch = useDispatch()
    const { translate } = useTranslation()
    const [universityName, setUniversityName] = useState('-')
    const openFull = () => {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'educator.infoEducatorModal',
          data: {
            ...reduxData,
            full: true
          }
        })
      }
      const setPageProfile = (newPage) => {
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'educator.infoEducatorModal',
          data: {
            ...reduxData,
            full: true,
            pageProfile: newPage
          }
        })
      }
    const setPage= (newPage) => {
        openFull()
        setPageProfile(newPage)
      }
    const editAcademicInfo = () => {

            setPage('academic-info')

    }

    useEffect(() => {
        if (universitiesList?.length > 0) {
        setUniversityName(universitiesList?.filter((item) => item.id === user?.educatorInfo?.educatorAcademicInfo?.university)[0])
        }
        // eslint-disable-next-line
    }, [universitiesList])
    
    return (
                <>
                  <div className='d-flex flex-row justify-content-between mt-7'>
                    <div className='d-flex'>
                      <span className='font-weight-bold'>Academic Info</span>
                    </div>
                    <span className='btn-link' onClick={editAcademicInfo}>Edit</span>
                  </div>
                  <div className='borderTop mt-3' />
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>{translate('profile.actions.academicInfo.leveleducation')}</span>
                    <span className='text-gray'>{user?.educatorInfo?.educatorAcademicInfo?.educationLevel.charAt(0).toUpperCase() + user?.educatorInfo?.educatorAcademicInfo?.educationLevel?.slice(1)}</span>
                  </div>
                  <div className='borderTop mt-4' />
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>{translate('profile.actions.academicInfo.concentration')}</span>
                    <span className='text-gray'>{user?.educatorInfo?.educatorAcademicInfo?.concentration}</span>
                  </div>
                  <div className='borderTop mt-4' />
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>{translate('profile.actions.academicInfo.university')}</span>
                    <span className='text-gray'>{universityName?.name}</span>
                  </div>
                  <div className='borderTop mt-4' />
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>{translate('profile.actions.academicInfo.graduationyear')}</span>
                    <span className='text-gray'>{user?.educatorInfo?.educatorAcademicInfo?.graduationDate}</span>
                  </div>
                </>

    )

}

export default EducatorAcademicInfo