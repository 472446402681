import {gql, useQuery} from '@apollo/client'

const usePayslipsQuery = (vars = {}) => {
    return useQuery(
        gql`query(
            $limit: Int,
            $next: String,
            $previous: String,
            $sortField: String,
            $userId: [String!],
            $from: DateTime,
            $to: DateTime,
            $status: [PaymentStatus!]
            $searchString: String,
            $pending: Boolean,
            $current: Boolean
          ) {
            payslips(
              limit: $limit,
              next: $next,
              previous: $previous,
              sortField: $sortField,
              status: $status, 
              userId: $userId,
              from: $from,
              to: $to,
              searchString: $searchString
              pending: $pending,
              current: $current
            ) {
              hasNext
              hasPrevious
              next
              previous
              totalDocs            
              docs {
                id
                userId {
                    id
                    firstName
                    lastName
                }
                status
                from
                to
                category
                payslipDate
                payslipNumber
                paymentItemIds
                payslipDate
                isManual
                createdAt
                updatedAt
                payslipProps {
                  amount
                  isPenalized
                }
                paymentItemsPayslip {
                  id
                  amount
                  date
                }     
              }
            }
          }`,
        {
            variables: vars,
            fetchPolicy: 'cache-first'
        }
    )
}

export default usePayslipsQuery