import React from 'react'
import { Row } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { Button, TableSelector } from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import usePaginatedServices from '../../../Model/Services/List'
import CreateServiceModal from '../../../Model/Services/Create/Modal'
import EditServiceModal from '../../../Model/Services/Edit/Modal'
import DeleteServiceModal from '../../../Model/Services/Delete/Modal'
import pick from 'lodash/pick'
import moment from 'moment'
import SetTopicsModal from './Modal/SetTopicsModal'
import { ordenateArrayByName } from '../../../utils/ordenateArrayByName'
const ServicesView = () => {
  const { data, loading, error } = usePaginatedServices({ limit: 240 })
  const dispatch = useDispatch()
  const openEditModal = (service) => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'service.edit', data: { isOpen: true, ...service } })
  }
  const openDeletionModal = (service) => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'service.delete', data: { isOpen: true, ...service } })
  }
  const openSetTopicModal = (service) => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'service.setTopics', data: { isOpen: true, ...service } })
  }
  return (
    <div className='d-flex flex-column px'>
      <Row className='d-flex flex-column mx-0'>
        <div className='d-flex justify-content-end'>
          <span><Button label='+ Add' color='primary' onClick={() => dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'service.create', data: { isOpen: true } })} /></span>
        </div>
      </Row>
      <Row className='d-flex flex-column mx-0 mt-4'>
        {loading ? <Skeleton height={25} count={5} className='my-2' />
          : (error ? <div className='alert alert-danger'>Error en la petición</div> : data && ordenateArrayByName(data.docs).map((item) => {
            return (
              <div className='d-flex my-2'>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-start'>{item.name}</span>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-center'>{moment(item.createdAt).format('MMMM do, hh:mm')}</span>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-center'>{moment(item.updatedAt).format('MMMM do, hh:mm')}</span>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-end'>
                  <TableSelector
                    mainBtn={{
                      text: 'Edit'
                    }}
                    dropdownProps={{
                      options: [{
                        component: <span onClick={() => openEditModal({
                          serviceId: item.id,
                          updatedService: pick(item, ['name'])
                        })}
                                   >Edit Name
                        </span>
                      }, {
                        component: <span onClick={() => openSetTopicModal({
                          serviceId: item.id,
                          topicIds: Object.keys(item.topics).map((key) => {
                            const { id, name } = item.topics[key]
                            return { id, name }
                          })
                        })}
                                   >Edit Topics
                        </span>
                      }, {
                        component: <span onClick={() => openDeletionModal({
                          serviceId: item.id
                        })}
                                   >Delete
                        </span>
                      }]
                    }}
                  />
                </span>
              </div>
            )
          }))}
      </Row>
      <SetTopicsModal />
      <DeleteServiceModal />
      <CreateServiceModal />
      <EditServiceModal />
    </div>
  )
}

export default ServicesView
