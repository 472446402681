import React from 'react'
import {
  ListItem,
  IconArrowRight,
  CheckboxOption,
  IconAdd
} from '@Knowledge-OTP/znk-ui-components'
import LazyAvatar from '../../../../../common/LazyAvatar'
import useTranslation from '../../../../../i18n/useTranslation'
import SelectStudent from '../SelectStudent'
import PropTypes from 'prop-types'

const PlanStudent = (props) => {
  const { form, setForm, first, title, invoice, edit } = props
  const { translate } = useTranslation()
  return (
    <>
      <div className='pl-0 pr-2 py-4 pl-3'>
        {title && (<><h3><b>{translate('modal.newplan.student')}</b></h3><br /></>)}
        {edit ? (<SelectStudent
          setStudent={(stu) => {
            const val = { 
              ...form, 
              student: stu,              
            }
            if (!invoice) {
              val.isFirstPlan = stu?.studentInfo?.isFirtsPlan || false
              val.payments = {
                ...form.payments,
                registration: stu?.studentInfo?.notRegistrationFeePaid || false
              }
            }
            setForm(val)
          }}
          student={form.student || {}}
        >
          {form.student
            ? <ListItem
              className='py-1'
              id='x-0'
              label={null}
              Left={
                <div className='d-flex align-items-center'>
                  <LazyAvatar
                    userId={form?.student?.studentUserId || form?.student?.id}
                    name={`${form.student.firstName} ${form.student.lastName}`}
                    named={false}
                    uri={form.student.avatar}
                    size='avatar-medium'
                  />
                  <label className='ml-2 h4'>
                    {`${form.student.firstName} ${form.student.lastName}`}
                  </label>
                </div>
              }
              Right={<IconArrowRight />}
            />
            : !invoice ? <label className='btn-link'>{translate('modal.newplan.selectstudent')}</label>
              : <div style={{ alignItems: 'center' }}>
                <IconAdd className='text-primary' />
                <label className='btn-link ml-2'>{translate('payments.newinvoice.addstudent')}</label>
              </div>}
        </SelectStudent>)
          : (
            <>
              {form.student &&
              (<ListItem
                className='py-1'
                id='x-0'
                label={null}
                Left={
                  <div className='d-flex align-items-center'>
                    <LazyAvatar
                      userId={form?.student?.studentUserId || form?.student?.id}
                      name={`${form.student.firstName} ${form.student.lastName}`}
                      named={false}
                      uri={form.student.avatar}
                      size='avatar-medium'
                    />
                    <label className='ml-2 h4'>
                      {`${form.student.firstName} ${form.student.lastName}`}
                    </label>
                  </div>
                }
                Right={<IconArrowRight />}
              />)}
            </>
          )}
        {first && (
          <div className='d-flex px-2 my-4'>
            <CheckboxOption
              name='isFirstPlan'
              defaultState={form.isFirstPlan}
              value={form.isFirstPlan}
              onChange={
                (e) => {
                  setForm({
                    ...form,
                    isFirstPlan: e
                  })
                }
              }
            />
            <span
              style={{
                whiteSpace: 'nowrap',
                overflowX: 'hidden'
              }} title={translate('modal.newplan.needScheduledOrientation')}
            >{translate('modal.newplan.needScheduledOrientation')}
            </span>
          </div>
        )}
      </div>
      {!invoice && (<div className='borderTop ml-3 mb-1' />)}
    </>
  )
}

PlanStudent.propTypes = {
  first: PropTypes.bool,
  title: PropTypes.bool,
  invoice: PropTypes.bool,
  edit: PropTypes.bool
}

PlanStudent.defaultProps = {
  first: true,
  title: true,
  invoice: false,
  edit: true
}
export default PlanStudent
