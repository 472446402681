import React from 'react'

const StudentBlank = (props) => {
    const {className} = props
    return (
        <div className={`blank_students ${className}`}>
            <label className={`text-gray h45 text-align-center`}>
                Here will appear all the students who will have late work
            </label>
        </div>
    )
}

export default StudentBlank