import React, { useMemo, useState, useRef, useEffect} from 'react';
import { Table, IconSearch, IconIllustratedEmptyBox } from '@Knowledge-OTP/znk-ui-components'
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    TabContent,
    TabPane,
} from 'reactstrap'

import TablePlansSkeleton from '../../../Plans/Skeleton'
import Pagination from '../../../../common/Pagination'
import useGetUsers from '../../Model/useGetUsers';
import RenderFilters from '../../RenderFilters';
// import useTranslation from '../../../../i18n/useTranslation';
import lodash from 'lodash'
import useModalEmailsList from '../../../../common/ModalEmailsList/useModalEmailsList';

const defaultLimit = 10
const ComponentsTable = (props) => {
    const {
        tableData, setTableRef = () => {}, tableRef = {}, role='STUDENT', setUsers = () => {}, tabLinks = <></>, options = <></>, ...otherProps} = props
    // const {translate} = useTranslation()
    const defaultQuery = useMemo(() => {
        return {
            limit: defaultLimit,
            next: '',
            previous: '',
            role: role,
            search: null,
            serviceIds: [],
            topicIds: [],
            testDate: { id: '', name: '-', date: null },
            classYear: [],
            countriesIds: [],
            statuses: true,
        }
    }, [role])
    const [query, setQuery] = useState(defaultQuery)
    // const [queryData, setQueryData] = useState({})
    // const {users = [], paginationData = {}} = queryData

    const { data, loading, refetch } = useGetUsers(
        {...query, testDate: role === 'EDUCATOR' ? undefined : query.testDate?.date}
    )

    const getQueryData = (data) => {
        if(data?.allUsersFilter) {
            const {docs, ...paginationData} = data?.allUsersFilter
            setUsers(docs || [])
            return {
                users: docs || [],
                paginationData
            }
        }
        return {}
    }
    const {users = [], paginationData} = getQueryData(data) || {}

    const { setQueryForMailslist } = useModalEmailsList()
 

    const searchInput = useRef(null)
    const [, setValue] = useState('')


    const [currentPage, setCurrentPage] = useState(1)



    const canReset = useMemo(
        () => {
            return !lodash.isEqual(query, defaultQuery)
            //eslint-disable-next-line
        }, [query]
    )

    const totalPages = useMemo(() => {
        return (paginationData?.totalDocs || 0)/(query.limit || 1)
    }, [paginationData, query])

    const setRestauratePage = (newQuery) => {
        //restaura paginación
        setCurrentPage(1)
        setQuery({
            ...query,
            ...newQuery,
            next: '',
            previous: ''
        })
    } 

    useEffect(() => {
        if(refetch){
            setTableRef({
                ...tableRef,
                query,
                refetch: () => {
                    refetch()
                },
                refetchQuery: () => {
                    refetch()
                },
            })
        }
        // eslint-disable-next-line
    }, [refetch, query])

    useEffect(() => {
        setQueryForMailslist(query)
    // eslint-disable-next-line
    }, [query])
    
    return (
    <>
        <div
            className='col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between h-100 znk-scroll-horizontal' style={{ minHeight: '100%'}}>
            <div className={`m-0 p-0 d-flex flex-column h-100`}>
                <div 
                    style={{flexGrow: 'initial'}}
                    className='not-animation container-fluid mt-2 mt-md-5 d-flex px-0 justify-content-center flex-column-reverse flex-md-row'
                >
                    {tabLinks}
                    <div className='d-flex flex-row align-items-center justify-content-end justify-content-md-start flex-row-reverse flex-md-row'>
                        {/* <span className={'d-flex flex-row align-items-center'}> */}
                            <InputGroup className={`znk-card flex-nowrap`}>
                                <form autoComplete='off' onSubmit={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }}>
                                <input
                                    placeholder='Search'
                                    className='znk-input noRight trans form-control'
                                    id={`searchUser2-${role}`}
                                    key={`searchUser2-${role}`}
                                    ref={searchInput}
                                    // defaultValue={null}
                                    autoComplete={'off'}
                                    onKeyUp={(e) => {
                                        const { key } = e
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        if (key === "Enter") {
                                            setValue(searchInput?.current?.value || '')
                                            setRestauratePage({
                                                ...query, 
                                                search: searchInput?.current?.value || ''
                                            })
                                        }
                                    }}
                                />
                            </form>
                                <InputGroupAddon addonType='prepend' className='icon'>
                                <InputGroupText type='text' className='znk-no-border'>
                                    <span className='addon'><IconSearch size={18} className='text-gray' /></span>
                                </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                            {options}
                        {/* </span> */}
                    
                    
                    </div>
                </div>
                <RenderFilters 
                    style={{flexGrow: 'initial'}}
                    canReset={canReset} 
                    onReset={() => {setRestauratePage(defaultQuery)}} 
                    query={query} 
                    setQuery={(newQuery) => {
                        setRestauratePage(newQuery)
                    }} role={role} />
                <div style={{flexGrow: 1}} className={`m-0 p-0 appears-animation`}>
                    <TabContent className={`h-100`} activeTab={loading ? 'loading' : users?.length > 0 ? 'table' : 'blank'}>
                            <TabPane tabId={'loading'}>
                                <TablePlansSkeleton count={10} />
                            </TabPane>
                            <TabPane tabId={'table'}>
                                    <Table
                                        {...otherProps}
                                        tableData={users}
                                    />  
                            </TabPane>
                            <TabPane tabId={'blank'} className={`h-100`}>
                                    <div className={`w-100 h-100 d-flex flex-row justify-content-center align-items-center`}>
                                        <div className={`d-flex flex-column align-items-center m-0 p-0`}>
                                            <IconIllustratedEmptyBox size={250}/>
                                            <span className={`h1 font-weight-bold text-title`}>No rows data!</span>
                                        </div>
                                    </div>
                            </TabPane>
                    </TabContent> 
                </div>               
            </div>
            
        </div>
        <Pagination
            style={{
                bottom: '0px',
                position: 'sticky',
                backgroundImage: `linear-gradient(
                        to bottom, 
                        rgba(255, 255, 255, 0), 
                        rgba(255, 255, 255, 0.95) 25%
                    )`,
                paddingTop: '15px'
            }}
            pageSize={query.limit}
            changeSize={(size) => {setQuery({...query, limit: size})}}
            clickPrevious={() => {
                if(paginationData?.hasPrevious && !loading && currentPage > 1) {
                    setQuery({...query, previous: paginationData?.previous})
                    setCurrentPage(currentPage - 1)
                }
            }}
            hasPrevious={paginationData?.hasPrevious}
            clickNext={() => {
                if(paginationData?.hasNext && !loading && currentPage < totalPages ) {
                    setQuery({...query, next: paginationData?.next})
                    setCurrentPage(currentPage + 1)
                }
            }}
            hasNext={paginationData?.hasNext}
            activePage={currentPage}
            pageCount={Math.ceil(totalPages)}
            loading={loading}
        />     
    </>
    )
}

export default ComponentsTable