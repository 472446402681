import React from 'react'
import { Image as PDFImage, StyleSheet } from '@react-pdf/renderer'


const Image = (props) => {
    const styles = StyleSheet.create({
        img: props.style,
        // section: { color: 'white', textAlign: 'center', margin: 30 }
      });

    return (
        <PDFImage style={styles?.img || {}} src={props?.src ? props.src : null} />
    )
}

export default Image