import React, {useMemo} from 'react'
import {
    ListItem,
    Avatar
} from '@Knowledge-OTP/znk-ui-components'
import {TabPane, TabContent} from 'reactstrap'
import RenderInputOption from '../../../../../newPlanModal/Components/RenderInputOption'

const Team = (props) => {
    const {className, plan} = props
    const {/**activeTab,**/ councelorName, councelorAvatar} = useMemo(() => {
        if(plan?.counselorData) return {
            activeTab: 'selected',
            councelorName: `${plan?.counselorData?.firstName} ${plan?.counselorData?.lastName}`,
            councelorAvatar: plan?.aditionalInfo?.avatarCouncelor
        }
        return {activeTab: 'no-selected'}
        // eslint-disable-next-line
    }, [plan?.counselorData])

    const {/**activeTab,**/ advisorName, advisorAvatar} = useMemo(() => {
        if(plan?.advisorData) return {
            advisorName: `${plan?.advisorData?.firstName} ${plan?.advisorData?.lastName}`,
            advisorAvatar: plan?.aditionalInfo?.avatarAdvisor
        }
        return {}
        // eslint-disable-next-line
    }, [plan?.advisorData])

    const {/**activeTab,**/ headName, headAvatar} = useMemo(() => {
        if(plan?.headData) return {
            headName: `${plan?.headData?.firstName} ${plan?.headData?.lastName}`,
            headAvatar: plan?.aditionalInfo?.avatarHead
        }
        return {}
        // eslint-disable-next-line
    }, [plan?.headData])

    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
        <span className={`h4 font-weight-bold text-title`}>Team</span>
            <RenderInputOption key={'planName'} label={null}>
                <TabContent activeTab={'selected'}>
                    <TabPane tabId={'selected'}>
                        <ListItem
                            className='py-1 appears-animation'
                            id='x-0'
                            label={null}
                            Left={
                                <div className='d-flex flex-row align-items-center'>
                                    <Avatar
                                        name={councelorName}
                                        named={false}
                                        uri={councelorAvatar}
                                        size='avatar-medium'
                                    />
                                    <label className='ml-2 h4 align-self-center'>
                                        {councelorName}
                                        <span className='ml-2 text-gray h45'>
                                            (Counselor)
                                        </span>
                                    </label>
                                </div>
                            }
                            Right={null}
                        />
                        {
                            advisorName && (
                                <ListItem
                                    className='py-1 appears-animation'
                                    id='x-0'
                                    label={null}
                                    Left={
                                        <div className='d-flex flex-row align-items-center'>
                                            <Avatar
                                                name={advisorName}
                                                named={false}
                                                uri={advisorAvatar}
                                                size='avatar-medium'
                                            />
                                            <label className='ml-2 h4 align-self-center'>
                                                {advisorName}
                                                <span className='ml-2 text-gray h45'>
                                                    (Advisor)
                                                </span>
                                            </label>
                                        </div>
                                    }
                                    Right={null}
                                />
                            )
                        }
                        {
                            headName && (
                                <ListItem
                                    className='py-1 appears-animation'
                                    id='x-0'
                                    label={null}
                                    Left={
                                        <div className='d-flex flex-row align-items-center'>
                                            <Avatar
                                                name={headName}
                                                named={false}
                                                uri={headAvatar}
                                                size='avatar-medium'
                                            />
                                            <label className='ml-2 h4 align-self-center'>
                                                {headName}
                                                <span className='ml-2 text-gray h45'>
                                                    (Head)
                                                </span>
                                            </label>
                                        </div>
                                    }
                                    Right={null}
                                />
                            )
                        }
                    </TabPane>
                    {/* <TabPane tabId={`no-selected`}>
                        <span className={`btn-link`} onClick={() => {}}>Add personal counselor</span>       
                    </TabPane> */}
                </TabContent>
            </RenderInputOption>
        </div>
    )
}

export default Team