import React, {useState} from 'react'
import {IconBank, ListItem} from '@Knowledge-OTP/znk-ui-components'
// import {Collapse} from 'reactstrap'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import zelleIcon from '../../../../../../../../assets/zelle-icon.png'
import { Collapse } from 'reactstrap'

const ZelleMethod = (props) => {
  const {isParent} = props
  //const {__typename, ...info} = accountInfo
  const [isOpen, setOpen] = useState(false)
  const {translate} = useTranslation()
  console.log({one: process.env.REACT_APP_ZELLE_EMAIL, two: process.env.ZELLE_EMAIL})
  return (
    <>
      <ListItem
        className='px-4'
        showTopLine={!isParent}
        showBottomLine={!isParent}
        hasColorHover={true}
        hasHover={true}
        onClick={() => {setOpen(!isOpen)}}
        Left={
          <span className='d-flex align-items-center text-gray'>
            <IconBank className='mr-2' />
            {`${translate("profile.actions.addManualPaymentMethod.manualPaymentMethod")}`}
          </span>
        }
        Right={<span className='d-flex align-items-center text-gray'>
        Zelle
        <img src={zelleIcon} className='ml-2' width={25} alt='zelle payment'/>
      </span>}
      />
      <Collapse isOpen={isOpen}>
        <div className={'m-0 p-0 d-flex flex-column h45 text-gray px-3 py-1'}>
          <span>
            <b>{
              translate('profile.actions.addManualPaymentMethod.data.recipient')
            }: </b>
            {process.env.REACT_APP_ZELLE_EMAIL}
          </span>
        </div> 
      </Collapse>
    </>
  )
}

export default ZelleMethod
