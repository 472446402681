import React, { useEffect, useState, Fragment } from 'react'
import {
  Modal,
  IconClose,
  StickyButton,
  Button,
  IconOptions,
  IconErase,
  IconInvoice,
  Form,
  Field,
  PlainTextInput,
  LabeledField,
  InputSelect,
  AmountInput
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import './index.css'
import formatNumber from '../../../utils/formatNumber'
import useTranslation from '../../../i18n/useTranslation'
import SelectEmployee from '../Components/SelectEmployee'
import editPayslip from '../../../Model/Payments/Payslips/Edit'
import { isEmpty, validate } from 'validate.js'
import Menu from './menu'
import constraints from '../../../Model/Payments/Payslips/Edit/validation'
import {escapeRegExp} from 'lodash'
const types = [
  { id: 'lessonsPayment', name: 'Lesson' },
  { id: 'reimbursement', name: 'Reimbursement' },
  { id: 'content', name: 'Content' },
  { id: 'training', name: 'Training' }
]

const EditPayslip = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.editPayslip)
  const { isOpen, payslipId } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [errors, setErrors] = useState({})
  const [validationErrors, setValidationErrors] = useState({})
  const [form, setForm] = useState({
    student: undefined,
    total: 0,
    payments: [],
    newPayments: []
  })
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.editPayslip',
      data: {
        isOpen: false,
        payslipId: ''
      }
    })
  }
  const [mePayslip, { data }] = useLazyQuery(
    gql`query($payslipId: String!){
      payslip(payslipId: $payslipId) {
        id
        userId {
          id
          firstName
          lastName
        }
        from
        to
        category
        payslipDate
        payslipNumber
        paymentItemIds
        payslipDate
        isManual
        createdAt
        updatedAt
        status
        payslipProps {
          amount
          isPenalized
        }
        paymentItemsPayslip {
          id
          amount
          date
          description
          generatedDescription
          type
          isWaive
        }
      }
    }`,
    {
      variables: { payslipId: payslipId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (!payslipId) return
      await mePayslip()
    })()
  }, [payslipId, mePayslip])
  useEffect(() => {
    if (data?.payslip) {
      setForm({
        student: data.payslip.userId,
        payslipDate: data.payslip.payslipDate.split('T')[0],
        total: data.payslip.payslipProps.amount,
        newPayments: [],
        payments: data.payslip.paymentItemsPayslip.reduce((accumulator, element, index) => ({
          ...accumulator,
          [element.id]: {
            ...element,
            description: element.generatedDescription || element.description,
            paymentItemId: element.id,
            hover: false,
            isDelete: false,
            isNewPayment: false,
            errorAmount: '',
            errorDescription: '',
            errorType: ''
          }
        }), {})
      })
    }
    // eslint-disable-next-line
  }, [data])
  const removeItem = (type, index) => {
    if (type === 'New') {
      setForm({
        ...form,
        newPayments: form.newPayments.filter((e, i) => i !== index)
      })
    } else {
      setForm({
        ...form,
        payments: { ...form.payments, [index]: { ...form.payments[index], isDelete: true } }
      })
    }
  }
  const createItem = () => {
    const newVal = {
      type: 'lessonsPayment',
      amount: 0,
      description: '',
      errorAmount: '',
      errorDescription: '',
      errorType: '',
      hover: false,
      isNewPayment: true,
      isDelete: false
    }
    setForm({
      ...form,
      newPayments: form.newPayments.concat([newVal])
    })
  }
  const { submit, data: dataEdit, loading } = editPayslip((result) => {
  // eslint-disable-next-line no-console
  }, console.log)
  useEffect(() => {
    if (dataEdit) {
      if (dataEdit.isPayslipEmpty) {
        toggle()
        refetch()
      } else {
        if (!payslipId) return
        mePayslip()
        refetch()
      }
    }
    // eslint-disable-next-line
  }, [dataEdit])
  useEffect(() => {
    const totalNew = form.newPayments.reduce(
      (sum, current) => sum + (current.amount ? current.amount !== '' ? !isNaN(current.amount) ? !current.isWaive ? current.amount : 0 : 0 : 0 : 0)
      , 0)
    const totalOld = Object.values(form.payments).filter(e => !e.isDelete).reduce(
      (sum, current) => sum + (current.amount ? current.amount !== '' ? !isNaN(current.amount) ? !current.isWaive ? current.amount : 0 : 0 : 0 : 0)
      , 0)
    setForm({
      ...form,
      total: totalNew + totalOld
    })
    // eslint-disable-next-line
  }, [form.newPayments, form.payments])
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key.split('.')[1]
        customError[keyVal] = translate('payslip.actions.newPayslip.errors.' + keyVal)
      })
      setErrors(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])
  const onSubmit = (status) => {
    setErrors({})
    const payNew = form.newPayments.filter(e => !e.isDelete).map(
      element => {
        return {
          paymentItemId: element.paymentItemId,
          amount: element.amount,
          description: element.description,
          type: element.type,
          isNewPayment: element.isNewPayment,
          isDelete: element.isDelete
        }
      }
    )
    const payOld = Object.values(form.payments).map(
      element => {
        return {
          paymentItemId: element.paymentItemId,
          amount: element.amount,
          description: element.description,
          type: element.type,
          isNewPayment: element.isNewPayment,
          isDelete: element.isDelete
        }
      }
    )
    const newVal = {
      userId: form.student.id || data.payslip.userId,
      payslipDate: form.payslipDate === undefined ? '' : form.payslipDate,
      status: status,
      paymentItems: payOld.concat(payNew)
    }
    let firstError = false
    const errorsVal = validate({ input: newVal }, constraints)
    if (errorsVal !== undefined) {
      setValidationErrors(errorsVal)
      firstError = true
    }
    let hasError = false
    const payVal = Object.values(form.payments).filter(e => !e.isDelete).reduce(
      (items, current, index) => {
        if (!hasError && (isNaN(current.amount) || isEmpty(current.description) || current.type === undefined || isEmpty(current.description))) hasError = true
        return {
          ...items,
          [current.id]: {
            ...current,
            errorAmount: isNaN(current.amount) ? 'amount' : '',
            errorDescription: isEmpty(current.description) ? 'description' : '',
            errorType: current.type === undefined || isEmpty(current.type) ? 'type' : ''
          }
        }
      }
      , {})
    const oldVal = form.newPayments.map(
      (current) => {
        if (!hasError && (isNaN(current.amount) || parseFloat(current.amount) <= 0 || isEmpty(current.description) || current.type === undefined || isEmpty(current.description))) hasError = true
        return {
          ...current,
          errorAmount: isNaN(current.amount) ? 'amount' : parseFloat(current.amount) <= 0 ? 'amount' : '',
          errorDescription: isEmpty(current.description) ? 'description' : '',
          errorType: current.type === undefined || isEmpty(current.type) ? 'type' : ''
        }
      }
    )
    if (hasError || firstError) {
      setForm({
        ...form,
        payments: payVal,
        newPayments: oldVal
      })
      return
    }
    submit({ payslipId: payslipId, input: newVal })
  }
  return (
    <>
      {/* {
        confirm && (
          <ConfirmModal
            className='m-4'
            toggle={() => setConfirm(false)}
            isOpen
            title={state.state === 'remove' ? translate('payments.modal.removepayment.title') : translate('payments.modal.ignorepayment.title')}
            body={state.state === 'remove' ? translate('payments.modal.removepayment.bodyconfirm') : translate('payments.modal.ignorepayment.bodyconfirm')}
            confirmAction={onExecute}
            cancelAction={() => setConfirm(false)}
            confirmText={state.state === 'remove' ? translate('payments.modal.removeitem.confirm') : translate('payments.modal.ignorepayment.confirm')}
            cancelText={translate('plans.modal.cancelplan.cancel')}
          />
        )
      } */}
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        className='modal-sidebar modal-desktop-half'
        isOpen={isOpen}
        toggle={toggle}
        backdrop='static'
        Head={
          <Fragment>
            <div className='modal-header m-0 p-0 p-7 d-flex flex-column'>
              <div className='d-flex justify-content-between w-100'>
                <h2 className='font-weight-bold'>Edit Payslip</h2>
                <IconClose onClick={toggle} className='text-gray' />
              </div>
              <div className='w-100 d-flex flex-rowm align-items-center justify-content-between mt-6'>
                <div className='d-flex flex-column'>
                  <IconInvoice size={70} color='#2C3F58' />
                  <h5>{`${data ? 'PAY-' + String(data.payslip.payslipNumber).padStart(5, 0) : ''}`}</h5>
                </div>
                <div>
                  <h1 className='font-weight-bold'>${data ? formatNumber(data.payslip.payslipProps.amount) : formatNumber(0)}</h1>
                </div>
              </div>
            </div>
          </Fragment>
        }
      >
        {
          data
            ? <Form value={form} onChange={setForm} validationErrors={errors}>
              <div className='d-flex flex-column'>
                <div className='px-7'>
                  <hr className='' />
                  <SelectEmployee form={form} setForm={setForm} first={false} title={false} edit={false} />
                  <hr className='' />
                </div>
              </div>
              <div className='row d-flex m-0 mt-5 px-7'>
                <div className='col-6 p-0 pr-2'>
                  <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.type')}</label>
                  <Field
                    className='col-12'
                    component={PlainTextInput}
                    type={LabeledField}
                    fieldName='payslipDate'
                    fieldType='date'
                  />
                </div>
                <div className='col-6 pl-1 pr-0 pl-2'>
                  <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.number')}</label>
                  <Field
                    component={PlainTextInput}
                    type={LabeledField}
                    fieldName='number'
                    fieldType='text'
                    value={data ? 'PAY-' + String(data.payslip.payslipNumber).padStart(5, 0) : ''}
                    readOnly
                  />
                </div>
              </div>
              <div className='px-7'>
                <hr className='mt-1 mb-4' />
              </div>
              <div className='row d-flex m-0 px-7'>
                <div className='col-6 p-0 pr-2'>
                  <label className='text-gray font-weight-light mb-2'>Item</label>
                </div>
                <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                  <label className='text-gray font-weight-light mb-2'>Amount</label>
                </div>
              </div>
              <div className='px-7'>
                <hr className='mt-1 mb-4' />
              </div>
              {
                Object.values(form.payments).filter(e => !e.isDelete).filter(i => !i.isWaive).map(
                  (item, index) => {
                    return (
                      <Fragment
                        key={`item-${index}`}
                      >
                        <div
                          key={`item-${index}`}
                          className='w-100 d-flex flex-row justify-content-between align-items-center mt-1 hover-button'
                        >
                          <div className='w-100 pl-7 mr-7'>
                            {index > 0 && (
                              <div>
                                <hr className='mt-1 mb-4' />
                              </div>)}
                            <div className='row d-flex m-0'>
                              <div className='col-6 p-0 pr-2'>
                                <Field
                                  className='col-12'
                                  type={InputSelect}
                                  placeholder={translate('modal.newplan.selectservice')}
                                  options={types && [].concat(types.map((serv) => ({ value: serv.id, label: serv.name })))}
                                  InputClassName='w-100'
                                  value={item.type}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      payments: { ...form.payments, [item.id]: { ...item, type: e } }
                                    })
                                  }}
            filterOption={(obj, string) => {
              if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
              return false
            }}
                                />
                                {item.errorType !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.type')}</h5>)}
                              </div>
                              <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                                <Field
                                  className='col-12 placeholder-to-right'
                                  type={AmountInput}
                                  fieldType='number'
                                  placeholder='0.00'
                                  addOn='$'
                                  min='0'
                                  value={item.amount}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      payments: { ...form.payments, [item.id]: { ...item, amount: e } }
                                    })
                                  }}
                                />
                                {item.errorAmount !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.amount')}</h5>)}
                              </div>
                            </div>
                            <div className='row d-flex m-0 p-0 mt-1 mb-3'>
                              <div className='col-12 p-0'>
                                <Field
                                  type={PlainTextInput}
                                  placeholder='Description'
                                  fieldType='text'
                                  value={item.description}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      payments: { ...form.payments, [item.id]: { ...item, description: e } }
                                    })
                                  }}
                                />
                                {item.errorDescription !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.description')}</h5>)}
                              </div>
                            </div>
                          </div>
                          <div className='hover-button--on' onClick={() => { removeItem('Old', item.id) }}>
                            <IconErase size={18} color='#FF3E1D' />
                          </div>
                        </div>
                      </Fragment>
                    )
                  }
                )
              }
              {
                form.newPayments.map(
                  (item, index) => {
                    return (
                      <Fragment
                        key={`item-${index}`}
                      >
                        <div
                          key={`item-${index}`}
                          className='w-100 d-flex flex-row justify-content-between align-items-center mt-1 hover-button'
                        >
                          <div className='w-100 pl-7 mr-7'>
                            {index > 0 && (
                              <div>
                                <hr className='mt-1 mb-4' />
                              </div>)}
                            <div className='row d-flex m-0'>
                              <div className='col-6 p-0 pr-2'>
                                <Field
                                  className='col-12'
                                  type={InputSelect}
                                  placeholder={translate('modal.newplan.selectservice')}
                                  options={types && [].concat(types.map((serv) => ({ value: serv.id, label: serv.name })))}
                                  InputClassName='w-100'
                                  value={item.type}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      newPayments: form.newPayments.map(
                                        (ele, i) => {
                                          return i === index
                                            ? {
                                              ...ele,
                                              type: e
                                            }
                                            : ele
                                        }
                                      )
                                    })
                                  }}
                                  filterOption={(obj, string) => {
                                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                                    return false
                                  }}
                                />
                                {item.errorType !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.type')}</h5>)}
                              </div>
                              <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                                <Field
                                  className='col-12 placeholder-to-right'
                                  type={AmountInput}
                                  fieldType='number'
                                  placeholder='0.00'
                                  addOn='$'
                                  min='0'
                                  value={item.amount}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      newPayments: form.newPayments.map(
                                        (ele, i) => {
                                          return i === index
                                            ? {
                                              ...ele,
                                              amount: e
                                            }
                                            : ele
                                        }
                                      )
                                    })
                                  }}
                                />
                                {item.errorAmount !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.amount')}</h5>)}
                              </div>
                            </div>
                            <div className='row d-flex m-0 p-0 mt-1 mb-3'>
                              <div className='col-12 p-0'>
                                <Field
                                  type={PlainTextInput}
                                  placeholder='Description'
                                  fieldType='text'
                                  value={item.description}
                                  onChange={(e) => {
                                    setForm({
                                      ...form,
                                      newPayments: form.newPayments.map(
                                        (ele, i) => {
                                          return i === index
                                            ? {
                                              ...ele,
                                              description: e
                                            }
                                            : ele
                                        }
                                      )
                                    })
                                  }}
                                />
                                {item.errorDescription !== '' && (<h5 className='text-error'>{translate('payslip.actions.newPayslip.errors.description')}</h5>)}
                              </div>
                            </div>
                          </div>
                          <div className='hover-button--on' onClick={() => { removeItem('New', index) }}>
                            <IconErase size={18} color='#FF3E1D' />
                          </div>
                        </div>
                      </Fragment>
                    )
                  }
                )
              }
              <div className='px-7'>
                <hr className='mt-1' />
                <h4 className='btn-link cursor my-3' onClick={createItem}>{translate('payments.newinvoice.addnewitem')}</h4>
                <hr className='mt-1' />
              </div>
              <div className='row d-flex m-0 mt-5 px-7'>
                <div className='col-6 p-0 pr-2'>
                  <h2 className='font-weight-light mb-2'>Total</h2>
                </div>
                <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                  <h2 className='font-weight-light mb-2'>${formatNumber(form.total)}</h2>
                </div>
              </div>
              <div className='px-7'>
                <hr className='m-0 mt-3' />
              </div>
              <div className='px-3 px-lg-5 px-xl-7 d-flex flex-column my-8'>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='secondary'
                      activity={loading}
                      className='col-9'
                      label={data && data.payslip.status === 'draft' ? 'Save in draft' : 'Save changes'}
                      onClick={() => {
                        onSubmit(data && data.payslip.status === 'draft' ? 'draft' : 'pendingPayment')
                      }}
                      style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                      check
                    />
                    {data && data.payslip.status === 'draft' && (<Menu newAproveInvoice={(value) => { onSubmit(value) }}>
                      <Button
                        className='ml-3'
                        color='secondary'
                        style={{ bottom: '10px', right: '25px', width: '50px' }}
                        label={<IconOptions style={{ transform: 'rotate(90deg)' }} />}
                      />
                    </Menu>)}
                  </div>
                </StickyButton>
              </div>
              </Form>
            : <Skeleton count={4} height={35} />
        }
      </Modal>
    </>
  )
}

export default EditPayslip
