import React, { useMemo, useState, useEffect, useRef } from 'react'
import {
  Button,
  IconSearch,
  IconLesson,
  IconOptions,
  InputSearch,
  IconSignPlus,
  IconDolarRefresh,
  DropDownNew,
  DropDownItem,
  IconStarActive,
  IconCircleDolar,
  useWindowDimensions,
  IconFilter
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import * as moment from 'moment'
import StatusesFilter from './Filters/Statuses'
import StartDateFilter from '../Plans/Filters/StartDateBadge'
import TypesFilter from './Filters/Types'
import ParentsFilter from './Filters/Parent'
import StudentsFilter from './Filters/Students'
import './index.css'
import Menu from './Menu'
import NewPaymentItem from './Payments/new'
import NewInvoice from './Invoices/new'
import { useDispatch, useSelector } from 'react-redux'
import ViewInvoice from './Invoices/view'
import ProcessInvoice from './Invoices/process'
import EditInvoice from './Invoices/edit'
import formatNumber from '../../utils/formatNumber'
import { isEqual } from 'lodash'
import { Badge, TabPane, TabContent } from 'reactstrap'
import useTranslation from '../../i18n/useTranslation'
import Pagination from '../../common/Pagination'
import { gql, useQuery } from '@apollo/client'
//MAILS LIST
import useModalEmailsList from '../../common/ModalEmailsList/useModalEmailsList'
import OptionsEmailsAndDownloads from '../../common/ModalEmailsList/Components/Options'
import NoAuthorize from '../../common/NoAuthorize'
import useSendSummaryEmail from './Invoices/SendSummaryEmail/useSendSummaryEmail'
// import useCopyInvoiceLink from './CopyInvoiceLink'
import useGetInvoiceLink from '../../Model/Payments/GetInvoiceLink'
import ModalFilterMobile from './Filters/ModalFilterMobile'
const baseStatuses = ['pendingPayment', 'paid', 'unPaid', 'canceled']
const baseTypes = ['livePrep', 'counseling']

const PaymentsViews = props => {
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const { open: openSendSummaryEmail } = useSendSummaryEmail()
  const [txtSearch, setTxtSearch] = useState('')
  const [activePage, setActivePage] = useState(1)
  // const { copyInvoiceLink } = useCopyInvoiceLink()
  const { breakWidth } = useWindowDimensions()
  //eslint-disable-next-line
  const [getInvoiceLink, { data: dataInvoiceLink }] = useGetInvoiceLink()
  const [shortcuts, setShortcuts] = useState({
    currentCicle: true,
    pending: false,
    upcomming: false,
    paidOut: false,
    cancelled: false,
    draft: false,
    all: false
  })
  const baseQuery = {
    limit: 20,
    next: '',
    previous: '',
    sortField: 'from',

    category: [],
    parentUserId: [],
    userId: [], // ['5f4d0ec04fcf1942978b4de5'],
    searchString: undefined,

    status: [],
    from: undefined,
    to: undefined,
    current: shortcuts.currentCicle,
    pending: shortcuts.pending
  }
  const getDefaultQueryByShortcuts = (newShortcuts) => {
    const statusShort = newShortcuts.all
      ? ['pendingPayment', 'procesingPayment', 'paid', 'unPaid', 'draft', 'canceled']
      : newShortcuts.currentCicle
        ? ['pendingPayment', 'procesingPayment', 'paid', 'unPaid']
        : newShortcuts.paidOut
          ? ['paid']
          : newShortcuts.pending
            ? ['unPaid']
            : newShortcuts.cancelled
              ? ['canceled']
              : ['draft']
    return {
      ...baseQuery,
      status: statusShort,
      from: newShortcuts.currentCicle ? moment().clone().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z' : undefined,
      to: newShortcuts.currentCicle ? moment().clone().endOf('month').format('YYYY-MM-DD') + 'T23:59:59.999Z' : undefined,
      current: newShortcuts.currentCicle,
      pending: newShortcuts.pending
    }
  }


  const { staffInfo } = useSelector((state) => state.user)
  const isPermited = staffInfo?.permissions?.filter(e => e.isPermited).map(per => { return per.tag }).includes('invoices');
  const isTotalPermited = staffInfo?.permissions?.filter(e => e.isPermited).find(({ tag }) => tag === 'invoices').subpermissions.filter(({ isPermited }) => isPermited).map(({ tag }) => tag).includes("invoices-total")

  const modalData = useSelector((state) => state.modal.payments.viewInvoice)
  const { isOpen } = modalData

  const setCurrentShortcut = (shortcutKey) => {
    const newShortcut = {
      currentCicle: false,
      pending: false,
      upcomming: false,
      paidOut: false,
      cancelled: false,
      draft: false,
      all: false
    }
    newShortcut[shortcutKey] = true
    setShortcuts(newShortcut)
  }
  const resetShortcuts = () => {
    setShortcuts({
      currentCicle: true,
      pending: false,
      upcomming: false,
      paidOut: false,
      cancelled: false,
      draft: false,
      all: false
    })
  }


  //ACTUALIZACIÓN AL CLICKEAR UN SHORTCUT
  const defaultQuery = useMemo(
    () => {
      return getDefaultQueryByShortcuts(shortcuts)
      //eslint-disable-next-line
    }, [shortcuts]
  )
  useEffect(() => {
    setFilter(defaultQuery)
    //eslint-disable-next-line
  }, [defaultQuery])


  const [query, setQuery] = useState({ ...defaultQuery })
  const setFilter = (newQuery) => {
    //REINICIA PAGINACIÓN
    setActivePage(1)
    if (isEqual(query, newQuery)) {
      refetch()
    } else {
      setQuery({
        ...newQuery,
        next: '', previous: ''
      })
    }
  }

  const searchRef = useRef(null)
  useEffect(() => {
    //actualiza campo de busqueda
    if (!query.searchString || query.searchString?.length === 0) {
      // vacia el campo de busqueda
      if (searchRef?.current) searchRef.current.value = "";
    }
    setTxtSearch(query.searchString)
  }, [query.searchString])

  const canReset = (query) => {
    return !isEqual(query, defaultQuery)
  }

  const resetFilters = () => {
    setFilter({ ...defaultQuery })
  }

  const paymentQueryResult = `
              id
              status
              isRefunded
              hasNegativePayment
              category
              invoiceNumber
              userId {
                id
                firstName
                lastName
                # paymentMethod {
                #   kind
                #   ... on ManualPaymentMethod {
                #       kind
                #   }
                #   ... on CardPaymentMethod{
                #       kind
                #       status
                #       last4
                #       brand
                #       expMonth
                #       expYear
                #   }
                # }
              }
              processedTimes
              invoiceNumber
              createdAt
              updatedAt
              from
              to
              paymentItemIds
              lastProcessedAt
              amount
              amountPaid
              isManual
              paymentItems {                
                ... on PlanPaymentItem {
                  planId
                  id
                }
                ... on LessonPaymentItem {
                  lessonId
                  id
                }
                ... on EducatorPaymentItem {
                  id
                }
                ... on CustomPaymentItem {
                  id
                }
              }   
              `
  const { data, loading: loadInvoices, refetch } = useQuery(
    gql`query(
        $limit: Int,
        $next: String,
        $previous: String,
        $sortField: String,
        $status: [PaymentStatus!],
        $userId: [String!],
        $parentUserId: [String!],
        $from: DateTime,
        $to: DateTime,
        $category: [PaymentCategory!],
        $searchString: String,
        $current: Boolean,
        $pending: Boolean,
        #$order: Float
      ) {
        invoices(
          limit: $limit,
          next: $next,
          previous: $previous,
          sortField: $sortField,
          status: $status, 
          userId: $userId,
          parentUserId: $parentUserId,
          from: $from,
          to: $to,
          category: $category,
          searchString: $searchString
          current: $current,
          pending: $pending,
          #order: $order
        ) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs            
            docs {
                 ${paymentQueryResult}
            }
          }
        }`,
    {
      fetchPolicy: 'cache-first',
      variables: query
    }
  )


  const { data: dataTotal, loading: loadTotal } = useQuery(
    gql`query(
        $status: [PaymentStatus!],
        $userId: [String!],
        $parentUserId: [String!],
        $from: DateTime,
        $to: DateTime,
        $category: [PaymentCategory!],
        $searchString: String,
        $current: Boolean,
        $pending: Boolean,
      ) {
        totalInvoices(
          status: $status, 
          userId: $userId,
          parentUserId: $parentUserId,
          from: $from,
          to: $to,
          category: $category,
          searchString: $searchString
          current: $current,
          pending: $pending,
        ) {
            totalAmount
            totalStudents
          }
        }`,
    {
      fetchPolicy: 'cache-first',
      variables: query
    }
  )

  const { totalAmount, totalStudents } = useMemo(
    () => {
      return dataTotal?.totalInvoices || {}
    }, [dataTotal]
  )

  const {
    hasNext, hasPrevious, invoices,
    pageIndexPrevious, pageIndexNext, pageCount
  } = useMemo(
    () => {
      return {
        hasNext: data?.invoices?.hasNext || false,
        hasPrevious: data?.invoices?.hasPrevious || false,
        invoices: data?.invoices?.docs || [],
        pageIndexNext: data?.invoices?.next,
        pageIndexPrevious: data?.invoices?.previous,
        pageCount: Math.ceil((data?.invoices?.totalDocs || 0) / ((query?.limit || 10) || 1))
      }
      //eslint-disable-next-line
    }, [data, query?.limit]
  )

  // Modal lista de mails
  const { open: openEmailsListModal } = useModalEmailsList()





  const viewInvoice = (item) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.viewInvoice',
      data: {
        isOpen: true,
        invoiceId: item
      }
    })
  }
  const processInvoice = (item, multiple) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.processInvoice',
      data: {
        isOpen: true,
        isMultiple: false,
        invoiceId: item
      }
    })
  }
  const processInvoices = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.processInvoice',
      data: {
        isOpen: true,
        isMultiple: true,
        invoiceId: ''
      }
    })
  }
  const getPymentMethod = (method) => {
    if (!method) {
      return ''
    }
    switch (method.kind) {
      case 'manual':
        return 'Manual'
      case 'creditCard':
        return 'Credit card'
      default:
        return ''
    }
  }
  const clickNext = () => {
    if (hasNext) {
      setActivePage(activePage + 1)
      setQuery({
        ...query,
        next: pageIndexNext,
        previous: ''
      })
    }
  }
  const clickPrevious = () => {
    if (hasPrevious) {
      setActivePage(activePage - 1)
      setQuery({
        ...query,
        next: '',
        previous: pageIndexPrevious
      })
    }
  }
  const changeSize = (limit) => {
    // setActivePage(1)
    setFilter({ ...query, limit })
  }
  const updateList = () => {
    //actuliza listado pero no paginación
    refetch()
  }
  if (!isPermited) {
    return <NoAuthorize />
  }
  return (
    <>
      <div
        className='col-12 col-md-12 px-md-6 px-lg-7'
      // style={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        <div className='container-fluid mt-2 mt-md-5 d-flex px-0 flex-column-reverse flex-md-row'>
          <div className='d-flex align-items-center flex-row flex-grow-1 mt-2 mt-md-0'>
            <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.currentCicle ? 'active' : 'text-muted'}`} onClick={() => resetShortcuts()}>Current Cycle</span>
            <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.pending ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('pending')}>Pending</span>
            {/* <span className={`h3 mx-3 font-weight-bold ${shortcuts.upcomming ? '' : 'text-muted'}`} onClick={() => setCurrentShortcut('upcomming')}>Upcoming</span> */}
            <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.paidOut ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('paidOut')}>Paid</span>
            <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.draft ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('draft')}>Draft</span>
            <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.cancelled ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('cancelled')}>Cancelled</span>
            <span className={`nav-title h3 mx-3 font-weight-bold ${shortcuts.all ? 'active' : 'text-muted'}`} onClick={() => setCurrentShortcut('all')}>All invoices</span>
          </div>
          <div className='d-flex align-items-center justify-content-end justify-content-md-start flex-row-reverse flex-md-row'>
            {/* <ButtonEmailsList 
                      onClick={() => { openEmailsListModal(() => {}, {...query, limit: 0}, 'payments') }} 
            /> */}
            <span>
              <InputSearch
                placeholder='Search'
                className='m-0 p-3 h-25 trans flex-nowrap'
                addOnComponent={<IconSearch size={18} className='text-gray' />}
                onChange={(value) => { setTxtSearch(value) }}
                onKeyUp={({ key }) => {
                  if (key === 'Enter') setFilter({ ...query, searchString: txtSearch })
                }}
                value={txtSearch}
                innerRef={searchRef}
              />
            </span>
            <span><Button label='Process invoice' className='btn-table' color='primary' onClick={processInvoices} /></span>
            <OptionsEmailsAndDownloads
              options={[
                { title: translate('users.actions.emails'), onClick: () => { openEmailsListModal(() => { }, { ...query, limit: 0 }, 'payments') } },
                {
                  title: translate("misc.summaryInvoice.callToActionLabel") || "Download Summary",
                  onClick: () => {
                    openSendSummaryEmail(() => { }, true)
                  }
                },
              ]}
              loading={false}
            />
          </div>
        </div>
        <div className='container-fluid mt-2 mt-md-5 d-flex flex-row justify-content-between px-0'>
          {
            breakWidth === 'SM' ?
              <ModalFilterMobile setQuery={setQuery} query={query}>
                <div className='hover-icon'><IconFilter size={20} className='text-disabled' /></div>
              </ModalFilterMobile> :
              <div className={'m-0 p-0 d-flex flex-row'}>
                <ParentsFilter
                  {...{
                    studentFilters: query?.userId,
                    setStudentFilters: (userId) => {
                      setFilter({
                        ...query, userId
                      })
                    }
                  }}
                />
                <StudentsFilter
                  {...{
                    studentFilters: query?.userId,
                    setStudentFilters: (userId) => {
                      setFilter({
                        ...query, userId
                      })
                    }
                  }}
                />
                {!shortcuts.currentCicle && (
                  <StartDateFilter
                    startDateFilter={[query?.from, query?.to]}
                    setStartDateFilter={(startDateFilter) => {
                      const from = startDateFilter[0] !== undefined ? startDateFilter[0] : undefined
                      const to = startDateFilter[1] !== undefined ? startDateFilter[1] : undefined
                      setFilter({ ...query, from, to })

                    }}
                    typeFilter='Date'
                  />
                )}
                {(shortcuts.all || shortcuts.currentCicle || shortcuts.upcomming) &&
                  <StatusesFilter {...{
                    statuses: baseStatuses,
                    statusFilters: query?.status,
                    setStatusFilters: (status) => {
                      setFilter({
                        ...query, status
                      })
                    }
                  }} />
                }
                <TypesFilter {...{
                  types: baseTypes,
                  typesFilters: query?.category || [],
                  setTypesFilters: (category) => {
                    setFilter({ ...query, category })
                  }
                }} />
                {
                  canReset(query)
                    ? <Badge className='mx-1 filters' pill color='option' onClick={resetFilters}>
                      <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                        <IconSignPlus size={15} className='mr-1' />
                        {translate('misc.cleanFilters')}
                      </label>
                    </Badge>
                    : null
                }
              </div>}
          {/**<span className={'h45 text-gray'} onClick={() => {setOrder(changeOrder())}}>
              ORDER: {order === -1 ? 'ASC' : order === 1 ? 'DESC' : 'Default' }  
          </span>**/}
        </div>
        <div className='container-fluid d-flex flex-column mx-0 mt-4 px-0'>
          <TabContent activeTab={loadInvoices ? 'loading' : 'show'}>
            <TabPane tabId={`loading`}>
              <Skeleton style={{ borderRadius: '12px' }} className={`my-1`} count={query?.limit || 10} height={60} />
            </TabPane>
            <TabPane tabId={`show`}>
              {invoices.map(invoice => {
                const counselingWhite = invoice.category !== 'livePrep' && !['paid', 'unPaid', 'canceled'].includes(invoice.status)
                return (
                  <div
                    key={invoice.id}
                    className={`znk-invoice-row ${['paid', 'unPaid', 'canceled', 'draft'].includes(invoice.status) ? invoice.status : shortcuts.currentCicle ? invoice.category : 'upcomming'}`}
                  >
                    <span onClick={() => { }}>
                      {invoice.category === 'livePrep' ?
                        <IconLesson /> :
                        <IconStarActive className={counselingWhite && shortcuts.currentCicle ? 'text-white' : 'text-counseling'} />}
                      {/* <img src={`https://media.zinkerz.com/email/20210422/counseling${counselingWhite && shortcuts.currentCicle ? '-white' : ''}.png`} style={{height: 16, width: 16}} className='img-fluid' alt="counseling lesson" />} */}
                      <div style={{ marginLeft: 7 }}>End Of {moment(invoice.to).format('MMMM')}{invoice.isManual ? ' (Manual)' : ''}</div>
                    </span>
                    <span className='font-weight-bold text-color' onClick={() => { viewInvoice(invoice.id) }}>${invoice.status === 'paid' ? formatNumber(invoice.amount) : invoice.amountPaid === 0 ? formatNumber(invoice.amount) : formatNumber(invoice.amountPaid)}</span>
                    <span className='text-color' onClick={() => { viewInvoice(invoice.id) }}>{invoice.userId.firstName} {invoice.userId.lastName}</span>
                    <span className='text-color opacity' onClick={() => { viewInvoice(invoice.id) }}>{getPymentMethod(invoice.userId.paymentMethod)}</span>
                    <span className='text-color opacity justify-content-between' onClick={() => { viewInvoice(invoice.id) }}>
                      INV-{String(invoice.invoiceNumber).padStart(5, 0)}
                      {invoice.isRefunded && (<IconDolarRefresh className='mr-2' />)}
                      {invoice.hasNegativePayment && (<IconCircleDolar className='mr-2' />)}
                    </span>
                    <span className=''>
                      {invoice.status === 'pendingPayment'
                        ? <DropDownNew
                          direction='bottomLeft'
                          // fixedWidth
                          // hoverEnabled={false}
                          options={[{
                            component:
                              <DropDownItem>
                                <span
                                  className='p-3'
                                  onClick={() => {
                                    viewInvoice(invoice.id)
                                  }}
                                >View invoice
                                </span>
                              </DropDownItem>
                          }, {
                            component:
                              <DropDownItem>
                                <span
                                  className='p-3'
                                  onClick={() => {
                                    processInvoice(invoice.id)
                                  }}
                                >Process invoice
                                </span>
                              </DropDownItem>
                          }, 
                          // {
                          //   component:
                          //     <DropDownItem>
                          //       <span
                          //         className='p-3'
                          //         onClick={() => {
                          //           copyInvoiceLink(invoice?.id)
                          //         }}
                          //       >{translate('payments.invoiceLink.text')}
                          //       </span>
                          //     </DropDownItem>
                          // }
                        ]}
                        >
                          <div className={`mr-1 hover-icon cursor-pointer`}>
                            <IconOptions style={{ transform: 'rotate(90deg)' }} color={`${(counselingWhite && shortcuts.currentCicle) || invoice.category === 'livePrep' ? 'white' : 'rgba(37, 64, 80, 0.6)'}`} />
                          </div>
                        </DropDownNew>
                        :
                        <div className={`mr-1 hover-icon cursor-not-allowed`}>
                          <IconOptions style={{ transform: 'rotate(90deg)' }} color={`${counselingWhite && shortcuts.currentCicle ? 'white' : 'rgba(37, 64, 80, 0.6)'}`} onClick={() => { viewInvoice(invoice.id) }} />
                        </div>}
                    </span>
                  </div>
                )
              })}
            </TabPane>
          </TabContent>
          <Menu>
            <Button
              className='position-fixed p-0'
              color='primary'
              size={'small'}
              style={{ bottom: '10px', right: '25px', width: 'fit-content', zIndex: 100 }}
              label={<IconSignPlus size={15} className={`m-3`} style={{ transform: 'rotate(133deg)' }} />}
            />
          </Menu>
        </div>
        <NewInvoice refetch={updateList} />
        <NewPaymentItem refetch={updateList} />
        {isOpen && <ViewInvoice refetch={updateList} />}
        <ProcessInvoice refetch={updateList} />
        <EditInvoice refetch={updateList} />
      </div>
      <Pagination
        bottomFix
        className={`w-100 px-4 pt-7 d-flex flex-row justify-content-between align-items-center`}
        pageSize={query?.limit || 10}
        changeSize={changeSize}
        clickNext={clickNext}
        clickPrevious={clickPrevious}
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        activePage={activePage}
        pageCount={pageCount}
        loading={loadInvoices}
        innerSection={
          isTotalPermited ?
            <TabContent className={`w-100`} activeTab={loadTotal ? 'loading' : 'show'}>
              <TabPane
                className={`w-100`}
                tabId={'loading'}>
                <div className={`d-flex flex-row justify-content-end align-items-center px-2 pt-2 pb-2`}>
                  {
                    new Array(2).fill(0).map(
                      () => {
                        return (
                          <div className={`mx-2 d-flex flex-column align-items-center`}>
                            <Skeleton count={1}
                              width={50} height={12}
                            />
                            <Skeleton count={1}
                              width={70} height={12}
                            />
                          </div>
                        )
                      }
                    )
                  }
                </div>
              </TabPane>
              <TabPane
                className={`w-100`}
                tabId={'show'}>
                <div
                  className={`d-flex flex-row justify-content-end align-items-center px-2 pt-2 pb-2`}
                >
                  <div className='d-flex flex-column align-items-center px-2'>
                    <span className={`h4 text-info font-weight-bold`}>Students</span>
                    <span className={`h4 text-gray`}>{totalStudents}</span>
                  </div>
                  <div className='d-flex flex-column align-items-center px-2'>
                    <span className={`h4 text-info font-weight-bold`}>Total</span>
                    <span className={`h4 text-gray`}>{`$${formatNumber(totalAmount)}`}</span>
                  </div>
                </div>
              </TabPane>
            </TabContent> : null
        }
      />
    </>
  )
}

export default PaymentsViews
