import React, { useEffect, useState, useMemo, useRef } from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  Avatar,
  StickyButton,
  Input,
  IconSearch,
  IconOutLine,
  IconArrowRight,
  IconSignPlus
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import { UseAllUsers } from './Model/UseAllUsers'
import NewStudent from './Components/NewStudent'
import useTranslation from '../../../../i18n/useTranslation'
import { TabContent, TabPane } from 'reactstrap'
import Pagination from '../../../../common/Pagination'
import LazyGroupAvatar from '../../../../common/LazyAvatar/LazyGroupAvatar'

const SelectStudent = (props) => {
  const { setStudent, group, student, disabled = false } = props
  const [isOpen, setOpen] = useState(false)
  // const {isOpen: isOpenCreateStudentModal, close: closeCreateStudentModal} = useCreateStudentModal()
  const [selectStudent, setSelectStudent] = useState({})
  const [selectStudentGroup, setSelectStudentGroup] = useState([])
  const [studentSearch, setStudentSearch] = useState(null)
  useEffect(() => {
    if (student?.length > 0) {
      if (group) setSelectStudentGroup(student?.map((stu) => {
        return {
          ...stu,
          id: (stu.id || stu.studentUserId)
        }
      }))
      else {
        let aa = { ...student[0], id: (student[0].id || student[0].studentUserId) }
        setSelectStudent(aa)
      }
    }
  }, [student, group, setSelectStudent, setSelectStudentGroup, isOpen])
  const toggle = () => {
    setOpen(!isOpen)
    //setSelectStudent({})
    //setSelectStudentGroup([])
  }
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()

  const [query, setQuery] = useState({
    limit: 5,
    roles: ['STUDENT'],
    sortField: 'firstName',
    search: null,
    next: '',
    previous: ''
  })
  const { loading, data, refetch } = UseAllUsers(query, { fetchPolicy: 'cache-first' })

  const ordenateStudentArray = (array) => {
    return array.map((stu) => {
      return {
        ...stu,
        username: `${stu.firstName} ${stu.lastName}`
      }
    }).sort((a, b) => {
      if (a.username > b.username) return 1
      if (a.username < b.username) return -1
      return 0
    })
  }

  const [currentPage, setCurrentPage] = useState(1)
  const { students, paginatedData } = useMemo(
    () => {
      if (data?.allUsers) {
        const { docs, ...paginatedData } = data?.allUsers
        return {
          students: data?.allUsers?.docs ? ordenateStudentArray(data.allUsers.docs) : [],
          paginatedData
        }
      }
      return {
        students: [],
        paginatedData: {}
      }
    }, [data]
  )
  const newStudentCallback = (student) => {
    if (group) {
      setSelectStudentGroup(selectStudentGroup.concat([student]))
    } else {
      setSelectStudent(student)
    }
  }
  const currentRef = useRef(null)
  const [selectionScrollStyle, setSelectionScrollStyle] = useState({})
  useEffect(() => {
    if (currentRef) {
      setSelectionScrollStyle({
        width: `${currentRef?.current?.offsetWidth || 300}px`
      })
    }
  }, 
    //eslint-disable-next-line
    [currentRef?.current?.offsetWidth]
  )
  
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={() => { if (!disabled) toggle() }}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('modal.newplan.selectstudenttitle')}</span>
            <div className={`hover-icon`}>
              <IconClose size={20} onClick={toggle} />
            </div>
          </div>
        }
      >
        <div className='p-0 m-4' ref={currentRef}>
          <Input
            className='w-100 input-xs p-0 mb-3'
            placeholder={translate('modal.newplan.searchstudent')}
            addOnComponent={<IconSearch size={16} />}
            type='addon'
            onChange={(e) => {
              setStudentSearch(e.target.value);
            }}
            onKeyUp={({ key }) => {
              if (key === 'Enter') {
                setCurrentPage(1)
                setQuery({ ...query, search: studentSearch, next: '', previous: '' })
              }
            }}
          />
          <div className='m-0 py-2 px-0' >
            <NewStudent dataQuery={data?.allUsers || {}} callback={newStudentCallback} refetch={refetch}>
              <ListOption
                className='mb-2'
                id='x-0'
                IconRight={<IconArrowRight size={15} color='#696CFF' />}
                childClassName='d-flex'
                label={<span className='m-0 p-0' style={{ color: '#696CFF' }}>{translate('modal.newplan.createstudent')}</span>}
                icon={
                  <IconOutLine
                    className='p-3 mr-1'
                    backgroundColor='#696CFF'
                    color='#FFFFFF'
                    textChildren={<span className='m-0 font-weight-bold' style={{ color: 'white' }}>+</span>}
                    sizePx={25}
                  />
                }
              />
            </NewStudent>
            <TabContent activeTab={loading ? 'loading' : 'show'}>
              <TabPane tabId={`loading`}>
                <Skeleton className={'w-100 mb-1'} style={{ borderRadius: '10px' }} height={45} count={query?.limit || 5} />
              </TabPane>
              <TabPane tabId={`show`}>
                {
                  students.map((stu) => {
                    const oldStudent = Array.isArray(student) ? (student || [])?.find(({studentId, id}) => studentId === stu?.id || id === stu?.id) : {}
                    const canDelete = !oldStudent?.planStatus || ['draft'].includes(oldStudent?.planStatus)
                    
                    let isSelected = group
                    ? (selectStudentGroup || [])?.reduce(
                      (isSelected, sel) => {
                        return isSelected || sel.id === stu.id
                      }, false)
                    : selectStudent?.id === stu.id
                    return (
                      <ListOption
                        key={stu.id}
                        className={`mb-2 ${isSelected && !canDelete ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        childClassName='d-flex'
                        selectedInput={
                          group
                            ? selectStudentGroup.reduce(
                              (isSelected, sel) => {
                                return isSelected || sel.id === stu.id
                              }, false)
                            : selectStudent?.id === stu.id
                        }
                        id='x-0'
                        label={`${stu.firstName} ${stu.lastName}`}
                        icon={
                          <Avatar
                            name={`${stu.firstName} ${stu.lastName}`}
                            named={false}
                            uri={stu?.avatar}
                            size='avatar-small mr-1 avatar-span-white'
                          />
                        }
                        onClick={() => {
                          if(isSelected && !canDelete) return
                          if (group) {
                            if (isSelected) {
                              setSelectStudentGroup(
                                selectStudentGroup.filter(
                                  (sel) => sel.id !== stu.id
                                )
                              )
                            }
                            else setSelectStudentGroup(selectStudentGroup.concat([stu]))
                          } else {
                            if (isSelected) setSelectStudent({})
                            else setSelectStudent(stu)
                          }
                        }}
                      />
                    )
                  })
                }
              </TabPane>
            </TabContent>
            
            <Pagination
              size={`small`}
              useSelectorChangeSize={false}
              pageSize={query?.limit || 5}
              changeSize={() => { }}
              clickNext={() => {
                if (paginatedData?.hasNext) {
                  setCurrentPage(currentPage + 1)
                  setQuery({ ...query, next: paginatedData?.next, previous: '' })
                }
              }}
              clickPrevious={() => {
                if (paginatedData?.hasPrevious) {
                  setCurrentPage(currentPage - 1)
                  setQuery({ ...query, previous: paginatedData?.previous, next: '' })

                }
              }}
              hasPrevious={paginatedData?.hasPrevious}
              hasNext={paginatedData?.hasNext}
              activePage={currentPage}
              pageCount={Math.ceil((paginatedData?.totalDocs || 0) / (query?.limit || 1))}
              loading={loading}
            />
            {
              group && (
                <span style={selectionScrollStyle} className={`py-4 d-flex flex-row align-items-center text-truncate znk-scroll-horizontal-v2 znk-no-horizontal-scroll-bar`}>
                  <LazyGroupAvatar canDelete onDelete={(student) => {
                    setSelectStudentGroup(selectStudentGroup.filter((stu) => {
                      const id = stu?.studentUserId || stu?.id || stu?.studentId
                      return id !== student?.id
                    }))
                  }} useHover users={selectStudentGroup.map((stu, index) => {
                    return { 
                      ...stu, id: stu?.studentUserId || stu?.id || stu?.studentId,  
                      canDelete: !stu?.planStatus || ['draft'].includes(stu?.planStatus) 
                    }
                  })} />
                  {`${selectStudentGroup.length} students selected`}
                </span>
              ) 
            }
            {
            !group && selectStudent && Object.keys(selectStudent).length > 0 && [null].map(
              () => {
                const userName = `${selectStudent?.firstName} ${selectStudent?.lastName}`
                return (
                  <>
                    <section className='d-flex flex-row justify-content-start mb-1'><span className='text-gray h45'>{translate('modal.newplan.selecteduserlabel')}</span></section>
                    <section className='d-flex flex-row align-items-center justify-content-between'>
                      <div className='d-flex flex-row align-items-center'>
                        <Avatar name={userName} uri={selectStudent?.avatar} className={'mr-2'}/>
                        <span>{userName}</span>
                      </div>
                      <div className='hover-icon danger' onClick={() => {setSelectStudent(null)}}>
                        <IconSignPlus size={12} className="text-error rotate-90"/>
                      </div>
                    </section>
                  </>
                )
              })
            }
          </div>
      
          <StickyButton
            color='#696CFF'
            condition={true}
            disabled={!(group ? selectStudentGroup.length : (selectStudent && JSON.stringify(selectStudent) !== JSON.stringify({})) )}
            label={translate('modal.newplan.selectstudenttitle')}
            onClickButtonFunction={() => {
              setStudent(group ? selectStudentGroup : selectStudent)
              toggle()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

SelectStudent.propTypes = {
}

SelectStudent.defaultProps = {
}
export default SelectStudent
