import React, { useEffect, useMemo, useState } from 'react'
import {
  ListItem,
  Field,
  PlainTextInput,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import useTranslation from '../../../../../../../i18n/useTranslation'
import getPayerUser from '../../../../../../../Model/Users/Get/GetPayerUser'
import usePaymentScheduledReducer from '../../../../../../../reducers/payment-scheduled'
import isEqual from 'lodash/isEqual'
import { nanoid } from 'nanoid'
import PaymentItemList from './PaymentItemList'
import { getRangeDate } from '../../utils'
import useEditPaymentModal from '../../../../../../CounselingPlans/Modal/newPlanModal/Components/EditPaymentModal/useEditPaymentModal'
const PaymentSchedule = (props) => {
  // Declaraciones
  const {
    onChange = () => { },
    form,
    disabled = false,
    // setForm,
    // isEdit, // si el plan se esta editando toma como valor inicial form?.defaultPaymentItemsConfig
    // baseForm
  } = props
  const { translate } = useTranslation()
  const getDefaultState = (paymentItemsConfig = []) => {
    const payments = paymentItemsConfig || []
    if (payments?.length > 0) {
      const signingItems = payments.filter(({ type }) => ["otherFixed", "others-fixed"].includes(type))
      const paymentItems = payments.filter(({ type }) => !["otherFixed", "others-fixed"].includes(type))
      const atSigningValue = signingItems?.reduce((total, { amount }) => total + amount, 0) || 0
      const defaultPay = [
        //at signing
        {
          atSigning: true,
          isEdit: true, amount: atSigningValue,
          // signingItems?.reduce((total, {amount}) => total+amount, 0) || 0, 
          date: moment().startOf('day').toISOString(), paymentId: nanoid()
        }
      ].concat(
        paymentItems.map(
          (payment) => { return { ...payment, paymentId: nanoid() } }
        )
      )
      return defaultPay
    } else {
      return [
        //at signing
        { atSigning: true, isEdit: true, amount: 0, date: moment().startOf('day').toISOString(), paymentId: nanoid() }
      ]
    }
  }

  const { totalAmount } = useMemo(() => {
    const paymentsConfig = getDefaultState(form?.paymentItemsConfig)
    //valor total del plan
    const totalAmount = paymentsConfig?.reduce((total, { amount }) => total + amount, 0)
    return { paymentsConfig, totalAmount }
  }, [form.paymentItemsConfig])

  const { startDate, endDate } = useMemo(() => {
    //OBTIENE RANGO DE TIEMPO
    const [start, end] = getRangeDate(form)
    return {
      startDate: start, endDate: end
    }
  }, 
  //eslint-disable-next-line
  [form?.typeLesson, form?.paymentstart, form?.PartsSchedule])

  const { open: openEditPaymentModal, close, setTotalAmount } = useEditPaymentModal()

  const [pays] = useState([])
  const {
    state: paymentState, setData, setDefaultPayment,
    resetPayments, distributePayments, createPayment,
    setRangeTime, editPayment, removePayment, defaultPayments
  } = usePaymentScheduledReducer(
    totalAmount, pays
  )

  //define rango de tiempo
  useEffect(() => { setRangeTime(startDate, endDate) }, 
  //eslint-disable-next-line
  [startDate, endDate])

  const parseToPaymentItemConfig = (paymentState) => {
    return paymentState?.payments || []
  }

  // Efectos
  const { lastPay, canAddPayment } = useMemo(() => {
    const canAddPayment = paymentState?.payments ? paymentState?.payments.length > 0 : false


    const lastPay = paymentState?.payments && paymentState?.payments.length ? paymentState?.payments[paymentState?.payments.length - 1].date : moment().toISOString()
    const atSigningAmount = paymentState?.payments?.filter(({ atSigning }) => atSigning)?.reduce((total, { amount }) => total + amount, 0) || 0

    const totalToDistributed = (paymentState?.payments || 0)?.reduce(
      (total, { amount }) => total + amount, 0
    ) - atSigningAmount
    //guarda monto total a distribuir
    //, sirve para habilitar o no el botón de envío
    setData({ totalToDistributed })

    //setea el monto total que puede 
    //llegar a tener el pago
    setTotalAmount(totalToDistributed, 0)

    const newPaymentItemConfig = parseToPaymentItemConfig(paymentState)
    const setBool = !isEqual(form?.paymentItemsConfig, newPaymentItemConfig)
    if (setBool) {
      onChange(newPaymentItemConfig)
    }


    return { lastPay, atSigningAmount, canAddPayment }
    //eslint-disable-next-line
  }, [paymentState?.payments])






  useEffect(
    () => {
      if (!isEqual(paymentState.payments, defaultPayments)) resetPayments()
      // eslint-disable-next-line
    }, [defaultPayments]
  )



  const [timerDefault, setTimerDefault] = useState(null);
  useEffect(() => {
    if (!form) return

    if (timerDefault) {
      clearTimeout(timerDefault);
      setTimerDefault(null);
    }

    // //espera 750 ms luego de la actulización de form,
    // //entonces actualiza el paymentState 
    setTimerDefault(
      setTimeout(() => {
        const newState = getDefaultState(form.paymentItemsConfig)
        const setBool = !isEqual(newState, defaultPayments)
        if (setBool) {
          setDefaultPayment(newState)
        }
      }, 750)
    );
    //eslint-disable-next-line
  }, [form?.defaultPaymentItemsConfig, form?.paymentItemsConfig])



// colocar id del usuario (estudiante o padre del estudiante) para obtener el metodo de pago



const { data: dataPaymentMethod } = getPayerUser({ userId: form.student ? form.student.id : '' })



let canReset = useMemo(
  () => {
    if (defaultPayments) {
      return !isEqual(defaultPayments, form?.paymentState?.payments)
    }
    return false
    //eslint-disable-next-line
  }, [defaultPayments, form?.paymentState?.payments]
)

const canDistributed = true

const [executeDistributed, setExecuteDistributed] = useState(false)
useEffect(() => {
  if (executeDistributed) {
    distributePayments(true, false)
    setExecuteDistributed(false)
  }
  //eslint-disable-next-line
}, [executeDistributed])

return (
  <>
    <div className='py-6'>
      <h3><b>{translate('modal.newplan.paymentschedule.title')}</b></h3>
      <div className='row pl-3'>
        <label className='text-muted mb-2 mt-3 pl-0'>{`${translate('modal.newplan.paymentschedule.paymentstart')} (${form.paymentstart ? translate('profile.payments.endOf', moment(form.paymentstart).format('MMMM')) : translate('modal.newplan.paymentschedule.selectdate')})`}</label>
        <Field
          type={PlainTextInput}
          fieldName='paymentstart'
          disabled={!!(form.statusPlan && form.statusPlan === 'scheduled')}
          fieldType='date'
          InputClassName='col-12'
        />
        <ListItem
          className='col-12 pr-1 pl-0 my-2 py-2'
          Left={
            <label className='text-muted h45'>
              {translate('modal.newplan.paymentschedule.paymentmethod')}
            </label>
          }
          Right={
            <label className='text-muted h45'>
              {dataPaymentMethod && dataPaymentMethod.paymentMethod
                ? dataPaymentMethod.paymentMethod.last4
                : translate('modal.newplan.paymentschedule.nodefined')}
            </label>
          }
        />
        <div className='w-100'>
          <PaymentItemList
            onClick={(obj) => {
              openEditPaymentModal(
                (newPayment) => {
                  editPayment({ ...obj, ...newPayment, isEdit: true })
                  distributePayments()
                  close()
                },
                () => {
                  const leftPaymentToDistribute = paymentState?.payments.filter(({ atSigning, isEdit, paymentId }) => !atSigning && !isEdit && paymentId !== obj?.paymentId)?.length
                  const leftPayment/**Lesson**/ = paymentState?.payments.filter(({ atSigning, paymentId }) => !atSigning && paymentId !== obj?.paymentId)?.length
                  if (leftPayment <= 0) {//no se puede eliminar el pago
                    close()
                  } else {

                    //queda al menos un item no editado
                    if (leftPaymentToDistribute >= 1) {
                      removePayment(obj, false/**distributed**/)
                      distributePayments()
                    } else {
                      //se carga el monto del item eliminado
                      // en el ultimo pago
                      removePayment(obj, false/**distributed**/)
                      setExecuteDistributed(true)
                    }

                    close()
                  }
                },
                { amount: obj.amount, date: obj.date }
              )
            }}
            disabled={disabled}
            payments={paymentState?.payments || []} />
        </div>
        <label
          className={`${canAddPayment && !disabled ? 'btn-link w-100' : 'text-muted w-100 cursor-not-allowed'}`} onClick={form.statusPlan && form.statusPlan === 'scheduled' ? () => { } : () => {
            if (canAddPayment && !disabled) {
              createPayment({ amount: 0, date: moment(lastPay).add(1, 'month').toISOString(), type: "lessons-payment" }, false)
              distributePayments(false)
              // addPaymentItem({ time: moment(lastPay).add(1, 'month'), value: 0, custom: false })
            }
          }}
        >
          {translate('modal.newplan.paymentschedule.addmore')}
        </label>
        <label className={`${canDistributed && !disabled ? 'btn-link text-error w-100' : 'text-muted w-100 cursor-not-allowed'}`} onClick={() => {
          if (canDistributed && !disabled) {
            distributePayments(true, false/**atSigning not included**/)
          }
        }}>
          {translate('modal.newplan.paymentschedule.distribute')}
        </label>
        <label className={`${canReset && !disabled ? 'btn-link text-error w-100' : 'text-muted w-100 cursor-not-allowed'}`} onClick={() => {
          if (canReset && !disabled) resetPayments()
        }}>
          {translate('modal.newplan.paymentschedule.reset')}
        </label>
      </div>
    </div>
    {/* <div className='borderTop my-2' /> */}
  </>

)
}

PaymentSchedule.propTypes = {
}

PaymentSchedule.defaultProps = {
}
export default PaymentSchedule
