import React from 'react'
import {
    CheckboxSelector,
    IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'

const FilterStatus = (props) => {
    const { setStatuses = () => {}, statuses = [] } = props
    return (
        <CheckboxSelector
            setDataFilters={setStatuses}
            dataFilters={statuses}
            data={[
                {id: 'scheduled', name: 'Scheduled'},
                {id: 'pending', name: 'Pending'}, 
                // {id: 'finished', name: 'Finished'}, 
                {id: 'attended', name: 'Attended'}, 
                {id: 'missed', name: 'Missed'}, 
                {id: 'incomplete', name: 'Incomplete'},
                {id: 'no-show', name: 'Educator no show'},  
                {id: 'canceled', name: 'Canceled'}
            ]}
            typeFilter={'statuses'}
            strings={{
                viewAll: "View all",
                buttonCancel: "Cancel",
                buttonApply: "Apply",
                search: "Search"
            }}
        >
            <Badge pill color='option' className='mr-1 filters'>
                <label style={{cursor: 'pointer'}} className='m-0 p-0'>
                    Status
                    <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default FilterStatus