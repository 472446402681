import React, { useEffect, useState, useMemo } from 'react'
import useModalState from '../../../../../utils/useModalState'
import useTranslation from '../../../../../i18n/useTranslation'
import { 
  Button, DropDown, Form, 
  IconArrowUp, ModalLayout, 
  StickyButton 
} from '@Knowledge-OTP/znk-ui-components'
import PlanDetails from './Components/PlanDetails'
import ScheduleDetails from './Components/PlanScheduling'
import PlanEducators from './Components/PlanEducators'
import WeeklySchedule from './Components/WeeklySchedule'
import GroupLessonSchedulingModal from './Components/WeeklySchedule/GroupLessonScheduling'
import SelectEducator from './Components/PlanEducators/SelectEducator'
import PlanStudent from './Components/PlanStudents'
import SelectStudent from './Components/PlanStudents/SelectStudent'
import PricingPlan from './Components/PricingPlan'
import SelectMultipleEducators from '../../../modal/editChildPlan/PlanEducators/SelectEducator'
import { getTotalPay, totalLessonPart } from './utils'
import createGroupPlan from '../../../../../Model/Plan/Group/Create'
import momentz from 'moment-timezone'
import moment from 'moment'
import { useSelector } from 'react-redux'
import PaymentScheduleNew from './Components/PaymentScehdule/paymentScheduleNew'
import { TYPEFIXED } from '../../../const' 

const NewGroupPlan = (props) => {
  const { services, refetch, educatorsBase } = props
  const modalState = useModalState('plan.newGroupModal')
  const { translate } = useTranslation()
  const { isOpen, toggle, modalData } = modalState
  const { isEdit } = modalData
  const [form, setForm] = useState(() => ({
    excludeDays: [],
    ...modalData,
    amount: 0,
    discount: 0,
    typePlan: 'date',
    typeLesson: 'lessonPerWeek',
    typePlanPricing: 'perlesson',
    educatorsIndividualFull: [],
    educatorsGroupFull: [],
    paymentstart: moment().toLocaleString(),
  }))
  const currentService = services ? services.docs && services.docs.find((serv) => serv.id === form.idservice) : null
  const [cacheService, setCacheService] = useState(null)
  const localTime = useSelector((state) => state.timezone.zone)
  const { submit, loading } = createGroupPlan(() => {
    refetch()
    setForm({
      typePlan: 'date',
      idservice: null,
      locationPlan: null,
      planname: '',
      typePlanPricing: '',
      typeLesson: 'lessonPerWeek',
      lpw: [{ partname: 'Parte 1', startdate: new Date(), enddate: new Date(), typeLesson: 'lessonPerWeek' }],
      coutas: [],
      educatorsIndividualFull: [],
      educatorsGroupFull: [],
      excludeDays: [],
      paymentItemsConfig: [],
      payments: {
        registration: true,
        exam: false,
        pro: false,
        lessons: true,
        groupLessons: true,
        discount: {
          registration: 0,
          exam: 0,
          pro: 0,
          lessons: 0
        },
        values: {
          registration: 200,
          exam: 50,
          pro: 50
        }
      }
    })
    toggle()
  }, console.log)
  useEffect(() => {
    if (cacheService !== currentService) {
      setCacheService(currentService)
      if (currentService) {
        const thisTopicsIds = currentService.topics.reduce((arr, topic) => arr.concat(topic.id), [])
        setForm(form => {
          const newParts = Object.entries(form.lpw).reduce((reducedObj, [partIndex, value]) => {
            reducedObj[partIndex] = Object.keys(value).reduce((reducedPart, key) => {
              if (['partname', 'startdate', 'enddate'].includes(key)) {
                reducedPart[key] = value[key]
              } else {
                if (thisTopicsIds.includes(key)) {
                  reducedPart[key] = value[key]
                }
              }
              return reducedPart
            }, {})
            return reducedObj
          }, {})
          const newEducators = {}
          return {
            ...form,
            educatorsGroup: newEducators,
            educatorsIndividual: newEducators,
            lpw: newParts
          }
        })
      }
    }
  }, [cacheService, currentService])

  const onSubmit = (status) => {
    const fee = form.cuotas.map((cuota, index) => {
      return {
        amount: cuota.value * (1 - (parseFloat(form.payments.discount.lessons || 0) / 100)),
        date: cuota.time,
        description: `Payment ${index}`,
        type: 'lessonsPayment',
        percentDiscount: parseFloat(form.payments.discount.lessons || 0),
        amountWithoutDiscount: parseFloat(cuota.value)
      }
    })
    const otherFixed = [
      { key: 'registration', value: 200 },
      { key: 'exam', value: 50 },
      { key: 'pro', value: 50 }
    ]
    let auxForm = form
    if (auxForm.typePlan === 'time' && auxForm.typeLesson === 'totalLesson') {
      // setea enddate a cada parte
      auxForm = {
        ...auxForm,
        lpw: Object.keys(auxForm.lpw).reduce((obj, index) => {
          return { ...obj, [index]: { ...auxForm.lpw[index], enddate: auxForm.lpw[index].startdate } }
        }, {})
      }
    }
    otherFixed.forEach((obj) => {
      if (auxForm.payments[obj.key]) {
        fee.push({
          amount: parseFloat(obj.value) * (1 - (parseFloat(auxForm.payments.discount[obj.key] || 0) / 100)),
          date: moment(),
          description: `Payment ${obj.key}`,
          type: 'otherFixed',
          percentDiscount: parseFloat(auxForm.payments.discount[obj.key] || 0),
          amountWithoutDiscount: parseFloat(obj.value),
          typeFixed: TYPEFIXED[obj.key] || TYPEFIXED?.nothing
        })
      }
    })
    const parts = Object.entries(form.lpw).reduce((arr, [index, part]) => {
      const { partname, startdate, enddate, ...topics } = part
      const lessonsPerWeekGroup = []
      const lessonsPerWeekIndividual = []
      Object.entries(topics).forEach(([topicId, info]) => {
        if (info.valueGroup && info.groupBlocks) {
          const blocksEducator = form.educatorsGroupFull.find(ele => ele.topicId === topicId.toString() && ele.partIndex === parseInt(index))
          info.groupBlocks.forEach(block => {
            lessonsPerWeekGroup.push({
              topicId,
              educatorId: blocksEducator.educatorUserId, // form.educatorsGroup[topicId].id,
              duration: 50,
              ...block.val,
              hour: `${block.val.hour}:00`
            })
          })
        }
        if (info.valueIndividual) {
          lessonsPerWeekIndividual.push({
            topicId,
            lessonsPerWeek: info.valueIndividual,
            lockLessons: form.typePlan === 'date' ? !!info.lock : false
          })
        }
      })
      const totalLessons = totalLessonPart(part, (form.typePlan === 'time' && form.typeLesson === 'totalLesson') ? 'fixed' : 'perWeek')
      return arr.concat([{
        name: partname,
        from: startdate,
        to: form.typeLesson === 'totalLesson' ? startdate : enddate,
        totalLessons,
        scheduleType: form.typeLesson || 'lessonPerWeek',
        lessonsPerWeekGroup,
        lessonsPerWeekIndividual
      }])
    }, [])
    let recommendedEducators = form.educatorsIndividualFull.map(element => {
      return {
        educatorUserId: element.educatorUserId,
        topicId: element.topicId,
        rate: element.rate && element.rate !== '' ? (typeof element.rate === 'string' ? parseInt(element.rate) : element.rate) : 1,
        isLockEducator: false,
        lessonLink: ['zoom', 'inPerson'].includes(form.locationPlan) ? element.backupLink : '',
        teachingType: 'individual',
        isWaiveIndividual: element.isWaiveIndividual
      }
    })
    recommendedEducators = recommendedEducators.concat(form.educatorsGroupFull.map(element => {
      return {
        educatorUserId: element.educatorUserId,
        topicId: element.topicId,
        rate: element.rate && element.rate !== '' ? (typeof element.rate === 'string' ? parseInt(element.rate) : element.rate) : 1,
        isLockEducator: false,
        lessonLink: ['zoom', 'inPerson'].includes(form.locationPlan) ? element.backupLink : '',
        teachingType: 'group',
        isWaiveGroup: element.isWaiveGroup
      }
    })
    )

    const newVal = {
      planId: form.planId,
      name: form.planname || `${currentService.name} Plan`,
      serviceId: form.idservice,
      recommendedEducators,
      enforceRecommendedEducators: false,
      testDate: form.testdate?.date,
      statusPlan: status,
      parts,
      paymentItemsConfig: fee,
      studentPlans: form.students.map(stu => ({ 
        studentId: stu.id, 
        //Valor individual por lección
        rate: form.typePlanPricing === "perlesson" ? form.amount || 75 : stu.studentInfo.defaultRate || 75 
      })),
      location: {
        type: form.locationPlan,
        linkStudent: form.locationPlanLink || '',
        linkEducator: form.locationPlanLink || ''
      },
      typePlanPricing: form.typePlanPricing,
      individualRate: form.amount,
      groupRate: form.groupAmount ? form.groupAmount : 0,
      excludeDays: form.excludeDays || []
    }

    const partsModified = newVal.parts    
    newVal.parts.forEach((item, index) => {
      const from = partsModified[index].from
      item.lessonsPerWeekGroup.forEach((element, idx) => {
        const ndate = momentz(from).tz(localTime).day(element.day).hour(parseInt(element.hour.split(':')[0])).format()
        const nh = moment.utc(ndate)
        partsModified[index].lessonsPerWeekGroup[idx].day = nh.day()
        partsModified[index].lessonsPerWeekGroup[idx].hour = nh.format('HH:mm')
      })
    })

    newVal.parts = partsModified
    submit(newVal)
  }
  const weeklyScheduledValidation = (form) => {
    let pass = false
    const topicsWithIndividualEducators = form.educatorsIndividualFull.reduce((arr, edu) => arr.includes(edu.topicId) ? arr : arr.concat(edu.topicId), [])
    Object.values(form.lpw).forEach(
      (part, idx) => {
        const { partname, startdate, enddate, ...topics } = part
        if (part.partname === '' || !part.startdate || !part.enddate) {
          pass = true
        } else {
          if (Object.entries(topics).length) {
            let hasNoLessons = true
            Object.entries(topics).forEach(([topicId, info]) => {
              if (info.valueGroup || info.valueIndividual) {
                hasNoLessons = false
              }
              if (info.valueGroup && info.groupBlocks && info.valueGroup !== info.groupBlocks.length) {
                // value and blocks dont match
                pass = true
                return
              }
              if (info.groupBlocks && info.groupBlocks.length) {
                const blocksEducator = form.educatorsGroupFull.find(ele => ele.topicId === topicId.toString() && ele.partIndex === idx)
                if (!blocksEducator) {
                  // No Educator for this part
                  pass = true
                  return
                }
              }
              if (info.valueIndividual && !topicsWithIndividualEducators.includes(topicId)) {
                // no educator for individual topic
                pass = true
              }
            })
            if (hasNoLessons) {
              // has no lessons this part
              pass = true
            }
          } else {
            // no tiene ningún topico seteado
            pass = true
          }
        }
      }
    )
    return pass
  }
  const canSubmit = useMemo(() => {
    if (!form.idservice || form.idservice === '0') {
      return true
    }
    if (!form.testdate) {
      return true
    }
    if (!form.locationPlan) {
      return true
    }
    let pass = weeklyScheduledValidation(form)
    
    if (!form.students || !form.students.length === 0) {
      return true
    }
    if (form.typePlanPricing === 'perlesson' && (typeof form.amount !== 'number' || typeof form.groupAmount !== 'number')) {
      return true
    }
    if (form.typePlanPricing === 'permonth' && (typeof form.amount !== 'number')) {
      return true
    }
    if (pass) {
      return true
    }
    return false
    // eslint-disable-next-line
  }, [form])

  return (
    <>
      <ModalLayout
        className='modal-sidebar modal-colored scheduled-modal modal-desktop-half'
        isOpen={isOpen}
        toggle={toggle}
        minHead={
          <div className='p-0 m-0'>
            <span className='h2 font-weight-bold flex-grow-1 text-truncate'>
              {
                isEdit ? translate('plans.modal.editGroupPlan') : translate('plans.modal.newGroupPlan')
              }
            </span>
          </div>
        }
        topHead={
          <div className='p-0 m-0'>
            <span className='h2 font-weight-bold flex-grow-1 text-truncate'>
              {
                isEdit ? translate('plans.modal.editGroupPlan') : translate('plans.modal.newGroupPlan')
              }
            </span>
          </div>
        }
      >
        <Form value={form} onChange={setForm}>
          <div className='w-100 px-2'>
            <PlanDetails form={form} setForm={setForm} data={services} />
            <div className={`w-100 borderTop my-2`}/>
            <ScheduleDetails form={form} setForm={setForm} currentService={currentService} />
            <div className={`w-100 borderTop my-2`}/>
            <PlanEducators form={form} setForm={setForm} currentService={currentService} educatorsBase={educatorsBase} multiple />
            <div className={`w-100 borderTop my-2`}/>
            <WeeklySchedule form={form} setForm={setForm} currentService={currentService} educatorsBase={educatorsBase} />
            <div className={`w-100 borderTop my-2`}/>
            <PlanStudent form={form} setForm={setForm} />
            <div className={`w-100 borderTop my-2`}/>
            <PricingPlan form={form} setForm={setForm} />
            <div className={`w-100 borderTop my-2`}/>
            <PaymentScheduleNew
              form={form}
              setForm={setForm}
              totalPay={getTotalPay(form)}
              canReset
              canDistributed
            />
            <StickyButton>
              <div className='m-0 p-0 row'>
                <Button
                  color='primary'
                  activity={loading}
                  disabled={canSubmit}
                  className={
                    isEdit ? 'col-11' : 'col-9'
                  }
                  label={isEdit ? translate('plans.modal.save') : translate('plans.modal.saveindraft')}
                  onClick={() => {
                    onSubmit('draft')
                  }}
                  style={{ borderRadius: `${isEdit ? '16px' : '16px 0px 0px 16px'}` }}
                />
                {
                  !isEdit &&
                    <Button
                      color='primary'
                      className='col-2'
                      disabled={canSubmit}
                      label={
                        <DropDown
                          direction='up'
                          hoverEnabled={false}
                          classNameMenu='w-auto mb-3'
                          options={[{
                            component:
  <h4
    className='p-3'
    onClick={() => {
      onSubmit('sent')
    }}
  >{translate('plans.modal.saveinsent')}
  </h4>
                          }]}
                        >
                          <IconArrowUp />
                        </DropDown>
                      }
                      style={{ borderRadius: '0px 16px 16px 0px', marginLeft: '1px' }}
                    />
                }
              </div>
            </StickyButton>
          </div>
        </Form>
      </ModalLayout>
      <SelectEducator form={form} />
      <SelectMultipleEducators />
      <SelectStudent />
      <GroupLessonSchedulingModal setForm={setForm} form={form} />
    </>
  )
}

export default NewGroupPlan
