import React, { useCallback } from 'react'
import {IconLoad, Switch} from '@Knowledge-OTP/znk-ui-components'
import setUserStatus from '../../../../../../Model/Users/ChangeStatus'
import useGetUserStatus from './Model/getUserStatus'
const SwitchUserStatus = (props) => {
    const {userId, onChange = () => {}, ...otherProps} = props
    const {data, refetch} = useGetUserStatus({id: userId})
    const isActive = data?.user?.isActive
    
    const { submit: submitStatusUser, loading } = setUserStatus(() => {   
        if(refetch) refetch()
        if(onChange) onChange()
    }, console.log)
    const RenderSwitch = useCallback(
        (props) => {
            return (
                <Switch
                    initialState={isActive} 
                    onChange={() => {
                        submitStatusUser({userId, status: !isActive})
                    }}
                    {...props}
                />
            )
        }, 
        // eslint-disable-next-line
        [isActive, userId]
    )
    return (
        <div className='d-flex flex-row align-items-center'>
            {loading && (<IconLoad className={'mr-2'}/>)}
            <RenderSwitch
                style={{}}
                className='m-0'
                {...otherProps}
            />
        </div>
    )
} 

export default SwitchUserStatus