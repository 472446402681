import React, { useState } from 'react'
import { IconErase, IconTime, ToolTipInfo } from '@Knowledge-OTP/znk-ui-components'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import useTranslation from '../../../../../../i18n/useTranslation'
import useDeleteSendPlanCron from './Model/useDeleteSendPlanCron'
import { showAlert } from '../../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import { Collapse } from 'reactstrap'
import { nanoid } from 'nanoid'

const formatDate = 'MM/DD/YYYY hh:mm a'

const ProgramSentPlanLabel = (props) => {
    const { sendDate, planId, onDelete = () => { } } = props
    const currentTimezone = useSelector((state) => state.user.timezone)
    const [sendCronDelete, setSendCronDelete] = useState(false)
    const dispatch = useDispatch()
    const [deleteCron] = useDeleteSendPlanCron()
    const { translate, replaceVariables } = useTranslation()
    const text = replaceVariables(translate('plans.modal.sendplan.programSentPlan.programFor'), { 'DATE': moment(sendDate).tz(currentTimezone).format(formatDate) })

    const onSuccessMutation = (res) => {
        setSendCronDelete(true)
        if (onDelete) onDelete()
    }
    const onErrorMutation = (err) => {
        console.error(err)
        showAlert({ text: err.toString(), status: 'error' }, dispatch)
    }

    const executeDeleteCron = () => {
        deleteCron({ variables: { planId } }).then(onSuccessMutation, onErrorMutation)
    }
    const tooltipId = nanoid()
    return (
        <Collapse isOpen={!sendCronDelete}>
            <div className='m-0 p-0 py-2 w-100 d-flex flex-row align-items-center justify-content-between'>
                <ToolTipInfo
                    className={'w-90 d-flex flex-row align-items-center'}
                    tooltipId={tooltipId}
                    place={'top'}
                    innerElement={<span>{text}</span>}
                >
                    <label className='pb-0 text-gray h45 text-truncate mb-0 w-100'>
                            <IconTime size={16} className={'mr-2'} />
                            {text}
                    </label>
                </ToolTipInfo>
                <div className='m-0 p-0 d-flex flex-row align-items-center justify-content-end'>
                    {
                        planId && (
                            <div
                                onClick={() => { executeDeleteCron() }}
                                className='hover-icon'>
                                <IconErase size={16} className={'text-error'} />
                            </div>
                        )
                    }
                </div>
            </div>
        </Collapse>
    )
}

export default ProgramSentPlanLabel