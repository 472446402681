import validate from 'validate.js'

const constraints = {
  value: {
    presence: true,
    length: {
      minimum: 1
    }
  }
}

export default (data) => {
  return validate(data, constraints)
}
