import React, {useEffect, useState, useMemo} from 'react'
import TopicFilter from '../Filters/TopicFilter'
import EducatorFilter from '../Filters/TeacherFilter';
import StudentFilter from '../Filters/StudentFilter'
import './index.css'
import ListItem from '../ListItem'
import Skeleton from 'react-loading-skeleton'
import StateFilter from "../Filters/StateFilter";
import {IconSignPlus} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from "reactstrap";
import useTranslation from "../../../../i18n/useTranslation";
import {getStatus} from "../Filters/StateFilter/helpers/getStatus";

const List = (props) => {
    const {lessons: lessonsProps, loading} = props
    //lecciones sin contar educator-unavailability
    const lessons = useMemo(() => {
        return lessonsProps?.filter(({completeLesson}) => completeLesson?.kind !== 'educator-unavailability')
    }, [lessonsProps])
    const {translate} = useTranslation()
    const [topics, setTopics] = useState([])
    const [statuses, setStatuses] = useState([])
    const [educatorsFilters, setEducatorsFilters] = useState([])
    const [studentsFilters, setStudentsFilters] = useState([])
    const [lessonsState, setLessonsState,] = useState({
        listType: 'normalList',
        group: []
    })
    useEffect(() => {
        setLessonsState({
            listType: 'normalList',
            group: lessons
        })
    }, [lessons])
    useEffect(() => {
        const list = []
        if (topics.length !== 0) {
            // eslint-disable-next-line
            topics.map((id) => {
                const items = lessons.filter(lesson => {
                    if (statuses.length === 0) {
                        return lesson?.service?.id === id
                    } else {
                        return lesson?.service?.id === id && statuses.includes(getStatus(lesson, lesson?.completeLesson, translate))
                    }
                })
                const collection = items ? items : []
                if (collection.length !== 0) {
                    list.push({
                        name: collection[0].service.name,
                        collection,
                    })
                }
            })
            setLessonsState({
                listType: 'collectionGroup',
                group: list,
            })
        }
        if (topics.length === 0 && statuses.length !== 0) {
            // eslint-disable-next-line
            statuses.map(status => {
                list.push({
                    name: status,
                    collection: lessons.filter(lesson => getStatus(lesson, lesson.completeLesson, translate) === status),
                })
            })
            setLessonsState({
                listType: 'collectionGroup',
                group: list,
            })
        }
        // eslint-disable-next-line
    }, [topics, statuses])
    const cleanFilters = () => {
        setTopics([])
        setEducatorsFilters([])
        setStudentsFilters([])
        setStatuses([])
        setLessonsState({
            listType: 'normalList',
            group: lessons
        })
    }
    const {listType, group} = lessonsState
    return (
        <>
            <div className="ml-8 p-0 w-100 d-flex flex-row justify-content-between align-items-center">
                <div className='container-fluid mt-2 mb-4 mt-md-5 d-flex px-0 znk-scroll-horizontal'>
                    <TopicFilter serviceFilters={topics} setServiceFilters={setTopics}/>
                    <StateFilter statusFilters={statuses} setStatusFilters={setStatuses}/>
                    <StudentFilter
                        setLessonsState={setLessonsState}
                        originalLessons={lessons}
                        studentsFilters={studentsFilters}
                        setStudentsFilters={setStudentsFilters}/>
                    <EducatorFilter
                        setLessonsState={setLessonsState}
                        originalLessons={lessons}
                        educatorsFilters={educatorsFilters}
                        setEducatorsFilters={setEducatorsFilters}/>
                    <Badge className='mx-1 filters' pill color='option' onClick={cleanFilters}>
                        <label style={{cursor: 'pointer'}} className='m-0 p-0'>
                            <IconSignPlus size={15} className='mr-1'/>
                            {translate('misc.cleanFilters')}
                        </label>
                    </Badge>
                </div>
            </div>
            {
                loading
                    ?
                    <div className="mt-3">
                        <Skeleton count={5} height={40}/>
                    </div>
                    :
                    <div className='container-fluid d-flex flex-column mx-0 mt-4 px-0'
                         style={{overflowX: 'scroll', overflowY: 'auto', height: '50rem'}}>
                        {
                            listType === 'collectionGroup'
                                ?
                                group.map(({collection, name}) =>
                                    <>
                                        {
                                            collection.length !== 0
                                            &&
                                            <>
                                                <div className="border-bottom mb-4 mt-4">
                                                    <span className="h2">{name}</span>
                                                </div>
                                                {collection.map(lesson => <ListItem key={lesson.id} lesson={lesson}/>)}
                                            </>
                                        }
                                    </>)
                                :
                                group.map(lesson => <ListItem key={lesson.id} lesson={lesson}/>)
                        }
                    </div>
            }
        </>
    )
}

export default List
