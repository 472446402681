import moment from 'moment-timezone'

export const getStatusByShortcuts = (shortcuts) => {

    const statusShort = shortcuts.all
      ? ['pendingPayment', 'procesingPayment', 'paid', 'unPaid', 'draft']
      : shortcuts.currentCicle
        ? ['pendingPayment', 'procesingPayment', 'paid', 'unPaid']
        : shortcuts.paidOut
          ? ['paid']
          : shortcuts.pending
            ? ['unPaid']
            : ['draft']
    
    return statusShort
}


export const getQueryByShortcut = (shortcuts, query = {}) => {
    const utcDate = moment.utc()
    let from, to
    if (utcDate.date() > 22) {
      from = utcDate.clone().date(23).startOf('day')
      to = from.clone().add(1, 'month').subtract(1, 'day').endOf('day')
    } else {
      to = utcDate.clone().date(22).endOf('day')
      from = to.clone().subtract(1, 'month').add(1, 'day').startOf('day')
    }
    const currentCycle = { from, to }
    const statusShort = getStatusByShortcuts(shortcuts)
    return {
      status: statusShort,//query?.status?.length > 0 ? query.status : statusShort,
      from: shortcuts.currentCicle ? currentCycle.from.toISOString() : null, //query?.from ? query.from.split('T')[0] + 'T00:00:00.000Z' : undefined,
      to: shortcuts.currentCicle ? currentCycle.to.toISOString() : null, //query?.to ? query.to.split('T')[0] + 'T23:59:59.999Z' : undefined,
      current: shortcuts.currentCicle,
      pending: shortcuts.pending,
    }
  }

export const getNewShortcuts = (shortcutKey) => {
    const newShortcut = {
        currentCicle: false,
        pending: false,
        upcomming: false,
        paidOut: false,
        cancelled: false,
        draft: false,
        all: false
      }
      newShortcut[shortcutKey] = true 
      return newShortcut
}

export default {
    getStatusByShortcuts,
    getNewShortcuts,
    getQueryByShortcut
}