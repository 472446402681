import validate from 'validate.js'

const constraints = {
  userId: {
    presence: true
  },
  content: {
    presence: true,
  },
}

export default (data) => {
  return validate(data, constraints)
}
