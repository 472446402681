import React from 'react'
import {ModalLayout} from '@Knowledge-OTP/znk-ui-components'
import Notes from '../../../../../../Student/modal/InfoStudent/Notes'
import { useState } from 'react'

const CheckInNotes = (props) => {
    const {studentId, checkinId} = props
    const [isOpen, setOpen] = useState(false)
    const toggle = () => {setOpen(!isOpen)}
    return (
        <>
            <ModalLayout
                className={'modal-centered'}
                contentClassName={'h-auto'}
                isOpen={isOpen}
                toggle={toggle}
                topHead={<span className='h2 font-weight-bold'>Check In Notes</span>}
            >
                <Notes page='notes' studentId={studentId} checkinId={checkinId}/>
            </ModalLayout>
            <div className='m-0 p-0' onClick={toggle} >{props.children}</div>
        </>
    )
}

export default CheckInNotes