import React, {useEffect} from 'react'
import { useLocation } from 'react-router'
import useLazyGetTask from '../../../../Model/useLazyGetTask'
import useViewTaskModal from '../../useViewTaskModal'
import useNewTaskModal from '../../../newTaskModal/useNewTaskModal'
import useAssignTaskModal from '../../../assingTaskModal/useAssignTaskModal'
import useDeleteTaskModal from '../../../deleteTaskModal/useDeleteTaskModal'

const RedirectToTaskModal = () => {
    const {openForCreatedTask: openViewTaskModal, close: closeViewTaskModal} = useViewTaskModal()
    const {close: closeNewTaskModal} = useNewTaskModal()
    const {close: closeAssignTaskModal} = useAssignTaskModal()
    const {close: closeDeleteTaskModal} = useDeleteTaskModal()
    const [getTask, {refetch}] = useLazyGetTask(
        (res) => {
            //abre el modal
            const {task, ...other} = res?.getTask
            openViewTaskModal(
                {...task, ...other, isEdit: true, taskName: task?.taskName || task?.name}, 
                (action) => {
                    switch(action) {
                        case 'edit':
                            closeNewTaskModal()
                            break
                        case 'new':
                            closeNewTaskModal()
                            break    
                        case 'duplicate' :
                            break
                        case 'assign' : //ejecuta mutación en modal assignTaskModal
                            //luego de asignar la tarea
                            //setea info de task en taskViewModal
                            closeAssignTaskModal()
                            break 
                        case 'delete' : //ejecuta mutación en modal deleteTaskModal
                            closeDeleteTaskModal()
                            closeViewTaskModal()
                            break        
                        default: 
                            break        
                    }
                    refetch()
                }, true)
        },
        (err) => {
            console.error(err)
        }
    )

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const taskId = searchParams.get('taskId')

    useEffect(
        () => {
            if(taskId) getTask({variables: {taskId}})
            // eslint-disable-next-line
        }, [taskId]
    )
    return (
        <></>
    )
}

export default RedirectToTaskModal