import React from 'react'
import {
  FilterBetweenDates,
  FilterBetweenDatesInner,
  IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'

const StartDateFilterBadge = (props) => {
  const { translate } = useTranslation()
  const { startDateFilter, setStartDateFilter, typeFilter, useInner = false } = props

  const checkboxSelectorProps = {
    className: 'h45',
    strings: {
      title: translate('filters.plans.startDateTitle'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
      from: translate('filters.plans.fromDate'),
      to: translate('filters.plans.toDate')
    },
    setDates: setStartDateFilter,
    typeFilter: typeFilter,
    dates: startDateFilter,
    direction: `bottomCenter`,
  }

  return (
    useInner ?
    <FilterBetweenDatesInner style={{maxWidth: 'auto'}} {...checkboxSelectorProps}/> :
      <FilterBetweenDates {...checkboxSelectorProps}>
<Badge className='mx-1 filters' pill color='option'>
          <label style={{cursor: 'pointer'}} className='m-0 p-0'>
            Dates
            <IconArrowDown className='ml-2' size={15} />
          </label>
        </Badge>
      </FilterBetweenDates>
  )
}
export default StartDateFilterBadge
