import React from 'react'
import {useMutation, gql} from '@apollo/client'
import {IconLoad, IconCreditCard} from '@Knowledge-OTP/znk-ui-components'
import { showAlert } from '../../../../../../common/Alert/util'
import useTranslation from '../../../../../../i18n/useTranslation'
import { useDispatch } from 'react-redux'

const RecreatePaymentItemForEducator = (props) => {
    const {lessonId} = props
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const [submit, {loading}] = useMutation(
        gql`
            mutation($lessonId: ID!) {
                createPaymentsForLessonEducator(lessonId: $lessonId)
            }
        `
    )
    return (
        <span onClick={() => {
            submit({
                variables: {lessonId}
            }).then((result) => {
                showAlert({
                    text: translate(`modal.infolesson.recreatepayment.${
                        result.data.createPaymentsForLessonEducator ? 
                        'paymentExist' : 'success'
                    }`),
                    status: "success"
                }, dispatch)
            }, (e) => {
                showAlert({
                    text: e.message || translate("modal.infolesson.recreatepayment.error"),
                    status: "error"
                }, dispatch)
                console.error(e)
            })
        }}>
            {loading ? <IconLoad className={'mr-2'}/> : <IconCreditCard className={`mr-2`} />} 
            {translate("modal.infolesson.recreatepayment.option")}
        </span>
    )
}

export default RecreatePaymentItemForEducator