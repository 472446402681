import {useMutation, gql} from '@apollo/client'
import myUniversity from './queryResults/myUniversity'

    const useMarkMyUniversity = (onSuccess = () => {}, onDelete = () => {}) => {

    return useMutation(
        gql`
            mutation(
                     $userId: ID
                     $universityId: ID!
                     $action: UniversityAction!
                     $mark: UniversityEarly!
                     $makeValidation: Boolean = false

                     ){
                         markMyUniversity (
                             universityId: $universityId
                             action: $action
                             mark: $mark
                             userId: $userId
                             makeValidation: $makeValidation
                         ){
                            ${myUniversity} 
                         }
                     }
        `,
        {
            onCompleted: () => {
                onSuccess()
            },
            onError: () => {
                onDelete()
            }
        }
    )
}

export default useMarkMyUniversity