import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useDeleteTemplateModal from './useDeleteTemplateModal'

const DeleteTemplateModal = () => {
    const {toggle, isOpen, onChange, close} = useDeleteTemplateModal()
    return (
        <ConfirmModal 
            classNameActionDiv={`d-flex flex-column`}
            classNameConfirm={'text-error'}
            isOpen={isOpen}
            toggle={toggle}
            title={'Do you want to delete this template?'}
            body={'This action can not be undone'}
            confirmAction={() => {
                onChange()
                close()
            }}
            cancelAction={() => {close()}}
            confirmText={'Delete template'}
            cancelText={'Cancel'}
        />
    )
}

export default DeleteTemplateModal