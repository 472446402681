import React from 'react'
import Skeleton from 'react-loading-skeleton'

const TimelinePlanSkeleton = (props) => {
    const {className, ...otherProps} = props
    return (
        <div className={`d-flex flex-row justify-content-between align-items-center ${className}`} {...otherProps}>
            <div className={`d-flex flex-row justify-content-start align-items-center`}>
                <Skeleton count={1} width={50} height={50} style={{borderRadius: '12px'}} />
                <div className={`d-flex flex-column justify-content-center ml-2`}>
                    <Skeleton count={1} className={`ml-0`} width={200} style={{borderRadius: '12px'}} />
                    <Skeleton count={2} className={`ml-1 d-flex flex-column mt-2`} width={100} style={{borderRadius: '12px'}}/>
                </div>
            </div>
            <Skeleton count={1} width={40} height={40} style={{borderRadius: '50%'}} />
        </div>
    )
}

export default TimelinePlanSkeleton