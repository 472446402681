import React, {useMemo} from 'react'
import { useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import useCalendar from '../../Calendar/Sections/Calendar/useCalendar'
import {NameField, DateField, StudentField, StatusField, OptionsField, EducatorField} from './Fields'
import moment from 'moment'

const useColumns = (refetch = () => {}) => {
    const {breakWidth} = useWindowDimensions()
    const {getLessonStatus} = useCalendar()
    return useMemo(
        () => {
            let config = [{col: 3, className: 'text-truncate'},{col: 2, className: 'text-truncate'},{col: 2},{col: 2},{col: 2},{col: 1}]
            if(breakWidth === 'SM') {
                config = [{col: 6, className: 'text-truncate'}, {col: 4}, {col: 2}]
            } else if (breakWidth === 'MD') {
                config = [{col: 4, className: 'text-truncate'}, {col: 3}, {col: 3}, {col: 2}]
            }
            return {
                config,
                generateRow: (lesson, upcommingLessons = []) => {
                    const {status} = getLessonStatus(lesson)
                    const inProgress = moment() > moment(lesson?.startDate) && moment() < moment(lesson?.endDate)
                    //scheduled, incomplete, finished, draft, upcoming, attended, missed
                    let rowInner = [
                            <NameField className={`d-flex flex-row justify-content-start`} lesson={lesson}/>,
                            <DateField lesson={lesson}/>,
                            <EducatorField className={`d-flex flex-row justify-content-start align-items-center text-truncate`} lesson={lesson}/>,
                            <StudentField className={`d-flex flex-row justify-content-start align-items-center text-truncate znk-scroll-horizontal-v2`} lesson={lesson}/>,
                            <StatusField className={`d-flex flex-row justify-content-end align-items-center`} realStatus={status} lesson={lesson}/>,
                            <OptionsField className={`d-flex flex-row justify-content-end`} lesson={lesson} refetch={refetch}/>,
                        ]
                    if(breakWidth === 'SM') {
                        rowInner = [
                            <NameField className={`d-flex flex-row justify-content-start`} lesson={lesson}/>,
                            <EducatorField className={`d-flex flex-row justify-content-start align-items-center text-truncate`} lesson={lesson}/>,
                            // <StatusField className={`d-flex flex-row justify-content-end align-items-center`} realStatus={status} lesson={lesson}/>,
                            <OptionsField className={`d-flex flex-row justify-content-end`} lesson={lesson} refetch={refetch}/>,
                        ]
                    } else if(breakWidth === 'MD') {
                        rowInner = [
                            <NameField className={`d-flex flex-row justify-content-start`} lesson={lesson}/>,
                            <EducatorField className={`d-flex flex-row justify-content-start align-items-center text-truncate`} lesson={lesson}/>,
                            <StatusField className={`d-flex flex-row justify-content-end align-items-center`} realStatus={status} lesson={lesson}/>,
                            <OptionsField className={`d-flex flex-row justify-content-end`} lesson={lesson} refetch={refetch}/>,
                        ]
                    }
                    return {
                        rowInner,
                        item: {
                            ...lesson,
                            className: `lesson-${
                                status === 'scheduled' && 
                                (upcommingLessons.includes(lesson?.id) || inProgress) 
                                    ? 'upcoming' : status 
                            } align-items-center`
                        }
                    }
                }
            }
            //eslint-disable-next-line
        }, [breakWidth]
    )
}

export default useColumns