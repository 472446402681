import React, { useMemo } from 'react'
import { 
  useWindowDimensions,
  Avatar,
  IconUser,
  ToolTipInfo
} from '@Knowledge-OTP/znk-ui-components'
import StatusField from '../../Plans/Fields/Status'
import moment from 'moment-timezone'
//responsive
import CompactPlanInfoField from '../../Plans/Fields/CompactPlanInfo'

import useViewPlanModal from '../Modal/viewPlanModal/useViewPlanModal'
import useOptionsByStatus from '../Modal/viewPlanModal/Components/useOptionsByStatus'
import ProgressPlanField from './Fields/ProgressPlanField'
//Sección con grafico circular, muestra el progreso del plan
//también se usa en viewPlanModal - Summary
import PlanProgress from '../Modal/viewPlanModal/Tabs/Summary/Sections/PlanProgress'

//reducers
import useCounselingPlanReducer from '../../../reducers/counseling-plan'
import useTemplateReducer from '../../../reducers/template'
import {getValues} from './util'
import CountryFlag from '../../Users/Components/CountryFlag'
import Atom from '../../../assets/Atom.svg'

// const getValues = (date, type) => {
//   const month = moment(date).month()
//   let year = moment(date).year()
//   if (month >= 10 && month <= 12) {
//     year += 1
//   }
//   const difY = year - moment().year()
//   switch (type) {
//     case 'class':
//       return year.toString().substr(2, 2)
//     default:
//       return 12 - difY
//   }
// }

const statusDictionary = value => {
  switch (value) {
      case 'draft':
        return 'Draft'
      case 'sent':
        return 'Sent'
      case 'scheduled':
          return 'Pre Application'
      case 'application':
          return 'Application'
      case 'postApplication':
          return 'Post Application'
      case 'completed':
          return 'Completed'
      case 'cancel':
          return 'cancel'    
      default:
          return 'Pre Application'
  }
}

const typeDictionary = {
  'graduate': 'Graduate',
  'undergraduate': 'Undergraduate',
  'transfer': 'Transfer',
  'highSchool': 'High School',
}
//parsea status por phase actual
export const parseStatus = (plan) => {
  return statusDictionary(plan?.status)
}  
const useResponsivePlanColumns = (data, refetchPlanList = () => {}) => {
  const { breakWidth } = useWindowDimensions()

  const {parseToDropdown, getOptions} = useOptionsByStatus()

  const {parsePlanFromAPI} = useCounselingPlanReducer()
  const {filterDelete} = useTemplateReducer()

  const {
    open: openViewPlanModal //abre modal para visualizar un plan
  } = useViewPlanModal()

  const getCompactAvatarField = (colSpan = 8) => {
    return { 
      render: (item) => <CompactPlanInfoField 
        item={item}
        studentUser={{...item.studentUser, avatar: item?.aditionalInfo?.avatar || null}} 
        status={parseStatus(item)} 
        thisService={{name: item?.type, topics: []}} 
      />, 
      colSpan, 
      className: 'justify-content-start align-items-center', 
      id: 'avatar' 
    }
  }
  const getAvatarField = (colSpan = 3) => {
    return { 
      render: (item) => {
        const hasParent = item?.aditionalInfo?.parentFirstName && item?.aditionalInfo?.parentLastName
        const userName = `${item?.studentUser?.firstName} ${item?.studentUser?.lastName}`
        return (
          <div className={`d-flex flex-row align-items-center`}>
            <Avatar
              name={userName}
              uri={item?.aditionalInfo?.avatar || null}
            />
            <div className={`d-flex flex-column ml-2 align-self-center`}>
              <span className={``}>{userName}</span>
              <ToolTipInfo
                tooltipId={item?.aditionalInfo?.parentUserId}
                innerElement={`Parent`}
              >
                <span className={`h45 text-gray d-flex flex-row align-items-center`} 
                  // style={hasParent ? {visibility: 'visible'} : {visibility: 'hidden'}}
                  >
                  <IconUser size={12} className={`mr-1 text-gray`}/> 
                  
                  {hasParent ? `${item?.aditionalInfo?.parentFirstName} ${item?.aditionalInfo?.parentLastName}` : `No parent`}
                </span>
              </ToolTipInfo>
            </div>
          </div>
        )  
      }, 
      colSpan, 
      className: 'justify-content-start align-items-center text-title', 
      id: 'avatar' 
    }
  }
  const getTypeField = (colSpan = 2) => {
    return {
      render: (item) => {
       // return <span style={{textTransform: 'capitalize'}}>{item?.type}</span>
       return <span style={{textTransform: 'capitalize'}}>{typeDictionary[item?.type]}</span>
      },
      hover: () => {
        return <span>Type</span>
      },
      colSpan,
      type: 'tooltip',
      className: 'justify-content-center align-items-center text-secondary',
      id: 'service'
    }
  }
  const getApplicationYearField = (colSpan = 2) => {
    return {
      render: (item) => {
        const applicationYear = item?.aditionalInfo?.applicationYear
        const grade = getValues(moment(parseInt(applicationYear)).toISOString(), 'class')
        let title = {}
        if(!applicationYear) title = {title: 'Student dont have the graduation date on his profile.'}
        return <span {...title}>{
          applicationYear ?
            `Class of ‘${grade}` : 
            'No defined'              
          } 
        </span>
      },
      hover: (item) => {
        return <span>{`From ${moment(item?.from).format(`DD MMM`)} to ${moment(item?.to).format(`DD MMM`)}`}</span>
      },
      colSpan,
      type: 'tooltip',
      className: 'justify-content-center align-items-center text-secondary',
      id: 'duration'
    }
  }

  const isAtomic = (colSpan = 2) => {
    return {
      render: (item) => {
        return (
          item.isAtomic === true && (
            <div className='d-flex-flex column'>
              {' '}
              <img
                src={Atom}
                alt='Plan from atomic'
                color='#7E8C9E'
                width='40'
                height='40'
              />
            </div>
          )

        )
      },
      colSpan,
      className: 'justify-content-center align-items-center text-title',
      id: 'isAtomic'
    }
  }

  const getProgressField = (colSpan = 2) => {
    return {
      render: (item) => {
        return <ProgressPlanField item={item}/>
      },
      hover: (item) => {
        const parsePlan = parsePlanFromAPI(item)
        return <PlanProgress plan={{
          ...parsePlan,
          tasks: filterDelete({phases: parsePlan?.tasks})?.phases
        }}/>
      },
      colSpan,
      type: 'tooltip',
      className: 'justify-content-center align-items-center text-secondary',
      id: 'progress'
    }
  }
  const getStatusField = (colSpan = 2) => {
    return {
      render: (item) => {
        return <StatusField plan={item} status={parseStatus(item)} />
      },
      colSpan,
      className: 'justify-content-end align-items-center text-secondary',
      id: 'status'
    }
  }

  const getOptionsField = (colSpan = 3) => {
    return {
      optionsParser: (item) => {
        return {
          options: parseToDropdown(getOptions(item?.status, item, () => {
            refetchPlanList()
          }, undefined, () => {
            refetchPlanList()
          })), 
          mainBtnConfig: {
            text: 'View plan', 
            onClick: () => {
              openViewPlanModal(() => {
                refetchPlanList()
              }, item)
            }
          }
        }
      },
      colSpan,
      type: 'selector',
      className: 'justify-content-end align-items-center text-secondary',
      id: 'buttons'
    }
  }


  // const getParentField = (colSpan = 3) => {
  //   return {
  //     render: (item) => {
  //       return <span>Parent</span>
  //     },
  //     colSpan,
  //     className: 'justify-content-end align-items-center text-secondary',
  //     id: 'status'
  //   }
  // }
  const getCountryField = (colSpan = 3) => {
    return {
      render: (item) => {
        const country = {
          id: item?.aditionalInfo?.studentCountryId,
          code: item?.aditionalInfo?.studentCountryCode,
          name: item?.aditionalInfo?.studentCountryName
        }
        return <CountryFlag className={'w-100'} country={country}/>
        /**<span>{item?.aditionalInfo?.studentCountryName}</span>**/
      },
      colSpan,
      className: 'justify-content-center align-items-center text-secondary',
      id: 'status'
    }
  }

  return useMemo(() => {
    if(breakWidth === 'SM') {
      return [
        getCompactAvatarField(8),
        getOptionsField(3)
      ]
    }
    else if(breakWidth === 'MD') {
      return [
        getCompactAvatarField(6),
        getApplicationYearField(3),
        getOptionsField(3)
      ]
    }
    return [
      getAvatarField(2),
      isAtomic(1),
      getCountryField(1),
      getTypeField(2),
      getApplicationYearField(1),
      getProgressField(1),
      getStatusField(2),
      getOptionsField(3)
    ]
    // eslint-disable-next-line
  }, [breakWidth, data])
}

export default useResponsivePlanColumns
