import {useMutation, gql} from '@apollo/client'

const useIssueCheckInToStudent = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation(
                $studentId: String!
                $checkInId: String
                $reason: IssueCheckInReason!
            ) {
                issueCheckInToStudent (
                    studentId: $studentId
                    checkInId: $checkInId
                    reason: $reason
                )
            }
        `,
        {
            onCompleted: onSuccess, onError
        }
    )
}

export default useIssueCheckInToStudent