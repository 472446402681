import React, { useMemo, useEffect, useState, useRef } from 'react'
import PlanListItem from './PlanListItem'
import CardHeader from '../Components/CardHeader'
import useLessonsQuery from './Model/useLessonsQuery'
import {IconLesson} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import SkeletonListItem from './PlanListItem/Skeleton'
import WspAlert from '../../../../Calendar/modal/WspAlert'
import useTranslation from '../../../../../i18n/useTranslation'

const CurrentlyOngoingLesson = (props) => {
    const {onLoad = () => {}} = props
    const {translate} = useTranslation()
    const { data, refetch, loading } = useLessonsQuery({
        limit: 0,
        filterStatus: ['inprogress'],
        status: ['scheduled']
    }, () => {
        onLoad()
    })

    const lessons = useMemo(() => data?.lessons?.docs || [], [data])

    const [count, setCount] = useState(0)
    useEffect(
        () => {
            if (count > 0) {
                //ejecuta cada minuto
                refetch()
            }
            const timer = setTimeout(() => {
                setCount(count + 1)
            }, 60*1000)
            return () => { clearTimeout(timer) }
            //eslint-disable-next-line
        }, [count]
    )

    const searchRef = useRef(null)

    const parseLessons = useMemo(() => { // separa arreglo lessons en grupos de 4
        if (lessons?.length > 0) {
            const groups = Math.ceil(lessons.length / 4)
            const newLessons = []
            for (let i = 0; i < groups; i++) {
                newLessons.push(lessons.slice(4 * (i), 4 * (i + 1)))
            }
            return newLessons
        }
        return []
    }, [lessons])


    return (
        <>
            {/* Currently on going lessons */}
            <CardHeader 
                innerInfo={translate("dashboard.currentlyOnGoingLessons.info")}
                onReload={() => {refetch()}} loading={loading}
                className={`secondaryBackground`} searchRef={searchRef} 
                title={'Currently on going lessons'} />
            <div className={`w-100 znk-scroll-horizontal`}>
                {/* <button onClick={() => {setActiveTab(!activeTab)}}>CLICK</button> */}
                <div className='p-0 m-0 d-flex flex-row'
                    style={{ backgroundColor: '#f8f9fa', width: lessons?.length > 4 && false ? `${(Math.ceil(lessons?.length/2))*50}%` : `100%` }}
                > 
                    <TabContent className={`w-100`} activeTab={loading ? 'loading' : parseLessons?.length > 0 ? `show` : `blank`}>
                        <TabPane className={`w-100`} tabId={`loading`}>
                            <div className={`px-4`}>
                            {
                                new Array(5).fill(0).map(
                                    () => {
                                        return (
                                            <SkeletonListItem className={`mb-2`}/>
                                            )
                                        }
                                )
                            }
                            </div>
                        </TabPane>
                        <TabPane className={`w-100`} tabId={`show`}>
                            <div className={`px-4`}>
                            {
                                lessons.map((lesson, index) => {
                                    return (
                                        <PlanListItem
                                            lesson={lesson}
                                            className={`col-12 ${index % 2 === 0 ? `secondaryBackground` : ''}`}
                                        />
                                    )
                                })
                            }
                            </div>
                        </TabPane>
                        <TabPane className={`w-100`} tabId={`blank`}>
                            <div className={`d-flex flex-row justify-content-center w-100`} style={{height: '200px'}}>
                                <div className={`d-flex flex-column justify-content-center align-items-center text-center`}>
                                    <IconLesson size={40} className={`text-disabled`}/>
                                    <label className={`h45 mt-4 text-gray`}>No current lessons</label>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </div>

                <WspAlert/>
            </div>
        </>
    )
}

export default CurrentlyOngoingLesson