import React from 'react'
import {
  Input,
  IconCountry
} from '@Knowledge-OTP/znk-ui-components'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import { useState } from 'react'

const ProgramSentPlan = (props) => {
  const { onProgram = () => { } } = props
  const currentTimezone = useSelector((state) => state.user.timezone)
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)
  const {translate, replaceVariables} = useTranslation()
  const titleLabel = translate('plans.modal.sendplan.programSentPlan.title')
  const applyLabel = translate('plans.modal.sendplan.programSentPlan.apply')
  const timezoneLabel = replaceVariables(translate('plans.modal.sendplan.programSentPlan.currentTimezone'), {'TIMEZONE': currentTimezone})
  return (
    <div className='p-2'>
      <div className='w-100 d-flex flex-column align-items-start'>
        <label className='h4 text-info font-weight-bold'>{titleLabel}</label>
        {/* translate('plans.modal.sendplan.button') */}
        <Input
          className={'w-100 my-4'}
          type={'date'}
          size={'small'}
          value={date ? moment(date).format('YYYY-MM-DD') : null}
          onChange={(e) => {
            setDate(e.target.value)
          }}
        />
        <Input
          className='m-0 w-100'
          type='time'
          step="3600"
          value={time}
          onChange={(e) => {
            const newtime = e.target.value
            const hour = newtime.split(':')[0]
            setTime(`${hour}:00`)
          }}
        />
      </div>
      <div className='w-100 d-flex flex-row justify-content-end py-2'>
        <span className='text-gray h45 d-flex flex-row align-items-center'>
          <IconCountry size={12} className={'mr-1'} />
          {timezoneLabel}
        </span>
      </div>
      <div className='w-100 d-flex flex-row justify-content-end mt-2'>
        <span className='h4 btn-link text-primary' onClick={() => { 
          const hour = time.split(':')[0]
          const sendDate = moment(date).tz(currentTimezone).set({hour: parseInt(hour)}).startOf('hour').toISOString()
          onProgram(sendDate) 
        }}>{applyLabel}</span>
      </div>
    </div>
  )
}

export default ProgramSentPlan