import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  Button,
  CarouselFrame,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
// import {usePlaidLink} from 'react-plaid-link'
import zelleIconBw from '../../../../../assets/zelle-icon-bw.png'
import bankIcon from '../../../../../assets/bank.png'
import creditCardIcon from '../../../../../assets/credit-card-icon.png'
import useGetRegisteredPaymentMethodsUser from '../../Model/useGetRegisteredPaymentMethodsUser'
import { iconVisa, iconMastercard, iconDiscover, iconDiners, iconAmex, iconCardUndefined } from '../../../../../common/Icons/icons'
import useSetManualPaymentMethod from '../../Model/useSetManualPaymentMethod'
import useSetZellePaymentMethod from '../../Model/useSetZellePaymentMethod'
import useSetCreditCardDefaultPaymentMethod from '../../Model/useSetCreditCardDefaultPaymentMethod'
import AddNewCreditCard from './Modal/AddNewCreditCard'
import { showAlert } from '../../../../../common/Alert/util'

const paymentMethodKeys = {
  Card: 'CardPaymentMethod',
  BankDebit: 'BankDebitPaymentMethod',
  Manual: 'ManualPaymentMethod',
  BankCredit: 'BankCreditPaymentMethod',
  Zelle: 'ZellePaymentMethod',
}

const ChangePaymentMethod = () => {
  const { isOpen, userId, paymentMethod, refetch } = useSelector(
    (state) => state.modal.payments.changePaymentMethod
  )

  const dispatch = useDispatch()

  const {
    data: ownerPaymentMethod,
    refetch: refetchOwnerPaymentMethod,
  } = useGetRegisteredPaymentMethodsUser({
    variables: { id: userId },
    skip: !userId
  }
  )

  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()

  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.changePaymentMethod',
      data: {
        isOpen: !isOpen,
      },
    })
  }

  const togggleAdNewCreditCard = () => {
    refetchOwnerPaymentMethod()
    setOpenAddNewCreditCard(!openAddNewCreditCard)
  }

  // // PLAID
  // const keyPlaid = 'keyPlaid'
  // const onSuccess = useCallback((token, metadata) => {
  //   // send token to server
  //   mutationSetBankDebitData({
  //     variables: {
  //       accountId: metadata.accounts[0].id,
  //       plaidPublicToken: token,
  //       institutionId: metadata.institution.institution_id,
  //     },
  //   })
  //   // eslint-disable-next-line
  // }, [])

  // const config = {
  //   token: keyPlaid,
  //   onSuccess,
  //   // ...
  // }

  // const { open } = usePlaidLink(config)
  const [paymentMethods, setPaymentMethods] = useState([
    {
      // CREDIT CARD
      value: paymentMethodKeys.Card,
      text: translate('invoice.change.paymentmethod.add.credit'),
      icon: <img src={creditCardIcon} alt='credit card' width={24} />
    },
    {
      // TRANSFER
      value: paymentMethodKeys.Manual,
      text: translate('invoice.change.paymentmethod.transferNonUsd'),
      icon: <img src={bankIcon} width='24' alt='bank' />
    },
    {
      // ZELLE
      value: paymentMethodKeys.Zelle,
      text: translate('invoice.change.paymentmethod.zelle'),
      icon: <img src={zelleIconBw} width='24' alt='zelle' />
    }
  ])

  useEffect(() => {
    if (ownerPaymentMethod?.registeredPaymentMethods?.length > 0) {
      const uniquePaymentMethods = [
        ...new Map(
          [
            ...paymentMethods,
            ...ownerPaymentMethod.registeredPaymentMethods.map((userMethod) => ({
              value: userMethod?.id,
              text: `**** **** **** ${userMethod?.cardInfo?.last4} ${userMethod?.cardInfo?.expMonth}/${userMethod?.cardInfo?.expYear}`,
              icon: (
                <img
                  src={getIconCard(userMethod?.cardInfo?.brand)}
                  alt='credit-card'
                  width={24}
                />
              ),
            })),
          ].map((method) => [method.value, method])
        ).values()
      ];
      setPaymentMethods(uniquePaymentMethods);
    }
    // eslint-disable-next-line
  }, [ownerPaymentMethod]);

  const getIconCard = (brand) => {
    switch (brand) {
      case 'visa':
        return iconVisa;
      case 'mastercard':
        return iconMastercard;
      case 'diners':
        return iconDiners;
      case 'amex':
        return iconAmex;
      case 'discover':
        return iconDiscover;
      default:
        return iconCardUndefined;
    }
  };

  const [currentMethod, setCurrentMethod] = useState(paymentMethod)
  const [openAddNewCreditCard, setOpenAddNewCreditCard] = useState(false)

  useEffect(() => {
    if (isOpen) {
      if (paymentMethod?.id) {
        setCurrentMethod(paymentMethod?.id);
        return
      }
      setCurrentMethod(paymentMethod?.__typename)
    }// eslint-disable-next-line
  }, [isOpen])

  const [
    setManualPaymentMethod,
    { loading: loadingManualPaymentMethod},
  ] = useSetManualPaymentMethod(
    () => {
      toggle()
      refetch()
    },
    (error) => {
      showAlert({
        text: `Error setting up manual payment: ${error.message}`,
        status: 'error'
      });
    }
  )

  const [
    setZellePaymentMethod,
    { loading: loadingZellePaymentMethod },
  ] = useSetZellePaymentMethod(
    () => {
      toggle()
      refetch()
    },
    (error) => {
      showAlert({
        text: `Error setting up zelle payment: ${error.message}`,
        status: 'error'
      });
    }
  )

  const [
    setCreditCardDefaultPaymentMethod,
    { loading: loadingCreditCardDefaultPaymentMethod }
  ] = useSetCreditCardDefaultPaymentMethod(
    () => {
      toggle()
      refetch()
    },
    (error) => {
      showAlert({
        text: `Error setting up manual payment: ${error.message}`,
        status: 'error'
      });
    }
  )

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex mb-0 px-5'>
            <div className='d-flex flex-column justify-content-start mr-4'>
              <span className='text-title font-weight-bold h2'>
                {translate('invoice.change.paymentmethod.changeMethod')}
              </span>
            </div>
            <div className='pt-1'>
              <IconClose
                className='d-flex align-self-start'
                size={20}
                onClick={toggle}
              />
            </div>
          </div>
        }
      >
        <div className='m-0 p-0 mx-5 mt-0'>
          <div className='m-0 px-0 pb-4 pt-0'>
            {paymentMethods.map((method) => (
              <ListOption
                className={`mb-2 ${
                  method.value === currentMethod
                    ? 'selected-secondary'
                    : ''
                }`}
                id='x-0'
                label={null}
                icon={
                  <div
                    title={`${method.text}`}
                    className='d-flex align-items-center text-muted m-0'
                  >
                    {method.icon !== '' && method.icon}
                    <span
                      className='ml-2 text-end'
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {method.text}
                    </span>
                  </div>
                }
                onClick={() => {
                  setCurrentMethod(method.value);
                }}
              />
            ))}
          </div>
          {currentMethod !== paymentMethod && (
            <CarouselFrame type='animation'>
              <span
                style={{ whiteSpace: 'nowrap' }}
                title={translate('invoice.change.paymentmethod.confirmChangeMethodMsg')}
                className='text-error h45 mb-4'
              >
                {translate('invoice.change.paymentmethod.confirmChangeMethodMsg')}
              </span>
            </CarouselFrame>
          )}
          <Button
            condition
            label={translate('invoice.change.paymentmethod.changeMethod')}
            style={{
              backgroundColor: '#FFB519',
              border: 'none',
              width: '100%',
            }}
            onClick={() => {
              switch (currentMethod) {
                // case paymentMethodKeys.BankDebit:
                //   open();
                //   break;
                case paymentMethodKeys.Manual:
                  setManualPaymentMethod({ variables: { userId } });
                  break;
                case paymentMethodKeys.Zelle:
                  setZellePaymentMethod({ variables: { userId } });
                  break;
                case paymentMethodKeys.Card:
                  setOpenAddNewCreditCard(true);
                  break;
                default:
                  // eligio una tarjeta de credito
                  setCreditCardDefaultPaymentMethod({
                    variables: {
                      userId,
                      paymentMethodId: currentMethod,
                    },
                  });
                  break;
              }
            }}
            color='primary'
            disabled={
              loadingManualPaymentMethod ||
              loadingZellePaymentMethod ||
              loadingCreditCardDefaultPaymentMethod
            }
            activity={
              loadingManualPaymentMethod ||
              loadingZellePaymentMethod ||
              loadingCreditCardDefaultPaymentMethod
            }
          />
        </div>
      </Modal>
      <AddNewCreditCard
        isOpen={openAddNewCreditCard}
        toggle={togggleAdNewCreditCard}
        refetch={refetch}
        userId={userId}
        registeredPaymentMethods={ownerPaymentMethod?.registeredPaymentMethods || []}
      />
    </>
  );
}

ChangePaymentMethod.propTypes = {}

ChangePaymentMethod.defaultProps = {}

export default ChangePaymentMethod
