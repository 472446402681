import React from 'react'
import moment from 'moment-timezone'

const getDateLimits = (parts) => {
  const planStartUnixTime = parts.reduce(
    (num, item) => (moment(item.from).valueOf() < num ? moment(item.from.split('T')[0]).valueOf() : num),
    Number.POSITIVE_INFINITY
  )
  const planEndUnixTime = parts.reduce(
    (num, item) => (moment(item.to).valueOf() < num ? moment(item.to.split('T')[0]).valueOf() : num),
    Number.POSITIVE_INFINITY
  )
  return [planStartUnixTime, planEndUnixTime].map(item => moment(item))
}
const PlanDurationField = (props) => {
  const { parts, fixedParts } = props
  const [startMoment] = getDateLimits(parts || fixedParts)
  return startMoment.format('MMM YYYY')
}
export const PlanDurationHover = (props) => {
  const { parts, fixedParts } = props
  const [startMoment, endMoment] = getDateLimits(parts || fixedParts)
  return (
    <div className='m-0 px-4 py-2 d-flex flex-column'>
      <label className='align-self-center font-weight-normal text-title' style={{ lineHeight: '1.571' }}>{startMoment.format('MMMM YYYY')}</label>
      <label className='align-self-center text-muted' style={{ fontSize: '12px', lineHeight: '1.5' }}>
        {
          `${startMoment.format('MMM Do, YYYY')} to ${endMoment.format('MMM Do, YYYY')}`
        }
      </label>
    </div>
  )
}
export default PlanDurationField
