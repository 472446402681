import {gql, useQuery} from '@apollo/client'

const useTotalPayslipsQuery = (vars = {}) => {
    return useQuery(
        gql`query(
            $userId: [String!],
            $from: DateTime,
            $to: DateTime,
            $status: [PaymentStatus!]
            $searchString: String,
            $pending: Boolean,
            $current: Boolean
          ) {
            totalPayslips(
              status: $status, 
              userId: $userId,
              from: $from,
              to: $to,
              searchString: $searchString
              pending: $pending,
              current: $current
            ) {
              totalAmount
              totalEducators
            }
          }`,
        {
            variables: vars,
            fetchPolicy: 'cache-first'
        }
    )
}

export default useTotalPayslipsQuery