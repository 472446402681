import React, { useState } from 'react'
import { IconSearch, Input } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import ParentItem from './ParentItem/parentItem'

const ParentSearchBox = (props) => {
  const { selectedValues, addItem, onSuccess, users, loading } = props
  const selectedObj = selectedValues.reduce((obj, parent) => ({ ...obj, [parent.parentUserId]: true }), {})
  const [search, setSearch] = useState('')
  return (
    <div className='d-flex flex-column mt-4'>
      <Input
        type='addon'
        className='my-1'
        addOnComponent={<IconSearch className='text-gray' />}
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
      />
      <span className='d-flex flex-column my-4' style={{ maxHeight: '320px', overflowY: 'auto' }}>
        {
          loading
            ? <Skeleton height={20} count={4} className='my-2' />
            : users && users.docs && users.docs.filter((user) => new RegExp(search, 'i').test(`${user.firstName} ${user.lastName}`)).map((user) => {
              const { id, firstName, lastName } = user
              return (selectedObj[id] ? null
                : <ParentItem key={id} {...{ firstName, lastName, id, addItem, onSuccess }} />
              )
            })
        }
      </span>
    </div>
  )
}

export default ParentSearchBox
