import React, { useMemo } from 'react'
import { CheckboxSelectorInner } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import useCountries from '../../../Model/Countries/List'

const CountriesFilter = (props) => {
  const { translate } = useTranslation()
  const { countryFilters, setCountryFilters, className, style = {} } = props
  const { data: countries, loading: countriesLoading } = useCountries({ limit: 0 })
  const baseCountries = useMemo(
    () => (!countriesLoading && countries && countries.docs ? countries.docs.map((country) => country.id) : []),
    [countries, countriesLoading]
  )
  const countriesArray = useMemo(() => countries && countries.docs ? countries.docs : baseCountries, [countries, baseCountries])
  
  return (
    <CheckboxSelectorInner
      className={className}
      setDataFilters={setCountryFilters}
      direction='bottomCenter'
      dataFilters={countryFilters}
      data={countriesArray.map(item => ({ name: item.name, id: item.id })).sort((a, b) => a.name.localeCompare(b.name))}
      typeFilter='countries'
      strings={{
        viewAll: translate('filters.plans.viewAll'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search')
      }}
      style={style}
    />
    //   {/* <ListItem
    //     className='roundedItem'
    //     Left={<label>{translate('filters.plans.countriesTitle')}</label>}
    //     Right={<IconArrowRight size={15} />}
    //   />
    // </CheckboxSelectorInner> */}
  )
}
export default CountriesFilter
