import {
    Field,
    Form,
    LabeledField,
    PlainTextInput,
    Button,
    ModalLayout,
    SelectUser,
    PhoneSelector,
    SelectOption,
    CheckboxOption
} from '@Knowledge-OTP/znk-ui-components'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { FormGroup, Label, Input, TabContent, TabPane } from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'
import addSetParentsToStudent from '../../../../Model/Users/Student/addSetParentsToStudent'
import useCreateParentModal from './useCreateParentModal'
import { UseAllUsers } from '../../../Plans/modal/newPlan/Model/UseAllUsersLazy'
import { ordenateUserArray } from '../../Utils/ordenateUsersArray'
import { parseCountrySelector } from '../../Utils/parseCountriesArray'
import Select from 'react-select'
import { showAlert } from '../../../../common/Alert/util'
import Create from '../../../Educator/Model/useCreateEducatorOrStaff'

const CreateParentModal = (props) => {
    // eslint-disable-next-line
    const { countries, refetch } = props
    const [selectUser, setSelectUser] = useState('')
    const [selectCode, setSelectCode] = useState('')
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const [getStudent, { loading: loadingStudents, data: dataStudents, refetch: refetchAllStudents }] = UseAllUsers()
    // eslint-disable-next-line
    const { isOpen, toggle, onChange: refetchParent, ...formData } = useCreateParentModal()
    // eslint-disable-next-line
    const { submit: submitCreateParentWithStudent, loading: loadingCreateParent } = addSetParentsToStudent((result) => {
        showAlert({
            text: translate('modal.newParent.success'),
            status: 'success'
          }, dispatch)
          refetch()
          toggle()
    }, (err) => {
        showAlert({
            text: err.toString(),
            status: 'error'
        }, dispatch)
    })

    const { submit: createOnlyParent, loading: loadingOnlyParent } = Create((result) => {
        showAlert({
            text: translate('modal.newParent.success'),
            status: 'success'
        }, dispatch)
        refetch()
        toggle()
    }, (err) => {
        showAlert({
            text: err.toString(),
            status: 'error'
        }, dispatch)
    })
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        countryId: '',
        countryName: '',
        mobileCode: '',
        mobileNumber: '',
        managePayments: false,
        manageApprovals: false,
        childrens: [],
        isNewParent: true,
        isDelete: false,
        gender: '',
        timezone: '',
    })
    const [countriesList, setCountriesList] = useState([])
    const { translate } = useTranslation()

    useEffect(() => {
        setCountriesList(parseCountrySelector(countries?.docs))
    }, [countries])
    // eslint-disable-next-line
    const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: '#F0F4F8',
            // match with the menu
            borderRadius: 12,
            height: 44,
            // Overwrittes the different states of border
            borderColor: 'transparent',
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                // Overwrittes the different states of border
                borderColor: 'transparent'
            }
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        })
    }
    const enableSaveButton = () => {
        return (
            form?.firstName !== '' &&
            form?.lastName !== '' &&
            form?.email.match(regexEmail) &&
            form?.countryId !== '' 
            // &&
            // form?.mobileCode !== '' &&
            // form?.mobileNumber?.length > 5 &&
            // selectUser !== ''
        )
    }
    const createParentFunction = () => {
        if (selectUser && selectUser !== '') {
        let parent = {
            firstName: form?.firstName,
            lastName: form?.lastName,
            email: form?.email,
            countryId: form?.countryId,
            phone: selectCode.trimEnd() + ' ' + form?.mobileNumber,
            managePayments: false,
            manageApprovals: false,
            isNewParent: true,
            isDelete: false
        }
        submitCreateParentWithStudent({ input: { parents: [parent] }, userStudentId: selectUser, sendEmail: form?.sendInvite })
    } else {
        let user = {
            firstName: form?.firstName,
            lastName: form?.lastName,
            email: form?.email,
            countryId: form?.countryId,
            gender: form?.gender,
            timezone: form?.timezone,
            phone: selectCode.trimEnd() + ' ' + form?.mobileNumber,
            
            // isAdmin: shortcuts?.staff
        }
        createOnlyParent({ user, typeUser: 'PARENT', sendEmail: form?.sendInvite  })
    }
    }
    useEffect(() => {
        getStudent({
            variables: {
                limit: 0,
                roles: ['STUDENT'],
                sortField: 'firstName'
            }
        })
    }, [getStudent])

    return (
        <div className='w-100 py-5 px-8 text-title' style={{ overflowY: 'scroll' }}>
            <ModalLayout
                className='modal-centered'
                dimensionClassName='m-0 pr-5 py-3 pl-3'
                centerHeaderOverall='w-100'
                isOpen={isOpen}
                toggle={toggle}
                topHead={
                    <span className={`text-title font-weight-bold h2`}>New Parent</span>
                }
            >
                <TabContent activeTab={loadingStudents ? 'loading' : 'show'} className='px-2'>
                    <TabPane tabId='loading'>
                        <>
                            <div className='mt-8 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>
                                    <div className='col-6 pr-0'>
                                        <Skeleton count={1} width={150} height={44} />
                                    </div>
                                    <div className='col-6 pl-1'>
                                        <Skeleton count={1} width={150} height={44} />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>
                                    <Skeleton count={1} width={300} height={44} />
                                </div>
                            </div>
                            <div className='mt-5 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>
                                    <div className='col-6 pr-0'>
                                        <Skeleton count={1} width={150} height={44} />
                                    </div>
                                    <div className='col-6 pl-1'>
                                        <Skeleton count={1} width={150} height={44} />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>

                                    <Skeleton count={1} width={300} height={44} />

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 pr-1'>
                                    <Skeleton count={1} width={150} height={30} />
                                </div>
                                <div className='col-6 pr-1'>
                                    <Skeleton count={1} width={150} height={44} />
                                </div>
                            </div>
                            <div className='mt-5 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>
                                    <Skeleton count={1} width={300} height={44} />
                                </div>
                            </div>
                        </>
                    </TabPane>
                    <TabPane tabId='show'>
                        <Form value={form} onChange={setForm}>

                            <FormGroup className='mt-5 mb-0'>
                                <Label>Parent name</Label>
                                <div className='row'>
                                    <div className='col-6 pr-0'>
                                        <Field
                                            type={LabeledField}
                                            component={PlainTextInput}
                                            placeholder={translate(
                                                'profile.actions.editPersonalInfo.firstName'
                                            )}
                                            style={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0
                                            }}
                                            labelContainerClassName='mb-0'
                                            onChange={(e) => {
                                                setForm({
                                                    ...form,
                                                    firstName: e
                                                })
                                            }}
                                            value={form?.firstName}
                                        />
                                    </div>
                                    <div className='col-6 pl-1'>
                                        <Field
                                            type={LabeledField}
                                            component={PlainTextInput}
                                            placeholder={translate(
                                                'profile.actions.editPersonalInfo.lastName'
                                            )}
                                            style={{
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0
                                            }}
                                            labelContainerClassName='mb-0'
                                            value={form?.lastName}
                                            onChange={(e) => {
                                                setForm({
                                                    ...form,
                                                    lastName: e
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className='mt-2'>
                                <Label>
                                    {translate('profile.actions.editPersonalInfo.email')}
                                </Label>
                                <Field
                                    type={LabeledField}
                                    component={PlainTextInput}
                                    value={form?.email}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            email: e
                                        })
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className='mt-2'>
                                <Label>Gender</Label>
                                <Field
                                    className='row col-12'
                                    fieldName='gender'
                                    fieldType='date'
                                    type={SelectOption}
                                    options={[
                                        { id: 'male', text: 'Male' },
                                        { id: 'female', text: 'Female' },
                                        { id: 'other', text: 'Other'},
                                    ]}
                                    value={form?.gender}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            gender: e
                                        })
                                    }}
                                    OptionClassName='col-12'
                                />
                            </FormGroup>
                            <FormGroup className='mt-2'>
                                <Label>{translate('profile.actions.editPersonalInfo.phone')}</Label>
                                <div className='row'>
                                    <div className='col-6 pr-1'>
                                        <PhoneSelector
                                            defaultValue={form?.mobileCode || ''}
                                            selectCode={selectCode}
                                            setSelectCode={setSelectCode}
                                        />
                                    </div>
                                    <div className='col-6 pl-0'>
                                        <Field
                                            type={LabeledField}
                                            component={PlainTextInput}
                                            fieldName='phoneNumber'
                                            placeholder='12345621'
                                            value={form?.mobileNumber}
                                            onChange={(e) => {
                                                setForm({
                                                    ...form,
                                                    mobileNumber: e
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className='mt-2'>
                                <Label>
                                    {translate('profile.actions.editPersonalInfo.country')}
                                </Label>
                                <Select
                                    styles={customStyles}
                                    options={countriesList}
                                    value={form?.countryName}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            countryId: e.value,
                                            countryName: e.name
                                        })
                                    }}
                                />
                            </FormGroup>
                            <div className='row'>
                                <div className='col-6 pr-1'>
                                    <FormGroup check className='ml-1'>
                                        <Label check>
                                            <Input
                                                type='checkbox'
                                                fieldName='managePayments'
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        managePayments: e
                                                    })
                                                }}
                                            />{' '}
                                            Manage payments
                                        </Label>
                                    </FormGroup>
                                </div>
                                <div className='col-6 pr-1'>
                                    <FormGroup check className=''>
                                        <Label check>
                                            <Input
                                                type='checkbox'
                                                fieldName='managePayments'
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        manageApprovals: e
                                                    })
                                                }}
                                            />{' '}
                                            Manage approvals
                                        </Label>
                                    </FormGroup>
                                </div>
                            </div>
                            <FormGroup className='mt-3'>
                                <Label>Link Student</Label>
                                {dataStudents?.allUsers?.docs.length > 0 && <SelectUser
                                    className={'my-0'}
                                    activeUsers={dataStudents?.allUsers?.docs}
                                    selectUser={selectUser}
                                    setSelectUser={setSelectUser}
                                    ordenateUserArray={ordenateUserArray}
                                />}
                            </FormGroup>
                            <FormGroup className='mt-2'> 
                                <div className='d-flex col-12'>
                                <Field
                                    type={CheckboxOption}
                                    fieldName='sendInvite'
                                    defaultState={form.sendInvite}
                                />
                                <label className='text-muted h45'>Send email invite?</label>
                                </div>

                            </FormGroup>
                            <Button
                                label='Add New Parent'
                                color='primary'
                                check
                                size='medium'
                                onClick={() => { createParentFunction() }}
                                disabled={!enableSaveButton()}
                                activity={loadingCreateParent || loadingOnlyParent}
                                style={{ width: '100%' }}
                                className='mt-5'
                            />
                        </Form>
                    </TabPane>
                </TabContent>


            </ModalLayout>
        </div>
    )
}

export default CreateParentModal
