import {useDispatch, useSelector} from 'react-redux'

const useSetStatusModalConfirm = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.setStatusConfirm)
    const {isOpen} = reduxData
    const setData = (open = false, dataPlan = null, onChange = () => {}, newStatus = null, useMutation = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.setStatusConfirm',
            data: {
                ...reduxData,
                isOpen: open,
                dataPlan,
                useMutation,
                newStatus,
                onChange
            }
        })
    }

    return {
        ...reduxData,
        open: (onChange = () => {}, dataPlan = null, newStatus = 'postApplication', useMutation = false) => {
            setData(true, dataPlan, onChange, newStatus, useMutation)
        },
        close: () => {
            setData(false)
        },
        toggle: () => {
            setData(!isOpen)
        }
    }
}

export default useSetStatusModalConfirm