import {useLazyQuery, gql} from '@apollo/client'

const useLazyGetPdfTemplate = () => {
    return useLazyQuery(
        gql`
            query (
                $templateId: ID
                $templateKey: ID
            ) {
                getPdfTemplate (
                    templateId: $templateId
                    templateKey: $templateKey
                ) {
                    id
                    name
                    json
                    key
                }
            }
        `
    )
}

export default useLazyGetPdfTemplate