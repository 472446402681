import plansQueryResult from '../../../../../../Model/QueryResult/usePlansQueryResult'
const planResult = `
        ${plansQueryResult}
        ... on TimelinePlan {
            testDate
            timelineId {
                _id
            }
            parts {
                lessonPerWeekPerTopic {
                    topicId
                    lessonsPerWeek
                    lockLessons
                    totalLessons
                }
                lessonsPerWeek
                totalLessons
                from
                to
                name
            }
        }
    `


export default planResult