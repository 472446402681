import React, { useEffect } from 'react'
import {
    TemplateTimeline,
    Task
} from '@Knowledge-OTP/znk-ui-components'
import useTemplateReducer from '../../reducers/template'
import useTaskReducer from '../../reducers/task'

const ReducerTestView = () => {
    const {state: template, addStep, addTask, removeStep} = useTemplateReducer()
    const {state: task, setToPending, overwriteTask, setToCanceled} = useTaskReducer(false)
    const {state: tasks, ...multiFunctions} = useTaskReducer(true)
    useEffect(() => {
        multiFunctions.setData([
            {
                taskId: '1',
                taskName: 'Task Pending',
                status: 'pending'
            },
            {
                taskId: '2',
                taskName: 'Task Canceled',
                status: 'canceled'
            },
            {
                taskId: '3',
                taskName: 'Task Draft',
                status: 'draft'
            },
        ])
        // eslint-disable-next-line
    }, [])
    
    return (
        <>
            <TemplateTimeline
                className='w-50'
                isEdit
                onDelete={({phaseId, stepId}) => {
                    removeStep({phaseId, stepId})
                }}
                onEdit={({phaseId, stepId}) => {}}
                onCreate={({phaseId}) => {
                    addStep({phaseId, title: "New Step", subtitle: "Subtitle", tasks: []})
                }}
                onCreateTask={({phaseId, stepId}) => {
                    addTask({phaseId, stepId, title: "New Task", type: "exam"})
                }}
                stages={template.phases}
            />
            <Task
                taskClassName={'w-25'}
                labelLeft={task?.taskName || 'No defined'}
                labelRight={task?.status || 'No defined'}
                onClick={() => {setToPending()}}
                isCounseling
            />
            <div className={`d-flex flex-row my-2`}>
                <span onClick={() => {setToPending()}}>setToPending</span>
                <span className={`mx-3`} onClick={() => {setToCanceled()}}>setToCanceled</span>
                <span onClick={() => {overwriteTask({taskName: 'Edit name'})}}>overwriteTask</span>
            </div>

            <div className={`d-flex flex-column w-25`}>
                {
                    tasks.map((task) => {
                        return (
                            <Task
                                labelLeft={task?.taskName || 'No defined'}
                                labelRight={`${task?.status || 'No defined'}`}
                                onClick={() => {
                                    multiFunctions.setToPending(task.taskId)
                                }}
                                isCounseling
                            />
                        )
                    })
                }
                <span onClick={() => {multiFunctions.addTask({taskName: 'Nueva tarea', status: 'draft'})}}>Create task</span>
            </div>
        </>
    )
}

export default ReducerTestView