import React from 'react'
import { 
    ListItem, 
    IconArrowRight,
    ToolTipInfo,
    IconLoad
} from '@Knowledge-OTP/znk-ui-components'
import { nanoid } from 'nanoid'


const ListOption = ({
    Icon, 
    IconRight = IconArrowRight, 
    left, 
    onClick, 
    iconClass = '', 
    status = 'primary', 
    disableTooltip = true,
    tooltipText = 'No message',
    loading = false,
    ...otherProps
}) => {
    //const color = status === 'primary' ? status : status === 'danger' ? 'red' : status
    const colorText =  
        status === 'primary' ? 
            'text-primary' : 
            status === 'danger' ? 
                'text-error' : 
                status === 'title' ?
                    'text-title' :
                    status === 'disabled' ?
                        'text-gray' :
                        status
    return (
        <ListItem   
            hasHover={status !== 'disabled'}
            className={`py-4 px-2 hover-no-border ${status === 'disabled' ? 'cursor-not-allowed' : ''}`}
            onClick={() => {if(onClick && status !== 'disabled') onClick()}}
            Left={
                <div className='d-flex flex-row'>
                    <Icon className={`align-self-center mr-2 ${colorText} ${iconClass}`} />
                    <span className={`align-self-center ${colorText}`}>{left}</span>
                </div>
            }
            Right={
                <ToolTipInfo
                    tooltipId={nanoid()}
                    disable={disableTooltip}
                    innerElement={<span className={'text-white'}>{tooltipText}</span>}
                >
                    <div className={`hover-icon`}>
                        {
                            loading ? 
                                <IconLoad/> :
                                <IconRight/>
                        }
                    </div>
                </ToolTipInfo>
            }
            {...otherProps}
        />
    )
}

export default ListOption