import React from 'react'
import {ListItem, IconLink} from '@Knowledge-OTP/znk-ui-components'
import useCopyText from '../../../../../../../common/CopyText/useCopyText'
import { showAlert } from '../../../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'

const CopyStripeId = (props) => {
    const {stripeId} = props
    const dispatch = useDispatch()
    const [, setCopyText] = useCopyText()
    const onCopyText = () => {
        showAlert({text: 'Copy text!', status: 'success'}, dispatch)
    }
    const onCopyError = () => {
        showAlert({text: 'Error!', status: 'error'}, dispatch)
    }
    return (
        <ListItem
            showBottomLine
            showTopLine
            className={'w-100'}
            Left={<span className='text-gray'>Stripe Id: </span>}
            Right={
                <div onClick={() => { setCopyText(stripeId, onCopyText, onCopyError) }} className='d-flex flex-row align-items-center'>
                    <span>{stripeId}</span>
                    <div className='hover-icon ml-1'><IconLink /></div>
                </div>
            }
        />
    )
}

export default CopyStripeId