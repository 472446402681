import React from 'react'
import {Input} from '@Knowledge-OTP/znk-ui-components'

const InputText = (props) => {
    const {value = '', label = 'Enter text', onChange = () => {}, className } = props
    return (
        <div className={`d-flex flex-column ${className}`}>
            <span className={`w-100`}>{label}</span>
            <Input 
                className={`w-100`} 
                type={'text'}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export default InputText