import React, { useState, useMemo, useEffect } from 'react'
import CalendarSection from './Sections/Calendar'
import {
  Avatar,
  Calendar,
  useWindowDimensions,
  IconArrowRight,
  IconArrowLeft,
  IconProgram,
  LayoutTwoSections,
  IconUser,
} from '@Knowledge-OTP/znk-ui-components'
import PendingNotification from './Components/PendingNotification'
import InfoLesson from './modal/InfoLesson'
import OpenLessonModalFromUrl from '../../common/OpenLessonModalFromUrl'
import DuplicateLesson from './modal/DuplicateLesson'
import MakeUpLesson from './modal/MakeUpLesson'
import NewLesson from './modal/NewLesson'
import SetPendingLesson from './modal/SetPendingLesson'
import RescheduledLesson from './modal/RescheduledLesson'
import PenalizeLesson from './modal/PenalizeLesson'
import EditPayment from './modal/EditPayment'
import ChangeTimezone from './modal/ChangeTimezone'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import EditLesson from './modal/EditLesson'
import WspAlert from './modal/WspAlert'
import SendEmailReminder from './modal/SendEmailReminder'
import useTranslation from '../../i18n/useTranslation'
import InfoStudent from '../Student/modal/InfoStudent'
import InfoEducator from '../Educator/modal/InfoEducator'

import useCountries from '../../Model/Countries/List'
import useQueryCalendarView from './Model/useQueryCalendarView'

import SelectUser from './Components/SelectUser'

import ScheduledConflict from './Components/ScheduledConflict'
import ConfirmDropRescheduled from './modal/ConfirmDropRescheduled'
import OrientationModal from './modal/OrientationModal'
import ResumeOrientation from './modal/ResumeOrientation'
import ConfirmSetBraincertToLesson from './modal/ConfirmSetBraincertToLesson'
import NoAuthorize from '../../common/NoAuthorize'
import ServiceSelector from './Sections/Filters/ServiceSelector'
import IncludeAllUsersBox from './Components/IncludeAllUsersBox'
import { Collapse } from 'reactstrap'


const CalendarView = () => {
  const [week, setWeek] = useState(0)
  const { translate } = useTranslation()
  const { upcomming, /**selectedUserData,**/ selectedArrayUserData } = useSelector((state) => state.modal.calendar.selectUser)
  const { staffInfo } = useSelector((state) => state.user)
  const isPermited = staffInfo?.permissions?.filter(e => e.isPermited).map(per => { return per.tag }).includes('calendar');
  const [selectUser, setSelectUser] = useState([])
  const [allIncludes, setAllIncludes] = useState(false)

  const zone = useSelector((state) => state.timezone.zone)
  const datetimes = useMemo(() => {
    const currentMoment = moment().tz(zone).add(week, 'week')
    return [
      currentMoment.clone().tz(zone).startOf('isoweek').toISOString(),
      currentMoment.clone().endOf('isoweek').toISOString(),
      //start of month
      currentMoment.clone().startOf('month').startOf('isoweek').toISOString(),
      //end of month
      currentMoment.clone().endOf('month').endOf('isoweek').toISOString(),
    ]
  }, [week, zone])
  const [
    from/**Start of week**/, to /**end of week**/,
    startMonth, endMonth
  ] = datetimes

  const defaultVariables = {
      kinds: [
        'plan-lesson',
        'individual-lesson',
        'group-lesson',
        'counselor'
      ].concat(selectUser?.length === 1 ? ['educator-unavailability'] : []),
      userIds: selectUser,// || [],
      from: startMonth,
      to: endMonth,
      serviceId: null,
      allIncludes: false
  }
  const [variables, setVariables] = useState(defaultVariables) 

  const { data, refetch: refetchCalendarQuery, loading: loadCalendar } = useQueryCalendarView(variables)

  const calendarView = useMemo(() => {
    if (data) return (data?.calendarView || []).filter(({ startDate }) => moment(startDate) >= moment(from) && moment(startDate) < moment(to))
    return []
  }, [data, from, to])

  useEffect(() => {
    if (startMonth && endMonth) {
      setVariables({
        ...defaultVariables, 
        kinds: defaultVariables.kinds.concat(selectUser?.length === 1 ? ['educator-unavailability'] : []),
        userIds: selectUser,// || '',
        from: startMonth,
        to: endMonth,
        serviceId: variables?.serviceId || null,
        allIncludes: allIncludes
      })
    }
    // eslint-disable-next-line
  }, [selectUser,
    //from, to, 
    startMonth, endMonth, allIncludes])


  const [now, currentNow] = useMemo(() => {
    return [moment(), moment().add(week, 'weeks')]
  }, [week])


  const { breakWidth } = useWindowDimensions()

  const [openSideLeft, setOpenSideLeft] = useState(false)
  const toggleSideLeft = () => { setOpenSideLeft(!openSideLeft) }
  const listLessons = calendarView?.filter((lesson) => (
    lesson.status !== 'canceled' &&
    lesson.status !== 'pending' &&
    lesson.status !== 'paused' &&
    lesson.busy)
  )

  const { data: countries } = useCountries({ limit: 0 })

  //switch para activar refetch de usuarios en componente SelectUser
  const [refetchIn, setRefetchIn] = useState(false)
  if (!isPermited) {
    return <NoAuthorize />
  }
  return (
    <>
      <LayoutTwoSections
        isOpen={openSideLeft}
        toggle={toggleSideLeft}
        disabledClasses={breakWidth === 'SM'}
        IconHide={IconArrowLeft}
        IconShow={IconArrowRight}
        className={'h-100'}
        LeftSideMax={
          <>
            <span className='h3 font-weight-bold'>{translate('lessons.calendar.title')}</span>
            <Calendar
              className='mt-5'
              theme={'red'}
              data={(data?.calendarView || []).filter((les) => les?.__typename !== 'EducatorUnavailability') || []}
              today={now}
              onToday={() => {
                setWeek(0)
              }}
              moment={currentNow}
              setMoment={(selectWeek) => {
                let addWeeks = moment(selectWeek)
                if (addWeeks.isoWeekday() > 1) { // returns 1-7 where 1 is Monday and 7 is Sunday
                  addWeeks.add(1, 'week')
                }
                setWeek(week + moment.duration(addWeeks.startOf('isoWeek').set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 }).diff(from)).asWeeks())
              }}
              viewMode='month'
              focusWeek={null}
              isOpen
              ShowBPLeft
              ShowBPRight
            />
            <div className='m-0 p-0 d-flex flex-column w-100'>
              <form method={'get'} onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
              }}>
                <SelectUser
                  isMulti={true}
                  selectUser={selectUser}
                  setSelectUser={(sel) => {
                    if(sel?.length <= 1) setAllIncludes(false)
                    setSelectUser(sel)
                  }}
                  refetchIn={refetchIn}
                  setRefetchIn={setRefetchIn}
                />
                <Collapse isOpen={selectUser?.length > 1}>
                  <IncludeAllUsersBox setAllIncludes={setAllIncludes} value={allIncludes} />
                </Collapse>
                {
                  selectUser?.length === 1 && (
                    <PendingNotification user={selectUser[0]} />
                  )
                }
                {/**Selector de servicios**/}
                <ServiceSelector
                  filters={variables?.serviceId}
                  setFilters={(serviceId) => {
                    setVariables({...variables, serviceId})
                  }}
                />
              </form>
            </div>
            
          </>
        }
        LeftSideMaxClass={'d-flex flex-column m-5 p-0 hidde-scroll-vertical'}
        LeftSideMin={
          <>
            <div className={'znk-option-item mb-3'}>
              <IconProgram size={15} />
            </div>
            <div className={'znk-option-item'}>
              {
                (selectedArrayUserData?.length > 0 && selectUser) ?
                  <Avatar
                    name={selectedArrayUserData?.length === 1 ? `${selectedArrayUserData[0]?.firstName} ${selectedArrayUserData[0]?.lastName}` : `${selectedArrayUserData?.length}`}
                    named={false}
                    uri={null}
                    size='avatar-small m-0'
                  /> : <IconUser size={15} />
              }
            </div>
          </>
        }
        LeftSideMinClass={'m-0 p-0 d-flex flex-column justify-content-start align-items-center'}
        RightSide={
          <CalendarSection
            openSideLeft={openSideLeft}
            week={week}
            setWeek={setWeek}
            from={from}
            to={to}
            refetchCalendarQuery={refetchCalendarQuery}
            upcomming={upcomming}
            loading={loadCalendar}
            lessons={listLessons}
          />
        }
        RightSideClass={`m-0 p-0 h-100`}
        RightSideStyle={{ backgroundColor: 'white' }}
      />

      
      <InfoLesson refetchCalendarQuery={refetchCalendarQuery} />
      <OpenLessonModalFromUrl />
      <DuplicateLesson refetchPendingLessons={() => {
        refetchCalendarQuery()
      }} />
      <MakeUpLesson />
      <NewLesson refetchPendingLessons={() => {
        refetchCalendarQuery()
      }} />
      <EditPayment refetchPendingLessons={() => {
        refetchCalendarQuery()
      }} />
      <SetPendingLesson refetchPendingLessons={() => {
        refetchCalendarQuery()
      }} />
      <RescheduledLesson />
      <PenalizeLesson />
      <ChangeTimezone />
      <EditLesson refetchCalendar={() => {
        refetchCalendarQuery()}} />
      <WspAlert />
      <SendEmailReminder />
      <InfoStudent refetch={() => { setRefetchIn(true) }} />
      <InfoEducator countries={countries} refetch={() => { setRefetchIn(true) }} />
      <ScheduledConflict />
      <ConfirmDropRescheduled />
      <OrientationModal refetch={refetchCalendarQuery} />
      <ResumeOrientation />
      <ConfirmSetBraincertToLesson />
    </>
  )
}

export default CalendarView
