import { gql, useQuery } from '@apollo/client'

const useGetAllOnboardingSession = (vars = {}) => {
    return useQuery(
        gql`
            query {
                getAllOnboardingSession {
                    id
                    kind
                    socketId
                    planId
                    onboardingStatus
                    plan {
                        id
                        sentDate
                        name
                        status
                        updatedAt
                        kind
                        studentUser {
                            studentUserId
                            firstName
                            lastName
                        }
                        ... on LessonsFixedPlan {
                            pendingLessons
                            fixedParts: parts {
                                TotalPerTopic {
                                    topicId
                                    lessonsPerWeek
                                    lockLessons
                                    totalLessons
                                }
                                type
                                totalLessons
                                from
                                to
                                name
                            }
                        }
                        ... on LessonsPerWeekPlan {
                            pendingLessons
                            parts {
                                lessonPerWeekPerTopic {
                                    topicId
                                    lessonsPerWeek
                                    lockLessons
                                    totalLessons
                                }
                                lessonsPerWeek
                                totalLessons
                                from
                                to
                                name
                            }
                        }
                        ... on TimelinePlan {
                            parts {
                                lessonPerWeekPerTopic {
                                    topicId
                                    lessonsPerWeek
                                    lockLessons
                                    totalLessons
                                }
                                lessonsPerWeek
                                totalLessons
                                from
                                to
                                name
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: vars
        }
    )
}

export default useGetAllOnboardingSession