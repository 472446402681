import React, { useEffect, useMemo, useState } from 'react'
import useModalState from '../../../../utils/useModalState'
import { Form, IconClose, LoadingPage, Modal } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import PlanStudent from './PlanStudent'
import { totalLessonPart } from '../../GroupPlans/modal/New/utils'
import PlanDetails from './PlanDetails'
import PlanIndividualSchedule from './PlanIndividualSchedule'
import WeeklySchedule from './WeeklySchedule'
import PlanEducators from './PlanEducators'
import SelectEducator from './PlanEducators/SelectEducator'
import PlanPricing from './PlanPricing'
import { gql, useLazyQuery } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import PaymentSchedule from './PaymentSchedule'
import moment from 'moment-timezone'
import SubmitPlan from './SubmitPlan'

const EditChildPlan = (props) => {
  const { getServiceById, refetch, educators } = props
  const { isOpen, toggle, modalData } = useModalState('plan.editChildPlan')
  const { translate } = useTranslation()
  const { info } = modalData
  
  //el plan esta clonado de otro, al final del proceso crea un nuevo plan hijo del grupal
  //const isDuplicate = useMemo(() => info?.isDuplicate || false, [info])
  
  const [form, setForm] = useState({})
  const service = useMemo(() => info ? getServiceById(info.serviceId) : null, [info, getServiceById])
  const groupParts = useMemo(() => info
    ? (info.groupPerWeekInfo || info.groupFixedInfo).PartsSchedule.reduce((arr, part) =>
      arr.concat([part.lessonsPerWeekGroup.reduce((obj, item) => {
        const { topicId, totalLessons } = item
        const topic = service ? service.topics.find(top => top.id === topicId) : {}
        if (obj[topicId]) {
          obj[topicId] = { lessons: obj[topicId].lessons + 1, totalLessons: obj[topicId].totalLessons + totalLessons, ...topic }
          return obj
        }
        return {
          ...obj,
          [topicId]: { lessons: 1, totalLessons, ...topic }
        }
      }, {})
      ]), [])
    : [], [info, service])
  const [getPayerUser, { data: payerUser, loading }] = useLazyQuery(gql`query ($userId: String!) {
      getPayerUser(
          userId: $userId
      ){
          id
          emails {
              address
          }
          firstName
          lastName
          paymentMethod {
              kind
              ... on ManualPaymentMethod {
                  kind
              }
              ... on CardPaymentMethod{
                  kind
                  status
                  last4
                  brand
                  expMonth
                  expYear
              }
          }
      }
  }`)
  useEffect(() => {
    if (info && info.studentUser) {
      const { studentUserId } = info.studentUser
      getPayerUser({ variables: { userId: studentUserId } })
    }
  }, [info, getPayerUser])
  const thisPayerUser = payerUser && !isEmpty(payerUser) && info && info.studentUser && payerUser.getPayerUser.id !== info.studentUser.studentUserId ? payerUser.getPayerUser : null
  const [baseForm, setBaseForm] = useState({})
  useEffect(() => {
    if (isOpen) {
      //setea la información del plan en el formulario de edición
      const namesPayments = {
        'Payment pro': { name: 'pro' },
        'Payment exam': { name: 'exam' },
        'Payment registration': { name: 'registration' }
      }
      const cuotas = []
      // Objeto payments original sin ningún campo checkeado
      let paymentstart = null
      let payments = {
        registration: false,
        exam: false,
        pro: false,
        lessons: false,
        discount: {
          registration: 0,
          exam: 0,
          pro: 0,
          lessons: 0
        },
        values: {
          registration: 200,
          exam: 50,
          pro: 50
        }
      }
      info.paymentItemsConfig.forEach((pay) => {
        if (pay.type === 'others-fixed') {
          namesPayments[pay.description].used = true
          payments = {
            ...payments,
            [namesPayments[pay.description].name]: true,
            discount: {
              ...payments.discount,
              [namesPayments[pay.description].name]: pay.percentDiscount
            },
            values: {
              ...payments.values,
              [namesPayments[pay.description].name]: pay.amountWithoutDiscount
            }
          }
        } else {
          const payDate = moment(pay.date)
          if (payDate < paymentstart || !paymentstart) paymentstart = payDate
          cuotas.push({
            time: payDate,
            custom: false,
            value: pay.amountWithoutDiscount
          })
          payments = {
            ...payments,
            lessons: true,
            groupLessons: true,
            discount: {
              ...payments.discount,
              lessons: pay.percentDiscount
            }
          }
        }
      }, {})
      const parts = (info.parts || info.fixedParts)
      const service = getServiceById(info.serviceId)
      const defaultForm = {
        planId: info.id,
        groupId: info.groupId,
        testDate: info.testDate,
        paymentstart: paymentstart.endOf('month').format('YYYY-MM-DD'),
        service,
        cuotas,
        payments,
        studentUser: {
          studentUserId: info.studentUser.studentUserId,
          rate: info.studentUser.rate
        },
        status: info.status,
        typeLesson: parts[0].type && parts[0].type === 'totalLesson' ? 'totalLesson' : 'lessonPerWeek',
        name: info.name,
        typePlan: info.testDate ? 'date' : 'time',
        educatorsGroup: info.recommendedEducators.filter(edu => edu.teachingType === 'group').map(edu => ({ ...edu, topic: service.topics.find(topic => topic.id === edu.topicId) || {} })),
        educatorsIndividual: form?.educatorsIndividual || info.recommendedEducators.filter(edu => edu.teachingType === 'individual').map(edu => ({ ...edu, topic: service.topics.find(topic => topic.id === edu.topicId) || {} })),
        from: info.from || parts[0].from,
        to: info.to || parts[parts.length - 1].to,
        amount: info.pricingRate,
        groupAmount: info.typePlanPricing !== 'permonth' ? info.groupPricingRate : 0,
        location: info.location,
        isFirstPlan: info.isFirstPlan,
        totalCost: info.totalCost,
        lpw: parts.reduce((obj, part, idx) => {
          const individual = info.kind === 'lessonsPerWeek' ? part.lessonPerWeekPerTopic : part.TotalPerTopic
          return {
            ...obj,
            [idx]: {
              from: part.from,
              to: part.to,
              name: part.name,
              valueIndividual: individual.reduce((obj, item) => {
                const { topicId, lessonsPerWeek, lockLessons } = item
                const topic = service ? service.topics.find(top => top.id === topicId) : {}
                return {
                  ...obj,
                  [topicId]: {
                    lock: lockLessons,
                    lessons: lessonsPerWeek,
                    totalLessons: totalLessonPart({
                      startdate: part.from,
                      enddate: part.to,
                      [topicId]: { valueIndividual: lessonsPerWeek }
                    }, info.testDate || part.schedule),
                    ...topic
                  }
                }
              }, {}),
              valueGroup: groupParts.length > idx ? groupParts[idx] : {}
            }
          }
        }, {}),
        typePlanPricing: info.typePlanPricing
      }
      setForm(defaultForm)
      setBaseForm(defaultForm)
    }
    // eslint-disable-next-line
  }, [isOpen, groupParts, getServiceById])
  // Gets all Topics that have at least 1 lesson per week and creates the array to check Max Hours
  const individualTopicsSpan = isEmpty(form) ? {} : Object.values(form.lpw).reduce((topics, part) => {
    const { from, to } = part
    Object.entries(part.valueIndividual).forEach(([idTopic, values]) => {
      if (values.lessons > 0) {
        if (!Object.keys(topics).includes(idTopic)) {
          topics[idTopic] = {
            name: values.name,
            prospectiveHours: [{
              from,
              to,
              lessonsPerWeek: values.lessons
            }]
          }
        } else {
          topics[idTopic].prospectiveHours.push({ from, to, lessonsPerWeek: values.lessons })
        }
      }
    })
    return topics
  }, {})
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className='modal-sidebar modal-colored scheduled-modal modal-desktop-half'
      backdrop='static'
      Head={<div className='modal-header p-0 m-0'>
        <div className='d-flex align-items-center w-100 p-7'>
          <h2 className='font-weight-bold flex-grow-1'>
            {
              translate('plans.modal.editplan')
            }
          </h2>
          <IconClose onClick={toggle} />
        </div>
      </div>}
    >
      <div className='w-100 p-7'>
        {
          info?.id || info?._id
        }
        {info ? (
          <Form value={form} onChange={setForm}>
            <PlanStudent student={info.studentUser} thisPayerUser={thisPayerUser} loading={loading} />
            <PlanDetails planInfo={info} form={form} />
            <PlanIndividualSchedule lpw={form.lpw || {}} setForm={setForm} />
            <WeeklySchedule lpw={form.lpw || {}} />
            <PlanEducators baseEducators={educators} topics={individualTopicsSpan} info={info} groupEducators={form.educatorsGroup} individualEducators={form.educatorsIndividual} setForm={setForm} />
            <PlanPricing form={form} setForm={setForm} />
            <PaymentSchedule baseForm={baseForm} form={form} setCuotas={(cuotas) => setForm(form => ({ ...form, cuotas }))} thisPayerUser={thisPayerUser} />
            <SubmitPlan {...{ toggle, form, refetch }} />
          </Form>
        ) : <LoadingPage />}
        <SelectEducator />
      </div>
    </Modal>
  )
}

export default EditChildPlan
