import { useSelector, useDispatch } from "react-redux"

const useSelectTemplateModal = () => {
    const reduxData = useSelector((state) => state.modal.pdfEditor.selectPdfTemplate)
    const {isOpen} = reduxData
    const dispatch = useDispatch()
    const setData = (obj = {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'pdfEditor.selectPdfTemplate',
            data: {
                ...reduxData,
                ...obj
            }
        })
    }

    return {
        ...reduxData,
        open: () => {setData({isOpen: true})},
        close: () => {setData({isOpen: false})},
        toggle: () => {setData({isOpen: !isOpen})}  
    }
}

export default useSelectTemplateModal