import React,{ useMemo } from 'react'
import {
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import UseSelectorField from './useSelectorField'
import AvatarField from './Components/AvatarField'
import AvatarCompactField from './Components/AvatarCompactField'
import moment from 'moment'
import CountryFlag from './Components/CountryFlag'

export const getUtcOffset = (timezone) => {
  const tz = moment().tz(timezone).format('Z')
  return 'GMT ' + tz.split(':')[0]
}

const UseColumnsParents = (refetch = () => {}) => {
  const {breakWidth} = useWindowDimensions()
  return useMemo(() => {
    if(['MD'].includes(breakWidth)) {
      return [
        {
          render: (item) => <AvatarCompactField item={item}/>, id: 'name', colSpan: 5, className: ''
        },
        {
          render: (item) => `${item.phone || 'No defined'}`, id: 'phone', colSpan: 5, className: 'justify-content-start align-items-center text-gray'
        },
        {
          optionsParser: (item) => {
            return UseSelectorField(item, 'parent')
          },
          type: 'selector',
          id: 'buttons',
          colSpan: 2,
          className: 'justify-content-end align-items-center'
        } 
      ]
    }
    else if ( ['SM'].includes(breakWidth) ) {
      return [
        {
          render: (item) => <AvatarCompactField item={item} />, id: 'name', colSpan: 8, className: ''
        },
        {
          optionsParser: (item) => {
            return UseSelectorField(item, 'parent')
          },
          type: 'selector',
          id: 'buttons',
          colSpan: 2,
          className: 'justify-content-end align-items-center'
        }
      ]
    }
    return [
      {
        render: item => <AvatarField item={item} />, id: 'name', colSpan: 2, className: 'justify-content-start align-items-center text-title'
      }, {
        render: (item) => `${item.emails[0].address}`, id: 'email', colSpan: 3, className: 'justify-content-start align-items-center text-gray'
      }, {
        render: (item) => `${item.phone || 'No defined'}`, id: 'phone', colSpan: 2, className: 'justify-content-start align-items-center text-gray'
      }, {
        render: (item) => <CountryFlag className={'w-100'} country={item?.country}/>/** `${item.country.name}`*/, id: 'country.name', colSpan: 2, className: 'justify-content-start align-items-center text-gray'
      }, {
        render: (item) => moment.duration(moment().diff(moment(item.createdAt))).humanize(), id: (item) => `updatedAt-${item.id}`, colSpan: 1, className: 'justify-content-start align-items-center text-gray'
      }, {
        optionsParser: (item) => {
          return UseSelectorField(item, 'parent')
        },
        type: 'selector',
        id: 'buttons',
        colSpan: 2,
        className: 'justify-content-end align-items-center'
      }
    ]
    //eslint-disable-next-line
  }, [breakWidth, refetch])
}

export default UseColumnsParents
