import React from 'react'
import usePaginatedTopics from '../../../Model/Topics/List'
import { Row } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { Button, TableSelector } from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import CreateTopicModal from '../../../Model/Topics/Create/Modal'
import EditTopicModal from '../../../Model/Topics/Edit/Modal'
import pick from 'lodash/pick'
import moment from 'moment'
import DeleteTopicModal from '../../../Model/Topics/Delete/Modal'
import { ordenateArrayByName } from '../../../utils/ordenateArrayByName'
const TopicsView = () => {
  const { data, loading, error } = usePaginatedTopics({ limit: 240 })
  const dispatch = useDispatch()
  const openEditModal = (topic) => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'topic.edit', data: { isOpen: true, ...topic } })
  }
  const openDeletionModal = (topic) => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'topic.delete', data: { isOpen: true, ...topic } })
  }
  return (
    <div className='d-flex flex-column px'>
      <Row className='d-flex flex-column mx-0'>
        <div className='d-flex justify-content-end'>
          <span><Button label='+ Add' color='primary' onClick={() => dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'topic.create', data: { isOpen: true } })} /></span>
        </div>
      </Row>
      <Row className='d-flex flex-column mx-0 mt-4'>
        {loading ? <Skeleton height={25} count={5} className='my-2' />
          : (error ? <div className='alert alert-danger'>Error en la petición</div> : data && ordenateArrayByName(data.docs).map((item) => {
            return (
              <div className='d-flex my-2'>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-start'>{item.name}</span>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-center'>{moment(item.createdAt).format('MMMM do, hh:mm')}</span>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-center'>{moment(item.updatedAt).format('MMMM do, hh:mm')}</span>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-end'>
                  <TableSelector
                    mainBtn={{
                      text: 'Edit'
                    }}
                    dropdownProps={{
                      options: [{
                        component: <span onClick={() => openEditModal({
                          topicId: item.id,
                          updatedTopic: pick(item, ['name'])
                        })}
                                   >Edit Name
                        </span>
                      }, {
                        component: <span
                          className='text-danger' onClick={() => openDeletionModal({
                            topicId: item.id
                          })}
                                   >Delete
                        </span>
                      }]
                    }}
                  />
                </span>
              </div>
            )
          }))}
      </Row>
      <DeleteTopicModal />
      <CreateTopicModal />
      <EditTopicModal />
    </div>
  )
}

export default TopicsView
