import {useQuery, gql} from '@apollo/client'
import planQueryResult from '../../../CounselingPlans/Model/queryResults/planQueryResult'

const usePlan = (vars = {}) => {
    return useQuery(
        gql`
            query (
                $kind: [PlanKind!]!
                $status: [PlanStatus!]!
                $studentsUserIds: [ID!]
                $limit: Int
            ) {
                plans (
                    limit: $limit
                    kind: $kind
                    status: $status
                    studentsUserIds: $studentsUserIds
                ) {
                    docs {
                        ${planQueryResult}
                    }
                }
            }
        `,
        {
            variables: vars
        }
    )
}

export default usePlan