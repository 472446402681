import React, {useMemo} from 'react'
import formatNumber from '../../../utils/formatNumber'

const CostField = (props) => {
  const { paymentItemsConfig, realAmount } = props.item
  const totalAmount = useMemo(
    () => realAmount?.totalAmount || paymentItemsConfig.reduce((sum, cuota) => sum + cuota.amount, 0), 
    [paymentItemsConfig, realAmount]
  )
  return (
    <>
      ${formatNumber(totalAmount) || '-'}
    </>
  )
}

export default CostField
