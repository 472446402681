import {useMutation, gql} from '@apollo/client'

const useRemoveGroupLessonByDates = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
        mutation (
            $excludeDays: [String!]!
            $planId: ID!
            ) {
            removeGroupLessonByDates (
                excludeDays: $excludeDays
                planId: $planId
            )
        }
        `,
        {
            onCompleted, onError
        }
    )
}

export default useRemoveGroupLessonByDates