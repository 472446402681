import React, { useState, useMemo } from 'react'
import {
    ModalLayout,
    useWindowDimensions,
    Button,
    CalendarMonth,
    ListItem,
    IconCircleBack,
    IconCircleNext,
} from '@Knowledge-OTP/znk-ui-components'
import useReviewScheduledModal from './useReviewScheduledModal'
import useGetLessonsForGroupPlan from './Model/useGetLessonsForGroupPlan'
import moment from 'moment'
import getLang from '../../../../../i18n/getLocale'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'


const ReviewScheduledModal = () => {
    const { breakWidth } = useWindowDimensions()
    const userTimezone = useSelector((state) => state.user.timezone)
    const reduxData = useReviewScheduledModal()
    const { translate } = useTranslation()
    moment.locale(getLang())
    const {
        isOpen,
        toggle,
        onChange = () => { },
        props = {},
        unselected: defaultUnselected,
        loading
    } = reduxData
    console.log("LOADING =>", reduxData)
    const {
        datesConfig = [
            // {
            //     from: moment().utc().startOf('week'), //moment(), 
            //     to: moment().utc().add(6, 'weeks').endOf('week'), //moment(), 
            //     days: [0/**Lunes**/, 2/**Miercoles**/]
            // }
        ],
        //utiliza los horarios reales de las lecciones
        showRealLessons = false,
        planId = null,
    } = props
    const {data, loading: loadQuery} = useGetLessonsForGroupPlan({
        groupPlanId: planId,
        status: ["scheduled"]
    })
    const [currentDate, setCurrentDate] = useState(moment())

    const [unselected, setUnselected] = useState([])
    useEffect(() => { setUnselected(defaultUnselected) }, [defaultUnselected])

    const dates = useMemo(
        () => {
            let dates = []
            if(showRealLessons) {
                const lessons = data?.getLessonsForGroupPlan
                dates = (lessons || [])?.map(({startDate}) => startDate)
            }
            else {
                datesConfig.forEach(({ from, to, days }) => {
                    let aux = []
                    let start = from.clone()
                    while (start < to) {
                        days.forEach(
                            (day) => {
                                aux.push(
                                    start.clone().isoWeekday(day + 1).toISOString()
                                )
                            }
                        )
                        start.add(1, 'weeks')
                    }
                    dates = dates.concat(aux)
                })
            }
            return dates
        }, [datesConfig, showRealLessons, data]
    )


    return (
        <ModalLayout
            className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'
                } modal-colored h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={`d-flex flex-column`}>
                    <span className={`h2 text-title font-weight-bold mt-2`}>{translate('plans.actions.reviewshedule')}</span>
                </div>
            }
        >
            <div className='m-0 p-0'>
                <ListItem
                    showBottomLine
                    showTopLine
                    Left={<span style={{textTransform: 'capitalize'}}>{`${currentDate.clone().format('MMMM YYYY')}`}</span>}
                    Right={
                        <div className={`d-flex flex-row`}>
                            <div className={`hover-icon mr-2`} onClick={() => { setCurrentDate(currentDate.clone().add(-1, 'month')) }}>
                                <IconCircleBack size={15} />
                            </div>
                            <div className={`hover-icon`} onClick={() => { setCurrentDate(currentDate.clone().add(1, 'month')) }}>
                                <IconCircleNext size={15} />
                            </div>
                        </div>
                    }
                />
                <CalendarMonth
                    currentMonth={currentDate.clone().month()}
                    currentYear={currentDate.clone().year()}
                    className={`mt-4`}
                    setUnselected={setUnselected}
                    unselected={unselected}
                    selected={dates}
                    timezone={userTimezone}
                />
            </div>
            <Button
                color='#696CFF'
                style={{ backgroundColor: '#696CFF' }}
                className='w-100 mt-3'
                disabled={false}
                activity={loadQuery || loading}
                label={unselected?.length > 0 ? `Save ${unselected?.length} day${unselected?.length > 1 ? 's' : ''} of unavailability` : 'No corrected days'}
                onClick={() => {
                    if (onChange) onChange(unselected)
                }}
            />


        </ModalLayout>
    )
}

export default ReviewScheduledModal