import React, { useEffect } from 'react'
import { IconTask, Button } from '@Knowledge-OTP/znk-ui-components'
import { useSelector, useDispatch } from 'react-redux'
import useOperationTaskMutation from '../../Model/useOperationTask'
import useAllUserTasks from '../modals/ShowScheduledMeeting/Models/useAllUserTasks'
import useUploadFileToTask from '../../Model/useUploadFileToTask'
import { UseGetUserTask } from '../modals/ShowScheduledMeeting/Models/useGetUserTask'
import { showAlert } from '../../../../../common/Alert/util'
import useTranslation from '../../../../../i18n/useTranslation'
import phaseNames from '../modals/UploadFile/Utils/phaseNames'

const SendToReviewButton = (props) => {
  const refetchUserData = useSelector((state) => state.user.refetch)
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const { task,  filesUploadedByUser, setTaskData } = props
  const planId = task?.dataPlan ? task?.dataPlan?.id : task?.planId

  const [
    operationMutation,
    { loading: loadMutation, data: dataMutation },
  ] = useOperationTaskMutation(
    () => {
      //   callBack()
    },
    (err) => {
      console.error('Error on complete task : ', err)
    },
  )
  // Query para traer una sola task
  // eslint-disable-next-line
  const [getOneTask, { data: dataOneTask, loading: loadingOneTask }] = UseGetUserTask()
  const { refetch: refetchTasks } = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })
  // Mutation para subir archivos a la task
  const {
    submit: submitUpload,
    loading: uploadLoading,
    // data: uploadData,
  } = useUploadFileToTask(
    () => {
      sendToReviewFunction()
    },
    () => { },
  )

  const sendToReviewFunction = () => {
    operationMutation({
      variables: {
        universities: task?.universities,
        operation: 'review',
        userId: task?.userId,
        taskId: task?.id,
        stepId: task?.stepId,
        phase: phaseNames[task?.phaseId],
        planId,
      },
    })
  }

    // Función que es llamada para subir archivos a una task
    const uploadFileFunction = () => {
      if (filesUploadedByUser?.length === 0) return
      try {
        submitUpload({
          files: filesUploadedByUser,
          taskId: task?.id,
          stepId: task?.stepId,
          phase: phaseNames[task?.phaseId],
          planId,
        })
      } catch (error) {
        showAlert(
          {
            text: translate('modal.task.errorAlert'),
            status: 'error',
          },
          dispatch,
        )
      }
    }
  /* eslint-disable */
  useEffect(() => {

    if (dataMutation) {
      refetchUserData()
      refetchTasks()
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase: phaseNames[task?.phaseId],
          planId
        }
      })
    }
  }, [dataMutation])

  useEffect(() => {
    if (dataOneTask) {
      setTaskData(dataOneTask?.getUserTask)
    }
  }, [dataOneTask])

  /* eslint-enable */
  return (
    <>
      <Button
        onClick={() => {
          filesUploadedByUser.length > 0 ? uploadFileFunction() : sendToReviewFunction()
        }}
        className='d-flex justify-content-center align-items-center py-2 mb-2'
        activity={loadMutation || uploadLoading}
        label={
          <div className='d-flex justify-content-between align-items-center my-auto w-100'>
            <div className='col-10 mx-n2'>
              <span className='d-flex justify-content-start align-middle'>
                Send to review
              </span>
            </div>
            <div className='col-2 py-2 mx-n2 d-flex justify-content-end align-items-center'>
              <IconTask
                color='#ffffff'
                fill='#b0b2f9'
                stroke='#767aff'
                strokeWidth='1'
                size={16}
              />
            </div>
          </div>
        }
        style={{
          width: '100%',
          border: '1px solid',
          borderRadius: '10px',
          borderColor: '#696bff',
          backgroundColor: '#696bff',
          color: '#ffffff',
          cursor: 'pointer',
        }}
        size='medium'
      />
    </>
  )
}

export default SendToReviewButton
