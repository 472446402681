import { gql, useLazyQuery } from '@apollo/client'

const useNotesQuery = () => {
    return useLazyQuery(
        gql`
        query(
            $userId: ID!,
            $sortCreatedAt: String,
            $ownerIds: [ID!],
        ){
            getNotes(
                userId: $userId,
                ownerIds: $ownerIds,
                sortCreatedAt: $sortCreatedAt
            ) {
                id
                ownerId
                ownerFirstName
                ownerLastName
                content
                createdAt
                checkinId
            }
        }`
    )
}

export default useNotesQuery
