import {gql, useQuery} from '@apollo/client'

const useAllUsers = (variables = {}) => {
    return useQuery(
        gql`
            query (
                $state: Boolean
                $sortField: String
                $previous: String
                $next: String
                $limit: Int
                $ids: [ID!]
                $roles: [Roles!]
                $search: String
            ) {
                allUsers (
                    state: $state
                    sortField: $sortField
                    previous: $previous
                    next: $next
                    limit: $limit
                    ids: $ids
                    roles: $roles
                    search: $search

                ) {
                    docs {
                        id
                        avatar
                        firstName
                        lastName
                    }
                }
            }
        `, {variables}
    )
}

export default useAllUsers