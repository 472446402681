import React from 'react'
import { ConfirmModal } from '@Knowledge-OTP/znk-ui-components'
import { useSelector, useDispatch } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'

const ConfirmDropRescheduled = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const { isOpen, confirmAction, cancelAction, collissionKinds } = useSelector((state) => state.modal.lesson.confirmDropRescheduled)
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'lesson.confirmDropRescheduled',
      data: {
        isOpen: !isOpen,
        collissionKinds,
        confirmAction,
        cancelAction
      }
    })
  }
  const cantRescheduledAnyway = collissionKinds.includes('educatorLesson') || collissionKinds.includes('studentLesson')
  return (
    <ConfirmModal
      className=''
      classNameActionDiv='d-flex flex-column'
      classNameConfirm='text-primary text-right'
      classNameCancel='text-gray text-right'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('modal.lesson.confirmDropRescheduled.title')}
      body={translate('modal.lesson.confirmDropRescheduled.body')}
      confirmAction={() => {
        confirmAction()
        toggle()
      }}
      // SOLO CUANDO NO ES DE LESSON: studentLesson y educatorLesson
      cancelAction={
        !(cantRescheduledAnyway)
          ? () => {
            cancelAction()
            toggle()
          }
          : null
      }
      disabledCancel={cantRescheduledAnyway}
      confirmText={translate('modal.lesson.confirmDropRescheduled.undo')}
      cancelText={translate('modal.lesson.confirmDropRescheduled.scheduledAnyway')}
    />
  )
}

export default ConfirmDropRescheduled
