import { gql, useMutation } from '@apollo/client'

const useSetCreditCardDefaultPaymentMethod = (
  onSuccess = () => {},
  onError = () => {}
) => {
  return useMutation(
    gql`
        mutation setUserDefaultPaymentMethod($userId:String!, $paymentMethodId: String!) {
            setUserDefaultPaymentMethod(userId: $userId, paymentMethodId: $paymentMethodId) {
            id
            paymentMethod {
                id
                kind
                ... on CardPaymentMethod {
                cardInfo {
                    last4
                    brand
                    expYear
                    expMonth
                }
                }
                ... on BankDebitPaymentMethod {
                validationStatus
                accountInfo {
                    bankName
                    last4
                }
                }
                ... on BankCreditPaymentMethod {
                status
                sourceInfo {
                    bankName
                    accountNumber
                    swiftCode
                    routingNumber
                }
                }
                ... on ManualPaymentMethod {
                manualPaymentInfo {
                    bankName
                    accountNumber
                    routingNumber
                    beneficiary
                    address
                    swiftCode
                }
                }
            }
            registeredPaymentMethods {
                id
                kind
                ... on CardPaymentMethod {
                cardInfo {
                    last4
                    brand
                    expYear
                    expMonth
                }
                }
                ... on BankDebitPaymentMethod {
                validationStatus
                accountInfo {
                    bankName
                    last4
                }
                }
                ... on BankCreditPaymentMethod {
                status
                sourceInfo {
                    bankName
                    accountNumber
                    swiftCode
                    routingNumber
                }
                }
                ... on ManualPaymentMethod {
                manualPaymentInfo {
                    bankName
                    accountNumber
                    routingNumber
                    beneficiary
                    address
                    swiftCode
                }
                }
                ... on ZellePaymentMethod {
                zellePaymentInfo {
                    emailToSend
                }
                }
            }
            }
        }
        `,
    {
      onCompleted: onSuccess,
      onError
    }
  )
}

export default useSetCreditCardDefaultPaymentMethod;