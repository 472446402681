import moment from 'moment'
export const parseLpwGroupPlan = (plan, localTime) => {
    return plan.PartsSchedule.reduce((allParts, part, index) => {
        const startDate = moment(part.from).utc()
        const groupTopics = part.lessonsPerWeekGroup.reduce((topics, block) => {
          const { topicId, day: dayBlock, hour: hourBlock } = block
          const dates = startDate.clone().day(dayBlock).hour(parseInt(hourBlock.split(':')[0])).tz(localTime)
          const day = dates.day()
          const hora = dates.format('HH')
          if (topics[topicId]) {
            return {
              ...topics,
              [topicId]: {
                valueGroup: topics[topicId].valueGroup + 1,
                groupBlocks: topics[topicId].groupBlocks.concat([{ id: `${day}-${parseInt(hora)}`, val: { day: day, hour: parseInt(hora) } }])
              }
            }
          } else {
            return {
              ...topics,
              [topicId]: {
                valueGroup: 1,
                groupBlocks: [{ id: `${day}-${parseInt(hora)}`, val: { day: day, hour: parseInt(hora) } }]
              }
            }
          }
        }, {})
        return {
          ...allParts,
          [index]: {
            ...part.lessonsPerWeekIndividual.reduce((allLpw, lpw) => {
              const { topicId } = lpw
              if (allLpw[topicId]) {
                return {
                  ...allLpw,
                  [topicId]: {
                    ...allLpw[topicId],
                    valueIndividual: lpw.lessonsPerWeek,
                    lock: lpw.lockLessons
                  }
                }
              } else {
                return {
                  ...allLpw,
                  [lpw.topicId]: { valueIndividual: lpw.lessonsPerWeek, lock: lpw.lockLessons, valueGroup: 0, groupBlocks: [] }
                }
              }
            }, groupTopics),
            startdate: part.from.split('T')[0],
            enddate: part.to.split('T')[0],
            partname: part.name,
            typeLesson: part.scheduleType
          }
        }
      }, {})
}

export default {
    parseLpwGroupPlan
}