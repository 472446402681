import {gql, useMutation} from '@apollo/client'

const useCompleteGroupPlan = (
    onSuccess = () => {},
    onError = () => {}
) => {
    return useMutation(
        gql`
            mutation (
                $pendingLessonsId: [String!] = []
                $groupId: ID!
            ) {
                completeGroupPlan (
                    pendingLessonsId: $pendingLessonsId
                    groupId: $groupId
                ) {
                    id
                    status
                }
            }
        `,
        {
            onCompleted: onSuccess,
            onError
        }
    )
}

export default useCompleteGroupPlan