import React from 'react'
import { TabContent, TabPane } from 'reactstrap'
import InnerAssingTaskModal from '../../../../../../../CounselingTemplates/Task/assingTaskModal/inner'
import useAssingTaskModal from '../../../../../../../CounselingTemplates/Task/assingTaskModal/useAssignTaskModal'

const TasksSection = (props) => {
    const {selectedTasks = [], currentIndex = 0, onContinue = () => {}} = props

    const { validation } = useAssingTaskModal()
    return (
        <TabContent activeTab={`task-${currentIndex}`}>
            {
                (selectedTasks || []).map(
                    (task, index) => {
                        return (
                            <TabPane tabId={`task-${index}`}>
                                <InnerAssingTaskModal
                                    useWizard
                                    taskData={task} 
                                    close={() => {}} 
                                    onChange={(inputMutation) => {
                                        onContinue(inputMutation, index, task)
                                    }} 
                                    useMutation={false} 
                                    loading={false} 
                                    validation={validation}
                                />
                            </TabPane>
                        )
                    }
                )
            }
        </TabContent>
    )
}

export default TasksSection