import React, { useState, useEffect } from 'react'
import {
    ListItem,
    IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import useGetDocsAndExams from './Model/getDocAndExams'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../store/modal/const'
import TypesFilter from '../../../../Filters/Types'
import taskTypes from './utils/taskTypes'
import DocsItemComponent from './Components/docItem'
import ExamItemComponent from './Components/examItem'

const DocsAndExam = ({ plan }) => {

    const dispatch = useDispatch()

    const userId = plan?.studentUser?.studentUserId

    const { data, loading } = useGetDocsAndExams(userId)

    const [docsAndExams, setDocsAndExams] = useState([])
    const [docsAndExamsFiltered, setDocsAndExamsFiltered] = useState([])

    const TYPES = ['file', 'exam']

    const [filters, setFilters] = useState({
        type: TYPES,
    })

    useEffect(() => {

        if (data && data?.getUserDocsAndExams) {

            const docs = data?.getUserDocsAndExams
                .filter(doc => doc.type === taskTypes.uploadFile)
                .map(doc => ({
                    type: 'file',
                    name: doc.name,
                    instructions: doc.instructions,
                    files: doc?.resolveTask?.uploadFiles,
                    id: doc.id
                })).reduce((acc, element) => {
                    let newElements = element.files ? element.files.map(files => ({ ...files, type: element.type, instructions: files.instructions, name: element.name })) : []
                    return [...acc, ...newElements]
                }, [])

            const exams = data.getUserDocsAndExams
                .filter(doc => doc.type === taskTypes.scoreCollection)
                .map(doc => ({
                    type: 'exam',
                    name: doc.name,
                    instructions: doc.instructions,
                    score: doc?.resolveTask?.score?.overall,
                    id: doc.id,
                    examDate: doc?.resolveTask?.score?.examDate,
                    examType: doc?.resolveTask?.score?.name
                }))

            const docsAndExams = [...docs, ...exams]

            setDocsAndExams(docsAndExams)

            setDocsAndExamsFiltered(docsAndExams)
        }

    }, [data])


    useEffect(() => {
        if (docsAndExams.length > 0) {
            const newDocsAndExamsFiltered = docsAndExams.filter(
                (item) => filters.type.includes(item.type)
            )
            setDocsAndExamsFiltered(newDocsAndExamsFiltered)
        }
        // eslint-disable-next-line
    }, [filters])


    const handleViewDetails = (item) => {

        if (item.type === 'file') {
            return null
        }
        let examToShow = data.getUserDocsAndExams.filter(doc => 
            doc.id === item.id
        )
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'counseling.viewExamModal',
            data: {
                isOpen: true,
                examData: examToShow[0]
            }
        })
    }

    return (
        <div className='mt-4'>
            <TypesFilter
                types={TYPES}
                typesFilters={filters.type}
                setTypesFilters={(newTypes) => {
                    setFilters({ ...filters, type: newTypes })
                }} />
            <div className='mt-4'>
                {
                    !loading &&
                    docsAndExamsFiltered.map((item, index) => (
                        <div key={index}>
                            <ListItem
                                hasHover={false}
                                forceBorderOverflow={false}
                                showTopLine={false}
                                onClick={() => handleViewDetails(item)}
                                showBottomLine={false}
                                Left={
                                    // eslint-disable-next-line
                                    <a target="_blank" href={(item.type === 'file') ? item.url : null}>
                                        <div className={`d-flex flex-row `}>
                                            {
                                                item.type === 'file' ?
                                                <DocsItemComponent docData={item}/>
                                                    :
                                                    <ExamItemComponent examData={item}/>
                                            }
                                        </div>
                                    </a>
                                }
                                Right={
                                    <div onClick={() => handleViewDetails(item)} className={`hover-icon`}><IconArrowRight /></div>
                                }
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default DocsAndExam