import React, { Fragment } from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import {
  Avatar,
  ListItem
} from '@Knowledge-OTP/znk-ui-components'
import isEmpty from 'lodash/isEmpty'
import IndividualEducator from './IndividualEducator'
const PlanEducators = (props) => {
  const { setForm, groupEducators, individualEducators, info, topics, baseEducators } = props
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const openEducatorModal = (topic, educators, prospectiveParts) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.changeTopicEducator',
      data: {
        isOpen: true,
        topic,
        setEducator: (edus) => {
          setForm(pre => ({
            ...pre,
            educatorsIndividual: individualEducators.filter(edu => edu.topicId !== topic.id).concat(edus),
            recommendedEducators: individualEducators.filter(edu => edu.topicId !== topic.id).concat(edus).concat(groupEducators)
          }))
        },
        serviceId: info.serviceId,
        prospectiveParts: prospectiveParts,
        currentEducators: educators,
        type: 'individual'
      }
    })
  }
  const removeIndividualEducator = (topicId, educatorId) => {
    setForm(pre => {
      return ({
        ...pre,
        educatorsIndividual: individualEducators.filter(edu => edu.educatorUserId !== educatorId || (edu.educatorUserId === educatorId && edu.topicId !== topicId))
      })
    })
  }
  const updateEducatorWage = (educatorId, topicId) => rate => {
    setForm(pre => ({
      ...pre,
      educatorsIndividual: individualEducators.map(edu => {
        if (edu.topicId === topicId && edu.educatorUserId === educatorId) {
          return {
            ...edu,
            rate
          }
        } else return edu
      })
    }))
  }
  const updateEducatorLink = (educatorId, topicId) => link => {
    setForm(pre => ({
      ...pre,
      educatorsIndividual: individualEducators.map(edu => {
        if (edu.topicId === topicId && edu.educatorUserId === educatorId) {
          return {
            ...edu,
            lessonLink: link
          }
        } else return edu
      })
    }))
  }
  const changeWaive = (educatorId, topicId) => waive => {
    setForm(pre => ({
      ...pre,
      educatorsIndividual: individualEducators.map(edu => {
        if (edu.topicId === topicId && edu.educatorUserId === educatorId) {
          return {
            ...edu,
            isWaiveIndividual: waive
          }
        } else return edu
      })
    }))
  }
  const getBackupLink = (educatorId) => {
    const foundEducator = baseEducators.find((edu) => edu.id === educatorId)
    if (foundEducator) {
      return foundEducator.educatorInfo?.backupLink
    }
  }
  return (
    <div className='d-flex flex-column w-100'>
      <h3 className='font-weight-bold my-2'>{translate('program.plan.infoModal.educators')}</h3>
      {
        groupEducators && groupEducators.length ? (
          <>
            {groupEducators.map((edu) => {
              return (
                <ListItem
                  key={edu.id}
                  className='py-1'
                  id='x-0'
                  label={null}
                  Left={
                    <div className='d-flex align-items-center'>
                      <Avatar
                        name={`${edu.firstName} ${edu.lastName}`}
                        named={false}
                        uri={null}
                        size='avatar-medium'
                      />
                      <label className='ml-2 h4'>
                        {`${edu.firstName} ${edu.lastName}`} <span className='text-muted font-weight-light'>{`(Group, ${edu.topic.name})`}</span>
                      </label>
                    </div>
                  }
                />
              )
            })}
          </>
        ) : null
      }
      {
        !isEmpty(topics) ? (
          <>
            {Object.entries(topics).map(([topicId, topicInfo], idx) => {
              const thisEducators = individualEducators && individualEducators.length ? individualEducators.filter(edu => edu.topic.id === topicId) : []
              return (
                <Fragment key={idx}>
                  {thisEducators.map(edu => {
                    return <IndividualEducator location={info.location} key={`${topicId}-${edu.educatorUserId}`} edu={edu} removeEducator={() => removeIndividualEducator(topicId, edu.educatorUserId)} changeWage={updateEducatorWage(edu.educatorUserId, topicId)} updateLink={updateEducatorLink(edu.educatorUserId, topicId)} getBackupLink={getBackupLink} changeWaive={changeWaive(edu.educatorUserId, topicId)} />
                  })}
                  <span className='btn-link text-primary mt-2 mb-3' onClick={() => openEducatorModal({ id: topicId, name: topicInfo.name }, thisEducators, topicInfo.prospectiveHours)}>Add more recommended {topicInfo.name} educators</span>
                </Fragment>
              )
            })}
          </>
        ) : null
      }
      <div className='borderTop my-2' />
    </div>
  )
}

export default PlanEducators
