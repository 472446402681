export default function (type, kind = '') {
  switch (type) {
    case 'lessonsPayment':
      return kind === '' ? 'Lessons' : kind === 'plan-payment-item' ? 'Study Plan' : 'Lessons'
    case 'otherFixed':
      return 'Registration'
    case 'marathon':
      return 'Marathon'
    case 'examFee':
      return 'Examen Fee'
    case 'other':
      return 'Other'
    case 'content':
      return 'Content'
    case 'reimbursement':
      return 'Reimbursement'
    case 'training':
      return 'Training'
    case 'scoreSendingFee':
      return 'Score Sending Fee'
    case 'counselingPayment':
      return 'Counseling Plan'
    case 'applicationFee':
      return 'Application Fee'
    case 'additionalApplication':
      return 'Additional Application'
    case 'zinkerzCamp':
      return 'Zinkerz Camp'
    case 'universityCharge':
      return 'Counseling Plan'
    case 'studyplan':
      return 'Study Plan'
    default:
      return ''
  }
}
