import { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

const PAYLOAD = 'counseling.assignMultipleTasks'

const useAssignMultipleTasks = () => {
    const dispatch = useDispatch()
    //En funciones pushTask y removeTask sobreescribe redux para evitar 
    // la apertura del modal al seleccionar y deseleccionar una tarea
    const overwriteReduxData = {
        isOpen: false
    }
    const reduxData = useSelector((state) => state.modal.counseling.assignMultipleTasks)
    const {isOpen, onChange, selectedTasks} = reduxData
    // console.log("selectedTasks =>", selectedTasks)
    const setOpen = (open, onChange = () => {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: PAYLOAD,
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
            }
        })
    }
    const pushTask = useCallback(
        (task = undefined) => {
            if(!task) return
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: PAYLOAD,
                data: {
                    ...reduxData,
                    ...overwriteReduxData,
                    selectedTasks: selectedTasks.concat([task]),
                }
            })
        }
        // eslint-disable-next-line
        , [reduxData, dispatch]
    )
    const removeTask = useCallback(
        (task = undefined) => {
            if(!task) return
            const currentTaskId = task?.id || task?.taskId
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: PAYLOAD,
                data: {
                    ...reduxData,
                    ...overwriteReduxData,
                    selectedTasks: selectedTasks.filter(({taskId, id}) => ![taskId, id].includes(currentTaskId)),
                }
            })
            // eslint-disable-next-line
        }, [reduxData, dispatch]
    ) 
    const getIsSelected = (task) => {
        const currentTaskId = task?.id || task?.taskId
        return selectedTasks.map(({taskId, id}) => taskId || id).includes(currentTaskId)
    }


    const unselectAll = () => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: PAYLOAD,
            data: {...reduxData, selectedTasks: []}
        })
    }
    


    const closeAndReset = () => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: PAYLOAD,
            data: {
                ...reduxData,
                isOpen: false,
                selectedTasks: []
            }
        })
    }


    return {
        ...reduxData,
        onChange,
        isOpen,
        selectedTasks,
        pushTask,
        removeTask,
        getIsSelected,
        unselectAll: () => {unselectAll(reduxData)},
        open: (onChange = () => {}) => {
            setOpen(true, onChange)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
        closeAndReset
    }
}

export default useAssignMultipleTasks