import React, { useMemo } from 'react'
import {
  IconArrowRight,
  LessonCard,
  PlanCard,
  ListItem,
  Avatar,
  Action
} from '@Knowledge-OTP/znk-ui-components'
import { useSelector } from 'react-redux'
import { TabContent, TabPane } from 'reactstrap'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import Skeleton from 'react-loading-skeleton'

const Summary = (props) => {
  const { studentResume, loading } = props
  const { translate } = useTranslation()

  const services = useSelector((state) => state.user.services)
  const orientationService = services.find(({ isOrientation }) => isOrientation)

  const upcomingLesson = studentResume?.user?.studentInfo?.upcomingLesson
  const upcomingLessonIsOrientation = upcomingLesson?.service?.id === orientationService?.id
  const linkedEducators = studentResume?.user?.studentInfo?.linkedEducators
  // const currentPlans = []
  const currentPlans = useMemo(() => {
    if (studentResume?.plans?.docs) {
      const parsedPlans = studentResume?.plans?.docs?.map((plan) => {
        let startTime
        let endTime
        let partArray
        let isFixedPlan = false
        let isTotalLesson = false
        if (plan.kind === 'lessonsFixed') {
          partArray = (plan.lessonPerTopic || plan.fixedParts) || []
          isFixedPlan = true
          isTotalLesson = (partArray || []).reduce(
            (isTotal, lessonPart) =>
              isTotal || lessonPart.type === 'totalLesson',
            false
          )
          // isTotalLessonsPlan = plan.lessonPerTopic.reduce((isTotal, lessonPart) => isTotal || (lessonPart.type === 'totalLesson'), false)
        } else partArray = plan.parts || []

        startTime = (partArray || []).reduce(
          (num, item) =>
            moment(item.from.split('T')[0]).valueOf() < num
              ? moment(item.from.split('T')[0]).valueOf()
              : num,
          Number.POSITIVE_INFINITY
        )
        endTime = (partArray || []).reduce(
          (num, item) =>
            moment(item.to.split('T')[0]).valueOf() > num
              ? moment(item.to.split('T')[0]).valueOf()
              : num,
          Number.NEGATIVE_INFINITY
        )

        const lpwTotal = (partArray || []).reduce((totalLpw, part) => {
          return (
            totalLpw +
            (part?.lessonsPerWeek ||
              part?.TotalPerTopic?.reduce(
                (totalLessons, part) => totalLessons + (part?.lessonsPerWeek || 0),
                0
              ))
          )
        }, 0)

        return {
          ...plan,
          startTime: moment(startTime),
          endTime: moment(endTime),
          isFixedPlan,
          isTotalLesson,
          lessonsTotal: lpwTotal,
          status:
            plan.status === 'scheduled' || plan.status === 'paid'
              ? startTime > new Date().getTime()
                ? 'upcoming'
                : 'inprogress'
              : plan.status
        }
      })
      return parsedPlans?.filter((item) => item.status === 'inprogress' || item.status === 'upcoming')
    }
    return []
  }, [studentResume])

  return (
    <div className='d-flex flex-column'>
      <div className='pr-6'>
        <label className='h4 font-weight-bold mt-4 mb-2'>{translate('student.modal.summary.upcommingLesson')}</label>
        <TabContent activeTab={loading ? 'loading' : upcomingLesson ? 'show' : 'blank'}>
          <TabPane tabId={`loading`}>
            <Skeleton count={1} height={112} className='w-100' />
          </TabPane>
          <TabPane tabId={`show`}>
            <LessonCard
              title={upcomingLessonIsOrientation ? upcomingLesson?.title : `${upcomingLesson?.service?.name} ${upcomingLesson?.topic?.name}`}
              status='upcoming'
              lessonTime={moment(upcomingLesson?.startDate)}
              lessonEndTime={moment(upcomingLesson?.endDate)}
              attendants={
                upcomingLesson?.substitute === null
                  ? [{ name: `${upcomingLesson?.educator?.firstName} ${upcomingLesson?.educator?.lastName}`, avatar: null }]
                  : [{ name: `${upcomingLesson?.substitute?.firstName} ${upcomingLesson?.substitute?.lastName}`, avatar: null }]
              }
              onClick={() => { }}
              showCountdown
              preLabelTime={null}
            />
          </TabPane>
          <TabPane tabId={`blank`}>
            <Action
              color='transparent'
              textColor='#7079FF'
              border='dashed 1px #7079FF'
              className='my-3 w-100'
              labelLeft={
                <span className='w-100 d-flex flex-column my-3'>
                  <label className='m-0'>
                    {translate('student.modal.summary.upcommingLessonPlaceholder')}
                  </label>
                </span>
              }
              labelRight={null}
              action={null}
            />
          </TabPane>
        </TabContent>
      </div>
      <div className={`${currentPlans?.length > 0 ? '' : 'pr-6'}`}>
        <label className='h4 font-weight-bold mt-4 mb-2'>{translate('student.modal.summary.currentPlan')}</label>
        <TabContent activeTab={loading ? 'loading' : currentPlans?.length > 0 ? 'show' : 'blank'}>
          <TabPane tabId={`loading`}>
            <Skeleton count={1} height={112} className='w-100' />
          </TabPane>
          <TabPane tabId={`show`}>
            <div className='d-flex flex-row znk-scroll-horizontal-v2 znk-scroll-horizontal-planCard'>
              {currentPlans?.map((plan) => {
                return (
                  <PlanCard
                    className='mx-2'
                    styleCard={{ minWidth: '90%' }}
                    onClick={() => { }}
                    translate={translate}
                    {...plan}
                  />
                )
              })}
            </div>
          </TabPane>
          <TabPane tabId={`blank`}>
            <Action
              color='transparent'
              textColor='#7079FF'
              border='dashed 1px #7079FF'
              className='my-3 w-100'
              labelLeft={
                <span className='w-100 d-flex flex-column my-3'>
                  <label className='m-0'>
                    {translate('student.modal.summary.currentPlanPlaceholder')}
                  </label>
                </span>
              }
              labelRight={null}
              action={null}
            />
          </TabPane>
        </TabContent>
      </div>
      <div className='pr-6'>
        <label className='h4 font-weight-bold mt-4 mb-2'>{translate('student.modal.summary.educators')}</label>
        <div className='d-flex flex-column'>
          <TabContent activeTab={loading ? 'loading' : linkedEducators?.length > 0 ? 'show' : 'blank'}>
            <TabPane tabId={`loading`}>
              <Skeleton count={3} height={40} className='w-100' />
            </TabPane>
            <TabPane tabId={`show`}>
              {
                linkedEducators?.map((edu) => {
                  const educatorName = `${edu?.firstName} ${edu?.lastName}`
                  return (
                    <ListItem
                      Left={
                        <div className='m-0 p-0 d-flex flex-row align-items-center'>
                          <Avatar
                            name={educatorName}
                            uri={null}
                            innerDiv
                            size='avatar-small'
                          />
                          <div className='d-flex flex-row align-items-center'>
                            <span className='ml-2'>
                              {educatorName}
                            </span>
                            <span className='h45 ml-1 text-gray'>
                              ({
                                edu?.topicCount.map((topic) => topic?.name).join(',')
                              })
                            </span>
                          </div>
                        </div>
                      }
                      Right={<IconArrowRight />}
                    />
                  )
                })
              }
            </TabPane>
            <TabPane tabId={`blank`}>
              <Action
                color='transparent'
                textColor='#7079FF'
                border='dashed 1px #7079FF'
                className='my-3 w-100'
                labelLeft={
                  <span className='w-100 d-flex flex-column my-3'>
                    <label className='m-0'>
                      {translate('student.modal.summary.educatorsPlaceholder')}
                    </label>
                  </span>
                }
                labelRight={null}
                action={null}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  )
}

export default Summary
