import React, { useEffect } from 'react'
import {
    Notification
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import useLesson from '../../Model/useLesson'
import { Collapse } from 'reactstrap'
import RescheduledPendingLessons from '../../modal/RescheduledPendingLessons'

const PendingNotification = (props) => {
    const { user } = props
    const dispatch = useDispatch()
    const [getPending, { data: pending }] = useLesson(
        `
            pendingInfo {
                userId
                pendingDate
            }
            ... on IndividualLesson {
                pendingUser {
                    firstName
                    lastName 
                }
            }
            ... on PlanLesson {
                pendingUser {
                    firstName
                    lastName 
                }
            }
            ... on GroupLesson {
                pendingUser {
                    firstName
                    lastName 
                }
            }
        `
    )
    useEffect(() => {
        if (user) {
            getPending({
                variables: {
                    studentIds: [user || ''],
                    status: ['pending'],
                    limit: 0
                }
            })
        }
    }, [user, getPending])
    const pendingLessons = pending?.lessons?.docs || []
    return (
        <Collapse isOpen={user && pendingLessons?.length > 0} >
            {
                user && pendingLessons?.length > 0 && (
                    <>
                        <Notification
                            label={`${pendingLessons?.length} lessons pending`}
                            number={1}
                            className='mb-3'
                            onClick={() => {
                                dispatch({
                                    type: 'SET_DATA_MODAL_FORM_LIST',
                                    payload: 'lesson.rescheduledpending',
                                    data: {
                                        isOpen: true
                                    }
                                })
                            }}
                        />
                        <RescheduledPendingLessons lessons={pendingLessons} />
                    </>
                )
            }
        </Collapse>
    )
}
export default PendingNotification