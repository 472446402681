import React, {useMemo, useState, useEffect} from 'react'
import {
    IconIllustratedReminder, 
    Button, 
    CheckboxOption, 
    Avatar, 
    ModalLayout,
    ListItem 
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import {TabContent, TabPane} from 'reactstrap'
import useSendEmailReminder from './useSendEmailReminder'

// import usePlan from '../Model/usePlan'
//import useGetTask from '../../Model/useGetTask'
import { UseGetUserTask } from '../viewTaskModal/modals/ShowScheduledMeeting/Models/useGetUserTask'
import phaseNames from '../viewTaskModal/modals/UploadFile/Utils/phaseNames'
import useSendTaskReminderEmail from '../Model/useSendTaskReminderEmail'
import { showAlert } from '../../../../common/Alert/util'
import {useDispatch} from 'react-redux'

const SendEmailReminder = () => {
    const {isOpen, toggle, taskData, onChange} = useSendEmailReminder()
    const dispatch = useDispatch()
    const {dataPlan} = taskData
    const [dataTask, setDataTask] = useState(null)
    // obtiene dataPlan (taskData debe traer planId)
    //const {data, loading: loadDataTask} = useGetTask({taskId: taskData?.id})
    // eslint-disable-next-line
    const [getOneTask, { data, loading: loadDataTask }] = UseGetUserTask()

    useEffect(() => {
        getOneTask({
            variables: {
              taskId: taskData?.id,
              stepId: taskData?.stepId,
              phase: phaseNames[taskData?.phase] || phaseNames[taskData?.phaseName] || phaseNames[taskData?.phaseId],
              planId: taskData?.planId,
            },
          }).then((resp) => {
            setDataTask(resp?.data?.getUserTask)
          }
        )
    // eslint-disable-next-line
    }, [taskData])
    
    // const {data, loading: loadDataPlan} = usePlan({
    //     planId: taskData?.planId || ''
    // })
    //const dataTask = useMemo(() => data?.getTask, [data])
    const {
        studentName, parentName
    } = useMemo(
        () => {
            if(dataTask) {
                const isAssigned = !!dataTask?.user
                if(isAssigned) {
                    const student = dataTask?.user
                    const parent = dataTask?.user?.studentInfo?.parents?.find(e => e.manageApprovals)
                    const isFamily = student?.studentInfo?.studentType === 'family'
                    return {
                        studentName: `${student?.firstName} ${student?.lastName}`,
                        parentName: parent && isFamily ? `${parent?.firstName} ${parent?.lastName}` : null
                    }
                } else if (dataPlan?.studentUser) {
                    //si la tarea no esta asignada, muestra el usuario dueño del plan
                    const studentName = `${dataPlan?.studentUser?.firstName} ${dataPlan?.studentUser?.lastName}`
                    const isFamily = dataPlan?.aditionalInfo?.studentType === 'family'
                    const parentName = `${dataPlan?.aditionalInfo?.parentFirstName} ${dataPlan?.aditionalInfo?.parentLastName}`
                    return {studentName, parentName: isFamily && parentName ? parentName : null}
                }
                
            }
            return {studentName: null, parentName:null}
        }, [dataPlan, dataTask]
    )

    const defaultSelected = {
        student: false, parent: false
    }
    const [selected, setSelected] = useState(defaultSelected)
    useEffect(() => {
        //resetea checkboxes
        setSelected(defaultSelected)
         // eslint-disable-next-line
    }, [taskData?.id])

    const [sendTaskReminder, {loading: loadMutation}] = useSendTaskReminderEmail(
        () => {
            showAlert({
                status: 'success',
                text: "Success"
            }, dispatch)
            onChange()
        }, (err) => {
            console.error(err)
            showAlert({
                status: 'error',
                text: err.toString()
            }, dispatch)
        }
    )

    return (
        true && (
            <ModalLayout
                buttonLabel={null}
                className='modal-colored modal-centered fixed-head-modal'
                isOpen={isOpen}
                toggle={toggle}
                topHead={
                    <div className='modal-header px-2 py-3 d-flex flex-row justify-content-between'>
                        <div className='d-flex flex-column'>
                            <IconIllustratedReminder className='mb-3' size={80} />
                            <span className='h2 font-weight-bold'>Send Alert</span>
                        </div>
                    </div>
                }
                disabledScrollAnimation
            >
                <div className='m-0 px-2'>
                    <span className='text-gray'>{`User will be alerted that they are waiting for them in the lesson.`}</span>
                    <div className='d-flex flex-column py-4'>
                        <TabContent activeTab={!loadDataTask && (studentName) ? 'show' : 'loading'}>
                            <TabPane tabId={'loading'}>
                                <Skeleton count={2} height={40} />
                            </TabPane>
                            <TabPane tabId={'show'}>
                                {
                                    studentName && (
                                        <ListItem
                                            className='py-2'
                                            Left={
                                                <div className='d-flex flex-row align-items-baseline'>
                                                    <Avatar
                                                        name={studentName}
                                                    />
                                                    <span className='ml-2'>{studentName}</span>
                                                    <span className='text-gray h5 ml-1'>{`(Student)`}</span>
                                                </div>
                                            }
                                            Right={
                                                <CheckboxOption
                                                    defaultState={selected?.student}
                                                    onChange={(val) => {
                                                        setSelected({...selected, student: val})
                                                    }}
                                                />
                                            }
                                        />
                                    )
                                }
                                {
                                    parentName && (
                                        <ListItem
                                            className='py-2 pl-2'
                                            Left={
                                                <div className='d-flex flex-row align-items-baseline'>
                                                    <Avatar name={parentName}/>
                                                    <span className='ml-2'>{parentName}</span>
                                                    <span className='text-gray h5 ml-1'>{`(Parent)`}</span>
                                                </div>
                                            }
                                            Right={
                                                <CheckboxOption
                                                    defaultState={selected?.parent}
                                                    onChange={(val) => {
                                                        setSelected({...selected, parent: val})
                                                    }}
                                                />
                                            }
                                        />
                                    ) 
                                }
                            </TabPane>
                        </TabContent>
                    </div>
                    <Button
                        disabled={false}
                        onClick={() => {
                            sendTaskReminder({
                                variables: {
                                    sendToStudent: selected.student,
                                    sendToParent: selected.parent,
                                    taskId: taskData?.id
                                }
                            })
                        }}
                        activity={loadMutation}
                        color='primary'
                        className='w-100 mb-6'
                        check
                        size='large'
                        label={"Send email"}
                    />
                </div>
            </ModalLayout>
        ) 
    )
}

export default SendEmailReminder