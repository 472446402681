import React from 'react'
import { PaymentProgress } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'

export const ProgressPlanHover = (props) => {
  const { item } = props
  const { translate } = useTranslation()
  // studentPlans
  const progress = item.studentPlans.reduce((totalApprove, stu) => {
    return totalApprove + (stu.planStatus === 'scheduled' ? 1 : 0)
  }, 0)
  const percent = (progress / item.studentPlans.length) * 100
  return (
    <div className='p-0 m-3'>
      <label className='h45 text-gray mb-2'>{`${percent.toFixed(2)}% ${translate('plans.groupPlans.studentApproved')} (${progress}/${item.studentPlans.length})`}</label>
      <PaymentProgress
        completed={percent} bgColor='#778699' progressColor='#FFB519' data={{
          pendingTotal: 0,
          paidTotal: 0,
          countPending: 0,
          countPaid: 0
        }} omitText
      />
    </div>
  )
}
export default ProgressPlanHover
