import React, { useState } from 'react'
import { Avatar, IconRight, LessonItem } from '@Knowledge-OTP/znk-ui-components'
import { ListGroupItem, Collapse } from 'reactstrap'

const GroupStudentItem = ({ item, onChecked = () => {} , setSelectedLessons = () => {}, selectedLessons = []}) => {
    const [collapse, setCollapse] = useState(false)    
    const toggle = () => {
        setCollapse(!collapse)
    }
    return (
        <ListGroupItem style={{border: 'none', padding: 0}}>
            <div onClick={toggle} className='d-flex flex-row align-items-center justify-content-between pb-3'>
                <div className='d-flex flex-row align-items-center'>
                    <Avatar size='avatar-medium' name={`${item?.firstName} ${item?.lastName}`} />
                    <div className='ml-2'>{item?.firstName} {item?.lastName}</div>
                </div>
                <IconRight size={16} />
            </div>
            <Collapse isOpen={collapse} className='p-1'>
                {
                    item?.lessons?.map(
                        lesson => (
                            <LessonItem
                                dataLesson={lesson}
                                useCheck
                                checked={selectedLessons.includes(lesson.id)}
                                onChangeCheck={(bool) => {
                                    if (!selectedLessons.includes(lesson.id)) {
                                        setSelectedLessons(selectedLessons.concat([lesson.id]))
                                    } else {
                                        setSelectedLessons(selectedLessons.filter(id => id !== lesson.id))
                                    }
                                }}
                            />
                        )
                    )
                }
            </Collapse>
        </ListGroupItem>
    )
}

export default GroupStudentItem

// mm puedes usar Table como en group plans
// pero yo lo haria a mano asi es mas facil de modificar
// y lo del excel ya te lo mando
// znk-admin/src/common/DownloadExcel/useExcelDownload.js
// hice un hook jeje
// tienes que usar la función exportToCsv
// le entregas la data a lafuncion
// el nombre del archivo
// y te lo descarga