import React, {useMemo} from 'react'
import {
  IconIllustratedReminder,
  IconTaskComment,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'

const NoHaveMessages = (props) => {
  const {collection, __typename, noPermission = false} = props
  // eslint-disable-next-line
  const {roles, id: userID} = useSelector((state) => state.user)

  const isAdmin = roles.includes('SUPER_ADMIN')

  const getText = useMemo(() => {
    if (isAdmin && !noPermission) return 'Here you can chat with the student'

    // eslint-disable-next-line
  }, [collection])
  return (
    <div className='h-100 text-center pt-4 d-flex flex-column align-items-center justify-content-center'>
      {(__typename === 'UserTasksOut' || __typename === 'UserTasks') ? (
        <IconTaskComment size={80} />
      ) : (
        <IconIllustratedReminder size={80} />
      )}
      <div className='m-4'>
        <span className='text-gray h4'>{getText}</span>
      </div>
    </div>
  )
}

export default NoHaveMessages
