import React from 'react'
import {LessonItem} from '@Knowledge-OTP/znk-ui-components'

const RenderSelectedLessons = (props) => {
    const {selectedLessons} = props
    return (
        <div className={`w-100 mt-3 mb-5 p-0`} style={{ maxHeight: '300px', overflowY: 'scroll' }}>
            {
                selectedLessons.map(
                    (lesson) => {
                        return (
                            <LessonItem
                                // className={`appears-animation`}
                                dataLesson={lesson}
                                useCheck={false}
                                checked={false}
                                onChangeCheck={(bool) => {
                                }}
                            />
                        )
                    }
                )
            }
        </div>
    )
}
export default RenderSelectedLessons