import React, {useMemo, useState} from 'react'
import {
    ModalLayout,
    useWindowDimensions,
    InputSelect,
    Avatar,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import useMoveStudentModal from './useMoveStudentModal'
import useAllPlans from '../../../Model/useAllPlans'
import useMoveStudentFromGroupPlan from '../../../Model/useMoveStudentFromGroupPlan'
import {showAlert} from '../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
const RenderInput = (props) => {
    const {label, className} = props
    return (
        <div className={`d-flex flex-column m-0 p-0 ${className}`}>
            <span className='h45 text-gray my-2'>{label}</span>
            {props.children}            
        </div>
    )
}
const MoveStudentModal = () => {
    const {isOpen, toggle, dataPlan, onChange} = useMoveStudentModal()
    const dispatch = useDispatch()
    const {translate} = useTranslation()

    const {breakWidth} = useWindowDimensions()
    const {data: allPlans, loading} = useAllPlans({
        kinds: ['group']
    })
    const plans = useMemo(
        () => allPlans?.allPlans?.filter(({serviceId}) => serviceId !== dataPlan.serviceId) || [],
        // eslint-disable-next-line
        [allPlans, dataPlan?.serviceId]
    )
    
    const [planIdsObj, setPlanIdsObj] = useState({
        planIdSelector: null,
    })

    const [moveStudent, {loading: loadMoveStudentMutation}] = useMoveStudentFromGroupPlan(
        (res) => {
            onChange(res.moveStudentFromGroupPlan)
            showAlert(
                {
                    text: 'Success!',
                    status: 'success'
                }, dispatch
            )
        },
        (err) => {
            console.error(err)
            showAlert(
                {
                    text: err.toString(),
                    status: 'error'
                }, dispatch
            )
        }
    )

    return (
        <ModalLayout
            isOpen={isOpen}
            toggle={toggle}
            className={`modal-${
                breakWidth === 'SM' ? 'botbar' : 'centered'
            } modal-colored h-auto`}
            topHead={<span className={`h2 font-weight-bold text-title`}>
                {translate("plans.groupPlans.moveStudent.title")}
                {/* Move student to group plan */}
                </span>}
        >
            <div className='d-flex flex-row align-items-center my-2'>
                <Avatar
                    name={`${dataPlan.studentUser.firstName} ${dataPlan.studentUser.lastName}`}
                    named={false}
                    uri={dataPlan?.getStudentInfo?.avatar || null}
                    size='avatar-medium'
                />
                <span className='ml-2'>{`${dataPlan.studentUser.firstName} ${dataPlan.studentUser.lastName}`}</span>
            </div>
            <RenderInput label={
                translate("plans.groupPlans.moveStudent.moveTo")
                /**'You are gonna move this student to'**/
            }/>
            <RenderInput label={`${translate("plans.groupPlans.moveStudent.selectPlan")}`}>
                <InputSelect
                    className={`w-100`}
                    isLoading={loading}
                    onChange={
                        (planId) => {
                            setPlanIdsObj({
                                ...planIdsObj,
                                planIdSelector: planId
                            })
                        }
                    }
                    value={planIdsObj.planIdSelector}
                    options={
                        plans.map(
                            (plan) => {
                                return {
                                    label: plan?.name,
                                    value: plan?.id
                                }
                            }
                        )
                    }
                />
            </RenderInput>
            {
                /**
                 * 
                 * <RenderInput label={'or'} className={`align-items-center`}/>
            <RenderInput label={'Enter plan id: '}>
                <Input 
                    className={`w-100`} 
                    type='text'
                    value={planIdsObj.planIdText}
                    onChange={
                        (e) => {
                            setPlanIdsObj({
                                ...planIdsObj,
                                planIdText: e.target.value
                            })
                        }
                    }
                />
            </RenderInput>
                 * 
                 * **/
            }
            
            <Button
                className={`w-100 mt-4`}
                label={translate("plans.groupPlans.moveStudent.moveStudent")}
                color={'primary'}
                activity={loadMoveStudentMutation}
                disabled={!planIdsObj.planIdSelector}
                onClick={
                    () => {
                        moveStudent(
                            {
                                variables: {
                                    studentId: dataPlan.studentUser.studentUserId,
                                    originPlanId: dataPlan.groupId,
                                    destinyPlanId: planIdsObj.planIdSelector,
                                }
                            }
                        )
                    }
                }
            />
        </ModalLayout>
    )
}

export default MoveStudentModal