import React,{ useEffect } from 'react'
import {
    Field,
    CheckboxOption,
    ListItem,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'

const SendWspItem = (props) => {
    const {sendWspState, setSendWspState, dataPlan} = props
    const { translate } = useTranslation()
    const onChangeSelectionFn = () => {
        setSendWspState(!sendWspState)}

    useEffect(() => {
        if (dataPlan && dataPlan?.status) {
            setSendWspState(dataPlan.status === 'draft' ? true : false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPlan])

    return (
        <>

            <ListItem
                className='py-2'
                label={null}
                Left={
                    <div className='d-flex align-items-center'>

                        <span className='h5 text-gray'>
                            {translate('plans.modal.sendplan.sendwspstudent')}
                        </span>

                    </div>
                }
                Right={
                    <Field
                        type={CheckboxOption}
                        fieldName={1}
                        checked={sendWspState}
                        onChange={() => {
                            onChangeSelectionFn()
                        }
                        }
                    />
                }
            />



        </>
    )
}

export default SendWspItem
