import React from 'react'
import useTranslation from '../../../../../../../i18n/useTranslation'
import TypeEducators from './TypeEducators'
import TypeIndividualEducators from './TypeIndividualEducators'
import isEmpty from 'lodash/isEmpty'

const PlanEducators = (props) => {
  const { setForm, form, currentService, educatorsBase, multiple, disabled = false } = props
  const { translate } = useTranslation()
  const groupEducators = form.educatorsGroup || {}
  const getTopicsByType = (type) => {
    return Object.values(form.lpw).reduce((arr, part) => {
      const thisTopics = Object.keys(part).reduce((arrayAccu, idTopic) => {
        if (part[idTopic][`value${type}`] > 0) {
          return arrayAccu.concat([idTopic])
        } else {
          return arrayAccu
        }
      }, [])
      return arr.concat(thisTopics)
    }, [])
  }
  // Gets all Topics that have at least 1 lesson per week and creates the array to check Max Hours
  const individualTopicsSpan = isEmpty(form) ? [] : Object.values(form.lpw).reduce((topics, part) => {
    const { startdate, enddate, partname, typeLesson, ...partTopics } = part
    Object.entries(partTopics).forEach(([idTopic, values]) => {
      if (values.valueIndividual > 0) {
        if (!Object.keys(topics).includes(idTopic)) {
          topics[idTopic] = {
            name: values.name,
            prospectiveHours: [{
              from: startdate,
              to: enddate,
              lessonsPerWeek: values.valueIndividual
            }]
          }
        } else {
          topics[idTopic].prospectiveHours.push({
            from: startdate,
            to: enddate,
            lessonsPerWeek: values.valueIndividual
          })
        }
      }
    })
    return topics
  }, {})
  return (
    <div className='py-6 d-flex flex-column'>
      <h3 className='font-weight-bold'>{translate('program.plan.infoModal.educators')}</h3>
      <TypeEducators {...{ form, setForm, currentService }} educators={groupEducators} type='Group' thisTopics={getTopicsByType('Group')} educatorsBase={educatorsBase} disabled={disabled} />
      <TypeIndividualEducators {...{ form, setForm, currentService }} educators={form.educatorsIndividualFull} type='Individual' educatorsBase={educatorsBase} multiple={!!multiple} topics={individualTopicsSpan} />
      {/* <div className='borderTop my-2' /> */}
    </div>
  )
}

export default PlanEducators
