import { gql, useQuery } from '@apollo/client'
import validate from './validation'

export default (vars) => {
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return { loading: false, error: errors, data: {} }
  }

  const { data, error, loading } = useQuery(
    gql`query ($educatorId: ID, $minDurationInMinutes: Int, $to: DateTime!, $from: DateTime!) {
    myFreeTimeFrames(
      educatorUserId: $educatorId,
      minDurationInMinutes: $minDurationInMinutes,
      to: $to,
      from: $from
    )
  }`,
    {
      variables: vars
    }
  )
  return { loading, data: data ? data.myFreeTimeFrames : {}, error }
}
