import React from 'react'
import {
  EducatorNote
} from '@Knowledge-OTP/znk-ui-components'
import useSetEducatorNote from '../Model/SetEducatorNote/useSetEducatorNote'

const CreateEducatorNote = (props) => {
  const { userId, text, onSave, data } = props

  const { submit: submitEducatorNote, loading: loadingUpdateNote/**, validationErrors: validateUpdateErrors**/ } = useSetEducatorNote(() => {
    onSave()
  }, console.log)
  return (
    <EducatorNote
      data={data}
      textNote={text}
      canDelete={true}
      activity={loadingUpdateNote}
      onCreate={(newText) => {
        submitEducatorNote({ userId: userId, educatorNote: newText })
      }}
      canEdit={!text} />
  )
}

export default CreateEducatorNote
