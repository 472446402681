import { gql, useLazyQuery } from "@apollo/client"
import { examScoreResult } from "./useLazyPlan"

const useLazyScoreByStudent = (variables = {}) => {
    return useLazyQuery(
        gql`
            query (
                $studentId: ID!
            ) {
                getScoresByStudentId (
                    studentId: $studentId
                ) {
                    id
                    planId
                    taskId
                    examId
                    name
                    examScoreId
                    studentId
                    serviceId
                    examDate
                    overall
                    ${examScoreResult}
                }
            }
        `,
        { variables }
    )
}

export default useLazyScoreByStudent