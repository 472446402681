import React, { useEffect, useMemo } from 'react'
import {
  ListItem,
  Field,
  PlainTextInput,
  IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import AddPayment from './AddPayment'
import useTranslation from '../../../../../../../i18n/useTranslation'
import getPayerUser from '../../../../../../../Model/Users/Get/GetPayerUser'
import { getFeeFromRangeDate, getTotalLessons } from '../../utils'

const PaymentScheduleNew = (props) => {
  // Declaraciones
  const {
    form,
    setForm,
    amountTotalGroupLessons
  } = props
  const { translate } = useTranslation()
  // Calculos cada vez que renderiza
  let start = form.from
  let end = form.to
  let payments = []

  if (form.typeLesson === 'totalLesson' && form.typePlan === 'time') {
    payments = getFeeFromRangeDate(moment(start), moment(start).add(2, 'week'))
  } else if (start !== null && end !== null) {
    start = moment(start)
    end = moment(end)
    payments = getFeeFromRangeDate(start, end)
  }
  const [listIndiv, listGroup] = getTotalLessons(form)
  const totalLessonsPayment = (listIndiv * (form.payments.lessons ? form.amount || 0 : 0) + (listGroup * (form.payments.groupLessons ? form.groupAmount || 0 : 0)))
  const totalPay = form.typePlanPricing === 'permonth' ? (form.payments.lessons ? form.amount : 0) * payments.length : totalLessonsPayment
  const canAddPayment = form.cuotas ? form.cuotas.length > 0 : false
  const valueWhenPlanApr = Object.keys(form.payments.values).reduce(
    (accu, payKey) => {
      if (form.payments[payKey]) {
        return accu + (form.payments.values[payKey] - form.payments.values[payKey] * (form.payments.discount[payKey] / 100))
      } else {
        return accu
      }
    }, 0)
  const discountToFee = form.payments.discount.lessons > 0 ? (1 - (form.payments.discount.lessons / 100)) : 1
  // Efectos
  const lastPay = useMemo(() => form.cuotas && form.cuotas.length ? form.cuotas[form.cuotas.length - 1].time : moment(), [form.cuotas])

  useEffect(() => {
    InitPayment()
    // eslint-disable-next-line
  },[form.lpw, form.typePlan, form.amount, form.groupAmount, form.typePlanPricing, form.payments])

  useEffect(() => {
    if (form.paymentstart) {
      const baseMonth = moment.utc(form.paymentstart).endOf('month').add(-2, 'd')
      if (form.cuotas && form.paymentstart && form.cuotas.length > 0 && moment(form.paymentstart).format() > moment(form.cuotas[0].time).format()) {
        setCuotas(form.cuotas.map((c) => {
          c.time = baseMonth.clone()
          baseMonth.add(1, 'month')
          return c
        }))
      }
    }
  }, [form.paymentstart]) // eslint-disable-line

  // funciones
  const setCuotas = (cuotas) => {
    setForm(pre => {
      return {
        ...pre,
        cuotas
      }
    })
  }
  const InitPayment = () => {
    //* crea los payments iniciales
    //* form.lpw['0'] es el indice de la primera parte
    //* parts - 1 es el indice de la ultima parte por lo tanto debería existir form.lpw[part-1]
    //* obtener la fecha de inicio de la primera parte y la fecha de fin de la última parte
    const parts = Object.keys(form.lpw).length
    let start = form.lpw['0'] ? form.lpw['0'].startdate ? form.lpw['0'].startdate : null : null
    let end = form.lpw[parts - 1] ? form.lpw[parts - 1].enddate ? form.lpw[parts - 1].enddate : null : null
    let payments = []
    if (form.typeLesson === 'totalLesson' && form.typePlan === 'time') {
      payments = getFeeFromRangeDate(moment(start), moment(start).add(2, 'week'))
    } else if (start !== null && end !== null) {
      start = moment(start)
      end = moment(end)
      payments = getFeeFromRangeDate(start, end)
    }
    const [listIndiv, listGroup] = getTotalLessons(form)
    setCuotas(payments.map((pay) => {
      const aux = (listIndiv * (form.amount || 0) + (amountTotalGroupLessons || (listGroup * (form.groupAmount || 0)))) / payments.length
      // const value = form.typePlanPricing === 'permonth' ? ((form.payments.groupLessons ? (amountTotalGroupLessons || (form.groupAmount || 0)) : 0) + (form.payments.lessons ? form.amount : 0)) : aux
      const value = form.typePlanPricing === 'permonth' ? (form.amount / payments.length).toFixed(2) : aux
      return { ...pay, value: value }
    }))
  }
  const deletePaymentItem = (index) => newValue => {
    const oldValue = parseFloat(form.cuotas[index].value)
    const auxArray = []
    if (form.cuotas.length > 1) {
      form.cuotas.forEach((obj, ind) => {
        if (ind !== index) {
          auxArray.push(obj)
        }
      })
      auxArray[auxArray.length - 1].value = parseFloat(auxArray[auxArray.length - 1].value) + oldValue
      setCuotas(auxArray)
    } else {
      alert('No es posible eliminar todas las cuotas')
    }
  }
  const editPaymentItem = (index) => (newValue, date) => {
    // cantidad total de cuotas no customizadas
    let cTotal = 0
    let total = form.cuotas.reduce((totalRed, cuota, indx) => {
      if (!cuota.custom && indx !== index) { cTotal += 1 }
      return (indx === index) ? newValue + totalRed : (cuota.custom) ? parseFloat(cuota.value) + totalRed : totalRed
    }, 0) // valor total de cuotas customizadas
    let valorCuota = 0
    // se calcula el monto a repartir entre las cuotas no customizadas
    total = totalPay - total
    if (cTotal > 0) {
      // valor de las cuotas no customizadas
      valorCuota = total / (cTotal)
    }
    setCuotas(
      form.cuotas.map((c, indx) => {
        if (index === indx) {
          return { ...c, value: newValue, custom: true, time: date }
        } else if (c.custom) {
          return c
        } else {
          return { ...c, value: valorCuota }
        }
      })
    )
    // setCanDistributed(true)
  }
  const addPaymentItem = (newPayment) => {
    // cantidad total de cuotas no customizadas
    let cTotal = 0
    const total = form.cuotas.reduce((totalRed, cuota) => {
      if (!cuota.custom) { cTotal += 1 }
      return !cuota.custom ? parseFloat(cuota.value) + totalRed : totalRed
    }, 0) // valor total de cuotas no customizadas
    let valorCuota = 0
    if (cTotal > 0) {
      valorCuota = total / (cTotal + 1)
    }
    newPayment = { ...newPayment, value: valorCuota }
    setCuotas(
      form.cuotas.concat([newPayment]).map((c) => {
        if (c.custom) {
          return c
        } else {
          return { ...c, value: valorCuota }
        }
      })
    )
  }
  const distributedPayment = async () => {
    const total = form.cuotas.reduce((totalRed, cuota) => {
      return cuota.value + totalRed
    }, 0) // valor total de cuotas no customizadas
    const cTotal = form.cuotas.length
    let valorCuota = 0
    if (cTotal > 0) {
      valorCuota = total / cTotal
    }
    setCuotas(
      form.cuotas.map((c) => {
        return { ...c, value: valorCuota, custom: false }
      })
    )
    // setCanDistributed(false)
  }
  // colocar id del usuario (estudiante o padre del estudiante) para obtener el metodo de pago
  const { data: dataPaymentMethod } = getPayerUser({ userId: form.student ? form.student.id : '' })
  const canReset = true
  const canDistributed = true
  return (
    <>
      <div className='py-6'>
        <h3><b>{translate('modal.newplan.paymentschedule.title')}</b></h3>
        <div className='row pl-3'>
          <label className='text-muted mb-2 mt-3 pl-0'>{`${translate('modal.newplan.paymentschedule.paymentstart')} (${form.paymentstart ? translate('profile.payments.endOf', moment(form.paymentstart).format('MMMM')) : translate('modal.newplan.paymentschedule.selectdate')})`}</label>
          <Field
            type={PlainTextInput}
            fieldName='paymentstart'
            fieldType='date'
            InputClassName='col-12'
          />
          <ListItem
            className='col-12 pr-1 pl-0 my-2 py-2'
            Left={
              <label className='text-muted h45'>
                {translate('modal.newplan.paymentschedule.paymentmethod')}
              </label>
            }
            Right={
              <label className='text-muted h45'>
                {dataPaymentMethod && dataPaymentMethod.paymentMethod
                  ? dataPaymentMethod.paymentMethod.last4
                  : translate('modal.newplan.paymentschedule.nodefined')}
              </label>
            }
          />
          <div className='w-100'>
            <ListItem
              className='py-2 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label>{translate('modal.newplan.paymentschedule.whenapproving')}</label>
              }
              Right={
                <label>{`$${valueWhenPlanApr ? valueWhenPlanApr.toFixed(2) : 0}`}<IconArrowRight /></label>
              }
            />
            {form.cuotas && form.cuotas.length > 0 ? form.cuotas.map((obj, index) => {
              return (
                <ListItem
                  key={index}
                  className='py-2 w-100'
                  showBottomLine={false}
                  showTopLine={false}
                  Left={
                    <label>
                      <span>
                        {translate('modal.newplan.endof') + ' ' + moment(obj.time).format('MMMM')}
                      </span>
                      <span className='text-muted h45'>
                        {`    (${translate('modal.newplan.indexFee', {
                          num1: parseInt(index + 1), num2: parseInt(form.cuotas.length)
                        })})`}
                      </span>
                    </label>
                  }
                  Right={
                    <AddPayment time={moment(obj.time)} value={obj.value} customPayment={false} deleteCuota={deletePaymentItem(index)} setCuotas={editPaymentItem(index)} maxValue={totalPay}>
                      <label>{`$${(obj.value * discountToFee).toFixed(2)}`} <IconArrowRight /></label>
                    </AddPayment>
                  }
                />
              )
            }) : <label>{translate('modal.newplan.paymentschedule.selectlimitdates')}</label>}
            <ListItem
              key={-1}
              className='py-2 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label>{'Total: '}</label>
              }
              Right={
                <label>{`$${((totalPay * discountToFee) + valueWhenPlanApr).toFixed(2)}`}</label>
              }
            />
          </div>
          <label
            className={`${canAddPayment ? 'btn-link w-100' : 'text-muted  w-100'}`} onClick={() => {
              if (canAddPayment) {
                addPaymentItem({ time: moment(lastPay).add(1, 'month'), value: 0, custom: false })
              }
            }}
          >
            {translate('modal.newplan.paymentschedule.addmore')}
          </label>
          <label className={`${canDistributed ? 'btn-link text-error w-100' : 'text-muted w-100'}`} onClick={canDistributed ? distributedPayment : null}>
            {translate('modal.newplan.paymentschedule.distribute')}
          </label>
          <label className={`${canReset ? 'btn-link text-error w-100' : 'text-muted w-100'}`} onClick={canReset ? InitPayment : null}>
            {translate('modal.newplan.paymentschedule.reset')}
          </label>
        </div>
      </div>
      <div className='borderTop my-2' />
    </>

  )
}

PaymentScheduleNew.propTypes = {
}

PaymentScheduleNew.defaultProps = {
}
export default PaymentScheduleNew
