import moment from 'moment'
const now = moment()
const initialState = {
    from: now.toISOString(),
    to: now.toISOString(),
    totalAmount: 0,
    payments: [],
    defaultPayments: []
}
export default initialState

