import {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'

export default (onSuccess, onError = () => {}) => {
  const [mutation, {data, error, loading}] = useMutation(
    gql`
        mutation(
              $studentId: ID!
              $universityId: ID!
              $attending: Boolean
              ){
                setAttendingUniversity (
                    universityId: $universityId
                    studentId: $studentId
                    attending: $attending
                ) {
                    id
                    studentInfo{
                      id
                        universities{
                            id
                            status
                            early
                            name
                            collegeId
                            isPrincipal
                            attending
                            logo
                    }
                }
              }
            }
      `,
  )
  const submit = useCallback(
    (obj) => {
      mutation({variables: obj})
        .then((result) => {
          onSuccess(result)
        })
        .catch((e) => {
          onError(e)
        })
    },
    [mutation, onSuccess, onError],
  )
  return {loading, data: data ? data.setAttendingUniversity : null, error, submit}
}
