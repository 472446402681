import { gql, useQuery } from '@apollo/client'
import planResult from './planQueryResult'

const useStudentDashboardPlansEndSoon = (vars = {}) => {
    return useQuery(
        gql`
        query {
            studentDashboardPlansEndSoon {
                ${planResult}
            }  
        }`,
        {
            variables: vars,
            fetchPolicy: 'cache-first'
        }
    )
}

export default useStudentDashboardPlansEndSoon