import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useDeleteEventModal from './useDeleteEventModal'

const DeleteEventModal = () => {
    const {isOpen, toggle, close, eventId, onChange} = useDeleteEventModal()
    return (
        <ConfirmModal 
            classNameActionDiv={`d-flex flex-column`}
            classNameConfirm={'text-error white-space-nowrap'}
            isOpen={isOpen}
            toggle={toggle}
            title={'Do you want to delete this event?'}
            body={'This action can not be undone'}
            confirmAction={() => {
                onChange(eventId)
            }}
            cancelAction={() => {close()}}
            confirmText={'Delete event'}
            cancelText={'Cancel'}
            loading={false}
        />
    )
}

export default DeleteEventModal