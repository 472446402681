import {useMutation, gql} from '@apollo/client'

const useCreatePdfTemplate = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $name: String!
                $json: String!
                $key: String!
            ) {
                createPdfTemplate (
                    templateInput: {
                        name: $name,
                        json: $json,
                        key: $key
                    } 
                ) {
                    id
                    name
                    json
                    key
                }
            }
        `, {
            onCompleted: onSuccess,
            onError
        }
    )
}

export default useCreatePdfTemplate