import React from 'react'
import {
  FilterBetweenDatesInner
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'

const StartDateFilter = (props) => {
  const { translate } = useTranslation()
  const { startDateFilter, setStartDateFilter, typeFilter } = props

  return (
      <FilterBetweenDatesInner
      className={'h45'}
        strings={{
          title: translate('filters.plans.startDateTitle'),
          buttonCancel: translate('filters.plans.buttonCancel'),
          buttonApply: translate('filters.plans.buttonApply'),
          from: translate('filters.plans.fromDate'),
          to: translate('filters.plans.toDate')
        }}
        setDates={setStartDateFilter}
        typeFilter={typeFilter}
        dates={startDateFilter}
        direction={`bottomCenter`}
      />
  )
}
export default StartDateFilter
