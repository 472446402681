import { useSelector, useDispatch } from "react-redux";
import { SET_DATA_MODAL_FORM_LIST} from "../../../../store/modal/const";

const useViewInvoice = () => {

    const dispatch = useDispatch();
    const reduxData  = useSelector((state) => state.modal.payments.viewInvoice);
    const { isOpen, invoiceId } = reduxData;

    const setInvoiceData = (invoiceId) => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'payments.viewInvoice',
            data: {
                ...reduxData,
                invoiceId,
    },
    })
}
    const setOpen = (open, invoiceId = '') => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'payments.viewInvoice',
            data: {
                ...reduxData,
                isOpen: open,
                invoiceId,
    },
        });
    };
    
    return {
        isOpen,
        invoiceId,
        open: (invoiceId) => {setOpen(true, invoiceId)},
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)},
        setInvoiceData,
    };
}

export default useViewInvoice;
