import { gql, useLazyQuery } from '@apollo/client'

const useGetEmailsFromInvoices = () => {
  return useLazyQuery(
    gql`query(
        $limit: Int,
        $next: String,
        $previous: String,
        $sortField: String,
        $status: [PaymentStatus!],
        $userId: [String!],
        $parentUserId: [String!],
        $from: DateTime,
        $to: DateTime,
        $category: [PaymentCategory!],
        $searchString: String,
        $current: Boolean,
        $pending: Boolean,
        #$order: Float
      ) {
        invoices(
          limit: $limit,
          next: $next,
          previous: $previous,
          sortField: $sortField,
          status: $status, 
          userId: $userId,
          parentUserId: $parentUserId,
          from: $from,
          to: $to,
          category: $category,
          searchString: $searchString
          current: $current,
          pending: $pending,
          #order: $order
        ) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs            
            docs {
              userId {
                emails {
                    address
                  }
                studentInfo {
                    parents {
                        email
                    }
                }
              }
            }
          }
        }`,
    {
      fetchPolicy: 'no-cache'
    }
  )
}

export default useGetEmailsFromInvoices