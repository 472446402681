import React, {useState} from 'react'
import { ModalLayout, Button } from '@Knowledge-OTP/znk-ui-components'
import ListItem from './ListItem'

const ProccessEducatorCalendar = (props) => {
    const {selectedEducators} = props
    const [isOpen, setOpen] = useState(false)
    const toggle = () => {setOpen(!isOpen)}

    const [proccessEducatorIndex, setProccesingEducatorIndex] = useState(null)
    return (
        <>
            <div className={`m-0 p-0`} onClick={toggle}>
                {props.children}
            </div>
            <ModalLayout
                className={`modal-centered modal-colored`}
                isOpen={isOpen}
                toggle={toggle}
                disabledScrollAnimation
                topHead={
                    <span className={`text-title h2 font-weight-bold`}>
                        Proccess Google Calendar
                    </span>
                }
            >

                <div className='d-flex flex-column text-gray h45'>
                    <label>Create (create)</label>
                    <label>Rebuild (create - populate)</label>
                    <label>Populate (clean - populate)</label>
                </div>
                {
                    selectedEducators.map((user, index) => {
                        return (
                            <ListItem 
                                defaultProccess={'rebuild'}
                                isCurrentElement={proccessEducatorIndex === index}
                                onCompleted={() => {
                                    if(index === selectedEducators.length - 1) return
                                    setProccesingEducatorIndex(index + 1)
                                }}
                                user={user}/>
                        )
                    })
                }
                <Button 
                    onClick={() => {
                        setProccesingEducatorIndex(0)
                    }}
                    color={'primary'}
                    label={'Start'}
                    className={`w-100 mt-3`}
                />
            </ModalLayout>
        </>
    )
}

export default ProccessEducatorCalendar