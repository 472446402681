import { useLazyQuery, gql } from '@apollo/client'

export const UseGetCourseMaterials = () => {
    return useLazyQuery(
        gql`
        query (
            $userStudentId: ID
        ) {
            getCourseMaterials (
                userStudentId: $userStudentId
            ) {
                serviceId
                topicId
                link
            }
        }
        `
    ) 
}
