import React, {useEffect} from 'react'
import {Badge} from 'reactstrap'
const phaseFilters = {
    'pre-application' : 'Pre Application',
    'application' : 'Application',
    'post-application' : 'Post Application',
}
const PhasesFilter = (props) => {
    const {filters = [], setFilters = () => {}} = props
    // const [selected, setSelected] = useState(Object.keys(phaseFilters))
    useEffect(
        () => {
            setFilters(Object.keys(phaseFilters))
            // eslint-disable-next-line
        }, []
    )
    const allSelected = Object.keys(phaseFilters).reduce(
        (bool, id) => bool && filters.includes(id), 
        true
    ) 
    return (
        <>
            <Badge
                color={allSelected ? 'primary' : 'secondary'}
                className='ml-0 mr-1 my-0 px-3 mr-1 p-0 cursor-pointer'
                pill
                onClick={() => {setFilters(Object.keys(phaseFilters))}}
            >
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                    All
                </label>
            </Badge>
            {
                Object.entries(phaseFilters).map(
                    ([phaseId, phaseLabel], index) => {
                        const isSelected = filters.includes(phaseId)
                        const lastSelected = isSelected && filters.length === 1
                        return (
                            <Badge
                                key={index}
                                onClick={() => {
                                    if(lastSelected) return
                                    
                                    if(isSelected) {
                                        setFilters(filters.filter((id) => id !== phaseId))
                                    }
                                    else setFilters(filters.concat(phaseId))
                                }}
                                color={isSelected ? 'primary' : 'secondary'}
                                className={`ml-0 mr-1 my-0 px-3 mr-1 p-0 ${lastSelected ? '' : 'cursor-pointer'}`}
                                pill
                            >
                                <label style={{ cursor: lastSelected ? 'not-allowed' : 'pointer' }} className='m-0 p-0'>
                                    {phaseLabel}
                                </label>
                            </Badge>
                        )
                    }
                )
            }
        </>
    )
}

export default PhasesFilter