import React from 'react'
import {
  Modal,
  IconClose,
  TimezoneSelector
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { showAlert } from '../../../../common/Alert/util'
import { useMutation, gql } from '@apollo/client'
import { CHANGE_TIMEZONE } from '../../../../store/timezone/const'

const ChangeTimezone = () => {
  const { isOpen } = useSelector((state) => state.modal.lesson.changeTimezoneModal)
  const { translate } = useTranslation()

  const zone = useSelector((state) => state.timezone.zone)
  
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'lesson.changeTimezoneModal',
      data: { isOpen: !isOpen }
    })
  }

  const [setTimezone, {loading}] = useMutation(
    gql`
      mutation($input: UpdateUserInput!, $id: ID) {
        updateUser(input: $input, id: $id) {
          id
          timezone
        }
      }
    `,
    {
      update: (cache, {data: {updateUser: {timezone}}}) => {
        dispatch({
          type: CHANGE_TIMEZONE,
          payload: timezone
        })
        showAlert({
          text: translate('lessons.calendar.modal.changeTimezoneSuccess'),
          status: 'success'
        }, dispatch)
        toggle()
      },
      onError: () => {
        showAlert({
          text: translate('lessons.calendar.modal.changeTimezoneError'),
          status: 'error'
        }, dispatch)
      }
    },
  )

  return (
    <>
      <Modal
        buttonLabel={null}
        className='modal-colored modal-centered fixed-head-modal'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header p-6 d-flex flex-row justify-content-between'>
            <span className='h2 font-weight-bold'>{translate('lessons.calendar.modal.changeTimezone')}</span>
            <IconClose onClick={toggle} />
          </div>
        }
      >
        <div className='m-0 px-6'>
          <TimezoneSelector
            selected={zone}
            mutation={(current) => {
              setTimezone({
                variables: {
                  input: {timezone: current},
                }
              })
            }}
            activity={loading}
          />
        </div>
      </Modal>
    </>
  )
}

export default ChangeTimezone
