import { CheckboxSelector, IconArrowDown } from '@Knowledge-OTP/znk-ui-components'
import React from 'react'
import { Badge } from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'
import useCountries from '../../../Model/Countries/List'

const CountryFilter = (props) => {
  const { translate } = useTranslation()
  const { 
    countryFilters, 
    setCountryFilters, 
    setNamesDataFilters, 
    namesDataFilters 
  } = props
  
  const { data: countries } = useCountries({ limit: 0 })

  return (
    <CheckboxSelector
      setDataFilters={setCountryFilters}
      direction='bottom'
      dataFilters={countryFilters}
      setNamesDataFilters={setNamesDataFilters} 
      namesDataFilters={namesDataFilters}
      typeFilter='services'
      data={(countries?.docs || []).map(item => ({ name: item.name, id: item.id })).sort((a, b) => a.name.localeCompare(b.name))}
      strings={{
        viewAll: translate('filters.plans.viewAll'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search')
      }}
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.plans.countriesTitle')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default CountryFilter
