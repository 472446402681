import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
import validateEducator from './validationEducator'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation (
      $userId: ID!,
      $typeUser: Roles!,
      $backupLink: String,
      $weeklyCapHours: Int,
      $materials: String,
      $googleCalendar: String,
      $handbook: String,
      $sinceDate: Int,
    ) {
        updateGeneralInfo(
            userId: $userId
            typeUser: $typeUser,
            backupLink: $backupLink,
            weeklyCapHours: $weeklyCapHours,
            materials: $materials,
            googleCalendar: $googleCalendar,
            handbook: $handbook,
            sinceDate: $sinceDate,
        ){
      id
      createdAt
      emails {
        address
        verified
      }
      countryId
      gender
      spokenLanguages
      address
      city
      state
      zipCode
      firstName
      lastName
      phone
      timezone
      birthdate
      studentInfo {
        institution
        graduationDate
        gpa
      }
      parentInfo {
        children {
          studentUserId
          managePayments
          manageApprovals
          firstName
          lastName
          email
        }
      }
      educatorInfo {
        id
        sinceDate
        services {
          serviceId
          name
          defaultRate
          topics {
            topicId
            teachingTypes
            name
          }
        }
        backupLink
      }
      roles
        }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = obj.typeUser === 'EDUCATOR' ? validateEducator(obj) : validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error', obj.typeUser)
      return
    }
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.updateGeneralInfo : null, error, validationErrors, submit }
}
