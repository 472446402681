import { gql, useLazyQuery } from '@apollo/client'

const useCheckUsersLessonsMatch = (onCompleted = () => {}, onError = () =>{}) => {
  return useLazyQuery(
    gql`query(
        $isStudent: Boolean!,
        $userId: String!,
      ) {
        checkUsersLessonsMatch(
            isStudent: $isStudent,
            userId: $userId,
        ) {
            studentUserId
            firstName
            lastName
            lessons {
              lessonId
              startDate
              endDate
              title
              kind
              id
              status
              educator {
                firstName
                lastName
              }
            }
        }
      }`,
    {
      fetchPolicy: 'network-only',
      onCompleted, onError
    }
  )
}

export default useCheckUsersLessonsMatch