import React from "react";
import { Avatar } from '@Knowledge-OTP/znk-ui-components'

const useDesktopColumns = () => {

  return [
    {
      render: (item) => {
        const [{ firstName, lastName }] = item?.studentInfo
        const studentName = `${firstName} ${lastName}`

        return (
          <div className={`d-flex flex-row align-items-center m-0 p-0`}>
            <Avatar name={studentName} uri={item?.avatar} />
            <span className={`ml-2`}>{studentName}</span>
          </div>
        );
      },
      colSpan: 4,
      id: "studentName",
    },
    {
      render: (item) => {
        const [{ studentInfo }] = item?.studentInfo
        const { classOf } = studentInfo;
        return (
          <span className='text-gray'>
            {`Class of ${classOf}`}
          </span>
        );
      },
      className: `d-flex flex-row m-0 p-0 justify-content-center`,
      colSpan: 4,
      id: 'testDate',
    },
    {
      render: (item) => {
        const totalExams = item?.exams.length;
        return <span className={`text-gray`}>{`${totalExams} Exams`}</span>;
      },
      className: `d-flex flex-row justify-content-end m-0 p-0`,
      colSpan: 4,
      id: "totalExams",
    },
  ];
};

export default useDesktopColumns;
