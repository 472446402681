export const ordenateCountriesArray = (array) => {
    if (array?.length < 1) return
    return array?.map((country) => {
      return {
        label: country.label,
        value: country.value
      }
    }).sort((a, b) => {
      if (a.label > b.label) return 1
      if (a.label < b.label) return -1
      return 0
    })
  }

export const parseCountrySelector = (array) => {
    if (array?.length === 0) return
    const countries = array?.map(
        e => {
          return { value: e.id, label: e.name }
        }
      ) 
   return ordenateCountriesArray(countries)
}
