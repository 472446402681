import { useSelector, useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../store/modal/const'
import get from 'lodash/get'
import { useCallback } from 'react'
const useModalState = (stateKey) => {
  const { isOpen, ...modalData } = useSelector((state) =>
    get(state.modal, stateKey)
  )
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: stateKey,
      data: { isOpen: !isOpen, ...modalData }
    })
  }
  const onChange = useCallback((setFormFunc) => {
    const newVal = setFormFunc(modalData)
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: stateKey,
      data: { isOpen: true, ...newVal }
    })
  }, [dispatch, stateKey, modalData])
  return {
    isOpen,
    modalData,
    toggle,
    onChange
  }
}

export default useModalState
