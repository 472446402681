import {
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import beanScheduled from '../../beanScheduled.svg'
import beanCancel from '../../beanCancel.svg'

const useBubblesModalHeader = (status) => {
    const {breakWidth} = useWindowDimensions()
    let beanImage;
    switch (status) {
        case 'scheduled': 
            beanImage = beanScheduled
            break
        case 'cancel': 
            beanImage = beanCancel
            break
        default:
            beanImage = beanScheduled
            break
    }

    const style = {
        backgroundImage: `url(${beanImage}), url(${beanImage}), url(${beanImage})`,
        backgroundPosition: '247% -50%, -32% 86%, 70% 15%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '300px, 170px, 50px',
        maxHeight: '100vh',
    }

    const styleMD = {
        backgroundImage: `url(${beanImage}), url(${beanImage}), url(${beanImage})`,
        backgroundPosition: '280% -53%, -37% 74%, 70% 15%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '300px, 170px, 50px',
        maxHeight: '100vh',
    }
    
    const styleSM = {
        backgroundImage: `url(${beanImage}), url(${beanImage}), url(${beanImage})`,
        backgroundPosition: '300% -40%, -37% 74%, 70% 15%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '230px, 170px, 50px',
        maxHeight: '100vh',
    }
    
    return breakWidth === 'SM' ? 
        styleSM : 
            breakWidth === 'MD' ? 
            styleMD : 
            style
}

export default useBubblesModalHeader