import { gql, useLazyQuery } from '@apollo/client'
import useExcelDownload from '../../../common/DownloadExcel/useExcelDownload'
import moment from 'moment'

const useDownLoadExcelUsers = () => {
    const exportToCSV = useExcelDownload()
  return useLazyQuery(
    gql`
    query {
        users {
            id
            gender
            country {
              name
            }
            emails {
              address
              verified
            }
            timezone
            firstName
            lastName
            phone
            spokenLanguages
            address
            city
            state
            zipCode
            birthdate
            studentInfo {
              institution
              studentType
              graduationDate
              gpa
              defaultRate
              parents {
                parentUserId
                firstName
                lastName
                email
                phone
                managePayments
                manageApprovals
              }
            }
            educatorInfo {
              rating
              defaultRate
            }
        }
    }
    `,
    {
        fetchPolicy: 'no-cache',
        onCompleted: ({users}) => {
            //country, educatroInfo, studentInfo, emails

            exportToCSV(
                users.map((user) => {
                    const {country, educatorInfo, studentInfo, emails, ...userInfo} = user
                    const email = emails.find(({verified}) => verified)
                    return {
                        ...userInfo,
                        birthdate: userInfo?.birthdate ? moment(userInfo.birthdate).format('YYYY-MM-DD') : 'No defined', 
                        country: country?.name || 'No defined',
                        email: email?.address || 'No defined',
                        ...educatorInfo,
                        ...studentInfo
                    }
                }), 
                "allUsers"
            )
        }
    }
  )
}

export default useDownLoadExcelUsers


// import React from 'react'
// import {Button, IconDocument, IconLoad} from '@Knowledge-OTP/znk-ui-components'
// import useExcelDownload from '../../../common/DownloadExcel/useExcelDownload'
// import { useLazyQuery, gql } from '@apollo/client'
// import moment from 'moment'

// const DownLoadExcelUsers = (props) => {
//     const exportToCSV = useExcelDownload()
//     const [getDataUsers, {loading}] = useLazyQuery(
//         gql`
//         query {
//             users {
//                 id
//                 gender
//                 country {
//                   name
//                 }
//                 emails {
//                   address
//                   verified
//                 }
//                 timezone
//                 firstName
//                 lastName
//                 phone
//                 spokenLanguages
//                 address
//                 city
//                 state
//                 zipCode
//                 birthdate
//                 studentInfo {
//                   institution
//                   studentType
//                   graduationDate
//                   gpa
//                   defaultRate
//                   parents {
//                     parentUserId
//                     firstName
//                     lastName
//                     email
//                     phone
//                     managePayments
//                     manageApprovals
//                   }
//                 }
//                 educatorInfo {
//                   rating
//                   defaultRate
//                 }
//             }
//         }
//         `,
//         {
//             fetchPolicy: 'no-cache',
//             onCompleted: ({users}) => {
//                 //country, educatroInfo, studentInfo, emails

//                 exportToCSV(
//                     users.map((user) => {
//                         const {country, educatorInfo, studentInfo, emails, ...userInfo} = user
//                         const email = emails.find(({verified}) => verified)
//                         return {
//                             ...userInfo,
//                             birthdate: userInfo?.birthdate ? moment(userInfo.birthdate).format('YYYY-MM-DD') : 'No defined', 
//                             country: country?.name || 'No defined',
//                             email: email?.address || 'No defined',
//                             ...educatorInfo,
//                             ...studentInfo
//                         }
//                     }), 
//                     "allUsers"
//                 )
//             }
//         }
//     )
//     return (
//         <Button
//             size={'small'}
//             color={'primary'}
//             className={`w-auto`}
//             label={loading ? <IconLoad/> : <IconDocument size={15} />}
//             onClick={() => {
//                 getDataUsers()
//             }}
//         />
//     )
// }

// export default DownLoadExcelUsers