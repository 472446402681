//Deprecated?? El hook abre el modal src/Pages/CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/Detail/index.js
import React, { useState } from 'react'
import {
    ModalLayout,
    RedRibbon,
    ScoreItem,
    ScoreListItem,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'

const ScoresModal = (props) => {

    const { task } = props
    const isEdit = task?.status === "pending" ? true : false
    // eslint-disable-next-line
    const [scoresToShow, setScoresToShow] = useState(task?.resolveTask?.score)

    return (
        <ModalLayout
            className='modal-colored modal-sidebar modal-desktop-half exam-modal'
            // isOpen={isOpen}
            // toggle={toggle}
            topHead={
                <div className='background-head'>
                    <div className='modal-header px-6 px-sm-7 mt-0 pt-8 d-flex flex-column'>
                        <RedRibbon
                            className='ml-3'
                            left='znk-ribbon-task left'
                            right='znk-ribbon-task right'
                        />
                        <div className='m-0 p-0 d-flex flex-column my-2'>
                            <span className='h3 text-white font-weight-bold'>
                                {moment(task?.dueDate).format('ddd, MMM YYYY')}
                            </span>
                            <span className='h4 text-white'>
                                {moment(task?.dueDate).format('MMMM Do, YYYY - h:mm a')}
                            </span>
                        </div>
                        <div className='d-flex flex-row w-100 justify-content-between'>
                            {scoresToShow?.results &&
                                scoresToShow?.results
                                    .filter((x) => x.name !== 'Test Scores')
                                    .map((x) => (
                                        <ScoreItem
                                            className='m-0 mr-1'
                                            serviceLabel={x.name.split(' ')[0]}
                                            // serviceScore={x.name.split(' ')[1]}
                                            scoreLabel={x.value}
                                        />
                                    ))}
                        </div>
                    </div>
                </div>
            }
        >
            {/* Muestra los scores "generales?? promedios??"  */}

            {scoresToShow?.results && (
                <span className='d-flex justify-content-start text-gray'>
                    {scoresToShow?.name}
                </span>
            )}
            {scoresToShow?.results
                .filter((x) => x.name !== 'Test Scores')
                .map((x) => (
                    <>
                        <ScoreListItem
                            className='m-1'
                            id={x.name}
                            label={x.name.split(' ')[0]}
                            value={x.value}
                            defaultValue={x.value}
                            onChange={null}
                            isEditable={isEdit}
                        />
                    </>
                ))}

            {/* Muestra los puntajes de los tests  */}
            {scoresToShow?.results &&
                scoresToShow?.results
                    .filter((x) => x.name === 'Test Scores')
                    .map((x) => (
                        <>
                            <span className='d-flex justify-content-start text-gray'>
                                {x.name}{' '}
                            </span>
                            {x.values.map((exam) => (
                                <ScoreListItem
                                    id={exam.name}
                                    className='m-1'
                                    label={exam.name}
                                    value={exam.value}
                                    onChange={null}
                                    isEditable={isEdit}
                                />

                            ))}
                        </>
                    ))}
        </ModalLayout>
    )
}

export default ScoresModal
