import React from 'react'
import { Avatar, IconSignCheck, DropDownNew, DropDownItem, CarouselFrame } from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import moment from 'moment'

const LessonsIcons = (props) => {
  const { classNameItem, data, idTopic, manual } = props
  const RenderIcon = (props) => {
    const { item, selected, scheduled, checked, key, onClick } = props
    const avatarClass = !manual
      ? `
          ${!selected ? 'no' : ''}set-avatar 
          ${scheduled ? 'scheduled-avatar' : ''} 
          ${checked && selected ? 'selected-avatar' : ''}`
      : `
          ${!selected ? 'no' : ''}set-avatar-manual 
          ${scheduled ? 'scheduled-avatar-manual' : ''} 
          ${checked && selected ? 'selected-avatar-manual' : ''}
        `
    return (
      <DropDownNew
        options={[{component: <DropDownItem>{`Original: ${moment(item?.old).format('MMMM Do YYYY, h:mm a')}`}</DropDownItem>}]}
        direction={`bottomLeft`}
      >
        <Avatar
          key={key}
          className={`m-1 ${classNameItem}`}
          named={false}
          size={`avatar-medium ${avatarClass}`}
          name={item.lesson.substring(0, 1).toUpperCase()}
          IconComponent={checked && IconSignCheck}
          uri={null}
          onClick={onClick}
        />
      </DropDownNew>
    )
  }
  // {lesson, idTopic, lpw}
  return (
    <div className={`w-100`}>
      <CarouselFrame type='scroll'>
        {data.length > 0 &&
          data.map((item, ind) => {
            if (manual) {
              return (
                <RenderIcon
                  key={ind}
                  item={item}
                  checked={item.scheduled}
                  selected={idTopic === item.idTopic}
                  scheduled={item.scheduled}
                  onClick={() => {
                    if (item?.onClick) item.onClick()
                  }}
                />
              )
            } else {
              return (
                <RenderIcon
                  key={ind}
                  item={item}
                  checked={item.scheduled}
                  selected={idTopic === item.idTopic}
                  scheduled={item.scheduled}
                  onClick={() => {
                    if (item?.onClick) item.onClick()
                  }}
                />
              )
            }
          })}
      </CarouselFrame>
    </div>
  )
}

LessonsIcons.propTypes = {
  classNameItem: PropTypes.string,
  data: PropTypes.array,
  idLesson: PropTypes.string,
  idTopic: PropTypes.string,
  lesson: PropTypes.number,
  checked: PropTypes.bool,
  selected: PropTypes.bool
}
LessonsIcons.defaultProps = {
  classNameItem: '',
  data: [],
  idLesson: null,
  idTopic: '',
  lesson: 0,
  checked: false,
  selected: false
}

export default LessonsIcons
