import moment from 'moment'

export const getTotalLessons = (form) => {
  let count = 0
  let groupCount = 0
  if (form.lpw) {
    if (form.typePlan === 'time' && form.typeLesson === 'totalLesson') {
      Object.keys(form.lpw).forEach((lpwInd) => {
        const partFrom = moment(form.lpw[lpwInd].startdate)
        const partTo = moment(form.lpw[lpwInd].enddate)
        Object.keys(form.lpw[lpwInd]).filter(
          (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
        ).forEach((idTopic) => {
          count += form.lpw[lpwInd][idTopic].valueIndividual || 0
          if (form.lpw[lpwInd][idTopic].groupBlocks) {
            form.lpw[lpwInd][idTopic].groupBlocks.forEach(ele => {
              groupCount = groupCount + getEfectiveDays(partFrom, partTo, ele.val.day)
            })
          }
        })
      })
    } else {
      Object.keys(form.lpw).forEach((lpwInd) => {
        let weeks = 1
        let partFrom
        let partTo
        if (form.lpw[lpwInd].startdate && form.lpw[lpwInd].enddate) {
          partFrom = moment(form.lpw[lpwInd].startdate)
          partTo = moment(form.lpw[lpwInd].enddate)
          // si una semana tiene menos de tres dias no contarla como una semana valida
          weeks = (partFrom.diff(partTo, 'week') * -1) + 1
          if (partTo.day() < partFrom.day() && partTo.day() !== 0 && weeks >= 2) {
            // en este caso diff cuenta una semana menos
            weeks += 1
          }
          if (partFrom.day() > 3 && partFrom.day() !== 0 && weeks >= 2) {
            weeks -= 1
          }
          if (partTo.day() < 3 && partTo.day() !== 0 && weeks >= 2) {
            weeks -= 1
          }
        }
        Object.keys(form.lpw[lpwInd]).filter(
          (key) => key !== 'startdate' && key !== 'enddate' && key !== 'partname'
        ).forEach((idTopic) => {
          if (form.typePlan === 'time' && form.lpw[lpwInd].typeLesson === 'totalLesson') {
            count += (form.lpw[lpwInd][idTopic].valueIndividual || 0)
          } else {
            count += (form.lpw[lpwInd][idTopic].valueIndividual || 0) * weeks
          }

          if (form.lpw[lpwInd][idTopic].groupBlocks) {
            form.lpw[lpwInd][idTopic].groupBlocks.forEach(ele => {
              groupCount = groupCount + getEfectiveDays(partFrom, partTo, ele.val.day)
            })
          }
        })
      })
    }
  }
  return [count, groupCount]
}

const getEfectiveDays = (startDate, endDate, day) => {
  const start = moment(startDate)
  const end = moment(endDate)
  let result = 0
  const current = start.clone()
  if (current.day() === day) {
    result++
  }
  let exit = false
  while (exit === false) {
    current.add(1, 'day')
    if (current.isAfter(end)) {
      exit = true
    } else if (current.day() === day) {
      result++
    }
  }
  return result
}

export const getTotalPay = (form, groupAmount = 0) => {
  const parts = Object.keys(form.lpw).length
  let start = form.lpw['0'] ? form.lpw['0'].startdate ? form.lpw['0'].startdate : null : null
  let end = form.lpw[parts - 1] ? form.lpw[parts - 1].enddate ? form.lpw[parts - 1].enddate : null : null
  let payments = []
  if (form.typeLesson === 'totalLesson' && form.typePlan === 'time') {
    payments = getFeeFromRangeDate(moment(start), moment(start).add(2, 'week'))
  } else if (start !== null && end !== null) {
    start = moment(start)
    end = moment(end)
    payments = getFeeFromRangeDate(start, end)
  }
  const [listIndiv, listGroup] = getTotalLessons(form)
  return form.typePlanPricing === 'permonth'
    ? (form.payments.lessons ? form.amount : 0) * payments.length
    : ((form.payments.lessons ? listIndiv * (form.amount || 0) : 0) + (groupAmount > 0 ? groupAmount : form.payments.groupLessons ? listGroup * (form.groupAmount || 0) : 0))
}

export const getFeeFromRangeDate = (from, to) => {
  // Caso 1: Si tiene más lecciones en el primer mes que la cantidad de lecciones por semana,
  // entonces se debe aplicar un pago por ese mes (COMIENZA AL MENOS UNA SEMANA ANTES DEL PRIMER MES)
  // Caso 2: Si tiene más lecciones en el último mes que el doble de lecciones por semana,
  // entonces se debe aplicar un pago por ese mes (si NO ACABA ANTES DE LA SEGUNDA SEMANA DEL SIGUIENTE MES)
  // Caso 3: Si la parte es tan corta como para NO COMENZAR AL MENOS UNA SEMANA ANTES DEL PRIMER MES y
  // ademas ACABA ANTES DE LA SEGUNDA SEMANA DEL SIGUIENTE MES se debe aplicar un solo pago al final
  // del segundo mes
  const beginAtLeastOneWeekBeforeFirtsMonth = moment(from).endOf('month').diff(moment(from), 'days') >= 7
  const finishBeforeSecondWeekOfFinalMonth = moment(to).diff(moment(to).startOf('month'), 'days') < 14
  from = moment(from).startOf('month')
  to = moment(to).endOf('month')
  const payments = []
  let c = 0
  while (moment(from) < moment(to)) {
    if (c === 0) {
      // primer payment
      if (beginAtLeastOneWeekBeforeFirtsMonth) {
        // aplicar payment al final del mes FROM
        // caso 1
        payments.push({
          time: moment(from),
          custom: false
        })
      }
    } else if (
      parseInt(moment(from).clone().format('M')) === parseInt(moment(to).format('M'))
    ) {
      // ultimo payment
      if (!finishBeforeSecondWeekOfFinalMonth || payments.length === 0) {
        // aplicar payment al final del mes TO
        // caso 2
        payments.push({
          time: moment(from),
          custom: false
        })
      }
    } else {
      payments.push({
        time: moment(from),
        custom: false
      })
    }
    from.add(1, 'month')
    c++
  }
  return payments
}

export const getRangeDate = (form) => {
  //OBTIENE RANGO DE TIEMPO
  const startFirstPart = (form?.PartsSchedule || []).reduce(
    (min, { from }) => {
      if (moment(from).valueOf() < min) return moment(from).valueOf()
      return min
    }, Number.POSITIVE_INFINITY
  )
  const endLastPart = (form?.PartsSchedule || []).reduce(
    (max, { to }) => {
      if (moment(to).valueOf() > max) return moment(to).valueOf()
      return max
    }, Number.NEGATIVE_INFINITY
  )
  const diffMonth = Math.abs(moment.duration(moment(startFirstPart).diff(moment(endLastPart))).asMonths())
  let start = moment(form.paymentstart).toISOString() || moment(startFirstPart).toISOString()
  let end = form?.paymentstart ? moment(form?.paymentstart).add(diffMonth, 'months').toISOString() : moment(endLastPart).toISOString()

  if (form.typeLesson === 'totalLesson' && form.typePlan === 'time') {
    end = moment(start).add(2, 'month').toISOString()
  }
  //OBTIENE RANGO DE TIEMPO
  return [start, end]
}

export const getFlatFeeAmounts = (form) => {
  const parts = Object.keys(form.lpw).length
  let start = form.lpw['0'] ? form.lpw['0'].startdate ? form.lpw['0'].startdate : null : null
  let end = form.lpw[parts - 1] ? form.lpw[parts - 1].enddate ? form.lpw[parts - 1].enddate : null : null
  let payments = []
  if (form.typeLesson === 'totalLesson' && form.typePlan === 'time') {
    payments = getFeeFromRangeDate(moment(start), moment(start).add(2, 'week'))
  } else if (start !== null && end !== null) {
    start = moment(start)
    end = moment(end)
    payments = getFeeFromRangeDate(start, end)
  }
  return [form.payments.groupLessons ? form.groupAmount : 0, form.payments.lessons ? form.amount : 0].map(item => item * (form.typePlanPricing !== 'permonth' ? payments.length : 1))
}

export const totalLessonPart = (info, type) => {
  let count = 0
  if (type === 'fixed') {
    Object.keys(info).filter(
      (key) => !['startdate', 'enddate', 'partname', 'typeLesson'].includes(key)
    ).forEach((idTopic) => {
      count += info[idTopic].valueIndividual || 0
    })
  } else {
    let weeks = 1
    if (info.startdate && info.enddate) {
      const partFrom = moment(info.startdate).utc()
      const partTo = moment(info.enddate).utc()
      // si una semana tiene menos de tres dias no contarla como una semana valida
      weeks = (partFrom.diff(partTo, 'week') * -1) + 1
      if (partTo.day() < partFrom.day() && partTo.day() !== 0 && weeks >= 2) {
        // en este caso diff cuenta una semana menos
        weeks += 1
      }
      if (partFrom.day() > 3 && partFrom.day() !== 0 && weeks >= 2) {
        weeks -= 1
      }
      if (partTo.day() < 3 && partTo.day() !== 0 && weeks >= 2) {
        weeks -= 1
      }
    }
    Object.keys(info).filter(
      (key) => !['startdate', 'enddate', 'partname', 'typeLesson'].includes(key)
    ).forEach((idTopic) => {
      count += (info[idTopic].valueIndividual || 0) * weeks
    })
  }
  return count
}
