import {useSelector, useDispatch} from 'react-redux'

const useCreateEducatorModal = () => {
    const reduxData = useSelector((state) => state.modal.educator.createEducatorModal)
    const {isOpen, onChange} = reduxData
    const dispatch = useDispatch()

    const setData = (isOpen = false, dataUser = null, onChange = () => {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'educator.createEducatorModal',
            data: {
                ...reduxData,
                isOpen,
                dataUser,
                onChange
            }
        })
    }
    return {
        ...reduxData,
        setDataUser: (newDataUser = null) => {
            setData(isOpen, newDataUser, onChange)
        },
        open: (dataUser = null, onChange = () => {}) => {
            setData(true, dataUser, onChange)
        },
        close: () => {
            setData(false)
        },
        toggle: () => {
            setData(!isOpen)
        }
    }
}

export default useCreateEducatorModal