import React from 'react'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../../../store/modal/const'
import { useDispatch } from 'react-redux'
import EducatorItem from '../EducatorItem'

const TypeEducators = (props) => {
  const { 
    type, form, setForm, thisTopics, 
    currentService, educators, multiple, 
    educatorsBase, disabled = false 
  } = props
  const allVarAreDefined = form.lpw['0'].startdate && form.lpw['0'].enddate && thisTopics.length > 0
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const openEducatorModal = (topic, partIndex, educatorUserId = undefined) => {
    if (form.statusPlan && form.statusPlan === 'scheduled') return
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.selectTopicEducator',
      data: {
        isOpen: true,
        idTopic: topic.id,
        topicName: topic.name,
        setEducator: (edu) => {
          const eduService = edu.educatorInfo.services.find((s) => s.serviceId === currentService.id)
          const topicEducator = eduService.topics.find(top => top.topicId === topic.id)
          if (!educatorUserId) {
            setForm(pre => ({
              ...pre,
              [`educators${type}`]: { ...pre[`educators${type}`], [topic.id]: { ...edu } },
              educatorsGroupFull: pre.educatorsGroupFull.concat({
                educatorUserId: edu.id,
                backupLink: form.locationPlan === 'hangouts' ? edu.educatorInfo.backupLink : '',
                firstName: edu.firstName,
                isManualRate: true,
                lastName: edu.lastName,
                rate: topicEducator.groupRate,
                teachingType: 'group',
                topicId: topic.id,
                partIndex,
                __typename: 'User',
                isWaiveGroup: topicEducator.isWaiveGroup
              })
            }))
          } else {
            let Teacher = {}
            form.educatorsGroupFull.forEach(
              e => {
                Teacher = Teacher.educatorUserId === educatorUserId && Teacher.partIndex === partIndex ? edu : e
              }
            )
            setForm(pre => ({
              ...pre,
              [`educators${type}`]: { ...pre[`educators${type}`], [topic.id]: { ...Teacher } },
              educatorsGroupFull: pre.educatorsGroupFull.map(teacher => {
                return teacher.educatorUserId === educatorUserId && teacher.partIndex === partIndex && teacher.topicId === topic.id ? {
                  educatorUserId: edu.id,
                  backupLink: form.locationPlan === 'hangouts' ? edu.educatorInfo.backupLink : '',
                  firstName: edu.firstName,
                  isManualRate: true,
                  lastName: edu.lastName,
                  rate: topicEducator.groupRate,
                  teachingType: 'group',
                  topicId: topic.id,
                  partIndex,
                  __typename: 'User',
                  isWaiveGroup: topicEducator.isWaiveGroup
                }
                  : teacher
              })
            }))
          }
        },
        educator: educators[topic.id] || {},
        serviceId: currentService.id,
        form,
        type,
        multiple
      }
    })
  }
  
  return (
    <>
      <h5 className='font-weight-light text-gray text-uppercase my-2'>{type}</h5>
      {!currentService
        ? <label>{translate('plans.modal.finishParams')}</label>
        : !allVarAreDefined
          ? <label>{translate('modal.newplan.recommendEducators.empty')}</label>
          : Object.values(form.lpw).map(
            (e, i) => {
              return (
                <>
                  <label>{e.partname}</label>
                  <>
                    {
                      currentService.topics.map((topic) => {
                        const rel = form.educatorsGroupFull.filter(ele => ele.partIndex === i && ele.topicId === topic.id).length
                        return (
                          thisTopics.includes(topic.id) && e[topic.id] && e[topic.id].valueGroup
                            ? (
                              <div className='flex-grow-1'>
                                {
                                  rel > 0
                                    ? form.educatorsGroupFull.filter(ele => ele.partIndex === i && ele.topicId === topic.id).map(
                                      educator => {
                                        return (
                                          <EducatorItem 
                                            educatorsBase={educatorsBase}
                                            disabled={disabled}
                                            onClickLeft={() => openEducatorModal(topic, i, educator.educatorUserId)} 
                                            key={`${topic.id}-${i}`} type={type} topic={topic} 
                                            educator={educator} setForm={setForm} form={form} 
                                          />
                                        )
                                      }
                                    )
                                    : disabled ? null : <label className='btn-link' onClick={() => openEducatorModal(topic, i)}>{translate(`modal.newplan.recommendEducators.${type}`, { name: topic.name })}</label>
                                }
                              </div>
                            )
                            : null
                        )
                      })
                    }
                  </>
                </>
              )
            }
          )}
    </>
  )
}

export default TypeEducators
