import { 
  ListItem, 
  IconCircleDolar, 
  IconRight, 
  IconEdit, 
  IconDate, 
  IconNotificationOff, 
  IconCreditCard, 
  IconCountry, 
  IconArrowRight, 
  IconArrowDown, 
  IconArrowUp,
  IconStundent,
  IconProfile,
} from '@Knowledge-OTP/znk-ui-components'
import React, { useState } from 'react'
import moment from 'moment'
import {Collapse} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import useTranslation from '../../../../i18n/useTranslation'

const SimpleProfile = (props) => {
  const { user, type, className, style, setPage } = props
  const { full: stuRateFull } = useSelector(state => state.modal.educator.infoEducatorModal)
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  //const openFull = () => {
  //  dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.profile', data: { isOpen: true, full: true, type: type } })
  //}
  //const closeSimple = () => {
  //  dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.profile', data: { isOpen: false, full: false, type: null } })
  //}
  const openNotifications = () => {
    const { studentInfo } = user
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRate', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.studentInfo', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.edit', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.familyInfo', data: { isOpen: false } })
    dispatch(
      {
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'user.notificationHub',
        data: {
          isOpen: true,
          id: user.id,
          studentInfo
        }
      }
    )
  }
  const editUser = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRate', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.studentInfo', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.notificationHub', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.familyInfo', data: { isOpen: false } })
    if (stuRateFull || true) {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'user.edit',
        data: {
          isOpen: true,
          ...user,
          currentIsActive: user.isActive,
          phoneDialCode: user.phone ? user.phone.split(' ')[0] : '',
          phoneNumber: user.phone ? user.phone.split(' ')[1] : '',
          birthDay: user.birthdate ? moment(user.birthdate).date() : '',
          birthMonth: user.birthdate ? moment(user.birthdate).month() : '',
          birthYear: user.birthdate ? moment(user.birthdate).year() : '',
          email: user.emails[0].address,
          timezone: { value: user.timezone, label: user.timezone },
          country: { value: user.country.id, label: user.country.name }
        }
      })
    } else {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'user.editShort',
        data: {
          isOpen: true,
          ...user,
          currentIsActive: user.isActive,
          phoneDialCode: user.phone ? user.phone.split(' ')[0] : '',
          phoneNumber: user.phone ? user.phone.split(' ')[1] : '',
          birthDay: user.birthdate ? moment(user.birthdate).date() : '',
          birthMonth: user.birthdate ? moment(user.birthdate).month() : '',
          birthYear: user.birthdate ? moment(user.birthdate).year() : '',
          email: user.emails[0].address,
          timezone: { value: user.timezone, label: user.timezone },
          country: { value: user.country.id, label: user.country.name }
        }
      })
    }
  }
  const setStudentServicesRate = (type) => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.edit', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.studentInfo', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.notificationHub', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.familyInfo', data: { isOpen: false } })
    if (stuRateFull) {
      dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRateShort', data: { isOpen: true } })
      dispatch(
        {
          type: SET_DATA_MODAL_FORM_LIST,
          payload: 'user.servicesRate',
          data: {
            isOpen: true,
            typeUser: type,
            userId: user.id
          }
        }
      )
    } else {
      dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRateShort', data: { isOpen: false } })
      dispatch(
        {
          type: SET_DATA_MODAL_FORM_LIST,
          payload: stuRateFull ? 'user.servicesRate' : 'user.servicesRate',
          data: {
            isOpen: true,
            typeUser: type,
            userId: user.id
          }
        }
      )
    }
  }


  const toggleAvailabilityTemporal = () => {
    dispatch(
      {
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'user.availabilityTemporal',
        data: {
          dataUser: {
            id: user.id
          }
        }
      }
    )
  }

  const toggleAvailabilityRecurring = () => {
    dispatch(
      {
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'user.availabilityRecurring',
        data: {
          dataUser: {
            id: user.id
          }
        }
      }
    )
  }
  const [isOpenAvailable, setOpenAvailable] = useState(false)
  const [isOpenCollapse, setOpenCollapse] = useState(false)
  const toggleAvailabilityCollapse = () => {
    setOpenAvailable(!isOpenAvailable)
  }
  const toggleWeeksBlock = () => {
    dispatch(
      {
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'user.weeksBlock',
        data: {
          dataUser: {
            id: user.id
          }
        }
      }
    )
  }
  return (
    <div className='m-0 p-0 d-flex flex-column justify-content-start' style={style}>
      {
        user ? (
          <div className={`w-100 ${ className || 'p-7' }`}>
            {/**<span className='d-flex w-100 flex-row justify-content-between'>
              <Avatar
                name={`${user.firstName} ${user.lastName}`}
                named={false}
                uri={null}
                size='avatar-extra'
              />
              <div className='d-flex flex-row justify-content-between'>
                <div className='znk-table-dropdown dropdown-menu-width-content' style={{ alignItems: 'start' }}>
                  <DropDown
                    direction='down'
                    fixedWidth
                    hoverEnabled={false}
                    options={[
                      {
                        component:
            <span
              className='h4 text-logo'
            >Options ?
            </span>
                      }
                    ]}
                  >
                    <IconOptions size={20} className='m-0 p-0' style={{ transform: 'rotate(90deg)' }} color='rgba(119, 134, 153, .4)' />
                  </DropDown>
                </div>
                <IconClose size={20} onClick={closeSimple} color='rgba(119, 134, 153, .4)' />
              </div>
            </span>
            <h2 className='font-weight-bold mt-3'>{user.firstName} {user.lastName}</h2>
            <h5 className='text-gray mt-2'>{user.emails[0].address}</h5>
            {['educator', 'staff'].includes(type) && <h5 className='text-gray mt-1'>{user.phone}</h5>}
            {
              ['student'].includes(type)
                ? (<h5 className='text-gray mt-1'>
                  {user.studentInfo.parents && user.studentInfo.parents.length > 0 ? 'Family ' : 'Independent '}
                  | Facebook
                  | {user.isActive ? 'Active ' : 'Inactive '}
                  | Class of {moment(user.studentInfo.graduationDate).format('YYYY')}
                </h5>)
                : (<h5 className='text-gray mt-1'>
                  {type === 'educator' ? 'Educator ' : 'Staff '}
                  | Facebook
                  | {user.isActive ? 'Active ' : 'Inactive '}
                </h5>)
            }
            {['educator', 'staff'].includes(type) && (
              <div className='d-flex flex-row align-items-center'>
                <Rating mode='View' initialRating={user.educatorInfo.rating} className='mini mr-2' size={10} />
                <h5 className='text-gary'>{user.educatorInfo.rating}/5</h5>
              </div>
            )}
            <h5 className='text-primary mt-1 btn-link' onClick={openFull}>View complete profile</h5>
            <div className='borderTop mt-7' />**/}
            <ListItem
              showTopLine={false}
              showBottomLine
              className='py-4'
              Left={
                <div 
                  className='d-flex flex-row align-items-center'
                  onClick={() => {
                    setPage('full-profile')
                  }}
                >
                  <IconProfile size={16} color='#72809D' className='mr-3' />
                  <span className='text-title'>View Profile</span>
                </div>
              }
              Right={
                <div className={`hover-icon`} onClick={() => {setOpenCollapse(!isOpenCollapse)}}>
                  {
                    !isOpenCollapse ?
                      <IconArrowDown size={16} /> :
                      <IconArrowUp size={16} />
                  }
                </div>
              }
            />

            <Collapse isOpen={isOpenCollapse} className={'m-0 pl-3 pr-0 py-0'}>
              <ListItem
                showTopLine={false}
                showBottomLine={false}
                className='py-4'
                onClick={() => {
                  setPage('edit-profile')
                  editUser()
                }}
                Left={
                  <div className='d-flex flex-row'>
                    <IconEdit color='#72809D' className='align-self-center mr-2' />
                    <span className='align-self-center text-title'>
                    {translate('profile.ediprofile')}
                    </span>
                  </div>
                }
                Right={<IconArrowRight size={16} />}
              />
              <ListItem
                showTopLine
                showBottomLine
                className='py-4'
                onClick={() => {
                  setPage('academic-info')
                }}
                Left={
                  <div className='d-flex flex-row'>
                    <IconStundent color='#72809D' className='align-self-center mr-2' />
                    <span className='align-self-center text-title'>
                    {translate('profile.editacademicinfo')}
                    </span>
                  </div>
                }
                Right={<IconArrowRight size={16} />}
              />
            </Collapse>
            {
              ['student', 'educator'].includes(type) && (
                <>
                  <ListItem
                    showTopLine={false}
                    showBottomLine
                    className='py-4'
                    onClick={() => {
                      setPage('student-form')
                      setStudentServicesRate(type)
                    }}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconCircleDolar size={16} color='#72809D' className='mr-3' />
                        <span className='text-title'>{type === 'student' ? translate('profile.servicesrate') : translate('profile.employmentdetails')}</span>
                      </div>
                    }
                    Right={
                      <IconRight size={16} />
                    }
                  />
                </>
              )
            }
            {
              type === 'educator' && (
                <>
                  <ListItem
                    showTopLine={false}
                    showBottomLine
                    className='py-4'
                    onClick={() => {toggleAvailabilityCollapse()}}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconDate size={16} color='#72809D' className='mr-3' />
                        <span className='text-title'>{translate('profile.availability')}</span>
                      </div>
                    }
                    Right={
                      <IconRight size={16} />
                    }
                  />
                  <Collapse isOpen={isOpenAvailable}>
                    <ListItem
                      showTopLine={false}
                      showBottomLine
                      className='py-4'
                      onClick={() => {
                        setPage('av-temporal')
                        toggleAvailabilityTemporal()}
                      }
                      Left={
                        <div className='d-flex flex-row align-items-center'>
                          <span className='text-title ml-4'>{translate('profile.availabilitytemp')}</span>
                        </div>
                      }
                      Right={
                        <IconRight size={16} />
                      }
                    />
                    <ListItem
                      showTopLine={false}
                      showBottomLine
                      className='py-4'
                      onClick={() => {
                        setPage('av-recurring')
                        toggleAvailabilityRecurring()
                      }}
                      Left={
                        <div className='d-flex flex-row align-items-center'>
                          <span className='text-title ml-4'>{translate('profile.availabilityperm')}</span>
                        </div>
                      }
                      Right={
                        <IconRight size={16} />
                      }
                    />
                     <ListItem
                      showTopLine={false}
                      showBottomLine
                      className='py-4'
                      onClick={() => {
                        setPage('weeks-block')
                        toggleWeeksBlock()
                      }}
                      Left={
                        <div className='d-flex flex-row align-items-center'>
                          <span className='text-title ml-4'>{translate('profile.infoeducator.menuweeksblock')}</span>
                        </div>
                      }
                      Right={
                        <IconRight size={16} />
                      }
                    />
                  </Collapse>
                </>
              )
            }
            {
              ['educator', 'parent'].includes(type) && (
                <>
                  <ListItem
                    showTopLine={false}
                    showBottomLine
                    className='py-4'
                    onClick={() => {
                      setPage('notifications')
                      openNotifications()
                    }}
                    Left={
                      <div className='d-flex flex-row align-items-center'>
                        <IconNotificationOff size={16} color='#72809D' className='mr-3' />
                        <span className='text-title'>{translate('profile.notifications')}</span>
                      </div>
                    }
                    Right={
                      <IconRight size={16} />
                    }
                  />
                </>
              )
            }
            {
              type === 'parent' && (<>
                <ListItem
                  showBottomLine
                  className='py-4'
                  onClick={() => {
                    // if (!openSideModal) toggleSideModal()
                    setPage('changePaymentMethod')
                  }}
                  Left={
                    <div className='d-flex flex-row align-items-center'>
                      <IconCreditCard color='#72809D' className='align-self-center mr-2' />
                      <span className='text-title'>
                        {user?.paymentMethod === null
                          ? translate('profile.actions.changeMethod.add')
                          : translate('profile.actions.changeMethod.change')}
                      </span>
                    </div>
                  }
                  Right={<IconArrowRight />}
                />
                <ListItem
                  showBottomLine
                  className='py-4'
                  onClick={() => {
                    // if (!openSideModal) toggleSideModal()
                    setPage('changeTimezone')
                  }}
                  Left={
                    <div className='d-flex flex-row align-items-center'>
                      <IconCountry color='#72809D' className='align-self-center mr-2' />
                      <span className='text-title'>
                        {translate('student.modal.profile.changeTimezone')}
                      </span>
                    </div>
                  }
                  Right={<IconArrowRight />}
                />
              </>)
            }
          </div>)
          : <div className='w-100 p-5'><Skeleton count={4} height={35} /></div>
      }
    </div>
  )
}

export default SimpleProfile
