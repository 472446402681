import {useQuery, gql} from '@apollo/client'

const useMyPaymentsItems = (vars = {}) => {
    return useQuery(
        gql`
            query($planId: String!) {
                myPaymentsItems(planId: $planId) {
                    docs {
                        amount
                        status
                        description
                        category
                        type
                        date
                    }
                }
            }
        `, {
            variables: vars
        }
    )
}

export default useMyPaymentsItems