import React from 'react'
import useSetServiceTopics from '../../../../../Model/Services/SetTopics'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, IconClose, Modal, PlainArray, useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import { ModalHeader } from 'reactstrap'
import TopicSelector from '../../../Topics/Selector'
import useTranslation from '../../../../../i18n/useTranslation'

const SetTopicsModal = (props) => {
  const { isOpen, ...otherProps } = useSelector(state => state.modal.service.setTopics)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'service.setTopics', data: { isOpen: !isOpen, ...otherProps } })
  }
  const onSuccess = (result) => {
    toggle()
  }
  const { submit, loading, validationErrors } = useSetServiceTopics(onSuccess, console.log)
  const { translate } = useTranslation()
  const onChange = (setFormFunc) => {
    const newVal = setFormFunc(otherProps)
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'service.setTopics', data: { isOpen: true, ...newVal } })
  }
  const { breakWidth } = useWindowDimensions()
  const onSubmit = () => {
    submit({ ...otherProps, topicIds: otherProps.topicIds.map((topic) => topic.id) })
  }
  return (
    <Modal
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <ModalHeader
          toggle={toggle}
          close={<IconClose size={20} onClick={toggle} />}
          className='mt-md-4'
        >
          <span className='h2 font-weight-bold mr-4'>
            {translate('constants.service.setTopics')}
          </span>
        </ModalHeader>
      }
    >
      <Form value={otherProps} onChange={onChange} validationErrors={validationErrors}>
        <Field
          type={PlainArray}
          fieldName='topicIds'
          component={TopicSelector}
          submit={onSubmit}
          loading={loading}
        />
      </Form>
    </Modal>
  )
}

export default SetTopicsModal
