import React from 'react'
import { IconRemove } from '@Knowledge-OTP/znk-ui-components'

const Topic = (props) => {
  const { name, removeItem } = props
  return (
    <div className='d-flex justify-content-between'>
      <span>{name}</span>
      <span><IconRemove size={20} onClick={removeItem} /></span>
    </div>
  )
}

export default Topic
