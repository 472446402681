import React from 'react'
import {
    Input,
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import RenderInputOption from '../../Components/RenderInputOption'

const PlanDetails = (props) => {
    const {className, plan, setData} = props
    // useEffect(() => {
    //     if(!plan?.name) setData({name: 'Counseling plan'})
    //     // eslint-disable-next-line
    // }, [plan])
    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <span className={`h4 font-weight-bold text-title`}>Plan details</span>
            <RenderInputOption key={'name'} label={`Plan name`}>
                <Input 
                    placeholder={`Plan name`} 
                    value={plan?.name}
                    onChange={(e) => {
                        setData({name: e?.target?.value})
                    }} 
                />
            </RenderInputOption>
            <RenderInputOption key={'serviceId'} label={`Type`}>
                <InputSelect 
                    placeholder={`Type plan`}
                    value={plan?.type} 
                    options={
                    [
                        {
                            value: 'undergraduate',
                            label: 'Undergraduate'
                        },
                        {
                            value: 'transfer',
                            label: 'Transfer'
                        },
                        {
                            value: 'graduate',
                            label: 'Graduate'
                        },
                        {
                            value: 'highSchool',
                            label: 'High School'
                        }
                    ]
                    }
                    onChange={(type) => {
                        setData({type})
                    }}
                />
            </RenderInputOption>
        </div>
    )
}

export default PlanDetails