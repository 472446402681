import React, {useRef, useState, useEffect} from 'react'
import {Activity, CheckboxOption} from '@Knowledge-OTP/znk-ui-components'
import {Draggable} from 'react-beautiful-dnd'
import {useSelector} from 'react-redux'
import moment from 'moment-timezone'

function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    // eslint-disable-next-line
    [ref.current], // Recall only if ref changes
  )

  return [ref, value]
}
const LessonBloc = (props) => {
  const {
    idLesson,
    type,
    status,
    title,
    subtitle,
    widthBlocPx,
    heightBlocMinutePx,
    durationMin,
    minutes,
    className,
    onClick,
    icon,
    selectBlockInCalendar,
    deselectBlockInCalendar,
    selected,
    canChecked,
    showCheckBoxOnLeft,
    activity,
    isDraggingOver,
    //hidden,
    idEducator,
    idStudents,
    from
  } = props
  //Lunes: 0 <-> Domingo: 6
  const blockKey = `${moment(from).day() === 0 ? 6 : moment(from).day()-1}-${moment(from).hour()}`
  const beforeNow = moment(from) < moment()

  const [hoverRef, isHovered] = useHover()
  const [hoverIcon, iconHovered] = useHover()

  const {isActive, freeFrames} = useSelector((state) => state.modal.lesson.dragAndDropCalendar)

  const RenderIconOrSelect = ({status, iconHovered, selected, loading}) => {
    if (loading) {
      return <Activity size={10} inverse className='small-loading' />
    } else {
      if (
        // bloques CON iconos use iconHovered para mostrar el checkBox al hacer hover sobre icon
        ((status === 'upcoming' && iconHovered) ||
          // bloques sin iconos, siempre renderiza el checkbox,
          // solo cambia el ancho del div que lo contiene
          status === 'scheduled' ||
          selected) &&
        canChecked
      ) {
        return (
          <CheckboxOption
            onChange={(e) => {
              if (e) selectBlockInCalendar(idLesson)
              else deselectBlockInCalendar(idLesson)
            }}
            defaultState={selected}
          />
        )
      } else if (icon !== undefined) return icon
    }
    return null
  }

  const showAvailable = isActive && freeFrames.includes(blockKey) && !beforeNow

  return status === 'free' || status === 'draggingOver' ? (
    <div
      ref={hoverRef}
      onClick={status === 'draggingOver' ? () => {} : onClick}
      className={`d-flex flex-column justify-content-center znk-bloc-lesson znk-bloc-lesson-${
        status !== 'draggingOver'
          ? ((isHovered && !isActive) || showAvailable)
            ? 'freeHover'
            : 'free'
          : 'draggingOver'
      } znk-bloc-lesson-size-${type} ${className}`}
      style={{
        marginTop: `${heightBlocMinutePx * minutes}px`,
        minWidth: `${widthBlocPx - 6}px`,
        width: `${widthBlocPx - 6}px`,
        minHeight: `${heightBlocMinutePx * durationMin}px`,
        height: `${heightBlocMinutePx * durationMin}px`,
      }}
    >
      {status !== 'draggingOver' && (
        <span className='h2 title align-self-center'>{showAvailable ? <span className={'h45'}>Available</span> : '+'}</span>
      )}
    </div>
  ) : (
    <Draggable
      draggableId={
        JSON.stringify(
          {
            idLesson,
            idEducator,
            idStudents
          }
        )
      }
      key={idLesson}
      index={0}
      isDragDisabled={!['scheduled', 'upcoming'].includes(status)}
    >
      {(provided, snapshot) => {
        const propsDraggable = provided.draggableProps
        propsDraggable.style = {
          ...propsDraggable.style,
          transform: snapshot.isDragging
            ? `${propsDraggable.style.transform} rotate(-2deg)`
            : null,
          marginTop: `${heightBlocMinutePx * minutes}px`,
          minWidth: `${widthBlocPx - 6}px`,
          width: `${widthBlocPx - 6}px`,
          minHeight: `${heightBlocMinutePx * durationMin}px`,
          height: `${heightBlocMinutePx * durationMin}px`,
        }
        return (
          <div
            className={`d-flex ${
              showCheckBoxOnLeft ? 'flex-row-reverse' : 'flex-row'
            } znk-bloc-lesson znk-bloc-lesson-${
              selected || activity || (isDraggingOver && !snapshot.isDragging)
                ? 'active'
                : status
            } znk-bloc-lesson-size-${type} ${
              snapshot.isDragging ? '' : className
            }`}
            {...propsDraggable}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div
              onClick={onClick}
              className={`d-flex flex-column znk-bloc-lesson-text${
                activity ? '-activity' : ''
              } ${canChecked || activity ? '' : 'w-100'}`}
            >
              <span className='h45 title' title={title}>{title}</span>
              <span className='h5 subtitle' title={subtitle}>{subtitle}</span>
            </div>
            <div
              ref={hoverIcon}
              className={`
                      d-flex flex-column 
                      align-self-${durationMin <= 60 ? 'center' : 'start mt-2'} 
                      ${showCheckBoxOnLeft ? 'mr-2' : 'ml-1'} 
                      znk-bloc-lesson-${
                        status === 'scheduled' // bloques SIN iconos,
                          ? // usa estilos css para mostrar el checkbox al hacer hover
                            activity
                            ? 'activity'
                            : selected || activity
                            ? 'check-selected'
                            : canChecked
                            ? 'check'
                            : '' // sin clase css el mismo icono usara el espacio que necesite
                          : 'icon'
                      }`}
            >
              <RenderIconOrSelect
                status={status}
                iconHovered={iconHovered}
                selected={selected}
                loading={activity}
              />
            </div>
            {provided.placeholder}
          </div>
        )
      }}
    </Draggable>
  )
}

export default LessonBloc
