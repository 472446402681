import validate from 'validate.js'

const constraints = {
  'educatorBankInfo.bankNumber': {
    presence: {
      allowEmpty: false
    }
  },
  'educatorBankInfo.bankRouting': {
    presence: {
      allowEmpty: false
    }
  },
  userId: {
    presence: {
      allowEmpty: false
    }
  }
}

export default (data) => {
  return validate(data, constraints)
}
