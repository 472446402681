import React, { useState } from 'react'
import { Action } from '@Knowledge-OTP/znk-ui-components'
import ChildLang from '../ChildLang'

const ChildKey = props => {
  const { myPath, myKey, myValues } = props
  const [collapsed, setCollapsed] = useState(false)
  const toggle = () => setCollapsed(prev => !prev)
  const myChildKeys = Object.keys(myValues)
  return (
    <div className='d-flex flex-column'>
      <Action action='secondary' status='scheduled' labelLeft={myKey} onClick={toggle} />
      {collapsed && (
        <div className='ml-2 my-2 pl-2 d-flex flex-column' style={{ borderLeft: '2px solid rgb(0, 0, 0, 0.2)' }}>
          {
            myChildKeys
              .filter((item) => item !== 'es' && item !== 'en')
              .map((item) => <ChildKey key={item} myPath={`${myPath}.${item}`} myKey={item} myValues={myValues[item]} />)
          }
          {
            myChildKeys
              .filter((item) => item === 'es' || item === 'en')
              .map((item) => <ChildLang key={item} myPath={`${myPath}.${item}`} myKey={item} myValues={myValues[item]} />)
          }
        </div>
      )}
    </div>
  )
}

ChildKey.propTypes = {

}

export default ChildKey
