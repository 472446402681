import React from 'react'
import {
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'

const TablePlansSkeleton = (props) => {
    const {count = 10} = props
    const {breakWidth} = useWindowDimensions()
    return (
        new Array(count).fill(0).map(
            (_, i) => {
              return (
                <div className={`d-flex flex-row justify-content-between align-items-center my-3`} key={`tab-ske-${i}`}>
                  <div className={`d-flex flex-row align-items-center`}>
                    <Skeleton className={`mr-1`} count={1} height={40} width={40} style={{ borderRadius: '10px' }} />
                    <Skeleton count={1} height={34} width={150} style={{ borderRadius: '10px' }} />
                  </div>
                  {

                    breakWidth === 'MD' ? 
                      <Skeleton count={1} height={20} width={100} style={{ borderRadius: '10px' }} /> :
                      breakWidth !== 'SM' ? 
                        <>
                          <Skeleton count={1} height={20} width={120} style={{ borderRadius: '10px' }} />
                          <Skeleton count={1} height={20} width={120} style={{ borderRadius: '10px' }} />
                          <Skeleton count={1} height={20} width={100} style={{ borderRadius: '10px' }} />
                        </>
                        : null

                  }

                  {
                    breakWidth === 'SM' ? 
                    <Skeleton count={1} height={15} width={40} style={{ borderRadius: '10px' }} />:
                    <Skeleton count={1} height={40} width={130} style={{ borderRadius: '10px' }} />
                  }

                </div>
              )
            } 
        )
    )
}
export default TablePlansSkeleton