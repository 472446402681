const useColumns = () => {
    return [
        { render: item => item?.title, colSpan: 5, id: 'title' },
        { render: item => item?.startDate, colSpan: 6, id: 'startDate' },
        { render: item => item?.studentRate, colSpan: 4, id: 'studentRate' },
        { render: item => item?.educatorRate, colSpan: 4, id: 'educatorRate' },
        { render: item => item?.paymentsLabel, colSpan: 4, id: 'paymentsLabel' },
    ]
}

export default useColumns