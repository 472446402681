import React, { useState } from 'react'
import {
  Activity,
  Field,
  Form, IconAdd,
  IconEdit,
  PlainTextInput
} from '@Knowledge-OTP/znk-ui-components'
import useSetServiceRateToCountry from '../../../../../../../Model/Countries/SetServiceRateToCountry'

const ServiceRate = (props) => {
  const { name, serviceId, countryId, defaultRate, onSuccess } = props
  const [isEdit, setIsEdit] = useState(false)
  const [form, setForm] = useState(() => ({ countryServiceRateInput: { serviceId, defaultRate }, countryId }))
  const onAddSuccess = (result) => {
    onSuccess(result)
    setIsEdit(false)
  }
  const { submit, loading, validationErrors } = useSetServiceRateToCountry(onAddSuccess, console.log)
  const onSubmit = () => {
    submit(form)
  }
  return (
    <span className='d-flex justify-content-end align-items-center'>
      <span className='mr-auto'>
        {name}
      </span>
      {
        isEdit ? (
          <Form value={form} onChange={setForm} validationErrors={validationErrors}>
            <Field
              fieldName='countryServiceRateInput.defaultRate'
              type={PlainTextInput}
              fieldType='number'
            />
            <IconAdd size={20} className='pointer-event' onClick={onSubmit} />
          </Form>
        ) : (
          <>
            <span>{defaultRate}</span>
            <span>{loading ? <Activity /> : <IconEdit size={20} className='actions-cursor' onClick={() => setIsEdit(true)} />}</span>
          </>
        )
      }
    </span>
  )
}

export default ServiceRate
