import React, {useMemo} from 'react'
import { Avatar } from '@Knowledge-OTP/znk-ui-components'

const AvatarCompactField = (props) => {
    const {item} = props
    const {
        userName, avatar, text
    } = useMemo(
        () => {
            return {
                userName: `${item?.firstName} ${item?.lastName}`,
                avatar: item?.avatar,
                text: item?.emails[0]?.address
            }
        }, [item]
    )
    return (
        <div className={`d-flex flex-row w-auto align-items-center`}>
            <Avatar
                name={userName || 'No Defined'}
                named={false}
                uri={avatar}
                size='avatar-medium'
            />
            <div className={`d-flex flex-column m-0 p-0 ml-2`}>
                <span>{userName}</span>
                <span className={`text-gray`}>{text}</span>
            </div>
        </div>
    )
}

export default AvatarCompactField