import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation create(
        $name: String!,
        $studentUserId: ID!,
        $rate: Float,
        $serviceId: ID!,
        $statusPlan: PlanStatus,
        $enforceRecommendedEducators: Boolean!,
        $isFirstPlan: Boolean!,
        $typePlanPricing: TypePlanPricing!,
        $pricingRate: Float,
        $testDate: DateTime,
        $recommendedEducators: [PlanEducatorInput!]!,
        $parts: [LessonsPerWeekPlanPartInput!]!,
        $paymentItemsConfig: [PaymentsItemsConfigInput!]
        $location: LocationLessonInput!
        $activityTest: ActivityTestInput
        $timelinePlanId: String = null
        $timelineId: String = null
        $checkInDates: [CheckInDateInput!] = []
        $sendDate: DateTime,
        $isAtomic: Boolean
        ){
        createLessonsPerWeekPlan(
          input: {
            name: $name,
            statusPlan: $statusPlan,
            checkInDates: $checkInDates
            studentUser: {
              studentUserId: $studentUserId,
              rate: $rate
            },
            serviceId: $serviceId,
            recommendedEducators: $recommendedEducators,
            enforceRecommendedEducators: $enforceRecommendedEducators,
            testDate: $testDate,
            parts: $parts,
            paymentItemsConfig: $paymentItemsConfig,
            location: $location,
            isFirstPlan: $isFirstPlan,
            typePlanPricing: $typePlanPricing,
            pricingRate: $pricingRate,
            activityTest: $activityTest
            timelinePlanId: $timelinePlanId
            timelineId: $timelineId
            sendDate: $sendDate,
            isAtomic: $isAtomic
          }
        ){id}
      }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      const errorMsg = errors && Object.values(errors) ? Object.values(errors)[0] : undefined
      onError(errorMsg?.toString() || 'validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.createLessonsPerWeekPlan : null, error, validationErrors, submit }
}
