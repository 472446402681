import { useQuery, gql } from "@apollo/client"

const useGetAllWeeklyInsights = () => {
    return useQuery(
        gql`
            query {
                getAllWeeklyInsights {
                    id
                    startDate
                    endDate
                }
            }
        `
    )
}

export default useGetAllWeeklyInsights