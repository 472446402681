import {useQuery, gql} from '@apollo/client'
import planQueryResult from './queryResult/planQueryResult'


// activityId
// user

const useAllTimelines = (variables = {}) => {
    return useQuery(
        gql`
            query (
                $sortAscending: Boolean
                $sortField: String
                $previous: String
                $next: String
                $limit: Int
                $search: String
                $countryIds: [String!]
                $endDate: DateTime = null
                $startDate: DateTime = null
                $activitiesIds: [String!] = []
                $servicesIds: [String!] = []
                ) {
                allTimelines (
                    sortAscending: $sortAscending
                    sortField: $sortField
                    previous: $previous
                    next: $next
                    limit: $limit
                    search: $search 
                    countryIds: $countryIds
                    endDate: $endDate
                    startDate: $startDate
                    activitiesIds: $activitiesIds
                    servicesIds: $servicesIds
                ) {
                    hasNext
                    hasPrevious
                    next
                    previous
                    totalDocs
                    docs {
                        id
                        status
                        deleteAt
                        activityIds {
                            deleteAt
                            type
                        }
                        studentUser {
                            studentUserId
                            firstName
                            lastName
                        }
                        getTestAndEvents {
                            ... on Event {
                                name
                                tbd
                                id
                                kind
                                startDate
                                endDate
                                busy
                                userIdsInvolved
                                createdAt
                                updatedAt
                                googleCalendarEventId
                                description
                            }
                            ... on Test {
                                name
                                tbd
                                id
                                kind
                                startDate
                                endDate
                                busy
                                userIdsInvolved
                                createdAt
                                updatedAt
                                googleCalendarEventId
                                serviceId
                                registrationDate
                                scoreReleaseDate
                            }
                        }
                        getPlans {
                            ${planQueryResult}
                            timelineId {_id}
                            comment
                            deleteAt
                        }
                        getScheduledPlans {
                            ${planQueryResult}
                            comment
                        }
                        getAditionalInfo {
                            counselingPlansIds
                            graduationDate
                            countryName
                        }
                    }
                }
            }
        `,
        {
            variables
        }
    )
}

export default useAllTimelines