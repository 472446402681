import React, { useState, useMemo } from 'react'
import { Button } from '@Knowledge-OTP/znk-ui-components'
import useAllUsers from './Model/useAllUsers'
import ListItem from './ListItem'
import ProccessEducatorCalendar from './Modal/ProccessEducatorsCalendar'
const DEFAULT_QUERY = {
    state: true,
    sortField: 'firstName',
    previous: null,
    next: null,
    limit: 0,
    roles: ['EDUCATOR'],
    search: ''
}
const GoogleCalendarEducator = () => {
    const [query] = useState(DEFAULT_QUERY)
    const {data} = useAllUsers(query)
    const users = useMemo(() => data?.allUsers?.docs || [], [data])

    const [selected, setSelected] = useState([])
    const onSelected = (userId) => {
        if(selected.includes(userId)) setSelected(selected.filter((id) => id !== userId))
        else setSelected(selected.concat([userId]))
    }
    const selectAll = () => {setSelected(users.map(({id}) => id))}
    const unselectAll = () => {setSelected([])}
    const allSelect = useMemo(() => {
        const allInSelected = (users || []).reduce((bool, {id: userId}) => {
            return bool && selected.includes(userId)
        }, true)
        return allInSelected && users?.length === selected?.length
    }, [selected, users])
    
    return (
        <div className='w-100 mt-3'>
            <div className={`w-100`}>
                <label className={`btn-link`} onClick={allSelect ? unselectAll : selectAll}>{
                    allSelect ? 'Unselect All' : 'Select All'
                }</label>
            </div>
            {
                users.map(
                    (user) => {
                        return (
                            <ListItem 
                                onClick={() => {
                                    if(!user) return
                                    onSelected(user?.id)
                                }}
                                isSelected={selected.includes(user?.id)} user={user} />
                        )
                    }
                )
            }
            <ProccessEducatorCalendar selectedEducators={(users || []).filter(({id}) => selected.includes(id))}>
                <Button
                    style={{
                        bottom: 10, right: 20, position: 'fixed'
                    }} color={'primary'} label={'Proccess'} />
            </ProccessEducatorCalendar>
        </div>
    )
}

export default GoogleCalendarEducator