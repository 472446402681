import React, {useCallback} from 'react'
import {gql, useMutation} from '@apollo/client'
import useTranslation from '../../../../../../../i18n/useTranslation'
import {showAlert} from '../../../../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import { Activity } from '@Knowledge-OTP/znk-ui-components'

const AddZellePaymentMethod = (props) => {
  const {refetch, userId} = props
  const dispatch = useDispatch()
  // MUTACIÓN PARA CAMBIAR EL MÉTODO DE PAGO BANK DEBIT
  const [mutationAddZellePayment, {loading}] = useMutation(
    gql`
      mutation ($userId: ID!) {
        setZellePaymentMethodToUser (userId: $userId) {
          paymentMethod {
            kind
          }
        }
      }
    `,
  )
  const onSuccess = useCallback(
    () => {
      // send token to server
      mutationAddZellePayment({
        variables: {userId}
      })
        .then(() => {
          showAlert(
            {
              text: translate(
                'profile.actions.addManualPaymentMethod.successAlert',
              ),
              status: 'success',
            },
            dispatch,
          )
          refetch()
        })
        .catch((error) => {
          showAlert(
            {
              text: translate(
                'profile.actions.addManualPaymentMethod.errorAlert',
              ),
              status: 'error',
            },
            dispatch,
          )
          // eslint-disable-next-line
            console.error(error)
        })
    },
    // eslint-disable-next-line
    [mutationAddZellePayment, refetch, userId],
  )  

  const {translate} = useTranslation()
  return (
    <span className='btn-link m-0 p-0' onClick={onSuccess}>
      {loading ? <Activity style={{marginTop: '7px'}} />: `${translate("profile.actions.addManualPaymentMethod.zelle")}`}
    </span>
  )
}

export default AddZellePaymentMethod
