import React, {useEffect, useState, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {db, auth} from '../../Pages/VirtualChat/fireBase'
import {messagesToArray} from '../../Pages/VirtualChat/helpers/getMessages'
import {gql, useLazyQuery} from '@apollo/client'
import queryLessonResult from '../../Model/QueryResult/useLessonResult'
import useSocketConnection from '../SocketGateway/useSocketConnection'
import moment from 'moment-timezone'
import {showAlert} from '../Alert/util'
// import NotificationSound from '../ChatNotification/pristine-609.mp3'
import NotificationSound from '../ChatNotification/SD_ALERT_20-cut.mp3'
import {useHistory} from 'react-router'
import useTranslation from '../../i18n/useTranslation'
localStorage.setItem(
  'messageRead',
  localStorage.getItem('messageRead')
    ? localStorage.getItem('messageRead')
    : null,
)

const ChatNotification = () => {
  const user = auth.currentUser
  const sound = new Audio(NotificationSound)
  const idAlert = new Date().getTime()
  const dispatch = useDispatch()
  const history = useHistory()
  const {translate} = useTranslation()
  const [newInvokeLessons, setNewInvokeLessons] = useState(0)
  const {
    id: staticUserID,
    services
  } = useSelector((state) => state.user)
  const orientationId = services.find(({isOrientation}) => isOrientation)?.id

  const [notificationPermission, setNotificationPermission] = useState(null)

  const listenMessages = (inside = () => {}) => {
    getInvokeLesson({variables: {}}).then(
      (res) => {
        const lessons = res?.data?.getMyInvokeLessons || []
        for(let i=0; i < lessons.length; i++) {
          const lesson = lessons[i]
          if (!lesson) continue //return
          if (moment() >= moment(lesson?.endDate)) continue //return
          const {id} = lesson
          //escucha los mensajes de las lecciones individualmente (no la colección messages completa)
          db.collection('messages').doc(id).onSnapshot(() => {
            db.collection('messages').doc(id).get()
              .then(async (resp) => {
                await inside(lesson, resp?.data())
            })
          })
        }
      }, console.error
    )
  }

  const showNotification = (lesson, data) => {
    if (!data) return
    const messages = messagesToArray(data)
    if (!(messages?.length > 0)) return
    const lastMessage = messages[messages.length - 1]
    const {userID, text, userName, index, readed = {}} = lastMessage
    //si el mensaje ya ha sido leido no muestra la notificación
    if(readed[userID]?.readed) return

    const formatTextId = `${index}_${userID}_${lesson?.id}`

    // status del estudiante en el chat de la lección
    let onlineStatus = localStorage.getItem(
      `status-${lesson?.id}-${staticUserID}`,
    )
    onlineStatus = !!JSON.parse(onlineStatus)?.online
    if (onlineStatus) return

    if (formatTextId === localStorage.getItem('messageRead')) return
    localStorage.setItem('messageRead', formatTextId)
    localStorage.setItem(
      'unreadMessages',
      parseInt(localStorage.getItem('unreadMessages')) + 1,
    )
    if (userID !== staticUserID) {
      const finalText = `${text.substring(0, 40)} ${
        text.substring(0, 40).length >= 40 ? '.....' : ''
      }`
      sound.play()
      if (localStorage.getItem('lastIdAlert')) {
        dispatch({
          type: 'REMOVE_ALERT',
          payload: {id: idAlert},
        })
      }
      localStorage.removeItem('lastIdAlert')

      localStorage.setItem('lastIdAlert', idAlert)

      showAlert(
        {
          text: `${userName}: ${finalText} `,
          status: 'message',
          time: 10000,
          id: idAlert,
          permanet: true,
          unreadMsgs: localStorage.getItem('unreadMessages')
            ? parseInt(localStorage.getItem('unreadMessages'))
            : 0,
          onClick: () => {
            dispatch({
              type: 'REMOVE_ALERT',
              payload: {id: idAlert},
            })
            
            localStorage.setItem('unreadMessages', 0)
            localStorage.removeItem('lastIdAlert')
            history.push('/calendar')
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: `plan.${lesson?.service?.id === orientationId ? 'orientationLessonModal' : 'infoLessonModal'}`,
              data: {
                isOpen: true,
                dataLesson: lesson,
                modalType: 'chat',
              }
            })
          },
        },
        dispatch,
      )
      if (notificationPermission === 'granted') return
      new Notification(userName, {
        body: text,
        silent: true,
      })
    }
  }
  const [getInvokeLesson] = useLazyQuery(
    gql`
      query {
        getMyInvokeLessons{
          ${queryLessonResult}
        }
      }
    `,
    {
      variables: {},
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
    }
  )
  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then()
    } catch (e) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (user) {
      if (checkNotificationPromise()) {
        if (notificationPermission === 'granted') {
          setNotificationPermission(Notification.permission)
        } else if (notificationPermission !== 'denied') {
          Notification.requestPermission(function (permission) {
            setNotificationPermission(permission)
          })
        }
      }
      listenMessages((lesson, resp) => {
        showNotification(lesson, resp)
      })
    }
    // eslint-disable-next-line
  }, [notificationPermission, newInvokeLessons])


  const {connection: socketConnection} = useSocketConnection()
  const socketEmitKey = useMemo(() => `checkInvokeLessons-${staticUserID}`, [staticUserID])
  useEffect(() => {
    //escucha cuando hay nuevas lecciones donde el admin haya sido invocado
    console.log("socketEmitKey", socketEmitKey)
    socketConnection.on(socketEmitKey, () => {
      showAlert({status: 'success', text: translate("virtualChat.invokeAdmin.newInvokeLesson")}, dispatch)
      setNewInvokeLessons(newInvokeLessons+1)
    })
    // eslint-disable-next-line
}, [socketConnection, socketEmitKey, setNewInvokeLessons])

  return <></>
}

export default ChatNotification
