import validate from 'validate.js'

const constraints = {
  notificationInfo: {
    presence: true
  },
  userId: {
    presence: true
  }
}

export default (data) => {
  return validate(data, constraints)
}
