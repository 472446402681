import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'

export default function useSetEducatorNote(onSuccess = ()=>{}, onError = ()=>{}){
    const [mutation, { data, error, loading }] = useMutation(
        gql`mutation updateEducatorNote($userId: ID!, $educatorNote: String!) {
            updateEducatorNote(userId: $userId, educatorNote: $educatorNote)
        }`
    )

    const [validationErrors, setValidationErrors] = useState({})

    const submit = useCallback((obj) => {
        const errors = validate(obj)
        if (errors && Object.keys(errors).length) {
            setValidationErrors(errors)
            onError('validation Error')
            return
        }

        mutation({ variables: obj }).then((result) => {
            onSuccess(result)
        }).catch((e) => {
            setValidationErrors({ general: 'misc.apiError' })
            onError(e)
        })
    }, [mutation, onSuccess, onError])

    return { loading, data , error, validationErrors, submit }
}

// import {useMutation, gql} from '@apollo/client'

// const useSetEducatorNote = (onSuccess = () => {}, onError = () => {}) => {
//     return useMutation(
//         gql`mutation (
//                 $userId: ID!
//                 $educatorNote: String!
//             ) {
//             updateEducatorNote(
//               staffInfo: $staffInfo
//               userId: $userId
//             ) 
//         }`,
//         {
//             onCompleted: onSuccess, 
//             onError
//         }
//     )
// }

// export default useSetEducatorNote