import React from 'react'
import {
    IconArrowRight, IconCreditCard,
    IconDuplicate,
    IconPenalize, IconRepeat, IconVideo,
    ListItem
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch} from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'

const AttendedList = (props) => {
    const {translate} = useTranslation()
    const dispatch = useDispatch()
    const {lesson: dataLesson} = props
    const makeup = dataLesson?.isMakeUp || false

    return (
        <>
            <ListItem
                className='p-2'
                onClick={() => {
                    if (dataLesson?.recurringId) window.open(dataLesson?.recurringId, '_blank')
                }}
                Left={
                    <div className='d-flex flex-row'>
                        <IconVideo color={!dataLesson?.recurringId ? '#D6DAE3' : '#72809D'}
                                   className='align-self-center mr-2'/>
                        <span
                            className={`align-self-center ${dataLesson?.recurringId ? 'text-title' : 'text-disabled'}`}>
              {translate('modal.infolesson.viewlessonrecording')}
            </span>
                    </div>
                }
                Right={
                    !dataLesson?.recurringId
                        ? <IconArrowRight color='#D6DAE3'/>
                        : <IconArrowRight/>
                }
            />
            <ListItem
                onClick={() => {
                    dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'plan.duplicateLessonModal',
                        data: {
                            isOpen: true,
                            dataLesson: dataLesson
                        }
                    })
                }}
                showTopLine
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconDuplicate color='#72809D' className='align-self-center mr-2'/>
                        <span className='align-self-center text-title'>
              {translate('modal.infolesson.duplicatelesson')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
            <ListItem
                onClick={() => {
                    if (!makeup) {
                        dispatch({
                            type: 'SET_DATA_MODAL_FORM_LIST',
                            payload: 'plan.makeUpLesson',
                            data: {
                                isOpen: true,
                                dataLesson: dataLesson
                            }
                        })
                    }
                }}
                showTopLine
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconRepeat color={makeup ? '#D6DAE3' : '#72809D'} className='align-self-center mr-2'/>
                        <span className={`align-self-center ${makeup ? 'text-disabled' : 'text-title'}`}>
              {translate('modal.infolesson.makeuplesson')}
            </span>
                    </div>
                }
                Right={
                    makeup
                        ? <IconArrowRight color='#D6DAE3'/>
                        : <IconArrowRight/>
                }
            />
            <ListItem
                showTopLine
                onClick={
                    () => {
                        dispatch({
                            type: 'SET_DATA_MODAL_FORM_LIST',
                            payload: 'plan.penalizeLesson',
                            data: {
                                isOpen: true,
                                dataLesson: dataLesson
                            }
                        })
                    }
                }
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconPenalize color='#72809D' className='align-self-center mr-2'/>
                        <span className='align-self-center text-title'>
              {translate('modal.infolesson.penalizelesson')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
            <ListItem
                showTopLine
                showBottomLine
                onClick={
                    () => {
                        dispatch({
                            type: 'SET_DATA_MODAL_FORM_LIST',
                            payload: 'plan.editPaymentLesson',
                            data: {
                                isOpen: true,
                                dataLesson
                            }
                        })
                    }
                }
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconCreditCard color='#72809D' className='align-self-center mr-2'/>
                        <span className='align-self-center text-title'>
              {translate('modal.infolesson.editpayment')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
            <ListItem
                showTopLine
                onClick={() => {
                    dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'plan.cancelLesson',
                        data: {
                            isOpen: true,
                            lessonId: dataLesson?.id || '',
                            isOrientation: false
                        }
                    })
                }}
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <span className='align-self-center text-title'>
              {translate('modal.infolesson.youneedremove')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
        </>
    )
}

export default AttendedList
