import React, { useEffect, useMemo } from 'react'
import {
  InvoiceCard,
  ResumenInvoice,
  IconStarOutline,
  IconLesson,
  ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import usePayments from '../../../Student/modal/InfoStudent/useQueries/usePayments'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'

const Payments = (props) => {
  const { idStudent, paymentMethod, page } = props
  const { translate } = useTranslation()
  const [paymentsQuery, { data, loading }] = usePayments()
  useEffect(() => {
    if (page === 'payments' && idStudent) {
      paymentsQuery(
        {
          variables: {
            limit: 0,
            userId: [idStudent]
          }
        }
      )
    }
  }, [page, idStudent, paymentsQuery])

  const [
    unPaid,
    notUpcommingsPayments,
    upcommingPayments,
    others
  ] = useMemo(() => {
    if (data?.invoices) {
      //arreglo con los pagos que no caen en ninguna de las anteriores categorias
      //unPaid, notUpcommingsPayments, upcommingPayments
      let others = []

      const pendingPayment = data?.invoices?.docs?.filter((payment) => payment.status === 'pendingPayment')
      others = others.concat(
        data?.invoices?.docs?.filter(
          ({ status }) => !['unPaid', 'pendingPayment'].includes(status)
        )
      )
      const unPaid = data?.invoices?.docs?.find((payment) => payment.status === 'unPaid')

      // ARREGLO PARA PAGOS NO UPCOMMINGS
      const notUpcommingsPayments = []
      // PROXIMA FECHA DE PAGO
      let minDate = pendingPayment?.reduce((minMoment, payment) => {
        if (moment(payment?.to).toDate() < minMoment) return moment(payment?.to).toDate()
        return minMoment
      }, Number.POSITIVE_INFINITY)
      minDate = moment(minDate)

      const upcommingPayments = pendingPayment?.reduce(
        (arrayUpcomming, payment) => {
          if (
            moment(payment.to).month() === minDate.month() &&
            moment(payment.to).year() === minDate.year()
          ) arrayUpcomming.push(payment)
          else notUpcommingsPayments.push(payment)
          return arrayUpcomming
        }
        , []
      )
      return [
        unPaid,
        notUpcommingsPayments,
        upcommingPayments,
        others
      ]
    }
    return []
  }, [data])

  const paymentName = {
    BankCreditPaymentMethod: {
      name: translate('student.modal.payments.credit')
    },
    BankDebitPaymentMethod: {
      name: translate('student.modal.payments.debit')
    },
    ManualPaymentMethod: {
      name: translate('student.modal.payments.manual')
    },
    CardPaymentMethod: {
      name: translate('student.modal.payments.card')
    },
    ZellePaymentMethod: {
      name: translate('student.modal.payments.zelle')
    }
  }

  const convertCamelToSentence = (text) => {
    if (text) {
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
    return ''
  }

  return (
    <div className='d-flex flex-column m-0 p-0 pr-6 mt-4'>
      {
        (loading)
          ? <Skeleton height={190} count={1} className='w-100' />
          : <>
            {
              (unPaid)
                ? (
                  <InvoiceCard
                    type='warn'
                    text={`${translate('student.modal.payments.endOf')} ${moment(unPaid.to).format('MMMM')}`}
                    textTop='Next pay:'
                    typePayment={paymentName[paymentMethod?.__typename]?.name}
                    amount={unPaid.amount}
                    invoice={`INV-${unPaid.invoiceNumber}`}
                    empty={false}
                    emptyText=''
                  />
                )
                : upcommingPayments?.length > 0 &&
                (
                  <>
                    <InvoiceCard
                      type='normal'
                      text={`${translate('student.modal.payments.endOf')} ${moment(upcommingPayments[0].to).format('MMMM')}`}
                      textTop={`${translate('student.modal.payments.nextPay')}:`}
                      typePayment={paymentName[paymentMethod?.__typename]?.name}
                      amount={upcommingPayments[0].amount}
                      invoice={`INV-${upcommingPayments[0].invoiceNumber}`}
                      empty={false}
                      emptyText=''
                    />
                    {
                      upcommingPayments.map((payment, index) => {
                        if (index === 0) return (null)
                        return (
                          <ResumenInvoice
                            key={index}
                            type={
                              payment?.category === 'counseling'
                                ? 'counseling'
                                : 'upcomming'
                            }
                            text={
                              payment?.category === 'counseling'
                                ? 'Counseling'
                                : `${translate('student.modal.payments.endOf')} ${moment(payment?.to).format('MMMM')}`
                            }
                            className='mx-0 my-1 px-2 py-1'
                            amount={payment?.amount}
                            icon={
                              payment?.category === 'counseling'
                                ? <IconStarOutline color='white' size={15} className='text-muted' />
                                : <IconLesson color='white' size={15} className='text-muted' />
                            }
                          />
                        )
                      })
                    }
                  </>
                )
            }
          </>
      }
      <div className='p-0 mx-0 mt-5 mb-2'>
        <div className='mx-0 mt-0 mb-3 p-0 d-flex flex-row justify-content-between'>
          <span className='font-weight-bold text-title'>{translate('student.modal.payments.upcomming')}</span>
          {/* <span className='text-gray'>{translate('student.modal.payments.viewHistory')}</span> */}
        </div>
        {
          (loading)
            ? <Skeleton count={4} height={38} className='w-100 mx-0 my-1 px-2 py-1' />
            : <>
              {
                (unPaid && upcommingPayments) &&
                upcommingPayments.map((payment, indexPayment) => {
                  return (
                    <ToolTipInfo
                      key={`upcommingPayments${indexPayment}`}
                      tooltipId={`upcommingPayments${indexPayment}`}
                      innerElement={<span>{convertCamelToSentence(payment?.status)}</span>}
                    >
                      <ResumenInvoice
                        key={indexPayment}
                        type='upcomming'
                        text={
                          payment?.category === 'counseling'
                            ? 'Counseling'
                            : `${translate('student.modal.payments.endOf')} ${moment(payment?.to).format('MMMM')}`
                        }
                        className='mx-0 my-1 px-2 py-1'
                        amount={payment?.amount}
                        icon={
                          payment?.category === 'counseling'
                            ? <IconStarOutline color='white' size={15} className='text-muted' />
                            : <IconLesson color='white' size={15} className='text-muted' />
                        }
                      />
                    </ToolTipInfo>
                  )
                })
              }
              {
                notUpcommingsPayments &&
                notUpcommingsPayments.map((payment, index) => {
                  // pending: gris
                  // upcomming: azul
                  // unpaid: rojo arriba y mostrar upcomming en el listado de abajo
                  return (
                    <ToolTipInfo
                      key={`notUpcommingsPayments${index}`}
                      tooltipId={`notUpcommingsPayments${index}`}
                      innerElement={<span>{convertCamelToSentence(payment?.status)}</span>}
                    >
                      <ResumenInvoice
                        type='normal'
                        text={
                          payment?.category === 'counseling'
                            ? 'Counseling'
                            : `${translate('student.modal.payments.endOf')} ${moment(payment?.to).format('MMMM')}`
                        }
                        className='mx-0 my-1 px-2 py-1'
                        amount={payment?.amount}
                        icon={
                          payment?.category === 'counseling'
                            ? <IconStarOutline size={15} className='text-muted' />
                            : <IconLesson size={15} className='text-muted' />
                        }
                      />
                    </ToolTipInfo>
                  )
                })
              }
              <span className={`text-gray h45 my-2`}>Others</span>
              {
                others &&
                others.map((payment, index) => {
                  // pending: gris
                  // upcomming: azul
                  // unpaid: rojo arriba y mostrar upcomming en el listado de abajo
                  return (
                    <ToolTipInfo
                      key={`other${index}`}
                      tooltipId={`other${index}`}
                      innerElement={<span>{convertCamelToSentence(payment?.status)}</span>}
                    >
                      <ResumenInvoice
                        type='normal'
                        text={
                          payment?.category === 'counseling'
                            ? 'Counseling'
                            : `${translate('student.modal.payments.endOf')} ${moment(payment?.to).format('MMMM')}`
                        }
                        className='mx-0 my-1 px-2 py-1'
                        amount={payment?.amount}
                        icon={
                          payment?.category === 'counseling'
                            ? <IconStarOutline size={15} className='text-muted' />
                            : <IconLesson size={15} className='text-muted' />
                        }
                      />
                    </ToolTipInfo>
                  )
                })
              }
            </>
        }
        {/* <div
          style={{ cursor: 'pointer' }}
          className='znk-card empty mx-0 my-1 px-2 py-1'
          onClick={() => {
            // view all
          }}
        >
          <div className='left'>
            <div className='h45 text-gray'>{translate('student.modal.payments.viewAll')}</div>
          </div>
          <div><IconArrowRight size={15} className='text-gray' /></div>
        </div> */}
      </div>
    </div>
  )
}

export default Payments
