import React, {useMemo} from 'react'
import {
    CheckboxSelector, 
    IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import {Badge} from 'reactstrap'
import useAllTimelinesCountries from '../../Model/useAllTimelinesCountries'

const CountryFilter = (props) => {
    const {
        setFilters = () => {},
        filters = []
    } = props
    const {translate} = useTranslation()
    const capitalize = (text) => {
        const result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
    const {data} = useAllTimelinesCountries()
    const countries = useMemo(
        () => {
            return data?.allTimelinesCountries || []
        }, [data]
    )
    return (
        <CheckboxSelector
            setDataFilters={setFilters}
            direction='bottomCenter'
            dataFilters={filters}
            typeFilter='statuses'
            data={
                countries?.map((item) => {
                return { name: capitalize(item.name), id: item.id}
                }).sort((a, b) => {
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })
            }
            strings={{
                viewAll: translate('filters.plans.viewAll'),
                buttonCancel: translate('filters.plans.buttonCancel'),
                buttonApply: translate('filters.plans.buttonApply'),
                search: translate('filters.plans.search')
            }}
            >
            <Badge className='mx-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                    Country
                    <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default CountryFilter