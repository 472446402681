import React, { useEffect, useState, useMemo } from 'react'
import {
  Modal,
  IconClose,
  Field,
  SelectOption,
  Form,
  InputSelect,
  PlainTextInput,
  StickyButton,
  Button,
  LabeledField,
  AmountInput
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import PlanStudent from '../../../Plans/modal/newPlan/Components/PlanStudent'
import createPaymentItemFromAdmin from './createPayment'
import { categories, types } from '../constants'
import moment from 'moment'
import { isEmpty } from 'validate.js'
import {escapeRegExp} from 'lodash'

const NewPaymentItem = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.newPayment)
  const { isOpen } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [errors, setErrors] = useState({})
  const [form, setForm] = useState({
    student: null,
    chargeType: 'studentCharge',
    category: 'livePrep',
    type: 'lessonsPayment',
    amount: 0,
    discount: 0,
    date: moment(new Date()).format('YYYY-MM-DD'),
    description: ''
  })
  const { submit, loading, validationErrors } = createPaymentItemFromAdmin((result) => {
    refetch()
    toggle()
  }, console.log)
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key.split('.')[1]
        customError[keyVal] = translate('payments.addpayment.errors.' + keyVal)
      })
      setErrors(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])
  const toggle = () => {
    setForm({
      student: null,
      chargeType: 'studentCharge',
      type: 'lessonsPayment',
      category: 'livePrep',
      amount: 0,
      discount: 0,
      date: moment(new Date()).format('YYYY-MM-DD'),
      description: ''
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.newPayment',
      data: {
        isOpen: false
      }
    })
  }
  const onSubmit = () => {
    const newForm = { ...form }
    newForm.userId = form.student ? form.student.id : ''
    if (form.student) delete newForm.student
    submit({ paymentInput: newForm })
  }
  const filterTypes = useMemo(
    () => {
      return types.filter(
        e => e.type.includes(form.category?.toUpperCase() === 'LIVEPREP' ? 'invoice': 'counseling')
        )
        //eslint-disable-next-line
      }, [form, types]
  )
  useEffect(() => {
    setForm({
      ...form,
      type: null
    })
    //eslint-disable-next-line
  }, [form?.category])

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('payments.addnewpayment')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={form} onChange={setForm} validationErrors={errors}>
          <div className='m-0 p-0 mb-2 pl-2 pr-2'>
            <hr className='m-0 p-0' />
            <PlanStudent className='m-0 p-0' form={form} setForm={setForm} first={false} title={false} invoice />
            {validationErrors && validationErrors['paymentInput.userId'] && <div className='text-error'>{translate('payments.addpayment.errors.user')}</div>}
            <hr className='m-0 p-0' />
            <div className='row d-flex m-0 p-0'>
              <div className='col-12 p-0'>
                <Field
                  className='col-12 row inline mt-4'
                  type={SelectOption}
                  fieldName='chargeType'
                  options={[
                    { id: 'studentCharge', text: translate('payments.charges.studentCharge') },
                    { id: 'studentRefund', text: translate('payments.charges.studentRefund') }
                  ]}
                  studentCharge
                  StyleOptionClassName={{
                    width: '49%',
                    whiteSpace: 'nowrap'
                  }}
                  StyleSelect={{
                    justifyContent: 'space-between'
                  }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Category</label>
                <Field
                  className='col-12'
                  component={InputSelect}
                  type={LabeledField}
                  fieldName='category'
                  placeholder={translate('modal.newplan.selectservice')}
                  options={categories && [].concat(categories.map((serv) => ({ value: serv.id, label: translate(`payments.categories.${serv.id}`) })))}
                  InputClassName='w-100'
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                    return false
                  }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Type</label>
                <Field
                  className='col-12'
                  component={InputSelect}
                  fieldName='type'
                  type={LabeledField}
                  placeholder={translate('modal.newplan.selectservice')}
                  options={filterTypes.map(
                      (serv) => ({ value: serv.id, label: translate(`payments.types.${serv.id}`) })
                    ).concat(
                      types.filter(t => t.id === 'other').map((serv) => ({ value: serv.id, label: translate(`payments.types.${serv.id}`) }))
                    )
                  }
                  InputClassName='w-100'
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                    return false
                  }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 mt-2 p-0'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>Discount</label>
                <Field
                  className='col-12 placeholder-to-right'
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='discount'
                  fieldType='number'
                  placeholder='0.00 '
                  addOn='%'
                  min='0'
                />
              </div>
              <div className='col-6 pl-1 pr-0 pl-2'>
                <label className='text-gray font-weight-light mb-2'>Amount</label>
                <Field
                  className='col-12 placeholder-to-right'
                  component={AmountInput}
                  type={LabeledField}
                  fieldName='amount'
                  fieldType='number'
                  placeholder='0.00 '
                  step="0.1"
                  addOn='$'
                  min='0'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Description</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  placeholder='Description'
                  fieldName='description'
                  fieldType='text'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Payment Date</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='date'
                  fieldType='date'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-3'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loading}
                      className='col-12 aling-text-left'
                      label={translate('payments.newinvoice.addnewitem')}
                      onClick={() => { onSubmit() }}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default NewPaymentItem
