import React, { useState, useMemo } from 'react'
import {
  Modal,
  useWindowDimensions,
  Button,
  IconClose,
  IconArrowRight,
  Field,
  CheckboxOption,
  ListItem,
  Avatar,
  Form
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import SendPlansFromAdmin from '../../../../Model/Plan/SendPlansFromAdmin'

const SendAllPlans = (props) => {
  const { getStudentById } = props
  const { isOpen, confirmAction, dataPlan, refetch } = useSelector((state) => state.modal.plan.sendAllPlansModal)
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()

  const { submit: sendPlans, loading: loadSendPlans } = SendPlansFromAdmin(
    (result) => {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.sendAllPlansModal',
        data: {
          isOpen: false,
          confirmAction: () => {},
          dataPlan: null,
          refetch: refetch
        }
      })
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.sendOnePlanModal',
        data: {
          isOpen: false,
          confirmAction: () => {},
          dataPlan: null,
          refetch: refetch
        }
      })
      // hacer refetch de la query de childplans
      refetch()
    }, (err) => {
      console.log(err)
      alert('error')
    }
  )
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.sendAllPlansModal',
      data: { isOpen: !isOpen, confirmAction, dataPlan: dataPlan, refetch: refetch }
    })
  }
  const [form, setForm] = useState({})
  let allScheduled = true

  const validation = useMemo(() => {
    return Object.entries(form).reduce((valid, [planId, obj]) => {
      return valid || (obj.student || obj.parent)
    }, false)
  }, [form])

  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-colored`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header mx-5 mt-7 mb-1 d-flex flex-column'>
            <div className='m-0 p-0 d-flex flex-row w-100 justify-content-between'>
              <span className='h2 font-weight-bold text-title'>
                {translate('plans.modal.sendplan.titleAll')}
              </span>
              <IconClose size={24} onClick={toggle} />
            </div>
            <span className='h45 text-gray'>
              Fusce dapibus, tellus ac curs. cosmmodo, tortor mauris ondimentum.
            </span>
          </div>
        }
      >
        <div className='row mt-1 mx-4 mb-4 p-0'>
          <div className='m-0 p-0 w-100 mt-1 mx-5 mb-5'>
            <div className='m-0 p-0'>
              <Form value={form} onChange={setForm}>
                {dataPlan && dataPlan.studentPlans.map((stu) => {
                  const stuInfo = getStudentById(stu.studentId)
                  const parentInfo = stuInfo?.studentInfo?.parents ? stuInfo.studentInfo.parents.reduce((accuPar, par) => {
                    if (par.managePayments) return par
                    else return accuPar
                  }, {}) : {}
                  if (stu.planStatus === 'draft' || stu.planStatus === 'sent') {
                    allScheduled = false
                    return (
                      <>
                        <ListItem
                          className='py-2'
                          label={null}
                          Left={
                            <div className='d-flex align-items-center'>
                              <Avatar
                                name={stuInfo && `${stuInfo.firstName} ${stuInfo.lastName}`}
                                named={false}
                                uri={null}
                                size='avatar-small'
                              />
                              <label className='ml-2 h4'>
                                {stuInfo && `${stuInfo.firstName} ${stuInfo.lastName}`}
                                <span className='h5 text-gray'> {`(${translate('plans.modal.sendplan.student')})`}</span>
                              </label>
                            </div>
                          }
                          Right={
                            <Field
                              type={CheckboxOption}
                              fieldName={`${stu.planId}.student`}
                              checked={form[stu.planId] ? form[stu.planId].student : false}
                              onChange={(e) => {
                                if (!form[stu.planId]) {
                                  setForm({ ...form, [stu.planId]: { student: e, parent: false } })
                                } else {
                                  setForm({ ...form, [stu.planId]: { ...form[stu.planId], student: e } })
                                }
                              }}
                            />
                          }
                        />
                        {
                          stuInfo && parentInfo && JSON.stringify(parentInfo) !== '{}' &&
                          stuInfo.studentInfo.studentType === 'family' &&
                            <ListItem
                              className='py-2 ml-3'
                              label={null}
                              Left={
                                <div className='d-flex align-items-center'>
                                  <Avatar
                                    name={`${parentInfo.firstName} ${parentInfo.lastName}`}
                                    named={false}
                                    uri={null}
                                    size='avatar-small'
                                  />
                                  <label className='ml-2 h4'>
                                    {`${parentInfo.firstName} ${parentInfo.lastName}`}
                                    <span className='h5 text-gray'> {`(${translate('plans.modal.sendplan.parent')})`}</span>
                                  </label>
                                </div>
                              }
                              Right={
                                <Field
                                  type={CheckboxOption}
                                  fieldName={`${stu.planId}.parent`}
                                  checked={form[stu.planId] ? form[stu.planId].parent : false}
                                  onChange={(e) => {
                                    if (!form[stu.planId]) {
                                      setForm({ ...form, [stu.planId]: { student: false, parent: e, name: `${stuInfo.firstName} ${stuInfo.lastName}` } })
                                    } else {
                                      setForm({ ...form, [stu.planId]: { ...form[stu.planId], parent: e } })
                                    }
                                  }}
                                />
                              }
                            />
                        }
                        <div className='borderTop my-2' />
                      </>
                    )
                  } else return (null)
                })}
                {
                  allScheduled && <label className='h4 text-gray my-4'>{translate('plans.modal.sendplan.allScheduled')}</label>
                }
              </Form>
            </div>
            <Button
              color='#696CFF'
              style={{ backgroundColor: '#696CFF' }}
              className='w-100 mt-3'
              disabled={allScheduled || !validation}
              label={
                <span className='w-100 d-flex' style={{ justifyContent: 'space-between' }}>
                  {translate('plans.modal.sendplan.button')}
                  <IconArrowRight size={20} />
                </span>
              }
              activity={loadSendPlans}
              onClick={() => {
                sendPlans({
                  items: Object.entries(form).reduce((array, [idPlan, obj]) => {
                    if (obj.student || obj.parent) {
                      array.push({
                        planId: idPlan,
                        sendToStudent: obj.student,
                        sendToParent: obj.parent
                      })
                    }
                    return array
                  }, [])
                })
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SendAllPlans
