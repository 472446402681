import {
    ModalPushScroll,
    IconClose,
    IconOptions,
    Avatar,
    SelectUser,
    Modal,
} from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FullProfile from '../../../Users/Modal/Profile/full'
import SimpleProfile from '../../../Users/Modal/Profile/simple'
import { gql, useLazyQuery } from '@apollo/client'
import PersonalModalForm from '../../../Users/Modal/changePersonal'
import Skeleton from 'react-loading-skeleton'
import { Collapse, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import Childrens from '../../Components/Childrens'
import Payments from '../../Components/Payments'
import Notes from '../../../Student/modal/InfoStudent/Notes'
import EditAvatar from '../../../../common/EditAvatar'
import useTranslation from '../../../../i18n/useTranslation'

import ChangeNotificationsModal from '../../../Users/Modal/Student/ChangeNotifications'

import './style.css'
import ChangeTimezone from '../../../Student/modal/InfoStudent/Profile/ChangeTimezone'
import userQueryResult from '../../../../Model/Users/QueryResults/userQueryResult'
import ChangePaymentMethodPFromParent from '../../Components/ChangePaymentMethod'
import { ordenateUserArray } from '../../Utils/ordenateUsersArray'
import { addPaymentMethodToChildrens } from '../../Utils/addPaymentMethodToChildrens'
import { addIdToStudents } from '../../Utils/addIdToStudentList'

const InfoParent = (props) => {
    const { countries, refetch } = props
    const { translate } = useTranslation()
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.parent.infoParentModal)
    const { isOpen, full, userId, type, initPage, pageProfile, tab } = reduxData
    const [selectUser, setSelectUser] = useState('')
    const [openCrop, setOpenCrop] = useState(false)
    const [isReset, setIsReset] = useState(null)
    const toggleCrop = () => {
        setOpenCrop(!openCrop)
    }
    const toggle = () => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'parent.infoParentModal',
            data: {
                ...reduxData,
                full: false,
                isOpen: !isOpen,
                pageProfile: 'profile'
            }
        })
    }
    const toggleFull = () => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'parent.infoParentModal',
            data: {
                ...reduxData,
                full: !full,
                pageProfile: 'profile'
            }
        })
    }

    const openFull = () => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'parent.infoParentModal',
            data: {
                ...reduxData,
                full: true
            }
        })
    }

    const [id] = useState(null)
    const getxUser = (userId) => {
        getUser({id: userId})
        //setId(userId)
    }
    const [getUser, { data, loading: loadParentData }] = useLazyQuery(
        gql`query($id: String!) {
        user(id: $id) {
            ${userQueryResult}
        }
      }
    `,
        {
            variables: { id: userId || id },
            fetchPolicy: 'network-only'
        }
    )
    useEffect(() => {
        if (userId || id) {
            getUser()
        }
        // eslint-disable-next-line
    }, [userId || id])
    const [activeTab, setActiveTab] = useState(tab ? tab : initPage || 'summary')
    const parentName = `${data?.user?.firstName} ${data?.user?.lastName}`
    const parentAvatar = data?.user?.avatar

    useEffect(() => {
        if (initPage && !tab) setActiveTab(initPage)
    }, [initPage, tab])
    // altura de la sección profile para dejar los tabs en el borde inferior de la pantalla
    let navMenuDimensions = null
    let modalContentDimensions = null
    let modalHeaderDimensions = null
    const [profileMenuDimensions, setProfileMenuDimensions] = useState({})
    useEffect(() => {
        if (navMenuDimensions && modalContentDimensions && modalHeaderDimensions) {
            setProfileMenuDimensions({
                // determina el alto que debe tener el menu de profile para que el listado de tabs
                // aparesca en la parte inferior de la pantalla.
                minHeight: `${parseInt(modalContentDimensions.height - modalHeaderDimensions.height - 2.5 * navMenuDimensions.height)}px`
            })
        }
    }, [navMenuDimensions, modalContentDimensions, modalHeaderDimensions, isOpen])
    const setPageProfile = (newPage) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'parent.infoParentModal',
            data: {
                ...reduxData,
                full: true,
                pageProfile: newPage
            }
        })
    }
    const onChangeAvatar = (file) => {
        setOpenCrop(true)
    }
    const fetch = async () => {
        await getUser()
        setIsReset(isReset === null ? true : !isReset)
    }
    
    return (
        <>
            <Modal
                className='modal-centered medium modal-colored white-modal pr-3 pl-3 '
                isOpen={openCrop}
                toggle={toggleCrop}
                backdrop='static'
                style={{ height: '95%' }}
                StyleModalBody={{ height: '100%' }}
                classModalBody='contentHeight'
                Head={
                    <div className='modal-header p-0 m-0'>
                        <div className='d-flex justify-content-between w-100 pt-7 pb-4 px-7'>
                            <h2 className='font-weight-bold'>{translate('profile.actions.changeAvatar.title')}</h2>
                            <IconClose onClick={toggleCrop} className='text-gray' />
                        </div>
                    </div>
                }
            >
                <EditAvatar userId={userId} fetch={fetch} toogle={toggleCrop} />
            </Modal>
            <ModalPushScroll
                isOpen={isOpen}
                more={full}
                minHead={null}
                refContent={(el) => {
                    if (!el) return
                    modalContentDimensions = el.getBoundingClientRect()
                }}
                MinHead={
                    <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
                        <div className='d-flex flex-row align-items-center'>
                            {
                                (loadParentData)
                                    ? (
                                        <>
                                            <Skeleton circle count={1} height={50} width={50} /> <Skeleton count={1} width={50} height={25} className='ml-3' />
                                        </>
                                    )
                                    : (
                                        <>
                                            <Avatar
                                                name={parentName}
                                                uri={parentAvatar}
                                                innerDiv
                                                size='avatar-small'
                                                activateChange={true}
                                                onClickImage={onChangeAvatar}
                                                isReset={isReset}
                                            /> <span className='h4 text-title ml-2'>{parentName}</span>
                                        </>
                                    )
                            }
                        </div>
                        <div className='d-flex flex-row align-items-center'>
                            <IconOptions color='#D7E1EA' onClick={() => { }} className='mr-2' style={{ transform: 'rotate(90deg)' }} />
                            <IconClose color='#D7E1EA' onClick={toggle} />
                        </div>
                    </div>
                }
                Head={
                    <div
                        className='modal-header px-6 px-sm-7 d-flex flex-column pt-4 mt-0'
                    >
                        <div
                            ref={(el) => {
                                if (!el) return
                                modalHeaderDimensions = el.getBoundingClientRect()
                            }}
                            className='d-flex flex-row justify-content-between w-100'
                        >
                            {
                                (loadParentData)
                                    ? (
                                        <div className='d-flex flex-column w-100'>
                                            <Skeleton duration={2} circle count={1} height={120} width={120} />
                                            <Skeleton duration={2} count={1} height={40} className='w-100' />
                                            <Skeleton duration={2} count={4} height={20} className='w-100' />
                                        </div>
                                    )
                                    : (
                                        <div className='d-flex flex-column'>
                                            <Avatar
                                                name={parentName}
                                                uri={parentAvatar}
                                                innerDiv
                                                size='avatar-extra'
                                                activateChange={true}
                                                onClickImage={onChangeAvatar}
                                                isReset={isReset}
                                            />
                                            <div className='mt-5 mb-3 d-flex flex-column'>
                                                <span className='h2 font-weight-bold text-title'>{parentName}</span>
                                                <span className='h45 text-gray mt-2'>{data?.user?.emails?.length > 0 ? data.user.emails[0].address : 'No defined'}</span>
                                                <span className='h45 text-gray mt-2'>
                                                    {data?.user?.phone}
                                                </span>
                                                <span className='h45 text-gray mt-2'>
                                                    {`Parent | Facebook | ${data?.user?.isActive ? 'Active' : 'Inactive'}`}
                                                </span>
                                                <span className='h45 text-primary btn-link mt-2' onClick={() => {
                                                    setPageProfile('full-profile')
                                                }}>View complete profile</span>
                                            </div>
                                        </div>
                                    )
                            }
                            <div className='d-flex flex-row'>
                                <IconOptions color='#D7E1EA' onClick={() => { }} className='mr-2' style={{ transform: 'rotate(90deg)' }} />
                                <IconClose color='#D7E1EA' onClick={toggle} />
                            </div>
                        </div>
                        <div className='m-0 p-0 w-100'>
                            <Collapse isOpen={activeTab === 'profile'}>
                                <SimpleProfile
                                    style={profileMenuDimensions}
                                    user={data && data.user}
                                    openFull={openFull}
                                    setPage={(newPage) => {
                                        openFull()
                                        setPageProfile(newPage)
                                    }}
                                    page={pageProfile}
                                    type={type}
                                    className='m-0 p-0'
                                />
                            </Collapse>
                        </div>
                    </div>
                }
                sideLeft={
                    <div className='pl-6 pl-sm-7 m-0 pt-4 pr-0'>
                        <div
                            ref={(el) => {
                                if (!el) return
                                navMenuDimensions = el.getBoundingClientRect()
                            }}
                            className='znk-scroll-horizontal'
                            style={{ overflowX: 'scroll' }}
                        >
                            <Nav className='w-100 flex-nowrap'>
                                <NavItem className='nav-title mb-2 mr-2' active={activeTab === 'profile'}>
                                    <NavLink onClick={() => { setActiveTab('profile') }}>
                                        Profile
                                    </NavLink>
                                </NavItem>
                                <NavItem className='nav-title mb-2 mr-2' active={activeTab === 'students'}>
                                    <NavLink onClick={() => { setActiveTab('students') }}>
                                        Students
                                    </NavLink>
                                </NavItem>
                                <NavItem className='nav-title mr-2' active={activeTab === 'payments'}>
                                    <NavLink onClick={() => { setActiveTab('payments') }}>
                                        Payments
                                    </NavLink>
                                </NavItem>
                                <NavItem className='nav-title mr-2' active={activeTab === 'notes'}>
                                    <NavLink onClick={() => { setActiveTab('notes') }}>
                                        Notes
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                        <TabContent activeTab={activeTab} className='px-2'>
                            <TabPane tabId='profile' />
                            <TabPane tabId='students'>
                                {/* <Summary educator={data && data.user ? data.user : null} loading={loadParentData} /> */}
                                <Childrens key={data?.user?.parentInfo?.children?.studentUserId} 
                                childrens={data?.user?.parentInfo?.children} page={activeTab} 
                                parentId={data?.user?.id}/>
                            </TabPane>
                            <TabPane
                                className='mr-5 mt-2 ml-1'
                                tabId='payments'>
                                    <span className='h45 text-gray mt-2'>Select student</span>
                                {activeTab === 'payments' && <SelectUser
                                    className={'pr-6'}
                                    activeUsers={addIdToStudents(data?.user?.parentInfo?.children)}
                                    selectUser={selectUser}
                                    setSelectUser={setSelectUser}
                                    ordenateUserArray={ordenateUserArray}
                                // refetchIn={refetchIn}
                                // setRefetchIn={setRefetchIn}
                                />}
                                {selectUser &&
                                    <Payments page={activeTab} idStudent={selectUser} paymentMethod={data?.user?.paymentMethod} />}

                            </TabPane>
                            <TabPane tabId='notes'>
                                <Notes className='pr-6' page={activeTab} studentId={userId} />
                            </TabPane>
                        </TabContent>
                    </div>
                }
                sideRight={
                    <TabContent activeTab={pageProfile} className='px-2'>
                        <TabPane tabId='full-profile'>
                            <FullProfile afterEditPersonalAction={() => {
                                //se ejecuta al clickear link Edit en ViewProfile
                                setPageProfile('edit-profile')
                            }} user={data && data.user} type={type} refetch={(id) => {
                                getxUser(id)}} toggleRightSide={toggleFull} />
                        </TabPane>
                        <TabPane tabId='edit-profile'>
                            <PersonalModalForm
                                countries={countries}
                                refetchUser={(id) => getxUser(id)}
                                toggleRightSide={toggleFull}
                                actionAfterUpdate={() => {
                                    // acción que se ejecuta luego de actualizar la información del usuario
                                    // actualiza listado de usuarios
                                    refetch()
                                    // cierra lado derecho del modal
                                    setPageProfile('full-profile')
                                }}
                            />
                        </TabPane>
                        <TabPane tabId='changeTimezone'>
                            <ChangeTimezone
                                currentTimezone={data?.user?.timezone}
                                toggle={toggleFull}
                                userId={data?.user?.id}
                            />
                        </TabPane>
                        <TabPane tabId='notifications'>
                            <ChangeNotificationsModal toggleFull={toggleFull} />
                        </TabPane>
                        <TabPane tabId='changePaymentMethod'>
                          { pageProfile === 'changePaymentMethod' && <ChangePaymentMethodPFromParent 
                           key={data?.user?.id}
                           parent={data?.user}
                        //    childrens={data?.user?.parentInfo?.children}
                           childrens={addPaymentMethodToChildrens(data?.user?.parentInfo?.children, data?.user?.childrenPaymentMethod)}
                           toggleFull={toggleFull}
                           refetch={refetch}
                           />}
                        </TabPane>
                    </TabContent>
                }
                overRight={
                    null
                }

            />
        </>
    )
}

export default InfoParent