import React from 'react'
import {
    IconEdit,
    IconVideo,
    IconPenalize,
    IconRepeat,
    IconDuplicate,
    IconWspAlert,
    IconEmail,
    IconCreditCard,
    IconProgram,
    IconSignPlus
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { hasHttp } from '../../../../Calendar/modal/utils'
import useTranslation from '../../../../../i18n/useTranslation'
const ICONSIZE = 15
const useGetItems = (lesson, refetch = () => {}) => {
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const services = useSelector((state) => state.user.services)
    const orientationId = services.find(({isOrientation}) => isOrientation)?.id
    const editLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconEdit className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.editlesson')}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'lesson.editLessonModal',
                data: {
                    isOpen: true,
                    dataLesson: lesson,
                    editPending: lesson?.status === 'pending'
                }
            })
        }
    }
    const penalizeLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconPenalize className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.penalizelesson')}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'plan.penalizeLesson',
                data: {
                    isOpen: true,
                    dataLesson: lesson
                }
            })
        }
    }
    const makeupLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconRepeat className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.makeuplesson')}</span>,
        onClick: () => {
            if (!(lesson?.isMakeUp || false)) {
                dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'plan.makeUpLesson',
                    data: {
                        isOpen: true,
                        dataLesson: lesson
                    }
                })
            }
        }
    }
    const duplicateLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconDuplicate className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.duplicatelesson')}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'plan.duplicateLessonModal',
                data: {
                    isOpen: true,
                    dataLesson: lesson
                }
            })
        }
    }
    const rescheduledLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconProgram className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.rescheduling')}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'lesson.rescheduledLesson',
                data: {
                    isOpen: true,
                    dataLesson: lesson
                }
            })
        }
    }


    const setPendingLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconEdit className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.leavepending')}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'lesson.rescheduledLesson',
                data: {
                    isOpen: true,
                    dataLesson: lesson
                }
            })
        }
    }
    const wspAlertLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconWspAlert className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.sendwspalert')}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'lesson.wspAlert',
                data: {
                    isOpen: true,
                    dataLesson: lesson,
                    studentsId: [],
                    educatorId: null
                }
            })
        }
    }
    const emailAlertLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconEmail className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.sendreminder')}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'lesson.sendEmailReminder',
                data: {
                    isOpen: true,
                    dataLesson: lesson,
                    studentsId: [],
                    educatorId: null
                }
            })
        }
    }
    //finished
    const viewRecordingLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconVideo className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.viewlessonrecording')}</span>,
        onClick: () => {
            window.open(hasHttp(lesson.recordingUrl), '_blank')
        }
    }
    const editPaymentLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconCreditCard className={`mr-2`} size={ICONSIZE}/> {translate('modal.infolesson.editpayment')}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'plan.editPaymentLesson',
                data: {
                  isOpen: true,
                  dataLesson: lesson
                }
            })
        }
    }   

    const cancelLessonItem = {
        label: <span className={`d-flex flex-row align-items-center`}><IconSignPlus className={`mr-2`} size={ICONSIZE}/> {'Cancel Lesson'}</span>,
        onClick: () => {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'plan.cancelLesson',
                data: {
                  isOpen: true,
                  lessonId: lesson?.id || '',
                  isOrientation: lesson?.isMeeting ? false : lesson.service.id === orientationId,
                  isMeeting: lesson?.isMeeting,
                  dataLesson: lesson,
                  refetch: refetch
                }
            })
        }
    }   
    return {
        viewRecordingLessonItem,
        emailAlertLessonItem,
        wspAlertLessonItem,
        setPendingLessonItem,
        rescheduledLessonItem,
        duplicateLessonItem,
        makeupLessonItem,
        penalizeLessonItem,
        editLessonItem,
        editPaymentLessonItem,
        cancelLessonItem
    }
}

export default useGetItems