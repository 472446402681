import React from 'react'
import { Avatar, IconAdd, ListItem } from '@Knowledge-OTP/znk-ui-components'

const ParentItem = (props) => {
  const { firstName, lastName, id, addItem } = props
  return (
    <ListItem
      Left={
        <Avatar
          name={`${firstName} ${lastName}`}
          named
          className='flex-row'
        />
      }
      Right={<IconAdd size={20} style={{ cursor: 'pointer' }} onClick={() => addItem(id)} />}
    />
  )
}

export default ParentItem
