import validate from 'validate.js'

const constraints = {
  userId: {
    presence: true
  },
  'studentInfo.institution': {
    presence: {
      allowEmpty: false
    }
  },
  // 'studentInfo.gpa': {
  //   numericality: {
  //     onlyInteger: false,
  //     greaterThanOrEqualTo: 0
  //   }
  // },
  'studentInfo.month': {
    presence: {
      allowEmpty: false
    }
  },
  'studentInfo.year': {
    presence: {
      allowEmpty: false
    }
  }
}

export default (data) => {
  const errs = validate(data, constraints)
  return errs
}
