import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation.js'
import queryLessonResult from '../../QueryResult/useLessonResult.js'

export default (onSuccess, onError) => {
  const [mutation, { data, error, loading}] = useMutation(
    gql`mutation (
        $educatorAttendance: AttendaceStatus!
        $attendance: [AttendanceStudentsInput!]!
        $lessonId: String!
    ) {
        sendLessonAttendance(
            educatorAttendance: $educatorAttendance
            attendance: $attendance
            lessonId: $lessonId
        ) {
            ${queryLessonResult}
        }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      //client.resetStore()
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return [submit, { loading, data: data ? data.sendLessonAttendance : null, error, validationErrors }]
}
