import React, { useEffect, useMemo, useState } from 'react'
import {
    Input
} from '@Knowledge-OTP/znk-ui-components'
import ShortcutFilter from './Shortcut'
import moment from 'moment-timezone'

const InnerFilter = (props) => {
    const { filters = [], onCancel = () => { }, onApply = () => { }, style = {} } = props
    const [dates, setDates] = useState([])
    const [start, end] = dates

    useEffect(() => {
        if (filters[0] !== start || filters[1] !== end) {
            setShortcut(null)
            setDates(filters)
        }
        //eslint-disable-next-line
    }, [filters])
    const { startValue, endValue } = useMemo(() => {
        return {
            startValue: start ? moment(start).format('YYYY-MM-DD') : null,//filters[0] ? moment(filters[0]).format('YYYY-MM-DD') : null,
            endValue: end ? moment(end).format('YYYY-MM-DD') : null//filters[1] ? moment(filters[1]).format('YYYY-MM-DD') : null
        }

    }, [start, end])
    const [shortcut, setShortcut] = useState(null)

    const applyShortcut = (key) => {
        let newStart = start
        let newEnd = end
        switch (key) {
            case 'today':
                newStart = moment().startOf('day')
                newEnd = moment().endOf('day')
                break
            case 'yesterday':
                newStart = moment().subtract(1, 'day').startOf('day')
                newEnd = moment().subtract(1, 'day').endOf('day')
                break
            case 'month':
                newStart = moment().startOf('month')
                newEnd = moment().endOf('month')
                break
            case 'last-month':
                newStart = moment().subtract(1, 'month').startOf('month')
                newEnd = moment().subtract(1, 'month').endOf('month')
                break
            case 'last-3-months':
                newStart = moment().subtract(4, 'month').startOf('month')
                newEnd = moment().subtract(1, 'month').endOf('month')
                break
            case 'week-7':
                newStart = moment().subtract(1, 'week').startOf('day')
                newEnd = moment().endOf('day')
                break
            case 'last-week':
                newStart = moment().subtract(1, 'isoWeek').startOf('isoWeek')
                newEnd = moment().subtract(1, 'isoWeek').endOf('isoWeek')
                break
            default:
        }

        setShortcut(key)
        setDates([newStart.toISOString(), newEnd.toISOString()])
        //aplica filtro y cierra dropdown
        onApply([newStart.toISOString(), newEnd.toISOString()])
    }
    return (
        <div className={`d-flex flex-column py-2 px-3`} style={{ maxWidth: '250px', ...style }}>
            <div className={`d-flex flex-row w-100 mb-1`}>
                <ShortcutFilter
                    isSelected={shortcut === 'today'}
                    onClick={() => {applyShortcut('today')}}
                    label={`Today`}
                />
                <ShortcutFilter
                    isSelected={shortcut === 'yesterday'}
                    onClick={() => {applyShortcut('yesterday')}}
                    label={`Yesterday`}
                />
            </div>
            <div className={`d-flex flex-row w-100 mb-1`}>

                <ShortcutFilter
                    isSelected={shortcut === 'week-7'}
                    onClick={() => {applyShortcut('week-7')}}
                    label={`Last 7 days`}
                />
                <ShortcutFilter
                    isSelected={shortcut === 'month'}
                    onClick={() => {applyShortcut('month')}}
                    label={`This month`}
                />
            </div>
            <div className={`d-flex flex-row w-100 mb-1`}>

                <ShortcutFilter
                    isSelected={shortcut === 'last-month'}
                    onClick={() => {applyShortcut('last-month')}}
                    label={`Last month`}
                />
                <ShortcutFilter
                    isSelected={shortcut === 'last-3-months'}
                    onClick={() => {applyShortcut('last-3-months')}}
                    label={`Last 3 months`}
                />
            </div>
            {/* <div className={`d-flex flex-row w-100`}>
            </div> */}
            <label className={`h45 text-gray mt-4 mb-1`}>From</label>
            <Input
                onChange={(e) => {
                    setShortcut(null)
                    setDates([moment(e.target.value).startOf('day').toISOString(), end])
                }}
                name={'startDate'} value={startValue} type={`date`} />
            <label className={`h45 text-gray mt-3 mb-1`}>To</label>
            <Input
                onChange={(e) => {
                    setShortcut(null)
                    setDates([start, moment(e.target.value).endOf('day').toISOString()])
                }}
                name={'endDate'} value={endValue} type={`date`} />
            <div className={`w-100 d-flex flex-row justify-content-between mt-4`}>
                <span className={`btn-link text-error`} onClick={onCancel}>Cancel</span>
                <span
                    onClick={() => {
                        onApply(dates)
                    }}
                    className={`btn-link text-primary`}>Apply</span>
            </div>
        </div>
    )
}

export default InnerFilter