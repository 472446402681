import {useMutation, gql} from '@apollo/client'
import testQueryResult from './queryResult/useTestQueryResult'

const useCreateTestMutation = (onSuccess = () => {}, onDelete = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $newTestInput: TestInput!
                ) {
                createTest (
                    newTestInput: $newTestInput
                )  {
                    ${testQueryResult}
                }
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError: () => {
                onDelete()
            }
        }
    )
}

export default useCreateTestMutation