import React, { useState, useEffect } from 'react'
import {
  Modal,
  IconClose,
  ListItem,
  IconRight,
  Field,
  PlainTextInput,
  StickyButton,
  Button,
  InputSelect,
  LabeledField
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { gql, useLazyQuery } from '@apollo/client'
import PlanStudent from '../../../Plans/modal/newPlan/Components/PlanStudent'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import './index.css'
import formatNumber from '../../../../utils/formatNumber'
import processOne from './processInvoice'
import processPayments from './processPayments'
import { categories } from '../../Payments/constants'
import { escapeRegExp } from 'lodash'
import { showAlert } from '../../../../common/Alert/util'

const ProcessInvoice = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.processInvoice)
  const { isOpen, isMultiple, invoiceId } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [query, setQuery] = useState({})
  const [form, setForm] = useState({
    student: undefined,
    startDate: moment(moment().clone().startOf('month')).format('YYYY-MM-DD'),
    endDate: moment(moment().clone().endOf('month')).format('YYYY-MM-DD'),
    category: ['livePrep']
  })
  const toggle = () => {
    setForm({
      student: undefined,
      startDate: moment(moment().clone().startOf('month')).format('YYYY-MM-DD'),
      endDate: moment(moment().clone().endOf('month')).format('YYYY-MM-DD'),
      category: ['livePrep']
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.processInvoice',
      data: {
        isOpen: false,
        isMultiple: false,
        invoiceId: ''
      }
    })
  }
  const [meInvoice, { data }] = useLazyQuery(
    gql`query($invoiceId: String!){
      invoice(invoiceId: $invoiceId) {
        id
        invoiceNumber
        userId {
          id
          firstName
          lastName
          paymentMethod {
            ... on BankCreditPaymentMethod {
              kind
              sourceInfo {
                accountNumber
                routingNumber
                bankName
                swiftCode
              }
            }
            ... on BankDebitPaymentMethod {
              kind
              validationStatus
              accountInfo {
                bankName
                last4
              }
            }
            ... on ManualPaymentMethod {
              kind
              manualPaymentInfo {
                bankName
                accountNumber
                routingNumber
                beneficiary
                address
                swiftCode
              }
            }
            ... on CardPaymentMethod {
              kind
              last4
              brand
              expMonth
              expYear
              cardInfo {
                brand
                last4
                expYear
                expMonth
              }
            }
          }
        }  
        billedUserId
        processedTimes
        createdAt
        updatedAt
        category
        from
        to
        status
        paymentItemIds
        lastProcessedAt
        amount
        taxFee
        amounts {
          subTotal
          taxAmount
          amount
        }
      }
    }`,
    {
      variables: { invoiceId: invoiceId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (invoiceId === '') return
      await meInvoice()
    })()
  }, [invoiceId, meInvoice])
  useEffect(() => {
    if (data) {
      setForm({
        student: data.invoice.userId
      })
    }
  }, [data])
  useEffect(() => {
    if (!form.startDate || !form.endDate || !form.category) return
    setQuery({
      limit: 0,
      status: ['pendingPayment'],
      sortField: 'from',
      from: moment(form.startDate).clone().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z',
      to: moment(form.endDate).clone().endOf('month').format('YYYY-MM-DD') + 'T23:59:59.999Z',
      current: true,
      category: form.category
    })
  }, [form.startDate, form.endDate, form.category])
  const [invoices, { data: dataInvoices }] = useLazyQuery(
    gql`query(
      $limit: Int,
      $sortField: String,
      $status: [PaymentStatus!],
      $userId: [String!],
      $parentUserId: [String!],
      $from: DateTime,
      $to: DateTime,
      $category: [PaymentCategory!],
      $searchString: String,
      $current: Boolean
      )
      {
        invoices(
          limit: $limit,
          sortField: $sortField,
          status: $status, 
          userId: $userId,
          parentUserId: $parentUserId,
          from: $from,
          to: $to,
          category: $category,
          searchString: $searchString,
          current: $current)
        {
          hasNext
          hasPrevious
          next
          previous
          totalDocs
          docs {
            id
            status
            category
            userId {
              id
              firstName
              lastName
            }
            billedUserId
            processedTimes
            createdAt
            updatedAt
            from
            to
            paymentItemIds
            lastProcessedAt
            amount
          }
        }
      }`,
    {
      variables: query,
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (!form.startDate || !form.endDate || !form.category) return
      await invoices()
    })()
    // eslint-disable-next-line
  }, [query, invoices])
  const { submit: submitOne, loading: loadingOne, error: errorOne } = processOne(() => {
    refetch()
    toggle()
  },
  () => {
  }
  )
  const { submit: submitMore, loading: loadingMore, error: errorMore } = processPayments(() => {
    refetch()
    toggle()
  })

  useEffect(() => {
    if (errorOne?.message) {
      showAlert({ text: errorOne.message, status: 'error' }, dispatch)
    }
    if (errorMore?.message) {
      showAlert({ text: errorMore.message, status: 'error' }, dispatch)
    }
  }, [errorOne, errorMore, dispatch])

  const onSubmit = () => {
    if (!isMultiple) {
      submitOne({ invoiceId })
    } else {
      submitMore({
        from: moment(form.startDate).clone().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z',
        to: moment(form.endDate).clone().endOf('month').format('YYYY-MM-DD') + 'T23:59:59.999Z',
        category: form.category[0]
      })
    }
  }
  const paymentName = {
    BankCreditPaymentMethod: {
      name: translate('student.modal.payments.credit')
    },
    BankDebitPaymentMethod: {
      name: translate('student.modal.payments.debit')
    },
    ManualPaymentMethod: {
      name: translate('student.modal.payments.manual')
    },
    CardPaymentMethod: {
      name: translate('student.modal.payments.card')
    },
    ZellePaymentMethod: {
      name: translate('student.modal.payments.zelle')
    }
  }
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header px-2 flex-row'>
            <div className='d-flex flex-column  '>
              <span className='flex-grow-1 text-title font-weight-bold h3'>{translate('payments.processpayment.title')}</span>
              {!isMultiple && data
                ? (<span className='text-title font-weight-bold h2'>INV-{data ? String(data.invoice.invoiceNumber).padStart(5, 0) : ''}</span>)
                : <span>{translate('payments.processpayment.subtitle')}</span>}
            </div>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <>
          {!isMultiple && (<>
            <div className='d-flex flex-column'>
              <div className='px-2'>
                <div className='borderTop mt-3' />
              </div>
              <div className=''>
                {data
                  ? <PlanStudent form={form} setForm={setForm} title={false} first={false} invoice edit={false} />
                  : <Skeleton height={60} />}
              </div>
              <div className='px-2'>
                <div className='borderTop' />
              </div>
            </div>
            <div className='px-2'>
              {data
                ? <ListItem
                  className='mb-2 border-bottom w-100'
                  Left={<span>{translate('misc.paymentMethod')}</span>}
                  Right={
                    <span>
                      {data && data.invoice.userId.paymentMethod ? paymentName[data.invoice.userId.paymentMethod?.__typename]?.name : ''}
                      <IconRight className='ml-1' />
                    </span>
                  }
                  />
                : <Skeleton height={40} />}
            </div>
            <div className='px-2 mt-2'>
              {data
                ? <div className='d-flex justify-content-between back-primary px-3 znk-invoice-row'>
                  <span>End of {moment(data.invoice.to).format('MMMM')}</span>
                  <span>${formatNumber(data.invoice.amount)}</span>
                </div>
                : <Skeleton height={60} />}
            </div>
          </>)}
          {isMultiple && (
            <>
              <div className='d-flex m-0 mt-5 px-2'>
                <div className='col-12 p-0 pr-2'>
                  <label className='text-gray font-weight-light mb-2'>Category</label>
                    <Field
                      className='col-12 p-0'
                      component={InputSelect}
                      filterOption={(obj, string) => {
                        if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                        return false
                      }}
                      type={LabeledField}
                      fieldName='category'
                      placeholder={translate('modal.newplan.selectservice')}
                      options={categories && [].concat(categories.map((serv) => ({ value: serv.id, label: translate(`payments.categories.${serv.id}`) })))}
                      InputClassName='w-100'
                      onChange={(value) => {setForm({ ...form, category: [value]})}}
                      value={form.category[0]}
                    />
                </div>
              </div>
              <div className='d-flex m-0 px-2'>
                <div className='col-6 p-0 pr-2'>
                  <label className='text-gray font-weight-light mb-2'>From</label>
                  <Field
                    type={PlainTextInput}
                    fieldName='startDate'
                    fieldType='date'
                    value={form.startDate}
                    onChange={(value) => { setForm({ ...form, startDate: value }) }}
                  />
                </div>
                <div className='col-6 pl-1 pr-0 pl-2'>
                  <label className='text-gray font-weight-light mb-2'>To</label>
                  <Field
                    type={PlainTextInput}
                    fieldName='endDate'
                    fieldType='date'
                    value={form.endDate}
                    onChange={(value) => { setForm({ ...form, endDate: value }) }}
                  />
                </div>
              </div>
            </>
          )}
          <div className={!isMultiple ? 'h-auto' : 'h-fixed'}>
            {isMultiple && dataInvoices && (
              <div className='m-0 mt-5 px-2'>
                <div className='d-flex justify-content-between'>
                  <span>{dataInvoices.invoices.totalDocs} {dataInvoices.invoices.totalDocs > 1 ? 'invoices' : 'invoice'}</span>
                  <span>${formatNumber(dataInvoices.invoices.docs.reduce((sum, current) => sum + current.amount, 0))}</span>
                </div>
                {
                  dataInvoices.invoices.docs.map((element, index) => {
                    return (
                      <div key={element.id} className='d-flex justify-content-between back-primary px-3 znk-invoice-row'>
                        <span>End of {moment(element.to).format('MMMM')}</span>
                        <span>{element.userId.firstName} {element.userId.lastName}</span>
                        <span>${formatNumber(element.amount)}</span>
                      </div>
                    )
                  })
                }
              </div>
            )}
          </div>
          <div className={!isMultiple ? 'px-2 mt-6' : 'px-2 mt-4'}>
            <StickyButton>
              <div className='m-0 p-0 row'>
                <Button
                  color='primary'
                  activity={loadingOne | loadingMore}
                  className='col-9'
                  label={!isMultiple ? 'Process Invoice' : dataInvoices ? 'Process ' + dataInvoices.invoices.totalDocs + ' Invoices' : 'Process 0 Invoices'}
                  onClick={onSubmit}
                  style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                  check
                />
              </div>
            </StickyButton>
          </div>
        </>
      </Modal>
    </>
  )
}

export default ProcessInvoice
