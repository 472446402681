import React from 'react'

const useDesktopColumns = () => {
    return [
        { 
            render: item => {
                return (
                    <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                        <img src={item?.logo} alt={``} style={{width: '40px', height: '40px'}} />
                        <span className={`ml-2`}>{item?.name}</span>
                    </div>
                )
            }, 
            colSpan: 4, 
            id: 'avatar' 
        },
        { 
            render: item => {
            return (
                <span>{`${item?.ranking}°`}</span>
            )
            }, 
            colSpan: 4,
            className: `text-gray d-flex flex-row justify-content-center`, 
            id: 'avatar' 
        },
        { 
            render: item => {
            return (
                <span>{`${item?.users?.length} students`}</span>
            )
            }, 
            className: `text-gray d-flex flex-row justify-content-center`, 
            colSpan: 4, 
            id: 'avatar' 
        },
      ]
}

export default useDesktopColumns