import React, { Suspense, lazy, useEffect, useState, useMemo } from 'react'
import {
    Table,
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import useUniversitiesGroupByStudents from '../../../../Model/useUniversitiesGroupByStudents'
import { TabContent, TabPane } from 'reactstrap'
import useDesktopColumns from './Columns/useDesktopColumns'
import Pagination from '../../../../../../common/Pagination'

const ChildRow = lazy(() => import('./ChildRow'))
const ROWSLIMIT = 10

const StudentsSection = (props) => {
    const {
        search: searchProp = null,
        filters = {},
    } = props
    const columns = useDesktopColumns()

    const [query, setQuery] = useState({
        previous: null,
        next: null,
        limit: ROWSLIMIT,
        search: null,
        page: 0
    })
    const { data, loading, refetch } = useUniversitiesGroupByStudents(
        query, {fetchPolicy: 'no-cache'}
    )

    const {rows, paginatedData} = useMemo(() => {
        if(data?.universitiesGroupByStudents) {
            const {docs, ...paginatedData} = data?.universitiesGroupByStudents
            // console.log("*** docs", docs)
            return {rows: docs, paginatedData}
        }
        return {rows: [], paginatedData: {}}
    }, [data])
    const {
        hasNext,
        hasPrevious,
        next,
        previous,
        totalDocs
    } = paginatedData
    //se ejecuta al aplicar algún filtro (filters)
    useEffect(() => {
        setQuery({ ...query, page: 0, next: null, previous: null, ...filters })
    },
        // eslint-disable-next-line
        [filters])
    const refetchData = () => {
        refetch()
    }
    useEffect(() => {
        setQuery({ ...query, search: searchProp, next: null, previous: null, page: 0 })
        // eslint-disable-next-line
    }, [searchProp])



    return (
        <div className={`h-100 d-flex flex-column justify-content-between`}>
            <div>
                <TabContent activeTab={loading ? 'loading' : 'show'}>
                    <TabPane tabId={`show`}>
                        <Table
                            className={`cursor-pointer`}
                            loading={false}
                            columnList={columns}
                            tableData={rows}
                            collapseRender={(props) => {
                                return (
                                    <Suspense fallback={<Skeleton />}>
                                        <ChildRow refetch={() => { refetchData() }} items={props} />
                                    </Suspense>
                                )
                            }}
                            onClick={(value) => { }}
                        />
                    </TabPane>
                    <TabPane tabId={`loading`}>
                        <Skeleton count={query?.limit || ROWSLIMIT} height={40} />
                    </TabPane>
                </TabContent>
            </div>
            <Pagination
                bottomFix
                pageSize={query.limit}
                size='large' //large, small
                changeSize={(newLimit) => { setQuery({ ...query, limit: newLimit || query?.limit || ROWSLIMIT }) }}
                clickNext={() => {
                    if (hasNext) {
                        setQuery({ 
                            ...query, 
                            previous: null,
                            next: next,
                            page: query.page + 1 
                        })
                    }
                }}
                clickPrevious={() => {
                    if (hasPrevious) {
                        setQuery({ 
                            ...query, 
                            previous: previous,
                            next: null,
                            page: query.page - 1 
                        })
                    }
                }}
                hasPrevious={hasPrevious && !loading}
                hasNext={hasNext && !loading}
                activePage={(query?.page || 0) + 1}
                pageCount={Math.ceil(totalDocs/(query?.limit || 1))}
                loading={loading}
            />
        </div>
    )
}


export default StudentsSection