import React from 'react'
import {
    ModalLayout,
} from '@Knowledge-OTP/znk-ui-components'
import NextTimelinePlanList from '../NextTimelinePlanList'
import useTimelinePlanModal from './useTimelinePlanModal'

const TimelinePlanModal = () => {
    const {isOpen, toggle, onChange} = useTimelinePlanModal()
    return (
        <ModalLayout
            className={`modal-colored modal-sidebar modal-desktop-half white-modal`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h3 text-title`}>Plans to Send</span>
            }
            disabledScrollAnimation
        >
            <NextTimelinePlanList showLabel limit={0} onChange={onChange} />
        </ModalLayout>
    )
}
export default TimelinePlanModal