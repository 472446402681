import React from 'react'
import { ConfirmModal } from '@Knowledge-OTP/znk-ui-components'
import useCancelPlanModal from './useCancelPlanModal'
//mutation for cancel plan
import useDeleteCouncelingPlanFromAdminMutation from '../../Model/useDeleteCouncelingPlanFromAdmin'


const CancelPlanModal = () => {
    const { planData, toggle, isOpen, onChange, close, loading, useMutation } = useCancelPlanModal()
    
    const callBack = () => {
        onChange({ ...planData, status: 'cancel' })
    }
    const [cancelPlan, {loading: loadCancel}] = useDeleteCouncelingPlanFromAdminMutation(() => {
        callBack()
    })
    return (
        <ConfirmModal
            classNameActionDiv={`d-flex flex-column`}
            classNameConfirm={'text-error white-space-nowrap'}
            isOpen={isOpen}
            toggle={toggle}
            title={'Do you want to cancel this plan?'}
            body={'This action can not be undone'}
            confirmAction={() => {
                if(useMutation){
                    cancelPlan({
                        variables: {
                            planId: planData?.id
                        }
                    })
                }
                else callBack()
            }}
            loading={loadCancel || loading}
            cancelAction={() => { close() }}
            confirmText={'Cancel plan'}
            cancelText={'Cancel'}
        />
    )
}

export default CancelPlanModal