import { gql, useQuery } from '@apollo/client'
import { Field, SelectCheck } from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

const StaffPermissions = (props) => {
    // eslint-disable-next-line
    const { permissionsToSubmit, setPermissionsToSubmit } = props

    const [selectPermissions, setSelectPermissions] = useState([])
    const { data: dataPermissions, loading: loadingPermissions } = useQuery(
        gql`query($role: Roles!) {
      getPermissions(role: $role) {
          id
          name
          tag
      }
    }
    `,
        {
            variables: { role: 'SUPER_ADMIN' },
            fetchPolicy: 'network-only'
        }
    )

    useEffect(() => {
        setPermissionsToSubmit(dataPermissions?.getPermissions?.map(e => { return { name: e.name, tag: e.tag, isPermited: selectPermissions.includes(e.tag) } }) ?? [])
        // eslint-disable-next-line 
    }, [selectPermissions])

    return (
        <div className='mt-1'>
            {
                loadingPermissions
                    ?
                    <Skeleton count={10} height={43} style={{ borderRadius: '10px' }} />
                    :
                    <Field
                        className='col-12'
                        type={SelectCheck}
                        fieldName='typePlan'
                        options={dataPermissions?.getPermissions?.map(e => ({ id: e.tag, text: e.name }))}
                        StyleOptionClassName={{
                            width: '100%',
                            whiteSpace: 'nowrap'
                        }}
                        StyleSelect={{
                            justifyContent: 'space-between'
                        }}
                        value={selectPermissions}
                        onChange={
                            (value) => {
                                setSelectPermissions(value)
                            }
                        }
                    />
            }

        </div>
    )
}

export default StaffPermissions