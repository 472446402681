import React, { useState, Suspense, lazy, useEffect, useMemo } from 'react'
import {
    Table,
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useStudentsGroupByClass from '../../../../Model/useStudentsGroupByClass'
import useColumnsDesktop from './Columns'
import Pagination from '../../../../../../common/Pagination'

const ChildRow = lazy(() => import('./ChildRow'))
const ROWSLIMIT = 10

const ClassOfSection = (props) => {
    const {
        search: searchProp = null,
        filters = {},
    } = props
    const columns = useColumnsDesktop()

    const [query, setQuery] = useState({
        search: null,
        limit: ROWSLIMIT,
        page: 0
    })
    const { page } = query


    const { loading, data, refetch } = useStudentsGroupByClass(
        { ...query, calculateTotal: page === 0 }
    )
    const { rows } = useMemo(() => {
        if (data?.studentsGroupByClass) {
            const { docs } = data?.studentsGroupByClass
            return { rows: docs }
        }
        return { rows: [] }
    }, [data])


    //cantidad total de documentos
    const [total, setTotal] = useState(-1)
    useEffect(() => {
        const totalAux = data?.studentsGroupByClass?.total
        if (totalAux !== -1 && totalAux) {
            setTotal(totalAux)
        }
    }, [data])



    useEffect(() => {
        setQuery({ ...query, page: 0, search: searchProp, ...filters })
        // eslint-disable-next-line
    }, [searchProp, filters])

    const refetchData = () => {
        refetch()
    }

    const totalPages = useMemo(
        () => {
            return Math.ceil(total / (query.limit || ROWSLIMIT))
            //eslint-disable-next-line
        }, [total, query?.limit]
    )
    return (
        <div className={`h-100 d-flex flex-column justify-content-between`}>
            <div>
                <TabContent activeTab={loading ? 'loading' : 'show'}>
                    <TabPane tabId={`show`}>
                        <Table
                            className={`cursor-pointer`}
                            loading={false}
                            columnList={columns}
                            tableData={rows}
                            collapseRender={(props) => {
                                return (
                                    <Suspense fallback={<Skeleton />}>
                                        <ChildRow refetch={() => { refetchData() }} items={props} />
                                    </Suspense>
                                )
                            }}
                            onClick={(value) => { }}
                        />
                    </TabPane>
                    <TabPane tabId={`loading`}>
                        <Skeleton count={query?.limit || ROWSLIMIT} height={40} />
                    </TabPane>
                </TabContent>
            </div>

            <Pagination
                bottomFix={true}
                pageSize={query.limit}
                size='large' //large, small
                changeSize={(newLimit) => { setQuery({ ...query, limit: newLimit || query?.limit || ROWSLIMIT }) }}
                clickNext={() => {
                    if (query.page + 1 < totalPages) {
                        setQuery({ ...query, page: query.page + 1 })
                    }
                }}
                clickPrevious={() => {
                    if (query.page > 0) {
                        setQuery({ ...query, page: query.page - 1 })
                    }
                }}
                hasPrevious={query.page > 0}
                hasNext={query.page + 1 < totalPages}
                activePage={(query?.page || 0) + 1}
                pageCount={totalPages}
                loading={loading}
            />
        </div>
    )
}

export default ClassOfSection