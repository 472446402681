import {gql, useQuery} from '@apollo/client'
import planQueryResult from '../../Timelines/Model/queryResult/planQueryResult'

const useGetFirtsPlanOnTimeline = (vars = {}) => {
    return useQuery(
        gql`
        query (
            $limit: Int
        ) {
            getFirtsPlanOnTimeline (
                limit: $limit
            ) {
                timelineId {
                    _id
                }
                ${planQueryResult}
            }
        }
        `,
        {
            variables: vars
        }
    )
}

export default useGetFirtsPlanOnTimeline