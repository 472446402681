import React, { useEffect, useState } from 'react'
import {
  StickyButton,
  ListItem,
  Modal,
  IconClose,
  IconOptions,
  Input,
  IconSignPlus,
  DropDown,
  Avatar,
  IconPassword
} from '@Knowledge-OTP/znk-ui-components'
import { useSelector, useDispatch } from 'react-redux'
import { FormGroup } from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'
import EditRate from '../NewLesson/EditRate'
import EditLessonPayment from '../../../../Model/Lessons/EditLessonPayment'
import moment from 'moment-timezone'

const EditPayment = (props) => {
  const { refetchPendingLessons } = props
  const { isOpen, dataLesson } = useSelector((state) => state.modal.plan.editPaymentLesson)
  const isPlanLesson = dataLesson?.__typename === 'PlanLesson'
  const dispatch = useDispatch()
  const { translate } = useTranslation()

  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.editPaymentLesson',
      data: {
        isOpen: !isOpen,
        dataLesson
      }
    })
  }

  const { submit: editPaymentSubmit, loading: loadEdit } = EditLessonPayment(
    (res) => {
      // cierra el modal y actualiza la información de la lección en redux
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.infoLessonModal',
        data: {
          isOpen: true,
          dataLesson: res?.data?.editLessonPayment || dataLesson
        }
      })
      // refect a query de lecciones pendientes
      refetchPendingLessons()
      toggle()
    },
    (e) => {
      console.error(e)
      alert('error')
    })

  const student = dataLesson ? (dataLesson.student || dataLesson.studentPlan || dataLesson.studentGroup) : null
  const educator = dataLesson?.substitute || dataLesson?.educator || {}
  const [form, setForm] = useState({
    studentRate:
            Array.isArray(student)
              ? student.map((stu) => {
                return ({
                  id: stu?.studentUserId || '',
                  rate: stu?.rate || 0,
                  check: stu?.ignorePaymentItem || false,
                  name: `${stu?.firstName} ${stu?.lastName}`
                })
              })
              : [
                {
                  id: student?.studentUserId || '',
                  rate: student?.rate || 0,
                  check: student?.ignorePaymentItem || false,
                  name: `${student?.firstName} ${student?.lastName}`
                }
              ],
    educatorRate: educator?.rate || 0,
    educatorRateCheck: educator?.ignorePaymentItem || false,
    paymentFor: moment(dataLesson?.lessonConfig?.paymentFor).format('YYYY-MM-DD')
  })

  const [defaultRate, setDefaultRate] = useState(0)
  const [defaultCheck, setDefaultCheck] = useState(false)
  const [titleEditRate, setTitleEditRate] = useState('')

  useEffect(() => {
    setForm({
      ...form,
      studentRate:
                Array.isArray(student)
                  ? student.map((stu) => {
                    return ({
                      id: stu?.studentUserId || '',
                      rate: stu?.rate || 0,
                      check: stu?.ignorePaymentItem || false,
                      name: `${stu?.firstName} ${stu?.lastName}`
                    })
                  })
                  : [
                    {
                      id: student?.studentUserId || '',
                      rate: student?.rate || 0,
                      check: student?.ignorePaymentItem || false,
                      name: `${student?.firstName} ${student?.lastName}`
                    }
                  ],
      educatorRate: educator?.rate || 0,
      educatorRateCheck: educator?.ignorePaymentItem || false,
      paymentFor: moment(dataLesson?.lessonConfig?.paymentFor).format('YYYY-MM-DD')
    })
    // eslint-disable-next-line
    }, [dataLesson, student, isOpen])

  // tarifa de estudiante solo aplica para lecciones individuales
  // tarifa del educador
  // remove payment solo para las lecciones individuales

  const atLessOneIsNotCheck =
        form?.studentRate.reduce(
          (boolAccu, stu) => {
            return boolAccu || !stu.check
          }, false) || !form.educatorRateCheck
  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header p-6 d-flex flex-row justify-content-between'>
          <span className='h2 font-weight-bold'>{translate('modal.editpayment.title')}</span>
          <IconClose onClick={toggle} />
        </div>
      }
    >
      <div className='m-0 px-6'>
        <FormGroup className='w-100 d-flex flex-column'>
          {
            form.studentRate.map((stu, index) => {
              return (
                <>
                  {(index === 0 && form.studentRate.length > 1) && (<span className='text-title'>{translate('modal.newlesson.studentrate')}</span>)}
                  <ListItem
                    className='w-100'
                    Left={
                      form.studentRate.length === 1
                        ? <span className='text-title'>{translate('modal.newlesson.studentrate')}</span>
                        : <div className='d-flex flex-row align-items-center'>
                          <Avatar
                            name={`${stu.name}`}
                          />
                          <span className='ml-4 text-title'>{`${stu.name}`}</span>
                        </div>
                    }
                    Right={
                      <div className='d-flex flex-row m-0 p-0 align-items-center'>
                        <span
                          className={`mr-2 ${stu.check ? 'text-gray' : 'text-title'}`}
                          style={stu.check ? { textDecoration: 'line-through' } : {}}
                        >{`$${(stu.rate || 0).toFixed(2)}`}
                        </span>
                        {
                          isPlanLesson
                            ? <IconPassword />
                            : (
                              <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                                <DropDown
                                  direction='down'
                                  fixedWidth
                                  hoverEnabled={false}
                                  className='d-flex flex-column align-items-center'
                                  options={[
                                    {
                                      component:
                                <span
                                  onClick={() => {
                                    setDefaultRate(form.studentRate[index].rate)
                                    setDefaultCheck(form.studentRate[index].check)
                                    setTitleEditRate(translate('modal.newlesson.editsturate'))
                                    dispatch({
                                      type: 'SET_DATA_MODAL_FORM_LIST',
                                      payload: 'plan.editRateLesson',
                                      data: {
                                        isOpen: true,
                                        onClickFunction: (newVal, check) => {
                                          const arrayOfRates = form.studentRate
                                          arrayOfRates[index] = {
                                            ...arrayOfRates[index],
                                            rate: newVal,
                                            check: check
                                          }

                                          setForm({
                                            ...form,
                                            studentRate: arrayOfRates
                                          })
                                        }
                                      }
                                    })
                                  }}
                                >{translate('modal.newlesson.adjustrate')}
                                </span>
                                    },
                                    {
                                      component:
                                <span onClick={() => {
                                  const arrayOfChecks = form.studentRate
                                  arrayOfChecks[index] = {
                                    ...arrayOfChecks[index],
                                    check: !arrayOfChecks[index].check
                                  }
                                  setForm({
                                    ...form,
                                    studentRate: arrayOfChecks
                                  })
                                }}
                                >{translate('modal.newlesson.waiverate')}
                                </span>
                                    }
                                  ]}
                                >
                                  <IconOptions className='ml-1' style={{ transform: 'rotate(90deg)' }} />
                                </DropDown>
                              </div>
                            )
                        }
                      </div>
                    }
                  />
                </>
              )
            })
          }
          <ListItem
            className='w-100'
            Left={
              <span className='text-title'>{translate('modal.newlesson.educatorwage')}</span>
            }
            Right={
              <div className='d-flex flex-row m-0 p-0 align-items-center'>
                <span
                  className={`mr-2 ${form.educatorRateCheck ? 'text-gray' : 'text-title'}`}
                  style={form.educatorRateCheck ? { textDecoration: 'line-through' } : {}}
                >{`$${(form.educatorRate).toFixed(2)}`}
                </span>
                <div className='znk-table-dropdown dropdown-menu-width-content p-0 m-0'>
                  <DropDown
                    direction='down'
                    fixedWidth
                    hoverEnabled={false}
                    className='d-flex flex-column align-items-center'
                    options={[
                      {
                        component:
  <span
    onClick={() => {
      setDefaultRate(form.educatorRate)
      setDefaultCheck(form.educatorRateCheck)
      setTitleEditRate(translate('modal.newlesson.editedurate'))
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.editRateLesson',
        data: {
          isOpen: true,
          onClickFunction: (newVal, check) => {
            setForm({
              ...form,
              educatorRate: newVal,
              educatorRateCheck: check
            })
          }
        }
      })
    }}
  >{translate('modal.newlesson.adjustrate')}
  </span>
                      },
                      {
                        component:
  <span onClick={() => {
    setForm({
      ...form,
      educatorRateCheck: !(form?.educatorRateCheck)
    })
  }}
  >{translate('modal.newlesson.waiverate')}
  </span>
                      }
                    ]}
                  >
                    <IconOptions className='ml-1' style={{ transform: 'rotate(90deg)' }} />
                  </DropDown>
                </div>
              </div>
            }
          />
          <div className='mx-0 my-2 p-0 d-flex flex-column'>
            {
              !isPlanLesson && (
                <>
                  <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                    {translate('modal.newlesson.paymentfor')}
                  </label>
                  <Input
                    className='w-100'
                    type='date'
                    value={form?.paymentFor}
                  />
                </>
              )
            }
            {
              (atLessOneIsNotCheck && !isPlanLesson) && (
                <div className='mt-4 mx-0 d-flex flex-row align-items-center'>
                  <IconSignPlus size={15} color='red' />
                  <span
                    className='text-error btn-link ml-2'
                    onClick={() => {
                      // renunciar a todas las tarifas
                      setForm({
                        ...form,
                        studentRate: form.studentRate.map((stu) => { return { ...stu, check: true } }),
                        educatorRateCheck: true
                      })
                    }}
                  >{translate('modal.editpayment.remove')}
                  </span>
                </div>
              )
            }
          </div>
        </FormGroup>
        <StickyButton
          disabled={false}
          onClickButtonFunction={() => {
            editPaymentSubmit({
              lessonPaymentEditInput: {
                studentsConfig: form.studentRate.map((stu) => {
                  return {
                    waiveStudentPayment: stu.check,
                    studentRate: stu.rate,
                    studentUserId: stu.id
                  }
                }),
                waiveEducatorPayment: form?.educatorRateCheck || false,
                educatorRate: form?.educatorRate
              },
              lessonId: dataLesson?.id
            })
          }}
          activity={loadEdit}
          color='#696CFF'
          className='w-100 mb-6'
          size='medium'
          label={translate('modal.newlesson.savechanges')}
        />
      </div>
      <EditRate defaultRate={defaultRate} defaultCheck={defaultCheck} title={titleEditRate} />
    </Modal>
  )
}

export default EditPayment
