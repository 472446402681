import {gql, useLazyQuery} from '@apollo/client'

const useListPaymentMethodsFromCustomer = (onCompleted = () => {}, onError = () => {}) => {
    return useLazyQuery(
        gql`
        query (
            $stripeId: String!
            $page: Float
            ) {
            listPaymentMethodsFromCustomer (
                stripeId: $stripeId
                page: $page
            ) {
                id
                kind
                ... on CardPaymentMethod {
                    last4
                    brand
                    expMonth
                    expYear
                    paymentMethodId
                }
                ... on BankDebitPaymentMethod {
                    id
                    customerId
                    sourceId
                    accountInfo {
                        bankName
                    }
                }
                ... on BankCreditPaymentMethod {
                    sourceId
                    sourceInfo {
                        accountNumber
                    }
                }
            }
        }
        `,
        {
            onCompleted, onError
        }
    )
}

export default useListPaymentMethodsFromCustomer