import { useSelector, useDispatch } from 'react-redux'
import escapeRegExp from 'lodash/escapeRegExp'

const useAddCounselorModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.addCounselorModal)
    const {isOpen, onChange} = reduxData
    const setData = (open, onChange = () => {}, multi = false, defaultSelectedUsers = []) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.addCounselorModal',
            data: {
                ...reduxData,
                onChange,
                multi,
                isOpen: open,
                defaultSelectedUsers
            }
        })
    }
    return {
        ...reduxData,
        isOpen,
        onChange,
        open: (onChange = () => {}, multi = false, defaultSelectedUsers = []) => { 
            setData(true, onChange, multi, defaultSelectedUsers) 
        },
        close: () => { setData(false) },
        toggle: () => { setData(!isOpen) },
        filterSearch: (search, text) => {
            return new RegExp(escapeRegExp(search), 'i').exec(`${text}`)
        }
    }
}

export default useAddCounselorModal