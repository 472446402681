import {useDispatch, useSelector} from 'react-redux'

const usePopupDisableTest = (props) => {
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.activities.disableTest)
  const {isOpen, testId, onChange} = reduxData
  const setOpen = (open, testId, onChange = () => {}) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'activities.disableTest',
      data: {
        ...reduxData,
        isOpen: open,
        testId,
        onChange,
      },
    })
  }
  return {
    ...reduxData,
    isOpen,
    testId,
    onChange,
    open: (testId, onChange = () => {}) => {
      setOpen(true, testId, onChange)
    },
    close: () => {
      setOpen(false)
    },
    toggle: () => {
      setOpen(!isOpen)
    },
  }
}

export default usePopupDisableTest
