import React from 'react'
import {
    Action
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { CheckInEventItem } from '../../../CheckIns'
import useGetCheckInsForPlan from '../../../CheckIns/Model/useGetCheckInsForPlan'
import useTranslation from '../../../../../../../i18n/useTranslation'

const CheckInSection = (props) => {
    const { plan = {} } = props
    const { data, loading, refetch } = useGetCheckInsForPlan({ planId: plan.id })
    const {translate} = useTranslation()
    return (
        <div className='mb-4 d-flex flex-column'>
            <TabContent activeTab={loading ? 'loading' : 'show'} >
                <TabPane tabId={'loading'}>
                    <Skeleton className={'w-100'} height={38} style={{ borderRadius: '8px' }} count={3} />
                </TabPane>
                <TabPane tabId={'show'}>
                    {
                        (data?.getCheckInsForPlan || []).map(
                            (checkin) => {
                                return (
                                    <CheckInEventItem
                                        checkin={checkin} studentUserId={plan?.studentUser?.studentUserId}
                                        refetch={refetch} planStatus={plan?.status}
                                    />
                                )
                            }
                        )

                    }
                    {(data?.getCheckInsForPlan.length === 0) && 
                        <Action
                        color='transparent'
                        textColor='#7079FF'
                        border='dashed 1px #7079FF'
                        className='my-3 py-1 w-100'
                        labelLeft={
                          <span className='w-100 d-flex flex-column my-3'>
                            <label className='m-0'>
                              {translate('program.plan.checkIns.noCheckins')}
                            </label>
                          </span>
                        }
                        labelRight={null}
                        action={null}
                      />}
                </TabPane>
            </TabContent>
        </div>
    )
}

export default CheckInSection