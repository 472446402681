import React, {useMemo} from 'react'
import {
    ConfirmModal
} from '@Knowledge-OTP/znk-ui-components'
import useSetStatusModalConfirm from './useSetStatusModalConfirm'
import useSetPlanStatus from '../../Model/useSetPlanStatus'
import {useDispatch} from 'react-redux'
import {showAlert} from '../../../../common/Alert/util'

const labels = {
    'scheduled': {
        title: 'Are you sure?', 
        body: 'You are about to change the plan status from ',
        name: 'Pre Application'
    },
    'application': {
        title: 'Are you sure?', 
        body: 'You are about to change the plan status from ',
        name: 'Application'
    },
    'postApplication': {
        title: 'Are you sure?', 
        body: 'You are about to change the plan status from ',
        name: 'Post Application'
    },
    'completed': {
        title: 'Are you sure?', 
        body: 'You are about to change the plan status from ',
        name: 'Completed'
    },
}


const SetStatusModalConfirm = () => {
    const {isOpen, toggle, onChange, close, useMutation, newStatus, dataPlan} = useSetStatusModalConfirm()
    const dispatch = useDispatch()
    const [submit, {loading}] = useSetPlanStatus((resp) => {
        if(onChange) onChange(resp.updateStatusPlan)
    }, (err) => {
        showAlert({
            text: err.toString(),
            status: 'error'
        }, dispatch)
    })
    const {title, body} = useMemo(() => {
        return labels[newStatus] || {title: null, body: null}
    }, [newStatus])
    return (
        <ConfirmModal
            className={``}
            classNameActionDiv={``}
            classNameConfirm={``}
            classNameCancel={``}
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            body={<span>{body}<b>{labels[dataPlan?.status]?.name}</b> to <b>{labels[newStatus]?.name}</b>.</span>}
            confirmAction={() => {
                if(useMutation) {
                    submit({variables: {planId: dataPlan?.id, status: newStatus}})
                } else {
                    if(onChange) onChange({...dataPlan, status: newStatus})
                }
            }}
            cancelAction={() => {
                close()
            }}
            confirmText={`Confirm`}
            cancelText={`Cancel`}
            disabledCancel={false}
            loading={loading}
            isReset={false}
        />
    )
}

export default SetStatusModalConfirm