import React from 'react'
import queryLessonResult from '../../../../Model/QueryResult/useLessonResult'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, gql } from '@apollo/client'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import {
    ConfirmModal,
    Activity
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import { showAlert } from '../../../../common/Alert/util'

const ConfirmSetBraincertToLesson = () => {
    const {translate} = useTranslation()
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.plan.setBraincertToLesson)
    const {isOpen, lessonId, isOrientation} = reduxData

    const toggle = () => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'plan.setBraincertToLesson',
            data: {
              ...reduxData,
              isOpen: !isOpen
            }
        })
    }
    const [setBraincert, {loading}] = useMutation(
        gql`
            mutation update($lessonId: String!) {
                setBraincertToLesson(lessonId: $lessonId) {
                    ${queryLessonResult}
                }
            }
        `
    )
    return(
        <ConfirmModal
            className=''
            classNameActionDiv='flex-column'
            classNameConfirm='text-right text-error'
            classNameCancel='mx-3 text-right'
            isOpen={isOpen}
            toggle={toggle}
            title={translate('modal.setbraincert.title')}
            body={translate("modal.setbraincert.body")}
            confirmAction={() => {
                setBraincert({
                    variables: {lessonId}
                }).then(({data: {setBraincertToLesson}}) => {
                    isOrientation ? 
                    dispatch({
                        type: SET_DATA_MODAL_FORM_LIST,
                        payload: 'plan.infoLessonModal',
                        data: {
                          isOpen: false,
                          dataLesson: setBraincertToLesson
                        }
                    })
                    : dispatch({
                        type: SET_DATA_MODAL_FORM_LIST,
                        payload: 'plan.infoLessonModal',
                        data: {
                          isOpen: true,
                          dataLesson: setBraincertToLesson
                        }
                    })
                    toggle()
                }, (err) => {
                    showAlert({
                        text: err,
                        status: "error"
                    }, dispatch)
                })
            }}
            cancelAction={() => {}}
            confirmText={loading ? <Activity /> : translate("modal.setbraincert.confirm")}
            cancelText={null}
        />
    )
}

export default ConfirmSetBraincertToLesson