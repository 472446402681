import React, { useState } from 'react'
import { Activity, IconSignPlus, LessonItem, ListItem } from '@Knowledge-OTP/znk-ui-components'
import { ListGroupItem, Collapse } from 'reactstrap'
import moment from 'moment-timezone'


const ShowWeek = (props) => {
    const { week, timezone, userId, submit } = props
    console.log({ week })
    const [loadId, setLoadId] = useState()
    const [collapse, setCollapse] = useState(false)
    const toggle = () => {
        setCollapse(!collapse)
    }
    return (
        <ListGroupItem style={{ border: 'none', padding: 0 }}>
            <ListItem
                key={`wek-${moment(week.startDate).valueOf()}`}
                id={`wek-${moment(week.startDate).valueOf()}`}
                showTopLine={false}
                showBottomLine
                className='py-4'
                Left={
                    <div
                        className='d-flex flex-row align-items-center'
                        onClick={toggle}
                    >
                        {timezone && <span className='text-gray'>{`${moment(week.startDate).tz(timezone).format('MMM, D, YYYY')}  to  ${moment(week.endDate).tz(timezone).format('MMM, D, YYYY')}`}</span>}
                    </div>
                }
                Right={
                    <div className={`hover-icon`} onClick={() => {
                        setLoadId(`wek-${moment(week.startDate).valueOf()}`)
                        submit({ userId, startDate: new Date(week.startDate), endDate: new Date(week.endDate) })
                    }}>
                        {
                            loadId === `wek-${moment(week.startDate).valueOf()}`
                                ?
                                <Activity />
                                :
                                <IconSignPlus size={16} />
                        }
                    </div>
                }
            />
            <Collapse isOpen={collapse} className='p-1'>
                {
                    week?.lessons?.map(
                        lesson => (
                            <LessonItem
                                dataLesson={lesson.lessons}
                            />
                        )
                    )
                }
            </Collapse>
        </ListGroupItem>
    )
}

export default ShowWeek