import {useEffect, useMemo} from 'react'
import {useLazyQuery, gql} from '@apollo/client'
import queryLessonResult from '../../../../../../Model/QueryResult/useLessonResult'

const usePaymentsAndLessonData = (lessonId) => {
    const [getPayments, {data, refetch}] = useLazyQuery(
        gql`
        query (
            $lessonId: String!
        ) {
            getPaymentItemFromLesson (
              lessonId: $lessonId
            ) {
                id
                kind
                chargeType
                type
                amount
                date
                userId
                status
                description
            }
            getLesson (
              lessonId: $lessonId
            ) {
                ${queryLessonResult}
            }
        }
        `
    )
    useEffect(() => {
        if(lessonId) {
            getPayments({variables: {lessonId}})
        }
    }, [lessonId, getPayments])
    const paymentsData = useMemo(() => {
        if(data?.getLesson && data?.getPaymentItemFromLesson) {
            const {getLesson: lesson, getPaymentItemFromLesson: payments} = data
            let students = lesson.student || lesson.studentPlan || lesson.studentGroup
            if(!Array.isArray(students)) students = [students]
            
            let educator = lesson.educator
            let substitute = lesson.substitute

            let rObj = {
                students: students.map((stu) => {
                    return {
                        ...stu,
                        payments: payments.filter(({userId}) => userId === stu.studentUserId)
                    }
                })
            }
            if(educator) rObj.educator = {
                ...educator,
                payments: payments.filter(({userId}) => userId === educator.educatorUserId)
            }
            if(substitute) rObj.substitute = {
                ...substitute,
                payments: payments.filter(({userId}) => userId === substitute.educatorUserId)
            } 
            return rObj
        }
        return {}
    }, [data])
    return {...paymentsData, refetch}
}

export default usePaymentsAndLessonData