import React, { useMemo } from 'react'
import {
    TemplateTimeline
} from '@Knowledge-OTP/znk-ui-components'
import useViewTaskModal from '../../../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import useNewTaskModal from '../../../../../CounselingTemplates/Task/newTaskModal/useNewTaskModal'
import useNewStepModal from '../../../../../CounselingTemplates/Step/newStepModal/useNewStepModal'
import useViewPlanModal from '../../useViewPlanModal'
import useTemplateReducer from '../../../../../../reducers/template'

const Timeline = (props) => {
    const {
        plan,
        onChangeTask,
        onCreateTask,
        addStepOnTimeline,
        editStepOnTimeline,
        removeStepOnTimeline,
        isEdit = true
    } = props
    const { openForCreatedTask } = useViewTaskModal()
    const { open: openCreateTask, close: closeCreateTask } = useNewTaskModal()
    const { open: openNewStepModal } = useNewStepModal()
    const {dataPlan} = useViewPlanModal()
    const { filterDelete } = useTemplateReducer()
    const phases = useMemo(() => {
        return plan?.tasks ? filterDelete({ phases: plan?.tasks }).phases : []
    }, [plan, filterDelete])

    return (
        <div className={`my-5`}>
            <TemplateTimeline
                className='w-100 p-0'
                isEdit={isEdit}
                stages={phases}
                disabledDragAndDrop
                onClickTask={(task, { phaseId, stepId }) => {
                    //abre modal para ver tarea 
                    openForCreatedTask({ 
                        ...task, 
                        planId: plan?.id || plan?.planId, 
                        userId: dataPlan?.studentUser?.studentUserId,
                        phaseId, stepId, isEdit
                    }, 
                        (action, newTask) => {
                            onChangeTask(action, newTask)
                        },
                    true /**useMutation**/)
                }}
                onCreateTask={({ phaseId, stepId, tasks }) => {
                    //ejecutar mutación createTaskFromPlan
                    
                    //abre modal para crear tarea
                    openCreateTask((newTask) => {
                        onCreateTask({ ...newTask, phaseId, stepId, planId: plan?.id })
                        closeCreateTask()
                    }, {stepId, phaseId, planId: plan?.id, position: tasks?.length}, 
                    true /**useMutation**/)
                }}
                onCreate={({ phaseId }) => {

                    //ejecutar mutación para crear step

                    openNewStepModal((newStepName) => {
                        addStepOnTimeline({
                            stepName: newStepName,
                            name: newStepName,
                            title: newStepName,
                            phaseId
                        })
                    })
                }}
                onEdit={({ phaseId, stepName, stepId }) => {

                    //ejecutar mutación para crear step
                    openNewStepModal((newStepName) => {
                        editStepOnTimeline({
                            stepName: newStepName,
                            name: newStepName,
                            title: newStepName,
                            phaseId,
                            stepId
                        })
                    }, stepName)
                }}
                onDelete={({stepId, phaseId}) => {
                    //ejecutar mutación para eliminar step
                    removeStepOnTimeline({stepId, phaseId})
                }}
            />
        </div>
    )
}

export default Timeline