import React, { useState, useEffect, useMemo } from 'react'
import {
  Modal,
  IconClose,
  Field,
  Input,
  Button,
  CheckboxOption,
  IconArrowRight,
  IconArrowDown,
  ListItem,
  IconArrowUp
} from '@Knowledge-OTP/znk-ui-components'
import { FormGroup, Collapse } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  parseDataToModalNewLesson,
  parseOcurrencesToMutationNewIndividualLesson,
  parseOcurrencesToCalendar,
  closeCalendarConflict,
  callBackForConflict
} from '../utils.js'
import ScheduleNewLessonFromAdmin from '../../../../Model/Lessons/Create/ScheduleNewLessonFromAdmin/index'
import ScheduleNewLessonGroupFromAdmin from '../../../../Model/Lessons/Create/ScheduleNewLessonGroupFromAdmin/index'
import moment from 'moment'
import useTranslation from '../../../../i18n/useTranslation'

const DuplicateLesson = (props) => {
  const { refetchPendingLessons } = props
  const dispatch = useDispatch()
  const { isOpen, dataLesson } = useSelector((state) => state.modal.plan.duplicateLessonModal)
  const { translate } = useTranslation()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.duplicateLessonModal',
      data: {
        isOpen: !isOpen,
        dataLesson
      }
    })
  }

  const [form, setForm] = useState({
    lockRes: false,
    setPending: false,
    date: null,
    timeStart: null,
    timeEnd: null
  })

  const [defined, setDefined] = useState(false)
  useEffect(() => {
    if (dataLesson?.startDate && dataLesson?.endDate && !defined) {
      setForm({
        lockRes: false,
        setPending: false,
        date: moment(dataLesson.startDate).format('YYYY-MM-DD'),
        timeStart: moment(dataLesson.startDate).format('HH:mm'),
        timeEnd: moment(dataLesson.endDate).format('HH:mm')
      })
      setDefined(true)
    }
    if (!isOpen) setDefined(false)
    // eslint-disable-next-line
  }, [dataLesson, isOpen, setDefined])

  // scheduleNewLessonFromAdmin
  // para lecciones grupales => scheduleNewLessonGroupFromAdmin
  const { submit: duplicateIndividual, loading: loadIndividual } = ScheduleNewLessonFromAdmin(
    (result) => {
      if (result?.data?.scheduleNewLessonFromAdmin?.hasOccurrences) {
        const ocu = result?.data?.scheduleNewLessonFromAdmin?.occurrencesList
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.scheduledConflict',
          data: {
            ...parseOcurrencesToCalendar(
              ocu,
              (dataCalendar) => {
                callBackForConflict(() => {
                  // dataCalendar.ocurrences siempre debe venir cargado con solo UNA lección
                  const solveLesson = dataCalendar?.ocurrences[0]
                  if (['newBlock', 'force', 'setPending'].includes(solveLesson.resolve)) {
                    duplicateIndividual(
                      parseOcurrencesToMutationNewIndividualLesson(
                        dataCalendar,
                        {
                          lessonInput:
                          solveLesson.resolve === 'newBlock'
                            ? parseToMutation(form, dataLesson, topic)
                            : solveLesson.resolve === 'force'
                              ? { // scheduled anyway
                                ...parseToMutation(form, dataLesson, topic),
                                ignoreEducatorUnavailability: true
                              }
                              : { // Dejar pendiente
                                ...parseToMutation(form, dataLesson, topic),
                                setToPending: true,
                                lessonsPending: 1
                              }
                        }
                      )
                    )
                  } else {
                    // ignore
                  }
                }, dataCalendar, dispatch)
              }
            ),
            isOpen: true
          }
        })
      } else if (result?.data?.reScheduleLessonFromStudent?.errorKey) {
        // no hace nada
      } else {
        closeCalendarConflict(dispatch)
        toggle()
      }
      refetchPendingLessons()
    },
    () => {
      alert('error')
    }
  )
  const { submit: duplicateGroup, loading: loadGroup } = ScheduleNewLessonGroupFromAdmin(
    (result) => {
      if (result?.data?.scheduleNewLessonGroupFromAdmin?.hasOccurrences) {
        const ocu = result?.data?.scheduleNewLessonGroupFromAdmin?.occurrencesList
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.scheduledConflict',
          data: {
            ...parseOcurrencesToCalendar(
              ocu,
              (dataCalendar) => {
                callBackForConflict(
                  () => {
                    // dataCalendar.ocurrences siempre debe venir cargado con solo UNA lección
                    const solveLesson = dataCalendar?.ocurrences[0]
                    if (['newBlock', 'force', 'setPending'].includes(solveLesson.resolve)) {
                      duplicateGroup(
                        parseOcurrencesToMutationNewIndividualLesson(
                          dataCalendar,
                          {
                            lessonInput:
                            solveLesson.resolve === 'newBlock'
                              ? parseToMutation(form, dataLesson, topic)
                              : solveLesson.resolve === 'force'
                                ? { // scheduled anyway
                                  ...parseToMutation(form, dataLesson, topic),
                                  ignoreEducatorUnavailability: true
                                }
                                : { // Dejar pendiente
                                  ...parseToMutation(form, dataLesson, topic),
                                  setToPending: true,
                                  lessonsPending: 1
                                }
                          }
                        )
                      )
                    } else {
                      // ignore
                    }
                  }, dataCalendar, dispatch
                )
              }
            ),
            isOpen: true
          }
        })
      } else if (result?.data?.reScheduleLessonFromStudent?.errorKey) {
        // no hace nada
      } else {
        closeCalendarConflict(dispatch)
        toggle()
      }
      refetchPendingLessons()
    },
    () => {
      alert('error')
    }
  )

  const topic = (dataLesson?.individualTopic || dataLesson?.planTopic || dataLesson?.groupTopic)
  const parseToMutation = (obj, lessonInfo, topicData) => {
    let isGroup = lessonInfo.__typename === 'GroupLesson'
    const date = moment(obj.date).set({
      hour: parseInt(obj?.timeStart.split(':')[0]),
      minute: parseInt(obj?.timeStart.split(':')[1]),
      second: 0,
      millisecond: 0
    })
    const endDate = moment(obj?.date).set({
      hour: parseInt(obj?.timeEnd.split(':')[0]),
      minute: parseInt(obj?.timeEnd.split(':')[1]),
      second: 0,
      millisecond: 0
    })

    const educator = lessonInfo?.substitute || lessonInfo?.educator || {}
    let student = lessonInfo?.studentGroup || lessonInfo?.studentPlan || lessonInfo?.student || {}
    if(student && !Array.isArray(student)) student = [student]

    let info = {
      serviceId: lessonInfo?.service?.id,
      topicId: topicData?.id,
      educatorUserId: educator?.educatorUserId,
      title: lessonInfo?.title,
      date: date,
      startTime: obj?.timeStart,
      endTime: obj?.timeEnd,
      lessonDuration: moment.duration(moment(endDate).diff(date)).asMinutes(),
      lockLesson: obj?.lockRes,
      setToPending: obj?.setPending,
      commentForEducator: lessonInfo?.commentForEducator, // String!,
      endDateRepeat: endDate.toISOString(), // DateTime!,

      // FALTA COMPLETAR
      isRepeat: false, // Boolean!,
      location: {
        type: lessonInfo?.location?.type || 'zoom', // TypeLink!
        linkStudent: lessonInfo?.location?.linkStudent || 'google.com',
        linkEducator: lessonInfo?.location?.linkEducator || 'google.com'
      }, // LocationLessonInput!,
      lessonsPending: 1, // Float!,

      // ARREGLAR
      ignoreEducatorUnavailability: (educator?.ignoreAvailabilty || false), // Boolean!,educator.ignoreAvailabilty

      // EDUCATOR RATE
      ignorePaymentToEducator: educator?.ignorePaymentItem || false, // Boolean!,educator.ignorePaymentItem
      educatorRate: educator?.rate || 0,
      

      paymentFor: date?.clone()?.toISOString() || moment().toISOString() // DateTime!, para cuando quieres poner el payment de esa lesson especifica
    }
    if(isGroup){
      return {
        ...info,
        students: student.map((stu) => {
          return {
            studentUserId: stu?.studentUserId,
            ignorePaymentToStudent: stu?.ignorePaymentItem || false, // Boolean!,
            studentRate: stu?.rate,
          }
        }),
      }
    }
    else {
      return {
        ...info,
        students: [student[0]?.studentUserId],
        ignorePaymentToStudent: student[0]?.ignorePaymentItem, // Boolean!,
        studentRate: student[0]?.rate,
      }
    }
  }

  const [isOpenScheOp, setOpenScheOp] = useState(false)

  //DURACIÓN DE LA LECCIÓN PARA AUTOFILL
  const lessonDuration = useMemo(() => {
    let momentStart = moment().set({
      hour: parseInt(form?.timeStart?.split(':')[0]),
      minutes: parseInt(form?.timeStart?.split(':')[1]),
      seconds: 0
    })
    let momentEnd = moment().set({
      hour: parseInt(form?.timeEnd?.split(':')[0]),
      minutes: parseInt(form?.timeEnd?.split(':')[1]),
      seconds: 0
    })
    let duration = form?.timeEnd 
      ? moment.duration(momentEnd.diff(momentStart)).asMinutes() 
      : 50
        
    return parseInt(duration)
    // eslint-disable-next-line
  }, [form.timeEnd]) 

  useEffect(() => {
    if (form.timeStart) {
      // autofill timeEnd
      setForm({
        ...form,
        timeEnd: moment().set({
          hour: parseInt(form.timeStart.split(':')[0]),
          minutes: parseInt(form.timeStart.split(':')[1]),
          seconds: 0
        }).add(lessonDuration, 'minutes').format('HH:mm')
      })
    }
    // eslint-disable-next-line
  }, [form.timeStart, lessonDuration])
  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header p-6 d-flex flex-row justify-content-between'>
          <span className='h2 font-weight-bold'>{translate('modal.infolesson.duplicatelesson')}</span>
          <IconClose onClick={toggle} />
        </div>
      }
    >
      <div className='m-0 px-6'>
        <FormGroup className='w-100 d-flex flex-column'>
          <div className='w-100 d-flex flex-column'>
            <span className='w-100 p-0 text-gray'>
              {translate('modal.newlesson.date')}
            </span>
            <Input
              type='date' name='date' id='date'
              value={form.date}
              onChange={(e) => {
                setForm({
                  ...form,
                  date: e.target.value
                })
              }}
            />
          </div>
          <div className='mx-0 my-3 p-0 d-flex flex-row'>
            <div className='w-50 d-flex flex-column'>
              <span className='p-0 text-gray'>
                {translate('modal.newlesson.start')}
              </span>
              <Input
                className='noRight'
                type='time' name='timeStart' id='timeStart'
                value={form.timeStart}
                onChange={(e) => {
                  setForm({
                    ...form,
                    timeStart: e.target.value
                  })
                }}
              />
            </div>
            <div className='w-50 d-flex flex-column'>
              <span className='p-0 text-gray'>
                {translate('modal.newlesson.end')}
              </span>
              <Input
                className='noLeft'
                type='time' name='timeEnd' id='timeEnd'
                value={form.timeEnd}
                onChange={(e) => {
                  setForm({
                    ...form,
                    timeEnd: e.target.value
                  })
                }}
              />
            </div>
          </div>
        </FormGroup>
        <ListItem
          className='m-0 px-0 pt-1 pb-5'
          onClick={() => {
            setOpenScheOp(!isOpenScheOp)
          }}
          Left={<span>{translate('modal.newlesson.schedulingoptions')}</span>}
          Right={isOpenScheOp ? <IconArrowUp /> : <IconArrowDown />}
        />
        <div className='borderTop' />
        <Collapse isOpen={isOpenScheOp}>
          <div className='d-flex flex-row mt-5'>
            <Field
              type={CheckboxOption}
              fieldName='lockRes'
              checked={form.lockRes}
              onChange={(e) => {
                setForm({
                  ...form,
                  lockRes: e
                })
              }}
            />
            <span>{translate('modal.newlesson.lockrescheduling')}</span>
          </div>
          <div className='d-flex flex-row mt-5'>
            <Field
              type={CheckboxOption}
              fieldName='setPending'
              checked={form.setPending}
              onChange={(e) => {
                setForm({
                  ...form,
                  setPending: e
                })
              }}
            />
            <span>{translate('modal.newlesson.setlessonpending')}</span>
          </div>
        </Collapse>
        <label
          className='btn-link w-100 my-5'
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'plan.newLessonModal',
              data: {
                isOpen: true,
                isEdit: false,
                defaultForm: {
                  ...parseDataToModalNewLesson(dataLesson),
                  educator: dataLesson?.substitute || dataLesson?.educator || {},
                  //student: dataLesson?.student || dataLesson?.studentPlan || dataLesson?.studentGroup || {},
                  service: dataLesson?.service || {},
                  topic: dataLesson?.individualTopic || dataLesson?.planTopic || dataLesson?.groupTopic || {},
                  locationLesson: dataLesson?.location?.type || null,
                  locationLessonLink: dataLesson?.location?.linkStudent || dataLesson?.location?.linkEducator || null
                }
              }
            })
            toggle()
          }}
        >
          {translate('modal.duplicatelesson.viewalloptions')}
        </label>
        <Button
          onClick={() => {
            if (dataLesson?.__typename === 'GroupLesson') {
              // LECCIÓN GRUPAL
              duplicateGroup({
                lessonInput: parseToMutation(form, dataLesson, topic)
              })
            } else {
              // LECCIÓN INDIVIDUAL
              duplicateIndividual({
                lessonInput: parseToMutation(form, dataLesson, topic)
              })
            }
          }}
          activity={loadIndividual || loadGroup}
          style={{ backgroundColor: '#696CFF' }}
          className='w-100 mb-6'
          size='medium'
          label={
            <div className='d-flex flex-row justify-content-between'>
              <span>{translate('modal.newlesson.createlesson')}</span>
              <IconArrowRight />
            </div>
          }
        />
      </div>
    </Modal>
  )
}

export default DuplicateLesson
