import { initialState } from './initialState'
import {
  SET_USER_ACTION
} from './const'
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_ACTION:
      return {
        ...state,
        ...action.payload
      }
    default:
      return { ...state }
  }
}
