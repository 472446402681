import React, { useMemo } from 'react'
import {
    ToolTipInfo,
    IconWspAlert,
    IconLoad,
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import { nanoid } from 'nanoid'
import moment from 'moment'
import LazyAvatar from '../../../../../../common/LazyAvatar'
import LazyGroupAvatar from '../../../../../../common/LazyAvatar/LazyGroupAvatar'
import LessonCountdownRow from './LessonCountdownRow'

const PlanListItem = (props) => {
    const {
        className = '',
        lesson = {},
        // ...otherProps
    } = props
    const dispatch = useDispatch()
    const tooltipId = nanoid()
    
    

    // const preLabelTime = <span className={`d-flex flex-row align-items-center`}><IconTime size={12} className={`mr-1`}/> <span>{`${totalDuration}min`}</span></span>
    const preLabelTime = null//<span className={`d-flex flex-row align-items-center`}><IconTime size={12} className={`mr-1`}/> <span>{`${totalDuration}min`}</span></span>
    const status = lesson.status
    const title = lesson.title
    const lessonTime = moment(lesson.startDate)
    const lessonEndTime = moment(lesson.endDate)
    const educator = lesson?.substitute || lesson?.educator || {}
    const students = lesson?.studentGroup ?
        lesson.studentGroup :
        [lesson?.student || lesson?.studentPlan]

    const attendants = [
        {
            name: `${educator?.firstName} ${educator?.lastName}`,
            avatar_url: null,
            id: educator?.educatorUserId,
            title: <>{`Educator: ${educator?.firstName} ${educator?.lastName} (${educator?.attendanceStatus})`}</>
        },
    ].concat(students.map((student) => {
        return {
            name: `${student?.firstName} ${student?.lastName}`,
            avatar_url: null,
            id: student?.studentUserId,
            title: <>{`Student: ${student?.firstName} ${student?.lastName} (${student?.attendanceStatus})`}</>
        }
    }))
    const showCountdown = false
    const activity = false
    const showTime = true
    const style = {}
    const onClick = () => { }


    const onWspReminder = () => {
        const students = lesson?.studentGroup ?
            lesson?.studentGroup.map(({ studentUserId }) => studentUserId) :
            lesson?.studentPlan?.studentUserId ?
                [lesson?.studentPlan?.studentUserId] :
                lesson?.student?.studentUserId ?
                    [lesson?.student?.studentUserId] : []
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'lesson.wspAlert',
            data: {
                isOpen: true,
                dataLesson: lesson,
                studentsId: students,
                educatorId: null
            }
        })
    }


    const { breakWidth } = useWindowDimensions()
    const hiddenLeftCount = useMemo(() => ['MD', 'SM'].includes(breakWidth), [breakWidth])
    return (
        <div className={`p-2 ${className}`}>
            <div
                className={`lessonCard upcoming-card mx-0 mb-2 cursor-pointer`}
                onClick={onClick}
                style={style}
            >
                <div className='lessonCardBody px-3 py-3'>
                    <div className='lessonInfo text-truncate'>
                        <span>{title}</span>
                        <span
                            className='small font-weight-light'
                            title={`${preLabelTime || ''} ${showTime
                                ? `${lessonTime.format('hh:mma')} - ${lessonEndTime.format(
                                    'hh:mma',
                                )}`
                                : ''
                                }`}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {preLabelTime}
                            {`${showTime
                                ? `${lessonTime.format('hh:mma')} - ${lessonEndTime.format(
                                    'hh:mma',
                                )}`
                                : ''
                                }`}
                        </span>
                    </div>
                    {activity ? (
                        <div className='h-100 d-flex flex-column justify-content-center'>
                            <IconLoad className='h1' />
                        </div>
                    ) : (
                        <div className='lessonAttendants'>
                            {
                                attendants.length > 1 ? (
                                    <LazyGroupAvatar
                                        useHover
                                        // avatarClassName={`${status}-avatar namedText`}
                                        users={attendants.map((item) => ({
                                            id: item?.id,
                                            name: item.name,
                                            avatar: item.avatar_url,
                                            title: item?.title
                                        }))} />
                                ) : (
                                    attendants.map((item, idx) => (
                                        <LazyAvatar
                                            userId={item?.id}
                                            className={`${status}-avatar namedText`}
                                            name={item.name}
                                            uri={item.avatar_url}
                                            size='avatar-medium'
                                            key={idx}
                                        />
                                    ))
                                )
                            }
                        </div>
                    )}
                </div>
                <LessonCountdownRow 
                    hiddenLeftCount={hiddenLeftCount}
                    optionSection={
                        <ToolTipInfo
                            tooltipId={tooltipId}
                            innerElement={<span className={`text-white`}>Send Wsp reminder</span>}
                        >
                            <div className={`hover-icon primary`} onClick={() => { onWspReminder() }}>
                                <IconWspAlert size={16} />
                            </div>
                        </ToolTipInfo>
                    }
                    lesson={lesson}/>
                {status === 'inprogress' || (status === 'upcoming' && showCountdown) ? (
                    <div className='lessonCardFooter px-3 py-2'>
                        <span>
                            {status === 'incomplete' ? 'Ends In' : 'Starts In'}
                        </span>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default PlanListItem