import React from 'react'
import PdfViewer from '../../../../../PdfEditor/PdfViewer'

const ProposalShoot = (props) => {
    const {plan, ...otherProps} = props
    return (
        plan?.id ? (
            <PdfViewer counselingPlanId={plan.id} isAdmin {...otherProps} />
        ) : null
    )
}

export default ProposalShoot