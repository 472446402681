import { useDispatch, useSelector } from 'react-redux';

const useInfoLesson = () => {
    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state.modal.plan.infoLessonModal)
    const {isOpen} = reduxData
    // const services = useSelector((state) => state.user.services)
    // const orientation = services?.find((serv) => serv.isOrientation)
    const setOpen = (isOpen = false, lesson = {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: `plan.infoLessonModal`,
            data: {
              isOpen,
              dataLesson: lesson
            }
        })
    }
    return {
        ...reduxData,
        open: (lesson = {}) => {
            setOpen(true, lesson)
        },
        close: () => {
            setOpen(false)
        },
        toggle: (lesson = {}) => {
            setOpen(!isOpen, lesson)
        }
    }
}

export default useInfoLesson