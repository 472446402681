import { gql, useQuery } from '@apollo/client'
import docsQueryResult from './queryResults/useTaskResult'

export default (vars) => {
  // Sirve para traer todos los archivos de un usuario.
  // Se le puede pasar un taskId y traer solo los de esa task
  const { data, loading, refetch } = useQuery(
    gql`query(
      $planId: ID!
        )
        {
            getUserDocsAndExams (planId: $planId){
                ${docsQueryResult}
            }
          }`,
    {
      variables: vars,
    },
  )
  return {
    loading,
    data: data ? data.getUserDocsAndExams : [],
    refetch,
  }
}
