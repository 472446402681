import React from 'react'
import {
  ListItem,
  Avatar,
  IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../../i18n/useTranslation'
import SelectStudent from '../../../../../../Plans/modal/newPlan/SelectStudent'

const Student = (props) => {
  const { form, setForm, className, isEdit: cantChange } = props
  const { translate } = useTranslation()
  const RenderStudentListItem = ({ student }) => {
    return (
      <ListItem
        className='py-1'
        id='x-0'
        label={null}
        Left={
          <div className='d-flex align-items-center'>
            <Avatar
              name={`${student.firstName} ${student.lastName}`}
              named={false}
              uri={null}
              size='avatar-medium'
            />
            <label className='ml-2 h4'>
              {`${student.firstName} ${student.lastName}`}
            </label>
          </div>
        }
        Right={!cantChange && (<IconArrowRight />)}
      />
    )
  }
  const RenderListItem = () => {
    const defaultHtml = <label className='btn-link'>{
      translate('modal.newplan.selectstudent')
    }
    </label>
    if (form?.student) {
      if (Array.isArray(form.student)) {
        if(form?.student?.length === 0) return (defaultHtml)
        return (
          form.student.map((stu) => {
            return (
              <RenderStudentListItem student={stu} />
            )
          })
        )
      } else {
        if (JSON.stringify(form.student) === '{}' && cantChange) {
          return `    0 ${translate('modal.infolesson.students')}`
        } else {
          return (<RenderStudentListItem student={form.student} />)
        }
      }
    } else {
      return (defaultHtml)
    }
  }

  const setStudents = (stu, resetWaive) => {
    let students = stu
    if (!Array.isArray(students)) students = [students]
    setForm({
      ...form,
      // studentId: students.map((stu) => stu.id),
      student: students.map((stu, index) => {
        delete stu.studentInfo //studentInfo determina si el
        //estudiante fue seleccionado recien o ya lo estaba desde antes
        if(!stu?.waive) stu.waive = false
        return {
          studentId: stu.id,
          firstName: stu.firstName,
          lastName: stu.lastName,
          // studentNamsetExamScoreForme: stu.userName,
          // ...stu,
          // rate: rates[index]
        }
      }),
      // studentRate: rates,
      // waiveStudentRate: resetWaive ? new Array( students.length ).fill(false) : form.waiveStudentRate,
      // isStudentRate: true
    })
  }
  //useEffect(() => {
  //  // CUANDO SE SELECCIONA SERVICIO RESETEA LOS RATE DE LOS ESTUDIANTES
  //  if(form?.student && !isEdit) setStudents(form.student, false)
  //  // eslint-disable-next-line
  //}, [form?.service])
  return (
    <>
      <div className={`m-0 p-0 ${className}`}>
        <h3 className='mb-3'><b>{translate('modal.newplan.student')}</b></h3>
        {cantChange
          ? <RenderListItem />
          : <SelectStudent
              group={false}
              setStudent={(stu) => {setStudents(stu, true)}}
              student={{}}
            >
              <RenderListItem />
            </SelectStudent>
          }
      </div>
    </>
  )
}

export default Student
