import {useSelector, useDispatch} from 'react-redux'

const useCompletaAllPlans = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.completeAllPlans)
    const {isOpen} = reduxData
    const setOpen = (open) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'completeAllPlans',
            data: {
                ...reduxData,
                isOpen: open,
            }
        })
    }

    return {
        isOpen,
        open: () => {
            setOpen(true)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
    }

}

export default useCompletaAllPlans