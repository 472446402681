import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'

export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation (
      $paymentItemId: ID!
    ) {
      cancelPaymentItemFromAdmin(
          paymentItemId: $paymentItemId,
      ){
        ...on PlanPaymentItem {
          id
        }
        ... on CustomPaymentItem {
          id
        }
        ... on LessonPaymentItem {
          id
        }
        ... on EducatorPaymentItem {
          id
        }
      }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const remove = useCallback((obj) => {
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.cancelPaymentItemFromAdmin : null, error, validationErrors, remove }
}
