import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PlanListItemSkeleton = (props) => {
    const {className = ''} = props
    return (
        <div className={`d-flex flex-row w-100 justify-content-between align-items-center ${className}`}>
            <div className={`d-flex flex-row align-items-center`}>
                <Skeleton className='mr-2' count={1} style={{borderRadius: '12px'}} width={40} height={40} />
                <div className={`d-flex flex-column justify-content-center`}>
                    <Skeleton count={1} width={100} height={12} />
                    <Skeleton count={1} width={70} height={10} />
                </div>
            </div>
            
            <div className={`d-flex flex-row justify-content-between`}>
                <Skeleton width={15} height={15} className={'mr-2'} style={{borderRadius: '50%'}}/>
                <Skeleton width={15} height={15} style={{borderRadius: '50%'}} />
            </div>
        </div>
    )
}

export default PlanListItemSkeleton