import {useLazyQuery, gql} from '@apollo/client'
import queryLessonResult from '../../QueryResult/useLessonResult'


export const useGetLesson = (onSuccess = () => {}, onError = () => {}) => {
  // Sirve para traer una lesson en particular.
  return useLazyQuery(
    gql`query(
        $lessonId: String
              )
              {
        getLesson(
            lessonId: $lessonId
    ) {
        ${queryLessonResult}
    }
}`,
    {
      fetchPolicy: 'no-cache',
      onCompleted: onSuccess,
      onError,
    },
  )
}