import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation (
      $teachingTypes: [TeachingType!]!,
      $topicId: ID!,
      $serviceId: ID!,
      $userId: ID!,
      $individualRate: Float!,
      $groupRate: Float!,
      $isWaiveIndividual: Boolean!,
      $isWaiveGroup: Boolean!
    ) {
        setTeachingTypesToEducatorService(
            teachingTypes: $teachingTypes,
            topicId: $topicId,
            serviceId: $serviceId,
            userId: $userId
            individualRate: $individualRate,
            groupRate: $groupRate,
            isWaiveIndividual: $isWaiveIndividual,
            isWaiveGroup: $isWaiveGroup
        ){
      id
      emails {
        address
        verified
      }
      countryId
      gender
      spokenLanguages
      address
      city
      state
      zipCode
      firstName
      lastName
      phone
      timezone
      birthdate
      studentInfo {
        institution
        graduationDate
        gpa
      }
      parentInfo {
        children {
          studentUserId
          managePayments
          manageApprovals
          firstName
          lastName
          email
        }
      }
      educatorInfo {
        id
        services {
          serviceId
          name
          defaultRate
          topics {
            topicId
            teachingTypes
            name
          }
        }
        backupLink
      }
      roles
        }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.setTeachingTypesToEducatorService : null, error, validationErrors, submit }
}
