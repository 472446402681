import React from 'react'
import PlanProgressSection from './Sections/PlanProgress'
import ApplicationStatusSection from './Sections/ApplicationStatus'
import TeamSection from '../Resume/Sections/Team'
import PaymentScheduledSection from './Sections/PaymentScheduled'
import PaymentProgressSection from './Sections/PaymentProgress'

import StudentSection from '../Resume/Sections/Student'

import PlanActionStatus from '../Resume/Sections/PlanActionStatus'
import LiveprepTimeline from '../Resume/Sections/LiveprepTimeline'

import ListOption from '../../../../../CounselingTemplates/Task/viewTaskModal/Components/ListOption'

const Summary = (props) => {
    const {plan, setData, options} = props
    const sectionProps = {plan, setData}
    // const {options} = useOptionsByStatus(statusPlan, plan)
    return (
        <div className={`d-flex flex-column`}>
            {
                plan?.status === 'cancel' && 
                <>
                    <PlanActionStatus className={`my-1`} {...sectionProps}/>
                    <div className={`borderTop w-100 m-0`} />
                </>
            }
            <StudentSection className={`my-5`} {...sectionProps} />
            <div className={`borderTop w-100 m-0`} />
            <PlanProgressSection className={`my-5`} {...sectionProps} />
            <div className={`borderTop w-100 m-0`} />
            <ApplicationStatusSection className={`my-5`} {...sectionProps} />
            {
                plan?.timeline?.length > 0 && (
                    <>
                        <div className={`borderTop w-100 m-0`} />
                        <LiveprepTimeline className={`my-5`} {...sectionProps} />
                    </>
                ) 
            }
            <div className={`borderTop w-100 m-0`} />
            <TeamSection className={`my-5`} {...sectionProps} />
            <div className={`borderTop w-100 m-0`} />
            <PaymentScheduledSection className={`my-5`} {...sectionProps} />
            <div className={`borderTop w-100 m-0`} />
            <PaymentProgressSection className={`my-5`} {...sectionProps}/>
            {
                options.map((option, index) => {
                    return (
                        <ListOption 
                            showBottomLine={index < options.length - 1} 
                            showTopLine={index===0}
                            {... option} 
                        />
                    )
                })
            }
        </div>
    )
}

export default Summary