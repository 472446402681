import React, { useState } from 'react'
import { Activity, CheckboxOption, Field, Form, IconEdit } from '@Knowledge-OTP/znk-ui-components'
import isEqual from 'lodash/isEqual'
import useSetTeachingTypes from '../../../../../../../../Model/Users/Educators/SetTeachingTypesToEducatorService'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const TopicTeachingType = (props) => {
  const { topic, serviceId, userId, onChange } = props
  const [selectedTypes, setSelectedTypes] = useState(topic.teachingTypes.reduce((obj, item) => { return { ...obj, [item]: true } }, {}))
  const { translate } = useTranslation()
  const onSuccess = (result) => {
    const { data: { setTeachingTypesToEducatorService: { educatorInfo } } } = result
    onChange((val) => ({ ...val, educatorInfo }))
  }
  const { submit, loading } = useSetTeachingTypes(onSuccess, console.log)
  const arrayFromSelected = Object.entries(selectedTypes).filter((item) => item[1]).map(item => item[0])
  const onSubmit = () => {
    submit({
      teachingTypes: arrayFromSelected,
      topicId: topic.topicId,
      serviceId,
      userId
    })
  }
  return (
    <span className='d-flex justify-content-end'>
      <span className='mr-auto'>
        {topic.name}
      </span>
      <Form value={selectedTypes} onChange={setSelectedTypes}>
        <span>{translate('individual')}</span>
        <span className='d-flex align-items-center'>
          <Field
            type={CheckboxOption}
            className='mx-2 border-right border-logo'
            fieldName='individual'
            defaultState={topic.teachingTypes.indexOf('individual') !== -1}
          />
        </span>
        <span>{translate('group')}</span>
        <span>
          <Field
            type={CheckboxOption}
            className='mx-2 pr-md-2 border-right border-logo'
            fieldName='group'
            defaultState={topic.teachingTypes.indexOf('group') !== -1}
          />
        </span>
        <span style={{ minWidth: '20px' }}>
          {loading ? <Activity /> : (
            !isEqual(topic.teachingTypes, Object.entries(selectedTypes).filter((item) => item[1]).map(item => item[0]))
              ? <IconEdit
                size={16}
                className='text-body text-right'
                onClick={() => onSubmit()}
                style={{ cursor: 'pointer' }}
              /> : null
          )}
        </span>
      </Form>
    </span>
  )
}

export default TopicTeachingType
