import React, {useMemo} from 'react'
import {
    IconStarActive,
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import DateField from './Fields/DateField'
import StudentField from './Fields/Student'
import useViewTaskModal from '../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import useNewTaskModal from '../../../CounselingTemplates/Task/newTaskModal/useNewTaskModal'
import useAssignTaskModal from '../../../CounselingTemplates/Task/assingTaskModal/useAssignTaskModal'
import useDeleteTaskModal from '../../../CounselingTemplates/Task/deleteTaskModal/useDeleteTaskModal'


export const getTaskNameField = (task, onClickOpenTask = () => {}) => {
    return <>
        <IconStarActive size={16} className={`text-counseling mr-2`} style={{minWidth: '16px'}} />
        {/* <div className={`star-shape small counseling mr-1`} /> */}
        <span 
            onClick={() => {
                onClickOpenTask(task)
            }}
            className={`h45 text-info text-truncate font-weight-light ${task?.status === 'completed' ? 'text-line-through text-gray' : ''}`} 
            title={task?.name} >{task?.name}</span>
    </>
}

export const getDateField = (task, onChangeTask = () => {}) => {
    return <DateField className={`h45 text-info font-weight-light`} task={task} onChangeTask={onChangeTask} />
}

const useColumns = (refetch = () => {}) => {
    const {/**open: openViewTaskModal,**/ openForCreatedTask: openViewTaskModal, close: closeViewTaskModal} = useViewTaskModal()
    const {close: closeNewTaskModal} = useNewTaskModal()
    const {close: closeAssignTaskModal} = useAssignTaskModal()
    const {close: closeDeleteTaskModal} = useDeleteTaskModal()  
    const {breakWidth} = useWindowDimensions()
    const onClickOpenTask = (task) => {
        openViewTaskModal(
        {...task, isEdit: true, taskName: task?.name}, 
        onChangeTask, 
        true /**useMutation**/)
    }

    const onChangeTask = (action) => {
        switch(action) {
            case 'edit':
                closeNewTaskModal()
                break
            case 'new':
                closeNewTaskModal()
                break    
            case 'duplicate' :
                break
            case 'assign' : //ejecuta mutación en modal assignTaskModal
                //luego de asignar la tarea
                //setea info de task en taskViewModal
                closeAssignTaskModal()
                break 
            case 'delete' : //ejecuta mutación en modal deleteTaskModal
                closeDeleteTaskModal()
                closeViewTaskModal()
                break        
            default: 
                break        
        }
        refetch()
    }
    
    
    const getCounselingField = (task) => {
        return <span onClick={() =>{onClickOpenTask(task)}}  className={`h45 text-info font-weight-light`}>Counseling</span>
    }
    const getStudentField = (task) => {
        return <StudentField task={task} />
    }
    

    return useMemo(
        () => {
            if(['SM', 'MD'].includes(breakWidth)){
                return {
                    config: [
                        {
                            col: 4,
                            className: 'h4 d-flex flex-row justify-content-start align-items-center'
                        },
                        // {
                        //     col: 2,
                        //     className: 'h4 d-flex flex-row justify-content-center align-items-center'
                        // },
                        {
                            col: 4,
                            className: 'h4 d-flex flex-row justify-content-start align-items-center'
                        },
                        {
                            col: 4,
                            className: 'h4 d-flex flex-row justify-content-end align-items-center'
                        }
                    ],
                    renderRow: (task) => {
                        return {
                            rowInner: [
                                getTaskNameField(task, onClickOpenTask),
                                // getCounselingField(),
                                getStudentField(task),
                                getDateField(task, onChangeTask)
                            ],
                            item: task
                        }
                    }
                }
            }
            return {
                config: [
                    {
                        col: 4,
                        className: 'h4 d-flex flex-row justify-content-start align-items-center'
                    },
                    {
                        col: 2,
                        className: 'h4 d-flex flex-row justify-content-center align-items-center'
                    },
                    {
                        col: 3,
                        className: 'h4 d-flex flex-row justify-content-start align-items-center'
                    },
                    {
                        col: 3,
                        className: 'h4 d-flex flex-row justify-content-end align-items-center'
                    }
                ],
                renderRow: (task) => {
                    return {
                        rowInner: [
                            getTaskNameField(task, onClickOpenTask),
                            getCounselingField(task),
                            getStudentField(task),
                            getDateField(task, onChangeTask)
                        ],
                        item: task
                    }
                }
            }
            // eslint-disable-next-line
        }, [breakWidth]
    )
}

export default useColumns