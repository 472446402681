import { useQuery, gql } from "@apollo/client";

const useAllUsers = (vars = {}, options = {fetchPolicy: 'cache-first'}) => {
    return useQuery(
        gql`
            query (
                $universitiesIds: [String!] = []
                $sortAscending: Boolean = true
                $sortField: String = "firstName"
                $previous: String = null
                $next: String = null
                $limit: Int
                $search: String
            ) {
                universitiesGroupByStudents (
                    universitiesIds: $universitiesIds
                    sortAscending: $sortAscending
                    sortField: $sortField
                    previous: $previous
                    next: $next
                    limit: $limit
                    search: $search
                ) {
                    hasNext
                    hasPrevious
                    next
                    previous
                    totalDocs
                    docs {
                        id
                        isActive
                        firstName
                        lastName
                        avatar
                        studentInfo {
                            universities {
                                universityId: id
                                status
                                early
                                isPrincipal
                                name
                                collegeId
                                logo
                                attending
                            }
                            classOf
                            graduationDate
                        }
                        getUserAssignTasks {
                            planId
                            taskId
                            phaseId
                            stepId
                            task {
                                universities
                            }
                        }
                    }
                }
            }
        `,
        {
            variables: vars, 
            ...options
            //fetchPolicy: 'network-only'

        },
        
    )
}

export default useAllUsers;