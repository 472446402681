import React, { useEffect, useState, Fragment, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, IconClose, Field, InputSelect, PlainTextInput, AmountInput, Button, IconOptions, StickyButton, LabeledField } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'
import PlanStudent from '../../../Plans/modal/newPlan/Components/PlanStudent'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import formatNumber from '../../../../utils/formatNumber'
// import getPaymentsType from '../../../../utils/getPaymentsType'
import '../../index.css'
import Menu from '../new/menu'
import editInvoice from '../../../../Model/Payments/Invoices/Edit'
import { categories } from '../../Payments/constants'
import { isEmpty, validate } from 'validate.js'
import constraints from './constraints'
import { escapeRegExp } from 'lodash'
import { getPaymentItemEnum } from '../view/PaymentItemsEnum'
import PaymentItemInput from './PaymentItemInput'

const EditInvoice = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.editInvoice)
  const { isOpen, invoiceId } = modalData || { isOpen: false, invoiceId: '' }
  // const invoiceId = '615cc65b54655c3923af86cb'
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const [validationErrors, setValidationErrors] = useState({})
  const [form, setForm] = useState({
    student: undefined,
    payments: {},
    newPayments: {}
  })
  const [errors, setErrors] = useState()
  const [meInvoice, { data }] = useLazyQuery(
    gql`query($invoiceId: String!){
      invoice(invoiceId: $invoiceId) {
        id
        invoiceNumber
        invoiceDate
        invoiceDue
        isManual
        userId {
          id
          firstName
          lastName
          paymentMethod {
            ... on CardPaymentMethod {
              brand
              last4
            }
          }
        }
        billedUserId
        processedTimes
        createdAt
        updatedAt
        category
        from
        to
        status
        paymentItems {
          ... on PlanPaymentItem {
            id
            planId
            kind
            createdAt
            updatedAt
            category
            amount
            percentDiscount
            amountWithoutDiscount
            date
            draft
            status
            lastProcessedAt
            description
            generatedDescription
            type
          }
          ... on LessonPaymentItem {
            lessonId
            id
            kind
            createdAt
            updatedAt
            category
            amount
            percentDiscount
            amountWithoutDiscount
            date
            draft
            status
            lastProcessedAt
            description
            generatedDescription
            type
          }
          ... on EducatorPaymentItem {
            id
            kind
            createdAt
            updatedAt
            category
            amount
            percentDiscount
            amountWithoutDiscount
            date
            draft
            status
            lastProcessedAt
            description
            type
          }
          ... on CustomPaymentItem {
            id
            kind
            createdAt
            updatedAt
            category
            amount
            percentDiscount
            amountWithoutDiscount
            date
            draft
            status
            lastProcessedAt
            description
            type
          }
        }
        paymentItemIds
        lastProcessedAt
        amount
        taxFee
        amounts {
          subTotal
          taxAmount
          amount
        }
      }
    }`,
    {
      variables: { invoiceId: invoiceId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (!invoiceId) return
      await meInvoice()
    })()
  }, [invoiceId, meInvoice])
  useEffect(() => {
    if (data) {
      setForm({
        student: data.invoice.userId,
        category: data.invoice.category,
        number: 'INV-' + data.invoice.invoiceNumber,
        invoiceDate: data.invoice.isManual ? data.invoice.invoiceDate.split('T')[0] : data.invoice.from.split('T')[0],
        dueDate: data.invoice.isManual ? data.invoice.invoiceDue.split('T')[0] : data.invoice.to.split('T')[0],
        newPayments: {},
        payments: data.invoice.paymentItems.reduce((accumulator, element, index) => ({
          ...accumulator,
          [element.id]: {
            ...element,
            kind: element.kind,
            type: element.type,
            // modeType: element.type, //element.kind === 'plan-payment-item' && element.type === 'lessonsPayment' ? 'studyplan' : 'lessonsPayment',
            planId: element.planId ?? '',
            description: element.generatedDescription || element.description,
            isNewpayment: false,
            isDelete: false,
            errorType: '',
            errorDiscount: '',
            errorAmount: '',
            errorDescription: isEmpty(element.description) ? 'description' : '',
            errorPlan: ''
          }
        }), {}),
        items: [],
        taxFee: data.invoice.taxFee,
        subTotal: data.invoice.amounts.subTotal,
        taxAmount: data.invoice.amounts.taxAmount,
        total: data.invoice.amounts.amount,
        status: data.invoice.status,
        isManual: data.invoice.isManual
      })
    }
  }, [data])
  const toggle = () => {
    setForm({
      student: undefined,
      category: 'livePrep',
      invoiceDate: undefined,
      dueDate: undefined,
      idcategory: 'lessonsPayment',
      discount: 0,
      amount: 0,
      description: '',
      payments: {},
      newPayments: {},
      taxFee: 0,
      subTotal: 0,
      taxAmount: 0,
      total: 0
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.editInvoice',
      data: {
        isOpen: false,
        invoiceId: ''
      }
    })
  }
  const deleteItem = (item) => {
    setForm({
      ...form,
      payments: { ...form.payments, [item.id]: { ...item, isDelete: true } }
    })
  }
  const deleteNewItem = (item, index) => {
    setForm({
      ...form,
      newPayments: { ...form.newPayments, [index]: { ...item, isDelete: true } }
    })
  }
  const { submit, loading } = editInvoice(() => {
    refetch()
    toggle()
  }, console.log)
  const onSubmit = (status) => {
    setErrors({})
    const newVal = {
      category: form.category,
      invoiceDate: form.invoiceDate,
      dueDate: form.dueDate,
      taxFee: form.taxFee,
      userId: form.student.id,
      status: status
    }
    let firstError = false
    const errorsVal = validate({ input: newVal }, constraints)
    if (errorsVal !== undefined) {
      setValidationErrors(errorsVal)
      firstError = true
    }
    let hasError = false
    const itemVal = Object.values(form.payments).reduce(
      (items, current, index) => {
        if (!hasError && (isNaN(current.percentDiscount) || parseFloat(current.percentDiscount) < 0 || isNaN(current.amountWithoutDiscount) || isEmpty(current.description) || current.type === undefined)) {
          hasError = true
        }
        return {
          ...items,
          [current.id]: {
            ...current,
            errorDiscount: isNaN(current.percentDiscount) ? 'amount' : parseFloat(current.percentDiscount) < 0 ? 'amount' : '',
            errorAmount: isNaN(current.amountWithoutDiscount) ? 'amount' : parseFloat(current.amountWithoutDiscount) <= 0 ? 'amount' : '',
            errorDescription: isEmpty(current.description) ? 'description' : '',
            errorType: current.type === undefined || isEmpty(current.type) ? 'type' : '',
            errorPlan: current.type === "studyplan" && current.planId === '' ? 'plan' : ''
          }
        }
      }
      , {})
    const itemValNew = Object.values(form.newPayments).reduce(
      (items, current, index) => {
        if (!hasError && (isNaN(current.percentDiscount) || parseFloat(current.percentDiscount) < 0 || isNaN(current.amount) || isEmpty(current.description) || current.type === undefined || isEmpty(current.description))) hasError = true
        return {
          ...items,
          [index]: {
            ...current,
            errorDiscount: isNaN(current.percentDiscount) ? 'amount' : parseFloat(current.percentDiscount) < 0 ? 'amount' : '',
            errorAmount: isNaN(current.amount) ? 'amount' : parseFloat(current.amount) <= 0 ? 'amount' : '',
            errorDescription: isEmpty(current.description) ? 'description' : '',
            errorType: current.type === undefined || isEmpty(current.type) ? 'type' : '',
            errorPlan: current.type === "studyplan" && current.planId === '' ? 'plan' : ''
          }
        }
      }
      , {})
    if (hasError || firstError) {
      setForm({
        ...form,
        payments: itemVal,
        newPayments: itemValNew
      })
      return
    }
    const payments = Object.values(form.payments).map(
      element => {
        return {
          paymentItemId: element.id,
          // amountWithoutDiscount: element.amountWithoutDiscount,
          amount: element.amountWithoutDiscount,
          discount: element.percentDiscount,
          description: element.description,
          type: element.type,
          isDelete: element.isDelete,
          isNewPayment: false,
          kind: element.kind,
          planId: element.planId
        }
      }
    )
    const newPayments = Object.values(form.newPayments).filter(e => !e.isDelete).map(
      element => {
        return {
          amount: element.amountWithoutDiscount,
          // amountWithoutDiscount: element.amountWithoutDiscount,
          discount: element.percentDiscount,
          description: element.description,
          type: element.type,
          isDelete: false,
          isNewPayment: true,
          kind: element.kind,
          planId: element.planId
        }
      }
    )
    newVal.paymentItems = payments.concat(newPayments)
    newVal.paymentItems = newVal.paymentItems.map((e) => {
      return { 
        ...e, 
        type: e.type === 'studyplan' && false ? 'lessonsPayment' : e.type, 
        kind: e.type === 'studyplan' ? 'plan-payment-item' : e.kind 
      } 
    })
    newVal.paymentItems = newVal.paymentItems.map(e => ({ ...e, kind: getPaymentItemEnum(e.kind) }))
    submit({ invoiceId: invoiceId, input: newVal })
  }
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key.split('.')[1]
        customError[keyVal] = translate('payslip.actions.newPayslip.errors.' + keyVal)
      })
      setErrors(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])
  const newSaveInvoice = (value) => {
    onSubmit(value)
  }
  const newAproveInvoice = (value) => {
    onSubmit(value)
  }
  const addPayment = () => {
    setForm({
      ...form,
      newPayments: {
        ...form.newPayments,
        [Object.values(form.newPayments).length]: {
          type: 'lessonsPayment',
          amount: 0,
          percentDiscount: 0,
          isDelete: false,
          isNewpayment: true,
          errorType: '',
          errorDiscount: '',
          errorAmount: '',
          errorDescription: '',
          kind: 'custom-payment-item',
          planId: ''
        }
      }
    })
  }
  useEffect(() => {
    const amountPayments = Object.values(form.payments).reduce(
      (sum, current) => {
        if (current.isDelete === false) {
          sum += (current.percentDiscount === 0 ? current.amountWithoutDiscount : parseFloat(current.amountWithoutDiscount) - ((parseFloat(current.percentDiscount) / 100) * parseFloat(current.amountWithoutDiscount)))
        }
        return sum
      }
      , 0)
    const amountNewPayments = Object.values(form.newPayments).reduce(
      (sum, current) => {
        if (current.isDelete === false) {
          sum += current.isDelete === true ? 0 : (current.percentDiscount === 0 ? current.amountWithoutDiscount : parseFloat(current.amountWithoutDiscount) - ((parseFloat(current.percentDiscount) / 100) * parseFloat(current.amountWithoutDiscount)))
        }
        return sum
      }
      , 0)
    const taxAmount = parseFloat(form.taxFee) === 0
      ? 0
      : ((parseFloat(form.taxFee) / 100) * (amountPayments + amountNewPayments))
    const total = parseFloat(form.taxFee) === 0
      ? amountPayments + amountNewPayments
      : amountPayments + amountNewPayments + ((parseFloat(form.taxFee) / 100) * (amountPayments + amountNewPayments))
    setForm({
      ...form,
      subTotal: amountPayments + amountNewPayments,
      taxAmount: taxAmount,
      total: total
    })
    // eslint-disable-next-line
  }, [form.payments, form.newPayments, form.taxFee])
  useLayoutEffect(() => {
    setForm({
      ...form,
      dueDate: moment(form.invoiceDate).add(7, 'days').format('YYYY-MM-DD')
    })
    // eslint-disable-next-line
  }, [form.invoiceDate])


  const calculateAmountWithDiscount = (amount = 0, percent = 0) => {
    return percent > 0 ? amount*((100-percent)/100) : amount
  }
  return (
    <>
      <div className='m-0 p-0' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        className='modal-sidebar modal-colored white-modal modal-desktop-half pr-3 pl-3'
        isOpen={isOpen}
        toggle={toggle}
        backdrop='static'
        Head={
          <div className='modal-header p-0 m-0'>
            <div className='d-flex justify-content-between w-100 p-7'>
              <h2 className='font-weight-bold'>Edit Invoice</h2>
              <IconClose onClick={toggle} className='text-gray' />
            </div>
          </div>
        }
      >
        {
          data ? (
            <Fragment>
              <div className='m-0 p-0 mb-2'>
                <div className='px-7'>
                  <div className='borderTop mt-5' />
                </div>
                <div className='px-4'>
                  <PlanStudent form={form} setForm={setForm} title={false} first={false} invoice edit={false} />
                </div>
                <div className='px-7'>
                  <div className='borderTop' />
                </div>
                <div className='row d-flex m-0 mt-5 px-7'>
                  <div className='col-6 p-0 pr-2'>
                    <label className='text-gray font-weight-light mb-2'>Category</label>
                    <Field
                      className='col-12 px-0'
                      type={InputSelect}
                      isDisabled
                      fieldName='category'
                      placeholder={translate('modal.newplan.selectservice')}
                      options={categories && [].concat(categories.map((serv) => ({ value: serv.id, label: translate(`payments.categories.${serv.id}`) })))}
                      InputClassName='w-100'
                      value={form.category}
                      filterOption={(obj, string) => {
                        if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                        return false
                      }}
                    />
                  </div>
                  <div className='col-6 pl-1 pr-0 pl-2'>
                    <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.number')}</label>
                    <Field
                      component={PlainTextInput}
                      type={LabeledField}
                      fieldName='number'
                      fieldType='text'
                      readOnly
                      value={form.number}
                    />
                  </div>
                </div>
                <div className='row d-flex m-0 mt-5 px-7'>
                  <div className='col-6 p-0 pr-2'>
                    <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.invoicedate')}</label>
                    <Field
                      component={PlainTextInput}
                      type={LabeledField}
                      fieldName='invoiceDate'
                      fieldType='date'
                      value={form.invoiceDate}
                      disabled={form.isManual === false}
                      onChange={
                        (e) => {
                          setForm({
                            ...form,
                            invoiceDate: e
                          })
                        }
                      }
                    />
                    {!isEmpty(errors) && errors.invoiceDate && <h5 className='text-error'>{translate('payments.editinvoice.errors.invoicedate')}</h5>}
                  </div>
                  <div className='col-6 pl-1 pr-0 pl-2'>
                    <label className='text-gray font-weight-light mb-2'>Due Date</label>
                    <Field
                      component={PlainTextInput}
                      type={LabeledField}
                      fieldName='dueDate'
                      fieldType='date'
                      value={form.dueDate}
                      disabled={form.isManual === false}
                      onChange={
                        (e) => {
                          setForm({
                            ...form,
                            dueDate: e
                          })
                        }
                      }

                    />
                    {!isEmpty(errors) && errors.dueDate && <h5 className='text-error'>{translate('payments.editinvoice.errors.duedate')}</h5>}
                  </div>
                </div>
                <div className='px-7'>
                  <hr className='mt-1 mb-4' />
                </div>
                <div className='row d-flex m-0 px-7'>
                  <div className='col-12 p-0 pr-2'>
                    <label className='text-gray font-weight-light mb-2'>Item</label>
                  </div>
                  <div className='col-4 pl-0 text-left'>
                    <label className='text-gray font-weight-light mb-2'>Discount</label>
                  </div>
                  <div className='col-8 pl-2 text-left'>
                    <label className='text-gray font-weight-light mb-2'>Amount</label>
                  </div>
                </div>
                <div className='px-7'>
                  <hr className='mt-1 mb-4' />
                </div>
                {
                  Object.values(form.payments).filter(e => !e.isDelete).map(
                    (element, index) => {
                      return (
                        <PaymentItemInput
                          showToBorder={index > 0}
                          onDelete={(element) => { deleteItem(element) }}
                          invoice={data?.invoice}
                          setForm={setForm} form={form} element={element} index={index}
                          onChangeType={(e) => {
                            setForm({
                              ...form,
                              payments: { 
                                ...form.payments, 
                                [element.id]: { 
                                  ...element, 
                                  type: 
                                    element.kind === 'plan-payment-item' && element.type === 'lessonsPayment' 
                                      ? 'studyplan' : e 
                                } 
                              }
                            })
                          }}
                          onChangeDiscount={(e) => {
                            setForm({
                              ...form,
                              payments: { ...form.payments, [element.id]: { ...element, 
                                percentDiscount: e,
                                amount: calculateAmountWithDiscount(element.amountWithoutDiscount, e)
                              } }
                            })
                          }}
                          onChangeAmount={(e) => {
                            setForm({
                              ...form,
                              payments: { ...form.payments, [element.id]: { 
                                ...element, 
                                amountWithoutDiscount: e,
                                amount: calculateAmountWithDiscount(e, element.percentDiscount)
                              } }
                            })
                          }}
                          onChangeDescription={(e) => {
                            setForm({
                              ...form,
                              payments: { ...form.payments, [element.id]: { ...element, description: e } }
                            })
                          }}
                          onChangePlan={(value) => {
                            setForm({
                              ...form,
                              payments: { ...form.payments, [element.id]: { ...element, planId: value.id } }
                            })
                          }}
                        />
                      )
                    }
                  )
                }
                {
                  Object.values(form.newPayments).filter(e => !e.isDelete).map(
                    (element, index) => {
                      return (
                        <PaymentItemInput
                          showToBorder={index > 0 || (Object.values(form.payments).filter(e => !e.isDelete)).length > 0}
                          onDelete={(element) => { deleteNewItem(element, index) }}
                          invoice={data?.invoice}
                          formName={'newPayments'}
                          setForm={setForm} form={form} element={element} index={index}
                          onChangeType={(e) => {
                            setForm({ ...form, newPayments: { ...form.newPayments, [index]: { ...element, type: e } } })
                          }}
                          onChangeDiscount={(e) => {
                            setForm({
                              ...form,
                              newPayments: { ...form.newPayments, [index]: { ...element, 
                                percentDiscount: e,
                                amount: calculateAmountWithDiscount(element.amountWithoutDiscount, e)
                               } }
                            })
                          }}
                          onChangeAmount={(e) => {
                            setForm({
                              ...form,
                              newPayments: { ...form.newPayments, [index]: { ...element, 
                                amountWithoutDiscount: e, 
                                amount: calculateAmountWithDiscount(e, element.percentDiscount)
                                
                              } }
                            })
                          }}
                          onChangeDescription={(e) => {
                            setForm({
                              ...form,
                              newPayments: { ...form.newPayments, [index]: { ...element, description: e } }
                            })
                          }}
                          onChangePlan={(value) => {
                            setForm({
                              ...form,
                              newPayments: { ...form.newPayments, [index]: { ...element, planId: value.id } }
                            })
                          }}
                        />
                      )
                    }
                  )
                }
                <div className='row d-flex m-0 mt-2 px-7'>
                  <span className='btn-link cursor' onClick={addPayment}>Add new payment</span>
                </div>
                <div className='row d-flex m-0 mt-5 px-7'>
                  <div className='col-6 p-0 pr-2'>
                    <label className='text-gray font-weight-light mb-2'>Subtotal</label>
                  </div>
                  <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                    <label className='text-gray font-weight-light mb-2'>${formatNumber(form.subTotal)}</label>
                  </div>
                </div>
                <div className='px-7'>
                  <hr className='m-0 mt-1' />
                </div>
                <div className='row d-flex m-0 p-0 mt-4 mb-2 px-7' style={{ alignItems: 'center' }}>
                  <div className='col-5 p-0 pr-2'>
                    <label className='text-gray font-weight-light mb-2 font-weight-light'>{form.taxFee === 0 ? 'Tax' : `Credit Card Processing Fee at ${form.taxFee}`}</label>
                  </div>
                  <div className='col-3 pl-1 pr-0 pl-2 text-right'>
                    <Field
                      className='col-12 placeholder-to-right'
                      component={AmountInput}
                      type={LabeledField}
                      fieldType='number'
                      placeholder='0.00 '
                      addOn='%'
                      min='0'
                      value={form.taxFee}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          taxFee: e
                        })
                      }}
                    />
                  </div>
                  <div className='col-4 pl-1 pr-0 pl-2 text-right'>
                    <label className='text-gray font-weight-light mb-2 font-weight-light'>${formatNumber(form.taxAmount)}</label>
                  </div>
                </div>
                <div className='px-7'>
                  <hr className='m-0 mt-1' />
                </div>
                <div className='row d-flex m-0 mt-5 px-7'>
                  <div className='col-6 p-0 pr-2'>
                    <label className='text-gray font-weight-light mb-2 font-weight-bold'>Total Price</label>
                  </div>
                  <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                    <label className='text-gray font-weight-light mb-2 font-weight-bold'>${formatNumber(form.total)}</label>
                  </div>
                </div>
                <div className='px-7'>
                  <hr className='m-0 mt-1' />
                </div>
                <div className='px-3 px-lg-5 px-xl-7 d-flex flex-column my-5'>
                  <StickyButton>
                    <div className='m-0 p-0 row mt-7'>
                      <Button
                        color={form.status === 'draft' ? 'secondary' : 'primary'}
                        activity={loading}
                        className={form.status === 'draft' ? 'col-9' : 'col-12'}
                        label={form.status === 'draft' ? 'Save in draft' : 'Save changes'}
                        onClick={() => {
                          onSubmit(form.status)
                        }}
                        style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                        check
                      />
                      {form.status === 'draft' && (<Menu newSaveInvoice={(value) => { newSaveInvoice(value) }} newAproveInvoice={(value) => { newAproveInvoice(value) }}>
                        <Button
                          className='ml-3'
                          color='secondary'
                          style={{ bottom: '10px', right: '25px', width: '50px' }}
                          label={<IconOptions style={{ transform: 'rotate(90deg)' }} />}
                        />
                      </Menu>)}
                    </div>
                  </StickyButton>
                </div>
              </div>
            </Fragment>
          )
            : (
              <div className='px-7'>
                <Skeleton count={6} />
              </div>
            )
        }
      </Modal>
    </>
  )
}

export default EditInvoice
