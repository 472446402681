import React, {useState} from 'react'
import {
  ModalLayout,
  ListOption,
  Button,
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import {TabContent, TabPane, Collapse} from 'reactstrap'

const MIN_OPTIONS = 7
//~
const ScheduledMeetingModal = (props) => {
  const {hours, open, toggle, onClick, form, setForm, loading} = props
  
  //     showAlert(
  //       {
  //         text: 'Success',
  //         status: 'success',
  //       },
  //       dispatch,
  //     )
  //     showAlert(
  //       {
  //         text: err,
  //         status: 'error',
  //       },


  const parsedFramesArray = hours

  const [selected, setSelected] = useState(null)
  const [showMore, setShowMore] = useState(false)
  // useEffect(() => {
  //   setSelected(null)
  //   setShowMore(false)
  // }, [parsedFramesArray])

  const RenderOption = ({frame}) => {
    const from = frame
    const timeLabel = `${moment(from).format('ddd DD, MMM hh:mma')} to ${moment(from).add(30, 'minutes').format('hh:mma')}`
    return (
      <ListOption
        onClick={() => {
          setSelected(from)
          setForm({...form, date: moment(from).format("YYYY-MM-DD"), timeStart: moment(from).format("HH:mm")})
        }}
        className={`mb-2 p-0 py-2 px-2 ${from === selected ? 'selected' : ''} `}
        spanClass='text-truncate'
        title={timeLabel}
        label={timeLabel}
      />
    )
  }
  return (
    <ModalLayout
      className='modal-colored modal-centered white-modal'
      topHead={
        <span className='h3 text-title'>Schedule Meeting</span>
      }
      isOpen={open}
      toggle={() => {toggle(!open)}}
      // contentClassName={`h-auto`}
      disabledScrollAnimation
    >
      <div className='d-flex flex-column m-0 p-0'>
        <span className='mb-5'>Please select a day and time to meet for the meeting.</span>
        <div className='d-flex flex-column p-0 m-0 my-4'>
          <TabContent
            // activeTab={
            //   !loadOrientationHours &&
            //   !loadAllUsersCounseler &&
            //   parsedFramesArray
            //     ? 'show'
            //     : 'loading'
            // }
            activeTab={'show'}
          >
            <TabPane tabId='show'>
              {parsedFramesArray
                .filter((_, index) => index < MIN_OPTIONS)
                .map((frame, index) => {
                  return <RenderOption index={index} frame={frame} />
                })}
              {parsedFramesArray?.length > MIN_OPTIONS && (
                <>
                  <Collapse isOpen={showMore}>
                    {parsedFramesArray
                      .filter((_, index) => index >= MIN_OPTIONS)
                      .map((frame, index) => {
                        return <RenderOption index={index} frame={frame} />
                      })}
                  </Collapse>
                  <div className='d-flex flex-row justify-content-center'>
                    <span
                      className='btn-link'
                      onClick={() => {
                        setShowMore(!showMore)
                      }}
                    >
                      {showMore
                        ? 'See less'
                        : 'See more'
                      }
                    </span>
                  </div>
                </>
              )}
            </TabPane>
            <TabPane tabId='loading'>
              <Skeleton
                className='d-flex flex-column w-100 mb-2'
                count={5}
                height={40}
              />
            </TabPane>
          </TabContent>
          <div className='d-flex flex-column p-0 m-0 my-4'>
            <Button
              disabled={!selected}
              className='w-100 my-2'
              label='Schedule Meeting'
              color='primary'
              activity={loading}
              size='large'
              check
              onClick={() => {
                if (selected)
                  onClick(selected)
              }}
            />
        </div>

        </div>
      </div>

    </ModalLayout>
  )
}

export default ScheduledMeetingModal
