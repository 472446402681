import React, { useEffect, useState, useMemo } from 'react'
import {
  Avatar,
  IconUser,
  IconClose,
  IconOptions,
  ModalPushScroll,
  IconStudent,
  IconCountry,
  Modal
} from '@Knowledge-OTP/znk-ui-components'
import { Collapse, Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { omit } from 'lodash'
import './style.css'
import useTranslation from '../../../../i18n/useTranslation'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import { useDispatch, useSelector } from 'react-redux'

import useSummary from './useQueries/useSummary'
import Skeleton from 'react-loading-skeleton'
import Summary from './Summary'
import Timeline from './Timeline'
import CpTimeline from './CpTimeline'
import Plans from './Plans'
import Payments from './Payments'
import Learn from './Learn'
import Profile from './Profile'
import Notes from './Notes'
// tabs profile
import EditProfile from '../../../Users/Modal/changePersonal/index.js'
import SetStudentServicesRateModalForm from '../../../Users/Modal/Student/setStudentServicesRate'
import ChangeTimezone from './Profile/ChangeTimezone'
import ChangeNotificationsInner from '../../../Users/Modal/Student/ChangeNotifications/innerModal/index'
import ChangePassword from './Profile/ChangePassword'
import AddParentModal from '../../../Users/Modal/Student/AddParent'

// change notification settings
import ToggleReminder from '../../../Users/Modal/Student/ChangeNotifications/ToggleReminder'
import ToggleTimedReminder from '../../../Users/Modal/Student/ChangeNotifications/ChangeReminder'
import useCountries from '../../../../Model/Countries/List'
import useServices from '../../../../Model/Services/List'
import AddServiceCustom from '../../../Users/Components/addServiceCustom/index'
import StudentProfile from '../../../Users/Modal/Profile/full'
import ChangeStudentInfo from '../../../Users/Modal/Student/changeStudentInfo/index'
import ChangeFamilyInfo from '../../../Users/Modal/Student/EditFamily/index'
import moment from 'moment'
import ChangePaymentMethod from './Profile/ChangePaymentMethod'
import EditAvatar from '../../../../common/EditAvatar'

//Accounts info
import ViewAccounts from './Profile/ViewAccounts'

const InfoStudent = (props) => {
  const { refetch: refetchList } = props
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.student.infoStudentModal)
  const { isOpen, dataStudent, initPage, profilePage, full, tab } = reduxData
  
  const { translate } = useTranslation()
  //QUERIES
  const { data: countries } = useCountries({ limit: 0 })
  const { data: dataServices } = useServices({ limit: 0 })
  const capitalize = (str) => {
    if (!str) return ''
    return str.toString().charAt(0).toUpperCase() + str.toString().slice(1)
  }
  const [activeTab, setActiveTab] = useState(tab ?? 'summary')
  const [isReset, setIsReset] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
    const toggleCrop = () => {
    setOpenCrop(!openCrop)
  }
  useEffect(() => {
    if(initPage && !tab) setActiveTab(initPage)
  }, [initPage, tab])
  const [querySummary, { data: studentResume, loading: loadStudentResume, refetch }] = useSummary()
  useEffect(() => {
    if (isOpen && dataStudent?.id) {
      querySummary({
        variables: {
          id: dataStudent?.id,
          studentsUserIds: [dataStudent?.id],
          kind: [
            'lessonsPerWeek',
            'lessonsFixed'
          ],
          status: [
            'draft',
            'sent',
            'scheduled',
            'paid',
            'pause',
            'cancel',
            'resume'
          ]
        }
      })
    }
  }, [isOpen, dataStudent.id, querySummary])

  const userData = studentResume?.user
  const studentName = `${userData?.firstName} ${userData?.lastName}`
  const parentName =
    userData?.studentInfo?.parents?.length > 0
      ? `${userData?.studentInfo?.parents[0].firstName} ${userData?.studentInfo?.parents[0].lastName}` : ''
  const userEmail = (userData?.emails?.length) > 0 ? userData.emails[0].address : ''

  const toggleSideModal = () => { 
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.infoStudentModal',
      data: {
        ...reduxData,
        full: !full,
        profilePage: 'viewProfile'
      }
    })
  }
  const setActiveTabRight = (newPage) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.infoStudentModal',
      data: {
        ...reduxData,
        full: true,
        profilePage: newPage,
      }
    })
  }

  const toggle = () => {
    if (isOpen) {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'student.infoStudentModal',
        data: {
          ...reduxData,
          full: false,
          profilePage: 'viewProfile'
        }
      })
    }
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'student.infoStudentModal',
      data: {
        ...reduxData,
        isOpen: !isOpen
      }
    })
  }

  // funciones para cambiar configuración de notificaciones
  const editReminder = (reminderType, reminderKey, reminderValue) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.notificationToggle',
      data: {
        isOpen: true,
        reminderType,
        reminderValue,
        reminderKey,
        prevNotifs: notificationsSettings
      }
    })
  }
  const editTimedReminder = (reminderType, reminderKey, reminderValue) => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.notificationChange',
      data: {
        isOpen: true,
        reminderType,
        reminderValue,
        reminderKey,
        prevNotifs: notificationsSettings
      }
    })
  }
  const notificationsSettings = useMemo(() => {
    return userData && userData?.studentInfo && userData?.studentInfo?.notifications
      ? omit(userData?.studentInfo?.notifications, [
        '__typename',
        'lessonReminder.__typename'
      ])
      : {
        missedLesson: true,
        lessonsNotesReady: true,
        lessonReminder: { isAvailable: true, duration: 30 }
      }
  }, [userData])

  let navMenuDimensions = null
  let modalContentDimensions = null
  let modalHeaderDimensions = null
  const [profileMenuDimensions, setProfileMenuDimensions] = useState({})
  useEffect(() => {
    if(navMenuDimensions && modalContentDimensions && modalHeaderDimensions){
      setProfileMenuDimensions({
        //determina el alto que debe tener el menu de profile para que el listado de tabs
        // aparesca en la parte inferior de la pantalla.
        minHeight: `${parseInt(modalContentDimensions.height - modalHeaderDimensions.height - 2.5*navMenuDimensions.height)}px`
      })
    }
  }, [navMenuDimensions, modalContentDimensions, modalHeaderDimensions, isOpen])
  const getValues = (date, type) => {
    const month = moment(date).month()
    let year = moment(date).year()
    if (month >= 10 && month <= 12) {
      year += 1
    }
    const difY = year - moment().year()
    switch (type) {
      case 'class':
        return year.toString().substr(2, 2)
      default:
        return 12 - difY
    }
  }
  const falseFuntion = () => {}
  const onChangeAvatar = (file) => {
    setOpenCrop(true)
  }
  const fetch = async () => {
    await refetchList()
    setIsReset(isReset === null ? true : !isReset)
  }
  return (
    <>
      {
        <Modal
          className='modal-centered medium modal-colored white-modal pr-3 pl-3 '
          isOpen={openCrop}
          toggle={toggleCrop}
          backdrop='static'
          style={{height: '95%'}}
          StyleModalBody={{height: '100%'}}
          classModalBody='contentHeight'
          Head={
            <div className='modal-header p-0 m-0'>
              <div className='d-flex justify-content-between w-100 pt-7 pb-4 px-7'>
                <h2 className='font-weight-bold'>{translate('profile.actions.changeAvatar.title')}</h2>
                <IconClose onClick={toggleCrop} className='text-gray' />
              </div>
            </div>
          }
        >
          <EditAvatar userId={dataStudent?.id } fetch={fetch} toogle={toggleCrop}/>
        </Modal>
      }
      <ModalPushScroll
        buttonLabel={null}
        className='modal-colored modal-sidebar modal-desktop-half fixed-head-modal input-modal'
        isOpen={isOpen}
        toggle={toggle}
        disabledScrollAnimation={activeTab === 'profile'}
        refContent={(el) => {
          if (!el) return
          modalContentDimensions = el.getBoundingClientRect()
        }}
        MinHead={
          <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
            <div className='d-flex flex-row align-items-center'>
              {
                (loadStudentResume)
                  ? (
                    <>
                      <Skeleton circle count={1} height={50} width={50} /> <Skeleton count={1} width={50} height={25} className='ml-3' />
                    </>
                  )
                  : (
                    <>
                      <Avatar
                        name={studentName}
                        uri={userData?.avatar ?? null}
                        innerDiv
                        size='avatar-small'
                        activateChange={true}
                        onClickImage={onChangeAvatar}
                        isReset={isReset}
                      /> <span className='h4 text-title ml-2'>{studentName}</span>
                    </>
                  )
              }
            </div>
            <div className='d-flex flex-row align-items-center'>
              <IconOptions color='#D7E1EA' onClick={() => { }} className='mr-2' style={{ transform: 'rotate(90deg)' }} />
              <IconClose color='#D7E1EA' onClick={toggle} />
            </div>
          </div>
        }
        Head={
          <div
            className='modal-header px-6 px-sm-7 d-flex flex-column pt-4 mt-0'>
            <div
              ref={(el) => {
                if (!el) return
                modalHeaderDimensions = el.getBoundingClientRect()
              }}
              className='d-flex flex-row justify-content-between w-100'>
              {
                (loadStudentResume)
                  ? (
                    <div className='d-flex flex-column w-100'>
                      <Skeleton duration={2} circle count={1} height={120} width={120} />
                      <Skeleton duration={2} count={1} height={40} className='w-100' />
                      <Skeleton duration={2} count={4} height={20} className='w-100' />
                    </div>
                  )
                  : (
                    <div className='d-flex flex-column'>
                      <Avatar
                        name={studentName}
                        uri={userData?.avatar ?? null}
                        innerDiv
                        size='avatar-extra'
                        activateChange={true}
                        onClickImage={onChangeAvatar}
                        isReset={isReset}
                      />
                      <div className='mt-5 mb-3 d-flex flex-column'>
                        <span className='h2 font-weight-bold text-title'>{studentName}</span>
                        <span className='h45 text-gray mt-2'>{userEmail}</span>
                        <span className='h45 text-gray my-2'>
                          {
                                        `${capitalize(userData?.studentInfo?.studentType)} |  
                                        Facebook  |  
                                        ${userData?.isActive ? capitalize('active') : capitalize('inactive')}  | 
                                        Class of ‘${getValues(userData?.studentInfo?.graduationDate, 'class')}`
                          }
                        </span>
                        <span className='h45 d-flex flex-row mb-2' style={{ whiteSpace: 'nowrap' }}>
                          <span className='d-flex flex-row align-items-center text-gray'><IconStudent className='mr-2' size={12} color='#35425B' />{getValues(userData?.studentInfo?.graduationDate, 'grade')}°</span>
                          {
                            userData?.studentInfo?.studentType === 'family' &&
                            (<span className='d-flex flex-row align-items-center text-gray mx-3'><IconUser className='mr-2' size={12} color='#35425B'/>{parentName}</span>)
                          }
                          <span className='d-flex flex-row align-items-center text-gray'><IconCountry className='mr-2' size={12} color='#35425B' />{countries && userData ? countries.docs.filter(e => e.id === userData?.countryId)[0]?.name : ''}</span>
                        </span>
                        <div className='d-flex flex-row mt-4'>
                          <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-1'>
                            <IconUser className='mr-1' size={12} />Profile
                          </Badge>
                          <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-1 mx-2'>
                            <IconUser className='mr-1' size={12} />Course material
                          </Badge>
                          <Badge className='badge badge-pill badge-empty d-flex flex-row align-items-center py-0 font-weight-bold'>+</Badge>
                        </div>
                      </div>
                    </div>
                  )
              }
              <div className='d-flex flex-row'>
                <IconOptions color='#D7E1EA' onClick={() => { }} className='mr-2' style={{ transform: 'rotate(90deg)' }} />
                <IconClose color='#D7E1EA' onClick={toggle} />
              </div>
            </div>
            <div className='m-0 p-0 w-100'>
              <Collapse isOpen={activeTab === 'profile'}>
                <Profile
                  style={profileMenuDimensions}
                  openSideModal={full}
                  toggleSideModal={toggleSideModal}
                  setActiveTabRight={setActiveTabRight}
                  userData={userData}
                />
              </Collapse>
            </div>
          </div>
        }
        sideLeft={
          <div className='pl-6 pl-sm-7 m-0 pt-4 pr-0'>
            <div
              ref={(el) => {
                if (!el) return
                navMenuDimensions = el.getBoundingClientRect()
              }}
              className='znk-scroll-horizontal'
              style={{ overflowX: 'scroll' }}>
              <Nav className={'w-100 flex-nowrap'}>
                <NavItem className='nav-title mb-2 mr-2' active={activeTab === 'summary'}>
                  <NavLink onClick={() => { setActiveTab('summary') }}>
                    {translate('student.modal.summary.title')}
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'learn'}>
                  <NavLink onClick={() => { setActiveTab('learn') }}>
                    {translate('student.modal.learn.title')}
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'notes'}>
                  <NavLink onClick={() => { setActiveTab('notes') }}>
                    {translate('student.modal.notes.title')}
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'plans'}>
                  <NavLink onClick={() => { setActiveTab('plans') }}>
                    {translate('student.modal.plans.title')}
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'timeline'}>
                  <NavLink onClick={() => { setActiveTab('timeline') }}>
                    Live Prep Timeline
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'cp-timeline'}>
                  <NavLink onClick={() => { setActiveTab('cp-timeline') }}>
                  {translate('student.modal.timeline.title')}
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'payments'}>
                  <NavLink onClick={() => { setActiveTab('payments') }}>
                    {translate('student.modal.payments.title')}
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'profile'}>
                  <NavLink onClick={() => { setActiveTab('profile') }}>
                    {translate('student.modal.profile.title')}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={activeTab} className='px-2'>
              <TabPane tabId='summary'>
                <Summary loading={loadStudentResume} studentResume={studentResume} />
              </TabPane>
              <TabPane tabId='timeline'>
                <Timeline studentId={dataStudent?.id} />
              </TabPane>
              <TabPane tabId='cp-timeline'>
                <CpTimeline studentId={dataStudent?.id} />
              </TabPane>
              <TabPane tabId='plans'>
                <Plans page={activeTab} idStudent={dataStudent?.id} />
              </TabPane>
              <TabPane tabId='payments'>
                <Payments page={activeTab} idStudent={dataStudent?.id} paymentMethod={userData?.paymentMethod} />
              </TabPane>
              <TabPane tabId='learn'>
                <Learn page={activeTab} idStudent={dataStudent?.id} />
              </TabPane>
              <TabPane tabId='profile' />
              <TabPane tabId='notes'>
                <Notes className={'pr-6'} page={activeTab} studentId={dataStudent?.id} />
              </TabPane>
            </TabContent>
          </div>
        }
        sideRight={
          <TabContent activeTab={profilePage} className='px-2'> 
            <TabPane tabId='editProfile'>
              {/**<FullProfile user={userData} type={'student'} toggleRightSide={toggleSideModal}/>**/}
              <EditProfile
                countries={countries}
                type='student'
                toggleRightSide={toggleSideModal}
                actionAfterUpdate={() => {
                  //acción que se ejecuta luego de actualizar la información del usuario
                  //actualiza summary
                  refetch()
                  //actualiza listado de usuarios
                  if(refetchList) refetchList()
                  //cierra lado derecho del modal
                  setActiveTabRight('viewProfile')
                }}/>
            </TabPane>
            <TabPane tabId='viewProfile'>
              <StudentProfile
                user={userData}
                type='student'
                toggleRightSide={toggleSideModal}
                afterEditPersonalAction={() => {setActiveTabRight('editProfile')}}
                afterEditStudentInfoAction={() => {setActiveTabRight('editStudentInfo')}}
                afterEditFamilyInfoAction={() => {setActiveTabRight('editFamilyInfo')}}
                refetch={() => {
                  refetch()
                }}
                refetchList={refetchList || falseFuntion}
              />
            </TabPane>
            <TabPane tabId='editStudentInfo'>
              <ChangeStudentInfo
                toggleRightSide={toggleSideModal}
                actionAfterSubmit={() => {
                  if(refetchList) refetchList()
                  setActiveTabRight('viewProfile')
                }}
                refetchUser={() => {
                  refetch()
                }}/>
            </TabPane>
            <TabPane tabId='editFamilyInfo'>
              <ChangeFamilyInfo
                toggleRightSide={toggleSideModal}
                actionAfterSubmit={() => {
                  if(refetchList) refetchList()
                  setActiveTabRight('viewProfile')
                }}
                refetchUser={() => {
                  refetch()
                }}/>
            </TabPane>
            <TabPane tabId='setServiceRate'>
              <SetStudentServicesRateModalForm 
                toggleRightSide={toggleSideModal}
                userId={userData?.id} />
            </TabPane>
            <TabPane tabId='notificationSettings'>
              <ChangeNotificationsInner
                notificationsSettings={notificationsSettings}
                editReminder={editReminder}
                editTimedReminder={editTimedReminder}
                styles={{}}
                toggle={toggleSideModal}
              />
            </TabPane>
            {/**<TabPane tabId='changePaymentMethod'>
              <div>changePaymentMethod</div>
              </TabPane>**/}
            <TabPane tabId='changeTimezone'>
              <ChangeTimezone
                currentTimezone={userData?.timezone}
                toggle={toggleSideModal}
                userId={userData?.id}
              />
            </TabPane>
            <TabPane tabId='viewAccounts'>
              {activeTab === 'profile' && <ViewAccounts
                // currentTimezone={userData?.timezone}
                toggle={toggleSideModal}
                userId={userData?.id}
                user={userData}
              />}
            </TabPane>
            <TabPane tabId='changePassword'>
              <ChangePassword toggle={toggleSideModal} />
            </TabPane>
            <TabPane tabId='changePaymentMethod'>
              {profilePage === 'changePaymentMethod' &&
              <ChangePaymentMethod
                userData={userData}
                toggle={toggleSideModal}
                refetch={refetch}
                // userId={userData?.id}
              />
              }
            </TabPane>
          </TabContent>
        }
        overRight={null}
        more={full}
      />
      <ToggleReminder refetch={() => {
        refetch()
        }} userId={userData?.id} />
      <ToggleTimedReminder refetch={() => {
        refetch()
      }} userId={userData?.id} />
      <AddServiceCustom services={dataServices?.docs || []} />
      <AddParentModal />
    </>
  )
}

export default InfoStudent
