import React, { useState } from 'react'
import PropTypes from 'prop-types'
import usePaginatedLessons from '../../../../Model/Lessons/List/ByPlanId'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import useServices from '../../../../Model/Services/List'
import Resume from './Resume'
import CheckInsSection from '../scheduled/CheckIns'
import ActivitySection from './Activity'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import * as moment from 'moment'
import useTranslation from '../../../../i18n/useTranslation'

const SectionKey = {
  resume: 'resume',
  activity: 'activity',
  checkIn: 'check-in' 
}


const InfoModalDraft = (props) => {
  const { info, topicKeys, groupInfo } = props
  const { translate } = useTranslation()
  const { data: services } = useServices({ limit: 0 })
  const { data: lessons, loading } = usePaginatedLessons({
    planId: info.id,
    status: ['draft'],
    limit: 20
  })
  const parsedLessons = !isEmpty(lessons) && lessons.totalDocs
    ? lessons.docs.map(item => {
      return {
        ...item,
        status: startCase(item.status),
        lessonTime: moment(item.startDate),
        lessonEndTime: moment(item.endDate),
        attendants: [
          { name: `${item?.educator?.firstName} ${item?.educator?.lastName}` }, 
          { name: `${item?.student?.firstName} ${item?.student?.lastName}` }
        ]
      }
    }).sort((a, b) => a.lessonTime.valueOf() - b.lessonTime.valueOf())
    : []
  const [activeTab, setActiveTab] = useState('resume')

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  return (
    <div className='px-4 mt-4 d-flex flex-column'>
      <Nav className='w-100 znk-scroll-horizontal flex-nowrap'>
        <NavItem className='nav-title mb-2 mr-2' active={activeTab === SectionKey.resume}>
          <NavLink
            onClick={() => {
              toggle(SectionKey.resume)
            }}
          >
            {translate('modal.navbar.resume')}
          </NavLink>
        </NavItem>
        <NavItem className='nav-title mr-2' active={activeTab === SectionKey.activity}>
          <NavLink
            onClick={() => {
              toggle(SectionKey.activity)
            }}
          >
            {translate('modal.navbar.activity')}
          </NavLink>
        </NavItem>
        <NavItem className='nav-title mr-2' active={activeTab === SectionKey.checkIn}>
          <NavLink
            onClick={() => {
              toggle(SectionKey.checkIn)
            }}
          >
            {translate("program.plan.infoModal.checkInSection")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className='px-4'>
        <TabPane tabId={SectionKey.resume}>
          <Resume services={services} topicKeys={topicKeys} info={info} groupInfo={groupInfo} lessons={parsedLessons} lessonsLoading={loading} />
        </TabPane>
        <TabPane tabId={SectionKey.activity}>
          <ActivitySection topicKeys={topicKeys} info={info} lessons={parsedLessons} lessonsLoading={loading} />
        </TabPane>
        <TabPane tabId={SectionKey.checkIn}>
          <CheckInsSection plan={info} refetch={() => {}} />
        </TabPane>
      </TabContent>
    </div>
  )
}
InfoModalDraft.propTypes = {
  lessons: PropTypes.array
}
InfoModalDraft.defaultProps = {
  lessons: []
}
export default InfoModalDraft
