import React, {useState, useMemo} from 'react'
import {useQuery, gql} from '@apollo/client'
import StudentsOnTimezone from './modal/StudentsOnTimezone'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment-timezone'

const TimezoneDTS = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.developer.studentsOnTimezone)
    const [startWeek, setStartWeek] = useState(moment().startOf('isoWeek').toISOString())
    const week = useMemo(() => {
        return new Array(7).fill(0).map((_, index) => {
            return moment(startWeek).clone().utc().add(index, 'day') //utc
        })
    }, [startWeek])
    const {data} = useQuery(
        gql`
            query {
                allTimezoneInZinkerz
            }
        `
    )
    return (
        <div className={`d-flex flex-column`}>
            <div className={`d-flex flex-row`}>
                <span className={`btn-link mr-2`} onClick={() => {
                        setStartWeek(moment(startWeek).add(-1, 'week').toISOString())
                    }}>Back</span>
                <span className={`btn-link`} onClick={() => {
                    setStartWeek(moment(startWeek).add(1, 'week').toISOString())
                    }}>Next</span>
            </div>
            {
                week.map((day) => {
                    return (
                        <div className={`d-flex flex-column`}>
                            <span className={'text-title'}>{day.clone().utc().format('YYYY-MM-DD')}</span>
                            {
                                (data?.allTimezoneInZinkerz || []).filter((timezone) => timezone?.length > 0).filter((timezone) => {
                                    let before = moment(`${day.clone().utc().add(-60*24, 'minutes').format('YYYY-MM-DD')}`).tz(timezone).isDST()
                                    let after = moment(`${day.clone().utc().format('YYYY-MM-DD')}`).tz(timezone).isDST()
                                    return before !== after  
                                })
                                    .map((timezone) => {
                                        const before = moment(day.clone().utc().add(-60*24, 'minutes').format('YYYY-MM-DD')).tz(timezone).utcOffset()
                                        const after = moment(day.clone().utc().format('YYYY-MM-DD')).tz(timezone).utcOffset()
                                        return (
                                            <span onClick={
                                                () => {
                                                    const appendsHours = 1 // (before/60) - (after/60)
                                                    console.log("append hours => *** ", appendsHours)
                                                    dispatch({
                                                        type: SET_DATA_MODAL_FORM_LIST,
                                                        payload: 'developer.studentsOnTimezone',
                                                        data: {
                                                            ...reduxData, 
                                                            isOpen: true, 
                                                            timezone, 
                                                            changeDate: day.clone().utc().format('YYYY-MM-DD'),
                                                            appendsHours
                                                        }
                                                    })
                                                }
                                            }>{`${timezone} // ${before/60} // ${after/60}`}</span>
                                        )
                                    })
                            }
                        </div>
                    )
                })
            }
            <StudentsOnTimezone/>
        </div>
    )
}

export default TimezoneDTS