import React from 'react'
import {
  Field,
  PlainTextInput,
  SelectOption
} from '@Knowledge-OTP/znk-ui-components'
import InputLpw from './InputLpw'
import moment from 'moment'
import useTranslation from '../../../../../../../i18n/useTranslation'

const ScheduleDetails = (props) => {
  const { setForm, form, currentService, disabled = false } = props
  const { translate } = useTranslation()
  const numParts = Object.keys(form.lpw).length
  const addPart = () => {
    setForm(pre => ({ ...pre, lpw: { ...form.lpw, [numParts]: { partname: `Part ${numParts + 1}` } } }))
  }

  function removePart(item) {
    let nums = 0
    const newLpw = Object.values(form.lpw).reduce((obj, part, idx) => {
      if (idx !== item) {
        obj[nums] = part
        obj[nums].partname = `Part ${nums + 1}`
        nums += 1
      }
      return obj
    }, {})
    setForm(pre => ({ ...pre, lpw: newLpw }))
  }

  return (
    <>
      <div className='py-6'>
        <h3><b>{translate('modal.newplan.scheduledetails.title')}</b></h3>
        <div className='row p-0 m-0'>
          {
            form.typePlan === 'time' &&
              <>
                <label className='col-12 px-0 text-gray font-weight-light mb-2 mt-3'>
                  {translate('modal.newplan.scheduledetails.typeschedulinglesson')}
                </label>
                <Field
                  className='col-12 row'
                  type={SelectOption}
                  fieldName='typeLesson'
                  options={[
                    { id: 'totalLesson', text: 'Total' },
                    { id: 'lessonPerWeek', text: translate('modal.newplan.scheduledetails.perweek') }
                  ]}
                  StyleOptionClassName={{
                    width: '49%'
                  }}
                  StyleSelect={{
                    justifyContent: 'space-between'
                  }}
                  isDisabled={disabled}
                />
              </>
          }
        </div>
        <div className='row p-0 m-0'>
          {Object.keys(form.lpw).map((zero, item) => {
            return (
              <div className='w-100' key={item}>
                <label className='col-12 text-gray font-weight-light mb-2 mt-3 pl-0 d-flex align-items-center justify-content-between'>
                  <span>
                    {translate('modal.newplan.scheduledetails.partname')}
                  </span>
                  {
                    numParts > 1 && (
                      <span className='btn-link text-error' onClick={() => removePart(item)}>
                        {translate('modal.newplan.scheduledetails.removePart')}
                      </span>
                    )
                  }
                </label>
                <Field
                  className='col-12'
                  type={PlainTextInput}
                  fieldName={`lpw[${item}].partname`}
                  disabled={disabled}
                />
                {
                  (form.typePlan === 'time' && form.typeLesson === 'totalLesson')
                    ? (
                      <div className='col-12 p-0 mt-3'>
                        <label className='text-gray font-weight-light mb-2'>{translate('modal.newplan.startdate')}</label>
                        <Field
                          type={PlainTextInput}
                          fieldName={`lpw[${item}].startdate`}
                          placeholder={translate('modal.newplan.startdate')}
                          fieldType='date'
                          disabled={disabled}
                        />
                      </div>
                    ) : (
                      <div className='row d-flex m-0 mt-3 p-0'>
                        <div className='col-6 p-0'>
                          <label className='text-gray font-weight-light mb-2'>{translate('modal.newplan.startdate')}</label>
                          <Field
                            type={PlainTextInput}
                            fieldName={`lpw[${item}].startdate`}
                            placeholder={translate('modal.newplan.startdate')}
                            fieldType='date'
                            className='noRight'
                            disabled={disabled}
                          />
                        </div>
                        <div className='col-6 pl-1 pr-0'>
                          <label className='text-gray font-weight-light mb-2'>{translate('modal.newplan.enddate')}</label>
                          <Field
                            type={PlainTextInput}
                            fieldName={`lpw[${item}].enddate`}
                            placeholder={translate('modal.newplan.enddate')}
                            fieldType='date'
                            min={moment(form.lpw[item].startdate).format('YYYY-MM-DD')}
                            className='noLeft'
                            disabled={disabled}
                          />
                        </div>
                      </div>)
                }
                {/* {
                  form.typePlan === 'time' &&
                    <>
                      <label className='col-12 px-0 text-gray font-weight-light mb-2 mt-3'>
                        {translate('modal.newplan.scheduledetails.lesson')}
                      </label>
                      <Field
                        className='col-12 row'
                        type={SelectOption}
                        fieldName={`lpw[${item}].typeLesson`}
                        options={[
                          { id: 'totalLesson', text: 'Total' },
                          { id: 'lessonPerWeek', text: translate('modal.newplan.scheduledetails.perweek') }
                        ]}
                        StyleOptionClassName={{
                          width: '49%'
                        }}
                        StyleSelect={{
                          justifyContent: 'space-between'
                        }}
                      />
                    </>
                } */}
                {currentService
                  ? currentService.topics.map((topic) => {
                    return (
                      <InputLpw
                        key={topic.id}
                        title={translate('modal.newplan.scheduledetails.groupalPerWeek', { name: topic.name })}
                        type='Group'
                        disabled={disabled}
                        topic={topic} part={item} form={form} setForm={setForm} lock={false}
                      />
                    )
                  })
                  : null}
                {currentService
                  ? currentService.topics.map((topic) => {
                    return (
                      <InputLpw
                        key={topic.id}
                        title={
                          form.typePlan === 'time'
                            ? translate('modal.newplan.scheduledetails.totalLesson', { name: topic.name })
                            : translate('modal.newplan.scheduledetails.individualPerWeek', { name: topic.name })
                        }
                        type='Individual'
                        disabled={disabled}
                        topic={topic} part={item} form={form} setForm={setForm} lock={form.typePlan !== 'time'}
                      />
                    )
                  })
                  : null}
              </div>
            )
          })}
          {
            !disabled && (
              <label
                className='btn-link pt-3'
                onClick={addPart}
              >{translate('modal.newplan.scheduledetails.addnewpart')}
              </label>
            )
          }
        </div>
      </div>
    </>
  )
}

export default ScheduleDetails
