import React, { Suspense, lazy, useEffect, useState, useMemo } from 'react'
import {
    Table,
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useDesktopColumns from './Columns/useDesktopColumns'
import Pagination from '../../../../../../common/Pagination'
import useGetStudentGroupsByUniversity from '../../../../Model/useGetStudentGroupsByUniversity'
import './index.css'

const ChildRow = lazy(() => import('./ChildRow'))
const ROWSLIMIT = 10

const UniversitiesSection = (props) => {
    const {
        search: searchProp = null,
        filters = {},
    } = props
    const [query, setQuery] = useState({
        limit: ROWSLIMIT,
        page: 0,
        search: null
    })
    const { page } = query


    const columns = useDesktopColumns()
    // const [rows, setRows] = useState([])
    const { loading, data, refetch: refetchData } = useGetStudentGroupsByUniversity(
        { ...query, calculateTotal: page === 0 }, {fetchPolicy: 'no-cache'}
    )
    const { rows } = useMemo(
        () => {
            if (data?.getStudentGroupsByUniversity) {
                const { docs } = data?.getStudentGroupsByUniversity
                return { rows: docs }
            }
            return { rows: [] }
        }, [data]
    )

    //cantidad total de documentos
    const [total, setTotal] = useState(-1)
    useEffect(() => {
        const totalAux = data?.getStudentGroupsByUniversity?.total
        if (totalAux !== -1 && totalAux) {
            setTotal(totalAux)
        }
    }, [data])




    // const refetchData = () => {
    //     // setAuxLoading(true)
    //     refetch()
    //     // if (page === 0) {
    //     //     setQuery({ ...query, calculateTotal: page === 0 })
    //     // } else {
    //     //     setQuery({ ...query, page: 0 })
    //     // }
    // }


    useEffect(() => {
        //reejecuta la query
        setQuery({
            ...query,
            ...filters,
            search: searchProp, page: 0
        })
        // eslint-disable-next-line
    }, [searchProp, filters])

    const totalPages = useMemo(
        () => {
            return Math.ceil(total / (query.limit || ROWSLIMIT))
            //eslint-disable-next-line
        }, [total, query?.limit]
    )

    return (
        <div className={`h-100 d-flex flex-column justify-content-between`}>
            <div>
                <TabContent activeTab={loading ? 'loading' : 'show'}>
                    <TabPane tabId={`show`}>
                        <Table
                            className={`cursor-pointer`}
                            loading={false}
                            columnList={columns}
                            tableData={rows}
                            collapseRender={(props) => {
                                return (
                                    <Suspense fallback={<Skeleton />}>
                                        <ChildRow refetch={() => { refetchData() }} items={props} />
                                    </Suspense>
                                )
                            }}
                            onClick={(value) => { }}
                        />
                    </TabPane>
                    <TabPane tabId={`loading`}>
                        <Skeleton count={query?.limit || ROWSLIMIT} height={40} />
                    </TabPane>
                </TabContent>
            </div>

            <Pagination
                bottomFix={true}
                pageSize={query.limit}
                size='large' //large, small
                changeSize={(newLimit) => { setQuery({ ...query, limit: newLimit || query?.limit || ROWSLIMIT }) }}
                clickNext={() => {
                    if (query.page + 1 < totalPages) {
                        setQuery({ ...query, page: query.page + 1 })
                    }
                }}
                clickPrevious={() => {
                    if (query.page > 0) {
                        setQuery({ ...query, page: query.page - 1 })
                    }
                }}
                hasPrevious={query.page > 0}
                hasNext={query.page + 1 < totalPages}
                activePage={(query?.page || 0) + 1}
                pageCount={totalPages}
                loading={loading}
            />
        </div>
    )
}

export default UniversitiesSection