import React from 'react'
import {
  Modal
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import setLessonToPending from '../../../../Model/Lessons/SetLessonToPending/index'
import useTranslation from '../../../../i18n/useTranslation'
import { showAlert } from '../../../../common/Alert/util'

const SetPendingLesson = (props) => {
  const { refetchPendingLessons } = props
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const { isOpen, lessonId } = useSelector((state) => state.modal.plan.setPendingLesson)
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.setPendingLesson',
      data: {
        isOpen: !isOpen,
        lessonId
      }
    })
  }

  const modalInfoData = useSelector((state) => state.modal.plan.infoLessonModal)
  // scheduleNewLessonFromAdmin
  // para lecciones grupales => scheduleNewLessonGroupFromAdmin
  const { submit: setLessonInPending } = setLessonToPending(
    () => {
      toggle()
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.infoLessonModal',
        data: {
          ...modalInfoData,
          isOpen: false
        }
      })
      refetchPendingLessons()
    },
    (error) => {
      showAlert({
        text: error.toString(),
        status: 'error'
      }, dispatch)
    }
  )

  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className='row p-0 m-7'>
        <span className='h2 m-0 pr-5'>
          <b>{translate('modal.setpendinglesson.title')}</b>
        </span>
        <p className='m-0 p-0 mt-5'>{translate('modal.setpendinglesson.body')}</p>
        <div className='m-0 p-0 w-100 d-flex justify-content-end mt-5'>
          <div className='w-40 d-flex flex-column justify-content-end'>
            <button
              className='btn btn-link p-0 mx-3 my-2'
              style={{ textAlign: 'right' }}
              onClick={() => {
                setLessonInPending({ lessonId })
              }}
            >
              {translate('modal.setpendinglesson.okleave')}
            </button>
            <button
              className='btn btn-link p-0 mx-3 my-2'
              style={{ textAlign: 'right' }}
              onClick={() => {}}
            >
              {translate('modal.setpendinglesson.rescheduled')}
            </button>
            <button
              className='btn btn-link p-0 mx-3 my-2 text-muted'
              style={{ textAlign: 'right' }}
              onClick={toggle}
            >
              {translate('modal.setpendinglesson.dontdoanything')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SetPendingLesson
