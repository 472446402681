import React, {useState, useEffect, useCallback, useMemo} from 'react'
// import {toJpeg} from 'html-to-image'
import { Page, Text, View, Document, StyleSheet, PDFViewer, usePDF } from '@react-pdf/renderer'
import useUploadPdfBase64 from '../../../../../Model/useUploadPdfBase64.js'
import useUser from '../../../../../Model/useUser.js'
// import { jsPDF } from "jspdf";

// import * as fs from 'fs'
// import './useGetBuffer'
// import { saveAs } from 'file-saver';

// const fs = require('fs')

import axios from 'axios'
import moment from 'moment'


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '30px 10px'
    },
    section: {
        margin: 0,
        padding: 0,
        // flexGrow: 1
    },
    table: {
        width: 'auto', 
        display: 'flex', 
        flexDirection: 'column'
    },
    cell: {
        margin: 5,
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center',
        alignContent: 'center',
        alignSelf: 'center', 
        // width: 'auto'
        flexGrow: 1,
        fontSize: '8px'
        // fontFamily: 'Oswald',
        // //important
        // textAlign: 'justify',
        // width: '25%'
    },
    activity: {
        backgroundColor: 'rgb(238, 92, 92)', 
        margin: '10px', 
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        fontSize: '8px'
    },
    activitySummary: {
        backgroundColor: 'rgb(238, 92, 92)', 
        margin: '2px', 
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        fontSize: '8px'
    },
    cell1: {width: '20%'},
    cell2: {width: '15%'},
    cell3: {width: '15%'},
    cell4: {width: '15%'},
    cell5: {width: '15%'},
    cell6: {width: '20%'},
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-around'
    },
    tableHeader: {
        backgroundColor: 'white'
    },
    rowBody: {
        backgroundColor: 'rgb(247,248,252)',
        borderBottom: '1px solid #72809d'
    },
    column: {
        // flexGrow: 1,
        // width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    circle: {
        padding: 0, 
        margin: 0, 
        borderRadius: '50%', 
        backgroundColor: 'blue', 
        width: '9px', 
        height: '9px', 
        marginRight: '5px', 
        alignSelf: 'center'
    },
    h1: {
        fontSize: '20px',
        marginBottom: 5
    },
    h2: {
        fontSize: '15px',
        marginBottom: 5
    },
    h3: {
        fontSize: '10px',
        marginBottom: 5
    },
    bold: {
        fontWeight: 'bold'
    },
    header: {
        margin: '0px 30px'
    },
    redBox: {
        border: '1px solid red',
        borderRadius: 0,
        color: 'red',
        padding: 5
    }
});

const PDFViewerTimeline = (props) => {
    const { plan } = props
    const { studentUser: studentUserFromPlan } = plan

    //mutación para subir archivo al bucket en amazon S3
    const [uploadTimelinePDF] = useUploadPdfBase64(
        (r) => {
            alert("success")
        },
        (err) => {
            console.error(err)
        }
    )
    const {data: dataStudent} = useUser({
        id: studentUserFromPlan?.studentUserId
    })
    const studentUser = useMemo(() => dataStudent?.user || {}, [dataStudent])

    


    //algoritmo para tabla de costos
    const parseaEvents = (events = []) => {
        let summary = {}
        //Parsea los eventos separados por mes
        
        //Separa cada plan/evento en los meses que se desarrollan
        const parseEvents = events?.reduce(
            (array, event) => {
                if(event?.type === 'plan') {
                    //PLAN
                    // guarda nombre del plan en objeto summary
                    if(!Object.keys(summary).includes(event?.planId)) summary[event?.planId] = event?.planname || 'No defined'
                    //separa las partes del plan por mes
                    // recorre cada parte
                    const parts = Object.values(event?.lpw).reduce((newParts, {startdate, enddate, partname, ...topics}) => {
                        const ranges = []
                        const totalLessonPerWeek = Object.entries(topics).reduce((total, [_/**topic id**/, {value: lpw}]) => {
                            return total + lpw
                        }, 0)

                        let start = moment(startdate).utc().set({
                            minutes: 0, seconds: 0, milliseconds: 0
                        })
                        let end = moment(enddate).utc().set({
                            minutes: 0, seconds: 0, milliseconds: 0
                        })

                        while(start < end) {
                            const last = start.clone().month() === end.clone().month() 
                            ranges.push([
                                // inicio de rango
                                start.clone().format('YYYY-MM-DD'), 
                                //fin de rango
                                !last ? 
                                    start.clone().endOf('month').format('YYYY-MM-DD') : 
                                    end.clone().format('YYYY-MM-DD') 
                            ])
                            //siguiente iteración
                            start = start.endOf('month').add(1, 'day')
                        }
                        return newParts.concat(ranges.map(
                            (range) => {
                                // total de lecciones por semanas
                                const totalLpw = totalLessonPerWeek
                                // total de semanas en rango de tiempo
                                const totalWeeks = Math.ceil(moment.duration(moment(range[1]).diff(moment(range[0]))).asWeeks())
                                const totalLessons = totalLpw*totalWeeks
                                //valor por lección
                                const pricingRate = event?.pricingRate || 0
                                const typePlanPricing = event?.typePlanPricing
                                const isPrincingPerMonth = typePlanPricing === 'permonth'
                                return {
                                    // ...event,
                                    // planId: event?.planId,
                                    //nombre que aparece en columna activity
                                    name: `${event?.planname}`,
                                    type: 'plan',
                                    ranges: range,
                                    totalLpw,
                                    totalWeeks,
                                    totalLessons,
                                    pricingRate,
                                    typePlanPricing,
                                    comment: event?.comment || null,
                                    princingTotal: isPrincingPerMonth ? 
                                        pricingRate : pricingRate*totalLessons,
                                    //calculo aproximado del total, multiplicación literal de los
                                    // numeros que aparecen en el documento
                                    calculatePricingTotal: isPrincingPerMonth ? 
                                        Math.ceil(pricingRate) : Math.ceil(pricingRate)*Math.ceil(totalLessons) 
                                }
                            }
                        ))
                    }, [])

                    // console.log("parts", parts)
                    //agrupa las partes
                    //si hay un plan que tiene mas de una
                    // parte en un mismo mes, las junta (suma los costos)
                    // {
                    //     '01-2022': [...events],
                    //     '02-2022': []
                    // }
                    // const notGroup = false
                    const partsGroup = parts.reduce(
                        (obj, part) => {
                            const monthKey = moment(part.ranges[0]).format('YYYY-MM')
                            const isPrincingPerMonth = part?.typePlanPricing === 'permonth'
                            if(!Object.keys(obj).includes(monthKey)) {
                                return {
                                    ...obj,
                                    [monthKey]: {
                                        ...part,
                                        calculatePricingTotal: isPrincingPerMonth ? Math.ceil(part.pricingRate) : Math.ceil(part.totalLessons)*Math.ceil(part.pricingRate) 
                                    }
                                }
                            } else {
                                const old = obj[monthKey]
                                obj[monthKey] = {
                                    ...old,
                                    // ranges: range,
                                    totalLpw: old.totalLpw+part?.totalLpw,
                                    totalWeeks: old.totalWeeks > part?.totalWeeks ? old.totalWeeks : part?.totalWeeks,
                                    totalLessons: old.totalLessons+part?.totalLessons,
                                    // pricingRate: old.pricingRate+part?.pricingRate,
                                    princingTotal: isPrincingPerMonth ? old.princingTotal : old.princingTotal+part?.princingTotal,
                                    name: `${old.name} (+)`,
                                }
                                obj[monthKey].calculatePricingTotal = Math.ceil(obj[monthKey].totalLessons)*Math.ceil(obj[monthKey].pricingRate)
                                return obj
                            }
                        }, {}
                    )

                    // console.log("partsGroup",partsGroup)
                    return array.concat(Object.values(partsGroup))
                } else {
                    //EVENT & TEST
                    if(!Object.keys(summary).includes(event?.eventId)) summary[event?.eventId] = event?.name || 'No defined'
                    array.push(
                        {
                            ...event,
                            name: event?.name,
                            type: 'event',
                            ranges: [event?.date, event?.date],
                            totalLpw: 0,
                            pricingRate: 0,
                            totalWeeks: 0,
                            totalLessons: 0,
                            princingTotal: 0 ,
                            calculatePricingTotal: 0,
                            // comment: event?.comment || null,
                            // description: event?.description || null
                        }
                    )
                    return array
                }
                // return array
            }, [])

        // console.log("parseEvents", parseEvents)    
        

        //Ordena los items cronologicamente
        const sortEvents = parseEvents.sort((rangeA, rangeB) => {
            if (moment(rangeA.ranges[0]) < moment(rangeB.ranges[0])) return -1;
            if (moment(rangeA.ranges[0]) > moment(rangeB.ranges[0])) return 1;
            return 0;
        })

        //agrupa los items por mes
        //[{mes, eventos}, ...]
        // console.log("summary", summary)
        return {
            events: sortEvents.reduce((monthObj, event) => {
                const monthKey = moment(event?.ranges[0]).format('YYYY-MM')
                if( Object.keys(monthObj).includes(monthKey) ) {
                    //actualiza arreglo
                    monthObj[monthKey].events.push(event)
                } else {
                    //crea llave
                    monthObj = {
                        ...monthObj,
                        [monthKey]: {
                            month: event?.ranges[0],
                            events: [event] 
                        } 
                    }
                }
                return monthObj
            }, {}),
            summary: Object.values(summary)
        }

        // //junta las partes según plan
        // return monthEvents.reduce(
        //     () => {

        //     }, {}
        // )
    }

    //eventos agrupados por mes. Ademas incluye calculo aproximado de pago mensual.
    const {events, summary} = useMemo(() => parseaEvents(plan?.timeline), [plan])
    // console.log("events", events)
    
    
    const MyDocument = useCallback(
        ({events = [], summary = [], studentUser = null}) => {
            let currentYear = null
            //separa summary en subgrupos de máximo 50 caracteres
            const parsedSummary = summary.reduce((array, name) => {
                const lastGroupLength = array[array?.length-1].join('').length
                //cabe en el ultimo grupo
                if(lastGroupLength + name?.length <= 75) array[array?.length-1].push(name)
                //no cabe, se crea nuevo subgrupo
                else array.push([name])

                return array
            }, [[]])
            const studentName = studentUser ? `${studentUser?.firstName} ${studentUser?.lastName}` : 'No defined'
            return (
                <Document>
                    <Page size="A4" style={styles.page}>
                        <View style={{...styles.section, ...styles.header}}>
                            <View style={{...styles.section, marginBottom: '15px'}}>
                                <Text style={{...styles.h1, ...styles.bold}}>Zinkerz Live Prep Timeline</Text>
                                {/* TIMELINE */}
                                {/* <Image src={src} /> */}
                            </View>
                            <View style={{...styles.section, width: '100%', marginBottom: '15px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <View style={{width: '70%', display: 'flex', flexDirection: 'column'}}>
                                    <Text style={{...styles.h2, ...styles.bold}}>{studentName}</Text>
                                    <Text style={{...styles.h3}}>Country: {studentUser?.country?.name}</Text>
                                    <Text style={{...styles.h3}}>Graduation Date: {
                                        studentUser?.studentInfo?.graduationDate ? 
                                            moment(studentUser?.studentInfo?.graduationDate).format('MMMM YYYY') : 
                                            'No defined'
                                    }</Text>
                                </View>
                                <View style={{...styles.redBox, width: '30%'}}>
                                    <Text style={{...styles.cell}}>Disclaimer: The estimations below are based on projected start and end date(s) for the activities displayed in this proposal. Final numbers are subject to change and should be used as reference only.</Text>
                                </View>
                            </View>
                            <View style={{...styles.section, marginBottom: '15px'}}>
                                <Text style={styles.h2}>Test Summary</Text>
                                {
                                    parsedSummary.map((arrayNames) => {
                                        //máximo 50 caracteres por fila
                                        return( 
                                            <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'start'}}>
                                                {
                                                    arrayNames.map(
                                                        (name) => {
                                                            return (<Text style={{...styles.activitySummary}}>{name}</Text> )
                                                        }
                                                    )
                                                }
                                            </View>
                                        )
                                        
                                    })
                                }
                            </View>
                        </View>
                        <View style={styles.section}>
                            <View style={styles.table}>
                                {
                                    Object.values(events).map(
                                        ({month, events}) => {
                                            let showYearHeader = false
                                            if(currentYear !== moment(month).year()){ 
                                                currentYear = moment(month).year()
                                                showYearHeader = true
                                            }
                                            // if(currentYear !== moment(month).year()) currentYear = moment(month).year()
                                            return (
                                                <>
                                                    {
                                                        showYearHeader && 
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.cell, width: '20%'}}>{currentYear}</Text>
                                                            <View style={{...styles.table, width: '80%'}}>
                                                                <View style={{...styles.row, ...styles.tableHeader}}>
                                                                    <Text style={{...styles.cell, ...styles.cell1}}><span>Activities</span></Text>
                                                                    <Text style={{...styles.cell, ...styles.cell2}}><span>N° of Lessons per Week</span></Text>
                                                                    <Text style={{...styles.cell, ...styles.cell3}}><span>N° of Lessons per Month</span></Text>
                                                                    <Text style={{...styles.cell, ...styles.cell4}}><span>Price per Lesson</span></Text>
                                                                    <Text style={{...styles.cell, ...styles.cell5}}><span>Monthly Cost (approx)</span></Text>
                                                                    <Text style={{...styles.cell, ...styles.cell6}}><span>Comments</span></Text>
                                                                    {/* <Text style={styles.cell}>3</Text> */}
                                                                </View>
                                                            </View>
                                                        </View>
                                                    }
                                                    <View style={{...styles.row, ...styles.rowBody}}>
                                                        <View style={{...styles.cell, width: '20%', display: 'flex', flexDirection: 'row'}}>
                                                            <Text style={styles.circle} />
                                                            <Text style={{alignSelf: 'center'}}>{moment(month).format('MMM')}</Text>
                                                        </View>
                                                        <View style={{...styles.table, width: '80%'}}>
                                                            {
                                                                events.map((event) => {
                                                                    const {
                                                                        type,
                                                                        // ranges,
                                                                        totalLpw,
                                                                        // totalWeeks,
                                                                        name,
                                                                        totalLessons,
                                                                        pricingRate,
                                                                        princingTotal,
                                                                        calculatePricingTotal,
                                                                    } = event
                                                                    const isPlan = type === 'plan'
                                                                    return (
                                                                        <View style={styles.row}>
                                                                            <Text style={{...styles.cell, ...styles.cell1, ...styles.activity}}>{name}</Text>
                                                                            <Text style={{...styles.cell, ...styles.cell2}}>{isPlan ? Math.ceil(totalLpw) : ' - '}</Text>
                                                                            <Text style={{...styles.cell, ...styles.cell3}}>{isPlan ? Math.ceil(totalLessons) : ' - '}</Text>
                                                                            <Text style={{...styles.cell, ...styles.cell4}}>{isPlan ? `$${Math.ceil(pricingRate)}` : ' - '}</Text>
                                                                            <Text style={{...styles.cell, ...styles.cell5}}>{isPlan ? `$${Math.ceil(calculatePricingTotal || princingTotal)}` : ' - '}</Text>
                                                                            <Text style={{...styles.cell, ...styles.cell6}}>{event?.description || event?.comment || '-'}</Text>
                                                                        </View>
                                                                    )
                                                                })
                                                            }
                                                        </View>
                                                    </View>
                                                </>
                                            )
                                        }   
                                    )
                                }
                            </View>
                        </View>
                    </Page>
                </Document>
            )
        }, []
    )

    const [instance, updateInstance] = usePDF({ document: <MyDocument  events={events} studentUser={studentUser} summary={summary}/> });
    useEffect(() => {
        updateInstance({ document: <MyDocument events={events} studentUser={studentUser} summary={summary}/> })
    // eslint-disable-next-line
    }, [summary, studentUser, events])

    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    }

    const [hasFile, setHasFile] = useState(null)
    useEffect(
        () => {
            if(instance?.url) {
                axios.get(instance.url,  { responseType: 'blob' }
                    ).then(
                    (result) => {
                        getBase64(result?.data).then(
                            data => {
                                setHasFile(data)
                            }
                        );
                    },
                    (err) => {
                        console.error(err)
                    }
                )
            }
            // eslint-disable-next-line
        }, [instance?.url]
    )


    return (
        <>
            <PDFViewer className={'w-100'} style={{height: '500px'}}>
                <MyDocument events={events} studentUser={studentUser} summary={summary} />
            </PDFViewer>
            {/* <div className={'m-0 p-0'} style={{backgroundColor: 'white' }} ref={tableRef}> */}
                {/* <MyDocument events={events} studentUser={studentUser} summary={summary} /> */}
            {/* </div> */}
            {
            hasFile && false && <span className={`h45 btn-link`} onClick={() => {
                // console.log("hasFile", hasFile)
                uploadTimelinePDF({
                    variables: {
                        file: hasFile,
                        bucket: `media.zinkerz.com/counseling/${plan?.studentUser?.studentUserId}`
                    }
                })
            }}>Upload</span> }
        </>
    )
}



export default PDFViewerTimeline