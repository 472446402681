import React from 'react'
import moment from 'moment'
import {
    FileItem,
    RedRibbon,
} from '@Knowledge-OTP/znk-ui-components'
import { examColors } from './examColors'

const ExamComponent = (props) => {
    const {exam} = props
    const color = examColors[exam?.name?.split(" ")[0]] ||'purple'
    return (

        <div className='d-flex justify-content-between mb-7'>
            <div className='d-flex flex-row'

            >
                <FileItem
                    fileText={<div className='d-flex flex-column text-align-start'>
                        <RedRibbon
                            className='znk-ribbon-small ml-n4 mt-n2'
                            left='znk-ribbon-small left'
                            right=''
                        />
                        <span className='h5 text-white'>{exam?.name?.split(" ")[0]}</span>
                        <span className='h5 text-white'>{exam?.overall || '-'}</span>
                        <span className='h5 text-white'>{moment(exam?.examDate).format('MMM YYYY') }</span>
                    </div>
                    }
                    className='align-items-start justify-content-end'
                    color={color}
                    size='medium-plus'
                />
            </div>
        </div>

    )


}

export default ExamComponent