import React, { useContext } from 'react'
import { useLocation } from 'react-router'
import { get } from 'lodash'
import { ListItem } from '@Knowledge-OTP/znk-ui-components'
import EditKey from '../modals/EditKey'
import i18nContext from '../../../contexts/i18n'

const Lang = (props) => {
  const { setter } = useContext(i18nContext)
  const { strings } = props
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const selectedPath = params.get('key')
  if (selectedPath) {
    const shownValues = get(strings, selectedPath)
    return (
      <div className='d-flex flex-column pt-md-5 px-lg-5' style={{ flex: 1 }}>
        <span className='h2 mb-6'>Values on <span className='font-weight-bold'>{selectedPath}</span></span>
        {
          shownValues ? (Object.keys(shownValues).length ? Object.keys(shownValues).map((key) => {
            return (
              <EditKey
                path={`${selectedPath}.${key}`}
                value={shownValues[key]}
                setter={setter}
                className='modal-center'
              >
                <ListItem
                  Left={key}
                  Right={<span className='text-right'>{typeof shownValues[key] === 'string' ? shownValues[key] : 'function'}</span>}
                />
              </EditKey>
            )
          }) : 'Nothing found') : "Couldn't found that key"
        }
      </div>)
  }
  return (
    <div className='d-flex align-items-center justify-content-center' style={{ flex: 1 }}>
      No Lang selected
    </div>
  )
}
Lang.defaultProps = {
  langs: ['en', 'es'],
  strings: {}
}
export default Lang
