import React, { useEffect, useState } from 'react'
import {
  Modal,
  IconClose,
  Field,
  Form,
  InputSelect,
  PlainTextInput,
  StickyButton,
  Button,
  AmountInput,
  LabeledField,
  CheckboxOption
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment'
import useManualPayment from './setManualPayment'
import { showAlert } from '../../../../common/Alert/util'
import {escapeRegExp} from 'lodash'

const types = [
  { id: 'cash', name: 'Cash' },
  { id: 'check', name: 'Check' },
  { id: 'creditCard', name: 'Credit Card' },
  { id: 'debitCard', name: 'Debit Card' },
  { id: 'bankTransfer', name: 'Bank Transfer' },
  { id: 'payPal', name: 'PayPal' },
  { id: 'zelle', name: 'Zelle' },
  { id: 'other', name: 'Other' }
]
const NewRecord = (props) => {
  const { refetch, fullRefetch } = props
  const modalData = useSelector((state) => state.modal.payments.newRecord)
  const {amount} = modalData
  const { isOpen } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [form, setForm] = useState({})
  useEffect(() => {
    if (modalData) {
      setForm({
        invoiceId: modalData.invoiceId,
        paymentMethod: 'bankTransfer',
        amount: modalData.amount.toFixed(2),
        lastProcessedAt: moment(new Date()).format('YYYY-MM-DD'),
        sendReceipt: false
      })
    }
  }, [modalData])
  const toggle = () => {
    setForm({
      invoiceId: null,
      paymentMethod: 'bankTransfer',
      amount: '',
      lastProcessedAt: moment(new Date()).format('YYYY-MM-DD'),
      sendReceipt: false
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.newRecord',
      data: {
        isOpen: false,
        invoiceId: '',
        amount: 0
      }
    })
  }
  const { submit, loading, validationErrors,  } = useManualPayment((result) => {
    if (!result.data.setRecordManualPayment) {
      showAlert({text: translate('payments.addpayment.temperror'), status: 'error', onClick: () => {}}, dispatch)
      return
    }
    refetch()
    fullRefetch()
    toggle()
  }, (err) => {
    showAlert({text: err.toString(), status: 'error'}, dispatch)
    console.log('***', err)
  }) 
  const onSubmit = () => {
    const currentTime = moment().format('HH:mm:ss')
    const combinedDateTime = `${form.lastProcessedAt} ${currentTime}`
    form.lastProcessedAt = moment(combinedDateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    submit({ recordPayment: { ...form, amount: parseFloat(form.amount) } })
  }
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('payments.addnewrecord')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={form} onChange={setForm} validationErrors={validationErrors}>
          <div className='m-0 p-0 mb-2 pl-2 pr-2'>
            <div className='row d-flex m-0 p-0'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.addpayment.amount')}</label>
                <Field
                  className='col-12 placeholder-to-right'
                  component={AmountInput}
                  type={LabeledField}
                  fieldName='amount'
                  fieldType='number'
                  placeholder='0.00 '
                  addOn='$'
                  min='0'
                  max={amount}
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-4'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.addpayment.date')}</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='lastProcessedAt'
                  fieldType='date'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-4'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.addpayment.type')}</label>
                <Field
                  //className='col-12'
                  type={InputSelect}
                  fieldName='paymentMethod'
                  placeholder={translate('modal.newplan.selectservice')}
                  options={types && [].concat(types.map((serv) => ({ value: serv.id, label: serv.name })))}
                  InputClassName='w-100'
                  direction='up'
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                    return false
                  }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-4'>
              <div className='col-12 p-0'>
                <Field
                  type={CheckboxOption}
                  fieldName='sendReceipt'
                  checked={form.sendReceipt}
                  onChange={(e) => {
                    setForm({ ...form, sendReceipt: !form.sendReceipt })
                  }}
                  label={translate('payments.addpayment.send')}
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-6'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='success'
                      activity={loading}
                      className='col-12 aling-text-left'
                      label={translate('payments.addpayment.addpayment')}
                      onClick={() => { onSubmit() }}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default NewRecord
