import {
    Field,
    Form,
    LabeledField,
    PlainTextInput,
    Button,
    ModalLayout,
    SelectOption,
    InputSelect,
    CheckboxOption,
} from '@Knowledge-OTP/znk-ui-components'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { FormGroup, Label, TabContent, TabPane } from 'reactstrap'
import escapeRegExp from 'lodash/escapeRegExp'
import moment from 'moment-timezone'
import useTranslation from '../../../../i18n/useTranslation'
import Create from '../../Model/useCreateEducatorOrStaff'
import useCreateEducatorModal from './useCreateEducatorModal'
import { parseCountrySelector } from '../../../Parent/Utils/parseCountriesArray'
import Select from 'react-select'
import { showAlert } from '../../../../common/Alert/util'
import StaffPermissions from './Components/staffPermissions'

const CreateEducatorAndStaffModal = (props) => {
    // eslint-disable-next-line
    const { countries, shortcuts, refetch } = props
    const [permissionsToSubmit, setPermissionsToSubmit] = useState([])
    const role = shortcuts?.educators ? 'Educator' : 'Staff'
    const dispatch = useDispatch()
    // eslint-disable-next-line
    const { submit: createUser, loading: loadingCreateUser, data: dataCreateUser } = Create((result) => {
        showAlert({
            text: role +" "+ translate('modal.newEducator.success'),
            status: 'success'
        }, dispatch)
        refetch()
        toggle()
    }, (err) => {
        showAlert({
            text: err.toString(),
            status: 'error'
        }, dispatch)
    })
    // eslint-disable-next-line
    const { isOpen, toggle, ...formData } = useCreateEducatorModal()
    // eslint-disable-next-line
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        countryId: '',
        countryName: '',
        timezone: '',
        permissions: []
    })
    const [countriesList, setCountriesList] = useState([])
    const { translate } = useTranslation()

    useEffect(() => {
        setCountriesList(parseCountrySelector(countries?.docs))
    }, [countries])
    // eslint-disable-next-line
    const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: '#F0F4F8',
            // match with the menu
            borderRadius: 12,
            height: 44,
            // Overwrittes the different states of border
            borderColor: 'transparent',
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
                // Overwrittes the different states of border
                borderColor: 'transparent'
            }
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
        })
    }
    const enableSaveButton = () => {
        return (
            form?.firstName !== '' &&
            form?.lastName !== '' &&
            form?.email.match(regexEmail) &&
            form?.countryId !== '' &&
            form?.gender !== '' &&
            form?.timezone !== ''
        )
    }
    const createEducatorFunction = () => {
        let user = {
            firstName: form?.firstName,
            lastName: form?.lastName,
            email: form?.email,
            countryId: form?.countryId,
            gender: form?.gender,
            timezone: form?.timezone,
            // sendInvite: form?.sendInvite,
            
            // isAdmin: shortcuts?.staff
        }
        // createUser({ user: {user} })
        createUser({ user, 
            typeUser: shortcuts?.educators ? 'EDUCATOR' : 'SUPER_ADMIN' ,
            permissions: permissionsToSubmit,
            sendEmail: form?.sendInvite 
        }, 
            )
    }    

    return (
        <div className='w-100 py-5 px-8 text-title znk-scroll-horizontal'>
            <ModalLayout
                className='modal-centered'
                dimensionClassName='m-0 pr-5 py-3 pl-3'
                centerHeaderOverall='w-100'
                isOpen={isOpen}
                toggle={toggle}
                topHead={
                    <span className={`text-title font-weight-bold h2`}>New {role}</span>
                }
            >
                <TabContent activeTab={loadingCreateUser ? 'loading' : 'show'} className='px-2'>
                    <TabPane tabId='loading'>
                        <>
                            <div className='mt-8 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>
                                    <div className='col-6 pr-0'>
                                        <Skeleton count={1} width={150} height={44} />
                                    </div>
                                    <div className='col-6 pl-1'>
                                        <Skeleton count={1} width={150} height={44} />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>
                                    <Skeleton count={1} width={300} height={44} />
                                </div>
                            </div>
                            <div className='mt-5 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>
                                    <div className='col-6 pr-0'>
                                        <Skeleton count={1} width={150} height={44} />
                                    </div>
                                    <div className='col-6 pl-1'>
                                        <Skeleton count={1} width={150} height={44} />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>

                                    <Skeleton count={1} width={300} height={44} />

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 pr-1'>
                                    <Skeleton count={1} width={150} height={30} />
                                </div>
                                <div className='col-6 pr-1'>
                                    <Skeleton count={1} width={150} height={44} />
                                </div>
                            </div>
                            <div className='mt-5 mb-0'>
                                <Skeleton count={1} width={100} height={30} />
                                <div className='row'>
                                    <Skeleton count={1} width={300} height={44} />
                                </div>
                            </div>
                        </>
                    </TabPane>
                    <TabPane tabId='show'>
                        <Form value={form} onChange={setForm}>

                            <FormGroup className='mt-5 mb-0'>
                                <Label>{role} name</Label>
                                <div className='row'>
                                    <div className='col-6 pr-0'>
                                        <Field
                                            type={LabeledField}
                                            component={PlainTextInput}
                                            placeholder={translate(
                                                'profile.actions.editPersonalInfo.firstName'
                                            )}
                                            style={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0
                                            }}
                                            labelContainerClassName='mb-0'
                                            onChange={(e) => {
                                                setForm({
                                                    ...form,
                                                    firstName: e
                                                })
                                            }}
                                            value={form?.firstName}
                                        />
                                    </div>
                                    <div className='col-6 pl-1'>
                                        <Field
                                            type={LabeledField}
                                            component={PlainTextInput}
                                            placeholder={translate(
                                                'profile.actions.editPersonalInfo.lastName'
                                            )}
                                            style={{
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0
                                            }}
                                            labelContainerClassName='mb-0'
                                            value={form?.lastName}
                                            onChange={(e) => {
                                                setForm({
                                                    ...form,
                                                    lastName: e
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className='mt-2'>
                                <Label>
                                    {translate('profile.actions.editPersonalInfo.email')}
                                </Label>
                                <Field
                                    type={LabeledField}
                                    component={PlainTextInput}
                                    value={form?.email}
                                    placeholder={translate(
                                        'profile.actions.editPersonalInfo.email'
                                        )}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            email: e
                                        })
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className='mt-2'>
                                <Label>Gender</Label>
                                <Field
                                    className='row col-12'
                                    fieldName='gender'
                                    fieldType='date'
                                    type={SelectOption}
                                    options={[
                                        { id: 'male', text: 'Male' },
                                        { id: 'female', text: 'Female' },
                                        { id: 'other', text: 'Other'},
                                    ]}
                                    value={form?.gender}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            gender: e
                                        })
                                    }}
                                    OptionClassName='col-12'
                                />
                            </FormGroup>
                            <FormGroup className='mt-2'>
                                <Label>
                                    {translate('profile.actions.editPersonalInfo.country')}
                                </Label>
                                <Select
                                    styles={customStyles}
                                    options={countriesList}
                                    value={form?.countryName}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            countryId: e.value,
                                            countryName: e.name
                                        })
                                    }}
                                />
                            </FormGroup>

                            <FormGroup className='mt-2'>
                                <Label>
                                    {translate('modal.newplan.createstudent.timezone')}
                                </Label>

                                <Field
                                    type={LabeledField}
                                    component={InputSelect}
                                    className='col-12 p-0'
                                    fieldName='timezone'
                                    options={moment.tz.names().map((tz) => {
                                        return { value: tz, label: tz }
                                    })}
                                    filterOption={(obj, string) => {
                                        if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                                        return false
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className='mt-2'>
                                {shortcuts?.staff && <Label>
                                    {translate('modal.newplan.createstaff.permissions')}
                                </Label>}
                                {shortcuts?.staff && <StaffPermissions 
                                permissionsToSubmit={permissionsToSubmit} 
                                setPermissionsToSubmit={setPermissionsToSubmit}
                                />}
                            </FormGroup>
                            <FormGroup className='mt-2'> 
                                <div className='d-flex col-12'>
                                <Field
                                    type={CheckboxOption}
                                    fieldName='sendInvite'
                                    defaultState={form.sendInvite}
                                />
                                <label className='text-muted h45'>Send email invite?</label>
                                </div>

                            </FormGroup>
                            <Button
                                label={`Add New ${role}`}
                                color='primary'
                                check
                                size='medium'
                                onClick={() => { createEducatorFunction() }}
                                disabled={!enableSaveButton()}
                                activity={loadingCreateUser}
                                style={{ width: '100%' }}
                                className='mt-5'
                            />
                        </Form>
                    </TabPane>
                </TabContent>


            </ModalLayout>
        </div>
    )
}

export default CreateEducatorAndStaffModal
