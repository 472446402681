import React, {useMemo} from 'react'
import {
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import RenderInputOption from '../RenderInputOption'

const titleCase = (string = '') => {
    let sentence = string.toLowerCase().split("-");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    
    return sentence.join(" ");
  }
  

const SelectPhaseStep = (props) => {
    const {onChange, phase = null, step = null, plan} = props
    const phases = useMemo(
        () => {
            return plan?.tasks || []
        }, [plan]
    )
    const steps = useMemo(
        () => {
            return phases.find(({phaseName}) => phaseName === phase)?.steps || []
        }, [phase, phases]
    )

    console.log("steps***", steps)

    return (    
        <div className={`m-0 p-0 d-flex flex-row w-100`}>
            <RenderInputOption key={'name'} type={`text`} label={`Phase`} className={`w-50 mr-1 mt-0`} >
                <InputSelect 
                    name={`phase`}
                    value={phase}
                    type={`text`} 
                    placeholder={'Select phase'}
                    onChange={(e) => {
                        onChange(e, null)
                    }} 
                    options={
                        phases?.map(
                            ({phaseName}) => {
                                return {label: titleCase(phaseName), value: phaseName}
                            }
                        )
                    }
                /> 
            </RenderInputOption>
            <RenderInputOption key={'name'} type={`text`} label={`Step`} className={`w-50 ml-1 mt-0`} >
                <InputSelect 
                    name={`step`}
                    value={step}
                    type={`text`} 
                    placeholder={'Select step'}
                    onChange={(e) => {
                        const thisStep = steps.find(({id}) => id === e)
                        onChange(phase, e, thisStep?.tasks?.length)
                    }} 
                    options={
                        steps.map(
                            ({id, stepName}) => {
                                return {label: stepName, value: id}
                            }
                        )
                    }
                /> 
            </RenderInputOption>
        </div>
    )
}

export default SelectPhaseStep