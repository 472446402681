import React, {useState, useMemo, useEffect} from 'react'
import {
  Modal,
  IconClose,
  Button,
  IconTime,
  IconDate,
  LessonItem,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import GroupStudentItem from './GroupStudentItem'
import useExcelDownload from '../../../../common/DownloadExcel/useExcelDownload.js'
import moment from 'moment'
import useRemoveLessonFromAdminIncativeUser from '../../Model/useRemoveLessonFromAdminIncativeUser.js'

const ShowLessonsPending = (props) => {
    const {isOpen, toggle, students = [], handletAction, loading, type} = props
    const [users, setUsers] = useState(students ? students : [])
    useEffect(() => {setUsers(students)}, [students])
    const lessons =  users.reduce((acum, student) => {return acum.concat(student.lessons.map(e => {return  e.id}))}, [])
    const [totPending, totScheduled] = users.reduce((arr, student) => { 
        arr[0] += student.lessons.filter(e => e.status === 'pending').length
        arr[1] += student.lessons.filter(e => e.status === 'scheduled').length
        return arr
     }, [0, 0])
    const [selectedLessons, setSelectedLessons] = useState([])
    const { translate } = useTranslation()
    const exportToCSV = useExcelDownload()
    const parseList = (list) => {
        let items = []
        for (let index = 0; index < users.length; index++) {
            const student = students[index];
            items.push({
                studentId: student.studentUserId,
                firstName: student.firstName,
                lastName: student.lastName,
                title: ''
            })
            items.push({
                studentId: '',
                firstName: 'lessonId',
                lastName: 'startDate',
                title: ''
            })
            for (let l = 0; l < student.lessons.length; l++) {
                const lesson = student.lessons[l];
                items.push({
                    studentId: '',
                    firstName: lesson.lessonId,
                    lastName: moment(lesson.startDate).format('Do MMM HHa'),
                    title: lesson.title
                })
            }
        }
        return items
    }
    const allSelected = useMemo(() => {
        return lessons.length === selectedLessons.length
    }, [lessons, selectedLessons])
    const selectOrUnselectAll = () => {
        if(allSelected) {
            setSelectedLessons([])
        }
        else {
            setSelectedLessons(lessons)
        }
    }
    const { submit } = useRemoveLessonFromAdminIncativeUser(
        (resp) => {
            let auxUsers = []
            for (let index = 0; index < users.length; index++) {
                const user = users[index];
                const lessons = user.lessons
                const results = lessons.filter((item) => !selectedLessons.includes(item.id));
                if (results.length > 0) {
                    auxUsers.push({...user, lessons: results})
                }
            }
            setUsers(auxUsers)
            setSelectedLessons([])
        },
        (err)=> {
            console.log(JSON.stringify(err))
        }
    )
    return (
        <>
            <div className='m-0 p-0 w-100' onClick={toggle}>
                {props.children}
            </div>
            <Modal
                buttonLabel={null}
                className='modal-centered h-auto'
                isOpen={isOpen}
                toggle={toggle}
                Head={
                    <div className='modal-header px-2 d-flex flex-column'>
                        <div className='w-100 d-flex justify-content-between'>
                            <span className='text-title font-weight-bold h2'>{translate('users.actions.titlechangestatus')}</span>
                            <IconClose size={20} onClick={toggle} />
                        </div>
                        <span className='mt-2'>{translate('users.actions.subtitlechangestatus')}</span>
                    </div>
                }
                
            >
                <div className={`w-100 d-flex flex-column m-0 p-0`}>
                    <div className='w-100'>
                        <p
                            className='text-right text-cursor text-primary h45'
                            onClick={() => { exportToCSV(parseList(users), "Pending Lessons BY Student") }}>
                                Download List
                        </p>
                    </div>
                    <div className='w-100 d-flex flex-row justify-content-between'>
                        <p className='d-flex flex-row align-items-center'>
                            <div className='d-flex align-items-center'><IconTime size={14}/><span className='ml-2 text-gray h45'>{totPending}</span></div>
                            <div className='mx-2'>|</div>
                            <div className='d-flex align-items-center'><IconDate size={14}/><span className='ml-2 text-gray h45'>{totScheduled}</span></div>
                        </p>
                        <p
                            className='text-right text-cursor text-primary h45'
                            onClick={selectOrUnselectAll}>
                                {allSelected ? ' Unselect all' : ' Select all'}
                        </p>
                    </div>
                </div>
                <div style={{maxHeight: 500, overflowY: 'auto', paddingBottom: 10}}>
                   
                    {
                        type === 'educator' && users?.map((element, index) => (
                            <GroupStudentItem 
                                item={element} key={`grstu-${index}`} 
                                selectedLessons={selectedLessons} 
                                setSelectedLessons={setSelectedLessons} 
                            />
                        ))
                    }
                    {
                        type === 'student' && users[0]?.lessons?.map((lesson, index) => (
                            <LessonItem
                                CustomIcon={lesson.status === 'pending' ? IconTime : IconDate}
                                key={`lesstu-${index}`}
                                dataLesson={lesson}
                                useCheck
                                checked={selectedLessons.includes(lesson.id)}
                                onChangeCheck={(bool) => {
                                    if (!selectedLessons.includes(lesson.id)) {
                                        setSelectedLessons(selectedLessons.concat([lesson.id]))
                                    } else {
                                        setSelectedLessons(selectedLessons.filter(id => id !== lesson.id))
                                    }
                                }}
                            />
                        ))
                    }
                </div>
                <div className='d-flex flex-row'>
                    <div
                        style={{
                            bottom: '0px',
                            position: 'sticky',
                            backgroundImage: `linear-gradient(
                                to bottom, 
                                rgba(255, 255, 255, 0), 
                                rgba(255, 255, 255, 0.95) 25%
                            )`,
                            paddingTop: '15px',
                        }}
                        className='w-50 d-flex flex-row py-2'
                    >
                        <Button
                            color='primary'
                            activity={loading}
                            className='w-90 mt-auto mb-0 mt-2'
                            label={'Force Inactive'}
                            onClick={() => {
                                console.log({ver: selectedLessons.length > 0})
                                handletAction(users.length > 0)
                            }}
                        //disabled={loading}
                        />
                    </div>
                    <div
                        style={{
                            bottom: '0px',
                            position: 'sticky',
                            backgroundImage: `linear-gradient(
                                to bottom, 
                                rgba(255, 255, 255, 0), 
                                rgba(255, 255, 255, 0.95) 25%
                            )`,
                            paddingTop: '15px',
                        }}
                        className='w-50 d-flex flex-row justify-content-center align-items-center py-2'
                    >
                        <Button
                            color='secondary'
                            activity={loading}
                            className='w-95 mt-auto mb-0 mt-2'
                            label={'Remove Lessons'}
                            onClick={() => {
                                submit({lessonIds: selectedLessons})
                            }}
                            disabled={!selectedLessons.length > 0}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ShowLessonsPending