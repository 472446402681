import React, { useMemo, useEffect } from 'react'
import {
    InputSelect, Input, Avatar,
    StickyButton, Switch,
    ScheduledCalendarForFinder
} from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import useAllUsers from '../../Model/useAllUsers'
import moment from 'moment'
import useMyFreeTimesFrames from '../../Model/useMyFreeTimesFrames'
import { useSelector } from 'react-redux'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import { useState } from 'react'

const Search = (props) => {
    const { form = {}, setForm = () => {}, className = '', onClick = () => { }, loading: loadEducatorFinder = false, defaultHideInputs = false, dateShortCuts = [/**{label: '', start: date, end: date}**/] } = props
    const timezone = useSelector((state) => state.user.timezone)
    const services = useSelector((state) => state.user.services)
    const {translate} = useTranslation()

    const { data, loading } = useAllUsers({
        state: true,
        roles: ["STUDENT"],
        limit: 0,
        // sortField: String
        // previous: String
        // next: String
        // ids: [ID!]
        // search: String
    })
    const studentOptions = (data?.allUsers?.docs || []).map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` }))


    const [query, setQuery] = useState({
        involveMyself: false,
        studentIds: form?.studentId ? [form.studentId] : [],
        educatorUserId: undefined,
        minDurationInMinutes: 50,
        from: form?.start ? new Date(form.start) : new Date(),
        to: form?.end ? new Date(form.end) : new Date()
    })
    useEffect(() => {
        setQuery({
            ...query,
            studentIds: form?.studentId ? [form.studentId] : [],
            from: form?.start ? new Date(form.start) : new Date(),
            to: form?.end ? new Date(form.end) : new Date()
        })
    },
    // eslint-disable-next-line
    [form?.start, form?.end, form?.studentId])
    
    const { data: freeFrames, loading: loadFrames } = useMyFreeTimesFrames(query)

    useEffect(() => {
        //blanquea bloques seleccionados en calendario
        setForm({...form, frames: []})
    }, 
    // eslint-disable-next-line
    [form?.start, form?.end, form?.studentId])


    // console.log("freeFrames =>", freeFrames?.myFreeTimeFrames)

    const loadingGrid = useMemo(() => {
        return loading || loadFrames || !form?.studentId || !form?.start || !form?.end
    }, [loading, loadFrames, form])


    const currentService = useMemo(
        () => (services || []).find(({ id }) => id === form?.serviceId), [form, services]
    )
    const currentTopic = useMemo(
        () => (currentService?.topics || []).find(({ id }) => id === form?.topicId), [form, currentService]
    )
    const currentUser = useMemo(
        () => ((data?.allUsers?.docs || []).find(({ id }) => form?.studentId === id)), 
        // eslint-disable-next-line
        [form, data?.allUsers?.docs]
    )

    const pushFrame = (newframe) => {
        const frames = form?.frames || []
        const includeFrame = !!frames.find((frame) => 
            moment(frame.time).toISOString() === moment(newframe.time).toISOString()
        )
        if(includeFrame) {
            setForm({
                ...form,
                frames: (form?.frames || []).filter(({time}) => moment(time).toISOString() !== moment(newframe.time).toISOString())
            })
        } else setForm({ ...form, frames: [...(form?.frames || []), newframe]})
    }

    const [hideInputs, setHideInputs] = useState(defaultHideInputs)




    return (
        <div className={`d-flex flex-column align-items-center ${className}`} style={{ gap: 15 }}>
            <>
                <div style={{gap: 10}} className='w-100 d-flex flex-row align-items-center justify-content-end'>
                    <span>Hide Inputs</span>
                    <Switch className={'mb-0'} initialState={hideInputs} onChange={() => {setHideInputs(!hideInputs)}}/>
                </div>
                {
                    hideInputs ?
                        <div className='d-flex flex-column align-items-start justify-content-center w-100' style={{ gap: 20 }}>
                            <label className='h4 text-info'>{`${currentService?.name} - ${currentTopic?.name}`}</label>
                        </div> :
                        <>
                            <div className='w-100 d-flex flex-column justify-content-between align-items-start'>
                                <label className='text-gray w-100'>{translate("modal.newplan.selecteducator.finder.service")}</label>
                                <InputSelect
                                    options={services.map(({ name, id }) => { return { label: name, value: id } })}
                                    onChange={(serviceId) => { setForm({ ...form, serviceId, topicId: null }) }}
                                    value={form.serviceId}
                                    PlaceholderSelect={translate("modal.newplan.selecteducator.finder.selectServicePlaceholder")} InputClassName={'w-100'} />
                            </div>
                            <div className='w-100 d-flex flex-column justify-content-between align-items-start'>
                                <label className='text-gray w-100'>{translate("modal.newplan.selecteducator.finder.topic")}</label>
                                <InputSelect
                                    value={form.topicId}
                                    options={(services.find(({ id }) => id === form?.serviceId) || { topics: [] }).topics.map(({ id, name }) => ({ value: id, label: name }))}
                                    onChange={(topicId) => { setForm({ ...form, topicId }) }} PlaceholderSelect={translate("modal.newplan.selecteducator.finder.selectTopicPlaceholder")} InputClassName={'w-100'} />
                            </div>
                        </>
                }


                {
                    hideInputs ?
                        <div className='d-flex flex-column align-items-start justify-content-center w-100' style={{ gap: 20 }}>
                            <div className='d-flex flex-row justify-content-start align-items-center' style={{ gap: 10 }}>
                                <Avatar
                                    name={currentUser ? `${currentUser?.firstName} ${currentUser?.lastName}` : `No Defined`}
                                />
                                <span>{currentUser ? `${currentUser?.firstName} ${currentUser?.lastName}` : `No Defined`} <small className='text-gray'>{`(${translate("modal.newplan.selecteducator.finder.student")})`}</small></span>
                            </div>
                        </div> :
                        <div className='w-100 d-flex flex-column justify-content-between align-items-start'>
                            <label className='text-gray w-100'>{translate("modal.newplan.selecteducator.finder.student")}</label>
                            <InputSelect
                                onChange={(studentId) => {
                                    setForm({ ...form, studentId })
                                }}
                                value={form?.studentId}
                                isLoading={loading}
                                options={studentOptions} PlaceholderSelect={translate("modal.newplan.selecteducator.finder.selectStudentPlaceholder")} InputClassName={'w-100'} />
                        </div>
                }



                <div className='w-100 borderTop'/>
                <div className='d-flex flex-row justify-content-between w-100' style={{ gap: 10 }}>
                    <div className='w-50 d-flex flex-column justify-content-between align-items-start'>
                        <label className='text-gray w-100'>{translate("modal.newplan.selecteducator.finder.start")}</label>
                        <Input onChange={(res) => {
                            const newdate = res.target.value
                            setForm({ ...form, start: newdate })
                        }} value={form.start} type={'date'} className={'w-100'} />
                    </div>
                    <div className='w-50 d-flex flex-column justify-content-between align-items-start'>
                        <label className='text-gray w-100'>{translate("modal.newplan.selecteducator.finder.end")}</label>
                        <Input onChange={(res) => {
                            const newdate = res.target.value
                            setForm({ ...form, end: newdate })
                        }} value={form.end} type={'date'} className={'w-100'} />
                    </div>
                </div>
                <div style={{overflowX: 'clip', gap: 10}} className='w-100 d-flex flex-row justify-content-start'>
                        {
                            dateShortCuts.map(
                                ({label, start, end}) => {
                                    return (
                                        <Badge
                                            color={'primary'}
                                            className='ml-0 mr-1 my-0 px-3 mr-1 p-0 cursor-pointer'
                                            pill
                                            onClick={() => {
                                                setForm({
                                                    ...form, 
                                                    start: moment(start).format('YYYY-MM-DD'), 
                                                    end: moment(end).format('YYYY-MM-DD')
                                                })
                                            }}
                                        >
                                            <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                                                {label}
                                            </label>
                                        </Badge>
                                        // <span
                                        //     className='h45 btn-link'
                                        //     onClick={() => {
                                                
                                        //     }}
                                        // >{label}</span>
                                    )
                                }
                            )
                        }
                </div>
                <div className='w-100 borderTop'/>
                
                
                <div className='d-flex flex-row justify-content-end w-100' style={{ gap: 10 }}>
                    <span className='btn-link text-error' onClick={() => {
                        setForm({...form, frames: []})
                    }}>{translate("modal.newplan.selecteducator.finder.clearAll")}</span>
                </div>
            </>

            <ScheduledCalendarForFinder
                disableTooltip
                loading={loadingGrid}
                // setSelected={(response) => {
                //     pushFrame(response)
                // }}
                freeMode
                setSelectedFreeMode={pushFrame}
                selectedDay={[]}
                educator={{}}
                checkedDays={form?.frames || []}
                myFreeTimeFrames={freeFrames?.myFreeTimeFrames}
                from={form.start ? moment(form?.start) : moment()}
                fromPart={form.start}
                toPart={form.end}
                zone={timezone}
                // lang={selectedLang}
                className='pb-6 pt-4'
                useSecondaryStyle
            // getIndexOfLesson={() => {}}
            />


            <StickyButton
                color='#696CFF'
                condition={true}
                disabled={false}
                className='w-100'
                label={translate("modal.newplan.selecteducator.finder.submit")}
                activity={loadEducatorFinder}
                onClickButtonFunction={() => {
                    onClick()
                }}
            />
        </div>
    )
}

export default Search