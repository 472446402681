import { gql, useQuery } from '@apollo/client'

const useGetDocsAndExams = (userId) => {
    return useQuery(
        gql`
            query ($userId: ID) {
                getUserDocsAndExams(userId: $userId) {
                    id,
                    name,
                    instructions,
                    isEspecific,
                    universities,
                    type,
                    planId,
                    phase,
                    stepId,
                    status,
                    selectedExam {
                        id: _id
                          name
                          serviceId
                          results {
                              name
                              scale
                              values {
                                  name
                                scale
                            }
                        }
                      }
                    resolveTask {
                        uploadFiles {
                            url
                            uploadDate
                        }
                        score {
                            name
                            examDate
                            overall
                            results {
                                name
                                scale
                                value
                                values {
                                    name
                                    scale
                                    value
                                }
                            }
                        }
                    }
                }
            }
        `,{
            variables: {
                userId: userId
            }
        }
    )
}

export default useGetDocsAndExams