const getBrowserLang = function () {
  const full = navigator.language || navigator.userLanguage
  const parts = full.split('-')
  return parts[0]
}

const getSavedLang = function () {
  return window.localStorage.locale
}

const getUserLang = function (user) {
  if (!user) return null
  if (!user.profile) return null
  if (!user.profile.language) return null
  return user.profile.language
}

export default function (user) {
  return 'en' || getUserLang(user) || getSavedLang() || getBrowserLang() || 'en'
}
