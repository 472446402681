import React from 'react'

import CounselingTemplatesModal from '../CounselingTemplates'
import useCounselingTemplates from '../CounselingTemplates/useCounselingTemplates'

import NewCounselingPlanModal from '../CounselingPlans/Modal/newPlanModal'
import useNewCounselingPlanModal from '../CounselingPlans/Modal/newPlanModal/useNewPlanModal'

import SelectTemplateModal from '../CounselingTemplates/SelectTemplateModal'
import useSelectTemplateModal from '../CounselingTemplates/SelectTemplateModal/useSelectTemplateModal'

import ViewCounselingPlanModal from '../CounselingPlans/Modal/viewPlanModal'
import useViewCounselingPlanModal from '../CounselingPlans/Modal/viewPlanModal/useViewPlanModal'

import AddDiscountModal from '../CounselingPlans/Modal/newPlanModal/Components/AddDiscountModal'
import useAddDiscountModal from '../CounselingPlans/Modal/newPlanModal/Components/AddDiscountModal/useAddDiscountModal'

import EditPaymentModal from '../CounselingPlans/Modal/newPlanModal/Components/EditPaymentModal'
import useEditPaymentModal from '../CounselingPlans/Modal/newPlanModal/Components/EditPaymentModal/useEditPaymentModal'

import AddCounselorModal from '../CounselingPlans/Modal/newPlanModal/Sections/Team/Modal/AddCounselorModal'
import useAddCounselorModal from '../CounselingPlans/Modal/newPlanModal/Sections/Team/Modal/AddCounselorModal/useAddCounselorModal'

import ConfirmCloseModal from '../CounselingPlans/Modal/newPlanModal/Components/ConfirmCloseModal'
import useConfirmCloseModal from '../CounselingPlans/Modal/newPlanModal/Components/ConfirmCloseModal/useConfirmCloseModal'

import SendPlanModal from '../CounselingPlans/Modal/SendPlanModal'
import useSendOneCounselingPlanModal from '../CounselingPlans/Modal/SendPlanModal/useSendOnePlanModal'

import AssingTaskModal from '../CounselingTemplates/Task/assingTaskModal'
import useAssignTaskModal from '../CounselingTemplates/Task/assingTaskModal/useAssignTaskModal'

import CompleteTaskModal from '../CounselingTemplates/Task/completeTaskModal'
import useCompleteTaskModal from '../CounselingTemplates/Task/completeTaskModal/useCompleteTaskModal'

import CancelPlanModal from '../CounselingPlans/Modal/cancelPlanModal'
import useCancelCounselingPlanModal from '../CounselingPlans/Modal/cancelPlanModal/useCancelPlanModal'

//no es un modal
import RedirectToModalPlanCounseling from '../CounselingPlans/Redirect/RedirectToModalPlan'

import CreateTimelineModal from '../Timelines/Modal/CreateTimelineModal'
import useCreateTimelineModal from '../Timelines/Modal/CreateTimelineModal/useCreateTimelineModal'

import DeleteTimelineModal from '../Timelines/Modal/DeleteTimelineModal'
import useDeleteTimelineModal from '../Timelines/Modal/DeleteTimelineModal/useDeleteTimelineModal'

import ViewPlanProgress from '../Plans/modal/confirmCancelPlan/ViewPlanProgress'
import {useSelector} from 'react-redux'

import SendTaskEmailReminder from '../CounselingTemplates/Task/sendEmailReminder' 
import useSendTaskEmailReminder from '../CounselingTemplates/Task/sendEmailReminder/useSendEmailReminder' 

import UniversitiesDirectoryModal from '../University/UniversityDirectory'
import useUniversityDirectory from '../University/UniversityDirectory/useUniversityDirectory'

// import ShowUniversityModal from '../University/ShowUniversity'
import CollegeModal from '../University/MyCollegeModal'
import useMyCollegeModal from '../University/MyCollegeModal/useMyCollegeModal'

import SelectPdfTemplate from '../PdfEditor/Modal/SelectPdfTemplate'
import useSelectPdfTemplate from '../PdfEditor/Modal/SelectPdfTemplate/useSelectPdfTemplate'

import AllStudentModal from '../Workspace/Sections/Tasks/Sections/StudentWithoutTasks/Modal/AllStudentModal'
import useAllStudentModal from '../Workspace/Sections/Tasks/Sections/StudentWithoutTasks/Modal/AllStudentModal/useAllStudentModal'

//staff profile
import StaffProfile from '../Staff'
import useStaffModal from '../Staff/useStaffModal'

import TransportCostumerModal from '../Users/Modal/TransportCostumer'
import useTransportCostumer from '../Users/Modal/TransportCostumer/useTransportCostumer'

// TASK
import NewTaskModal from '../CounselingTemplates/Task/newTaskModal'
import useNewTaskModal from '../CounselingTemplates/Task/newTaskModal/useNewTaskModal'

import ViewTaskModal from '../CounselingTemplates/Task/viewTaskModal'
import useViewTaskModal from '../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'

import NewStepModal from '../CounselingTemplates/Step/newStepModal'
import useNewStepModal from '../CounselingTemplates/Step/newStepModal/useNewStepModal'

import DeleteTemplateModal from '../CounselingTemplates/DeleteTemplateModal'
import useDeleteTemplateModal from '../CounselingTemplates/DeleteTemplateModal/useDeleteTemplateModal'

import ShowScheduledMeeting from '../CounselingTemplates/Task/viewTaskModal/modals/ShowScheduledMeeting'
import useShowScheduleMeeting from '../CounselingTemplates/Task/viewTaskModal/modals/ShowScheduledMeeting/useShowScheduledMeeting'

import RedirectFromUrl from '../CounselingTemplates/RedirectFromUrl'

import RedirectToTaskModal from '../CounselingTemplates/Task/viewTaskModal/Components/RedirectToTaskModal'

import SetApplicationStatusModal from '../CounselingPlans/Modal/setApplicationStatusModal'
import useSetApplicationStatusModal from '../CounselingPlans/Modal/setApplicationStatusModal/useSetApplicationStatusModal'

import SetCompletedPlanStatusModal from '../CounselingPlans/Modal/setCompletedStatusModal'
import useSetCompletedPlanStatusModal from '../CounselingPlans/Modal/setCompletedStatusModal/useSetCompletedStatusModal'

import SetStatusModalConfirm from '../CounselingPlans/Modal/setStatusModalConfirm'
import useSetStatusModalConfirm from '../CounselingPlans/Modal/setStatusModalConfirm/useSetStatusModalConfirm'

import CompletePlanModal from '../Plans/modal/CompletePlan'
import useCompletePlanModal from '../Plans/modal/CompletePlan/useCompletePlan'

import UploadFileModal from '../CounselingTemplates/Task/viewTaskModal/modals/UploadFile'
import useShowUploadFileModal from '../CounselingTemplates/Task/viewTaskModal/modals/UploadFile/useShowUploadFileModal'

import ReviewScheduledModal from '../Plans/GroupPlans/modal/ReviewScheduled'
import useReviewScheduledModal from '../Plans/GroupPlans/modal/ReviewScheduled/useReviewScheduledModal'

import MoveStudentModal from '../Plans/GroupPlans/modal/MoveStudent'
import useMoveStudentModal from '../Plans/GroupPlans/modal/MoveStudent/useMoveStudentModal'

import EmailsListModal from '../../common/ModalEmailsList'
import useModalEmailsList from '../../common/ModalEmailsList/useModalEmailsList'

import TimelinePlanModal from '../Workspace/Sections/Tasks/Sections/NextTimelinePlan/TimelinePlanModal'
import useTimelinePlanModal from '../Workspace/Sections/Tasks/Sections/NextTimelinePlan/TimelinePlanModal/useTimelinePlanModal'

import ChatNotification from '../../common/ChatNotification'

import SendWeeklyInsightModal from '../Dashboard/Dashboard/Components/SendWeeklyInsightModal'
import useSendWeeklyInsightModal from '../Dashboard/Dashboard/Components/SendWeeklyInsightModal/useSendWeeklyInsightModal'

import SendSummaryEmailModal from '../Payments/Invoices/SendSummaryEmail'
import useSendSummaryEmail from '../Payments/Invoices/SendSummaryEmail/useSendSummaryEmail'

import AssignMultipleTasks from '../Workspace/Sections/Tasks/Components/AssignMultipleTasks'
import useAssignMultipleTasks from '../Workspace/Sections/Tasks/Components/AssignMultipleTasks/useAssignMultipleTasks'

import EditCustomService from '../Users/Components/editCumtomService'

import SubmitScore from '../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore'


import useSubmitScoreModal from '../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/useSubmitScoreModal'

import DeleteGroupPlanModal from '../Plans/GroupPlans/modal/DeleteGroupPlan'
import useDeleteGroupPlanModal from '../Plans/GroupPlans/modal/DeleteGroupPlan/useDeleteGroupPlanModal'
const RenderModals = () => {
    const {isOpen: isOpenCounselingTemplates} = useCounselingTemplates()
    const {isOpen: isOpenNewCounselingPlan} = useNewCounselingPlanModal()
    const {isOpen: isOpenSelectTemplate} = useSelectTemplateModal()
    const {isOpen: isOpenViewCounselingPlan} = useViewCounselingPlanModal()
    const {isOpen: isOpenAddDiscount} = useAddDiscountModal()
    const {isOpen: isOpenEditPayment} = useEditPaymentModal()
    const {isOpen: isOpenAddCounselor} = useAddCounselorModal()
    const {isOpen: isOpenConfirmClose} = useConfirmCloseModal()
    const {isOpen: isOpenSendOneCounselingPlan} = useSendOneCounselingPlanModal()
    const {isOpen: isOpenAssignTask} = useAssignTaskModal()
    const {isOpen: isOpenCompleteTask} = useCompleteTaskModal()
    const {isOpen: isOpenCancelCounselingPlan} = useCancelCounselingPlanModal()
    const {isOpen: isOpenCreateTimeline} = useCreateTimelineModal()
    const {isOpen: isOpenDeleteTimeline} = useDeleteTimelineModal()
    //viewPlanProgress
    const { isOpen: isOpenViewPlanProgress } = useSelector((state) => state.modal.plan.viewPlanProgress)
    const { isOpen: isOpenSendTaskEmail } = useSendTaskEmailReminder()
    const { isOpen: isOpenUniversityDirectory } = useUniversityDirectory()
    const { isOpen: isOpenMyCollegeModal } = useMyCollegeModal()
    const { isOpen: isOpenSelectPdfTemplate } = useSelectPdfTemplate()
    const { isOpen: isOpenAllStudent } = useAllStudentModal()
    const { isOpen: isOpenStaff } = useStaffModal()
    const { isOpen: isOpenTransportCostumer } = useTransportCostumer()
    //ExamViewDetail
    const { isOpen: isOpenExamViewDetail } = useSubmitScoreModal()
    // useSelector((state) => state.modal.counseling.viewExamModal)

    //Tasks & Steps
    const { isOpen: isOpenNewTask } = useNewTaskModal()
    const { isOpen: isOpenViewTask } = useViewTaskModal()
    const { isOpen: isOpenNewStep } = useNewStepModal()
    const { isOpen: isOpenDeleteTemplate } = useDeleteTemplateModal()
    const { isOpen: isOpenScheduleMeeting } = useShowScheduleMeeting()
    const { isOpen: isOpenSetApplicationStatus  } = useSetApplicationStatusModal()
    const { isOpen: isOpenSetCompletedPlanStatusModal } = useSetCompletedPlanStatusModal()
    const { isOpen: isOpenSetStatusModalConfirm } = useSetStatusModalConfirm()
    const { isOpen: isOpenCompletePlanModal} = useCompletePlanModal()
    const { isOpen: isOpenUploadFileModal } = useShowUploadFileModal()
    const { isOpen: isOpenReviewScheduled } = useReviewScheduledModal()
    const { isOpen: isOpenMoveStudent } = useMoveStudentModal()

    //Emails modal list
    const { isOpen: isOpenEmailsModalList } = useModalEmailsList()

    const {isOpen: isOpenTimelinePlan } = useTimelinePlanModal()

    const {isOpen: isOpenWeeklyInsight} = useSendWeeklyInsightModal()

    const {isOpen: isOpenSummaryInvoice} = useSendSummaryEmail()

    const {isOpen: isOpenAssignMultipleTasks} = useAssignMultipleTasks()

    const {isOpen: isOpenDeleteGroupPlan} = useDeleteGroupPlanModal()

    return (
        <>
            {isOpenCounselingTemplates && <CounselingTemplatesModal/>}
            
            {/**formulario de creación de counseling plans**/}
            {isOpenNewCounselingPlan && <NewCounselingPlanModal/>}
            
            {/** Select pdf template for /pdf-editor**/}
            {isOpenSelectTemplate && <SelectTemplateModal/>}
            
            {(isOpenViewCounselingPlan || true) && <ViewCounselingPlanModal/>}
            
            {isOpenAddDiscount && <AddDiscountModal/>}
            
            {isOpenEditPayment && <EditPaymentModal/>}
            
            {isOpenAddCounselor && <AddCounselorModal/>}

            {isOpenConfirmClose && <ConfirmCloseModal/>}

            {/**confirm modal for close action**/}
            {isOpenSendOneCounselingPlan && <SendPlanModal/>}

            {isOpenAssignTask && <AssingTaskModal/>}

            {isOpenCompleteTask && <CompleteTaskModal/>}
            
            {isOpenCancelCounselingPlan && <CancelPlanModal/>}

            <RedirectToModalPlanCounseling/>

            {/* TIMELINE MODALS */}
            {isOpenCreateTimeline && <CreateTimelineModal/>}

            {isOpenDeleteTimeline && <DeleteTimelineModal/>}

            {isOpenViewPlanProgress && <ViewPlanProgress/>}
            
            {isOpenSendTaskEmail && <SendTaskEmailReminder/>}

            {/* UNIVERSITY MODAL */}
            {isOpenUniversityDirectory && <UniversitiesDirectoryModal/>}

            {isOpenMyCollegeModal && <CollegeModal/>}

            {isOpenSelectPdfTemplate && <SelectPdfTemplate/>}
            
            {isOpenAllStudent && <AllStudentModal/>}

            {isOpenStaff && <StaffProfile/>}

            {/* MODAL PARA MOVER MÉTODOS DE PAGO DE UN USUARIO A OTRO */}
            {isOpenTransportCostumer && <TransportCostumerModal/>}

            {isOpenExamViewDetail && 
                <SubmitScore/>
                // <ExamViewDetail />
            }

            {/* <ShowUniversityModal/> */}

            {isOpenNewTask && <NewTaskModal/>}    

            {isOpenViewTask && <ViewTaskModal/>}

            {isOpenScheduleMeeting && <ShowScheduledMeeting />}

            {isOpenUploadFileModal && <UploadFileModal />}
            
            {isOpenNewStep && <NewStepModal/>}
            
            {isOpenDeleteTemplate && <DeleteTemplateModal/>}

            <RedirectFromUrl/>
            <RedirectToTaskModal/>

            {isOpenSetApplicationStatus && <SetApplicationStatusModal/>}

            {isOpenSetCompletedPlanStatusModal && <SetCompletedPlanStatusModal/>}

            {isOpenSetStatusModalConfirm && <SetStatusModalConfirm/>}

            {isOpenCompletePlanModal && <CompletePlanModal/>}

            {isOpenReviewScheduled && <ReviewScheduledModal/>}

            {isOpenMoveStudent && <MoveStudentModal/>}

            {isOpenEmailsModalList && <EmailsListModal/>}

            {isOpenTimelinePlan && <TimelinePlanModal/>}


            <ChatNotification/>

            {isOpenWeeklyInsight && <SendWeeklyInsightModal/>}


            {isOpenSummaryInvoice && <SendSummaryEmailModal/>}

            {isOpenAssignMultipleTasks && <AssignMultipleTasks/>}

            <EditCustomService/>

            {isOpenDeleteGroupPlan && <DeleteGroupPlanModal/>}
        </>
    )
}


export default RenderModals