import { Avatar, IconArrowRight, ListItem } from '@Knowledge-OTP/znk-ui-components'
import React from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment'

const Teach = (props) => {
  const { educator } = props
  const { translate } = useTranslation()
  const getValues = (date, type) => {
    const month = moment(date).month()
    let year = moment(date).year()
    if (month >= 10 && month <= 12) {
      year += 1
    }
    const difY = year - moment().year()
    switch (type) {
      case 'class':
        return year.toString().substr(2, 2)
      default:
        return 12 - difY
    }
  }
  return (
    <div className='pr-6 d-flex flex-column'>
      {
        educator?.educatorInfo.getTeach.map(
          (ele, index) => {
            return (
              <ListItem
                showBottomLine={false}
                showTopLine={false}
                className={`d-flex flex-row justify-content-between align-items-center py-0 ${index > 0 ? 'mt-3' : 'mt-4'}`} 
                key={ele.id} 
                Left={
                  <div className='d-flex flex-row align-items-center'>
                    <Avatar name={ele.firstName + ' ' + ele.lastName} size='avatar-large' />
                    <div className='d-flex flex-column ml-3'>
                      <span className='h4 font-weight-bold text-title' style={{ color: '#2C3F58' }}>{ele.firstName} {ele.lastName}</span>
                      <span className='h5 text-gray' style={{ color: '#778699' }}>{ele.studentInfo.graduationDate ? getValues(ele.studentInfo.graduationDate, 'grade') + '°, ': ''}{ele.gender ? translate(`misc.${ele.gender}`) + ', ' : ''}{ele.country.name}</span>
                    </div>
                  </div>
                }
                Right={<IconArrowRight color='#72809D'/>}
              />
            )
          }
        )
      }
    </div>
  )
}

export default Teach
