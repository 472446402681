import {useEffect, useMemo} from 'react'
import {useLazyQuery, gql} from '@apollo/client'
const capitalizeText = (string = '') => {
  return string.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))); 
}
const useGetPaymentMethodLabel = (userId) => {
    const [getPayerUser, {data: payerUserQuery, loading}] = useLazyQuery(
        gql`
          query($userId: String!) {
            getPayerUser(userId: $userId, getMyPaymentMethod: true) {
              paymentMethod {
                kind
                ... on CardPaymentMethod {
                  brand
                  last4
                  cardInfo {
                    brand
                    last4
                    expYear
                    expMonth
                  }
                }
                ... on ManualPaymentMethod {        
                  kind
                  manualPaymentInfo {
                    bankName
                    accountNumber
                    routingNumber
                    beneficiary
                    address
                    swiftCode
                  }
                }
                ... on BankDebitPaymentMethod {
                  kind
                  validationStatus
                  accountInfo {
                    bankName
                    last4
                  }
                }
                ... on BankCreditPaymentMethod {
                  status
                  sourceInfo {
                    accountNumber
                    routingNumber
                    bankName
                    swiftCode
                  }
                }
                ... on ZellePaymentMethod {
                  zellePaymentInfo {
                    emailToSend
                  }
                }
              }
    
    
            }
          }
        `,
    )
    useEffect(() => {
      if (userId) {
        getPayerUser({
          variables: {userId},
        })
      }
    }, [userId, getPayerUser])    

    
    const paymentMethodLabel = useMemo(
          () => {
            let paymentMethodLabel = `No defined`
            if (
                payerUserQuery &&
                payerUserQuery.getPayerUser &&
                payerUserQuery.getPayerUser.paymentMethod
            ) {
                // manual
                // creditCard
                // bankCredit
                // bankDebit
                const paymentMethod = payerUserQuery.getPayerUser.paymentMethod
                switch (payerUserQuery.getPayerUser.paymentMethod.kind) {
                  case 'manual':
                      paymentMethodLabel = `${paymentMethod?.manualPaymentInfo?.bankName} (Manual)`    
                      break
                  case 'creditCard':
                      paymentMethodLabel = `${paymentMethod.brand} ${paymentMethod.last4}`    
                      break
                  case 'bankDebit':
                      paymentMethodLabel = `${paymentMethod.accountInfo?.bankName} ${paymentMethod.accountInfo?.last4}`    
                      break  
                  case 'bankCredit':
                      paymentMethodLabel = `${paymentMethod?.sourceInfo?.accountNumber} ${paymentMethod?.sourceInfo?.routingNumber}`    
                      break 
                  case 'zelle':
                      paymentMethodLabel = `Zelle`
                      break 
                  default : 
                      paymentMethodLabel = `No defined`
                }
                
            }
            return capitalizeText(paymentMethodLabel)
          }, 
          [payerUserQuery]
    )

    return {
      label: paymentMethodLabel,
      paymentMethod: payerUserQuery?.getPayerUser?.paymentMethod || null,
      loading
    }
}

export default useGetPaymentMethodLabel