import { CheckboxSelector, IconArrowDown } from '@Knowledge-OTP/znk-ui-components'
import React from 'react'
import { Badge } from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'
import moment from 'moment'

const ClassYearFilter = (props) => {
  const { translate } = useTranslation()
  const { classFilters, setClassFilters } = props
  const currentYear = moment().format('YYYY')
  const selectorData = [0, 1, 2, 3, 4, 5].map((item) => ({
    name: parseInt(currentYear) + item,
    id: parseInt(currentYear) + item
  }))
  return (
    <CheckboxSelector
      setDataFilters={setClassFilters}
      direction='bottom'
      dataFilters={classFilters}
      typeFilter='services'
      data={selectorData}
      strings={{
        viewAll: translate('filters.plans.viewAll'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search')
      }}
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.users.classYear')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default ClassYearFilter
