import React, {useState} from 'react'
import { 
    ModalLayout,
    IconDiscount,
    Input,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import useAddDiscountModal from './useAddDiscountModal'
import RenderInputOption from '../RenderInputOption'

const AddDiscountModal = () => {
    const {isOpen, toggle, onChange} = useAddDiscountModal()
    const [discount, setDiscount] = useState(0)
    return (
        <ModalLayout
            className={`modal-colored modal-centered white-modal h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={`d-flex flex-column`}>
                    <IconDiscount size={80} />
                    <span className={`h2 text-title font-weight-bold mt-2`}>Add Discount</span>
                </div>
            }
            underHead={null}
            options={null}
            minHead={<span className={`h3 text-title`}>Add Discount</span>}
            sideRight={null}
            toggleRight={() => {}}
            useRightSection={false}
            isOpenRight={false}
        >
            <RenderInputOption key={'discount'} label={'Discount'}>
                <Input 
                    type={'number'}
                    value={null} 
                    onChange={(e) => {
                        setDiscount(e?.target?.value)
                    }}
                    max={100}
                    min={0}
                    className={`mb-6`}
                />
                <Button 
                    className={'w-100'}
                    label={'Add discount'}
                    color={'primary'}
                    size={'medium'} 
                    onClick={() => {
                        if(onChange) onChange(discount)
                    }} 
                />
            </RenderInputOption>

        </ModalLayout>
    )
}

export default AddDiscountModal