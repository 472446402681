import React, {useState} from 'react'
import {
    TabContent, TabPane
} from 'reactstrap'

//TABS
import LessonPartFix from './Tabs/LessonPartFix'
import LessonHighCost from './Tabs/LessonHighCost'
import TimezoneDTS from './Tabs/TimezoneDTS'
import PlanLessonPayment from './Tabs/PlanLessonPayment'
import GoogleCalendarEducator from './Tabs/GoogleCalendarEducator'
import ScoresRefactorModule from './Tabs/Scores'
const tabsKeys = {
    lessonPartFix: 'lesson-part-fix',
    lessonHightCost: 'lesson-hight-cost',
    timezoneDTS: 'timezone-dts',
    planLessonPayment: 'plan-lesson-payment',
    googleCalendarEducator: 'google-calendar-educator',
    scoreRefactor: 'score-refactor',
}

const DeveloperView = () => {
  const [currentTab, setCurrentTab] = useState(tabsKeys.scoreRefactor)  
  return (
    <div className='col-12 col-md-12 px-md-6 px-lg-7' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
      <div className='container-fluid mt-2 mt-md-5 d-flex px-0 flex-column-reverse flex-md-row'>
        <div className='d-flex flex-grow-1 mt-2 pr-0 pr-md-2 mt-md-0 align-items-center znk-scroll-horizontal'>
          <span 
            className={`nav-title ${currentTab === tabsKeys.lessonPartFix && 'active'} m-0 mx-3`} 
            onClick={() => {
                setCurrentTab(tabsKeys.lessonPartFix)
            }}>Lessons parts</span>
          <span 
            className={`nav-title ${currentTab === tabsKeys.lessonHightCost && 'active'} m-0 mx-3`} 
            onClick={() => {
                setCurrentTab(tabsKeys.lessonHightCost)
            }}>Lesson hight cost</span>
          <span 
            className={`nav-title ${currentTab === tabsKeys.timezoneDTS && 'active'} m-0 mx-3`} 
            onClick={() => {
                setCurrentTab(tabsKeys.timezoneDTS)
            }}>TimezoneDTS</span>
            <span 
              className={`nav-title ${currentTab === tabsKeys.planLessonPayment && 'active'} m-0 mx-3`} 
              onClick={() => {
                  setCurrentTab(tabsKeys.planLessonPayment)
              }}>Plan Lesson Payment</span>

            <span 
              className={`nav-title ${currentTab === tabsKeys.googleCalendarEducator && 'active'} m-0 mx-3`} 
              onClick={() => {
                  setCurrentTab(tabsKeys.googleCalendarEducator)
              }}>Google Calendar Educator</span>


            <span 
              className={`nav-title ${currentTab === tabsKeys.scoreRefactor && 'active'} m-0 mx-3`} 
              onClick={() => {
                  setCurrentTab(tabsKeys.scoreRefactor)
              }}>Score Refactore</span>
        </div>
      </div>
      <div className={'Tabs'}>
        <TabContent activeTab={currentTab} >
            <TabPane tabId={tabsKeys.lessonPartFix}>
              <LessonPartFix/>
            </TabPane>
            <TabPane tabId={tabsKeys.lessonHightCost}>
              <LessonHighCost />
            </TabPane>
            <TabPane tabId={tabsKeys.timezoneDTS}>
              <TimezoneDTS />
            </TabPane>
            <TabPane tabId={tabsKeys.planLessonPayment}>
              <PlanLessonPayment />
            </TabPane>
            <TabPane tabId={tabsKeys.googleCalendarEducator}>
              <GoogleCalendarEducator />
            </TabPane>
            <TabPane tabId={tabsKeys.scoreRefactor}>
              <ScoresRefactorModule />
            </TabPane>
            
        </TabContent>    
      </div>
    </div>
  )
}

export default DeveloperView
