import React, { useState } from 'react'
import {
  Modal
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import SureModal from './sure.js'

const CancelLesson = (props) => {
  const {setLessonsSelected, lessonsSelected, refetch = () => {}} = props
  const dispatch = useDispatch()
  const reduxCancel = useSelector((state) => state.modal.plan.cancelLesson)
  const { isOpen, lessonId, isOrientation, dataLesson, isMeeting, refetch: refetchList } = reduxCancel
  const { translate } = useTranslation()
  const [textMeeting, setTextMeeting] = useState('')
  const [optionMeeting, setOptionMeeting] = useState(0)
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.cancelLesson',
      data: {
        ...reduxCancel,
        isOpen: !isOpen
      }
    })
  }

  const [openSure, setOpenSure] = useState(false)
  const [all, setAll] = useState(false)
  return (
    <>
      <Modal
        buttonLabel={null}
        className='modal-colored modal-centered fixed-head-modal'
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className='row p-0 m-7'>
          <span className='h2 m-0 pr-5'>
            <b>{isMeeting ? 'Remove Meeting' : isOrientation ? 'Remove Orientation' : translate('modal.cancellesson.youareremoving')}</b>
          </span>
          <p className='m-0 p-0 mt-5'>{translate('modal.cancellesson.wantdelete')}</p>
          <div className='m-0 p-0 w-100 d-flex justify-content-end mt-5'>
            <div className='w-100 d-flex flex-column justify-content-end'>
              {
                !isOrientation && !isMeeting && dataLesson?.status !== 'pending' &&
                <button
                  title={translate('modal.cancellesson.removeall')}
                  className='btn btn-link p-0 mx-3 my-2 text-right white-space-nowrap text-truncate'
                  onClick={() => {
                    toggle()
                    setAll(true)
                    setOpenSure(true)
                  }}
                >
                  {translate('modal.cancellesson.removeall')}
                </button>
              }
              {
                isMeeting && (<>
                  <button
                    title={translate('modal.cancellesson.removeonly')}
                    className='btn btn-link p-0 mx-3 my-2 text-right white-space-nowrap text-truncate'
                    onClick={() => {
                      setTextMeeting('Remove meeting & reassign task')
                      setOptionMeeting(1)
                      toggle()
                      setAll(false)
                      setOpenSure(true)
                    }}
                  >
                    Remove meeting & reassign task
                  </button>
                  <button
                    title={translate('modal.cancellesson.removeonly')}
                    className='btn btn-link p-0 mx-3 my-2 text-right white-space-nowrap text-truncate'
                    onClick={() => {
                      setTextMeeting('Remove meeting & task')
                      setOptionMeeting(2)
                      toggle()
                      setAll(false)
                      setOpenSure(true)
                    }}
                  >
                    Remove meeting & task
                  </button>
                </>)
              }
              { !isMeeting &&
                <button
                  title={translate('modal.cancellesson.removeonly')}
                  className='btn btn-link p-0 mx-3 my-2 text-right white-space-nowrap text-truncate'
                  onClick={() => {
                    toggle()
                    setAll(false)
                    setOpenSure(true)
                  }}
                >
                
                  {!isOrientation ? translate('modal.cancellesson.removeonly') : 'Remove Orientation'}
                </button>
              }
              <button
                title={translate('modal.cancellesson.cancel')}
                className='btn btn-link p-0 mx-3 my-2 text-muted white-space-nowrap text-truncate'
                style={{ textAlign: 'right' }}
                onClick={toggle}
              >
                {translate('modal.cancellesson.cancel')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <SureModal 
        setLessonsSelected={setLessonsSelected}
        lessonsSelected={lessonsSelected}
        open={openSure} 
        setOpenFunction={setOpenSure} 
        allFuture={all} 
        idLesson={lessonId || ''} 
        refetch={refetch}
        isOrientation={isOrientation}
        isMeeting={isMeeting}
        textMeeting={textMeeting}
        optionMeeting={optionMeeting}
        refetchList={refetchList}/>
    </>
  )
}

export default CancelLesson
