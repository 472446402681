import {gql, useMutation} from '@apollo/client'


const useUpdateScore = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $examScore: ScoresInput!
            ) {
                updateScore (
                    examScore: $examScore
                ) {
                    id
                }
            }
        `, {onCompleted, onError}
    )
}

export default useUpdateScore