import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation update($userId: ID!, $startDate: DateTime!, $endDate: DateTime!){
    removeWeek(userId: $userId, startDate: $startDate, endDate: $endDate) {
        startDate
        endDate
    }
  }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.setUserStatus : null, error, validationErrors, submit }
}
