import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { TabContent, TabPane } from 'reactstrap'
import { ordenateUserArray } from '../../../../Parent/Utils/ordenateUsersArray'
import CounselorSelector from '../CounselorSelectorRow'
import LiveprepSelector from '../LiveprepSelectorRow'
import counselorTypes from '../../Utils/counselorTypes'
import UseUpdateGlobalVar from '../../Models/updateGlobalVar'
import { showAlert } from '../../../../../common/Alert/util'
import useTranslation from '../../../../../i18n/useTranslation'
const SelectGlobalCounselor = (props) => {

    const { staff = [], 
            loading,
            counselors 
          } = props
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const [selectUserCounseling, setSelectUserCounseling] = useState(null)
    const [selectUserLiveprep, setSelectUserLiveprep] = useState(null)
    const [counselorVarId, setCounselorVarId] = useState(null)
    const [liveprepVarId, setLiveprepVarId] = useState(null)
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
    const [isOpenConfirmModalLP, setIsOpenConfirmModalLP] = useState(false)
    //TODO: Internacionalizar
    const titlesArray = [translate(`globalCounselors.counselingTitle`), translate(`globalCounselors.liveprepTitle`)]

    //eslint-disable-next-line
    const [updateGlobalVar, {loading: loadingUpdateGloablVar}] = UseUpdateGlobalVar()
    const onClickCounseling = () => { 
        updateGlobalVar({
            variables: {
              globalVarId: counselorVarId,
              serviceUpdate:{
                type: counselorTypes.counseling,
                name: selectUserCounseling,
                value: 0,
                isActive: true,
              }
            }
          }).then(
            () => {
              showAlert({
                  text: translate(`globalCounselors.counselingSuccess`),
                  status: 'success'
              }, dispatch)
              setIsOpenConfirmModal(!isOpenConfirmModal)
          },
          (err) => {
              console.error(err.toString())
              showAlert({
                  text: translate(`globalCounselors.counselingError`),
                  status: 'error'
              }, dispatch)
              setIsOpenConfirmModal(!isOpenConfirmModal)
            }
          )
    }

    const onClickLivePrep = () => {
        updateGlobalVar({
            variables: {
              globalVarId: liveprepVarId,
              serviceUpdate:{
                type: counselorTypes.livePrep,
                name: selectUserLiveprep,
                value: 0,
                isActive: true,
              }
            }
          }).then(
            () => {
              showAlert({
                  text: translate(`globalCounselors.liveprepSuccess`),
                  status: 'success'
              }, dispatch)
              setIsOpenConfirmModalLP(!isOpenConfirmModalLP)
          },
          (err) => {
              console.error(err.toString())
              showAlert({
                  text: translate(`globalCounselors.liveprepError`),
                  status: 'error'
              }, dispatch)
              setIsOpenConfirmModalLP(!isOpenConfirmModalLP)
          }
          )
    }

    useEffect(() => {
      if (counselors?.length > 0){
        const counselorCounseling = counselors.find(counselor => counselor.type === counselorTypes.counseling)
        const counselorLiveprep = counselors.find(counselor => counselor.type === counselorTypes.livePrep)
        if (counselorCounseling) {
          setSelectUserCounseling(counselorCounseling?.name)
          setCounselorVarId(counselorCounseling?.id)
        }
        if (counselorLiveprep) {
          setSelectUserLiveprep(counselorLiveprep?.name)
          setLiveprepVarId(counselorLiveprep?.id)} 
      }
    }, [counselors])

    return (
        <TabContent
            activeTab={loading ? 'loading' : 'show'}
        >
            <TabPane tabId={`loading`}>
                <div className='mt-6 d-flex flex-column'>
                    <Skeleton count={1} width={60} height={20} className={`w-20 my-1`} />
                    <Skeleton count={1} width={100} height={40} className={`w-100 my-1`} />
                    <Skeleton count={1} width={60} height={20} className={`w-20 my-1`} />
                    <Skeleton count={1} width={100} height={40} className={`w-100 my-1`} />
                </div>
            </TabPane>
            <TabPane tabId={'show'}>
                {/* Hacer un componente y pasarle los datos para no repetir el codigo */}
                 <div className='mt-6'>
                    <CounselorSelector
                        className={'my-2 w-40'}
                        activeUsers={staff || []}
                        selectUser={selectUserCounseling}
                        setSelectUser={setSelectUserCounseling}
                        ordenateUserArray={ordenateUserArray}
                        title={titlesArray[0]}
                        onClick={()=>{onClickCounseling()}}
                        isOpenConfirmModal={isOpenConfirmModal}
                        setIsOpenConfirmModal={setIsOpenConfirmModal}
                        activity={loadingUpdateGloablVar}      
                    />
                    <LiveprepSelector
                        className={'my-2 w-40'}
                        activeUsers={staff || []}
                        selectUser={selectUserLiveprep}
                        setSelectUser={setSelectUserLiveprep}
                        ordenateUserArray={ordenateUserArray}
                        title={titlesArray[1]}
                        onClick={()=>{onClickLivePrep()}}
                        isOpenConfirmModal={isOpenConfirmModalLP}
                        setIsOpenConfirmModal={setIsOpenConfirmModalLP}
                        activity={loadingUpdateGloablVar}      
                    />
                </div>
            </TabPane>
        </TabContent>

    )
}

export default SelectGlobalCounselor



