import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation update($studentInfo: StudentInput!, $userId: ID!) {
        setStudentInfoToUser(studentInfo: $studentInfo, userId: $userId){
          id
          paymentMethod {
            ... on CardPaymentMethod {
              last4
              brand
              expYear
              expMonth
            }
          }
          emails {
            address
            verified
          }
          countryId
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          studentInfo {
            institution
            graduationDate
            gpa
          }
          parentInfo {
            children {
              studentUserId
              managePayments
              manageApprovals
              firstName
              lastName
              email
            }
          }
          educatorInfo {
            id
            defaultRate
            services {
              serviceId
              name
              defaultRate
              topics {
                topicId
                teachingTypes
                name
              }
            }
            backupLink
          }
          roles
        }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    delete obj.studentInfo.month
    delete obj.studentInfo.year
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.setStudentInfoToUser : null, error, validationErrors, submit }
}
