import React from 'react'
import {IconArrowRight} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'

const ListItem = (props) => {
  const {item, onClick} = props
  return (
    <div
      className='d-flex flex-row align-items-center list-item-university py-2 px-2'
      onClick={onClick}
    >
      <div className='w-25 align-self-center'>
        <img
          src={item?.logo}
          alt={`${item?.name}`}
          style={{width: '50px', height: '50px'}}
        />
      </div>
      <div className='w-100 align-self-center'>{item?.name}</div>
      <div className={`align-self-center`}>
        <IconArrowRight color='#E3E3E3' size={16} />
      </div>
    </div>
  )
}

ListItem.defaultProps = {
  item: {},
  onClick: () => {},
}

ListItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
}

export default ListItem
