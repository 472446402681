import { useMutation, gql } from '@apollo/client'

const useDelOnboardingSession = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation ($planId: ID!) {
                delOnboardingSession (planId: $planId)
            }
        `,
        {onCompleted, onError}
    )
}

export default useDelOnboardingSession