import {useMutation, gql} from '@apollo/client'

const useDismissTimelinePlan = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
        mutation (
            $planId: ID!
        ) {
            dismissTimelinePlan (planId: $planId)
        }
        `,
        {
            onCompleted: onSuccess, onError
        }
    )
}

export default useDismissTimelinePlan