import validate from 'validate.js'

const constraints = {
  name: {
    presence: true
  },
  code: {
    presence: true
  },
  dialCode: {
    presence: true,
    format: {
      pattern: '\\+[0-9]{1,3}'
    }
  },
  defaultStudentRate: {
    presence: true
  }
}

export default (data) => {
  return validate(data, constraints)
}
