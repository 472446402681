import React, { useState } from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  StickyButton,
  Input,
  IconDiscount
} from '@Knowledge-OTP/znk-ui-components'
import { Collapse } from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'

const AddDiscount = (props) => {
  const { setDiscount, discount, paymentLabel } = props
  const dispatch = useDispatch()
  const [value, setValue] = useState(discount)
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)
  const toggleCollapse = () => setIsOpenCollapse(!isOpenCollapse)
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()

  const { isOpen } = useSelector((state) => state.modal.plan.addDiscount)

  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.addDiscount.isOpen',
      data: !isOpen
    })
  }

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex modal-header-height-responsive'>
            <div className='d-flex flex-column justify-content-start'>
              <IconDiscount className='mb-3' size={80} color='#454B54' />
              <span className='text-title font-weight-bold h2'>{translate('modal.newplan.adddiscount')}</span>
            </div>
            <div className='pt-1'>
              <IconClose className='d-flex align-self-start' size={20} onClick={toggle} />
            </div>
          </div>
        }
      >
        <div className='m-0 px-4 py-0'>
          <div className='m-0 py-0 px-0 my-0'>
            <label className='w-100 text-muted my-3'>
              {paymentLabel} :
              <Input
                className='mt-2'
                onChange={(e) => {
                  setValue({ ...value, lessons: parseInt(e.target.value !== '' ? e.target.value : '0') })
                }}
                value={value.lessons} placeholder={`${translate('modal.newplan.discount')} (%)`} type='number' max='100' min='0'
              />
            </label>
            <label className='btn-link' onClick={toggleCollapse}>
              {isOpenCollapse ? translate('modal.newplan.lessoptions') : translate('modal.newplan.moreoptions')}
            </label>
            <Collapse isOpen={isOpenCollapse} className='m-0 p-0 pb-2'>
              <label className='w-100 text-muted my-3'>
                {translate('modal.newplan.registrationfee')} :
                <Input
                  className='mt-2'
                  onChange={(e) => {
                    setValue({ ...value, registration: parseInt(e.target.value !== '' ? e.target.value : '0') })
                  }}
                  value={value.registration} placeholder={`${translate('modal.newplan.discount')} (%)`} type='number' max='100' min='0'
                />
              </label>
              <label className='w-100 text-muted my-3'>
                {translate('modal.newplan.examregistrationfee')} :
                <Input
                  className='mt-2'
                  onChange={(e) => {
                    setValue({ ...value, exam: parseInt(e.target.value !== '' ? e.target.value : '0') })
                  }}
                  value={value.exam} placeholder={`${translate('modal.newplan.discount')} (%)`} type='number' max='100' min='0'
                />
              </label>
              <label className='w-100 text-muted my-3'>
                Zinkerz Pro :
                <Input
                  className='mt-2'
                  onChange={(e) => {
                    setValue({ ...value, pro: parseInt(e.target.value !== '' ? e.target.value : '0') })
                  }}
                  value={value.pro} placeholder={`${translate('modal.newplan.discount')} (%)`} type='number' max='100' min='0'
                />
              </label>
            </Collapse>
          </div>
          <StickyButton
            className='p-0 m-0'
            condition={value.discount !== null}
            label={translate('modal.newplan.adddiscount')}
            color='#696CFF'
            onClickButtonFunction={() => {
              setDiscount(
                value,
                isOpenCollapse
              )
              toggle()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

AddDiscount.propTypes = {
}

AddDiscount.defaultProps = {
}
export default AddDiscount
