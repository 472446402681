import validate from 'validate.js'

const constraints = {
  userId: {
    presence: {
      allowEmpty: false
    }
  },
  typeUser: {
    presence: {
      allowEmpty: false
    }
  },
  materials: {
    presence: {
      allowEmpty: false
    }
  }
}

export default (data) => {
  return validate(data, constraints)
}
