import React from 'react';
import { Field, SelectOption } from '@Knowledge-OTP/znk-ui-components'

const AtomicFilter = (props) => {
  const { atomicFilter, setAtomicFilter } = props
  const atomicOptions = [
    { id: true, text: 'True' },
    { id: false, text: 'False' }
  ]

  return (
    <>
      <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-2'>
        View atomix only
      </label>
      <Field
        className='col-12  p-2'
        type={SelectOption}
        fieldName='atomicFilter'
        options={atomicOptions}
        value={atomicFilter}
        onChange={(e) => {
          const atomic = atomicOptions.reduce((obj, atomic) => {
            if (atomic.id === e) return { id: e, name: atomic.name }
            return obj
          }, {})
          setAtomicFilter(atomic.id)
        }}
      />
      <span className='btn-link col-12 mt-2 mb-2 mt-3 text-right pb-4 pl-4' onClick={() => setAtomicFilter(undefined)}>
        Reset
      </span>

    </>
  )
}

export default AtomicFilter
