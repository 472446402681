import React, { useState, useEffect } from 'react'
import {
  Modal,
  IconClose,
  Field,
  PlainTextInput,
  StickyButton,
  Button
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import './index.css'
import processOnePayslip from '../../../Model/Payments/Payslips/ProcessOne'
import processMultiplePayslips from '../../../Model/Payments/Payslips/ProcessMultiple'
import SelectEmployee from '../Components/SelectEmployee'
import useTranslation from '../../../i18n/useTranslation'
import formatNumber from '../../../utils/formatNumber'

const ProcessPayslip = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.processPayslip)
  const { isOpen, isMultiple, payslipId, onChange = () => {} } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [query, setQuery] = useState({})
  const [form, setForm] = useState({
    student: undefined,
    startDate: moment(moment().clone().startOf('month')).format('YYYY-MM-DD'),
    endDate: moment(moment().clone().endOf('month')).format('YYYY-MM-DD')
  })
  const toggle = () => {
    setForm({
      student: undefined,
      startDate: moment(moment().clone().startOf('month')).format('YYYY-MM-DD'),
      endDate: moment(moment().clone().endOf('month')).format('YYYY-MM-DD')
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.processPayslip',
      data: {
        isOpen: false,
        isMultiple: false,
        invoiceId: ''
      }
    })
  }
  const [meInvoice, { data }] = useLazyQuery(
    gql`query($payslipId: String!){
      payslip(payslipId: $payslipId) {
        id
        userId {
          id
          firstName
          lastName
        }
        from
        to
        category
        payslipDate
        payslipNumber
        paymentItemIds
        payslipDate
        isManual
        createdAt
        updatedAt
        status
        payslipProps {
          amount
          isPenalized
        }
        paymentItemsPayslip {
          id
          amount
          date
          description
          type
          isWaive
        }
      }
    }`,
    {
      variables: { payslipId: payslipId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (!payslipId || payslipId === '') return
      await meInvoice()
    })()
  }, [payslipId, meInvoice])
  useEffect(() => {
    if (data) {
      setForm({
        student: data.payslip.userId
      })
    }
  }, [data])
  useEffect(() => {
    if (!form.startDate || !form.endDate) return
    setQuery({
      limit: 0,
      status: ['pendingPayment'],
      sortField: 'from',
      from: moment(form.startDate).clone().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z',
      to: moment(form.endDate).clone().endOf('month').format('YYYY-MM-DD') + 'T23:59:59.999Z',
      current: true
    })
  }, [form.startDate, form.endDate])
  const [invoices, { data: dataInvoices }] = useLazyQuery(
    gql`query(
        $limit: Int,
        $sortField: String,
        $userId: [String!],
        $from: DateTime,
        $to: DateTime,
        $status: [PaymentStatus!]
        $searchString: String
        $current: Boolean
      )
      {
        payslips(
          limit: $limit,
          sortField: $sortField,
          status: $status, 
          userId: $userId,
          from: $from,
          to: $to,
          searchString: $searchString,
          current: $current)
        {
          hasNext
          hasPrevious
          next
          previous
          totalDocs
          docs {
            id
            userId {
                id
                firstName
                lastName
                paymentMethod {
                ... on CardPaymentMethod {
                  brand
                  last4
                }
                ... on ZellePaymentMethod {
                  zellePaymentInfo {
                    emailToSend
                  }
                }
              }
            }
            status
            from
            to
            category
            payslipDate
            payslipNumber
            paymentItemIds
            payslipDate
            isManual
            createdAt
            updatedAt
            payslipProps {
              amount
              isPenalized
            }
            paymentItemsPayslip {
              id
              amount
              date
              isWaive
            }
          }
        }
      }`,
    {
      variables: query,
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (!form.startDate || !form.endDate) return
      await invoices()
    })()
    // eslint-disable-next-line
  }, [query, invoices])
  const { submit: submitOne, loading: loadingOne } = processOnePayslip(() => {
    refetch()
    if(onChange) onChange()
    toggle()
  })
  const { submit: submitMore, loading: loadingMore } = processMultiplePayslips(() => {
    refetch()
    if(onChange) onChange()
    toggle()
  }, console.log)
  const onSubmit = () => {
    if (!isMultiple) {
      submitOne({ payslipId })
    } else {
      submitMore({
        payslipIds: dataInvoices.payslips.docs.map(e => { return e.id })
        // from: moment(form.startDate).clone().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z',
        // to: moment(form.endDate).clone().endOf('month').format('YYYY-MM-DD') + 'T23:59:59.999Z'
      })
    }
  }
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header flex-column'>
            <div className='w-100 d-flex flex-row justify-content-between'>
              <div className='d-flex flex-column '>
                <h1 className='flex-grow-1 text-title font-weight-bold h3'>Process Payslips</h1>
                {!isMultiple && data && (<h1 className='text-title font-weight-bold'>PAY-{String(data.payslip.payslipNumber).padStart(5, 0)}</h1>)}
              </div>
              <IconClose size={20} onClick={toggle} />
            </div>
            {
              isMultiple
                ? <h4 className='text-gray mt-3'>{translate('payslips.actions.subtitleprocesmultiple')}</h4>
                : <h4 className='text-gray mt-3'>{translate('payslips.actions.subtitleprocesone').split('[name]')[0] + (data ? data.payslip.userId.firstName + ' ' + data.payslip.userId.lastName : '') + translate('payslips.actions.subtitleprocesone').split('[name]')[1]}</h4>
            }
          </div>
        }
      >
        <>
          {!isMultiple && (<>
            <div className='d-flex flex-column'>
              <div className='px-2'>
                <div className='borderTop mt-2' />
              </div>
              <div className='px-2'>
                {data
                  ? <SelectEmployee form={form} setForm={setForm} title={false} first={false} invoice edit={false} />
                  : <Skeleton height={60} />}
              </div>
              <div className='px-2'>
                <div className='borderTop' />
              </div>
            </div>
            <div className='px-2 mt-4'>
              {data
                ? <div className='d-flex justify-content-between back-primary px-3 znk-invoice-row'>
                  <span>End of {moment(data.payslip.to).format('MMMM')}</span>
                  <span>${formatNumber(data.payslip.payslipProps.amount)}</span>
                </div>
                : <Skeleton height={60} />}
            </div>
          </>)}
          {isMultiple && (
            <div className='d-flex m-0 mt-5 px-2'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>From</label>
                <Field
                  type={PlainTextInput}
                  fieldName='startDate'
                  fieldType='date'
                  value={form.startDate}
                  onChange={(value) => { setForm({ ...form, startDate: value }) }}
                />
              </div>
              <div className='col-6 pl-1 pr-0 pl-2'>
                <label className='text-gray font-weight-light mb-2'>To</label>
                <Field
                  type={PlainTextInput}
                  fieldName='endDate'
                  fieldType='date'
                  value={form.endDate}
                  onChange={(value) => { setForm({ ...form, endDate: value }) }}
                />
              </div>
            </div>
          )}
          <div className={!isMultiple ? 'h-auto' : 'h-fixed'}>
            {dataInvoices && isMultiple && (
              <div className='m-0 mt-5 px-2'>
                <div className='d-flex justify-content-between'>
                  <span>{dataInvoices.payslips.totalDocs} {dataInvoices.payslips.totalDocs > 1 ? 'invoices' : 'invoice'}</span>
                  <span>${formatNumber(dataInvoices.payslips.docs.reduce((sum, current) => sum + current.payslipProps.amount, 0))}</span>
                </div>
                {
                  dataInvoices.payslips.docs.map((element, index) => {
                    return (
                      <div key={element.id} className='d-flex justify-content-between back-primary px-3 znk-invoice-row'>
                        <span>End of {moment(element.to).format('MMMM')}</span>
                        {isMultiple && (<span>{element.userId.firstName} {element.userId.lastName}</span>)}
                        <span>${formatNumber(element.payslipProps.amount)}</span>
                      </div>
                    )
                  })
                }
              </div>
            )}
          </div>
          <div className={!isMultiple ? 'px-2 mt-6' : 'px-2 mt-4'}>
            <StickyButton>
              <div className='m-0 p-0 row'>
                <Button
                  color='primary'
                  activity={loadingOne | loadingMore}
                  className='col-9'
                  label={!isMultiple ? 'Process Payslip' : dataInvoices ? 'Process ' + dataInvoices.payslips.totalDocs + ' Payslips' : 'Process 0 Payslips'}
                  onClick={onSubmit}
                  style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                  check
                />
              </div>
            </StickyButton>
          </div>
        </>
      </Modal>
    </>
  )
}

export default ProcessPayslip
