import {useMutation, gql} from '@apollo/client'
import planQueryResult from './queryResults/planQueryResult'

const useSetPlanStatus = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $planId: ID!
                $status: PlanStatus!
                $universities: [String!] = null
            ) {
                updateStatusPlan (
                    planId: $planId
                    status: $status
                    universities: $universities
                )  {
                    ${planQueryResult}
                }
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError
        }
    )
}

export default useSetPlanStatus