export const getPaymentItemEnum = (item) => {
    switch (item) {
        case 'plan-payment-item':
            return 'planPaymentItem'
        case 'lesson-payment-item':
            return 'lessonPaymentItem'
        case 'educator-payment-item':
            return 'educatorPaymentItem'
        case 'custom-payment-item':
            return 'customPaymentItem'
        default:
            return item
    }
}
