import React from 'react'
import {
    ModalLayout,
    IconSeen,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useShowLoginInfoModal from './useShowLoginInfoModal'

const ShowLoginInfoModal = (props) => {

    const { isOpen, toggle } = useShowLoginInfoModal()
    const { task } = props

    function passwordVisibility() {
        const passVisibility = document.getElementById('pass')
        if (passVisibility.type === 'password') {
            passVisibility.type = 'text'
        } else {
            passVisibility.type = 'password'
        }
    }

    return (
        <>
            <ModalLayout
                isOpen={isOpen}
                toggle={toggle}
                className='modal-colored modal-sidebar modal-desktop-half white-modal'
                topHead={
                    <div className='d-flex flex-column'>
                        <span className='h2' style={{ color: '#485a70' }}>
                            {task?.name}
                        </span>
                        <span className='h5 mt-2'>{task?.instructions}</span>
                    </div>
                }
            >

                <>
                    <div className='d-flex flex-column mb-3'>
                        <span className='h4 mb-2'>
                            {/* {translate('counseling.account')} */}Account
                        </span>
                        <input
                            className='h45 pl-3 text-info'
                            style={{
                                border: 'none',
                                backgroundColor: '#f0f4f8',
                                borderRadius: '8px',
                                height: '35px',
                                outline: 'none',
                            }}
                            type='text'
                            id='platform'
                            name='platform'
                            placeholder='Account name'
                            disabled='true'
                            value={task?.resolveTask?.loginInfo?.platform?.name}
                        />
                    </div>

                    <div className='d-flex flex-column mb-3'>
                        <span className='h4 mb-2'>
                            {/* {translate('counseling.user')}  */} User
                        </span>
                        <input
                            className='h45 pl-3 text-info'
                            style={{
                                border: 'none',
                                backgroundColor: '#f0f4f8',
                                borderRadius: '8px',
                                height: '35px',
                                outline: 'none',
                            }}
                            type='text'
                            id='user'
                            name='user'
                            placeholder='username or email'
                            defaultValue={
                                task?.resolveTask
                                    ? task?.resolveTask?.loginInfo?.platform?.username
                                    : ''
                            }
                            disabled={task?.status !== 'pending'}
                        />
                    </div>

                    <div className='d-flex flex-column mb-4'>
                        <span className='h4 mb-2'>
                            {/* {translate('counseling.password')}  */} Password
                        </span>
                        <div>
                            <div
                                className='w-100 pr-2 pl-3 d-flex flex-row'
                                style={{
                                    border: 'none',
                                    backgroundColor: '#f0f4f8',
                                    borderRadius: '8px',
                                    height: '35px',
                                    outline: 'none',
                                }}
                            >
                                <input
                                    className='h45 w-100 d-flex flex-row text-info'
                                    style={{
                                        border: 'none',
                                        backgroundColor: '#f0f4f8',
                                        width: '90%',
                                        height: '100%',
                                        outline: 'none',
                                    }}
                                    type='password'
                                    id='pass'
                                    name='pass'
                                    placeholder='password'
                                    defaultValue={
                                        task?.resolveTask
                                            ? task?.resolveTask?.loginInfo?.platform?.password
                                            : ''
                                    }
                                    disabled={task?.status !== 'pending'}
                                />
                                <IconSeen
                                    style={{ cursor: 'pointer' }}
                                    className='align-self-center'
                                    color='#c4cad2'
                                    onClick={() => passwordVisibility()}
                                />
                            </div>
                        </div>
                    </div>
                </>


            </ModalLayout>

        </>
    )
}

ShowLoginInfoModal.defaultProps = {
    isOpen: false,
    toggle: () => { },
}

ShowLoginInfoModal.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
}

export default ShowLoginInfoModal
