import {useQuery, gql} from '@apollo/client'


const useAllTimelinesServices = (variables = {}) => {
    return useQuery(
        gql`
            query {
                allTimelinesServices {
                    id
                    name
                }
            }
        `,
        {
            variables
        }
    )
}

export default useAllTimelinesServices