import React from 'react'
import {
  Field,
  CheckboxOption
} from '@Knowledge-OTP/znk-ui-components'

const InputLpw = (props) => {
  const { topic, form, setForm, part, lock, title } = props
  const change = (val) => {
    setForm({ ...form, lpw: { ...form.lpw, [part]: { ...form.lpw[part], [topic.id]: { ...form.lpw[part][topic.id], value: val } } } })
  }
  // console.log("topic.id", topic.id)
  // console.log("part", part)
  // console.log("form.lpw", form.lpw)
  return (
    <div className='row m-0'>
      <label className='col-12 text-muted mb-2 mt-3 px-0'>
        {title}
      </label>
      <div className='col-9 px-0 d-flex'>
        <div
          className='col-3 znk-input text-center d-flex justify-content-center align-items-center' onClick={() => {
            if (form.lpw[part][topic.id]) {
              if (form.lpw[part][topic.id].value > 0) {
                change(
                  form.lpw[part][topic.id]
                    ? form.lpw[part][topic.id].value ? parseInt(form.lpw[part][topic.id].value) - 1 : 0 : 0)
              }
            }
          }} style={{ userSelect: 'none', borderRadius: '12px 0px 0px 12px' }}
        >-
        </div>
        <input
          id={topic.id}
          className='znk-input col-5'
          type='number'
          name={`lpw['${topic.id}']`}
          placeholder='0'
          min={0}
          onChange={(e) => {
            change(parseInt(e.target.value))
          }}
          style={{ textAlignLast: 'center', borderRadius: '0px', margin: '0px 3px' }}
          value={form.lpw[part][topic.id] ? form.lpw[part][topic.id].value ? form.lpw[part][topic.id].value : 0 : 0}
        />
        <div
          className='col-3 znk-input text-center d-flex justify-content-center align-items-center' onClick={() => {
            change(
              form.lpw[part][topic.id]
                ? form.lpw[part][topic.id].value ? parseInt(form.lpw[part][topic.id].value) + 1 : 1 : 1
            )
          }} style={{ userSelect: 'none', borderRadius: '0px 12px 12px 0px' }}
        >+
        </div>
      </div>
      {lock && (
        <div
          className='col-3 znk-input text-center d-flex justify-content-center align-items-center text-muted'
          style={{
            userSelect: 'none',
            backgroundColor: 'white',
            border: '1px solid rgb(220, 225, 231)'
          }}
        >
          <Field
            type={CheckboxOption}
            fieldName={`lpw[${part}][${topic.id}].lock`}
          />
          Lock
        </div>
      )}
    </div>
  )
}

export default InputLpw
