import {useLazyQuery, gql} from '@apollo/client'
import educatorNotesResult from '../../../../../../Model/QueryResult/useEducatorNotes'
const useLessons = () => {
    return useLazyQuery(
        gql`query (
            $planId: String
            $sortField: String
            $previous: String
            $next: String
            $limit: Int
            $status: [LessonStatus!]!
            ) {
              lessons(
                planId: $planId
                sortField: $sortField
                previous: $previous
                next: $next
                limit: $limit
                status: $status
              ){
              hasNext
              hasPrevious
              totalDocs
              docs {
                  id
                  status
                  title
                  description
                  educator {
                      educatorUserId
                      firstName
                      lastName
                      rate
                      isManualRate
                      ignoreAvailabilty
                      ignorePaymentItem
                  }
                  service {
                      id
                      name
                  }
                  topic {
                      id
                      name
                  }
                  commentForEducator
                  recordingUrl
                  braincertId
                  backupLink
                  recurringId
                  kind
                  startDate
                  endDate
                  busy
                  userIdsInvolved
                  notes {
                      status
                      educatorNotes {
                        ${educatorNotesResult}
                      }
                      sentTime
                  }
                  ... on PlanLesson {
                    part
                    student {
                      studentUserId
                      planId
                      firstName
                      lastName
                      rate
                      attendanceStatus
                      isManualRate
                      ignoreAvailabilty
                      whatsappAlertSent
                      wasLate
                      rating
                    }
                  }
                  ... on GroupLesson {
                      students {
                          studentUserId
                          planId
                          firstName
                          lastName
                          rate
                          attendanceStatus
                          isManualRate
                          ignoreAvailabilty
                          whatsappAlertSent
                          wasLate
                          rating
                      }
                  }
              }
            }
        }`
    )
}

export default useLessons