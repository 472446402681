import {useDispatch, useSelector} from 'react-redux'

const useSendWeeklyInsightModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.analitics.sendWeeklyInsight)
    const {isOpen} = reduxData
    const setData = (open = false, onChange = () => {}, useMutation = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'analitics.sendWeeklyInsight',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                useMutation
            }
        })
    }

    return {
        ...reduxData,
        open: (onChange = () => {}, useMutation = false) => {setData(true, onChange, useMutation)},
        close: () => {setData(false)},
        toggle: () => {setData(!isOpen)
        }
    }
}

export default useSendWeeklyInsightModal