import { gql, useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import useExcelDownload from './useExcelDownload'
import userQueryResult from '../../../Model/Users/QueryResults/userQueryResult'

const useDownLoadExcelUsers = () => {
    const exportToCSV = useExcelDownload()
  return useLazyQuery(
    gql`query(
        $role: Roles!, 
        $limit: Int, 
        $previous: String, 
        $next: String, 
        $search: String, 
        $serviceIds: [ID!], 
        $topicIds: [ID!], 
        $testDate: DateTime, 
        $classYear: [Int!], 
        $countriesIds: [ID!], 
        $statuses: Boolean, 
        $showInactive: Boolean
    ) {
      allUsersFilter(role: $role, limit: $limit, previous: $previous, next: $next, search: $search, serviceIds: $serviceIds, topicIds: $topicIds, testDate: $testDate, classYear: $classYear, countriesIds: $countriesIds, statuses: $statuses, showInactive: $showInactive) {
        hasNext
        hasPrevious
        next
        previous
        totalDocs
        docs {
          ${userQueryResult}  
        }
      }
    }
  `,
    {
        fetchPolicy: 'no-cache',
        onCompleted: ({allUsersFilter}) => {
            exportToCSV(
                allUsersFilter?.docs?.map((user) => {
                    const {
                        firstName,
                        lastName,
                        country,
                        emails,
                        studentInfo,
                        phone
                    } = user

                    return {
                        user: `${firstName} ${lastName}`,
                        country: country?.name,
                        email: emails?.[0]?.address,
                        classOf: studentInfo?.graduationDate ? moment(studentInfo?.graduationDate).format('YYYY') : '--',
                        phone: phone || '--',
                    }
                }), 
                "Users"
            )
        }
    }
  )
}

export default useDownLoadExcelUsers