import React, { useState, useEffect } from 'react'
import {
    ModalLayout,
    ListItem,
    IconArrowRight,
    ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
import { TabContent, TabPane, Badge } from 'reactstrap'
import useViewTaskModal from './useViewTaskModal'
import DeleteTaskModal from '../deleteTaskModal'

import ShowUserAvatar from './Components/ShowUserAvatar'
import ShowActionByStatus from './Components/ShowActionByStatus'
import ShowTaskStatus from './Components/ShowTaskStatus'

import useOptionsByStatus from './Components/useOptionsByStatus'
import DaysToComplete from './Components/DaysToComplete'

import IsSpecific from './Components/IsSpecific'

import VirtualChat from '../../../VirtualChat'
import collectionType from '../../../VirtualChat/utils/collectionType'
import parseUsersList from '../../../VirtualChat/utils/parseUsersList'
import { useChatNotification } from '../../../VirtualChat/Model/useChatNotification'
import taskTypes from '../../../University/Utils/taskTypes'

const RenderOption = ({ Icon, left, onClick, status = 'primary', disabled = false, showArrow = true, showBottomLine = false, className, iconToShow = null, ...otherProps }) => {
    //const color = status === 'primary' ? status : status === 'danger' ? 'red' : status
    let colorText = status === 'primary' ? 'text-primary' : status === 'danger' ? 'text-error' : status
    if (disabled) colorText = 'text-gray'
    return (
        <ListItem
            hasHover={!disabled}
            className={`py-4 px-2 hover-no-border ${className}`}
            onClick={() => {
                if (disabled) return
                if (onClick) onClick()
            }}
            Left={
                <div className='d-flex flex-row'>
                    {Icon && <Icon className={`align-self-center mr-2 ${colorText}`} />}
                    <span className={`align-self-center ${colorText}`}>{left}</span>
                </div>
            }
            Right={
                !disabled ?
                    <div className={`hover-icon`}>
                        {showArrow ? <IconArrowRight /> : iconToShow}
                    </div> : null
            }
            showBottomLine={showBottomLine}
            {...otherProps}
        />
    )
}
const ViewTaskModal = () => {
    const { isOpen, toggle, taskReducer: { state: taskData, setData }, onChange, useMutation } = useViewTaskModal()
    const [teamTask, setTeamTask] = useState({})
    const [showTab, setShowTab] = useState('menu')
    const {
        taskName,
        instructions,
        isEspecific,
        isEdit,
        userId,
        status,
        isCreated,
        name,
        type
    } = taskData

    const unreadMsg = taskData?.chatNotification?.councelor
    const meUserId = useSelector((state) => state.user.id)
    const { options } = useOptionsByStatus(status, taskData, useMutation)

    const [usersList, setUsersList] = useState([])

      // eslint-disable-next-line
    const [chatNotification/** , { data: dataChatNotification, loading: loadignChatNotification, refetch: refetchChatNotification }*/] = useChatNotification(   
      () => {
        onChange('edit', { ...taskData, chatNotification: {councelor: 0, student: 0}})
      },
      (err) => {
        console.error('Error with chat notification : ', err)
      },
    )

    useEffect(() => {
        setTeamTask({
            advisor: taskData?.dataPlan?.advisorData || taskData?.dataPlan?.advisor,
            counselor: taskData?.dataPlan?.counselorData || taskData?.dataPlan?.councelor,
            head: taskData?.dataPlan?.headData || taskData?.dataPlan?.head,
            student: taskData?.dataPlan?.studentUser
        })
    }, [taskData])

    useEffect(() => {
        if (showTab === 'chat' && teamTask?.counselor) {
            setUsersList(parseUsersList(userId, teamTask))
              chatNotification({
                variables: {
                    taskId: taskData?.id,
                    stepId: taskData?.stepId,
                    phase: taskData?.phaseId?.replace(/-([a-z])/g, function (g) {
                        return g[1].toUpperCase()
                      }) || taskData?.phase,
                    planId: taskData?.dataPlan?.id,
                    chanel: 'counselingTasks',
                    userIn: true
                  },
              })
        }
        // eslint-disable-next-line
    }, [teamTask, showTab, taskData])

    return (
        <>
            <ModalLayout
                className={`modal-colored modal-sidebar modal-desktop-half white-modal`}
                isOpen={isOpen}
                toggle={() => {
                    setShowTab('menu')
                    toggle()
                }}
                disabledScrollAnimation
                topHead={
                    <span className={`text-title`}
                        style={{ fontSize: '22px' }}
                    >
                        {taskName || name}
                    </span>
                }
                minHead={
                    <span className={`text-title`}
                        style={{ fontSize: '22px' }}
                    >
                        {taskName || name}
                    </span>
                }
            >
                {
                    (userId && status !== 'unassigned') && (
                        <ShowUserAvatar userId={userId} isEdit={false} />
                    )
                }
                <div className='mt-2'>
                {(type === taskTypes.scheduledMeetting && status !== 'unassigned')  && <span className='text-info'>
                    Meeting with:
                </span>}
                </div>
                {
                    taskData?.userMeetingId && (
                        <ShowUserAvatar userId={taskData?.userMeetingId} isEdit={false} />
                    )
                }
                <div className={`d-flex flex-row justify-content-between mx-0 my-4`}>
                    <DaysToComplete task={taskData} />
                    {
                        isEspecific && (
                            <IsSpecific universitiesList={taskData.universities}
                                findInDB={true}
                            />
                        )
                    }
                </div>
                <div className='d-flex flex-row mb-6'>
                    <span className='text-info'>
                        {instructions}
                    </span>
                </div>
                <ShowTaskStatus task={taskData} setData={setData} />
                {
                    isCreated && isEdit && (
                        <ShowActionByStatus
                            onChangeTask={(action, newTask) => {
                                onChange(action, newTask)
                            }}
                            task={taskData}
                            setData={setData}
                            useMutation
                            className={`mb-6`} />
                    )
                }
                <TabContent
                    activeTab={showTab}
                >
                    <TabPane tabId='menu'>
                        <ToolTipInfo disable={isEdit} innerElement={
                            <div className={`d-flex flex-column m-0 p-0`}>
                                <span>Active the edit mode</span>
                            </div>
                        }>
                            {
                                options.map((props, index) => {
                                    if (props?.key === 'chatWithStudent') {
                                        return (
                                            <RenderOption
                                                {...props}
                                                key={index}
                                                showTopLine
                                                showArrow={unreadMsg === 0 ? true : false}
                                                iconToShow={    
                                                <Badge
                                                    className='px-2 badge badge-pill badge-danger p-0'
                                                    pill
                                                >
                                                    {unreadMsg}
                                                </Badge>
                                                      }

                                                disabled={!isEdit}
                                                onClick={() => {    
                                                    setShowTab('chat')
                                                }
                                                }
                                            />
                                        )
                                    }
                                    return (
                                        <RenderOption
                                            {...props}
                                            key={index}
                                            showTopLine
                                            disabled={!isEdit}

                                        />
                                    )
                                })
                            }
                        </ToolTipInfo>
                    </TabPane>
                    <TabPane tabId='chat'>
                        {/* <div className='ml-n2 hover-icon w-10' onClick={() => { setShowTab('menu') }}>
                            <IconArrowLeft size={20} color={'#818ea7'} />
                        </div> */}
                        {/* <div 
                    onClick={()=>{setShowTab('menu')}}
                    className='hover-icon'>
                        <IconArrowLeft size={12} />
                  </div> */}
                        <VirtualChat
                            {...taskData}
                            collectionType={collectionType[taskData?.__typename] || 'messagesTask'}
                            usersList={usersList}
                            collection={taskData}
                            actualUserId={meUserId}
                            onBack={() => {setShowTab('menu')}}
                            toggle={() => {
                                toggle()
                            }}
                        />
                    </TabPane>
                </TabContent>
            </ModalLayout>
            <DeleteTaskModal />
        </>
    )
}

export default ViewTaskModal