import { combineReducers } from 'redux'
import modal from './modal/reducer'
import translation from './translation/reducer'
import timezone from './timezone/reducer'
import alert from './alert/reducer'
import user from './user/reducer'
import platform from './platform/reducer'
import clipboard from './clipboard/reducer'
import save from './save/reducer'

export default combineReducers({
  modal,
  translation,
  timezone,
  alert,
  user,
  platform,
  clipboard,
  save
})
