import React from 'react'
// import useTranslation from '../../../../i18n/useTranslation'

const StatisticsComponent = (props) => {
    const { universityData } = props
    //   const {translate} = useTranslation()
    const noInfo = 'No info'
    const internationalNumberFormat = new Intl.NumberFormat('en-US')

    return (

        <div className='statistics-information mt-4'>
            <div className='d-flex flex-row mb-5'>
                <div className='w-50'>
                    <div className='title mb-2'
                        style={{
                            fontSize: '13px',
                            color: '#AAB7C4'
                        }}>
                        Acceptance Rate
                    </div>
                    <div className='sub-title'
                        style={{ color: '#2C3F78' }}>
                        {universityData?.acceptanceRate
                            ? Math.round(universityData?.acceptanceRate * 100) + '%'
                            : noInfo}
                    </div>
                </div>
                <div className='w-50'>
                    <div className='title mb-2'
                        style={{
                            fontSize: '13px',
                            color: '#AAB7C4'
                        }}>
                        Graduation Rate
                    </div>
                    <div className='sub-title'
                        style={{ color: '#2C3F78' }}>
                        {universityData?.fourYearGraduationRate
                            ? Math.round(universityData?.fourYearGraduationRate * 100) + '%'
                            : noInfo}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-row mb-5'>
                <div className='w-50'>
                    <div className='title mb-2'
                        style={{
                            fontSize: '13px',
                            color: '#AAB7C4'
                        }}>
                        SAT Average
                    </div>
                    <div className='sub-title'
                        style={{ color: '#2C3F78' }}>
                        {universityData?.satAverage ? universityData?.satAverage : noInfo}
                    </div>
                </div>
                <div className='w-50'>
                    <div className='title mb-2'
                        style={{
                            fontSize: '13px',
                            color: '#AAB7C4'
                        }}>
                        ACT Average
                    </div>
                    <div className='sub-title'
                        style={{ color: '#2C3F78' }}>
                        {universityData?.actCumulativeMidpoint
                            ? universityData?.actCumulativeMidpoint
                            : noInfo}
                    </div>
                </div>
            </div>
            <div className='d-flex flex-row mb-5'>
                <div className='w-50'>
                    <div className='title mb-2'
                        style={{
                            fontSize: '13px',
                            color: '#AAB7C4'
                        }}>
                        Number of Years
                    </div>
                    <div className='sub-title'
                        style={{ color: '#2C3F78' }}>
                        {universityData?.typeYear ? universityData?.typeYear : noInfo}
                    </div>
                </div>
                <div className='w-50'>
                    <div className='title mb-2'
                        style={{
                            fontSize: '13px',
                            color: '#AAB7C4'
                        }}>
                        Average Cost after Aid
                    </div>
                    <div className='sub-title'
                        style={{ color: '#2C3F78' }}>
                        {universityData?.avgCostOfAttendance
                            ? '$' +
                            internationalNumberFormat.format(
                                universityData?.avgCostOfAttendance,
                            )
                            : noInfo}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticsComponent
