import React from 'react'
import {
    ModalLayout, 
    useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useCompletePlanModal from './useCompletePlan'
import InnerCompletePlanModal from './inner'
import useTranslation from '../../../../i18n/useTranslation'

const CompletePlanModal = () => {
    const modalData = useCompletePlanModal()
    const {isOpen, toggle, onChange, dataPlan, useMutation} = modalData
    const {translate} = useTranslation()
    const {breakWidth} = useWindowDimensions()
    
    return (
        <ModalLayout
            className={`modal-${
                breakWidth === 'SM' ? 'botbar' : 'centered'
              } modal-colored h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h2 font-weight-bold text-title`}>{translate("plans.modal.completeplan.title") || "Complete Plan"}</span>
            }
        >
            <InnerCompletePlanModal onChange={onChange} dataPlan={dataPlan} useMutation={useMutation} />
        </ModalLayout>
    )
}

export default CompletePlanModal