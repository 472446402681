import { Form, IconClose, Modal, Field, PlainTextInput, AmountInput, StickyButton, Button, SelectCheck } from '@Knowledge-OTP/znk-ui-components'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import addCustomService from '../../../../Model/Users/Student/SetServiceRateToStudent'
import removeCustomService from '../../../../Model/Users/Student/RemoveServiceRateFromStudent'
import { FormGroup, Input, Label } from 'reactstrap'
import useTeachingTypesToEducatorService from '../../../../Model/Users/Educators/SetTeachingTypesToEducatorService'
import removeServiceFromEducator from '../../../../Model/Users/Educators/removeServiceFromEducator'

const EditCustomService = (props) => {
  const { services } = props
  const { isOpen, typeUser, userId, ...formData } = useSelector((state) => state.modal.user.customService)
  const { form, item, element } = formData
  const [serForm, setSerForm] = useState({
    teachingTypes: [],
    individualAmount: 0,
    groupAmount: 0,
    isWaiveIndividual: false,
    isWaiveGroup: false
  })
  const dispatch = useDispatch()
  const toggle = () => {
    setSerForm({
      teachingTypes: [],
      individualAmount: 0,
      groupAmount: 0,
      isWaiveIndividual: false,
      isWaiveGroup: false
    })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.customService', data: { isOpen: false } })
  }
  useEffect(() => {
    if (item) {
      if (typeUser === 'student') {
        setSerForm(pre => ({
          ...pre,
          idservice: item.serviceId,
          amount: item.defaultRate,
          userId: userId
        }))
      } else {
        setSerForm(pre => ({
          ...pre,
          idservice: item.serviceId,
          teachingTypes: element.teachingTypes,
          individualAmount: element.individualRate,
          groupAmount: element.groupRate,
          isWaiveIndividual: element.isWaiveIndividual,
          isWaiveGroup: element.isWaiveGroup
        }))
      }
    }
  }, [item, userId, element, typeUser])
  const get = (value) => {
    if (form && services) {
      const result = services.filter(e => e.id === item.serviceId)
      return result.length ? result[0][value] : ''
    }
  }
  const { submit, loading } = addCustomService(() => {
    toggle()
  }, console.log)
  const { submit: SubmitEducator, loading: loadingEducator } = useTeachingTypesToEducatorService(() => {
    toggle()
  }, console.log)
  const onSubmit = () => {
    if (typeUser === 'student') {
      const newVal = {
        userId: userId,
        studentServiceRateInput: {
          serviceId: serForm.idservice,
          defaultRate: serForm.amount
        }
      }
      submit(newVal)
    } else {
      const newVal = {
        userId: userId,
        serviceId: item.serviceId,
        topicId: element.topicId,
        teachingTypes: serForm.teachingTypes,
        individualRate: serForm.individualAmount,
        groupRate: serForm.groupAmount,
        isWaiveIndividual: serForm.isWaiveIndividual,
        isWaiveGroup: serForm.isWaiveGroup
      }
      SubmitEducator(newVal)
    }
  }
  const { submit: submitRemove, loading: loadingRemove } = removeCustomService(() => {
    toggle()
  }, console.log)
  const { submit: submitRemoveEducator, loading: loadingRemoveEducator } = removeServiceFromEducator(() => {
    toggle()
  }, console.log)
  const removeService = () => {
    if (typeUser === 'student') {
      submitRemove({ serviceId: item.serviceId, userId: userId })
    } else {
      submitRemoveEducator({ serviceId: item.serviceId, userId: userId, topicId: element.topicId })
    }
  }


  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{typeUser === 'student' ? 'Edit Services Rate' : item ? `${item.name} - ${element.name}` : ''}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={serForm}>
          <div className='m-0 p-0 mb-2 px-3'>
            {
              typeUser === 'student' &&
              (
                <>
                  <label className='text-gray font-weight-light mb-2'>Service</label>
                  <Field
                    className='col-12'
                    type={PlainTextInput}
                    fieldType='text'
                    value={get('name')}
                    disabled
                  />
                  <label className='text-gray font-weight-light mt-3 mb-2'>Amount</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={AmountInput}
                    fieldName='amount'
                    fieldType='number'
                    placeholder='$  0.00 '
                    min='0'
                    onChange={(value) => {
                      setSerForm(pre => ({
                        ...pre,
                        amount: value
                      }))
                    }}
                  />
                </>
              )
            }
            {
              typeUser === 'educator' &&
              (
                <>
                  <Field
                    className='col-12 row inline'
                    type={SelectCheck}
                    fieldName='typePlan'
                    options={[
                      { id: 'individual', text: 'Individual' },
                      { id: 'group', text: 'Group' }
                    ]}
                    StyleOptionClassName={{
                      width: '49%',
                      whiteSpace: 'nowrap'
                    }}
                    StyleSelect={{
                      justifyContent: 'space-between'
                    }}
                    value={serForm.teachingTypes}
                    onChange={
                      (value) => {
                        setSerForm(pre => ({
                          ...pre,
                          teachingTypes: value
                        }))
                      }
                    }
                  />
                  <label className='text-gray font-weight-light mt-3 mb-2'>Individual wage</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={AmountInput}
                    fieldName='individualAmount'
                    fieldType='number'
                    placeholder='$  0.00 '
                    disabled={!serForm.teachingTypes.includes('individual')}
                    min='0'
                    onChange={(value) => {
                      setSerForm(pre => ({
                        ...pre,
                        individualAmount: value
                      }))
                    }}
                  />
                  <label className='text-gray font-weight-light mt-2 mb-2'>Group wage</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={AmountInput}
                    fieldName='groupAmount'
                    fieldType='number'
                    placeholder='$  0.00 '
                    disabled={!serForm.teachingTypes.includes('group')}
                    min='0'
                    onChange={(value) => {
                      setSerForm(pre => ({
                        ...pre,
                        groupAmount: value
                      }))
                    }}
                  />
                  <label className='text-gray font-weight-light mt-4 mb-2'>Payments</label>
                  <FormGroup check className='mt-3'>
                    <Label check>
                      <Input
                        type='checkbox'
                        defaultChecked={serForm.isWaiveIndividual}
                        disabled={!serForm.teachingTypes.includes('individual')}
                        onChange={() => {
                          setSerForm(pre => ({
                            ...pre,
                            isWaiveIndividual: !serForm.isWaiveIndividual
                          }))
                        }}
                      />{' '}
                      Waive individual lessons
                    </Label>
                  </FormGroup>
                  <FormGroup check className='mt-3'>
                    <Label check>
                      <Input
                        type='checkbox'
                        defaultChecked={serForm.isWaiveGroup}
                        disabled={!serForm.teachingTypes.includes('group')}
                        onChange={(e) => {
                          setSerForm(pre => ({
                            ...pre,
                            isWaiveGroup: !serForm.isWaiveGroup
                          }))
                        }}
                      />{' '}
                      Waive group lessons
                    </Label>
                  </FormGroup>
                </>
              )
            }
            <label className='font-weight-light mt-3 mb-2 mt-2 btn-link text-error cursor' onClick={removeService}>Remove service</label>
            <div className='row d-flex m-0 p-0 mt-5'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loading || loadingRemove || loadingEducator || loadingRemoveEducator}
                      className='col-12 aling-text-left'
                      label='Save'
                      onClick={onSubmit}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default EditCustomService
