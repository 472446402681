import React from 'react'
import { TabContent, TabPane } from 'reactstrap'
import useActionByStatus from './useActionByStatus'

const ShowActionByStatus = (props) => {
    const { task, setData, className, onChangeTask = () => { } } = props
    const {actions} = useActionByStatus({task, setData, onChangeTask})
    return (
        <div className={`${className}`}>
            <TabContent activeTab={task?.status}>
                {
                    actions.map(
                        ({status, onClick, Inner, props}) => {
                            return (
                                
                                <TabPane tabId={status}>
                                    <Inner
                                        onClick={onClick}
                                        {...props}
                                    />
                                </TabPane>
                            )
                        }
                    )
                }
            </TabContent>
        </div>
    )
}

export default ShowActionByStatus