import validate from 'validate.js'

const constraints = {
  userId: {
    presence: {
      allowEmpty: false
    }
  },
  typeUser: {
    presence: {
      allowEmpty: false
    }
  },
  backupLink: {
    presence: {
      allowEmpty: false
    }
  },
  weeklyCapHours: {
    presence: {
      allowEmpty: false
    }
  },
  googleCalendar: {
    presence: {
      allowEmpty: false
    }
  },
  handbook: {
    presence: {
      allowEmpty: false
    }
  }
}

export default (data) => {
  return validate(data, constraints)
}
