import React from 'react'

/** maqueta de input para formulario **/
const RenderInputOption = ({label, children, className, style = {}}) => {
    //reemplaza a la clase 'my-3' solo si className contiene otra clase de tipo 'my-X'
    const replaceClass = /^(my-[0-9])$/.test(className)
    return ( 
        <div className={`d-flex flex-column ${replaceClass ? className : `my-3 ${className}`}`} style={style}>
            <span className={`h45 text-gray mb-2`}>{label}</span>
            {children}
        </div>
    )    
}

export default RenderInputOption