import React, {useMemo} from 'react'
import {
    Button, IconTask
} from '@Knowledge-OTP/znk-ui-components'
import DaysToComplete from '../../../../../CounselingTemplates/Task/viewTaskModal/Components/DaysToComplete'
import useActionByStatus from '../../../../../CounselingTemplates/Task/viewTaskModal/Components/ShowActionByStatus/useActionByStatus'
import IsSpecific from '../../../../../CounselingTemplates/Task/viewTaskModal/Components/IsSpecific'
import SelectMultipleTask from './SelectMultipleTask'

const DateField = (props) => {
    const { task = {}, onChangeTask = () => {}, className } = props
    const isReview = task?.status === 'review'
    const isCompleted = task?.status === 'completed'
    const isUnassigned = task?.status === 'unassigned' || !(task?.userId)
    const {actions} = useActionByStatus({ 
        task, 
        setData: () => {}, 
        onChangeTask })
    const realStatus = useMemo(
        () => {
            return !(task?.userId) ? 'unassigned' : task?.status
        }, [task]
    )    
    const action = actions.find(({status}) => status === realStatus)
    const haveMessages = task?.chatNotification?.councelor > 0
    return (
        <div className={`m-0 p-0 d-flex flex-row w-auto ${className}`} >
            {
                task?.isEspecific && (
                    <IsSpecific
                        className={`d-flex flex-row align-items-center `}
                        findInDB
                        showLabel={false}
                        taskId={task?.id || task?.taskId}
                        showTooltip={task?.universities?.length > 0}
                        universitiesList={task?.universities || []}
                    />
                )
            }
            {
                haveMessages ?
                    <span className={`h45 text-gray`}>{`Pending messages (${task.chatNotification.councelor})`}</span> :
                    isReview || isUnassigned ?
                        <>
                            {
                                isUnassigned && (
                                    <div className='d-flex flex-row align-items-center ml-3'>
                                        <SelectMultipleTask task={task}/>
                                    </div>
                                )
                            }
                            <Button
                                className={`h45 m-0 py-0 px-2 w-auto ml-2 ${isUnassigned ? 'btn-blue-border' : ''}`}
                                size={`small`}
                                color={isReview ? `primary` : null}
                                stopPropagation={isReview ? true : false}
                                label={action?.label}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.nativeEvent.stopImmediatePropagation()
                                    if(action?.onClick) action.onClick()
                                }}
                            />
                        </> :
                        isCompleted ? 
                        <div className={`d-flex flex-row m-0 p-0`}>
                            {/* <span onClick={() => {
                                if(action?.onClick) action.onClick()
                            }} className={`h45 text-attendedText ml-2`}>{action?.label}</span> */}
                            <DaysToComplete task={task} noIcon type={`date`} normalClass={`text-gray`} />
                            <IconTask 
                                color='#9fda1f'
                                fill='#ffffff'
                                size={16}
                                className={`ml-2`} />
                        </div> :
                            <DaysToComplete task={task} noIcon type={`date`} normalClass={`text-gray`} />
            }
        </div>
    )
}

export default DateField