import { useLazyQuery, gql } from "@apollo/client"

export const UseUserQuery = () => {
    return useLazyQuery(
        gql`
        query($id: String!) {
          user(id: $id) {
            id
            emails {
              address
              verified
            }
            countryId
            gender
            spokenLanguages
            address
            city
            state
            zipCode
            firstName
            lastName
            phone
            timezone
            birthdate
            studentInfo {
              institution
              graduationDate
              studentType
              gpa
              servicesRate {
                defaultRate
                name
                serviceId
              }
              parents {
                parentUserId
                managePayments
                manageApprovals
                firstName
                lastName
                email
              }
            }
            isActive
            roles
            avatar
          }
        }
      `
    )
}

export default UseUserQuery