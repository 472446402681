import { useMutation, gql } from "@apollo/client"

const useEditCheckIn = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $checkInInput: CheckInDateInput!
                $checkInId: String!
            ) {
                editCheckIn (
                    checkInInput: $checkInInput
                    checkInId: $checkInId
                ) {
                    id
                    date
                    type
                }
            }
        `, {onCompleted, onError}
    )
}

export default useEditCheckIn