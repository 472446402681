import React from "react";

const useDesktopColumns = () => {

  return [
    {
      render: (item) => {
        const classOf = item?._id 
        return (
          <div className={`d-flex flex-row align-items-center m-0 p-0`}>
            <span className={`ml-2`}>{`Class of ‘${classOf}`} </span>
          </div>
        );
      },
      colSpan: 4,
      id: "classOf",
    },
    {
      render: (item) => {
        const totalStudents = item?.studentsByClass?.length
        return (
          <div className='d-flex flex-row align-items-flex-end m-0 p-0'>
            <span className='text-gray'>
              {`Total Students: ${totalStudents}`}
            </span>
          </div>
        )
      },
      className: `d-flex flex-row justify-content-end m-0 p-0`,
      colSpan: 4,
      id: "totalStudents",
    }
  ];
};

export default useDesktopColumns;
