import React, { useState } from 'react'
import useSetRecurrentAvailability from '../../../../../../Model/Users/Educators/SetRecurrentAvailability'
import { Button, RecurrentAvailabilityCalendar } from '@Knowledge-OTP/znk-ui-components'
import omit from 'lodash/omit'

const RecurrentAvailability = (props) => {
  const { formData } = props

  const [selectedDays, setSelectedDays] = useState(() => formData.availabilityBlocks.map(item => ({ val: omit(item, ['__typename']), id: `${item.day}-${item.hour}` })))
  const { submit, loading } = useSetRecurrentAvailability(console.log, console.log)
  const onSubmit = () => {
    submit({
      recurrentAvailability: {
        blocks: selectedDays.map(block => block.val)
      },
      educatorId: formData.educatorUserId
    })
  }
  return (
    <>
      <RecurrentAvailabilityCalendar
        loading={loading}
        selectedBlocks={selectedDays}
        addSelected={(item) => setSelectedDays((blocks) => blocks.concat(item))}
        removeSelected={(blockId) => setSelectedDays((blocks) => blocks.filter(item => item.id !== blockId))}
      />
      <Button
        color='primary'
        check
        className='w-100 my-2'
        label={`Save ${selectedDays.length} blocks`}
        disabled={selectedDays.length === 0}
        onClick={onSubmit}
      />
    </>
  )
}

export default RecurrentAvailability
