import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation update($userEducatorId: ID!, $status: Boolean!, $userStatus: Boolean = null){
    setEducatorStatus(userEducatorId: $userEducatorId, status: $status, userStatus: $userStatus) {
        id
    }
  }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.setEducatorStatus : null, error, validationErrors, submit }
}
