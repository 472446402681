import React from 'react'
import { IconClose } from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import SetStudentRateContent from './setStudentRateContent'

const SetStudentServicesRateModalForm = (props) => {
  const { toggleRightSide } = props
  const { 
    typeUser
  } = useSelector((state) => state.modal.user.servicesRate)
 

  const dispatch = useDispatch()
  const closeOver = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRate', data: { isOpen: false } })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.servicesRateShort', data: { isOpen: false } })
  }
  
  
  return (
    <>
      <div className='w-100 py-5 px-8 text-title'>
        <span className='d-flex w-100 flex-row justify-content-between'>
          <h2 className='font-weight-bold'>{typeUser === 'student' ? 'Services Rates' : 'Employment Details'}</h2>
          <IconClose size={20} onClick={toggleRightSide || closeOver} color='rgba(119, 134, 153, .4)' />
        </span>
        <SetStudentRateContent {...props} />
      </div>
    </>
  )
}

export default SetStudentServicesRateModalForm
