import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'

export default function useDeleteNote(onSuccess, onError){
    const [mutation, { data, error, loading }] = useMutation(
        gql`mutation deleteNote($userId: String!, $noteId:String!) {
            deleteNote(userId: $userId, noteId: $noteId){
                id
            }
        }`
    )

    const [validationErrors, setValidationErrors] = useState({})

    const submit = useCallback((obj) => {
        mutation({ variables: obj }).then((result) => {
            onSuccess(result)
        }).catch((e) => {
            setValidationErrors({ general: 'misc.apiError' })
            onError(e)
        })
    }, [mutation, onSuccess, onError])

    return {  loading, data , error, validationErrors, submit }
}
