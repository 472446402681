import React, { useState } from 'react'
import {
  ConfirmModal,
  Activity
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useApolloClient } from '@apollo/client'

const CancelMultipleLessons = (props) => {
  const { refetchCalendarQuery, setLessonsSelected } = props
  const { isOpen, lessonsId } = useSelector((state) => state.modal.lesson.cancelMultiple)
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const client = useApolloClient()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'lesson.cancelMultiple',
      data: {
        isOpen: false,
        lessonsId
      }
    })
  }
  const [loading, setLoading] = useState(false)
  return (
    <ConfirmModal
      className=''
      classNameActionDiv='flex-column'
      classNameConfirm='text-right text-error'
      classNameCancel='mx-3 text-right'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('lesson.multipleselection.sureremove')}
      body={translate('lesson.multipleselection.cannotundone')}
      confirmAction={() => {
        let mutation = lessonsId.reduce((stringMutation, lesson, index) => {
          return stringMutation + `remove${index}: cancelLessonFromAdmin (cancelFuture: false, lessonId: "${lesson}"){id}\n`
        }, '')
        mutation = 'mutation {\n' + mutation + '}'
        try {
          setLoading(true)
          client.mutate({
            mutation: gql`${mutation}`,
            update: () => {
              // ejecutar refetch del calendario
              refetchCalendarQuery()
              //deselecciona las lecciones eliminadas
              setLessonsSelected([])
              setLoading(false)
              toggle()
            }
          })
        } catch {
          // error en try
          console.error('Error al ejecutar petición cancelLessonFromAdmin.')
          setLoading(false)
        }
      }}
      cancelAction={toggle}
      confirmText={loading ? <Activity /> : translate('lesson.multipleselection.yesremove')}
      cancelText={translate('modal.cancellesson.cancel')}
    />
  )
}

export default CancelMultipleLessons
