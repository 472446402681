import React, {useEffect} from 'react'
import {
    ListItem,
    IconLink
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'
import { UseGetCourseMaterials } from './Model/useGetCourseMaterials'
import NewCourseMaterials from './modal/NewCourseMaterials'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../store/modal/const'
import Services from '../../../../../../Model/Services/List'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../../../common/Alert/util'
import Skeleton from 'react-loading-skeleton'

const CourseMaterialsStudent = (props) => {
    const { userId } = props
    const { translate } = useTranslation()
    const [getCourseMaterials, {data, loading: loadCourseMaterials, refetch}] = UseGetCourseMaterials()
    const dispatch = useDispatch()
    const { data: dataServices } = Services({ limit: 0 })
    const services = dataServices?.docs?.filter((serv) => !serv.isOrientation) || []
    useEffect(() => {
        if(userId) {
            getCourseMaterials({
                variables: {
                    userStudentId: userId
                }
            })
        }
    }, [userId, getCourseMaterials])
    const courseMaterials = data?.getCourseMaterials || []
    
    const openModalNew = (course = {}) => {
        //abrir modal de edición
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'student.newCourseMaterials',
            data: {
                isOpen: true,
                courseMaterial: course
            }
        })
    }
    return (
        <div className='w-100 p-0 m-0'>
            <div className='container-fluid flex-column m-0 p-0'>
                { 
                    !loadCourseMaterials ? 
                        courseMaterials?.length > 0 ?
                            (courseMaterials).map((course) => {
                                const {serviceId, topicId, link} = course
                                const service = services.find((serv) => serv.id === serviceId) || {}
                                const topic = service.topics.find((top) => top.id === topicId) || {}
                                return (
                                    <ListItem
                                        showBottomLine
                                        className='py-4 w-100'
                                        styleLeft={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden'
                                        }}
                                        Left={
                                            <div 
                                                className='d-flex flex-row'
                                            >
                                                <span 
                                                    className='align-self-center text-title' 
                                                    style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} 
                                                    title={`${service?.name}/${topic?.name}`}>
                                                    {`${service?.name}/${topic?.name}`}
                                                </span>
                                            </div>
                                        }
                                        Right={
                                            <div className={`m-0 p-0 d-flex flex-row align-items-center`}>
                                                <span
                                                    onClick={() => {openModalNew(course)}} 
                                                    className={'mr-2 znk-menu-option'} 
                                                    style={{
                                                        textOverflow: 'ellipsis', 
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        maxWidth: '150px'
                                                    }} 
                                                    title={link} >{
                                                    link || 
                                                    translate(
                                                        `profile.actions.editservices.materialsTopic.courseNoDefined`
                                                        )
                                                    }</span>
                                                {
                                                    link && (
                                                        <div 
                                                            className={`hover-icon`} 
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(link).then(() => {
                                                                    showAlert({
                                                                        text: translate(
                                                                            `profile.actions.editservices.materialsTopic.copySuccess`
                                                                            ),
                                                                        status: 'success'
                                                                    }, dispatch)
                                                                }, (err) => {
                                                                    showAlert({
                                                                        text: translate(
                                                                            `profile.actions.editservices.materialsTopic.copyError`
                                                                            ),
                                                                        status: 'error'
                                                                    }, dispatch)
                                                                })
                                                            }}><IconLink size={15}/></div>
                                                    )
                                                }
                                            </div>
                                        }
                                    />
                                )
                            }) :
                            <span className={`w-100 text-title`}>
                                {translate("profile.actions.editservices.materialsTopic.noData")}
                            </span> :
                        <Skeleton count={5} height={40} />
                }
                <div className={`w-100 d-flex flex-row justify-content-center my-4`}>
                    <span 
                        className={`btn-link`}
                        onClick={() => {openModalNew()}}
                    >{translate("profile.actions.editservices.materialsTopic.newCourse")}</span>
                </div>
            </div>
            <NewCourseMaterials userId={userId} refetch={refetch} dataServices={dataServices} />
        </div>
    )
}

export default CourseMaterialsStudent