import React, {useEffect, useState, useMemo} from 'react'
import {
    ModalLayout,
    Button,
    Form,
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import useCreatePlanModal from './useCreatePlanModal'
import ScheduleDetails from '../../../Plans/modal/newPlan/Components/ScheduleDetails'
import PlanDetails from '../../../Plans/modal/newPlan/Components/PlanDetails'
import Pricing from './Sections/Pricing'
import Comment from './Sections/Comment'
import { useSelector } from 'react-redux'


// const RenderInput = ({label, children, className = ''}) => {
//     return (
//         <div className={`d-flex flex-column m-0 p-0 ${className}`}>
//             <span className={`mb-3`}>{label}</span>
//             {children}
//         </div>
//     )
// }

const defaultForm = {
    cuotas: [],
    planId: '',
    typePlan: 'date',
    lpw: {
      0: {
        partname: 'Part 1'
      }
    },
    statusPlan: 'draft',
    payments: {
      registration: false,
      exam: false,
      pro: false,
      lessons: false,
      discount: {
        registration: 0,
        exam: 0,
        pro: 0,
        lessons: 0
      },
      values: {
        registration: 200,
        exam: 50,
        pro: 50
      }
    }
}

const CreatePlanModal = () => {
    const {toggle, isOpen, onChange, plan, validation} = useCreatePlanModal()
    const [form, setForm] = useState(defaultForm)
    const services = useSelector((state) => state.user.services)
    const currentService = services.find(({id}) => form?.idservice === id)
    const {breakWidth} = useWindowDimensions()
    const isEdit = useMemo(() => {
        if(plan) return true
        return false
    }, [plan])

    useEffect(() => {
        if(plan) setForm({...defaultForm, ...plan, typePlan: 'date'})
        else setForm(defaultForm)
    }, [plan, isOpen])


    return (
        <ModalLayout
            // className={`modal-colored modal-sidebar modal-desktop-half white-modal h-auto`}
            className={`modal-colored modal-centered white-modal h-auto ${breakWidth !== 'SM' ? 'modal-widespread' : ''}`}
            dimensionClassName={`m-0 pl-4 pr-3 py-3`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h3 text-title font-weight-bold`}>
                    Create Plan
                </span>
            }
            underHead={null}
            minHead={
                <span className={`h3 text-title font-weight-bold`}>
                    Create Plan
                </span>
            }
        >
            <div className={`d-flex flex-column`}>
                <Form value={form} onChange={setForm}>
                    <PlanDetails
                        form={form}
                        setForm={setForm}
                        data={{docs: services}}
                        isEdit={isEdit}
                        min
                        disableTypePlan
                    />
                    <ScheduleDetails
                        setForm={setForm}
                        form={form}
                        currentService={currentService}
                    />
                    <Pricing
                        form={form}
                        setForm={setForm}
                    />
                    <Comment
                        form={form}
                        setForm={setForm}
                    />
                </Form>    
                <Button
                    disabled={!!validation(form)}
                    className={`mt-5 mb-3 w-100`}
                    label={'Create plan'}
                    size={`medium`}
                    color={'primary'}
                    onClick={() => {
                        onChange(form)
                    }}
                />
            </div>
        </ModalLayout>
    )
}

export default CreatePlanModal