import {useMutation, gql} from '@apollo/client'
import planQueryResult from '../../../CounselingPlans/Model/queryResults/planQueryResult'

const useOperationTaskMutation = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $universities: [ID!]
                $operation: OperationTask!
                $userId: ID
                $taskId: ID!
                $stepId: ID!
                $phase: PhaseEnum!
                $planId: ID!
                $dueTime: Float
                $userMeetingId: ID
                ) {
                operationTask (
                    universities: $universities
                    operation: $operation
                    userId: $userId
                    taskId: $taskId
                    stepId: $stepId
                    phase: $phase
                    planId: $planId
                    dueTime: $dueTime
                    userMeetingId: $userMeetingId
                )  {
                    ${planQueryResult}
                }
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError
        }
    )
}

export default useOperationTaskMutation