import React, {useEffect} from 'react'
import { ListAvatars } from '@Knowledge-OTP/znk-ui-components'
import { useLazyQuery, gql } from '@apollo/client'

const LazyGroupAvatar = (props) => {
    const { users = [], canDelete, onDelete = () => {}, ...otherProps } = props
    const [getUsers, {data}] = useLazyQuery(
        gql`
        query($userIds: [ID!]) {
            users (userIds: $userIds) {
                id
                firstName
                lastName
                avatar
            }
        } 
        `
    )
    useEffect(
        () => {
            if (users?.length > 0) {
                getUsers({
                    variables: {
                        userIds: users.map((u) => u.id),
                    }
                })
            }
            //eslint-disable-next-line
        }, [users]
    )
    return (
        <ListAvatars
            avatars={
                (data?.users || users).map(
                    studentUser => {
                        let oldUser = {}
                        if(data?.users && users.map(({id}) => id).includes(studentUser.id || studentUser.studentUserId)) {
                            oldUser = users.find(({id}) => id === studentUser.id || id === studentUser.studentUserId ) || {}
                        }
                        return {
                            ...oldUser,
                            ...studentUser,
                            name: studentUser ?
                                `${studentUser.firstName} ${studentUser.lastName}` :
                                'Not Found',
                            avatar: studentUser?.avatar    
                        }
                    }
                )
            }
            canDelete={canDelete}
            onDelete={(element) => {
                if(onDelete) onDelete(element)
            }}
            {...otherProps}
        />

    )
}

export default LazyGroupAvatar