import {gql, useMutation} from '@apollo/client'

const useAddNewScore = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $examScore: ScoresInput!
                $planId: ID
                $studentId: ID
                $serviceId: ID
                $stepId: ID
                $phase: PhaseEnum
                $taskId: ID
            ) {
                addNewScore (
                    examScore: $examScore
                    planId: $planId
                    studentId: $studentId
                    serviceId: $serviceId
                    stepId: $stepId
                    phase: $phase
                    taskId: $taskId
                ) {
                    id
                }
            }
        `, {onCompleted, onError}
    )
}

export default useAddNewScore