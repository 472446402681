import React, {useMemo, useState} from 'react'
import {TabPane, TabContent} from 'reactstrap'
import {
    IconErase,
    TimelinePlan
} from '@Knowledge-OTP/znk-ui-components'
import useCreateTimelineModal from '../../../../../Timelines/Modal/CreateTimelineModal/useCreateTimelineModal'
import RenderInputOption from '../../Components/RenderInputOption'

import PDFViewerTimeline from './PDFViewerTimeline'
import moment from 'moment'

const Timeline = (props) => {
    const {className, plan, setData, timelineShowMode=false/**,  isEdit*/} = props
    const { timeline } = plan
    const {open, close, parseToTimeline} = useCreateTimelineModal()

    const {from, to} = useMemo(() => {
        if(plan?.timeline) {
           return plan.timeline?.reduce(({from, to}, event) => {
               let rangeObject = {}
               let fromEvent
               let toEvent
                if(event?.type === 'plan') {
                    fromEvent = event?.lpw[0]?.startdate
                    fromEvent = moment(fromEvent)
                    const largo = Object.keys(event?.lpw)?.length
                    toEvent = event?.lpw[largo - 1]?.enddate
                    toEvent = moment(toEvent)
                } else {
                    fromEvent = moment(event?.date)
                    toEvent = moment(event?.date)
                }

                //setea from (mínimo)
                if(fromEvent?.valueOf() < from) rangeObject.from = fromEvent?.valueOf()
                else rangeObject.from = from

                //setea to (máximo)
                if(toEvent?.valueOf() > to) rangeObject.to = toEvent?.valueOf()
                else rangeObject.to = to

                return rangeObject
           }, {from: Number.POSITIVE_INFINITY, to: Number.NEGATIVE_INFINITY}) 
        }
        return {from: 0, to: 0}
    }, [plan])

    const openTimelineModal = (studentUser = null, plans = null) => {
        open(
            ({plans}) => {
                //setea timeline en el plan
                setData({
                    timeline: plans
                })
                //cierra modal de creación de timeline
                close()
            }, studentUser, plans
        )
    }

    //muesta tabPane con pdf viewer
    const [showPdf, setShowPdf] = useState(false)


    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <div className={`w-100 d-flex flex-row justify-content-between align-items-center`}>
                <span className={`h4 font-weight-bold text-title`}>Live Prep Timeline</span>
                <div className={`d-flex flex-row align-items-center`}>
                    {
                        plan?.timeline && 
                        <div onClick={() => {setData({timeline: null})}}
                            className={`d-flex flex-row hover-icon cursor-pointer ml-2`}>
                            <IconErase className={`text-error`} size={15}/>
                        </div>
                    }
                    <span className={`h45 btn-link`} onClick={() => {
                        setShowPdf(!showPdf)
                    }}>{showPdf ? 'Back' : 'View pdf'}</span>
                </div>
            </div>
            <TabContent activeTab={showPdf ? 'pdf' : 'timeline'}>
                <TabPane tabId={`timeline`}>
                    <RenderInputOption key={'planName'} label={null}>
                    <TabContent activeTab={timeline ? 'selected' : 'no-selected'}>
                        <TabPane tabId={'selected'}>
                            <div className={`h45 d-flex flex-row justify-content-between`}>
                                <div className={`d-flex flex-column`}>
                                    <span className={`h45 text-gray`}>{`FROM ${moment(from).format('MMM, DD')?.toUpperCase()} TO ${moment(to).format('MMM, DD')?.toUpperCase()}`}</span>
                                    <span className={`h4 text-title mt-1`}>{`${timeline?.filter(({type}) => type==='plan')?.length} plans & ${timeline?.filter(({type}) => type==='event')?.length} events`}</span>
                                </div>
                                <span 
                                    onClick={() => {
                                        openTimelineModal({...plan?.studentUser, lock: true, isAdd: true}, timeline)
                                    }} 
                                    className={`h45 btn-link`}>Edit</span>
                            </div>
                            <TimelinePlan show={timelineShowMode} events={parseToTimeline( timeline?.filter( ({statusBuild}) => statusBuild !== 'delete' ) )}/>
                        </TabPane>
                        <TabPane tabId={`no-selected`}>
                            <span className={`btn-link`} onClick={() => {
                                openTimelineModal({...plan?.studentUser, lock: true, isAdd: true})
                            }}>Add timeline</span>       
                        </TabPane>
                    </TabContent>
                    </RenderInputOption>
                </TabPane>
                <TabPane tabId={'pdf'}>
                    {
                        showPdf && (
                            <PDFViewerTimeline plan={plan} isOpen={showPdf}/>
                        ) 
                    }
                </TabPane>
            </TabContent>
            {/* <div style={{height: '0px', overflow: 'hidden'}}>
                <div ref={timelineRef} className={`p-2`} style={{backgroundColor: 'white'}}>
                    <TimelinePlan show={true} events={parseToTimeline( timeline?.filter( ({statusBuild}) => statusBuild !== 'delete' ) )}/>
                </div>
            </div> */}
        </div>
    )
}

export default Timeline