import {useMemo} from 'react'
import ResumeSection from '../../Tabs/Resume'
import TasksSection from '../../Tabs/Tasks'
import TimelineSection from '../../Tabs/Timeline'
import SummarySection from '../../Tabs/Summary'
import UniversitiesSection from '../../Tabs/Universities'
import MettingsSection from '../../Tabs/Mettings'
import PaymentsSection from '../../Tabs/Payments'
import DocsAndExam from '../../Tabs/DocsAndExam'

const useTabsByStatus = (status) => {
    //status: oneOf [scheduled, draft, sent]
    //actions: edit, sent, cancel, approve
    
    const {baseSections: sections, defaultTab} = useMemo(() => {
        let baseSections = []
        let defaultTab = 'resume'
        if(["draft", "cancel"].includes(status)) {
            baseSections = baseSections.concat([
                {
                    label: 'Resume',
                    key: 'resume',
                    Inner: ResumeSection
                },
            ])
        }
        else if(["sent"].includes(status)) {
            baseSections = baseSections.concat([
                {
                    label: 'Summary',
                    key: 'resume',
                    Inner: ResumeSection
                },
            ])
        }
        else if(["scheduled", "application", "completed", "postApplication"].includes(status)) {
            defaultTab = 'summary'
            baseSections = baseSections.concat([
                {
                    label: 'Summary',
                    key: 'summary',
                    Inner: SummarySection
                },
                {
                    label: 'Tasks',
                    key: 'tasks',
                    Inner: TasksSection
                },
                {
                    label: 'Documents',
                    key: 'docs&exam',
                    Inner: DocsAndExam
                },
                {
                    label: 'Meetings',
                    key: 'mettings',
                    Inner: MettingsSection
                },
                {
                    label: 'Universities',
                    key: 'universities',
                    Inner: UniversitiesSection
                },
                {
                    label: 'Timeline',
                    key: 'timeline',
                    Inner: TimelineSection
                },
                {
                    label: 'Payments',
                    key: 'payments',
                    Inner: PaymentsSection
                }
            ])
        }
        return {baseSections, defaultTab}
    }, [status])

    return {
        sections: sections,
        defaultTab
    }
}

export default useTabsByStatus