import React, {useMemo} from 'react'
import {
    ListItem,
    Avatar,
    IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'


const StudentList = (props) => {
    const {students = [], onClickStudent = () => {}} = props
    
    const sortStudents = useMemo(
        () => {
            let newStudents = [...students] 
            return newStudents.sort((studentA, studentB) => {
                const {tasks: tasksA} = studentA
                const {tasks: tasksB} = studentB
                const dateA = tasksA[0]?.task?.reviewDate
                const dateB = tasksB[0]?.task?.reviewDate
                return moment(dateA).format('YYYYMMDD') - moment(dateB).format('YYYYMMDD')
            })
        }, [students]
    )

    return (
        sortStudents?.map(
            (student, index) => {
                const {tasks, user} = student
                const userName = user ? `${user?.firstName} ${user?.lastName}` : `No defined`
                const lastTask = tasks[0]
                const lastAssign = lastTask?.task?.reviewDate
                
                return (
                    <ListItem
                        key={index}
                        className={`py-1`}
                        Left={
                            <div className={`d-flex flex-row m-0 p-0 align-items-center h45 text-info`}>
                                <Avatar 
                                    name={userName} 
                                    uri={user?.avatar}
                                />
                                <span className={`ml-2`}>{userName}</span>
                            </div>
                        }
                        Right={
                            <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                                <span className={`h45 text-info mr-2`}>
                                    {moment(lastAssign).fromNow()}
                                </span>
                                <div 
                                    className={`hover-icon`}
                                    onClick={() => {
                                        // history.push({
                                        //     pathname: '/workspace/tasks',// Current Path
                                        //     search: `?search-swt-planid=${lastTask?.planId}`// Your Param needed
                                        // });
                                        if(onClickStudent) onClickStudent(student)
                                    }}    
                                >
                                    <IconArrowRight size={15} />
                                </div>
                            </div>
                        }
                    />
                )
            }
        )
    )
}

export default StudentList