import {useQuery, gql} from '@apollo/client'

const useAllPlans = (vars = {}) => {
    return useQuery(
        gql`query(
            $kinds: [PlanKind!]
            ){
            allPlans(
                kinds: $kinds
                ) {
              id
              name
            }
        }`,
        {
            variables: vars
        }
    )
}

export default useAllPlans