import { ConfirmModal } from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useState } from 'react'
import useCheckUsersLessonsMatch from '../../../../Model/Users/checkUsersMatch'
import ShowLessonsPending from '../../Modal/Profile/ShowLessonsPending'
import useToggleActiveStatus from '../../../Dashboard/Student/Widget/StudentWithoutLessons/Model/useToggleActiveStatus'
import { showAlert } from '../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
const ConfirmActions = props => {
    const { setIsOpen, refetch, data } = props
    const [showLessons, setShowLessons] = useState(false)
    const [students, setStudents] = useState([])
    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const [isOpenConfirmOnly, setIsOpenConfirmOnly] = useState(false)
    const dispatch = useDispatch()
    // const [submitStatusUser, { loading: LoadingStatusUser }] = useToggleActiveStatus()
    const [submitStatusUser, { loading }] = useToggleActiveStatus()
    const [submitCheck, { data: dataCheck }] = useCheckUsersLessonsMatch()
    useEffect(() => {
        if (data?.isActive) submitCheck({ variables: { isStudent: true, userId: data?.id } })
        else setIsOpenConfirmOnly(true)
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (dataCheck?.checkUsersLessonsMatch.length) {
            setStudents(dataCheck.checkUsersLessonsMatch)
            setShowLessons(true)
        }
        else if( dataCheck?.checkUsersLessonsMatch?.length === 0 ) {
            setIsOpenConfirmOnly(true)
        }
    }, [dataCheck])
    const toggleLessons = () => {
        setShowLessons((prev) => !prev)
        setIsOpen(false)
        showLessons(false)
    }
    return (
        <>
            <ConfirmModal
                classNameActionDiv={`d-flex flex-row`}
                isOpen={isOpenConfirm}
                toggle={setIsOpenConfirm}
                loading={loading}
                title={'Are you sure?'}
                body={'This action is gonna inactivate this user.'}
                confirmAction={() => {
                    submitStatusUser({
                        variables: { userId: data?.id, force: false }
                    }).then((res)=>{
                        refetch()
                        setIsOpenConfirmOnly(false)
                        setIsOpenConfirm(false)
                        setIsOpen()
                          showAlert({
                            text: 'Success!',
                            status: 'success'
                        }, dispatch)
                        },
                        (err) => {
                          showAlert({
                            text: err?.toString(),
                            status: 'error'
                        }, dispatch)
                        })
                }}
                cancelAction={() => { setIsOpenConfirm() }}
                classNameConfirm={data?.isActive ? 'text-error' : 'text-primary'}
                confirmText={data?.isActive ? 'Yes, Inactivate' : 'Yes Activate'}
                cancelText={'Cancel'}
            />
            {
                showLessons && <ShowLessonsPending
                    isOpen={showLessons}
                    toggle={toggleLessons}
                    students={students}
                    handletAction={
                        (hasLessons) => {
                            console.log({hasLessons})
                            if (hasLessons) setIsOpenConfirm(true)
                            else submitStatusUser({
                                variables: { userId: data?.id, force: false }
                            }).then((res)=>{
                                refetch()
                                setIsOpenConfirmOnly(false)
                                setIsOpenConfirm(false)
                                setIsOpen()
                                  showAlert({
                                    text: 'Success!',
                                    status: 'success'
                                }, dispatch)
                                },
                                (err) => {
                                  showAlert({
                                    text: err?.toString(),
                                    status: 'error'
                                }, dispatch)
                                })
                        }
                    }
                    //loading={LoadingStatus}
                    type='student'
                />
            }
            {
                isOpenConfirmOnly && <ConfirmModal
                    classNameActionDiv={`d-flex flex-row`}
                    isOpen={isOpenConfirmOnly}
                    toggle={setIsOpenConfirmOnly}
                    loading={loading}
                    title={'Are you sure?'}
                    body={`This action is going to ${data?.isActive ? 'inactivate' : 'activate'} this user.`}
                    confirmAction={() => {
                        submitStatusUser({
                            variables: { userId: data?.id, force: false }
                        }).then((res)=>{
                            refetch()
                            setIsOpenConfirmOnly(false)
                            setIsOpenConfirm(false)
                            setIsOpen()
                              showAlert({
                                text: 'Success!',
                                status: 'success'
                            }, dispatch)
                            },
                            (err) => {
                              showAlert({
                                text: err?.toString(),
                                status: 'error'
                            }, dispatch)
                            })
                    }}
                    cancelAction={() => { setIsOpenConfirmOnly() }}
                    classNameConfirm={data?.isActive ? 'text-error' : 'text-primary'}
                    confirmText={data?.isActive ? 'Yes, Inactivate' : 'Yes Activate'}
                    cancelText={'Cancel'}
                />
            }
        </>
    )
}

export default ConfirmActions