import React, { useEffect, useMemo } from 'react'
import {
    TemplateTimeline,
    TemplateTimelineSkeleton
} from '@Knowledge-OTP/znk-ui-components'
import usePlans from '../useQueries/usePlans'
import useViewTaskModal from '../../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import useCounselingPlanReducer from '../../../../../reducers/counseling-plan'
import useTemplateReducer from '../../../../../reducers/template'
import {TabContent, TabPane} from 'reactstrap'

const CpTimeline = (props) => {
    const {studentId = ''} = props
    const [getPlans, { data, loading }] = usePlans()
    const {parsePlanFromAPI} = useCounselingPlanReducer()
    const {filterDelete} = useTemplateReducer()
    const {open: openViewTaskModal} = useViewTaskModal()
    useEffect(
        () => {
            getPlans(
                {
                    variables: {
                        limit: 1,
                        studentsUserIds: [studentId],
                        status: [
                            'scheduled',
                            'application',
                            'postApplication'
                        ],
                        kind: [
                            'counceling'
                        ]
                    } 
                }
            )
            // eslint-disable-next-line
        }, [studentId]
    )
    const phases = useMemo(
        () => {
            const auxPlan = data?.plans?.docs[0] || null
            return auxPlan ? parsePlanFromAPI(auxPlan)?.tasks : null

        }, 
        // eslint-disable-next-line
        [data]
    )

    return (
        <div className={`d-flex flex-column m-0 p-0`}>
            <TabContent activeTab={loading ? 'loading' : phases ? 'show' : 'blank'}>
                <TabPane tabId={`loading`}>
                    <TemplateTimelineSkeleton/>
                </TabPane>
                <TabPane tabId={`show`}>
                    <TemplateTimeline
                        onClickTask={(task) => {
                            openViewTaskModal(task, () => {})
                        }}
                        stages={phases ? filterDelete({phases}).phases : []}
                    />
                </TabPane>
                <TabPane tabId={`blank`}>
                    <label className={`h45 text-gray text-center w-100 mt-4`}>User dont have counseling plan active</label>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default CpTimeline