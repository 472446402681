import {gql, useMutation} from '@apollo/client'

const useCheckPaymentItemForLesson = (lessonId, recurringId) => {
    const [checkPaymentItems] = useMutation(
        gql`
            mutation($lessonId: String!, $recurringId: String) {
                checkPaymentItemForLesson(
                    lessonId: $lessonId,
                    recurringId: $recurringId,
                )
            }
        `
    )
    return () => {
        checkPaymentItems({
            variables: {lessonId, recurringId}
        })
    }
}

export default useCheckPaymentItemForLesson