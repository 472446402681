import React, {useEffect, useState, useReducer, useRef, useMemo} from 'react'
import { Field, Form, IconClose, Modal, PlainTextInput, LabeledField, Button, AmountInput } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import useEditUniversity from '../../../../Model/Universities/Edit/edit'
import { isEmpty, pick } from 'lodash'
import getBase64 from '../../../../utils/getBase64'
import { gql, useLazyQuery } from '@apollo/client'

const initialState = {
    operation: '',
    id: null,
    push: null
}

const UniversityEditModal = (props) => {
    const { refetchList, state: stateModal, dispatchModal } = props
    const {isOpen, operation, id, refetch} = stateModal
    const { translate } = useTranslation()
    const [form, setForm] = useState({})
    const [state, dispatch] = useReducer(reducer, initialState)
    const [errors, setError] = useState({})
    const [shortDes, setShortDes] = useState('')
    const inputRef = useRef(null)
    const [load, setLoad] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [logo, setLogo] = useState(null)
    const [getUniversity, { data }] = useLazyQuery(
        gql`query($universityId: ID!) {
            getUniversity(universityId: $universityId) {
                id
                name
                shortDescription
                collegeUnitId
                ranking
                stateAbbr
                city
                typeCategory
                typeYear
                undergraduateSize
                fourYearGraduationRate
                acceptanceRate
                satAverage
                actCumulativeMidpoint
                rankingsBestValue
                rankingsBestTotal
                avgCostOfAttendance 
                logo
            }
        }`
    )
    useMemo(() => {
        if (load) return
        if (data) {
            setForm(pick(data.getUniversity, ['name', 'shortDescription', 'collegeUnitId', 'ranking', 'avgCostOfAttendance', 'stateAbbr', 'city', 'typeCategory', 'typeYear', 'undergraduateSize', 'fourYearGraduationRate', 'acceptanceRate', 'satAverage', 'actCumulativeMidpoint', 'rankingsBestValue', 'rankingsBestTotal']))
            setShortDes(data.getUniversity.shortDescription)
            setLogo(data.getUniversity.logo)
            setLoad(true)
        }
        // eslint-disable-next-line
    }, [data])
    useMemo(() => {
        if (!id || load) return
        getUniversity({variables: {universityId: id}})
        // eslint-disable-next-line
    }, [stateModal.id])
    const { submit, loading: loadingAdd, validationErrors } = useEditUniversity((result) => {
        refetchList()
        if (operation === 'editInside') {
            refetch()
        }
        dispatchModal({type: operation === 'edit' ? 'toggle' : 'toggleEditInside'})
    }, console.log)    
    useEffect(() => {
        if (isEmpty(validationErrors)) return
        var customErrors = {}
        Object.keys(validationErrors).forEach(key => {
            const keyVal = key.split('.')[1]
            customErrors[keyVal] = translate('universities.errors.' + keyVal)
        })
        setError(customErrors)
        // eslint-disable-next-line
    }, [validationErrors])
    useEffect(() => {
        const newVal = {
            ...form,
            shortDescription: shortDes,
            rankingsBestTotal: form.rankingsBestTotal,
            rankingBestValue: form.rankingBestValue,
            isPrincipal: true,
            isActive: true
        }
        if (selectedImage) {
            getBase64(selectedImage).then(
                data => {
                    newVal.logo = data
                    submit({universityId: id,  input: newVal })
                }
            )
            return
        } else {
            delete newVal.logo
        }
        if (state.push === null) return
        switch (operation) {
            case "edit":
                submit({universityId: id,  input: newVal })
                return
            case "editInside":
                submit({universityId: id,  input: newVal })
                return
            default:
                throw new Error("")
        }
        // eslint-disable-next-line
    }, [state.push])
    const onSelectFile = (e) => {
        setSelectedImage(e.target.files[0])
    }
    return (
        <Modal
            className='modal-sidebar modal-colored white-modal modal-desktop-half pl-3'
            isOpen={isOpen}
            toggle={()=>{dispatchModal({type: operation === 'edit' ? 'toggle' : 'toggleEditInside'})}}
            backdrop='static'
            Head={
            <div className='modal-header p-0 m-0'>
                <div className='d-flex justify-content-between w-100 p-7'>
                <h2 className='font-weight-bold'>{translate('universities.editNewUniversity')}
                </h2>
                <IconClose onClick={()=>{dispatchModal({type: operation === 'edit' ? 'toggle' : 'toggleEditInside'})}} className='text-gray' />
                </div>
            </div>
            }
        >
             <Form value={form} onChange={setForm} validationErrors={errors}>
                <div className='d-flex flex-column pr-6 pl-7'>
                    <div className='row d-flex m-0 mt-3'>
                        <div className='col-12 p-0 pr-1'>
                            <img src={!selectedImage ? logo : URL.createObjectURL(selectedImage)} className='img-fluid' onClick={() => {inputRef.current.click()}} alt='logo-university' />
                        </div>
                    </div>
                    <div className='row d-flex m-0'>
                        <div className='col-12 p-0 pr-1'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.website')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='website'
                                fieldType='text'
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0 mt-3'>
                        <div className='col-12 p-0 pr-1'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.name')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='name'
                                fieldType='text'
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0'>
                        <div className='col-12 p-0 pr-1'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.shortDescription')}</label>
                            <textarea
                                className={`znk-input form-control`}
                                rows={5}
                                style={{resize: 'none'}}
                                onChange={(e) => {setShortDes(e.target.value)}}
                                value={shortDes}
                            />
                            {!isEmpty(errors) && errors.shortDescription && (<h5 className='text-error'>{translate('universities.errors.shortDescription')}</h5>)}
                        </div>
                    </div>
                    <div className='row d-flex m-0 mt-3'>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.collegeUnitId')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='collegeUnitId'
                                fieldType='text'
                            />
                        </div>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.ranking')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='ranking'
                                fieldType='number'
                                min="0"
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0'>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.stateAbbr')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='stateAbbr'
                                fieldType='text'
                            />
                        </div>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.city')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='city'
                                fieldType='text'
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0'>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.typeCategory')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='typeCategory'
                                fieldType='text'
                            />
                        </div>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.typeYear')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='typeYear'
                                fieldType='text'
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0'>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.undergraduateSize')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='undergraduateSize'
                                fieldType='number'
                            />
                        </div>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.acceptanceRate')}</label>
                            <Field
                                component={PlainTextInput}
                                fieldName='acceptanceRate'
                                fieldType='number'
                                type={AmountInput}
                                placeholder='0.00'
                                min='0'
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0'>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.fourYearGraduationRate')}</label>
                            <Field
                                component={PlainTextInput}
                                fieldName='fourYearGraduationRate'
                                fieldType='number'
                                type={AmountInput}
                                placeholder='0.00'
                                min='0'
                            />
                        </div>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.actCumulativeMidpoint')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='actCumulativeMidpoint'
                                fieldType='number'
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0'>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.satAverage')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='satAverage'
                                fieldType='number'
                            />
                        </div>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.avgCostOfAttendance')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='avgCostOfAttendance'
                                fieldType='number'
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0'>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.rankingBestTotal')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='rankingsBestTotal'
                                fieldType='number'
                            />
                        </div>
                        <div className='col-6 p-0 pr-2'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.rankingBestValue')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='rankingsBestValue'
                                fieldType='number'
                            />
                        </div>
                    </div>
                    <div className='row d-flex m-0 mt-3'>
                        <div className='col-12 p-0 pr-1'>
                            <label className='text-gray font-weight-light mb-2'>{translate('universities.logo')}</label>
                            <Field
                                component={PlainTextInput}
                                type={LabeledField}
                                fieldName='logo'
                                fieldType='text'
                                readOnly
                                onClick={() => {inputRef.current.click()}}
                                value={selectedImage?.name}
                            />
                            <input type="file" accept="image/*" hidden ref={inputRef} onChange={onSelectFile} />
                        </div>
                    </div>
                    <div className='px-3 px-lg-5 px-xl-7 d-flex flex-column my-5'>
                        <div className='m-0 p-0 row'>
                            <Button
                                color='secondary'
                                activity={loadingAdd}
                                className='col-12'
                                label='Save'
                                onClick={() => {dispatch({type: 'save'})}}
                                style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                            />
                        </div>
                    </div>
                </div>
            </Form>
      </Modal>
    )
}

export default UniversityEditModal

function reducer(state, action) {
    switch (action.type) {
        case "save":
            return {...state, push: !state.push}
        default:
            throw new Error("")
    }
}