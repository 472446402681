import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider as RawApolloProvider, ApolloClient, InMemoryCache
  , 
  // HttpLink 
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import url from './client/url'
import FirebaseContext from '../../../contexts/FirebaseContext'
import AuthContext from '../../../contexts/AuthContext'

import {createUploadLink} from 'apollo-upload-client' 

const propTypes = {
  children: PropTypes.any
}
// const httpLink = new HttpLink({ uri: `${url}/graphql` })
const httpLink = new createUploadLink({
  uri: `${url}/graphql`,
})

function ApolloProvider({ children }) {
  const firebase = useContext(FirebaseContext)
  const [client, setClient] = useState(new ApolloClient({ cache: new InMemoryCache(), link: httpLink }))
  const [firebaseAuthed, setFirebaseAuth] = useState(false)
  useEffect(() => {
    if (firebase) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const asyncAuthLink = setContext(
            request =>
              new Promise((success, fail) => {
                // do some async lookup here
                user.getIdToken().then((token) => {
                  success({
                    headers: { authorization: `Bearer ${token}` }
                  })
                }).catch(fail)
              })
          )
          setClient(
            new ApolloClient({
              cache: new InMemoryCache(),
              link: asyncAuthLink.concat(httpLink)
            })
          )
          setFirebaseAuth(true)
        } else {
          setFirebaseAuth(false)
        }
      })
    }
  }, [firebase])
  return <AuthContext.Provider value={firebaseAuthed}><RawApolloProvider client={client}>{children}</RawApolloProvider></AuthContext.Provider>
}

ApolloProvider.propTypes = propTypes

export default ApolloProvider
