import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Topic from './Topic'
import SearchBox from './SearchBox'
import { Button } from '@Knowledge-OTP/znk-ui-components'
import isEqual from 'lodash/isEqual'

const propTypes = {
  value: PropTypes.array,
  addItem: PropTypes.func,
  removeItem: PropTypes.func,
  renderItem: PropTypes.func
}
const defaultProps = {}

function TopicSelector(props) {
  const {
    value, addItem: rawAddItem, removeItem, renderItem, submit, loading
  } = props

  const addItem = useCallback((newItem = {}) => rawAddItem(newItem), [rawAddItem])

  const renderTopic = useCallback(
    (_, index) => {
      const item = (
        <Topic
          key={index}
          index={index}
          topicId={_.id}
          name={_.name}
          removeItem={() => removeItem(index)}
        />
      )
      return renderItem({ item, index })
    },
    [renderItem, removeItem]
  )
  const [initialValue, setInitialValue] = useState(null)
  useEffect(() => {
    setInitialValue(value)
    // eslint-disable-next-line
  }, [setInitialValue])
  return <>
    <span className='d-flex flex-column'>
      <h3 className='font-weight-bold'>Selected Topics</h3>
      <span className='d-flex flex-column my-4'>
        {value.length ? value.map(renderTopic) : <span className='align-self-center'>No Topics Found</span>}
      </span>
      <Button
        label='Save Changes'
        color='primary'
        check
        className='ml-auto'
        disabled={isEqual(initialValue, value)}
        activity={loading}
        onClick={submit}
      />
    </span>
    <hr className='my-4' />
    <span>
      <h3 className='font-weight-bold'>Select new Topics</h3>
      <SearchBox
        selectedValues={value}
        addItem={addItem}
      />
    </span>
  </>
}

TopicSelector.propTypes = propTypes
TopicSelector.defaultProps = defaultProps

export default TopicSelector
