import {useMutation, gql} from '@apollo/client'

const useEditCouncelingPlanMutation = (onSuccess = () => {}, onError = () => {} ) => {
    return useMutation(
        gql`
            mutation (
                $sendDate: DateTime
                $input: SendCounselingPlan!
            ) {
                sendCouncelingPlanFromAdmin (
                    sendDate: $sendDate
                    input: $input
                )
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError
        }
    )
}

export default useEditCouncelingPlanMutation