import React from 'react'
import { IconClose } from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import Skeleton from 'react-loading-skeleton'
import ContentFull from './contentFull'

const FullProfile = (props) => {
  const { 
    user, 
    type, 
    toggleRightSide, 
    // afterEditPersonalAction, 
    // afterEditStudentInfoAction, 
    // afterEditFamilyInfoAction, 
    // refetch: update, 
    // refetchList 
  } = props
  
  const dispatch = useDispatch()
  
  const closeFull = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.profile', data: { isOpen: true, full: false, type: type } })
  }
  
  return (
    <>
      {
        user
          ? <div className='w-100 py-5 px-8 text-title'>
              <span className='d-flex w-100 flex-row justify-content-between'>
                <h2 className='font-weight-bold'>Profile Info</h2>
                <IconClose size={20} onClick={toggleRightSide || closeFull} color='rgba(119, 134, 153, .4)' />
              </span>
              <ContentFull {...props} />
            </div>
          : <Skeleton count={4} height={35} />
      }
    </>
  )
}

export default FullProfile
