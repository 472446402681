import React from 'react'
import {
    IconArrowRight,
    Button,
    IconTask,
    AlertButton
} from '@Knowledge-OTP/znk-ui-components'
import useAssignTaskModal from '../../../assingTaskModal/useAssignTaskModal'
import useCompleteTaskModal from '../../../completeTaskModal/useCompleteTaskModal'
import useOperationTaskMutation from '../../../Model/useOperationTask'
// import useActionByStatus from './useActionByStatus'
import taskTypes from '../../../../../University/Utils/taskTypes'
import {showAlert} from '../../../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import {UseGetUserTask} from '../../modals/ShowScheduledMeeting/Models/useGetUserTask'

const useActionByStatus = (props) => {
    const { task, setData, onChangeTask = () => { } } = props
    const dispatch = useDispatch()
    const { open: openAssignTask } = useAssignTaskModal()
    const { open: openCompleteTask, close: closeCompleteTask } = useCompleteTaskModal()

      // eslint-disable-next-line
    const [getOneTask, { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask }] = UseGetUserTask(   
      (resp) => {
        const taskToSave = resp?.getUserTask
        setData(taskToSave)
        onChangeTask('edit', taskToSave)
      },
      (err) => {
        console.error('Error on getting task : ', err)
      },
    )
    const [taskOperation, { loading: loadOperation }] = useOperationTaskMutation(
        (result) => {
            getOneTask({
                variables: {
                  taskId: task?.id || task?.taskId,
                  stepId: task?.stepId,
                  phase:
                  task?.phaseId.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }),
                  planId: task?.planId,
                },
              })
        },
        (err) => {
            console.error("Error on assign task mutation : ", err)
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
        }
    )

    return {
        actions: [
            {
                status: 'unassigned',
                onClick: () => {
                    openAssignTask((newTask) => {
                        //luego de asignar la tarea
                        //setea info de task en taskViewModal
                        setData(newTask)
                        onChangeTask('assign', newTask)
                    }, {...task, userId: task?.dataPlan?.studentUser?.studentUserId}, true /**useMutation**/)
                },
                Inner: Button,
                label: 'Assign task',
                props: {
                    className: `w-100 btn-blue-border`,
                    label:
                        <div className={`d-flex flex-row justify-content-between w-100 py-3`}>
                            <span>Assign task</span>
                            <IconArrowRight className={`align-self-center`} size={15} />
                        </div>
                }
            },
            {
                status: 'pending',
                onClick: () => {
                    if (task?.type === taskTypes.completion || task?.type === taskTypes.link || task?.type === taskTypes.loginInfo) {
                        taskOperation({
                            variables: {
                                universities: task.universities,
                                operation: 'review',
                                userId: task.userId,
                                //taskData
                                taskId: task?.id || task?.taskId,
                                stepId: task?.stepId,
                                phase: task?.phaseId.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }),
                                planId: task?.planId,
                            }
                        })
                    }
                },
                Inner: AlertButton,
                activity: loadOperation || loadingOneTask,
                label: 'In progress',
                props: {
                    className: `w-100`,
                    status: 'warning',
                    label: 'In progress',  
                    Icon: IconTask,
                    activity: loadOperation || loadingOneTask
                },
                
            },
            {
                status: 'review',
                onClick: () => {
                    openCompleteTask((newTask) => {
                        //marca task como finalizada (completada)
                        setData({ ...task, status: 'completed' })
                        onChangeTask('edit', { ...task, status: 'completed' })
                        closeCompleteTask()
                    }, task, true /**useMutation**/)
                },
                Inner: Button,
                label: 'Complete task',
                activity: loadOperation || loadingOneTask,
                props: {
                    className: `w-100 btn-primary`,
                    label:
                        <div className={`d-flex flex-row justify-content-between w-100 py-3`}>
                            <span>Complete task</span>
                            <IconTask className={`align-self-center`} size={15} />
                        </div>
                }
            },
            {
                status: 'completed',
                onClick: () => {},
                Inner: AlertButton,
                label: 'Completed',
                props: {
                    Icon: IconTask
                }
            }
        ]
    }
}

export default useActionByStatus