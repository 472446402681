import { useMutation, gql } from '@apollo/client'

const useAddCheckIn = (onCompleted = () => {}, onError = () => {}) => {

    return useMutation(
        gql`
            mutation (
                $checkInInput: CheckInDateInput!
                $planId: String!
            ) {
                addCheckIn (
                    checkInInput: $checkInInput
                    planId: $planId
                ) {
                    id
                    date
                    type
                }
            }
        `, {onCompleted, onError}
    )
}


export default useAddCheckIn