import React from 'react'
import SearchBox from './SearchBox'
import useAddParentToStudent from '../../../../../../Model/Users/Parent/AddParentToStudent'

const ParentInfo = (props) => {
  const { users, parents, userId, onSuccess } = props
  const { submit, loading } = useAddParentToStudent(onSuccess, console.log)
  const addItem = (parentUserId) => {
    submit({ parentUserId, userId })
  }
  return (<SearchBox loading={loading} users={users} selectedValues={parents} addItem={addItem} onSuccess={onSuccess} />)
}

export default ParentInfo
