import React, {useEffect, useMemo, useState} from 'react'
import {
    ModalLayout,
    Button,
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
// import ReactToPdf from 'react-to-pdf'

//mutation
import useCreateCouncelingPlan from '../../Model/useCreateCouncelingPlan'
import useEditCouncelingPlan from '../../Model/useEditCouncelingPlan'

import useNewPlanModal from './useNewPlanModal'
import PlanDetails from './Sections/PlanDetails'
import ScheduleDetails from './Sections/ScheduleDetails'
import Team from './Sections/Team'
import Student from './Sections/Student'
import PlanBreakdown from './Sections/PlanBreakdown'
import PaymentSchedule from './Sections/PaymentSchedule'
import Timeline from './Sections/Timeline'
import Proposal from './Sections/Proposal'
//reducers
import useCounselingPlanReducer from '../../../../reducers/counseling-plan'

//bubbles styles
import useBubblesModalHeader from '../../../../bubbles/Components/useBubblesModalHeader'
import PdfGenerator from '../../../PdfEditor/CounselingProposalViewer/PdfGenerator'

//confirm modal for close action
import useConfirmCloseModal from './Components/ConfirmCloseModal/useConfirmCloseModal'

import usePdfUpload from '../../../PdfEditor/CounselingProposalViewer/usePdfUpload'
import useTemplateReducer  from '../../../../reducers/template'

const NewPlanModal = () => {
    const {isOpen, toggle, parseDataToMutation, validation, isEdit, dataPlan, onChange, close: closeModal} = useNewPlanModal()
    const {state: plan, updateData: setData, resetData, parsePlanFromAPI} = useCounselingPlanReducer()
    const sectionProp = {plan, setData, isEdit: !!isEdit, dataPlan}
    const {filterDelete} = useTemplateReducer()
    const [isAtomic, setIsAtomic] = useState(dataPlan?.isAtomic || false)
    useEffect(() => {
    //actualiza la información del plan entrante en el reducers
        if(isEdit) {
            if(dataPlan) setData(dataPlan)
        } else resetData() //deja el formulario en su estado inicial
        
        // eslint-disable-next-line
    }, [dataPlan, isEdit])


    //upload proposal to S3
    const [uploadPdfToS3, {loading: loadPdfUpload}] = usePdfUpload(
        () => {
            closeModal()
        }, 
        () => {
            closeModal()
        }
    )

    //create mutation
    const [createPlan, {loading: loadCreate}] = useCreateCouncelingPlan(
    (result) => {
        if(onChange) onChange(parsePlanFromAPI(result?.createCouncelingPlan))
        //genera documento pdf
        generateDocument(result?.createCouncelingPlan)
    }, () => {
        if(onChange) onChange()
    })
    //edit mutation
    const [editPlan, {loading: loadEdit}] = useEditCouncelingPlan(
        (result) => {
            if(onChange) onChange(parsePlanFromAPI(result?.editCouncelingPlan))
            
            //genera documento pdf
            generateDocument(result?.editCouncelingPlan)
        }, 
        () => {
            if(onChange) onChange()
            
        }
    )


    const invalidForm = useMemo(() => {
        //verifica si totalToDistributed está en cero (se confeccionaron todas las cuotas)
        if(plan.totalToDistributed > 0 || plan.totalToDistributed < 0) return true
        const invalid = !!validation(plan)
        return invalid
    }, [plan, validation])

    const bubblesStyle = useBubblesModalHeader('scheduled')
    //confirm before close new plan modal
    const {open: openConfirm, close} = useConfirmCloseModal()

    // const timelineRef = useRef(null)
    const [timelineShowMode] = useState(false)

    const [loadingProposalGenerator, setLoadingProposalGenerator] = useState(false)
    const confirmForm = (finalPlan) => {
        //ejecuta mutaciones
        const mutationFunction = isEdit ? editPlan : createPlan
        const {councelingPlanInput, timelineInput} = parseDataToMutation(finalPlan) 
        const variables = {councelingPlanInput, timelineInput, isAtomic}
        if(isEdit) variables.planId = finalPlan.id
        setLoadingProposalGenerator(false)
        mutationFunction({variables})
    }
    const generateDocument = (newPlan) => {
        setLoadingProposalGenerator(true)
        setStartPdfGenerator({start: true, plan: newPlan}) //goto PdfGenerator.setUrl
    }
    const [startPdfGenerator, setStartPdfGenerator] = useState({start: false, plan: null})

    return (
        <ModalLayout
            style={bubblesStyle}
            backdrop='static'
            className={`modal-colored modal-sidebar modal-desktop-half scheduled-modal`}
            isOpen={isOpen}
            toggle={() => {
                if(isOpen) {
                    openConfirm(() => {
                        close()
                        toggle()
                    })
                }
            }}
            topHead={
                <span className={`h3 text-white`}>
                    {isEdit ? 'Edit Counseling Plan' : 'New Counseling Plan'}
                </span>
            }
            minHead={
                <span className={`h3 text-white`}>
                    {isEdit ? 'Edit Counseling Plan' : 'New Counseling Plan'}
                </span>
            }
        >
            <div className={`m-0 p-0 d-flex flex-column`}>
                <Student className={`my-5`} useProposalParams {...sectionProp}/>
                <div className={`borderTop w-100 m-0`} />
                <Proposal className={`my-5`} useProposalParams {...sectionProp}/>
                <div className={`borderTop w-100 m-0`} />
                <PlanDetails className={`my-5`} {...sectionProp}/>
                <div className={`borderTop w-100 m-0`} />
                <ScheduleDetails className={`my-5`} {...sectionProp}/>
                <div className={`borderTop w-100 m-0`} />
                <Timeline timelineShowMode={timelineShowMode} className={`my-5`} {...sectionProp}/>
                <div className={`borderTop w-100 m-0`} />
                <Team className={`my-5`} {...sectionProp}/>

                {
                //<div className={`borderTop w-100 m-0`} />
                //<Advisor className={`my-5`} {...sectionProp}/>
                }
                
                <div className={`borderTop w-100 m-0`} />
                <PlanBreakdown className={`my-5`} {...sectionProp}/>
                <div className={`borderTop w-100 m-0`} />
                <PaymentSchedule className={`my-5`} {...sectionProp}/>
                <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={isAtomic}
                            onChange={(e) => setIsAtomic(e.target.checked)}
                            id="isAtomicCheckbox"
                        />
                        <label className="form-check-label" htmlFor="isAtomicCheckbox">
                            From Atomic plan
                        </label>
                </div>
                <PdfGenerator 
                    plan={
                        startPdfGenerator.plan || 
                        {...plan, tasks: (plan?.tasks || []).map((step) => {return {...step, phaseName: step?.phaseId}})}
                    } // necesita info de timeline tiempo antes de ejecutar
                    // para que el componente pueda renderizar
                    start={startPdfGenerator.start} 
                    currentUrl={plan?.proposalPdfUrl}
                    setUrl={(url) => {
                        // ¡SOLO SE EJECUTA UNA VEZ!
                        setLoadingProposalGenerator(false)
                        setStartPdfGenerator({...startPdfGenerator, start: false})
                        setData({...plan, proposalPdfUrl: url})
                        //subir archivo a s3
                        uploadPdfToS3(url, startPdfGenerator.plan?.studentUser?.studentUserId, startPdfGenerator.plan?.id )
                        
                 }} />

                 <TabContent activeTab={
                     loadCreate ? 
                        'loadCreate' : loadEdit ? 
                            'loadEdit' : loadingProposalGenerator ? 
                                'loadingProposalGenerator' : loadPdfUpload ? 
                                    'loadPdfUpload' : 'null' }>
                     <TabPane tabId={`loadCreate`}>
                        <label className={`h45 text-gray`}>Creating plan</label>
                     </TabPane>
                     <TabPane tabId={`loadEdit`}>
                        <label className={`h45 text-gray`}>Editing plan</label>
                     </TabPane>
                     <TabPane tabId={`loadingProposalGenerator`}>
                       <label className={`h45 text-gray`}>Generating document</label>
                     </TabPane>
                     <TabPane tabId={`loadPdfUpload`}>
                        <label className={`h45 text-gray`}>Uploading document</label>
                     </TabPane>
                     <TabPane tabId={`null`}>
                        <label className={`h45 text-gray`}></label>
                     </TabPane>
                 </TabContent>
                <Button
                    className={`w-100`}
                    label={isEdit ? 'Edit plan' : 'Create plan'}
                    color={'primary'}
                    disabled={invalidForm}
                    activity={loadCreate || loadEdit || loadingProposalGenerator || loadPdfUpload}
                    onClick={() => {
                        confirmForm({...plan, tasks: filterDelete({phases: plan?.tasks}).phases })
                    }}
                />
            </div>
        </ModalLayout>
    )
}

export default NewPlanModal