import {gql, useQuery} from '@apollo/client'

export const examScoreResult = `
    id
    _id
    name
    results {
        name
        scale
        method
        values {
            name
            scale
            isActive
            isOnMethod
            value
        }
        value
        isActive
        isPrincipal
        isOverallHidden
    }
    serviceId
    examDate
    overall
`

const useGetPlansWithExams = (variables = {}) => {
    return useQuery(
        gql`
            query {
                getPlansWithExams {
                    id
                    name
                    serviceId
                    selectedExam {
                        scores {
                            name
                        }
                    }
                }
            }
        `, {variables}
    )
}

export default useGetPlansWithExams