import { CheckboxSelector, CheckboxSelectorInner, IconArrowDown } from '@Knowledge-OTP/znk-ui-components'
import React, { useMemo } from 'react'
import { Badge } from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'

const StatusesFilter = (props) => {
  const { translate } = useTranslation()
  const { statuses, statusFilters, setStatusFilters, useInner = false } = props
  const pillColor = (item) => {
    let statusClass
    switch (item) {
      case 'canceled':
        statusClass = 'gray'
        break
      case 'pendingPayment':
        statusClass = 'error'
        break
      case 'unpaid':
        statusClass = 'warning'
        break
      case 'paid':
        statusClass = 'success'
        break
      default:
        statusClass = 'primary'
    }
    return statusClass
  }
  const selectorData = useMemo(() => statuses.map((item) => ({
    name: translate(`invoices.status.${item}`),
    id: item,
    Component: (
      <Badge
        color={pillColor(item)}
        pill
        className='mx-1 point'
      >
        &nbsp;
      </Badge>
    )
  })), [statuses, translate])
  
  const checkboxSelectorProps = {
    setDataFilters: setStatusFilters,
    direction: 'bottom',
    dataFilters: statusFilters,
    typeFilter: 'statuses',
    data: selectorData,
    strings: {
      viewAll: translate('filters.plans.viewAll'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
      search: translate('filters.plans.search')
    }
  }

  return (
    useInner ?
    <CheckboxSelectorInner style={{maxWidth: 'auto'}} {...checkboxSelectorProps} /> :
    <CheckboxSelector {...checkboxSelectorProps}>
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.plans.status.title')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default StatusesFilter
