import React from 'react'
import ServicesFilter from './Services'
import Skeleton from 'react-loading-skeleton'
import StartDate from './StartDate'
import { IconSignPlus } from '@Knowledge-OTP/znk-ui-components'
import isEqual from 'lodash/isEqual'
import useTranslation from '../../../../i18n/useTranslation'

const ActivitiesFilters = (props) => {
  const { filters, setFilters, kind, services, servicesLoading, baseFilter } = props
  const resetFilters = () => setFilters(baseFilter)
  const { translate } = useTranslation()
  return (
    <span className='px-2 d-flex'>
      {kind === 'test' ? (
        servicesLoading ? <Skeleton /> : <ServicesFilter services={services} serviceFilters={filters.serviceIds} setServiceFilters={(serviceIds) => setFilters(prevFilters => ({ ...prevFilters, serviceIds }))} />
      ) : null}
      <StartDate
        currentDates={[filters.from, filters.to]} setDateFilter={(dates) => {
          const [from, to] = dates
          setFilters(prev => ({ ...prev, from, to }))
        }}
      />
      {
        !isEqual(filters, baseFilter) ? (
          <span className='h45 btn-link d-flex align-items-center' onClick={resetFilters}>
            <IconSignPlus size={14} className='mr-1' />
            {translate('misc.cleanFilters')}
          </span>
        ) : null
      }
    </span>
  )
}

export default ActivitiesFilters
