import React from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import {
  AmountInput,
  CheckboxOption,
  Field,
  IconOutLine,
  ListItem,
  SelectOption
} from '@Knowledge-OTP/znk-ui-components'
import AddDiscount from '../../../GroupPlans/modal/New/Components/PricingPlan/AddDiscount'

const PlanPricing = (props) => {
  const { form, setForm } = props
  const { translate } = useTranslation()
  const [totalLessons, totalGroupLessons] = Object.values(form.lpw).reduce((arr, part) => {
    arr[0] += Object.values(part.valueIndividual).reduce((sum, lpw) => sum + lpw.totalLessons, 0)
    arr[1] += Object.values(part.valueGroup).reduce((sum, lpw) => sum + lpw.totalLessons, 0)
    return arr
  }, [0, 0])
  const [groupFlatFee, indivFlatFee] = [form.groupAmount, form.amount]
  const checkBoxObject = {
    registration: {
      value: form.payments.values.registration,
      valueWithDiscount: form.payments.values.registration - (form.payments.values.registration * (form.payments.discount.registration / 100))
    },
    exam: {
      value: form.payments.values.exam,
      valueWithDiscount: form.payments.values.exam - (form.payments.values.exam * (form.payments.discount.exam / 100))
    },
    pro: {
      value: form.payments.values.pro,
      valueWithDiscount: form.payments.values.pro - (form.payments.values.pro * (form.payments.discount.pro / 100))
    },
    groupLessons: { value: form.typePlanPricing === 'permonth' ? groupFlatFee : (totalGroupLessons * (form.groupAmount && form.groupAmount > 0 ? form.groupAmount : 0)) },
    lessons: { value: form.typePlanPricing === 'permonth' ? indivFlatFee : (totalLessons * (form.amount && form.amount > 0 ? form.amount : 0)) }
  }
  const registrationValue = form.payments.registration ? checkBoxObject.registration.valueWithDiscount : 0
  const examValue = form.payments.exam ? checkBoxObject.exam.valueWithDiscount : 0
  const proValue = form.payments.pro ? checkBoxObject.pro.valueWithDiscount : 0

  // VALOR TOTAL DE LECCIONES O CUOTAS
  const lessonsValue = form.payments.lessons ? checkBoxObject.lessons.value : 0
  // VALOR TOTAL DE LECCIONES O CUOTAS
  const groupLessonsValue = form.payments.groupLessons ? checkBoxObject.groupLessons.value : 0

  // VALOR TOTAL CON OTROS PAYMENTS
  const totalValue = (registrationValue + examValue + proValue + lessonsValue + (form.typePlanPricing === 'perlesson' ? groupLessonsValue : 0)).toFixed(2)

  // DESCUENTO A TOTAL DE LECCIONES O CUOTAS
  const discount = form.payments.discount.lessons
    ? ((lessonsValue + groupLessonsValue) * (form.payments.discount.lessons / 100)).toFixed(2) : (0).toFixed(2)

  return (
    <>
      <div className='py-2'>
        <h3 className='font-weight-bold my-2'>{translate('modal.newplan.pricingplan.pricing')}</h3>
        <div className='row mx-0'>
          <Field
            className='col-12'
            type={SelectOption}
            fieldName='typePlanPricing'
            options={[
              { id: 'perlesson', text: translate('modal.newplan.pricingplan.perlessonrate') },
              { id: 'permonth', text: translate('modal.newplan.pricingplan.flatfee') }
            ]}
            value={form.typePlanPricing}
          />
          <label className='col-12 text-muted mb-2 mt-3 pl-0'>
            {form.typePlanPricing ? form.typePlanPricing === 'perlesson'
              ? translate('modal.newplan.pricingplan.rate')
              : translate('modal.newplan.pricingplan.amount') : translate('modal.newplan.pricingplan.amount')}
          </label>
          <Field
            className='col-12 placeholder-to-right'
            type={AmountInput}
            fieldName='amount'
            fieldType='number'
            placeholder='$  0.00 '
            min='0'
          />
          {form.typePlanPricing === 'perlesson' ? (
            <>
              <label className='col-12 text-muted mb-2 mt-3 pl-0'>
                {translate('modal.newplan.pricingplan.grouprate')}
              </label>
              <Field
                className='col-12 placeholder-to-right'
                type={AmountInput}
                fieldName='groupAmount'
                fieldType='number'
                placeholder='$  0.00 '
                min='0'
              />
            </>
          ) : null}
          <div className='mt-3 w-100'>
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.registration'
                    checked={form.payments.registration}
                  />
                  <label>{translate('modal.newplan.registrationfee')}</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.registration ? 'text-muted' : ''}`} style={!form.payments.registration ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.registration.valueWithDiscount}${form.payments.discount.registration ? `(-${form.payments.discount.registration}%)` : ''}`}
                </label>
              }
            />
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.exam'
                    checked={form.payments.exam}
                  />
                  <label>{translate('modal.newplan.examregistrationfee')}</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.exam ? 'text-muted' : ''}`} style={!form.payments.exam ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.exam.valueWithDiscount}${form.payments.discount.exam ? `(-${form.payments.discount.exam}%)` : ''}`}
                </label>
              }
            />
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.pro'
                    checked={form.payments.pro}
                  />
                  <label> Zinkerz Pro</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.pro ? 'text-muted' : ''}`} style={!form.payments.pro ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.pro.valueWithDiscount}${form.payments.discount.pro ? `(-${form.payments.discount.pro}%)` : ''}`}
                </label>
              }
            />
            {form.typePlanPricing === 'perlesson' ? (
              <ListItem
                className='py-0 w-100'
                showBottomLine={false}
                showTopLine={false}
                Left={
                  <div className='d-flex col-12'>
                    <Field
                      type={CheckboxOption}
                      fieldName='payments.groupLessons'
                      checked={form.payments.groupLessons}
                    />
                    <label>{form.typePlanPricing === 'permonth' ? translate('modal.newplan.groupmonthlyfee') : `${totalGroupLessons} ${translate('modal.newplan.groupLessons')}`}</label>
                  </div>
                }
                Right={
                  form.payments.groupLessons
                    ? <label>{`$${checkBoxObject.groupLessons.value}`}</label>
                    : <label className='text-muted' style={{ textDecoration: 'line-through' }}>{`$${checkBoxObject.groupLessons.value}`}</label>
                }
              />
            ) : null}
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.lessons'
                    checked={form.payments.lessons}
                  />
                  <label>{form.typePlanPricing === 'permonth' ? translate('modal.newplan.monthlyfee') : `${totalLessons} ${translate('modal.newplan.lessons')}`}</label>
                </div>
              }
              Right={
                form.payments.lessons
                  ? <label>{`$${checkBoxObject.lessons.value}`}</label>
                  : <label className='text-muted' style={{ textDecoration: 'line-through' }}>{`$${checkBoxObject.lessons.value}`}</label>
              }
            />
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label className='font-weight-bold'>{form.payments.discount.discount > 0 ? 'Subtotal' : 'Total price'}</label>
              }
              Right={
                <label className='font-weight-bold'>{`$${totalValue}`}</label>
              }
            />
            <AddDiscount
              paymentLabel={form.typePlanPricing === 'permonth' ? translate('modal.newplan.monthlyfee') : `${totalLessons} ${translate('modal.newplan.lessons')}`}
              discount={form.payments.discount}
              setDiscount={(newObjectDiscount) => {
                setForm({
                  ...form,
                  payments: {
                    ...form.payments,
                    discount: newObjectDiscount
                  }
                })
              }}
            >
              {
                form.payments.discount.lessons > 0
                  ? <ListItem
                    className='py-3 w-100'
                    showBottomLine={false}
                    showTopLine={false}
                    Left={
                      <div className='d-flex'>
                        <IconOutLine
                          className='p-3 mr-1'
                          backgroundColor='#FF3E1D'
                          color='#FFFFFF'
                          textChildren={<span className='m-0' style={{ color: 'white' }}>x</span>}
                          sizePx={10}
                        />
                        <label>{`Discount (${form.payments.discount.lessons}%)`}</label>
                      </div>
                    }
                    Right={
                      <label>{`$${discount}`}</label>
                    }
                  />
                  : <label className='btn-link'>{translate('modal.newplan.adddiscount')}</label>
              }
            </AddDiscount>
            {form.payments.discount.lessons > 0 && (
              <ListItem
                className='py-0 w-100'
                showBottomLine={false}
                showTopLine={false}
                Left={
                  <label className='font-weight-bold'>{translate('modal.newplan.totalprice')}</label>
                }
                Right={
                  <label className='font-weight-bold'>{`$${(totalValue - discount).toFixed(2)}`}</label>
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className='borderTop ml-3' />
    </>
  )
}

export default PlanPricing
