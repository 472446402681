import React, { useEffect, useMemo } from 'react'
import { getFeeFromRangeDate } from '../../../GroupPlans/modal/New/utils'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import { Field, IconArrowRight, ListItem, PlainTextInput } from '@Knowledge-OTP/znk-ui-components'
import AddPayment from '../../../GroupPlans/modal/New/Components/PaymentScehdule/AddPayment'
import isEqual from 'lodash/isEqual'
const PaymentSchedule = (props) => {
  const {
    form,
    setCuotas,
    thisPayerUser,
    baseForm
  } = props
  const cuotas = form.cuotas
  let start = form.from
  let end = form.to
  let payments = []
  if (form.typeLesson === 'totalLesson' && form.typePlan === 'time') {
    payments = getFeeFromRangeDate(moment(start), moment(start).add(2, 'week'))
  } else if (start !== null && end !== null) {
    start = moment(start)
    end = moment(end)
    payments = getFeeFromRangeDate(start, end)
  }
  const [listIndiv, listGroup] = Object.values(form.lpw).reduce((arr, part) => {
    arr[0] += Object.values(part.valueIndividual).reduce((sum, lpw) => sum + lpw.totalLessons, 0)
    arr[1] += Object.values(part.valueGroup).reduce((sum, lpw) => sum + lpw.totalLessons, 0)
    return arr
  }, [0, 0])
  const totalLessonsPayment = (listIndiv * (form.payments.lessons ? form.amount || 0 : 0) + (listGroup * (form.payments.groupLessons ? form.groupAmount || 0 : 0)))
  const totalPay = form.typePlanPricing === 'permonth' ? (form.payments.lessons ? form.amount : 0) : totalLessonsPayment
  const calculatedCuotas = payments.map((pay) => {
    const aux = totalPay / payments.length
    return { ...pay, value: aux }
  }).filter(item => item.value > 0)
  const InitPayment = () => {
    setCuotas(calculatedCuotas)
  }
  useEffect(() => {
    if (!isEqual(form, baseForm)) {
      InitPayment()
    }
    // eslint-disable-next-line
  },[form.lpw, form.typePlan, form.amount, form.groupAmount, form.typePlanPricing, form.payments, baseForm])
  useEffect(() => {
    if (form.paymentstart !== baseForm.paymentstart) {
      const baseMonth = moment.utc(form.paymentstart).endOf('month')
      setCuotas(cuotas.map((c) => {
        c.time = baseMonth.clone()
        baseMonth.add(1, 'month')
        return c
      }))
    }
  }, [form.paymentstart, baseForm.paymentstart]) // eslint-disable-line
  const deletePaymentItem = (index) => () => {
    const oldValue = cuotas[index].value
    const auxArray = []
    if (cuotas.length > 1) {
      cuotas.forEach((obj, ind) => {
        if (ind !== index) {
          auxArray.push(obj)
        }
      })
      auxArray[auxArray.length - 1].value = auxArray[auxArray.length - 1].value + oldValue
      setCuotas(auxArray)
    } else {
      alert('No es posible eliminar todas las cuotas')
    }
  }
  const editPaymentItem = (index) => (newValue, date) => {
    // cantidad total de cuotas no customizadas
    let cTotal = 0
    let total = cuotas.reduce((totalRed, cuota, indx) => {
      if (!cuota.custom && indx !== index) { cTotal += 1 }
      return (indx === index) ? newValue + totalRed : (cuota.custom) ? cuota.value + totalRed : totalRed
    }, 0) // valor total de cuotas customizadas
    let valorCuota = 0
    // se calcula el monto a repartir entre las cuotas no customizadas
    total = totalPay - total
    if (cTotal > 0) {
      // valor de las cuotas no customizadas
      valorCuota = total / (cTotal)
    }
    setCuotas(
      cuotas.map((c, indx) => {
        if (index === indx) {
          return { ...c, value: newValue, custom: true, time: moment(date) }
        } else if (c.custom) {
          return c
        } else {
          return { ...c, value: valorCuota }
        }
      })
    )
  }
  const addPaymentItem = (newPayment) => {
    // cantidad total de cuotas no customizadas
    let cTotal = 0
    const total = cuotas.reduce((totalRed, cuota) => {
      if (!cuota.custom) { cTotal += 1 }
      return !cuota.custom ? cuota.value + totalRed : totalRed
    }, 0) // valor total de cuotas no customizadas
    let valorCuota = 0
    if (cTotal > 0) {
      valorCuota = total / (cTotal + 1)
    }
    newPayment = { ...newPayment, value: valorCuota }
    setCuotas(
      cuotas.concat([newPayment]).map((c) => {
        if (c.custom) {
          return c
        } else {
          return { ...c, value: valorCuota }
        }
      })
    )
    // setCanReset(true)
  }
  const distributedPayment = async () => {
    const total = cuotas.reduce((totalRed, cuota) => {
      return cuota.value + totalRed
    }, 0)
    // valor total de cuotas no customizadas
    const cTotal = cuotas.length
    let valorCuota = 0
    if (cTotal > 0) {
      valorCuota = total / cTotal
    }
    setCuotas(
      cuotas.map((c) => {
        return { ...c, value: valorCuota, custom: false }
      })
    )
  }
  const canAddPayment = cuotas.length > 0
  const valueWhenPlanApr = Object.keys(form.payments.values).reduce((accu, payKey) => {
    if (form.payments[payKey]) {
      return accu + (form.payments.values[payKey] - form.payments.values[payKey] * (form.payments.discount[payKey] / 100))
    } else {
      return accu
    }
  }, 0)
  const { translate } = useTranslation()
  
  const lastPay = useMemo(() => cuotas && cuotas?.length > 0 ? cuotas[cuotas.length - 1].time : moment(), [cuotas])
  const discountToFee = form.payments.discount.lessons > 0 ? (1 - (form.payments.discount.lessons / 100)) : 1
  const canReset = !isEqual(calculatedCuotas, form.cuotas)
  const canDistributed = cuotas.reduce((obj, c, idx) => {
    if (idx === 0) {
      return {
        ...obj,
        cuotaVal: c.value
      }
    } else {
      return {
        ...obj,
        arentDistributed: obj.arentDistributed || c.value !== obj.cuotaVal
      }
    }
  }, { arentDistributed: false, cuotaVal: null }).arentDistributed
  return (
    <>
      <div className='py-6'>
        <h3><b>{translate('modal.newplan.paymentschedule.title')}</b></h3>
        <div className='row pl-3'>
          <label className='text-muted mb-2 mt-3 pl-0'>{`${translate('modal.newplan.paymentschedule.paymentstart')} (${form.paymentstart ? translate('profile.payments.endOf', moment().format('MMMM')) : translate('modal.newplan.paymentschedule.selectdate')})`}</label>
          <Field
            type={PlainTextInput}
            fieldName='paymentstart'
            fieldType='date'
            InputClassName='col-12'
          />
          <ListItem
            className='col-12 pr-1 pl-0 my-2 py-2'
            Left={
              <label className='text-muted h45'>
                {translate('modal.newplan.paymentschedule.paymentmethod')}
              </label>
            }
            Right={
              <label className='text-muted h45'>
                {thisPayerUser && thisPayerUser.paymentMethod
                  ? thisPayerUser.paymentMethod.last4
                  : translate('modal.newplan.paymentschedule.nodefined')}
              </label>
            }
          />
          <div className='w-100'>
            <ListItem
              className='py-2 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label>{translate('modal.newplan.paymentschedule.whenapproving')}</label>
              }
              Right={
                <label>{`$${valueWhenPlanApr.toFixed(2)}`}<IconArrowRight /></label>
              }
            />
            {cuotas.length > 0 ? cuotas.map((obj, index) => {
              return (
                <ListItem
                  key={index}
                  className='py-2 w-100'
                  showBottomLine={false}
                  showTopLine={false}
                  Left={
                    <label>
                      <span>
                        {translate('modal.newplan.endof') + ' ' + moment(obj.time).format('MMMM')}
                      </span>
                      <span className='text-muted h45'>
                        {`    (${translate('modal.newplan.indexFee', {
                          num1: parseInt(index + 1), num2: parseInt(cuotas.length)
                        })})`}
                      </span>
                    </label>
                  }
                  Right={
                    <AddPayment value={obj.value} customPayment={false} deleteCuota={deletePaymentItem(index)} setCuotas={editPaymentItem(index)} time={obj.time} maxValue={totalPay}>
                      <label>{`$${(obj.value * discountToFee).toFixed(2)}`} <IconArrowRight /></label>
                    </AddPayment>
                  }
                />
              )
            }) : <label>{translate('modal.newplan.paymentschedule.selectlimitdates')}</label>}
            <ListItem
              key={-1}
              className='py-2 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label>{'Total: '}</label>
              }
              Right={
                <label>{`$${((totalPay * discountToFee) + valueWhenPlanApr).toFixed(2)}`}</label>
              }
            />
          </div>
          <label
            className={`${canAddPayment ? 'btn-link w-100' : 'text-muted  w-100'}`} onClick={() => {
              if (canAddPayment) {
                addPaymentItem({ time: moment(lastPay).add(1, 'month'), value: 0, custom: false })
              }
            }}
          >
            {translate('modal.newplan.paymentschedule.addmore')}
          </label>
          <label className={`${canDistributed ? 'btn-link text-error w-100' : 'text-muted w-100'}`} onClick={canDistributed ? distributedPayment : null}>
            {translate('modal.newplan.paymentschedule.distribute')}
          </label>
          <label className={`${canReset ? 'btn-link text-error w-100' : 'text-muted w-100'}`} onClick={canReset ? InitPayment : null}>
            {translate('modal.newplan.paymentschedule.reset')}
          </label>
        </div>
      </div>
      <div className='borderTop my-2' />
    </>
  )
}

export default PaymentSchedule
