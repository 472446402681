import React from 'react'
import {
  Field,
  CheckboxOption
} from '@Knowledge-OTP/znk-ui-components'

const InputLpw = (props) => {
  const { topic, form, setForm, part, lock, title, type, disabled = false } = props
  const change = (val) => {
    if(disabled) return
    if (form.statusPlan && form.statusPlan === 'scheduled') return
    setForm({ ...form, lpw: { ...form.lpw, [part]: { ...form.lpw[part], [topic.id]: { ...form.lpw[part][topic.id], [`value${type}`]: val } } } })
  }
  const thisVal = form.lpw[part][topic.id] && form.lpw[part][topic.id][`value${type}`] ? form.lpw[part][topic.id][`value${type}`] : 0
  return (
    <div className='row m-0'>
      <label className='col-12 p-0 text-muted mb-2 mt-3'>
        {title}
      </label>
      <div className={`${lock ? 'col-9' : 'col-12'} d-flex p-0`}>
        <div className='col-3 p-0'>
          <div
            className={`znk-input noRight text-center d-flex justify-content-center align-items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => {
              if (thisVal) {
                change(thisVal - 1)
              }
            }}
          >-
          </div>
        </div>
        <div className='col-6 px-1'>
          <input
            id={topic.id}
            className='znk-input noLeft noRight w-100'
            disabled={form.statusPlan === 'scheduled' || disabled}
            type='number'
            name={`lpw['${topic.id}']`}
            placeholder='0'
            min={0}
            onChange={(e) => {
              change(parseInt(e.target.value))
            }}
            style={{ textAlignLast: 'center' }}
            value={thisVal}
          />
        </div>
        <div className='col-3 p-0'>
          <div
            className={`znk-input noLeft text-center d-flex justify-content-center align-items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => {
              change(thisVal + 1)
            }}
          >+
          </div>
        </div>
      </div>
      {lock && (
        <div
          className='col-3 p-0 pl-1'
        >
          <div
            className='znk-input text-center border bg-white d-flex justify-content-center align-items-center text-muted'
          >
            <Field
              type={CheckboxOption}
              fieldName={`lpw[${part}][${topic.id}].lock`}
              disabled={form.statusPlan === 'scheduled' || disabled}
            />
            Lock
          </div>
        </div>
      )}
    </div>
  )
}

export default InputLpw
