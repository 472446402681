import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation create(
        $items: [SendPlan!]!
        $sendDate: DateTime
        $sendWspToStudent: Boolean = true
    ){
        sendPlansFromAdmin(
            sendDate: $sendDate 
            input: {
                items: $items
            }
            sendWspToStudent: $sendWspToStudent
      )
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error')
      return
    }
    mutation({ variables: obj }).then((result) => {
      onSuccess({ ...result, variables: obj })
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.sendPlansFromAdmin : null, error, validationErrors, submit }
}
