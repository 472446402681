import React, {useState, useMemo, useEffect} from 'react'
import {
    Modal, 
    IconClose, 
    Button,
    Field,
    CheckboxOption,
    ListItem,
    Avatar,
    Form
} from '@Knowledge-OTP/znk-ui-components'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import {useSelector, useDispatch} from 'react-redux'
//query user para obtener el padre del estudiante si lo tiene
import useUserQuery from './useUserQuery'
import Skeleton from 'react-loading-skeleton'
import SendCounselorResume from '../../../../Model/Lessons/SendCounselorResume'
import { showAlert } from '../../../../common/Alert/util'
import useTranslation from '../../../../i18n/useTranslation'

const ResumeOrientationModal = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const reduxResume = useSelector(
    (state) => state.modal.plan.resumeOrientationModal,
  )
  const {isOpen, dataLesson} = reduxResume
  const toggle = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'plan.resumeOrientationModal',
      data: {
        ...reduxResume,
        isOpen: !isOpen,
      },
    })
  }
  
  const reduxOrientationModal = useSelector((state) => state.modal.plan.orientationLessonModal)
  const {submit: sendResumeMutation, loading: loadSendResume} = SendCounselorResume(
    ({data}) => {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'plan.orientationLessonModal',
        data: {
          ...reduxOrientationModal,
          dataLesson: data?.sendCounselorResume
        },
      })
      toggle()
    },
    () => {
      showAlert({text: "¡Error!", status: "error"}, dispatch)
    }
  )
  const [getParents, {loading, data: dataStudent}] = useUserQuery()
  const [form, setForm] = useState({
      sendStudent: true,
      sendParent: true
  })
  const validation = useMemo(() => {
    return Object.values(form).reduce((bool, send) => {
        return bool || send // es true si al menos uno de los checks es true
    })
  }, [form])
  const [educator, student] = useMemo(() => {
    let studentAux = dataLesson?.student || dataLesson?.studentPlan || dataLesson?.studentGroup || {}
    if(Array.isArray(studentAux)) studentAux = studentAux.length > 0 ? studentAux[0] : {} //si la clase es grupal utiliza el primer estudiante    
    return [
        dataLesson?.substitute || dataLesson?.educator || {},
        studentAux
    ]
  }, [dataLesson])

  useEffect(() => {
    if(student?.studentUserId){
        getParents({
            variables: {
                id: student.studentUserId
            }
        })
    } 
    // eslint-disable-next-line
  }, [student])
  const parent = useMemo(() => {
    if(dataStudent?.user?.studentInfo?.studentType === 'family'){
        return dataStudent?.user?.studentInfo?.parents?.length > 0 
            ? dataStudent.user.studentInfo.parents[0] : null
    }
    return null
  }, [dataStudent])
  
  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header px-6 pt-6 pb-4 d-flex flex-column'>
          <div className='w-100 d-flex flex-row justify-content-between'>
            <span className='h2 font-weight-bold'>
              {translate('lessons.modal.infolesson.sendresume')}
            </span>
            <IconClose onClick={toggle} />
          </div>
        </div>
      }
    >
        <div className='m-0 px-6'>
            <Form value={form} onChange={setForm}>
                <div className='d-flex flex-column my-2'>
                    <ListItem
                        className='py-2'
                        label={null}
                        Left={
                            <div className='d-flex align-items-center'>
                            <Avatar
                                name={`${student?.firstName} ${student?.lastName}`}
                                named={false}
                                uri={null}
                                size='avatar-small'
                            />
                            <label className='ml-2 h4'>
                                {`${student?.firstName} ${student?.lastName}`}
                                <span className='h5 text-gray'> {`(Student)`}</span>
                            </label>
                            </div>
                        }
                        Right={
                            <Field
                                type={CheckboxOption}
                                fieldName={`sendStudent`}
                                checked={form.sendStudent}
                            />
                        }
                    />
                    {
                        loading ?
                        <Skeleton count={1} /> :
                        parent &&
                        <ListItem
                            className='py-2'
                            label={null}
                            Left={
                                <div className='d-flex align-items-center'>
                                <Avatar
                                    name={`${parent?.firstName} ${parent.lastName}`}
                                    named={false}
                                    uri={null}
                                    size='avatar-small'
                                />
                                <label className='ml-2 h4'>
                                    {`${parent?.firstName} ${parent.lastName}`}
                                    <span className='h5 text-gray'> {`(Parent)`}</span>
                                </label>
                                </div>
                            }
                            Right={
                                <Field
                                    type={CheckboxOption}
                                    fieldName={`sendParent`}
                                    checked={form.sendParent}
                                />
                            }
                        /> 
                    }
                </div>
            </Form>
            <Button 
                disabled={!validation}
                className={'w-100 mt-2 mb-6'}
                color={'primary'}
                size={'normal'}
                label={'Send'}
                activity={loadSendResume}
                onClick={() => {
                  let data = {
                    councelorId: educator?.educatorUserId,
                    lessonId: dataLesson?.id
                  }
                  if(form.sendStudent) data.studentId = student?.studentUserId
                  if(form.sendParent) data.parentId = parent?.parentUserId
                  sendResumeMutation(data)
                }}
            />
        </div>
    </Modal>
  )
}

export default ResumeOrientationModal
