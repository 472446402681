// LISTADO DE TASK
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TasksList from '../../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/Tasks'
import useNewTaskModal from '../../../../../../CounselingTemplates/Task/newTaskModal/useNewTaskModal'
import useViewTaskModal from '../../../../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import useAssignTaskModal from '../../../../../../CounselingTemplates/Task/assingTaskModal/useAssignTaskModal'
import { UseGetAllUserTasks } from '../../../../../../University/Model/useGetAllUserTasks'
import taskTypes from '../../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/utils/taskTypes'

const Tasks = () => {
    const reduxData = useSelector((state) => state.modal.student.infoStudentModal)
    const [allTasks, setAllTasks] = useState([])
    const { dataStudent } = reduxData
    const planId = dataStudent?.studentInfo?.homeNotification?.councelingPlanId || ''
    const taskStatus = []
    const [
        getAllUserTasks,
        // eslint-disable-next-line
        { data: dataAllUserTasks, loading, refetch },
    ] = UseGetAllUserTasks({})
    // const [getPlans, {data: plans, refetch}] = usePlansLazy(() => {}, () => {})
    // useEffect(
    //     () => {
    //         if(studentUserId) {
    //             getPlans({
    //                 variables: {
    //                     kind: ['counceling'],
    //                     status: ['draft', 'sent', 'scheduled'],
    //                     studentsUserIds: [studentUserId]
    //                 }
    //             })
    //         }
    //         // eslint-disable-next-line
    //     }, [studentUserId]
    // )

    // const { parsePlanFromAPI } = useCounselingPlanReducer()
    const { close: closeAssingTaskModal } = useAssignTaskModal()
    const { close: closeNewTaskModal } = useNewTaskModal()
    const { close: closeViewTaskModal } = useViewTaskModal()

    // const allTasks = useMemo(
    //     () => {
    //         if(plans?.plans?.docs) return plans.plans.docs.reduce((array, {tasks}) => {
    //             return array.concat(tasks)
    //         }, [])
    //         return null
    //     }, [plans]
    // )

    useEffect(() => {
        //Cada vez que se terminen de actualizar las tasks (traemos todas), hacemos un filter para cada estado
        if (dataAllUserTasks?.getUserTasks?.length > 0) {
            const examScoresTasks = dataAllUserTasks?.getUserTasks.filter(task => task.type === taskTypes.scoreCollection)
            setAllTasks(examScoresTasks)

        } else {
            setAllTasks([])
        }
    }, [dataAllUserTasks])

    useEffect(() => {
        if (planId && planId !== '') {
            const variables = {
                status: taskStatus,
                planId,
            }
            getAllUserTasks({ variables })
        }
        // eslint-disable-next-line
    }, [planId])

    return (
        <div className={'m-0 p-0 mb-3'} >
            {
                allTasks?.length > 0 &&
                <TasksList
                    // plan={parsePlanFromAPI({ tasks: allTasks }/**plans[plans.length-1]**/)}
                    allTasks={allTasks}
                    onChangeTask={(action, task) => {
                        switch (action) {
                            case 'edit':
                                //cierra formulario de nueva tarea
                                closeNewTaskModal()
                                break
                            case 'duplicate':
                                break
                            case 'assign':
                                //cierra modal para asignar tarea 
                                closeAssingTaskModal()
                                break
                            case 'delete':
                                //cierra modal de tarea (luego de eliminar)
                                closeViewTaskModal()
                                break
                            default:
                                break
                        }
                        refetch()
                    }}
                />
            }
        </div>
    )
}

export default Tasks