import React from 'react'
import usePaginatedCountries from '../../../Model/Countries/List'
import { Row } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import { Button, TableSelector } from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import CreateCountryModal from '../../../Model/Countries/Create/Modal'
import EditCountryModal from '../../../Model/Countries/Edit/Modal'
import pick from 'lodash/pick'
import moment from 'moment'
import DeleteCountryModal from '../../../Model/Countries/Delete/Modal'
import SetServicesRateModalForm from './Modal/setCountriesServicesRate'

const CountriesView = () => {
  const { data, loading, error } = usePaginatedCountries({ limit: 0 })
  const dispatch = useDispatch()
  const openEditModal = (country) => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'country.edit', data: { isOpen: true, ...country } })
  }
  const openDeletionModal = (country) => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'country.delete', data: { isOpen: true, ...country } })
  }
  const openSetServicesRateModal = (country) => {
    dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'country.servicesRate', data: { isOpen: true, countryId: country.id, servicesRate: country.servicesRate } })
  }
  const ordenateCountriesArray = (array) => {
    if (array?.length < 1) return
    return array?.map((country) => {
      return country
    }).sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })
  }
  return (
    <div className='d-flex flex-column'>
      <Row className='d-flex flex-column mx-0'>
        <div className='d-flex justify-content-end'>
          <span><Button label='+ Add' color='primary' onClick={() => dispatch({ type: 'SET_DATA_MODAL_FORM_LIST', payload: 'country.create', data: { isOpen: true } })} /></span>
        </div>
      </Row>
      <Row className='d-flex flex-column mx-0 mt-4'>
        {loading ? <Skeleton height={25} count={5} className='my-2' />
          : (error ? <div className='alert alert-danger'>Error en la petición</div> : data && ordenateCountriesArray(data.docs).map((item) => {
            return (
              <div className='d-flex my-2'>
                <span style={{ flex: 1 }} className='d-flex align-items-center justify-content-start'>{item.name}</span>
                <span style={{ flex: 2 }} className='d-flex align-items-center justify-content-center'>{item.code}</span>
                <span style={{ flex: 2 }} className='d-flex align-items-center justify-content-center'>{item.dialCode}</span>
                <span style={{ flex: 2 }} className='d-flex align-items-center justify-content-center'>{item.defaultStudentRate}</span>
                <span style={{ flex: 2 }} className='d-flex align-items-center justify-content-center'>{moment(item.createdAt).format('MMMM do, hh:mm')}</span>
                <span style={{ flex: 2 }} className='d-flex align-items-center justify-content-center'>{moment(item.updatedAt).format('MMMM do, hh:mm')}</span>
                <span style={{ flex: 2 }} className='d-flex align-items-center justify-content-end'>
                  <TableSelector
                    mainBtn={{
                      text: 'Edit',
                      onClick: () => openEditModal({
                        countryId: item.id,
                        updatedCountry: pick(item, ['name', 'code', 'dialCode', 'defaultStudentRate'])
                      })
                    }}
                    dropdownProps={{
                      options: [{
                        component: <span onClick={() => openEditModal({
                          countryId: item.id,
                          updatedCountry: pick(item, ['name', 'code', 'dialCode', 'defaultStudentRate'])
                        })}
                        >Edit
                        </span>
                      }, {
                        component: <span onClick={() => openSetServicesRateModal(item)}>Add Service Rate</span>
                      }, {
                        component: <span onClick={() => openDeletionModal({
                          countryId: item.id
                        })}
                        >Delete
                        </span>
                      }]
                    }}
                  />
                </span>
              </div>
            )
          }))}
      </Row>
      <SetServicesRateModalForm />
      <DeleteCountryModal />
      <CreateCountryModal />
      <EditCountryModal />
    </div>
  )
}

export default CountriesView
