import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useDeleteTimelineModal from './useDeleteTimelineModal'
import useDeleteTimelineMutation from '../../Model/useDeleteTimeline'
import {showAlert} from '../../../../common/Alert/util'
import { useDispatch } from 'react-redux'

const DeleteTimelineModal = () => {
    const {isOpen, toggle, close, timelineId, onChange, useMutation} = useDeleteTimelineModal()
    
    const dispatch = useDispatch()
    const [deleteTimeline, {loading: loadDelete}] = useDeleteTimelineMutation(
        (res) => {
            showAlert(
                {
                    text: '¡Timeline has been deleted successfully!',
                    status: 'success'
                },
                dispatch
            )
            onChange(timelineId)
        },
        (err) => {
            showAlert(
                {
                    text: err.toString(),
                    status: 'error'
                },
                dispatch
            )
        }
    )
    return (
        <ConfirmModal 
            classNameActionDiv={`d-flex flex-column`}
            classNameConfirm={'text-error white-space-nowrap'}
            isOpen={isOpen}
            toggle={toggle}
            title={'Do you want to delete this timeline?'}
            body={'This action can not be undone'}
            confirmAction={() => {
                if(useMutation) {
                    deleteTimeline({
                        variables: {
                            timelineId
                        }
                    })
                } else onChange(timelineId)
                
            }}
            cancelAction={() => {close()}}
            confirmText={'Delete timeline'}
            cancelText={'Cancel'}
            loading={loadDelete}
        />
    )
}

export default DeleteTimelineModal