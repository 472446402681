import React, {useEffect, useState, useMemo} from 'react'
import {
    ModalLayout, 
    AmountInput, 
    Button,
    InputMonth
} from '@Knowledge-OTP/znk-ui-components'
import useEditPaymentModal from './useEditPaymentModal'
import RenderInputOption from '../RenderInputOption'
import moment from 'moment'

const EditPaymentModal = () => {
    const {
        isOpen, 
        toggle, 
        onChange, 
        totalAmount: totalToDistributed,
        minAmount = 0,
        onDelete,
        defaultValue = {amount: null, date: null},
        title,
        isNew
    } = useEditPaymentModal()
    const [values, setValues] = useState({amount: null, date: null})
    useEffect(() => {
        if(isOpen && defaultValue) setValues(defaultValue)
        // eslint-disable-next-line
    }, [isOpen])
    const {parseMinAmount, parseMaxAmount} = useMemo(() => {
        return {
            parseMinAmount: minAmount,
            parseMaxAmount: totalToDistributed+(defaultValue?.amount || 0)  
        }
        // eslint-disable-next-line
    }, [minAmount, defaultValue, totalToDistributed])
    return (
        <ModalLayout
            className={`modal-colored modal-centered white-modal h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={`d-flex flex-column`}>
                    <span className={`h2 text-title font-weight-bold mt-2`}>{title || 'Edit payment item'}</span>
                </div>
            }
            underHead={null}
            options={null}
            minHead={<span className={`h3 text-title`}>{title || 'Edit payment item'}</span>}
            sideRight={null}
            toggleRight={() => {}}
            useRightSection={false}
            isOpenRight={false}
        >
            <RenderInputOption className={`mb-3`} key={'amount'} label={`Amount (min: ${parseMinAmount}, max: ${parseMaxAmount})`}>
                <AmountInput 
                    type={'number'}
                    value={values.amount} 
                    onChange={(e) => {
                        setValues({...values, amount: e})}
                    }
                    min={parseMinAmount}
                    max={parseMaxAmount}
                />
            </RenderInputOption>
            
            {/* <RenderInputOption key={'date'} label={'Payment date'}>
                <Input 
                    type={'date'}
                    value={values?.date ? moment(values?.date).format('YYYY-MM-DD') : null} 
                    onChange={(e) => {
                        setValues({...values, date: e.target.value})
                    }} isClearable={false}
                />
            </RenderInputOption> */}
            <RenderInputOption key={'date'} label={'Payment date'}>
                <InputMonth 
                    onChange={(e) => {
                        setValues({...values, date: moment(e).toISOString()})
                    }}
                    minYear={2021}
                    value={values?.date} isClearable={false} />
            </RenderInputOption>
            {
                !isNew && (
                    <RenderInputOption key={'remove'} label={null}>
                        <span className={`btn-link text-error`} onClick={() => {onDelete()}}>Remove payment</span>
                    </RenderInputOption>
                ) 
            }
            {/* <span>{values?.date}</span> */}
            <Button 
                disabled={!(parseFloat(values.amount) >= (parseMinAmount || 0) && parseFloat(values.amount) <= parseMaxAmount) || !values.date}
                className={'w-100'}
                label={title || 'Edit payment item'}
                color={'primary'}
                size={'medium'} 
                onClick={() => {
                    if(onChange) onChange({...values, amount: parseFloat(values.amount)})
                }} 
            />

        </ModalLayout>
    )
}

export default EditPaymentModal