import React, { useEffect, useState } from 'react'
import { ModalLayout, IconSearch, Input } from '@Knowledge-OTP/znk-ui-components'
import './index.css'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import { collegesMock } from './mocks/universitiesMock'
import useUniveristyDirectory from './useUniversityDirectory'
import useAllUniversities from '../Model/useAllUniversities'
import Pagination from '../../../common/Pagination'
import CompleteUniversityInfo from '../Utils/index'
import Skeleton from 'react-loading-skeleton'
import { TabContent, TabPane } from 'reactstrap'
import useMyCollegeModal from '../MyCollegeModal/useMyCollegeModal'

const UniversityDirectory = (props) => {

  // const {/**universities,*/ addUniversity } = props
  // eslint-disable-next-line
  const { selector = false, setSelected, selected, plan/** , onAddUniversity*/ } = props
  const {
    isOpen,
    userId,
    onChange: onAddUniversity,
    // open,
    // close: closeUniversityDirectory,
    toggle,
    universities,
    planId
  } = useUniveristyDirectory()

  const { open: openMyCollegeModal } = useMyCollegeModal()
 
  const [inputValue, setInputValue] = useState('')
  // Arreglo con el listado de información que necesitamos traer desde la API de Colleges
  const infoToObtain = ['name',
    'short_description',
    'city',
    'state_abbr',
    'type_category',
    'undergraduate_size',
    'acceptance_rate',
    'four_year_graduation_rate',
    'rankings_best_colleges',
    'sat_average',
    'act_cumulative_midpoint',
    'type_year',
    'avg_cost_of_attendance',
    'website',
  ]

  const showCollege = async (college, infoToObtain = []) => {
    if(!college?.isPrincipal) {
      const collegeComplete = await CompleteUniversityInfo(college, infoToObtain)
        openMyCollegeModal(() => {
          onAddUniversity()
        }, collegeComplete, userId, planId, universities)

    } else {
      openMyCollegeModal(() => {
        onAddUniversity()
      }, college, userId, planId, universities)
    }
  }

  // pagination
  const [page, setPage] = useState(1)
  const [next, setNext] = useState("")
  const [previous, setPrevious] = useState("")
  const [limit, setLimit] = useState(20)

  // data: es la información que traemos de la base de datos, con la query getUniversities
  const { loading, data } = useAllUniversities({
    limit: limit,
    next: next,
    previous: previous,
    search: inputValue.toLowerCase() || '',
  })

  useEffect(() => {

  }, [data, next, limit])

  useEffect(() => {
    setInputValue('')
  }, [isOpen])

  return (
    <>
      <ModalLayout
        isOpen={isOpen}
        toggle={() => toggle()}
        topHead={<span className={`h2 text-title`}>University Directory</span>}
        className='modal-colored modal-sidebar modal-desktop-half white-modal'
        underHead={
          <div
            className='w-100'
            style={{
              borderRadius: 16,
              backgroundColor: '#f0f4f8',
              height: '50px',
            }}
          >
            <Input
              type='addon'
              className='mr-5 px-2 d-flex flex-row-reverse'
              addOnComponent={
                <IconSearch
                  color='#bec5cf'
                  className='d-flex justify-content-start'
                  size={18}
                />
              }
              placeholder='Search'
              onChange={(event) => setInputValue(event.target.value)}
              value={inputValue}
            />
          </div>
        }
      >
        {/* Listado y filtrado de universidades */}
        <TabContent activeTab={loading ? 'loading' : 'list'}>
          <TabPane tabId={`loading`}>
            <Skeleton count={5} height={40} />
          </TabPane>
          <TabPane tabId={`list`}>
            <div className='h-100 d-flex flex-column'>
              {data?.docs?.length > 0 &&
                data?.docs.map((university, index) => (
                  (!university.deleteAt || university.deleteAt === null)  &&
                  <ListItem
                    key={index}
                    item={university}
                    onClick={async () => {
                      showCollege(university, infoToObtain)
                    }}
                  />
                ))}
              <Pagination
                pageSize={limit}
                changeSize={(size) => {
                  setLimit(size)
                }}
                clickNext={() => {
                  if (page < Math.floor(data.totalDocs / limit)) {
                    setPage(page + 1)
                    setPrevious("")
                    setNext(data.next)
                  }

                }}
                clickPrevious={() => {
                  if (page > 1) {
                    setPage(page - 1)
                    setNext("")
                    setPrevious(data.previous)
                  }
                }}
                hasPrevious={page > 1}
                hasNext={Math.floor(data.totalDocs / limit) > page}
                activePage={page}
                pageCount={
                  Math.floor(data?.totalDocs / limit) > 0
                    ? Math.floor(data?.totalDocs / limit)
                    : 1
                }
                className={`w-100 d-flex flex-row justify-content-between align-items-end pagination-opacity pt-2`}
              />
            </div>
          </TabPane>
        </TabContent>
      </ModalLayout>
    </>
  )
}

UniversityDirectory.defaultProps = {
  universities: collegesMock,
  addUniversity: () => { },
  onAddUniversity: () => { },
  isOpen: false,
  toggle: () => { },
}

UniversityDirectory.propTypes = {
  universities: PropTypes.array,
  addUniversity: PropTypes.func,
  onAddUniversity: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default UniversityDirectory
