import React from 'react'
import noDocsImg from '../../../../../assets/noDocs.svg'
import useTranslation from '../../../../../i18n/useTranslation'

const NoDocsComponent = () => {
  const {translate} = useTranslation()

  return (
    <>
      <div
        className='d-flex flex-column justify-content-center w-100 mb-2 mt-4 py-8'
        style={{
          borderRadius: '10px',
          border: '2px dashed',
          borderColor: '#f0f2f5',
        }}
      >
        <div className='d-flex justify-content-center w-100 mb-3'>
          <img
            src={noDocsImg}
            alt='No Docs'
            color='#696bff'
            width='70'
            height='90'
          />
        </div>
        <div className='d-flex flex-column justify-content-center w-100 mt-2'>
          <span className='text-muted text-center h4 mb-4'>
          {translate('universities.collegeModal.noDocs.title')}
          </span>
          <span className='text-muted text-center h4'>
          {translate('universities.collegeModal.noDocs.subtitle')}
          </span>
        </div>
      </div>
    </>
  )
}

export default NoDocsComponent
