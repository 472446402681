import React from 'react'
import {
    FileItem,
} from '@Knowledge-OTP/znk-ui-components'

const DocsItemComponent = (props) => {
    const { docData } = props

    return (
        <div
        className='d-flex flex-row w-100 align-items-center'>
            <FileItem
                lineWidth='d-flex flex-column w-100 mt-6 mb-2'
                fileText={
                    <>
                        <hr
                            style={{
                                display: 'block',
                                margin: 'auto',
                                overflow: 'hidden',
                                borderWidth: '3px',
                                backgroundColor: '#aacbff',
                                width: '60%',
                            }}
                        />
                        <hr
                            style={{
                                display: 'block',
                                margin: 'auto',
                                overflow: 'hidden',
                                borderWidth: '3px',
                                backgroundColor: '#aacbff',
                                width: '60%',
                            }}
                        />
                        <hr
                            style={{
                                display: 'block',
                                margin: 'auto',
                                overflow: 'hidden',
                                borderWidth: '3px',
                                backgroundColor: '#aacbff',
                                width: '40%',
                            }}
                        />
                    </>
                }
                className='align-items-center'
                color='#247cff'
                size='small-plus'
            />
             <div className='d-flex flex-column mx-4 my-auto w-70'>

                <div
                    className='d-flex flex-column ml-4 my-auto'
                >
                    <span className='text-info'>{docData?.name}</span>
                    <span
                        className='h6 text-truncate'
                        style={{
                            color: '#a8b2be',
                            maxWidth: '40vh',
                        }}
                    >
                        {docData?.url}
                    </span>
                </div>
            </div>
        </div>

    )
}


export default DocsItemComponent
