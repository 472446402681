import React from 'react'
import {
    IconArrowDown,
    DateRangeSelector
} from '@Knowledge-OTP/znk-ui-components'
import {Badge} from 'reactstrap'
const RangeDateFilter = (props) => {
    const {filters = [null, null], setFilters = () => {}} = props
    return (
        <DateRangeSelector
            filters={filters}
            setFilters={setFilters}
        >
            <Badge className='mx-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                    Range
                    <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </DateRangeSelector>
    )
}

export default RangeDateFilter