import React from 'react'
import { Avatar } from '@Knowledge-OTP/znk-ui-components'

const StudentField = (props) => {
  const { studentUser, item } = props
  return (
    <>
      <Avatar
        name={`${studentUser.firstName} ${studentUser.lastName}`}
        named={false}
        uri={item?.getStudentInfo?.avatar || null}
        size='avatar-medium'
      />
      <span className='ml-2'>{`${studentUser.firstName} ${studentUser.lastName}`}</span>
    </>
  )
}

export default StudentField
