import validate from 'validate.js'

const constraints = {
  PlanInputToresume: {
    presence: true
  },
  planId: {
    presence: true
  }
}

export default (data) => {
  return validate(data, constraints)
}
