import React, { useState, useRef } from 'react'
import {
    IconSearch,
    IconArrowRight,
    IconArrowLeft,
} from '@Knowledge-OTP/znk-ui-components'
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap'
import {TabContent, TabPane} from 'reactstrap'
import OrderByFilter from './Filters/GroupBy'
import LayoutSideRightSection from '../../Components/LayoutViewRightSection'
import "../../index.css"

import UniversitiesTab from './Sections/Universities'
import UniversityFilters from './Sections/Universities/Filters'

import StudentsTab from './Sections/Students'
import StudentFilters from './Sections/Students/Filters'

import ClassOfTab from './Sections/ClassOf'
import ClassOfFilter from './Sections/ClassOf/Filters'



const defaultGroups = [
    {id: 'students', name: 'Students', placeholder: 'Search student name'},
    {id: 'universities', name: 'Universities', placeholder: 'Search university name'},
    {id: 'class', name: 'Class', placeholder: 'Search student name'},
]
const UniversitiesSection = (props) => {
    const {PanelSection} = props
    const mobile = false
    const [isOpen, setOpen] = useState(false)
    const toggle = () => {setOpen(!isOpen)}
    const [groupBy, setGroupBy] = useState(defaultGroups?.find(({id}) => id === 'students'))
    
    const [search, setSearch] = useState(null)
    
    const searchInput = useRef(null)
    const [universityFilters, setUniversityFilters] = useState({studentIds: []}) 
    const [studentFilters, setStudentFilters] = useState({universitiesIds: []}) 
    const [classFilters, setClassFilters] = useState({studentIds: []}) 

    
    return (
        <LayoutSideRightSection
            leftSide={
                <>
                    <div
                        className={`h-100 col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between`} 
                        style={{ minHeight: '100%' }}
                        >
                        {!mobile && (
                            <div
                                className='znk-option-item'
                                style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                }}
                                onClick={toggle}
                            >
                                {isOpen ? (
                                    <IconArrowRight size={15} />
                                ) : (
                                    <IconArrowLeft size={15} />
                                )}
                            </div>
                        )}
                        <div className={`d-flex flex-column m-0 p-0 h-100`}>
                            <PanelSection/>
                            <div className={`d-flex flex-column my-4 justify-content-between`}>
                                <div className={`d-flex flex-row justify-content-end m-0 p-0 w-20 left-gradient`} style={{alignSelf: 'end'}}>
                                    <InputGroup className={`znk-card w-100 flex-nowrap p-0`} title={'Search'} >
                                        <input
                                            className={"znk-input noRight trans form-control"}
                                            placeholder={groupBy.placeholder}
                                            ref={searchInput}
                                            onKeyUp={({ key }) => {
                                                if (key === 'Enter') {
                                                    //buscar
                                                    setSearch(searchInput.current.value)
                                                }
                                            }}
                                        />
                                        <InputGroupAddon addonType='prepend' className='icon'>
                                            <InputGroupText type='text' className='znk-no-border'>
                                                <span className='addon'><IconSearch size={18} className='text-gray' /></span>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </div>
                                <div className={'w-100 horizontal-scroll d-flex flex-row'} style={{alignItems: 'flex-end'}}>
                                    <div className={`d-flex flex-column m-0 p-0 w-100`}>
                                        <div className={`d-flex flex-row m-0 p-0 justify-content-between align-items-center pr-4 w-100 mt-2`}>
                                            <TabContent activeTab={groupBy?.id}>
                                                <TabPane tabId={`universities`}>
                                                    <UniversityFilters setFilters={(newFilters) => {
                                                        setUniversityFilters(newFilters)
                                                        }} filters={universityFilters}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={`students`}>
                                                    <StudentFilters
                                                        setFilters={(newFilters) => {
                                                            setStudentFilters(newFilters)
                                                        }}
                                                        filters={studentFilters}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={`class`}>
                                                    <ClassOfFilter
                                                        setFilters={(newFilters) => {
                                                            setClassFilters(newFilters)
                                                        }}
                                                        filters={classFilters}
                                                    />
                                                </TabPane>
                                                
                                            </TabContent>
                                            <div>
                                                <OrderByFilter
                                                    setSelected={setGroupBy}
                                                    selected={groupBy}
                                                    defaultGroups={defaultGroups}
                                                />
                                            </div>
                                        </div>
                                        <div className={`p-0 mx-0 mb-0 mt-2`}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`m-0 p-0 h-100`}>
                                <TabContent activeTab={groupBy?.id} className={`h-100`}>
                                    <TabPane tabId={`universities`} className={`h-100`}>
                                        <UniversitiesTab 
                                            search={search} 
                                            filters={universityFilters}
                                        />
                                    </TabPane>
                                    <TabPane tabId={`students`} className={`h-100`}>
                                        <StudentsTab
                                            search={search} 
                                            filters={studentFilters}
                                        />
                                    </TabPane>
                                    <TabPane tabId={`class`} className={`h-100`}>
                                        <ClassOfTab
                                            search={search} 
                                            filters={classFilters}
                                        />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                        {/* <Pagination
                            bottomFix
                            pageSize={10}
                            size='large' //large, small
                            changeSize={() => { }}
                            clickNext={() => { }}
                            clickPrevious={() => { }}
                            hasPrevious={false}
                            hasNext={false}
                            activePage={1}
                            pageCount={10}
                            loading={false}
                        /> */}
                    </div>
                </>
            }
            rightSide={
                <div className='m-0 p-0 d-flex flex-column justify-content-start p-5 pl-6 h-100' style={{overflowY: 'scroll'}}>
                    
                </div>
            }
            isOpen={isOpen}
            styleLeftSection={{overflowY: 'scroll'}}
        />
    )
}

export default UniversitiesSection