import { useQuery, gql } from '@apollo/client'

const useGetScoresGroupByTestDate = (
  vars = {},
  options = { fetchPolicy: 'cache-first' }
) => {
  return useQuery(
    gql`
      query(
        $countryIds: [String!]
        $classOf: [Int!]
        $testDate: DateTime
        $servicesIds: [String!]
        $groupBy: String
        $search: String
        $sortField: String = "examDate"
        $limit: Int
        $page: Int
      ) {
        getScoresWithFilters(
          search: $search
          limit: $limit
          sortField: $sortField
          countryIds: $countryIds
          classOf: $classOf
          testDate: $testDate
          servicesIds: $servicesIds
          groupBy: $groupBy
          page: $page
        ) {
          docs {
            _id
            examName
            examDate
            studentsByTest {
              studentId
              studentName
              exams {
                id
                name
                examDate
                totalScore
                planId
                taskId
                planInfo {
                  name
                  id
                  activityTest
                  serviceId
                  __typename
                }
                examId
                results {
                  name
                  scale
                  value
                  method
                  values {
                    name
                    scale
                    value
                    isOnMethod
                  }
                }
              }
            }
          }
          totalRows
        }
      }
    `,
    {
      ...vars,
    }
  );
};

export default useGetScoresGroupByTestDate;
