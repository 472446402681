import React, { useCallback, useEffect, useState } from 'react'
import { IconLoad, Switch, ToolTipInfo } from '@Knowledge-OTP/znk-ui-components'
import useSwitchDisabledForStudent from './Model/useSwitchDisabledForStudent'
import useTranslation from '../../../../../../i18n/useTranslation'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../../../common/Alert/util'

const DisabledForStudent = (props) => {
    const { disableForStudents = false, educatorId = '', refetch = () => { } } = props
    const { translate } = useTranslation()
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState(disableForStudents)
    useEffect(() => {
        setDisabled(disableForStudents)
    }, [disableForStudents])

    const onSuccess = () => {
        if(refetch) refetch()
        showAlert({text: translate('profile.actions.employmentdetails.disabledForStudent.onSuccess'), status: 'success'}, dispatch)
    }

    const onError = (err) => {
        console.error(err)
        showAlert({text: translate('profile.actions.employmentdetails.disabledForStudent.onError'), status: 'error'}, dispatch)
    }

    const [switchDisabled, { loading }] = useSwitchDisabledForStudent(onSuccess, onError)

    const RenderSwitch = useCallback(
        (props) => {
            return (
                <Switch
                    initialState={!disabled} 
                    onChange={() => {switchDisabled({ variables: { educatorId } })}}
                    // getState={() => { }} 
                    style={{}}
                    {...props}
                />
            )
        }, [disabled, switchDisabled, educatorId]
    )

    const leftText = translate('profile.actions.employmentdetails.disabledForStudent.switchText')

    return (
        <>
            <div className='d-flex flex-row justify-content-between mt-3'>
                <ToolTipInfo
                    className={'w-80 text-truncate'}
                    place='top'
                    innerElement={leftText}
                >
                    <span className='text-title'>{leftText}</span>
                </ToolTipInfo>
                <div className='d-flex flex-row align-items-center'>
                    {loading && (<IconLoad size={16}/>)}
                    <RenderSwitch className={'mb-0 ml-1'} />
                </div>
            </div>
        </>
    )

}

export default DisabledForStudent