import React, {useEffect, useState} from 'react'
import {
    Modal,
    ListOption,
    Avatar,
    Input,
    IconSearch,
    Button,
    IconClose,
    Switch,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslate from '../../../../../i18n/useTranslation'
import { useSelector, useDispatch } from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../store/modal/const'
import AllUsersLazy from '../../../../../Model/Users/List/lazy'
import AddParentToStudent from '../../../../../Model/Users/AddParentToStudent'
import Skeleton from 'react-loading-skeleton'
import {showAlert} from '../../../../../common/Alert/util'
import escapeRegExp from 'lodash/escapeRegExp'

const AddParentModal = (props) => {
    const reduxData = useSelector((state) => state.modal.student.addParentModal)
    const {setParent} = props
    const {isOpen, idStudent, refetch} = reduxData
    const {translate} = useTranslate()
    const dispatch = useDispatch()
    const [swicthState, setSwitchState] = useState(true)
    const toggle = () => {
        dispatch({ 
            type: SET_DATA_MODAL_FORM_LIST, 
            payload: 'student.addParentModal', 
            data: { 
                ...reduxData,
                isOpen: !isOpen
            } 
        })
    }
    //usuario con rol PARENT
    const [getParents, {data, loading}] = AllUsersLazy({
        fetchPolicy: 'no-cache'
    })
    useEffect(() => {
        if(isOpen) {
            getParents(
                {
                    variables: {
                        limit: 0,
                        roles: ["PARENT"],
                        state: swicthState,
                    }
                }
            )
        }
        // eslint-disable-next-line
    }, [isOpen, swicthState])
    const [selectedParent, setSelectedParent] = useState(null)
    const [parentSearch, setParentSearch] = useState('')
    const users = data?.allUsers?.docs || []
    const {submit: addParent, loading: loadMutation} = AddParentToStudent(
        () => {
            refetch()
            toggle()
            showAlert({
                text: translate('profile.actions.editFamilyInfo.linkedParent.successAlert'),
                status: 'success'
            }, dispatch)
        },
        () => {
            showAlert({
                text: translate('profile.actions.editFamilyInfo.linkedParent.errorAlert'),
                status: 'error'
            }, dispatch)
        }
    )

    return (
        <Modal
            className={`modal-centered`}
            isOpen={isOpen}
            toggle={toggle}
            Head={
                <div className='modal-header w-100 d-flex flex-row justify-content-between'>
                    <span className='h2 font-weight-bold mr-4'>
                        {translate('profile.actions.editFamilyInfo.linkedParent.title')}
                    </span>
                    <IconClose onClick={toggle} className={'text-cancel'}/>
                </div>
            }
        >
            <div className={'m-0 px-4 py-0'}>
                <Switch initialState={true} getState={(state) => setSwitchState(state)}/>
                <span className='ml-3 text-primary font-weight-bold'>{swicthState ? translate('profile.actions.editFamilyInfo.linkedParent.activeUsers') : translate('profile.actions.editFamilyInfo.linkedParent.inactiveUsers')}</span>
                <Input
                    className='w-100 input-xs p-0 mb-3'
                    placeholder={translate('profile.actions.editFamilyInfo.linkedParent.search')}
                    addOnComponent={<IconSearch size={16} />}
                    type='addon'
                    onChange={(e) => {
                        setParentSearch(e.target.value)
                    }}
                />
                {
                    loading
                    ? <Skeleton count={5} height={40} />
                    : 
                    users.filter(
                        (parent) => (parentSearch !== null && parentSearch !== '') ? new RegExp(escapeRegExp(parentSearch), 'i').exec(`${parent.firstName} ${parent.lastName}`) : true
                      ).map((parent) => {
                        let parentName = `${parent.firstName} ${parent.lastName}` 
                        return (
                            <ListOption
                                key={parent.id}
                                className='mb-2'
                                childClassName='d-flex'
                                selectedInput={selectedParent === parent.id}
                                label={parentName}
                                icon={
                                <Avatar
                                    name={parentName}
                                    named={false}
                                    uri={null}
                                    size='avatar-small mr-1 avatar-span-white'
                                />
                                }
                                onClick={() => {
                                    setSelectedParent(parent?.id)
                                    if (setParent) {
                                        setParent({
                                            id: parent?.id,
                                            name: parentName,
                                            avatar: parent?.avatar,
                                            email: parent?.email,
                                            phone: parent?.phone,
                                        })
                                    }
                                }
                                }
                            />
                        )
                    })
                }
                {
                    idStudent && selectedParent && (
                        <Button
                        className={'w-100'}
                        activity={loadMutation}
                        label={translate('profile.actions.editFamilyInfo.linkedParent.save')}
                        color={'primary'}
                        disabled={!idStudent || !selectedParent}
                        onClick={() => {
                            addParent({
                                    parentUserId: selectedParent,
                                    userId: idStudent
                                })
                            }}
                        />
                    )
                }
                {
                    // button for new student from new plan or new user
                    selectedParent && setParent && (
                        <Button
                        className={'w-100'}
                        activity={loadMutation}
                        label={'Select Parent'}
                        color={'primary'}
                        disabled={!selectedParent}
                        onClick={() => {
                            toggle()
                        }}
                        />
                    )
                }
             
            </div>
        </Modal>
    )
}

export default AddParentModal
