import React, { useMemo } from 'react'
import { 
  useWindowDimensions,
  Avatar
} from '@Knowledge-OTP/znk-ui-components'
import StatusField from '../../Plans/Fields/Status'
import moment from 'moment-timezone'
//responsive
import CompactPlanInfoField from '../../Plans/Fields/CompactPlanInfo'
//modal de creación/edición de timeline
import useCreateTimelineModal from '../Modal/CreateTimelineModal/useCreateTimelineModal'

import ViewCounselingPlanField from './Components/ViewCounselingPlanField'

// import ProgressPlanField from './Fields/ProgressPlanField'
//Sección con grafico circular, muestra el progreso del plan
//también se usa en viewPlanModal - Summary
// import PlanProgress from '../../CounselingPlans/Modal/viewPlanModal/Tabs/Summary/Sections/PlanProgress'
import useDeleteTimelineModal from '../Modal/DeleteTimelineModal/useDeleteTimelineModal'

const getValues = (date, type) => {
  const month = moment(date).month()
  let year = moment(date).year()
  if (month >= 10 && month <= 12) {
    year += 1
  }
  const difY = year - moment().year()
  switch (type) {
    case 'class':
      return year.toString().substr(2, 2)
    default:
      return 12 - difY
  }
}


const useResponsivePlanColumns = (data, refetchTimelines) => {
  const { breakWidth } = useWindowDimensions()
  const {open: openCreateTimelineModal, close: closeCreateTimelineModal} = useCreateTimelineModal()
  const {open: openDeleteTimelineModal, close: closeDeleteTimelineModal} = useDeleteTimelineModal()

  //retorna el rango de fecha del timeline
  const getRangeTime = (item) => {
    const MIN_DATE = (item?.timeline || []).reduce((minDate, plan) => {
      if(plan?.lpw) {
        //plan
        const minDatePlan = Object.entries(plan.lpw).reduce((min, [_, part]) => {
          if(part['startdate'] && moment(part['startdate']).valueOf() < min) return moment(part['startdate']).valueOf()
          return min
        }, minDate)
        return minDatePlan
      } else {
        //evento
        if(moment(plan?.date).valueOf() < minDate) return moment(plan?.date).valueOf()
        return minDate 
      }
    }, Number.POSITIVE_INFINITY)

    const MAX_DATE = (item?.timeline || []).reduce((maxDate, plan) => {
      if(plan?.lpw) {
        //plan
        //verifica la fecha de fin en las partes del plan
        const maxDatePlan = Object.entries(plan.lpw).reduce((max, [_, part]) => {
          if(part['enddate'] && moment(part['enddate']).valueOf() > max) return moment(part['enddate']).valueOf()
          return max
        }, maxDate)
        return maxDatePlan
      } else {
        //evento
        //verifica la fecha de fin en al fecha del evento/test (plan.date)
        if(moment(plan?.date).valueOf() > maxDate) return moment(plan?.date).valueOf()
        return maxDate 
      }
    }, Number.NEGATIVE_INFINITY)

    return [moment(MIN_DATE).utc(), moment(MAX_DATE).utc()]
  }
  const editTimeline = (item) => {
    //abre modal de edición de timeline
    openCreateTimelineModal(() => {
      refetchTimelines()
      closeCreateTimelineModal()
    }, item?.studentUser, item?.timeline, true, item?.id )
  }
  return useMemo(() => {
    let columns = []
    if(breakWidth === 'SM') {
      columns.push({ 
        render: (item) => <CompactPlanInfoField studentUser={item.studentUser} status={item.status} thisService={{name: '', topics: []}} />, 
        colSpan: 8, 
        className: 'justify-content-start align-items-center', 
        id: 'avatar' 
      })
    }
    else {
      columns = columns.concat([
        // 2
        { 
          render: (item) => {
            const userName = `${item?.studentUser?.firstName} ${item?.studentUser?.lastName}`
            return (
              <div className={`d-flex flex-row`}>
                <Avatar
                  name={userName}
                />
                <span className={`align-self-center ml-2`}>{userName}</span>
              </div>
            )  
          }, 
          colSpan: 2, 
          className: 'justify-content-start align-items-center text-title', 
          id: 'avatar' 
        },
        // 1
        {
          render: (item) => {
            return <span>{item?.getAditionalInfo?.countryName}</span>
          },
          colSpan: 1,
          className: 'justify-content-center align-items-center text-secondary',
          id: 'events'
        },
        // 1
        {
          render: (item) => {
            const applicationYear = item?.getAditionalInfo?.graduationDate
            let title = {}
            if(!applicationYear) title = {title: 'Student dont have the graduation date on his profile.'}
            return <span {...title}>{
              applicationYear ? 
                `Class of ‘${getValues(moment(applicationYear).toISOString(), 'class')}` : 
                'No defined'              
              } 
            </span>
          },
          // render: (item) => {
          //   return <span>{`Class of ${moment(item?.getAditionalInfo?.graduationDate).format('YY')}`}</span>
          // },
          colSpan: 1,
          className: 'justify-content-center align-items-center text-secondary',
          id: 'events'
        },
        // 2
        {
          render: (item) => {
            return <span>{`${item.getPlans?.length} plans & ${item?.getTestAndEvents?.length} events`}</span>
          },
          colSpan: 2,
          className: 'justify-content-center align-items-center text-secondary',
          id: 'events'
        },
        // 1
        {
          render: (item) => {
            //poder abrir modal del counseling plan
            //colocar estrella de counseling + (type de plan || ' - ')
            //counselingPlansIds
            const counselingId = item?.getAditionalInfo?.counselingPlansIds[0]
            return (
              <ViewCounselingPlanField id={'counselingPlanField'} counselingPlanId={counselingId} /> 
              // <span onClick={() => {
              //   const counselingPlanId = item?.getAditionalInfo?.counselingPlansIds[0]
              //   console.log(counselingPlanId)
              // }} className={`d-flex flex-row align-items-center`}>
              //   <div className={`star-shape small counseling mr-1`} />Counseling plan
              // </span>
            ) 
          },
          preventOnClick: true,
          colSpan: 1,
          className: 'justify-content-start align-items-center text-secondary cursor-pointer',
          id: 'counselingPlanField'
        },
        // 2
        {
          render: (item) => {
            const [MIN_DATE, MAX_DATE] = getRangeTime(item)
            return <span>{`${MIN_DATE.format('MMM Do')} to ${MAX_DATE.format('MMM Do')}`}</span>
          },
          hover: (item) => {
            const [MIN_DATE, MAX_DATE] = getRangeTime(item)
            return <span>{`${MIN_DATE.format('MMM Do YYYY')} to ${MAX_DATE.format('MMM Do YYYY')}`}</span>
          },
          colSpan: 2,
          type: 'tooltip',
          className: 'justify-content-center align-items-center text-secondary',
          id: 'progress'
        },
        // 1
        {
          render: (item) => {
            return <StatusField status={item?.status} />
          },
          colSpan: 1,
          className: 'justify-content-end align-items-center text-secondary',
          id: 'status'
        }
      ])
    }
    // 2
    columns.push(
      {
        optionsParser: (item) => {
          return {
            options: [
              {component: <span
                onClick={() => {
                  openDeleteTimelineModal(() => {
                    //actualiza listado de timelines
                    refetchTimelines()
                    //cierra modal de confirmación
                    closeDeleteTimelineModal()
                  }, item.id, true)
                }}
              >Cancel timeline</span>}
            ], 
            mainBtnConfig: {
              text: 'Edit timeline', 
              onClick: () => {
                editTimeline(item)
              }
            }
          }
        },
        colSpan: 2,
        type: 'selector',
        className: 'justify-content-end align-items-center text-secondary',
        id: 'buttons'
      }
    )
    return columns
    // eslint-disable-next-line
  }, [breakWidth, data])
}

export default useResponsivePlanColumns
