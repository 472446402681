import queryLessonResult from '../../../../Model/QueryResult/useLessonResult'
import {useLazyQuery, gql} from '@apollo/client'

export default (addQueryString = '') => {
    return useLazyQuery(
        gql`query (
            $planId: String
            $sortField: String
            $previous: String
            $next: String
            $limit: Int
            $status: [LessonStatus!]!
            $studentIds: [String!]
          ) {
            lessons(
              planId: $planId
              sortField: $sortField
              previous: $previous
              next: $next
              limit: $limit
              status: $status
              studentIds: $studentIds
            ){
            docs {
              ${queryLessonResult}
              ${addQueryString}
            }
          }
        }`,
        {
          fetchPolicy: 'network-only'
        }
    )
}