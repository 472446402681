import React, { useState, useRef, useMemo, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  IconSearch,
  IconArrowRight,
  IconArrowLeft,
} from "@Knowledge-OTP/znk-ui-components";
import { TabContent, TabPane } from "reactstrap";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import StudentsScoresGroups from "./Sections/Students";
import TestDateScoresGroups from "./Sections/TestDate";
import ClassOfScoresGroups from "./Sections/ClassOf";
import OrderByFilter from "./Filters/GroupBy";
import GraduationFilter from "./Filters/Graduation";
import CountryFilter from "./Filters/Country";
import TestDateFilter from "./Filters/TestDates";
import LayoutSideRightSection from "../../Components/LayoutViewRightSection";
import { Button, IconSignPlus } from "@Knowledge-OTP/znk-ui-components";
import useHandlerExamForm from "./Sections/ShowResultsScore/useHandlerExamForm";
import ServicesFilters from "../../../Users/Filters/services";

const Scores = (props) => {
  const [filters, setFilters] = useState({
    //pagination
    limit: 10,
    previous: null,
    next: null,
    role: "STUDENT",
    testDate: { id: "", name: "-", date: null },
    //filters
    searchByStudent: "",
    searchByClass: "",
    searchByTestDate: "",
    classOf: [],
    countryIds: [],
    serviceIds: [],
  });
  const [graduationDates, setGraduationDates] = useState([]);
  const { PanelSection } = props;
  const mobile = false;
  const [isOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!isOpen);
  };
  const searchInput = useRef(null);
  const [countriesFilters = [], setCountriesFilters] = useState({
    countryIds: [],
  });
  const [refetch, handleRefetch] = useState(false);
  const { open: openCreateScoreModal } = useHandlerExamForm(handleRefetch);

  const { data: dataCountries } = useQuery(
    gql`
      query {
        getAllCouncelingCountries {
          id
          name
        }
      }
    `
  );
  const countriesList = useMemo(
    () => dataCountries?.getAllCouncelingCountries || [],
    [dataCountries]
  );

  const [classFilters, setClassFilters] = useState({ class: [] });
  const [testDateFilters, setTestDateFilters] = useState({ testDates: [] });
  const [servicesFilters, setServicesFilters] = useState({ serviceIds: [] });
  const defaultGroups = [
    {
      id: "students",
      name: "Students",
      placeholder: "Search",
    },
    { id: "class", name: "Class", placeholder: "Search" },
    { id: "testDate", name: "Test Date", placeholder: "Search" },
  ];
  const [groupBy, setGroupBy] = useState(
    defaultGroups?.find(({ id }) => id === "students")
  );

  // const [query, setQuery] = useState({
  //   limit: 10,
  //   page: 0,
  //   search: null,
  // });

  useEffect(() => {
    setClassFilters({ class: [] });
    setCountriesFilters({ countryIds: [] });
    setTestDateFilters({ testDates: [] });
    setServicesFilters({ serviceIds: [] });
  }, [groupBy.id]);

  // const [currentPage, setCurrentPage] = useState(1);
  return (
    <>
      <LayoutSideRightSection
        leftSide={
          <>
            <div
              className={`h-100 col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between`}
              style={{ minHeight: "100%" }}
            >
              {!mobile && (
                <div
                  className="znk-option-item"
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                  onClick={toggle}
                >
                  {isOpen ? (
                    <IconArrowRight size={15} />
                  ) : (
                    <IconArrowLeft size={15} />
                  )}
                </div>
              )}
              <div className="d-flex flex-column m-0 p-0 h-100">
                <PanelSection />
                <div className="d-flex flex-column my-4 justify-content-between">
                  <div
                    className="d-flex flex-row justify-content-end m-0 p-0 w-20 left-gradient"
                    style={{ alignSelf: "end" }}
                  >
                    <InputGroup
                      className="znk-card w-100 flex-nowrap p-0"
                      title="Search"
                    >
                      <input
                        className="znk-input noRight trans form-control"
                        placeholder={groupBy.placeholder}
                        ref={searchInput}
                        onKeyUp={({ key }) => {
                          if (key === "Enter") {
                            // buscar
                            switch (groupBy.id) {
                              case "students":
                                setFilters({
                                  ...filters,
                                  searchByStudent: searchInput.current.value,
                                });
                                break;
                              case "class":
                                setFilters({
                                  ...filters,
                                  searchByClass: searchInput.current.value,
                                });
                                break;
                              case "testDate":
                                setFilters({
                                  ...filters,
                                  searchByTestDate: searchInput.current.value,
                                });
                                break;
                              default:
                                break;
                            }
                          }
                        }}
                      />
                      <InputGroupAddon addonType="prepend" className="icon">
                        <InputGroupText type="text" className="znk-no-border">
                          <span className="addon">
                            <IconSearch size={18} className="text-gray" />
                          </span>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                  <div
                    className="w-100 horizontal-scroll d-flex flex-row"
                    style={{ alignItems: "flex-end" }}
                  >
                    <div className="d-flex flex-column m-0 p-0 w-100">
                      <div className="d-flex flex-row m-0 p-0 justify-content-left align-items-start pr-4 w-100 mt-2">
                        <GraduationFilter
                          graduationDates={graduationDates}
                          graduationFilters={filters.classOf}
                          setGraduationFilters={(newTypes) => {
                            // setCurrentPage(1);
                            setClassFilters({
                              classOf: newTypes,
                            });
                          }}
                        />
                        <CountryFilter
                          countries={[...new Set(countriesList)]}
                          countryFilters={filters.countryIds}
                          setCountryFilters={(newCountries) => {
                            // setCurrentPage(1);
                            setCountriesFilters({
                              countryIds: newCountries,
                            });
                          }}
                        />
                        <TestDateFilter
                          {...{
                            testDate: filters.testDate,
                            setTestDate: (td) => {
                              setTestDateFilters({ testDate: td.date });
                              // setQuery({ testDate: td });
                            },
                            servicesFilters: filters.serviceIds,
                          }}
                        />

                        <ServicesFilters
                          servicesFilters={filters?.serviceIds}
                          setServicesFilters={(services) => {
                            setServicesFilters({ servicesIds: services });
                          }}
                        />
                        <div className="d-flex ml-auto">
                          <OrderByFilter
                            setSelected={setGroupBy}
                            selected={groupBy}
                            defaultGroups={defaultGroups}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`m-0 p-0 h-100`}>
                  <TabContent activeTab={groupBy?.id} className={`h-100`}>
                    <TabPane tabId={`students`} className={`h-100`}>
                      <StudentsScoresGroups
                        search={filters.searchByStudent}
                        filters={
                          groupBy.id === "students"
                            ? {
                              ...countriesFilters,
                              ...classFilters,
                              ...testDateFilters,
                              ...servicesFilters
                            }
                            : {}
                        }
                        refetchQuery={refetch}
                      />
                    </TabPane>
                    <TabPane tabId="testDate" className={`h-100`}>
                      <TestDateScoresGroups
                        search={filters.searchByTestDate}
                        filters={
                          groupBy.id === "testDate"
                            ? {
                              ...testDateFilters,
                              ...countriesFilters,
                              ...classFilters,
                              ...servicesFilters
                            }
                            : {}
                        }
                        refetchQuery={refetch}
                      />
                    </TabPane>
                    <TabPane tabId={`class`} className={`h-100`}>
                      <ClassOfScoresGroups
                        search={filters.searchByClass}
                        filters={
                          groupBy.id === "class"
                            ? {
                              ...classFilters,
                              ...countriesFilters,
                              ...testDateFilters,
                              ...servicesFilters
                            }
                            : {}
                        }
                        setGraduationDates={setGraduationDates}
                        refetchQuery={refetch}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </div>

              {/* <Pagination
                bottomFix
                pageSize={10}
                size='large' //large, small
                changeSize={() => { }}
                clickNext={() => { }}
                clickPrevious={() => { }}
                hasPrevious={false}
                hasNext={false}
                activePage={1}
                pageCount={10}
                loading={false}
            /> */}
              <div
                className="d-flex flex-row justify-content-start align-items-left"
                style={{ position: "sticky", bottom: 0 }}
              >
                <Button
                  className="position-fixed p-0 ml-5"
                  color="primary"
                  size={"small"}
                  style={{
                    bottom: "15px",
                    right: "180px",
                    width: "fit-content",
                    /**   heigth: '50px',**/ boderRadius: "25px",
                  }}
                  label={
                    <span className="p-2">
                      <IconSignPlus
                        size={15}
                        className={`m-3`}
                        style={{ transform: "rotate(45deg)" }}
                      />
                    </span>
                  }
                  onClick={() => openCreateScoreModal(null, null, null)}
                />
              </div>
            </div>
          </>
        }
        rightSide={
          <div
            className="m-0 p-0 d-flex flex-column justify-content-start p-5 pl-6 h-100"
            style={{ overflowY: "scroll" }}
          ></div>
        }
        isOpen={isOpen}
        styleLeftSection={{ overflowY: "scroll" }}
      />
    </>
  );
};

export default Scores;
