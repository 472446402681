import React from 'react'
import {
    useWindowDimensions,
    ConfirmModal
} from '@Knowledge-OTP/znk-ui-components'
import useDismissPlan from './useDismissPlan'
import useDismissTimelinePlan from '../../Model/useDismissTimelinePlan'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../../../../common/Alert/util'
import '../PostponeRemider/index.css'

const DismissPlanModal = () => {
    const {isOpen, toggle, plan, close, onChange = () => {}} = useDismissPlan()
    const dispatch = useDispatch()
    const {breakWidth} = useWindowDimensions()
    const [dismissPlan, {loading}] = useDismissTimelinePlan(
        (res) => {
            showAlert(
                {
                    text: 'Success!',
                    status: 'success'
                }, dispatch
            )
            if(onChange) onChange()
            close()
        },
        (err) => {
            showAlert(
                {
                    text: 'Error!',
                    status: 'error'
                }, dispatch
            )
            console.error(err.toString())}
    )
    return (
        <ConfirmModal
            classNameModal={`${['SM'].includes(breakWidth) ? 'bottom-modal' : ''}`}
            loading={loading}
            classNameActionDiv='flex-row justify-content-end'
            classNameConfirm='text-right text-error'
            classNameCancel='mx-3 text-right'
            isOpen={isOpen}
            toggle={toggle}
            title={'Dismiss Plan'}
            body={<span className={`text-info`}>You are gonna cancel this timeline plan.</span>}
            confirmAction={() => {
               dismissPlan({variables: {planId: plan?.id}})
            }}
            cancelAction={toggle}
            confirmText={'Dismiss Plan'}
            cancelText={'Cancel'}
        />
    )
}

export default DismissPlanModal