import React from 'react'
import { IconArrowDown, ListAvatars } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'

const GroupStudents = props => {
  const { students } = props
  const { translate } = useTranslation()
  return (
    <>
      <ListAvatars direction='right' avatars={students.map(studentUser => ({ name: studentUser ? `${studentUser.firstName} ${studentUser.lastName}` : 'Not Found' }))} />
      <span className='align-self-center ml-2 text-title d-flex flex-row align-items-center'>
        {(students || [])?.length} {translate('plans.groupPlans.students')} 
        <div className={'hover-icon m-0 p-0'}>
          <IconArrowDown size={16} className='text-gray' />
        </div>
      </span>
    </>
  )
}

GroupStudents.propTypes = {

}

export default GroupStudents
