import React, {useRef, useMemo} from 'react'
import {
    DropDownNew,
    IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import InnerFilter from './inner'

const FilterDate = (props) => {
    const {filters = [], setFilters = () => {}, useInner = false} = props
    const badgeRef = useRef(null)
    const filterProps = useMemo(() => (
        {
            onCancel: () => {
                if(badgeRef?.current) badgeRef.current.click()
            },
            onApply: (newDates = []) => {
                setFilters(newDates)
                if(badgeRef?.current) badgeRef.current.click()
            },
            filters: filters
        }
    ), [badgeRef, filters, setFilters])
    
    return (
        useInner ? 
        <InnerFilter style={{maxWidth: 'auto'}} {...filterProps} /> : 
        <DropDownNew
            direction={'bottomCenter'}
            hoverEnabled={false}
            isOpen={false}
            options={
                [
                    {component: <InnerFilter {...filterProps}/>}
                ]
            }
            trigger={['click']}
        >
            <div
                onMouseEnter={() => {
                    badgeRef.current.click()
                }}
                className={`m-0 p-0`} 
                ref={badgeRef}
            >
                <Badge className='mx-1 filters' pill color='option'>
                    <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                    {'Date'}
                    <IconArrowDown className='ml-2' size={15} />
                    </label>
                </Badge>
            </div>
        </DropDownNew>

    )
}

export default FilterDate