import React from 'react'
import useTranslation from '../../../../../../../i18n/useTranslation'

const CannotCompleteSection = (props) => {
    const {toggle = () => {}} = props
    const {translate} = useTranslation()
    return (
        <>
            <label className={`text-gray`}>
                {
                    translate("modal.completeGroupPlan.cannotCompleteText")
                }
                {/* Group plan has pending or scheduled lessons, or any of his individuals plans is not on scheduled, draft or cancel status.  */}
            </label>
            {/* <RenderSelectedLessons selectedLessons={pendingLessons} /> */}

            <div className={`d-flex flex-row justify-content-end w-100`}>
                <label
                    onClick={toggle}
                    className={`btn-link text-gray cursor-pointer mb-4 mr-2`}>Ok</label>

            </div>
        </>
    )
}

export default CannotCompleteSection