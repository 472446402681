import validate from 'validate.js'

const constraints = {
  planId: {
    presence: true
  }
//   studentUserId: {
//     presence: true
//   },
//   rate: {
//     presence: true
//   },
//   serviceId: {
//     presence: true
//   },
//   enforceRecommendedEducators: {
//     presence: true
//   },
//   testDate: {
//     presence: true
//   },
//   recommendedEducators: {
//     presence: true
//   },
//   parts: {
//     presence: true
//   },
//   paymentItemsConfig: {
//     presence: true
//   },
//   statusPlan: {
//     presence: true
//   },
//   location: {
//     presence: true
//   }
}

export default (data) => {
  return validate(data, constraints)
}
