import React, {useState, useEffect} from 'react'
import {
    ModalLayout,
    Input,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import useNewStepModal from './useNewStepModal'

const NewStepModal = (props) => {
    const {isOpen, toggle, onChange, defaultValue, currentPhase = 'Pre-Application'} = useNewStepModal()
    const [stepName, setStepName] = useState(null)
    //setea defaultValue en state
    useEffect(() => {if(defaultValue) setStepName(defaultValue)}, [defaultValue])

    return (
        <ModalLayout
            className={`modal-centered h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={<span className={`h3 text-title`}>{`New step in "${currentPhase}"`}</span>}
        >
            <span className={`h45 text-gray`}>Name</span>
            <Input onChange={(e) => {
                setStepName(e?.target?.value)
            }} value={stepName} type={'text'}  placeholder={`Step name`} />
            <Button 
                className={`w-100 mt-3`}
                label={defaultValue === null ? `Create new step` : `Edit step`} 
                onClick={
                    () => {
                        toggle()
                        onChange(stepName)
                        setStepName(null)
                    }
                }
                color={'primary'}
            />
        </ModalLayout>
    )
}

export default NewStepModal