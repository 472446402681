import React from 'react'
import {
  ListItem,
  Avatar,
  IconArrowRight,
  IconAdd
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import PropTypes from 'prop-types'
import Employee from '../Employee'

const SelectEmployee = (props) => {
  const { form, setForm, title, edit, className='pl-0 pr-6 py-4 pl-3' } = props
  const { translate } = useTranslation()
  return (
    <>
      <div className={`${className}`}>
        {title && (<><h3><b>{translate('modal.newplan.student')}</b></h3><br /></>)}
        {edit ? (<Employee
          setStudent={(stu) => {
            setForm({ ...form, student: stu })
          }}
          student={form.student || {}}
        >
          {form.student
            ? <ListItem
              className='py-1'
              id='x-0'
              label={null}
              Left={
                <div className='d-flex align-items-center'>
                  <Avatar
                    name={`${form.student.firstName} ${form.student.lastName}`}
                    named={false}
                    uri={form.student.avatar}
                    size='avatar-medium'
                  />
                  <label className='ml-2 h4'>
                    {`${form.student.firstName} ${form.student.lastName}`}
                  </label>
                </div>
              }
              Right={<IconArrowRight />}
            />
            : <div style={{ alignItems: 'center' }}>
              <IconAdd className='text-primary' />
              <label className='btn-link ml-2'>{translate('payslips.newpayslip.addemployee')}</label>
            </div>}
        </Employee>)
          : (
            <>
              {form.student &&
              (<ListItem
                className='py-1'
                id='x-0'
                label={null}
                Left={
                  <div className='d-flex align-items-center'>
                    <Avatar
                      name={`${form.student.firstName} ${form.student.lastName}`}
                      named={false}
                      uri={form.student.avatar}
                      size='avatar-medium'
                    />
                    <label className='ml-2 h4'>
                      {`${form.student.firstName} ${form.student.lastName}`}
                    </label>
                  </div>
                }
                Right={<IconArrowRight />}
              />)}
            </>
          )}
      </div>
    </>
  )
}

SelectEmployee.propTypes = {
  first: PropTypes.bool,
  title: PropTypes.bool,
  invoice: PropTypes.bool,
  edit: PropTypes.bool
}

SelectEmployee.defaultProps = {
  first: true,
  title: true,
  invoice: false,
  edit: true
}
export default SelectEmployee
