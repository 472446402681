import { gql, useQuery } from '@apollo/client'

const AllActivities = (props) => {
  const { serviceIds, kinds, from = undefined } = props
  return useQuery(gql`
    query (
        $serviceIds: [String!]
        $kinds: [String!]
        $from: DateTime
    ) {
      allActivities(
          from: $from
          kinds: $kinds,
          serviceIds: $serviceIds){
          ... on Test {
            id
            name
            startDate
            endDate
            serviceId
            registrationDate
            scoreReleaseDate
            isDisabled
          }
      }
    }
  `, { 
      fetchPolicy: 'no-cache',
      variables: { serviceIds, kinds, from } }
  )
}

export default AllActivities
