import React, {useState} from 'react';
import {IconSignPlus} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation';
import ServicesFilter from '../Filters/services'
import ClassYearFilter from '../Filters/ClassYear'
import CountryFilter from '../Filters/Countries'
import StatusFilter from '../Filters/Status'
import { Badge } from 'reactstrap'
import TestDateFilter from '../Filters/testdata'
import TopicsFilter from '../Filters/topics'
// import { useSelector } from 'react-redux';
// import _ from 'lodash'

//retorna el html con los filtros especificos para cada sección (student, educators, staffs y parents)
//STUDENT EDUCATOR STAFF PARENT
const STUDENT = 'STUDENT'
const EDUCATOR = 'EDUCATOR'
// eslint-disable-next-line
const STAFF = 'SUPER_ADMIN'
const PARENT = 'PARENT'
const RenderFilters = (props) => {
    const {role = STUDENT, setQuery = () => {}, query = {}, canReset = false, onReset = () => {}, ...otherProps} = props
    const {translate} = useTranslation()
    // const services = useSelector((state) => state.user.services)

    // const getServiceById = (serviceId) => {
    //   return !_.isEmpty(services)
    //     ? services.find((service) => service.id === serviceId)
    //     : null
    // }
  const [namesDataFilters, setNamesDataFilters] = useState([])
    return (
        <>
            <div className='container-fluid mt-2 mt-md-5 d-flex px-0 mb-3 znk-scroll-horizontal' {...otherProps}>
              {
                [STUDENT, EDUCATOR].includes(role) && (
                  <>
                  <ServicesFilter 
                    servicesFilters={query?.serviceIds} 
                    setServicesFilters={(services) => {
                      setQuery({ ...query, serviceIds: services })
                    }} 
                  />
                    {
                      query?.serviceIds?.length ? 
                        <TopicsFilter 
                          {...{ 
                            topicFilters: query?.topicIds, 
                            setTopicFilters: (ids) => {setQuery({...query, topicIds: ids})}, 
                            serviceFilters: query?.serviceIds
                          }}  
                        /> : null
                    } 
                  </>   
                )
              }
              {
                [STUDENT].includes(role) && (
                  <TestDateFilter {...{ 
                    testDate: query?.testDate, 
                    setTestDate: (td) => {
                      setQuery({...query, testDate: td})
                    },
                    servicesFilters: query?.serviceIds
                  }} />
                )
              }
              
              {
                [STUDENT].includes(role) && (
                  <ClassYearFilter 
                    classFilters={query?.classYear} 
                    setClassFilters={(newClassYear) => {setQuery({...query, classYear: newClassYear})}} />
                )
              }

              {
                [STUDENT, PARENT].includes(role) && (
                  <CountryFilter 
                    countryFilters={query?.countriesIds} 
                    setCountryFilters={(newCountries) => {setQuery({...query, countriesIds: newCountries})}} 
                    setNamesDataFilters={setNamesDataFilters} 
                    namesDataFilters={namesDataFilters} 
                  /> 
                )
              }
              {
                [STUDENT, EDUCATOR, PARENT, STAFF].includes(role) && (
                  <StatusFilter statusFilters={ ![undefined, null].includes(query?.statuses) ? [query?.statuses ? '1' : '0'] : ['1', '0']} 
                    setStatusFilters={(status) => {
                      const allSelected = (status.includes('1') && status.includes('0')) || status.length === 0 || !status
                      const newStatus = allSelected ? undefined /**Muestra activos e inactivos**/ : status.includes('1')
                      setQuery({...query, statuses: newStatus})
                    }} />
                )
              }
              {
                canReset
                  ? <Badge className='mx-1 filters cursor-pointer' pill color='option' onClick={onReset}>
                    <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                      <IconSignPlus size={15} className='mr-1' />
                      {translate('misc.cleanFilters')}
                    </label>
                  </Badge>
                  : null
              }
            </div>
        </>
    )
}

export default RenderFilters