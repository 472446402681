import React, {useState} from 'react'
import { IconSignPlus, DropDownNew } from '@Knowledge-OTP/znk-ui-components'
import { showAlert } from '../../../../../../../../common/Alert/util'
import UnactiveUserMenu from '../UnactiveUserMenu'
import useDismissStudent from '../../../Model/useDismissStudent'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../../../../i18n/useTranslation'
const DismissUser = (props) => {
    const {
       onChange = () => {},
       userId
    } = props
    const [dismiss, {loading}] = useDismissStudent(
        () => {},
        (err) => {console.error(err.toString())}
    )
    const [step, setStep] = useState(`begin`) //begin, confirm, success
    const dispatch = useDispatch()
    
    const closeDropdown = () => {
        //cierra el dropdown
        setTimeout(() => {
            setVisible(false)
            //vuelve a la pagina inicial
            setTimeout(() => {
                setStep('begin')
                setVisible(undefined)
            }, 200)
        }, 3000)
    }
    
    
    const dismissUser = () => {
        dismiss({
            variables: {
                studentId: userId
            }
        }).then(
            () => {
                setStep('success')
                showAlert({
                    text: 'Success!',
                    status: 'success'
                }, dispatch)
                closeDropdown()
                if(onChange) onChange()
            },
            (err) => {
                console.error(err.toString())
                setStep('error')
                showAlert({
                    text: 'Error!',
                    status: 'error'
                }, dispatch)
                closeDropdown()
            }
        )


        
    }

    const { translate } = useTranslation()
    const [visible, setVisible] = useState(undefined)
    return (
        <DropDownNew
            visible={visible}
            direction={`bottomLeft`}
            options={[
                {
                    component: <UnactiveUserMenu
                        onConfirm={() => { dismissUser() }}
                        loading={loading}
                        step={step}
                        onCancel={() => { setStep('begin') }}
                        beginText={translate('dashboard.studentWithoutLessons.listItem.dismissUser') || 'Dismiss User'}
                        successText={translate('dashboard.studentWithoutLessons.listItem.missedNotification') || 'Missed Notification!'}
                        // errorText={'Error!'} 
                    />
                }
            ]
            }
        >
            <div className={`hover-icon danger`} onClick={() => {
                setStep('confirm')
            }}>
                <IconSignPlus className={`text-error`}/>
            </div>
        </DropDownNew>
    )
}

export default DismissUser