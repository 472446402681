export const getEmailsListFromUsers = (usersListFromPlan) => {
    if (!Array.isArray(usersListFromPlan)) return []
    const studentsEmails = []
    const parentsEmails = []
    if (usersListFromPlan?.length < 1) return
    // eslint-disable-next-line
    usersListFromPlan.map((plan) => {
        studentsEmails.push(plan.emails[0].address)
        if (plan?.studentInfo?.parents?.length > 0) {
            // eslint-disable-next-line
            plan?.studentInfo?.parents.map((parent) => {
                parentsEmails.push(parent.email)
            }
            )
        }
    }
    )
    return { studentsEmails: [...new Set(studentsEmails)], parentsEmails: [...new Set(parentsEmails)] }
}