import {useDispatch, useSelector} from 'react-redux'

const useSendWspAlertModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.dashboard.testDateCheckInStudent)
    const {isOpen} = reduxData
    const setData = (
        res = {isOpen: false, onChange: () => {}, student: {}, type: 'getScore', plan: {}}
        ) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'dashboard.testDateCheckInStudent',
            data: {
                ...reduxData,
                ...res
            }
        })
    }

    const closeModal = (isOpen) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'dashboard.testDateCheckInStudent',
            data: {
                ...reduxData,
                isOpen
            }
        })
    }

    return {
        ...reduxData,
        open: (onChange = () => {}, student = {}, type = 'getScore' /**testRegistration, getScore**/, plan = {}) => {
            setData({isOpen: true, onChange, student, type, plan})
        },
        close: () => {
            closeModal()
            // setData({isOpen: false})
        },
        toggle: () => {
            setData({isOpen: !isOpen})
        }
    }
} 

export default useSendWspAlertModal