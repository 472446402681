import {useMutation, gql} from '@apollo/client'
import planQueryResult from './queryResults/planQueryResult'

const useEditCouncelingPlanMutation = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $timelineInput: TimelineInput
                $councelingPlanInput: CouncelingPlanInput!
                $planId: ID!
                $isAtomic: Boolean
            ) {
                editCouncelingPlan (
                    councelingPlanInput: $councelingPlanInput
                    planId: $planId
                    timelineInput: $timelineInput
                    isAtomic: $isAtomic
                )  {
                    ${planQueryResult}
                }
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError
        }
    )
}

export default useEditCouncelingPlanMutation