import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import {
  Button, CheckboxOption,
  Field,
  Form,
  IconClose,
  LabeledField,
  Modal,
  PlainTextInput,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import useEditUser from '../../../../../Model/Users/Educators/SetEducatorInfoToUser'
import { FormGroup } from 'reactstrap'
import { pick } from 'lodash'
import EducatorServiceConfig from './EducatorServiceConfig'
import useTranslation from '../../../../../i18n/useTranslation'

const SetEducatorInfoModal = () => {
  const { isOpen, ...formData } = useSelector((state) => state.modal.user.setEducatorInfo)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.setEducatorInfo', data: { isOpen: !isOpen, ...formData } })
  }
  const onChange = (setFormFunc) => {
    const newVal = setFormFunc(formData)
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.setEducatorInfo', data: { isOpen: true, ...newVal } })
  }
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const { submit, loading, validationErrors } = useEditUser(toggle, console.log)
  const form = formData
  const onSubmit = () => {
    const updData = pick(form, ['backupLink', 'isActive', 'defaultRate'])
    try {
      submit({ educatorInfo: updData, userId: form.id })
    } catch (e) {
      // eslint-disable-next-line
      console.log({e})
    }
  }

  return (
    <Modal
      buttonLabel={null}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
      isOpen={isOpen}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      toggle={toggle}
      Head={
        <div
          onClick={toggle}
          className='modal-header d-flex justify-content-between mt-md-4'
        >
          <span className='h2 font-weight-bold mr-4'>
            {translate('users.educatorInfo')}
          </span>
          <IconClose size={20} onClick={toggle} />
        </div>
      }
    >
      <Form
        value={form}
        onChange={onChange}
        validationErrors={validationErrors}
      >
        <FormGroup className='d-flex align-items-center'>
          <Field
            type={CheckboxOption}
            fieldName='isActive'
            defaultState={form.currentIsActive}
          />
          <span>{form.isActive ? 'Active' : 'Deactivated'}</span>
        </FormGroup>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='defaultRate'
          placeholder='120'
          fieldType='number'
          disabled={!form.isActive}
          label={translate('users.editStudentInfo.defaultRate')}
        />
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='backupLink'
          placeholder='Backup Link'
          disabled={!form.isActive}
          label={translate('users.editStudentInfo.backuplink')}
        />
        <Button
          label={translate('misc.saveChanges')}
          color='primary'
          check
          size='medium'
          onClick={onSubmit}
          disabled={loading}
          activity={loading}
          style={{ width: '100%' }}
        />
      </Form>
      <EducatorServiceConfig {...formData} onChange={onChange} />
    </Modal>

  )
}

export default SetEducatorInfoModal
