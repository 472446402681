import {gql, useMutation} from '@apollo/client'

const useCheckInScoreNotification = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
        mutation ($planIds: [ID!]!) {
            checkInScoreNotification (planIds: $planIds)
        }`, {onCompleted, onError}
    )
}

export default useCheckInScoreNotification
