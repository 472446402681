import React from 'react'
import SearchBox from './SearchBox'
import useAddServiceToEducator from '../../../../../../../Model/Users/Educators/AddServiceToEducator'

const ServiceSelector = (props) => {
  const { userId, services, onChange } = props
  const onSuccess = (result) => {
    const { data: { addServiceToEducator: { educatorInfo } } } = result
    onChange((val) => ({ ...val, educatorInfo }))
  }
  const { submit, loading } = useAddServiceToEducator(onSuccess, console.log)
  const addService = (educatorServiceInput) => {
    submit({ educatorServiceInput, userId })
  }
  return (
    <span className='d-flex flex-column'>
      <SearchBox addItem={addService} loading={loading} selectedValues={services.map((item) => item)} />
    </span>
  )
}

export default ServiceSelector
