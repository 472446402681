import {useLazyQuery, gql} from '@apollo/client'
import taskQueryResult from '../../../../../../University/Model/queryResults/useTaskResult'

export const UseGetUserTask = (onSuccess = () => {}, onError = () => {}) => {
  // Sirve para traer una task en particular.
  return useLazyQuery(
    gql`query(
        $taskId: ID!
        $stepId: ID!
        $phase: PhaseEnum!
        $planId: ID!
              )
              {
        getUserTask(
            taskId: $taskId
            stepId: $stepId
            phase: $phase
            planId: $planId
    ) {
        ${taskQueryResult}
    }
}`,
    {
      fetchPolicy: 'no-cache',
      onCompleted: onSuccess,
      onError,
    },
  )
}
