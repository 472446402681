import React, { useEffect, useState } from 'react'
import {
  Modal,
  useWindowDimensions,
  StickyButton,
  Input,
  AmountInput,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const AddPayment = (props) => {
  const { value, setCuotas, maxValue, minValue, deleteCuota, time, statusPlan } = props
  const [isOpen, setOpen] = useState(false)
  const toggle = () => { setOpen(!isOpen) }
  const [amount, setAmount] = useState(0)
  const [date, setDate] = useState(null)
  useEffect(() => {
    setAmount(value)
  }, [value])
  useEffect(() => {
    if (time) {
      setDate(time.clone().utc().format('YYYY-MM-DD'))
    }
  }, [time])
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className='m-0 px-4 py-2'>
          <div className='m-0 py-2 px-0'>
            <label className='w-100 mt-4'>
              {`${translate('modal.newplan.pricingplan.amount')} (Max: ${maxValue.toFixed(2)}) (Min: ${minValue.toFixed(2)})`}
            </label>
            <AmountInput
              placeholder={translate('modal.newplan.pricingplan.amount')} 
              type='number' 
              value={parseFloat(parseFloat(amount).toFixed(2))} 
              onChange={(value) => {
                setAmount(value)
              }} 
              step={0.05}
              max={maxValue} 
              min={minValue}
              disabled={!!(statusPlan && statusPlan === 'scheduled')}
            />
            <label className='w-100 mt-4'>
              {translate('modal.newplan.paymentschedule.paymentdate')}
            </label>
            <Input
              placeholder='Date'
              type='date'
              value={date}
              disabled={!!(statusPlan && statusPlan === 'scheduled')}
              onChange={(e) => {
                setDate(e.target.value)
              }}
            />
            <label
              className='btn-link text-error mt-4' onClick={statusPlan && statusPlan === 'scheduled' ? () => {} : () => {
                deleteCuota(0)
                toggle()
              }}
            >{translate('modal.newplan.paymentschedule.removepayment')}
            </label>
          </div>
          {
            statusPlan && statusPlan === 'scheduled' ? (
              <StickyButton
                condition
                label='Close'
                color='#696CFF'
                disabled={amount <= 0 || amount > maxValue || amount < minValue}
                onClickButtonFunction={() => {
                  toggle()
                }}
              />
            )
              : (<StickyButton
                condition
                label={"Edit payment item" || translate('modal.newplan.paymentschedule.createpayment')}
                color='#696CFF'
                disabled={amount <= 0 || amount > maxValue || amount < minValue}
                onClickButtonFunction={() => {
                  setCuotas(parseFloat(amount), date)
                  toggle()
                }}
                 />)
          }
        </div>
      </Modal>
    </>
  )
}

AddPayment.propTypes = {
  value: PropTypes.number,
  onClickButton: PropTypes.func,
  customPayment: PropTypes.bool,
  maxValue: PropTypes.number, 
  minValue: PropTypes.number
}

AddPayment.defaultProps = {
  value: 0,
  onClickButton: () => {},
  customPayment: false, 
  minValue: 0
}
export default AddPayment
