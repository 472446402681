import {useWindowDimensions} from '@Knowledge-OTP/znk-ui-components'
import beanCancel from '../beanCancel.svg'
import beanScheduled from '../beanScheduled.svg'

const useBubblesModalHeader = (
  status,
  distribution = 0,
  defaultBreakwidth = null
) => {
  const {breakWidth} = useWindowDimensions()
  const dimension = defaultBreakwidth || breakWidth
  let beanImage
  switch (status) {
    case 'scheduled':
      beanImage = beanScheduled
      break
    case 'cancel':
      beanImage = beanCancel
      break
    default:
      beanImage = beanScheduled
      break
  }

  const style = {
    backgroundImage: `url(${beanImage}), url(${beanImage}), url(${beanImage})`,
    backgroundPosition:
      distribution === 1
        ? '-85% -50%, 112% 106%, 82% 15%'
        : '-30% -50%, 88% 67%, 70% 15%',
    // backgroundPosition: '-30% -50%, 88% 67%, 70% 15%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: `300px, ${
      distribution === 1 ? '210px, 93px' : '170px, 50px'
    }`,
    maxHeight: '100vh',
  }

  const styleMD = {
    backgroundImage: `url(${beanImage}), url(${beanImage}), url(${beanImage})`,
    backgroundPosition: '280% -53%, -37% 74%, 70% 15%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '300px, 170px, 50px',
    maxHeight: '100vh',
  }

  const styleSM = {
    backgroundImage: `url(${beanImage}), url(${beanImage}), url(${beanImage})`,
    backgroundPosition: '300% -40%, -37% 74%, 70% 15%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '230px, 170px, 50px',
    maxHeight: '100vh',
  }

  const styleSMCustom = {
    backgroundImage: `url(${beanImage}), url(${beanImage}), url(${beanImage})`,
    backgroundPosition: '300% -40%, 32% -104%, 108% 70%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '230px, 70px, 50px',
    maxHeight: '100vh',
  }

  return dimension === 'SM-custom' ? styleSMCustom :
      dimension === 'SM' ? styleSM : dimension === 'MD' ? styleMD : style
}

export default useBubblesModalHeader
