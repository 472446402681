const queryUserAccount = `
    id
    firstName
    lastName  
    studentInfo {
        id
        accounts {
            username
            password
            platform
            originAccount
            taskInfo {
                planId
                phase
                stepId
                taskId
            }
        }
        aditionalInfo
        }
`

export default queryUserAccount
