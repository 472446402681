import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import useSetMyUniversity from '../../../Model/useSetMyUniversity'
// import useTranslation from '../../../../../i18n/useTranslation'
import StatisticsComponent from '../statisticsInfo'

const ShowUniversityComponent = (props) => {
  const {
    // submit: addUniversity,
    // loading,
    data,
  } = useSetMyUniversity(() => {})
  const {university, universityId /** , addUniversity */} = props
  const [universityData, setUniversityData] = useState(null)
  const refetchUserData = useSelector((state) => state.user.refetch)
  // const {translate} = useTranslation()
  const [render, setRender] = useState(false)
  /* eslint-disable */
    useEffect(() => {
        setUniversityData(university)
    }, [university])
    useEffect(() => {
        if (data) {
            refetchUserData()
            setRender(!render)
        }
    }, [data])
    /* eslint-enable */
  useEffect(() => {}, [universityId, data])
  return (
    <>
    <StatisticsComponent 
    universityData={universityData}
    />
    </>
  )
}
export default ShowUniversityComponent
