import React, {useState, useEffect} from 'react'
import {
    ModalLayout,
    Input,
    Button,
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import useCreatePdfTemplate from './useCreatePdfTemplate'
import createPdfTemplate from '../../Model/createPdfTemplate'
import editPdfTemplate from '../../Model/editPdfTemplate'

const CreatePdfTemplate = () => {
    const {isOpen, toggle, useMutation, isEdit, onChange, template} = useCreatePdfTemplate()
    const [newTemplate, setNewTemplate] = useState({
        name: null, key: null
    })
    const {breakWidth} = useWindowDimensions()

    //actualiza newTemplate cuando template se actualiza en redux
    useEffect(
        () => {
            if(template) {
                setNewTemplate(template)
            }
        }, [template]
    )

    const [createPdf, {loading: loadCreate}] = createPdfTemplate(
        (res) => {
            onChange(res?.createPdfTemplate)
        },
        (err) => {console.error(err)}
    )
    const [editPdf, {loading: loadEdit}] = editPdfTemplate(
        (res) => {
            onChange(res?.editPdfTemplate)
        },
        (err) => {console.error(err)}
    )
    return (
        <ModalLayout
            className={`modal-centered white-modal`}
            isOpen={isOpen}
            toggle={toggle}
            contentClassName={`h-auto ${breakWidth === 'SM' ? 'p-0' : ''}`}
            useRightSection={false}
            disabledScrollAnimation
            topHead={<span className={`h3 text-title`}>{`${isEdit ? 'Edit' : 'Create'} PDF Templates`}</span>}
            underHead={null}
            minHead={<span className={`h3 text-title`}>{`${isEdit ? 'Edit' : 'Create'} PDF Templates`}</span>}
        >   
            <label className={`mb-2`}>Name</label>
            <Input 
                type={'text'}
                className={`mb-4`} 
                value={newTemplate?.name}
                placeholder={`Template name`}
                onChange={(e) => {
                    setNewTemplate({
                        ...newTemplate, name: e?.target?.value
                    })
                }} />

            <label className={`mb-2`}>Key</label>
            <Input 
                type={'text'} 
                className={`mb-4`}
                placeholder={`Template key`}
                value={newTemplate?.key}
                onChange={(e) => {
                    setNewTemplate({
                        ...newTemplate, key: e?.target?.value
                    })
                }} />

            <Button 
                color={`primary`}
                label={isEdit ? `Edit PDF template` : `Create PDF template`}
                activity={loadCreate || loadEdit}
                check
                className={`w-100 mt-3`}
                onClick={() => {
                    if(useMutation) {
                        const templateInput = {
                            name: newTemplate?.name,
                            key: newTemplate?.key,
                            json: newTemplate?.json || JSON.stringify([{header: [], body: [], footer: []}]),
                        }
                        if(isEdit) {
                            //edit mutation
                            editPdf({
                                variables: {
                                    templateInput,
                                    templateId: template?.id
                                }
                            })
                        } else {
                            //create mutation
                            createPdf({
                                variables: templateInput
                            })
                        }
                    } else {
                        onChange(newTemplate)
                    }
                }}
            />        
            
        </ModalLayout>
    )
}

export default CreatePdfTemplate