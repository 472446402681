import React, { useEffect, useState, useMemo } from 'react'
import {
  Modal,
  ListItem,
  Avatar,
  IconArrowRight,
  IconCountry,
  StickyButton
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  SelectDateHeader,
  DateList,
  DateCalendar,
  ConfirmRescheduled
} from './individual/index'
import moment from 'moment-timezone'
import useTranslation from '../../../../i18n/useTranslation'
import reScheduleLessonFromAdmin from '../../../../Model/Lessons/ReScheduleLessonFromAdmin'
import SelectEducator from '../../../Plans/modal/newPlan/SelectEducator'
import {
  parseOcurrencesToMutationRescheduled,
  parseOcurrencesToCalendar,
  closeCalendarConflict,
  callBackForConflict
} from '../utils'
import { TabPane, TabContent } from 'reactstrap'
import './style.css'
import RescheduleCounselor from '../../../../Model/Lessons/RescheduleCounselor'
import {UseMyFreeTimeFrames} from './Model/useMyFreeTimeFrames'
import { showAlert } from '../../../../common/Alert/util'

const RescheduledLesson = () => {
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const { isOpen, dataLesson, rescheduledPending } = useSelector((state) => state.modal.lesson.rescheduledLesson)
  
  //SERVICIO ORIENTACIÓN
  const services = useSelector((state) => state.user.services)
  const orientation = services?.find((serv) => serv.isOrientation)
  const isOrientation = dataLesson?.service?.id === orientation?.id
  const reduxOrientationModal = useSelector((state) => state.modal.plan.orientationLessonModal)

  useEffect(() => {
    if (!isOpen) setTimeScheduled(null)
  }, [isOpen])

  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'lesson.rescheduledLesson',
      data: {
        isOpen: !isOpen,
        dataLesson
      }
    })
  }

  const {
    loading: loadOrientationMutation, 
    submit: rescheduledOrientation,
  } = RescheduleCounselor(
    (result) => {
      toggle()
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.orientationLessonModal',
        data: {
          ...reduxOrientationModal,
          dataLesson: result?.data?.reScheduleCounselor
        }
      })
    },
    () => {
      alert("error")
    }
  )

  const { submit: rescheduleSubmit, loading: loadRescheduled } = reScheduleLessonFromAdmin (
    (result) => {
      if (result?.data?.reScheduleLessonFromAdmin?.hasOccurrences) {
        const ocu = result?.data?.reScheduleLessonFromAdmin?.occurrencesList
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'lesson.scheduledConflict',
          data: {
            ...parseOcurrencesToCalendar(
              ocu,
              (dataCalendar) => {
                callBackForConflict(
                  () => {
                    // dataCalendar.ocurrences siempre debe venir cargado con solo UNA lección
                    const solveLesson = dataCalendar?.ocurrences[0]
                    if (['newBlock', 'force', 'setPending'].includes(solveLesson.resolve)) {
                      rescheduleSubmit(
                        parseOcurrencesToMutationRescheduled(
                          dataCalendar,
                          solveLesson.resolve === 'newBlock'
                          ? 
                            {
                              startDate: timeScheduled?.date || timeScheduled,
                              lessonId: dataLesson.id,
                              ignoreEducatorUnavailability: false,
                              setAsPending: false
                            }
                          : solveLesson.resolve === 'force'
                            ? // scheduled anyway - dataCalendar viene seleccionado con el horario original
                            {
                              startDate: timeScheduled?.date || timeScheduled,
                              lessonId: dataLesson.id,
                              ignoreEducatorUnavailability: true,
                              setAsPending: false
                            }
                            : // Dejar pendiente
                            {
                              startDate: timeScheduled?.date || timeScheduled,
                              lessonId: dataLesson.id,                      
                              ignoreEducatorUnavailability: false,
                              setAsPending: true
                            }
                        )
                      )
                    } else {
                      // ignore => solo para recurrencias
                    }
                  }, dataCalendar, dispatch
                )
              }
            ),
            isOpen: true
          }
        })
      } else if (result?.data?.reScheduleLessonFromAdmin?.errorKey) {
        //alerta de error
        showAlert({
          text: result?.data?.reScheduleLessonFromAdmin?.message,
          status: 'error'
        }, dispatch)
      } else {
        // cierra el calendario de incidencias
        closeCalendarConflict(dispatch)
        // reiniciar formulario
        toggle()
        dispatch({
          type: 'SET_DATA_MODAL_FORM_LIST',
          payload: 'plan.infoLessonModal',
          data: {
            isOpen: rescheduledPending ? !rescheduledPending : true,
            dataLesson: result?.data?.reScheduleLessonFromAdmin?.newLessons[0]
          }
        })
      }
    }, (e) => {
      alert('error')
      console.error(e)
    }
  )

  const [page, setPage] = useState('list')
  const lessonDuration = moment.duration(moment(dataLesson?.endDate).diff(dataLesson?.startDate)).asMinutes()

  const zone = useSelector((state) => state.timezone.zone)

  const [from] = useMemo(() => {
    const fromDate = (dataLesson?.startDate && moment(dataLesson.startDate) > moment())
      ? moment(dataLesson.startDate).set('minute', 0).toISOString()
      : moment().add(1, 'hours').set('minute', 0).toISOString()
    return [
      fromDate,
      moment(fromDate).add(1, 'week').toISOString()]
  }, [dataLesson])

  useEffect(() => {
    setPage('list')
  }, [isOpen])

  const [educator, setEducator] = useState({})
  useEffect(() => {
    if (dataLesson?.substitute || dataLesson?.educator) setEducator(dataLesson.substitute || dataLesson.educator)
  }, [dataLesson])

  const [timeScheduled, setTimeScheduled] = useState(null)

  const topic = dataLesson?.individualTopic || dataLesson?.planTopic || dataLesson?.groupTopic || {}
  const service = dataLesson?.service || {}
  const student = dataLesson?.student || dataLesson?.studentPlan || dataLesson?.studentGroup || {}
  // STATE SE USA PARA DETERMINAR SEMANA DEL CALENDARIO LessonSelectCalendar
  const [currentMoment, setCurrentMoment] = useState(moment(from).startOf('isoWeek').toISOString())
  useEffect(() => { setCurrentMoment(moment(from).tz(zone).startOf('isoWeek').toISOString()) }, [from, zone])

  //QUERY myFreeTimesFrames
  const [getFreeTimes, { data, loading }] = UseMyFreeTimeFrames()
  useEffect(() => {
    if(currentMoment && educator?.educatorUserId && (student?.studentUserId || student?.length > 0) && page && zone) {
      let studentsIds = student?.length > 0 ? 
        student.map(({studentUserId}) => studentUserId) : [student.studentUserId]
      getFreeTimes({
        variables: {
          involveMyself: false,
          // from: from,
          from: page==='list' ? moment().toISOString() : currentMoment,
          to: page==='list' ? moment().add(1, 'week').toISOString() : moment(currentMoment).add(1, 'week').toISOString(),
          minDurationInMinutes: 60,
          educatorUserId: educator.educatorUserId,
          studentIds: studentsIds
        }
      })
    }
  }, [currentMoment, educator, student, getFreeTimes, page, zone])
  const myFreeFrames = useMemo(() => {
    return data?.myFreeTimeFrames || []
  }, [data])
  
  const educatorName = `${educator?.firstName} ${educator?.lastName}`
  const RenderTimezoneAndEducator = (
    <>
      <SelectEducator
        isNewLesson
        idTopic={topic?.id || ''}
        topicName={topic?.name || ''}
        form={{}}
        idService={service?.id || ''}
        setEducator={(edu) => {
          setEducator({
            ...edu,
            educatorUserId: edu?.id || edu?.educatorUserId
          })
        }}
        educator={educator || {}}
      >
        <ListItem
          showBottomLine
          showTopLine
          className='py-3'
          Left={
            <div className='m-0 p-0 d-flex flex-row align-items-center'>
              <Avatar
                name={educatorName}
                uri={null}
                size='avatar-medium'
              />
              <div className='d-flex flex-row align-items-center'>
                <label className='ml-4 mr-0 my-0'>{educatorName} <span className='h5 text-gray'>{`(${topic?.name})`}</span></label>
              </div>
            </div>
          }
          Right={
            <div className='m-0 py-1 px-2 hover-icon'>
              <IconArrowRight />
            </div>
          }
        />
      </SelectEducator>
      <ListItem
        showBottomLine={page !== 'calendar'}
        className='py-4'
        onClick={() => {
          dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'lesson.changeTimezoneModal',
            data: { isOpen: true }
          })
        }}
        Left={
          <div className='m-0 p-0 d-flex flex-row align-items-center'>
            <IconCountry />
            <div className='d-flex flex-row align-items-center'>
              <label className='ml-4 mr-0 my-0'>{zone.replace('/', ', ')} <span className='h5 text-gray'>(GMT-4)</span></label>
            </div>
          </div>
        }
        Right={
          <div className='m-0 py-1 px-2 hover-icon'>
            <IconArrowRight />
          </div>
        }
      />
    </>
  )

  const now = useMemo(() => {
    return moment()
  }, [])
  const possibleBlocks = useMemo(() => {
    return myFreeFrames?.reduce((arr, timeFrame) => {
        const [fromISO, toISO] = timeFrame
        const fromMoment = moment(fromISO).tz(zone)
        const toMoment = moment(toISO).tz(zone)
          .subtract(50, 'minute')
          .milliseconds(0)
          .seconds(0)
          .minutes(0)
        // eslint-disable-next-line
        while (fromMoment < toMoment) {
          const lessonArr = toMoment.clone()
          if (toMoment > now.clone().add(1, 'hour')) {
            arr.unshift({
              moment: lessonArr,
              label: `${lessonArr.format('h:mma')} to ${lessonArr
                      .clone()
                      .add(lessonDuration, 'm')
                      .format('h:mma')} | ${lessonArr.format('MMM D, YYYY')}`
            })
          }
          toMoment.subtract(isOrientation ? 15 : 1, isOrientation ? 'm' : 'h')
        }
        return arr
      }, [])
      ?.filter((block) => block.moment.hour() > 6 && block.moment.hour() < 24)
      ?.sort((a, b) => a.moment.valueOf() - b.moment.valueOf())
  // eslint-disable-next-line
  }, [myFreeFrames, zone])
  const [showElements, setShowElements] = useState(5)    
  const addFiveElements = () => {setShowElements(showElements+5)}    

  const RenderBody = ({ page }) => {
    return (
      <TabContent activeTab={page} className='px-2'>
        <TabPane tabId='list'>
          <DateList
            topic={topic}
            educator={educator}
            setEducator={setEducator}
            timezone={zone}
            loading={loading}
            needMoreOptionsFunction={() => {
              setTimeScheduled(null)
              setPage('calendar')
            }}
            RenderTimezoneAndEducator={RenderTimezoneAndEducator}
            possibleBlocks={possibleBlocks.slice(0, showElements)}
            addFiveElements={addFiveElements}
            lessonDuration={lessonDuration}
            setTimeScheduled={setTimeScheduled}
            timeScheduled={timeScheduled}
            isOrientation={isOrientation}
          />
        </TabPane>
        <TabPane tabId='calendar'>
          <DateCalendar
            setPage={setPage}
            topic={topic}
            educator={educator}
            setEducator={setEducator}
            timezone={zone}
            RenderTimezoneAndEducator={RenderTimezoneAndEducator}
            freeFrames={myFreeFrames}
            setTimeScheduled={setTimeScheduled}
            timeScheduled={timeScheduled}
            from={from}
            setCurrentMoment={setCurrentMoment}
            currentMoment={currentMoment}
            loading={loading}
          />
        </TabPane>
        <TabPane tabId='confirm'>
          <ConfirmRescheduled
            setPage={setPage}
            educator={educator}
            service={service}
            topic={topic}
            timeScheduledLabel={
                          `${moment(timeScheduled?.date || timeScheduled).format('h:mma')} to ${moment(timeScheduled?.date || timeScheduled)
                              .clone()
                              .add(lessonDuration, 'm')
                              .format('h:mma')} | ${moment(timeScheduled?.date || timeScheduled).format('MMM D, YYYY')}`
            }
          />
        </TabPane>
      </TabContent>
    )
  }

  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <SelectDateHeader
          title={page === 'confirm' ? translate('modal.rescheduledlesson.rescheduled') : translate('modal.rescheduledlesson.selectdate')}
          subtitle={`For ${service?.name} ${topic?.name} lesson | ${page === 'confirm' ? `${zone.split('/')[1]}, GMT-4` : ''}`}
          showCalendar={page === 'calendar'}
          toggle={toggle}
        />
      }
    >
      <div className='m-0 p-0'>
        <RenderBody page={page} />
        <StickyButton
          activity={loadRescheduled || loadOrientationMutation}
          condition={true}
          disabled={timeScheduled === null}
          className='px-7 py-5 pt-0'
          label={translate('modal.rescheduledlesson.continue')}
          color='#696CFF'
          onClickButtonFunction={() => {
            if (page === 'confirm') {
              if(isOrientation){
                rescheduledOrientation({
                  changeCouncelorInput: {
                    startDate: timeScheduled?.date || timeScheduled,
                    endDate: moment(timeScheduled?.date || timeScheduled).add(lessonDuration, 'm').toISOString(),
                    councelorId: educator?.educatorUserId || educator?.id || '',
                    studentId: student?.studentUserId || '',
                    lessonId: dataLesson?.id || ''
                  }
                })
              }
              else{
                rescheduleSubmit({
                  startDate: timeScheduled?.date || timeScheduled,
                  lessonId: dataLesson.id,
                  ignoreEducatorUnavailability: false,
                  setAsPending: false
                })
              }
            } else { setPage('confirm') }
          }}
        />
      </div>
    </Modal>
  )
}

export default RescheduledLesson
