import React, { useState } from 'react'
import {
    DropDownNew,
    IconUser
} from '@Knowledge-OTP/znk-ui-components'
import useToggleActiveStatus from '../../../Model/useToggleActiveStatus'
import { useDispatch } from 'react-redux'
import UnactiveUserMenu from '../UnactiveUserMenu'
import { showAlert } from '../../../../../../../../common/Alert/util'
import HandleShowLessonsPending from './HandleShowLessonsPending'
import ConfirmToggleUser from './ConfirmToggleUser'

const CheckboxActiveUser = (props) => {
    const {
        onChange = () => { },
        userId
    } = props
    const [openShowPending, setOpenShowPending] = useState(false)

    const [toggleActiveUser, { loading }] = useToggleActiveStatus()
    const [step, setStep] = useState(`begin`) //begin, confirm, success
    const dispatch = useDispatch()
    const toggleUser = (force = false) => {
        toggleActiveUser({
            variables: { userId: userId, force }
        }).then(
            (res) => {
                const newActive = !!res?.data?.toggleActiveStatus
                onChange(newActive)
                setStep('success')
                showAlert({
                    text: 'Success!',
                    status: 'success'
                }, dispatch)
                
                setOpenShowPending(false)
                setOpenConfirmToggle(false)

                //cierra el dropdown
                setTimeout(() => {
                    setVisible(false)
                    //vuelve a la pagina inicial
                    setTimeout(() => {
                        setStep('begin')
                        setVisible(undefined)
                    }, 200)
                }, 3000)
            },
            (error) => {
                console.error(error?.toString())
                setStep('error')
                showAlert({
                    text: error?.toString(),
                    status: 'error'
                }, dispatch)


                setOpenShowPending(true)
                //cierra el dropdown
                setTimeout(() => {
                    setVisible(false)
                    //vuelve a la pagina inicial
                    setTimeout(() => {
                        setStep('begin')
                        setVisible(undefined)
                    }, 1000)
                }, 3000)
            }
        )
    }

    const [visible, setVisible] = useState(undefined)

    const [openConfirmToggle, setOpenConfirmToggle] = useState(false)
    return (
        <>
            <DropDownNew
                visible={visible}
                direction={`bottomLeft`}
                options={[
                    {
                        component: <UnactiveUserMenu
                            onConfirm={() => { toggleUser() }}
                            loading={loading}
                            step={step}
                            onCancel={() => { setStep('begin') }}
                        />
                    }
                ]
                }
            >
                <div className={`hover-icon`} onClick={() => {
                    setStep('confirm')
                }}>
                    {
                        <IconUser className={`text-error`} />
                        // isActive ? <IconChecboxSelected /> : <IconCheckboxFilled />
                    }
                </div>
            </DropDownNew>
            <HandleShowLessonsPending 
                studentId={userId} 
                isOpen={openShowPending} 
                toggle={() => {setOpenShowPending(!openShowPending)}} 
                onChange={() => {//force unactive
                    setOpenConfirmToggle(true)
                }}
            />
            <ConfirmToggleUser
                isOpen={openConfirmToggle} 
                toggle={() => {setOpenConfirmToggle(!openConfirmToggle)}} 
                onConfirm={() => {
                    toggleUser(true)
                }} 
                loading={loading}
            />
        </>
    )
}


export default CheckboxActiveUser