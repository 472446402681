import { gql, useMutation } from '@apollo/client'

const useCreateUserInStripe = (onCompleted = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation($userId: String!) {
        createUserInStripe(userId: $userId) {
            stripeId
        }
      }
    `,
    {
      onCompleted,
      onError
    }
  )
}

export default useCreateUserInStripe
