import {gql, useMutation} from '@apollo/client'

const useSendWspAlertForTestDate = (
    onCompleted = () => {},
    onError = () => {}
) => {
    return useMutation(gql`
        mutation (
            $type: String!
            $studentId: String!
            $messages: [CheckInMessage!]!
            $registerDate: String
            $serviceMonth: String
            $serviceName: String!
        ) {
            sendWspAlertForTestDate (
                type: $type
                studentId: $studentId
                messages: $messages
                registerDate: $registerDate
                serviceMonth: $serviceMonth
                serviceName: $serviceName
            )
        }
    `, {
        onCompleted,
        onError
    })
}

export default useSendWspAlertForTestDate