import {useQuery, gql} from '@apollo/client'

const usePaginatedServicesQuery = (vars = {}, options = {fetchPolicy: 'cache-firts'}) => {
    return useQuery(
        gql`
            query (
                $sortField: String
                $previous: String
                $next: String
                $limit: Int
            ) {
                paginatedServices (
                    sortField: $sortField
                    previous: $previous
                    next: $next
                    limit: $limit
                ) {
                    docs {
                        id
                        name
                    }
                }
            }
        `,
        {
            variables: vars,
            options
        }
    )
}

export default usePaginatedServicesQuery