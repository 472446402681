import React, { useState, useEffect, useMemo } from 'react'
import {
  useWindowDimensions,
  ListOption,
  Avatar,
  StickyButton,
  Input,
  IconSearch,
  CheckboxOption,
  ToolTipInfo,
  IconLesson,
  ModalLayout
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../../../../../i18n/useTranslation'
import useModalState from '../../../../../../../utils/useModalState'
import isEmpty from 'lodash/isEmpty'
import { gql, useLazyQuery } from '@apollo/client'
import { nanoid } from 'nanoid'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
const SelectEducator = (props) => {
  const { isOpen, toggle, modalData } = useModalState('plan.selectTopicEducator')
  const { idTopic, setEducator, topicName, type, form } = modalData
  const [selectEducator, setSelectEducator] = useState({})
  const [recommended, setRecommended] = useState(false)
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const prospectiveParts = useMemo(() => {
    const parts = []
    if (
      (form && form.lpw['0'].startdate && form.lpw[(Object.keys(form.lpw).length - 1).toString()].enddate) &&
      (form.typePlan !== 'time' || form.typeLesson !== 'totalLesson')
    ) {
      Object.keys(form.lpw).forEach((part) => {
        if (form.lpw[part][idTopic]) {
          const { startdate, enddate } = form.lpw[part]
          if (startdate && enddate) {
            parts.push({
              from: startdate,
              to: enddate,
              lessonsPerWeek: form.lpw[part][idTopic][`value${type}`]
            })
          }
        }
      })
    }
    return parts
  }, [idTopic, form, type])
  const [educatorSearch, setEducatorSearch] = useState(null)
  const [getEducators, { data, loading }] = useLazyQuery(
    gql`query ($topicId: ID!, $serviceId: ID!,  $teachingTypes: [TeachingType!], $prospectiveParts: [PartCapInfo!]!) {
        allEducatorByTopic(topicId: $topicId, teachingTypes: $teachingTypes, serviceId: $serviceId ) {
            id
            emails {
                address
                verified
            }
            countryId
            firstName
            lastName
            timezone
            educatorInfo {
                id
                defaultRate
                services {
                    serviceId
                    name
                    defaultRate
                    topics {
                        topicId
                        teachingTypes
                        name
                        individualRate
                        groupRate
                        isWaiveIndividual
                        isWaiveGroup
                    }
                }
                isCapped (
                    parts: $prospectiveParts
                )
                backupLink
            }
        }
    }`
  )
  useEffect(() => {
    if (idTopic && form && type && prospectiveParts) {
      getEducators({
        variables: {
          topicId: idTopic,
          serviceId: form.idservice,
          teachingTypes: [type.toLowerCase()],
          prospectiveParts
        }
      })
    }
  }, [idTopic, type, getEducators, form, prospectiveParts])
  const educators = loading || !data || isEmpty(data) ? [] : data.allEducatorByTopic
  return (
    <ModalLayout
      buttonLabel={null}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'} h-auto`}
      isOpen={isOpen}
      toggle={toggle}
      topHead={
        <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('modal.newplan.selecteducator.title')}</span>
      }
      // Head={
      //   <div className='modal-header d-flex align-items-center pb-0 my-3'>
      //     <IconClose size={20} onClick={toggle} />
      //   </div>
      // }
    >
      <div className='p-0 mt-0 mb-4 mx-3'>
        <Input
          className='w-100 input-xs p-0 mb-3'
          placeholder={`${translate('modal.newplan.selecteducator.search')} ${topicName}`}
          addOnComponent={<IconSearch size={16} />}
          type='addon'
          onChange={(e) => {
            setEducatorSearch(e.target.value)
          }}
        />
        <div
          className='m-0 py-2 px-0' style={{
            maxHeight: '300px',
            overflow: 'hidden scroll'
          }}
        >
          <TabContent activeTab={loading ? `loading` : `show`}>
            <TabPane tabId={`loading`}>
              <Skeleton style={{borderRadius: '12px'}} count={5} height={44} className={`w-100 mb-2`}/>
            </TabPane>
            <TabPane tabId={`show`}>
              {

                educators.filter(
                  (edu) => educatorSearch !== null && educatorSearch !== '' ? new RegExp(educatorSearch, 'i').exec(`${edu.firstName} ${edu.lastName}`) : true
                ).map((edu) => {
                  const tooltipId = nanoid()
                  return (
                    <ListOption
                      className='mb-2'
                      id='x-0'
                      label={null}
                      selectedInput={selectEducator.id === edu.id}
                      childClassName='w-100'
                      icon={
                        <div className='d-flex align-items-center text-muted'>
                          <Avatar
                            name={`${edu.firstName} ${edu.lastName}`}
                            named={false}
                            uri={null}
                            size='avatar-small avatar-span-white'
                          />
                          <div className='d-flex flex-row align-items-center justify-content-between w-100'>
                            <span className='ml-2 h4'>
                              {`${edu.firstName} ${edu.lastName}`}
                            </span>
                            {parseInt(edu.educatorInfo.isCapped.split('/')[0]) > 0 && (
                              <ToolTipInfo
                                innerElement={
                                  <span className='mr-2 text-white'>
                                    {`${edu.educatorInfo.isCapped.split('/')[0]} ${translate('modal.newplan.selecteducator.outof')} ${edu.educatorInfo.isCapped.split('/')[1]} ${translate('modal.newplan.selecteducator.weeks')}`}
                                  </span>
                                }
                                place={'top'}
                                tooltipId={tooltipId}
                              >
                                <IconLesson className={`mr-2`} size={16} />
                              </ToolTipInfo>
                            )}
                          </div>
                        </div>
                      }
                      onClick={() => {
                        setSelectEducator(edu)
                      }}
                    />
                  )
                })
              }
            </TabPane>
          </TabContent>
        </div>
        <div className='d-flex px-2 mb-4'>
          <CheckboxOption
            name='recommended'
            defaultState={recommended}
            onChange={(e) => {
              setRecommended(e)
            }}
          />
          <span
            style={{
              whiteSpace: 'nowrap',
              overflowX: 'hidden'
            }} title={translate('modal.newplan.selecteducator.allow')}
          >{translate('modal.newplan.selecteducator.allow')}
          </span>
        </div>
        <StickyButton
          color='#696CFF'
          condition={true}
          disabled={JSON.stringify(selectEducator) === JSON.stringify({})}
          label={translate('modal.newplan.selecteducator.title')}
          onClickButtonFunction={() => {
            setEducator({ ...selectEducator, recommended: recommended })
            toggle()
          }}
        />
      </div>
    </ModalLayout>
  )
}

SelectEducator.propTypes = {
  form: PropTypes.object
}

SelectEducator.defaultProps = {
}
export default SelectEducator
