import { useQuery, gql } from "@apollo/client"
import { useMemo } from "react"
//import planQueryResult from './queryResults/planQueryResult'
import taskQueryResult from '../../University/Model/queryResults/useTaskResult'

const phasesQueryResult = `
    phaseName
    steps {
        id
        stepName
        idReference
        deleteAt
        tasks {
            id
            name
            idReference
            instructions
            type
            dueTime
            buttonLabel
            linkToShow
            isEspecific
            isReviewable
            status
            userId
            deleteAt
            showInDocs
            uploadDocs
            userMeetingId
            chatNotification {
                councelor
                student
            }
            accountsPlatform
            startDate
            dueDate
            reviewDate
            completedDate
            canceledDate
            ## serviceDate
            examId
            selectedExam {
                id: _id
                name
                results {
                    name
                    scale
                    values {
                        name
                        scale
                    }
                }
                serviceId
            }
            universities
            resolveTask {
                link {
                    lastProcessedAt
                    accessedCount
                }
                loginInfo {
                    platform{
                        name
                        username
                        password
                    }
                }
                score {
                    name
                    examDate
                    overall
                    results { 
                        name
                        scale
                        value
                        values {
                            name
                            scale
                            value
                        }
                    }
                }
                meeting
                uploadFiles {
                    url
                    uploadDate
                }
                completion
            }
        }
    }
`


const planQueryResult = `id
status
kind
typePlanPricing
studentUser {
    studentUserId
    firstName 
    lastName
    rate
    isManualRate
    grade
}
name
from
to
pricingRate
sentDate
sendPlanCron {
    sent
    sendDate
}
paymentItemsConfig {
    amount
    percentDiscount
    amountWithoutDiscount
    date
    description
    type
}
...on CouncelingPlan {
    isAtomic
    type
    taskTemplate
    proposalVersion
    advisor {
        teamUserId
        firstName
        lastName
    }
    head {
        teamUserId
        firstName
        lastName
    }
    councelor {
        teamUserId
        firstName
        lastName
    }
    tasks {
        ${phasesQueryResult}
    }
    timelines {
        plans {
            id
            name
            lessonIds
            from
            to
            status
            testDate
            kind
            pricingRate
            typePlanPricing
            serviceId
            parts {
                lessonsPerWeek
                totalLessons
                lessonPerWeekPerTopic {
                  topicId
                  lessonsPerWeek
                  totalLessons
                  lockLessons
                }
                from
                to
                name
            }
            deleteAt
        }
        events {
            name
            tbd
            id
            kind
            startDate
        }
        tests {
            name
            tbd
            id
            kind
            startDate
        }
    }
    
    aditionalInfo {
        parentUserId
        parentFirstName
        parentLastName
        applicationYear
        studentCountryId
        studentCountryCode
        studentCountryName
        studentType
        avatar
        avatarParent
        avatarCouncelor
        avatarAdvisor
        avatarHead
    } 
    
    tasksMeetings {
        ${taskQueryResult}
    }
}`

/**
 * 
 *
 *   
 * 
 * ***/
const usePlans = (variables = {}, options = {fetchPolicy: 'no-cache'}) => {
    const {data, loading, refetch} = useQuery(
        gql`
            query (
                $sortField: String
                $previous: String
                $next: String
                $limit: Int

                $finishSoon: Boolean
                $pendingApproval: Boolean
                $lastApproved: Boolean
                $previouslySent: Boolean
                $shortRecenlySent: Boolean
                
                $lessonsPending: Int
                $countryIds: [ID!]
                $educatorUserIds: [ID!]
                $testDate: DateTime
                $to: DateTime
                $from: DateTime
                $endDateFilter: [DateTime!]
                $startDateFilter: [DateTime!]
                $kind: [PlanKind!]!
                $topicIds: [ID!]
                $lessonStatus: [LessonStatus!]
                $status: [PlanStatus!]!
                $studentsUserIds: [ID!]
                $serviceIds: [ID!]
                $search: String
                $type: [PlanType!]
                $classOf: [Int!]
                $isAtomic: Boolean
                ) {
                plans (
                    sortField: $sortField
                    previous: $previous
                    next: $next
                    limit: $limit
                    finishSoon: $finishSoon
                    lastApproved: $lastApproved
                    pendingApproval: $pendingApproval
                    previouslySent: $previouslySent
                    shortRecenlySent: $shortRecenlySent
                    lessonsPending: $lessonsPending
                    countryIds: $countryIds
                    educatorUserIds: $educatorUserIds
                    testDate: $testDate
                    to: $to
                    from: $from
                    endDateFilter: $endDateFilter
                    startDateFilter: $startDateFilter
                    kind: $kind
                    topicIds: $topicIds
                    lessonStatus: $lessonStatus
                    status: $status
                    studentsUserIds: $studentsUserIds
                    serviceIds: $serviceIds
                    search: $search
                    type: $type
                    classOf: $classOf
                    isAtomic: $isAtomic
                    ) {
                        hasNext
                        hasPrevious
                        next
                        previous
                        totalDocs
                        docs {
                            ${planQueryResult}
                        } 
                    }
                }
        `, 
        {
            ...options,
            variables
        }
    )

    const {plans, paginatedData} = useMemo(() => {
        const {docs, ...paginatedInfo} = data?.plans || {}
        return {
            plans: docs || [],
            paginatedData: paginatedInfo
        }
    }, [data])

    return {plans, paginatedData, loading, refetch}
}

export default usePlans