import { useMutation, gql } from '@apollo/client'

const useDisableTestMutation = (onSuccess = () => { }, onDelete = () => { }) => {
    return useMutation(
        gql`
            mutation (
                $testId: String!
                ) {
                disableTest (
                    testId: $testId
                )  {                
                    name
                    tbd
                    id
                    kind
                    startDate
                    endDate
                    busy
                    userIdsInvolved
                    createdAt
                    updatedAt
                    googleCalendarEventId
                    serviceId
                    registrationDate
                    scoreReleaseDate
                    isDisabled
                }
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError: () => {
                onDelete()
            }
        }
    )
}

export default useDisableTestMutation