import React, { useState } from 'react'
import PropTypes from 'prop-types'
import usePaginatedServices from '../../../../../../../Model/Services/List'
import { IconSearch, Input } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import ServiceItem from './ServiceItem'

const SearchBox = props => {
  const { selectedValues, addItem, onSuccess } = props
  const { data, loading } = usePaginatedServices({ limit: 200 })
  const { docs: services } = data
  const selectedObj = selectedValues.reduce((obj, service) => ({ ...obj, [service.serviceId]: true }), {})
  const [search, setSearch] = useState('')
  return (
    <div className='d-flex flex-column mt-4'>
      <Input
        type='addon'
        className='my-1'
        addOnComponent={<IconSearch className='text-gray' />}
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
      />
      <span className='d-flex flex-column my-4'>
        {
          loading
            ? <Skeleton height={20} count={4} className='my-2' />
            : services && services.filter((service) => new RegExp(search, 'i').test(service.name)).map((service) => {
              const { id } = service
              return (selectedObj[id] ? null
                : <ServiceItem service={service} addItem={addItem} onSuccess={onSuccess} />
              )
            })
        }
      </span>
    </div>
  )
}

SearchBox.propTypes = {
  selectedValues: PropTypes.array,
  addItem: PropTypes.func
}

export default SearchBox
