import {
  ModalPushScroll,
  IconClose,
  IconOptions,
  Avatar,
  Modal,
  IconFlag,
  IconCountry
} from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import FullProfile from '../../../Users/Modal/Profile/full'
import SimpleProfile from '../../../Users/Modal/Profile/simple'
import { gql, useLazyQuery } from '@apollo/client'
import PersonalModalForm from '../../../Users/Modal/changePersonal'
import SetStudentServicesRateModalForm from '../../../Users/Modal/Student/setStudentServicesRate'
import EditFamilyInfo from '../../../Users/Modal/Student/EditFamily'
import Skeleton from 'react-loading-skeleton'
import { Collapse, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'

import Notes from '../../../Student/modal/InfoStudent/Notes'
import Summary from './Summary'
import Teach from './Teach'
import Paylips from './Paylips'
import AvailabilityRecurring from '../../../Users/Modal/Educator/SetAvailability/Recurring'
import AvailabilityTemporal from '../../../Users/Modal/Educator/SetAvailability/Temporal'

import ChangeNotificationsModal from '../../../Users/Modal/Student/ChangeNotifications'

import './style.css'
import useTranslation from '../../../../i18n/useTranslation'
import EditAvatar from '../../../../common/EditAvatar'
import * as moment from 'moment'
import AcademicInfo from '../../../Users/Modal/Educator/AcademicInfo'
import { WeeksBlock } from '../../../Users/Modal/Educator/SetWeeksBlock'

const InfoEducator = (props) => {
  const { countries, refetch } = props
  // const {translate} = useTranslation()
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.educator.infoEducatorModal)
  const { isOpen, full, userId, type, initPage, pageProfile, tab } = reduxData
  const { translate } = useTranslation()
  const { isOpen: isOpenEdit } = useSelector((state) => state.modal.user.edit)
  // const { isOpen: isOpenAvRecurring } = useSelector((state) => state.modal.user.availabilityRecurring)
  // const { isOpen: isOpenAvTemporal } = useSelector((state) => state.modal.user.availabilityTemporal)
  // const { isOpen: isOpenAcadInfo } = useSelector((state) => state.modal.user.studentInfo)
  // const { isOpen: servicesStudentRate } = useSelector((state) => state.modal.user.servicesRate)
  // const { isOpen: familyInfo } = useSelector((state) => state.modal.user.familyInfo)
  const [isReset, setIsReset] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
    const toggleCrop = () => {
    setOpenCrop(!openCrop)
  }
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'educator.infoEducatorModal',
      data: {
        ...reduxData,
        initPage: 'summary',
        full: false,
        isOpen: !isOpen
      }
    })
  }
  const toggleFull = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'educator.infoEducatorModal',
      data: {
        ...reduxData,
        full: !full
      }
    })
  }
  const openFull = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'educator.infoEducatorModal',
      data: {
        ...reduxData,
        full: true
      }
    })
  }

  const [id] = useState(null)
  const getxUser = (userId) => {
    getUser({
      id: userId
    })
  }
  const [getUser, { data, loading: loadEducatorData }] = useLazyQuery(
    gql`query($id: String!) {
      user(id: $id) {
        id
        createdAt
        emails {
          address
          verified
        }
        countryId
        country {
            id,
            name,
            defaultStudentRate
        }
        avatar
        gender
        spokenLanguages
        address
        city
        state
        zipCode
        firstName
        lastName
        phone
        timezone
        birthdate
        studentInfo {
          institution
          graduationDate
          gpa
          defaultRate
          aditionalInfo
          studentType
          notifications {
            missedLesson
            lessonsNotesReady
            lessonReminder {
              isAvailable
              duration
            }
          }
          servicesRate {
            defaultRate
            name
            serviceId
          }
          parents {
            parentUserId
            managePayments
            manageApprovals
            firstName
            lastName
            email
            phone
          }
        }
        educatorInfo {
          disableForStudents
          educatorAcademicInfo {
            educationLevel
            concentration
            university
            graduationDate
          }
          canTeach
          sinceDate
          myPayslips {
            id
            status
            category
            payslipDate
            to
            payslipNumber
            payslipProps {
              amount
              isPenalized
            }
          }
          getTeach {
            id
            country {
              name
            }
            firstName
            lastName
            gender
            studentInfo {
              graduationDate
            }
          }
          defaultRate
          services {
            serviceId
            name
            defaultRate
            topics {
              topicId
              individualRate
              groupRate
              isWaiveIndividual
              isWaiveGroup
              teachingTypes
              name
            }
          }
          backupLink
          weeklyCapHours
          isActive
          handbook
          availabilityBlocks {
            day
            hour
          }
          availabilityUpdated {
            updatedAt
            updatedBy
          }
          canTeach
          rating
          upcomingLesson {
            ...on IndividualLesson {
              kind
              title
              status
              startDate
              endDate
              educator {
                educatorUserId
                firstName
                lastName
                attendanceStatus
              }
              substitute {
                educatorUserId
                firstName
                lastName
                attendanceStatus
              }
              topic {
                id
                name
              }
              service {
                id
                name
              }
              student {
                firstName
                lastName
                studentUserId
              }
            }
            ...on PlanLesson {
              kind
              title
              status
              startDate
              endDate
              educator {
                educatorUserId
                firstName
                lastName
                attendanceStatus
              }
              substitute {
                educatorUserId
                firstName
                lastName
                attendanceStatus
              }
              topic {
                id
                name
              }
              service {
                id
                name
              }
              studentPlan: student {
                firstName
                lastName
                studentUserId
              }
            }
            ...on GroupLesson {
              kind
              title
              status
              startDate
              endDate
              educator {
                educatorUserId
                firstName
                lastName
                attendanceStatus
              }
              substitute {
                educatorUserId
                firstName
                lastName
                attendanceStatus
              }
              topic {
                id
                name
              }
              service {
                id
                name
              }
              students {
                firstName
                lastName
                studentUserId
              }
            }
          }          
        }
        isActive
        roles
      }
    }
  `,
    {
      variables: { id: userId || id },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    if (userId || id) {
      getUser()
    }
    // eslint-disable-next-line
  }, [userId, id])
  // useEffect(() => {
  //   if (data) {

  //   }
  // }, [data])
  const [activeTab, setActiveTab] = useState(tab ? tab : initPage || 'summary')
  const educatorName = `${data?.user?.firstName} ${data?.user?.lastName}`
  useEffect(() => {
    if (initPage && !tab) setActiveTab(initPage)
  }, [initPage, tab])
  // altura de la sección profile para dejar los tabs en el borde inferior de la pantalla
  let navMenuDimensions = null
  let modalContentDimensions = null
  let modalHeaderDimensions = null
  const [profileMenuDimensions, setProfileMenuDimensions] = useState({})
  useEffect(() => {
    if (navMenuDimensions && modalContentDimensions && modalHeaderDimensions) {
      setProfileMenuDimensions({
        // determina el alto que debe tener el menu de profile para que el listado de tabs
        // aparesca en la parte inferior de la pantalla.
        minHeight: `${parseInt(modalContentDimensions.height - modalHeaderDimensions.height - 2.5 * navMenuDimensions.height)}px`
      })
    }
  }, [navMenuDimensions, modalContentDimensions, modalHeaderDimensions, isOpen])
  const setPageProfile = (newPage) => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'educator.infoEducatorModal',
      data: {
        ...reduxData,
        full: true,
        pageProfile: newPage
      }
    })
  }
  const onChangeAvatar = (file) => {
    setOpenCrop(true)
  }
  const fetch = async () => {
    await refetch()
    setIsReset(isReset === null ? true : !isReset)
  }

  return (
    <>
      {
        <Modal
          className='modal-centered medium modal-colored white-modal pr-3 pl-3 '
          isOpen={openCrop}
          toggle={toggleCrop}
          backdrop='static'
          style={{height: '95%'}}
          StyleModalBody={{height: '100%'}}
          classModalBody='contentHeight'
          Head={
            <div className='modal-header p-0 m-0'>
              <div className='d-flex justify-content-between w-100 pt-7 pb-4 px-7'>
                <h2 className='font-weight-bold'>{translate('profile.actions.changeAvatar.title')}</h2>
                <IconClose onClick={toggleCrop} className='text-gray' />
              </div>
            </div>
          }
        >
          <EditAvatar userId={userId} fetch={fetch} toogle={toggleCrop}/>
        </Modal>
      }
      <ModalPushScroll
        isOpen={isOpen}
        more={full}
        minHead={null}
        refContent={(el) => {
          if (!el) return
          modalContentDimensions = el.getBoundingClientRect()
        }}
        MinHead={
          <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
            <div className='d-flex flex-row align-items-center'>
              {
                (loadEducatorData)
                  ? (
                    <>
                      <Skeleton circle count={1} height={50} width={50} /> <Skeleton count={1} width={50} height={25} className='ml-3' />
                    </>
                  )
                  : (
                    <>
                      <Avatar
                        name={educatorName}
                        uri={data?.user?.avatar ?? null}
                        innerDiv
                        size='avatar-small'
                        activateChange={true}
                        onClickImage={onChangeAvatar}
                        isReset={isReset}
                      /> <span className='h4 text-title ml-2'>{educatorName}</span>
                    </>
                  )
              }
            </div>
            <div className='d-flex flex-row align-items-center'>
              <IconOptions color='#D7E1EA' onClick={() => { }} className='mr-2' style={{ transform: 'rotate(90deg)' }} />
              <IconClose color='#D7E1EA' onClick={toggle} />
            </div>
          </div>
        }
        Head={
          <div
            className='modal-header px-6 px-sm-7 d-flex flex-column pt-4 mt-0'
          >
            <div
              ref={(el) => {
                if (!el) return
                modalHeaderDimensions = el.getBoundingClientRect()
              }}
              className='d-flex flex-row justify-content-between w-100'
            >
              {
                (loadEducatorData)
                  ? (
                    <div className='d-flex flex-column w-100'>
                      <Skeleton duration={2} circle count={1} height={120} width={120} />
                      <Skeleton duration={2} count={1} height={40} className='w-100' />
                      <Skeleton duration={2} count={4} height={20} className='w-100' />
                    </div>
                  )
                  : (
                    <div className='d-flex flex-column'>
                      <Avatar
                        name={educatorName}
                        uri={data?.user?.avatar ?? null}
                        innerDiv
                        size='avatar-extra'
                        activateChange={true}
                        onClickImage={onChangeAvatar}
                        isReset={isReset}
                      />
                      <div className='mt-5 mb-3 d-flex flex-column'>
                        <span className='h2 font-weight-bold text-title'>{educatorName}</span>
                        <span className='h45 text-gray mt-2'>{data?.user?.emails?.length > 0 ? data.user.emails[0].address : 'No defined'}</span>
                        <span className='h45 text-gray mt-2'>
                          {`Educator | Facebook | ${data?.user?.educatorInfo?.isActive ? 'Active' : 'Inactive'}`}
                        </span>
                        {data?.user?.phone && <span className='h45 text-gray mt-2'>
                          {data?.user?.phone}
                        </span>}
                        <span className='h45 text-gray mt-2'>
                        <IconFlag className='mr-1 mb-1' size={12} color='#72809D'/>{data?.user?.educatorInfo?.sinceDate ? `Since ${data?.user?.educatorInfo?.sinceDate}` : data?.user?.createdAt ? `Since  ${moment(data?.user?.createdAt).format("YYYY")}` : translate('profile.infoeducator.sincenodate')} 
                          <IconCountry className='ml-2 mr-1' size={12} color='#72809D'/>{data?.user?.timezone}
                        </span>
                        {
                          /**
                           * <span className='h45 text-primary btn-link mt-2' onClick={() => {
                                setPageProfile('full-profile')
                              }}>View complete profile</span>
                           * **/
                        }
                        
                      </div>
                    </div>
                  )
              }
              <div className='d-flex flex-row'>
                <IconOptions color='#D7E1EA' onClick={() => {}} className='mr-2' style={{ transform: 'rotate(90deg)' }} />
                <IconClose color='#D7E1EA' onClick={toggle} />
              </div>
            </div>
            <div className='m-0 p-0 w-100'>
              <Collapse isOpen={activeTab === 'profile'}>
                <SimpleProfile
                  style={profileMenuDimensions}
                  user={data && data.user}
                  openFull={openFull}
                  setPage={(newPage) => {
                    openFull()
                    setPageProfile(newPage)
                  }}
                  page={pageProfile}
                  type={type}
                  className='m-0 p-0'
                />
              </Collapse>
            </div>
          </div>
        }
        sideLeft={
          <div className='pl-6 pl-sm-7 m-0 pt-4 pr-0'>
            <div
              ref={(el) => {
                if (!el) return
                navMenuDimensions = el.getBoundingClientRect()
              }}
              className='znk-scroll-horizontal'
              style={{ overflowX: 'scroll' }}
            >
              <Nav className='w-100 flex-nowrap'>
                <NavItem className='nav-title mb-2 mr-2' active={activeTab === 'summary'}>
                  <NavLink onClick={() => { setActiveTab('summary') }}>
                    Summary
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'teach'}>
                  <NavLink onClick={() => { setActiveTab('teach') }}>
                    Teach
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'paylips'}>
                  <NavLink onClick={() => { setActiveTab('paylips') }}>
                    Payslips
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'notes'}>
                  <NavLink onClick={() => { setActiveTab('notes') }}>
                    Notes
                  </NavLink>
                </NavItem>
                <NavItem className='nav-title mr-2' active={activeTab === 'profile'}>
                  <NavLink onClick={() => { setActiveTab('profile') }}>
                    Profile
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={activeTab} className='px-2'>
              <TabPane tabId='summary'>
                <Summary educator={data && data.user ? data.user : null} loading={loadEducatorData} />
              </TabPane>
              <TabPane tabId='teach'>
                <Teach educator={data && data.user ? data.user : null} loading={loadEducatorData} />
              </TabPane>
              <TabPane tabId='paylips'>
                <Paylips educator={data && data.user ? data.user : null} loading={loadEducatorData} />
              </TabPane>
              <TabPane tabId='notes'>
                <Notes className='pr-6' page={activeTab} studentId={data?.user?.id}/>
              </TabPane>
              <TabPane tabId='profile' />
            </TabContent>
          </div>
        }
        sideRight={
          <TabContent activeTab={pageProfile} className='px-2'>
            <TabPane tabId='full-profile'>
              <FullProfile user={data && data.user} type={type} refetch={(id) => getxUser(id)} toggleRightSide={toggleFull} />
            </TabPane>
            <TabPane tabId='edit-profile'>
              <PersonalModalForm 
                countries={countries} 
                refetchUser={(id) => getxUser(id)} 
                toggleRightSide={toggleFull}
                actionAfterUpdate={() => {
                  // acción que se ejecuta luego de actualizar la información del usuario
                  // actualiza listado de usuarios
                  refetch()
                  // cierra lado derecho del modal
                  setPageProfile('full-profile')
                }} 
              />
            </TabPane>
            <TabPane tabId='av-recurring'>
              <AvailabilityRecurring page={pageProfile} toggleFull= {toggleFull} educator={data?.user}/>
            </TabPane>
            <TabPane tabId='av-temporal'>
              <AvailabilityTemporal page={pageProfile} toggleFull= {toggleFull} educator={data?.user}/>
            </TabPane>
            <TabPane tabId='weeks-block'>
              <WeeksBlock page={pageProfile} toggleFull= {toggleFull} userId={userId} timezone={data?.user?.timezone} />
            </TabPane>
            <TabPane tabId='student-form'>
              <SetStudentServicesRateModalForm toggleRightSide={toggleFull} />
            </TabPane>
            <TabPane tabId='academic-info'>
            <AcademicInfo toggleRightSide={toggleFull} userData={data?.user}/>
            </TabPane>
            <TabPane tabId='edit-family'>
              <EditFamilyInfo refetchUser={(id) => { getxUser(id) }} refetchList={refetch} />
            </TabPane>
            <TabPane tabId='notifications'>
              <ChangeNotificationsModal toggleFull={toggleFull}/>
            </TabPane>
          </TabContent>
        }
        overRight={
          isOpenEdit
                ? <PersonalModalForm countries={countries} refetchUser={(id) => getxUser(id)} /> :
          null
          /**isOpenAvRecurring
            ? <AvailabilityRecurring toggleFull= {toggleFull} />
            : isOpenAvTemporal
              ? <AvailabilityTemporal toggleFull= {toggleFull} />
              : isOpenEdit
                ? <PersonalModalForm countries={countries} refetchUser={(id) => getxUser(id)} />
                : isOpenAcadInfo
                  ? <StudentModalForm refetchUser={(id) => { getxUser(id) }} />
                  : servicesStudentRate ? (full ? <SetStudentServicesRateModalForm /> : null)
                    : familyInfo ? <EditFamilyInfo refetchUser={(id) => { getxUser(id) }} refetchList={refetch} /> : null**/
        }
      />
      {/**<ChangeTimezone/>**/}
    </>
  )
}

export default InfoEducator