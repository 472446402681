import { gql, useLazyQuery } from "@apollo/client"
import { examScoreResult } from "./useLazyPlan"

const useLazyTask = (variables = {}) => {
    return useLazyQuery(
        gql`
            query (
                $taskId: ID!
            ) {
                getTask (
                    taskId: $taskId
                ) {
                    planId
                    taskId
                    phaseId
                    stepId
                    newStatus
                    dataPlan {
                        id
                    }
                    task {
                        examId
                        selectedExam {
                            ${examScoreResult}
                        }
                        resolveTask {
                            score {
                                ${examScoreResult}
                            }
                        }
                        selectedExamId
                    }
                }
            }
        `,
        {variables}
    )
}

export default useLazyTask