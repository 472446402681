import { useMutation, gql } from "@apollo/client"

const useGenerateGoogleCalendarDocument = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
        mutation (
            $userId: String! 
            $role: String!
            ) {
            generateGoogleCalendarDocument (
                userId: $userId
                role: $role    
            )
        }`, {onCompleted, onError}
    )
}

export default useGenerateGoogleCalendarDocument