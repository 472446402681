import { useSelector, useDispatch } from "react-redux"

const useTransportCostumer = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.payments.transportCostumer)
    const {isOpen, onChange} = reduxData
    const setOpen = (open, onChange = () => {}, userId = null ) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'payments.transportCostumer',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                userId
            }
        })
    }
    return {
        ...reduxData,
        isOpen,
        onChange,
        open: (onChange = () => {}, userId = null) => {
            setOpen(true, onChange, userId)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        }
    }
}

export default useTransportCostumer
