import React from 'react'
import {
  IconWebsite
} from '@Knowledge-OTP/znk-ui-components'

const ViewUniversityWebsite = (props) => {

  const { college, styles } = props

  return (
    <>
      <div className={`mt-2 h-100 d-flex flex-row ${styles}`}>
        <a
          style={{textDecoration: 'none'}}
          className='w-100'
          href={`http://${college?.website}`}
          // eslint-disable-next-line
          target="_blank">
          <span className='mr-5 h45 text-secondary d-flex flex-row align-items-center'>
            <IconWebsite className='text-secondary mr-2' size={14} />{' '}{college?.website && college?.website?.toLowerCase()}
          </span>
        </a>
      </div>
    </>
  )
}
export default ViewUniversityWebsite