import React from 'react'
import moment from 'moment'
import { IconArrowRight, IconLesson, ListItem } from '@Knowledge-OTP/znk-ui-components'

const PartScheduleView = (props) => {
  const { groupPart, idx, currentService } = props
  const { from: startdate, to: enddate, lessonsPerWeekGroup, lessonsPerWeekIndividual } = groupPart
  return (
    <div className='d-flex flex-column'>
      <span className='h5 font-weight-light text-gray my-2 text-uppercase'>
        {`Part ${idx + 1}: from ${moment(startdate.split('T')[0]).format('MMMM Do')} to ${moment(enddate.split('T')[0]).format('MMMM Do')}`}
        {
          currentService.topics.map(
            (topic, i) => {
              const groupTopic = lessonsPerWeekGroup.filter(e => e.topicId === topic.id)
              return groupTopic.length > 0
                ? <ListItem
                  className={`py-2 ${i === 0 ? 'mt-3' : ''}`}
                  Left={
                    <span>
                      <IconLesson size={16} className='text-logo' />
                      <span className='ml-2'>
                        {`${groupTopic.length} group ${topic.name} lessons`} <span className='font-weight-light text-gray'>({groupTopic.reduce((sum, group) => sum + group.totalLessons, 0)})</span>
                      </span>
                    </span>
                  }
                  Right={<IconArrowRight size={20} />}
                />
                : null
            }
          )
        }
        {
          currentService.topics.map(
            (topic, i) => {
              const individualTopic = lessonsPerWeekIndividual.filter(e => e.topicId === topic.id)
              return individualTopic.length > 0
                ? <ListItem
                  className={`py-2 ${i === 0 ? 'mt-3' : ''}`}
                  Left={
                    <span>
                      <IconLesson size={16} className='text-logo' />
                      <span className='ml-2'>
                        {`${individualTopic[0].lessonsPerWeek} individual ${topic.name} lessons`} <span className='font-weight-light text-gray'>({individualTopic[0].totalLessons})</span>
                      </span>
                    </span>
                  }
                  Right={<IconArrowRight size={20} />}
                />
                : null
            }
          )
        }
      </span>
    </div>
  )
}

export default PartScheduleView
