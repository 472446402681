import React from 'react'
import {
  PlanCard,
  ConfirmModal
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import SetToPauseOrCancelPlan from '../../../../Model/Plan/SetToPauseOrCancelPlan'
import { showAlert } from '../../../../common/Alert/util'

const ConfirmCancelPlan = (props) => {
  const { isOpen, refetch, dataPlan } = useSelector((state) => state.modal.plan.confirmCancelPlan)
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.confirmCancelPlan',
      data: { isOpen: !isOpen, refetch: refetch, dataPlan: dataPlan }
    })
  }

  const { submit, loading } = SetToPauseOrCancelPlan((result) => {
    // onsuccess
    refetch()
    toggle()
    showAlert({text: translate('plans.modal.cancelplan.onSuccess'), status: 'success'}, dispatch)
    // solo se puede pausar o cancelar el plan si esta en estado sent o scheduled
  },
  (err) => {
    // eslint-disable-next-line
    showAlert({text: translate('plans.modal.cancelplan.onError'), status: 'error'}, dispatch)
    console.error(err)
  })
  return (
    <ConfirmModal
      className='m-4'
      toggle={toggle}
      isOpen={isOpen}
      title={
        dataPlan.newStatus === 'cancel'
          ? translate('plans.modal.cancelplan')
          : translate('plans.modal.pauseplan')
      }
      body={translate('plans.modal.cancelplan.body')}
      confirmAction={() => {
        submit({
          statusPlan: dataPlan.newStatus,
          planId: dataPlan.planId
        })
      }}
      cancelAction={toggle}
      loading={loading}
      confirmText={translate('plans.modal.cancelplan.confirm')}
      cancelText={translate('plans.modal.cancelplan.cancel')}
    />
  )
}
ConfirmCancelPlan.propTypes = PlanCard.propTypes
ConfirmCancelPlan.defaultProps = PlanCard.defaultProps
export default ConfirmCancelPlan
