import React from 'react'
import { DropDownNew, DropDownItem, IconOptions, IconLoad } from '@Knowledge-OTP/znk-ui-components'


const OptionsEmailsAndDownloads = (props) => {
    const { options, loading, className = 'ml-3' } = props
    return (
        <div>
            {options?.length > 0 && (
                <DropDownNew
                    className={`${className}`}
                    options={options.map((e, index) => {
                        return {
                                component: (
                                    <DropDownItem key={index}
                                        onClick={e.onClick}
                                    >
                                        {e.title}
                                    </DropDownItem>
                                ),
                            }
                    })}
                    direction='bottomLeft'
                >
                    <div className='hover-icon znk-icon-action-modal'>
                        {loading ? <IconLoad /> : <IconOptions
                            size={20}
                            className='text-disabled'
                            style={{ transform: 'rotate(90deg)' }}
                        />}
                    </div>

                </DropDownNew>
            )}
        </div>
    )
}

export default OptionsEmailsAndDownloads

