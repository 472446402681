import React from 'react'
import {
    DropDown,
    IconArrowRight, IconCreditCard,
    IconDuplicate, IconEdit,
    IconProgram, IconRepeat, IconSignCheck, IconWspAlert,
    ListItem
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch} from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment'

const List = (props) => {
    const {translate} = useTranslation()
    const dispatch = useDispatch()
    const {lesson: dataLesson} = props
    const makeup = dataLesson?.isMakeUp || false
    const isGroup = dataLesson.__typename === 'GroupLesson'
    const lessonPast = dataLesson?.endDate ? moment() >= moment(dataLesson?.endDate) : false
    return (
        <>
            <ListItem
                className='p-2'
                onClick={() => {

                }}
                Left={
                    <div className='d-flex flex-row'>
                        <IconSignCheck color='#696CFF' className='align-self-center mr-2'/>
                        <span className='align-self-center text-primary'>
                End Lesson
              </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
            <ListItem
                showTopLine
                className='p-2'
                onClick={() => {
                    dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'lesson.wspAlert',
                        data: {
                            isOpen: true,
                            dataLesson: dataLesson,
                            studentsId: [],
                            educatorId: null
                        }
                    })
                }}
                Left={
                    <div className='d-flex flex-row'>
                        <IconWspAlert color='#696CFF' className='align-self-center mr-2'/>
                        <span className='align-self-center text-primary'>
              {translate('modal.infolesson.sendwspalert')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
            {
                !lessonPast &&
                (
                    <ListItem
                        showTopLine
                        className='p-2'
                        Left={
                            <div className='d-flex flex-row'>
                                <IconProgram color='#696CFF' className='align-self-center mr-2'/>
                                <span className='align-self-center text-primary'>
                      {translate('modal.infolesson.scheduledoptions')}
                    </span>
                            </div>
                        }
                        Right={
                            <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                                <DropDown
                                    direction='down'
                                    fixedWidth
                                    hoverEnabled={false}
                                    options={[
                                        {
                                            component: <span onClick={() => {
                                                dispatch({
                                                    type: 'SET_DATA_MODAL_FORM_LIST',
                                                    payload: 'lesson.rescheduledLesson',
                                                    data: {
                                                        isOpen: true,
                                                        dataLesson: dataLesson
                                                    }
                                                })
                                            }}
                                            >{translate('modal.infolesson.rescheduling')}</span>
                                        },
                                    ].concat((!isGroup && false) ? [{
                                        component:
                                            <span
                                                onClick={() => {
                                                    dispatch({
                                                        type: 'SET_DATA_MODAL_FORM_LIST',
                                                        payload: 'plan.setPendingLesson',
                                                        data: {
                                                            isOpen: true,
                                                            lessonId: dataLesson?.id || ''
                                                        }
                                                    })
                                                }}>{translate('modal.infolesson.leavepending')}</span>
                                    }] : [])}
                                >
                                    <IconArrowRight/>
                                </DropDown>
                            </div>
                        }
                    />
                )
            }
            <ListItem
                onClick={() => {
                    dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: 'plan.duplicateLessonModal',
                        data: {
                            isOpen: true,
                            dataLesson: dataLesson
                        }
                    })
                }}
                showTopLine
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconDuplicate color='#696CFF' className='align-self-center mr-2'/>
                        <span className='align-self-center text-primary'>
              {translate('modal.infolesson.duplicatelesson')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
            <ListItem
                onClick={() => {
                    if (!makeup) {
                        dispatch({
                            type: 'SET_DATA_MODAL_FORM_LIST',
                            payload: 'plan.makeUpLesson',
                            data: {
                                isOpen: true,
                                dataLesson: dataLesson
                            }
                        })
                    }
                }}
                showTopLine
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconRepeat color={makeup ? '#D6DAE3' : '#696CFF'} className='align-self-center mr-2'/>
                        <span className={`align-self-center ${makeup ? 'text-disabled' : 'text-primary'}`}>
              {translate('modal.infolesson.makeuplesson')}
            </span>
                    </div>
                }
                Right={
                    makeup
                        ? <IconArrowRight color='#D6DAE3'/>
                        : <IconArrowRight/>
                }
            />
            <ListItem
                showTopLine
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconEdit color='#696CFF' className='align-self-center mr-2'/>
                        <span className='align-self-center text-primary'>
              {translate('modal.infolesson.editlesson')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
            <ListItem
                showTopLine
                onClick={
                    () => {
                        dispatch({
                            type: 'SET_DATA_MODAL_FORM_LIST',
                            payload: 'plan.editPaymentLesson',
                            data: {
                                isOpen: true,
                                dataLesson
                            }
                        })
                    }
                }
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconEdit color='#696CFF' className='align-self-center mr-2'/>
                        <span className='align-self-center text-primary'>
              {translate('modal.infolesson.editpayment')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
            <ListItem
                showTopLine
                onClick={
                    () => {
                        dispatch({
                            type: 'SET_DATA_MODAL_FORM_LIST',
                            payload: 'plan.penalizeLesson',
                            data: {
                                isOpen: true,
                                dataLesson: dataLesson
                            }
                        })
                    }
                }
                className='p-2'
                Left={
                    <div className='d-flex flex-row'>
                        <IconCreditCard color='#696CFF' className='align-self-center mr-2'/>
                        <span className='align-self-center text-primary'>
              {translate('modal.infolesson.penalizelesson')}
            </span>
                    </div>
                }
                Right={<IconArrowRight/>}
            />
        </>
    )
}

export default List
