import React, { useEffect, useMemo } from "react"
import {
    Avatar,
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import useAllUser from './Model/UseAllUsers'
import userUpcomingLesson from "./Model/UserUpcomingLesson"
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { escapeRegExp } from 'lodash'
import { showAlert } from "../../../../common/Alert/util"

const SelectUser = (props) => {
    const { selectUser, setSelectUser, refetchIn, setRefetchIn, isMulti = false } = props
    const dispatch = useDispatch()
    const { translate } = useTranslation()

    const selectedUser = useSelector((state) => state.modal.calendar.selectUser)

    const [getAllUsers, { data: dataUsers, loading, refetch }] = useAllUser()
    const [getUpcomingLesson, { data: dataUpcomingLesson, loading: loadUpcoming }] = userUpcomingLesson()

    useEffect(() => {
        getAllUsers({
            variables: {
                roles: [
                    'STUDENT',
                    'EDUCATOR'
                ],
                limit: 0
            }
        })
    }, [getAllUsers])
    useEffect(() => {
        if (refetchIn) {
            refetch()
            setRefetchIn(false)
        }
        // eslint-disable-next-line
    }, [refetchIn])
    useEffect(() => {
        if (selectUser && dataUsers?.allUsers?.docs) {
            let selectStudent = dataUsers.allUsers.docs.filter((stu) => selectUser.includes(stu.id))
            //setea el usuario seleccionado
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'calendar.selectUser',
                data: {
                    ...selectedUser,
                    selectedUserData: {},
                    selectedArrayUserData: selectStudent,
                }
            })
            //obtiene la lección mas próxima
            getUpcomingLesson({ variables: { userId: selectUser } })
        } else if (!selectUser) {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'calendar.selectUser',
                data: {
                    selectedUserData: null,
                    upcomming: null
                }
            })
        }
        // eslint-disable-next-line
    }, [selectUser, dataUsers, dispatch])
    useEffect(() => {
        if (dataUpcomingLesson) {
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'calendar.selectUser',
                data: {
                    ...selectedUser,
                    upcomming: dataUpcomingLesson?.userUpcomingLesson?.id
                }
            })
        }
        // eslint-disable-next-line
    }, [dataUpcomingLesson, dispatch])

    const ordenateUserArray = (array) => {
        return array?.map((stu) => {
            return {
                name: `${stu.firstName} ${stu.lastName}`,
                id: stu.id,
                avatar: stu?.avatar || null
            }
        }).sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
        })
    }
    const activeUsers = useMemo(
        () => {
            return dataUsers?.allUsers?.docs?.filter((stu) => {
                if (stu?.roles?.includes("EDUCATOR") && stu?.roles?.length === 1) {
                    return stu.isActive && stu?.educatorInfo?.isActive
                }
                return stu.isActive
            }) || []
        }, [dataUsers]
    )
    const openProfile = (user) => {
        if (user?.roles?.includes("EDUCATOR")) {
            //ABRE MODAL DE EDUCADOR
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'educator.infoEducatorModal',
                data: {
                    isOpen: true,
                    full: false,
                    userId: user?.id || '',
                    type: 'educator',
                    initPage: 'summary',
                    profilePage: 'full-profile',
                }
            })
        }
        else {
            //ABRE MODAL DE ESTUDIANTE
            dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'student.infoStudentModal',
                data: {
                    isOpen: true,
                    dataStudent: user,
                    initPage: 'summary',
                    full: false,
                    profilePage: 'viewProfile'
                }
            })
        }
    }

    return (
        <>
            <div className='m-0 p-0 d-flex flex-row justify-content-between'>
                <span className='h45 text-gray'>{translate('lessons.calendar.user')}</span>
                {
                    (selectUser?.length === 1 && activeUsers.filter((stu) => (selectUser||[]).includes(stu.id)).length) &&
                    (
                        <span
                            className='h45 btn-link'
                            onClick={() => {
                                const userSelect = activeUsers.find((stu) => selectUser.includes(stu.id))
                                if(userSelect) openProfile(userSelect)
                                else showAlert({text: 'Error! Please try again.', status: 'error'}, dispatch)
                            }}
                        >{translate('lessons.calendar.seeProfile')}
                        </span>
                    )
                }
            </div>
            <InputSelect
                isMulti={isMulti}
                name={`selectUserComponent`}
                isLoading={loading || loadUpcoming}
                InputClassName='my-3'
                menuPlacement={'top'}
                value={selectUser}
                filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.data.name)) return true
                    return false
                }}
                options={
                    ordenateUserArray(activeUsers)?.map((stu) => {
                        return {
                            value: stu.id,
                            name: stu.name,
                            label:
                                <div className="w-100 d-flex flex-row align-items-center justify-content-start">
                                    <div className='d-flex flex-row'>
                                        <Avatar
                                            name={stu.name}
                                            named={false}
                                            uri={stu?.avatar || null}
                                            size='avatar-small mr-1'
                                        />
                                        <span className='align-self-center'>{stu.name}</span>
                                    </div>
                                </div>
                        }
                    })
                } onChange={(res) => {
                    setSelectUser(res)
                }}
            />
        </>
    )
}

export default SelectUser
