import validate from 'validate.js'

const constraints = {
    userId: {
        presence: true
    },
    educatorNote: {
        presence: true,
    },
}

export default (data) => {
    return validate(data, constraints)
}