import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'

export default function useUpdateNote(onSuccess, onError){
    const [mutation, { data, error, loading }] = useMutation(
        gql`mutation updateNote($userId: String!, $noteId: String!,  $content:String!) {
            updateNote(userId: $userId, noteId: $noteId, content: $content){
                id
            }
        }`
    )

    const [validationErrors, setValidationErrors] = useState({})

    const submit = useCallback((obj) => {
        const errors = validate(obj)
        if (errors && Object.keys(errors).length) {
            setValidationErrors(errors)
            onError('validation Error')
            return
        }

        mutation({ variables: obj }).then((result) => {
            onSuccess(result)
        }).catch((e) => {
            setValidationErrors({ general: 'misc.apiError' })
            onError(e)
        })
    }, [mutation, onSuccess, onError])

    return { loading, data , error, validationErrors, submit }
}
