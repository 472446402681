import React from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import { ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import PaymentHistory from './history'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../i18n/useTranslation'

const UserInvoicesModalForm = (props) => {
  const { isOpen, ...formData } = useSelector((state) => state.modal.user.invoices)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.invoices', data: { isOpen: !isOpen, ...formData } })
  }
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const { userId } = formData
  return (
    <Modal
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <ModalHeader
          toggle={toggle}
          close={<IconClose size={20} onClick={toggle} />}
          className='mt-md-4'
        >
          <span className='h2 font-weight-bold mr-4'>
            {translate('Invoices')}
          </span>
        </ModalHeader>
      }
    >
      {userId ? <PaymentHistory userId={userId} /> : <Skeleton count={4} height={44} />}
    </Modal>
  )
}

UserInvoicesModalForm.propTypes = {}

export default UserInvoicesModalForm
