import {useQuery, gql} from '@apollo/client'

const useAllUniversitiesOnPlan = () => {
    return useQuery(
        gql`query {
            allUniversitiesOnPlan {
                id
                name
                collegeId
                collegeUnitId
                isActive
                createdAt
                updatedAt
                deleteAt
                isPrincipal
            }
        }
        `
    )
}

export default useAllUniversitiesOnPlan