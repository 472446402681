import React from 'react'
import {
  ConfirmModal
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import { useDispatch} from 'react-redux'
import { showAlert } from '../../../../../common/Alert/util'
import useDeleteGroupPlanModal from './useDeleteGroupPlanModal'
import useDeleteGroupPlan from '../../../Model/useDeleteGroupPlan'
const DeleteGroupPlanModal = (props) => {

  const { isOpen, toggle, dataPlan, onChange, close: closeModal } = useDeleteGroupPlanModal()

  const [deleteGroupPlan, {loading}] = useDeleteGroupPlan(
    (res) => {
        showAlert({
            text: 'Success',
            status: 'success'
        }, dispatch)
        if (onChange) onChange()
        closeModal()
    },
    (err) => {
        showAlert({
            text: err.toString(),
            status: 'error'
        }, dispatch)
    }
)
  const { translate } = useTranslation()
  const dispatch = useDispatch()

  return (
    <ConfirmModal
      className=''
      loading={loading}
      classNameActionDiv='flex-column'
      classNameConfirm='text-right text-error'
      classNameCancel='mx-3 text-right'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('modal.cancellesson.sure')}
      body={translate('modal.cancellesson.cannotbeundone')}
      confirmAction={() => {
        deleteGroupPlan({variables: {planId: dataPlan.id}})
      }}
      cancelAction={toggle}
      confirmText={'Delete'}
      cancelText={'Cancel'}
    />
  )
}

export default DeleteGroupPlanModal
