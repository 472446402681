import React, {useEffect} from 'react'
import {Avatar} from '@Knowledge-OTP/znk-ui-components'
import {gql, useLazyQuery} from '@apollo/client'
const LazyAvatar = (props) => {
    const {userId = null, ...originalProps} = props
    const [getAvatar, {data}] = useLazyQuery(
        gql`query($id: String!) {
            user(id: $id) {
              id
              avatar
            }
          }`
    )

    useEffect(
        () => {
            if(userId) {
                getAvatar({
                    variables: {id: userId}
                })
            }
            // eslint-disable-next-line
        }, [userId]
    )
    // const avatar = useMemo(
    //     () => {
    //         return data?.user?.avatar || null
    //     }, [data]
    // ) 
    
    return (
        <>
            {
                data?.user?.avatar ? 
                <Avatar
                    {...originalProps}
                    uri={data.user.avatar || originalProps?.uri}
                /> :
                <Avatar {...originalProps}/>    
            }
        </>
    )
}

export default LazyAvatar