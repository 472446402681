import React from 'react'
import { ListItem, IconRight, IconEarlyAction } from '@Knowledge-OTP/znk-ui-components'

const EarlyActionItem = (props) => {
    const { college, checkEarlyAction } = props

    return (
        <ListItem
            className='borderTop w-100 m-0 px-2'
            hasHover
            Left={
                <div>
                    <IconEarlyAction
                        size={20}
                        color='#696bff'
                        backgroundColor='#c0cc18'
                    />
                    <span
                        className='ml-5 h4'
                        style={{
                            color: '#696bff',
                        }}
                    >
                        {college?.early === 'action' ? 'Remove ' : 'Mark as '}
                        Early Action
                    </span>
                </div>
            }
            Right={<IconRight size={16} />}
            onClick={() => {
                checkEarlyAction()
              }
              }
        />
    )
}
export default EarlyActionItem