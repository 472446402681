import {useMutation, gql} from '@apollo/client'

const useEditTimeline = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $timelineInput: TimelineInput!
                $timelineId: ID!
                ) {
                editTimeline (
                    timelineInput: $timelineInput
                    timelineId: $timelineId
                ) {
                    id
                }
            }
        `,
        {
            onCompleted: onSuccess,
            onError: onError
        }
    )
}

export default useEditTimeline