const testQueryResult = `
    name
    tbd
    id
    kind
    startDate
    endDate
    busy
    userIdsInvolved
    createdAt
    updatedAt
    googleCalendarEventId
    serviceId
    registrationDate
    scoreReleaseDate
`

export default testQueryResult