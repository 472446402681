import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'

export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation (
            $invoiceId: String!
    ) {
        processInvoice(
            invoiceId: $invoiceId
        ){
        id
      }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.createGroupPlan : null, error, validationErrors, submit }
}
