import React, {useEffect, useMemo, useState} from 'react'
import {
    ModalLayout,
} from '@Knowledge-OTP/znk-ui-components'

import useViewPlanModal from './useViewPlanModal'
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'


//reducers
import useCounselingPlanReducer from '../../../../reducers/counseling-plan'

import useBubblesModalHeader from '../../../../bubbles/Components/useBubblesModalHeader'

import useOptionsByStatus from './Components/useOptionsByStatus'
//handler for tabs and sections by status of plan ["scheduled", "draft", "sent"]
import useTabsByStatus from './Components/useTabsByStatus'

import useChangeListener from './Tabs/useChangeListener'
import useTemplateReducer from '../../../../reducers/template'

import moment from 'moment'

// import useOperationTaskMutation from '../../../CounselingTemplates/Task/Model/useOperationTask'
import useDeleteTaskModal from '../../../CounselingTemplates/Task/deleteTaskModal/useDeleteTaskModal'
import useViewTaskModal from '../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import useNewTaskModal from '../../../CounselingTemplates/Task/newTaskModal/useNewTaskModal'
import useAssingTaskModal from '../../../CounselingTemplates/Task/assingTaskModal/useAssignTaskModal'
import useUser from '../../Model/useUser'
import useCounselingPlanList from '../../useCounselingPlanList'
import useSetApplicationStatusModal from '../setApplicationStatusModal/useSetApplicationStatusModal'
import useSetCompletedPlanStatusModal from '../setCompletedStatusModal/useSetCompletedStatusModal'
import useSetStatusModalConfirm from '../setStatusModalConfirm/useSetStatusModalConfirm'
import { typeDictionary } from '../../Utils/typePlanDictionary'
const ViewPlanModal = () => {
    const {
        isOpen, 
        toggle, 
        dataPlan, 
    } = useViewPlanModal()
    const {refetch} = useCounselingPlanList()
    const planReducer = useCounselingPlanReducer()
    const {state: plan, updateData: setData, setPosition} = planReducer
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowRight') {
                handleScroll(100); 
            } else if (e.key === 'ArrowLeft') {
                handleScroll(-100);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleScroll = (scrollOffset) => {
        const container = document.getElementById('navOptions');
        if (container) {
            container.scrollLeft += scrollOffset;
        }
    };
    useEffect(() => {
        //actualiza la información del plan entrante en el reducers
        if(dataPlan) setData(setPosition(dataPlan))
        
        // eslint-disable-next-line
    }, [dataPlan])

    const {statusPlan, modalStatus, classText} = useMemo(() => {
        return {
            classText: '',// plan?.status ===  'cancel' ? 'text-light' : 'text-white',
            statusPlan: plan?.status || 'draft', //status del plan en API
            modalStatus: plan?.status ===  'cancel' ? 
                'canceled' : 
                ['draft', 'sent'].includes(plan?.status) ? 
                    'scheduled' :
                    'scheduled-counseling' //para color del modal (statuses en modal colored)
        }
    }, [plan])

    const bubblesStyle = useBubblesModalHeader(plan?.status || 'scheduled')
    
    const onChangeOptions = (action, newPlan) => {
        switch(action){
            case 'cancel':
                setData({...newPlan, status: 'cancel'})
                break
            case 'send':
                setData({
                    ...newPlan, 
                    status: 'sent', 
                    sentDate: newPlan?.sentDate || moment().toISOString() 
                })
                break
            case 'edit':
                setData({
                    ...newPlan, 
                })
                break    
            default:
                break    
        }
        onChangePlan()
    }


    const {open: openSetApplicationStatus, close: closeSetApplicationStatus} = useSetApplicationStatusModal()
    const {open: openSetCompletedPlanStatusModal, close: closeSetCompletedPlanStatusModal} = useSetCompletedPlanStatusModal()
    const {open: openSetStatusModalConfirm, close: closeSetStatusModalConfirm} = useSetStatusModalConfirm()
    const onChangeStatus = phase => {
        if (plan?.id) {
            switch (phase) {
                case "application":
                    openSetApplicationStatus(() => {
                        refetch()
                        onAddUniversity()
                        closeSetApplicationStatus()
                    }, plan, true)  
                    return;
                case "completed":
                    openSetCompletedPlanStatusModal(() => {
                        refetch()
                        onAddUniversity()
                        closeSetCompletedPlanStatusModal()
                    }, plan, true)
                    return;
                default:
                    openSetStatusModalConfirm(() => {
                        refetch()
                        onAddUniversity()
                        closeSetStatusModalConfirm()
                    }, plan, phase, true /**useMutation**/)
                    return;
              }
        }
    }
    
    const {options: optionsDefault, parseToDropdown} = useOptionsByStatus(plan?.status, plan, onChangeOptions, onChangeStatus)
        
    //listado de opciones sin "cancelar plan"
    // const options = optionsDefault.slice(0, optionsDefault.length - 1)

    //retorna el listado de tabs según el status del plan
    const {sections: sectionsDefault, defaultTab} = useTabsByStatus(statusPlan, plan)
    const [activeTab, setActiveTab] = useState(defaultTab)
    useEffect(() => {setActiveTab(defaultTab)}, [defaultTab])
    

    const {
        //se ejecuta para actualiza task en viewPlanModal
        editTask: editTaskOnTimeline,
        addTask: addTaskOnTimeline,
        addStep: addStepOnTimeline,
        removeStep: removeStepOnTimeline,
        editStep: editStepOnTimeline,
        // se ejecuta el callback de viewPlanModal notificando que
        //el plan ha cambiado
        onChangePlan
    } = useChangeListener(plan)



    const {close: closeDeleteModal} = useDeleteTaskModal()
    const {close: closeViewTaskModal} = useViewTaskModal()
    const {close: closeNewTaskModal} = useNewTaskModal()
    const {close: closeAssingTaskModal} = useAssingTaskModal()


    const onChangeTask = (action, newTask) => {
        //Setea info en plan en timeline (template reducer)
        // luego timeline sobreescribe el plan en viewPlanModal
        switch(action) {
            case 'edit':
                editTaskOnTimeline(newTask)
                closeNewTaskModal()
                break
            case 'duplicate' :
                break
            case 'assign' : //ejecuta mutación en modal assignTaskModal
                //luego de asignar la tarea
                //setea info de task en taskViewModal
                editTaskOnTimeline(newTask)
                closeAssingTaskModal()
                break 
            case 'delete' : //ejecuta mutación en modal deleteTaskModal
                editTaskOnTimeline(newTask)
                closeDeleteModal()
                closeViewTaskModal()
                break        
            default: 
                break        
        }

        //ejecuta onchange entregado desde el listado de planes
        // en useViewPlanModal().open()
        onChangePlan(/**newPlan**/)
    }

    const onCreateTask = (newTask) => {
        addTaskOnTimeline({...newTask, isFromApi: true})
        onChangePlan(/**newPlan**/)
    }

    const {filterDelete} = useTemplateReducer()
    
    //universidades en perfil del usuario (estudiante dueño del plan)
    const {data, refetch: refetchUserQuery} = useUser({id: dataPlan?.studentUser?.studentUserId || ''})
    
    const universities = data?.user?.studentInfo?.universities || []
    //Ejecuta cuando se agrega una universidad al listado del estudiante
    const onAddUniversity = () => {
        refetchUserQuery()
        onChangePlan(/**newPlan**/)
    }

    const onRemoveUniversity = () => {
        refetchUserQuery()
        onChangePlan(/**newPlan**/)
    }
    return (
        <ModalLayout
            style={bubblesStyle}
            className={`modal-colored modal-sidebar modal-desktop-half ${modalStatus}-modal`}
            isOpen={isOpen}
            toggle={() => {
                if(statusPlan === 'scheduled') setActiveTab('summary')
                toggle()
              }}
            // toggle={toggle}
            options={parseToDropdown(optionsDefault, true)}
            loadingOptions={false}
            topHead={
                <div className={`m-0 p-0 mt-4 mb-3 d-flex flex-column`}>
                    <div className={`d-flex flex-row align-items-center mb-7`}>
                        <div className={`shape star-shape medium mr-1`}/>
                        <span className={`h4 ${classText} m-0`}>Counseling</span>
                    </div>
                    <span 
                        className={`h2 ${classText} font-weight-bold`} 
                        style={
                            (plan?.status === 'cancel')
                                ? { textDecoration: 'line-through' }
                                : {}
                        }
                    >
                        {'Counseling Plan'}
                    </span>
                    <span className={`h4 my-2 ${classText} font-weight-bold`}>
                        {`${moment(plan?.from).utc().format(`MMM Do, YYYY`)} to ${moment(plan?.to).utc().format(`MMM Do, YYYY`)}`}
                    </span>
                    <span className={`h45 ${classText}`}>
                        {/* {`${plan?.type ? plan.type.substring(0,1).toUpperCase()+plan.type.substring(1,plan.type.length) : 'Type'} | ${plan?.aditionalInfo?.studentCountryName || 'Country'}`} */}
                        {`${typeDictionary[plan?.type] || 'Type'} | ${plan?.aditionalInfo?.studentCountryName || 'Country'}`}
                    </span>
                    <span className={`h45 ${classText}`}>
                        {plan?.id}
                    </span>
                </div>
            }
            minHead={
                <span 
                    className={`h3 ${classText}`}
                    style={
                        (plan?.status === 'cancel')
                            ? { textDecoration: 'line-through' }
                            : {}
                    } 
                >
                    {'Counseling Plan'}
                </span>
            }
        >
            <div className="m-0 p-0 d-flex flex-column">
                <Nav className='flex-nowrap' style={{ overflowX: 'auto', overflowY: 'hidden', scrollbarWidth: 'none' } }  id='navOptions'>
                    {
                        sectionsDefault.map(({label, key}, index) => {
                            return (
                                <NavItem key={index} className='nav-title mb-2 mr-2' active={activeTab === key}>
                                    <NavLink onClick={() => {setActiveTab(key)}}>
                                        {label}
                                    </NavLink>
                                </NavItem>
                            )
                        })
                    }
                </Nav>
                <div className={`borderTop w-100`} /> 
                <TabContent activeTab={activeTab}>
                    {
                        sectionsDefault.map(({Inner, key}, index) => {
                            return (
                                <TabPane tabId={key} key={index}>
                                    <Inner 
                                        key={index}
                                        plan={{
                                            ...plan,
                                            tasks: filterDelete({phases: plan?.tasks || []}).phases,
                                            originalTask: plan?.tasks
                                        }} 
                                        setData={setData} 
                                        onChangeTask={onChangeTask} 
                                        onCreateTask={onCreateTask} 
                                        addStepOnTimeline={addStepOnTimeline}
                                        editStepOnTimeline={editStepOnTimeline}
                                        removeStepOnTimeline={removeStepOnTimeline}
                                        isEdit={plan?.status !== 'cancel'}
                                        statusPlan={statusPlan} 
                                        //options={optionsDefault}
                                        options={[]}
                                        universities={universities}
                                        onAddUniversity={onAddUniversity}
                                        onRemoveUniversity={onRemoveUniversity}
                                    />
                                </TabPane>
                            )
                        })  
                    }
                </TabContent>
            </div>
        </ModalLayout>
    )
}

export default ViewPlanModal