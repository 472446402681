import React from 'react'
import { IconArrowDown, CheckboxSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import useTranslation from '../../../../../../i18n/useTranslation'

const OwnerFilters = ({owners, onSelectedNoteFilter, ownersSelected}) => {
  const { translate } = useTranslation()

  return (
    <CheckboxSelector
      setDataFilters={onSelectedNoteFilter}
      direction='bottom'
      dataFilters={ownersSelected}
      data={owners.sort((a, b) => a.name.localeCompare(b.name))}
      typeFilter='owners'
      strings={{
        viewAll: translate('filters.notes.viewAll'),
        buttonCancel: translate('filters.notes.buttonCancel'),
        buttonApply: translate('filters.notes.buttonApply'),
        search: translate('filters.notes.search')
      }}
      dropdownProps={{classNameOverlay: 'min-width-none'}}
    >
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.notes.title')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default OwnerFilters
