import {gql, useMutation} from '@apollo/client'

const UseUpdateGlobalVar = (onSuccess = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation($globalVarId: ID!, $serviceUpdate: UpdateGlobalVarsInput!) {
        updateGlobalVar(globalVarId: $globalVarId, serviceUpdate: $serviceUpdate) {
          id
          type
          name
        }
      }
    `,
    {
      onCompleted: onSuccess,
      onError,
    },
  )
}

export default UseUpdateGlobalVar