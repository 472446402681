import React from 'react'
import { ListItem } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const ChildPlanPricing = (props) => {
  const { info, paymentsNames, totalIndividualLesson, totalGroupLesson } = props
  const { translate } = useTranslation()
  const otherFixedPayment = info.paymentItemsConfig.filter((pay) => pay.type === 'others-fixed')
  return (
    <div className='py-4'>
      <label className='h3 font-weight-bold mb-4 mt-2'>{translate('modal.titles.pricing')}</label>
      <div className='d-flex flex-column my-3'>
        {info.typePlanPricing === 'permonth'
          ? <>
            <label className='h4 font-weight-bold mt-2 mb-1'>{translate('modal.infoplan.perfeerate')}</label>
            <label className='h4 font-weight-normal my-1'>{`${translate('modal.infoplan.feerate')}: $${(info.pricingRate).toFixed(2)}`}</label>
          </>
          : <>
            <label className='h4 font-weight-bold mt-2 mb-1'>{translate('modal.infoplan.perlessonrate')}</label>
            <label className='h4 font-weight-normal my-1'>{`${translate('modal.infoplan.individuallessonrate')}: $${(info.pricingRate).toFixed(2)}`}</label>
          </>}
        {
          (info?.groupPricingRate && info.groupPricingRate >= 0 && info.typePlanPricing !== 'permonth') ? (
            <label className='h4 font-weight-normal my-1'>{`${translate('modal.infoplan.groupallessonrate')}: $${(info.groupPricingRate).toFixed(2)}`}</label>
          ) : null
        }
      </div>
      <div className='m-0 p-0 pb-3 w-100'>
        {
          otherFixedPayment.map((pay) => {
            return (
              <ListItem
                className='m-0 py-1 w-100'
                Left={
                  <span>{paymentsNames[pay.description] || pay.description}</span>
                }
                Right={
                  <span className='text-gray'>{`$${pay.amount.toFixed(2)}${
                    pay.percentDiscount > 0 ? `(-${pay.percentDiscount}%)` : ''}`}
                  </span>
                }
              />
            )
          })
        }
        {
          totalIndividualLesson > 0 && (
            <ListItem
              className='m-0 py-1 w-100'
              Left={
                <span>{`${totalIndividualLesson} ${totalIndividualLesson > 1 ? translate('modal.infoplan.individuallessons').toLowerCase() : translate('modal.infoplan.lesson').toLowerCase()}`}</span>
              }
              Right={
                <span className='text-gray'>{info.typePlanPricing !== 'permonth' ? `$${(info.pricingRate * totalIndividualLesson).toFixed(2)}` : ''}</span>
              }
            />
          )
        }
        {
          (totalGroupLesson > 0) && (
            <ListItem
              className='m-0 py-1 w-100'
              Left={
                <span>{`${totalGroupLesson} ${totalGroupLesson > 1 ? translate('modal.infoplan.grouplessons').toLowerCase() : translate('modal.infoplan.lesson').toLowerCase()}`}</span>
              }
              Right={
                <span className='text-gray'>{info.typePlanPricing !== 'permonth' ? `$${(totalGroupLesson * info.groupPricingRate).toFixed(2)}` : '-'}</span>
              }
            />
          )
        }
        <ListItem
          className='m-0 py-1 w-100'
          Left={
            <span className='font-weight-bold'>{translate('profile.payments.total')}</span>
          }
          Right={
            <span className='font-weight-bold'>
              ${
                info.paymentItemsConfig.reduce((total, pay) => {
                  return total + pay.amount
                }, 0).toFixed(2)
              }
            </span>
          }
        />
      </div>
    </div>
  )
}

export default ChildPlanPricing
