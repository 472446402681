import React, { useEffect } from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from "react-router-dom";

const ListenerPlatform = () => {
    const platforms = useSelector((state) => state.platform.platform)
    let location = useLocation();
    useEffect(() => {
        if(location?.pathname === '/plans' && platforms?.length === 1 && platforms.includes('counseling')) {
            alert("redireccionar")
        }
    }, [platforms, location])
    return (<></>)
}

export default ListenerPlatform