import React, {useState, useMemo} from 'react'
import {
  Navbar,
  useWindowDimensions,
  Avatar,
  FloatButton,
  IconClose,
  Modal,
  NavbarMobile
} from '@Knowledge-OTP/znk-ui-components'

import SwitchPlatform from './SwitchPlatform'
import getEnv from '../../helpers/getEnv'

import { useItems } from './Header/items'
import { useHistory/** , useLocation*/ } from 'react-router'
import { useSelector } from 'react-redux'
import useTranslation from '../../i18n/useTranslation'
import EditAvatar from '../EditAvatar'


const Layout = (props) => {
  const enviroment = getEnv()
  const { translate } = useTranslation()
  const history = useHistory()
  const { breakWidth } = useWindowDimensions()
  const {
    items, 
    secondaryItems,
    toolOptions,
    settingOption
  } = useItems()
  const {firstName, lastName, avatar: avatarUser, refetch} = useSelector((state) => state.user)
  const [isReset, setIsReset] = useState(null)
  const [openCrop, setOpenCrop] = useState(false)
    const toggleCrop = () => {
    setOpenCrop(!openCrop)
  }
  const onChangeAvatar = (file) => {
    setOpenCrop(true)
  }
  const fetch = async () => {
    await refetch()
    setIsReset(isReset === null ? true : !isReset)
  }
  const onWorkSpace = useMemo(
    //sirve para eliminar el padding de bodypublic
    // cuando el tamaño de pantalla es SM
    () => {
      return ['/workspace/colleges', '/workspace/tasks'].includes(history.location.pathname)
      // eslint-disable-next-line
    }, [history, breakWidth]
  )

  // const location = useLocation()
  
  
  const isCalendar = false 
  // /^\/calendar\/.*/.test(location.pathname) ||
  //   /^\/calendar\?.*/.test(location.pathname) || /^\/calendar/.test(location.pathname)

  // const isLessonList = /^\/lessons\/.*/.test(location.pathname) ||
  //   /^\/lessons\?.*/.test(location.pathname) || /^\/lessons/.test(location.pathname)


  return (
    <>
      {
        <Modal
          className='modal-centered medium modal-colored white-modal pr-3 pl-3 '
          isOpen={openCrop}
          toggle={toggleCrop}
          backdrop='static'
          style={{height: '95%'}}
          StyleModalBody={{height: '100%'}}
          classModalBody='contentHeight'
          Head={
            <div className='modal-header p-0 m-0'>
              <div className='d-flex justify-content-between w-100 pt-7 pb-4 px-7'>
                <h2 className='font-weight-bold'>{translate('profile.actions.changeAvatar.title')}</h2>
                <IconClose onClick={toggleCrop} className='text-gray' />
              </div>
            </div>
          }
        >
          <EditAvatar userId={null} fetch={fetch} toogle={toggleCrop}/>
        </Modal>
      }
      <div
        className={`w-100 h-100 ${breakWidth === 'SM' ? 'bg-light' : 'bg-light'}`}
      >
        {breakWidth === 'SM' ? (
          <div className='d-flex flex-column align-items-center h-100'>
            <div className='navPublic w-100'>
              {/* <Navbar
                light
                expand='xs'
                className=''
                navClass=''
                history={history}
                isPublic={false}
                items={items}
              /> */}
              <NavbarMobile 
                items={items}
                FootBrand={
                  <div className='navbar-brand d-flex flex-column justify-content-end'>
                      <span
                        className='d-flex justify-content-between align-items-center'
                        onClick={() => history.push('/')}
                        style={{cursor: 'pointer'}}
                      >
                        <Avatar
                          name={`${firstName} ${lastName}`}
                          uri={avatarUser}
                          size='avatar-small'
                          activateChange={true}
                          onClickImage={onChangeAvatar}
                          isReset={isReset}
                        />
                        <span className='h4 d-none d-xl-block'>
                          &nbsp;{firstName}
                        </span>
                      </span>
                  </div>
                }
              />
            </div>
            <div 
              style={
                isCalendar
                  ? {overflowY: 'hidden'}
                  : {}
              }
              className={`col-12 bodyPublic thisbody ${onWorkSpace ? 'pr-0' : ''}`}>{props.children}</div>
          </div>
        ) : (
          <div className='d-none d-md-flex h-100'>
            <div className='col-1 h-100 p-0'>
              <Navbar
                light
                expand='md'
                className='d-none d-md-flex flex-column h-100'
                navClass='d-flex justify-content-start flex-column'
                isPublic={false}
                items={items}
                secondaryItems={secondaryItems}
                navSecondaryClass={'h-100 d-flex justify-content-end flex-column'}
                FootBrand={
                  <div className='navbar-brand d-flex flex-column justify-content-end'>
                      <span
                        className='d-flex justify-content-between align-items-center mb-2'
                        onClick={() => history.push('/')}
                        style={{cursor: 'pointer'}}
                      >
                        <Avatar
                          name={`${firstName} ${lastName}`}
                          uri={avatarUser}
                          size='avatar-small'
                          activateChange={true}
                          onClickImage={onChangeAvatar}
                          isReset={isReset}
                        />
                        <span className='h4 d-none d-xl-block'>
                          &nbsp;{firstName}
                        </span>
                      </span>
                  </div>
                }
              />
            </div>
            <div 
            style={
              isCalendar
                ? {overflowY: 'hidden'}
                : {}
            }
            className='col-11 h-100 bodyPublic d-flex flex-column p-0 hidde-scroll-vertical'>              
              {props.children}
            </div>
          </div>
        )}
        {
          ['beta', 'local'].includes(enviroment) &&
          <SwitchPlatform/>
        }
        {
          breakWidth === 'SM' && (
            <FloatButton
              options={[
                {
                  label: 'Tools',
                  options: toolOptions,
                },
                {
                  label: 'Setting',
                  options: settingOption
                }
              ]}
            />
          )
        }
      </div>
    </>
  )
}

export default Layout
