export const examColors = {
    SAT: 'sat',
    TOEFL: 'toefl',
    ACT: 'act',
    IELTS: 'ielts',
    AP: 'ap',
    Duolingo: 'duolingo',
    SSAT: 'ssat',
    GRE: 'gre',
    LSAT: 'lsat',
    ISEE: 'isee',
  }