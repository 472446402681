import {useMutation, gql} from '@apollo/client'

const useEditPdfTemplate = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $templateInput: PdfTemplateInput!
                $templateId: ID!
            ) {
                editPdfTemplate (
                    templateInput: $templateInput
                    templateId: $templateId
                ) {
                    id
                    name
                    json
                    key
                }
            }
        `,
        {
            onCompleted: onSuccess,
            onError
        }
    )
}

export default useEditPdfTemplate