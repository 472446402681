import React, { useState } from 'react'
import {
  ListItem,
  IconOptions,
  PlainTextInput,
  Field,
  DropDown,
  IconPassword,
  IconArrowUp,
  IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
import EditRate from './EditRate.js'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import {Collapse} from 'reactstrap'

const PricingAndPayments = (props) => {
  const { form, setForm, isEdit } = props
  const dispatch = useDispatch()
  const [defaultRate, setDefaultRate] = useState(0)
  const [defaultCheck, setDefaultCheck] = useState(false)
  const [title, setTitle] = useState('')
  const { translate } = useTranslation()

  const [editing, setEditing] = useState(false)

  const [openStudentRates, setOpenStudentRates] = useState(false)
  const toggleStudentRatesCollapse = () => {setOpenStudentRates(!openStudentRates)}  

  const educatorWage = form?.educatorRate || 0


  return (
    <>
      <div className='m-0 p-0'>
        <h3><b>{translate('modal.newlesson.pricingpayment')}</b></h3><br />
        {
          form?.typeLesson === 'group' &&
          <ListItem
            className='py-3'
            showBottomLine
            Left={
              <span>
                {translate('modal.newlesson.studentrate')}
              </span>
            }
            Right={
              <div className='d-flex flex-row align-items-center'>
                <span className={'mr-1'}>
                  {
                    form?.studentRate?.length > 0 
                    ? form?.studentRate?.reduce(
                      ([minRate, maxRate], rate) => {
                        if(minRate > rate) return [rate, maxRate]
                        else if(maxRate < rate) return [minRate, rate]
                        return [minRate, maxRate]
                      }, [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY])?.map(
                        (r) => `$${parseFloat(r).toFixed(2)}`)?.join(' - ')
                    : (0).toFixed(2)
                  }
                </span>
                <div className={'hover-icon px-2 py-1'} onClick={toggleStudentRatesCollapse}>
                  {
                    openStudentRates
                    ? <IconArrowUp/>
                    : <IconArrowDown/>
                  }
                </div>
              </div>
            }
          />
        }
        <Collapse isOpen={openStudentRates || form?.typeLesson !== 'group'}>
          {
            form?.student?.length > 0 
            ? form?.student?.map(({firstName, lastName, rate, waive}, indexStu) => {
                return (
                  <ListItem
                    className='py-3'
                    Left={
                      <span>
                        {
                          form?.typeLesson === 'group'
                          ? `${firstName} ${lastName}`
                          : translate('modal.newlesson.studentrate')
                        }
                      </span>
                    }
                    Right={
                      <div className='d-flex flex-row'>
                        <span
                          className={`${waive ? 'text-gray' : ''}`} style={
                                          waive
                                            ? {
                                              textDecoration: 'line-through'
                                            } : {}
                          }
                        >{`$${(rate || 0).toFixed(2)}`}
                        </span>
                        {
                          (editing || !isEdit) &&
                            (
                              <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                                {
                                  form?.isPlanLesson
                                    ? (<IconPassword className='ml-1' />)
                                    : (
                                      <DropDown
                                        direction='down'
                                        fixedWidth
                                        hoverEnabled={false}
                                        options={[
                                          {
                                            component:
                                        <span
                                          onClick={() => {
                                            setDefaultRate(rate || 0)
                                            setDefaultCheck(waive || false)
                                            setTitle(translate('modal.newlesson.editsturate'))
                                            dispatch({
                                              type: 'SET_DATA_MODAL_FORM_LIST',
                                              payload: 'plan.editRateLesson',
                                              data: {
                                                isOpen: true,
                                                onClickFunction: (newVal, check) => {
                                                  form.studentRate[indexStu] = newVal
                                                  form.waiveStudentRate[indexStu] = check
                                                  setForm({
                                                    ...form,
                                                    isStudentRate: false,
                                                    student: form.student.map((stuAux, index) => {
                                                      if(index === indexStu) return {
                                                        ...stuAux, 
                                                        rate: newVal,
                                                        waive: check
                                                      }
                                                      return stuAux
                                                    })
                                                  })
                                                }
                                              }
                                            })
                                          }}
                                        >{translate('modal.newlesson.adjustrate')}
                                        </span>
                                          },
                                          {
                                            component: <span onClick={() => {
                                              waive = !waive 
                                              setForm({
                                                ...form,
                                                student: form.student.map((stuAux, index) => {
                                                  if(index === indexStu) return {
                                                    ...stuAux, 
                                                    waive: waive 
                                                  }
                                                  return stuAux
                                                })
                                              }) //por alguna razon es necesario
                                            }}
                                                      >{translate('modal.newlesson.waiverate')}
                                                      </span>
                                          }
                                        ]}
                                      >
                                        <IconOptions className='ml-1' style={{ transform: 'rotate(90deg)' }} />
                                      </DropDown>
                                    )
                                }
                              </div>
                            )
                        }
                      </div>
                    }
                  />
                )
              })
            : <div className={`w-100 py-3 d-flex flex-row ${form?.typeLesson === 'group' ? 'justify-content-center' : 'justify-content-left'}`}>
                <span className={'text-gray'}>
                  {
                    form.typeLesson === 'group'
                    ? translate('modal.newplan.selectstudents')
                    : translate('modal.newplan.selectstudent')
                  }</span>
              </div>
          }
        </Collapse>
        <ListItem
          className='py-3'
          showTopLine={openStudentRates}
          Left={
            <span>{translate('modal.newlesson.educatorwage')}</span>
          }
          Right={
            <div className='d-flex flex-row'>
              <span
                className={`${form?.waiveEducatorRate ? 'text-gray' : ''}`} style={
                                form?.waiveEducatorRate
                                  ? {
                                    textDecoration: 'line-through'
                                  } : {}
                }
              >{`$${(educatorWage).toFixed(2)}`}
              </span>
              {
                (editing || !isEdit) && (
                  <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDown
                      direction='down'
                      fixedWidth
                      hoverEnabled={false}
                      options={[
                        {
                          component:
  <span
    onClick={() => {
      setDefaultRate(educatorWage)
      setDefaultCheck(form?.waiveEducatorRate || false)
      setTitle(translate('modal.newlesson.editedurate'))
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.editRateLesson',
        data: {
          isOpen: true,
          onClickFunction: (newVal, check) => {
            setForm({
              ...form,
              educatorRate: newVal,
              waiveEducatorRate: check,
              isEducatorRate: false
            })
          }
        }
      })
    }}
  >{translate('modal.newlesson.adjustrate')}
  </span>
                        },
                        {
                          component: <span onClick={() => {
                            setForm({
                              ...form,
                              waiveEducatorRate: !(form?.waiveEducatorRate)
                            })
                          }}
                          >{translate('modal.newlesson.waiverate')}
                          </span>
                        }
                      ]}
                    >
                      <IconOptions className='ml-1' style={{ transform: 'rotate(90deg)' }} />
                    </DropDown>
                  </div>
                )
              }
            </div>
          }
        />
        {
          !form.isPlanLesson && (
            (editing || !isEdit)
              ? (
                <>
                  <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                    {translate('modal.newlesson.paymentfor')}
                  </label>
                  <Field
                    className='col-12 w-100'
                    type={PlainTextInput}
                    fieldName='paymentDate'
                    fieldType='date'
                    value={form.paymentDate}
                  />
                </>
              )
              : (
                <ListItem
                  className='py-3'
                  Left={translate('modal.newlesson.paymentfor')}
                  Right={`${translate('modal.newlesson.endOf')} ${moment(form.paymentDate).format('MMMM')}`}
                />
              )
          )
        }
        <ListItem
          className='pt-3'
          Left={
            <span className='font-weight-bold'>{translate('modal.newlesson.totalprice')}</span>
          }
          Right={
            <span className='font-weight-bold'>{`$${(
              form?.student?.length > 0 
                ? form?.student?.reduce((total, {rate, waive}) => {
                  if(waive) return total
                  return total + rate
                }, 0) 
                : 0).toFixed(2)}`}</span>
          }
        />
        {
          isEdit &&
          (<label className='btn-link' onClick={() => { setEditing(!editing) }}>{translate('modal.editlesson.editpricepayment')}</label>)
        }
      </div>
      <EditRate defaultRate={defaultRate} defaultCheck={defaultCheck} title={title} />
    </>
  )
}

export default PricingAndPayments
