import React, {useEffect} from 'react'
import { Table } from "@Knowledge-OTP/znk-ui-components"
import useColumns from './useColumns'
import { useLazyQuery, gql } from '@apollo/client'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import { useDispatch } from 'react-redux'
//MODAL
import SetPartAttributeModal from './modal/SetPartAttribute' 
import moment from 'moment-timezone'

const LessonPartFix = () => {
    const dispatch = useDispatch()
    const columns = useColumns()
    const [getLessons, {data, loading, refetch}] = useLazyQuery(
        gql`
        query ($limit: Float!) {
            getPlanLessonWithoutPart (
              limit: $limit
            ){
              id
              title
              startDate
              endDate
              topic {
                id
                name
              }
              educator {
                firstName
                lastName      
              }
              recurringId
              planId
            }
        }`,
        {variables: {limit: 100}}
    )
    useEffect(() => {
        getLessons({variables: {limit: 100}})  
    }, [getLessons])
    const parseLesson = (data?.getPlanLessonWithoutPart || []).map((lesson) => {
        return {
            planId: lesson.planId,
            title: lesson?.title,
            startDate: moment(lesson.startDate).format('DD MMMM YY, HH:mm a'),
            endDate: moment(lesson.endDate).format('DD MMMM YY, HH:mm a'),
            topicName: lesson?.topic?.name || 'No defined',
            lessonData: lesson
        }
    })
    return (
        <div className={'m-0 p-0 mt-3 text-title'}>
            <Table
                loading={loading}
                columnList={columns}
                tableData={parseLesson}
                onClick={(item) => {
                    dispatch({
                        type: SET_DATA_MODAL_FORM_LIST,
                        payload: 'developer.setIndexPartModal',
                        data: {
                          isOpen: true,
                          planId: item.planId,
                          lessonData: item.lessonData 
                        }
                    })
                }}
            />
            <SetPartAttributeModal refetch={refetch}/>
        </div>
    )
}

export default LessonPartFix