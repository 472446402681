import React from 'react'
import {
    ConfirmModal
} from '@Knowledge-OTP/znk-ui-components'
import useCompleteTaskModal from './useCompleteTaskModal'
import useOperationTaskMutation from '../Model/useOperationTask'

const CompleteTaskModal = () => {
    const {isOpen, toggle, onChange, close, taskData, useMutation, loading} = useCompleteTaskModal()

    const callBack = () => {
        onChange(/**newTask*/)
    }

    const [operationMutation, {loading: loadMutation}] = useOperationTaskMutation(
        () => {
            callBack()
        },
        (err) => {
            console.error("Error on complete task : ", err)
        },
    )
    return (
        <ConfirmModal 
            classNameActionDiv={`d-flex flex-column`}
            classNameConfirm={'text-primary white-space-nowrap'}
            isOpen={isOpen}
            toggle={toggle}
            title={'Do you want complete this task?'}
            body={null}
            loading={loading || loadMutation}
            confirmAction={() => {
                if(useMutation) {
                    operationMutation({
                        variables: {
                            universities: [],
                            operation: 'approve',
                            userId: taskData?.userId,
                            taskId: taskData?.taskId,
                            stepId: taskData?.stepId,
                            phase: taskData?.phaseId.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }),
                            planId: taskData?.planId,
                        }
                    })
                } else callBack()
                   
            }}
            cancelAction={() => {close()}}
            confirmText={'Complete task'}
            cancelText={'Cancel'}
        />
    )
}

export default CompleteTaskModal