import React, {useMemo} from 'react'
import {
    ListItem,
    Avatar,
    IconEdit,
    IconArrowRight
} from '@Knowledge-OTP/znk-ui-components'
import useGetUserData from './Model/useGetUser'
import Skeleton from 'react-loading-skeleton'
import { TabContent, TabPane} from 'reactstrap'
import SelectStudent from '../../../../../Plans/modal/newPlan/SelectStudent'

const ShowUserAvatar = (props) => {
    const {className, userId = null, onChange, isEdit = true, ...otherProps} = props
    const {data, loading} = useGetUserData({id: userId || ''})
    const userName = useMemo(() => {    
        return data?.user ? `${data?.user?.firstName} ${data?.user?.lastName}` : null
    }, [data])
    const activeTab = useMemo(() => {
        if(loading) return 'loading'
        if(userId) return 'user'
        return 'no-defined'
    }, [loading, userId])
    return (
        <TabContent activeTab={activeTab}>
            <TabPane tabId={'loading'}>
                <Skeleton className={`my-2`} count={1} height={20} width={90}/>
            </TabPane>
            <TabPane tabId={'user'}>
                <ListItem
                    className={`py-4 mr-2 ${className}`}
                    showBottomLine
                    showTopLine
                    Left={
                        <div className='d-flex align-items-center'>
                            {
                                userName && (
                                    <>
                                        <Avatar
                                            name={userName}
                                            named={false}
                                            uri={null}
                                            size='avatar-small'
                                        />
                                        <label className='ml-2 h4 align-self-center mb-0 text-info'>
                                            {userName}
                                        </label>
                                    </>
                                ) 
                            }
                        </div>
                    }
                    Right={
                        isEdit && 
                        (<SelectStudent 
                            setStudent={(student) => {
                                onChange(student)
                            }} 
                            student={data?.user}
                        >
                            <div className={`hover-icon`}>
                                <IconEdit/>
                            </div>
                        </SelectStudent>)
                    }
                    {...otherProps}
                />
            </TabPane>
            <TabPane tabId={'no-defined'}>
                <ListItem
                    className={`py-2 ${className}`}
                    showBottomLine
                    showTopLine
                    Left={
                        <div className='d-flex align-items-center'>
                            <label className='ml-2 h4 align-self-center mb-0 text-info'>
                            Select User
                            </label>
                        </div>
                    }
                    Right={
                        <SelectStudent 
                            setStudent={(student) => {
                                onChange(student)
                            }} 
                            student={data?.user}
                        >
                            <div className={`hover-icon`}>
                                <IconArrowRight/>
                            </div>
                        </SelectStudent>
                    }
                    {...otherProps}
                />
            </TabPane>
        </TabContent>
    )
}

export default ShowUserAvatar