import React, {useEffect, useState} from 'react'
import {
    Modal,
} from '@Knowledge-OTP/znk-ui-components'
import { Label } from 'reactstrap'

import {TabContent, TabPane} from 'reactstrap'
import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../../../store/modal/const'
import SelectStudents from './Tabs/SelectStudents'
import AdjustLessons from './Tabs/AdjustLessons'

const TABS = {
    SelectStudents: 'select-students',
    AdjustLessons: 'adjust-lessons'
}

const StudentsOnTimezone = () => {
    //ids de estudiantes a los que se aplicará el ajuste
    const [studentIds, setStudentIds] = useState([])
    const [activeTab, setActiveTab] = useState(TABS.SelectStudents)
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.developer.studentsOnTimezone)
    console.log("reduxData", reduxData)
    const [appendsHours, setAppendsHours] = useState(0)
    const {isOpen, timezone, changeDate} = reduxData
    
    //restaura tab luego de cerrar modal
    useEffect(() => {
        if(!isOpen) {
            setActiveTab(TABS.SelectStudents)
            setStudentIds([])
        }
    }, [isOpen])
    
    const toggle = () => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'developer.studentsOnTimezone',
            data: {...reduxData, isOpen: !isOpen}
        })
    }

    return (
        <Modal
            className={'modal-centered'}
            isOpen={isOpen}
            toggle={toggle}
            Head={
                <>
                <div className={'d-flex flex-column'}>
                    <span className={'h2 font-weight-bold text-title'}>Educators</span>
                    <span className={'h45 text-gray'}>Timezone: {timezone}</span>
                </div>
                <div className={`d-flex flex-row`}>
                <Label>
                    Hours to move:
                </Label>
                </div>
                <div className={`d-flex flex-row`}>
                <select className={"col-12"} value={appendsHours} onChange={(e) => setAppendsHours(Number(e.target.value))}>
                        <option value="0">Select Hours</option>
                       <option value="1">+1</option>
                       <option value="-1">-1</option>
                </select>
               </div>
               </>
            }
        >
            <TabContent activeTab={activeTab}>
                <TabPane tabId={TABS.SelectStudents}>
                    <SelectStudents toggle={toggle} timezone={timezone} setStudentIds={setStudentIds} studentIds={studentIds} onContinue={() => {setActiveTab(TABS.AdjustLessons)}} />
                </TabPane>
                <TabPane tabId={TABS.AdjustLessons}>
                    <AdjustLessons appendsHours={appendsHours} toggle={toggle} activeTab={activeTab}  timezone={timezone} changeDate={changeDate} studentIds={studentIds} onBack={() => {setActiveTab(TABS.SelectStudents)}} />
                </TabPane>
            </TabContent>
        </Modal>
    )
}

export default StudentsOnTimezone