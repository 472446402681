import React, {useMemo} from 'react'
import { IconArrowDown, CheckboxSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import useAllUsersAssign from '../../../../../CounselingPlans/Model/useAllUsersAssign'

const AssignBy = (props) => {
    const {filters = [], setFilters = () => {}} = props
    const {counselor: counselors} = useAllUsersAssign()

    const allCounselors = useMemo(
        () => {
            const users = [...(counselors || [])]
            return users?.map((user) => {
                return {...user, name: `${user.firstName} ${user.lastName}`}
            }).sort((a, b) => a.name.localeCompare(b.name)) || []
        }, [counselors]
    )
    return (
        <CheckboxSelector
            setDataFilters={setFilters}
            direction='bottom'
            dataFilters={filters}
            data={allCounselors}
            typeFilter='status'
            strings={{
                viewAll: 'View all',
                buttonCancel: 'Cancel',
                buttonApply: 'Apply',
                search: 'Search'
            }}
            >
            <Badge className='mr-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                Assign by
                <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default AssignBy