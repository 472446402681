export const getEmailsListFromParents = (usersListFromPlan) => {
    if (!Array.isArray(usersListFromPlan)) return []
    const studentsEmails = []
    const parentsEmails = []
    if (usersListFromPlan?.length < 1) return
    // eslint-disable-next-line
    usersListFromPlan.map((plan) => {
        parentsEmails.push(plan.emails[0].address)
        if (plan?.parentInfo?.children?.length > 0) {
            // eslint-disable-next-line
            plan?.parentInfo?.children.map((child) => {
                studentsEmails.push(child.email)
            }
            )
        }
    }
    )
    return { studentsEmails: [...new Set(studentsEmails)], parentsEmails: [...new Set(parentsEmails)] }
}