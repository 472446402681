import { CheckboxSelector, CheckboxSelectorInner, IconArrowDown } from '@Knowledge-OTP/znk-ui-components'
import React from 'react'
import { Badge } from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'

const TypesFilter = (props) => {
    const {typesFilters, setTypesFilters, types = [], useInner = false} = props
  const { translate } = useTranslation()
  const capitalize = (text) => {
    const result = text?.replace(/([A-Z])/g, " $1");
    return result?.charAt(0)?.toUpperCase() + result?.slice(1);
  }

  const checkboxSelectorProps = {
    setDataFilters: setTypesFilters,
    direction: 'bottomCenter',
    dataFilters: typesFilters,
    typeFilter: 'statuses',
    data: types?.map((type) => {
      return {name: capitalize(type), id: type}
    }),
    strings: {
      viewAll: translate('filters.plans.viewAll'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
      search: translate('filters.plans.search')
    }
  }

  return (
    useInner ?
    <CheckboxSelectorInner style={{maxWidth: 'auto'}} {...checkboxSelectorProps}/> :
    <CheckboxSelector {...checkboxSelectorProps}>
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          Type
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default TypesFilter
