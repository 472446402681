import React from 'react'
import HeaderField from '../../../HeaderField'
import Skeleton from 'react-loading-skeleton'

const StudentNameField = (props) => {
    const { className, loading = false, plan,
        header = false, onSort = () => {}, sort = 'name', order = false } = props
    const studentName = `${plan?.studentUser?.firstName} ${plan?.studentUser?.lastName}`

    return (
        <>
        {
            loading ?
                <span className={`d-flex flex-row justify-content-start align-items-center ${className}`}>
                    <Skeleton count={1} width={100} height={16} style={{ borderRadius: '12px' }} />
                </span> :
                header ? 
                <HeaderField 
                    active={sort === 'student'} order={order} 
                    onClick={() => { onSort('student', !order /**ASC**/) }} 
                    className={`h45 text-gray d-flex flex-row justify-content-start align-items-center ${className}`}>
                        Student Name
                </HeaderField> :
                <span 
                    title={studentName} 
                    className={`text-truncate h4 text-info d-flex flex-row justify-content-start align-items-center ${className}`}>{
                        studentName
                    }
                </span>
        }
        </>    
    )
}

export default StudentNameField