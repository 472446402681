import React, { useState, useEffect, useMemo } from 'react'
import {
  ModalLayout,
  Field,
  SelectOption,
  Form,
  InputSelect,
  PlainTextInput,
  StickyButton,
  Button,
  IconArrowRight,
  ListItem,
  useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { categories, types } from '../constants'
import { gql, useLazyQuery } from '@apollo/client'
import formatNumber from '../../../../utils/formatNumber'
import editCustomPaymentItemFromAdmin from './editCustomPaymentItemFromAdmin'
import editLessonPaymentItemFromAdmin from './editLessonPaymentItemFromAdmin'
import editPlanPaymentItemFromAdmin from './editPlanPaymentItemFromAdmin'
import moment from 'moment'
import AddPayment from '../../../Plans/GroupPlans/modal/New/Components/PaymentScehdule/AddPayment'
import EditPlanPaymentItemFromAdmin from '../../../../Model/Payments/Edit/EditPlanPaymentItemFromAdmin'
import { showAlert } from '../../../../common/Alert/util'
import {escapeRegExp} from 'lodash'

const EditPaymentItem = (props) => {
  const { refetch, setExit } = props
  const {breakWidth} = useWindowDimensions()
  const modalData = useSelector((state) => state.modal.payments.editPaymentPlan)
  const { isOpen, paymentId, kind, invoiceId } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const [form, setForm] = useState({
    chargeType: 'studentCharge',
    category: 'livePrep',
    type: 'lessonsPayment',
    amount: 0,
    discount: 0,
    date: new Date(),
    description: ''
  })
  const toggle = () => {
    setForm({
      chargeType: 'studentCharge',
      category: 'livePrep',
      type: 'lessonsPayment',
      amount: 0,
      discount: 0,
      date: new Date(),
      description: ''
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.editPaymentPlan',
      data: {
        isOpen: false,
        invoiceId: ''
      }
    })
  }
  const [canReset, setCanReset] = useState(false)
  // const [canDistributed, setCanDistributed] = useState(false)
  const [cuotas] = useState([])
  // const [lastPay, setLastPay] = useState(cuotas ? cuotas[cuotas.length - 1] ? cuotas[cuotas.length - 1].time : moment() : moment())
  // const [planId, setPlanId] = useState(undefined)
  // const [statusPlan, setStatusPlan] = useState('')
  const [formLesson, setFormLesson] = useState({
    amount: 0,
    discount: 0,
    description: '',
    date: new Date()
  })
  const [formCustom, setFormCustom] = useState({
    chargeType: 'studentCharge',
    category: 'livePrep',
    type: 'lessonsPayment',
    amount: 0,
    discount: 0,
    description: '',
    date: new Date()
  })
  const [paymentData, setPaymentData] = useState({
    category: 'livePrep',
    chargeType: 'studentCharge',
    type: 'lessonsPayment',
    userId: "",
    discount: 0
  })
  // const [getPlan, { data: dataPlan }] = useLazyQuery(
  //   gql`
  //     query($planId: ID!){
  //       plan(planId: $planId){
  //         status
  //       }
  //     }
  //   `,
  //   {
  //     variables: { planId: planId },
  //     fetchPolicy: 'network-only'
  //   }
  // )
  const [mePayment, { data }] = useLazyQuery(
    gql`query($paymentItemId: ID!){
      paymentItem(paymentItemId: $paymentItemId) {
        ... on PlanPaymentItem {
          planId
          planItems {
            id
            kind
            amount
            amountWithoutDiscount
            generatedDescription
            percentDiscount
            status
            date
            category
            chargeType
            draft
            lastProcessedAt
            description
            type
            userId
            planId
          }
        }
        ... on LessonPaymentItem {
          id
          kind
          amount
          amountWithoutDiscount
          percentDiscount
          status
          date
          category
          draft
          lastProcessedAt
          description
          chargeType
          generatedDescription
          type
          userId
        }
        ... on EducatorPaymentItem {
          lessonId
          id
          amount
          amountWithoutDiscount
          percentDiscount
          status
          date
          category
          draft
          chargeType
          lastProcessedAt
          description
          type
          userId
        }
        ... on CustomPaymentItem {
          id
          amount
          amountWithoutDiscount
          percentDiscount
          chargeType
          status
          date
          category
          draft
          lastProcessedAt
          description
          chargeType
          type
          userId
        }
      }
    }`,
    {
      variables: { paymentItemId: paymentId },
      fetchPolicy: 'network-only'
    }
  )
  // useEffect(() => {
  //   (async () => {
  //     if (planId) {
  //       await getPlan()
  //     }
  //   })()
  // }, [planId, getPlan])
  // useEffect(() => {
  //   if (dataPlan) {
  //     setStatusPlan(dataPlan.plan.status)
  //   }
  // }, [dataPlan])
  useEffect(() => {
    (async () => {
      if (!paymentId) return
      await mePayment()
    })()
  }, [paymentId, mePayment])
  //useEffect(() => {
  //  if (data) {
  //    if (kind === 'plan-payment-item') {
  //      // setPlanId(data.paymentItem.planId)
  //      setForm({
  //        planId: data.paymentItem.planId,
  //        whenAprove: kind === 'plan-payment-item' ? data.paymentItem.planItems.filter(e => e.type === 'otherFixed').reduce((sum, current) => sum + current.amount, 0) : 0,
  //        planItems: kind === 'plan-payment-item' ? data.paymentItem.planItems.filter(e => e.type !== 'otherFixed') : [],
  //        paymentstart: data.paymentItem.planItems.filter(e => e.type !== 'otherFixed')[0].date.split('T')[0]
  //      })
  //      setCuotas(
  //        data.paymentItem.planItems.filter(e => e.type !== 'otherFixed').map((cuo) => {
  //          return {
  //            ...cuo,
  //            value: cuo.amount,
  //            time: cuo.date,
  //            custom: false,
  //            isEdit: false,
  //            isNewPayment: false
  //          }
  //        })
  //      )
  //    }
  //    if (kind === 'lesson-payment-item') {
  //      setFormLesson({
  //        amount: data.paymentItem.amount,
  //        description: data.paymentItem.generatedDescription ?? data.paymentItem.description,
  //        date: moment(data.paymentItem.date.split('T')[0]).format('YYYY-MM-DD'),
  //        chargeType: data.paymentItem.chargeType,
  //        category: data.paymentItem.category,
  //        type: data.paymentItem.type,
  //        discount: data.paymentItem.percentDiscount,
  //        userId: data.paymentItem.userId
  //      })
  //    }
  //    if (kind === 'custom-payment-item') {
  //      setFormCustom({
  //        chargeType: data.paymentItem.chargeType,
  //        category: data.paymentItem.category,
  //        type: data.paymentItem.type,
  //        amount: Math.abs(data.paymentItem.amountWithoutDiscount),
  //        description: data.paymentItem.generatedDescription ?? data.paymentItem.description,
  //        date: moment(data.paymentItem.date.split('T')[0]).format('YYYY-MM-DD'),
  //        discount: data.paymentItem.percentDiscount,
  //        userId: data.paymentItem.userId
  //      })
  //    }
  //  }
  //}, [data, kind])
  //useEffect(() => {
  //  if (cuotas && cuotas.length > 0) {
  //    setLastPay(cuotas[cuotas.length - 1].time)
  //  }
  //}, [cuotas])
  //useEffect(() => {
  //  setCuotas(prevCuotas => {
  //    const start = moment.utc(form.paymentstart).endOf('month').add(-2, 'd')
  //    return prevCuotas ? prevCuotas.map(cuo => {
  //      const time = start.clone()
  //      start.add(1, 'month')
  //      return {
  //        ...cuo,
  //        time: time.toISOString(),
  //        isEdit: true
  //      }
  //    }) : []
  //  })
  //  if (data && (moment(form.paymentstart).month() !== moment(data.paymentItem.planItems.filter(e => e.type !== 'otherFixed')[0].date.split('T')[0]).month())) {
  //    setCanReset(true)
  //  }
  //  // eslint-disable-next-line
  //}, [form.paymentstart])
  const { submit: submitCustom, loading: loadingCustom } = editCustomPaymentItemFromAdmin(() => {
    refetch()
    toggle()
  }, console.log)
  const { submit: submitLesson, loading: loadingLesson } = editLessonPaymentItemFromAdmin(() => {
    refetch()
    toggle()
  }, console.log)
  const { submit: submitPlan, loading: loadingPlan } = editPlanPaymentItemFromAdmin(() => {
    refetch()
    toggle()
  }, console.log)
  const onSubmit = (type) => {
    if (kind === 'custom-payment-item') {
      submitCustom({ paymentItemId: paymentId, paymentInput: formCustom })
    } else if (kind === 'lesson-payment-item') {
      submitLesson({ paymentItemId: paymentId, paymentInput: formLesson })
    } else {
      const newVal = {
        payments: cuotas.map (
          (payment, index) => {
            return !payment.isNewPayment
              ? {
                paymentId: payment.id,
                userId: payment.userId,
                date: payment.time,
                amount: payment.value,
                discount: 0,
                description: payment.description,
                category: payment.category,
                chargeType: payment.chargeType,
                type: payment.type,
                isNewPayment: payment.isNewPayment,
                isEdit: payment.isEdit
              }
              : {
                paymentId: undefined,
                userId: cuotas[0].userId,
                date: payment.time,
                amount: payment.value,
                discount: 0,
                description: 'Payment ' + index.toString(),
                category: cuotas[0].category,
                chargeType: cuotas[0].chargeType,
                type: cuotas[0].type,
                isNewPayment: true,
                isEdit: false
              }
          }
        )
      }
      submitPlan({ planId: form.planId, paymentInput: newVal })
    }
  }
  const canAddPayment = true
  // const AddPaymentItem = (newPayment) => {
  //   // cantidad total de cuotas no customizadas
  //   let cTotal = 0
  //   const total = cuotas.reduce((totalRed, cuota) => {
  //     if (!cuota.custom) { cTotal += 1 }
  //     return !cuota.custom ? cuota.value + totalRed : totalRed
  //   }, 0) // valor total de cuotas no customizadas
  //   let valorCuota = 0
  //   if (cTotal > 0) {
  //     valorCuota = total / (cTotal + 1)
  //   }
  //   newPayment = { ...newPayment, value: valorCuota, isNewPayment: true }
  //   setCuotas(
  //     cuotas.concat([newPayment]).map((c) => {
  //       if (c.custom) {
  //         return c
  //       } else {
  //         return { ...c, value: valorCuota, isEdit: true }
  //       }
  //     })
  //   )
  //   setCanReset(true)
  // }
  // const InitPayment = () => {
  //   setCuotas(
  //     form.planItems.map((cuo) => {
  //       return {
  //         ...cuo,
  //         value: cuo.amount,
  //         time: cuo.date,
  //         custom: false,
  //         isEdit: false,
  //         isNewPayment: false
  //       }
  //     })
  //   )
  //   setCanReset(false)
  //   // setCanDistributed(false)
  //   setForm({
  //     ...form,
  //     paymentstart: form.planItems[0].date.split('T')[0]
  //   })
  // }
  // const DistributedPayment = async () => {
  //   const total = cuotas.reduce((totalRed, cuota) => {
  //     return cuota.value + totalRed
  //   }, 0) // valor total de cuotas no customizadas
  //   const cTotal = cuotas.length
  //   let valorCuota = 0
  //   if (cTotal > 0) {
  //     valorCuota = total / cTotal
  //   }
  //   setCuotas(
  //     cuotas.map((c) => {
  //       return { ...c, value: valorCuota, custom: false }
  //     })
  //   )
  //   setCanDistributed(true)
  // }

  const [meInvoice, { data: dataInvoice }] = useLazyQuery(
    gql`query($invoiceId: String!){
      invoice(invoiceId: $invoiceId) {
        id
      }
    }`    
  )

  const {loading, submit: editPlanPayments} = EditPlanPaymentItemFromAdmin(
    () => {
      if (invoiceId === '') return
      meInvoice({
        variables: { invoiceId: invoiceId },
        fetchPolicy: 'network-only'
      })

      // showAlert({
      //   text: "Success",
      //   status: 'success'
      // }, dispatch)
      // //refetch()
      // setExit()
      // 
    },
    () => {
      showAlert({
        text: "¡Error!",
        status: 'error'
      }, dispatch)
    }  
  )

  useEffect(() => {
    if (dataInvoice && invoiceId !== '') {
      showAlert({
        text: "Success",
        status: 'success'
      }, dispatch)
      if (!dataInvoice.invoiceId) {
        setExit()
      } else {
        refetch()
      }
      toggle()
    }
    // eslint-disable-next-line
  }, [dataInvoice])

  const initCustomPayments = (payments = []) => {
    if(payments.length > 0){
      setPaymentData({
        category: payments[0].category,
        chargeType: payments[0].chargeType,
        type: payments[0].type,
        userId: payments[0].userId,
        discount: payments[0].percentDiscount
      })
      setCustomPayments(payments
        //descarta los cancelados
        .filter(({status}) => status !== 'canceled')
        .map((pay) => {
        return ({
          ...pay,
          date: moment(pay.date).utc().startOf('month').add(25, 'days').toISOString(),
          amount: pay.amount,
          //status: moment().startOf('month') > moment(pay.date).startOf('month') ? 'paid' : pay.status, //cambio el status para que el payment no se modifique
          status: pay.status,
          realStatus: pay.status,
          isEdit: false,
          isDelete: false,
          isNewPayment: false
        })
      }))
      setCanReset(false)
    }
  }
  
  const createCustomPayment = () => {
    //agrega un elemento a customPayments 
    //el valor del nuevo payment viene de la distribución de valor remanente
    // y tiene fecha de pago un mes despues del ultimo payment
    let lastPayment = customPayments.filter((pay) => !pay.isDelete) 
    lastPayment = lastPayment[lastPayment.length-1]
    const reminderPayments = customPayments.filter(
      (pay) => 
        !pay.isEdit && 
        pay.status === 'pendingPayment' && 
        !pay.isDelete
    )
    const reminder = reminderPayments.reduce((rem, pay) => rem + pay.amount, 0)
    const reminderAmount = reminder/(reminderPayments.length + 1)
    setCustomPayments(
      customPayments
        .map((pay) => {
          if(!pay.isEdit && pay.status === 'pendingPayment') return {...pay, amount: reminderAmount}
          return pay
        })
        .concat([{
          date: moment(lastPayment.date).utc().add(1, 'month').startOf('month').add(25, 'days').toISOString(),
          amount: reminderAmount,
          status: 'pendingPayment',
          type: 'lessonsPayment',
          isEdit: false,
          isDelete: false,
          isNewPayment: true,
        }])
    )
    setCanReset(true)
  }

  const editCustomPayment = (newAmount, newDate, indexPayment) => {
    // setea el nuevovalor y fecha al payment con indice indexPayment
    // distribuye le remanente luego de la actualización entre las cuotas no customizadas ni pagadas
    // ademas reordena las fechas de pago de los payments posteriores al de indice indexPayment
    const reminderPayments = customPayments.filter(
      (pay, index) => 
        (!pay.isEdit && //pago ha sido editado 
        pay.status === 'pendingPayment' && !pay.isDelete) || // pago aun no esta pagado
        (indexPayment === index && pay.isEdit) //el pago ya fue editado antes
    )
    const reminder = reminderPayments.reduce((rem, pay) => rem + pay.amount, 0)
    //const reminderAmount = reminder/(reminderPayments.length)
    const reminderAmount = (reminder-newAmount)/(reminderPayments.length-1)
    setCustomPayments(
      customPayments
        .map((pay, index) => {
          if(indexPayment === index) return {...pay, amount: newAmount, date: newDate, isEdit: true}
          else if (!pay.isEdit && pay.status === 'pendingPayment' && !pay.isDelete) {
            return {
              ...pay, 
              amount: reminderAmount, //no considera el payment recién editado
              isDelete: parseFloat(reminderAmount) === 0.0,
              date: (
                  index > indexPayment 
                ) ? moment(newDate).add(
                  index-(
                    indexPayment + 
                    //no considera los elementos eliminados entre el nuevo elemento y el indice actual
                    customPayments.slice(indexPayment, index).filter((pay) => pay.isDelete).length
                  ), 'months') : pay.date
            }
          } 
          return {
            ...pay, 
            date: 
              (index > indexPayment && !pay.isDelete) 
              ? moment(newDate).add(index-(
                indexPayment + 
                //no considera los elementos eliminados entre el nuevo elemento y el indice actual
                customPayments.slice(indexPayment, index).filter((pay) => pay.isDelete).length
              ), 'months') : pay.date
          }
        })
    )
    setCanReset(true)
  }

  const deletePayment = (indexPayment) => {
    const reminderPayments = customPayments.filter(
      (pay, index) => 
        (
          !pay.isEdit && //pago no ha sido editado 
          pay.status === 'pendingPayment' && // pago aun no esta pagado 
          !pay.isDelete && // no está oculto
          index !== indexPayment // no es el payment eliminado
        )
    )
    const reminder = reminderPayments.reduce((rem, pay) => rem + pay.amount, 0)
    //const reminderAmount = reminder/(reminderPayments.length)
    const reminderAmount = ( reminder + customPayments[indexPayment].amount)/(reminderPayments.length)
    setCustomPayments(
      customPayments
        .reduce(({array, count, start}, pay, index) => {
          if(indexPayment === index) {
            //el pago que se eliminó es el siguiente por pagar
            if (count === 0 && !start) start = pay.date
            array.push({...pay, amount: 0, isDelete: true})
          }
          else if (
            !pay.isEdit && //pago no ha sido editado 
            pay.status === 'pendingPayment' && // pago aun no esta pagado 
            !pay.isDelete // no está oculto
          ) {
            if (count === 0 && !start) start = pay.date
            array.push({
              ...pay, 
              amount: reminderAmount,
              date: moment(start).utc().add(count, 'month').startOf('month').add(25, 'days').toISOString()
            })
            count = count + 1
          }
          else array.push(pay)
          return {array, count, start}
        }, {array: [], count: 0, start: null}).array
    )
    setCanReset(true)
  }

  const paymentsFromApi = useMemo(() => {
    return data?.paymentItem?.planItems?.filter((pay) => pay.type !== "otherFixed") || []
  }, [data])

  //setea la fecha del proximo pago al montar el componente
  useEffect(() => {
    let pendingPaid = paymentsFromApi.filter((pay) => pay.status === 'pendingPayment')
    if(pendingPaid.length > 0) {
      let date = moment(pendingPaid[0].date).utc().startOf('month').add(25, 'days').format('YYYY-MM-DD')
      setForm({...form, paymentstart: date})
      setDateToPayments(date)
    } 
    // eslint-disable-next-line
  }, [paymentsFromApi])

  const otherPaymentsFromApi = useMemo(() => {
    return data?.paymentItem?.planItems?.filter((pay) => pay.type === "otherFixed") || []
  }, [data])
  const [customPayments, setCustomPayments] = useState([])
  useEffect(() => {
    /**
     * isEdit, isDelete, isNewPayment
     * **/
    if( paymentsFromApi.length > 0 ) {
      initCustomPayments(paymentsFromApi)
    }
  }, [paymentsFromApi])

  const reminderPayments = useMemo(() => {
    return customPayments.filter((pay) => !pay.isEdit && pay.status === 'pendingPayment' && !pay.isDelete)
  }, [customPayments])

  // eslint-disable-next-line
  const setDateToPayments = (start) => {
    setCustomPayments(
      customPayments.reduce((obj, pay) => {
        if(!pay.isDelete && pay.status === 'pendingPayment'){
          obj.array.push({
            ...pay, 
            date: moment(start).utc().add( 
              obj.count, 
              'months'
            ).startOf('month').add(25, 'days').toISOString()
          })
          obj.count = obj.count + 1
        }
        else{
          obj.array.push(pay)
        }
        return obj
      }, {array: [], count: 0}).array
    )
    setCanReset(true)
  }
  // useEffect(() => {
  //   if(form?.paymentstart) setDateToPayments(form.paymentstart)
  //   // eslint-disable-next-line
  // }, [form])
  
  const parseToMutation = () => {
    let arrayPayments = customPayments.reduce((obj, pay) => {
    /**
     *[
        {
          paymentId: "60d35a96084bcb7efb4cb4ee"
          userId: "5f92deac586824186078a36c"
          date: "2021-06-23T16:00:23.505+00:00"
          amount: 200
          discount: 0
          description: "Payment registration",
          category: livePrep
          chargeType: studentCharge
          type: otherFixed
          isNewPayment: false
          isEdit: false
          isDelete: false
        },
      ]
     ***/
      if((pay.isDelete && pay?.id) || !pay.isDelete) {
        //se elimina un payment existente
        let newObj = {
          userId: paymentData.userId,
          date: moment(pay.date).utc().startOf('month').add(25, 'days').toISOString(),
          amount: pay.amount,
          discount: paymentData.discount,
          description: pay?.description || `Payment ${obj.corr}`,
          category: paymentData.category,
          chargeType: paymentData.chargeType,
          type: pay.type,
          isNewPayment: pay.isNewPayment,
          isEdit:  pay.isEdit || true,
          isDelete: pay.isDelete
        }
        if(pay?.id) newObj.paymentId = pay.id
        obj.corr = obj.corr + 1
        obj.array.push(newObj)
      }
      return obj
    }, {array: [], corr: 0}).array
    return arrayPayments
  }

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <ModalLayout
        buttonLabel={null}
        className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
          } modal-colored h-auto`}
        isOpen={isOpen}
        toggle={toggle}
        // centerHeaderOverall={`text-title font-weight-bold h2`}
        topHead={
          <span className='flex-grow-1 text-title font-weight-bold h2'>{`${
            translate('payments.editnewpayment')
          }`}
          </span>
        }
        disabledScrollAnimation
      >
        {kind === 'custom-payment-item' && (
          <Form value={formCustom} onChange={setFormCustom}>
            <div className='m-0 p-0 mb-2 pl-0 pr-0'>
              <div className='row d-flex m-0 p-0'>
                <div className='col-12 p-0'>
                  <Field
                    className='col-12 row inline mt-4'
                    type={SelectOption}
                    fieldName='chargeType'
                    options={[
                      { id: 'studentCharge', text: translate('payments.charges.studentCharge') },
                      { id: 'studentRefund', text: translate('payments.charges.studentRefund') }
                    ]}
                    studentCharge
                    StyleOptionClassName={{
                      width: '49%',
                      whiteSpace: 'nowrap'
                    }}
                    StyleSelect={{
                      justifyContent: 'space-between'
                    }}
                  />
                </div>
              </div>
              <div className='row d-flex m-0 p-0 mt-2'>
                <div className='col-12 p-0'>
                  <label className='text-gray font-weight-light mb-2'>Category</label>
                  <Field
                    className='col-12'
                    type={InputSelect}
                    fieldName='category'
                    placeholder={translate('modal.newplan.selectservice')}
                    options={categories && [].concat(categories.map((serv) => ({ value: serv.id, label: translate(`payments.categories.${serv.id}`) })))}
                    InputClassName='w-100'
                    filterOption={(obj, string) => {
                      if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                      return false
                    }}
                  />
                </div>
              </div>
              <div className='row d-flex m-0 p-0 mt-2'>
                <div className='col-12 p-0'>
                  <label className='text-gray font-weight-light mb-2'>Type</label>
                  <Field
                    className='col-12'
                    type={InputSelect}
                    fieldName='type'
                    placeholder={translate('modal.newplan.selectservice')}
                    options={types && [].concat(types.filter(e => ['both', 'payment'].includes(e.type)).map((serv) => ({ value: serv.id, label: translate(`payments.types.${serv.id}`) })))}
                    InputClassName='w-100'
                    filterOption={(obj, string) => {
                      if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                      return false
                    }}
                  />
                </div>
              </div>
              <div className='row d-flex m-0 mt-2 p-0'>
                <div className='col-6 p-0 pr-2'>
                  <label className='text-gray font-weight-light mb-2'>Discount</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={PlainTextInput}
                    fieldName='discount'
                    fieldType='number'
                    placeholder='%  0.00 '
                    min='0'
                  />
                </div>
                <div className='col-6 pl-1 pr-0 pl-2'>
                  <label className='text-gray font-weight-light mb-2'>Amount</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={PlainTextInput}
                    fieldName='amount'
                    fieldType='number'
                    placeholder='$  0.00 '
                    min='0'
                  />
                </div>
              </div>
              <div className='row d-flex m-0 p-0 mt-2'>
                <div className='col-12 p-0'>
                  <label className='text-gray font-weight-light mb-2'>Description</label>
                  <Field
                    type={PlainTextInput}
                    placeholder='Description'
                    fieldName='description'
                    fieldType='text'
                  />
                </div>
              </div>
              <div className='row d-flex m-0 p-0 mt-2'>
                <div className='col-12 p-0'>
                  <label className='text-gray font-weight-light mb-2'>Payment date</label>
                  <Field
                    type={PlainTextInput}
                    fieldName='date'
                    fieldType='date'
                  />
                </div>
              </div>
              <div className='row d-flex m-0 p-0 mt-3'>
                <div className='col-12 p-0 '>
                  <StickyButton>
                    <div className='m-0 p-0 row'>
                      <Button
                        color='primary'
                        activity={loadingCustom}
                        className='col-12 aling-text-left'
                        label={translate('payments.editnewpayment')}
                        onClick={() => { onSubmit('custom') }}
                        style={{ borderRadius: '16px' }}
                        check='yes'
                      />
                    </div>
                  </StickyButton>
                </div>
              </div>
            </div>
          </Form>
        )}
        {kind === 'lesson-payment-item' && (
          <Form value={formLesson} onChange={setFormLesson}>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Amount</label>
                <Field
                  className='col-12 placeholder-to-right'
                  type={PlainTextInput}
                  fieldName='amount'
                  fieldType='number'
                  placeholder='$  0.00 '
                  min='0'
                />
              </div>
              <div className='col-12 p-0 mt-3'>
                <label className='text-gray font-weight-light mb-2'>Description</label>
                <Field
                  className='col-12'
                  type={PlainTextInput}
                  placeholder='Description'
                  fieldName='description'
                  fieldType='text'
                />
              </div>
              <div className='col-12 p-0 mt-3'>
                <label className='text-gray font-weight-light mb-2'>Payment date</label>
                <Field
                  type={PlainTextInput}
                  fieldName='date'
                  fieldType='date'
                />
              </div>
              <div className='col-12 p-0 mt-5'>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loadingLesson}
                      className='col-12 aling-text-left'
                      label={translate('payments.editnewpayment')}
                      onClick={() => { onSubmit('custom') }}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </Form>
        )}
        {kind === 'plan-payment-item' && (
          <div className='px-0'>
            <Form value={form} onChange={setForm}>
              <label className='text-title font-weight-bold h3'>Payment scheduled</label>
              <div className='col-12 p-0 mt-4'>
                <label className='text-gray font-weight-light mb-2'>Next payment date</label>
                <Field
                  type={PlainTextInput}
                  fieldName='paymentstart'
                  fieldType='date'
                  onChange={
                    (e) => {
                      setForm({
                        ...form,
                        paymentstart: e
                      })
                      setDateToPayments(e)
                    }
                  }
                />
              </div>
              {/* <div className='col-12 p-0 mt-3'>
                <div className='d-flex flex-row justify-content-between text-gray text-12'>
                  <label>Payment method</label>
                  <label>Visa 0000</label>
                </div>
              </div> */}
              <div className='w-100 mt-3'>
                <ListItem
                  className='py-2 w-100'
                  showBottomLine={false}
                  showTopLine={false}
                  Left={
                    <label>{translate('modal.newplan.paymentschedule.whenapproving')}</label>
                  }
                  Right={
                    <span className={'d-flex flex-row align-items-center'} style={{cursor: 'not-allowed'}}>
                      {`$${
                        otherPaymentsFromApi 
                          ? formatNumber(otherPaymentsFromApi.reduce((total, pay) => pay.amount + total, 0)) 
                          : '0.00'}`
                      }
                      <IconArrowRight className={'ml-2'} />
                    </span>
                  }
                />
                {customPayments.map((obj, index) => {
                    return (
                      obj.isDelete
                      ? null
                      : <ListItem
                          key={index}
                          className='py-1 w-100'
                          showBottomLine={false}
                          showTopLine={false}
                          Left={
                            <label>
                              <span>
                                {translate('modal.newplan.endof') + ' ' + moment(obj.date).format('MMM')}
                              </span>
                              <span className='text-muted h45'>
                                {
                                obj.status === 'pendingPayment' ?
                                `    (${translate('modal.newplan.indexFee', {
                                  num1: parseInt(
                                    (index + 1) - (customPayments.slice(0, index).filter((pay) => pay.isDelete).length)
                                    ), num2: parseInt(customPayments.filter((pay) => !pay.isDelete).length)
                                })})` :
                                `    (Paid out)`}
                              </span>
                            </label>
                          }
                          Right={
                            // <label>{`${formatNumber(obj.value)}`} <IconArrowRight /></label>
                            obj.status === 'pendingPayment' ?
                            <AddPayment
                              time={moment(obj.date)} value={obj.amount} customPayment={false}
                              // deleteCuota={deletePaymentItem(index)}
                              deleteCuota={() => {
                                if(reminderPayments.length === 1 && (!obj.isEdit && obj.status === 'pendingPayment')) alert("no se puede eliminar")
                                else deletePayment(index)
                              }}
                              // setCuotas={editPaymentItem(index)}
                              setCuotas={(newAmount, newDate) => {
                                editCustomPayment(newAmount, newDate, index)
                              }}
                              // maxValue={totalPay}
                              maxValue={reminderPayments.reduce((rem, pay) => rem + pay.amount, 0) + (obj.isEdit ? obj.amount : 0)}
                              minValue={
                                reminderPayments.length === 1 && (!obj.isEdit && obj.status === 'pendingPayment') // solo queda un payment sin editar (y es este)
                                ? reminderPayments.reduce((rem, pay) => rem + pay.amount, 0)
                                : 0}
                              //statusPlan={statusPlan}
                            >
                              <span className={'d-flex flex-row align-items-center'}>{`${formatNumber(obj.amount)}`} <IconArrowRight className={'ml-2'} /></span>
                            </AddPayment> : 
                            <span className={'d-flex flex-row align-items-center'} style={{cursor: 'not-allowed'}}>{`${formatNumber(obj.amount)}`} <IconArrowRight className={'ml-2'} /></span>
                          }
                        />
                    )
                  }
                  )
                }
                <ListItem
                  className='py-1 w-100'
                  showBottomLine={false}
                  showTopLine={false}
                  Left={
                    <label>
                      <span>
                        Total
                      </span>
                    </label>
                  }
                  Right={
                    <span className={'d-flex flex-row align-items-center'} style={{cursor: 'not-allowed'}}>
                      {`${formatNumber(
                        customPayments.filter((pay) => !pay.isDelete).reduce((total, pay) => {
                          return total + pay.amount
                        }, 0)
                        )}`} 
                    </span>
                  }
                />
                <label
                  className={`w-100 mt-4 ${canAddPayment ? 'btn-link' : 'text-muted'}`} onClick={() => {
                    if (canAddPayment) {
                      createCustomPayment()
                    }
                  }}
                >
                  {translate('modal.newplan.paymentschedule.addmore')}
                </label>
                {/* <label className={`w-100 ${canDistributed ? 'btn-link text-error' : 'text-muted'}`} onClick={canDistributed ? DistributedPayment : null}>
                  {translate('modal.newplan.paymentschedule.distribute')}
                </label> */}
                <label 
                  className={`w-100 ${canReset ? 'btn-link text-error' : 'text-muted'}`} 
                  onClick={
                    () => {
                      if(canReset) initCustomPayments(paymentsFromApi)
                    }
                  }
                  >
                  {translate('modal.newplan.paymentschedule.reset')}
                </label>
              </div>
              <div className='col-12 p-0 mt-5'>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loadingPlan || loading}
                      className='col-12 aling-text-left'
                      label='Save Changes'
                      onClick={() => { 
                        editPlanPayments({
                          paymentInput: {
                            payments: parseToMutation()
                          },
                          planId: data?.paymentItem?.planId || ''
                        })
                        //onSubmit('custom') 
                      }}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </Form>
          </div>
        )}
      </ModalLayout>
    </>
  )
}

export default EditPaymentItem