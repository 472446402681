import { useLazyQuery, gql } from "@apollo/client"

const useGetGroupPlan = () => {
    return useLazyQuery(
        gql`query($planId: ID!){
            getGroupPlan(planId: $planId) {
              id
              name
              cancelDate
              pauseDate
              resumeDate
              studentPlans {
                studentId
                planId
                planStatus
                registerDate
                firstName
                lastName
              }
              serviceId
              lessonIds
              paymentItemIds
              paymentItemsConfig {
                amount
                percentDiscount 
                amountWithoutDiscount
                date
                description
                type
              }
              recommendedEducators {
                educatorUserId
                firstName
                lastName
                rate
                isManualRate
                topicId
                teachingType
                lessonLink
                isWaiveIndividual
                isWaiveGroup
              }
              selectedEducators {
                educatorUserId
                firstName
                lastName
                rate
                isManualRate
                topicId
                lessonLink
                isWaiveIndividual
                isWaiveGroup
              }
              status
              kind
              createdAt
              updatedAt  
              isFirstPlan
              typePlanPricing
              pricingRate
              groupPricingRate
              location {
                type
                linkEducator
                linkStudent
              }
              groupId
              ... on GroupPLan {
                testDate
                PartsSchedule {
                  name
                  from
                  to
                  scheduleType
                  lessonsPerWeekGroup {
                    topicId
                    educatorId
                    day
                    duration
                    hour
                    totalLessons
                  }
                  lessonsPerWeekIndividual {
                    topicId
                    lessonsPerWeek
                    lockLessons
                  }
                }
                studentPlans {
                  studentId
                  planId
                  registerDate
                  planStatus
                }
                lessonGroupIds
              },
              childPlans {
                id
                studentUser {
                  studentUserId
                  firstName
                  lastName
                  rate
                  isManualRate
                }
                kind
                name
                status
                ... on LessonsPerWeekPlan {
                  parts {
                    from
                    to
                    totalLessons
                    name
                    lessonsPerWeek
                    lessonPerWeekPerTopic {
                      topicId
                      lessonsPerWeek
                      lockLessons
                    }
                  }
                }
                ... on LessonsFixedPlan {
                  parts {
                    from
                    tof: to
                    type
                    TotalPerTopic {
                      topicId
                      lessonsPerWeek
                      lockLessons
                      numberFixedLessonsPerWeek
                    }
                  }
                }
              }
            }
        }`,
        {
          fetchPolicy: 'network-only'
        }
    )
}

export default useGetGroupPlan