import React from 'react'

const useColumnsDesktop = () => {
    return [
        { 
            render: item => {
                return (
                    <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                        <span>{`Class of ‘${item?._id}`}</span>
                    </div>
                )
            }, 
            colSpan: 4, 
            id: 'classof' 
        },
        { 
            render: item => {
                return (
                    <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                        <span>{`${item?.users?.length || 0} students`}</span>
                    </div>
                )
            }, 
            className: `text-gray`,
            colSpan: 4, 
            id: 'students' 
        },
    ]
}

export default useColumnsDesktop