export const UniversitiesStatus = [
    'wishlist', 'inProgress', 
    'applied', 'waitlistedDiffered', 
    'admitted', 'rejected', 'withdrawn'
]
export const CompleteUniversitiesStatus = [
    // 'wishlist', 'inProgress', 
    // 'applied', 
    'admitted', 'rejected', 'withdrawn' // 'waitlistedDiffered',
]

// export default {UniversitiesStatus, CompleteUniversitiesStatus}