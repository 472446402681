import axios from 'axios'
const API = axios.create({
  baseURL: process.env.REACT_APP_I18N_URL || 'http://localhost:4000',
  headers: {
    'Content-Type': 'application/json'
  }
})

export const getDict = async (admin = false) => API.get(`/${admin ? '?admin=true' : ''}`)
export const setKey = async (path, newValue, platform = 'myLivePrep') => API.post('/set', { path, newValue, platform })

export default async (admin) => {
  try {
    const { data } = await getDict(admin)
    return data
  } catch (e) {
    return {}
  }
}
