import React from 'react'
import {
    ListItem,

  } from '@Knowledge-OTP/znk-ui-components'
  import moment from 'moment-timezone'
export default function UpdatedAtBy(props) {

    const {educator} = props

  return (
    <ListItem
              showBottomLine
              onClick={
                () => {}
              }
              Left={
                <div className='d-flex flex-row'>
                <span className='ml-1 font-weight-light h4 text-gray'>
                  Last updated on 
                </span>
                <span className='ml-1 font-weight-bold h4 text-gray'>
                 {moment(educator?.educatorInfo?.availabilityUpdated?.updatedAt).format('MMM Do, YY')}
                </span>
                <span className='ml-1 font-weight-light h4 text-gray'>
                  by
                </span>
                <span className='ml-1 font-weight-bold h4 text-gray'>
                  {educator?.educatorInfo?.availabilityUpdated?.updatedBy}
                </span>
              </div>

              }
              Right={
                  null
              }
            />
  )
}
