import React from 'react'
import CreateActivityModal from './Create'
import TestTable from './Test'
import EventTable from './Event'
import useServices from '../../../Model/Services/List'
import EditActivityModal from './Edit'

const ActivitiesView = (props) => {
  const { data: services, loading: servicesLoading } = useServices({ limit: 0 })

  return (
    <div className='d-flex flex-column'>
      <TestTable {...{ services, servicesLoading }} />
      <div className='borderTop my-2' />
      <EventTable />
      <CreateActivityModal {...{ services, servicesLoading }} />
      <EditActivityModal {...{ services, servicesLoading }} />
    </div>
  )
}

export default ActivitiesView
