import React, { useState, useEffect } from 'react'
import {
  Modal,
  IconClose,
  Field,
  Form,
  PlainTextInput,
  StickyButton,
  Button,
  AmountInput
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { gql, useLazyQuery } from '@apollo/client'
import editLessonPaymentItemFromAdmin from './editLessonPaymentItemFromAdmin'
import moment from 'moment'
import { showAlert } from '../../../../common/Alert/util'

const EditLessonPaymentItem = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.editPaymentLesson)
  const { isOpen, paymentId, kind } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.editPaymentLesson',
      data: {
        isOpen: false,
        invoiceId: ''
      }
    })
  }
  const [formLesson, setFormLesson] = useState({
    amount: 0,
    discount: 0,
    description: '',
    date: new Date()
  })
  const [mePayment, { data, refetch: refetchPaymentQuery }] = useLazyQuery(
    gql`query($paymentItemId: ID!){
      paymentItem(paymentItemId: $paymentItemId) {
        ... on PlanPaymentItem {
          planId
          planItems {
            id
            kind
            amount
            amountWithoutDiscount
            generatedDescription
            percentDiscount
            status
            date
            category
            chargeType
            draft
            lastProcessedAt
            description
            type
            userId
            planId
          }
        }
        ... on LessonPaymentItem {
          id
          kind
          amount
          amountWithoutDiscount
          percentDiscount
          status
          date
          category
          draft
          lastProcessedAt
          description
          chargeType
          generatedDescription
          type
          userId
        }
        ... on EducatorPaymentItem {
          lessonId
          id
          amount
          amountWithoutDiscount
          percentDiscount
          status
          date
          category
          draft
          chargeType
          lastProcessedAt
          description
          type
          userId
        }
        ... on CustomPaymentItem {
          id
          amount
          amountWithoutDiscount
          percentDiscount
          chargeType
          status
          date
          category
          draft
          lastProcessedAt
          description
          chargeType
          type
          userId
        }
      }
    }`,
    {
      variables: { paymentItemId: paymentId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (!paymentId) return
      await mePayment()
    })()
  }, [paymentId, mePayment])
  useEffect(() => {
    if (data) {
        setFormLesson({
          amount: data.paymentItem.amount,
          description: data.paymentItem.generatedDescription ?? data.paymentItem.description,
          date: moment(data.paymentItem.date.split('T')[0]).format('YYYY-MM-DD'),
          chargeType: data.paymentItem.chargeType,
          category: data.paymentItem.category,
          type: data.paymentItem.type,
          discount: data.paymentItem.percentDiscount,
          userId: data.paymentItem.userId
        })
    }
  }, [data, kind])
 
  const { submit: submitLesson, loading: loadingLesson } = editLessonPaymentItemFromAdmin(
    () => {
        showAlert({
            text: "Success",
            status: 'success'
        }, dispatch)
        refetchPaymentQuery()
        refetch()
        toggle()
    }, 
    (error) => {
        showAlert({
            text: "¡Error!",
            status: 'error'
        }, dispatch)
        console.error(error)
    }
  )
  const onSubmit = () => {
    submitLesson({ paymentItemId: paymentId, paymentInput: formLesson })
  }
  
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('payments.editnewpayment')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={formLesson} onChange={setFormLesson}>
            <div className='row d-flex m-0 mx-4 p-0 mt-2'>
                <div className='col-12 p-0'>
                    <label className='text-gray font-weight-light mb-2'>Amount</label>
                    <AmountInput
                        className='col-12 placeholder-to-right'
                        placeholder={translate('modal.newplan.pricingplan.amount')} 
                        type='number' 
                        value={parseFloat(parseFloat(formLesson.amount).toFixed(2))} 
                        onChange={(value) => {
                            setFormLesson({
                                ...formLesson,
                                amount: parseFloat(value)
                            })
                        }} 
                        step={0.05} 
                        min={0}
                    />{/**
                    <Field
                        className='col-12 placeholder-to-right'
                        type={AmountInput}
                        fieldName='amount'
                        fieldType='number'
                        placeholder='$  0.00 '
                        min='0'
                    />**/}
                </div>
                <div className='col-12 p-0 mt-3'>
                    <label className='text-gray font-weight-light mb-2'>Description</label>
                    <Field
                        className='col-12'
                        type={PlainTextInput}
                        placeholder='Description'
                        fieldName='description'
                        fieldType='text'
                    />
                </div>
                <div className='col-12 p-0 mt-3'>
                    <label className='text-gray font-weight-light mb-2'>Payment date</label>
                    <Field
                        type={PlainTextInput}
                        fieldName='date'
                        fieldType='date'
                    />
                </div>
                <div className='col-12 p-0 mt-5'>
                    <StickyButton>
                        <div className='m-0 p-0 row'>
                        <Button
                            color='primary'
                            activity={loadingLesson}
                            className='col-12 aling-text-left'
                            label={translate('payments.editnewpayment')}
                            onClick={() => { onSubmit('custom') }}
                            style={{ borderRadius: '16px' }}
                            check='yes'
                        />
                        </div>
                    </StickyButton>
                </div>
            </div>
        </Form>
      </Modal>
    </>
  )
}

export default EditLessonPaymentItem
