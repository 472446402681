import React, { useEffect } from 'react'
import { Activity, ListItem } from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useTranslation from '../../../../../../i18n/useTranslation'
import { gql, useLazyQuery } from '@apollo/client'

const ChildPlanPaymentSchedule = (props) => {
  const { info } = props
  const { translate } = useTranslation()
  const [getPayerUser, { data, loading }] = useLazyQuery(
    gql`query ($userId: String!) {
        getPayerUser(
            userId: $userId
        ){
            gender
            emails {
                address
            }
            firstName
            lastName
            paymentMethod {
                kind
                ... on ManualPaymentMethod {
                    kind
                }
                ... on CardPaymentMethod{
                    kind
                    status
                    last4
                    brand
                    expMonth
                    expYear
                }
                ... on ZellePaymentMethod{
                  zellePaymentInfo {
                    emailToSend
                  }
              }
            }
        }
    }`
  )
  useEffect(() => {
    if (info.studentUser) {
      const { studentUserId } = info.studentUser
      getPayerUser({ variables: { userId: studentUserId } })
    }
  }, [info.studentUser, getPayerUser])
  const paymentMethod = data && data.getPayerUser ? data.getPayerUser.paymentMethod : null
  return (
    <div className='py-4'>
      <label className='h3 mb-4 font-weight-bold mb-4 mt-2'>{translate('modal.titles.paymentschedule')}</label>
      {
        loading
          ? (<Activity />)
          : paymentMethod ? (
            <ListItem
              className='m-0 py-1 mb-4'
              Left={
                <small className='text-gray'>{translate('modal.titles.paymentmethod')}</small>
              }
              Right={
                <small className='text-gray'>
                  {
                    paymentMethod.__typename === 'CardPaymentMethod'
                      ? `${paymentMethod.brand} ${paymentMethod.last4}`
                      : `${paymentMethod.kind}`
                  }
                </small>
              }
            />
          ) : null
      }
      <ListItem
        className='m-0 p-0 w-100 py-1'
        Left={
          <label className='text-gray'>{translate('modal.newplan.paymentschedule.whenapproving')}</label>
        }
        Right={
          <label>{`$${
            info.paymentItemsConfig.filter((pay) => pay.type === 'others-fixed').reduce((accuPay, pay) => {
              return accuPay + pay.amount
            }, 0).toFixed(2)}`}
          </label>
        }
      />
      {info.paymentItemsConfig
        ? info.paymentItemsConfig.filter((pay) => pay.type !== 'others-fixed').map((pay, index) => {
          return (
            <ListItem
              showBottomLine={info.paymentItemsConfig.filter((pay) => pay.type === 'lessons-payment').length - 1 === index}
              className={`m-0 p-0 ${info.paymentItemsConfig.filter((pay) => pay.type === 'lessons-payment').length - 1 === index ? 'mb-2' : ''}`}
              Left={
                <label className='text-gray'>{translate('profile.payments.endOf', moment(pay.date).format('MMM'))}</label>
              }
              Right={
                <label>{`$${pay.amount.toFixed(2)}`}</label>
              }
            />
          )
        })
        : null}
    </div>
  )
}

export default ChildPlanPaymentSchedule
