export const showAlert = ({text, status, onClick}, dispatch) => {
    let id = new Date().getTime()
    dispatch({
        type: 'ADD_ALERT',
        payload: {id, text, status, onClick}
    })
    setTimeout(() => {
        dispatch({
            type: 'REMOVE_ALERT',
            payload: {id}
        })  
    }, 5000)
}

export default {
    showAlert
}