import {gql, useMutation} from '@apollo/client'

const useNewPopulateGoogleCalendar = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $lessonId: String
                $endDate: DateTime
                $startDate: DateTime
                $sendOneLesson: Boolean
                $populateCalendar: Boolean
                $cleanCalendar: Boolean
                $createCalendar: Boolean
                $userId: String!
            ) {
                newPopulateGoogleCalendar (
                    lessonId: $lessonId
                    endDate: $endDate
                    startDate: $startDate
                    sendOneLesson: $sendOneLesson
                    populateCalendar: $populateCalendar
                    cleanCalendar: $cleanCalendar
                    createCalendar: $createCalendar
                    userId: $userId
                )
            }
        `, {onCompleted, onError}
    )
}

export default useNewPopulateGoogleCalendar