import {useSelector, useDispatch} from 'react-redux'

const useDismissPlan = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.dashboard.dismissTimelinePLan)
    const {isOpen} = reduxData
    const setData = (isOpen = false, onChange = () => {}, plan = {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'dashboard.dismissTimelinePLan',
            data: {
                ...reduxData,
                isOpen,
                onChange,
                plan
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, plan = {}) => {
            setData(true, onChange, plan)
        },
        close: () => {setData(false)},
        toggle: () => {setData(!isOpen)},
    }
}

export default useDismissPlan