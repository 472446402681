export const ordenateUserArray = (array) => {
    if (array?.length < 1) return
    return array?.map((stu) => {
      return {
        name: `${stu.firstName} ${stu.lastName}`,
        id: stu.id,
        avatar: stu.avatar
      }
    }).sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })
  }