import React, { useState, useEffect, useMemo } from 'react'
import {
    ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import { nanoid } from 'nanoid'
import moment from 'moment'

const LessonCountdownRow = (props) => {
    const {lesson = {}, hiddenLeftCount = false, optionSection = <></>} = props
    const tooltipId = nanoid()
    const [percent, setPercent] = useState(0)
    const [second, setSecond] = useState(0)
    const {
        totalDurationSeconds,
    } = useMemo(
        () => {
            return {
                totalDurationSeconds: Math.abs(moment.duration(moment(lesson.startDate).diff(lesson.endDate)).asSeconds())
            }
        }, [lesson]
    )
    useEffect(() => {
        if (lesson.startDate && lesson.endDate) {
            const totalDuration = Math.abs(moment.duration(moment(lesson.startDate).diff(moment(lesson.endDate))).asSeconds())
            const currentSeconds = Math.abs(moment.duration(moment(lesson.startDate).diff(moment())).asSeconds())
            const newPercent = Math.floor((currentSeconds / totalDuration) * 100)

            const timer = setTimeout(() => {
                setPercent(newPercent)
                setSecond(currentSeconds)
            }, 1000)
            return () => { clearTimeout(timer) }
        }
        //eslint-disable-next-line
    }, [percent, second])
    return (
        <div className='lessonCardFooter px-3 py-2 d-flex flex-row justify-content-between'>
                    <ToolTipInfo
                        className={`d-flex flex-row align-items-center ${hiddenLeftCount ? 'w-80' : 'w-90'}`}
                        tooltipId={tooltipId}
                        innerElement={<span className={`text-white`}>{`${moment(lesson.startDate).format('DDo MMMM, hh:mma')} - ${moment(lesson.endDate).format('hh:mma')}`}</span>}
                    >
                        <div className={`d-flex flex-row justify-content-between align-items-center w-100`}>
                            {
                                !hiddenLeftCount && (
                                    <span className={`h45 text-white w-20 text-center`}>{moment().startOf('day').add(second, 'seconds').format('mm:ss')}</span>
                                )
                            }
                            <div className={`progress-lesson-bar animation-bar ${hiddenLeftCount ? 'w-80' : 'w-60'} mx-1`}><div style={{ width: `${percent}%` }} /></div>
                            <span className={`h45 text-white w-20 text-center`}>-{moment().startOf('day').add(totalDurationSeconds - second, 'seconds').format('mm:ss')}</span>
                        </div>
                    </ToolTipInfo>
                    <div className={`d-flex flex-row justify-content-end align-items-center ${hiddenLeftCount ? 'w-20' : 'w-10'}`}>
                        {optionSection}
                    </div>
                </div>
    )
}

export default LessonCountdownRow