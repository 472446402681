const constraints = {
  'input.userId': {
    presence: {
      allowEmpty: false,
      message: '^Please enter a name'
    }
  },
  'input.payslipDate': {
    presence: {
      allowEmpty: false,
      message: '^Please enter a name'
    }
  },
  'input.status': {
    presence: {
      allowEmpty: false
    }
  }
}

export default constraints
