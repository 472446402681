import React from 'react'
import {
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'


const NotSendMessage = (props) => {


  return (
    <div style={{position: 'sticky', bottom: '10px', backgroundColor: 'white'}}>
          <InputGroup>
            <Input
              className='znk-input trans'
              placeholder='Chat available only for counselors'
              onChange={() => {}}
              value={null}
              disabled={true}
            />
            <InputGroupAddon addonType='prepend' className='icon'>
              <InputGroupText type='text' className='znk-no-border'>
                  <span
                    className='text-gray'
                    onClick={() => {}}
                    style={{cursor: 'not-allowed'}}
                  >
                    Send
                  </span>
        
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>


    </div>
  )
}

export default NotSendMessage
