import React from 'react'
import SentPlanCron from './SentPlanCron'

const StatusField = (props) => {
  const { status, spanClassName = '', label = null, plan = {} } = props
  let statusClass
  switch (String(status).toLowerCase()) {
    case 'draft':
      statusClass = 'gray'
      break
    case 'sent':
      statusClass = 'error'
      break
    case 'scheduled':
      statusClass = 'warning'
      break
    case 'paid':
      statusClass = 'success'
      break
    case 'cancel':
      statusClass = 'logo'
      break
    case 'pause':
      statusClass = 'gray'
      break
    case 'completed':
      statusClass = 'success-alt'
      break
    case 'application':
        statusClass = 'inProgress'
        break
    case 'post application':
        statusClass = 'primary-alt'
        break
    case 'pre application':
      statusClass = 'warning-alt'
      break
    case 'finished':
      statusClass = 'success'
      break
    default:
      statusClass = 'warning'
      break
  }
  return (
    <>
      {['draft', 'sent'].includes(status?.toLowerCase()) && (<SentPlanCron plan={plan} className={'mr-2'}/>)}
      <span style={{textTransform: 'capitalize'}} className={`d-md-flex align-items-center font-weight-light justify-content-end ${spanClassName}`}>
        {label || (status === 'finished' ? 'Completed' : status)}
      </span>
      <span className={`mx-0 mr-2 mx-xl-0 ml-xl-2 ml-2 badge badge-pill point badge-${statusClass}`}>&nbsp;</span>
    </>
  )
}

export default StatusField
