import { gql, useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import useExcelDownload from './useExcelDownload'
import counselingPlanQueryResult from '../../../Pages/CounselingPlans/Model/queryResults/planQueryResult'
const useDownLoadExcelPlans = () => {
    const exportToCSV = useExcelDownload()
  return useLazyQuery(
    gql`query (
        $sortField: String,
        $previous: String,
        $next: String,
        $limit: Int,
        $to: DateTime,
        $from: DateTime,
        $status: [PlanStatus!]!,
        $studentsUserIds: [ID!],
        $serviceIds: [ID!],
        $kind: [PlanKind!]!,
        $topicIds: [ID!]
        $educatorUserIds: [ID!]
        $countryIds: [ID!]
        $lessonsPending: Int
        $endDateFilter: [DateTime!]
        $startDateFilter: [DateTime!]
        $finishSoon: Boolean
        $lastApproved: Boolean
        $previouslySent: Boolean
        $shortRecenlySent: Boolean
        $testDate: DateTime
        ){
      plans (
        sortField: $sortField,
        previous: $previous,
        next: $next,
        limit: $limit,
        to: $to,
        from: $from,
        status: $status,
        studentsUserIds: $studentsUserIds,
        serviceIds: $serviceIds,
        kind: $kind,
        topicIds: $topicIds
        educatorUserIds: $educatorUserIds
        countryIds: $countryIds
        lessonsPending: $lessonsPending
        endDateFilter: $endDateFilter
        startDateFilter: $startDateFilter
        finishSoon: $finishSoon
        lastApproved: $lastApproved
        previouslySent: $previouslySent
        shortRecenlySent: $shortRecenlySent
        testDate: $testDate
      ){
        hasNext
        hasPrevious
        totalDocs
        docs {
          ... on CouncelingPlan {
            ${counselingPlanQueryResult}
          }
          id
          name
          cancelDate
          pauseDate
          resumeDate
          studentUser {
            studentUserId
            firstName
            lastName
            rate
            isManualRate
          }
          serviceId
          lessonIds
          paymentItemIds
          paymentItemsConfig {
            amount
            percentDiscount 
            amountWithoutDiscount
            date
            description
            type
          }
          status
          kind
          createdAt
          updatedAt  
          isFirstPlan
          typePlanPricing
          pricingRate
          groupPricingRate
          groupId
          from
          to
          ... on GroupPLan {
          studentPlans {
            studentId
            planId
            registerDate
            planStatus
          }
          lessonGroupIds
          childPlans {
            id
            name
            cancelDate
            pauseDate
            resumeDate
            studentUser {
              studentUserId
              firstName
              lastName
              rate
              isManualRate
            }
            serviceId
            lessonIds
            paymentItemIds
            paymentItemsConfig {
              amount
              percentDiscount 
              amountWithoutDiscount
              date
              description
              type
            }
            status
            kind
            createdAt
            updatedAt  
            isFirstPlan
            typePlanPricing
            pricingRate
            groupPricingRate
            location {
              type
            }
            groupId
            from
            to
            testDate
            ... on LessonsPerWeekPlan {
              testDate
            }          
          }
          }
          ... on LessonsPerWeekPlan {
            testDate
          }
        }
      }
    }`,
    {
        fetchPolicy: 'no-cache',
        onCompleted: ({plans}) => {
            exportToCSV(
                plans?.docs?.map((plan) => {
                    const {
                        studentUser,
                        name,
                        status,
                        paymentItemsConfig,
                        createdAt,
                    } = plan

                    return {
                        student: `${studentUser?.firstName} ${studentUser?.lastName}`,
                        planName: name,
                        status,
                        totalAmount: paymentItemsConfig?.map(({amount}) => (amount)).reduce((a, b) => a + b, 0),
                        createdAt: moment(createdAt).startOf('day').fromNow(),
                    }
                }), 
                "Plans"
            )
        }
    }
  )
}

export default useDownLoadExcelPlans