import React, { useEffect, useMemo } from 'react'
import { CheckboxSelector, Avatar, IconArrowDown, CheckboxSelectorInner } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import { Badge } from 'reactstrap'
import {gql, useLazyQuery} from '@apollo/client' 

const ParentsFilter = (props) => {
  const { translate } = useTranslation()
  const { studentFilters, setStudentFilters, loading = false, useInner = false } = props

  const [getParents, { data: dataParent, loading: loadingParent }] = useLazyQuery(
    gql`query($roles: [Roles!], $search: String, $limit: Int) {
        allUsers(roles: $roles, search: $search, limit: $limit) {
            hasNext
            hasPrevious
            next
            previous
            totalDocs
            docs {
                id
                firstName
                lastName
            }
        }
    }
    `,
    {
      variables: { limit: 0, roles: ['PARENT'], search: '' }
    }
  )

  useEffect(
    () => {
      getParents()
    }, [getParents]
  )
  const students = useMemo(() => {
    return dataParent?.allUsers?.docs || []
  }, [dataParent])

  const checkboxSelectorProps = {
    setDataFilters: setStudentFilters,
    direction: 'bottomRight',
    dataFilters: studentFilters,
    loading: loadingParent || loading,
    data: students.map(user => ({
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,
      Component: (
        <Avatar
          name={`${user.firstName} ${user.lastName}`}
          named={false}
          uri={null}
          size='avatar-small'
        />
      )
    })).sort((a, b) => a.name.localeCompare(b.name)),
    typeFilter: 'users',
    strings: {
      viewAll: translate('filters.plans.viewAll'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
      search: translate('filters.plans.search')
    }
  }

  return (
    useInner ? 
    <CheckboxSelectorInner style={{maxWidth: 'auto'}} {...checkboxSelectorProps}/> :
    <CheckboxSelector {...checkboxSelectorProps}>
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.payments.parentTitle')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default ParentsFilter
