import {useMutation, gql} from '@apollo/client'

export const UseAppendCourseMutation = () => {
    return useMutation(
        gql`
            mutation (
                $courseMaterial: CourseMaterialInput!
                $userStudentId: ID
            ) {
                appendCourseMaterials (
                    courseMaterial: $courseMaterial
                    userStudentId: $userStudentId
                )
            }
        `
    )
}