import React from 'react';
import useColumns from './Columns/useColumns';

const PlanListItem = (props) => {
    const {
        className = '',
        header = false,
        onSort = () => {}, 
        loading = false,
        sortData = {order: true, sort: undefined},
        refetch = () => {},
        ...plan
    } = props
    const {Header, Body, Skeleton} = useColumns()
    return (
        <>
            <div className={`w-100 d-flex flex-row align-items-center w-100 px-6 py-4 ${className}`}>
                {
                    header ? 
                        <Header onSort={onSort} sort={sortData.sort} order={sortData.order}/>
                    : 
                    loading ? 
                    <>
                        <Skeleton />
                    </> :
                    <>
                       <Body
                        refetch={refetch}
                        plan={plan}/>
                    </>
                }
                
                
            </div>
        </>
    )
}

export default PlanListItem