import React, { useEffect, useState, useMemo } from 'react'
import {
  ModalScroll,
  CalendarScheduled,
  ListItem,
  IconCountry,
  IconProgram,
  IconArrowDown,
  IconCircleNext,
  IconCircleBack,
  IconArrowRight,
  Button
} from '@Knowledge-OTP/znk-ui-components'
import { useSelector, useDispatch } from 'react-redux'
import LessonIcons from './LessonIcons'
import useTranslation from '../../../../i18n/useTranslation'
import myFreeTimeFrames from '../../../../Model/CalendarEvent/myFreeTimeFrames'
import CollisionDetails from './CollisionDetails'
import moment from 'moment-timezone'

const ScheduledConflict = (props) => {
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const dataConflictRedux = useSelector((state) => state.modal.lesson.scheduledConflict)
  const {
    isOpen,
    ocurrences,
    indexOcurrence,
    callBack,
    loading: loadMutation
  } = dataConflictRedux
  const {
    collissionKinds,
    collissionKindsDetails,
    topic,
    dataStudent,
    dataEducator,
    lessonDuration,
    isGroup,
    old,
    recurringId
  } = useMemo(() => {
    if (ocurrences[indexOcurrence]) {
      
      return ocurrences[indexOcurrence]
    }
    return {}
  }, [indexOcurrence, ocurrences])

  //Fija la primera lección por agendar determinada por la primera ocurrencia sin resolución
  useEffect(() => {
    if(ocurrences?.length > 0 && isOpen) {
      const defaultIndex = ocurrences?.findIndex(({collissionKinds}) => collissionKinds?.length > 0)
      if(defaultIndex >= 0) {
        setNewTimeOfOcurrence(null, defaultIndex, false, null, false)
      }
    }
    // eslint-disable-next-line
  }, [isOpen])

  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'lesson.scheduledConflict',
      data: {
        ...dataConflictRedux,
        isOpen: !isOpen
      }
    })
  }
  const zone = useSelector((state) => state.timezone.zone)

  const [fromState, setFromState] = useState(moment(old).tz(zone))
  useEffect(() => { 
    
    setFromState(moment(old).tz(zone)) 
  }, [ocurrences, zone, indexOcurrence, old])

  useEffect(() => { 
    setFromState(fromState.tz(zone)) 
  // eslint-disable-next-line
  }, [/**fromState,**/ zone])

  const nextWeek = () => { setFromState(fromState.clone().add(1, 'week')) }
  const previusWeek = () => { setFromState(fromState.clone().subtract(1, 'week')) }
  const resetWeek = () => { setFromState(moment(old)) }

  const minDurationInMinutes = useMemo(() => {
    if(lessonDuration > 60) return 60
    return lessonDuration
  }, [lessonDuration])
  
  const { data: freeTimes, loading, refetch: refetchFreeTime } = myFreeTimeFrames({
    involveMyself: false,
    from: fromState.clone().startOf('isoWeek').toISOString(),
    to: fromState.clone().endOf('isoWeek').toISOString(),
    educatorUserId: dataEducator?.id,
    studentIds: dataStudent?.map((stu) => stu?.id),
    minDurationInMinutes
  }, {fetchPolicy: 'no-cache'})
  
  const RenderHeader = ({ className }) => {
    return (
      <div className={`d-flex flex-row align-items-center w-100 ${className}`}>
        <div className='d-flex flex-column w-100'>
          <div className='d-flex flex-row'>
            <LessonIcons
              manual
              idTopic={topic?.id}
              data={
                ocurrences.map(
                  (ocu, index) => {
                    return {
                      ...ocu,
                      lesson: topic?.name,
                      scheduled: ocu.new !== null,
                      idTopic: topic?.id,
                      onClick: () => {
                        setNewTimeOfOcurrence(null, index, false, null, false)
                      }
                    }
                  }
              )
              }
            />
          </div>
          <span className='text-title font-weight-bold mb-2'>
            {translate('modal.lesson.scheduleConflict.chooseDateTopic', { topic: topic?.name })}
          </span>
          {
            collissionKindsDetails?.length > 0 ? 
            <>
              {
                collissionKindsDetails.map((coll) => {
                  // const {collissionKinds, user, lessons} = coll
                  return (
                    <CollisionDetails {...coll}/>
                  )
                })
              }
            </> :
            <>
              {
                collissionKinds?.includes('studentLesson') &&
                  <span className='h45 text-error'>{translate('modal.lesson.scheduleConflict.studentNotAvailable')}</span>
              }
              {
                collissionKinds?.includes('educatorLesson') &&
                  <span className='h45 text-error'>{translate('modal.lesson.scheduleConflict.educatorNotAvailable')}</span>
              } 
              {
                collissionKinds?.includes('educatorUnavailability') &&
                  <span className='h45 text-error'>Educator no available [by availability calendar]</span>
              }
            </>
          }
          {/** ARREGLAR - FALTA UN colissionKinds **/}
        </div>
      </div>
    )
  }

  const setNewTimeOfOcurrence = (newTime, indexOcu, next, newResolve, finish) => {
    const newIndex = ocurrences.reduce(
      (newIndx, ocu, index) => {
        if (
          newIndx === -1 &&
                    ((index === ocurrences.length - 1) ||
                    (index !== indexOcu && ocu.new === null))
        ) return index
        return newIndx
      }, -1)
    const newDataConflictRedux = {
      ...dataConflictRedux,
      ocurrences: ocurrences.map((ocu, index) => {
        if (indexOcu === index) return { ...ocu, new: newTime, resolve: newResolve }
        return ocu
      }),
      indexOcurrence:
              (indexOcu === ocurrences.length - 1)
                ? indexOcu
                : (next ? newIndex : indexOcu)
    }
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'lesson.scheduledConflict',
      data: newDataConflictRedux
    })
    if (finish) callBack(newDataConflictRedux)
  }

  return (
    <ModalScroll
      isOpen={isOpen}
      toggle={toggle}
      topOffSet={60}
      className='modal-colored modal-centered fixed-head-modal white-modal'
      MinHead={
        <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between px-5'>
          <RenderHeader className='p-4' />
          {/** <IconClose className={'position-absolute'} style={{top: '25px', right: '20px'}} color='#D7E1EA' onClick={toggle} />**/}
        </div>
      }
      Head={
        <div className='modal-header pl-7 pb-4 pt-4' style={{ zIndex: 100 }}>
          <RenderHeader />
          {/** <IconClose className={'position-absolute'} style={{top: '25px', right: '20px'}} color='#D7E1EA' onClick={toggle} />**/}
        </div>
      }
    >
      <div className='m-0 p-0 d-flex flex-column px-4 mb-4 pt-0'>
        <div className='ml-6 mr-3 mb-3'>
          <ListItem
            showBottomLine={false}
            showTopLine
            Left={
              <div className='d-flex flex-row align-items-center'><IconCountry className='mr-2' /><span>{zone}</span></div>
            }
            Right={
              <div
                className='hover-icon m-0 py-1 px-2'
                onClick={() => {
                  dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'lesson.changeTimezoneModal',
                    data: {
                      isOpen: true
                    }
                  })
                }}
              ><IconArrowDown />
              </div>
            }
          />
          <ListItem
            showBottomLine
            showTopLine
            Left={
              <div className='d-flex flex-row align-items-center'>
                <IconProgram className='mr-2' />
                <span style={{ textTransform: 'capitalize' }}>{fromState.clone().format('MMMM, YYYY')}</span>
                {
                  moment(old).clone().format('YYYY-MM-DD') !== fromState.clone().format('YYYY-MM-DD') &&
                                    (<span className='ml-1 h45 btn-link text-gray' onClick={resetWeek}>Reset</span>)
                }
              </div>
            }
            Right={
              <div className='d-flex flex-row m-0 p-0'>
                <div className='hover-icon m-0 py-1 px-2' onClick={previusWeek}><IconCircleBack /></div>
                <div className='hover-icon m-0 py-1 px-2' onClick={nextWeek}><IconCircleNext /></div>
              </div>
            }
          />
        </div>
        <CalendarScheduled
          selectedBlock={null}
          setSelectedBlock={(momentSelected) => {
            setNewTimeOfOcurrence(momentSelected?.toISOString(), indexOcurrence, true, 'newBlock', false)
          }}
          from={fromState.clone().toISOString()}
          zone={zone}
          myFreeTimeFrames={freeTimes}
          loading={loading}
          checked={ocurrences.map((ocu) => ocu.new)}
          className='pl-3 pt-6 pb-0 pr-0'
        />
        <div className='ml-6 mr-3 mb-3'>
          <Button
            disabled={false}
            activity={false}
            onClick={nextWeek}
            className='w-100 my-2'
            color='input'
            size='small'
            label={
              <div className='d-flex flex-row justify-content-between align-items-center m-0'>
                <span className='h45' style={{ color: '#7079FF' }}>{translate('modal.lesson.scheduleConflict.goNextWeek')}</span>
                <IconArrowRight size={15} color='#7079FF' />
              </div>
            }
          />
          <div className='borderTop' />
          {
            // se muestra solamnete cuando no es grupal
            !isGroup && (
              <Button
                disabled={false}
                activity={false}
                onClick={() => {
                  setNewTimeOfOcurrence(ocurrences[indexOcurrence].old, indexOcurrence, true, 'setPending', recurringId === null)
                }}
                className='w-100 my-2'
                color='input'
                size='small'
                label={
                  <div className='d-flex flex-row justify-content-between align-items-center m-0'>
                    <span className='h45' style={{ color: '#7079FF' }}>{translate('modal.lesson.scheduleConflict.setLessonPending')}</span>
                    <IconArrowRight size={15} color='#7079FF' />
                  </div>
                }
              />
            )
          }
          {
            (collissionKinds?.includes('educatorUnavailability') && collissionKinds.length === 1) &&
              <Button
                disabled={false}
                activity={false}
                onClick={() => {
                  setNewTimeOfOcurrence(ocurrences[indexOcurrence].old, indexOcurrence, true, 'force', recurringId === null)
                }}
                className='w-100 my-2'
                color='input'
                size='small'
                label={
                  <div className='d-flex flex-row justify-content-between align-items-center m-0'>
                    <span className='h45' style={{ color: '#7079FF' }}>{translate('modal.lesson.scheduleConflict.scheduledAnyway')}</span>
                    <IconArrowRight size={15} color='#7079FF' />
                  </div>
                }
              />
          }
          <Button
            disabled={
              ocurrences.reduce((disabled, { new: newTime, resolve: currentResolve }) => {
                if (newTime === null || currentResolve === null) return true
                return disabled
              }, false)
            }
            activity={loadMutation}
            onClick={() => { 
              refetchFreeTime()
              callBack(dataConflictRedux) 
            }}
            color='primary'
            className='w-100 mb-0 mt-2'
            size='large'
            label={
              <div className='d-flex flex-row justify-content-between'>
                <span>{translate('modal.lesson.scheduleConflict.createLesson')}</span>
              </div>
            }
          />
        </div>
      </div>
    </ModalScroll>
  )
}
export default ScheduledConflict
