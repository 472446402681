import React from 'react'
import SearchBox from './SearchBox'
import useSetServiceRateToCountry from '../../../../../../Model/Countries/SetServiceRateToCountry'
import ServiceRate from './ServiceRate'

const StudentServiceSelector = (props) => {
  const { countryId, services, onSuccess } = props
  const { submit: setSubmit, loading: setLoading } = useSetServiceRateToCountry(onSuccess, console.log)
  const addService = (countryServiceRateInput) => {
    setSubmit({ countryServiceRateInput, countryId })
  }

  function translate(str) {
    return str
  }

  return (
    <span className='d-flex flex-column'>
      <span className='d-flex flex-column'>
        <SearchBox addItem={addService} loading={setLoading} selectedValues={services} />
      </span>
      <span className='d-flex flex-column'>
        <span>{translate('Current Service rates')}</span>
        {
          services.map((serviceRate) => {
            const { serviceId, defaultRate, name } = serviceRate
            return (
              <ServiceRate key={serviceId} {...{ serviceId, defaultRate, name, countryId }} onSuccess={onSuccess} />
            )
          })
        }
      </span>
    </span>
  )
}

export default StudentServiceSelector
