import React, {useState} from 'react'
import useTranslation from '../../../../../i18n/useTranslation'
import {Collapse} from 'reactstrap'

const CollisionDetails = (props) => {
    const {collissionKinds = [], lessons = [], user = null} = props
    const {translate} = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const toggle = () => {setOpen(!isOpen)}
    return (
        <div className={`d-flex flex-column m-0 p-0`}>
          <div className={`d-flex flex-column m-0 p-0 cursor-pointer`} onClick={toggle}>
            {
              collissionKinds?.includes('studentLesson') &&
                <span className='h45 btn-link text-error'>{translate('modal.lesson.scheduleConflict.studentNotAvailable')}</span>
            }
            {
              collissionKinds?.includes('educatorLesson') &&
                <span className='h45 btn-link text-error'>{translate('modal.lesson.scheduleConflict.educatorNotAvailable')}</span>
            } 
            {
              collissionKinds?.includes('educatorUnavailability') &&
                <span className='h45 btn-link text-error'>{translate('modal.lesson.scheduleConflict.educatorNotAvailableByCalendar') || 'Educator no available [by availability calendar]'}</span>
            }
          </div>
          <Collapse isOpen={isOpen}>
                <div className={`d-flex flex-column m-0 pl-2 pr-0 py-0`}>
                    {
                        lessons.map(({id, kind}) => {
                            if(kind === 'educator-unavailability') return (
                              <label className={`h45 text-info`}>
                                {translate('modal.lesson.scheduleConflict.adjustEducatorAvailable') || 'Adjust educator availability calendar'}
                              </label>
                            )
                            return (
                            <label className={`h45 text-info`}>
                                <span 
                                  className={`h45 text-primary btn-link`} 
                                  onClick={() => {
                                     window.open(`${process.env.ADMIN_URL || 'http://admin.zinkerz.com'}/calendar?lessonId=${id}`, '_blank');
                                  }}>{translate('modal.lesson.scheduleConflict.thisLesson') || 'This lesson'}</span>{` ${translate('modal.lesson.scheduleConflict.isGeneratingConflicts') || 'is generating conflicts by'} `} <span className={`h45 text-info`}>{`${user?.firstName} ${user.lastName}`}</span>
                            </label>
                            )  
                        })
                    }
                </div>
          </Collapse>
        </div>
    )
}

export default CollisionDetails