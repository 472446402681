import {useMutation, gql} from '@apollo/client'

const useDeleteTimeline = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $timelineId: ID!
                ) {
                deleteTimeline (
                    timelineId: $timelineId
                )
            }
        `,
        {
            onCompleted: onSuccess,
            onError: onError
        }
    )
}

export default useDeleteTimeline