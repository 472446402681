import React from 'react'
import {
  IconCreditCard,
  ListItem,
} from '@Knowledge-OTP/znk-ui-components'
import { iconVisa, iconMastercard, iconDiscover, iconDiners, iconAmex, iconCardUndefined } from '../../../../../../../../common/Icons/icons'

const CreditCardMethod = (props) => {
  const {cardInfo, isClickable, isParent} = props
  return (
    <ListItem
      className='px-4'
      showTopLine={!isParent}
      showBottomLine={!isParent}
      hasColorHover={isClickable}
      hasHover={isClickable}
      Left={!isParent &&
        <span className='d-flex align-items-center text-gray'>
          <IconCreditCard className='mr-2' />
          Credit Card
        </span>
      }
      Right={
        <span className='d-flex align-items-center'>
          <span className='mr-2 font-weight-light text-gray'>
            **** **** **** {cardInfo.last4}
            <span className='ml-1 small'>
              {cardInfo.expMonth}/{cardInfo.expYear}
            </span>
          </span>
          {cardInfo.brand === 'visa' ? (
            <img src={iconVisa} alt='visa card' />
          ) : cardInfo.brand === 'mastercard' ? (
            <img src={iconMastercard} alt='master card' />
          ) : cardInfo.brand === 'diners' ? (
            <img src={iconDiners} alt='diners card' />
          ) : cardInfo.brand === 'amex' ? (
            <img src={iconAmex} alt='amex card' />
          ) : cardInfo.brand === 'discover' ? (
            <img src={iconDiscover} alt='discovery card' />
          ) : (
            <>
              <img src={iconCardUndefined} alt='undefined card' />
              {/* {cardInfo.brand} */}
            </>
          )}
        </span>
      }
    />
  )
}

export default CreditCardMethod
