import React, { useState } from 'react'
import {
  Modal,
  IconClose,
  useWindowDimensions,
  ListOption,
  Avatar,
  StickyButton,
  Activity,
  Input,
  IconSearch
} from '@Knowledge-OTP/znk-ui-components'
import GetStudent from '../../../../Model/Users/List'
// import NewStudent from './Components/NewStudent'
import escapeRegExp from 'lodash/escapeRegExp'
import useTranslation from '../../../../i18n/useTranslation'
const Employee = (props) => {
  const { setStudent, group } = props
  const [isOpen, setOpen] = useState(false)
  const [selectStudent, setSelectStudent] = useState({})
  const [selectStudentGroup, setSelectStudentGroup] = useState([])
  const [studentSearch, setStudentSearch] = useState(null)
  const toggle = () => {
    setOpen(!isOpen)
    setSelectStudent('')
  }
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const { loading, data } = GetStudent({
    limit: 0,
    roles: ['EDUCATOR'],
    sortField: 'firstName'
  })

  const ordenateStudentArray = (array) => {
    return array.map((stu) => {
      return {
        ...stu,
        username: `${stu.firstName} ${stu.lastName}`
      }
    }).sort((a, b) => {
      if (a.username > b.username) return 1
      if (a.username < b.username) return -1
      return 0
    })
  }
  const students = loading ? [] : data ? data.docs ? ordenateStudentArray(data.docs) : [] : []
  //   const newStudentCallback = (student) => {
  //     if (group) {
  //       setSelectStudentGroup(selectStudentGroup.concat([student]))
  //     } else {
  //       setSelectStudent(student)
  //     }
  //   }
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('payslips.newpayslip.addemployee')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <div className='p-0 m-4'>
          <Input
            className='w-100 input-xs p-0 mb-3'
            placeholder={translate('modal.newplan.searchstudent')}
            addOnComponent={<IconSearch size={16} />}
            type='addon'
            onChange={(e) => {
              setStudentSearch(e.target.value)
            }}
          />
          <div className='m-0 py-2 px-0'>
            {/* <NewStudent dataQuery={data} callback={newStudentCallback}>
              <ListOption
                className='mb-2'
                id='x-0'
                IconRight={<IconArrowRight size={15} color='#696CFF' />}
                childClassName='d-flex'
                label={<span className='m-0 p-0' style={{ color: '#696CFF' }}>{translate('modal.newplan.createstudent')}</span>}
                icon={
                  <IconOutLine
                    className='p-3 mr-1'
                    backgroundColor='#696CFF'
                    color='#FFFFFF'
                    textChildren={<span className='m-0 font-weight-bold' style={{ color: 'white' }}>+</span>}
                    sizePx={25}
                  />
                }
              />
            </NewStudent> */}
            {(loading || JSON.stringify(students) === JSON.stringify([]))
              ? <div className='d-flex justify-content-center'><Activity /></div>
              : students.filter(
                (stu) => (studentSearch !== null && studentSearch !== '') ? new RegExp(escapeRegExp(studentSearch), 'i').exec(`${stu.firstName} ${stu.lastName}`) : true
              ).map((stu) => {
                return (
                  <ListOption
                    key={stu.id}
                    className='mb-2'
                    childClassName='d-flex'
                    selectedInput={
                      group
                        ? selectStudentGroup.reduce(
                          (isSelected, sel) => {
                            return isSelected || sel.id === stu.id
                          }, false)
                        : selectStudent.id === stu.id
                    }
                    id='x-0'
                    label={`${stu.firstName} ${stu.lastName}`}
                    icon={
                      <Avatar
                        name={`${stu.firstName} ${stu.lastName}`}
                        named={false}
                        uri={null}
                        size='avatar-small mr-1 avatar-span-white'
                      />
                    }
                    onClick={() => {
                      if (group) {
                        setSelectStudentGroup(selectStudentGroup.concat([stu]))
                      } else {
                        setSelectStudent(stu)
                      }
                    }}
                  />
                )
              })}
          </div>
          <StickyButton
            color='#696CFF'
            condition={group ? selectStudentGroup.length : JSON.stringify(selectStudent) !== JSON.stringify({})}
            label={translate('payslips.newpayslip.addemployee')}
            onClickButtonFunction={() => {
              setStudent(group ? selectStudentGroup : selectStudent)
              toggle()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

Employee.propTypes = {
}

Employee.defaultProps = {
}
export default Employee
