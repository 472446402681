import React, { useMemo, useEffect, useState } from 'react'
import {
    ModalLayout,
    ListItem,
    Button,
    Input,
    SelectOption,
    CheckboxOption,
    IconArrowUp,
    DropDownNew,
    DropDownItem
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import useTaskReducer from '../../../../reducers/task'
import useNewTaskModal from './useNewTaskModal'
import InputDueDate from './Components/InputDueDate'
import SelectExamScore from './Components/SelectExamScore'
import SelectAccount from './Components/SelectAccount'
import SelectPlan from './Components/SelectPlan'
import SelectPhaseStep from './Components/SelectPhaseStep'
import SelectStudent from './Components/SelectStudent'
import { showAlert } from '../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import SelectActivity from '../assingTaskModal/Components/SelectActivity'

//mutation
import useCreateTaskFromPlan from '../Model/useCreateTaskFromPlan'
import useUpdateTaskFromPlan from '../Model/useUpdateTaskFromPlan'

// import {findTasksOnPlan} from '../utils'
import RenderInputOption from './Components/RenderInputOption'
import SelectDate from '../newTaskModal/Components/SelectDate'
import moment from 'moment'

import SelectCounselor from '../assingTaskModal/Components/SelectCounselor'
import { extractTeamFromPlan } from '../assingTaskModal/Utils/extractTeamFromPlan'

const typeTask = {
    completion: 'completion',
    uploadFile: 'uploadFile',
    link: 'link',
    scheduledMeetting: 'scheduledMeetting',
    scoreColletion: 'scoreColletion',
    logoinInfo: 'logoinInfo',
}


const NewTaskModal = () => {
    const { close, toggle, ...reduxData } = useNewTaskModal()
    const { isOpen, onChange, taskData, useMutation, isEdit, loading, completedTask } = reduxData
    //task reducer
    const { state: task, setData, validation, parseToTaskInput, parseTaskFromAPI, resetData } = useTaskReducer(false)
    const [selectUser, setSelectUser] = useState("")
    const [examDateSelected, setExamDateSelected] = useState("")
    const [selected, setSelected] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        setSelectUser(task?.userMeetingId)
    }, [task])

    useEffect(
        () => {
            if (taskData && Object.entries(taskData)?.length > 0) {
                if (taskData?.status === 'unassigned') {
                    setData({
                        ...taskData,
                        dueDate: moment().add(taskData?.dueTime, 'days')
                        // dueTime: moment(taskData.dueDate).endOf('day').diff(moment(), 'days') 
                    })
                } else {
                    const days = moment.duration(moment(taskData.dueDate).startOf('day').diff(moment())).asDays()
                    setData({
                        ...taskData,
                        dueTime: Math.ceil(days)
                    })
                }
            } else resetData()
        },
        // eslint-disable-next-line
        [taskData]
    )
    // useEffect(() => {
    //     setData({
    //         ...taskData, 
    //         dueTime: parseInt(moment.duration(moment(task.dueDate).diff(moment())).asDays()) 
    //     })
    // }, [taskData?.dueDate])
    //escucha cuando task cambia y ejecuta validation para habilitar 
    //o deshabilitar el botón según el caso
    const disabled = useMemo(() => {
        //validation viene con contenido cuando el objeto 
        //no pasa la validación
        return !!validation(completedTask)
        // eslint-disable-next-line
    }, [task, completedTask])
    const callBack = (newTask) => {
        if (onChange) {
            let ids = {}
            if (isEdit) ids = { phaseId: taskData?.phaseId, stepId: taskData?.stepId }
            onChange({ ...newTask, ...ids })
        }
        else alert("no onChange function")
    }

    const findTasksOnPlan = (planTask) => {
        //RETORNA EL ARREGLO DE TASKS CONTENIDO EN phase
        //  CON taskData?.phaseId Y step CON taskData?.stepId 

        const tasks =
            planTask
                ?.find(
                    ({ phaseName }) => [taskData?.phaseId, task?.phaseId].includes(phaseName)
                )
                ?.steps?.find(
                    ({ id }) => [taskData?.stepId, task?.stepId].includes(id)
                )?.tasks

        // const task = tasks[tasks?.length - 1]
        return tasks
    }

    const [createTaskForPlan, { loading: loadCreate }] = useCreateTaskFromPlan(
        (result) => {
            showAlert({
                text: 'The task has been successfully updated!',
                status: 'success'
            }, dispatch)
            const planTask = result?.createTaskFromPlan?.tasks
            const tasks = findTasksOnPlan(planTask).map((task) => {
                return parseTaskFromAPI(task)
            })
            callBack(tasks[tasks?.length - 1])
        },
        (err) => {
            console.error(err)
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
        }
    )

    const [updateTaskForPlan, { loading: loadUpdate }] = useUpdateTaskFromPlan(
        (result) => {
            showAlert({
                text: 'The task has been successfully updated!',
                status: 'success'
            }, dispatch)
            const planTask = result?.updateTaskFromPlan?.tasks
            const tasks = findTasksOnPlan(planTask).map((task) => parseTaskFromAPI(task))
            const task = tasks?.find(({ taskId }) => taskId === taskData?.taskId || taskId === taskData?.id)
            callBack(task)
        },
        (err) => {
            console.error(err)
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
        }
    )

    const submitForm = (userId = null) => {
        if (useMutation) {

            const otherParams = {
                stepId: completedTask ? task?.stepId : taskData?.stepId,
                phase: (completedTask ? task?.phaseId : taskData?.phaseId)?.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase() }),
                planId: completedTask ? task?.planId : taskData?.planId,
                userMeetingId: task?.type === typeTask.scheduledMeetting ? selectUser : null
            }
            const variables = {
                taskInput: parseToTaskInput({
                    ...task,
                    examDate: examDateSelected,
                    //TODO SCORES: agregar el selected.id
                    status: !isEdit ? 'unassigned' : (isEdit && task?.type === typeTask?.scheduledMeetting) ? 'pending' : task?.status,
                    statusSave: !isEdit ? 'new' : 'edit',
                    examId: selected
                }),
                ...otherParams
            }
            if (!isEdit) {

                createTaskForPlan({ variables: { ...variables, assingTo: userId } })
            } else {
                updateTaskForPlan({
                    variables: {
                        ...variables,
                        taskId: taskData?.taskId || taskData?.id,
                        status: (isEdit && task?.type === typeTask?.scheduledMeetting) ? 'pending' : taskData?.status
                    }
                })
            }
        }
        else {
            callBack(task)
        }
    }

    return (
        <ModalLayout
            className={`modal-colored modal-sidebar modal-desktop-half white-modal`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h3 text-title`}>
                    New Task
                </span>
            }
            minHead={
                <span className={`h3 text-title`}>
                    New Task
                </span>
            }
        >
            {
                completedTask && (
                    <>
                        <RenderInputOption key={'selectStudent'} type={`text`} label={null} >
                            <SelectStudent
                                disabled={false}
                                onChange={(student) => {
                                    setData({
                                        student,
                                        dataPlan: null, planId: null, phaseId: null, stepId: null
                                    })
                                }}
                                value={task?.student || null}
                            />
                        </RenderInputOption>
                        <RenderInputOption key={'name'} type={`text`} label={`Counseling Plan`} >
                            <SelectPlan
                                userId={task?.student?.id}
                                value={task?.planId}
                                onChange={(plan) => {
                                    setData({ dataPlan: plan, planId: plan?.id, phaseId: null, stepId: null })
                                }} />
                        </RenderInputOption>
                        <SelectPhaseStep
                            plan={task?.dataPlan}
                            phase={task?.phaseId}
                            step={task?.stepId}
                            onChange={(phaseId, stepId, position = 0) => {
                                setData({ phaseId, stepId, position })
                            }}
                        />
                    </>
                )
            }



            <InputDueDate
                value={task?.dueTime || 0}
                onChange={(newValue) => {
                    if (newValue < 0) return
                    setData({
                        dueTime: newValue,
                        dueDate: moment(task.dueDate).add(newValue - task?.dueTime, 'days').toISOString(),
                    })
                }}
                showTopLine
                showBottomLine
            />
            {
                isEdit && (
                    <SelectDate
                        task={task}
                        className={`h4 ml-1`}
                        value={task?.dueDate}
                        onChange={(newValue) => {
                            setData({
                                dueDate: newValue,
                                dueTime: moment(newValue).endOf('day').diff(moment(), 'days')
                            })
                        }}
                    />
                )
            }
            <ListItem
                Left={
                    <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                        <CheckboxOption
                            onChange={(e) => {
                                setData({ isEspecific: e })
                            }}
                            value={task?.isEspecific}
                            className={`ml-1 text-info`} />
                        <span className={`ml-2 text-info`}>{`College specific task`}</span>
                    </div>
                }
                showBottomLine
            />
            {
                (isEdit && task?.type === typeTask?.scheduledMeetting) &&
                (<SelectCounselor
                    team={extractTeamFromPlan(task?.dataPlan)}
                    selectUser={selectUser}
                    setSelectUser={setSelectUser}
                />
                )
            }
            <RenderInputOption key={'name'} type={`text`} label={`Task name`} >
                <Input
                    name={`taskName`}
                    value={task?.taskName || null}
                    type={`text`}
                    placeholder={'Task name'}
                    onChange={(e) => {
                        setData({ taskName: e.target.value })
                    }}
                />
            </RenderInputOption>
            <RenderInputOption key={'instructions'} type={`textarea`} label={`Instructions`} >
                <textarea
                    name={`instructions`}
                    placeholder={`Describe here how to complete...`}
                    value={task?.instructions || null}
                    onChange={(e) => {
                        setData({ instructions: e.target.value })
                    }}
                    className='znk-input w-100 p-3'
                    style={{ height: '150px' }}
                />
            </RenderInputOption>
            <RenderInputOption key={'type'} type={`options`} label={`Task type`} >
                <SelectOption
                    name={`type`}
                    value={task?.type || null}
                    className={`w-100`}
                    options={[
                        { id: typeTask.completion, text: 'Completion task (default)' },
                        { id: typeTask.uploadFile, text: 'Upload file' },
                        { id: typeTask.link, text: 'Go to link' },
                        { id: typeTask.scheduledMeetting, text: 'Schedule meeting' },
                        { id: typeTask.scoreColletion, text: 'Add exam score' },
                        { id: typeTask.logoinInfo, text: 'Log in' },
                    ]}
                    showNotSelected
                    changeFunction={() => {
                        setData({ type: null })
                    }}
                    onChange={(e) => {
                        //como SelectOption no lo podemos usar solo con referencias
                        // se carga la opción seleccionada en la entidad task del reducer
                        setData({ type: e })
                    }}
                />
            </RenderInputOption>
            {task?.type && (<div className={`borderTop w-100`} />)}
            <TabContent activeTab={task.type}>
                {
                    task?.type && (
                        <TabPane tabId={task.type}>
                            <RenderInputOption label={`Task action label`}>
                                <Input
                                    name={`buttonLabel`}
                                    value={task?.buttonLabel || null}
                                    type={`text`}
                                    placeholder={'Task action label'}
                                    onChange={(e) => {
                                        setData({ buttonLabel: e.target.value })
                                    }}
                                />
                                {task?.type !== typeTask.uploadFile && <div className={`d-flex flex-row mt-1`}>
                                    <CheckboxOption
                                        value={task?.uploadDocs}
                                        onChange={(e) => {
                                            setData({ uploadDocs: e })
                                        }}
                                        // defaultState={task?.showInDocs}
                                        className={`ml-1`} />
                                    <span className={`ml-2`}>{`Allow upload documents`}</span>
                                </div>}
                                {task?.uploadDocs && <div className={`d-flex flex-row mt-1`}>
                                    <CheckboxOption
                                        value={task?.showInDocs}
                                        onChange={(e) => {
                                            setData({ showInDocs: e })
                                        }}
                                        // defaultState={task?.showInDocs}
                                        className={`ml-1`} />
                                    <span className={`ml-2`}>{`Show in documents`}</span>
                                </div>}
                            </RenderInputOption>
                        </TabPane>
                    )
                }
                <TabPane tabId={typeTask.completion}>
                    {null}
                </TabPane>
                <TabPane tabId={typeTask.uploadFile}>
                    {/* <div className={`d-flex flex-row m-0 p-0`}>
                        <RenderInputOption label={'Max file'} className={`pr-1`}>
                            <Input 
                                name={`maxFile`}
                                value={task?.maxFile || null}
                                type={`text`} 
                                placeholder={'Max file'}
                                onChange={(e) => {
                                    setData({maxFile: e.target.value})
                                }} 
                            /> 
                        </RenderInputOption>
                        <RenderInputOption label={'Max file size'} className={`pl-1`}>
                            <Input 
                                name={`fileSize`}
                                value={task?.fileSize || null}
                                type={`text`} 
                                placeholder={'File size'}
                                onChange={(e) => {
                                    setData({fileSize: e.target.value})
                                }} 
                            /> 
                        </RenderInputOption>
                    </div> */}
                    {/* <RenderInputOption label={null} className={`pl-1`}>
                        <div className={`d-flex flex-row`}>
                            <CheckboxOption 
                                value={task?.showInDocs}
                                onChange={(e) => {
                                    setData({showInDocs: e})
                                }} 
                                // defaultState={task?.showInDocs}
                                className={`ml-1`}/>
                            <span className={`ml-2`}>{`Show in documents`}</span>
                        </div>
                    </RenderInputOption> */}
                </TabPane>
                <TabPane tabId={typeTask.link}>
                    <RenderInputOption label={typeTask.link}>
                        <Input
                            name={`link`}
                            value={task?.linkToShow || null}
                            type={`text`}
                            placeholder={'http://'}
                            onChange={(e) => {
                                setData({ linkToShow: e.target.value })
                            }}
                        />
                    </RenderInputOption>
                </TabPane>
                <TabPane tabId={typeTask.scheduledMeetting}>
                    {null}
                </TabPane>
                <TabPane tabId={typeTask.scoreColletion}>
                    <RenderInputOption label={`Exam to complete score`}>
                        <SelectExamScore
                            onChange={(e) => {
                                setData({ selectedExam: e })
                            }}
                            value={task?.selectedExam}
                        />
                        {task?.selectedExam?.id && 
                        <SelectActivity 
                            // selectedExam={task?.selectedExam} 
                            // serviceDate={task?.serviceDate}
                            selected={selected}
                            setSelected={setSelected}
                            task={task}
                            examDateSelected={examDateSelected}
                            setExamDateSelected={setExamDateSelected}
                            />}
                    </RenderInputOption>
                </TabPane>
                <TabPane tabId={typeTask.logoinInfo}>
                    <RenderInputOption footLabel={`You can create a new account by typing it.`}>
                        <SelectAccount
                            onChange={(accountId) => {
                                setData({ accountsPlatform: accountId })
                            }}
                            value={task.accountsPlatform}
                        />
                    </RenderInputOption>
                </TabPane>
            </TabContent>
            <div className={`d-flex flex-row m-0 p-0`}>
                <Button
                    disabled={disabled}
                    className={`${!completedTask ? 'w-100' : 'w-85'}`}
                    label={`Save task`}
                    color={'primary'}
                    activity={loading || loadCreate || loadUpdate}
                    style={{ borderRadius: `${!completedTask ? '16px' : '16px 0px 0px 16px'}` }}
                    onClick={() => { submitForm() }}
                />

                {
                    completedTask &&
                    <DropDownNew
                        direction='topLeft'
                        disabledDropdown={disabled || loading || loadCreate || loadUpdate}
                        disabled={disabled || loading || loadCreate || loadUpdate}
                        arrow={{ pointAtCenter: true }}
                        // hoverEnabled={false}
                        // classNameMenu='w-auto mb-3'
                        options={[{
                            component:
                                <DropDownItem
                                    className='p-1'
                                    onClick={() => {

                                        submitForm(task?.dataPlan?.studentUser?.studentUserId/**asigna al usuario**/)
                                    }}>
                                    Create and assign
                                </DropDownItem>
                        }]}
                        className={`w-15`}
                    >
                        <Button
                            color='primary'
                            className={'w-100 d-flex flex-row justify-content-center align-items-center'}
                            disabled={disabled}
                            activity={loading || loadCreate || loadUpdate}
                            label={
                                <IconArrowUp size={15} />
                            }
                            style={{ borderRadius: '0px 16px 16px 0px', marginLeft: '1px' }}
                        />
                    </DropDownNew>
                }
            </div>
        </ModalLayout>
    )
}

export default NewTaskModal