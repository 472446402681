import React, {useState, useMemo, useEffect} from 'react'
import { ModalLayout, IconSearch, Input, ListOption, Avatar, StickyButton } from '@Knowledge-OTP/znk-ui-components'
import useAddCounselorModal from './useAddCounselorModal'
import useAllUsers from '../../../../../../Model/useAllUsers'
import Skeleton from 'react-loading-skeleton'
import { TabContent, TabPane } from 'reactstrap'
import Pagination from '../../../../../../../../common/Pagination'

const AddCounselorModal = () => {
    const {isOpen, toggle, onChange, /**filterSearch,**/ multi, defaultSelectedUsers} = useAddCounselorModal()
    
    const [query, setQuery] = useState({
        // state: true,
        sortField: 'firstName',
        previous: '',
        next: '',
        limit: 5,
        // ids: [],
        roles: ['SUPER_ADMIN'],
        search: ''
    })
    const [currentPage, setCurrentPage] = useState(1)
    const {data, loading} = useAllUsers(query, {fetchPolicy: 'cache-first'})
    
    const {
        counselor,
        paginatedData
    } = useMemo(() => {
        if(data?.allUsers) {
            //counselors
            const {docs, ...paginatedData} = data.allUsers
            return {
                counselor: (docs || [])?.filter(({roles}) => roles.includes('SUPER_ADMIN')),
                paginatedData 
            }
        }
        return {counselor: [], paginatedData: {}}
    }, [data])

    const [search, setSearch] = useState('')
    const [selectedUsers, setSelectedUsers] = useState([]) 
    useEffect(() => {
        if(defaultSelectedUsers?.length >= 0) {
            setSelectedUsers(
                defaultSelectedUsers.reduce((array, userId) => {
                    const findCouncelor = counselor.find(({id}) => id === userId)
                    if(findCouncelor) array.push(findCouncelor)
                    return array
                }, [])
            )
        }
    }, [defaultSelectedUsers, counselor])
    return (
        <ModalLayout
            className={`modal-colored modal-centered white-modal h-auto`}
            topHead={
                <span className={`text-title font-weight-bold h2`}>Select Counselor</span>
            }
            isOpen={isOpen}
            toggle={toggle}
        >
            <div className='p-0 mx-3'>
                <Input
                    className='w-100 input-xs p-0 mb-3'
                    placeholder={'Search'}
                    addOnComponent={<IconSearch size={16} />}
                    type='addon'
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    onKeyUp={({ key }) => {
                    if (key === 'Enter') {
                        setCurrentPage(1)
                        setQuery({...query, search: search, next: '', previous: ''})
                    }
                    }}
                />
                <div className='m-0 py-2 px-0'>
                    <TabContent activeTab={loading ? 'loading' : 'show'}>
                        <TabPane tabId={`loading`}>
                            <Skeleton count={5} height={40} style={{borderRadius: '10px'}} className={`w-100 my-1`} />
                        </TabPane>
                        <TabPane tabId={`show`}>
                            {
                                counselor.map((user) => {
                                    const {id, firstName, lastName} = user
                                    const isSelected = selectedUsers.filter(({id: userId}) => userId === id).length > 0
                                    return (
                                    <ListOption
                                        key={id}
                                        className='mb-2'
                                        childClassName='d-flex'
                                        selectedInput={isSelected}
                                        id='x-0'
                                        label={`${firstName} ${lastName}`}
                                        icon={
                                            <Avatar
                                                name={`${firstName} ${lastName}`}
                                                named={false}
                                                uri={null}
                                                size='avatar-small mr-1 avatar-span-white'
                                            />
                                        }
                                        onClick={() => {
                                            if(isSelected) {
                                                setSelectedUsers(selectedUsers.filter(({id}) => user.id !== id))
                                            } else {
                                                if(
                                                    !multi && 
                                                    selectedUsers.length === 1
                                                ) {
                                                    //deselecciona el actual y selecciones otro usuario
                                                    setSelectedUsers([user])
                                                    return    
                                                }
                                                setSelectedUsers(selectedUsers.concat([user]))
                                            }
                                            
                                        }}
                                    />
                                    )
                                })
                            }
                        </TabPane>
                    </TabContent>
                    <Pagination
                        size={`small`}
                        useSelectorChangeSize={false}
                        pageSize={query?.limit || 5}
                        changeSize={() => {}}
                        clickNext={() => {
                            if(paginatedData?.hasNext) {
                                setCurrentPage(currentPage + 1)
                                setQuery({...query, next: paginatedData?.next, previous: ''})
                            }
                        }}
                        clickPrevious={() => {
                            if(paginatedData?.hasPrevious) {
                                setCurrentPage(currentPage - 1)
                                setQuery({...query, previous: paginatedData?.previous, next: ''})
                            }
                        }} 
                        hasPrevious={paginatedData?.hasPrevious} 
                        hasNext={paginatedData?.hasNext} 
                        activePage={currentPage}
                        pageCount={Math.ceil((paginatedData?.totalDocs || 0)/(query?.limit || 1))}
                        loading={loading}
                    />
                </div>
                <StickyButton
                    color='#696CFF'
                    condition={true}
                    disabled={!(selectedUsers.length > 0)}
                    label={'Select counselor'}
                    onClickButtonFunction={() => {
                        onChange(selectedUsers)
                    }}
                />
            </div>
        </ModalLayout>
    )
}

export default AddCounselorModal