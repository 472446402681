import { useQuery, gql } from "@apollo/client"
import { useMemo } from "react"

const useGetExamScores = (variables = {limit: 10}) => {
    const {data, loading, refetch} = useQuery(
        gql`
            query {
                getExamScores (limit: 0) {
                    docs {
                        id
                        name
                        results {
                            name
                            scale
                            values {
                                name
                                scale
                            }
                        }
                        serviceId
                    } 
                }
            }
        `, 
        {
            variables,
            fetchPolicy: 'no-cache'
        }
    )

    const examScores = useMemo(() => {
        return data?.getExamScores?.docs || []
    }, [data])

    return {examScores, loading, refetch}
}

export default useGetExamScores