import validate from 'validate.js'

const constraints = {
  lessonId: {
    presence: true
  },
  makeUpInput: {
    presence: true
  }
}

export default (data) => {
  return validate(data, constraints)
}
