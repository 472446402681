import React from 'react'
import {
  ListItem,
  IconSignPlus, IconOptions,
  DropDownItem,
  DropDownNew
} from '@Knowledge-OTP/znk-ui-components'
import LazyAvatar from '../../../../../../../common/LazyAvatar'
import { useDispatch } from 'react-redux'
import StatusField from '../../../../../Fields/Status'
import { redirectToSendOnePlan } from '../../../../../Fields/utils'
import SelectStudent from '../../../../../modal/newPlan/SelectStudent'

const PlanStudent = (props) => {
  const { form, setForm } = props
  const dispatch = useDispatch()
  const test = () => { }
  const deletePlan = (item) => {
    setForm({ ...form, students: form.students.filter(element => element.planId !== item.planId) })
  }

  const getOptions = (student) => {

    let opciones = []
    if (student.__typename !== 'User') {
      if (student.planStatus === 'draft') {
        opciones = [
          {
            id: 'Send Plan',
            component:
    <DropDownItem
      onClick={() => {
        redirectToSendOnePlan(form.childPlans.filter(element => element.id === student.planId)[0], test, dispatch)
      }}
    >Send Plan
    </DropDownItem>
          },
          {
            id: 'Delete Plan',
            component:
            <DropDownItem onClick={() => { deletePlan(student) }}>
              Delete Plan
            </DropDownItem>
          }
        ]
      }
      if (student.planStatus === 'sent') {
        opciones = [
          {
            id: 'Resend Plan',
            component:
    <span
      onClick={() => {
        redirectToSendOnePlan(form.childPlans.filter(element => element.id === student.planId)[0], test, dispatch)
      }}
    >Resend Plan
    </span>
          },
          {
            id: 'Aprove Plan',
            component:
    <span
      onClick={() => {}}
    >Aprove Plan
    </span>
          },
          {
            id: 'Delete Plan',
            component:
    <>
      <div style={{ borderTop: '1px solid lightgray' }} className='mt-2' />
      <span
        onClick={() => { deletePlan(student) }}
      >Delete Plan
      </span>
    </>
          }
        ]
      }
    }
    return opciones
  }

  return (
    <div className='w-100 d-flex flex-column py-6'>
      <h3><b>Students</b></h3><br />
      {form?.students && form?.students?.length
        ? form.students.map((student) => {
          let opciones = getOptions(student)
          return (
            <ListItem
              className='py-1'
              label={null}
              key={student.id}
              Left={
                <div className='d-flex align-items-center'>
                  <LazyAvatar
                    userId={student?.id || student?.studentUserId || student?.studentId}
                    name={`${student.firstName} ${student.lastName}`}
                    named={false}
                    uri={null}
                    size='avatar-medium'
                  />
                  <label className='ml-2 h4'>
                    {`${student.firstName} ${student.lastName}`}
                  </label>
                </div>
              }
              Right={
                student.__typename === 'User'
                  ? <div className={`hover-icon`}>
                      <IconSignPlus size={16} className='text-error' onClick={() => setForm({ ...form, students: form.students.filter(stu => stu.id !== student.id) })} />
                    </div>
                  : <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
                    <DropDownNew
                      direction='bottomRight'
                      disabled={student.planStatus === 'scheduled'}
                      options={
                        opciones.map((item) => {
                          return item
                        })
                      }
                    >
                      <div className='d-flex align-items-center'>
                        <StatusField status={student.planStatus} />
                        {
                          student.planStatus !== 'scheduled' && (
                            <div className={`hover-icon ml-1`}>
                              <IconOptions style={{ transform: 'rotate(90deg)' }} />
                            </div>
                          )
                        }
                      </div>
                    </DropDownNew>
                    </div>
              }
            />
          )
        }) : null}
        <SelectStudent
          setStudent={(stus) => {
            setForm({ ...form, students: stus })
          }}
          group student={(form?.students || []).map((stu) => {
            return {...stu, id: stu?.studentId || stu?.id || stu?.studentUserId}
          })}
        >
          <label className='btn-link mt-3'>Select or create new students</label>
        </SelectStudent>
      {/* <div className='borderTop my-2' /> */}
    </div>
  )
}

PlanStudent.propTypes = {
}

PlanStudent.defaultProps = {
}
export default PlanStudent
