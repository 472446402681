import React, {useMemo} from 'react'
import {IconTime} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'

const DaysToComplete = (props) => {
    const {
        task, 
        noIcon = false, 
        type = 'days', /**oneOf['date', 'days'] **/
        missedClass = 'text-error',
        normalClass = 'text-info'
    } = props
    const {dueTime, status, dueDate} = task
    const {realDueTime, realDate, realDateLabel}  = useMemo(
        () => {
            const real = Math.ceil(moment.duration(
                moment(dueDate).startOf('day').diff(moment())
            ).asDays())
            let realDueTime = dueTime
            let realDate = dueDate ? moment(dueDate) : moment()
            switch (status) {
                case 'pending':
                    realDueTime = real
                    break
                case 'review':
                    realDueTime = real
                    break   
                default:    
                    realDueTime = dueTime //valor de referencia
            }


            let realDateLabel = `No defined`
            const today = moment().endOf('day').format('YYYY-MM-DD')
            const tomorrow = moment().add(1, 'day').endOf('day').format('YYYY-MM-DD')
            const yesterday = moment().add(-1, 'day').endOf('day').format('YYYY-MM-DD')

            if (realDate.clone().format('YYYY-MM-DD') === today)  realDateLabel = 'Today'
            else if (realDate.clone().format('YYYY-MM-DD') === tomorrow) realDateLabel = 'Tomorrow'
            else if (realDate.clone().format('YYYY-MM-DD') === yesterday) realDateLabel = 'Yesterday'
            else realDateLabel = realDate.clone().format('MMM Do')
            
            return {realDueTime, realDate, realDateLabel}
        }, [dueTime, status, dueDate]
    )
    const isMissedDays = ['pending', 'review'].includes(status) && realDueTime <= 0 && type === 'days'
    const isMissedDate = ['pending', 'review'].includes(status) && realDate.endOf('day') < moment() && type === 'date'
    return (
        <div className={`d-flex flex-row align-items-center m-0 p-0 ${isMissedDays || isMissedDate ? missedClass : normalClass}`}>
            {
                !noIcon && (<IconTime size={15}/>)
            }
            <span className={`h45 ml-2`}>
                {
                    type === 'days' ?
                        `${realDueTime >= 0 ? realDueTime : '0'} days to complete` : 
                        `${realDateLabel}`
                }
            </span>
        </div>
    )
}

export default DaysToComplete