import {useDispatch, useSelector} from 'react-redux'

const useCompletePlanModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.completePlanModal)
    const {isOpen} = reduxData
    const setData = (open, onChange = () => {}, dataPlan = null, useMutation = false, loading = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.completePlanModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                useMutation,
                loading,
                dataPlan
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, dataPlan = null, useMutation = false) => {
            setData(true, onChange, dataPlan, useMutation)
        },
        close: () => {setData(false)},
        toggle: () => {setData(!isOpen)}
    }
}

export default useCompletePlanModal