import { gql, useQuery } from '@apollo/client'

export default (vars) => {
  const { data, error, loading, refetch } = useQuery(
    gql`query($roles: [Roles!], $limit: Int, $sortField: String) {
		allUsers(roles: $roles, limit: $limit, sortField: $sortField) {
			hasNext
			hasPrevious
			next
			previous
			totalDocs
			docs {
				id
				isActive
				firstName
				lastName
				roles
				studentInfo {
					studentType
					parents {
						managePayments
						manageApprovals
						email
						firstName
						lastName
						parentUserId
						phone
					}
				}
			}
		}
	}
	`,
    {
      variables: vars
    }
  )
  return { loading, data: data ? data.allUsers : {}, error, refetch: () => refetch(vars) }
}
