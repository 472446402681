import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTaskReducer from '../../../../reducers/task'
const useViewTaskModal = () => {
    const dispatch = useDispatch()
    //Para que hook funciones debe haber solo UN componente NewTaskModal 
    // renderizado en el dominio
    const reduxData = useSelector((state) => state.modal.counseling.viewTaskModal)
    //task reducers
    const taskReducer = useTaskReducer(false)
    const {setData} = taskReducer

    const {isOpen, taskData, onChange} = reduxData
    useEffect(() => {
        if(taskData) setData(taskData)
        // eslint-disable-next-line
    }, [taskData])


    const setOpen = (open, onChange = () => {}, taskData = {}, useMutation = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.viewTaskModal',
            data: {
                ...reduxData,
                isOpen: open,
                taskData: {...taskData},
                onChange,
                useMutation
            }
        })
    }
    
    return {
        ...reduxData,
        taskReducer: {
            ...taskReducer,
            setData: (newTask) => {
                dispatch({
                    type: 'SET_DATA_MODAL_FORM_LIST',
                    payload: 'counseling.viewTaskModal',
                    data: {
                        ...reduxData,
                        taskData: {...newTask},
                    }
                })
            }
        },
        isOpen,
        onChange,
        open: (taskData = {}, onChange = () => {}, useMutation = false) => {
            //onChange recibe como parametro action (edit, duplicate, delete)
            // y result (la tarea modificada)
            setData({...taskData, isCreated: false})
            setOpen(true, onChange, {...taskData, isCreated: false}, useMutation)
            
        },
        openForCreatedTask: (taskData = {}, onChange = () => {}, useMutation = false) => {
            setData({...taskData, isCreated: true})
            setOpen(true, onChange, {...taskData, isCreated: true}, useMutation)
        },
        close: () => {
            setData({})
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        }
    }
}

export default useViewTaskModal