import {useMutation, gql} from '@apollo/client'
import planQueryResult from '../../../CounselingPlans/Model/queryResults/planQueryResult'

const useCreateTaskFromPlan = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $taskInput: UserTasksInput!
                $stepId: ID!
                $phase: PhaseEnum!
                $planId: ID!
                $assingTo: ID = null
                ) {
                createTaskFromPlan (
                    taskInput: $taskInput
                    stepId: $stepId
                    phase: $phase
                    planId: $planId
                    assingTo: $assingTo
                )  {
                    ${planQueryResult}
                }
            }
        `,
        {
            onCompleted: (result) => {
                onSuccess(result)
            },
            onError
        }
    )
}

export default useCreateTaskFromPlan