import {useMutation, gql} from '@apollo/client'

const useDeleteTaskTemplate = (onSuccess = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $templateId: ID!
                ) {
                deleteTaskTemplate (
                    templateId: $templateId
                )
            }
        `,
        {
            onCompleted: () => {
                onSuccess()
            }
        }
    )
}

export default useDeleteTaskTemplate