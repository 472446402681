import React, { useState, useEffect } from "react";
import HideTimeline from "../Components/HideTimeline";
import useLazyGetPdfTemplate from "../../Model/getLazyPdfTemplate";
import useElementsHandler from "../../Elements/useElementsHandler";
import usePdfViewer from "../usePdfViewer";
import { usePDF } from "@react-pdf/renderer";
import { toPng } from "html-to-image";
//recibe la información de un plan y retorna
// url de proposal con la infomación actualizada
const camelCaseToKey = (text) => {
  const result = text.replace(/([A-Z])/g, "-$1").toLowerCase();
  // const finalResult = result.charAt(0).toLowerCase() + result.slice(1);
  return result;
};
const PdfGenerator = (props) => {
  const {
    start = false,
    setUrl = () => {},
    plan = null, // foprmulario de edición / creación de plan
  } = props;
  // obtiene plantilla pdf según proposal version
  const [getPdfTemplate, { data }] = useLazyGetPdfTemplate();
  useEffect(() => {
    if (plan?.proposalVersion) {
      getPdfTemplate({
        variables: { templateKey: camelCaseToKey(plan.proposalVersion) },
      });
    }
    // eslint-disable-next-line
  }, [plan?.proposalVersion]);

  const { RenderHtmlDocument } = useElementsHandler();

  const { embedVarInDocument } = usePdfViewer();
  const [timelineRef, setTimelineRef] = useState(null);
  const [Html, setHtml] = useState(null);
  useEffect(() => {
    if (data?.getPdfTemplate && timelineRef?.current && plan && start) {
      toPng(
        timelineRef.current
        // , {width: 500, height: 500}
      )
        .then((timelineUrl) => {
          const documentObj = embedVarInDocument(
            plan,
            data.getPdfTemplate.json,
            timelineUrl
          );
          setHtml(RenderHtmlDocument(documentObj));
        })
        .catch((error) => {
          console.error("oops, something went wrong!", error);
        });
    }
    // eslint-disable-next-line
  }, [data?.getPdfTemplate, timelineRef, plan, start]);

  const [documentInstance, setDocumentInstance] = usePDF({ document: Html });
  useEffect(() => {
    // setDocument(documentInstance)
    if (Html) setDocumentInstance({ document: Html });
    // setDocumentInstanceProp({document: Html})
    // eslint-disable-next-line
  }, [Html]);

  const [currentUrl, setCurrentUrl] = useState(null);
  useEffect(() => {
    //cada vez que se actualiza la instancia del documento
    if (documentInstance?.url && !currentUrl) {
      // console.log("SOLO SE EJECUTA UNA VEZ")
      setCurrentUrl(documentInstance?.url);
      setUrl(documentInstance?.url);
    }
    // eslint-disable-next-line
  }, [documentInstance?.url, currentUrl]);

  return (
    // plan && (
    <HideTimeline
      tasks={plan?.tasks || []}
      setTimelineRef={setTimelineRef}
      generateImage={false}
    />
    // )
  );
};

export default PdfGenerator;
