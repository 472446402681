import React from 'react'
import {
  LessonCard,
  IconRight,
  IconDocument
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import useTranslation from '../../../../../i18n/useTranslation'
import Skeleton from 'react-loading-skeleton'
import formatNumber from '../../../../../utils/formatNumber'
import { Link } from 'react-router-dom'

const Summary = (props) => {
  const { educator, loading } = props
  const { translate } = useTranslation()

  return (
    <div className='d-flex flex-column'>
      <div className='pr-6'>
        <label className='h4 font-weight-bold mt-4 mb-2'>{translate('educator.modal.summary.upcommingLesson')}</label>
        {
          loading
            ? <Skeleton count={1} height={112} className='w-100' />
            : educator && educator.educatorInfo.upcomingLesson && (
              <LessonCard
                title={`${educator.educatorInfo.upcomingLesson?.service?.name} ${educator.educatorInfo.upcomingLesson?.topic?.name}`}
                status='upcoming'
                lessonTime={moment(educator.educatorInfo.upcomingLesson?.startDate)}
                lessonEndTime={moment(educator.educatorInfo.upcomingLesson?.endDate)}
                attendants={
                  educator.educatorInfo.upcomingLesson.kind === 'individual-lesson'
                    ? [{ name: `${educator.educatorInfo.upcomingLesson?.student?.firstName} ${educator.educatorInfo.upcomingLesson?.student?.lastName}`, avatar: null }]
                    : educator.educatorInfo.upcomingLesson.kind === 'plan-lesson'
                      ? [{ name: `${educator.educatorInfo.upcomingLesson?.studentPlan?.firstName} ${educator.educatorInfo.upcomingLesson?.studentPlan?.lastName}`, avatar: null }]
                      : educator.educatorInfo.upcomingLesson?.students.map(
                        e => ({
                          name: `${e.firstName} ${e.lastName}`, avatar: null
                        })
                      )
                }      
                onClick={() => {}}
                showCountdown
                preLabelTime={null}
              />
            )
        }
      </div>
      <label className='h4 font-weight-bold mt-4 mb-2 mt-5'>{translate('educator.modal.summary.services')}</label>
      <div className='borderTop mt-3' />
      <div className='pr-6'>
        {
          educator?.educatorInfo && educator.educatorInfo.services.map(
            item => {
              return item.topics ? item.topics.map(
                element => {
                  return element.teachingTypes && element.teachingTypes.length > 0 ? (
                    <>
                      <div className='d-flex flex-row justify-content-between mt-3 cursor'>
                        <span className='text-title'>{item.name} - {element.name}</span>
                        <div className='d-flex flex-row align-items-center'>
                          <span className='text-gray mr-2'>{!element.teachingTypes.includes('individual') ? '-' : element.isWaiveIndividual ? 'Waive' : 'USD $' + formatNumber(element.individualRate)} / {!element.teachingTypes.includes('group') ? '-' : element.isWaiveGroup ? 'Waive' : 'USD $' + formatNumber(element.groupRate)}</span>
                          <IconRight size={16} color='graytext' />
                        </div>
                      </div>
                      <div className='borderTop mt-3' />
                    </>
                  ) : null
                }
              ) : null
            }
          )
        }
      </div>
      <label className='h4 font-weight-bold mt-6'>{translate('educator.modal.summary.documents')}</label>
      <div className='pr-6 mt-3'>
        <div className={`mx-0 my-1 px-2 py-1 znk-card upcomming`}>
          <div className='left'>
            <IconDocument />
            <div className={`text upcomming-text`}>Educator Handbook</div>
          </div>
          <div className={`text upcomming-text`}>
            <Link to={{ pathname: `${educator?.educatorInfo?.handbook}` }} target="_blank" style={{color: 'white'}}>
              <IconRight />
            </Link>
          </div>
        </div>
        <div className={`mx-0 my-1 px-2 py-1 znk-card upcomming mt-2`} style={{backgroundColor: '#72809d'}}>
          <div className='left'>
            <IconDocument />
            <div className={`text upcomming-text`}>W-9</div>
          </div>
          <div className={`text upcomming-text`}>
            <IconRight />
          </div>
        </div>
        <div className={`mx-0 my-1 px-2 py-1 znk-card upcomming mt-2`} style={{backgroundColor: '#72809d'}}>
          <div className='left'>
            <IconDocument />
            <div className={`text upcomming-text`}>NDA</div>
          </div>
          <div className={`text upcomming-text`}>
            <IconRight />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Summary
