import { useSelector, useDispatch } from "react-redux";
import { SET_DATA_MODAL_FORM_LIST } from "../../../../store/modal/const";

const useViewInvoiceWithPymtItem = () => {

    const dispatch = useDispatch();
    const reduxData = useSelector((state) => state.modal.payments.viewInvoiceWithPymtItem);
    const { isOpen, invoiceId, paymentItemId } = reduxData;
    const setInvoiceData = (invoiceId) => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'payments.viewInvoice',
            data: {
                ...reduxData,
                invoiceId,
            },
        })
    }
    const setPaymentId = (paymentItemId) => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'payments.viewInvoiceWithPymtItem',
            data: {
                ...reduxData,
                paymentItemId,
            },
        })
    }
    const setOpen = (open, paymentItemId = '') => {
            dispatch({
                type: SET_DATA_MODAL_FORM_LIST,
                payload: 'payments.viewInvoiceWithPymtItem',
                data: {
                    ...reduxData,
                    paymentItemId,
                },
            });
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'payments.viewInvoice',
            data: {
                ...reduxData,
                isOpen: open,
            },
        });
    };

    return {
        isOpen,
        invoiceId,
        open: (paymentItemId) => { setOpen(true, paymentItemId) },
        close: () => { setOpen(false) },
        toggle: () => { setOpen(!isOpen) },
        setInvoiceData,
        setPaymentId,
        paymentItemId
    };
}

export default useViewInvoiceWithPymtItem;
