import React from 'react'
import {
  Avatar,
  DropDown,
  IconChecboxSelected,
  IconCheckboxFilled,
  IconOptions,
  IconSignPlus,
  Input,
  ListItem
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../i18n/useTranslation'

const IndividualEducator = (props) => {
  const { edu, changeWage, removeEducator, updateLink, location, getBackupLink, changeWaive } = props
  const { translate } = useTranslation()
  return (
    <ListItem
      className='py-1'
      label={null}
      Left={
        <div className='d-flex align-items-center'>
          <Avatar
            name={`${edu.firstName} ${edu.lastName}`}
            named={false}
            uri={null}
            size='avatar-medium'
          />
          <label className='ml-2 h4'>
            {`${edu.firstName} ${edu.lastName}`} <span className='text-muted font-weight-light'>{`(Individual, ${edu.topic.name})`}</span>
          </label>
        </div>
      }
      Right={
        <div className='znk-table-dropdown p-0 m-0'>
          <DropDown
            classNameMenu='pt-4 px-4 pb-2'
            direction='bottom'
            fixedWidth
            hoverEnabled={false}
            options={[
              {
                component: ['hangouts', 'zoom'].includes(location.type)
                  ? <div className='py-4 px-1'>
                    <div>
                      <label className='h4 text-gray mb-3'>Location Link:</label>
                      <Input
                        placeholder={location.type === 'hangouts' ? 'http://hangouts.com/ASDF' : 'http://zoom.com/ASDF'}
                        type='text'
                        value={location.type === 'hangouts' ? getBackupLink(edu.educatorUserId) : edu.lessonLink}
                        readOnly={location.type === 'hangouts'}
                        onChange={(e) => {
                          updateLink(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  : null
              },
              {
                component:
  <div className='py-4 px-1'>
    {
      !edu.isWaiveIndividual
        ? <div>
          <label className='h4 text-gray mb-3'>{translate('modal.newplan.educatorwage')} :</label>
          <Input
            placeholder={translate('modal.newplan.wage')}
            type='number'
            value={edu.rate}
            onChange={(e) => {
              changeWage(parseFloat(e.target.value))
            }}
          />
        </div>
        : null
    }
    <label
      className='h4 mt-3 btn-link text-gray d-flex flex-row'
      onClick={() => {
        changeWaive(!edu.isWaiveIndividual)
      }}
    >
      {
        edu.isWaiveIndividual
          ? <IconChecboxSelected flex={1} color='#696CFF' className='icon-list-option mr-2' />
          : <IconCheckboxFilled flex={1} color='#F0F4F8' className='icon-list-option mr-2' />
      } Waive this payments
    </label>

    <div className='borderTop my-2' />
    <label
      className='h4 mt-4 btn-link texterror d-flex flex-row'
      onClick={removeEducator}
    >
      <IconSignPlus className='mr-2 text-error' />{translate('modal.newplan.removeeducator')}
    </label>
  </div>
              }
            ]}
          >
            <IconOptions className='mb-1' style={{ transform: 'rotate(90deg)' }} />
          </DropDown>
        </div>
      }
    />
  )
}

export default IndividualEducator
