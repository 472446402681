import React, {useEffect} from 'react'
import {
 ListItem,
 IconLink,
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch} from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'
import useGetChargeFromStripe from '../../../../../Model/Stripe/GetChargeFromStripe'
import useCopyText from '../../../../../common/CopyText/useCopyText'
import { showAlert } from '../../../../../common/Alert/util'
import receipt from '../../../../../assets/receipt.svg'
const ReceiptComponent = (props) => {
  const {
    item,
  } = props
const { translate } = useTranslation()
const dispatch = useDispatch()
const [getReceipt, {data: dataReceipt}] = useGetChargeFromStripe()
const [copyText, setCopyText] = useCopyText()
const copyFn = (link) => {
    setCopyText(link, copySuccess, copyError)
}

const [copySuccess, copyError] = [
    (text) => {
        //success
        showAlert({ text: translate("payments.receipt.copySuccess"), status: 'success' }, dispatch)
    },
    (error, text) => {
        //error
        showAlert({ text: `${translate("payments.receipt.copyError")} \n ${copyText}`, status: 'error' }, dispatch)
    }
]
useEffect(
    () => {
        if(item?.id) {
            getReceipt({
                variables: {invoiceId: item.id}
            })
        }
        // eslint-disable-next-line
    }, [item]
)

  return (
    dataReceipt?.getChargeFromStripe ?

        (<ListItem
        className={`mt-1 w-100 pl-2 pr-3`}
        hasHover={false}
        showTopLine={false}
        showBottomLine={true}
        // eslint-disable-next-line
        Left={<div>
           <img
            src={receipt}
            alt='receipt'
            color='#818ea7'
            width='18'
            height='18'
          />
          {/* eslint-disable-next-line */}
            <a href={dataReceipt?.getChargeFromStripe} style={{textDecoration: 'none', color: 'inherit'}}
        // eslint-disable-next-line
        target="_blank"><span className='text-bold ml-2'>{translate('payments.receipt.text')}</span></a>
        </div>
        }
        Right={
            <IconLink size={16} color={'gray'} onClick={()=>{copyFn(dataReceipt?.getChargeFromStripe)}}/>
            }
      />) : null
    
  )
}

export default ReceiptComponent
