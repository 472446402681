import { gql, useLazyQuery } from '@apollo/client'

const useSummary = () => {
  return useLazyQuery(
    gql`
        query (
            $id: String!
            $studentsUserIds: [ID!]  
            $kind: [PlanKind!]!
            $status: [PlanStatus!]!
          ){
          user(id: $id){
            id
            isActive
            avatar
            emails {
              address
              verified
            }
            countryId
            country {
              id
              name
              code
              dialCode
              createdAt
              updatedAt
            }
            gender
            spokenLanguages
            address
            city
            state
            zipCode
            firstName
            lastName
            phone
            timezone
            birthdate
            stripeId
            paymentMethod {
              ... on BankCreditPaymentMethod {
                kind
                sourceInfo {
                  accountNumber
                  routingNumber
                  bankName
                  swiftCode
                }
              }
              ... on BankDebitPaymentMethod {
                kind
                validationStatus
                accountInfo {
                  bankName
                  last4
                }
              }
              ... on ManualPaymentMethod {
                kind
                manualPaymentInfo {
                  bankName
                  accountNumber
                  routingNumber
                  beneficiary
                  address
                  swiftCode
                }
              }
              ... on CardPaymentMethod {
                kind
                last4
                brand
                expMonth
                expYear
                cardInfo {
                  brand
                  last4
                  expYear
                  expMonth
                }
              }
              ... on ZellePaymentMethod {
                kind
                zellePaymentInfo {
                  emailToSend
                }
              }
            }
            registeredPaymentMethods {
              id
              kind
              ... on CardPaymentMethod {
                cardInfo {
                  last4
                  brand
                  expYear
                  expMonth
                  fingerPrint
                }
              }
              ... on BankDebitPaymentMethod {
                validationStatus
                accountInfo {
                  bankName
                  last4
                }
              }
              ... on BankCreditPaymentMethod {
                status
                sourceInfo {
                  bankName
                  accountNumber
                  swiftCode
                  routingNumber
                }
              }
              ... on ManualPaymentMethod {
                manualPaymentInfo {
                  bankName
                  accountNumber
                  routingNumber
                  beneficiary
                  address
                  swiftCode
                }
              }
              ... on ZellePaymentMethod {
                kind
                zellePaymentInfo {
                  emailToSend
                }
              }
            }
            studentInfo {
              institution
              graduationDate
              studentType
              defaultRate
              gpa
              servicesRate {
                defaultRate
                serviceId
                name
              }
              parents {
                parentUserId
                managePayments
                manageApprovals
                firstName
                lastName
                email
                phone
              }
              notifications {
                missedLesson
                lessonsNotesReady
                lessonReminder {
                  isAvailable
                  duration
                }
              }
              linkedEducators {
                educatorUserId
                firstName
                lastName
                topicCount {
                  topicId
                  name
                  count
                  kinds
                }
              }
              upcomingLesson {
                title
                status
                startDate
                endDate
                educator {
                  educatorUserId
                  firstName
                  lastName
                  attendanceStatus
                }
                substitute {
                  educatorUserId
                  firstName
                  lastName
                  attendanceStatus
                }
                topic {
                  id
                  name
                }
                service {
                  id
                  name
                }
              }
            }
            parentInfo {
              children {
                studentUserId
                managePayments
                manageApprovals
                firstName
                lastName
                email
              }
            }
            educatorInfo {
                id
                services {
                  serviceId
                  name
                  defaultRate
                  topics {
                    topicId
                    teachingTypes
                    name
                  }
                }
              availabilityBlocks {
                day
                hour
              }
              backupLink
              




            }
          }
          plans(
                studentsUserIds: $studentsUserIds
                kind: $kind
                status: $status
              ){
                hasNext
                hasPrevious
                totalDocs
                docs {
                  id
                  name
                  cancelDate
                  pauseDate
                  resumeDate
                  studentUser {
                    studentUserId
                    firstName
                    lastName
                    rate
                    isManualRate
                  }
                  serviceId
                  lessonIds
                  paymentItemIds
                  paymentItemsConfig {
                    amount
                    percentDiscount 
                    amountWithoutDiscount
                    date
                    description
                    type
                  }
                  recommendedEducators {
                    educatorUserId
                    firstName
                    lastName
                    rate
                    isManualRate
                    topicId
                    teachingType
                    isLockEducator
                    lessonLink
                  }
                  selectedEducators {
                    educatorUserId
                    firstName
                    lastName
                    rate
                    isManualRate
                    topicId
                    lessonLink
                  }
                  status
                  kind
                  createdAt
                  updatedAt  
                  isFirstPlan
                  typePlanPricing
                  pricingRate
                  groupPricingRate
                  location {
                    type
                    linkEducator
                    linkStudent
                  }
                  groupId
                  from
                  to
                  ... on GroupPLan {
                  PartsSchedule {
                    from
                    to
                    lessonsPerWeekGroup {
                      topicId
                      educatorId
                      day
                      duration
                      hour
                      totalLessons
                    }
                    lessonsPerWeekIndividual {
                      topicId
                      lessonsPerWeek
                      lockLessons
                      totalLessons
                    }
                  }
                  studentPlans {
                    studentId
                    planId
                    registerDate
                    planStatus
                  }
                  lessonGroupIds
                  childPlans {
                    id
                    name
                    cancelDate
                    pauseDate
                    resumeDate
                    studentUser {
                      studentUserId
                      firstName
                      lastName
                      rate
                      isManualRate
                    }
                    serviceId
                    lessonIds
                    paymentItemIds
                    paymentItemsConfig {
                      amount
                      percentDiscount 
                      amountWithoutDiscount
                      date
                      description
                      type
                    }
                    recommendedEducators {
                      educatorUserId
                      firstName
                      lastName
                      rate
                      isManualRate
                      topicId
                      teachingType
                      isLockEducator
                      lessonLink
                    }
                    selectedEducators {
                      educatorUserId
                      firstName
                      lastName
                      rate
                      isManualRate
                      topicId
                      lessonLink
                    }
                    status
                    kind
                    createdAt
                    updatedAt  
                    isFirstPlan
                    typePlanPricing
                    pricingRate
                    groupPricingRate
                    location {
                      type
                    }
                    groupId
                    from
                    to
                    testDate
                    ... on LessonsFixedPlan {
                      fixedParts: parts {
                        TotalPerTopic {
                          topicId
                          lessonsPerWeek
                          lockLessons
                          totalLessons
                        }
                        type
                        totalLessons
                        from
                        to
                        name
                      }
                    }
                    ... on LessonsPerWeekPlan {
                      testDate
                      parts {
                        lessonPerWeekPerTopic {
                          topicId
                          lessonsPerWeek
                          lockLessons
                          totalLessons
                        }
                        lessonsPerWeek
                        totalLessons
                        from
                        to
                        name
                      }
                    }          
                  }
                  }
                  ... on LessonsFixedPlan {
                    fixedParts: parts {
                      TotalPerTopic {
                        topicId
                        lessonsPerWeek
                        lockLessons
                        totalLessons
                      }
                      type
                      totalLessons
                      from
                      to
                      name
                    }
                    groupFixedInfo{
                        PartsSchedule {
                            lessonsPerWeekGroup {
                                topicId
                                educatorId
                                day
                                duration
                                hour
                                totalLessons
                            }
                        }
                    }
                  }
                  ... on LessonsPerWeekPlan {
                    testDate
                    parts {
                      lessonPerWeekPerTopic {
                        topicId
                        lessonsPerWeek
                        lockLessons
                        totalLessons
                      }
                      lessonsPerWeek
                      totalLessons
                      from
                      to
                      name
                    }
                    groupPerWeekInfo{
                        PartsSchedule {
                            lessonsPerWeekGroup {
                                topicId
                                educatorId
                                day
                                duration
                                hour
                                totalLessons
                            }
                        }
                    }
                  }
                }
          }
        }`
  )
}

export default useSummary
