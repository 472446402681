import React from 'react'
import {DropDown, IconOptions} from '@Knowledge-OTP/znk-ui-components'
import List from './List'

const PendingList = (props) => {
    const {lesson, createdDate, updateDate} = props
    return (
        <div className='znk-table-dropdown dropdown-menu-width-content pr-0'>
            <DropDown
                direction='down'
                fixedWidth
                hoverEnabled={false}
                options={[{
                    component: <List lesson={lesson}
                                     createdDate={createdDate}
                                     updateDate={updateDate}/>
                }]}
            >
                <IconOptions style={{transform: 'rotate(90deg)', marginRight: 10}}
                             color='rgba(37, 64, 80, 0.6)'/>
            </DropDown>
        </div>
    )
}

export default PendingList
