import React, { useMemo } from 'react'
import {
    IconDuplicate,
    IconEdit,
    IconStundent,
    IconEmail,
    IconComment,
} from '@Knowledge-OTP/znk-ui-components'
import { useSelector } from 'react-redux'
import useViewTaskModal from '../../useViewTaskModal'
import useNewTaskModal from '../../../newTaskModal/useNewTaskModal'
import useDeleteTaskModal from '../../../deleteTaskModal/useDeleteTaskModal'
import useAssingTaskModal from '../../../assingTaskModal/useAssignTaskModal'
import useSendEmailReminder from '../../../sendEmailReminder/useSendEmailReminder'

const useOptionsByStatus = (status = 'unassigned', task, useMutation = false) => {
    const { openToEdit } = useNewTaskModal()
    const { onChange, taskReducer: { setData } } = useViewTaskModal()

    const { open: openToDelete } = useDeleteTaskModal()

    const { open: openEmailReminder, close: closeEmailReminder } = useSendEmailReminder()

    const { open: openAssignTask } = useAssingTaskModal()
    const meId = useSelector((state) => state.user.id)


    const notAssignToStudent = useMemo(
        () => {
            return task?.userId /**tarea está asignada**/ && task?.userId !== task?.dataPlan?.studentUser?.studentUserId /**y no está asignada al estudiante**/
        }, [task]
    )

    const assignOption = (task) => {
        return {
            Icon: IconStundent,
            left: task?.status === 'review' ? `Assign to student` : 
            notAssignToStudent  ? `Assign to student` : `Assign to me`,
            onClick: () => {
                const userId = task?.status === 'review' ? task?.dataPlan?.studentUser?.studentUserId :
                notAssignToStudent ? task?.dataPlan?.studentUser?.studentUserId : meId
                openAssignTask((newTask) => {
                    //actualiza task en viewTaskModal
                    setData({ ...newTask, name: newTask?.taskName })
                    //ejecuta onchange de viewTaskModal
                    onChange('assign', newTask)
                }, { ...task, userId }, useMutation /**useMutation**/)
            },
            iconClass: '',
            status: 'primary',
        }
    }

    const options = useMemo(() => {
        let baseOptions = []
        baseOptions = baseOptions.concat([
            {
                Icon: IconEdit,
                left: 'Edit task',
                onClick: () => {
                    openToEdit(task, (newTask) => {
                        //actualiza task en viewTaskModal
                        setData({ ...newTask, name: newTask?.taskName }, onChange)
                        //setea información actualizada en template
                        onChange('edit', { ...newTask, name: newTask?.taskName })
                    }, useMutation /**useMutation**/)
                },
                iconClass: '',
                status: 'primary',
            }
        ])
        if (!task?.isCreated) {
            baseOptions.push(
                {
                    Icon: IconDuplicate,
                    left: 'Duplicate task',
                    onClick: () => {
                        onChange('duplicate', task)
                    },
                    iconClass: '',
                    status: 'primary',
                }
            )
        }
        baseOptions.push(
            {
                Icon: null,
                left: 'Need to cancel this task?',
                onClick: () => {
                    openToDelete((newTask) => {
                        //actualiza task en viewTaskModal
                        setData({ ...newTask, name: newTask?.taskName })
                        onChange('delete', newTask)
                    }, task, useMutation /**useMutation**/)
                },
                iconClass: '',
                status: 'text-gray',
                showArrow: false,
                showBottomLine: true,
                className: `h45 btn-link text-gray`
            }
        )
        if (['pending', 'review'].includes(status) && task?.isCreated) {
            baseOptions = [
                {
                    Icon: IconComment,
                    left: `Chat with student`,
                    onClick: () => {
                        
                    },
                    iconClass: '',
                    status: 'primary',
                    key: 'chatWithStudent'
                },
            ].concat(baseOptions)
        }
        if ([/**'completed', 'canceled',**/'pending', 'unassigned'].includes(status) && task?.isCreated) {
            baseOptions = [
                {
                    Icon: IconEmail,
                    left: `Send Reminder`,
                    onClick: () => {
                        openEmailReminder(
                            () => {
                                closeEmailReminder()
                            }, task
                        )
                    },
                    iconClass: '',
                    status: 'primary',
                },
                {...assignOption(task)},
            ].concat(baseOptions)
        }
        if (['review'].includes(status) && task?.isCreated) {
            baseOptions = [
                {...assignOption(task)},
            ].concat(baseOptions)
        }
        return baseOptions
        // eslint-disable-next-line
    }, [status, task])

    return {
        options: options,
        parseToDropdown: (options) => {
            return options.map(({ left, onClick }) => {
                return {
                    component: <span
                        onClick={() => {
                            if (onClick) onClick()
                        }}
                        className={`h4 text-logo`}
                    >{left}</span>
                }
            })
        }
    }
}

export default useOptionsByStatus