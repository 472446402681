import React from 'react'
import { SelectUser, Button, ConfirmModal } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
const CounselorSelector = (props) => {

    const { title,
        onClick = () => { },
        activeUsers = [],
        selectUser,
        setSelectUser,
        ordenateUserArray,
        isOpenConfirmModal,
        setIsOpenConfirmModal,
        activity,
    } = props
    const toggle = () => {setIsOpenConfirmModal(!isOpenConfirmModal) }
    const {translate, replaceVariables} = useTranslation()
    let userInfo = activeUsers?.find((user) => user.id === selectUser)
    
    return (

        <div className='mt-4'>
            <span>{title}</span>
            <div className='d-flex flex-row mb-4 mt-1'>
                {
                    activeUsers?.length > 0 &&
                    <SelectUser
                        className={'my-2 w-40'}
                        activeUsers={activeUsers}
                        selectUser={selectUser}
                        setSelectUser={setSelectUser}
                        ordenateUserArray={ordenateUserArray}
                    />
                }
                <Button
                    color='primary'
                    activity={false}
                    className='h-60 mt-1 col-2 ml-2'
                    label={'Save'}
                    onClick={() => {setIsOpenConfirmModal(true)}}
                    style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                />
            </div>
            <ConfirmModal
                className='m-4'
                isOpen={isOpenConfirmModal}
                toggle={toggle}
                loading={null}
                activity={activity}
                title={translate(
                    `globalCounselors.title`,
                  )}
                body={replaceVariables(
                    translate(
                      `globalCounselors.body`,
                    ),
                    {COUNSELOR: `${userInfo?.firstName} ${userInfo?.lastName}`},
                  )}
                classNameActionDiv='d-flex flex-column justify-content-end w-80'
                confirmAction={()=>{onClick()}}
                cancelAction={toggle}
                confirmText={translate(
                    `globalCounselors.confirmText`,
                  )}
                cancelText={translate(
                    `globalCounselors.cancelText`,
                  )}
            />
        </div>


    )
}

export default CounselorSelector







