import moment from 'moment'
import {useDispatch} from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'

const useCalendar = () => {
    const dispatch = useDispatch()
    const {translate} = useTranslation()
    const getLessonStatus = (lesson = {}, upcomming) => {
        //scheduled, incomplete, finished, draft, upcoming, attended, missed
        let status =
        (lesson.__typename === 'Counselor')
          ? 'scheduled' // orientación
          : (lesson.status === 'scheduled')
            ? moment(lesson.endDate) < moment() // lección esta scheduled (no finished) y ya terminó
              ? 'incomplete' : 'scheduled'
            : lesson.status === 'finished'
              ? lesson?.notes?.status === 'draft' ? 'incomplete' : 'attended'
              : (['draftPending', 'occurrence', 'draft', 'pending'].includes(lesson.status)) ? 'draft' : 'upcoming'
      
      // setea status a lección upcoming
      if (upcomming && lesson?.id === upcomming) status = 'upcoming' // upcoming se define desde query lo usa
      // else if(index === indexUpcomming && status === 'scheduled') status = 'upcoming' //sino verifica el indice

      let hasMissed = false
      //EDUCADOR
      const oficialEducator = lesson?.substitute || lesson?.educator
      const noshow = oficialEducator?.attendanceStatus === 'missed' // falto el educador/substituto
      
      const stu = lesson.studentPlan || lesson.student || lesson.studentGroup || {}
      let missed = false
      if (status === 'attended') {
        if (Array.isArray(stu)) {
          missed = stu.reduce((missedBool, student) => {
            if (student.attendanceStatus === 'attended') return false
            else if (student.attendanceStatus === 'missed') hasMissed = true
            return missedBool
          }, true)
        } else {
          missed = stu.attendanceStatus === 'missed'
        }
        if (oficialEducator?.attendanceStatus === 'missed' || missed) status = 'missed'
      }
      return {status, hasMissed, noshow}
    }

  const parseLesson = (lessonsArray, orientationId, upcomming) => {
    // obtiene el indice de la proxima lección
    return lessonsArray.map((lesson, index) => {
      const start = moment(lesson.startDate)
      let duration = moment.duration(moment(lesson.endDate).diff(start)).asMinutes()
      duration = duration < 20 ? 20 : duration // si la duration es menor que 20 la altura del bloque es demasiado pequeña
      const day = start.clone().day()
      // estados en calendario: attended - missed - scheduled - upcoming - incomplete
      /** estados en backend :
         *  draft
            finished
            scheduled**/
      let {status, hasMissed, noshow} = getLessonStatus(lesson, upcomming)
      const oficialEducator = lesson?.substitute || lesson?.educator
      let student = lesson?.student || lesson?.studentPlan || lesson?.studentGroup || []
      if(!Array.isArray(student)) student = [student]

      return {
        idLesson: lesson.id,
        day: day === 0 ? 6 : day - 1,
        hour: start.clone().hour(),
        minutes: start.clone().minutes(),
        durationMin: duration,
        title: `${lesson.title || (lesson.counselorId ? 'Counselor' : 'No defined')}${status==='draft'?' (Reservation)':''}`,
        subtitle:
            oficialEducator
                ? (noshow && ['attended', 'missed'].includes(status))
                    ? translate('modal.infolesson.noshow')
                    : `${oficialEducator.firstName} ${oficialEducator.lastName}`
                : lesson.counselorId ? 'Counselor' : 'No defined',
        type: duration <= 20 ? 'skinny' : duration <= 30 ? 'medium' : duration <= 45 ? 'normal' : 'big',
        status: status,
        semiAttended: (status === 'attended' && hasMissed),
        onClickLesson: () => {
          if (!lesson.counselorId /*&& lesson.status !== 'draft'*/) {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: `plan.${lesson?.service?.id === orientationId ? 'orientationLessonModal' : 'infoLessonModal'}`,
              data: {
                isOpen: true,
                dataLesson: lesson
              }
            })
          }
        },
        canChecked: true,
        isGroup: lesson.__typename === 'GroupLesson',
        student,
        educator: lesson?.educator || lesson?.substitute,
        service: lesson?.service,
        topic: lesson?.individualTopic || lesson?.planTopic || lesson?.groupTopic || {},
        idEducator: oficialEducator?.educatorUserId,
        disabled: lesson?.__typename === 'EducatorUnavailability',
        completeLesson: lesson,
      }
    })
  }
    return {
        parseLesson,
        getLessonStatus
        //scheduled, incomplete, finished, draft, upcoming, attended, missed
    }
}

export default useCalendar