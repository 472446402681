import React from 'react'
import useConfirmCloseModal from './useConfirmCloseModal'
import { ConfirmModal } from '@Knowledge-OTP/znk-ui-components'

const ConfirmCloseModal = () => {
    const {isOpen, toggle, onChange, close} = useConfirmCloseModal()
    return (
        <ConfirmModal 
            classNameActionDiv={`d-flex flex-row`}
            isOpen={isOpen}
            toggle={toggle}
            title={'Are you sure?'}
            body={'This action is gonna delete your advance.'}
            confirmAction={() => {
                onChange()
                close()
            }}
            cancelAction={() => {close()}}
            classNameConfirm={`text-primary`}
            confirmText={'Yes'}
            cancelText={'No'}
        />
    )
}

export default ConfirmCloseModal