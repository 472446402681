import {useEffect, useState} from 'react'
import {gql, useLazyQuery} from '@apollo/client'
const useUser = (userId) => {
    const [user, setUser] = useState(null)
    const [getUser, {loading}] = useLazyQuery(
        gql`
        query ($id: String!) {
            user (
                id: $id
            ) {
                id
                firstName
                lastName
                avatar
                studentInfo {
                    parents {
                        parentUserId
                        managePayments
                        manageApprovals
                        firstName
                        lastName
                    }
                }
            }
        }`
    )
    useEffect(() => {
        const variables = {id: userId}
        getUser({variables}).then(
            (res) => {setUser(res?.data?.user)},
            console.error
        )
        //eslint-disable-next-line
    }, [userId])

    return {
        user, loading
    }
}
export default useUser