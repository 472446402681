import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, IconClose, Form, Field, InputSelect, PlainTextInput, AmountInput, Button, IconOptions, StickyButton, LabeledField, IconErase } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import PlanStudent from '../../../Plans/modal/newPlan/Components/PlanStudent'
import formatNumber from '../../../../utils/formatNumber'
import createManualInvoice from './createManualInvoice'
import '../../index.css'
import Menu from './menu'
import { isEmpty, escapeRegExp } from 'lodash'
import validate from 'validate.js'
import { gql, useLazyQuery } from '@apollo/client'
import { categories, types } from '../../Payments/constants'
import moment from 'moment'
import constraints from './validation'
import { getPaymentItemEnum } from '../view/PaymentItemsEnum'
import SelectPlan from '../../../CounselingTemplates/Task/newTaskModal/Components/SelectPlan'

const NewInvoice = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.newInvoice)
  const { isOpen } = modalData
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    student: undefined,
    category: 'livePrep',
    invoiceDate: moment().format('YYYY-MM-DD'),
    dueDate: moment().add(7, 'days').format('YYYY-MM-DD'),
    idcategory: 'lessonsPayment',
    discount: 0,
    amount: 0,
    description: '',
    items: [{
      amount: '',
      discount: '0',
      description: '',
      type: 'lessonsPayment',
      errorType: '',
      errorDiscount: '',
      errorAmount: '',
      errorDescription: '',
      errorPlan: '',
      kind: 'custom-payment-item',
      planId: ''
    }],
    taxFee: 0,
    subTotal: 0,
    taxAmount: 0,
    total: 0,
    number: ''
  })
  const [errors, setErrors] = useState({})
  const [validationErrors, setValidationErrors] = useState({})
  const [getNumber, { data: dataNumber }] = useLazyQuery(
    gql`query($name: ID!) {
    getGlobalVar(name: $name) {
      name
      id
      isActive
      value
    }
  }`,
    {
      variables: { name: 'invoiceNumber' },
      fetchPolicy: 'network-only'
    })
  useMemo(() => {
    getNumber()
  }, [getNumber])
  const { submit, loading } = createManualInvoice((result) => {
    refetch()
    toggle()
  }, console.log)
  const toggle = () => {
    setErrors({})
    setForm({
      student: undefined,
      category: 'livePrep',
      invoiceDate: moment().format('YYYY-MM-DD'),
      dueDate: moment().add(7, 'days').format('YYYY-MM-DD'),
      idcategory: 'lessonsPayment',
      discount: 0,
      amount: 0,
      description: '',
      items: [{
        amount: '',
        discount: '0',
        description: '',
        type: 'lessonsPayment',
        errorType: '',
        errorDiscount: '',
        errorAmount: '',
        errorDescription: '',
        errorPlan: '',
        planId: ''
      }],
      taxFee: 0,
      subTotal: 0,
      taxAmount: 0,
      total: 0,
      number: ''
    })
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.newInvoice',
      data: {
        isOpen: false
      }
    })
  }
  const createItem = () => {
    setForm({
      ...form,
      items: {
        ...form.items,
        [Object.values(form.items).length]: {
          type: 'lessonsPayment',
          amount: 0,
          discount: 0,
          isDelete: false,
          isNewpayment: true,
          errorType: '',
          errorDiscount: '',
          errorAmount: '',
          errorDescription: '',
          errorPlan: '',
          kind: 'custom-payment-item',
          planId: ''
        }
      }
    })
    // setErrorItem({})
    // const newForm = {
    //   discount: form.discount,
    //   amount: form.amount,
    //   description: form.description
    // }
    // const validationResult = validate(newForm, constraintsItem)
    // if (validationResult !== undefined) {
    //   setErrorItem(validationResult)
    //   return
    // }
    // const Items = form.items
    // Items.push({
    //   amount: form.amount,
    //   discount: form.discount,
    //   description: form.description,
    //   type: form.idcategory
    // })

    // setForm({
    //   ...form,
    //   items: Items,
    //   subTotal: Items.reduce((sum, current) => sum + (parseFloat(current.discount) === 0 ? current.amount : (parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount)))), 0),
    //   taxAmount: parseFloat(form.taxFee) === 0 ? 0 : ((parseFloat(form.taxFee) / 100) * Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0)),
    //   total: (parseFloat(form.taxFee) === 0 ? Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0) : (Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0)) + ((parseFloat(form.taxFee) / 100) * Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0)))
    // })
  }
  useEffect(() => {
    const amountPayments = Object.values(form.items).reduce(
      (sum, current) => {
        sum += (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount)))
        return sum
      }
      , 0)
    const taxAmount = parseFloat(form.taxFee) === 0
      ? 0
      : ((parseFloat(form.taxFee) / 100) * (amountPayments))
    const total = parseFloat(form.taxFee) === 0
      ? amountPayments
      : amountPayments + ((parseFloat(form.taxFee) / 100) * (amountPayments))
    setForm({
      ...form,
      subTotal: amountPayments,
      taxAmount: taxAmount,
      total: total
    })
    // eslint-disable-next-line
  }, [form.items, form.taxFee])

  useEffect(() => {
    if (dataNumber) {
      setForm({
        ...form,
        number: 'INV-' + String(dataNumber.getGlobalVar.value).padStart(5, 0)
      })
    }
    // eslint-disable-next-line
  }, [dataNumber])
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key.split('.')[1]
        customError[keyVal] = translate('payments.newinvoice.errors.' + keyVal)
      })
      setErrors(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])
  useEffect(() => {
    setForm({
      ...form,
      dueDate: moment(form.invoiceDate).add(7, 'days').format('YYYY-MM-DD')
    })
    // eslint-disable-next-line
  }, [form.invoiceDate])
  const deleteItem = (index) => {
    if (index === 0 && form.items.length === 1) return
    setForm({
      ...form,
      items: Object.values(form.items).filter((_, i) => i !== index)
    })
    // const Items = form.items.filter((element, index) => index !== value)
    // setForm({
    //   ...form,
    //   items: Items,
    //   subTotal: (Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0)),
    //   taxAmount: parseFloat(form.taxFee) === 0 ? 0 : ((parseFloat(form.taxFee) / 100) * Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0)),
    //   total: (parseFloat(form.taxFee) === 0 ? Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0) : (Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0)) + ((parseFloat(form.taxFee) / 100) * Items.reduce((sum, current) => sum + (current.discount === 0 ? current.amount : parseFloat(current.amount) - ((parseFloat(current.discount) / 100) * parseFloat(current.amount))), 0)))
    // })
  }
  const onSubmit = (value) => {
    setErrors({})
    const newVal = {
      category: form.category,
      invoiceDate: form.invoiceDate,
      dueDate: form.dueDate,
      paymentItems: Object.values(form.items).filter(e => !e.isDelete).map(
        element => {
          return {
            amount: element.amount,
            discount: element.percentDiscount,
            description: element.description,
            type: element.type,
            kind: element.kind || 'custom-payment-item',
            planId: element.planId
          }
        }
      ),
      taxFee: form.taxFee,
      userId: form.student ? form.student.id : undefined,
      status: value
    }
    let firstError = false
    const errorsVal = validate({ newInvoice: newVal }, constraints)
    if (errorsVal !== undefined) {
      setValidationErrors(errorsVal)
      firstError = true
    }
    let hasError = false
    const itemValNew = Object.values(form.items).reduce(
      (items, current, index) => {
        if (!hasError && (isNaN(current.discount) || parseFloat(current.discount) < 0 || isNaN(current.amount) || parseFloat(current.amount) === 0 || isEmpty(current.description) || current.type === undefined || isEmpty(current.description))) hasError = true
        return {
          ...items,
          [index]: {
            ...current,
            errorDiscount: isNaN(current.discount) ? 'amount' : parseFloat(current.discount) < 0 ? 'amount' : '',
            errorAmount: isNaN(current.amount) ? 'amount' : parseFloat(current.amount) === 0 ? 'amount' : '',
            errorDescription: isEmpty(current.description) ? 'description' : '',
            errorType: current.type === undefined || isEmpty(current.type) ? 'type' : '',
            errorPlan: current.type === "studyplan" && current.planId === '' ? 'plan' : ''
          }
        }
      }
      , {})
    console.log({xx: form.items, itemValNew})
    if (hasError || firstError) {
      setForm({
        ...form,
        items: itemValNew
      })
      return
    }
    newVal.paymentItems = newVal.paymentItems.map(e => ({
      ...e,
      type: e.type === 'studyplan' ? 'lessonsPayment' : e.type,
      kind: getPaymentItemEnum(e.type === 'studyplan'  ? 'plan-payment-item' : e.kind)
    }))
    console.log({ newInvoice: newVal })
    submit({ newInvoice: newVal })
  }
  const newSaveInvoice = (value) => {
    onSubmit(value)
  }
  const newAproveInvoice = (value) => {
    onSubmit(value)
  }
  const isNaN = (value) => {
    return ((Number.isNaN(value)) || value === '')
  }
  return (
    <>
      <div className='m-0 p-0' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        className='modal-sidebar modal-colored white-modal modal-desktop-half pl-3'
        isOpen={isOpen}
        toggle={toggle}
        backdrop='static'
        Head={
          <div className='modal-header p-0 m-0'>
            <div className='d-flex justify-content-between w-100 p-7'>
              <h2 className='font-weight-bold'>
                {
                  translate('payments.addNewInvoice')
                }
              </h2>
              <IconClose onClick={toggle} className='text-gray' />
            </div>
          </div>
        }
      >
        <Form value={form} onChange={setForm} validationErrors={errors}>
          <div className='d-flex flex-column'>
            <div className='px-7'>
              <hr className='mt-1 mb-4' />
              <PlanStudent form={form} setForm={setForm} first={false} title={false} invoice />
              {!isEmpty(errors) && validationErrors && validationErrors['newInvoice.userId'] && <div className='text-error'>{validationErrors['newInvoice.userId'][0]}</div>}
              <hr className='mt-1 mb-4' />
            </div>
            <div className='row d-flex m-0 mt-5 px-7'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.category')}</label>
                <Field
                  className='col-12 p-0'
                  component={InputSelect}
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                    return false
                  }}
                  type={LabeledField}
                  fieldName='category'
                  placeholder={translate('modal.newplan.selectservice')}
                  options={categories && [].concat(categories.map((serv) => ({ value: serv.id, label: translate(`payments.categories.${serv.id}`) })))}
                  InputClassName='w-100'
                />
              </div>
              <div className='col-6 pl-1 pr-0 pl-2'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.number')}</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='number'
                  fieldType='text'
                  value={dataNumber ? 'INV-' + String(dataNumber.getGlobalVar.value).padStart(5, 0) : ''}
                  readOnly
                />
              </div>
            </div>
            <div className='row d-flex m-0 mt-5 px-7'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.invoicedate')}</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='invoiceDate'
                  fieldType='date'
                />
                {/* {!isEmpty(errors) && errors.invoiceDate && <h5 className='text-error'>{translate('payments.editinvoice.errors.invoicedate')}</h5>} */}
              </div>
              <div className='col-6 pl-1 pr-0 pl-2'>
                <label className='text-gray font-weight-light mb-2'>{translate('payments.newinvoice.duedate')}</label>
                <Field
                  component={PlainTextInput}
                  type={LabeledField}
                  fieldName='dueDate'
                  fieldType='date'
                />
              </div>
            </div>
            <div className='px-7'>
              <hr className='mt-1 mb-4' />
            </div>
            <div className='row d-flex m-0 px-7'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>Item</label>
              </div>
              <div className='col-2 pl-1 pr-0 pl-2 text-right'>
                <label className='text-gray font-weight-light mb-2'>Discount</label>
              </div>
              <div className='col-4 pl-1 pr-0 pl-2 text-right'>
                <label className='text-gray font-weight-light mb-2'>Amount</label>
              </div>
            </div>
            <div className='px-7'>
              <hr className='mt-1 mb-4' />
            </div>
            {
              Object.values(form.items).map((element, index) => {
                return (
                  <div
                    key={`item-${index}`}
                    className='w-100 d-flex flex-row justify-content-between align-items-center px-7 mt-1 hover-button'
                  >
                    <div className=''>
                      {
                        index > 0 && <hr className='mt-1 mb-4' />
                      }
                      <div className='row d-flex m-0'>
                        <div className='col-5 p-0 pr-2'>
                          <Field
                            className='col-12 p-0'
                            type={InputSelect}
                            filterOption={(obj, string) => {
                              if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                              return false
                            }}
                            placeholder={translate('modal.newplan.selectservice')}
                            options={types.filter(e => e.type.includes(form.category === 'livePrep' ? 'invoice': 'counseling')).map((serv) => ({ value: serv.id, label: translate(`payments.types.${serv.id}`) })).concat(types.filter(t => t.id === 'other').map((serv) => ({ value: serv.id, label: translate(`payments.types.${serv.id}`) })))}
                            InputClassName='w-100'
                            value={element.type}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                items: { ...form.items, [index]: { ...element, type: e } }
                              })
                            }}
                          />
                          {element.errorType !== '' && (<h5 className='text-error'>{translate('payments.newinvoice.errors.type')}</h5>)}
                        </div>
                        <div className='col-3 pl-1 pr-0 pl-2 text-right'>
                          <Field
                            className='col-12 placeholder-to-right'
                            type={AmountInput}
                            fieldType='number'
                            placeholder='0.00'
                            addOn='%'
                            min='0'
                            value={element.discount}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                items: { ...form.items, [index]: { ...element, discount: e } }
                              })
                            }}
                          />
                          {element.errorDiscount !== '' && (<h5 className='text-error'>{translate('payments.newinvoice.errors.discount')}</h5>)}
                        </div>
                        <div className='col-4 pl-1 pr-0 pl-2 text-right'>
                          <Field
                            className='col-12 placeholder-to-right'
                            type={AmountInput}
                            fieldType='number'
                            placeholder='0.00'
                            addOn='$'
                            min='0'
                            value={element.amount}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                items: { ...form.items, [index]: { ...element, amount: e } }
                              })
                            }}
                          />
                          {element.errorAmount !== '' && (<h5 className='text-error'>{translate('payments.newinvoice.errors.amount')}</h5>)}
                        </div>
                      </div>
                      <div className='row d-flex m-0 p-0 mt-1 mb-3'>
                        <div className='col-12 p-0'>
                          <Field
                            type={PlainTextInput}
                            placeholder='Description'
                            fieldType='text'
                            value={element.description}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                items: { ...form.items, [index]: { ...element, description: e } }
                              })
                            }}
                          />
                          {element.errorDescription !== '' && (<h5 className='text-error'>{translate('payments.newinvoice.errors.description')}</h5>)}
                        </div>
                      </div>
                      {
                        element.type === "studyplan" && (
                          <div className='row d-flex m-0 p-0 mt-1 mb-3'>
                            <div className='col-12 p-0'>
                              <SelectPlan
                                userId={form.student ? form.student.id : undefined}
                                onChange={(value) => {
                                  setForm({
                                    ...form,
                                    items: { ...form.items, [index]: { ...element, planId: value.id } }
                                  })
                                }}
                                value={element.planId}
                              />
                              {element.errorPlan !== '' && (<h5 className='text-error'>{translate('payments.editinvoice.errors.plan')}</h5>)}
                            </div>
                          </div>
                        )
                      }
                    </div>
                    <div className='hover-button--on' onClick={() => { deleteItem(index) }}>
                      <IconErase size={18} color='#FF3E1D' />
                    </div>
                  </div>
                )
              })
            }
            <div className='px-7'>
              <hr className='m-0 mt-3 mb-1' />
              <label className='btn-link font-weight-light' onClick={createItem}>{translate('payments.newinvoice.addnewitem')}</label>
              <hr className='m-0' />
            </div>
            <div className='row d-flex m-0 mt-5 px-7'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>Subtotal</label>
              </div>
              <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                <label className='text-gray font-weight-light mb-2'>${formatNumber(form.subTotal)}</label>
              </div>
            </div>
            <div className='px-7'>
              <hr className='m-0 mt-1' />
            </div>
            <div className='row d-flex m-0 p-0 mt-4 mb-2 px-7' style={{ alignItems: 'center' }}>
              <div className='col-5 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2 font-weight-light'>{form.taxFee === 0 ? 'Tax' : `Credit Card Processing Fee at ${form.taxFee}`}</label>
              </div>
              <div className='col-3 pl-1 pr-0 pl-2 text-right'>
                <Field
                  className='col-12 placeholder-to-right'
                  component={AmountInput}
                  type={LabeledField}
                  fieldName='taxFee'
                  fieldType='number'
                  placeholder='0.00 '
                  addOn='%'
                  min='0'
                />
              </div>
              <div className='col-4 pl-1 pr-0 pl-2 text-right'>
                <label className='text-gray font-weight-light mb-2 font-weight-light'>${formatNumber(form.taxAmount)}</label>
              </div>
            </div>
            <div className='px-7'>
              <hr className='m-0 mt-1' />
            </div>
            <div className='row d-flex m-0 mt-5 px-7'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2 font-weight-bold'>Total price</label>
              </div>
              <div className='col-6 pl-1 pr-0 pl-2 text-right'>
                <label className='text-gray font-weight-light mb-2 font-weight-bold'>${formatNumber(form.total)}</label>
              </div>
            </div>
            <div className='px-7'>
              <hr className='m-0 mt-1' />
            </div>
            <div className='px-3 px-lg-5 px-xl-7 d-flex flex-column my-5'>
              <StickyButton>
                <div className='m-0 p-0 row'>
                  <Button
                    color='secondary'
                    activity={loading}
                    className='col-9'
                    label='Save in draft'
                    onClick={() => {
                      onSubmit('draft')
                    }}
                    style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                    check
                  />
                  <Menu newSaveInvoice={(value) => { newSaveInvoice(value) }} newAproveInvoice={(value) => { newAproveInvoice(value) }}>
                    <Button
                      className='ml-3'
                      color='secondary'
                      style={{ bottom: '10px', right: '25px', width: '50px' }}
                      label={<IconOptions style={{ transform: 'rotate(90deg)' }} />}
                    />
                  </Menu>
                </div>
              </StickyButton>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default NewInvoice
