import React, {useMemo, useEffect} from 'react'
import {Brand} from '@Knowledge-OTP/znk-ui-components'
// reducers
import useTemplatePdfReducer from '../../../reducers/template-pdf'

import useElementsHandler from '../../PdfEditor/Elements/useElementsHandler'
import useGetPdfTemplate from '../Model/getPdfTemplate'
import useMyPlan from '../Model/useMyPlan'
import moment from 'moment'


// const STUDENT_SIGN_PAGE = 6
// const PARENT_SIGN_PAGE = 7
// const STUDENT_SIGN_SECTION = 'body[0].childrens[23].childrens[0].childrens[1]'
// const PARENT_SIGN_SECTION = 'body[0].childrens[14].childrens[0].childrens[1]'

const PdfViewer = (props) => {
  const {
    counselingPlanId,
    isAdmin = false,
    setDocument = () => {},
    // ...otherProps
  } = props
  // obtiene info de plan
  const {data: dataPlan} = useMyPlan({
    planId: counselingPlanId,
    isAdmin,
  })

  const {replaceText} = useTemplatePdfReducer()
  const {RenderHtmlDocument} = useElementsHandler()


  // obtiene plantilla pdf según proposal version
  const {data} = useGetPdfTemplate({templateId: '620430da836c84607e510010'})

  // parse la plantilla con la información del plan

  // obtiene imagen de counseling timeline


  // documento pdf en formato HTML (con variables inyectadas)
  const {Html, jsonDocument} = useMemo(() => {
    if (data?.getPdfTemplate?.json && dataPlan) {
      const studentIsFamily =
        dataPlan?.myPlan?.aditionalInfo?.studentType === 'family'

      const studentNames = [
        dataPlan?.myPlan?.studentUser?.firstName,
        dataPlan?.myPlan?.studentUser?.lastName,
      ]

      // TEAM
      const counselorNames = [
        dataPlan?.myPlan?.councelor?.firstName,
        dataPlan?.myPlan?.councelor?.lastName,
      ]
      // const advisorNames = dataPlan?.myPlan?.advisor
      //   ? [
      //       dataPlan?.myPlan?.advisor?.firstName,
      //       dataPlan?.myPlan?.advisor?.lastName,
      //     ]
      //   : null
      // const headNames = dataPlan?.myPlan?.head
      //   ? [dataPlan?.myPlan?.head?.firstName, dataPlan?.myPlan?.head?.lastName]
      //   : null

      const startTime = dataPlan?.myPlan?.from
        ? moment(dataPlan.myPlan.from)
        : null
      const endTime = dataPlan?.myPlan?.to ? moment(dataPlan.myPlan.to) : null
      const jsonDocumentWithVars = replaceText(data.getPdfTemplate.json, {
        STUDENT_NAME: studentNames.join(' '),
        STUDENT_FIRST_NAME: studentNames[0],
        INITIALS_STUDENT_NAME: `${studentNames[0].substring(
          0,
          1,
        )}${studentNames[1].substring(0, 1)}`,

        // COUNSELOR
        COUNSELOR_NAME: counselorNames.join(' '),
        INITIALS_COUNSELOR_NAME: `${counselorNames[0].substring(
          0,
          1,
        )}${counselorNames[1].substring(0, 1)}`,
        // ADVISOR
        // ADVISOR_NAME: advisorNames.join(' '),
        // INITIALS_ADVISOR_NAME: `${advisorNames[0].substring(0, 1)}${advisorNames[1].substring(0, 1)}`,

        COUNSELING_PLAN_NAME: dataPlan?.myPlan?.name,
        FROM: startTime
          ? startTime.clone().format('MMMM DD, YYYY')
          : '[NO_DEFINED]',
        TO: endTime ? endTime.clone().format('MMMM DD, YYYY') : '[NO_DEFINED]',
        PARENT_NAME: studentIsFamily
          ? `${dataPlan?.myPlan?.aditionalInfo?.parentFirstName} ${dataPlan?.myPlan?.aditionalInfo?.parentLastName}`
          : '-',
        PARENT_FIRST_NAME: dataPlan?.myPlan?.aditionalInfo?.parentFirstName,
        CONTRACT_LENGTH: `${
          startTime ? startTime.clone().format('YYYY/MM/DD') : '[NO_DEFINED]'
        } - ${endTime ? endTime.clone().format('YYYY/MM/DD') : '[NO_DEFINED]'}`,
        GRADE: dataPlan?.myPlan?.studentUser?.grade?.replace('_', ''),
        COUNTRY: dataPlan?.myPlan?.aditionalInfo?.studentCountryName,
        DEAR: `${studentNames[0]}`,
      })

      let documentObj = JSON.parse(jsonDocumentWithVars)

      return {
        Html: RenderHtmlDocument([documentObj[1]]),
        jsonDocument: JSON.stringify([documentObj[1]]),
      }
    }
    return {Html: <></>, jsonDocument: null}
    // eslint-disable-next-line
    }, [data, dataPlan]) 

  // useEffect(() => {
  //   toPng(
  //     timelineRef?.current,
  //     // , {width: 500, height: 500}
  //   )
  //     .then((dataUrl) => {
  //       // console.log("dataUrl", dataUrl)
  //       setTimelineUrl(dataUrl)
  //     })
  //     .catch((error) => {
  //       console.error('oops, something went wrong!', error)
  //     })
  // }, [timelineRef, dataPlan])

  useEffect(() => {
    setDocument({Html, jsonDocument})
  }, [Html, jsonDocument, setDocument])
  return (
    <div className={'position-relative'}>
      {/* <PDFViewer {...otherProps}> */}
        <div className={`position-absolute`} style={{top: '50px', left: '38px'}}>
          <Brand />
        </div>
        {Html}
      {/* </PDFViewer> */}
    </div>
  )
}

export default PdfViewer
