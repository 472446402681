import React from 'react'
import { IconArrowDown, CheckboxSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'

const ServicesFilter = (props) => {
  const { translate } = useTranslation()
  const { services, serviceFilters, setServiceFilters } = props
  return (
    <CheckboxSelector
      setDataFilters={setServiceFilters}
      direction='bottom'
      dataFilters={serviceFilters}
      data={services.docs.map(item => ({ name: item.name, id: item.id })).sort((a, b) => a.name.localeCompare(b.name))}
      typeFilter='services'
      strings={{
        viewAll: translate('filters.plans.viewAll'),
        buttonCancel: translate('filters.plans.buttonCancel'),
        buttonApply: translate('filters.plans.buttonApply'),
        search: translate('filters.plans.search')
      }}
    >
      <Badge className='mr-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.plans.services.title')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default ServicesFilter
