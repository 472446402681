import React, { useEffect, useState } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import rolesNames from '../../../../../../../VirtualChat/utils/rolesNames'
import ViewCounselor from '../../../../Components/ViewCounselor'
import useUser from '../../Model/useUser'
import useUsersQuery from '../../../../../../../Student/modal/InfoStudent/useQueries/useGetStaff'

const ShowCounselorAssigned = (props) => {
    const { team, userAssignedId } = props
    const [selectUser, setSelectUser] = useState('')
    const [allStaff, setAllStaff] = useState([])
    const [getUser, {loading}] = useUser()
    const [usersQuery, { data: dataUsers }] = useUsersQuery()
    let selectedUser = {}
    useEffect(() => {
        if (team.length > 0) {
            if (userAssignedId) {
                // eslint-disable-next-line
                selectedUser = team.filter(user => user.id === userAssignedId)
                if(selectedUser[0]) setSelectUser(selectedUser[0])
                else {
                    getUser({variables: {id: userAssignedId}}).then(
                        (res) => {
                            setSelectUser(res?.data?.user)
                        },
                        console.error
                    )
                }
            } else {
                // eslint-disable-next-line
                selectedUser = team.filter(user => user.role === rolesNames.counselor)
                setSelectUser(selectedUser[0])
            }
        }  else if (allStaff.length > 0) {
            selectedUser = allStaff.filter(user => user.id === userAssignedId)
            setSelectUser(selectedUser[0])
        } 
    }, [team, allStaff])

    useEffect(() => {
        if(userAssignedId)  {
        usersQuery({
            variables: {
              role: 'SUPER_ADMIN', 
              $limit: 0, 
              previous: '', 
              next: '', 
              search: undefined, 
              serviceIds: [], 
              topicIds: [], 
              testDate: undefined, 
              classYear: [], 
              countriesIds: [], 
              statuses: true, 
              showInactive: false
            }
          })
        }
        // eslint-disable-next-line
    }, [userAssignedId])

    useEffect(() => {
      if(dataUsers?.allUsersFilter?.docs?.length > 0) {
        setAllStaff(dataUsers?.allUsersFilter?.docs)
      }
    }, [dataUsers])

    return (
        <TabContent 
            className='mb-3'
            activeTab={loading ? 'loading' : selectUser !== {} ? 'show' : 'notShow'}>
            <TabPane tabId={`notShow`}>
                {null}
            </TabPane>
            <TabPane tabId={`show`}>
               <ViewCounselor user={selectUser} />
            </TabPane>
            <TabPane tabId={`loading`}>
                LOADING
            </TabPane>
        </TabContent>

    )
}

export default ShowCounselorAssigned
