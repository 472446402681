import React from 'react'
import { InputSearchRef, IconSearch, IconSettings, IconInfo, ToolTipInfo, IconRepeat, IconLoad, useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import { nanoid } from 'nanoid'
import ResponsiveSpan from './ResponsiveSpan'
import InfoWidgetModal from './InfoWidgetModal'
import './index.css'

const CardHeader = (props) => {
    const tooltipId = nanoid()
    const { 
        useControls = true, filterSection = null, title = 'title', subtitle = '', 
        titleModalInfo='Information',
        innerInfo = <>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </>,
        onChange = () => {}, searchRef = null, useSearch = false, onReload = () => {}, className = '',
        loading, ...otherProps } = props

    const {breakWidth} = useWindowDimensions()  
    return (
        <div className={`widget-card-header ${className}`} {...otherProps}>
            <div className={`d-flex flex-column py-4 px-5`}>
                <div className={`d-flex flex-row w-100 justify-content-between align-items-center`}>
                    <label className={`text-title h4 font-weight-bold`}>{title} <span className={`text-gray h45`}>{subtitle}</span></label>
                    <div className='d-flex flex-row'>
                        {
                            useControls && (
                                <>
                                    <div className={`hover-icon mr-1`} onClick={onReload}>{loading ? <IconLoad size={15} /> : <IconRepeat className={`text-gray`} size={15} />}</div>
                                    <div className={`hover-icon mr-1`}><IconSettings className={`text-gray`} size={15} /></div>
                                    {
                                        !['MD', 'SM'].includes(breakWidth) ? 
                                            <ToolTipInfo
                                                place={'left'}
                                                tooltipId={tooltipId}
                                                type={'dark'}
                                                innerElement={<ResponsiveSpan innerInfo={innerInfo}/>}
                                            >
                                                <div className={`hover-icon`}><IconInfo className={`text-gray`} size={15} /></div>
                                            </ToolTipInfo> : 
                                            <InfoWidgetModal titleModal={titleModalInfo} innerInfo={innerInfo}>
                                                <div className={`hover-icon`}><IconInfo className={`text-gray`} size={15} /></div>
                                            </InfoWidgetModal>
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
                <div className={`w-100 d-flex flex-row align-items-center justify-content-between`}>
                    <div className={`m-0 p-0`}>{
                        filterSection
                    }</div>
                    <div className={`m-0 p-0 w-40`}>
                        {
                            useSearch && (
                                <InputSearchRef
                                    searchRef={searchRef}
                                    className={`mr-4`}
                                    placeholder="Search..."
                                    onChange={onChange}
                                    addOnComponent={<IconSearch size={20} className={`text-gray`} />}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardHeader