import {gql, useLazyQuery} from '@apollo/client'

const useCheckUsersLessonsMatch = () => {
    return useLazyQuery(
        gql`
            query (
                $userId: String!
                $isStudent: Boolean!
            ) {
                checkUsersLessonsMatch (
                    userId: $userId
                    isStudent: $isStudent
                ) {
                    studentUserId
                    firstName
                    lastName
                    lessons {
                        lessonId
                        id
                        status
                        startDate
                        endDate
                        title
                        kind
                        educator {
                            educatorUserId
                            firstName
                            lastName
                        }
                    }
                }
            }
        `
    )
}

export default useCheckUsersLessonsMatch