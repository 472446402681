import React, {useMemo} from 'react'
import Skeleton from 'react-loading-skeleton'
import {TabContent, TabPane} from 'reactstrap'
import useStudentWithoutTasks from '../../../../Model/useStudentWithoutTasks'
import StudentList from './Components/StudentList'
import StudentBlank from './Components/StudentBlank'
import useAllStudentModal from './Modal/AllStudentModal/useAllStudentModal'

const StudentWithoutTask = (props) => {
    const {onClickStudent, className = ''} = props
    const {data, loading} = useStudentWithoutTasks({limit: 5})
    const students = useMemo(
        () => {
            return data?.studentWithoutTasks || []
        }, [data]
    )

    const {open} = useAllStudentModal()
    return (
        <div className={`m-0 p-0 d-flex wdtr flex-column ${className} hidde-scroll-vertical`}>
            <div className={`d-flex flex-row justify-content-between align-items-center`}>
                <span className='h4 text-title' style={{ lineHeight: '3' }}>
                    Students without tasks
                </span>
                <span
                    onClick={() => {open((res) => {onClickStudent(res)})}}
                    className='btn-link h45 text-gray' style={{ lineHeight: '3' }}>
                        See more
                </span>
            </div>
            <TabContent activeTab={loading ? 'loading' : students.length > 0 ? 'show' : 'blank'}>
                <TabPane tabId={`loading`}>
                    <Skeleton className={`w-100`} height={100}/>
                </TabPane>
                <TabPane tabId={`show`}>
                    <StudentList students={students} onClickStudent={onClickStudent} />
                </TabPane>
                <TabPane tabId={`blank`}>
                    <StudentBlank/>
                </TabPane>
            </TabContent>
        </div>
    )
}

export default StudentWithoutTask