import { gql, useLazyQuery } from '@apollo/client'

const useGetEmailsFromPayslips = () => {
  return useLazyQuery(
    gql`query(
        $limit: Int,
        $next: String,
        $previous: String,
        $sortField: String,
        $userId: [String!],
        $from: DateTime,
        $to: DateTime,
        $status: [PaymentStatus!]
        $searchString: String,
        $pending: Boolean,
        $current: Boolean
      ) {
        payslips(
          limit: $limit,
          next: $next,
          previous: $previous,
          sortField: $sortField,
          status: $status, 
          userId: $userId,
          from: $from,
          to: $to,
          searchString: $searchString
          pending: $pending,
          current: $current
        ) {
          hasNext
          hasPrevious
          next
          previous
          totalDocs            
          docs {
            userId {
              emails {
                  address
                }
              studentInfo {
                  parents {
                      email
                  }
              }
            }
          }
        }
      }`,
    {
      fetchPolicy: 'network-only'
    }
  )
}

export default useGetEmailsFromPayslips