import { useDispatch, useSelector } from 'react-redux'

const useCancelPlanModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.cancelPlanModal)
    const {isOpen, onChange, planData} = reduxData

    const setOpen = (open, onChange, planData = null, useMutation = false, loading = false) => {
        const obj = {
            isOpen: open,
            onChange,
            useMutation,
            loading
        }
        if(planData) obj.planData = planData
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.cancelPlanModal',
            data: {
                ...reduxData,
                ...obj
            }
        })
    }

    const setLoad = (load = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.cancelPlanModal',
            data: {
                ...reduxData,
                loading: load
            }
        })
    }

    return {
        ...reduxData,
        onChange,
        isOpen,
        planData,
        open: (onChange = () => {}, planData = {}, useMutation = false, loading = false) => {
            setOpen(true, onChange, planData, useMutation, loading) 
        },
        close: () => {
            setOpen(false, onChange, planData)
        },
        toggle: () => {
            setOpen(!isOpen, onChange, planData)
        },
        setLoad
    }
}

export default useCancelPlanModal