import React, {useState, useEffect} from 'react'


const InputLabel = (props) => {
    const {value = '', label = 'Enter text', onChange = () => {}, className } = props
    // const refLabel = useRef(null)
    const [stateValue, setStateValue] = useState(null)
    useEffect(() => {
        if(value) setStateValue(value)
    }, [value])


    //espera a que el usuario termine de teclear
    // const delay = (fn, ms) => {
    //     let timer = 0
    //     return (...args) => {
    //       clearTimeout(timer)
    //       timer = setTimeout(fn.bind(...args), ms || 0)
    //     }
    // }
    return (
        <div className={`d-flex flex-column ${className}`}>
            <span className={`w-100`}>{label}</span>
            <textarea
                className={`znk-input form-control`}
                // onKeyUp={delay(() => {
                //     onChange({target: {value: stateValue}})
                //   }, 4000)}
                onChange={(e) => {
                    setStateValue(e.target.value)
                }}
                value={stateValue}
            />
            <button className={`w-100`} onClick={() => {
                onChange({target: {value: stateValue}})
            }}>Aplicar</button>
        </div>
    )
}

export default InputLabel