import React, {useEffect} from 'react'
import {
  Field,
  PlainTextInput,
  SelectOption
} from '@Knowledge-OTP/znk-ui-components'
import InputLpw from './InputLpw'
import useTranslation from '../../../../../i18n/useTranslation'

const ScheduleDetails = (props) => {
  const { setForm, form, currentService } = props
  const { translate } = useTranslation()
  const parts = Object.keys(form.lpw).length || 0
  function removePart(item) {
    let nums = 0
    const newLpw = Object.values(form.lpw).reduce((obj, part, idx) => {
      if (idx !== item) {
        obj[nums] = part
        obj[nums].partname = `Part ${nums + 1}`
        nums += 1
      }
      return obj
    }, {})
    setForm(pre => ({ ...pre, lpw: newLpw }))
  }
  if (form.typePlan === 'time' && parts > 1) {
    setForm(pre => ({ ...pre, lpw: { 0: form.lpw[0] } }))
  }

  useEffect(() => {
    //verifica si se deseleccionó algún tópico y elimina el educador asignado
    if(form?.educators) {
      const topicsIds = Object.values(form.lpw).reduce((arrayTopics, part) => {
        return arrayTopics.concat(Object.entries(part).filter(([key, value]) => {
          return value?.value > 0 && !["enddate","startdate","partname"].includes(key)
        }))
      }, []).map((item) => item[0])
      let newEducators = form.educators
      newEducators = Object.entries(newEducators).reduce((newObject, [idTopic, content]) => {
        if(topicsIds.includes(idTopic)) newObject[idTopic] = content
        return newObject
      }, {})
      if(Object.keys(newEducators).length > 0){
        setForm({
          ...form,
          educators: newEducators
        })
      } 
    }
    // eslint-disable-next-line
  }, [form.lpw, setForm])
  
  return (
    <>
      <div className='pl-0 pr-6 py-6 pl-3'>
        <h3><b>{translate('modal.newplan.scheduledetails.title')}</b></h3>
        <div className='row pl-3'>
          {new Array(parts).fill(0).map((zero, item) => {
            return (
              <div className='w-100' key={item}>
                <label className='col-12 text-muted mb-2 mt-3 pl-0'>
                  {translate('modal.newplan.scheduledetails.partname')}
                  {
                    parts > 1 && (
                      <span className='btn-link text-error float-right' onClick={() => removePart(item)}>
                        {translate('modal.newplan.scheduledetails.removePart')}
                      </span>
                    )
                  }

                </label>
                <Field
                  className='col-12'
                  type={PlainTextInput}
                  fieldName={`lpw[${item}].partname`}
                  placeholder={`Part ${item + 1}`}
                />
                <label className='col-12 text-muted mb-2 mt-3 pl-0'>
                  {
                    (form.typePlan === 'time' && form.typeLesson === 'totalLesson')
                      ? `${translate('modal.newplan.startdate')}` : `${translate('modal.newplan.startdate')} - ${translate('modal.newplan.enddate')}`
                  }
                </label>
                {
                  (form.typePlan === 'time' && form.typeLesson === 'totalLesson')
                    ? <Field
                      className='col-12'
                      type={PlainTextInput}
                      fieldName={`lpw[${item}].startdate`}
                      placeholder={translate('modal.newplan.startdate')}
                      fieldType='date'
                      value={form.lpw[item].startdate ? form.lpw[item].startdate.split('T')[0] : null}
                      minDate='2021-03-25T12:00:00.000Z'
                    />
                    : <div className='d-flex m-0 p-0 w-100' style={{ justifyContent: 'space-between' }}>
                      <Field
                        type={PlainTextInput}
                        fieldName={`lpw[${item}].startdate`}
                        placeholder={translate('modal.newplan.startdate')}
                        fieldType='date'
                        InputStyle={{ textAlignLast: 'center', width: '49%' }}
                        value={form.lpw[item].startdate ? form.lpw[item].startdate.split('T')[0] : null}
                        minDate='2021-03-25T12:00:00.000Z'
                      />
                      <Field
                        type={PlainTextInput}
                        fieldName={`lpw[${item}].enddate`}
                        placeholder={translate('modal.newplan.enddate')}
                        fieldType='date'
                        min={form.lpw[item].startdate ? form.lpw[item].startdate.split('T')[0] : null}
                        InputStyle={{ textAlignLast: 'center', width: '49%' }}
                        value={form.lpw[item].enddate ? form.lpw[item].enddate.split('T')[0] : null}
                      />
                      </div>
                }
                {
                  form.typePlan === 'time' &&
                    <>
                      <label className='col-12 text-muted mb-2 mt-3'>
                        {translate('modal.newplan.scheduledetails.lesson')}
                      </label>
                      <Field
                        className='col-12 row'
                        type={SelectOption}
                        fieldName='typeLesson'
                        options={[
                          { id: 'totalLesson', text: 'Total' },
                          { id: 'lessonPerWeek', text: translate('modal.newplan.scheduledetails.perweek') }
                        ]}
                        value={form.typeLesson}
                        StyleOptionClassName={{
                          width: '49%'
                        }}
                        StyleSelect={{
                          justifyContent: 'space-between'
                        }}
                      />
                    </>
                }
                {currentService
                  ? currentService?.topics?.map((topic) => {
                    return (
                      <InputLpw
                        title={
                          (form.typePlan === 'time' && form.typeLesson === 'totalLesson')
                            ? translate('modal.newplan.scheduledetails.totalLesson', { name: topic.name })
                            : translate('modal.newplan.scheduledetails.individualPerWeek', { name: topic.name })
                        }
                        topic={topic} part={item} form={form} setForm={setForm} lock={form.typePlan !== 'time'}
                      />
                    )
                  })
                  : null}
              </div>
            )
          })}
          {
            form.typePlan !== 'time' && (
              <label
                className='btn-link pt-3'
                onClick={() => {
                  setForm({ ...form, lpw: { ...form.lpw, [parts]: { partname: `Part ${parts + 1}` } } })
                }}
              >{translate('modal.newplan.scheduledetails.addnewpart')}
              </label>
            )
          }
        </div>
      </div>
      <div className='borderTop ml-3' />
    </>
  )
}

ScheduleDetails.propTypes = {
}

ScheduleDetails.defaultProps = {
}
export default ScheduleDetails
