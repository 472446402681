import React from 'react'
import {
    Avatar
} from '@Knowledge-OTP/znk-ui-components'
import {getValues} from '../../../../../../CounselingPlans/Columns/util'
import moment from 'moment'

const useDesktopColumns = () => {

    const getClassOf = (item) => {
        const graduationDate = item?.studentInfo?.graduationDate
        const classof = getValues(moment(graduationDate).toISOString(), 'class')
        return parseInt(classof) > 0 ? `‘${classof}` : null
    }

    return [
        { 
            render: item => {
                const studentName = `${item?.firstName} ${item?.lastName}`
                return (
                    <div className={`d-flex flex-row align-items-center m-0 p-0`}>
                        <Avatar
                            name={studentName}
                            uri={item?.avatar}
                        />
                        <span className={`ml-2`}>{studentName}</span>
                    </div>
                )
            }, 
            colSpan: 4, 
            id: 'studentName' 
        },
        { 
            render: item => {
                const classof = item.studentInfo?.classOf || getClassOf(item) || null
                return (
                    <span className={`text-gray`}>{classof ? `Class of ‘${classof}` : `-`}</span>
                )
            }, 
            className: `d-flex flex-row m-0 p-0 justify-content-center`,
            colSpan: 4, 
            id: 'classOf' 
        },
        { 
            render: item => {
                return (
                        <span className={`text-gray`}>{`${item?.studentInfo?.universities?.length || 0} universities`}</span>
                )
            }, 
            className: `d-flex flex-row justify-content-end m-0 p-0`,
            colSpan: 4, 
            id: 'universities', 
        },
    ]
}

export default useDesktopColumns