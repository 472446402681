import React, {useState, useMemo} from 'react'
import {
    ModalLayout,
    TemplateTimeline,
    Button,
    IconRepeat,
    IconLoad
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'

import useGetTasksTemplate from '../Model/useGetTasksTemplate'
import useSelectTemplateModal from './useSelectTemplateModal'
import useViewTaskModal from '../Task/viewTaskModal/useViewTaskModal'
import useTemplateReducer from '../../../reducers/template'
import RenderTemplateItem from '../Components/RenderTemplateItem'

const SelectTemplateModal = () => {
    const {isOpen, toggle, setTab, activeTab, onChange, close} = useSelectTemplateModal()
    const {open: openTaskModal} = useViewTaskModal()
    //template reducer
    const {parseTemplateFromApi, getTaskCount, filterDelete} = useTemplateReducer()

    const {templates: templatesResult, loading: templatesLoading, refetch: refetchTemplateList} = useGetTasksTemplate()
    const templatesData = templatesResult.filter(({deleteAt}) => deleteAt === null)
    
    const templates = useMemo(() => {
        //parsea templates
        if(templatesData?.length > 0) return templatesData.map((template) => parseTemplateFromApi(template)) 
        return []
    }, [templatesData, parseTemplateFromApi])


    //tab para listado de template; loading, blank (para cuando no hay registros) o list
    const statusTab = useMemo(() => {
        if(templatesLoading) return 'loading'
        else if(templatesData?.length === 0) return 'blank'
        return 'list'
    }, [templatesLoading, templatesData])

    const [templateSelected, setTemplateSelected] = useState(null)

    return (
        <ModalLayout
            className={`modal-colored modal-centered white-modal h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={`d-flex flex-row align-items-baseline`}>
                    <span className={`h3 text-title mr-2`}>Templates</span>
                    <div className={`hover-icon`} onClick={() => {refetchTemplateList()}}>
                        {
                            templatesLoading ? 
                            <IconLoad size={15} /> :
                            <IconRepeat size={15} />
                        }
                    </div>
                </div>
            }
            underHead={null}
            options={null}
            minHead={
                <div className={`d-flex flex-row align-items-baseline`}>
                    <span className={`h3 text-title mr-2`}>Templates</span>
                    <div className={`hover-icon`} onClick={() => {refetchTemplateList()}}>
                        {
                            templatesLoading ? 
                            <IconLoad size={15} /> :
                            <IconRepeat size={15} />
                        }
                    </div>
                </div>
            }
            sideRight={null}
            toggleRight={() => {}}
            useRightSection={false}
            isOpenRight={false}
        >
            <TabContent activeTab={activeTab}>
                <TabPane tabId={`list`}>
                    <TabContent activeTab={statusTab}>
                        <TabPane tabId={`loading`}>
                            <Skeleton count={5} height={40} />
                        </TabPane>
                        <TabPane tabId={`blank`}>
                            No hay registros
                        </TabPane>
                        <TabPane tabId={`list`}>
                            <div className={`list-content-on-modal`}>
                                {
                                    templates.map((template, index) => {
                                        return (
                                            <RenderTemplateItem
                                                key={index}
                                                showBottomLine={index < templates.length-1}
                                                taskCount={getTaskCount(template)} 
                                                templateData={template}
                                                onClick={() => {
                                                    setTemplateSelected(template)
                                                    setTab('confirm')
                                                }} 
                                            />
                                        )
                                    })
                                }
                            </div>
                        </TabPane>
                    </TabContent>
                </TabPane>
                <TabPane tabId={`confirm`}>
                    <div className={`mx-3`}>
                        <TemplateTimeline
                            className='w-100'
                            isEdit={false}
                            stages={templateSelected ? filterDelete(templateSelected).phases : []}
                            onClickTask={(task) => {
                                openTaskModal({...task, isEdit: false})
                            }}
                        />
                    </div>
                    <div className={`d-flex flex-row justify-content-end mt-2`}>
                        <span 
                            onClick={() => {setTab('list')}} 
                            className={`btn-link text-error align-self-center mr-2`}>Back</span>
                        <Button 
                            className={`w-auto`} 
                            label={`Confirm`} 
                            onClick={() => {
                                if(onChange) onChange(templateSelected)
                                close()
                            }}
                            color={'primary'} 
                            size={`small`} 
                        />
                    </div>
                </TabPane>
            </TabContent>
        </ModalLayout>
    )
}

export default SelectTemplateModal