import React, { useEffect, useMemo } from 'react'
import {
  PlanCard,
  PlanCounselingCard
} from '@Knowledge-OTP/znk-ui-components'
import useViewPlanModal from '../../../../CounselingPlans/Modal/viewPlanModal/useViewPlanModal'
import useCounselingPlanReducer from '../../../../../reducers/counseling-plan'
import {TabContent, TabPane} from 'reactstrap'
import usePlans from '../useQueries/usePlans.js'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'

const Plans = (props) => {
  const { idStudent, page } = props
  const { translate } = useTranslation()
  const [plansQuery, { loading, data }] = usePlans()
  useEffect(() => {
    if (page === 'plans' && idStudent) {
      plansQuery({
        variables: {
          limit: 0,
          studentsUserIds: [idStudent],
          status: [
            'draft',
            'sent',
            'scheduled',
            'paid',
            'pause',
            'cancel',
            'resume'
          ],
          kind: [
            'lessonsPerWeek',
            'lessonsFixed',
            'counceling'
          ]
        }
      })
    }
  }, [page, idStudent, plansQuery])

  const [
    inProgress,
    upCommings,
    pendingApproval
  ] = useMemo(() => {
    if (data?.plans?.docs) {
      const parsedPlans = data?.plans?.docs?.map((plan) => {
        if (plan?.kind === 'counceling') {
          return {
            ...plan,
            status: plan.status === 'scheduled' ? 'inprogress' : plan.status
          }
        } else {
          let startTime
          let endTime
          let partArray
          let isFixedPlan = false
          let isTotalLesson = false
          if (plan.kind === 'lessonsFixed') {
            partArray = (plan.lessonPerTopic || plan.fixedParts) || []
            isFixedPlan = true
            isTotalLesson = (partArray || []).reduce(
              (isTotal, lessonPart) =>
                isTotal || lessonPart.type === 'totalLesson',
              false
            )
            // isTotalLessonsPlan = plan.lessonPerTopic.reduce((isTotal, lessonPart) => isTotal || (lessonPart.type === 'totalLesson'), false)
          } 
          else partArray = plan.parts || []

          startTime = (partArray || []).reduce(
            (num, item) =>
              moment(item.from.split('T')[0]).valueOf() < num
                ? moment(item.from.split('T')[0]).valueOf()
                : num,
            Number.POSITIVE_INFINITY
          )
          endTime = (partArray || []).reduce(
            (num, item) =>
              moment(item.to.split('T')[0]).valueOf() > num
                ? moment(item.to.split('T')[0]).valueOf()
                : num,
            Number.NEGATIVE_INFINITY
          )
          const lpwTotal = (partArray || []).reduce((totalLpw, part) => {
            return (
              totalLpw +
                (part?.lessonsPerWeek || part?.lessonPerWeekPerTopic || 
                  part?.TotalPerTopic.reduce(
                    (totalLessons, part) => totalLessons + (part?.lessonsPerWeek || 0),
                    0
                  ))
            )
          }, 0)

          return {
            ...plan,
            startTime: moment(startTime),
            endTime: moment(endTime),
            isFixedPlan,
            isTotalLesson,
            lessonsTotal: lpwTotal,
            status:
                plan.status === 'scheduled' || plan.status === 'paid'
                  ? startTime > new Date().getTime()
                    ? 'upcoming'
                    : 'inprogress'
                  : plan.status
          }
        }
        
      })

      return [
          parsedPlans?.filter((item) => item.status === 'inprogress'),
          parsedPlans?.filter((item) => item.status === 'upcoming'),
          parsedPlans?.filter((item) => item.status === 'sent')
      ]
    }
    return []
  }, [data])


  const {open: openCouselingPlanModal} = useViewPlanModal()
  const {parsePlanFromAPI} = useCounselingPlanReducer()
  const RenderCounselingPlanCard = (rawPlan) => {
    const {onClick, ...plan} = rawPlan
    return (
      <PlanCounselingCard
        styleCard={{cursor: 'pointer'}}
        title={plan?.name}
        subtitle={`${
          plan?.type
            ? plan.type
                .substring(0, 1)
                .toUpperCase() +
                plan.type.substring(
                1,
                plan.type.length,
              )
            : 'Type'
        } | ${
          plan?.aditionalInfo?.studentCountryName ||
          'Country'
        } | Until ${moment(plan?.to).format(
          'MMM DD, YYYY',
        )}`}
        text=''
        status={plan?.status}
        onClick={() => {
          openCouselingPlanModal(() => {

          }, parsePlanFromAPI(plan))
        }}
      />
    )
  }
  return (
    <div className='d-flex flex-column m-0 p-0 pr-6'>
      <div className='d-flex flex-column mx-0 my-2 p-0'>
        <span className='h5 text-muted my-2'>{translate('student.modal.plans.inProgress').toUpperCase()}</span>
        <TabContent activeTab={loading ? 'loading' : 'show'}>
          <TabPane tabId={`loading`}>
            <Skeleton count={3} height={112} className='w-100' />
          </TabPane>
          <TabPane tabId={`show`}>
          {
                inProgress &&
                        inProgress.map((plan) => {
                          return (
                            plan?.kind === 'counceling' ? 
                              <RenderCounselingPlanCard {...plan } status='scheduled' onClick={() => {alert('scheduled')}} />
                              :
                              <PlanCard
                                translate={translate}
                                name={plan.name}
                                status={plan.status}
                                className='mx-2'
                                styleCard={{ minWidth: '90%' }}
                                onClick={() => {}}
                                startTime={moment(plan.startTime)}
                                endTime={moment(plan.endTime)}
                                lessonsTotal={plan.lessonsTotal}
                                isTimelessPlan={plan.isFixedPlan}
                                isLessonPerWeek={!plan.isTotalLesson}
                              />
                          )
                        })
              }
              {
                upCommings &&
                        upCommings.map((plan) => {
                          return (
                            <PlanCard
                              translate={translate}
                              name={plan.name}
                              status={plan.status}
                              className='mx-2'
                              styleCard={{ minWidth: '90%' }}
                              onClick={() => {}}
                              startTime={moment(plan.startTime)}
                              endTime={moment(plan.endTime)}
                              lessonsTotal={plan.lessonsTotal}
                              isTimelessPlan={plan.isFixedPlan}
                              isLessonPerWeek={!plan.isTotalLesson}
                            />
                          )
                        })
              }
          </TabPane>
        </TabContent>
        
      </div>
      <div className='d-flex flex-column mx-0 my-2 p-0'>
        <span className='h5 text-muted my-2'>{translate('student.modal.plans.pendingApproval').toUpperCase()}</span>
        <TabContent activeTab={loading ? 'loading' : 'show'}>
          <TabPane tabId={`loading`}>
            <Skeleton count={3} height={112} className='w-100' />
          </TabPane>
          <TabPane tabId={`show`}>
            {
              pendingApproval &&
                      pendingApproval.map((plan) => {
                        return (
                          plan?.kind === 'counceling' ? 
                            <RenderCounselingPlanCard {...plan} onClick={() => {alert('sent')}} />
                            :
                            <PlanCard
                              translate={translate}
                              name={plan.name}
                              status={plan.status}
                              className='mx-2'
                              styleCard={{ minWidth: '90%' }}
                              onClick={() => {}}
                              startTime={moment(plan.startTime)}
                              endTime={moment(plan.endTime)}
                              lessonsTotal={plan.lessonsTotal}
                              isTimelessPlan={plan.isFixedPlan}
                              isLessonPerWeek={!plan.isTotalLesson}
                            />
                        )
                      })
            }
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}

export default Plans
