import {useSelector, useDispatch} from 'react-redux'

const useSetApplicationStatusModal = () => {
    const reduxData = useSelector((state) => state.modal.counseling.setApplicationStatus)
    const {isOpen} = reduxData
    const dispatch = useDispatch()
    const setData = (open, onChange = () => {}, dataPlan = null, useMutation = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.setApplicationStatus',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                dataPlan,
                useMutation
            }
        })
    }

    return {
        ...reduxData,
        open: (onChange = ()=>{}, dataPlan = null, useMutation = false) => {
            setData(true, onChange, dataPlan, useMutation)
        },
        toggle: () => {
            setData(!isOpen)
        },
        close: () => {
            setData(false)
        }
    }
}

export default useSetApplicationStatusModal