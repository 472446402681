import React from 'react'
import { IconLoad, IconRepeat } from '@Knowledge-OTP/znk-ui-components'

const AlertNotification = (props) => {
    const {show = false, loading, onClick = () => {}, text = 'Refresh'} = props 
    return (
        <div className={`d-flex flex-row justify-content-center`}>
            <span onClick={() => {
                onClick()
            }} className={`alert-notification ${show ? 'show' : ''} d-flex flex-row align-items-center`}>
                {loading ? <IconLoad size={14} className={`text-white mr-1`} /> : <IconRepeat size={14} className={`text-white mr-1`} />} {text}
            </span>
        </div>
    )
}

export default AlertNotification