import React, {useEffect, useCallback, useRef} from 'react'


const RenderBody = (props) => {
    const {onMount = () => {}} = props
    useEffect(() => {
        onMount()
    }, 
    // eslint-disable-next-line
    [])
    return (
        <>
            {props.children}
            {/* <button onClick={setHeight}>CLICK</button> */}
        </>
    )
}

const DinamicHeightPage = (props) => {
    const { bodyRef } = props
    const welcomeRef = useRef(null)
    const handleGetHeight = useCallback(() => {
        if (bodyRef.current) {
            const height = bodyRef.current.clientHeight;
            return `${height-80}px`
        }
        return ""
    }, [bodyRef])
    const setHeight = useCallback(() => {
        if(welcomeRef.current){
            
            welcomeRef.current.style.height = handleGetHeight()
        }
    }, 
    // eslint-disable-next-line
    [welcomeRef])
    return (
        <div
            ref={welcomeRef}
            className="w-100 d-flex flex-column justify-content-center align-items-center">
            <RenderBody
                ref={welcomeRef}
                onMount={() => { setHeight() }}
            >
                {props.children}
            </RenderBody>
        </div>
    )
}


export default DinamicHeightPage