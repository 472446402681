import { useSelector, useDispatch } from "react-redux"

const useDismissNotification = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.dashboard.dismissTestDateNotification)
    const {isOpen} = reduxData
    const setData = (isOpen = false, onChange = () => {}, planIds = [], type) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'dashboard.dismissTestDateNotification',
            data: {
                ...reduxData,
                isOpen,
                onChange,
                planIds, type
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, planIds = [], type) => {
            setData(true, onChange, planIds, type)
        },
        close: () => {setData(false)},
        toggle: () => {setData(!isOpen)},
    }
}

export default useDismissNotification