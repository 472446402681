import React from 'react'
import {ListItem, IconArrowRight} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import moment from 'moment'

const PlanActionStatus = (props) => {
    const {className, plan, ...otherProps} = props
    return (
        <ListItem 
            className={`${className}`}
            showBottomLine={false}
            showTopLine
            Left={
                <TabContent activeTab={plan.status}>
                    <TabPane tabId={'sent'} >
                        <div className={`d-flex flex-row`}>
                            <span className={`mx-0 mr-2 mx-xl-0 ml-xl-2 badge badge-pill point badge-warning align-self-center`}>&nbsp;</span>
                            <span className={`ml-2 h45 text-gray`}>Waiting approval {moment(plan?.sentDate).fromNow()}</span>
                        </div>
                    </TabPane>
                    <TabPane tabId={'cancel'} >
                        <div className={`d-flex flex-row`}>
                            <span className={`mx-0 mr-2 mx-xl-0 ml-xl-2 badge badge-pill point badge-input align-self-center`}>&nbsp;</span>
                            <span className={`ml-2 h45 text-gray`}>Canceled {moment(plan?.deleteAt).fromNow()}</span>
                        </div>
                    </TabPane>
                </TabContent>
            }
            Right={
                <div className={`hover-icon`}>
                    <IconArrowRight />
                </div>
            }
            {...otherProps}
        />
    )
}

export default PlanActionStatus