import React, { useMemo, useEffect, useState } from 'react'
import {
  IconSignPlus,
  ConfirmModal,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import Tasks from './Sections/Tasks'
import ExamComponent from './Sections/Exams/examComponent'
//import AddExamComponent from './Sections/Exams/addExamComponent'
import useHandlerExamForm from '../../../../Workspace/Sections/Scores/Sections/ShowResultsScore/useHandlerExamForm'
import ServiceComponent from './Sections/Exams/ServiceComponent'
import usePaginatedServices from '../../../../../Model/Services/List/index'
import useLazyScoreByStudent from '../../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/Model/useGetScoresByStudentId'
const Learn = (props) => {
  const { idStudent, page, refetch = () => {} } = props
  const { translate } = useTranslation()
  const now = useMemo(() => moment(), [])

  const { data: servicesData } = usePaginatedServices({ limit: 200 })
  const [docsAndExamsData, setDocsAndExamsData] = useState([])
  const [getScoreFromStudent, {data: dataScores, loading: loadingExams}] = useLazyScoreByStudent()
  const [isOpen, setOpen] = useState(false)
  const [selectedExamType, setSelectedExamType] = useState(null)
  const toggle = () => {
    setOpen(!isOpen)
  }
  useEffect(() => {
    if (page === 'learn' && idStudent && now){
      getScoreFromStudent({ variables: { studentId: idStudent } })
    }
  }, [page, idStudent, now, getScoreFromStudent])

  const {open: openSubmitScoreModal} = useHandlerExamForm(refetch)

  useEffect(() => {
    if (dataScores && dataScores?.getScoresByStudentId?.length > 0) {
      setDocsAndExamsData(dataScores?.getScoresByStudentId)
    }
  }, [dataScores]);
  
  return (
    <div className='d-flex flex-column m-0 p-0 pr-6'>
      <div className='p-0 mx-0 mt-5 mb-2'>
        <div className='mx-0 mt-0 mb-3 p-0 d-flex flex-row justify-content-between'>
          <span className='font-weight-bold text-title'>{translate('student.modal.learn.exams')} {docsAndExamsData?.length > 0 ? '(' + docsAndExamsData?.length + ')' : null}</span>
          {/* <span className='text-gray'>{translate('student.modal.learn.explore')}</span> */}
        </div>
        <div className='d-flex flex-row znk-scroll-horizontal-v2'>
          {
            loadingExams
              ? new Array(5).fill(0).map(() => {
                return (
                  <Skeleton className='mr-3' height={126} width={92} count={1} />
                )
              })
                : docsAndExamsData
                  .map((score) => {
                    return (
                      <div
                      onClick={() => {     
                        const showPlanData = (score?.planId && !score?.taskId) || (!score?.planId && score?.taskId)
                        openSubmitScoreModal(score, showPlanData, () => { })
                    }}>
                      <ExamComponent exam={score}/>
                      </div>
                    )
                  })
          }
          {/* Componente para agregar nuevo examen 
          TODO: abrir con el modal nuevo */}
{/*           {loadingExams ? null : 
            <div
            // onClick={openSubmitScore}
            onClick={()=>{setOpen(true)}}
            >
              
          <AddExamComponent />
          </div>
          }  */}
        </div>
      </div>
      <div className='p-0 mx-0 mt-5 mb-2'>
        {/* <div className='mx-0 mt-0 mb-3 p-0 d-flex flex-row justify-content-between'>
          <span className='font-weight-bold text-title'>{translate('student.modal.learn.task')}</span>
          <span className='text-gray'>{translate('student.modal.learn.viewHistory')}</span>
        </div> */}
        <div className='d-flex flex-column'>
          {
            // false
            //   ? <Action
            //     color='transparent'
            //     textColor='#7079FF'
            //     border='dashed 1px #7079FF'
            //     className='my-1'
            //     labelLeft={
            //       <span className='w-100 d-flex flex-column my-3'>
            //         <label className='m-0'>
            //           Comming soon
            //         </label>
            //       </span>
            //     }
            //     labelRight={null}
            //     action={null}
            //   />
            //   : 
              <Tasks studentUserId={idStudent} />
            // new Array(4).fill(0).map(() => {
            //   return (
            //     <div className='znk-card normal mb-2'>
            //       <div className='left'>
            //         <IconLesson size={15} color='#72809D' />
            //         <div className='text normal-text'>Test Prep Math and English</div>
            //       </div>
            //       <div className='text text-gray'>Yesterday</div>
            //     </div>
            //   )
            // })
          }
          <div className='znk-card normal mb-2' style={{ cursor: 'pointer' }}>
            <div className='left'>
              <IconSignPlus size={15} color='#72809D' style={{ transform: 'rotate(45deg)' }} />
              <div className='text text-gray'>
                {translate('student.modal.learn.createNewTask')}
              </div>
            </div>
          </div>
          {/* <div className='znk-card empty mb-2' style={{ cursor: 'pointer' }}>
            <div className='left'>
              <IconArrowDown size={15} color='#72809D' />
              <div className='text text-gray'>
                {translate('student.modal.learn.viewCompletedTask')}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ConfirmModal
      className=''
      bodyClassName='m-0 p-0 mt-5 w-100'
      loading={null}
      classNameActionDiv='flex-column'
      classNameConfirm='text-right text-error'
      classNameCancel='mx-3 text-right'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('student.modal.learn.selectTypeExam')}
      body={
      <ServiceComponent 
      services= {servicesData}
      selectedService={selectedExamType}
      setSelectedService={setSelectedExamType}
      />}
      confirmAction={()=>{
        toggle()
        //openSubmitScore(selectedExamType, idStudent)
        }}
      cancelAction={toggle}
      confirmText={'Yes'}
      cancelText={'No'}
    />
    </div>
  )
}

export default Learn
