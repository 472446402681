import {useMutation, gql} from '@apollo/client'

const useDeleteSendPlanCron = () => {
    return useMutation(
        gql`
            mutation ($planId: ID!) {
                deleteSendPlanCron (planId: $planId)
            }
        `
    )
}

export default useDeleteSendPlanCron