import { CheckboxSelector, CheckboxSelectorInner, IconArrowDown } from '@Knowledge-OTP/znk-ui-components'
import React, { useMemo } from 'react'
import { Badge } from 'reactstrap'
import useTranslation from '../../../i18n/useTranslation'

const defaultStatuses = [
  'draft', 'sent', 'scheduled', 'pause', 'finished', 'cancel'
  //'cancel', 'finished'
]

const StatusesFilter = (props) => {
  const { translate } = useTranslation()
  const { statusFilters, setStatusFilters, useInner = false, statuses = defaultStatuses } = props
  const pillColor = (itemIn) => {
    let statusClass
    const item = itemIn?.id || itemIn
    switch (item) {
      case 'draft':
        statusClass = 'gray'
        break
      case 'sent':
        statusClass = 'error'
        break
      case 'scheduled':
        statusClass = 'warning'
        break
      case 'paid':
        statusClass = 'success'
        break
      case 'cancel':
        statusClass = 'logo'
        break
      case 'pause':
        statusClass = 'primary'
        break
      case 'resume':
        statusClass = 'secondary'
        break
      case 'finished':
        statusClass = 'success'
        break
      default:
        statusClass = 'secondary'
        break
    }
    return statusClass
  }
  const selectorData = useMemo(() => statuses.map((item) => {
    const isString = typeof item === 'string'
    const baseObj = isString ? {} : item || {}
    return {
      ...baseObj,
      ids: item?.ids,
      multi: item?.multi,
      name: isString ? translate(`plans.status.${item}`) : item?.name,
      id: isString ? item : item?.id,
      className: item?.className || '',   
      Component: (
        !isString && !item?.statusColor ? null :
        <Badge
          color={item?.statusColor || pillColor(item)}
          pill
          className='mx-1 point'
        >
          &nbsp;
        </Badge>
      )
    }
  }), 
  // eslint-disable-next-line
  [statuses, translate])


  const selectorProps = {
    setDataFilters: setStatusFilters,
    direction: 'bottom',
    dataFilters: statusFilters,
    typeFilter: 'statuses',
    data: selectorData,
    strings: {
      viewAll: translate('filters.plans.viewAll'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
      search: translate('filters.plans.search')
    }
  }

  return (
    useInner ?
      <CheckboxSelectorInner
        style={{maxWidth: 'auto'}}
        {...selectorProps}
      /> :
      <CheckboxSelector {...selectorProps}>
        <Badge className='mx-1 filters' pill color='option'>
          <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
            {translate('filters.plans.status.title')}
            <IconArrowDown className='ml-2' size={15} />
          </label>
        </Badge>
      </CheckboxSelector>
  )
}
export default StatusesFilter
