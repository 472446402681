import React, { useState } from 'react'
import useAllActivities from '../useActivities'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import isEmpty from 'lodash/isEmpty'
import { IconAdd, Table } from '@Knowledge-OTP/znk-ui-components'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import moment from 'moment-timezone'
import HeaderFields from './HeaderFields'
import ActivitiesFilters from '../Filters'
import omit from 'lodash/omit'

const EventTable = () => {
  const [filters, setFilters] = useState({
    from: moment().toISOString(),
    to: null
  })
  const { data, loading, refetch } = useAllActivities({
    kinds: ['event'],
    ...filters
  })
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const docs = data && !isEmpty(data) ? data.allActivities : []
  const getEditInfo = (item) => {
    return {
      ...omit(item, '__typename'),
      startDate: item.startDate.split('T')[0]
    }
  }
  const editEvent = (item) => dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'activities.edit', data: { isOpen: true, kind: 'event', refetch, ...(getEditInfo(item)) } })
  return (
    <>
      <span className='px-2 my-3 d-flex justify-content-between align-items-center'>
        <span className='h2 d-flex justify-content-between align-items-center'>
          {translate('constants.activities.eventTitle')}
          <ActivitiesFilters
            kind='event' {...{ filters, setFilters }} baseFilter={{
              from: null,
              to: null
            }}
          />
        </span>
        <span><IconAdd className='text-primary' onClick={() => dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'activities.create', data: { isOpen: true, kind: 'event', refetch } })} /></span>
      </span>
      <Table
        loading={loading}
        tableData={docs.filter(item => item.kind === 'event')}
        headerColumns={HeaderFields()}
        columnList={[{
          render: (item) => (`${item.name}`), id: 'name', colSpan: 1
        }, {
          render: (item) => {
            return !item.tbd ? '✗' : '✓'
          },
          id: 'tbd',
          colSpan: 1,
          className: 'text-secondary align-items-center justify-content-center'
        }, {
          render: (item) => item.startDate ? moment(item.startDate).utc().format('MMMM Do, YYYY') : '-',
          id: 'eventDate',
          colSpan: 1,
          className: 'text-secondary align-items-center justify-content-center'
        }, {
          optionsParser: (item) => {
            return { mainBtnConfig: { text: translate('misc.edit'), onClick: () => editEvent(item) }, options: [{ component: <span onClick={() => editEvent(item)}>{translate('misc.edit')}</span> }] }
          },
          colSpan: 1,
          type: 'selector',
          className: 'justify-content-end align-items-center text-secondary',
          id: 'buttons'
        }]}
      />
    </>
  )
}

export default EventTable
