import React, {useState, useEffect} from 'react'
import {
    ModalLayout,
    Input,
    Button,
    CheckboxOption,
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import useCreateTestModal from './useCreateTestModal'

//model
import useCreateTestMutation from './Model/useCreateTest'
import useEditTestMutation from './Model/useEditTest'

import {useSelector} from 'react-redux'
import moment from 'moment'


const RenderInput = ({label, children, className = ''}) => {
    return (
        <div className={`d-flex flex-column m-0 p-0 ${className}`}>
            <span className={`mb-3`}>{label}</span>
            {children}
        </div>
    )
}

const defaultForm = {
    serviceId: null,
    registrationDate: null,
    scoreReleaseDate: null,
    startDate: null,
    endDate: null,
    tbd: false,
    name: null,
    isEdit: false
}

const CreateTestModal = () => {
    const {toggle, isOpen, onChange, test, validation} = useCreateTestModal()
    const [form, setForm] = useState(defaultForm)
    useEffect(() => {
        if(test) {setForm({
            ...test, 
            date: moment(test?.startDate).format('YYYY-MM-DD'), 
            registrationDate: moment(test?.registrationDate).format('YYYY-MM-DD'), 
            scoreReleaseDate: moment(test?.scoreReleaseDate).format('YYYY-MM-DD'), 
            isEdit: true
        })}
        else setForm(defaultForm)
    }, [test, isOpen])

    const mutationCallback = (test) => {
        onChange({
            date: moment(test?.startDate).utc().format(`YYYY-MM-DD`),
            toDefine: test?.tbd || false,
            name: test?.name,
            eventId: test?.id,
            isEdit: form.isEdit,
            serviceId: form.serviceId,
            registrationDate: form.registrationDate,
            scoreReleaseDate: form.scoreReleaseDate,
        })
    }

    const services = useSelector((state) => state.user.services)
    const [createTest, {loading: loadCreate}] = useCreateTestMutation(
        ({createTest}) => {
            mutationCallback(createTest)
        }, 
        (err) => {
            console.error(err)
        }
    )

    const [editTest, {loading: loadEdit}] = useEditTestMutation(
        ({editTest}) => {
            mutationCallback(editTest)
        },
        (err) => {
            console.error(err)
        }
    )
    return (
        <ModalLayout
            // style={bubblesStyle}
            className={`modal-colored modal-centered white-modal h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <span className={`h3 text-title`}>
                    Create Exam
                </span>
            }
            underHead={null}
            minHead={
                <span className={`h3`}>
                    Create Exam
                </span>
            }
        >
            <div className={`d-flex flex-column`}>
                <RenderInput label={`Service`} className={`my-3`} >
                    <InputSelect  
                        placeholder={`Select service`} 
                        value={form.serviceId} 
                        options={services.map((service) => {
                            return {value: service?.id, label: service?.name}
                        })}
                        onChange={(serviceId) => {
                            setForm({...form, serviceId})}} />
                </RenderInput>
                <div className={`d-flex flex-row m-0 p-0 row`}>
                    <RenderInput label={`Date`} className={`col-8`} >
                        <Input type={`date`} value={form.date} onChange={(e) => {setForm({...form, startDate: e?.target?.value, endDate: e?.target?.value })}} />
                    </RenderInput>
                    <div className={`d-flex flex-row align-self-end col-4`}>
                        <CheckboxOption value={form.toDefine} onChange={(e) => {setForm({...form, tbd: e})}} />
                        <span className={`text-truncate`} title={'To define'}>TBD</span>
                    </div>
                </div>
                <RenderInput label={`Name`} className={`mt-5`} >
                    <Input type={`text`} placeholder={`Name`} value={form.name} onChange={(e) => {setForm({...form, name: e?.target?.value})}} />
                </RenderInput>
                <RenderInput label={`Registration date`} className={`mt-5`} >
                    <Input type={`date`} name={`registrationDate`} value={form.registrationDate} onChange={(e) => {setForm({...form, registrationDate: e?.target?.value})}} />
                </RenderInput>
                <RenderInput label={`Score Delivery Date`} className={`mt-5`} >
                    <Input type={`date`} name={`scoreReleaseDate`} value={form.scoreReleaseDate} onChange={(e) => {setForm({...form, scoreReleaseDate: e?.target?.value})}} />
                </RenderInput>
                <Button
                    disabled={!!validation(form)}
                    className={`mt-5 mb-3 w-100`}
                    label={'Create test'}
                    size={`medium`}
                    color={'primary'}
                    activity={loadCreate || loadEdit}
                    onClick={() => {
                        // onChange(form)
                        const variables = {
                            serviceId: form?.serviceId,
                            registrationDate: form?.registrationDate,
                            scoreReleaseDate: form?.scoreReleaseDate,
                            startDate: form?.startDate,
                            endDate: form?.endDate,
                            tbd: form?.tbd,
                            name: form?.name
                        }
                        if(!form.isEdit) {
                            //crear test
                            createTest({
                                variables: {
                                    newTestInput: variables
                                }
                            })
                        } else {
                            //editar test
                            editTest({
                                variables: {
                                    editTestInput: variables,
                                    activityId: form?.eventId
                                }
                            })
                        }
                        
                    }}
                />
            </div>
        </ModalLayout>
    )
}

export default CreateTestModal