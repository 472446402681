import { useSelector, useDispatch } from "react-redux"

const useDeleteEventModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.deleteEventModal)
    const {isOpen} = reduxData

    const setOpen = (open, onChange = () => {}, eventId = null) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.deleteEventModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                eventId
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, eventId = null) => {
            setOpen(true, onChange, eventId)
        },
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)}
    }
}

export default useDeleteEventModal