import React, { useMemo, useState } from 'react'
import { IconLesson, ToolTipInfo } from '@Knowledge-OTP/znk-ui-components'
import PlanListItem from './PlanListItem'
import CardHeader from '../Components/CardHeader'
import useStudentsCheckIn from './Model/useStudentsCheckIn'
import CheckInStudentModal from './Modal/CheckInStudent'
import DismissNotification from './Modal/DismissNotification'
import SkeletonListItem from './PlanListItem/Skeleton'
import { TabContent, TabPane } from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import moment from 'moment'
import { CheckInTypeInt } from '../../../../Plans/modal/scheduled/CheckIns'

const IssueCheckIn = () => {
    const { data, loading: loadingQuery, refetch: refetchQuery } = useStudentsCheckIn({})
    const [fakeLoading, setFakeLoading] = useState(false)
    const allNotifications = useMemo(
        () => {
            if (data?.studentsCheckIn) return data?.studentsCheckIn

            return {}
        }, [data]
    )
    const {homework, missingLessons, pendingLessons, checkinPlans, general} = allNotifications
    const lengthNotifications = Object.values(allNotifications).reduce(
        (newarray, array) => Array.isArray(array) ? newarray.concat(array || []) : newarray, []).length
    const { translate } = useTranslation()
    const refetch = () => {
        setFakeLoading(true)
        refetchQuery().then(
            () => { setFakeLoading(false) },
            () => { setFakeLoading(false) }
        )
    }
    const loading = useMemo(() => loadingQuery || fakeLoading, [loadingQuery, fakeLoading])
    
    const getTooltipText = (tasksForWidget = []) => {
        return tasksForWidget?.map(
            (task) => {
                return <span>{task?.title} <br/> 
                <small>{moment(task?.endDate).format('YYYY-MM-DD HH:mm')} <br/></small>
                <small>{task?.educator?.firstName} {task?.educator?.lastName} <br/></small>
                <small>********** <br/></small>
               </span>
            }
        )
    }

    return (
        <>
            <CardHeader
                innerInfo={translate("dashboard.issueCheckIn.info")}
                titleModalInfo={translate("dashboard.issueCheckIn.infoTitleModal")}
                title={translate("dashboard.issueCheckIn.title")}
                className={`secondaryBackground`}
                onReload={() => { refetch() }}
                loading={loading}
            />
            <div className='d-flex flex-column w-100 m-0 p-0'
            // style={{backgroundColor: '#f8f9fa'}}
            >
                <TabContent activeTab={loading ? 'loading' : lengthNotifications > 0 ? 'show' : 'blank'}>
                    <TabPane tabId={'loading'}>
                        <div className='d-flex flex-column px-5'>
                            {
                                new Array(10).fill(0).map(() => {
                                    return (
                                        <SkeletonListItem className={`mb-2`} />
                                    )
                                })
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId={'show'}>
                        <>
                            {
                                (homework || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = getTooltipText(student?.tasksForWidget)
                                        return (
                                            <ToolTipInfo
                                            tooltipId={`homework-${index}`}
                                            disable={false}
                                            innerElement={
                                                <div>
                                                    {textToShowOnTooltip}
                                                </div>
                                            }
                                            place='bottom'
                                         >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id}
                                                text={'Missing Homework'}
                                                reason={"homework"}
                                                description={translate("dashboard.issueCheckIn.homework")}
                                                studentData={student}
                                            />
                                             </ToolTipInfo>
                                        )
                                    }
                                )
                            }
                            {
                                (missingLessons || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = getTooltipText(student?.tasksForWidget)
                                        // const textToShowOnTooltip = student?.tasksForWidget?.map(
                                        //     (task) => {
                                        //         return <span>{task?.title} <br/> 
                                        //         <small>{moment(task?.endDate).format('YYYY-MM-DD HH:mm')} <br/></small>
                                        //         <small>{task?.educator?.firstName} {task?.educator?.lastName} <br/></small>
                                        //         <small>********** <br/></small>
                                        //        </span>
                                        //     }
                                        // )
                                        return (
                                            <ToolTipInfo
                                            tooltipId={`missing-${index}`}
                                            disable={false}
                                            innerElement={
                                                <div>
                                                    {textToShowOnTooltip}
                                                </div>
                                            }
                                            place='bottom'
                                            >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id}
                                                text={'Missing Lessons'}
                                                reason={"missing"}
                                                description={translate("dashboard.issueCheckIn.missing")}
                                                studentData={student}
                                            />
                                            </ToolTipInfo>
                                        )
                                    }
                                )
                            }
                            {
                                (pendingLessons || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = getTooltipText(student?.tasksForWidget)
                                        // const textToShowOnTooltip = student?.tasksForWidget?.map(
                                        //     (task) => {
                                        //         return <span>{task?.title} <br/> 
                                        //         <small>{moment(task?.endDate).format('YYYY-MM-DD HH:mm')} <br/></small>
                                        //         <small>{task?.educator?.firstName} {task?.educator?.lastName} <br/></small>
                                        //         <small>********** <br/></small>
                                        //        </span>
                                        //     }
                                        // )
                                        return (
                                            <ToolTipInfo
                                            tooltipId={`pending-${index}`}
                                            disable={false}
                                            innerElement={
                                                <div>
                                                    {textToShowOnTooltip}
                                                </div>
                                            }
                                            place='bottom'
                                            >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id || student['_id']}
                                                text={'Pending Lessons'}
                                                reason={"pending"}
                                                description={translate("dashboard.issueCheckIn.pending")}
                                                studentData={student}
                                            />
                                            </ToolTipInfo>
                                        )
                                    }
                                )
                            }
                            {
                                (checkinPlans || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = <>
                                            <span>{student.planName}</span> <br/>
                                            <small>{`${student.firstName} ${student.lastName}`}</small> <br/>
                                            <small>{moment(student.checkInDate?.date).format('YYYY-MM-DD HH:mm')} <br/></small>
                                        </>
                                        // const textToShowOnTooltip = `NUEVA NOTIFICACIÓN`
                                        return (
                                            <ToolTipInfo
                                                tooltipId={`checkin-plan-${index}`}
                                                disable={false}
                                                innerElement={
                                                    <div>
                                                        {textToShowOnTooltip}
                                                    </div>
                                                }
                                                place='bottom'
                                            >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id || student['_id']}
                                                text={`Check In Plan - ${translate(CheckInTypeInt[student?.checkInDate?.type])}`}
                                                reason={student?.checkInDate?.type.toString() !== 'regular' ? student?.checkInDate?.type : 'checkinPlan' }
                                                description={translate("dashboard.issueCheckIn.checkinPlan")}
                                                studentData={student}
                                            />
                                            </ToolTipInfo>
                                        )
                                    }
                                )
                            }
                            {
                                (general || []).map(
                                    (student, index) => {
                                        const textToShowOnTooltip = getTooltipText(student?.tasksForWidget)
                                        // const textToShowOnTooltip = student?.tasksForWidget?.map(
                                        //     (task) => {
                                        //         return <span>{task?.title} <br/> 
                                        //         <small>{moment(task?.endDate).format('YYYY-MM-DD HH:mm')} <br/></small>
                                        //         <small>{task?.educator?.firstName} {task?.educator?.lastName} <br/></small>
                                        //         <small>********** <br/></small>
                                        //        </span>
                                        //     }
                                        // )
                                        return (
                                            <ToolTipInfo
                                            tooltipId={`general-${index}`}
                                            disable={false}
                                            innerElement={
                                                <div>
                                                    {textToShowOnTooltip}
                                                </div>
                                            }
                                            place='bottom'
                                            >
                                            <PlanListItem
                                                refetch={refetch}
                                                studentName={`${student?.firstName} ${student?.lastName}`}
                                                studentId={student?.id || student['_id']}
                                                text={'General'}
                                                reason={"general"}
                                                description={translate("dashboard.issueCheckIn.general")}
                                                studentData={student}
                                            />
                                            </ToolTipInfo>
                                        )
                                    }
                                )
                            }
                        </>
                    </TabPane>
                    <TabPane tabId={'blank'}>
                        <div className={`d-flex flex-row justify-content-center w-100`} style={{ height: '200px' }}>
                            <div className={`d-flex flex-column justify-content-center align-items-center text-center`}>
                                <IconLesson size={40} className={`text-disabled`} />
                                <label className={`h45 mt-4 text-gray`}>There are no notifications</label>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
            <CheckInStudentModal />
            <DismissNotification />
        </>
    )
}

export default IssueCheckIn