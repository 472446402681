import {nanoid} from 'nanoid'
import moment from 'moment'
const initialState = {
    counselingPlanId: nanoid(),
    counselingPlanName: null,
    from: moment().toISOString(),
    name: 'Counseling Plan',
    paymentStart: moment().toISOString(),
    proposalVersion: 'maleNonSenior'
}
export default initialState

