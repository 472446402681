import React from 'react'
import PropTypes from 'prop-types'
import ChildKey from './KeyItem/ChildKey'
const KeysNavigator = (props) => {
  const { strings } = props
  const mainKeys = Object.keys(strings)
  return (
    <div style={{ flex: 1 }}>
      {
        mainKeys.map((item) =>
          <ChildKey key={item} myKey={item} myPath={item} myValues={strings[item]} />
        )
      }
    </div>
  )
}
KeysNavigator.defaultProps = {
  strings: {}
}
KeysNavigator.propTypes = {
  strings: PropTypes.object
}
export default KeysNavigator
