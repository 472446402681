import { useQuery, gql } from "@apollo/client"

const UseAllEducatorByTopicPaginated = (vars = {}) => {
    return useQuery(
        gql`query (
            $topicId: ID!, 
            $serviceId: ID!, 
            $teachingTypes: [TeachingType!], 
            $prospectiveParts: [PartCapInfo!]!
            $sortField: String
            $previous: String
            $next: String
            $limit: Int
            $search: String
            $educatorsId: [ID!] = []
        ) {
            allEducatorByTopicPaginated(
                topicId: $topicId, 
                teachingTypes: $teachingTypes, 
                serviceId: $serviceId 
                sortField: $sortField
                previous: $previous
                next: $next
                limit: $limit
                search: $search
                educatorsId: $educatorsId
            ) {
                hasNext
                hasPrevious
                next
                previous
                totalDocs
                docs {
                    id
                    emails {
                        address
                        verified
                    }
                    countryId
                    firstName
                    lastName
                    timezone
                    educatorInfo {
                        id
                        defaultRate
                        services {
                            serviceId
                            name
                            defaultRate
                            topics {
                                topicId
                                teachingTypes
                                name
                                individualRate
                                groupRate
                                isWaiveIndividual
                                isWaiveGroup
                            }
                        }
                        isCapped (
                            parts: $prospectiveParts
                        )
                        backupLink
                    }
                }
            }
        }`,
        {
            variables: vars,
            fetchPolicy: 'cache-first'
        }
    )
}

export default UseAllEducatorByTopicPaginated