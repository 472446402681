import React from 'react'
import { IconClose, Modal, useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import { ModalHeader } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import ParentSelector from './ParentSelector'
import ParentsPermissions from './ParentsPermissions'
import useTranslation from '../../../../../i18n/useTranslation'

const AddParentToStudentModal = (props) => {
  const { allUsers } = props
  const { isOpen, ...formData } = useSelector((state) => state.modal.user.addParentToStudent)
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.addParentToStudent', data: { isOpen: !isOpen, ...formData } })
  }
  const updateAddModalData = (newUserInfo) => {
    const { data: { addParentToStudent: { studentInfo: { parents } } } } = newUserInfo
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.addParentToStudent', data: { isOpen: true, ...formData, parents } })
  }
  const updateSetModalData = (newUserInfo) => {
    const { data: { setParentPermissionsToStudent: { studentInfo: { parents } } } } = newUserInfo
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.addParentToStudent', data: { isOpen: true, ...formData, parents } })
  }

  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  const { parents, userId } = formData
  return (
    <Modal
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'sidebar'}`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <ModalHeader
          toggle={toggle}
          close={<IconClose size={20} onClick={toggle} />}
          className='mt-md-4'
        >
          <span className='h2 font-weight-bold mr-4'>
            {translate('profile.setParentToStudent')}
          </span>
        </ModalHeader>
      }
    >
      <ParentSelector users={allUsers} userId={userId} parents={parents || []} onSuccess={updateAddModalData} />
      <ParentsPermissions parents={parents || []} userId={userId} onSuccess={updateSetModalData} />
    </Modal>
  )
}

export default AddParentToStudentModal
