import React, { useState, useEffect } from 'react'
import AvatarEditor from 'react-avatar-editor'
import {
    Button,
    IconSignPlus,
    IconSignMinus
} from '@Knowledge-OTP/znk-ui-components'
import dragAndDropIcon from '../../assets/drag-and-drop.png'
import useUploadAvatar from '../../Pages/Student/modal/InfoStudent/useQueries/useUploadAvatar';
import useDeleteAvatar from '../../Pages/Student/modal/InfoStudent/useQueries/useDeleteAvatar';
import './index.css'

const EditAvatar = (props) => {
    const { userId, fetch, toogle } = props
    let dropArea = document.getElementById('dropZone')
    let dragImage = document.getElementById('dragImage')
    let dragSpan = document.getElementById('dragSpan')

    let editor = "";
    const [picture, setPicture] = useState({
        img: null,
        zoom: 1,
        croppedImg: null
    });

    const handleSlider = (event) => {
        setPicture({
            ...picture,
            zoom: event.target.value
        });
    };

    const setEditorRef = (ed) => {
        editor = ed;
    };


    const handleSave = (e) => {
        if (setEditorRef) {
            const canvasScaled = editor.getImageScaledToCanvas();
            const croppedImg = canvasScaled.toDataURL();

            setPicture({
                ...picture,
                img: null,
                croppedImg: croppedImg
            });
            uploadAvatar({ file: croppedImg, userId: userId })
        }
    };

    const handleFileChange = (e) => {
        dropArea.classList.remove('active')
        dragImage.style.visibility = 'hidden'
        dragSpan.style.visibility = 'visible'
        let url = URL.createObjectURL(e.target.files[0]);
        console.log(url);
        setPicture({
            ...picture,
            img: url,
            zoom: 1,
        });
    };

    const { submit: uploadAvatar, loading: loadingAvatar } = useUploadAvatar(() => {
        fetch()
        toogle()
    }, console.log())

    const {submit: deleteAvatar, loading: loadingDeleteAvatar} = useDeleteAvatar(() => {
        fetch()
        toogle()
      }, console.log())

    const addListeners = () => {
        if (dropArea) {
            // If user Drag File Over DropArea
            dropArea.addEventListener('dragover', (event) => {
                event.preventDefault() // preventing from default behaviour
                dropArea.classList.add('active')
                dragImage.style.visibility = 'visible'
                dragSpan.style.visibility = 'hidden'
            })

            // If user leave dragged File from DropArea
            dropArea.addEventListener('dragleave', () => {
                dropArea.classList.remove('active')
                dragImage.style.visibility = 'hidden'
                dragSpan.style.visibility = 'visible'
            })
        }

    }
    useEffect(() => {
        if (!dropArea) {
            // eslint-disable-next-line
            dropArea = document.getElementById('dropZone')
            // eslint-disable-next-line
            dragImage = document.getElementById('dragImage')
            // eslint-disable-next-line
            dragSpan = document.getElementById('dragSpan')

        }
        if (dropArea) {
            addListeners()
        }
    }, [picture])


    return (

        <>
            <div className='px-5'>
                <div
                    id='dropZone'
                    className='file-select d-flex flex-column align-items-center justify-content-center'
                >
                    <img
                        className='d-flex align-items-center justify-content-center'
                        style={{ visibility: 'hidden' }}
                        id='dragImage'
                        src={dragAndDropIcon}
                        alt='Early Decision Icon'
                        color='#696bff'
                        width='100'
                        height='100'
                    />
                    <span
                        className='d-flex flex-column align-items-center justify-content-center'
                        style={{
                            visibility: 'visible',
                            textAlign: 'center',
                        }}
                        id='dragSpan'
                        color='#696bff'
                        width='100'
                        height='100'
                    >
                        {' '}
                        Drag and Drop here or <br />
                        <span className='cursor-pointer'>
                            {' '}
                            <u>Browse image </u>{' '}
                        </span>
                    </span>
                    <input
                        className='d-flex justify-content-center py-2 mb-4 input-drop'
                        type='file'
                        style={{ backgroundColor: 'transparent' }}
                        id='input'
                        name='input'
                        onChange={handleFileChange}
                        multiple
                    />
                </div>
            </div>
            <img
                className='d-flex align-items-center justify-content-center'
                style={{ visibility: 'hidden' }}
                id='dragImage'
                src={dragAndDropIcon}
                alt='Drag and Drop Icon'
                color='#696bff'
                width='100'
                height='100'
            />

            <AvatarEditor
                className='d-flex mx-auto'
                ref={setEditorRef}
                image={picture.img}
                width={200}
                height={200}
                border={50}
                color={[255, 255, 255, 0.6]}
                rotate={0}
                scale={picture.zoom}
            />

            <div className='d-flex justify-content-center align-items-center'>
                <IconSignMinus
                    size={12}
                    color={'#696bff'}
                    className='mr-3'
                />
                <input
                    id="zoom-range"
                    type="range"
                    min="1" max="10"
                    value={picture.zoom}
                    onChange={(e) => handleSlider(e)}
                    step="0.1"

                />
                <IconSignPlus
                    size={12}
                    color={'#696bff'}
                    className='ml-3'
                    style={{ transform: 'rotate(45deg)' }}
                />
            </div>
            <div className='d-flex flex-row justify-content-center'>
                <Button
                    color='primary'
                    activity={loadingAvatar || loadingDeleteAvatar}
                    disabled={!picture?.img}
                    className='col-4 mt-2 mr-1'
                    label={'Save'}
                    onClick={(e) => handleSave(e)}
                    style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                />
                <Button
                    color='error'
                    activity={loadingAvatar || loadingDeleteAvatar}
                    disabled={false}
                    className='col-4 mt-2 ml-1'
                    label='Remove'
                    onClick={() => deleteAvatar({ userId: userId })}
                    style={{ borderRadius: `${'16px 16px 16px 16px'}` }}
                />

            </div>


        </>
    )
}

export default EditAvatar