import React, { useEffect } from 'react'
import {
    ModalLayout,
} from '@Knowledge-OTP/znk-ui-components'
import useDownLoadExcelPlans from './Model/usePlansQueryToCsv'
import useDownLoadExcelUsers from './Model/useUsersQueryToCsv'
import useExcelDownload from './useExcelDownload'

const ExcelDownloadComponent = (props) => {
    const {
        isOpen,
        toggle,
        query: variables,
        type,
    } = useExcelDownload()
    // eslint-disable-next-line no-unused-vars
    const {loadingExcelInfo, setLoadingExcelInfo, execDownload, setExecDownload} = props
    // eslint-disable-next-line no-unused-vars
    const [getPlans, { data: dataPlans, loading: loadingPlans }] = useDownLoadExcelPlans()
    // eslint-disable-next-line no-unused-vars
    const [getUsers, { data: dataUsers, loading: loadingUsers }] = useDownLoadExcelUsers()

    const chooseQuery = (type) => {
        let roleToQuery = ''
        switch (true) {
            case typeof type === 'object':
                roleToQuery = type?.students ? 'STUDENT' :
                    type?.parents ? 'PARENT' :
                        type?.educators ? 'EDUCATOR' :
                            'SUPER_ADMIN'
                variables.role = roleToQuery
                variables.testDate = roleToQuery === 'EDUCATOR' ? null : variables.testDate?.date
                getUsers({
                    variables: {
                        ...variables,
                    }
                }).then(() => {
                    setExecDownload(false)
                })
                return
            case type === 'counselingPlan':
                getPlans({ variables }).then(() => {
                    setExecDownload(false)
                })
                return
            case type === 'plans':
                getPlans(
                    {
                        variables: {
                            ...variables,
                        }
                    }
                ).then(() => {
                    setExecDownload(false)
                })
                return
            default:
                console.error('No type found')
        }
    }

    useEffect(() => {
        if (execDownload && type && variables) {
            chooseQuery(type)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variables, execDownload, type])


    useEffect(() => {
        setLoadingExcelInfo(loadingPlans || loadingUsers)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingPlans, loadingUsers])

    return (
        <ModalLayout
            className={`modal-centered modal-colored h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={`m-0 p-0 mt-4 mb-3 d-flex flex-column`}>
                    <div className={`d-flex flex-row align-items-center mb-5`}>
                        <span className={`h2 text-title m-0`}>Download Excel</span>
                    </div>
                </div>
            }
            underHead={
                <div className='d-flex flex-column w-100 mb-2'>
                    <div>
                        <span className='text-gray'>  Hola </span>

                    </div>

                </div>
            }
        >
            <div className={`m-0 p-0 d-flex flex-column`}>
                <div className={`borderTop w-100`} />

            </div>
        </ModalLayout>
    )
}

export default ExcelDownloadComponent