import React from 'react'
import { IconLoad, IconBook } from '@Knowledge-OTP/znk-ui-components'
import { useLazyQuery, gql } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../../../common/Alert/util'
import { hasHttp } from '../../../utils'

const GetCourseMAterials = (props) => {
    const { serviceId: serviceIdProp = null, topicId: topicIdProp = null, studentId = null } = props
    const dispatch = useDispatch()
    const [getUserInfo, { loading }] = useLazyQuery(
        gql`
            query($id: String!) {
                user(id: $id) {
                    studentInfo {
                        materialsTopic {
                            topicId
                            serviceId
                            link
                        }
                    }
                }
            }
        `, {
        fetchPolicy: 'no-cache'
    }
    )
    return (
        <span
            className={`cursor-pointer`}
            onClick={() => {
                getUserInfo({
                    variables: { id: studentId }
                }).then(
                    (success) => {
                        const materials = success?.data?.user?.studentInfo?.materialsTopic
                        const url = materials.find(
                            ({ serviceId, topicId }) => serviceId === serviceIdProp && topicId === topicIdProp
                        )?.link || ''
                        if (url) window.open(hasHttp(url), '_blank')
                        else {
                            showAlert({
                                status: 'warning',
                                text: 'No course material for this topic.'
                            }, dispatch)
                        }
                    },
                    (err) => {
                        console.error(err.toString())
                    }
                )
            }}
        >
            {
                loading ? <IconLoad className={`mr-2`} /> : <IconBook className={`mr-2`} />
            }
            Course Materials
        </span>
    )

}

export default GetCourseMAterials;