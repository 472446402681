import React from 'react'

const InputColor = (props) => {
    const {label, value, className, onChange = () => {}, onDelete = () => {}} = props
    return (
        <div className={`d-flex flex-row ${className}`}>
            <span className={`align-self-center pr-2`} onClick={onDelete}>{label}</span>
            <input type={'color'} onChange={onChange} value={value}/>
        </div>
    )
}

export default InputColor