import React, { useEffect, useMemo } from 'react'
import { Input, IconErase, ListItem, CheckboxOption } from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'

export const CheckInType = {
    regular: 'regular',
    scoreExpectation: 'scoreExpectation',
}

const CheckIns = (props) => {
    const { form, setForm = () => {}, isEdit = false } = props
    const fieldName = 'checkInDates'

    // const [currentDate, setCurrentDate] = useState(null)
    const addCheckIn = (date) => {
        // if (!date) {
        //     setForm({
        //         ...form, [fieldName]: (form[fieldName] || []).concat([{ date: null }])
        //     })
        // }
        // else 
        setForm({
            ...form, [fieldName]: (form[fieldName] || []).concat([{ date: date ? moment(date).toISOString() : null }])
        })
    }
    const validateParts = (form) => {
        const parts = Object.values(form?.lpw || {})
        //validation
        if (parts.length <= 0) return false
        const datesDefined = parts.reduce((bool, { startdate, enddate }) => {
            return bool && !!startdate && !!enddate
        }, true)
        if (!datesDefined) return false

        return true
    }
    const [minDate, maxDate, isTimeless] = useMemo(() => {
        const valid = validateParts(form)
        if (!valid) return []

        const parts = Object.values(form?.lpw || {})

        const minDate = parts.reduce((min, { startdate }) => {
            if (moment(startdate).toDate() < min) return moment(startdate).toDate()
            return min
        }, Number.POSITIVE_INFINITY)
        const maxDate = parts.reduce((max, { enddate }) => {
            if (moment(enddate).toDate() > max) return moment(enddate).toDate()
            return max
        }, Number.NEGATIVE_INFINITY)

        return [minDate, maxDate, form.typePlan === 'time']
    }, 
    // eslint-disable-next-line
    [form?.lpw, form.typePlan])

    const defaultDates = useMemo(() => {
        if (!minDate || !maxDate) return []

        const dates = []

        const totalDays = Math.abs(moment.duration(moment(minDate).diff(moment(maxDate))).asDays())
        if(isTimeless) {
            //si el plan dura mas de 5 semanas colocar checkin cada 5 semanas
            if(totalDays > 7*5/**5 semanas**/) {
                //calcula cuantos rangos de 5 semanas hay entre [minDate] y [maxDate menos 7 días]
                let totalRanges = Math.abs(moment.duration(moment(minDate).diff(moment(maxDate).subtract(7, 'days'))).asDays())
                totalRanges = Math.floor(totalRanges/(7*5))
                const startMoment = moment(minDate).clone()
                for(let t=1; t <= totalRanges; t++) {
                    dates.push({date: startMoment.clone().add(t*(5), 'weeks').toISOString()})
                }
            }
        } else {
            const midDate = moment(minDate).add(Math.floor(totalDays / 2), 'days').toISOString()
            dates.push({date: midDate})
        }
        
        
        const beforeFinal = totalDays >= 7
            ? moment(maxDate).subtract(7, 'days').toISOString()
            : moment(maxDate).subtract(1, 'days').toISOString()
        return [
            // { date: midDate },
            ...dates,
            { date: beforeFinal }
        ]
    }, [minDate, maxDate, isTimeless])

    const defaultCheckIns = (defaultDates) => {
        setForm({ ...form, [fieldName]: defaultDates })
    }
    useEffect(() => {
        if (defaultDates && !isEdit) defaultCheckIns(defaultDates)
    },
    // eslint-disable-next-line 
    [defaultDates, isEdit])

    const blankCheckIns = () => {
        setForm({ ...form, [fieldName]: [] })
    }
    const deleteDate = (currentIndex) => {
        setForm({ ...form, [fieldName]: form[fieldName].filter((_, index) => index !== currentIndex) })
    }
    const changeType = (index) => {
        setForm({
            ...form,
            [fieldName]: form[fieldName].map(
                (ch, indexC) =>
                    indexC === index ?
                        {
                            ...ch,
                            type:
                                !ch.type || ch.type === CheckInType.regular ?
                                    CheckInType.scoreExpectation :
                                    ch.type || CheckInType.regular
                        }
                        : ch
            )
        })
    }
    return (
        <>
            <div className='pl-0 pr-6 py-6 pl-3'>
                <h3><b>Check In's</b></h3>
                <div className='row pl-3'>

                    {
                        form[fieldName]?.length > 0 && (
                            <div className='w-100 d-flex flex-column'>
                                {
                                    form[fieldName].map(({ date, type }, index) => {
                                        const isLast = index === form[fieldName].length - 1
                                        return (
                                            <ListItem
                                                // showTopLine={isFirst}
                                                showBottomLine={!isLast}
                                                classNameLeft={'flex-grow-1'}
                                                className='w-100 d-flex flex-row align-items-center justify-content-between px-2'
                                                Left={
                                                    <div className='d-flex flex-column justify-content-between align-items-start w-100'>
                                                        <Input
                                                            min={moment(minDate).format('YYYY-MM-DD')}
                                                            max={moment(maxDate).format('YYYY-MM-DD')}
                                                            className={'w-100'}
                                                            value={date && moment(date).format('YYYY-MM-DD')}
                                                            type={'date'}
                                                            onChange={(res) => {
                                                                setForm({
                                                                    ...form, 
                                                                    [fieldName]: form[fieldName].map(
                                                                        (check, currentIndex) => {
                                                                            if(index === currentIndex) {
                                                                                return {...check, date: res?.target?.value/**moment(res?.target?.value).utc().toISOString()**/}
                                                                            }
                                                                            return check
                                                                        }
                                                                    )
                                                                })
                                                            }}
                                                        />
                                                        {
                                                            isLast &&
                                                            <span
                                                                onClick={() => changeType(index)}
                                                                className='d-flex flex-row h45 text-gray w-100 mt-2'>
                                                                <CheckboxOption
                                                                    value={type === CheckInType.scoreExpectation}
                                                                    defaultState={type === CheckInType.scoreExpectation}
                                                                />
                                                                Score Expectation
                                                            </span>
                                                        }
                                                    </div>
                                                }
                                                Right={
                                                    <div className='hover-icon' onClick={() => { deleteDate(index) }}>
                                                        <IconErase size={16} className={'text-error'} />
                                                    </div>
                                                }

                                            />
                                        )
                                    }
                                    )
                                }
                            </div>
                        )
                    }

                    <div className='w-100 d-flex flex-row align-items-center justify-content-between mt-4'>
                        <div className='d-flex flex-row align-items-center w-50'>
                            <span onClick={() => {
                                addCheckIn(null)
                            }} className='btn-link'>Add check-in</span>
                        </div>
                        <div className='w-50 d-flex flex-row align-items-center justify-content-end'>
                            <span onClick={() => { defaultCheckIns(defaultDates) }} className={`h45 btn-link`}>Default</span>
                            <span onClick={blankCheckIns} className={`h45 btn-link text-error ml-2`}>Remove All</span>
                        </div>
                    </div>

                </div>
            </div>
            <div className='borderTop ml-3' />
        </>
    )
}

export default CheckIns