import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'

export default function useCreateNote(onSuccess, onError){
    const [mutation, { data, error, loading }] = useMutation(
        gql`mutation createNote($userId: String!, $content:String!, $checkinId: String) {
            addNotesToUser(userId: $userId, content: $content, checkinId: $checkinId){
                id
            }
        }`
    )

    const [validationErrors, setValidationErrors] = useState({})

    const submit = useCallback((obj) => {
        const errors = validate(obj)
        if (errors && Object.keys(errors).length) {
            setValidationErrors(errors)
            onError('validation Error')
            return
        }

        console.log("obj =>", obj)
        mutation({ variables: obj }).then((result) => {
            onSuccess(result)
        }).catch((e) => {
            setValidationErrors({ general: 'misc.apiError' })
            onError(e)
        })
    }, [mutation, onSuccess, onError])

    return { loading, data , error, validationErrors, submit }
}
