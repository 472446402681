import React, { useEffect, useState, useMemo } from 'react'
import {
  ConfirmModal,
  IconSignPlus,
  MentionNote
} from '@Knowledge-OTP/znk-ui-components'
import useNotesQuery from '../useQueries/useNotes'
import useUsersQuery from '../useQueries/useGetStaff'
import Skeleton from 'react-loading-skeleton'
import useCreateNote from '../../../../../Model/Users/Notes/createNote'
import useTranslation from '../../../../../i18n/useTranslation'
import useDeleteNote from '../../../../../Model/Users/Notes/deleteNote'
import { useSelector } from 'react-redux'
import useUpdateNote from '../../../../../Model/Users/Notes/updateNote'
import OwnerFilters from './Filters/Owners'
import { useGetOwners } from '../useQueries/useGetOwners'
import { TabContent, TabPane } from 'reactstrap'


const Notes = ({ page, studentId, checkinId, className = '' }) => {
  const [creatingNote, setCreatingNote] = useState(false)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
  const [noteIdForDelete, setNoteIdForDelete] = useState(null)
  const [users, setUsers] = useState([])
  const currentUserId = useSelector(state => state.user.id)

  const { translate } = useTranslation()

  const [notesQuery, { data: dataNotes, loading, refetch: refetchNotes }] = useNotesQuery()
  const [usersQuery, { data: dataUsers }] = useUsersQuery()
  const [ownersQuery, { data: dataOwners/** , loading: loadingOwners, refetch: refetchOwners*/ }] = useGetOwners()

  const [ownerUsers, setOwnerUsers] = useState([])
  const [ownersSelected, setOwnersSelected] = useState([])


  const handleToggleModalConfirm = () => {
    setIsOpenModalConfirm(!isOpenModalConfirm)
  }

  const onCreateSuccessNote = () => {
    setCreatingNote(false)
    refetchNotes()
  }

  const onUpdateSuccessNote = () => {
    refetchNotes()
  }

  const { submit: submitCreateNote, loading: loadingCreateNote, validationErrors } = useCreateNote(onCreateSuccessNote, console.log)
  const { submit: submitUpdateNote /**,loading: loadingUpdateNote, validationErrors: validateUpdateErrors**/ } = useUpdateNote(onUpdateSuccessNote, console.log)
  const { submit: submitDeleteNote, loading: loadingDeleteNote } = useDeleteNote(() => { refetchNotes() }, console.log)

  const handleCreateNote = (newText) => {
    if (!newText) {
      return null
    }

    submitCreateNote({ userId: studentId, content: newText, checkinId })

    if (Object.keys(validationErrors).length > 0) {
      console.log(validationErrors)
      return null
    }
  }

  const handleDeleteNote = () => {
    submitDeleteNote({ userId: studentId, noteId: noteIdForDelete })
    setIsOpenModalConfirm(false)
  }

  const handleUpdateNote = (noteId, text) => {
    submitUpdateNote({ userId: studentId, noteId, content: text })
    refetchNotes()
  }

  const onSelectedNoteFilter = (ownersFiltered) => {
    setOwnersSelected(ownersFiltered)
    refetchNotes({ userId: studentId, ownerIds: ownersFiltered})
  }

  useEffect(() => {
    if (page === 'notes' && studentId) {
      notesQuery(
        {
          variables: {
            userId: studentId
          }
        }
      )
      usersQuery({
        variables: {
          role: 'SUPER_ADMIN', 
          $limit: 0, 
          previous: '', 
          next: '', 
          search: undefined, 
          serviceIds: [], 
          topicIds: [], 
          testDate: undefined, 
          classYear: [], 
          countriesIds: [], 
          statuses: true, 
          showInactive: false
        }
      })
    }
    // eslint-disable-next-line
  }, [page, studentId])

  useEffect(() => {
    if (dataNotes?.getNotes) {
      const ownerIds = dataNotes.getNotes.map(note => note.ownerId)
      ownersQuery({
        variables: {
          ids: ownerIds,
          roles: [
            'SUPER_ADMIN',
          ],
          limit: 0
        }
      })
    }
// eslint-disable-next-line
  }, [dataNotes?.getNotes])

  useEffect(() => {
    if (!dataUsers?.allUsersFilter) return
    setUsers(dataUsers.allUsersFilter.docs.map(user => ({id: user.id, display: `${user.firstName} ${user.lastName}`})))
    // eslint-disable-next-line
  }, [dataUsers?.allUsersFilter])

  useEffect(() => {
    if (dataOwners && ownerUsers.length === 0) {
      setOwnerUsers(dataOwners.allUsers.docs.map(owner => {
        if (owner.id === currentUserId) {
          return { id: owner.id, name: 'Me' }
        } else {
          return { id: owner.id, name: `${owner.firstName} ${owner.lastName}` } 
        } 
      }))
    }
    // eslint-disable-next-line
  }, [dataOwners])



  const notesArray = useMemo(
    () => {
      if(!dataNotes?.getNotes) return []
      return checkinId 
        ? dataNotes.getNotes.filter((note) => note?.checkinId === checkinId) 
        : dataNotes.getNotes
    }, [dataNotes, checkinId]
  )

  return (
    <div className={`d-flex flex-column m-0 p-0 ${className}`}>
      <div className='p-2'>
        <OwnerFilters owners={ownerUsers} onSelectedNoteFilter={onSelectedNoteFilter} ownersSelected={ownersSelected} />
      </div>
      <TabContent activeTab={loading || loadingCreateNote || loadingDeleteNote ? 'loading' : 'show'}>
        <TabPane tabId={'loading'}>
          <Skeleton height={190} count={1} className='w-100' />
        </TabPane>
        <TabPane tabId={'show'}>
            <>
              <div
                onClick={() => {
                  if (!creatingNote) {
                    setCreatingNote(true)
                  }
                }}
                style={{ cursor: 'pointer' }}
                className='d-flex flex-row justify-content-start align-items-center znk-note mb-2 p-3'
              >
                <IconSignPlus style={{ transform: 'rotate(45deg)' }} size={16} color='#72809D' />
                <span className='text-gray h45 ml-2'>Create new note</span>
              </div>
              {
                creatingNote &&
                <MentionNote
                  data={users}
                  className='mb-2'
                  onCreate={(newText) => {
                    handleCreateNote(newText)
                  }}
                  isNew={true}
                />
              }
              {
                notesArray.map((note, index) => {
                  return (
                    <MentionNote
                      key={note.id}
                      data={users}
                      className='mb-2'
                      dateNote={note.createdAt}
                      textNote={note.content}
                      ownerNote={(note?.ownerFirstName && note?.ownerLastName) ? `${note?.ownerFirstName} ${note?.ownerLastName.substring(0,1)}.` : undefined}
                      canDelete={note.ownerId === currentUserId}
                      canUpdate={note.ownerId === currentUserId}
                      onUpdate={(textUpdated) => {
                        handleUpdateNote(note.id, textUpdated)
                      }}
                      onDelete={() => {
                        setNoteIdForDelete(note.id)
                        setIsOpenModalConfirm(true)
                      }}
                    />
                  )
                })
              }
            </>
        </TabPane>
      </TabContent>
      <ConfirmModal
        className=''
        classNameActionDiv='flex-column'
        classNameConfirm='text-right text-error'
        classNameCancel='mx-3 text-right'
        isOpen={isOpenModalConfirm}
        toggle={handleToggleModalConfirm}
        title={translate('modal.cancelnote.sure')}
        body={translate('modal.cancelnote.cannotbeundone')}
        confirmAction={() => {
          handleDeleteNote()
        }}
        cancelAction={() => { setIsOpenModalConfirm(false) }}
        confirmText={translate('modal.cancelnote.yesremove')}
        cancelText={translate('modal.cancelnote.cancel')}
      />
    </div>
  )
}

export default Notes
