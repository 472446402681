import {gql, useMutation} from '@apollo/client'

const useCheckInTestRegistrationNotification = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation ($planIds: [ID!]!) {
                checkInTestRegistrationNotification (planIds: $planIds)
            }
        `, {onCompleted, onError}
    )
}

export default useCheckInTestRegistrationNotification