import React, { useState } from 'react'

import {
  Modal,
  useWindowDimensions,
  ListItem,
  IconQuestion,
  IconAdd
} from '@Knowledge-OTP/znk-ui-components'

import useTranslation from '../../../i18n/useTranslation'
import { useDispatch } from 'react-redux'

const Menu = (props) => {
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const toggle = () => {
    setOpen((open) => !open)
  }
  const callHelp = () => {
    // showFrontappChat()
  }
  const newInvoice = () => {
    toggle()
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.newPayslip',
      data: {
        isOpen: true
      }
    })
  }
  const newPayment = () => {
    toggle()
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.newPaymentSlip',
      data: {
        isOpen: true
      }
    })
  }
  return (
    <span>
      <div onClick={toggle}>{props.children}</div>
      <Modal
        buttonLabel={null}
        className={`modal-float modal-${
          breakWidth === 'SM' ? 'botbar mb-1' : 'corner mr-4 mb-8'
        }`}
        backdropClassName={`${breakWidth !== 'SM' && 'no-op'}`}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className='row m-0 p-0'>
          <div
            className='m-0 p-0 w-100'
            style={{
              listStyleType: 'none'
            }}
          >
            <ListItem
              onClick={() => {
                newInvoice()
              }}
              className='listItem-primary px-4'
              Left={
                <div className='d-flex align-items-center text-muted'>
                  <IconAdd size={16} className='mr-4' />
                  <label>New Payslip</label>
                </div>
              }
            />
            <ListItem
              onClick={() => {
                newPayment()
              }}
              className='listItem-primary px-4'
              Left={
                <div className='d-flex align-items-center text-muted'>
                  <IconAdd size={16} className='mr-4' />
                  <label>New Payslip Item</label>
                </div>
              }
            />
            <ListItem
              onClick={() => {
                // eslint-disable-next-line
              }}
              className='listItem-primary px-4'
              Left={
                <div
                  className='d-flex align-items-center text-muted'
                  onClick={callHelp}
                >
                  <IconQuestion size={16} className='mr-4' />
                  <label>{translate('lessons.needhelp')}</label>
                </div>
              }
            />
          </div>
        </div>
      </Modal>
    </span>
  )
}

export default Menu
