import React, { useEffect, useState } from 'react'
import { useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'
import { Row } from 'reactstrap'
import KeysNavigator from './KeysNavigator'
import getI18n from '../../utils/getI18n'
import Skeleton from 'react-loading-skeleton'
import Lang from './Lang'
import I18nContext from '../../contexts/i18n'
import { useDispatch } from 'react-redux'
import { TOGGLE_MODAL_ACTION } from '../../store/modal/const'
import useTranslation from '../../i18n/useTranslation'
import { useHistory, useLocation } from 'react-router'

const I18nView = () => {
  const { breakWidth } = useWindowDimensions()
  const [loading, setLoading] = useState(true)
  const [strings, setStrings] = useState({})
  const [platform, setPlatform] = useState('myLivePrep')
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const { search, pathname } = useLocation()
  const history = useHistory()
  const toggle = () => {
    dispatch({ type: TOGGLE_MODAL_ACTION, payload: 'constants.createCountry' })
  }
  useEffect(() => {
    setLoading(true)
    getI18n(platform === 'admin').then((strings) => {
      setLoading(false)
      setStrings(strings)
    }).catch((e) => {
      setLoading(false)
    })
  }, [platform])
  useEffect(() => {
    const params = new URLSearchParams(search)
    const selectedPath = params.get('platform')
    if (selectedPath && selectedPath !== platform && ['myLivePrep', 'admin'].includes(selectedPath)) {
      setPlatform(selectedPath)
    }
  }, [search, platform])
  const changePlatformI18n = (selectedPlatform) => {
    history.push(`${pathname}?platform=${selectedPlatform}`)
  }
  return (
    <div className='d-flex' style={{ flex: 1 }}>
      <div className='col-12 col-md-6 col-lg-5 col-xl-4 px-md-7'>
        <Row className='mt-md-5'>
          <span className='h1' onClick={toggle}>
            {translate('i18n.title')}
          </span>
        </Row>
        <Row className='my-md-5 px-0'>
          <div className='container-fluid mt-2 mt-md-5 d-flex px-0 flex-column-reverse flex-md-row'>
            <div className='d-flex flex-grow-1 mt-2 mt-md-0'>
              <span className={`h3 mx-3 font-weight-bold ${platform === 'myLivePrep' ? '' : 'text-muted'}`} onClick={() => changePlatformI18n('myLivePrep')}>{translate('i18n.platform.myLivePrep')}</span>
              <span className={`h3 mx-3 font-weight-bold ${platform === 'admin' ? '' : 'text-muted'}`} onClick={() => changePlatformI18n('admin')}>{translate('i18n.platform.admin')}</span>
            </div>
          </div>
        </Row>
        <I18nContext.Provider value={{ setter: setStrings, platform }}>
          <Row>
            {loading ? <Skeleton height={400} /> : <KeysNavigator strings={strings} />}
          </Row>
        </I18nContext.Provider>
      </div>
      {breakWidth !== 'SM' && (
        <div className='col-md-6 col-lg-7 col-xl-8 subBody overflow-auto'>
          <I18nContext.Provider value={{ setter: setStrings, platform }}>
            <Row className='pl-md-4 pr-md-0 pl-lg-6 pr-lg-3 px-xl-8 h-100'>
              {!loading && <Lang strings={strings} />}
            </Row>
          </I18nContext.Provider>
        </div>
      )}
    </div>
  )
}

export default I18nView
