import React, {useState} from 'react'
import {
    Avatar,
    IconArrowDown,
    IconArrowUp
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import StatusField from '../../../../../Fields/Status'
import RenderSelectedLessons from '../../Components/RenderSelectedLessons'
import InnerCompletePlanModal from '../../../../../modal/CompletePlan/inner'
import useTranslation from '../../../../../../../i18n/useTranslation'

const SelectLessonsForPlan = (props) => {
    const {
        currentPlan, pendingLessons = [], 
        onPrevious = () => { }, onNext = () => { }, onChecked = () => { }
    } = props
    const {translate} = useTranslation() 
    const [isOpenCollapse, setOpenCollapse] = useState(false)
    return (
        <>
            <label className={`h45 text-gray mb-2`}>
                {translate("modal.completeGroupPlan.selectLessonsText")}
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy */}
            </label>
            <div className='w-100 d-flex flex-row align-items-center mb-4'>
                <Avatar
                    className={`mr-2`}
                    name={`${currentPlan.studentUser?.firstName} ${currentPlan.studentUser?.lastName}`}
                    uri={currentPlan?.getStudentInfo?.avatar || null}
                />
                <div className={`d-flex flex-column align-items-start justify-content-center `}>
                    <span>{`${currentPlan.studentUser?.firstName} ${currentPlan.studentUser?.lastName}`}</span>
                    <div className={`d-flex flex-row-reverse justify-content-start align-items-center`}>
                        <StatusField spanClassName={`ml-2`} status={currentPlan?.status} />
                    </div>
                </div>
            </div>
            <InnerCompletePlanModal
                actionMode={'pagination'}
                onPrevious={onPrevious}
                onNext={onNext}
                onChecked={onChecked}
                onChange={() => { }}
                useMutation={false}
                dataPlan={currentPlan}
            />


            <div className={`borderTop w-100`} />
            <label className={`text-gray h45 mt-5 mb-3 cursor-pointer`} onClick={() => { setOpenCollapse(!isOpenCollapse) }}>
                {`${pendingLessons.length} ${translate("modal.completeGroupPlan.lessonsSelected")}`}
                {isOpenCollapse ? <IconArrowUp className={`ml-1`} size={12} /> : <IconArrowDown className={`ml-1`} size={12} />}
            </label>
            <Collapse isOpen={isOpenCollapse}>
                <RenderSelectedLessons selectedLessons={pendingLessons} />
            </Collapse>
        </>
    )
}

export default SelectLessonsForPlan