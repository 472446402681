import {useSelector, useDispatch} from 'react-redux'

const useAllStudentModal = () => {
    const reduxData = useSelector((state) => state.modal.counseling.allStudentWithoutTask)
    const dispatch = useDispatch()
    const {isOpen, onChange} = reduxData

    const setOpen = (open = false, onChange = () => {}) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.allStudentWithoutTask',
            data: {
                ...reduxData,
                isOpen: open,
                onChange
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}) => {
            setOpen(true, onChange)
        },
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen, onChange)}
    }
}


export default useAllStudentModal