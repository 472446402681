import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'

export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation (
            $groupId: ID!
            $name: String!,
            $serviceId: ID!,
            $recommendedEducators: [PlanEducatorInput!]!,
            $enforceRecommendedEducators: Boolean!,
            $testDate: DateTime,
            $statusPlan: PlanStatus,
            $parts: [GroupLessonPartInput!]!,
            $paymentItemsConfig: [PaymentsItemsConfigInput!]
            $studentPlans: [StudentPlanInput!]!
            $location: LocationLessonInput
            $typePlanPricing: TypePlanPricing!
            $individualRate: Float!
            $groupRate: Float!
            $excludeDays: [String!]!
    ) {
        editGroupPlan(
          groupId: $groupId,
          input: {
            name: $name,
            serviceId: $serviceId,
            recommendedEducators: $recommendedEducators,
            enforceRecommendedEducators: $enforceRecommendedEducators,
            testDate: $testDate,
            statusPlan: $statusPlan,
            PartsSchedule: $parts,
            paymentItemsConfig: $paymentItemsConfig,
            studentPlans: $studentPlans,
            location: $location,
            typePlanPricing: $typePlanPricing,
            pricingRate: $individualRate,
            groupPricingRate: $groupRate,
          },
          excludeDays: $excludeDays
        ){
        id
        serviceId
        lessonIds
        paymentItemIds
        recommendedEducators {
          educatorUserId
          firstName
          lastName
          rate
          isManualRate
          topicId
        }
        selectedEducators {
          educatorUserId
          firstName
          lastName
          rate
          isManualRate
          topicId
        }
        status
        kind
        createdAt
        updatedAt
        ... on GroupPLan {
          PartsSchedule {
            from
            to
            lessonsPerWeekGroup {
              topicId
              educatorId
              day
              duration
              hour
            }
            lessonsPerWeekIndividual {
              topicId
              lessonsPerWeek
              lockLessons
            }
          }
          studentPlans {
            studentId
            planId
            registerDate
          }
          lessonGroupIds
        }

        }
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.createGroupPlan : null, error, validationErrors, submit }
}
