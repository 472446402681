import React from 'react'
import {
  Activity,
  DropDown
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import {
  parsePlanToEdit,
  redirectToSendOnePlan,
  openNewPlanIndividualModal,
  redirectToCancelOrPause,
  redirectToResumePlan,
  openPlanModal
} from '../../Fields/utils.js'
import { useDispatch, useSelector } from 'react-redux'
import userLinkToMyZinkerz from '../../../../Model/Plan/LinkToMyZinkerz/linkToMyZinkerz'
import { showAlert } from '../../../../common/Alert/util'
import UseDelSessionWithPlanId from '../scheduled/Model/useDeleteSessions'

const OptionsPlan = (props) => {
  const { dataPlan, refetch } = props
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const configInfoPlan = useSelector((state) => state.modal.plan.seeInfo)
  const [delSessionWithPlanId] = UseDelSessionWithPlanId(
    ()=>{
      showAlert(
        {
          text: translate('plans.actions.sessiondeleted'),
          status: 'success',
        },
        dispatch,
      )
    },
    ()=>{
      showAlert(
        {
          text: translate('plans.actions.sessionnotdeleted'),
          status: 'error',
        },
        dispatch,
      )
    },
  )
  const { submit, loading} = userLinkToMyZinkerz(() => {
    showAlert({
      text: 'Plan Linked successfully',
      status: 'success'
    }, dispatch)
  }, console.log)

  const linkToZinerz = (planId) => {
    submit({planId})
    
  }

  const getOption = (status) => {
    /**
     * draft: [edit, view, send, cancel] OK
     * sent: [resend, edit, cancel, aprove, view] OK
     * scheduled: [view, adjust, pause, cancel] OK
     * pause: [resume, cancel, view] OK
     * cancel: [view]
     * If complete and pending: [adjust, pause, complete, cancel]
     **/
    const options = []

    if (status === 'draft') {
      // draft: [edit, view, send, cancel]
      options.push({
        component:
  <span
    className='h4 text-logo'
    onClick={() => { openNewPlanIndividualModal(parsePlanToEdit(dataPlan, configInfoPlan?.thisService?.name || ''), dispatch) }}
  >{translate('plans.actions.edit')}
  </span>
      })

      /** options.push({
        component: <span
          className='h4 text-logo'
          onClick = {() => { openPlanModal(dataPlan, configInfoPlan, dispatch) }}
        >{translate('plans.actions.view')}</span>
      }) **/

      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { redirectToSendOnePlan(dataPlan, refetch, dispatch) }}
        >{translate('plans.actions.send')}
        </span>
      })

      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { delSessionWithPlanId({ variables: {planId: dataPlan.id}}) }}
        >{translate('plans.actions.removesessions')}
        </span>
      })

      options.push({ component: <hr className='mt-2' /> })

      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { redirectToCancelOrPause('cancel', dataPlan, dispatch, refetch) }}
        >{translate('plans.actions.cancel')}
        </span>
      })
    } else if (status === 'sent') {
      // sent: [resend, edit, cancel, aprove, pause]
      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { redirectToSendOnePlan(dataPlan, refetch, dispatch) }}
        >{translate('plans.actions.resend')}
        </span>
      })
      options.push({
        component:
  <span
    className='h4 text-logo'
    onClick={() => { openNewPlanIndividualModal(parsePlanToEdit(dataPlan, 'SAT'), dispatch) }}
  >{translate('plans.actions.edit')}
  </span>
      })

      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { redirectToCancelOrPause('cancel', dataPlan, dispatch, refetch) }}
        >{translate('plans.actions.cancel')}
        </span>
      })

      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { delSessionWithPlanId({ variables: {planId: dataPlan.id}}) }}
        >{translate('plans.actions.removesessions')}
        </span>
      })

      options.push({ component: <hr className='mt-2' /> })

      options.push({
        component: (
          <span
            className="h4 text-logo"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_LIVEPREP_URL ||
                  "https://liveprep.zinkerz.com"}/onboarding/${
                  dataPlan.id
                }?isAdmin=true`,
                "_blank"
              );
            }}
          >
            {translate("plans.actions.approve")}
          </span>
        ),
      });
    } else if (status === 'scheduled') {
      // scheduled: [view, adjust, pause, cancel]
      /** options.push({
        component: <span
          className='h4 text-logo'
          onClick = {() => { openPlanModal(dataPlan, configInfoPlan, dispatch) }}
        >{translate('plans.actions.view')}</span>
      }) **/

      // options.push({
      //   component: <span
      //     className='h4 text-logo'
      //   >{translate('plans.actions.adjust')}
      //   </span>
      // })

      // options.push({
      //   component: <span
      //     className='h4 text-logo'
      //     onClick={() => { redirectToCancelOrPause('pause', dataPlan, dispatch, refetch) }}
      //   >{translate('plans.actions.pause')}
      //   </span>
      // })

      // options.push({ component: <hr className='mt-2' /> })
      if (dataPlan?.location.type === 'zinkerzPlatform') {
        options.push({
          component: <span
            className='h4 text-logo'
            onClick={() => { linkToZinerz(dataPlan.id) }}
          >{loading ? <Activity className='ml-auto mr-auto' /> : 'Link to myZinkerz'}
          </span>
        })
      }
      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { redirectToCancelOrPause('cancel', dataPlan, dispatch, refetch) }}
        >{translate('plans.actions.cancel')}
        </span>
      })
    } else if (status === 'paid') {
      // paid: [view]
      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { openPlanModal(dataPlan, configInfoPlan, dispatch) }}
        >{translate('plans.actions.view')}
        </span>
      })
    } else if (status === 'cancel') {
      // cancel: [view]
      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { openPlanModal(dataPlan, configInfoPlan, dispatch) }}
        >{translate('plans.actions.view')}
        </span>
      })
    } else if (status === 'pause') {
      // pause: [resume, cancel]
      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => {
            redirectToResumePlan(dataPlan, dispatch)
          }}
        >{translate('plans.actions.resume')}
        </span>
      })

      options.push({ component: <hr className='mt-2' /> })

      options.push({
        component: <span
          className='h4 text-logo'
          onClick={() => { redirectToCancelOrPause('cancel', dataPlan, dispatch, refetch) }}
        >{translate('plans.actions.cancel')}
        </span>
      })
    }

    return options
  }
  return (
    <div className='znk-table-dropdown dropdown-menu-width-content'>
      <DropDown
        direction='down'
        fixedWidth
        hoverEnabled={false}
        options={getOption(dataPlan ? dataPlan.status : '')}
      >
        {props.children}
      </DropDown>
    </div>
  )
}

export default OptionsPlan
