import validate from 'validate.js'

const constraints = {
  involveMyself: {
    presence: true
  },
  to: {
    presence: true
  },
  from: {
    presence: true
  },
  educatorUserId: {
    presence: false
  },
  minDurationInMinutes: {
    presence: false
  }
}

export default (data) => {
  return validate(data, constraints)
}
