import {useQuery, gql} from '@apollo/client'


const useAllTimelinesCountries = (variables = {}) => {
    return useQuery(
        gql`
            query {
                allTimelinesCountries {
                    id
                    name
                }
            }
        `,
        {
            variables
        }
    )
}

export default useAllTimelinesCountries