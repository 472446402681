import React from 'react'
import {ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import usePopupDisableTest from './usePopupDisableTest'
import useDisableTestMutation from '../../Model/DisableTest/useDisableTest'

const PopupDisableTest = () => {
  const {translate} = useTranslation()
  const {isOpen, toggle, testId, onChange} = usePopupDisableTest()
  const [disableTest, {loading: loadDisable}] = useDisableTestMutation(
    () => {
        onChange().then(() => {
        toggle()
    })
    }, 
    (err) => {
        console.error(err)
    }
)
  const confirmFunction = () => {
    disableTest({variables: {testId}})

  }

  const cancelFunction = () => {
    toggle()
  }

  return (
    <ConfirmModal
      className='m-4 d-flex flex-column'
      isOpen={isOpen}
      toggle={toggle}
      title={translate('misc.activities.disableTestPopup.Title')}
      body={translate('misc.activities.disableTestPopup.Body')}
      classNameActionDiv='d-flex flex-column justify-content-end w-80'
      alignRight
      confirmAction={() => {
        confirmFunction()
      }}
      loading={loadDisable}
      cancelAction={() => (cancelFunction())}
      confirmText={translate('misc.activities.disableTestPopup.Confirm')}
      cancelText={translate('misc.activities.disableTestPopup.Cancel')}
    />
  )
}

export default PopupDisableTest
