import React, { useEffect, useMemo } from 'react'
import {
    TimelinePlan,
    TimelinePlanSkeleton
} from '@Knowledge-OTP/znk-ui-components'
import usePlans from '../useQueries/usePlans'
import useTimelineReducer from '../../../../../reducers/timeline'
import useCreateTimelineModal from '../../../../Timelines/Modal/CreateTimelineModal/useCreateTimelineModal'
import {TabContent, TabPane} from 'reactstrap'

const TimelinePage = (props) => {
  const {studentId = ''} = props
  const [getPlans, { data, loading }] = usePlans()
  const {parseTimelineFromAPI} = useTimelineReducer()
  const {parseToTimeline} = useCreateTimelineModal() 
  useEffect(
      () => {
          getPlans(
              {
                  variables: {
                      limit: 1,
                      studentsUserIds: [studentId],
                      status: [
                          'scheduled',
                          'application',
                          'postApplication'
                      ],
                      kind: [
                          'counceling'
                      ]
                  } 
              }
          )
          // eslint-disable-next-line
      }, [studentId]
  )
  const events = useMemo(
      () => {
          const auxPlan = data?.plans?.docs[0] || null
          return auxPlan?.timelines ? parseTimelineFromAPI(auxPlan?.timelines) : null
      // eslint-disable-next-line
      }, [data]
  )
  return (
    <div className='d-flex flex-column mx-0 my-4 p-0 text-title'>
      <TabContent activeTab={loading ? 'loading' : events?.length > 0 ? 'show' : 'blank'}>
        <TabPane tabId={'loading'}>
          <TimelinePlanSkeleton/>
        </TabPane>
        <TabPane tabId={'show'}>
          <TimelinePlan
            events={parseToTimeline(events?.filter( ({statusBuild}) => statusBuild !== 'delete' ))}
          />
        </TabPane>
        <TabPane tabId={'blank'}>
          <label className={`h45 text-gray text-center w-100 mt-4`}>User dont have counseling plan active</label>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default TimelinePage
