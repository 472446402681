import { gql, useLazyQuery } from '@apollo/client'

const useGetLinkRequestPM = () => {
  return useLazyQuery(
    gql`
        query (
            $studentEmail: String!
        ) {
            getLinkRequestPaymentMethod(
                studentEmail: $studentEmail
          )
        }`
  )
}

export default useGetLinkRequestPM