import React, {useMemo, useState, useEffect} from 'react'
import {
    ListItem,
    ModalLayout,
    IconIllustratedAlert,
    Avatar,
    CheckboxOption,
    StickyButton
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment-timezone'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../../../../../common/Alert/util'
import useTranslation from '../../../../../../../i18n/useTranslation'
import useSendWspAlertModal from './useSendWspAlertModal'
import useUser from './Model/useUser'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useSendWspAlertForTestDate from '../../Model/useSendWspAlertForTestDate'
import { months } from '../../../../../../../utils/months'

const SendWspAlertModal = () => {
    const { isOpen, toggle, student: user, type, onChange = () => {}, plan } = useSendWspAlertModal()
    const {translate} = useTranslation()
    const {user: student, loading: loadUsers} = useUser(user?.id)
    const [serviceMonth, setServiceMonth] = useState(null)
    const dispatch = useDispatch()
    const [wspAlert, {loading}] = useSendWspAlertForTestDate(
        () => {
            showAlert({text: 'Success!', status: 'success'}, dispatch)
            onChange()
        },
        (err) => {
            showAlert({text: 'Error!', status: 'error'}, dispatch)
            console.error(err)
        }
    )

    const [users, studentId] = useMemo(
        () => {
            if(student) {
                const validParents = (student?.studentInfo?.parents || [])
                    .filter(({manageApprovals, managePayments}) => manageApprovals && managePayments)
                    .map((parent) => {return {...parent, id: parent?.id || parent?._id || parent?.parentUserId}})
                return [[student].concat(validParents), student?.id]
            }
            return []
        }, [student]
    )



    const [messages, setMessages] = useState([])
    useEffect(() => {
        setMessages(
            (users || []).map((student) => {
                return {
                    ...student,
                    message: '', 
                    userId: student?.id || student?._id,
                    check: false
                }
            })
        )
    }, [users]) 
    const buttonDisabled = useMemo(
        () => {
            const checkedUsers = (messages || []).filter(({check}) => check) 
            // console.log("checkedUsers => ", checkedUsers)
            return !(checkedUsers?.length > 0)
        }, [messages]
    )
    const obtainMonth = (date) => {
        const month = new Date(date).getMonth()
        return setServiceMonth(months[month])
    }
    useEffect(() => {
        if(plan?.testDate) {
            obtainMonth(plan?.testDate)
        }
        // eslint-disable-next-line
    }, [plan])
    
    return (
        <ModalLayout
            className='modal-colored modal-centered fixed-head-modal'
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className='d-flex flex-column'>
                    <IconIllustratedAlert className='mb-3' size={80} />
                    <span className='h2 font-weight-bold'>Check-In Student</span>
                </div>
            }
            disabledScrollAnimation
        >
            <div className='m-0 p-0'>
                {/* <span className='text-gray'>{'reason'}</span> */}
                <div className='d-flex flex-column py-4'>
                    <TabContent activeTab={loadUsers ? 'loading' : 'show'}>
                        <TabPane tabId={'loading'}>
                            {
                                new Array(2).fill(0).map(
                                    () => {
                                        return (
                                            <div className={`w-100 d-flex flex-row align-items-center justify-content-start py-2 mb-1`}>
                                                <Skeleton count={1} width={40} height={40} style={{borderRadius: '12px'}} />
                                                <Skeleton className={`ml-3`} count={1} width={100} height={12} />
                                            </div>
                                        )
                                    }
                                )
                            }
                        </TabPane>
                        <TabPane tabId={'show'}>
                            {
                                messages.map((user) => {
                                    const isStudent = student?.id === user?.userId
                                    const userName = `${user?.firstName} ${user?.lastName}`
                                    return (
                                        <ListItem
                                            className='py-2 mb-1'
                                            Left={
                                                <div className={`d-flex flex-row align-items-baseline ${!isStudent ? 'pl-2' : ''}`}>
                                                    <Avatar
                                                        name={userName}
                                                    />
                                                    <span className='ml-2'>{userName}</span>
                                                    <span className='text-gray h5 ml-1'>{`(${isStudent ? translate('modal.infolesson.student') : 'Parent'})`}</span>
                                                </div>
                                            }
                                            Right={
                                                <CheckboxOption
                                                    defaultState={false}
                                                    onChange={(newValue) => {
                                                        setMessages(
                                                            messages.map((student) => {
                                                                const {userId} = student
                                                                if(userId === user.userId) return {...student, check: newValue}
                                                                return student
                                                            })
                                                        )
                                                    }}
                                                />
                                            }
                                        />

                                    )
                                })
                            }
                        </TabPane>
                    </TabContent>
                </div>

                <StickyButton
                    disabled={buttonDisabled}
                    onClickButtonFunction={() => {
                        const registration = plan?.activityTest?.registrationDate || plan?.activityTestDate?.registrationDate
                        const variables = {
                            studentId,
                            type,
                            testDate: plan?.testDate, 
                            registerDate: registration ? moment(registration).format('MMM Do, YYYY') : 'NO REGISTRATION DATE' || moment(plan?.testDate).format('MMM Do, YYYY'),
                            serviceMonth: serviceMonth,
                            serviceName: plan?.examScores?.name,
                            messages: messages.filter(({check}) => check).map(({userId}) => {
                                return {
                                    message: 'DEFAULT_MESSAGE',
                                    userId
                                }
                            })
                        }
                        wspAlert({variables})
                    }}
                    activity={loading}
                    color='#696CFF'
                    className='w-100 mb-3'
                    size='medium'
                    label={translate('modal.sendalert.sendwsp')}
                />
            </div>
        </ModalLayout>
    )
}

export default SendWspAlertModal