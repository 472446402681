import React, { useState, useMemo, useEffect } from 'react'
import {
  Modal,
  useWindowDimensions,
  ButtonOptions,
  IconClose,
  Field,
  CheckboxOption,
  ListItem,
  Avatar,
  Form,
  PlanCard,
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment'
import SendPlansFromAdmin from '../../../../Model/Plan/SendPlansFromAdmin'
import ProgramSentPlan from './Components/ProgramSentPlan'
import ProgramSentPlanLabel from './Components/ProgramSentPlanLabel'
import SendWspItem from '../Components/sendWspItem'
const SendOnePlans = (props) => {
  const { getStudentById } = props
  const { isOpen, confirmAction, dataPlan, refetch } = useSelector((state) => state.modal.plan.sendOnePlanModal)

  //sendPlanCron data
  const { sent, sendDate } = dataPlan?.sendPlanCron || {}
  const hasProgramSent = useMemo(() => !!sendDate && !!!sent, [sendDate, sent])

  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const [sendWspState, setSendWspState] = useState(false)
  const { submit: sendPlans, loading: loadSendPlans } = SendPlansFromAdmin(
    (result) => {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.sendAllPlansModal',
        data: {
          isOpen: false,
          confirmAction: () => { },
          dataPlan: null,
          refetch: refetch
        }
      })
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.sendOnePlanModal',
        data: {
          isOpen: false,
          confirmAction: () => { },
          dataPlan: null,
          refetch: refetch
        }
      })
      refetch()
    }, (err) => {
      console.log(err)
    }
  )
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.sendOnePlanModal',
      data: { isOpen: !isOpen, confirmAction, dataPlan: dataPlan, refetch: refetch }
    })
  }
  const [form, setForm] = useState({
    student: false,
    parent: []
  })
  useEffect(() => {
    if (!isOpen) setForm({
      student: false,
      parent: []
    })
  }, [isOpen])
  const onChangeParentFn = (id) => {
    let arrayIds = []
    if (form?.parent.includes(id)) {
      arrayIds = form?.parent.filter(p => p !== id
      )
    } else {
      arrayIds = form?.parent
      arrayIds.push(id)
    }
    setForm({ ...form, parent: arrayIds })
  }

  const partArray = dataPlan ? (dataPlan.parts || dataPlan.fixedParts) : []
  const startDate = (partArray || []).reduce((minDate, part) => {
    const date = moment(part.from.split('T')[0]).valueOf()
    if (minDate >= date) return date
    return minDate
  }, Number.POSITIVE_INFINITY)

  const endDate = (partArray || []).reduce((maxDate, part) => {
    const date = moment(part.to ? part.to.split('T')[0] : part.tof.split('T')[0]).valueOf()
    if (maxDate <= date) return date
    return maxDate
  }, Number.NEGATIVE_INFINITY)
  const lpwTotal = partArray.length
    ? (dataPlan.testDate
      ? partArray[0].lessonsPerWeek
      : partArray[0].TotalPerTopic.reduce((totalLessons, part) => totalLessons + (part.lessonsPerWeek || 0), 0))
    : 0

  const studentInfo = getStudentById(dataPlan ? dataPlan.studentUser.studentUserId : '')
  // const parentInfo = studentInfo?.studentInfo?.parents ? studentInfo.studentInfo.parents.reduce((accuPar, par) => {
  //   if (par.managePayments) return par
  //   else return accuPar
  // }, {}) : {}
  const parentInfo = studentInfo?.studentInfo?.parents || []

  const validation = useMemo(() => {
    return (form.student || !!form.parent.length > 0)
  }, [form])

  const totalPlan = dataPlan?.kind === 'lessonsFixed' && (partArray || []).reduce((isTotal, part) => isTotal || part?.type === 'totalLesson', false)

  const onSendPlan = (programDate = undefined) => {
    //si [programDate] o [programTime] es undefined entonces envía el plan
    // sino lo programa para enviarlo en el futuro
    let variables = {
      items: [
        {
          planId: dataPlan.id,
          sendToStudent: form.student,
          sendToParent: !!form.parent,
          userParentIds: form?.parent || [],
        }
      ],
      sendWspToStudent: sendWspState
    }
    if (programDate) {
      variables.sendDate = programDate
    }
    sendPlans(variables)
  }

  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'
          } modal-colored`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header mx-5 mt-7 mb-1 d-flex flex-column'>
            <div className='m-0 p-0 d-flex flex-row w-100 justify-content-between'>
              <span className='h2 font-weight-bold text-title mr-1'>
                {translate('plans.modal.sendplan.titleAll')}
              </span>
              <IconClose size={24} onClick={toggle} />
            </div>
            <span className='h45 text-gray'>
              {translate('plans.modal.sendplan.subTitle')}
            </span>
          </div>
        }
      >
        <div className='row mt-1 mx-4 mb-4 p-0'>
          <div className='m-0 p-0 w-100 mt-1 px-5 mb-5'>
            <div className='m-0 p-0'>
              <Form value={form} onChange={setForm}>
                {
                  dataPlan && dataPlan.studentUser && (
                    <ListItem
                      className='py-2'
                      label={null}
                      Left={
                        <div className='d-flex align-items-center'>
                          <Avatar
                            name={`${dataPlan.studentUser.firstName} ${dataPlan.studentUser.lastName}`}
                            named={false}
                            uri={dataPlan?.studentUser?.avatar}
                            size='avatar-small'
                          />
                          <label className='ml-2 h4'>
                            {`${dataPlan.studentUser.firstName} ${dataPlan.studentUser.lastName}`}
                            <span className='h5 text-gray'> {`(${translate('plans.modal.sendplan.student')})`}</span>
                          </label>
                        </div>
                      }
                      Right={
                        <Field
                          type={CheckboxOption}
                          fieldName='student'
                          checked={form.student}
                        />
                      }
                    />
                  )
                }
                {
                  studentInfo && parentInfo?.length > 0 &&
                  studentInfo.studentInfo.studentType === 'family' &&
                  parentInfo.map((p, idx) =>
                  (
                    <ListItem
                      className='py-2'
                      label={null}
                      Left={
                        <div className='d-flex align-items-center'>
                          <Avatar
                            name={`${p.firstName} ${p.lastName}`}
                            named={false}
                            uri={p.avatar}
                            size='avatar-small'
                          />
                          <label className='ml-2 h4'>
                            {`${p.firstName} ${p.lastName}`}
                            <span className='h5 text-gray'> {`(${translate('plans.modal.sendplan.parent')})`}</span>
                          </label>
                        </div>
                      }
                      Right={
                        <Field
                          type={CheckboxOption}
                          fieldName={idx}
                          checked={form?.parent.includes(p.parentUserId)}
                          onChange={() => {
                            onChangeParentFn(p.parentUserId)
                          }}
                        />
                      }
                    />
                  )

                  )
                }
                {
                  dataPlan && (
                    <PlanCard
                      name={dataPlan.name}
                      startTime={moment(startDate)}
                      endTime={moment(endDate)}
                      status={dataPlan.status}
                      className='pending mt-3'
                      translate={translate}
                      lessonsTotal={lpwTotal}
                      isTimelessPlan={dataPlan?.kind === 'lessonsFixed'}
                      isLessonPerWeek={!totalPlan}
                    />
                  )
                }
              </Form>
            </div>
            {
              hasProgramSent && (
                <ProgramSentPlanLabel
                  planId={dataPlan.id}
                  onDelete={() => {
                    refetch()
                  }}
                  sendDate={sendDate} />
              )
            }
            <SendWspItem 
              sendWspState={sendWspState}
              setSendWspState={setSendWspState}
              dataPlan={dataPlan}
            />
            <ButtonOptions
              color='#696CFF'
              style={{ backgroundColor: '#696CFF' }}
              className='w-100 mt-3'
              disabled={!validation}
              activity={loadSendPlans}
              label={
                <span className='w-100 d-flex' style={{ justifyContent: 'space-between' }}>
                  {translate('plans.modal.sendplan.button')}
                </span>
              }
              onClick={() => { onSendPlan() }}
              options={[
                {
                  component:
                    <div>
                      <ProgramSentPlan
                        onProgram={(sendDate) => {
                          onSendPlan(sendDate)
                        }}
                      />
                    </div>
                }
              ]}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SendOnePlans
