import React from 'react'
import {
    ToolTipInfo,
    IconTime
} from '@Knowledge-OTP/znk-ui-components'
import {useSelector} from 'react-redux'
import useTranslation from '../../../i18n/useTranslation'
import moment from 'moment-timezone'
import {nanoid} from 'nanoid'

const formatDate = 'MM/DD/YYYY hh:mm a'

const SentPlanCron = (props) => {
    const {plan = {}, className=''} = props
    const {sent, sendDate} = plan?.sendPlanCron || {}
    const currentTimezone = useSelector((state) => state.user.timezone)
    const {translate, replaceVariables} = useTranslation()
    const text = replaceVariables(translate('plans.modal.sendplan.programSentPlan.programFor'), {'DATE': moment(sendDate).tz(currentTimezone).format(formatDate)})
    const tooltipId = nanoid()
    return (
        !!sendDate && !!!sent && (
            <ToolTipInfo
                tooltipId={tooltipId}
                innerElement={text} 
                className={`${className}`}
            >
                <IconTime size={16} className={'text-gray h45'}/>
            </ToolTipInfo>
        )
    )
}

export default SentPlanCron