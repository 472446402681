import { useCallback } from 'react'
import { gql, useMutation } from '@apollo/client'

export default (onSuccess, onError = () => { }) => {
    const [mutation, { data, error, loading }] = useMutation(
        gql`
            mutation($lessonIds: [ID!]!) {
                removeLessonFromAdminIncativeUser (
                    lessonIds: $lessonIds
                )
            }
        `,
    )
    const submit = useCallback(
        (obj) => {
            mutation({ variables: obj })
                .then((result) => {
                    onSuccess(result)
                })
                .catch((e) => {
                    onError(e)
                })
        },
        [mutation, onSuccess, onError],
    )
    return { loading, data: data ? data.removeLessonFromAdminIncativeUser : null, error, submit }
}
