import React from 'react'
import {useWindowDimensions, ConfirmModal} from '@Knowledge-OTP/znk-ui-components'
import useDismissNotification from './useDismissNotification'
import useCheckInTestRegistrationNotification from '../../Model/useCheckInTestRegistrationNotification'
import useCheckInScoreNotification from '../../Model/useCheckInScoreNotification'
import {showAlert} from '../../../../../../../common/Alert/util'
import {useDispatch} from 'react-redux'

const DismissNotificationModal = (props) => {
    const {title = `Dismiss Plan`, 
           textBody = 'You are gonna cancel this timeline plan.',
           confirmText = 'Dismiss Plan',
           cancelText = 'Cancel'
          } = props 
    const {breakWidth} = useWindowDimensions()
    const dispatch = useDispatch()
    const {isOpen, toggle, type, planIds, onChange = () => {}} = useDismissNotification()
    const onSuccess = () => {
        showAlert({
            text: 'Success!',
            status: 'success'
        }, dispatch)
        onChange()
    }
    const onError = (err) => {
        showAlert({
            text: err.toString(),
            status: 'error'
        }, dispatch)
        console.log(err)
    }
    
    const [checkScore, {loading: loadScore}] = useCheckInScoreNotification(onSuccess, onError)
    const [checkRegistration, {loading: loadRegistration}] = useCheckInTestRegistrationNotification(onSuccess, onError)
    
    return (
        <ConfirmModal
            classNameModal={`${['SM'].includes(breakWidth) ? 'bottom-modal' : ''}`}
            loading={loadScore || loadRegistration}
            classNameActionDiv='flex-row justify-content-end'
            classNameConfirm='text-right text-error'
            classNameCancel='mx-3 text-right'
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            body={<span className={`text-info`}>{textBody}</span>}
            confirmAction={() => {
                const variables = {planIds}
                if(type === 'getScore') checkScore({variables})
                else checkRegistration({variables})// testRegistration
            }}
            cancelAction={toggle}
            confirmText={confirmText}
            cancelText={cancelText}
        />
    )
}

export default DismissNotificationModal