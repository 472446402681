import React, {useMemo} from 'react'
import {
    DropDownNew,
    Input,
    IconOptions,
    CheckboxOption
} from '@Knowledge-OTP/znk-ui-components'
import {Collapse} from 'reactstrap'
import useTranslation from '../../../../../../../../../i18n/useTranslation'

const Options = (props) => {
    const { form, setForm, educatorsBase, educator, type, topic } = props
    const { translate } = useTranslation()
    const getLink = (educatorId) => {
        if (educatorsBase && educatorsBase.length === 0) {
            return ''
        }
        const base = educatorsBase.find(element => element.id === educatorId)
        if (!base) {
            return ''
        }
        const link = base.educatorInfo?.backupLink ?? ''
        return link
    }
    const isWaive = useMemo(
        () => {
            return !form[`educators${type}`][topic.id].isWaiveGroup
            //eslint-disable-next-line
        }, [form, topic]
    )
    return (
        <DropDownNew
            classNameMenu='pt-4 px-4 pb-2'
            direction='bottomRight'
            fixedWidth
            hoverEnabled={false}
            options={[
                {
                    component: ['hangouts', 'zoom'].includes(form.locationPlan)
                        ? <div className='py-4 px-2'>
                            <div>
                                <label className='h4 text-gray mb-3'>Location Link:</label>
                                <Input
                                    placeholder={form.locationPlan === 'hangouts' ? 'http://hangouts.com/ASDF' : 'http://zoom.com/ASDF'}
                                    type='text'
                                    value={form.locationPlan === 'hangouts' ? getLink(educator.educatorUserId) : educator.backupLink}
                                    readOnly={!(type === 'Group' && ['zoom'].includes(form.locationPlan))}
                                    disabled={form.statusPlan === 'scheduled'}
                                    onChange={(e) => {
                                        setForm({
                                            ...form,
                                            [`educators${type}`]: { ...form[`educators${type}`], [topic.id]: { ...form[`educators${type}`][topic.id], backupLink: e.target.value } },
                                            educatorsGroupFull: form.educatorsGroupFull.map(edit => {
                                                return {
                                                    ...edit,
                                                    backupLink: edit.educatorUserId === educator.educatorUserId ? e.target.value : edit.backupLink
                                                }
                                            })
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        : null
                },
                {
                    component:
                        <div className='py-4 px-2'>
                            <Collapse isOpen={isWaive}>
                                <div>
                                    <label className='h4 text-gray mb-3'>{translate('modal.newplan.educatorwage')} :</label>
                                    <Input
                                        placeholder={translate('modal.newplan.wage')}
                                        disabled={form.statusPlan === 'scheduled'}
                                        type='number'
                                        // value={type === 'Group' ? educator.rate : form.educatorsIndividual[topic.id].rate || (educators[topic.id].educatorInfo ? educators[topic.id].educatorInfo.defaultRate : educators[topic.id].rate)}
                                        value={educator.rate}
                                        onChange={(e) => {
                                            setForm({
                                                ...form,
                                                [`educators${type}`]: { ...form[`educators${type}`], [topic.id]: { ...form[`educators${type}`][topic.id], rate: e.target.value } },
                                                educatorsGroupFull: form.educatorsGroupFull.map(edit => {
                                                    return {
                                                        ...edit,
                                                        rate: edit.educatorUserId === educator.educatorUserId ? e.target.value : edit.rate
                                                    }
                                                })
                                            })
                                        }}
                                    />
                                </div>
                            </Collapse>

                            <label
                                className='h4 mt-3 btn-link text-gray d-flex flex-row'
                            >
                                {
                                    <CheckboxOption
                                    defaultState={form[`educators${type}`][topic.id].isWaiveGroup}
                                    onChange={() => {
                                        setForm({
                                            ...form,
                                            [`educators${type}`]: { ...form[`educators${type}`], [topic.id]: { ...form[`educators${type}`][topic.id], isWaiveGroup: !form[`educators${type}`][topic.id].isWaiveGroup } },
                                            educatorsGroupFull: form.educatorsGroupFull.map(edit => {
                                                return {
                                                    ...edit,
                                                    isWaiveGroup: !form[`educators${type}`][topic.id].isWaiveGroup
                                                }
                                            })
                                        })
                                    }}
                                    />
                                }
                                Waive this payments
                            </label>
                        </div>
                }
            ]}
        >
            <div className={`hover-icon`}>
                <IconOptions className='rotate-90' />
            </div>
        </DropDownNew>
    )
}

export default Options