export const planQueryResult = `
    id
    studentUser {
        studentUserId
        firstName
        lastName
    }
    serviceId
    testDate
    activityTest {
        registrationDate
        scoreReleaseDate
    }

    timesSkipped
    dateSkipped
    selectedExam {
      examId {
        _id
      }
      scores {
        name
        results {
          name
          scale
          value
          values {
            name
            scale
            value
          }
        }
        examDate
        overall
      }
    }

    ... on LessonsPerWeekPlan {
        activityTestDate {
            startDate
            serviceId
            registrationDate
            scoreReleaseDate
            id
        }
        examScores {
            id
            _id
            name
            results {
              name
              scale
              values {
                name
                scale
              }
            }
            serviceId
        }
    }
    `
    // startDate