import React, { useEffect } from 'react'
import { InputSelect } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import useActivities from '../../../../../../../CounselingTemplates/Task/Model/useActivities'
import CreateActivityModal from '../../../../../../../Constants/Activities/Create'
import useServices from '../../../../../../../../Model/Services/List'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../../../store/modal/const'
import useModalState from '../../../../../../../../utils/useModalState'


const AllActivities = (props) => {
  // eslint-disable-next-line
  const { selected, setSelected, examData, activities, setActivities, isView = false } = props
  const {translate} = useTranslation()
  const modalState = useModalState('activities.create')
  const { modalData} = modalState
  // const [activities, setActivities] = useState([])
  // const [selected, setSelected] = useState(null)
  const dispatch = useDispatch()

  const { data: services, loading: servicesLoading } = useServices({ limit: 0 })
  const [
    activitiesQuery,
    // eslint-disable-next-line
    { data: dataActivities, loading: loadingActivities, refetch: activitiesRefetch },
  ] = useActivities()

  const onSelect = (exam) => {
    setSelected(exam)
  }

  useEffect(() => {
    activitiesQuery({
      variables: {
        kinds: ['test'],
        serviceIds: [],
        from: null,
        to: null
      },
    })
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (dataActivities) {
      const serviceToFilter = examData?.examScoreId || examData?.serviceId
      const acitivitiesFilter = dataActivities?.allActivities?.filter(
        (activity) => activity.serviceId === serviceToFilter
      )
      let activityToShow;
      if (acitivitiesFilter.length > 0) {
        activityToShow = acitivitiesFilter?.filter((activity) =>
          activity.id === selected?.id
        )
        setActivities(acitivitiesFilter)
      } else if (selected?.id) {
        setActivities(dataActivities?.allActivities.filter((activity) => activity.serviceId === selected?.serviceId))
        activityToShow = dataActivities?.allActivities?.filter((activity) =>
          activity.id === selected?.id
        )
      } else if (selected?.serviceId) {
        setActivities(dataActivities?.allActivities.filter((activity) => activity.serviceId === selected?.serviceId))
      }
      if (Array.isArray(activityToShow) && activityToShow.length > 0) {
        setSelected(activityToShow[0])
      }
    } else {
      setActivities([{ id: '000', name: translate('modal.task.activity.anotherOption') }])
    }
    // eslint-disable-next-line
  }, [dataActivities, selected])

  return (
    loadingActivities ? <Skeleton count={1} width={375} height={44} /> :
      <div className='mb-4 mt-1'>
        <div className='d-flex flex justify-content-between align-items-end'>
          <span className='h45 text-gray mb-2'>{translate('modal.task.activity.selectDate')}</span>  
          {!isView && (
            <span className='h5 text-gray mb-2'
              style={{ cursor: 'pointer' }}
              isDisabled={isView}
              onClick={()=> {
                dispatch({
                  type: SET_DATA_MODAL_FORM_LIST,
                  payload: 'activities.create',
                  data: { ...modalData, isOpen: true, kind: 'test', refetch: activitiesRefetch }
                })
              } }> 
              {translate('modal.task.activity.newActivity')}</span>
          )}
        </div>
        <div>

          {activities?.length > 0 &&
            <InputSelect
              style={{ color: '#2C3F58' }}
              value={selected?.id}
              menuPlacement={'top'}
              isClearable={false}
              isDisabled={isView}
              options={
                activities
                  .map((activity) => {
                    return {
                      value: activity.id,
                      label: activity.name
                    }
                  })
              }
              onChange={(e) => {
                const exam = activities.find(({ id }) => id === e)
                onSelect(exam)
              }}
            />
          }
          <CreateActivityModal {...{ services, servicesLoading }} />
        </div>

      </div>
  )
}

export default AllActivities