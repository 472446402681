import React, { useState, useMemo } from 'react'
import { Activity, ConfirmModal } from '@Knowledge-OTP/znk-ui-components'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import moment from 'moment'
import { Collapse } from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'
import setStudentType from '../../../../Model/Users/ChangeType'
import setEducatorStatus from '../../../../Model/Users/Educators/ChangeStatus'
// import setUserStatus from '../../../../Model/Users/ChangeStatus'
import useCheckUsersLessonsMatch from '../../../../Model/Users/checkUsersMatch'
import Switch from '../../Components/Switch'
import ShowLessonsPending from './ShowLessonsPending'
import EducatorAcademicInfo from './Components/EducatorAcademicInfo'
import SincronizeUserMailchimpItem from '../../../Student/modal/InfoStudent/Profile/SincronizeUserMailchimpItem'
import { showAlert } from '../../../../common/Alert/util'
import useToggleActiveStatus from '../../../Dashboard/Student/Widget/StudentWithoutLessons/Model/useToggleActiveStatus'
import SwitchUserStatus from './Components/SwitchUserStatus'
import EducatorCalendarLink from './Components/EducatorCalendarLink'

const ContentFull = (props) => {
    const { 
        user, 
        type, 
        // toggleRightSide, 
        afterEditPersonalAction, 
        afterEditStudentInfoAction, 
        afterEditFamilyInfoAction, 
        refetch: update, 
        refetchList = () => {} 
    } = props

    const updateUser = (userId) => {
      update(userId)
      refetchList()
    }

    const [collapsePersonal, setCollapsePersonal] = useState(false)
    const [collapseFamily, SetCollapseFamily] = useState(false)
    const [showLessons, setShowLessons] = useState(false)
    const [students, setStudents] = useState([])
    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const [fakeSwitchLoading, setFakeSwitchLoading] = useState(false)

    const dispatch = useDispatch()
    const { translate } = useTranslation()
    // const closeFull = () => {
    //     dispatch({ 
    //         type: SET_DATA_MODAL_FORM_LIST, 
    //         payload: 'user.profile', 
    //         data: { isOpen: true, full: false, type: type } 
    //     })
    // }
    const editPersonalInfo = () => {
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'user.edit',
        data: {
            isOpen: true,
            ...user,
            currentIsActive: user.isActive,
            phoneDialCode: user.phone ? user.phone.split(' ')[0] : '',
            phoneNumber: user.phone ? user.phone.split(' ')[1] : '',
            birthDay: user.birthdate ? moment(user.birthdate).date() : '',
            birthMonth: user.birthdate ? moment(user.birthdate).month() : '',
            birthYear: user.birthdate ? moment(user.birthdate).year() : '',
            email: user.emails[0].address,
            timezone: { value: user.timezone, label: user.timezone },
            country: { value: user.country.id, label: user.country.name }
        }
      })
      if(afterEditPersonalAction) afterEditPersonalAction()
    }
    const editAcademicInfo = () => {
        const { studentInfo } = user
        dispatch(
        {
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'user.studentInfo',
            data: {
            isOpen: true,
            id: user.id,
            ...(studentInfo ? ({
                ...studentInfo,
                month: moment(studentInfo.graduationDate).month(),
                year: moment(studentInfo.graduationDate).year()
            }) : {
                month: moment().month(),
                year: moment().year()
            })
            }
        }
        )
        if(afterEditStudentInfoAction) afterEditStudentInfoAction()
    }
    const editFamilyInfo = () => {
        const { studentInfo } = user
        dispatch(
        {
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'user.familyInfo',
            data: {
            isOpen: true,
            userId: user.id,
            parents: studentInfo.parents
            }
        }
        )
        if(afterEditFamilyInfoAction) afterEditFamilyInfoAction()
    }
    const { submit, loading } = setStudentType(() => {
        update()
        refetchList()
    }, console.log)
    const value = user?.studentInfo?.studentType === 'family'
    const changeType = () => {
        submit({ userStudentId: user.id, type: value ? 'independent' : 'family' })
    }
    const { submit: submitStatus, loading: LoadingStatus } = setEducatorStatus((res) => {
      setShowLessons(false)
      updateUser(user.id)
      setIsOpenConfirm(false)
    }, console.log)
    
    
    const isEducatorActive = useMemo(
      () => {
        return user?.educatorInfo?.isActive && user?.isActive
      }, [user]
    )
    const submitStatusUserOk = () => {
      update(user.id)
      refetchList()
      setIsOpenConfirm(false)
      setShowLessons(false)
    }

    const [submitStatusUser, { loading: LoadingStatusUser }] = useToggleActiveStatus()
    // .then((res)=>{
    //   showAlert({
    //     text: 'Success!',
    //     status: 'success'
    // }, dispatch)
    // },
    // (err) => {
    //   showAlert({
    //     text: err?.toString(),
    //     status: 'error'
    // }, dispatch)
    // })
    const [submitCheck, {loading: loadCheckSubmit} ] = useCheckUsersLessonsMatch()
    const openLessonsMatchModal = (checkUsersLessonsMatch) => {
      if (checkUsersLessonsMatch?.length){
        setStudents(checkUsersLessonsMatch)
        setShowLessons(true)
      } else if( checkUsersLessonsMatch?.length === 0 ) {
        if (type === 'educator') excuteChangeStatus(false)
        else excuteChangeStatusUser(false)
      }
      setFakeSwitchLoading(false)
    }
    const changeStatus = () => {
      setFakeSwitchLoading(true)
      if (user?.isActive) {
        submitCheck({variables: {isStudent: false, userId: user.id}})
        .then(
          (res) => {openLessonsMatchModal(res?.data?.checkUsersLessonsMatch)},
          (err) => {
            showAlert({text: err.toString(), status: 'error'}, dispatch)
            setFakeSwitchLoading(false)
          }
        )
      } else {
        excuteChangeStatus(false)
        setFakeSwitchLoading(false)
      }
    }
    const changeStatusUser = () => {
      setFakeSwitchLoading(true)
      if (user?.isActive && type === 'student') {
        submitCheck({variables: {isStudent: true, userId: user.id}})
          .then(
            (res) => {openLessonsMatchModal(res?.data?.checkUsersLessonsMatch)},
            (err) => {showAlert({text: err.toString(), status: 'error'}, dispatch)}
          )
      } else {
        excuteChangeStatusUser(false)
      }
    }
    const toggleLessons = () => {
      setShowLessons((prev) => !prev)
    }
    const excuteChangeStatus = (hasLesson) => {
      if (hasLesson) setIsOpenConfirm(true)
      else submitStatus({ userEducatorId: user.id, status: !isEducatorActive, userStatus: !isEducatorActive })
    }
    const excuteChangeStatusUser = (hasLesson) => {
      if (hasLesson) setIsOpenConfirm(true)
      else submitStatusUser({
        variables: { userId: user?.id, force: false }
    }).then((res)=>{
      submitStatusUserOk()
        showAlert({
          text: 'Success!',
          status: 'success'
      }, dispatch)
      },
      (err) => {
        showAlert({
          text: err?.toString(),
          status: 'error'
      }, dispatch)
      })
      // ({ userId: user.id, status: !user.isActive })
    }

    return (
        <>
            <section className='d-flex flex-row justify-content-end'>
              <SincronizeUserMailchimpItem userId={user.id}/>
            </section>

            {
              showLessons && <ShowLessonsPending
                                isOpen={showLessons}
                                toggle={toggleLessons}
                                students={students}
                                handletAction={
                                  (hasLessons) => {
                                    if (type === 'educator') excuteChangeStatus(hasLessons)
                                    else excuteChangeStatusUser(hasLessons)
                                  }
                                }
                                loading={LoadingStatus}
                                type={type}
                              />
            }
            {
              ['student', 'parent'].includes(type)  && (
                <div className='d-flex flex-row justify-content-between mt-5'>
                  <span className='text-title'>{user?.isActive ? translate('misc.active') : translate('misc.inactive')}</span>
                  {LoadingStatusUser || loadCheckSubmit || fakeSwitchLoading ? (
                    <Activity />
                  ) : (
                    <Switch idComponent={'toggle-isActive'} isOn={user?.isActive} onColor='#696CFF' handleToggle={() => changeStatusUser()} />
                  )}
                </div>
              )
            }
            {
              ['staff'].includes(type) && (
                <div className='d-flex flex-row justify-content-between mt-5'>
                  <span className='text-title'>{user?.isActive ? translate('misc.active') : translate('misc.inactive')}</span>
                  <SwitchUserStatus userId={user?.id} onChange={() => {updateUser(user.id)}} />
                </div>
              )
            }
            {
              type === 'educator' && (
                <>
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>{isEducatorActive ? translate('misc.active') : translate('misc.inactive')}</span>
                    {user && user.educatorInfo && !LoadingStatus && !fakeSwitchLoading ? (
                      <Switch idComponent={`toggle-educatorStatus-${user?.id}`} isOn={isEducatorActive} onColor='#696CFF' handleToggle={() => changeStatus()} />
                    ) : (<Activity />)}
                  </div>
                </>
              )
            }
            <ConfirmModal
                classNameActionDiv={`d-flex flex-row`}
                isOpen={isOpenConfirm}
                toggle={setIsOpenConfirm}
                loading={LoadingStatus || LoadingStatusUser}
                title={'Are you sure?'}
                body={translate('payments.processpayment.titleInactive')}
                confirmAction={() => {
                    if (type === 'educator') submitStatus({ userEducatorId: user.id, status: !isEducatorActive, userStatus: !isEducatorActive })
                    else submitStatusUser({
                      variables: { userId: user?.id, force: false }
                  })
                }}
                cancelAction={() => { setIsOpenConfirm() }}
                classNameConfirm={type === 'educator' ? isEducatorActive ? 'text-error' : 'text-primary' : user.isActive ? 'text-error' : 'text-primary' }
                confirmText={type === 'educator' ? isEducatorActive ? 'Yes, Inactivate' : 'Yes Activate' : user.isActive ? 'Yes, Inactivate' : 'Yes Activate' }
                cancelText={'Cancel'}
            />
            <div className='d-flex flex-row justify-content-between mt-5'>
              <div className='d-flex'>
                <span className='font-weight-bold'>Personal Info</span>
              </div>
              <span className='btn-link' onClick={editPersonalInfo}>Edit</span>
            </div>
            <div className='borderTop mt-3' />
            <div className='d-flex flex-row justify-content-between mt-4'>
              <span className='text-title'>{type === 'student' ? 'Student' : type === 'educator' ? 'Educator' : type === 'parent' ? 'Parent' : 'Staff'} Name</span>
              <span className='text-gray'>{user.firstName} {user.lastName}</span>
            </div>
            <div className='borderTop mt-4' />
            <div className='d-flex flex-row justify-content-between mt-4'>
              <span className='text-title'>Email</span>
              <span className='text-gray'>{user.emails[0].address}</span>
            </div>
            <div className='borderTop mt-4' />
            <div className='d-flex flex-row justify-content-between mt-4'>
              <span className='text-title'>Gender</span>
              <span className='text-gray'>{
                user?.gender 
                ? user.gender.substring(0, 1).toUpperCase()+user.gender.substring(1, user.gender.length).toUpperCase()
                : 'No defined'
              }</span>
            </div>
            <div className='borderTop mt-4' />
            <div className='d-flex flex-row justify-content-between mt-4'>
              <span className='text-title'>Birthday</span>
              <span className='text-gray'>{moment(user.birthdate).format('DD-MM-YYYY')}</span>
            </div>
            <div className='borderTop mt-4' />
            <div className='d-flex flex-row justify-content-between mt-4'>
              <span className='text-title'>Mobile Phone</span>
              <span className='text-gray'>{user.phone}</span>
            </div>
            <div className='borderTop mt-4' />
            <div className='d-flex flex-row justify-content-between mt-4'>
              <span className='text-title'>Spoken Languages</span>
              <span className='text-gray'>{user.spokenLanguages}</span>
            </div>
            <Collapse
              isOpen={type === 'student' ? collapsePersonal : true}
            >
              <div className='borderTop mt-4' />
              <div className='d-flex flex-row justify-content-between mt-4'>
                <span className='text-title'>Address</span>
                <span className='text-gray'>{user.address}</span>
              </div>
              <div className='borderTop mt-4' />
              <div className='d-flex flex-row justify-content-between mt-4'>
                <span className='text-title'>State</span>
                <span className='text-gray'>{user.state}</span>
              </div>
              <div className='borderTop mt-4' />
              <div className='d-flex flex-row justify-content-between mt-4'>
                <span className='text-title'>City</span>
                <span className='text-gray'>{user.city}</span>
              </div>
              <div className='borderTop mt-4' />
              <div className='d-flex flex-row justify-content-between mt-4'>
                <span className='text-title'>Zip Code</span>
                <span className='text-gray'>{user.zipCode}</span>
              </div>
              <div className='borderTop mt-4' />
              <div className='d-flex flex-row justify-content-between mt-4'>
                <span className='text-title'>Country</span>
                <span className='text-gray'>{user.country.name}</span>
              </div>
              <div className='borderTop mt-4' />
              <div className='d-flex flex-row justify-content-between mt-4'>
                <span className='text-title'>Time Zone</span>
                <span className='text-gray'>{user.timezone}</span>
              </div>
              <div className='borderTop mt-4' />
              {type === 'educator' && (
                <>
                  <EducatorCalendarLink user={user} refetch={update}/>
                  <div className='borderTop mt-4' />
                </>
              )}
            </Collapse>
            {
              type === 'student' && (
                <div className='d-flex flex-row mt-4'>
                  <span className='btn-link' onClick={() => { setCollapsePersonal(!collapsePersonal) }}>{collapsePersonal ? 'View Less' : 'View All'}</span>
                </div>
              )
            }

            {type === 'educator' && (
              <EducatorAcademicInfo user={user} refetch={update}/>
            )}
            {
              type === 'student' && (
                <>
                  <div className='d-flex flex-row justify-content-between mt-7'>
                    <div className='d-flex'>
                      <span className='font-weight-bold'>Academic Info</span>
                    </div>
                    <span className='btn-link' onClick={editAcademicInfo}>Edit</span>
                  </div>
                  <div className='borderTop mt-3' />
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>School</span>
                    <span className='text-gray'>{user?.studentInfo?.institution}</span>
                  </div>
                  {/* <div className='borderTop mt-4' />
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>GPA</span>
                    <span className='text-gray'>{user?.studentInfo?.gpa}</span>
                  </div> */}
                  <div className='borderTop mt-4' />
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>Graduation Date</span>
                    <span className='text-gray'>{user?.studentInfo?.graduationDate ? moment(String(user.studentInfo.graduationDate).split('T')[0]).format('MMMM YYYY') : moment().format('MMMM YYYY')}</span>
                  </div>
                  <div className='borderTop mt-4' />
                  <div className='d-flex flex-row justify-content-between mt-7'>
                    <div className='d-flex'>
                      <span className='font-weight-bold'>Family Info</span>
                    </div>
                    {value && (<span className='btn-link cursor' onClick={editFamilyInfo}>Edit</span>)}
                  </div>
                  <div className='borderTop mt-3' />
                  <div className='d-flex flex-row justify-content-between mt-4'>
                    <span className='text-title'>{translate(`profile.${user?.studentInfo?.studentType}`)}</span>
                    {user && user.studentInfo && !loading ? (
                      <Switch idComponent={`toggle-studentType-${user?.id}`} isOn={user?.studentInfo?.studentType === 'family'} onColor='#696CFF' handleToggle={() => changeType()} />
                    ): (<Activity />)}
                  </div>
                  <div className='borderTop mt-3' />
                  <div className='d-flex flex-column mt-4'>
                    {
                      value && user.studentInfo.parents && user.studentInfo.parents.map(
                        (parent, index) => {
                          return index < 2 ? (
                            <>
                              <label className='text-primary mt-3'> Parent {index + 1}</label>
                              <div className='d-flex flex-row justify-content-between mt-1'>
                                <span className='text-title'>Name</span>
                                <span className='text-gray'>{parent.firstName} {parent.lastName}</span>
                              </div>
                              <div className='borderTop mt-3' />
                              <div className='d-flex flex-row justify-content-between mt-3'>
                                <span className='text-title'>Email</span>
                                <span className='text-gray'>{parent.email}</span>
                              </div>
                              <div className='borderTop mt-3' />
                              <div className='d-flex flex-row justify-content-between mt-3'>
                                <span className='text-title'>Phone</span>
                                <span className='text-gray'>{parent.phone}</span>
                              </div>
                              <div className='borderTop mt-3' />
                            </>
                          ) : null
                        }
                      )
                    }
                  </div>
                  {
                    value && user.studentInfo.parents && user.studentInfo.parents.length > 2 && (
                      <>
                        <Collapse
                          isOpen={collapseFamily}
                        >
                          {
                            user.studentInfo.parents && user.studentInfo.parents.map(
                              (parent, index) => {
                                return index >= 2 ? (
                                  <>
                                    <label className='text-primary mt-3'> Parent {index + 1}</label>
                                    <div className='d-flex flex-row justify-content-between mt-1'>
                                      <span className='text-title'>Name</span>
                                      <span className='text-gray'>{parent.firstName} {parent.lastName}</span>
                                    </div>
                                    <div className='borderTop mt-3' />
                                    <div className='d-flex flex-row justify-content-between mt-3'>
                                      <span className='text-title'>Email</span>
                                      <span className='text-gray'>{parent.email}</span>
                                    </div>
                                    <div className='borderTop mt-3' />
                                    <div className='d-flex flex-row justify-content-between mt-3'>
                                      <span className='text-title'>Phone</span>
                                      <span className='text-gray'>{parent.phone}</span>
                                    </div>
                                    <div className='borderTop mt-3' />
                                  </>
                                ) : null
                              }
                            )
                          }
                        </Collapse>
                        <div className='d-flex flex-row mt-4'>
                          <span className='btn-link' onClick={() => { SetCollapseFamily(!collapseFamily) }}>{collapseFamily ? 'View Less' : 'View all'}</span>
                        </div>
                      </>
                    )
                  }
                </>
              )
            }
        </>
    )

}

export default ContentFull