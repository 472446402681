import {useCallback} from 'react'
import {gql, useApolloClient} from '@apollo/client'
export default () => {
  const client = useApolloClient()
  const getvclink = useCallback(
    async (id, userId) => {
      const data = await client.query({
        fetchPolicy: 'no-cache',
        variables: {
          id: id,
          userId
        },
        query: gql`
          query(
                $id: ID!,
                $userId: ID
              ) {
            calendarEvent(calendarEventId: $id) {
              ... on IndividualLesson {
                launchUrl(userId: $userId)
                location {
                  type
                  linkStudent
                  linkEducator
                }
              }
              ... on PlanLesson {
                launchUrl(userId: $userId)
                location {
                  type
                  linkStudent
                  linkEducator
                }
              }
              ... on GroupLesson {
                launchUrl(userId: $userId)
                location {
                  type
                  linkStudent
                  linkEducator
                }
              }
            }
          }
        `
      })
      return data.data.calendarEvent
    },
    [client],
  )
  return getvclink
}
