import React, {useState} from 'react'
import StudentsList from './Sections/List'
import Plot from './Sections/Plot'
import {TabContent, TabPane} from 'reactstrap'

const StudentWithoutLesson = () => {
    const [section, setSection] = useState('list')
    return (
        <TabContent activeTab={section}>
            <TabPane tabId={`plot`}><Plot onBack={() => {setSection('list')}}/></TabPane>
            <TabPane tabId={`list`}><StudentsList onViewPlot={() => {setSection('plot')}}/></TabPane>
        </TabContent>
    )
}

export default StudentWithoutLesson