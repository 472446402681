import React, { useState, useEffect } from 'react'
import {
  IconClose,
  Button,
  Select,
  Form,
  Field,
  PlainTextInput,
  LabeledField,
  InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import { FormGroup, Label } from 'reactstrap'
import { isEmpty, pick } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import useEditUserStudentInfo from '../../../../../Model/Users/Student/SetStudentInfoToUser'
import useTranslation from '../../../../../i18n/useTranslation'
import escapeRegExp from 'lodash/escapeRegExp'
import { getValues } from '../../../../CounselingPlans/Columns/util'

const TOTAL_YEARS = 50
const START_YEARS = 2010

const StudentModalForm = (props) => {
  const { refetchUser, toggleRightSide, actionAfterSubmit } = props
  const { isOpen, ...formData } = useSelector((state) => state.modal.user.studentInfo)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.studentInfo', data: { isOpen: !isOpen, ...formData } })
  }
  const toggleSave = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.studentInfo', data: { isOpen: !isOpen, ...formData } })
    if(actionAfterSubmit) actionAfterSubmit()
    refetchUser(form.id)
    toggle()
  }

const getClassOf = (graduationDate) => {
  const classof = getValues(moment(graduationDate).toISOString(), 'class')
  return parseInt(classof)
}
  const onChange = (setFormFunc) => {
    const newVal = setFormFunc(formData)
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.studentInfo', data: { isOpen: true, ...newVal } })
  }
  const { translate } = useTranslation()
  const { submit, loading, validationErrors } = useEditUserStudentInfo(toggleSave, console.log)
  const form = formData
  const onSubmit = () => {
    const updData = pick(form, ['institution', 'month', 'year'])
    // updData.gpa = parseFloat(form.gpa)
    updData.aditionalInfo = form.aditionalInfo ? form.aditionalInfo : ''
    updData.graduationDate = moment()
      .year(form.year)
      .month(form.month)
      .date('01')
      .toISOString()
    updData.classOf = getClassOf(updData.graduationDate)
    try {
      submit({ studentInfo: updData, userId: form.id })
    } catch (e) {
    }
  }
  useEffect(() => {
    var customError = {}
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach(key => {
        const keyVal = key.split('.')[1]
        customError[keyVal] = translate('profile.actions.editAcademicInfo.errors.' + keyVal)
      })
      setErrors(customError)
    }
    // eslint-disable-next-line
  }, [validationErrors])

  return (
    <div className='w-100 py-5 px-8 text-title'>
      <span className='d-flex w-100 flex-row justify-content-between'>
        <h2 className='font-weight-bold'>Edit Academic Info</h2>
        <IconClose size={20} onClick={toggleRightSide || toggle} color='rgba(119, 134, 153, .4)' />
      </span>
      <Form
        value={form}
        onChange={onChange}
        validationErrors={errors}
      >
        <FormGroup className='mt-7'>
          <Label>
            {translate('profile.actions.editStudentInfo.institution')}
          </Label>
          <Field
            type={LabeledField}
            component={PlainTextInput}
            fieldName='institution'
            placeholder='Institution'
          />
        </FormGroup>
        {/* <FormGroup className='mt-5'>
          <Label>{translate('profile.actions.editStudentInfo.gpa')}</Label>
          <Field
            type={LabeledField}
            component={PlainTextInput}
            fieldName='gpa'
            placeholder='0'
          />
        </FormGroup> */}
        <FormGroup className='mt-5'>
          <Label>
            {translate('profile.actions.editStudentInfo.gradDate')}
          </Label>
          <div className='row'>
            <div className='col-7 pr-0'>
              <Field
                type={InputSelect}
                component={Select}
                fieldName='month'
                className='h-100 pr-1'
                options={new Array(12).fill(0).map((_, idx) => ({
                  label: moment().month(idx).format('MMMM'),
                  value: idx
                }))}
                defaultSelected={[form.month]}
                listItem='small'
                itemDivider='middle'
                multiple={false}
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0
                }}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                  return false
                }}
              />
            </div>
            <div className='col-5 pl-0'>
              <Field
                type={InputSelect}
                component={Select}
                fieldName='year'
                className='h-100'
                options={new Array(TOTAL_YEARS)
                  .fill(0)
                  .map((_, idx) => ({ label: START_YEARS + idx, value: START_YEARS + idx }))}
                defaultSelected={[form.year]}
                listItem='small'
                itemDivider='middle'
                multiple={false}
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
                filterOption={(obj, string) => {
                  if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                  return false
                }}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup className='mt-5'>
          <Label>{translate('profile.actions.editStudentInfo.additionalInfo')}</Label>
          <textarea
            onChange={(e) => {
              dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.studentInfo', data: { isOpen: true, ...form, aditionalInfo: e.target.value } })
            }}
            value={form.aditionalInfo}
            placeholder='Type here'
            className='znk-input w-100 p-3 mt-2'
            style={{ height: '200px' }}
          />
        </FormGroup>
        <Button
          label={translate('misc.saveChanges')}
          color='primary'
          check
          size='medium'
          onClick={onSubmit}
          disabled={loading}
          activity={loading}
          style={{ width: '100%' }}
        />
      </Form>
    </div>
  )
}

StudentModalForm.propTypes = {}

export default StudentModalForm
