import React, { useEffect } from 'react'
import useModalState from '../../../../utils/useModalState'
import moment from 'moment-timezone'
import { gql, useMutation } from '@apollo/client'
import {
  Button,
  CheckboxOption,
  Field,
  Form,
  IconClose, InputSelect,
  LabeledField,
  Modal,
  PlainTextInput
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import { FormGroup, Label } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import isEmpty from 'lodash/isEmpty'
import {escapeRegExp} from 'lodash'

import startCase from 'lodash/startCase'
import { showAlert } from '../../../../common/Alert/util'
import { useDispatch } from 'react-redux'

const CreateActivityModal = props => {
  const { services, servicesLoading } = props
  const dispatch = useDispatch()
  const modalState = useModalState('activities.create')
  const { isOpen, toggle, modalData, onChange } = modalState
  const { translate } = useTranslation()
  const { kind, tbd, scoreReleaseDate, registrationDate, name, startDate, serviceId, description, refetch } = modalData
  const isValid = () => {
    return startDate && (kind === 'test' ? name && serviceId : name)
  }
  useEffect(() => {
    if(isOpen) {
      let newName = ''
      const serviceId = modalData.serviceId
      const startDate = modalData.startDate
      if (serviceId && services && !isEmpty(services) && startDate) {
        const thisService = services.docs.find(item => item.id === serviceId)
        if (thisService) {
          newName = `${thisService.name} ${moment(startDate).format('MMM Do YYYY')}`
        } else {
          newName = `${moment(startDate).format('MMM Do YYYY')}`
        }
        onChange(form => {
          return { ...form, name: newName }
        })
      }
    }
    // eslint-disable-next-line
  },[modalData.serviceId, modalData.startDate, services, isOpen])
  const [mutationTest, { loading: loadingTest }] = useMutation(gql`mutation ($input: TestInput!){
      createTest(newTestInput: $input){
          name
          tbd
          id
          kind
          startDate
          endDate
          serviceId
          registrationDate
          scoreReleaseDate
      }
  }`)
  const [mutationEvent, { loading: loadingEvent }] = useMutation(gql`mutation ($input: EventInput!){
      createEvent(newEvent: $input){
          name
          tbd
          id
          kind
          startDate
          description
      }
  }`)
  const submit = () => {
    if (kind === 'event') {
      mutationEvent({
        variables: {
          input: {
            startDate: startDate,
            endDate: startDate,
            name,
            tbd: !!tbd,
            description
          }
        }
      }).then((res) => {
        showAlert({
          text: 'Activity has been created successfully!',
          status: 'success'
        }, dispatch)
        toggle()
        refetch(res?.data?.createEvent)
      }).catch((error)=> {
        showAlert({
          text: error.toString(),
          status: 'error'
        }, dispatch)
      })
    } else {
      mutationTest({
        variables: {
          input: {
            startDate: startDate,
            endDate: startDate,
            name,
            tbd: !!tbd,
            serviceId,
            registrationDate,
            scoreReleaseDate
          }
        }
      }).then((res) => {
        showAlert({
          text: 'Activity has been created successfully!',
          status: 'success'
        }, dispatch)
        toggle()
        refetch(res?.data?.createTest)
      }).catch((error)=> {
        showAlert({
          text: error.toString(),
          status: 'error'
        }, dispatch)
      })
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      className='modal-centered'
      toggle={toggle}
      Head={
        <div className='modal-header d-flex'>
          <h3 className='text-title font-weight-bold'>{translate(`constants.activities.create.title${startCase(kind)}`)}</h3>
          <IconClose onClick={toggle} />
        </div>
      }
    >
      <Form value={modalData} onChange={onChange}>
        {
          kind === 'test' ? (
            servicesLoading ? (
              <Skeleton height={46} />
            ) : (
              <FormGroup className='col-12'>
                <Label>{translate('constants.activities.create.service')}</Label>
                <Field
                  className='w-100'//'col-12'
                  type={InputSelect}
                  fieldName='serviceId'
                  placeholder={translate('modal.newplan.selectservice')}
                  options={services && services.docs && [{ value: '0', label: translate('modal.newplan.selectservice') }].concat(services.docs.map((serv) => ({ value: serv.id, label: serv.name })))}
                  InputClassName='w-100'
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                    return false
                  }}
                />
              </FormGroup>
            )
          ) : null
        }
        <FormGroup className='col-12'>
          <Label className='text-truncate w-100'>{translate(`constants.activities.${kind || 'test'}Date`)}</Label>
          <div className='row'>
            <div className='col-8'>
              <Field
                fieldName='startDate'
                type={PlainTextInput}
                fieldType='date'
              />
            </div>
            <div className='col-4 d-flex align-items-center'>
              <Field
                fieldName='tbd'
                type={CheckboxOption}
                defaultState={!!tbd}
              />
              <span 
                className='ml-1 text-truncate' 
                title={translate('constants.activities.tbd')}>{translate('constants.activities.tbd')}</span>
            </div>
          </div>
        </FormGroup>
        <Field
          type={LabeledField}
          fieldName='name'
          component={PlainTextInput}
          label={translate('constants.activities.name')}
          labelContainerClassName='col-12'
          labelClassName='text-truncate w-100'
        />
        {
          kind === 'event' ? (
            <>
              <Field
                type={LabeledField}
                fieldName='description'
                component={PlainTextInput}
                label={translate('constants.activities.description')}
                labelContainerClassName='col-12'
                labelClassName='text-truncate w-100'
              />
            </>
          ) : kind === 'test' ? (
            <>
              <Field
                type={LabeledField}
                fieldName='registrationDate'
                component={PlainTextInput}
                fieldType='date'
                label={translate('constants.activities.registrationDate')}
                labelContainerClassName='col-12'
                labelClassName='text-truncate w-100'
              />
              <Field
                type={LabeledField}
                fieldName='scoreReleaseDate'
                component={PlainTextInput}
                fieldType='date'
                label={translate('constants.activities.scoreReleaseDate')}
                labelContainerClassName='col-12'
                labelClassName='text-truncate w-100'
              />
            </>
          ) : null
        }
        <div className='col-12'>
          <Button
            disabled={!isValid()}
            color='primary'
            check
            activity={loadingTest || loadingEvent}
            className='w-100'
            onClick={submit}
            label={translate('misc.create')}
          />
        </div>
      </Form>
    </Modal>
  )
}

export default CreateActivityModal
