import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLazyQuery, gql} from '@apollo/client'
import {useLocation} from 'react-router-dom'
import queryLessonResult from '../../Model/QueryResult/useLessonResult'

const OpenLessonModalFromUrl = () => {
    const location = useLocation()
    //SERVICIO ORIENTACIÓN
    const services = useSelector((state) => state.user.services)
    const orientation = services?.find((serv) => serv.isOrientation)
    
    const searchParams = new URLSearchParams(location.search)
    const lessonId = searchParams.get('lessonId')
    const dispatch = useDispatch()
    const [getLessonData] = useLazyQuery(
        gql`
            query ($lessonId: String) {
                getLesson (lessonId: $lessonId) {
                    ${queryLessonResult}
                }
            }
        `, 
        {
            onCompleted: ({getLesson}) => {
                const isOrientation = getLesson?.service?.id === orientation?.id
                if(getLesson){
                    dispatch({
                        type: 'SET_DATA_MODAL_FORM_LIST',
                        payload: `plan.${isOrientation ? 'orientationLessonModal' : 'infoLessonModal'}`,
                        data: {
                          isOpen: true,
                          dataLesson: getLesson
                        }
                    })
                }
            }
        }
    )
    useEffect(() => {
        if(lessonId?.length > 0) {
            getLessonData({
                variables: {lessonId: lessonId.toString()}
            })
        }
    }, [lessonId, getLessonData])
    return (
        <></>
    )
}

export default OpenLessonModalFromUrl