import React, { useState, useEffect } from "react";
import {
  Button,
  Select as Rselect,
  Form,
  Field,
  PlainTextInput,
  LabeledField,
  SelectOption,
  IconCountry,
  InputSelect,
  PhoneSelector,
  ConfirmModal,
} from "@Knowledge-OTP/znk-ui-components";
import { FormGroup, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { SET_DATA_MODAL_FORM_LIST } from "../../../../store/modal/const";
import useTranslation from "../../../../i18n/useTranslation";
import * as moment from "moment";
import { pick, isEmpty } from "lodash";
import useEditUser from "../../../../Model/Users/Edit";
import Select, { components } from "react-select";
import { parseCountrySelector } from "../../../Parent/Utils/parseCountriesArray";
import usStatesList from "../../Utils/us_states.json";
import { showAlert } from "../../../../common/Alert/util";
const EditProfileContent = (props) => {
  const {
    // toggleRightSide,
    countries: itemsCountry,
    refetchList = () => {},
    refetchUser,
    actionAfterUpdate,
  } = props;
  const { isOpen, ...formData } = useSelector((state) => state.modal.user.edit);
  const { isOpen: isOpenShort, ...formDataShort } = useSelector(
    (state) => state.modal.user.editShort
  );
  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const [errors, setError] = useState({});
  const [selectCode, setSelectCode] = useState("");
  const [enableEditEmail, setEnableEditEmail] = useState(false);
  const [isOpenConfirmModalEmail, setOpenConfirmModalEmail] = useState(false);
  const countries = parseCountrySelector(itemsCountry?.docs);
  const toggle = () => {
    setOpenConfirmModalEmail(!isOpenConfirmModalEmail)
    setEnableEditEmail(false)
  }
  //const usStates = parseCountrySelector(usStatesList);
  const usStates = usStatesList?.map((e) => {
    return { label: e.name, value: e.name };
  });

  const items = moment.tz.names().map((e) => {
    return { value: e, label: e };
  });
  const closeOverSave = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.edit",
      data: { isOpen: false },
    });
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.editShort",
      data: { isOpen: false },
    });
    refetchUser(formData.id);
    refetchList();
  };
  const onChange = (setFormFunc) => {
    const newVal = setFormFunc(form);
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.edit",
      data: { isOpen: true, ...newVal },
    });
  };

  const { submit, loading, validationErrors } = useEditUser(
    (result) => {
      if (actionAfterUpdate) {
        actionAfterUpdate();
      } else {
        closeOverSave();
      }
      showAlert({
        text: translate(`profile.actions.editPersonalInfo.updatedSuccesfully`),
        status: "success"
      }, dispatch);
      console.log('Update successful', result)
      setEnableEditEmail(false)
    },
    (e) => {
      showAlert({
        text: translate(`profile.actions.editPersonalInfo.${e}`) || translate("profile.actions.editPersonalInfo.error"),
        status: "error"
      }, dispatch)
      console.error('Update failed:', e);
      setEnableEditEmail(false)
    }
  );
  const onSubmit = () => {
    const updData = pick(form, [
      "firstName",
      "lastName",
      "address",
      "city",
      "state",
      "zipCode",
      "spokenLanguages",
      "gender",
      "isActive",
      "phoneDialCode",
      "phoneNumber",
      "birthDay",
      "birthMonth",
      "birthYear",
      "email"
    ]);
    updData.phone = `${form.phoneDialCode.trimEnd()} ${form.phoneNumber}`;
    updData.birthYear = updData.birthYear === 0 ? null : updData.birthYear;
    updData.birthdate = moment()
      .year(form.birthYear)
      .month(form.birthMonth)
      .date(form.birthDay)
      .toISOString();
    updData.countryId = form.country.value;
    updData.state = form.state;
    updData.timezone = form.timezone.value;
    submit({ updatedUser: updData, userId: form.id });
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <IconCountry />
        </components.DropdownIndicator>
      )
    );
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#F0F4F8",
      // match with the menu
      borderRadius: 12,
      height: 44,
      // Overwrittes the different states of border
      borderColor: "transparent",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "transparent",
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const form = isOpen ? formData : isOpenShort ? formDataShort : {};
  const initialUsState = usStates.find((e) => e.value === form.state);
  const [stateSelected, setStateSelected] = useState(
    initialUsState ? initialUsState : null
  );

  const handleCountryChange = (value) => {
    const stateUs = usStates.find((e) => e.value === form.state);
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: "user.edit",
      data: { isOpen: true, ...formData, state: stateUs ? stateUs.value : "" },
    });
    setStateSelected(stateUs ? stateUs : null);
  };

  useEffect(() => {
    var customError = {};
    if (!isEmpty(validationErrors)) {
      Object.keys(validationErrors).forEach((key) => {
        const keyVal = key.split(".")[1];
        customError[keyVal] = translate(
          "profile.actions.editPersonalInfo.errors." + keyVal
        );
      });
      setError(customError);
    }
    // eslint-disable-next-line
  }, [validationErrors]);

  useEffect(() => {
    if (selectCode) {
      form.phoneDialCode = selectCode;
    }
    //eslint-disable-next-line
  }, [selectCode]);

  return (
    <Form value={form} onChange={onChange} validationErrors={errors}>
      {/* <FormGroup className='d-flex align-items-center'>
            <Field
                type={CheckboxOption}
                fieldName='isActive'
                defaultState={form.currentIsActive}
            />
            <span>{form.isActive ? 'Active' : 'Deactivated'}</span>
            </FormGroup> */}
      <FormGroup className="mt-7 mb-0">
        <Label>
          {formData?.roles?.includes("STUDENT")
            ? translate("profile.studentName")
            : translate("profile.educatorName")}
        </Label>
        <div className="row">
          <div className="col-6 pr-0">
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName="firstName"
              placeholder={translate(
                "profile.actions.editPersonalInfo.firstName"
              )}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              labelContainerClassName="mb-0"
            />
          </div>
          <div className="col-6 pl-1">
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName="lastName"
              placeholder={translate(
                "profile.actions.editPersonalInfo.lastName"
              )}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              labelContainerClassName="mb-0"
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup className="mt-5">
        <Label>{translate("profile.actions.editPersonalInfo.email")}</Label>
        {enableEditEmail ? <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName="email"
        /> :
          <div className="row"><Field
            type={LabeledField}
            component={PlainTextInput}
            fieldName="email"
            disabled
            labelContainerClassName='w-90 ml-3'
          /> <div className='align-self-center mb-5 mr-2 ml-2'
            onClick={() => setOpenConfirmModalEmail(true)}
          > <span className='btn-link'>Edit</span>
            </div>
          </div>}
      </FormGroup>
      <FormGroup className="mt-5">
        <Label>{translate("profile.actions.editPersonalInfo.gender")}</Label>
        <Field
          className="col-12"
          type={LabeledField}
          component={SelectOption}
          fieldName="gender"
          showNotSelected={false}
          options={[
            {
              id: "male",
              text: translate("profile.actions.editPersonalInfo.male"),
            },
            {
              id: "female",
              text: translate("profile.actions.editPersonalInfo.female"),
            },
            {
              id: "other",
              text: translate("profile.actions.editPersonalInfo.other"),
            },
          ]}
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate("profile.actions.editPersonalInfo.birthday")}</Label>
        <div className="row">
          <div className="col-3 pr-1">
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName="birthDay"
              defaultSelected={form.birthDay}
              options={new Array(31)
                .fill(0)
                .map((_, idx) => ({ label: idx + 1, value: idx + 1 }))}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              isClearable={false}
            />
          </div>
          <div className="col-6 pl-0 pr-1">
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName="birthMonth"
              defaultSelected={form.birthMonth}
              options={new Array(12).fill(0).map((_, idx) => ({
                label: moment()
                  .month(idx)
                  .format("MMMM"),
                value: idx,
              }))}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              isClearable={false}
            />
          </div>
          <div className="col-3 pl-0">
            <Field
              type={InputSelect}
              component={Rselect}
              fieldName="birthYear"
              defaultSelected={form.birthYear}
              options={new Array(80)
                .fill(0)
                .map((_, idx) => ({ label: 2020 - idx, value: 2020 - idx }))}
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              isClearable={false}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>{translate("profile.actions.editPersonalInfo.phone")}</Label>
        <div className="row">
          <div className="col-6 pr-1">
            <PhoneSelector
              fieldName="phoneDialCode"
              defaultValue={form.phoneDialCode}
              selectCode={selectCode}
              setSelectCode={setSelectCode}
            />
          </div>
          <div className="col-6 pl-0">
            <Field
              type={LabeledField}
              component={PlainTextInput}
              fieldName="phoneNumber"
              placeholder="12345621"
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup className="mt-5">
        <Label>
          {translate("profile.actions.editPersonalInfo.spokenLanguages")}
        </Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName="spokenLanguages"
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate("profile.actions.editPersonalInfo.country")}</Label>
        <Select
          styles={customStyles}
          options={countries}
          value={form.country}
          onChange={(value) => {
            handleCountryChange(value);
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: "user.edit",
              data: { isOpen: true, ...formData, country: value },
            });
          }}
        />
      </FormGroup>
      {form?.country?.label === "United States" ? (
        <FormGroup>
          <Label>{translate("profile.actions.editPersonalInfo.state")}</Label>
          <Select
            styles={customStyles}
            options={usStates}
            value={stateSelected}
            onChange={(value) => {
              setStateSelected(value);
              dispatch({
                type: SET_DATA_MODAL_FORM_LIST,
                payload: "user.edit",
                data: { isOpen: true, ...formData, state: value.value },
              });
            }}
          />
        </FormGroup>
      ) : (
        <FormGroup>
          <Label>{translate("profile.actions.editPersonalInfo.state")}</Label>
          <Field
            type={LabeledField}
            component={PlainTextInput}
            fieldName="state"
          />
        </FormGroup>
      )}

      <FormGroup className="mt-5">
        <Label>{translate("profile.actions.editPersonalInfo.city")}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName="city"
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate("profile.actions.editPersonalInfo.zipCode")}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName="zipCode"
        />
      </FormGroup>
      <FormGroup className="mt-5">
        <Label>{translate("profile.actions.editPersonalInfo.address")}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName="address"
        />
      </FormGroup>
      <FormGroup>
        <Label>{translate("profile.actions.editPersonalInfo.timeZone")}</Label>
        <Select
          components={{ DropdownIndicator }}
          styles={customStyles}
          options={items}
          value={form.timezone}
          onChange={(value) => {
            dispatch({
              type: SET_DATA_MODAL_FORM_LIST,
              payload: "user.edit",
              data: { isOpen: true, ...formData, timezone: value },
            });
          }}
        />
      </FormGroup>
      <Button
        label="Save changes"
        color="primary"
        check
        size="medium"
        onClick={onSubmit}
        disabled={loading}
        activity={loading}
        style={{ width: "100%" }}
        className="mt-5"
      />
      <ConfirmModal
        className=''
        //loading={loading || loadingOrientation}
        classNameActionDiv='flex-column'
        classNameConfirm='text-right text-error'
        classNameCancel='mx-3 text-right'
        isOpen={isOpenConfirmModalEmail}
        toggle={toggle}
        title={translate('profile.actions.editPersonalInfo.emailChange.title')}
        body={translate('profile.actions.editPersonalInfo.emailChange.body')}
        confirmAction={() => {
          setEnableEditEmail(true)
          setOpenConfirmModalEmail(false)
        }}
        cancelAction={toggle}
        confirmText={translate('profile.actions.editPersonalInfo.emailChange.confirm')}
        cancelText={translate('profile.actions.editPersonalInfo.emailChange.cancel')}
      />
    </Form>
  );
};

export default EditProfileContent;
