import {useQuery, gql} from '@apollo/client'
const useLessonsQuery = (vars = {}, onSuccess = () => {}) => {
    return useQuery(
        gql`
        query (
            $studentIds: [String!]
            $sortField: String
            $previous: String
            $next: String
            $limit: Int
            $serviceIds: [ID!]
            $endDate: DateTime
            $startDate: DateTime
            $planId: String
            $status: [LessonStatus!]!
            $filterStatus: [String!]
            $educatorIds: [String!]
            $search: String
        ) {
            lessons (
                studentIds: $studentIds
                sortField: $sortField
                previous: $previous
                next: $next
                limit: $limit
                serviceIds: $serviceIds
                endDate: $endDate
                startDate: $startDate
                planId: $planId
                status: $status
                filterStatus: $filterStatus
                educatorIds: $educatorIds
                search: $search
            ) {
                hasNext
                hasPrevious
                next
                previous
                totalDocs
                docs {
                    ... on IndividualLesson {
                        id
                        status
                        title
                        kind
                        educator {
                          educatorUserId
                          firstName
                          lastName
                          attendanceStatus
                        }
                        substitute {
                          educatorUserId
                          firstName
                          lastName
                          attendanceStatus
                        }
                        student {
                          attendanceStatus
                          studentUserId
                          firstName
                          lastName
                        }
                        startDate
                        endDate
                      }
                    ... on PlanLesson {
                        id
                        status
                        kind
                        title
                        startDate
                        endDate
                        educator {
                          educatorUserId
                          firstName
                          lastName
                          attendanceStatus
                        }
                        substitute {
                          educatorUserId
                          firstName
                          lastName
                          attendanceStatus
                        }
                        studentPlan : student {
                          attendanceStatus
                          studentUserId
                          firstName
                          lastName
                        }
                
                    }
                    ... on GroupLesson {
                        id
                        title
                        status
                        kind
                        startDate
                        endDate
                        educator {
                          educatorUserId
                          firstName
                          lastName
                          attendanceStatus
                        }
                        substitute {
                          educatorUserId
                          firstName
                          lastName
                          attendanceStatus
                        }
                        studentGroup : students {
                          attendanceStatus
                          studentUserId
                          firstName
                          lastName
                        }
                
                        
                    }
                    
                }
            }
        }
        `,
        {
            variables: vars,
            fetchPolicy: 'cache-first',
            onCompleted: onSuccess
        }
    )
}

export default useLessonsQuery