import educatorNotesResult from "../../../../Model/QueryResult/useEducatorNotes"

const queryLessonResult = `
    ... on IndividualLesson {
        id
        backupLink
        status
        title
        busy
        kind
        isMakeUp
        planId
        recurringId
        recordingUrl
        lessonConfig {
          isRepeat
          isRepeatByWeek
          isRepeatMonthly
          endDateRepeat
          paymentFor
        }
        educator {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        substitute {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        individualTopic : topic {
          id
          name
        }
        service {
          id
          name
        }
        student {
          attendanceStatus
          studentUserId
          firstName
          lastName
          rate
          isManualRate
          rating
          ignorePaymentItem
        }
        startDate
        endDate
        IndividualCreated : createdAt
        IndividualUpdate : updatedAt
        commentForEducator
        isLocked
        isMeeting
        isMakeUp
        location {
          type
          linkStudent
          linkEducator
        }
        makeUpInfo {
          makeUpId
        }
        notes {
          status
          educatorNotes {
            ${educatorNotesResult}
          }
          sentTime
          lostReason
        }
        penalizationInfo {
          amount
          specificIssueId
        }

        

      }
    ... on PlanLesson {
        id
        backupLink
        recordingUrl
        busy
        status
        kind
        title
        startDate
        endDate
        isMakeUp
        recurringId
        lessonConfig {
          isRepeat
          isRepeatByWeek
          isRepeatMonthly
          endDateRepeat
          paymentFor
        }
        educator {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        substitute {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        planTopic : topic {
          id
          name
        }
        service {
          id
          name
        }
        studentPlan : student {
          attendanceStatus
          studentUserId
          firstName
          lastName
          rate
          isManualRate
          rating
          ignorePaymentItem
        }
        PlanCreated : createdAt
        PlanUpdate : updatedAt
        commentForEducator
        isLocked
        isMakeUp
        location {
          type
          linkStudent
          linkEducator
        }
        makeUpInfo {
          makeUpId
        }
        notes {
          status
          educatorNotes {
            ${educatorNotesResult}
          }
          sentTime
          lostReason
        }
        penalizationInfo {
          amount
          specificIssueId
        }

    }
    ... on GroupLesson {
        id
        backupLink
        title
        status
        recordingUrl
        kind
        busy
        startDate
        endDate
        isMakeUp
        recurringId
        lessonConfig {
          isRepeat
          isRepeatByWeek
          isRepeatMonthly
          endDateRepeat
          paymentFor
        }
        educator {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        substitute {
          educatorUserId
          firstName
          lastName
          rate
          ignoreAvailabilty
          ignorePaymentItem
          isManualRate
          attendanceStatus
        }
        groupTopic : topic {
          id
          name
        }
        service {
          id
          name
        }
        studentGroup : students {
          attendanceStatus
          studentUserId
          firstName
          lastName
          rate
          isManualRate
          rating
          ignorePaymentItem
        }
        GroupCreated : createdAt
        GroupUpdate : updatedAt
        commentForEducator
        isLocked
        isMakeUp
        location {
          type
          linkStudent
          linkEducator
        }
        makeUpInfo {
          makeUpId
        }
        notes {
          status
          educatorNotes {
            ${educatorNotesResult}
          }
          sentTime
          lostReason
        }
        penalizationInfo {
          amount
          specificIssueId
        }

        
    }
    `

    export default queryLessonResult