import React, {useMemo} from 'react'
import { IconArrowDown, CheckboxSelector, CheckboxSelectorInner } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'
import useServices from '../../../../Model/Services/List'

const ServicesFilter = (props) => {
  const { serviceFilters, setServiceFilters, useInner = false } = props
  const { translate } = useTranslation()
  const { data: services, loading: servicesLoading } = useServices({ limit: 0 })
  const serviceData = useMemo(
    () => (services.docs || []).map(item => ({ name: item.name, id: item.id })).sort((a, b) => a.name.localeCompare(b.name)) 
  , [services])
  return (
    !servicesLoading && (
      useInner ? 
      <CheckboxSelectorInner
        style={{maxWidth: 'auto'}}
        setDataFilters={setServiceFilters}
        direction='bottom'
        dataFilters={serviceFilters}
        data={serviceData}
        typeFilter='services'
        strings={{
          viewAll: translate('filters.plans.viewAll'),
          buttonCancel: translate('filters.plans.buttonCancel'),
          buttonApply: translate('filters.plans.buttonApply'),
          search: translate('filters.plans.search')
        }}
      /> :
      <CheckboxSelector
        setDataFilters={setServiceFilters}
        direction='bottom'
        dataFilters={serviceFilters}
        data={serviceData}
        typeFilter='services'
        strings={{
          viewAll: translate('filters.plans.viewAll'),
          buttonCancel: translate('filters.plans.buttonCancel'),
          buttonApply: translate('filters.plans.buttonApply'),
          search: translate('filters.plans.search')
        }}
      >
        <Badge className='mr-1 filters' pill color='option'>
          <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
            {translate('filters.plans.services.title')}
            <IconArrowDown className='ml-2' size={15} />
          </label>
        </Badge>
      </CheckboxSelector>
    )
  )
}
export default ServicesFilter
