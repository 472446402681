import React, {useMemo, useState} from 'react'
import {
    CheckboxSelector, 
    IconArrowDown
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import {Badge} from 'reactstrap'
import useAllTimelinesActivities from '../../../Model/useAllTimelinesActivities'

const ActivitiesFilter = (props) => {
    const {
        setFilters = () => {},
        filters = [],
    } = props
    const {translate} = useTranslation()
    // const capitalize = (text) => {
    //     const result = text.replace(/([A-Z])/g, " $1");
    //     return result.charAt(0).toUpperCase() + result.slice(1);
    // }
    const {data} = useAllTimelinesActivities({
        servicesIds: []
    })
    const {activities, services} = useMemo(
        () => {
            const activities = data?.allTimelinesActivities || []
            const services = activities.reduce(
                (array, {service}) => {
                    if(service && !array.map(({id}) => id).includes(service.id)) array.push(service)
                    return array
                }, []
            )
            return {
                activities, services
            }
        }, [data]
    )

    const [selectedService, setSelectedService] = useState(null)
    const filterActivities = useMemo(() => {
        if(!selectedService) return activities
        return activities.filter(
            ({serviceId}) => serviceId === selectedService
        )
    }, [activities, selectedService])

    return (
        <CheckboxSelector
            setDataFilters={setFilters}
            direction='bottomCenter'
            dataFilters={filters}
            typeFilter='statuses'
            data={
                (filterActivities || []).map((item) => {
                return { name: item.name, id: item.id}
                }).sort((a, b) => {
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })
            }
            strings={{
                viewAll: translate('filters.plans.viewAll'),
                buttonCancel: translate('filters.plans.buttonCancel'),
                buttonApply: translate('filters.plans.buttonApply'),
                search: translate('filters.plans.search'),
                noData: 'No found data'
            }}
            useSelector
            selectorOptions={services.map(({name, id}) => {
                return {label: name, value: id}
            })}
            selectorValue={selectedService}
            onChangeSelector={(res) => {
                setSelectedService(res)
            }}
            >
            <Badge className='mx-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                    Activity
                    <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default ActivitiesFilter