import {gql, useMutation} from '@apollo/client'

const useDeleteGroupPlan = (
    onSuccess = () => {},
    onError = () => {}
) => {
    return useMutation(
        gql`
            mutation (
                $planId: ID!
            ) {
                deletePlan (
                    planId: $planId
                ) {
                    id
                }
            }
        `,
        {
            onCompleted: onSuccess,
            onError
        }
    )
}

export default useDeleteGroupPlan