import React, { useEffect } from 'react'
import { Stats } from '@Knowledge-OTP/znk-ui-components'
import { gql, useLazyQuery } from '@apollo/client'
import educatorNotesResult from '../../../Model/QueryResult/useEducatorNotes'
export const ProgressGroupPlanHover = (props) => {
  const { item, isHover } = props
  const [getLessons, { loading, data }] = useLazyQuery(gql`query (
    $planId: String
    $sortField: String
    $previous: String
    $next: String
    $limit: Int
    $status: [LessonStatus!]!
    ) {
      lessons(
        planId: $planId
        sortField: $sortField
        previous: $previous
        next: $next
        limit: $limit
        status: $status
      ){
      hasNext
      hasPrevious
      totalDocs
      docs {
        ... on GroupLesson {
          id
          status
          title
          description
          educator {
            educatorUserId
            firstName
            lastName
            rate
            isManualRate
            ignoreAvailabilty
            ignorePaymentItem
          }
          service {
            id
            name
          }
          topic {
            id
            name
          }
          commentForEducator
          recordingUrl
          braincertId
          backupLink
          recurringId
          kind
          startDate
          endDate
          busy
          userIdsInvolved
          planId
          students {
            studentUserId
            planId
            firstName
            lastName
            rate
            attendanceStatus
            isManualRate
            ignoreAvailabilty
            whatsappAlertSent
            wasLate
            rating
          }
          notes {
            status
            educatorNotes {
              ${educatorNotesResult}
            }
            sentTime
          }
        }
      }
    }
  }`)
  useEffect(() => {
    if (isHover) {
      getLessons({
        variables: {
          planId: item.id,
          status: [
            'finished',
            'pending',
            'scheduled'
          ],
          limit: 0
        }
      })
    }
  }, [isHover, getLessons, item.id])
  return (
    <Stats className='znk-stats-font-small' loading={loading} items={data ? data.lessons.docs : []} />
  )
}
export default ProgressGroupPlanHover
