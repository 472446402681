import React, { useMemo, useState } from 'react'
import {
    Task, IconSignPlus, TaskReady, IconLoad
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import moment from 'moment-timezone'
import taskTypes from '../../../../../University/Utils/taskTypes'
import useShowLoginInfoModal from '../../modals/LoginInfoModal/useShowLoginInfoModal'
import ShowLoginInfoModal from '../../modals/LoginInfoModal'
import ScoresModal from '../../modals/ScoresModal'
import OrientationLessonModal from '../../../../../Calendar/modal/OrientationModal/OrientationLessonModal'
import { useGetLesson } from '../../../../../../Model/Lessons/GetLesson/useGetLesson'
import useShowScheduleMeeting from '../../modals/ShowScheduledMeeting/useShowScheduledMeeting'
import useShowUploadFileModal from '../../modals/UploadFile/useShowUploadFileModal'
import UploadFileButton from '../UploadFileButton'
import SendToReviewButton from '../sendToReview'
import useHandlerData from '../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/useHandlerExamForm'
import { FORMODEL } from '../../../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/useHandlerExamForm'

const ShowTaskStatus = (props) => {
    const { task, setData } = props
    const { open: openLoginInfoModal } = useShowLoginInfoModal()
    const { open: openShowScoresModal } = useHandlerData(FORMODEL.task)
    const { open: openScheduleMeeting } = useShowScheduleMeeting()
    const { open: openUploadFile } = useShowUploadFileModal()
    let infoByStatus = {}
    const [dataLesson, setDataLesson] = useState({})
    const [isOpenLessonModal, setIsOpenLessonModal] = useState(false)
    const [filesUploadedByUser, setFilesUploadedByUser] = useState([])
    const [uploadStatusOk, setUploadStatus] = useState('')

    const [loadSubmitScoreModal, setSubmitScoreLoading] = useState(false)
    
    const toggle = () => {
        setIsOpenLessonModal(!isOpenLessonModal)
    }
    // eslint-disable-next-line
    const [getLesson, { data: dataGetLesson, loading: loadingGetLesson }] = useGetLesson(
        (resp) => {
            if (resp?.getLesson) {
                setDataLesson(resp?.getLesson)
            }
        },
        (err) => {
            console.error('Error on getting lesson : ', err)
        })

    const { showTaskInfo, buttonLabel, filesOnTask } = useMemo(() => {
        infoByStatus.filesOnTask = []
        switch (true) {
            case task.type === taskTypes.link:
                infoByStatus.showTaskInfo = () => { window.open(task?.linkToShow) }
                if (task?.status === 'pending') {
                    infoByStatus.buttonLabel = task?.buttonLabel
                } else {
                    infoByStatus.buttonLabel = `Submitted on: ${task?.reviewDate}`
                }
                break
            case task.type === taskTypes.loginInfo:
                infoByStatus.showTaskInfo = () => { openLoginInfoModal() }
                infoByStatus.buttonLabel = task?.resolveTask?.loginInfo?.platform?.name
                break
            case task.type === taskTypes.completion || task.type === taskTypes.link:
                infoByStatus.showTaskInfo = () => { }
                infoByStatus.buttonLabel = `Submitted on: ${moment(task?.reviewDate).format(
                    'MMM Do, YY h:mm a')}`
                break
            case task.type === taskTypes.uploadFile:
                if (task?.status === 'pending') {
                    infoByStatus.showTaskInfo = () => { openUploadFile(()=>{}, task) }
                } else {
                    infoByStatus.filesOnTask = task?.resolveTask?.uploadFiles
                    infoByStatus.showTaskInfo = () => { }
                }
                break
            case task.type === taskTypes.scoreCollection:
                if (task?.status === 'unassigned') {
                    infoByStatus.showTaskInfo = () => {}
                } else {
                infoByStatus.showTaskInfo = () => { 
                    setSubmitScoreLoading(true) 
                    openShowScoresModal(()=>{}, task?.id).then(
                        (res) => {
                        }
                    ).catch(() => {}).finally(() => {setSubmitScoreLoading(false)})
                }
                infoByStatus.buttonLabel = `Scores completed`
                if (task?.status !== 'pending' && task?.resolveTask?.uploadFiles?.length > 0) {
                    infoByStatus.filesOnTask = task?.resolveTask?.uploadFiles
                }
                }
                break
            case task.type === taskTypes.scheduledMeetting && task?.status === 'unassigned':
                infoByStatus.showTaskInfo = () => {}
                break
            case task.type === taskTypes.scheduledMeetting:
                if (task?.status === 'pending') {
                    infoByStatus.showTaskInfo = () => { openScheduleMeeting(()=>{}, task) }
                } else {
                    getLesson({
                        variables: {
                            lessonId: task?.resolveTask?.meeting,
                        }
                    })
                    infoByStatus.showTaskInfo = () => { setIsOpenLessonModal(true) }
                    infoByStatus.buttonLabel = `${moment(dataLesson?.startDate).format('dddd MMMM Do, h:mma')} - ${moment(dataLesson?.endDate).format('h:mma')}`
                }
                break
            default:
                infoByStatus.showTaskInfo = () => { alert('default') }
        }
        return infoByStatus
        // eslint-disable-next-line
    }, [task, dataLesson])

    return (
        <TabContent activeTab={task?.status === 'review' ? 'review' : task?.status === 'completed' ? 'completed' : 'inprogress'}>
            <TabPane className={'mb-6'} tabId={'review'}>
                {task.type === taskTypes.uploadFile ?
                    filesOnTask?.map(file => {
                        return <TaskReady className={'mb-1'} label={file.url.split("/").at(-1)} onClick={() => { window.open(file.url) }} />
                    })
                    :
                    <TaskReady label={buttonLabel} onClick={() => { showTaskInfo() }} />
                }
                {(task.type === taskTypes.scoreCollection && filesOnTask?.length > 0 ) &&
                    filesOnTask?.map(file => {
                        return <TaskReady className={'mb-1 mt-2'} label={file.url.split("/").at(-1)} onClick={() => { window.open(file.url) }} />
                    })
                }
            </TabPane>
            <TabPane tabId={'inprogress'}>
                <Task
                    noIcon
                    onClick={() => { showTaskInfo() }}
                    taskClassName={`my-6`}
                    className={'d-flex flex-row justify-content-end'}//rightSection className
                    labelLeft={<span className={`text-gray w-100`}>{task?.buttonLabel}</span>}
                    labelRight={
                        loadSubmitScoreModal ?
                        <IconLoad size={12}  style={{ verticalAlign: 'inherit' }}/> : 
                        <IconSignPlus size={12} style={{ verticalAlign: 'inherit' }} className={`rotate-45 text-gray`} />
                    }
                />
                {(task?.uploadDocs && task?.status === 'pending' && task?.type !== taskTypes.scoreCollection) && <UploadFileButton 
                    filesUploadedByUser={filesUploadedByUser}
                    setFilesUploadedByUser={setFilesUploadedByUser}
                    uploadStatusOk={uploadStatusOk}
                    setUploadStatus={setUploadStatus}
                />
            }
                {filesUploadedByUser?.length > 0 && <SendToReviewButton 
                    task={task}
                    filesUploadedByUser={filesUploadedByUser}
                    setTaskData={setData}
                    />}
            </TabPane>
            <TabPane className={'mb-6'} tabId={'completed'}>
            {task.type === taskTypes.uploadFile ?
                    filesOnTask?.map(file => {
                        return <TaskReady className={'mb-1'} label={file.url.split("/").at(-1)} onClick={() => { window.open(file.url) }} />
                    })
                    :
                    <TaskReady label={buttonLabel} onClick={() => { showTaskInfo() }} />
                }
            </TabPane>
            <ShowLoginInfoModal task={task} />
            <ScoresModal task={task} />
            <OrientationLessonModal
                isOpen={isOpenLessonModal}
                toggle={toggle}
                dataLesson={dataLesson}
                role={'admin'}
            />
        </TabContent>

    )
}

export default ShowTaskStatus 

