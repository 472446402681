import React, { useEffect, useState } from 'react'
import {
  Modal,
  useWindowDimensions,
  StickyButton,
  Input
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment-timezone'

const AddPayment = (props) => {
  const { value, setCuotas, maxValue, deleteCuota, time } = props
  const [isOpen, setOpen] = useState(false)
  const toggle = () => { setOpen(!isOpen) }
  const [amount, setAmount] = useState(0)
  const [date, setDate] = useState(null)
  useEffect(() => {
    setAmount(value)
  }, [value])
  useEffect(() => {
    if (time) {
      setDate(time.clone().endOf('month').format('YYYY-MM-DD'))
    }
  }, [time])
  const { translate } = useTranslation()
  const { breakWidth } = useWindowDimensions()
  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
      >
        <div className='m-0 px-4 py-2'>
          <div className='m-0 py-2 px-0'>
            <label className='w-100 mt-4'>
              {`${translate('modal.newplan.pricingplan.amount')} (${maxValue.toFixed(2)})`}
            </label>
            <Input
              placeholder={translate('modal.newplan.pricingplan.amount')} type='number' value={amount} onChange={(e) => {
                setAmount(e.target.value)
              }} max={maxValue}
            />
            <label className='w-100 mt-4'>
              {translate('modal.newplan.paymentschedule.paymentdate')}
            </label>
            <Input
              placeholder='Date' type='date' value={date} onChange={(e) => {
                setDate(e.target.value)
              }}
            />
            <label
              className='btn-link text-error mt-4' onClick={() => {
                deleteCuota(0)
                toggle()
              }}
            >{translate('modal.newplan.paymentschedule.removepayment')}
            </label>
          </div>
          <StickyButton
            condition
            label={translate('modal.newplan.paymentschedule.createpayment')}
            color='#696CFF'
            disabled={amount <= 0 || amount > maxValue || moment(date) < moment()}
            onClickButtonFunction={() => {
              setCuotas(parseFloat(amount), date)
              toggle()
            }}
          />
        </div>
      </Modal>
    </>
  )
}

AddPayment.propTypes = {
  value: PropTypes.number,
  onClickButton: PropTypes.func,
  customPayment: PropTypes.bool
}

AddPayment.defaultProps = {
  value: 0,
  onClickButton: () => {},
  customPayment: false
}
export default AddPayment
