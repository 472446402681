import React, {useState, useEffect, useMemo} from 'react'
import {
    Modal,
    IconClose,
    Button,
    Input,
    InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import { useSelector, useDispatch } from 'react-redux'
import { UseAppendCourseMutation } from '../../Model/useAppendCourseMaterials'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../../../store/modal/const'
import { showAlert } from '../../../../../../../../common/Alert/util'
import escapeRegExp from 'lodash/escapeRegExp'

const NewCourseMaterials = (props) => {
    const {userId, refetch, dataServices} = props
    const reduxData = useSelector((state) => state.modal.student.newCourseMaterials)
    const { isOpen, courseMaterial } = reduxData
    const { translate } = useTranslation()
    const dispatch = useDispatch()
    

    const [courseMaterialState, setCourseMaterialState] = useState({})
    const services = dataServices?.docs?.filter((serv) => !serv.isOrientation) || []
    const topics = useMemo(() => {
        return services.find((serv) => courseMaterialState.serviceId === serv.id)?.topics || []
    }, [courseMaterialState.serviceId, services])
    
    useEffect(() => {
        if(courseMaterial) {
            const {serviceId, topicId, link} = courseMaterial
            setCourseMaterialState({serviceId, topicId, link})
        } 
    }, [courseMaterial])

    const [appendCourseMaterials, {loading: loadMutation}] = UseAppendCourseMutation()

    const toggle = () => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'student.newCourseMaterials',
            data: {
              ...reduxData,
              isOpen: !isOpen,
            }
        })
    }
    return (
        <Modal
            className='modal-centered modal-colored white-modal modal-desktop-half pl-3'
            isOpen={isOpen}
            toggle={toggle}
            Head={
                <div className='modal-header p-0 m-0'>
                    <div className='d-flex justify-content-between w-100 px-6 pt-7 pb-0'>
                        <h2 className='font-weight-bold w-75'>
                            {translate('profile.actions.editservices.materialsTopic.newCourse.title')}
                        </h2>
                        <IconClose onClick={toggle} className='text-cancel' />
                    </div>
                </div>
            }
        >
            <div className='m-0 px-6 py-2 w-100'>
                <div className='m-0 p-0 w-100'>
                    <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                        {translate('modal.newlesson.service')}
                    </label>
                    <InputSelect
                        className='col-12 p-0'
                        name='service'
                        placeholder={translate('modal.newlesson.selectservice')}
                        value={courseMaterialState?.serviceId}
                        options={
                            services.map((serv) => {
                                return { value: serv.id, label: serv.name }
                            })
                        }
                        filterOption={(obj, string) => {
                          if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                          return false
                        }}
                        onChange={(serviceId) => {setCourseMaterialState({...courseMaterialState, serviceId})}}
                    />
                    <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                        {translate('modal.newlesson.topic')}
                    </label>
                    <InputSelect
                        className='col-12 p-0'
                        name='topic'
                        placeholder={translate('modal.newlesson.selecttopic')}
                        value={courseMaterialState?.topicId}
                        options={
                            topics.map((topic) => {
                                return { value: topic.id, label: topic.name }
                            })
                        }
                        filterOption={(obj, string) => {
                          if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                          return false
                        }}
                        onChange={(topicId) => {setCourseMaterialState({...courseMaterialState, topicId})}}
                    />
                    <label className='col-12 text-muted mt-2 mb-2 mt-3 pl-0'>
                        {translate('profile.actions.editservices.materialsTopic.newCourse.link')}
                    </label>
                    <Input
                        placeholder={'http://example.com'} 
                        type='text' 
                        value={courseMaterialState.link} 
                        onChange={({target: {value}}) => {
                            setCourseMaterialState({...courseMaterialState, link: value})
                        }}
                    />
                </div>
                <Button
                    label={translate('profile.actions.editservices.materialsTopic.newCourse.create')}
                    className={`w-100 btn-primary mt-4`}
                    disabled={false}
                    activity={loadMutation}
                    onClick={() => {
                        appendCourseMaterials({
                            variables: {
                                courseMaterial: courseMaterialState,
                                userStudentId: userId
                            }
                        }).then(() => {
                            refetch()
                            showAlert({
                                text: translate('profile.actions.editservices.materialsTopic.newCourse.successAlert'),
                                status: 'success'
                            }, dispatch)
                            toggle()
                        }, () => {
                            showAlert({
                                text: translate('profile.actions.editservices.materialsTopic.newCourse.errorAlert'),
                                status: 'error'
                            }, dispatch)
                        })
                    }}
                />
                </div>
        </Modal>
    )
}

export default NewCourseMaterials