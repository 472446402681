import {useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {COPY_TEXT} from '../../store/clipboard/const'

const useCopyText = () => {
    const dispatch = useDispatch()
    const copyText = useSelector((state) => state.clipboard.copyText)
    const setCopyText = useCallback(
        (text, onSuccess = () => {}, onError = () => {}) => {
            dispatch({
                type: COPY_TEXT,
                data: {
                    text,
                    onSuccess, 
                    onError
                }
            })
        },
        // eslint-disable-next-line
        [copyText]
    )
    return [copyText, setCopyText]
}

export default useCopyText