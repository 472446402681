import { gql, useQuery } from '@apollo/client'

export default (vars) => {
  return useQuery(
    gql`
    query($id: String!) {
        user(id: $id) {
          id
          emails {
            address
            verified
          }
          firstName
          lastName
        }
      }`,
    {
      variables: vars
    }
  )
}
