import React from 'react'
import Filter from './Filter'
import useTranslation from "../../../../../i18n/useTranslation";

const StateFilter = (props) => {
    const { translate } = useTranslation()
    const {statusFilters, setStatusFilters, useInner=false} = props
    const baseStatuses = [
        //missed,incomplete,noshow,finished,attended,upcoming,pending,inprogress
        {id: 'attended', label: 'Attended' || translate('calendar.sections.filters.inProgress')},
        {id: 'missed', label: translate('calendar.sections.filters.missed')},
        {id: 'noshow', label: translate('modal.infolesson.noshow')},
        {id: 'incomplete', label: translate('calendar.sections.filters.incomplete')},
        // {id: 'finished', label: translate('calendar.sections.filters.completed')},
        {id: 'inprogress', label: 'In progress'},
        {id: 'upcoming', label: translate('calendar.sections.filters.upcoming')},
        {id: 'pending', label: 'Pending'}
    ]
    return (
        <Filter {...{statuses: baseStatuses, statusFilters, setStatusFilters, useInner}} />
    )
}

export default StateFilter
