import {useSelector, useDispatch} from 'react-redux'

const useDeleteTemplateModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.deleteTemplateModal)
    const {isOpen, onChange} = reduxData
    const setOpen = (open = false, onChange = null) => {
        let obj = {isOpen: open}
        if(onChange) obj.onChange = onChange
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.deleteTemplateModal',
            data: {
                ...reduxData,
                ...obj
            }
        })
    }
    
    return {
        isOpen,
        onChange,
        open: (onChange = () => {}) => {
            setOpen(true, onChange)
        },
        close: (onChange = () => {}) => {
            setOpen(false, onChange)
        },
        toggle: (onChange = () => {}) => {
            setOpen(!isOpen, onChange)
        },
        
    }    
}

export default useDeleteTemplateModal