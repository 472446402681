import validate from 'validate.js'

const constraints = {
  payslipId: {
    presence: {
      allowEmpty: false,
      message: '^Please enter a name'
    }
  }
}

export default (data) => {
  return validate(data, constraints)
}
