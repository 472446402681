import React, {useEffect} from 'react'
import {
    Input,
    TemplateTimeline
} from '@Knowledge-OTP/znk-ui-components'
import RenderInputOption from '../../Components/RenderInputOption'
import useTemplateReducer from '../../../../../../reducers/template'
import useSelectTemplateModal from '../../../../../CounselingTemplates/SelectTemplateModal/useSelectTemplateModal'
import useViewTaskModal from '../../../../../CounselingTemplates/Task/viewTaskModal/useViewTaskModal'
import useNewTaskModal from '../../../../../CounselingTemplates/Task/newTaskModal/useNewTaskModal'
import useNewStepModal from '../../../../../CounselingTemplates/Step/newStepModal/useNewStepModal'
import useNewPlanModal from '../../useNewPlanModal'
import useDeleteTaskModal from '../../../../../CounselingTemplates/Task/deleteTaskModal/useDeleteTaskModal'

import './index.css'

import moment from 'moment'

const ScheduleDetails = (props) => {
    const {
        className, 
        plan, 
        setData,
        isEdit
    } = props

    const {dataPlan} = useNewPlanModal()

    const {open: openTemplatesSelection} = useSelectTemplateModal(true)
    //hook modal creación/edición de step
    const {open: openNewStep} = useNewStepModal()
    //hook modal creación/edición de step
    const {open: openNewTask, close: closeNewTask} = useNewTaskModal()
    const {
        state: templateReduce, 
        setData: setDataReduce, 
        initialState,
        filterDelete, 
        removeTask, 
        editTask,
        addTask,
        addStep,
        editStep,
        removeStep,
        moveTask,
        handlerTaskDragAndDrop
    } = useTemplateReducer()
    const {open: openTask, close: closeViewTask} = useViewTaskModal()
    const {close: closeDeleteTask} = useDeleteTaskModal()
    
    //plan?.timeline es el template que renderiza TemplateTimeline (no el del reduce)
    const templatePhases = plan?.tasks

    useEffect(() => {
        if(dataPlan?.tasks?.length > 0 && isEdit) {
            setDataReduce({phases: dataPlan?.tasks, isInitialState: false})
        }
        // eslint-disable-next-line
    }, [dataPlan, isEdit])

    //calcula la duración del plan
    useEffect(() => {
        //actualiza timeline en plan?.tasks
        if(templateReduce && !templateReduce?.isInitialState) {
            setData({
                tasks: templateReduce?.phases || [],
                taskTemplate: plan?.taskTemplate || templateReduce.templateId
            })
        }
        //duración del plan en días
        // return templateReduce?.phases?.reduce((total, {steps}) => total + steps?.filter(({statusBuild}) => statusBuild !== 'delete')
        //     .reduce(
        //         (total, {tasks}) => total + tasks?.filter(({statusBuild}) => statusBuild !== 'delete').reduce((total, {dueTime}) => {
        //             return total + dueTime
        //         }, 0), 0
        //     ), 0)
        // eslint-disable-next-line
    }, [templateReduce])

    //calcula la fecha de termino del plan
    //cada vez que se actualiza la duración o la fecha de inicio
    // useEffect(() => {
    //     if(planDuration) {
    //         const start = moment(plan.from).toISOString()
    //         setData({to: moment(start).add(planDuration, 'days').toISOString()})
    //     }
    //     // eslint-disable-next-line
    // }, [planDuration, plan?.from])

    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <span className={`h4 font-weight-bold text-title`}>Counseling Timeline</span>
            <div className={`m-0 p-0 d-flex flex-row row`}>
                <RenderInputOption className={`col-6`} key={'startTime'} label={`Start time`}>
                    <Input placeholder={`Start time`} value={
                        plan?.from ? moment(plan?.from).format('YYYY-MM-DD') : null
                    } type={`date`} onChange={(e) => {
                        setData({from: e?.target?.value})
                    }} />
                </RenderInputOption>
                <RenderInputOption className={`col-6`} key={'endTime'} label={`End time`}>
                    <Input placeholder={`End time`} value={
                        plan?.to ? moment(plan?.to).format('YYYY-MM-DD') : null
                    } type={`date`} onChange={(e) => {
                        setData({to: e?.target?.value})
                    }} />
                </RenderInputOption>
            </div>
            {
                templatePhases/** && !templateReduce?.isInitialState **/ ?
                    <div className={`mx-2 my-3 timeline-content`}>
                        <TemplateTimeline
                            className='w-100 p-2'
                            isEdit
                            stages={templatePhases ? filterDelete({phases: templatePhases}).phases : []}
                            onClickTask={(task, {phaseId, stepId}) => {
                                //abre modal para ver tarea 
                                openTask(
                                    {...task, isEdit: true},
                                    (action, inputTask) => {
                                        if(action === 'edit') {
                                            //editTask()
                                            //edita la tarea en reduce 
                                            editTask({...inputTask, phaseId, stepId, title: inputTask?.taskName || 'No defined'})
                                            closeNewTask()
                                        }
                                        else if(action === 'duplicate') {
                                            addTask({...inputTask, title: `${inputTask.taskName} - copy`, taskName: `${inputTask.taskName} - copy`, phaseId, stepId, isNew: true/** isNew para que task se muestre con animación **/})  
                                        }
                                        else if(action === 'delete') {
                                            removeTask({...inputTask, phaseId, stepId})
                                            closeDeleteTask()
                                            closeViewTask()
                                        }
                                    }
                                )
                            }}
                            onCreate={({phaseId}) => {
                                //abre modal para crear step
                                openNewStep((newStepName) => {
                                    //añade nuevo step
                                    addStep({stepName: newStepName, title: newStepName,  phaseId})
                                })
                            }}
                            onEdit={({phaseId, stepName, title, stepId}) => {
                                //abre modal para editar step
                                openNewStep((newStepName) => {
                                    //edita step
                                    editStep({stepId, stepName: newStepName, title: newStepName, phaseId})
                                }, stepName || title)
                            }}
                            onDelete={({phaseId, stepId}) => {
                                removeStep({phaseId, stepId})
                            }}
                            onCreateTask={({phaseId, stepId}) => {
                                openNewTask(
                                    (newTask) => {
                                        //callback
                                        addTask({...newTask, title: newTask.taskName, phaseId, stepId, isNew: true/** isNew para que task se muestre con animación **/})
                                        closeNewTask()
                                    }
                                )
                            }}
                            onDropTask={(env) => {
                                handlerTaskDragAndDrop(env, moveTask)
                            }}
                        />
                    </div> :
                    null

            }
            <RenderInputOption key={'timeline'} label={`Crea un timeline y asigna pasos para crear la planificación de tareas de este plan.`}>
                <div className={`d-flex flex-row m-0 p-0`}>
                    <span 
                        className={`btn-link`} 
                        onClick={() => {
                                openTemplatesSelection((templateSelected) => {
                                    console.log("templateSelected", templateSelected)
                                    //despues de seleccionar template
                                    setDataReduce(filterDelete(templateSelected))
                                })
                            }
                        }
                        >Select template</span>
                    <span className={`mx-2`}>or</span> 
                    <span 
                        className={`btn-link`} 
                        onClick={
                            () => {
                                //setea initialState en template reducer
                                setDataReduce({...initialState, isInitialState: false})
                            }
                        }>create a new one</span>
                </div>
            </RenderInputOption>
        </div>
    )
}

export default ScheduleDetails