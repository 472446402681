import React, { useState } from 'react'
import {
    Button,
    Activity
} from '@Knowledge-OTP/znk-ui-components'
import {useLazyQuery, gql} from '@apollo/client'
import { hasHttp } from '../../../utils'
import useTranslation from '../../../../../../i18n/useTranslation'

const BackupLinkButton = (props) => {
    const {className, educatorId} = props
    const { translate } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [getBackUpLink] = useLazyQuery(
        gql`
            query ($id: String!) {
                user (
                    id: $id
                ) {

                    educatorInfo { backupLink }
                }
            }
        `, {fetchPolicy: 'no-cache'}
    )
    return (
        <Button
          style={{ backgroundColor: '#72809d' }}
          className={className}
          size='large'
          onClick={() => {
              setLoading(true)
              getBackUpLink({variables: {id: educatorId}}).then(
                  (res) => {
                        const link = res?.data?.user?.educatorInfo?.backupLink
                        if(link) {
                            window.open(hasHttp(link), '_blank')
                        }
                        setLoading(false)
                    }, 
                    (err) => {
                        console.error(err)
                        setLoading(false)
                    } 
              )
            }}
          label={
            loading
            ? <div className={'w-100 d-flex flex-row justify-content-center'}><Activity inverse /></div>
            : <span className='text-center'>{translate('modal.infolesson.backupcall')}</span>}
        />
    )
}

export default BackupLinkButton