import React, { useMemo } from 'react'
import {
    IconEdit, IconErase,
    IconOptions, IconNotes,
    EventItem, DropDownNew,
    DropDownItem, IconSignPlus, IconTime, Action
} from '@Knowledge-OTP/znk-ui-components'
import moment from 'moment'
import Notes from './Modal/Notes'
import DeleteCheckIn from './Modal/Delete'
import EditCheckIn from './Modal/Edit'
import useGetCheckInsForPlan from './Model/useGetCheckInsForPlan'
import { TabContent, TabPane } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../../i18n/useTranslation'


export const CheckInTypeInt = {
    regular: 'program.plan.checkIns.types.regular',
    scoreExpectation: 'program.plan.checkIns.types.scoreRelease'
}

export const CheckInEventItem = (props) => {
    const { checkin, refetch = () => {}, studentUserId, planStatus } = props
    const { date, type, id } = checkin
    const {translate} = useTranslation()
    return (
        <EventItem
            // pending scheduling
            className={`my-1 py-1`}
            key={id}
            status={'secondary'}
            Icon={() => {
                return (
                    <div className='d-flex flex-row align-items-center'>
                        {
                            planStatus === 'scheduled' && (
                                <Notes checkinId={id} studentId={studentUserId}>
                                    <div className='hover-icon'><IconNotes size={12} className={'text-white'} /></div>
                                </Notes>
                            )
                        }
                        <DropDownNew
                            direction={'bottomRight'}
                            options={[
                                {
                                    component: (
                                        <DropDownItem
                                            className={'d-flex flex-row align-items-center text-info'}
                                            key={0}
                                            onClick={() => { }}
                                        >
                                            <EditCheckIn checkin={checkin} refetch={refetch}>
                                                <IconEdit size={12} className={'mr-1'} /> {translate("program.plan.checkIns.edit.option")}
                                            </EditCheckIn>
                                        </DropDownItem>
                                    ),
                                },
                                {
                                    component: (
                                        <DropDownItem
                                            className={'d-flex flex-row align-items-center text-error danger-option'}
                                            key={1}
                                            onClick={() => { }}
                                        >
                                            <DeleteCheckIn checkinId={id} refetch={refetch}>
                                                <IconErase size={12} className={'mr-1'} /> {translate("program.plan.checkIns.delete.option")}
                                            </DeleteCheckIn>
                                        </DropDownItem>
                                    ),
                                }
                            ]}
                        >
                            <div className='hover-icon mx-1'><IconOptions size={12} className={'text-white rotate-90'} /></div>
                        </DropDownNew>
                        {/* <div className='hover-icon mx-1'><IconEdit size={12} className={'text-white'}/></div>
                                                        <div className='hover-icon'><IconErase size={12} className={'text-white'}/></div> */}
                    </div>
                )
            }}
            titleForTitle={`${moment(date).utc().format("dddd, Do MMM")} (${translate(CheckInTypeInt[type])})`}
            title={
            <>
                <IconTime className={'mr-2'} style={{marginBottom: '3px'}} size={16}/>
                {moment(date).utc().format("dddd, Do MMM")} <small>{`(${translate(CheckInTypeInt[type])})`}</small>
            </>
            }
            info={''}
        />
    )
}

const CheckInsSection = (props) => {
    const { plan = {}/**use id, status, studentUser.studentUserId**/ } = props
    const { data, loading, refetch } = useGetCheckInsForPlan({ planId: plan.id })
    const checkIns = useMemo(() => data?.getCheckInsForPlan || [], [data])
    const sortArray = [...checkIns].sort(
        (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
    )
    const {translate} = useTranslation()
    return (
        <div className='d-flex flex-column'>
            <div className='container-fluid d-flex flex-column px-0 mb-5'>
                <div className='d-flex flex-column'>
                    <TabContent activeTab={loading ? 'loading' : 'show'}>
                        <TabPane tabId={'loading'}>
                            <Skeleton style={{ borderRadius: '8px' }} count={5} height={40} className={'w-100'} />
                        </TabPane>
                        <TabPane tabId={'show'}>
                            {
                                sortArray?.length > 0 ? sortArray.map((checkin) => {
                                    return (
                                        <CheckInEventItem
                                            checkin={checkin} studentUserId={plan?.studentUser?.studentUserId}
                                            refetch={refetch} planStatus={plan?.status}
                                        />
                                    )
                                }) :
                                <Action
                                color='transparent'
                                textColor='#7079FF'
                                border='dashed 1px #7079FF'
                                className='my-3 py-1 w-100'
                                labelLeft={
                                  <span className='w-100 d-flex flex-column my-3'>
                                    <label className='m-0'>
                                      {translate('program.plan.checkIns.noCheckins')}
                                    </label>
                                  </span>
                                }
                                labelRight={null}
                                action={null}
                              />
                            }
                        </TabPane>
                    </TabContent>

                    {(plan?.status !== 'cancel' && plan?.status !== 'finished') && <EditCheckIn
                        refetch={refetch}
                        checkin={{}}
                        isEdit={false} planId={plan.id}>
                        <EventItem
                            // pending scheduling
                            className={`my-1 py-1 cursor-pointer`}
                            key={'new-check-in'}
                            status={'input'}
                            Icon={() => { return (<div className='hover-icon'><IconSignPlus size={12} className={'rotate-45'} /></div>) }}
                            title={translate("program.plan.checkIns.create.option")}
                            info={''}
                        />
                    </EditCheckIn>}


                </div>
            </div>
        </div>
    )
}

export default CheckInsSection