import { useSelector, useDispatch } from "react-redux"
import validate from 'validate.js'

const constraints = {
    name: {
        presence: true
    },
    description: {
        presence: false
    },
    toDefine: {
        presence: true
    }
}
const constraints2 = {
    date: {
        presence: true
    }
}
const useCreateEventModal = () => {
    const dispatch = useDispatch()
    const reduxData = useSelector((state) => state.modal.counseling.createEventModal)
    const {isOpen} = reduxData

    const setOpen = (open, onChange = () => {}, event = null) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.createEventModal',
            data: {
                ...reduxData,
                isOpen: open,
                onChange,
                event
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange = () => {}, event = null) => {setOpen(true, onChange, event)},
        close: () => {setOpen(false)},
        toggle: () => {setOpen(!isOpen)},
        validation: (form) => {
            if(!form?.toDefine) return validate(form, {...constraints, ...constraints2})
            return validate(form, {...constraints})
        }
    }
}

export default useCreateEventModal