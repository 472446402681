import React from 'react'
import {TimelinePlan} from '@Knowledge-OTP/znk-ui-components'
import useCreateTimelineModal from '../../../../../../../Timelines/Modal/CreateTimelineModal/useCreateTimelineModal'

const LiveprepTimeline = (props) => {
    const {plan, className} = props
    const { parseToTimeline } = useCreateTimelineModal()
    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <span className={`h4 font-weight-bold text-title`}>Live Prep Timeline</span>
            <TimelinePlan show={false} events={parseToTimeline( (plan?.timeline || [])?.filter( ({statusBuild}) => statusBuild !== 'delete' ) )}/>
        </div>
    )
}

export default LiveprepTimeline