import React, {useState} from 'react'
import {
  IconArrowRight,
  ListItem,
  IconEdit,
  IconCountry,
  IconCircleDolar,
  IconNotificationOff,
  IconArrowDown,
  IconArrowUp,
  IconProfile,
  IconStundent,
  IconCreditCard,
  IconKey,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../i18n/useTranslation'
import { useDispatch } from 'react-redux'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import moment from 'moment-timezone'
import {Collapse} from 'reactstrap'

const Profile = (props) => {
  const { toggleSideModal, setActiveTabRight, userData, openSideModal, style } = props
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  //const servicesRate = useSelector((state) => state.modal.user.servicesRate)
  const toggleOpenEdit = () => {
    const phone = userData?.phone
    const dialCode = phone ? phone.split(' ').slice(0, phone.split(' ')?.length-1).join(' ') : ''
    const phoneNumber = phone ? phone.split(' ')[phone.split(' ')?.length-1] : ''

    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.edit',
      data: {
        isOpen: true,
        ...userData,
        currentIsActive: userData.isActive,
        phoneDialCode: dialCode,
        phoneNumber,
        birthDay: userData.birthdate ? moment(userData.birthdate).date() : '',
        birthMonth: userData.birthdate ? moment(userData.birthdate).month() : '',
        birthYear: userData.birthdate ? moment(userData.birthdate).year() : '',
        email: userData.emails[0].address,
        timezone: { value: userData.timezone, label: userData.timezone },
        country: { value: userData.country.id, label: userData.country.name }
      }
    })
  }
  const toggleOpenEditStudentInfo = () => {
    const { studentInfo } = userData
    dispatch(
      {
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'user.studentInfo',
        data: {
          isOpen: true,
          id: userData.id,
          ...(studentInfo ? ({
            ...studentInfo,
            month: moment(studentInfo.graduationDate).month(),
            year: moment(studentInfo.graduationDate).year()
          }) : {
            month: moment().month(),
            year: moment().year()
          })
        }
      }
    )
  }

  const toggleOpenEditFamilyInfo = () => {
    const { studentInfo } = userData
    dispatch(
      {
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'user.familyInfo',
        data: {
          isOpen: true,
          userId: userData.id,
          parents: studentInfo.parents
        }
      }
    )
  }

  const toggleOpenSetServiceRate = () => {
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'user.servicesRate',
      data: {
        isOpen: true,
        typeUser: "student",
        userId: userData?.id
      }
    })
  }

  const [openCollapse, setOpenCollapse] = useState(false)

  return (
    <div className='m-0 p-0 d-flex flex-column justify-content-start' style={style}>
      {/**<label 
        className={'my-3 mb-0 h45 btn-link'} 
        onClick={() => {
          setActiveTabRight('viewProfile')
          if (!openSideModal) toggleSideModal()
        }}>View complete profile</label>**/}
      <ListItem
        showTopLine
        showBottomLine
        hasHover
        className='py-4 hover-no-border px-2'
        onClick={() => {
        }}
        Left={
          <div 
            onClick={() => {
              setActiveTabRight('viewProfile')
              if (!openSideModal) toggleSideModal()
            }}
            className='d-flex flex-row'>
            <IconProfile color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {translate('student.modal.profile.viewProfile')}
            </span>
          </div>
        }
        Right={
          <div onClick={() => {
            setOpenCollapse(!openCollapse)
          }} className={'hover-icon m-0 px-1 py-0'}>
            {
              openCollapse
              ? <IconArrowUp className={'m-0 p-0 text-gray'} />
              : <IconArrowDown className={'m-0 p-0 text-gray'} />
            }
          </div>
        }
      />
      <Collapse isOpen={openCollapse} className={'m-0 pl-3 pr-0 py-0'}>
        <ListItem
          showTopLine={false}
          showBottomLine={false}
          hasHover
          className='py-4 hover-no-border px-2'
          onClick={() => {
            if (!openSideModal) toggleSideModal()
            toggleOpenEdit()
            setActiveTabRight('editProfile')
          }}
          Left={
            <div className='d-flex flex-row'>
              <IconEdit color='#72809D' className='align-self-center mr-2' />
              <span className='align-self-center text-title'>
              {translate('student.modal.profile.editPersonalInfo')}
              </span>
            </div>
          }
          Right={<IconArrowRight className={`text-gray`} />}
        />
        <ListItem
          showTopLine
          showBottomLine={false}
          hasHover
          className='py-4 hover-no-border px-2'
          onClick={() => {
            if (!openSideModal) toggleSideModal()
            toggleOpenEditStudentInfo()
            setActiveTabRight('editStudentInfo')
          }}
          Left={
            <div className='d-flex flex-row'>
              <IconStundent color='#72809D' className='align-self-center mr-2' />
              <span className='align-self-center text-title'>
                {translate('student.modal.profile.editAcademicInfo')}
              </span>
            </div>
          }
          Right={<IconArrowRight className={`text-gray`} />}
        />
        <ListItem
          showTopLine
          showBottomLine={false}
          hasHover
          className='py-4 hover-no-border px-2'
          onClick={() => {
            if (!openSideModal) toggleSideModal()
            toggleOpenEditFamilyInfo()
            setActiveTabRight('editFamilyInfo')
          }}
          Left={
            <div className='d-flex flex-row'>
              <IconStundent color='#72809D' className='align-self-center mr-2' />
              <span className='align-self-center text-title'>
                {translate('student.modal.profile.editFamilyInfo')}
              </span>
            </div>
          }
          Right={<IconArrowRight className={`text-gray`} />}
        />
      </Collapse>
      <ListItem
        showTopLine={openCollapse}
        showBottomLine
        hasHover
        className='py-4 hover-no-border px-2'
        onClick={() => {
          if (!openSideModal) toggleSideModal()
          toggleOpenSetServiceRate()
          setActiveTabRight('setServiceRate')
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconCircleDolar color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {translate('student.modal.profile.servicesRates')}
            </span>
          </div>
        }
        Right={<IconArrowRight className={`text-gray`} />}
      />
      <ListItem
        showBottomLine
        hasHover
        className='py-4 hover-no-border px-2'
        onClick={() => {
          if (!openSideModal) toggleSideModal()
          setActiveTabRight('notificationSettings')
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconNotificationOff color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {translate('student.modal.profile.notificationSettings')}
            </span>
          </div>
        }
        Right={<IconArrowRight className={`text-gray`} />}
      />
      {/**<ListItem
        showBottomLine
        className='py-4'
        onClick={() => {
          if (!openSideModal) toggleSideModal()
          setActiveTabRight('changePaymentMethod')
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconCreditCard color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {translate('student.modal.profile.changePaymentMethod')}
            </span>
          </div>
        }
        Right={<IconArrowRight className={`text-gray`} />}
      />**/}
      <ListItem
        showBottomLine
        hasHover
        className='py-4 hover-no-border px-2'
        onClick={() => {
          if (!openSideModal) toggleSideModal()
          setActiveTabRight('changeTimezone')
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconCountry color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {translate('student.modal.profile.changeTimezone')}
            </span>
          </div>
        }
        Right={<IconArrowRight className={`text-gray`} />}
      />
      <ListItem
        showBottomLine
        hasHover
        className='py-4 hover-no-border px-2'
        onClick={() => {
          if (!openSideModal) toggleSideModal()
          setActiveTabRight('changePaymentMethod')
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconCreditCard color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
              {userData?.paymentMethod === null
                ? translate('profile.actions.changeMethod.add')
                : translate('profile.actions.changeMethod.change')}
            </span>
          </div>
        }
        Right={<IconArrowRight className={`text-gray`} />}
      />
      <ListItem
        showBottomLine
        hasHover
        className='py-4 hover-no-border px-2'
        onClick={() => {
          if (!openSideModal) toggleSideModal()
          setActiveTabRight('viewAccounts')
        }}
        Left={
          <div className='d-flex flex-row'>
            <IconKey color='#72809D' className='align-self-center mr-2' />
            <span className='align-self-center text-title'>
            {translate('student.modal.profile.accounts')}
            </span>
          </div>
        }
        Right={<IconArrowRight className={`text-gray`} />}
      />
    </div>
  )
}

export default Profile
