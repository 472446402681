import React, {useState} from 'react'
import {
    ModalLayout,
    useWindowDimensions
} from '@Knowledge-OTP/znk-ui-components'

const InfoWidgetModal = (props) => {
    const {innerInfo = <></>, titleModal='Information'} = props
    const [isOpen, setOpen] = useState(false)
    const toggle = () => {setOpen(!isOpen)}
    const {breakWidth} = useWindowDimensions()
    return (
        <>
            <div onClick={toggle} className='m-0 p-0'>{props.children}</div>
            <ModalLayout
                className={`${breakWidth === 'SM' ? 'modal-botbar' : 'modal-centered'} modal-hauto modal-noborder-bottom`}
                isOpen={isOpen}
                toggle={toggle}
                disabledScrollAnimation={true}
                topHead={<span className={`h2 font-weight-bold text-title`}>{titleModal}</span>}
            >
                <p 
                style={
                    {
                        textAlign: 'justify',
                    }
                }
                className='text-info h4'>
                    {innerInfo}
                </p>
            </ModalLayout>
        </>
    )
}

export default InfoWidgetModal