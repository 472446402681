import React, {useMemo} from 'react'
import {ListItem, IconArrowRight} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../../../i18n/useTranslation'
import moment from 'moment'
const PaymentItemList = (props) => {
    const {payments, onClick = () => {}, disabled = false} = props
    const {atSigningAmount, totalPayments} = useMemo(
        () => {
            const totalLessonPayments = payments?.filter(({atSigning}) => !atSigning)?.reduce((total, {amount}) => total+amount, 0) || 0 
            const atSigningAmount = payments?.filter(({atSigning}) => atSigning)?.reduce((total, {amount}) => total+amount, 0) || 0
            return {atSigningAmount, totalPayments: totalLessonPayments+atSigningAmount}
        }, [payments]
    )
    const {translate} = useTranslation()
    return (
        <>
            <ListItem
              className='py-2 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label>{translate('modal.newplan.paymentschedule.whenapproving')}</label>
              }
              Right={
                <label className={`d-flex flex-row align-items-center`}>
                  {`$${atSigningAmount ? atSigningAmount.toFixed(2) : 0}`}
                  {/* <div className={`hover-icon ml-1`}><IconArrowRight /></div> */}
                </label>
              }
            />
            {
              // cuotas && cuotas.length > 0 ? cuotas
              payments ? (payments?.filter(({ atSigning }) => !atSigning) || [])?.map((obj, index) => {
                return (
                  <ListItem
                    key={index}
                    className='py-2 w-100'
                    showBottomLine={false}
                    showTopLine={false}
                    Left={
                      <label>
                        <span>
                          {translate('modal.newplan.endof') + ' ' + moment(obj.date).format('MMMM')}
                        </span>
                        <span className='text-muted h45'>
                          {`    (${translate('modal.newplan.indexFee', {
                            num1: parseInt(index + 1), num2: parseInt(payments?.length || 0)
                          })})`}
                        </span>
                      </label>
                    }
                    Right={
                      <label
                        style={{color: 'black'}}
                        className={`${!disabled ? 'btn-link cursor-pointer' : 'cursor-not-allowed'} d-flex flex-row align-items-center`}
                        onClick={() => {
                          if(onClick && !disabled) onClick(obj)
                        }}
                      >
                        {`$${(obj.amount).toFixed(2)}`}
                        {
                          !disabled && (
                            <div className={`hover-icon ml-1`}><IconArrowRight /></div>
                          )
                        }
                      </label>
                    }
                  />
                )
              }) : <label>{translate('modal.newplan.paymentschedule.selectlimitdates')}</label>}
            <ListItem
              key={-1}
              className='py-2 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label>{'Total: '}</label>
              }
              Right={
                <label>{`$${(totalPayments).toFixed(2)}`}</label>
              }
            />
        </>
    )
}

export default PaymentItemList