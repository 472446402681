import React, {useMemo} from 'react'
import {
    Avatar,
    ListItem
} from '@Knowledge-OTP/znk-ui-components'

const StudentAndParent = (props) => {
    const {plan, className} = props
    const { studentName } = useMemo(() => {
        if (plan?.studentUser) return {
            studentName: `${plan.studentUser?.firstName} ${plan.studentUser?.lastName}`,
            // activeTab: 'selected'
        }
        return {
            studentName: `No defined`,
            // activeTab: 'no-selected'
        }
        // eslint-disable-next-line
    }, [plan?.studentUser])

    const { parentName } = useMemo(() => {
        if (plan?.aditionalInfo?.parentFirstName && plan?.aditionalInfo?.parentLastName) return {
            parentName: `${plan?.aditionalInfo?.parentFirstName} ${plan?.aditionalInfo?.parentLastName}`,

        }
        return {
            parentName: null,
        }
        // eslint-disable-next-line
    }, [plan?.aditionalInfo])
    return (
        <div className={`d-flex flex-column ${className}`}>
            <ListItem
                className='py-1 appears-animation'
                id='x-0'
                label={null}
                Left={
                    <div className='d-flex align-items-center'>
                        <Avatar
                            name={studentName}
                            named={false}
                            uri={plan?.aditionalInfo?.avatar || null}
                            size='avatar-medium'
                        />
                        <label className='ml-2 h4 text-info'>
                            {studentName}
                            <span className='ml-2 h45 text-gray'>(Student)</span> 
                        </label>
                    </div>
                }
                Right={null}
            />
            {parentName && <ListItem
                className='py-1 appears-animation'
                id='x-0'
                label={null}
                Left={
                    <div className='d-flex align-items-center'>
                        <Avatar
                            name={parentName}
                            named={false}
                            uri={plan?.aditionalInfo?.avatarParent || null}
                            size='avatar-medium'
                        />
                        <label className=' ml-2 h4 text-info'>
                            {parentName}
                            <span className='ml-2 h45 text-gray'>(Parent)</span> 
                        </label> 
                    </div>
                }
                Right={null}
            />}
        </div>
    )
}

export default StudentAndParent