import {useQuery, gql} from '@apollo/client'

const useStudentWithoutTasks = (vars) => {
    return useQuery(
        gql`
            query (
                $limit: Int
            ) {
                studentWithoutTasks (
                    limit: $limit
                ) {
                    user {
                        id
                        firstName
                        lastName
                        avatar
                    }
                    tasks {
                        planId
                        task {
                            id
                            name
                            reviewDate
                        }
                    }
                }
            }
        `,
        {
            variables: vars
        }
    )
}

export default useStudentWithoutTasks