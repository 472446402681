import {useQuery, gql} from '@apollo/client'


const useGetUniversitiesStudent = () => {
    return useQuery(
        gql`
            query {
                getUniversitiesStudent {
                    name
                    id
                }
            }
        `
    )
}

export default useGetUniversitiesStudent