import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Layout from './common/Layout'
import ListenerPlatform from './common/ListenerPlatform'
import './index.css'
import I18nView from './Pages/I18n'
import ConstantsView from './Pages/Constants'
import UsersView from './Pages/Users'
import PlansView from './Pages/Plans'
import PaymentsViews from './Pages/Payments'
import TestView from './Pages/Test'
import LessonsView from './Pages/LessonsList'
import CalendarView from './Pages/Calendar'
import PayslipsViews from './Pages/Payslips'
import ArrayAlert from './common/Alert/index'
import DeveloperView from './Pages/Developer'
import TimelinesView from './Pages/Timelines'
import PdfEditorView from './Pages/PdfEditor'
import CounselingPlansView from './Pages/CounselingPlans'
import DashboardView from './Pages/Dashboard'
import RenderModals from './Pages/RenderModals'

import ReducerTestView from './Pages/ReducerTest'
import { Elements } from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import CopyText from './common/CopyText'


import WorkspaceView from './Pages/Workspace'

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY)

const App = () => (
  <Router>
    <Elements stripe={stripePromise}>
      <Layout>

        <Switch>
          <Route path='/constants' component={ConstantsView} />
          <Route path='/i18n' component={I18nView} />
          <Route path='/users/:user/:role/:tab' component={UsersView} />
          <Route path='/users' component={UsersView} />
          <Route path='/plans' component={PlansView} />
          <Route path='/testing' component={TestView} />
          <Route path='/payments' component={PaymentsViews} />
          <Route path='/lessons' component={LessonsView} />
          <Route path='/calendar' component={CalendarView} />
          <Route path='/developer' component={DeveloperView} />
          <Route
            exact
            path="/"
            render={() => {
                return (<Redirect to="/calendar" />)
            }}
          />
          <Route path='/payslips' component={PayslipsViews} />
          <Route path='/reducer' component={ReducerTestView} />
          <Route path='/counseling-plans' component={CounselingPlansView} />
          <Route path='/timelines' component={TimelinesView} />
          <Route path='/pdf-editor/:pdfTemplateId' component={PdfEditorView} />
          <Route path='/workspace/:section' component={WorkspaceView} />
          <Route path='/dashboard/:section' component={DashboardView} />
          <Route render={() => <div className='d-flex justify-content-center align-items-center flex-column ' style={{ flex: 1 }}>404</div>} />
        </Switch>
        <ArrayAlert />
        <CopyText/>
        <ListenerPlatform/>
        
        
        <RenderModals/>
        
      </Layout>
    </Elements>
  </Router>
)

export default App
