import { gql, useMutation } from '@apollo/client'

const usePostponeReminderTimelinePlan = (onCompleted = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
            mutation (
                $newRemiderDate: String!
                $planId: ID!
            ) {
                postponeReminderTimelinePlan (
                    planId: $planId 
                    newRemiderDate: $newRemiderDate
                )
            }
        `, {onCompleted, onError}
    )
}

export default usePostponeReminderTimelinePlan