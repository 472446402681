import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import {
  ModalLayout,
  IconIllustratedCalendar,
  IconProgram,
  ListOption,
  StickyButton,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import { TabContent, TabPane } from 'reactstrap'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../../store/modal/const'
import useShowScheduleMeeting from './useShowScheduledMeeting'
import { useGetOrientationHours } from './Models/useGetOrientationHours'
import useScheduleCounselorFromStudent from './Models/useScheduleCounselorFromStudent'
import { ParseFrames } from './utils'
import useAllUserTasks from './Models/useAllUserTasks'
import MessageModal from './Components/messageModal'
import useOperationTaskMutation from '../../../Model/useOperationTask'
import { UseGetUserTask } from './Models/useGetUserTask'
import UseUserQuery from '../../../../../Calendar/modal/ResumeOrientation/useUserQuery'
import ShowCounselorAssigned from './Components/ShowCounselorAssigned'
import { extractTeamFromPlan } from '../../../assingTaskModal/Utils/extractTeamFromPlan'
import phaseNames from '../UploadFile/Utils/phaseNames'
import { showAlert } from '../../../../../../common/Alert/util'
import useViewTaskModal from '../../useViewTaskModal'

const ShowScheduledMeeting = () => {
  const {
    isOpen,
    toggle,
    task,
  } = useShowScheduleMeeting()

  const { taskReducer: { setData } } = useViewTaskModal()
  const planId = task?.dataPlan?.id || task?.planId
  const studentInfoId = task?.dataPlan?.studentUser?.studentUserId || task?.userId
  const dispatch = useDispatch()

  const dataRedux = useSelector(
    (state) => state.modal.counseling.scheduleMeetingModal,
  )
  const meUserId = useSelector((state) => state.user.id)
  // eslint-disable-next-line
  const [getTimezone, { loading, data: dataStudent }] = UseUserQuery()
  const [getCounselorTimezone, { data: dataCounselor }] = UseUserQuery()

  // eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState(null)
  const [team, setTeam] = useState('')
  const [selectedStartTime, setSelectedStartTime] = useState(null)
  const [selectedEndTime, setSelectedEndTime] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [message, setMessage] = useState('')
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [allOptions, setAllOptions] = useState([])
  const [parsedArray, setParsedArray] = useState([])
  const [hideMoreOptions, setHideMoreOptions] = useState(false)
  const [
    getOrientationHours,
    { loading: loadingGetOrientationHours, data: dataGetOrientationHours },
  ] = useGetOrientationHours()

  const { refetch: refetchTasks } = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })

  useEffect(() => {
    setTeam(extractTeamFromPlan(task?.dataPlan))
  }, [task])

  // eslint-disable-next-line
  const [getOneTask, { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask }] = UseGetUserTask(
    (resp) => {
      const taskToSave = resp?.getUserTask
      setData(taskToSave)
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling.scheduleMeetingModal',
        data: {
          ...dataRedux,
          task: taskToSave,
        },
      })
         toggle()
    },
    (err) => {
      console.error('Error on getting task : ', err)
    },
  )

  const [
    sendToReviewMutation,
    { loading: loadSendToReviewMutation, data: dataSendToReviewMutation },
  ] = useOperationTaskMutation(
    () => {
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase: phaseNames[task?.phaseId] || phaseNames[task?.phase] || phaseNames[task?.phaseName],
          planId,
        },
      })
    },
    (err) => {
      console.error('Error on complete task : ', err)
    },
  )

  const sendToReviewFunction = () => {
    sendToReviewMutation({
      variables: {
        universities: task?.universities,
        operation: 'review',
        userId: task?.userId,
        taskId: task?.id,
        stepId: task?.stepId,
        phase: phaseNames[task?.phaseId] || phaseNames[task?.phase] || phaseNames[task?.phaseName],
        planId: planId,
      },
    })
  }

  const {
    submit: scheduleMeetingnMutation,
    loading: loadScheduleMeetingMutation,
    // eslint-disable-next-line
    data: scheduleMeetingData,
  } = useScheduleCounselorFromStudent(
    () => {
      setSelectedTime(null)
      sendToReviewFunction()
    },
    (err) => {
      console.error('Error scheduling task : ', err)
      showAlert({
        status: 'error',
        text: err?.toString()
      }, dispatch)
    },
  )
  const selectBlock = (timeMoment, selected) => {
    setSelectedTime(selected ? timeMoment : null)
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.scheduleMeetingModal.data',
      data: {
        ...dataRedux,
        scheduled: selected ? moment(timeMoment) : null,
      },
    })
  }

  const scheduleMeetingFunction = () => {
    try {
      scheduleMeetingnMutation({
        isAdmin: true,
        councelorInput: {
          startDate: selectedStartTime,
          endDate: selectedEndTime,
          councelorId: task?.userMeetingId || meUserId,
          phase: phaseNames[task?.phaseId] || phaseNames[task?.phase] || phaseNames[task?.phaseName],
          taskId: task?.id,
          stepId: task?.stepId,
          planId,
        },
      })
    } catch (error) {
      setMessage(error.toString())
      setMessageModalOpen(true)
    }
  }

  const studentCompleteInfo = useMemo(() => {
    if (dataStudent?.user) {
      return dataStudent?.user
    }
    return null
  }, [dataStudent])

  const counselorZone = dataCounselor?.user?.timezone || 'UTC'
  const zone = studentCompleteInfo?.timezone || 'UTC'
  const moreOptions = async () => {
    setHideMoreOptions(true)
    setParsedArray(ParseFrames(allOptions, zone/**moment.tz(zone).format('z')**/, counselorZone /**moment.tz(counselorZone).format('z')**/ ))
  }

  useEffect(() => {
    setHideMoreOptions(false)
  }, [])

  useEffect(() => {
    if (studentInfoId) {
      getTimezone({
        variables: {
          id: studentInfoId
        }
      })
    }
    // eslint-disable-next-line
  }, [studentInfoId])

  //obtiene el timezone del counselor
  useEffect(() => {
    if(task?.userMeetingId) {
      getCounselorTimezone({
        variables: {
          id: task?.userMeetingId
        }
      })
    }
    // eslint-disable-next-line
  }, [task?.userMeetingId])


  useEffect(() => {
    if (
      (task?.userMeetingId || meUserId) &&
      isOpen
    ) {
      getOrientationHours({
        variables: {
          educatorId: task?.userMeetingId || meUserId,
          from: moment(),
          to: task?.dueDate,
        },
      })
    }
    // eslint-disable-next-line
  }, [task, isOpen])

  useEffect(() => {
    if (dataGetOrientationHours) {
      setAllOptions(dataGetOrientationHours?.getOrientationHours)
      const responseArray = ParseFrames(
        dataGetOrientationHours?.getOrientationHours,
        zone, //moment.tz(zone).format('z'),
        counselorZone //moment.tz(counselorZone).format('z'),
      )
      responseArray.length = 4
      setHideMoreOptions(false)
      setParsedArray(responseArray)
    }
    // eslint-disable-next-line
  }, [dataGetOrientationHours])

  useEffect(() => {
    if (dataSendToReviewMutation) {
      refetchTasks()
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase: phaseNames[task?.phaseId] || phaseNames[task?.phase] || phaseNames[task?.phaseName],
          planId,
        },
      })
    }
    // eslint-disable-next-line
  }, [dataSendToReviewMutation])

  return (
    <>
      <ModalLayout
        isOpen={isOpen}
        toggle={toggle}
        className='modal-colored modal-sidebar modal-desktop-half white-modal'
        minHead={null}
        topHead={null}
        underHead={
          <div className='m-0 d-flex flex-column'>
            <IconIllustratedCalendar size={90} color='#000000' />
            <span className='mt-3 h2' style={{ color: '#2B3C55' }}>
              Select a Date
            </span>
            <span className='h4 mt-2' style={{ color: '#2B3C55' }}>
              {`${task?.name} | ${studentCompleteInfo?.city}, GMT${moment
                .tz(zone)
                .format('z')}`}
            </span>
          </div>
        }
      >
        {/* Listado de horarios */}
        <TabContent activeTab={loadingGetOrientationHours ? 'loading' : 'show'}>
          <TabPane tabId='loading'>
            <Skeleton count={1} height={50} className='my-1' />
            <Skeleton count={4} height={40} className='my-1' />
            <Skeleton count={1} height={10} className='my-6 w-70' />
          </TabPane>
          <TabPane tabId='show'>
            <>
              <ShowCounselorAssigned
                team={team}
                userAssignedId={task?.userMeetingId}
              />
              {parsedArray &&
                parsedArray?.map((time, index) => (
                  <ListOption
                    key={index}
                    // classNameFilledIcon='znk-list-option-selected-inner-gold'
                    className={`text-title mb-2 ${selectedTime === time ? 'selected-secondary' : ''
                      }`}
                    selectedCounseling={selectedTime === time}
                    classNameFilledIcon='text-counseling'
                    childClassName='d-flex flex-row'
                    onClick={() => {
                      setSelectedOption(time, index)
                      setSelectedStartTime(
                        moment(time).tz(zone).clone().format(),
                      )
                      setSelectedEndTime(
                        moment(time)
                          .tz(zone)
                          .clone()
                          .add(30, 'minutes')
                          .format(),
                      )
                      selectBlock(time, true)
                    }}
                    label={
                      <span className='d-flex flex-row align-items-center m-0 p-0'>
                        <IconProgram size={18} className='mr-2' />
                        {`
                            ${moment(time)
                            .tz(zone)
                            .format('h:mma')} to ${moment(time)
                              .tz(zone)
                              .clone()
                              .add(30, 'minutes')
                              .format('h:mma')} |
                              ${moment(time).tz(zone).format('ll')} 
                              `}
                      </span>
                    }
                  />
                ))}

              <div
                className='h45 text-gray my-6'
                hidden={hideMoreOptions}
                onClick={() => moreOptions()}
              >
                See more
              </div>
            </>
          </TabPane>
        </TabContent>
        <StickyButton
          label='Continue'
          check
          disabled={!selectedTime}
          btnClassName='mb-2'
          className='mb-2 w-100'
          size='large'
          activity={loadScheduleMeetingMutation || loadingOneTask || loadSendToReviewMutation}
          onClickButtonFunction={() => {
            scheduleMeetingFunction()
          }}
          color='counseling'
        />
      </ModalLayout>
      <MessageModal
        isOpen={messageModalOpen}
        toggle={() => setMessageModalOpen(!messageModalOpen)}
        messageBody={message}
      />
    </>
  )
}

ShowScheduledMeeting.defaultProps = {
  isOpen: false,
  toggle: () => { },
}

ShowScheduledMeeting.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default ShowScheduledMeeting
