const constraints = {
  stripeInvoiceId: {
    presence: {
      allowEmpty: false
    }
  },
  reason: {
    presence: {
      allowEmpty: false
    }
  },
  amountToRefund: {
    numericality: {
      onlyInteger: false,
      greaterThanOrEqualTo: 0
    }
  },
}

export default constraints