import React, { useMemo } from 'react'
import {IconLoad} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../../../../../i18n/useTranslation'

const UnactiveUserMenu = (props) => {
    const {translate} = useTranslation()
    const { 
        onConfirm = () => {}, onCancel = () => {},
        beginText = translate('dashboard.studentWithoutLessons.listItem.unactiveUser') || 'Unactive user', 
        successText = translate('dashboard.studentWithoutLessons.listItem.studentUnactive') || 'Student unactive!', 
        errorText = translate('dashboard.studentWithoutLessons.listItem.error') || 'Error!', 
        loading = false, step = 'begin' /**oneOf([begin, confirm, success])**/ } = props
    const marginLeft = useMemo(() => {
        let margin = 0
        switch (step) {
            case 'begin':
                margin = 0
                break
            case 'confirm':
                margin = 100
                break
            case 'success':
                margin = 200
                break
            case 'error':
                margin = 300
                break
            default:
                break    
        }
        return margin
    }, [step])
    return (
        <div style={{ overflow: 'hidden', width: '120px' }}>
            <div className={`d-flex flex-row align-items-center`} style={{
                position: 'relative', width: `400%`,
                overflow: 'hidden', transition: '.3s',
                marginLeft: `-${marginLeft}%`
            }}>
                <div className={`w-25 h-100`}>
                    <div className={`d-flex flex-row justify-content-center align-items-center h-100`}>
                        <span className={`text-info h45`}>{beginText}</span>
                    </div>
                </div>
                <div className={`w-25`}>
                    <div className={`d-flex flex-column`}>
                        <span>{translate('dashboard.studentWithoutLessons.listItem.areYouSure') || 'Are you sure?'}</span>
                        <div className={`d-flex flex-row justify-content-end align-items-center`}>
                            <span className='text-error btn-link mr-2' onClick={() => {
                                onCancel()
                            }}>{translate('dashboard.studentWithoutLessons.listItem.no') || 'No'}</span>
                            {
                                loading && (
                                    <span className={`mr-1`}><IconLoad/></span>        
                                )
                            }
                            <span className='text-primary btn-link' onClick={() => {
                                onConfirm()
                            }}>{translate('dashboard.studentWithoutLessons.listItem.yes') || 'Yes'}</span>
                        </div>
                    </div>
                </div>

                <div className={`w-25`}>
                    <div className={`d-flex flex-row justify-content-center align-items-center`}>
                        <span className={`text-info h4 text-center`}>{successText}</span>
                    </div>
                </div>

                <div className={`w-25`}>
                    <div className={`d-flex flex-row justify-content-center align-items-center`}>
                        <span className={`text-error h4`}>{errorText}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnactiveUserMenu