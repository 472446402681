import React from 'react'
import useTranslation from '../../../i18n/useTranslation'
import {Avatar} from '@Knowledge-OTP/znk-ui-components'
import PasswordSignin from './Password'
import SocialButton from '../Signin/Social/SocialButton'

const FullPassword = (props) => {
  const {back, isEducator, onForgot} = props
  const {translate} = useTranslation()
  const storage = global.localStorage.getItem('loggedUser')
  const goBack = () => {
    global.localStorage.removeItem('loggedUser')
    back()
  }
  const parsedInfo = JSON.parse(storage) ?? {}
  const name = `${parsedInfo.firstName} ${parsedInfo.lastName}`
  const providers = parsedInfo.providers ?? []
  return (
    <>
      <div className='h1 font-weight-light text-logo'>
        {translate('login.signin.existing.welcome')}
      </div>
      <div className='h4 my-2 font-weight-light text-secondary'>
        {translate('login.signin.existing.description')}
      </div>
      <div className='my-3 p-3 znk-input h-auto d-flex align-items-center'>
        <div className='flex-grow-1 d-flex align-items-center'>
          <Avatar name={name} />
          <span className='ml-2'>{name}</span>
        </div>
        <div
          className='btn-link text-underline text-gray font-weight-light'
          onClick={goBack}
        >
          {translate('login.signin.existing.back')}
        </div>
      </div>
      {providers.map((providerId) => {
        switch (providerId) {
          case 'password':
            return (
              <PasswordSignin
                key={providerId}
                email={parsedInfo.email}
                onForgot={() => {
                  onForgot(parsedInfo.email)
                }}
              />
            )
          case 'google.com':
          case 'facebook.com':
            return (
              <SocialButton
                isEducator={isEducator}
                isSignin
                key={providerId}
                providerId={providerId}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}

export default FullPassword
