import React from 'react'
import StudentsFilter from '../../Universities/Filters/Students'

const ClassOfFilter = (props) => {
    const {filters = {}, setFilters = () => {}} = props
    return (
       <div className={`d-flex flex-row m-0 p-0`}>
            <StudentsFilter
                setFilters={(students) => {
                    setFilters({
                        ...filters, 
                        studentIds: students
                    })
                }}
                filters={filters?.studentIds || []}
            />
       </div> 
    )
}

export default ClassOfFilter