import React, {useMemo} from 'react'
import {
    IconArrowRight,
    ListItem,
    Avatar
} from '@Knowledge-OTP/znk-ui-components'
import {TabPane, TabContent} from 'reactstrap'
import RenderInputOption from '../../../Components/RenderInputOption'
import useAddCounselorModal from '../Modal/AddCounselorModal/useAddCounselorModal'

const Head = (props) => {
    const {className, plan, setData} = props
    const {open, close} = useAddCounselorModal()
    const {activeTab, councelorName, councelorId} = useMemo(() => {
        if(plan?.headData) return {
            activeTab: 'selected',
            councelorName: `${plan?.headData?.firstName} ${plan?.headData?.lastName}`,
            councelorId: plan.headData?.teamUserId || plan.headData?.id
        }
        return {activeTab: 'no-selected'}
        // eslint-disable-next-line
    }, [plan?.headData])

    const openAddCounselor = (defaultCounselors = []) => {
        open((counselors) => {
            if(counselors?.length > 0) setData({
                head: counselors[0].id,
                headData: {
                    ...counselors[0], teamUserId: counselors[0].id
                }
            })
            close()
        }, false, defaultCounselors)
    }

    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            {/* <span className={`h4 font-weight-bold text-title`}>Advisor</span> */}
            <RenderInputOption key={'planName'} className={'my-0'} label={null}>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'selected'}>
                        <ListItem
                            className='py-1 appears-animation'
                            id='x-0'
                            label={null}
                            onClick={() => {openAddCounselor([councelorId])}}
                            Left={
                                <div className='d-flex align-items-center'>
                                    <Avatar
                                        name={councelorName}
                                        named={false}
                                        uri={null}
                                        size='avatar-medium'
                                    />
                                    <label className='ml-2 h4'>
                                        {councelorName} <span className={`m-0 p-0 text-gray`}>(Head)</span>
                                    </label>
                                </div>
                            }
                            Right={
                                <div className={`hover-icon`}><IconArrowRight/></div>
                            }
                        />
                    </TabPane>
                    <TabPane tabId={`no-selected`}>
                        <span className={`btn-link`} onClick={openAddCounselor}>Add personal head</span>       
                    </TabPane>
                </TabContent>
            </RenderInputOption>
        </div>
    )
}

export default Head