import { ordenateUserArray } from "./ordenateUsersArray"

export const ordenateStaffArray = (team, staff, allStaffArray, setAllStaffArray) => { 
    if (team?.length < 1 && staff?.length < 1) return
    const teamArray = ordenateUserArray(team)
    const teamIdsArray = team?.map((user) => user.id)
    const staffWithoutTeam = staff?.filter((user) => !teamIdsArray.includes(user.id))
    const orderedStaff = staffWithoutTeam?.map((user) => {
      return {
        name: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
        id: user.id,
        avatar: user.avatar
      }
    }).sort((a, b) => {
      if (a.name > b.name) return 1
      if (a.name < b.name) return -1
      return 0
    })

    setAllStaffArray(teamArray?.concat(orderedStaff))
    return allStaffArray
  }

  export const notOrdenateUserArray = (array) => {
    return array
  }