import {gql, useLazyQuery} from '@apollo/client'
import queryLessonResult from '../../../../../../Model/QueryResult/useLessonResult'

const useGetRecurringLessons = () => {
    return useLazyQuery(
        gql`
        query (
            $recurringId: String!
        ) {
            getRecurringLessons (
                recurringId: $recurringId
            ) {
                ${queryLessonResult}
            }
        }
        `
    )
}

export default useGetRecurringLessons