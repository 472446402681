import {useReducer} from 'react'
import reducer from './reducer'
import initialState from './initialState'
import {PhasesKeys2, PhasesTitles} from '../template/initialState'
import useTimelineReducer from '../timeline'
import moment from 'moment'

const useCounselingPlanReducer = () => { //multi: una task o un arreglo de tasks
    const [state, dispatch] = useReducer(reducer, initialState)
    const {parseTimelineFromAPI} = useTimelineReducer()
    const actionData = (
        data, 
        key, //[status, ...] 
        action //[set]
        ) => {
        dispatch({
            type: `${action}-${key}`,
            data
        })
    }
    return {
        state, 
        dispatch,
        updateData: (data) => {
            actionData(data, 'data', 'update')
        },
        resetData: () => {
            actionData(initialState, 'data', 'reset')
        },
        parsePlanFromAPI: (data) => {
            const discount = data?.paymentItemsConfig?.length > 0 ? data?.paymentItemsConfig[0].percentDiscount : 0
            return {
                ...data,
                id: data?.id,
                kind: data?.kind,
                name: data?.name,
                studentUser: {
                    ...data?.studentUser,
                    studentInfo: {
                        ...data?.studentUser?.studentInfo,
                        graduationDate: data?.aditionalInfo?.applicationYear ? moment(parseInt(data.aditionalInfo.applicationYear)).toISOString() : moment().toISOString()
                    }
                },
                paymentStart: data?.paymentStart,
                payments: data?.paymentItemsConfig,
                pricingRate: data?.pricingRate,
                discount: parseFloat(discount),
                serviceId: data?.serviceId,
                from:  data?.from,
                to: data?.to,
                type: data?.type,
                taskTemplate: data?.taskTemplate,
                tasks: data?.tasks.map((phase) => {
                    return {
                        ...phase,
                        phaseName: PhasesTitles[PhasesKeys2[phase?.phaseName]] || phase?.phaseName,
                        title: PhasesTitles[PhasesKeys2[phase?.phaseName]] || phase?.phaseName,
                        phaseId: phase?.phaseName || phase?.phaseId,
                        statusBuild: 'normal',
                        steps: phase.steps.map((step) => {
                            return {
                                ...step,
                                idReference: step?.idReference, 
                                stepId: step?.id || step?.stepId,
                                statusBuild: (step?.deleteAt === null || !step?.deleteAt) ? 'normal' : 'delete',
                                title: step?.stepName,
                                tasks: step?.tasks?.map((task) => {
                                    return {
                                        ...task,
                                        idReference: task?.idReference,
                                        taskId: task?.id || task?.taskId,
                                        title: task?.taskName || task?.name,
                                        taskName: task?.name,
                                        statusBuild: (task?.deleteAt === null || !task?.deleteAt) ? 'normal' : 'delete'
                                    }
                                })
                            }
                        }),
                    }
                }),
                councelor: data?.councelor?.teamUserId,
                counselorData: data?.councelor,
                advisor: data?.advisor?.teamUserId,
                advisorData: data?.advisor,
                head: data?.head?.teamUserId,
                headData: data?.head,
                timeline: data?.timelines ? parseTimelineFromAPI(data?.timelines) : null,
                proposalVersion: data?.proposalVersion,
                studentGrade: data?.studentUser?.grade
            }
        },
        setPosition: (plan) => {
            return {
                ...plan,
                tasks: plan?.tasks?.map((phase, index) => {
                    return {
                        ...phase,
                        steps: phase?.steps?.map((step, stepIndex) => {
                            return {
                                ...step,
                                position: stepIndex,
                                tasks: step?.tasks?.map((task, indexTask) => {
                                    return {
                                        ...task,
                                        position: indexTask
                                    }
                                })
                            }
                        }),
                        position: index
                    }
                })
            }
        }
        
    }
}

export default useCounselingPlanReducer