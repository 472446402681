import React, {useState, useEffect, useMemo} from 'react'
import {
  IconArrowLeft,
  ListItem,
  UserBubble,
} from '@Knowledge-OTP/znk-ui-components'
import UserItems from '../UserItems'
import {useSelector} from 'react-redux'
import {db} from '../../fireBase'

const ActualUser = (props) => {

  const { usersOnChat = [], loading, collection, backArrow = () => {} } = props
  const {firstName, lastName, id: userID} = useSelector(
    (state) => state.user,
  )
  const eventID = collection?.taskId ? collection?.taskId : collection?.id

  const [onlineStatus, setOnlineStatus] = useState({})
  // eslint-disable-next-line
  // const [usersList, setUsersList] = useState([])

  //This use Effects brings the user status from Firebase
  useEffect(() => {
    db.collection('usersStatus')
      .doc(eventID)
      .collection(userID).doc('status')
      .onSnapshot(snapshot => {
        setOnlineStatus(snapshot.data())
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID, userID])
  
  //This use Memo filters the users that are not the current user
  const usersList = useMemo(() => {
    if(usersOnChat?.length > 0) {
      return usersOnChat.filter(user => user.id !== userID)
    } 
    return []
    // eslint-disable-next-line
  }, [usersOnChat])

  return (
    <div style={{position: 'sticky', top: '70px', backgroundColor: 'white'}}>
      <ListItem
        showBottomLine
        showTopLine
        classNameLeft='ellipsis-text'
        classNameRight='flex-grow-2'
        style={{height: '70px'}}
        Left={
          (!loading || true) && (
            <div className='d-flex flex-row align-items-center'>
              <div className={'hover-icon mr-1'} onClick={backArrow}><IconArrowLeft /></div>
              <UserBubble
                name={`${firstName} ${lastName}`}
                online={onlineStatus?.online}
                className='mr-2'
                toolTipProperties={{
                  referenceID: `userBubble${userID}`,
                  toolTipText: 'You',
                }}
              />
              <UserItems usersList={usersList} eventID={eventID}/>
            </div>
          )
        }
        Right={
          <div className='d-flex flex-row m-0 p-0'>
            {/* <IconArrowRight /> */}
          </div>
        }
      />
    </div>
  )
}

export default ActualUser
