import React, {useState, useMemo, useEffect} from 'react'
import {
    ModalLayout, 
    TemplateTimeline, 
    FileItem,
    Input,
    BlankSpace,
    IconSignPlus,
    IconEdit,
    Button,
} from '@Knowledge-OTP/znk-ui-components'
import 'animate.css'
import useTemplateReducer from '../../reducers/template'
import Skeleton from 'react-loading-skeleton'
import useNewStepModal from './Step/newStepModal/useNewStepModal'
import useNewTaskModal from './Task/newTaskModal/useNewTaskModal'
import useViewTaskModal from './Task/viewTaskModal/useViewTaskModal'
import useDeleteTemplateModal from './DeleteTemplateModal/useDeleteTemplateModal'
//mutations & queries
import useCreateTaskTemplate from './Model/useCreateTaskTemplate'
import useEditTaskTemplate from './Model/useEditTaskTemplate'
import useGetTasksTemplate from './Model/useGetTasksTemplate'
import useDeleteTaskTemplate from './Model/useDeleteTaskTemplate'
import ListOption from './Task/viewTaskModal/Components/ListOption'
import useCounselingTemplates from './useCounselingTemplates'
import useDeleteTaskModal from './Task/deleteTaskModal/useDeleteTaskModal'

import {TabPane, TabContent} from 'reactstrap'

import RenderTemplateItem from './Components/RenderTemplateItem'


const CounselingTemplates = (props) => {
    const {isOpen, toggle, templateData, forSelection} = useCounselingTemplates()
    
    const [isOpenRight, setOpenRight] = useState(false)
    const toggleRight = () => {setOpenRight(!isOpenRight)}
    
    //controlador modal para eliminar un template
    const {open: openDeleteTemplateModal} = useDeleteTemplateModal() 
    //controlador modal para crear/editar step
    const {open: openNewStepModal} = useNewStepModal()

    const {close: closeDeleteTaskModal} = useDeleteTaskModal()
    
    //template reducer
    const {
        state: template, 
        initialState, 
        addStep, 
        editStep, 
        addTask, 
        removeStep, 
        setData, 
        getTaskCount, 
        editTask,
        moveTask,
        removeTask,
        parseDataToMutation,
        parseTemplateFromApi,
        filterDelete,
        changeTemplateName,
        handlerTaskDragAndDrop
    } = useTemplateReducer()
    const newTemplateGenerator = () => {
        setInit(true)
        setData({...initialState, templateName: 'Nuevo template', deleteAt: null})
        setEditTemplate(true)
        if(!isOpenRight) toggleRight()
    }
    const [isInit, setInit] = useState(false) //determina si ya se ha comenzado 
    // a confeccionar o editar un template

    useEffect(() => {
        if(templateData) {
            const parseData = parseTemplateFromApi(templateData)
            //abre sección derecha
            if(!isOpenRight) toggleRight()
            //desactiva modo edición
            setEditTemplate(false)
            setData({...parseData, templateId: parseData?.templateId, templateName: parseData?.templateName, isEdit: true})
        }
        // eslint-disable-next-line
    }, [templateData])

    //template save state
    //const {state: templateSaveState, saveState, emptySlot} = useSaveState('counselingTemplate')
    ////aloja el saveState del template en reducer (objeto template)
    //useEffect(() => {
    //    if(templateSaveState) {setData(templateSaveState)}
    //    // eslint-disable-next-line
    //}, [templateSaveState])
    const RenderTemplateItemParse = ({templateData, isNew = false, unsaved, isEdit = false, keyItem}) => {
        const isCurrentTemplate = (templateData.id === template.id || !!unsaved) && !isNew
        return (
            <RenderTemplateItem
                keyItem={keyItem}
                templateData={templateData} 
                isNew={isNew} 
                unsaved={unsaved} 
                isEdit={isEdit} 
                isCurrentTemplate={isCurrentTemplate}
                onClick={() => {
                    if(!isOpenRight) toggleRight()
                    setInit(true)
                    if(isNew) newTemplateGenerator()
                    else {
                        //desactiva modo edición
                        setEditTemplate(false)
                        setData({...templateData, templateId: templateData?.templateId, templateName: templateData?.templateName, isEdit})
                    }
                }}
                taskCount={getTaskCount(isCurrentTemplate ? template : templateData || {})}
                showBottomLine
            />
        )
    }

    const {open: openNewTask, close: closeNewTask} = useNewTaskModal()
    const {open: openViewTask, close: closeViewTask} = useViewTaskModal()

    //mutación para crear template en BD
    const [createTaskTemplate, {loading: loadCreate}] = useCreateTaskTemplate((result) => {
        refetchTemplateList()
        setEditTemplate(false)
        setInit(false)

        //ACTUALIZAR TEMPLATE QUE ACABA DE SER CREADO
        const resultTemplate = result?.createTaskTemplate
        if(resultTemplate) setData(parseTemplateFromApi(resultTemplate))
    })
    //mutación para editar template en BD
    const [editTaskTemplate, {loading: loadEdit}] = useEditTaskTemplate((result) => {
        refetchTemplateList()
        setEditTemplate(false)
        setInit(false)

        //ACTUALIZAR TEMPLATE QUE ACABA DE SER EDITADO
        const resultTemplate = result?.editTaskTemplate
        if(resultTemplate) setData(parseTemplateFromApi(resultTemplate))
    })
    //mutación para eliminar template en BD
    const [deleteTaskTemplate] = useDeleteTaskTemplate(() => {
        refetchTemplateList()
        setEditTemplate(false)
        setInit(false) 
        //cierra sección derecha
        setOpenRight(false)   
    })
    
    //query para obtener listado de templates
    const {templates: templatesData, loading: templatesLoading, refetch: refetchTemplateList} = useGetTasksTemplate()
    const [isEditTemplate, setEditTemplate] = useState(false)
    //templates filtrados sin contar los eliminados
    const templates = templatesData.filter(({deleteAt}) => deleteAt === null)

    //actualiza la pantalla al lado izquierdo
    const leftTab = useMemo(() => {
        if(templatesLoading) return 'loading'
        if(
            templates?.length > 0 || 
            (!template.isEdit && isInit /**se comenzó a confeccionar un template**/)) return 'show'
        return 'blank'
    }, [templatesLoading, templates, template, isInit])

    return (
        <>
            <div onClick={toggle}>{props.children}</div>
            <ModalLayout
                className={`white-modal`}
                isOpen={isOpen}
                toggle={toggle}
                topHead={<span className={`h3 text-title`} onClick={toggleRight}>Templates</span>}
                underHead={null}
                options={[
                    {component: <span onClick={() => {
                        newTemplateGenerator()
                    }}>New template</span>}
                ]}
                minHead={<span className={`h3 text-title`}>Templates</span>}

                sideRight={
                    <div className={`d-flex flex-column`}>
                        <div className={`d-flex flex-column`}>
                            <span className={`h3 text-title`}>New Counseling templates</span>
                            <div className={`d-flex flex-row`}>
                                {/**<span 
                                    className={`h45 btn-link text-primary ml-2 cursor-pointer`}
                                    onClick={() => {
                                        console.log("saveState(template)", template)
                                        saveState(template)
                                    }}
                                    >Guardar</span>
                                
                                    templateSaveState && (
                                        <>
                                            <span 
                                                className={`h45 btn-link text-error ml-2 cursor-pointer`} 
                                                onClick={() => {
                                                    //setData(templateSaveState)
                                                }}
                                                >Cargar</span>
                                            {
                                                true && (
                                                    <span 
                                                        className={`h45 btn-link text-error ml-2 cursor-pointer`} 
                                                        onClick={() => {
                                                            //vacía el slot de guardado
                                                            emptySlot()
                                                            //reinicializa template
                                                            newTemplateGenerator()
                                                        }}
                                                        >Eliminar</span>
                                                ) 
                                            }    
                                        </>
                                    )
                                **/}
                            </div>
                        </div>
                            <div className={`d-flex flex-column my-2`}>
                                <span className={`h45 text-gray mb-2`}>Template name:</span>
                                <Input 
                                    type={'text'} 
                                    placeholder={`Template name`} 
                                    value={template.templateName} 
                                    onChange={(e) => {
                                        changeTemplateName(e.target.value)
                                    }}
                                    disabled={!isEditTemplate}
                                />
                            </div>
                            <TemplateTimeline
                                className='w-100'
                                disableTooltip
                                isEdit={isEditTemplate}
                                onDelete={({phaseId, stepId}) => {
                                    removeStep({phaseId, stepId})
                                }}
                                onEdit={({phaseId, stepId, stepName, phaseTitle}) => {
                                    openNewStepModal((newStepName) => {
                                        //callback
                                        editStep({stepName: newStepName, title: newStepName, phaseId, stepId, statusBuild: 'edit'}) 
                                    }, stepName, phaseTitle)
                                }}
                                onCreate={({phaseId, title}) => {
                                    openNewStepModal((newStepName) => {
                                        //callback
                                        addStep({stepName: newStepName, title: newStepName,  phaseId})
                                    }, null, title)
                                }}
                                onCreateTask={({phaseId, stepId}) => {
                                    openNewTask(
                                        (newTask) => {
                                            //callback
                                            addTask({...newTask, title: newTask?.taskName, phaseId, stepId, isNew: true/** isNew para que task se muestre con animación **/})
                                            closeNewTask()
                                        }
                                    )
                                }}
                                onClickTask={(task, {phaseId, stepId}) => {
                                    openViewTask({...task, phaseId, stepId, isEdit: isEditTemplate}, (action, newTask) => {
                                        //callback
                                        //action : edit, duplicate y delete
                                        //newTask: estado de la task dentro del modal
                                        switch(action){
                                            case 'edit':
                                                editTask({...newTask, phaseId, stepId, title: newTask?.taskName || 'No defined'})
                                                //cierra formulario de task
                                                closeNewTask()
                                                break
                                            case 'delete':
                                                removeTask({...newTask, phaseId, stepId})
                                                //cierra modal de confirmación
                                                closeDeleteTaskModal()
                                                //cierra modal para visualizar task
                                                closeViewTask()
                                                break
                                            case 'duplicate':
                                                addTask({...newTask, phaseId, stepId, title: newTask?.taskName || 'No defined', isNew: true })
                                                break
                                            default: 
                                                return
                                        }
                                            
                                    })
                                }}
                                onDropTask={(env) => {
                                    handlerTaskDragAndDrop(env, moveTask)
                                }}
                                stages={filterDelete(template).phases}
                            />
                        {
                            !isEditTemplate ? (
                                <div className={`m-0 p-0 d-flex flex-column mt-3`}>
                                    <ListOption 
                                        onClick={() => {setEditTemplate(true)}}
                                        showBottomLine
                                        showTopLine
                                        Icon={IconEdit}
                                        status={'title'}
                                        left={'Edit Template'}
                                    />
                                    <ListOption
                                        onClick={() => {
                                            //abrir modal para borrar template
                                            openDeleteTemplateModal(
                                                () => {
                                                    deleteTaskTemplate({
                                                        variables: {templateId: template.id || template.templateId}
                                                    }) 
                                                }
                                            )
                                        }} 
                                        Icon={IconSignPlus}
                                        status={'danger'}
                                        left={'Delete Template'}
                                        showBottomLine={forSelection}
                                    />
                                    {forSelection && (
                                        <ListOption
                                            onClick={() => {
                                                //abrir modal para borrar template
                                                alert('seleccionar')
                                            }} 
                                            Icon={IconSignPlus}
                                            status={'danger'}
                                            left={'Select Template for plan'}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div className={`d-flex flex-row justify-content-end`}>
                                    <Button
                                        size={'small w-25'}
                                        activity={loadEdit || loadCreate}
                                        onClick={() => {
                                            if(template?.isEdit) {
                                                //encuentra la data original del template
                                                let templateData = templates.find(
                                                    ({id}) => id === template.templateId
                                                )
                                                //parsea objeto template al formato del reduce
                                                templateData = parseTemplateFromApi(templateData)
                                                setData({
                                                    ...templateData, 
                                                    templateId: templateData?.templateId, 
                                                    id: templateData?.templateId, 
                                                    templateName: templateData?.templateName,
                                                    name: templateData?.templateName, 
                                                    isEdit: true
                                                })
                                                //desactiva el modo edición
                                                setEditTemplate(false)
                                            }
                                            else newTemplateGenerator() //genera un nuevo template
                                            
                                            
                                        }} 
                                        label={'Cancel'} color={`error`} />
                                    <Button
                                        size={'small w-25 ml-2'}
                                        activity={loadEdit || loadCreate}
                                        onClick={() => {
                                            const variables = parseDataToMutation(template, template?.isEdit, true)
                                            if(template?.isEdit) editTaskTemplate({variables})
                                            else createTaskTemplate({variables})
                                        }} 
                                        label={template?.isEdit ? 'Save' : 'Create'} color={`primary`} />
                                </div>
                            )
                        }
                    </div>
                }
                toggleRight={toggleRight}
                useRightSection
                isOpenRight={isOpenRight}
            >
                <TabContent className={`h-100`} activeTab={leftTab}>
                    <TabPane tabId={'loading'}>
                        <Skeleton count={10} height={50} className={'w-100'}/>
                    </TabPane>
                    <TabPane tabId={'blank'} className={`h-100`}>
                        <BlankSpace
                            className={`w-100 h-100`}
                            icon={
                                <FileItem
                                    onClick={newTemplateGenerator}
                                    hoverEffect
                                    icon={
                                        <div className={`d-flex flex-column align-items-center mt-2`}>
                                            <IconSignPlus className={`rotate-45`}/>
                                            <span className={`text-white`}>New</span>
                                        </div>
                                    } 
                                    color={'primary'} 
                                    size={'medium'}/>
                            }
                            linkProps={{
                                text: <span className={`btn-link`}>Go to create new template</span>,
                                onClick: newTemplateGenerator,
                            }}
                            mainText='Here you can see the template`s list.'
                        />
                    </TabPane>
                    <TabPane tabId={'show'}>
                        <RenderTemplateItemParse key={-2} keyItem={-2} templateData={{templateId: "", templateName: "Create new template"}} isNew/>
                        {
                            /**Si hay registro de un template en curso y sin guardar lo muestra 
                             * en un estado diferente**/
                            template && !template?.isEdit && isInit && (
                                <RenderTemplateItemParse key={-1} keyItem={-1} templateData={template} unsaved />
                            )
                        }
                        {
                            /**listado de templates
                             *  contando el que se edita en este minuto**/
                            templates.map((templateItem, index) => {
                                // const templateSelected = template?.id === templateItem?.id
                                // if(templateSelected) console.log("template", template)
                                return (
                                    <RenderTemplateItemParse 
                                        key={index}
                                        keyItem={index}
                                        templateData={
                                            // filterDelete(
                                            templateItem?.id === template?.id ?
                                                template :
                                                parseTemplateFromApi(templateItem)
                                            // )
                                        } 
                                        isEdit 
                                    />
                                )
                            })
                        }
                    </TabPane>
                </TabContent>
            </ModalLayout>
        </>
    )
}

export default CounselingTemplates