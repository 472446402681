import React from 'react'
import { CheckboxSelector, Avatar, IconArrowDown, CheckboxSelectorInner } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import { Badge } from 'reactstrap'

const EducatorFilter = (props) => {
  const { translate } = useTranslation()
  const { students, studentFilters, setStudentFilters, loading, useInner = false } = props
  // const handledInput = (value) => {
  //   onChangeInput(value)
  // }

  const checkboxSelectorProps = {
    setDataFilters: setStudentFilters,
    direction: 'bottomLeft',
    dataFilters: studentFilters,
    loading: loading,
    data: students.map(user => ({
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,
      Component: (
        <Avatar
          name={`${user.firstName} ${user.lastName}`}
          named={false}
          uri={null}
          size='avatar-small'
        />
      )
    })).sort((a, b) => a.name.localeCompare(b.name)),
    typeFilter: 'users',
    strings: {
      viewAll: translate('filters.plans.viewAll'),
      buttonCancel: translate('filters.plans.buttonCancel'),
      buttonApply: translate('filters.plans.buttonApply'),
      search: translate('filters.plans.search')
    }
    // onChangeInput={handledInput}
  }

  return (
    useInner ?
    <CheckboxSelectorInner style={{maxWidth: 'auto'}} {...checkboxSelectorProps}/> :
    <CheckboxSelector {...checkboxSelectorProps}>
      <Badge className='mx-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.plans.educatorsTitle')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </CheckboxSelector>
  )
}
export default EducatorFilter
