import React from 'react'
import StudentFilter from '../Filters/Students'

const UniversityFilters = (props) => {
    const {setFilters, filters} = props
    return (
        <div className={`m-0 p-0`}>
            <StudentFilter setFilters={
                (studentIds) => {
                    setFilters({
                        ...filters,
                        studentIds
                    })
                }
            } filters={filters.studentIds || []} />
        </div>
    )
}

export default UniversityFilters