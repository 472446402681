import React, { useState, useEffect, useMemo } from 'react'
import {
  Input,
  CheckboxOption,
  RadioOption,
  Field,
  IconArrowDown,
  IconArrowUp,
  ListItem,
  PlainTextInput,
  InputAndButtons
} from '@Knowledge-OTP/znk-ui-components'
import { Collapse } from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'
import useLazyGetRecurringLessons from '../InfoLesson/Components/RecurringLessons/useLazyGetRecurringLessons'
import moment from 'moment-timezone'

const ScheduleDetails = (props) => {
  const { form, setForm, isEdit, editPending} = props
  const { translate, replaceVariables } = useTranslation()
  const [isOpenCollapse, setOpenCollapse] = useState(isEdit || false)
  useEffect(() => {
    if (isEdit) {
      setForm({
        ...form,
        current: true,
        future: false,
        totalLessonPending: 0
      })
    } else {
      setForm({
        ...form,
        repeat: true,
        totalLessonPending: 0
      })
    }
    // eslint-disable-next-line
    }, [isEdit])


  const lessonDuration = useMemo(() => {
    if(isEdit || true) {
      let momentStart = moment().set({
        hour: parseInt(form?.timeStart?.split(':')[0]),
        minutes: parseInt(form?.timeStart?.split(':')[1]),
        seconds: 0
      })
      let momentEnd = moment().set({
        hour: parseInt(form?.timeEnd?.split(':')[0]),
        minutes: parseInt(form?.timeEnd?.split(':')[1]),
        seconds: 0
      })
      let duration = form?.timeEnd 
        ? moment.duration(momentEnd.diff(momentStart)).asMinutes() 
        : 50
          
      return parseInt(duration)
    } 
    return 50
    // eslint-disable-next-line
  }, [isEdit, form.timeEnd])  
  useEffect(() => {
    if (form.timeStart) {
      // autofill timeEnd
      setForm({
        ...form,
        timeEnd: moment().set({
          hour: parseInt(form?.timeStart?.split(':')[0]),
          minutes: parseInt(form?.timeStart?.split(':')[1]),
          seconds: 0
        }).add(lessonDuration, 'minutes').format('HH:mm')
      })
    }
    // eslint-disable-next-line
  }, [form.timeStart, lessonDuration])  

  const [dateHasChange, setDateHasChange] = useState(false)
  const [getRecurringLesson, {data: recurringLesson}] = useLazyGetRecurringLessons()
  useEffect(() => {
    //EJECUTA LA QUERY PARA OBTENER RECURRENCIAS
    if(form?.recurringId && form?.id) {
      getRecurringLesson({
        variables: {recurringId: form.recurringId}
      })
    }
    // eslint-disable-next-line
  }, [form])
  const recurringDaysError = useMemo(
    () => {
      if(recurringLesson) {
        //verifica si hay alguna lección que no esté en el 
        //mismo día de la semana que el resto
        const recurrings = recurringLesson?.getRecurringLessons || []
        const thisLesson = recurrings.find(({id}) => id === form.id)
        //solo desde la lección actual
        const recurringsDaysToEdit = recurrings.reduce((newArray, lesson) => {
          const {startDate} = lesson
          if(
            (moment(startDate) > moment(thisLesson.startDate) && lesson.status !== 'pending') || lesson.id === thisLesson.id 
          ) {
            newArray.push(
              moment(lesson.startDate).isoWeekday()
            )
          }
          return newArray
        }, [])
        return recurringsDaysToEdit.reduce(
          (bool, dayOfWeek) => {
            //busca si alguna lección está en una día de la semana diferente a la original
            if(dayOfWeek !== moment(thisLesson.startDate).isoWeekday()) return true
            return bool
          }, false
        )
      }
      return false
    },
    // eslint-disable-next-line
    [recurringLesson]
  )
  return (
    <>
      <div className='p-0 m-0'>
        <h3><b>{translate('modal.newlesson.scheduledetails')}</b></h3>
        <div className='row pl-3'>
          {
            !form.setPending &&
            (
              <>
                <label className='col-12 text-muted mb-2 mt-3 pl-0'>
                  {translate('modal.newlesson.date')}
                </label>
                <Input
                  type='date' name='date' id='date'
                  value={form.date}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      date: e.target.value
                    })
                    setDateHasChange(true)
                  }}
                />
                {
                  isEdit && (
                    <Collapse isOpen={form.future && dateHasChange}>
                      <div className={'m-0 px-0 pt-4 pb-2 pl-3'}>
                        
                        <label className={`text-error`}>
                          {
                            recurringDaysError ?
                              translate('modal.newlesson.lessonondifferentday')/**There is a lesson on a different day of the week!**/ :
                              replaceVariables(translate('modal.newlesson.changethedate'), {'DAY': moment(form.date).format('dddd') })/**You change the date**/
                          }
                        </label>
                        <div className='d-flex flex-row'>
                          {/* aplica cambio de fecha a todas las recurrencias */}
                          <Field
                            type={CheckboxOption}
                            fieldName='applyChangeDate'
                            checked={form.applyChangeDate}
                            onChange={(e) => {
                              setForm({...form, applyChangeDate: e})
                            }}
                          />
                          <span>{translate('modal.newlesson.applychangetoall')}</span>
                        </div>
                      </div>
                    </Collapse>
                  )
                }
                <div className='mx-0 my-3 p-0 d-flex flex-row w-100'>
                  <div className='d-flex flex-column w-50'>
                    <span className='p-0 text-gray'>
                      {translate('modal.newlesson.start')}
                    </span>
                    <Input
                      className='noRight m-0'
                      type='time' name='timeStart' id='timeStart'
                      value={form.timeStart}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          timeStart: e.target.value
                        })
                      }}
                    />
                  </div>
                  <div className='d-flex flex-column w-50'>
                    <span className='p-0 text-gray'>
                      {translate('modal.newlesson.end')}
                    </span>
                    <Input
                      className='noLeft m-0'
                      type='time' name='timeEnd' id='timeEnd'
                      value={form.timeEnd}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          timeEnd: e.target.value
                        })
                      }}
                    />
                  </div>
                </div>
                {
                  !isEdit &&
                  (
                    <ListItem
                      className='m-0 px-0 py-1 col-12'
                      onClick={() => { setOpenCollapse(!isOpenCollapse) }}
                      Left={<span>{translate('modal.newlesson.schedulingoptions')}</span>}
                      Right={isOpenCollapse ? <IconArrowUp /> : <IconArrowDown />}
                    />
                  )
                }
              </>
            )
          }
          <Collapse isOpen={isOpenCollapse} className='col-12' style={form?.isMeeting ? {display: 'none'} : {}}>
            {
              (!isEdit)
                ? (!form.setPending)
                  ? (
                    <>
                      <div className='d-flex flex-row mt-5'>
                        <Field
                          type={CheckboxOption}
                          fieldName='repeat'
                          checked={!form.repeat}
                          onChange={(e) => {
                            let obj = {
                              repeat: e,
                              repeatweek: false,
                              repeatmonth: false
                            }
                            if ( e ) obj.repeatweek = true
                            setForm({
                              ...form,
                              ...obj
                            })
                          }}
                        />
                        <span>{translate('modal.newlesson.dontrepeat')}</span>
                      </div>
                      <Collapse isOpen={form.repeat}>
                        <div className='d-flex flex-row mt-5'>
                          <Field
                            type={RadioOption}
                            fieldName='repeatweek'
                            checked={form.repeatweek}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                repeatmonth: !e,
                                repeatweek: e
                              })
                            }}
                          />
                          <span>{translate('modal.newlesson.repeatlessonweek')}</span>
                        </div>
                        <div className='d-flex flex-row mt-5'>
                          <Field
                            type={RadioOption}
                            fieldName='repeatmonth'
                            checked={form.repeatmonth}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                repeatmonth: e,
                                repeatweek: !e
                              })
                            }}
                          />
                          <span>{translate('modal.newlesson.repeatlessonmonth')}</span>
                        </div>
                      </Collapse>
                      {
                        (form.repeatmonth || form.repeatweek) && (
                          <div className='my-5'>
                            <label className='text-gray'>{translate('modal.newlesson.repearlessonuntil')}</label>
                            <Field
                              type={PlainTextInput}
                              fieldName='repeatuntil'
                              fieldType='date'
                              checked={form.repeatuntil}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  repeatuntil: e
                                })
                              }}
                            />
                          </div>
                        )
                      }
                      <div className='borderTop my-3' />
                    </>
                  )
                  : (null)
                : (
                  <>
                    <label className='text-gray mb-0 mt-3'>{translate('modal.newlesson.applychangesto')}</label>
                    <div className='d-flex flex-row mt-3'>
                      <Field
                        type={RadioOption}
                        fieldName='current'
                        checked={form.current}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            current: e,
                            future: false
                          })
                        }}
                      />
                      <span>{translate('modal.newlesson.current')}</span>
                    </div>
                    {
                      form?.recurringId && (
                        <div className='d-flex flex-row mt-5'>
                          <Field
                            type={RadioOption}
                            fieldName='future'
                            checked={form.future}
                            disabled={false}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                current: false,
                                future: e
                              })
                            }}
                          />
                          <span>{translate('modal.newlesson.currentfuture')}</span>
                        </div>
                      )
                    }
                    <div className='borderTop my-3' />
                  </>
                )
            }
            {
              !form.setPending && (
                <div className='d-flex flex-row mt-5'>
                  <Field
                    type={CheckboxOption}
                    fieldName='lockRes'
                    checked={form.lockRes}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        lockRes: e
                      })
                    }}
                  />
                  <span>{translate('modal.newlesson.lockrescheduling')}</span>
                </div>
              )
            }
            <div className='d-flex flex-row mt-5'>
              <Field
                type={CheckboxOption}
                fieldName='setPending'
                disabled={editPending || false}
                checked={form.setPending}
                onChange={(e) => {
                  setForm({
                    ...form,
                    setPending: e
                  })
                }}
              />
              <span>{translate('modal.newlesson.setlessonpending')}</span>
            </div>
            {(form.setPending && !isEdit) && (
              <div>
                <InputAndButtons
                  className='w-50 mt-4 mb-0'
                  title=''
                  defaultValue={0}
                  setForm={setForm}
                  form={form}
                  inputName='totalLessonPending'
                  customSetForm={false}
                />
              </div>
            )}
          </Collapse>
        </div>
      </div>
    </>
  )
}

export default ScheduleDetails
