import { Avatar, IconArrowRight, ListItem } from '@Knowledge-OTP/znk-ui-components'
import React, { Fragment } from 'react'
import useTranslation from '../../../../i18n/useTranslation'
import SelectCounselor from '../../../Plans/modal/newPlan/SelectCounselor'

const Counselor = (props) => {
    const { form, setForm, className } = props
    const { translate } = useTranslation()
    return (
        <Fragment>
            <div className={`m-0 p-0 ${className}`}>
                <h3 className='mb-3'><b>Counselor</b></h3>
                <SelectCounselor
                    form={form}
                    setEducator={(edu) => {
                        setForm({
                          ...form,
                          educator: edu,
                        })
                      }}
                      educator={form.educator || {}}
                >
                    {
                        form.educator
                        ? <ListItem
                            className='py-1'
                            id='x-0'
                            label={null}
                            Left={
                                <div className='d-flex align-items-center'>
                                <Avatar
                                    name={`${form.educator.firstName} ${form.educator.lastName}`}
                                    named={false}
                                    uri={null}
                                    size='avatar-medium'
                                />
                                <label className='ml-2 h4'>
                                    {`${form.educator.firstName} ${form.educator.lastName}`}
                                </label>
                                </div>
                            }
                            Right={<IconArrowRight />}
                            />
                        : <label className='btn-link'>{translate('modal.newlesson.selecteducator')}</label>
                    }
                </SelectCounselor>
            </div>
        </Fragment>
    )
}

export default Counselor