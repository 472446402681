import React, {useMemo} from 'react'
import LazyAvatar from '../../../../../common/LazyAvatar'

const EducatorField = (props) => {
    const {lesson, ...otherProps} = props
    const educator = useMemo(
        () => {
            return lesson?.substitute || lesson?.educator || {}
        }, [lesson]
    )
    return (
        <span {...otherProps}>
            <LazyAvatar className={`mr-2`} size={`avatar-small`} userId={educator?.educatorUserId} name={`${educator?.firstName} ${educator?.lastName}`} />
            {`${educator?.firstName} ${educator?.lastName?.substring(0,1)}.`}</span>
    )
}

export default EducatorField