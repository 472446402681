import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import useSetMyUniversity from '../../../../../University/Model/useSetMyUniversity'
import useTranslation from '../../../../../../i18n/useTranslation'
const ShowUniversityComponent = (props) => {
  const {
    // submit: addUniversity,
    // loading,
    data,
  } = useSetMyUniversity(() => {})
  const internationalNumberFormat = new Intl.NumberFormat('en-US')
  const noInfo = 'No info'
  const {university, universityId /** , addUniversity */} = props
  const [universityData, setUniversityData] = useState(null)
  const refetchUserData = useSelector((state) => state.user.refetch)
  const {translate} = useTranslation()
  const [render, setRender] = useState(false)
  /* eslint-disable */
    useEffect(() => {
        setUniversityData(university)
    }, [university])
    useEffect(() => {
        if (data) {
            refetchUserData()
            setRender(!render)
        }
    }, [data])
    /* eslint-enable */
  useEffect(() => {}, [universityId, data])
  return (
    <>
      <div className='statistics-information'>
        <div className='d-flex flex-row'>
          <div className='w-50'>
            <div className='title mb-2 h45'>
              {translate('counseling.acceptanceRate')}
            </div>
            <div className='sub-title'>
              {universityData?.acceptanceRate
                ? Math.round(universityData?.acceptanceRate * 100) + '%'
                : noInfo}
            </div>
          </div>
          <div className='w-50'>
            <div className='title mb-2 h45'>
              {translate('counseling.graduationRate')}
            </div>
            <div className='sub-title'>
              {universityData?.fourYearGraduationRate
                ? Math.round(universityData?.fourYearGraduationRate * 100) + '%'
                : noInfo}
            </div>
          </div>
        </div>
        <div className='d-flex flex-row'>
          <div className='w-50'>
            <div className='title mb-2 h45'>
              {translate('counseling.satAvg')}
            </div>
            <div className='sub-title'>
              {universityData?.satAverage ? universityData?.satAverage : noInfo}
            </div>
          </div>
          <div className='w-50'>
            <div className='title mb-2 h45'>
              {translate('counseling.actAvg')}
            </div>
            <div className='sub-title'>
              {universityData?.actCumulativeMidpoint
                ? universityData?.actCumulativeMidpoint
                : noInfo}
            </div>
          </div>
        </div>
        <div className='d-flex flex-row'>
          <div className='w-50'>
            <div className='title mb-2 h45'>
              {translate('counseling.numYears')}
            </div>
            <div className='sub-title'>
              {universityData?.typeYear ? universityData?.typeYear : noInfo}
            </div>
          </div>
          <div className='w-50'>
            <div className='title mb-2 h45'>
              {translate('counseling.avgCost')}
            </div>
            <div className='sub-title'>
              {universityData?.avgCostOfAttendance
                ? '$' +
                  internationalNumberFormat.format(
                    universityData?.avgCostOfAttendance,
                  )
                : noInfo}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ShowUniversityComponent
