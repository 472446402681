import { useDispatch, useSelector } from 'react-redux'

const useNewTaskModal = () => {
    const dispatch = useDispatch()
    //Para que hook funciones debe haber solo UN componente NewTaskModal 
    // renderizado en el dominio
    const reduxData = useSelector((state) => state.modal.counseling.newTaskModal)
    const {isOpen} = reduxData
    const setOpen = (open, edit = false, taskData = {}, onChange = () => {}, useMutation = false, loading = false, completedTask = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.newTaskModal',
            data: {
                ...reduxData,
                isOpen: open,
                isEdit: edit,
                taskData,
                onChange,
                loading,
                useMutation, //indica si al enviar el formulario debe ejecutarse la mutación 
                //para crear la tarea (vinculada a un plan). TaskData debe contener planId, phaseId y stepId
                completedTask
            }
        })
    }
    const setLoad = (load = false) => {
        dispatch({
            type: 'SET_DATA_MODAL_FORM_LIST',
            payload: 'counseling.newTaskModal',
            data: {
                ...reduxData,
                loading: load,
            }
        })
    }
    return {
        ...reduxData,
        open: (onChange, taskData = {}, useMutation = false, completedTask = false) => {
            setOpen(true, false, taskData, onChange, useMutation, false, completedTask)
        },
        close: () => {
            setOpen(false)
        },
        toggle: () => {
            setOpen(!isOpen)
        },
        openToEdit: (taskData = {}, onChange = () => {}, useMutation = false) => {
            setOpen(true, true, taskData, onChange, useMutation)
        },
        setLoad
    }
}

export default useNewTaskModal