import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
    IconArrowRight, ModalLayout,
    useWindowDimensions, ListItem, IconArrowLeft, IconSignPlus
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import useTranslation from '../../../../i18n/useTranslation'
import StudentsFilter from '../Students'
import ParentsFilter from '../Parent'
import StartDateFilter from '../../../Plans/Filters/StartDate'
import StatusesFilter from '../Statuses'
import TypesFilter from '../Types'
const ModalFilterMobile = (props) => {
    const {
        setQuery: setQueryProp = () => { }, query = {},
        resetFilters = () => { }, canReset = false,
    } = props
    const setQuery = useCallback(
        (newQuery) => {
            setQueryProp(newQuery)
            setOpen(false)
        }, [setQueryProp]
    )
    const [isOpen, setOpen] = useState(false)
    const toggle = () => setOpen(!isOpen)
    const { breakWidth } = useWindowDimensions()
    const baseStatuses = ['pendingPayment', 'paid', 'unPaid', 'canceled']
    const baseTypes = ['livePrep', 'counseling']

    const { translate } = useTranslation()
    const Pages = {
        filters: { key: 'filters', name: 'Filters' },
        statuses: { key: 'statuses', name: translate('filters.payments.status.title') },
        students: { key: 'students', name: translate('filters.payments.students.title') },
        parents: { key: 'parents', name: translate('filters.payments.parents.title') },
        type: { key: 'type', name: translate('filters.payments.type.title') },
        date: { key: 'date', name: translate('filters.payments.date.title') },
    }

    const [page, setPage] = useState(Pages.filters.key)
    useEffect(() => { if (!isOpen) setTimeout(() => setPage(Pages.filters.key), 1000) }, [isOpen, Pages])

    const modalTitle = useMemo(() => Object.values(Pages).find(({ key }) => key === page)?.name, [page, Pages])
    const listItemClassName = 'w-100 hover-no-border py-2'
    return (
        <>
            <div className='m-0 p-0' onClick={toggle}>
                {props.children}
            </div>
            <ModalLayout
                className={`${breakWidth === 'SM' ? 'modal-botbar' : 'modal-centered'} modal-hauto modal-noborder-bottom`}
                disabledScrollAnimation={false}
                isOpen={isOpen}
                toggle={toggle}
                topHead={
                    <div className={`d-flex flex-row align-items-center`}>
                        {
                            Pages.filters.key !== page &&
                            <div className='hover-icon' onClick={() => setPage(Pages.filters.key)}>
                                <IconArrowLeft size={20} className={'text-disabled'} />
                            </div>
                        }
                        <span className={`h2 text-title m-0`}>{modalTitle}</span>
                    </div>
                }
            >
                <TabContent activeTab={page}>
                    <TabPane tabId={Pages.filters.key}>
                        <div className='m-0 p-0 d-flex flex-column'>
                            <ListItem
                                onClick={() => { setPage(Pages.statuses.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.statuses.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.students.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.students.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.parents.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.parents.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.type.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.type.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.date.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.date.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            {
                                canReset && (
                                    <ListItem
                                        onClick={resetFilters}
                                        className={listItemClassName}
                                        hasHover
                                        Left={<span className='text-error'>{translate('misc.cleanFilters')}</span>}
                                        Right={<div className='hover-icon'><IconSignPlus className={'rotate-90 text-error'} /></div>}
                                        showBottomLine
                                    />
                                )
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId={Pages.statuses.key}>
                        <StatusesFilter {...{
                            statuses: baseStatuses,
                            statusFilters: query?.status,
                            setStatusFilters: (status) => {
                                setQuery({
                                    ...query, status
                                })
                            },
                            useInner: true
                        }} />
                    </TabPane>
                    <TabPane tabId={Pages.students.key}>
                        <StudentsFilter
                            {...{
                                studentFilters: query?.userId,
                                setStudentFilters: (userId) => {
                                    setQuery({
                                        ...query, userId
                                    })
                                },
                                useInner: true
                            }}
                        />
                    </TabPane>
                    <TabPane tabId={Pages.parents.key}>
                        <ParentsFilter
                            {...{
                                studentFilters: query?.userId,
                                setStudentFilters: (userId) => {
                                    setQuery({
                                        ...query, userId
                                    })
                                },
                                useInner: true
                            }}
                        />
                    </TabPane>
                    <TabPane tabId={Pages.date.key}>
                        <StartDateFilter
                            startDateFilter={[query?.from, query?.to]}
                            setStartDateFilter={(startDateFilter) => {
                                const from = startDateFilter[0] !== undefined ? startDateFilter[0] : undefined
                                const to = startDateFilter[1] !== undefined ? startDateFilter[1] : undefined
                                setQuery({ ...query, from, to })

                            }}
                            typeFilter='Date'
                        />
                    </TabPane>
                    <TabPane tabId={Pages.type.key}>
                        <TypesFilter {...{
                            types: baseTypes,
                            typesFilters: query?.category || [],
                            setTypesFilters: (category) => {
                                setQuery({ ...query, category })
                            },
                            useInner: true
                        }} />
                    </TabPane>
                </TabContent>
            </ModalLayout>
        </>
    )
}


export default ModalFilterMobile