import React, { useEffect, useState, useMemo } from 'react'
import {
  ListItem,
  Field,
  SelectOption,
  CheckboxOption,
  IconOutLine,
  AmountInput
} from '@Knowledge-OTP/znk-ui-components'
import AddDiscount from './AddDiscount'
import useTranslation from '../../../../../../../i18n/useTranslation'
import { getTotalLessons, getFlatFeeAmounts } from '../../utils'
import { TYPEFIXED } from '../../../../../const'
import { getFeeFromRangeDate, getRangeDate } from '../../utils'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
const OTHERFIXED = [
  { key: 'registration', value: 200 },
  { key: 'exam', value: 50 },
  { key: 'pro', value: 50 }
]

const PricingPlan = (props) => {
  const { form, setForm = () => { }, isEdit = false, disabled = false } = props
  const { translate } = useTranslation()
  const [totalLessons, totalGroupLessons] = useMemo(
    () => getTotalLessons(form), 
    //eslint-disable-next-line
    [form?.lpw]
  )


  const [groupFlatFee, indivFlatFee] = getFlatFeeAmounts(form)
  const [discount, setDiscount] = useState(0)
  const checkBoxObject = useMemo(() => {
    const [totalLessons, totalGroupLessons] = getTotalLessons(form)
    return {
      registration: {
        value: form.payments.values.registration,
        valueWithDiscount: form.payments.values.registration - (form.payments.values.registration * (form.payments.discount.registration / 100))
      },
      exam: {
        value: form.payments.values.exam,
        valueWithDiscount: form.payments.values.exam - (form.payments.values.exam * (form.payments.discount.exam / 100))
      },
      pro: {
        value: form.payments.values.pro,
        valueWithDiscount: form.payments.values.pro - (form.payments.values.pro * (form.payments.discount.pro / 100))
      },
      groupLessons: { value: form.typePlanPricing === 'permonth' ? groupFlatFee : (totalGroupLessons * (form.groupAmount && form.groupAmount > 0 ? form.groupAmount : 0)) },
      lessons: { value: form.typePlanPricing === 'permonth' ? indivFlatFee : (totalLessons * (form.amount && form.amount > 0 ? form.amount : 0)) }
    }
  }, 
  //eslint-disable-next-line
  [JSON.stringify(form)])
  const registrationValue = form.payments.registration ? checkBoxObject.registration.valueWithDiscount : 0
  const examValue = form.payments.exam ? checkBoxObject.exam.valueWithDiscount : 0
  const proValue = form.payments.pro ? checkBoxObject.pro.valueWithDiscount : 0

  // VALOR TOTAL DE LECCIONES O CUOTAS
  const lessonsValue = form.payments.lessons ? checkBoxObject.lessons.value : 0
  // VALOR TOTAL DE LECCIONES O CUOTAS
  const groupLessonsValue = form.payments.groupLessons && form.typePlanPricing === 'perlesson' ? checkBoxObject.groupLessons.value : 0

  // VALOR TOTAL CON OTROS PAYMENTS
  const totalValue = form.typePlanPricing !== 'permonth'
    ? (registrationValue + examValue + proValue + lessonsValue + groupLessonsValue).toFixed(2)
    : (registrationValue + examValue + proValue) + form.amount

  // let totalValue = form.typePlanPricing === 'permonth' ? (form.payments.lessons ? form.amount : 0) * payments.length : totalLessonsPayment
  // totalValue += registrationValue + examValue + proValue

  // DESCUENTO A TOTAL DE LECCIONES O CUOTAS
  useEffect(() => {
    if (form.payments.discount.lessons) {
      let aux = 0
      if (form.typePlanPricing !== 'permonth') {
        aux = form.payments.discount.lessons
          ? ((lessonsValue + groupLessonsValue) * (form.payments.discount.lessons / 100)).toFixed(2) : (0).toFixed(2)
      } else {
        aux = form.payments.discount.lessons ? (form.amount * (form.payments.discount.lessons / 100)).toFixed(2) : (0).toFixed(2)
      }
      setDiscount(aux)
      // setForm({
      //   ...form,
      //   discount: aux,
      // })
    }
  }, [
    form.payments.discount.lessons,
    lessonsValue,
    groupLessonsValue,
    form,
    setForm
  ])


  const [firtsConfigLesson, setFirtsConfigLesson] = useState(false)
  const [firtsConfigFixed, setFirtsConfigFixed] = useState(false)
  // useEffect(() => {
  //   if(isEdit && !firtsConfig) {
  //     setFirtsConfig(true)
  //     return
  //   }
  //   const newPaymentItemsConfig = parseToPaymentItemConfig(form)
  //   const eq = !isEqual(newPaymentItemsConfig, form?.paymentItemsConfig)

  //   if(eq) {
  //     setForm({
  //       ...form,
  //       paymentItemsConfig: newPaymentItemsConfig
  //     })
  //   }
  // }, [form])


  //actualiza solo pagos por lecciones
  useEffect(() => {
    if (isEdit && !firtsConfigLesson) {
      setFirtsConfigLesson(true)
      return
    }
    const newPaymentItemsConfig = form.paymentItemsConfig?.filter(
      ({ type }) => ['others-fixed', 'otherFixed'].includes(type)
    ).concat(getLessonPayments(form) || [])
    const eq = !isEqual(newPaymentItemsConfig, form?.paymentItemsConfig)
    if (eq) {
      setForm({
        ...form,
        paymentItemsConfig: newPaymentItemsConfig,
        // groupAmount: form?.typePlanPricing === 'permonth' ? 0 : form?.groupAmount || 0 
      })
    }
  }, 
  //eslint-disable-next-line
  [totalLessons, totalGroupLessons, form?.paymentstart, form?.typePlanPricing, form?.amount, form?.groupAmount, form?.payments?.groupLessons, form?.payments?.lessons, form?.payments?.discount?.lessons
  ])

  //actualiza pagos fijos
  useEffect(() => {
    if (isEdit && !firtsConfigFixed) {
      setFirtsConfigFixed(true)
      return
    }
    const newPaymentItemsConfig = form.paymentItemsConfig?.filter(
      ({ type }) => ['lessons-payment', 'lessonsPayment'].includes(type)
    ).concat(getOtherFixedPayments(form) || [])
    const eq = !isEqual(newPaymentItemsConfig, form?.paymentItemsConfig)
    if (eq) {
      setForm({
        ...form,
        paymentItemsConfig: newPaymentItemsConfig
      })
    }
  }, 
  //eslint-disable-next-line
  [form?.payments?.registration, form?.payments?.exam, form?.payments?.pro, form?.payments?.discount?.registration, form?.payments?.discount?.exam, form?.payments?.discount?.pro,
  ])


  //confecciona pagos (por lecciones y pagos fijos)
  const getLessonPayments = (form) => {
    const [start, end] = getRangeDate(form)
    const cuotas = getFeeFromRangeDate(start, end)
    const lessonsValue = form.payments.lessons ? checkBoxObject.lessons.value : 0
    const groupLessonValue = form.payments.groupLessons && form.typePlanPricing === 'perlesson' ? checkBoxObject.groupLessons.value : 0
    const valuePerFee = form.typePlanPricing !== 'perlesson' ? lessonsValue / (cuotas?.length || 1) : (lessonsValue + groupLessonValue) / (cuotas?.length || 1)
    const fee = cuotas.map((cuota, index) => {
      return {
        amount: valuePerFee * (1 - (parseFloat(form.payments.discount.lessons || 0) / 100)),
        date: moment(cuota.time).toISOString(),
        description: `Payment ${index}`,
        type: 'lessonsPayment',
        percentDiscount: parseFloat(form.payments.discount.lessons || 0),
        amountWithoutDiscount: parseFloat(valuePerFee)
      }
    })
    return fee
  }

  const getOtherFixedPayments = (form) => {
    const fee = []
    const otherFixed = Object.entries(form?.payments?.values)
      .map(([key, value]) => { return { key, value } }) || OTHERFIXED
    otherFixed.forEach((obj) => {
      if (form.payments[obj.key]) {
        fee.push({
          amount: parseFloat(obj.value) * (1 - (parseFloat(form.payments.discount[obj.key] || 0) / 100)),
          date: moment().startOf('day').toISOString(),
          description: `Payment ${obj.key}`,
          type: 'otherFixed',
          percentDiscount: parseFloat(form.payments.discount[obj.key] || 0),
          amountWithoutDiscount: parseFloat(obj.value),
          typeFixed: TYPEFIXED[obj.key] || TYPEFIXED?.nothing
        })
      }
    })
    return fee
  }



  return (
    <>
      <div className='py-6'>
        <h3><b>{translate('modal.newplan.pricingplan.pricing')}</b></h3><br />
        <div className='row mx-0'>
          <Field
            className='col-12'
            type={SelectOption}
            fieldName='typePlanPricing'
            options={[
              { id: 'perlesson', text: translate('modal.newplan.pricingplan.perlessonrate') },
              { id: 'permonth', text: translate('modal.newplan.pricingplan.flatfee') }
            ]}
            value={form.typePlanPricing}
            disabled={disabled}
          />
          <label className='col-12 text-muted mb-2 mt-3 pl-0'>
            {form.typePlanPricing ? form.typePlanPricing === 'perlesson'
              ? translate('modal.newplan.pricingplan.rate')
              : translate('modal.newplan.pricingplan.amount') : translate('modal.newplan.pricingplan.amount')}
          </label>
          <Field
            className='col-12 placeholder-to-right'
            type={AmountInput}
            fieldName='amount'
            fieldType='number'
            placeholder='$  0.00 '
            min='0'
            disabled={disabled}
          />
          {form.typePlanPricing === 'perlesson' ? (
            <>
              <label className='col-12 text-muted mb-2 mt-3 pl-0'>
                {form.typePlanPricing && form.typePlanPricing === 'perlesson'
                  ? translate('modal.newplan.pricingplan.grouprate')
                  : translate('modal.newplan.pricingplan.groupamount')}
              </label>
              <Field
                className='col-12 placeholder-to-right'
                type={AmountInput}
                fieldName='groupAmount'
                fieldType='number'
                placeholder='$  0.00 '
                min='0'
                disabled={disabled}
              />
            </>
          ) : null}
          <div className='mt-3 w-100'>
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.registration'
                    checked={form.payments.registration}
                    disabled={disabled}
                  />
                  <label>{translate('modal.newplan.registrationfee')}</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.registration ? 'text-muted' : ''}`} style={!form.payments.registration ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.registration.valueWithDiscount}${form.payments.discount.registration ? `(-${form.payments.discount.registration}%)` : ''}`}
                </label>
              }
            />
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.exam'
                    checked={form.payments.exam}
                    disabled={disabled}
                  />
                  <label>{translate('modal.newplan.examregistrationfee')}</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.exam ? 'text-muted' : ''}`} style={!form.payments.exam ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.exam.valueWithDiscount}${form.payments.discount.exam ? `(-${form.payments.discount.exam}%)` : ''}`}
                </label>
              }
            />
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <div className='d-flex col-12'>
                  <Field
                    type={CheckboxOption}
                    fieldName='payments.pro'
                    checked={form.payments.pro}
                    disabled={disabled}
                  />
                  <label> Zinkerz Pro</label>
                </div>
              }
              Right={
                <label className={`${!form.payments.pro ? 'text-muted' : ''}`} style={!form.payments.pro ? { textDecoration: 'line-through' } : {}}>
                  {`$${checkBoxObject.pro.valueWithDiscount}${form.payments.discount.pro ? `(-${form.payments.discount.pro}%)` : ''}`}
                </label>
              }
            />
            {form.typePlanPricing === 'perlesson' ? (
              <ListItem
                className='py-0 w-100'
                showBottomLine={false}
                showTopLine={false}
                Left={
                  <div className='d-flex col-12'>
                    <Field
                      type={CheckboxOption}
                      fieldName='payments.groupLessons'
                      checked={form.payments.groupLessons}
                      disabled={disabled}
                    />
                    <label>{form.typePlanPricing === 'permonth' ? translate('modal.newplan.groupmonthlyfee') : `${totalGroupLessons} ${translate('modal.newplan.groupLessons')}`}</label>
                  </div>
                }
                Right={
                  form.payments.groupLessons
                    ? <label>{`$${checkBoxObject.groupLessons.value}`}</label>
                    : <label className='text-muted' style={{ textDecoration: 'line-through' }}>{`$${checkBoxObject.groupLessons.value}`}</label>
                }
              />
            ) : null}
            {
              form.typePlanPricing !== 'permonth'
                ? <ListItem
                  className='py-0 w-100'
                  showBottomLine={false}
                  showTopLine={false}
                  Left={
                    <div className='d-flex col-12'>
                      <Field
                        type={CheckboxOption}
                        fieldName='payments.lessons'
                        checked={form.payments.lessons}
                        disabled={disabled}
                      />
                      <label>{totalLessons + ' ' + translate('modal.newplan.lessons')}</label>
                    </div>
                  }
                  Right={
                    form.payments.lessons
                      ? <label>{`$${checkBoxObject.lessons.value}`}</label>
                      : <label className='text-muted' style={{ textDecoration: 'line-through' }}>{`$${(checkBoxObject.lessons.value).toFixed(2)}`}</label>
                  }
                />
                : <ListItem
                  className='py-0 w-100'
                  showBottomLine={false}
                  showTopLine={false}
                  Left={
                    <div className='d-flex col-12'>
                      <Field
                        type={CheckboxOption}
                        fieldName='payments.lessons'
                        checked={form.payments.lessons}
                        disabled={disabled}
                      />
                      <label>{translate('modal.newplan.monthlyfee')}</label>
                    </div>
                  }
                  Right={
                    form.payments.lessons
                      ? <label>{`$${form.amount}`}</label>
                      : <label className='text-muted' style={{ textDecoration: 'line-through' }}>{`$${(form.amount).toFixed(2)}`}</label>
                  }
                />
            }
            <ListItem
              className='py-0 w-100'
              showBottomLine={false}
              showTopLine={false}
              Left={
                <label className='font-weight-bold'>{form.payments.discount.discount > 0 ? 'Subtotal' : 'Total price'}</label>
              }
              Right={
                <label className='font-weight-bold'>{`$${totalValue}`}</label>
              }
            />
            {
              !disabled && (
                <AddDiscount
                  paymentLabel={form.typePlanPricing === 'permonth' ? translate('modal.newplan.monthlyfee') : `${totalLessons} ${translate('modal.newplan.lessons')}`}
                  discount={form.payments.discount}
                  setDiscount={(newObjectDiscount) => {
                    setForm({
                      ...form,
                      payments: {
                        ...form.payments,
                        discount: newObjectDiscount
                      }
                    })
                  }}
                  disabled={disabled}
                >
                  {
                    form.payments.discount.lessons > 0
                      ? <ListItem
                        className='py-3 w-100'
                        showBottomLine={false}
                        showTopLine={false}
                        Left={
                          <div className='d-flex'>
                            <IconOutLine
                              className='p-3 mr-1'
                              backgroundColor='#FF3E1D'
                              color='#FFFFFF'
                              textChildren={<span className='m-0' style={{ color: 'white' }}>x</span>}
                              sizePx={10}
                            />
                            <label>{`Discount (${form.payments.discount.lessons}%)`}</label>
                          </div>
                        }
                        Right={
                          <label>{`$${discount}`}</label>
                        }
                      />
                      : <label className='btn-link'>{translate('modal.newplan.adddiscount')}</label>
                  }
                </AddDiscount>
              )
            }

            {form.payments.discount.lessons > 0 && (
              <ListItem
                className='py-0 w-100'
                showBottomLine={false}
                showTopLine={false}
                Left={
                  <label className='font-weight-bold'>{translate('modal.newplan.totalprice')}</label>
                }
                Right={
                  <label className='font-weight-bold'>{`$${(totalValue - discount).toFixed(2)}`}</label>
                }
              />
            )}
          </div>
        </div>
      </div>
      {/* <div className='borderTop ml-3' /> */}
    </>
  )
}

PricingPlan.propTypes = {
}

PricingPlan.defaultProps = {
}

export default PricingPlan
