import React, {useMemo, useState} from 'react'
import {
    ModalLayout,
    IconIllustratedReminder,
    EarlyActionBadge,
    EarlyDecisionBadge,
    IconArrowRight,
    LogoItem,
    CheckboxOption,
    ListItem,
    IconBank,
    Button,
    IconAddUniversity,
} from '@Knowledge-OTP/znk-ui-components'
import useSetApplicationStatusModal from './useSetApplicationStatusModal'
import useUser from '../../../CounselingPlans/Model/useUser'
import useSetPlanStatus from '../../Model/useSetPlanStatus'
import StudentAndParent from '../viewPlanModal/Tabs/Resume/Sections/Student/Components/StudentAndParent'
import {showAlert} from '../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import useUniveristyDirectory from '../../../University/UniversityDirectory/useUniversityDirectory'
import UniversitiesQuantity from '../../Components/UniversitiesQuantity'
import useTranslation from '../../../../i18n/useTranslation'

const SetApplicationStatusModal = () => {
    const {isOpen, toggle, dataPlan, onChange} = useSetApplicationStatusModal()
    const {data, refetch: refetchUserQuery} = useUser({id: dataPlan?.studentUser?.studentUserId})
    const dispatch = useDispatch()
    const { translate } = useTranslation()

    const universities = useMemo(
        () => {
            return data?.user?.studentInfo?.universities || []
        }, [data]
    )

    const selector = true
    const [selected, selectUniversity] = useState([])

    const onChangeSelection = (id) => {
        if (selected.includes(id)) {
        selectUniversity(selected.filter(p => 
            p !== id
            ))
        } else {   
          selectUniversity(selected.concat(id))
        }
      }
      
    //university directory
    const { open: openUniversityDirectory } = useUniveristyDirectory()

    const [setPlanStatus, {loading}] = useSetPlanStatus(
        (resp) => {
            onChange(resp?.updateStatusPlan)
            showAlert({
                text: 'Plan has been updated successfully!',
                status: 'success'
            }, dispatch)
        },
        (err) => {
            showAlert({
                text: err.toString(),
                status: 'error'
            }, dispatch)
        }
    )

    return (
        <ModalLayout
            className={`modal-${
                false ? 'botbar' : 'centered'
            } modal-colored h-auto`}
            isOpen={isOpen}
            toggle={toggle}
            topHead={
                <div className={`d-flex flex-column`}>
                    <IconIllustratedReminder size={80} />
                    <span className={`h2 text-title font-weight-bold mt-2`}>Pre-Application to Application</span>
                </div>
            }
            underHead={
                <div className={`d-flex flex-column`}>
                    <span className={`h45 text-info mt-2`}> {translate('modal.sendtoapplication.infotext')}</span>
                </div>
            }
        >
            <div className='m-0 p-0'>
                {/* muestra actual status y nuevo status */}
                {/* <div className={`d-flex flex-row justify-content-around`}>
                    <span className={`d-flex flex-row m-0 p-0 align-items-center`}><span className={`mr-1`} style={{width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'black'}}/>Pre-Application</span>
                    <span className={`d-flex flex-row m-0 p-0 align-items-center`}><span className={`mr-1`} style={{width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'black'}}/>Application</span>
                </div> */}
                {/* mostrar avatar de estudiante */}

                <div className={`d-flex flex-column m-0 p-0`}>
                    <StudentAndParent className={`my-2`} plan={dataPlan} /> <UniversitiesQuantity quantity={selected?.length}/>
                </div>

                {/* selector de universidades */}
                <div className={`borderTop w-100 mt-2 mb-0 p-0`} />
                <div className={`d-flex flex-column m-0 p-0`} style={{maxHeight: '300px', overflowY: 'scroll'}}>
                    {
                        (universities || [])?.map(
                            (item) => {
                                return (
                                    <ListItem
                                        className={'py-1'}
                                        hasHover
                                        showBottomLine
                                        Left={
                                            <div className={`d-flex flex-row ml-2`}>
                                                {/* eslint-disable-next-line */}
                                                <div className={`d-flex flex-row justify-content-center my-2`} style={{
                                                    width: selector ? '25px' : '40px',
                                                    height: selector ? '25px' : '40px'
                                                }}>

                                                    {selector ?
                                                    // eslint-disable-next-line
                                                        item?.logo ? 
                                                            <img style={{ alignSelf: 'center', width: '25px', height: '25px'}} src={item?.logo} alt={item?.name || ""} /> :
                                                            <div className={`d-flex flex-row justify-content-center`} style={{
                                                                width: '25px',
                                                                height: '25px'
                                                            }}>
                                                                <IconBank className={`align-self-center`} color={'gray'} size={25} />
                                                            </div>
                                                        : <LogoItem
                                                            className='ml-4 mr-2 bg-transparent'
                                                            size={40}
                                                            early={item?.early}
                                                            image={item?.logo}
                                                            status={item?.status}
                                                            showTolTip={true}
                                                        />}
                                                </div>
                                                <span className={`align-self-center ml-4 text-info`}
                                                // style={{color: '#2C3F78'}}
                                                >{item?.name}</span>
                                            </div>
                                        }
                                        Right={
                                            selector ?
                                                <CheckboxOption
                                                    // id={item?.id}
                                                    onChange={(e) => {
                                                        onChangeSelection(item?.id)
                                                        // selectUniversity(selected.concat([item?.id]))
                                                    }}
                                                    defaultState={selected?.includes(item.id)}
                                                    value={selected?.includes(item.id)}
                                                    disabled={selected.length === 10  ? !selected?.includes(item.id) : false}
                                                /> :
                                                <div className={`hover-icon`}>{item?.early === 'action' ? <EarlyActionBadge /> :
                                                ['decision', 'decision2'].includes(item?.early) ? <EarlyDecisionBadge edVersion={item?.early}/> : <IconArrowRight className='mr-4' />}</div>
                                        }
                                    />
                                )
                            }
                        )
                    }
                <ListItem
                    hasHover
                    showBottomLine
                    onClick={() => {
                        openUniversityDirectory(() => {
                        }, dataPlan?.studentUser?.studentUserId/**userId**/, dataPlan?.id, universities, refetchUserQuery)
                    }}
                    Left={
                        <div className={`d-flex flex-row ${selector ? 'ml-2' : 'ml-2'}`}>
                            <IconAddUniversity size={selector ? 25 : 40} />
                            <span className={`align-self-center ml-4 text-primary`}>Add University</span>
                        </div>
                    }
                    Right={
                        <div className={`hover-icon ${selector ? '' : 'mr-4'}`}><IconArrowRight /></div>
                    }
            />
                </div>

                {/* envia mail */}

                <Button
                    className={`w-100 mb-2 mt-4`}
                    color={`primary`}
                    size={`normal`}
                    label={`Send`}
                    disabled={selected?.length > 10}
                    activity={loading}
                    onClick={() => {
                        setPlanStatus({
                            variables: {
                                planId: dataPlan?.id,
                                status: "application",
                                universities: selected
                            }
                        })
                    }}
                />
            </div>
        </ModalLayout>
    )
}

export default SetApplicationStatusModal