import userTaskResult from "./queryResults/userTaskResult"
import planQueryResult from '../../CounselingPlans/Model/queryResults/planQueryResult'
import {useQuery, gql} from '@apollo/client'

const usePaginatedTasks = ( vars = {} ) => {
    return useQuery(
        gql`query(
            $page: Float!
            $limit: Int
            $phases: [String!]
            $status: [String!]
            $users: [String!]
            $universities: [String!]
            $search: String
            $assignBy: [String!]
        ) {
            paginatedTasksForCounselor(
                page: $page
                limit: $limit
                phases: $phases
                status: $status
                search: $search 
                users: $users
                universities: $universities
                assignBy: $assignBy
            ) {
                hasNext
                hasPrevious
                totalDocs
                docs {
                    planId
                    taskId
                    phaseId
                    stepId  
                    task {
                        ${userTaskResult}
                    }
                    user {
                        id
                        firstName
                        lastName
                        avatar
                    }
                    dataPlan {
                        ${planQueryResult}
                    }
                }
                
            }
        }
        `,
        {
          variables: vars
        }
    )
}

export default usePaginatedTasks