import React from 'react'
import { ListItem, IconRight, IconEarlyDecision } from '@Knowledge-OTP/znk-ui-components'
import earlyDecisionNames from '../../../Utils/earlyDecision'

const EarlyDecisionItem = (props) => {
  const { college, edVersion, checkEarlyDecision } = props

  return (
    <ListItem
      className='borderTop w-100 m-0 px-2'
      hasHover
      Left={
        <div>
          <IconEarlyDecision size={20} color='#696bff' />
          <span
            className='ml-5 h4'
            style={{
              color: '#696bff',
            }}
          >
            {college?.early === edVersion ? 'Remove' : 'Mark as'}{' '}
            {earlyDecisionNames[edVersion]}
          </span>
        </div>
      }
      Right={<IconRight size={20} />}
      onClick={() => {
        checkEarlyDecision(edVersion)
      }
      }
    />
  )
}
export default EarlyDecisionItem