import React, { useMemo } from 'react'
import {
    Avatar,
    IconArrowRight,
    ListItem,
    InputMonth
    // InputSelect
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import SelectStudent from '../../../../../Plans/modal/newPlan/SelectStudent'
import RenderInputOption from '../../Components/RenderInputOption'
import moment from 'moment-timezone'
import { getValues } from '../../../../Columns/util'
import { gradesEnum as grades, firtsGradeIndex, lastGradeIndex } from './grades'


const Student = (props) => {
    const { className, plan, setData, useProposalParams = false, disabled = false } = props
    //nombre del estudiante seleccionado
    const { studentName, graduationDate, activeTab } = useMemo(() => {
        if (plan?.studentUser) return {
            studentName: `${plan.studentUser?.firstName} ${plan.studentUser?.lastName}`,
            graduationDate: plan?.graduationDate || plan?.studentUser?.studentInfo?.graduationDate || null,
            activeTab: 'selected'
        }
        return {
            studentName: null,
            graduationDate: null,
            activeTab: 'no-selected'
        }
        // eslint-disable-next-line
    }, [plan?.studentUser, plan?.graduationDate])
    //grade deshabilitado
    // const gradeDisabled = plan?.type && plan?.type !== "undergraduate"


    // setData({studentGrade: grade})

    const classofLabel = useMemo(() => {
        const classof = getValues(moment(graduationDate).toISOString(), 'class')
        return parseInt(classof) > 0 ? `‘${classof}` : `The student does not have a graduation date.`
    }, [graduationDate])
    const gradeLabel = useMemo(() => {
        return Object.values(grades).find((value) => value.id === plan?.studentGrade)?.label || null
        // eslint-disable-next-line
    }, [plan?.studentGrade])

    const getGrade = (graduation) => {
        //calcula grade en base a graduation date
        const grade = getValues(moment(graduation || null).toISOString(), 'grade')
        return parseInt(grade) < firtsGradeIndex ? grades[0].id :  parseInt(grade) <= lastGradeIndex ? grades[grade].id : grades['13'].id
    }
    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <span className={`h4 font-weight-bold text-title pb-3`}>Student</span>
            <SelectStudent
                disabled={disabled}
                setStudent={(student) => {
                    setData({
                        studentUser: {
                            ...student,
                            firstName: student?.firstName,
                            lastName: student?.lastName,
                            rate: student?.studentInfo?.defaultRate || 75,
                            studentUserId: student?.id
                        },
                        graduationDate: student?.studentInfo?.graduationDate,
                        studentGrade:
                            //calcula grade en base a graduation date (aun si tienes otro grade definido en su perfil)
                            getGrade(student?.studentInfo?.graduationDate)
                            // student?.studentInfo?.grade?.toString() === grades['12last'].id ?
                            //     grades['12last'].id :
                    })
                }}
                student={plan.studentUser}
            >
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'selected'}>
                        <ListItem
                            className='py-1 appears-animation'
                            id='x-0'
                            label={null}
                            Left={
                                <div className='d-flex align-items-center'>
                                    {
                                        studentName && (
                                            <>
                                                <Avatar
                                                    name={studentName}
                                                    named={false}
                                                    uri={null}
                                                    size='avatar-medium'
                                                />
                                                <label className='ml-2 h4'>
                                                    {studentName}
                                                </label>
                                            </>
                                        )
                                    }
                                </div>
                            }
                            Right={
                                !disabled && (<div className={`hover-icon`}><IconArrowRight /></div>)
                            }
                        />


                    </TabPane>
                    <TabPane tabId={`no-selected`}>
                        <span className={`btn-link`}>Select or create new student</span>
                    </TabPane>
                </TabContent>
            </SelectStudent>
            {
                useProposalParams && (
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={'selected'}>
                            <RenderInputOption className={`col-12`} label={`Graduation date `}>
                                <InputMonth
                                    minYear={2010}
                                    onChange={(e) => {
                                        const date = moment(e).toISOString()
                                        setData({
                                            graduationDate: date,
                                            studentGrade: getGrade(date)
                                        })
                                    }}
                                    value={graduationDate}
                                />
                            </RenderInputOption>

                            <RenderInputOption className={`col-12`} label={``}>
                                <div className={`d-flex flex-row justify-content-end`}>
                                    <span className={`h45 text-gray`}>
                                        <span className={`font-weight-bold pb-3`}>Class of </span>
                                        {classofLabel}
                                    </span>
                                    <span className={`text-gray h45 mx-2`}> - </span>
                                    <span className={`text-gray h45`}>
                                        <span className={`font-weight-bold pb-3`}>Grade </span>
                                        {gradeLabel}
                                    </span>
                                </div>
                            </RenderInputOption>



                        </TabPane>
                    </TabContent>
                )
            }
        </div>
    )
}

export default Student