import React, {Fragment, useEffect, useState, useMemo} from 'react'
import LessonBloc from './LessonBloc/index'
import LessonBlocGroup from './LessonBlocGroup/index'
//import CurrentHourLine from './CurrentHourLine/index'
import {
  IconTime,
  IconUserAlert,
  IconAlert,
  ToolTipInfo,
  IconOptionFilled,
  CurrentHourLine
} from '@Knowledge-OTP/znk-ui-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import moment from 'moment-timezone'

const CalendarLesson = (props) => {
  const {
    style,
    lessons,
    widthColumnPx,
    heightColumnPx,
    from,
    onClickInFreeBloc,
    changeTimezoneBullet,
    changeTimezoneClickAction,
    selectBlock,
    deselectBlock,
    selectedBlocks,
    handleDropLesson,
    handleDragLesson,
    handleDragUpdateLesson,
    zone,
    verticalView
  } = props
  const fromCalendar = moment(from)

  const gmtLabel = useMemo(() => {
    const number = Math.floor(moment().tz(zone).utcOffset() / 60)
    return `GMT${number > 0 ? `+${number}` : number}`
  }, [zone])

  let calendarParentDimensions = null
  const [widthColumnState, setWidthColumnState] = useState(widthColumnPx)  
  useEffect(() => {
    if(calendarParentDimensions && verticalView) return setWidthColumnState(calendarParentDimensions.width - 67)
  }, [calendarParentDimensions, verticalView])

  const RenderBlocCalendar = ({showHour, labelHour, indexDay, indexHour}) => {
    let les = lessons.reduce((lessonsBloc, lesson) => {
      if (lesson.hour === indexHour && lesson.day === indexDay){
        lessonsBloc.push(lesson)
      }
      return lessonsBloc
    }, [])

    // si hay mas de una lección en el mismo bloque
    // descarta las ocultas
    // lecciones ocultas solo pueden aparecer en un bloque vacío
    if (les.length > 1) {
      if(
        //verifica si el arreglo tiene al menos una lección
        les.filter((lesson) => !lesson.disabled).length > 0
      ) {
        //descarta las educator-unavailable
        les = les.filter((lesson) => !lesson.disabled)
      } else {
        //solo hay educator-unavailable en el arreglo
        //deja solo UN bloque de educator-unavailable
        les = [les.find((lesson) => lesson.disabled)]
      }
    }
    //if(les.length > 1) les = les.filter((lesson) => !lesson.disabled) 

    const functionIfBlocIsFree = () => {
      onClickInFreeBloc({day: indexDay, hour: indexHour, fromCalendar})
    }
    return (
      <Droppable
        droppableId={`${from}&${indexDay}&${indexHour}`}
        key={`${from}&${indexDay}&${indexHour}`}
        isDropDisabled={
          moment(from)
            .tz(zone)
            .startOf('isoWeek')
            .isoWeekday(parseInt(indexDay) + 1)
            .hour(indexHour) <= moment()
        }
      >
        {(provided, snapshot) => {
          //provided.placeholder.props.shouldAnimate = false
          const propsDroppable = provided.droppableProps
          propsDroppable.style = {
            ...propsDroppable.style,
            minWidth: `${widthColumnState}px`,
            width: `${widthColumnState}px`,
            minHeight: `${heightColumnPx}px`,
            height: `${heightColumnPx}px`,
          }
          return (
            <div
              className={`znk-bloc-calendar position-relative d-flex justify-content-center ${
                les.length === 0 ? 'empty-bloc' : ''
              }`}
              onClick={() => {
                //if(!(les.length === 1 && les[0].disabled)) {
                //  functionIfBlocIsFree()
                //}
              }}
              {...propsDroppable}
              ref={provided.innerRef}
            >
              {showHour && (
                <span className='h5 text-gray m-0 p-0 label-hour'>
                  {labelHour}
                </span>
              )}
              {les.length > 1 ? (
                <LessonBlocGroup
                  selectedBlocks={selectedBlocks}
                  from={fromCalendar
                    .clone()
                    .add(indexDay, 'day')
                    .add(indexHour, 'hour')
                    .toISOString()}
                  heightBlocMinutePx={heightColumnPx / 60}
                  widthBlocPx={widthColumnState}
                  lessons={les.map((lesson) => {
                    lesson.icon = lesson.semiAttended ? (
                      <IconUserAlert size={15} />
                    ) : lesson.status === 'missed' ? (
                      <IconAlert size={17} />
                    ) : ['incomplete', 'upcoming'].includes(lesson.status) ? (
                      <IconTime size={17} />
                    ) : null
                    return lesson
                  })}
                  selectBlockInCalendar={selectBlock}
                  deselectBlockInCalendar={deselectBlock}
                  isDraggingOver={snapshot.isDraggingOver}
                  blockKey={`${indexDay}-${indexHour}`}
                />
              ) : les.length === 1 ? 
                  (les[0].disabled
                  ? (
                      <LessonBloc
                        minutes={0}
                        durationMin={60}
                        heightBlocMinutePx={heightColumnPx / 60}
                        widthBlocPx={widthColumnState}
                        type='normal'
                        status={'blocked'}
                        title={null}
                        subtitle={null}
                        onClick={() => {}}
                        blockKey={`${indexDay}-${indexHour}`}
                        from={fromCalendar
                          .clone()
                          .add(indexDay, 'day')
                          .add(indexHour, 'hour')
                          .toISOString()}
                        disabledDragAndDrop={true}
                      />
                    )
                  : (
                      <LessonBloc
                        selected={selectedBlocks.indexOf(les[0].idLesson) !== -1}
                        idLesson={les[0].idLesson}
                        selectBlockInCalendar={selectBlock}
                        deselectBlockInCalendar={deselectBlock}
                        minutes={les[0].minutes}
                        durationMin={les[0].durationMin}
                        heightBlocMinutePx={heightColumnPx / 60}
                        widthBlocPx={widthColumnState}
                        type={les[0].type}
                        status={les[0].status}
                        title={les[0].title}
                        subtitle={les[0].subtitle}
                        canChecked={les[0].canChecked}
                        activity={les[0].loading}
                        icon={
                          les[0].semiAttended ? (
                            <IconUserAlert size={15} />
                          ) : les[0].status === 'missed' ? (
                            <IconAlert size={17} />
                          ) : ['incomplete', 'upcoming'].includes(les[0].status) ? (
                            <IconTime size={17} />
                          ) : null
                        }
                        onClick={les[0].onClickLesson}
                        isDraggingOver={snapshot.isDraggingOver}
                        idEducator={les[0].idEducator}
                        idStudents={les[0].student.map((stu) => stu.studentUserId || stu.id)}
                        blockKey={`${indexDay}-${indexHour}`}
                        from={fromCalendar
                          .clone()
                          .add(indexDay, 'day')
                          .add(indexHour, 'hour')
                          .toISOString()}
                      />
                  )) : (null)}
                  {
                    !(les.length === 1 && les[0].disabled) && (
                      <LessonBloc
                        minutes={0}
                        durationMin={60}
                        heightBlocMinutePx={heightColumnPx / 60}
                        widthBlocPx={widthColumnState}
                        type='normal'
                        status={snapshot.isDraggingOver ? 'draggingOver' : 'free'}
                        title={null}
                        subtitle={null}
                        onClick={functionIfBlocIsFree}
                        blockKey={`${indexDay}-${indexHour}`}
                        from={fromCalendar
                          .clone()
                          .add(indexDay, 'day')
                          .add(indexHour, 'hour')
                          .toISOString()}
                      />
                    )
                  }
                {provided.placeholder}
            </div>
          )
        }}
      </Droppable>
    )
  }
  const RenderColumn = ({indexDay}) => {
    let currentDay = fromCalendar.clone().add(indexDay, 'day') 
    let isCurrentDay = currentDay.clone().format('dddd DD YYYY') === moment().tz(zone).format('dddd DD YYYY')
    return (
      <div className={`znk-column-calendar h-100 d-flex flex-column flex-grow-1`}>
        <span className='text-gray text-center w-100 h5 label-day'>
          {isCurrentDay && <IconOptionFilled className={'p-0 my-0 ml-0 mr-1'} size={7} color={'red'}/>}
          {currentDay
            .clone()
            .format('dddd DD')
            .toUpperCase()}
        </span>
        {
          showRedLine && verticalView && isCurrentDay &&
          (<CurrentHourLine showHour={true} heightColumnPx={heightColumnPx} zone={zone} />)
        }
        {new Array(17).fill(0).map((zero, indexHour) => {
          indexHour += 7
          return (
            <Fragment key={indexHour}>
              <div className='borderTop m-0 p-0 w-100' />
              <RenderBlocCalendar
                indexDay={indexDay}
                indexHour={indexHour}
                labelHour={
                  indexHour > 7 ? (
                    fromCalendar.clone().set({hour: indexHour}).format(' hh a')
                  ) : (
                    <ToolTipInfo
                      tooltipId={'gmt-label'}
                      place={'right'}
                      innerElement={changeTimezoneBullet}
                    >
                      <div onClick={changeTimezoneClickAction}>{gmtLabel}</div>
                    </ToolTipInfo>
                  )
                }
                showHour={indexDay === 0 || verticalView}
              />
            </Fragment>
          )
        })}
      </div>
    )
  }
  
  const showRedLine = moment().tz(zone).startOf('isoWeek').toISOString() === fromCalendar.clone().tz(zone).startOf('isoWeek').toISOString()

  return (
    <DragDropContext
      onDragUpdate={handleDragUpdateLesson}
      onDragStart={handleDragLesson} 
      onDragEnd={handleDropLesson}
    >
      <div 
          ref={(el) => {
            if (!el) return
            calendarParentDimensions = el.getBoundingClientRect()
          }}
          className='d-flex znk-calendar m-0 pt-6' 
          style={style}
        >
        <div className='m-0 p-0'>
          {
            showRedLine && !verticalView &&
            (<CurrentHourLine showHour={true} heightColumnPx={heightColumnPx} zone={zone} />)
          }
          <div className={`m-0 p-0 d-flex ${verticalView ? 'flex-column' : 'flex-row'}`}>
            {new Array(7).fill(0).map((__, day) => {
              return <RenderColumn indexDay={day} key={day} />
            })}
          </div>
        </div>
      </div>
      {/** <div className={`w-100 h-100 znk-calendar-loading ${loading ? 'znk-calendar-loading-active' : ''}`} />**/}
    </DragDropContext>      
  )
}

export default CalendarLesson
