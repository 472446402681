import { Field, Form, IconClose, LabeledField, PlainTextInput, Button, PhoneSelector } from '@Knowledge-OTP/znk-ui-components'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Label, Input } from 'reactstrap'
import useTranslation from '../../../../../i18n/useTranslation'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../../store/modal/const'
import addSetParentsToStudent from '../../../../../Model/Users/Student/addSetParentsToStudent'
import { isEmpty, validate } from 'validate.js'
import { showAlert } from '../../../../../common/Alert/util'

const EditFamilyInfo = (props) => {
  const {
    refetchUser,
    toggleRightSide,
    actionAfterSubmit
  } = props
  const dispatch = useDispatch()
  const { userId, isOpen, ...formData } = useSelector((state) => state.modal.user.familyInfo)
  const [form, setForm] = useState({ newParents: [] })
  const [selectCode, setSelectCode] = useState('')
  const { translate } = useTranslation()
  const closeFull = () => {
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.familyInfo', data: { isOpen: false, full: false, type: 'student' } })
  }
 const selectCodeFn = (code, key) => {
    setForm({...form, parents:  {...form.parents, [key]: {...form.parents[key], phoneDialCode: code}}})
 }

  useEffect(() => {
    if (userId && formData && isOpen) {
      const aux = formData.parents.reduce((accumulator, element, index) => ({
        ...accumulator,
        [element.email]: {
          ...element,
          phoneDialCode: element.phone ? element.phone.split(' ')[0] : '',
          phoneNumber: element.phone ? element.phone.split(' ')[1] : '',
          isDelete: false,
          errorFirst: '',
          errorLast: '',
          errorCode: '',
          errorNumber: '',
          errorPayment: '',
          errorApproval: ''
        }
      }), {})
      setForm({ parents: aux, newParents: [] })
    }
    // eslint-disable-next-line
  }, [userId, isOpen])
  const { submit, loading } = addSetParentsToStudent((result) => {
    showAlert({
      text: translate('modal.newParent.success'),
      status: 'success'
    }, dispatch)
    refetchUser(userId)
    if (actionAfterSubmit) actionAfterSubmit()
    closeFull()
  }, () => {
    showAlert({
      text: translate('modal.newParent.error'),
      status: 'error'
    }, dispatch)
  })
  const onSubmit = () => {
    const parentOld = Object.values(form.parents).map(
      element => {
        return {
          userParentId: element.parentUserId,
          email: element.email,
          firstName: element.firstName,
          lastName: element.lastName,
          phone: (!element?.phoneDialCode && !element?.phoneNumber) ? null : (element?.phoneDialCode ? `${element?.phoneDialCode.trimEnd()} ` : '') + element?.phoneNumber,
          isNewParent: false,
          isDelete: element.isDelete,
          managePayments: element.managePayments,
          manageApprovals: element.manageApprovals
        }
      }
    )
    const parentNew = Object.values(form.newParents).filter(e => e.isDelete === false).map(
      element => {
        return {
          email: element.email,
          firstName: element.firstName,
          lastName: element.lastName,
          phone: (!element?.phoneDialCode || !element?.phoneNumber) ? null : (element?.phoneDialCode ? `${element?.phoneDialCode.trimEnd()} ` : '') + element?.phoneNumber,
          isNewParent: true,
          isDelete: false,
          managePayments: element.managePayments,
          manageApprovals: element.manageApprovals
        }
      }
    )
    const newVal = {
      parents: parentOld.concat(parentNew),
      userStudentId: userId
    }

    let hasError = false
    const payPa = Object.values(form.parents).filter(e => e.managePayments)
    const payPan = form.newParents.filter(e => e.managePayments)
    const apPa = Object.values(form.parents).filter(e => e.manageApprovals)
    const apPan = form.newParents.filter(e => e.manageApprovals)
    const parentsError = Object.values(form.parents).filter(e => e.isDelete === false).reduce(
      (items, current, index) => {
        if (!hasError && (isEmpty(current.firstName) || isEmpty(current.lastName) || 
        //isEmpty(current.phoneNumber) || isEmpty(current.phoneDialCode) || 
        (payPa.length === 0 && payPan.length === 0))) hasError = true
        return {
          ...items,
          [index]: {
            ...current,
            errorFirst: isEmpty(current.firstName) ? 'first' : '',
            errorLast: isEmpty(current.lastName) ? 'last' : '',
            //errorCode: isEmpty(current.phoneDialCode) ? 'phoneDialCode' : '',
            //errorNumber: isEmpty(current.phoneNumber) ? 'phoneNumber' : '',
            errorPayment: payPa.length > 0 || payPan.length > 0 ? '' : 'error',
            errorApproval: apPa.length > 0 || apPan.length > 0 ? '' : 'error'
          }
        }
      }
      , {})
    const newParentsError = Object.values(form.newParents).filter(e => e.isDelete === false).map(
      e => {
        if (!hasError && (validate({ from: e.email }, { from: { email: true } }) !== undefined || isEmpty(e.firstName) || isEmpty(e.lastName) || 
        //isEmpty(e.phoneNumber) || isEmpty(e.phoneDialCode) || 
        (payPa.length === 0 && payPan.length === 0))) hasError = true
        return {
          ...e,
          errorFirst: isEmpty(e.firstName) ? 'first' : '',
          errorLast: isEmpty(e.lastName) ? 'last' : '',
          //errorCode: isEmpty(e.phoneDialCode) ? 'phoneDialCode' : '',
          //errorNumber: isEmpty(e.phoneNumber) ? 'phoneNumber' : '',
          errorPayment: payPa.length > 0 || payPan.length > 0 ? '' : 'error',
          errorApproval: apPa.length > 0 || apPan.length > 0 ? '' : 'error',
          errorEmail: validate({ from: e.email }, { from: { email: true } }) === undefined ? '' : 'email'
        }
      }
    )
    if (hasError) {
      setForm({
        ...form,
        parents: parentsError,
        newParents: newParentsError
      })
      return
    }
    submit({ input: { parents: newVal.parents }, userStudentId: userId })
  }
  const addParent = () => {
    const parent = {
      firstName: '',
      lastName: '',
      email: '',
      phoneDialCode: '',
      phoneNumber: '',
      managePayments: false,
      manageApprovals: false,
      isDelete: false,
      errorFirst: '',
      errorLast: '',
      errorCode: '',
      errorNumber: '',
      errorPayment: '',
      errorApproval: '',
      errorEmail: ''
    }
    setForm({
      ...form,
      newParents: form.newParents.concat([parent])
    })
  }
  const changeManagePaymentNews = (element, idx) => {
    if (!element.managePayments === true) {
      setForm({
        ...form,
        parents: Object.values(form.parents).reduce((accumulator, element, index) => ({
          ...accumulator,
          [element.email]: {
            ...element,
            managePayments: false
          }
        }), {}),
        newParents: form.newParents.map((e, i) => {
          return {
            ...e,
            managePayments: i === idx ? !element.managePayments : false
          }
        })
      })
    } else {
      setForm({
        ...form,
        newParents: form.newParents.map((e, i) => {
          return {
            ...e,
            managePayments: i === idx ? !element.managePayments : e.managePayments
          }
        })
      })
    }
  }
  const changeManagePayment = (parent) => {
    if (!parent.managePayments === true) {
      setForm({
        ...form,
        newParents: form.newParents.map(e => {
          return {
            ...e,
            managePayments: false
          }
        }),
        parents: Object.values(form.parents).reduce((accumulator, element, index) => ({
          ...accumulator,
          [element.email]: {
            ...element,
            managePayments: parent.email === element.email ? !parent.managePayments : false
          }
        }), {})
      })
    } else {
      setForm({
        ...form,
        parents: { ...form.parents, [parent.email]: { ...parent, managePayments: !parent.managePayments } }
      })
    }
  }
  const deleteParent = (type, idx) => {
    switch (type) {
      case 'Old':
        setForm({
          ...form,
          parents: { ...form.parents, [idx]: { ...form.parents[idx], isDelete: true } }
        })
        break
      default:
        setForm({
          ...form,
          newParents: { ...form.newParents, [idx]: { ...form.newParents[idx], isDelete: true } }
        })
        break
    }
  }
  
  return (
    <div className='w-100 py-5 px-8 text-title'>
      <span className='d-flex w-100 flex-row justify-content-between'>
        <h2 className='font-weight-bold'>Edit Family Details</h2>
        <IconClose size={20} onClick={toggleRightSide || closeFull} color='rgba(119, 134, 153, .4)' />
      </span>
      <Form value={form} onChange={setForm}>
        {
          form.parents && Object.values(form.parents).filter(e => e.isDelete === false).map(
            (parent, index) => {
              return (
                <div key={index}>
                  {
                    index > 0 && (<hr />)
                  }
                  <FormGroup className='mt-7 mb-0'>
                    <Label>Parent name</Label>
                    <div className='row'>
                      <div className='col-6 pr-0'>
                        <Field
                          type={LabeledField}
                          component={PlainTextInput}
                          placeholder={translate(
                            'profile.actions.editPersonalInfo.firstName'
                          )}
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0
                          }}
                          labelContainerClassName='mb-0'
                          onChange={(e) => {
                            setForm({
                              ...form,
                              parents: { ...form.parents, [parent.email]: { ...parent, firstName: e } }
                            })
                          }}
                          value={parent.firstName}
                        />
                        {parent.errorFirst !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.firstName')}</h5>)}
                      </div>
                      <div className='col-6 pl-1'>
                        <Field
                          type={LabeledField}
                          component={PlainTextInput}
                          placeholder={translate(
                            'profile.actions.editPersonalInfo.lastName'
                          )}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                          }}
                          labelContainerClassName='mb-0'
                          value={parent.lastName}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              parents: { ...form.parents, [parent.email]: { ...parent, lastName: e } }
                            })
                          }}
                        />
                        {parent.errorLast !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.lastName')}</h5>)}
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className='mt-5'>
                    <Label>
                      {translate('profile.actions.editPersonalInfo.email')}
                    </Label>
                    <Field
                      type={LabeledField}
                      component={PlainTextInput}
                      disabled
                      value={parent.email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{translate('profile.actions.editPersonalInfo.phone')}</Label>
                    <div className='row'>
                      <div className='col-6 pr-1'>
                        <PhoneSelector
                          fieldName={`parents['${parent.email}'].phoneDialCode`}
                          defaultValue={form.parents[parent.email]?.phoneDialCode || ''}
                          selectCode={form.parents[parent.email]?.phoneDialCode} 
                          setSelectCode={selectCodeFn}
                          email={form.parents[parent.email]?.email}
                          multiple={true}
                        />
                        {parent.errorCode !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.phoneCode')}</h5>)}
                      </div>
                      <div className='col-6 pl-0'>
                        <Field
                          type={LabeledField}
                          component={PlainTextInput}
                          fieldName='phoneNumber'
                          placeholder='12345621'
                          value={parent.phoneNumber}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              parents: { ...form.parents, [parent.email]: { ...parent, phoneNumber: e } }
                            })
                          }}
                        />
                        {parent.errorNumber !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.phoneNumber')}</h5>)}
                      </div>
                    </div>
                  </FormGroup>
                  <div className='row'>
                    <div className='col-6 pr-1'>
                      <FormGroup check className=''>
                        <Label check>
                          <Input
                            type='checkbox'
                            fieldName='managePayments'
                            defaultChecked={parent.managePayments}
                            checked={parent.managePayments}
                            onChange={(e) => {
                              changeManagePayment(parent)
                            }}
                          />{' '}
                          Manage payments
                        </Label>
                        {parent.errorPayment !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.errorPayment')}</h5>)}
                      </FormGroup>
                    </div>
                    <div className='col-6 pr-1'>
                      <FormGroup check className=''>
                        <Label check>
                          <Input
                            type='checkbox'
                            fieldName='manageApprovals'
                            defaultChecked={parent.manageApprovals}
                            checked={parent.manageApprovals}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                parents: { ...form.parents, [parent.email]: { ...parent, manageApprovals: !parent.manageApprovals } }
                              })
                            }}
                          />{' '}
                          Manage approvals
                        </Label>
                        {parent.errorPayment !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.errorApprovals')}</h5>)}
                      </FormGroup>
                    </div>
                  </div>
                  <div className='d-flex flex-row justify-content-end mt-3'>
                    <h5 className='text-error cursor' onClick={() => { deleteParent('Old', parent.email) }}>Remove this parent</h5>
                  </div>
                </div>
              )
            }
          )
        }
        {
          form.newParents && Object.values(form.newParents).map(
            (element, index) => {
              if(element?.isDelete) return (null)
              return (
                <div key={index}>
                  <hr />
                  <FormGroup className='mt-7 mb-0'>
                    <Label>Parent name</Label>
                    <div className='row'>
                      <div className='col-6 pr-0'>
                        <Field
                          type={LabeledField}
                          component={PlainTextInput}
                          placeholder={translate(
                            'profile.actions.editPersonalInfo.firstName'
                          )}
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0
                          }}
                          labelContainerClassName='mb-0'
                          onChange={(e) => {
                            setForm({
                              ...form,
                              newParents: form.newParents.map(
                                (item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      firstName: e
                                    }
                                  } else {
                                    return item
                                  }
                                }
                              )
                            })
                          }}
                          value={element.firstName}
                        />
                        {element.errorFirst !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.firstName')}</h5>)}
                      </div>
                      <div className='col-6 pl-1'>
                        <Field
                          type={LabeledField}
                          component={PlainTextInput}
                          placeholder={translate(
                            'profile.actions.editPersonalInfo.lastName'
                          )}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                          }}
                          labelContainerClassName='mb-0'
                          value={element.lastName}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              newParents: form.newParents.map(
                                (item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      lastName: e
                                    }
                                  } else {
                                    return item
                                  }
                                }
                              )
                            })
                          }}
                        />
                        {element.errorLast !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.lastName')}</h5>)}
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className='mt-5'>
                    <Label>
                      {translate('profile.actions.editPersonalInfo.email')}
                    </Label>
                    <Field
                      type={LabeledField}
                      component={PlainTextInput}
                      value={element.email}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          newParents: form.newParents.map(
                            (item, idx) => {
                              if (index === idx) {
                                return {
                                  ...item,
                                  email: e
                                }
                              } else {
                                return item
                              }
                            }
                          )
                        })
                      }}
                    />
                    {element.errorEmail !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.lastName')}</h5>)}
                  </FormGroup>
                  <FormGroup>
                    <Label>{translate('profile.actions.editPersonalInfo.phone')} <span className={`h45 text-gray`}>(Optional)</span></Label>
                    <div className='row'>
                      <div className='col-6 pr-1'>
                        <PhoneSelector
                          defaultValue={form.phoneDialCode || ''}
                          selectCode={selectCode} 
                          setSelectCode={(newCode) => {
                            setForm({
                              ...form, 
                              newParents: form.newParents.map(
                                (item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      phoneDialCode: newCode
                                    }
                                  } else {
                                    return item
                                  }
                                }
                              )
                            })
                            setSelectCode(newCode)
                          }} 
                        />
                        {/* <Field
                          type={InputSelect}
                          component={Select}
                          defaultSelected={[element.phoneDialCode]}
                          fieldName={`newParents['${index}'].phoneDialCode`}
                          options={Countries.countryArr.map((item, _) => ({
                            label: item.dialCode,
                            value: item.dialCode
                          }))}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              newParents: form.newParents.map(
                                (item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      phoneDialCode: e
                                    }
                                  } else {
                                    return item
                                  }
                                }
                              )
                            })
                          }}
                          filterOption={(obj, string) => {
                            if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                            return false
                          }}
                        /> */}
                        {element.errorCode !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.phoneCode')}</h5>)}
                      </div>
                      <div className='col-6 pl-0'>
                        <Field
                          type={LabeledField}
                          component={PlainTextInput}
                          fieldName='phoneNumber'
                          placeholder='12345621'
                          value={element.phoneNumber}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              newParents: form.newParents.map(
                                (item, idx) => {
                                  if (index === idx) {
                                    return {
                                      ...item,
                                      phoneNumber: e
                                    }
                                  } else {
                                    return item
                                  }
                                }
                              )
                            })
                          }}
                        />
                        {element.errorNumber !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.phoneNumber')}</h5>)}
                      </div>
                    </div>
                  </FormGroup>
                  <div className='row'>
                    <div className='col-6 pr-1'>
                      <FormGroup check className=''>
                        <Label check>
                          <Input
                            type='checkbox'
                            fieldName='managePayments'
                            defaultChecked={element.managePayments}
                            checked={element.managePayments}
                            onChange={(e) => {
                              changeManagePaymentNews(element, index)
                            }}
                          />{' '}
                          Manage payments
                        </Label>
                        {element.errorPayment !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.errorPayment')}</h5>)}
                      </FormGroup>
                    </div>
                    <div className='col-6 pr-1'>
                      <FormGroup check className=''>
                        <Label check>
                          <Input
                            type='checkbox'
                            fieldName='managePayments'
                            defaultChecked={element.manageApprovals}
                            checked={element.manageApprovals}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                newParents: form.newParents.map(
                                  (item, idx) => {
                                    if (index === idx) {
                                      return {
                                        ...item,
                                        manageApprovals: !element.manageApprovals
                                      }
                                    } else {
                                      return item
                                    }
                                  }
                                )
                              })
                            }}
                          />{' '}
                          Manage approvals
                        </Label>
                        {element.errorPayment !== '' && (<h5 className='text-error'>{translate('profile.actions.editFamilyInfo.errors.errorApprovals')}</h5>)}
                      </FormGroup>
                    </div>
                  </div>
                  <div className='d-flex flex-row justify-content-end mt-3'>
                    <h5 className='text-error cursor' onClick={() => { deleteParent('New', index) }}>Remove this parent</h5>
                  </div>
                </div>
              )
            }
          )
        }
        <div className='d-flex flex-row mt-4'>
          <span className='btn-link cursor' onClick={addParent}>Add new parent</span>
          <span 
            className='btn-link cursor ml-3' 
            onClick={() => {
              dispatch({ 
                type: SET_DATA_MODAL_FORM_LIST, 
                payload: 'student.addParentModal', 
                data: {
                  isOpen: true,
                  idStudent: userId,
                  refetch: () => {
                    refetchUser(userId)
                    if (actionAfterSubmit) actionAfterSubmit()
                    dispatch({ 
                      type: SET_DATA_MODAL_FORM_LIST, 
                      payload: 'user.familyInfo', 
                      data: { 
                        ...formData,
                        userId,
                        isOpen: false, 
                        
                      } 
                    })
                  }
                } 
              })
            }}
          >{translate('profile.actions.editFamilyInfo.linkedParent.link')}</span>
        </div>
        <Button
          label='Save changes'
          color='primary'
          check
          size='medium'
          onClick={onSubmit}
          disabled={loading}
          activity={loading}
          style={{ width: '100%' }}
          className='mt-5'
        />
      </Form>
    </div>
  )
}

export default EditFamilyInfo
