import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
    IconArrowRight, ModalLayout,
    useWindowDimensions, ListItem, IconArrowLeft, IconSignPlus
} from '@Knowledge-OTP/znk-ui-components'
import { TabContent, TabPane } from 'reactstrap'
import StartDateFilter from '../../../Plans/Filters/StartDate'
import StatusesFilter from '../../../Plans/Filters/Statuses'
import TypesFilter from '../Types'
import GraduationFilter from '../Graduation'
import CountryFilter from '../Country'
import useTranslation from '../../../../i18n/useTranslation'


const ModalFilterMobile = (props) => {
    const { 
        setQuery: setQueryProp = () => {}, query = {}, 
        resetFilters = () => {}, canReset = false, statuses = undefined,
        countries = [], graduationDates = [], types = []
    } = props
    const setQuery = useCallback(
        (newQuery) => {
            setQueryProp(newQuery)
            setOpen(false)
        }, [setQueryProp]
    ) 
    const [isOpen, setOpen] = useState(false)
    const toggle = () => setOpen(!isOpen)
    const { breakWidth } = useWindowDimensions()
    
    const {translate} = useTranslation()
    const Pages = {
        statuses: {key: 'statuses', name: translate('filters.counselingplans.status.title')},
        types: {key: 'types', name: translate('filters.counselingplans.types.title')},
        classOf: {key: 'class-of', name: translate('filters.counselingplans.classOf.title')},
        countries: {key: 'countries', name: translate('filters.counselingplans.countriesTitle')},
        filters: {key: 'filters', name: 'Filters'},
        startDate: {key: 'start-date', name: translate('filters.counselingplans.startDateTitle')},
        finalizationDate: {key: 'finalization-date', name: translate('filters.counselingplans.finalizationDateTitle')},
    }
    
    const [page, setPage] = useState(Pages.filters.key)
    useEffect(() => { if (!isOpen) setTimeout(() => setPage(Pages.filters.key), 1000) }, [isOpen, Pages])
    
    const modalTitle = useMemo(() => Object.values(Pages).find(({key}) => key === page)?.name, [page, Pages])
    const listItemClassName = 'w-100 hover-no-border py-2'
    return (
        <>
            <div className='m-0 p-0' onClick={toggle}>
                {props.children}
            </div>
            <ModalLayout
                className={`${breakWidth === 'SM' ? 'modal-botbar' : 'modal-centered'} modal-hauto modal-noborder-bottom`}
                disabledScrollAnimation={false}
                isOpen={isOpen}
                toggle={toggle}
                topHead={
                    <div className={`d-flex flex-row align-items-center`}>
                        {
                            Pages.filters.key !== page &&
                            <div className='hover-icon' onClick={() => setPage(Pages.filters.key)}>
                                <IconArrowLeft size={20} className={'text-disabled'} />
                            </div>
                        }
                        <span className={`h2 text-title m-0`}>{modalTitle}</span>
                    </div>
                }
            >
                <TabContent activeTab={page}>
                    <TabPane tabId={Pages.filters.key}>
                        <div className='m-0 p-0 d-flex flex-column'>
                            <ListItem
                                onClick={() => { setPage(Pages.statuses.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.statuses.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.classOf.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.classOf.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.types.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.types.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            <ListItem
                                onClick={() => { setPage(Pages.countries.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.countries.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />        
                            <ListItem
                                onClick={() => { setPage(Pages.startDate.key) }}
                                className={listItemClassName}
                                hasHover
                                Left={<span>{Pages.startDate.name}</span>}
                                Right={<div className='hover-icon'><IconArrowRight /></div>}
                                showBottomLine
                            />
                            {
                                canReset && (
                                    <ListItem
                                        onClick={resetFilters}
                                        className={listItemClassName}
                                        hasHover
                                        Left={<span className='text-error'>{translate('misc.cleanFilters')}</span>}
                                        Right={<div className='hover-icon'><IconSignPlus className={'rotate-90 text-error'} /></div>}
                                        showBottomLine
                                    />
                                )
                            }
                        </div>
                    </TabPane>
                    <TabPane tabId={Pages.statuses.key}>
                        <StatusesFilter 
                            statuses={statuses}
                            useInner
                            statusFilters={query.status}
                            setStatusFilters={(newStatus) => { setQuery({ ...query, status: newStatus }) }}
                        />
                    </TabPane>
                    <TabPane tabId={Pages.classOf.key}>
                        <GraduationFilter
                            useInner
                            graduationDates={graduationDates}
                            graduationFilters={query.status}
                            setGraduationFilters={(newStatus) => { setQuery({ ...query, status: newStatus }) }}
                        />
                    </TabPane>
                    <TabPane tabId={Pages.types.key}>
                        <TypesFilter 
                            useInner
                            types={types}
                            typesFilters={query.status}
                            setTypesFilters={(newStatus) => { setQuery({ ...query, status: newStatus }) }}
                        />
                    </TabPane>
                    
                    <TabPane tabId={Pages.startDate.key}>
                        <StartDateFilter
                            startDateFilter={query.startDateFilter}
                            setStartDateFilter={(start) => { setQuery({ ...query, startDateFilter: start }) }}
                            typeFilter='StartDate' />
                    </TabPane>
                    <TabPane tabId={Pages.countries.key}>
                        <CountryFilter
                            useInner
                            countries={countries}
                            style={{maxWidth: 'auto'}}
                            className={'h45'}
                            countryFilters={query.countryIds}
                            setCountryFilters={(ids) => {setQuery({ ...query, countryIds: ids })}}
                        />
                    </TabPane>
                </TabContent>
            </ModalLayout>
        </>
    )
}


export default ModalFilterMobile