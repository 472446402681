import * as moment from 'moment-timezone'

const getValues = (date, type) => {
    const month = moment(date).month()
    let year = moment(date).year()
    if (month >= 10 && month <= 12) {
      year += 1
    }
    const difY = year - moment().year()
    switch (type) {
      case 'class':
        return year.toString().substr(2, 2)
      default:
        return 12 - difY
    }
  }

export const parseDateToClassOf = (date, array) => {
      
      let parsedDate = `Class of ‘${getValues(moment(parseInt(date)).toISOString(), 'class')}`

      

  return parsedDate
}
