import React, { useState, useEffect, useMemo } from 'react'
import {
  Modal,
  IconClose,
  Field,
  SelectOption,
  Form,
  InputSelect,
  PlainTextInput,
  StickyButton,
  Button,
  LabeledField,
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { categories, types } from '../constants'
import { gql, useLazyQuery } from '@apollo/client'
import {showAlert} from '../../../../common/Alert/util'
import editCustomPaymentItemFromAdmin from './editCustomPaymentItemFromAdmin'
import moment from 'moment'
import {escapeRegExp} from 'lodash'

const EditCustomPaymentItem = (props) => {
  const { refetch } = props
  const modalData = useSelector((state) => state.modal.payments.editPaymentCustom)
  const { isOpen, paymentId, kind } = modalData
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'payments.editPaymentCustom',
      data: {
        isOpen: false,
        invoiceId: ''
      }
    })
  }
  
  const [formCustom, setFormCustom] = useState({
    chargeType: 'studentCharge',
    category: 'livePrep',
    type: 'lessonsPayment',
    amount: 0,
    discount: 0,
    description: '',
    date: new Date()
  })
  
  const [mePayment, { data }] = useLazyQuery(
    gql`query($paymentItemId: ID!){
      paymentItem(paymentItemId: $paymentItemId) {
        ... on PlanPaymentItem {
          planId
          planItems {
            id
            kind
            amount
            amountWithoutDiscount
            generatedDescription
            percentDiscount
            status
            date
            category
            chargeType
            draft
            lastProcessedAt
            description
            type
            userId
            planId
          }
        }
        ... on LessonPaymentItem {
          id
          kind
          amount
          amountWithoutDiscount
          percentDiscount
          status
          date
          category
          draft
          lastProcessedAt
          description
          chargeType
          generatedDescription
          type
          userId
        }
        ... on EducatorPaymentItem {
          lessonId
          id
          amount
          amountWithoutDiscount
          percentDiscount
          status
          date
          category
          draft
          chargeType
          lastProcessedAt
          description
          type
          userId
        }
        ... on CustomPaymentItem {
          id
          amount
          amountWithoutDiscount
          percentDiscount
          chargeType
          status
          date
          category
          draft
          lastProcessedAt
          description
          chargeType
          type
          userId
        }
      }
    }`,
    {
      variables: { paymentItemId: paymentId },
      fetchPolicy: 'network-only'
    }
  )
  
  useEffect(() => {
    (async () => {
      if (!paymentId) return
      await mePayment()
    })()
  }, [paymentId, mePayment])

  useEffect(() => {
    if (data) {
        setFormCustom({
            chargeType: data.paymentItem.chargeType,
            category: data.paymentItem.category,
            type: data.paymentItem.type,
            amount: Math.abs(data.paymentItem.amountWithoutDiscount),
            description: data.paymentItem.generatedDescription ?? data.paymentItem.description,
            date: moment(data.paymentItem.date.split('T')[0]).format('YYYY-MM-DD'),
            discount: data.paymentItem.percentDiscount,
            userId: data.paymentItem.userId
        })
    }
  }, [data, kind])
  //useEffect(() => {
  //  if (cuotas && cuotas.length > 0) {
  //    setLastPay(cuotas[cuotas.length - 1].time)
  //  }
  //}, [cuotas])
  //useEffect(() => {
  //  setCuotas(prevCuotas => {
  //    const start = moment.utc(form.paymentstart).endOf('month').add(-2, 'd')
  //    return prevCuotas ? prevCuotas.map(cuo => {
  //      const time = start.clone()
  //      start.add(1, 'month')
  //      return {
  //        ...cuo,
  //        time: time.toISOString(),
  //        isEdit: true
  //      }
  //    }) : []
  //  })
  //  if (data && (moment(form.paymentstart).month() !== moment(data.paymentItem.planItems.filter(e => e.type !== 'otherFixed')[0].date.split('T')[0]).month())) {
  //    setCanReset(true)
  //  }
  //  // eslint-disable-next-line
  //}, [form.paymentstart])
  const { submit: submitCustom, loading: loadingCustom } = editCustomPaymentItemFromAdmin(
    () => {
        showAlert({
            text: "¡Success!",
            status: 'success'
        }, dispatch)
        refetch()
        toggle()
    }, 
    (error) => {
        showAlert({
            text: "¡Error!",
            status: 'error'
        }, dispatch)
        console.error(error)
    }
  )
  const onSubmit = () => {
    submitCustom({ paymentItemId: paymentId, paymentInput: formCustom })
  }

  const filterTypes = useMemo(
    () => {
      return types.filter(
        e => e.type.includes(formCustom.category?.toUpperCase() === 'LIVEPREP' ? 'invoice': 'counseling')
        )
        //eslint-disable-next-line
      }, [formCustom, types]
  )

  return (
    <>
      <div className='m-0 p-0 w-100' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>{translate('payments.editnewpayment')}</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={formCustom} onChange={setFormCustom}>
          <div className='m-0 p-0 mb-2 pl-2 pr-2'>
            <div className='row d-flex m-0 p-0'>
              <div className='col-12 p-0'>
                <Field
                  className='col-12 row inline mt-4'
                  type={SelectOption}
                  fieldName='chargeType'
                  options={[
                    { id: 'studentCharge', text: translate('payments.charges.studentCharge') },
                    { id: 'studentRefund', text: translate('payments.charges.studentRefund') }
                  ]}
                  studentCharge
                  StyleOptionClassName={{
                    width: '49%',
                    whiteSpace: 'nowrap'
                  }}
                  StyleSelect={{
                    justifyContent: 'space-between'
                  }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Category</label>
                <Field
                  className='col-12'
                  type={InputSelect}
                  fieldName='category'
                  placeholder={translate('modal.newplan.selectservice')}
                  options={categories && [].concat(categories.map((serv) => ({ value: serv.id, label: translate(`payments.categories.${serv.id}`) })))}
                  InputClassName='w-100'
            filterOption={(obj, string) => {
              if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
              return false
            }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Type</label>
                <Field
                  className='col-12'
                  component={InputSelect}
                  fieldName='type'
                  type={LabeledField}
                  placeholder={translate('modal.newplan.selectservice')}
                  options={filterTypes.map(
                      (serv) => ({ value: serv.id, label: translate(`payments.types.${serv.id}`) })
                    ).concat(
                      types.filter(t => t.id === 'other').map((serv) => ({ value: serv.id, label: translate(`payments.types.${serv.id}`) }))
                    )
                  }
                  InputClassName='w-100'
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                    return false
                  }}
                />
              </div>
            </div>
            <div className='row d-flex m-0 mt-2 p-0'>
              <div className='col-6 p-0 pr-2'>
                <label className='text-gray font-weight-light mb-2'>Discount</label>
                <Field
                  className='col-12 placeholder-to-right'
                  type={PlainTextInput}
                  fieldName='discount'
                  fieldType='number'
                  placeholder='%  0.00 '
                  min='0'
                />
              </div>
              <div className='col-6 pl-1 pr-0 pl-2'>
                <label className='text-gray font-weight-light mb-2'>Amount</label>
                <Field
                  className='col-12 placeholder-to-right'
                  type={PlainTextInput}
                  fieldName='amount'
                  fieldType='number'
                  placeholder='$  0.00 '
                  min='0'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Description</label>
                <Field
                  type={PlainTextInput}
                  placeholder='Description'
                  fieldName='description'
                  fieldType='text'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-2'>
              <div className='col-12 p-0'>
                <label className='text-gray font-weight-light mb-2'>Payment date</label>
                <Field
                  type={PlainTextInput}
                  fieldName='date'
                  fieldType='date'
                />
              </div>
            </div>
            <div className='row d-flex m-0 p-0 mt-3'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loadingCustom}
                      className='col-12 aling-text-left'
                      label={translate('payments.editnewpayment')}
                      onClick={() => { onSubmit('custom') }}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default EditCustomPaymentItem
