import React from 'react'
import ParentEditor from './ParentEditor'
const ParentsPermissions = (props) => {
  const { parents, userId, onSuccess } = props
  return (
    <div className='d-flex flex-column'>
      <span className='h2'>Current Parents</span>
      {
        parents.length
          ? parents.map((parent) => {
            const { parentUserId } = parent
            return <ParentEditor key={parentUserId} {...{ ...parent, onSuccess, userId }} />
          })
          : <span className='m-auto'> No parents found</span>
      }
    </div>
  )
}

export default ParentsPermissions
