import { IconClose, Modal, Field, InputSelect, Form, AmountInput, StickyButton, Button, SelectCheck } from '@Knowledge-OTP/znk-ui-components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import { SET_DATA_MODAL_FORM_LIST } from '../../../../store/modal/const'
import addCustomService from '../../../../Model/Users/Student/SetServiceRateToStudent'
import useTeachingTypesToEducatorService from '../../../../Model/Users/Educators/SetTeachingTypesToEducatorService'
import { FormGroup, Input, Label } from 'reactstrap'
import escapeRegExp from 'lodash/escapeRegExp'

const AddServiceCustom = (props) => {
  const { services } = props
  const { isOpen, typeUser, userId, ...formData } = useSelector((state) => state.modal.user.addCustomService)
  const { form } = formData
  const [serForm, setSerForm] = useState({ teachingTypes: ['individual'], topics: [], individualAmount: 25, groupAmount: 25, isWaiveIndividual: false, isWaiveGroup: false })
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const toggle = () => {
    setSerForm({ teachingTypes: ['individual'], topics: [], individualAmount: 25, groupAmount: 25, isWaiveIndividual: false, isWaiveGroup: false })
    dispatch({ type: SET_DATA_MODAL_FORM_LIST, payload: 'user.addCustomService', data: { isOpen: false } })
  }
  const { submit, loading, validationErrors } = addCustomService(() => {
    toggle()
  }, console.log)
  const { submit: SubmitEducator, loading: loadingEducator, validationErrors: validationErrorsEducator } = useTeachingTypesToEducatorService(() => {
    toggle()
  }, console.log)
  const onSubmit = () => {
    if (typeUser === 'student') {
      const newVal = {
        userId: userId,
        studentServiceRateInput: {
          serviceId: serForm.idservice,
          defaultRate: serForm.amount
        }
      }
      submit(newVal)
    } else {
      const newVal = {
        userId: userId,
        serviceId: serForm.idservice,
        topicId: serForm.idtopic,
        teachingTypes: serForm.teachingTypes,
        individualRate: serForm.individualAmount,
        groupRate: serForm.groupAmount,
        isWaiveIndividual: serForm.isWaiveIndividual,
        isWaiveGroup: serForm.isWaiveGroup
      }
      SubmitEducator(newVal)
    }
  }
  useEffect(() => {
  }, [validationErrors, validationErrorsEducator])
  useEffect(() => {
    if (form && form.country && form.country.servicesRate) {
      const cntSer = formData.form.country.servicesRate.filter(e => e.serviceId === serForm.idservice)
      const tps = services.filter(e => e.id === serForm.idservice)
      if (typeUser === 'student') {
        setSerForm(pre => ({
          ...pre,
          amount: cntSer.length > 0 ? cntSer[0].defaultRate : form.country.defaultStudentRate,
          topics: tps.length > 0 ? tps[0].topics : []
        }))
      } else {
        setSerForm(pre => ({
          ...pre,
          amount: cntSer.length > 0 ? cntSer[0].defaultRateEducator ? cntSer[0].defaultRateEducator : formData.form.country.defaultEducatorRate : formData.form.country.defaultEducatorRate,
          topics: tps.length > 0 ? tps[0].topics : []
        }))
      }
    }
    // eslint-disable-next-line
  }, [serForm.idservice])
  return (
    <>
      <div className='m-0 p-0' onClick={toggle}>
        {props.children}
      </div>
      <Modal
        buttonLabel={null}
        className='modal-centered'
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header d-flex align-items-center pb-0 my-3'>
            <span className='flex-grow-1 text-title font-weight-bold h2'>Add Custom Service</span>
            <IconClose size={20} onClick={toggle} />
          </div>
        }
      >
        <Form value={serForm}>
          <div className='m-0 p-0 mb-2 px-3'>
            {
              typeUser === 'student' && (<>
                <label className='text-gray font-weight-light mb-2'>Service</label>
                <Field
                  className='col-12'
                  type={InputSelect}
                  fieldName='idservice'
                  placeholder={translate('modal.newplan.selectservice')}
                  options={[{ value: '0', label: translate('modal.newplan.selectservice') }].concat(services.map((serv) => ({ value: serv.id, label: serv.name })))}
                  InputClassName='w-100'
                  onChange={(value) => {
                    setSerForm(pre => ({
                      ...pre,
                      idservice: value
                    }))
                  }}
                  filterOption={(obj, string) => {
                    if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                    return false
                  }}
                />
                <label className='text-gray font-weight-light mt-3 mb-2'>Amount</label>
                <Field
                  className='col-12 placeholder-to-right'
                  type={AmountInput}
                  fieldName='amount'
                  fieldType='number'
                  placeholder='$  0.00 '
                  min='0'
                  onChange={(value) => {
                    setSerForm(pre => ({
                      ...pre,
                      amount: value
                    }))
                  }}
                />
              </>)
            }
            {
              typeUser === 'educator' && (
                <>
                  <Field
                    className='col-12 row inline'
                    type={SelectCheck}
                    fieldName='typePlan'
                    options={[
                      { id: 'individual', text: 'Individual' },
                      { id: 'group', text: 'Group' }
                    ]}
                    StyleOptionClassName={{
                      width: '49%',
                      whiteSpace: 'nowrap'
                    }}
                    StyleSelect={{
                      justifyContent: 'space-between'
                    }}
                    value={serForm.teachingTypes}
                    onChange={
                      (value) => {
                        //debe haber al menos un teachingType seleccionado
                        if(value?.length === 0) return
                        setSerForm(pre => ({
                          ...pre,
                          teachingTypes: value
                        }))
                      }
                    }
                  />
                  <label className='text-gray font-weight-light mt-3 mb-2'>Service</label>
                  <Field
                    className='col-12'
                    type={InputSelect}
                    fieldName='idservice'
                    placeholder={translate('modal.newplan.selectservice')}
                    options={[{ value: '0', label: translate('modal.newplan.selectservice') }].concat(services.map((serv) => ({ value: serv.id, label: serv.name })))}
                    InputClassName='w-100'
                    onChange={(value) => {
                      setSerForm(pre => ({
                        ...pre,
                        idservice: value
                      }))
                    }}
                    filterOption={(obj, string) => {
                      if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                      return false
                    }}
                  />
                  <label className='text-gray font-weight-light mt-3 mb-2'>Topic</label>
                  <Field
                    className='col-12'
                    type={InputSelect}
                    fieldName='idtopic'
                    placeholder={translate('modal.newplan.selectservice')}
                    options={[{ value: '0', label: 'select' }].concat(serForm.topics.map((serv) => ({ value: serv.id, label: serv.name })))}
                    InputClassName='w-100'
                    onChange={(value) => {
                      setSerForm(pre => ({
                        ...pre,
                        idtopic: value
                      }))
                    }}
                    filterOption={(obj, string) => {
                      if (new RegExp(escapeRegExp(string), 'i').test(obj.label)) return true
                      return false
                    }}
                  />
                  <label className='text-gray font-weight-light mt-3 mb-2'>Individual wage</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={AmountInput}
                    fieldName='individualAmount'
                    fieldType='number'
                    placeholder='$  0.00 '
                    disabled={!serForm.teachingTypes.includes('individual')}
                    min='0'
                    onChange={(value) => {
                      setSerForm(pre => ({
                        ...pre,
                        individualAmount: value
                      }))
                    }}
                  />
                  <label className='text-gray font-weight-light mt-2 mb-2'>Group wage</label>
                  <Field
                    className='col-12 placeholder-to-right'
                    type={AmountInput}
                    fieldName='groupAmount'
                    fieldType='number'
                    placeholder='$  0.00 '
                    disabled={!serForm.teachingTypes.includes('group')}
                    min='0'
                    onChange={(value) => {
                      setSerForm(pre => ({
                        ...pre,
                        groupAmount: value
                      }))
                    }}
                  />
                  <label className='text-gray font-weight-light mt-4 mb-2'>Payments</label>
                  <FormGroup check className='mt-3'>
                    <Label check>
                      <Input
                        type='checkbox'
                        defaultChecked={serForm.isWaiveIndividual}
                        disabled={!serForm.teachingTypes.includes('individual')}
                        onChange={() => {
                          setSerForm(pre => ({
                            ...pre,
                            isWaiveIndividual: !serForm.isWaiveIndividual
                          }))
                        }}
                      />{' '}
                      Waive individual lessons
                    </Label>
                  </FormGroup>
                  <FormGroup check className='mt-3'>
                    <Label check>
                      <Input
                        type='checkbox'
                        defaultChecked={serForm.isWaiveGroup}
                        disabled={!serForm.teachingTypes.includes('group')}
                        onChange={(e) => {
                          setSerForm(pre => ({
                            ...pre,
                            isWaiveGroup: !serForm.isWaiveGroup
                          }))
                        }}
                      />{' '}
                      Waive group lessons
                    </Label>
                  </FormGroup>
                </>
              )
            }
            <div className='row d-flex m-0 p-0 mt-5'>
              <div className='col-12 p-0 '>
                <StickyButton>
                  <div className='m-0 p-0 row'>
                    <Button
                      color='primary'
                      activity={loading || loadingEducator}
                      className='col-12 aling-text-left'
                      label='Save'
                      onClick={onSubmit}
                      style={{ borderRadius: '16px' }}
                      check='yes'
                    />
                  </div>
                </StickyButton>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default AddServiceCustom
