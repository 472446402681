import React, {useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {EXECUTE_COPY_AGAIN} from '../../store/clipboard/const'
//import {CopyToClipboard} from 'react-copy-to-clipboard'
//import ClipboardJS from 'clipboard'
//const isOS = () => {
//    const isChrome = !!navigator.userAgent.match(/Chrome/i)
//    let isSafari = false
//    if(!isChrome) isSafari = !!navigator.userAgent.match(/Safari/i) 
//    const isMac = !!navigator.userAgent.match(/Macintosh|Mac/i)
//    return isMac && isSafari
//}

//const Clipboard = ((window, document) => {
//    let textArea,
//        copy;
//
//    
//
//    const createTextArea = (text) => {
//        textArea = document.createElement('textArea');
//        textArea.value = text;
//        document.body.appendChild(textArea);
//    }
//
//    const selectText = () => {
//        let range,
//            selection;
//
//        if (isOS()) {
//            range = document.createRange();
//            range.selectNodeContents(textArea);
//            selection = window.getSelection();
//            selection.removeAllRanges();
//            selection.addRange(range);
//            textArea.setSelectionRange(0, 999999);
//        } else {
//            textArea.select();
//        }
//    }
//
//    const copyToClipboard = () => {        
//        document.execCommand('copy');
//        document.body.removeChild(textArea);
//    }
//
//    copy = (text, onSuccess, onError) => {
//        try {
//            createTextArea(text);
//            selectText();
//            copyToClipboard();
//            onSuccess()
//        } catch (e) {
//            onError(e)
//        }
//    };
//
//    return {
//        copy: copy
//    };
//})(window, document, navigator);



const CopyText = () => {
    const spanRef = useRef(null)
    const dispatch = useDispatch()
    const {copyText, onCopySuccess, onCopyError, executeCopy} = useSelector((state) => state.clipboard) 
    //let clipboard = new ClipboardJS('#copyTextTrigger');
    //console.log("clipboard", clipboard)
    ////onSuccess
    //clipboard.on('success', (e) => {
    //    onCopySuccess(copyText)
    //    e.clearSelection();
    //});
    ////onError
    //clipboard.on('error', (e) => {
    //    console.log("Copy error : ", e)
    //    onCopyError(e, copyText)
    //});
    //useEffect(() => {
    //    return clipboard.destroy()
    //}, [clipboard])
    //recibe texto y lo copia en el portapapeles
    useEffect(() => {
        if(copyText && spanRef?.current && executeCopy) {
            //if(isOS() && false) {
            //    console.log("usa isOS")
            //    navigator.clipboard.writeText(copyText).then(
            //        () => {onCopySuccess(copyText)},
            //        (e) => {onCopyError(e, copyText)}
            //    )
            //    //Clipboard.copy(copyText, () => {onCopySuccess(copyText)}, (e) => {onCopyError(e, copyText)});
            //}
            //else {
            //    console.log("usa npm")
            //    
            //    //spanRef.current.click()
            //}
            navigator.clipboard.writeText(copyText).then(
                () => {onCopySuccess(copyText)},
                (e) => {onCopyError(e, copyText)}
            )
            dispatch({
                type: EXECUTE_COPY_AGAIN,
                executeCopy: false,
            })
        }
    }, [spanRef, copyText, executeCopy, onCopyError, onCopySuccess, dispatch])
    return (
        <div className={`position-absolute`} style={{top: '-100%'}}>
            {/**<CopyToClipboard text={copyText} onCopy={(text, result) => {
                if(result) onCopySuccess(text)
                else onCopyError(null, copyText)
                dispatch({
                    type: EXECUTE_COPY_AGAIN,
                    executeCopy: false,
                })
            }}>
            <span ref={spanRef}></span>
            </CopyToClipboard>**/}
            <input id="copyText" value={copyText || ''} onChange={() => {}} />
            <button ref={spanRef} className={'btn'} id="copyTextTrigger" data-clipboard-target="#copyText">
                <img src="assets/clippy.svg" alt="Copy to clipboard"/>
            </button>
        </div>
    )
}

export default CopyText