import { IconArrowRight, IconPayslip, InvoiceCard, ResumenInvoice } from '@Knowledge-OTP/znk-ui-components'
import React, { useMemo, useState } from 'react'
import moment from 'moment'
import './index.css'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../../../i18n/useTranslation'
import { Collapse } from 'reactstrap'

const Paylips = (props) => {
  const { educator, loading } = props
  const { translate } = useTranslation()
  const [collapse, SetCollapse] = useState(false)
  const nextPay = useMemo(() => {
    if (educator && educator.educatorInfo && educator.educatorInfo.myPayslips) {
      const pendingPayslips = educator.educatorInfo.myPayslips.filter(
        (e) => e.status === 'pendingPayment'
      )
      if (pendingPayslips.length > 0) {
        const sortedPayslips = pendingPayslips.sort(
          (a, b) => new Date(b.payslipDate) - new Date(a.payslipDate)
        )
        return {
          next: true,
          payslip: sortedPayslips[0],
        }
      }
    }
    return { next: false, payslip: {} };
  }, [educator])

  const more = []
  console.log(nextPay)
  return (
    <div className='d-flex flex-column m-0 p-0 pr-6'>
      {
        (loading)
          ? <Skeleton height={190} count={1} className='w-100' />
          : <div className='mt-4'>
            <InvoiceCard
              empty={!nextPay}
              type='normal'
              text={`${educator && nextPay?.next ? translate('student.modal.payments.endOf') : 'Nothing Due'} ${educator && nextPay?.next ? moment(nextPay.payslip?.payslipProps?.to).format('MMMM') : ''}`}
              textTop='Next pay:'
              typePayment=''
              amount={educator && nextPay?.next ? nextPay?.payslip?.payslipProps?.amount : 0}
              invoice={`PAY-${educator && nextPay?.next ? String(nextPay?.payslip?.payslipNumber).padStart(5, 0) : ''}`}
              emptyText=''
            />
        </div>
      }
      <div className='mx-0 mt-6 mb-3 p-0 d-flex flex-row justify-content-between'>
        <span className='font-weight-bold text-title'>{translate('student.modal.payments.lastpayment')}</span>
        <span className='text-gray'>{translate('student.modal.payments.viewHistory')}</span>
      </div>
      {
        educator && educator.educatorInfo.myPayslips.filter(e => e.status === 'paid')
          .sort((a, b) => new Date(b.payslipDate) - new Date(a.payslipDate))
          .map(
          (ele, index) => {
            if (index >= 10) {
              more.push(ele)
            }
            return index < 10 ? (
              <ResumenInvoice
                key={index}
                type='green'
                text={`${translate('student.modal.payments.endOf')} ${moment(ele.to).format('MMMM')}`}
                className='mx-0 my-1 px-2 py-1'
                amount={ele.payslipProps.amount}
                icon={<IconPayslip color='#35425B' className='mr-3' />}
              />
            ) : null
          }
        )
      }
      <Collapse
        isOpen={collapse}
      >
        {
          more.map(
            (ele, index) => {
              return (
                <ResumenInvoice
                  key={index}
                  type='green'
                  text={`${translate('student.modal.payments.endOf')} ${moment(ele.to).format('MMMM')}`}
                  className='mx-0 my-1 px-2 py-1'
                  amount={ele.payslipProps.amount}
                  icon={<IconPayslip color='#35425B' className='mr-3' />}
                />
              )
            }
          )
        }
      </Collapse>
      {
        more.length > 0 && (
          <div
            style={{ cursor: 'pointer' }}
            className='znk-card empty mx-0 my-1 px-2 py-1'
            onClick={() => {
              SetCollapse(!collapse)
            }}
          >
            <div className='left'>
              <div className='h45 text-gray'>{collapse === false ? translate('student.modal.payments.viewAll') : translate('student.modal.payments.viewLess')}</div>
            </div>
            <div><IconArrowRight size={15} className='text-gray' /></div>
          </div>)
      }
    </div>
  )
}

export default Paylips
