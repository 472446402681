import React, { useMemo } from 'react'
import { IconArrowDown, CheckboxSelector } from '@Knowledge-OTP/znk-ui-components'
import { Badge } from 'reactstrap'
import useUsers from '../../../../Model/useUsers'
const Students = (props) => {
    const {filters = [], setFilters = () => {}} = props
    const {data} = useUsers()
    
    const allUsers = useMemo(
        () => {
            const users = [...(data?.users || [])]
            return users?.map((user) => {
                return {...user, name: `${user.firstName} ${user.lastName}`}
            }).sort((a, b) => a.name.localeCompare(b.name)) || []
        }, [data]
    )
    return (
        <CheckboxSelector
            setDataFilters={setFilters}
            direction='bottom'
            dataFilters={filters}
            data={allUsers}
            typeFilter='status'
            strings={{
                viewAll: 'View all',
                buttonCancel: 'Cancel',
                buttonApply: 'Apply',
                search: 'Search'
            }}
            >
            <Badge className='mr-1 filters' pill color='option'>
                <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                Students
                <IconArrowDown className='ml-2' size={15} />
                </label>
            </Badge>
        </CheckboxSelector>
    )
}

export default Students