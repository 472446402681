import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'

export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation (
      $invoiceId: ID!
    ) {
        voidInvoice(
            invoiceId: $invoiceId,
      )
    }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const voidInvoice = useCallback((obj) => {
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.voidInvoice : null, error, validationErrors, voidInvoice }
}
