import { useLazyQuery, gql } from "@apollo/client";

const useGetChargeFromStripe = (onCompleted = () => {}, onError = () => {}) => {
    return useLazyQuery(
        gql`
            query (
                $invoiceId: String!
            ) {
                getChargeFromStripe (
                    invoiceId: $invoiceId
                ) 
            }
        `, {
            onCompleted, onError,
            fetchPolicy: 'no-cache',
        }
    )
}

export default useGetChargeFromStripe
