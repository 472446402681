import React from 'react'
import { 
    IconLoad, IconMailchimpSwitchOpen, 
    IconMailchimpSwitchClose, ToolTipInfoLight,
} from '@Knowledge-OTP/znk-ui-components'
import useSincronizeUserMailchimp from './useSincronizeUserMailchimp'
import "./index.css"
import { TabContent, TabPane } from 'reactstrap'
import useTranslation from '../../../../../../i18n/useTranslation'

const SincronizeUserMailchimpItem = (props) => {
    const { onClick = () => { }, className='', userId, ...otherProps } = props
    const { submit, loading } = useSincronizeUserMailchimp(
        () => {},
        () => {}
    )
    const {translate} = useTranslation()
    return (
        <ToolTipInfoLight 
            text={<span className='h45'>{translate("misc.mailchimpSincronize.tooltipMsg") || "Sincronize user data on mailchimp."}</span>}
        >
            
        <div
            onClick={() => {
                if (userId) submit(userId)
                if (onClick) onClick()
            }}
            className={`cursor-pointer scale-hover position-relative d-flex flex-row align-items-center justify-content-center ${className}`}
            {...otherProps}
        >
            {
                loading && (
                    <div className='position-absolute'>
                        <TabContent activeTab={loading ? 'icon' : 'none'}>
                            <TabPane tabId={'icon'}><IconLoad size={34} /></TabPane>
                            <TabPane tabId={'none'}></TabPane>
                        </TabContent>
                    </div>
                )
            }
            <div className='w-inherit h-100'>
                {
                    loading ?
                        <IconMailchimpSwitchClose  size={140} /> :
                        <IconMailchimpSwitchOpen size={140} />
                }
            </div>
        </div>
        </ToolTipInfoLight>
    )
}

export default SincronizeUserMailchimpItem