import {useMutation, gql} from '@apollo/client'

const useCompleteAllPlans = (onSuccess = () => {}, onError = () => {}) => {
    return useMutation(
        gql`
        mutation  {
            completeAllPlans {
                id
            }
        }
        `,
        {
            onCompleted: (res) => {
                onSuccess(res)
            },
            onError
        }
    )
}

export default useCompleteAllPlans