import {useSelector, useDispatch} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
const useConfirmAddCollege = () => {
    const reduxData = useSelector((state) => state.modal.counseling.confirmAppendCollege)
    const {isOpen} = reduxData
    const dispatch = useDispatch()

    const setData = (isOpen, onChange) => {
        dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'counseling.confirmAppendCollege',
            data: {
              ...reduxData,
              isOpen,
              onChange
            },
        })
    }

    return {
        ...reduxData,
        open: (onChange = () => {}) => {
            setData(true, onChange)
        },
        toggle: () => {setData(!isOpen)},
        close: () => {setData(false)},
    }
} 

export default useConfirmAddCollege