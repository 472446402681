export const userTaskResult = `
    id
    idReference
    name
    position
    instructions
    type
    dueTime
    buttonLabel
    isEspecific
    startDate
    dueDate
    reviewDate
    completedDate
    canceledDate
    linkToShow
    serviceDate
    examId
    chatNotification {
        councelor
        student
    }
    selectedExam {
        id: _id
        name
    }
    resolveTask {
        link {
            lastProcessedAt
            accessedCount
        }
        loginInfo {
            platform{
                name
                username
                password
            }
        }
        serviceId
    }
    resolveTask {
        link {
            lastProcessedAt
            accessedCount
        }
        loginInfo {
            platform{
                name
                username
                password
            }
        }
        score {
            name
            examDate
            overall
            results { 
                name
                scale
                value
                values {
                    name
                    scale
                    value
                }
            }
        }
        meeting
        uploadFiles {
            url
            uploadDate
        }
        completion
    }
    accountsPlatform
    universities
    status
    userId
    isReviewable
    showInDocs
    uploadDocs
    userMeetingId
    deleteAt
`

export default userTaskResult