import React, {useState, useMemo, useRef} from 'react'
import {
    Table,
    // IconStar,
    Button,
    IconSearch,
    IconSignPlus
} from '@Knowledge-OTP/znk-ui-components'
import Pagination from '../../common/Pagination'
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  TabPane, TabContent, Badge
} from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import useResponsivePlanColumns from './Columns/useResponsivePlanColumns'
import useCreateTimelineModal from './Modal/CreateTimelineModal/useCreateTimelineModal'
import useAllTimelines from './Model/useAllTimelines'
import useTimelineReducer from '../../reducers/timeline'
import CountryFilter from './Filters/Country'
import RangeTimeFilter from './Filters/Range'
import ActivitiesFilter from './Filters/Activities/Activities'
import ServicesFilter from './Filters/Activities/Services'
import RedirectToCreatePlan from './RedirectToCreatePlan'
import NewPlan from '../Plans/modal/newPlan'
import lodash from 'lodash'
import { useEffect } from 'react'

const defaultFilters = {
    //pagination
    limit: 10,
    previous: null,
    next: null,
    //filters
    search: null,
    status: '',
    type: '',
    countryIds: null,
    startDate: null,
    endDate: null,
    activitiesIds: [],
    servicesIds: [],
    //shortcuts
    allTimelines: true
}


const Timelines = () => {

    //objeto con filtros aplicados a listado de timelines
    const [filters, setFilters] = useState(defaultFilters)

    const [queryParams, setQueryParams] = useState({
        limit: filters?.limit || 10,
        previous: '',
        next: '',
        search: null,
        countryIds: null,
        startDate: null,
        endDate: null,
        activitiesIds: [],
        servicesIds: []
    })
    const {data, loading: loadTimelines, refetch: refetchTimelines} = useAllTimelines(queryParams)
    useEffect(() => {
        if(queryParams) {
            //reejecuta la query
            refetchTimelines(queryParams)
        }
    }, [queryParams, refetchTimelines])

    const {docs: allTimelines, ...timelinesFill} = useMemo(
        () => {
            if(data?.allTimelines) return {
                ...data?.allTimelines,
                docs: data?.allTimelines?.docs.map(
                    (timeline) => {
                        return {
                            ...timeline,
                            getPlans: (timeline?.getPlans || []).concat(
                                timeline?.getScheduledPlans.map(
                                    (plan) => {return {...plan, deleteAt: null}}
                                ) || [])
                        }
                    }
                )
            }
            return {}
        }, [data]
    ) 
    useEffect(() => {
        //cada vez que se actualizan los filtros se reejecuta la query
        setQueryParams(
            {
                ...queryParams,
                search: filters.search, 
                limit: filters.limit,
                previous: filters?.previous,
                next: filters?.next,
                countryIds: filters?.countryIds,
                startDate: filters?.startDate,
                endDate: filters?.endDate,
                activitiesIds: filters?.activitiesIds,
                servicesIds: filters?.servicesIds,
            }
        )
        // eslint-disable-next-line
    }, [filters])

    const columns = useResponsivePlanColumns(null, refetchTimelines)
    
    const [currentPage, setCurrentPage] = useState(1)

    const {open: openCreateTimelineModal, close: closeCreateTimelineModal} = useCreateTimelineModal()

    //timeline reducer for parse method
    const {parseTimelineFromAPI} = useTimelineReducer()
    const allTimelinesParse = useMemo(
        () => {
            if(allTimelines) {
                return allTimelines
                ?.filter(({deleteAt}) => !deleteAt || deleteAt === null )
                ?.map(
                    (timeline) => {
                        return {
                            ...timeline,
                            studentUser: timeline.studentUser,
                            timeline: parseTimelineFromAPI({
                                plans: timeline?.getPlans || [],
                                events: timeline?.getTestAndEvents || []
                            })
                        }
                    }
                )
            }
            return []
            // eslint-disable-next-line
        }, [allTimelines]
    )

    //referencia al campo de busqueda
    const searchField = useRef(null)

    const resetFilters = () => {
       setFilters(defaultFilters)
    }
    const canReset = () => {
        return !lodash.isEqual(filters, defaultFilters)
    }
    return (
        <div className={`col-12 col-md-12 px-md-6 px-lg-7 d-flex flex-column justify-content-between`} style={{minHeight: '100%'}}>
            <div className={`d-flex flex-column m-0 p-0`}>
                <div className='container-fluid mt-2 mt-md-5 px-2 d-flex flex-column-reverse flex-md-row'>
                    <div className='d-flex flex-grow-1 align-items-center znk-scroll-horizontal mt-2 pr-0 pr-md-2 mt-md-0'>
                        {/* SHORTCUTS */}
                        <span className={`nav-title ${filters?.allTimelines ? 'active' : ''} m-0 mr-3`}>All timelines</span>
                    </div>
                    <div className='d-md-flex d-none align-items-center justify-content-end justify-content-md-start flex-row-reverse flex-md-row'>
                        <span>
                            <Button label='+ New Timeline' className='btn-table' color='primary' onClick={() => {
                                openCreateTimelineModal(() => {
                                    refetchTimelines()
                                    closeCreateTimelineModal()
                                }, null, null, true)
                            }} />
                        </span>
                    </div>
                </div>
                <div className={`d-flex flex-row m-0 p-0 justify-content-between`} style={{minHeight: '68px'}}>
                    <div className={`container-fluid mt-2 mb-4 mt-md-5 px-0 d-flex znk-scroll-horizontal`}>
                        {/* FILTROS */}
                        <CountryFilter
                            setFilters={(countries) => {
                                setFilters({...filters, countryIds: countries})
                            }}
                            filters={filters?.countryIds || []}
                        />
                        <RangeTimeFilter 
                            filters={[filters.startDate, filters.endDate]} 
                            setFilters={(res) => {
                                setFilters({...filters, startDate: res[0], endDate: res[1]})
                            }}
                        />
                        {/**para timelines con planes que contengan el o los servicios**/}
                        <ServicesFilter
                            setFilters={(res) => {
                                setFilters({...filters, servicesIds: res})
                            }}
                            filters={filters.servicesIds}
                        />
                        {/**para timelines que contengan los test date/eventos seleccionados**/}
                        <ActivitiesFilter
                            filters={filters.activitiesIds} 
                            setFilters={(res) => {
                                setFilters({...filters, activitiesIds: res})
                            }}
                        />

                        {
                            canReset()
                                ?   <Badge 
                                        className='mx-1 filters cursor-pointer' pill color='option' 
                                        onClick={() => {resetFilters()}}
                                    >
                                        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
                                            <IconSignPlus size={15} className='mr-1' />
                                            Clear filters
                                        </label>
                                    </Badge>
                                : null
                        }
                    </div>
                    <InputGroup className={`znk-card w-25 flex-nowrap`} title={'Search'} >
                        <input
                            className={"znk-input noRight trans form-control"}
                            placeholder={'Search by name or student id'}
                            ref={searchField}
                            onKeyUp={({key}) => {
                                if(key === 'Enter') {
                                    //setea texto en filters.search para reejecutar la query
                                    setFilters({...filters, search: searchField?.current?.value})
                                }
                            }}
                        />
                        <InputGroupAddon addonType='prepend' className='icon'>
                            <InputGroupText type='text' className='znk-no-border'>
                            <span className='addon'><IconSearch size={18} className='text-gray' /></span>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </div>
                <div className={`m-0 p-0`}>
                    <TabContent activeTab={loadTimelines ? 'loading' : 'table'}>
                        <TabPane tabId={`loading`}>
                            <div className={`p-0 m-2`}>
                                <Skeleton count={10} height={43} style={{borderRadius: '10px'}} />
                            </div>
                        </TabPane>
                        <TabPane tabId={`table`}>
                            <Table
                                loading={loadTimelines}
                                tableData={allTimelinesParse}
                                columnList={columns}
                                style={{cursor: 'pointer'}}
                                onClick={(currentTimeline) => {
                                    //abre modal de edición de timeline
                                    openCreateTimelineModal(() => {
                                        refetchTimelines()
                                        closeCreateTimelineModal()
                                    }, currentTimeline?.studentUser, currentTimeline?.timeline, true, currentTimeline?.id )
                                }}
                            />
                        </TabPane>
                    </TabContent>
                </div>
            </div>
            <Pagination
                style={{
                    bottom: '0px',
                    position: 'sticky',
                    backgroundImage: `linear-gradient(
                        to bottom, 
                        rgba(255, 255, 255, 0), 
                        rgba(255, 255, 255, 0.95) 25%
                    )`,
                    paddingTop: '15px'
                }}
                pageSize={filters?.limit}
                changeSize={(limit) => {
                    setFilters({...filters, limit})
                }}
                clickNext={() => {
                    setFilters({...filters, next: timelinesFill?.next, previous: null})
                    setCurrentPage(currentPage + 1)
                }}
                clickPrevious={() => {
                    setFilters({...filters, next: null, previous: timelinesFill?.previous})
                    setCurrentPage(currentPage - 1)
                }}
                loading={loadTimelines}
                hasPrevious={timelinesFill?.hasPrevious}
                hasNext={timelinesFill?.hasNext}
                activePage={currentPage}
                pageCount={Math.ceil((timelinesFill.totalDocs/filters.limit) || 1)}
            />
            <RedirectToCreatePlan/>
            <NewPlan refetch={() => {
                refetchTimelines()
            }} educators={[]} />
        </div>
    )
}

export default Timelines