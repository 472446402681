import React, { useState } from 'react'
import {
  Modal,
  IconClose,
  ListItem,
  IconArrowRight,
  IconCountry,
  LessonCard,
  StickyButton,
  Button
} from '@Knowledge-OTP/znk-ui-components'
import { useSelector, useDispatch } from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment'

const RescheduledPendingLessons = (props) => {
  const { lessons } = props
  const { isOpen } = useSelector((state) => state.modal.lesson.rescheduledpending)
  const { selectedUserData: selectedUserDataRedux, selectedArrayUserData } = useSelector((state) => state.modal.calendar.selectUser)
  const selectedUserData = selectedArrayUserData[0] || selectedUserDataRedux || {}
  const dispatch = useDispatch()
  const { translate } = useTranslation()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'lesson.rescheduledpending',
      data: { isOpen: !isOpen }
    })
  }
  const zone = useSelector((state) => state.timezone.zone)
  const [selectedLesson, setSelectedLesson] = useState(null)
  const separateCamelCase = (str) => {
    if (str)
      str = str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    return str
  }

  //SERVICIO ORIENTACIÓN
  const services = useSelector((state) => state.user.services)
  const orientation = services?.find((serv) => serv.isOrientation)


  return (
    <Modal
      buttonLabel={null}
      className='modal-colored modal-centered fixed-head-modal'
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header px-6 pt-6 pb-4 d-flex flex-column w-100'>
          <div className='d-flex flex-row justify-content-between w-100'>
            <span className='h2 font-weight-bold'>
              {translate('modal.infolesson.haspending', { 
                name: selectedUserData?.firstName, 
                num: lessons.length })}
            </span>
            <div className={'m-0 p-0 d-flex flex-column justify-content-start flex-grow-1'}>
              <div className={'hover-icon'}><IconClose onClick={toggle} size={20} /></div>
            </div>
          </div>
          <span className='h4 text-gray text-justify'>
            {translate('modal.infolesson.tocontinue')}
          </span>
        </div>
      }
    >
      <div className='m-0 px-6'>
        <ListItem
          className='py-3'
          showBottomLine
          showTopLine
          onClick={() => {
            dispatch({
              type: 'SET_DATA_MODAL_FORM_LIST',
              payload: 'lesson.changeTimezoneModal',
              data: {
                isOpen: true
              }
            })
          }}
          Left={
            <label className='d-flex flex-row text-gray align-items-center mb-0'>
              <IconCountry className='mr-2' />
              <span>Timezone</span>
            </label>
          }
          Right={
            <label className='d-flex flex-row text-gray align-items-center mb-0'>
              <span>{`${zone}`}</span>
              <IconArrowRight className='ml-2' />
            </label>
          }
        />
        <div className='d-flex flex-column my-2'>
          {lessons.map(
            (lesson) =>
              <LessonCard
                title={lesson.title}
                status={selectedLesson === lesson?.id ? 'upcoming' : 'pending'}
                showTime={false}
                preLabelTime={`${separateCamelCase(lesson?.location?.type || 'No defined')} 
                | ${separateCamelCase(lesson.__typename?.replace('Lesson', ''))}`}
                lessonTime={moment(lesson.startDate)}
                lessonEndTime={moment(lesson.endDate)}
                attendants={[
                  { name: `${lesson?.educator?.firstName} ${lesson?.educator?.lastName}`, avatar_url: null }
                ]}
                showCountdown={false}
                className='w-100 m-1 mb-1'
                onClick={() => { setSelectedLesson(lesson.id) }}
              />
          )}
        </div>
        <StickyButton>
          <div className='m-0 p-0 my-2 row'>
            <Button
              color='primary'
              activity={false}
              disabled={selectedLesson === null}
              className='col-12 aling-text-left'
              label={translate('modal.infolesson.continue')}
              onClick={() => {
                toggle()
                const lessonObj = lessons.find((lesson) => selectedLesson === lesson.id)
                const isOrientation = lessonObj?.service?.id === orientation?.id
                dispatch({
                  type: 'SET_DATA_MODAL_FORM_LIST',
                  payload: `plan.${isOrientation ? 'orientationLessonModal' : 'infoLessonModal'}`,
                  data: {
                    isOpen: true,
                    dataLesson: lessons.find((lesson) => selectedLesson === lesson.id)
                  }
                })
              }}
              style={{ borderRadius: '16px' }}
            />
          </div>
        </StickyButton>
      </div>
    </Modal>
  )
}

export default RescheduledPendingLessons
