import React from 'react'
import { useWindowDimensions } from '@Knowledge-OTP/znk-ui-components'

const Box = (props) => {
    const {
        colLg = 6, colXl = 6, colMd = 6, colSm = 12, height = `325px`,
        minHeight, maxHeight, onScroll = () => { }
    } = props

    const styleBox = {
        // overflowY: 'hidden'
        height: 'inherit'
    }
    const styleCard = {
        minHeight: '600px',
        maxHeight: '600px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column'
    }
    const { breakWidth } = useWindowDimensions()
    return (
        <div
            className={`col-${colSm} col-md-${colMd} col-lg-${colLg} col-xl-${colXl} py-4 ${breakWidth === 'SM' ? 'px-0' : 'px-4'}`}
            style={styleBox} onScroll={onScroll}
        >
            <div className={`dashboard-box secondaryBackground znk-scroll-horizontal`} style={{
                ...styleCard,
                minHeight: `${minHeight || height}`,
                maxHeight: `${maxHeight || height}`,
            }}>
                {props.children}
            </div>
        </div>
    )
}

export default Box