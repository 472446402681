import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import 'firebase/auth'
import FirebaseContext from '../../../contexts/FirebaseContext'
import firebaseOptions from './firebaseOptions'

const propTypes = {
  children: PropTypes.any
}

function FirebaseProvider({ children }) {
  const [app, setApp] = useState(null)
  useEffect(() => {
    let result
    if (global.firebaseProvider) {
      console.log('using global')
      result = global.firebaseProvider
    } else {
      console.log('resetting')
      if (firebase.apps.length) {
        console.log('setting from firebase apps')
        result = firebase.app()
      } else {
        console.log('initializing')
        result = firebase.initializeApp(firebaseOptions)
      }
      global.firebaseProvider = result
    }
    setApp(result)
  }, [])
  return <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
}

FirebaseProvider.propTypes = propTypes

export default FirebaseProvider
