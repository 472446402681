import { gql, useLazyQuery } from '@apollo/client'

const useGetEmailsFromUsers = () => {
  return useLazyQuery(
    gql`query(
            $role: Roles!, 
            $limit: Int, 
            $previous: String, 
            $next: String, 
            $search: String, 
            $serviceIds: [ID!], 
            $topicIds: [ID!], 
            $testDate: DateTime, 
            $classYear: [Int!], 
            $countriesIds: [ID!], 
            $statuses: Boolean, 
            $showInactive: Boolean
            ) {
            allUsersFilter(
                role: $role, 
                limit: $limit, 
                previous: $previous, 
                next: $next, 
                search: $search, 
                serviceIds: $serviceIds, 
                topicIds: $topicIds, 
                testDate: $testDate, 
                classYear: $classYear, 
                countriesIds: $countriesIds, 
                statuses: $statuses, 
                showInactive: $showInactive
                ) {
          hasNext
          hasPrevious
          next
          previous
          totalDocs
          docs {
            emails {
              address
            }
            studentInfo {
              parents {
                email
              }
            }
            parentInfo {
              children {
                studentUserId
                managePayments
                manageApprovals
                firstName
                lastName
                email
              }
            }
          }
        }
      }
    `,
      {
        fetchPolicy: 'no-cache'
      }
  )
}

export default useGetEmailsFromUsers

