import React, {useMemo} from 'react'
import {TimelinePlan} from '@Knowledge-OTP/znk-ui-components'
import useTimelineReducer from '../../../../../../reducers/timeline'
import useCreateTimelineModal from '../../../../../Timelines/Modal/CreateTimelineModal/useCreateTimelineModal'

const TimelineShoot = (props) => {
    const {plan} = props

    // timeline reducer for parse method
    const {parseTimelineFromAPI} = useTimelineReducer()
    const {parseToTimeline} = useCreateTimelineModal()
    const timeline = useMemo(() => {
        if (plan?.timelines) {
            return parseTimelineFromAPI({
                plans: plan?.timelines?.plans || [],
                events:
                (plan?.timelines?.events || []).concat(plan?.timelines?.test || []) ||
                [],
            })
        }
        // events: []
        // plans: [{…}]
        // tests: []
    }, [plan, parseTimelineFromAPI])

    return (
        <div>
        {timeline && (
            <TimelinePlan
                show
                events={
                    parseToTimeline(
                        timeline.filter(({statusBuild}) => statusBuild !== 'delete'),
                        () => {},
                        () => {},
                    )
                }
            />
        )}
        </div>
    )
}

export default TimelineShoot