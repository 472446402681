import React from 'react'
import { View, StyleSheet } from '@react-pdf/renderer'

const Section = (props) => {
    const styles = StyleSheet.create({
        section: props.style,
      });
    return (
        <View style={styles?.section || {}}>
            {props.children}
        </View>
    )
}

export default Section