import React from 'react'
import { IconArrowDown, DateRangeSelector } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import { Badge } from 'reactstrap'

const StartDateFilter = (props) => {
  const { translate } = useTranslation()
  const { setDateFilter, currentDates } = props
  return (
    <DateRangeSelector
      setFilters={(e) => {
        setDateFilter(e)
      }}
      filters={currentDates}
    >
      <Badge className='mr-1 filters' pill color='option'>
        <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
          {translate('filters.plans.startDateTitle')}
          <IconArrowDown className='ml-2' size={15} />
        </label>
      </Badge>
    </DateRangeSelector>
    // <FilterBetweenDates
    //   strings={{
    //     title: translate('filters.plans.startDateTitle'),
    //     buttonCancel: translate('filters.plans.buttonCancel'),
    //     buttonApply: translate('filters.plans.buttonApply'),
    //     from: translate('filters.plans.fromDate'),
    //     to: translate('filters.plans.toDate'),
    //     typeFilter: 'StartDate'
    //   }}
    //   dates={currentDates}
    //   setDates={setDateFilter}
    //   direction='bottom'
    // >
    //   <Badge className='mr-1 filters' pill color='option'>
    //     <label style={{ cursor: 'pointer' }} className='m-0 p-0'>
    //       {translate('filters.plans.startDateTitle')}
    //       <IconArrowDown className='ml-2' size={15} />
    //     </label>
    //   </Badge>
    // </FilterBetweenDates>
  )
}
export default StartDateFilter
