import React, { useState, useEffect } from 'react'
import {
  Modal,
  useWindowDimensions,
  Button,
  IconClose,
  IconArrowRight,
  Input
} from '@Knowledge-OTP/znk-ui-components'
import { useDispatch, useSelector } from 'react-redux'
import QueryPaymentsAndLessons from '../confirmCancelPlan/queryPaymentsAndLessons/index'
import ResumePlan from '../../../../Model/Plan/ResumePlan/index'
import useTranslation from '../../../../i18n/useTranslation'
import BreakdownAndPayment from '../confirmCancelPlan/BreakdownAndPayment'
import AddPayment from '../newPlan/AddPayment'
import moment from 'moment'

const ResumePlanModal = (props) => {
  const { isOpen, dataPlan, refetch } = useSelector((state) => state.modal.plan.resumePlanModal)
  const { breakWidth } = useWindowDimensions()
  const { translate } = useTranslation()
  const dispatch = useDispatch()
  const toggle = () => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'plan.resumePlanModal',
      data: { isOpen: !isOpen, dataPlan: dataPlan, refetch: refetch }
    })
  }
  const { submit: resume } = ResumePlan(
    (result) => {
      refetch()
    },
    () => {
      alert('error')
    }
  )
  const data = QueryPaymentsAndLessons({
    planId: dataPlan.id
  })

  const lessonsToResume = data && data.lessons && data.lessons.docs.filter(
    (lesson) =>
      (lesson.status === 'pause' && moment() < moment(lesson.date)) || (lesson.status === 'pending')).length

  const [paymentItemsConfig, setPaymentItemsConfig] = useState([])
  useEffect(() => {
    if (data && data.myPaymentsItems && data.myPaymentsItems.docs) {
      setPaymentItemsConfig(data.myPaymentsItems.docs)
    }
  }, [data])

  const parsePaymentToMutation = (payments) => {
    return payments.reduce((arrayAccu, payAux) => {
      arrayAccu.push({
        amount: payAux.amount,
        amountWithoutDiscount: payAux.amountWithoutDiscount,
        date: payAux.date,
        description: payAux.description,
        paymentId: payAux.id,
        percentDiscount: payAux.percentDiscount,
        type: payAux.type
      })
      return arrayAccu
    }, [])
  }

  return (
    <>
      <Modal
        buttonLabel={null}
        className={`modal-${
          breakWidth === 'SM' ? 'botbar' : 'centered'
        } modal-colored`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <div className='modal-header mx-5 mt-7 mb-1 d-flex flex-row'>
            <span className='h2 font-weight-bold text-title'>
              Resume Plan
            </span>
            <IconClose size={24} onClick={toggle} />
          </div>
        }
      >
        <div className='row mt-1 mx-4 mb-4 p-0'>
          <div className='m-0 p-0 w-100 mt-1 mx-5 mb-2'>
            <label className='text-gray h4 mb-3'>Resume date</label>
            <Input type='date' />
            <div className='borderTop my-4' />
          </div>
          <div className='m-0 p-0 w-100 mt-1 mx-5 mb-5'>
            <BreakdownAndPayment
              lessonsToText={`${lessonsToResume} Lessons to resume`}
              paymentsItems={paymentItemsConfig}
              action='schedule'
              setCuotas={setPaymentItemsConfig}
            />
            <AddPayment
              customPayment={false}
              deleteCuota={() => {}}
              setCuotas={(newAmount, date) => {
                const newPayment = {
                  amount: newAmount,
                  percentDiscount: 0,
                  amountWithoutDiscount: newAmount,
                  date: date === null ? moment().toISOString() : moment(date).toISOString(),
                  description: 'Payment created when plan is resumed',
                  status: 'pendingPayment',
                  type: 'lessonsPayment'
                }
                setPaymentItemsConfig(paymentItemsConfig.concat(newPayment))
              }}
              maxValue={Number.POSITIVE_INFINITY}
            >
              <label className='btn-link'>{translate('modal.newplan.paymentschedule.addmore')}</label>
            </AddPayment>
            <Button
              color='#696CFF'
              style={{ backgroundColor: '#696CFF' }}
              className='w-100 mt-3'
              label={
                <span className='w-100 d-flex' style={{ justifyContent: 'space-between' }}>
                  Resume Plan
                  <IconArrowRight size={20} />
                </span>
              }
              onClick={() => {
                resume({
                  PlanInputToresume: {
                    paymentItemsConfig: parsePaymentToMutation(paymentItemsConfig)
                  },
                  planId: dataPlan?.id
                })
                toggle()
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ResumePlanModal
