import React, { useState, useMemo } from 'react'
import {
    ModalLayout,
    useWindowDimensions,
    InputSelect,
    Button
} from '@Knowledge-OTP/znk-ui-components'
import { Label } from 'reactstrap'
import useSendWeeklyInsightModal from './useSendWeeklyInsightModal'
import useGetFreeStatics from './Model/useGetFreeStatics'
import useGetAllWeeklyInsights from './Model/useGetAllWeeklyInsights'
import moment from 'moment'
import {showAlert} from '../../../../../common/Alert/util'
import { useDispatch } from 'react-redux'
import useTranslation from '../../../../../i18n/useTranslation'

const SendWeeklyInsightModal = () => {
    const { breakWidth } = useWindowDimensions()
    const dispatch = useDispatch()
    const { isOpen, toggle, useMutation, onChange, close } = useSendWeeklyInsightModal()
    const {data, loading: loadWeeklyInsight} = useGetAllWeeklyInsights()
    const [form, setForm] = useState({})
    const {translate} = useTranslation()

    const options = useMemo(
        () => {
            if(data?.getAllWeeklyInsights) {
                return (data?.getAllWeeklyInsights || []).map(({startDate}) => {
                    const week = moment(startDate).day(3)
                    return {
                        value: week.clone().format('YYYY-MM-DD'),
                        label: `${week.clone().startOf('isoweek').format('Do MMM')} - ${week.clone().endOf('isoweek').format('Do MMM, 2023')}`
                    }
                })
            }
            return []
        }, [data]
    )
    const [getStatics, {loading}] = useGetFreeStatics(
        () => {
            close()
            showAlert({text: translate("misc.weeklyInsight.successMsg") || 'Success!', status: 'success'}, dispatch)},
        (err) => {showAlert({text: err.toString(), status: 'success'}, dispatch)}
    )
    return (
        <ModalLayout
            className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'
                } modal-hauto modal-noborder-bottom`}
            isOpen={isOpen}
            toggle={toggle}
            disabledScrollAnimation={true}
            topHead={
                <div className={`d-flex flex-column`}>
                    <span className={`h2 text-title font-weight-bold mt-2`}>{translate("misc.weeklyInsight.titleModal") || "Send Weekly Insight"}</span>
                </div>
            }
        >
            <Label for='week'>{translate("misc.weeklyInsight.selectWeekLabel") || "Select week"}</Label>
            <InputSelect
                id='week'
                options={options}
                name='week'
                value={form?.week}
                onChange={(e) => {setForm({ ...form, week: e })}}
                isLoading={loadWeeklyInsight}
            />
            <Button
                className={'w-100 mt-4'}
                size={'medium'}
                color={'primary'}
                label={translate("misc.weeklyInsight.sendMail") || 'Send Mail'}
                disabled={!form?.week}
                activity={loading}
                onClick={() => {
                    if(useMutation) getStatics({variables: {...form, week: moment(form.week).toISOString()}})
                    else onChange(form)
                }}
            />

        </ModalLayout>
    )
}

export default SendWeeklyInsightModal