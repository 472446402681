import { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import validate from './validation'
export default (onSuccess, onError) => {
  const [mutation, { data, error, loading }] = useMutation(
    gql`mutation editLessonPaymentItemFromAdmin(
        $paymentItemId: ID!,
        $paymentInput: CreatePaymentItemInput!
    ){
        editLessonPaymentItemFromAdmin(paymentInput: $paymentInput, paymentItemId: $paymentItemId){
          ... on PlanPaymentItem {
            planId
            planItems {
              id
              kind
              amount
              amountWithoutDiscount
              percentDiscount
              status
              date
              category
              draft
              lastProcessedAt
              description
              type
            }
          }
          ... on LessonPaymentItem {
            id
            kind
            amount
            amountWithoutDiscount
            percentDiscount
            status
            date
            category
            draft
            lastProcessedAt
            description
            type
          }
          ... on EducatorPaymentItem {
            lessonId
            id
            amount
            amountWithoutDiscount
            percentDiscount
            status
            date
            category
            draft
            lastProcessedAt
            description
            type
          }
          ... on CustomPaymentItem {
            id
            amount
            amountWithoutDiscount
            percentDiscount
            status
            date
            category
            draft
            lastProcessedAt
            description
            type
          }
        }
      }`
  )
  const [validationErrors, setValidationErrors] = useState({})
  const submit = useCallback((obj) => {
    const errors = validate(obj)
    if (errors && Object.keys(errors).length) {
      setValidationErrors(errors)
      onError('validation Error', errors)
      return
    }
    mutation({ variables: obj }).then((result) => {
      onSuccess(result)
    }).catch((e) => {
      setValidationErrors({ general: 'misc.apiError' })
      onError(e)
    })
  }, [mutation, onSuccess, onError])
  return { loading, data: data ? data.editLessonPaymentItemFromAdmin : null, error, validationErrors, submit }
}
