import React from 'react'
import useGetPlansWithExams from './Model/useGetPlansWithExams'
import useGetTasksWithExams from './Model/useGetTasksWithExams'
import useHandlerExamForm, {FORMODEL} from '../../../CounselingPlans/Modal/viewPlanModal/Tabs/DocsAndExam/SubmitScore/useHandlerExamForm'

const ScoresRefactorModule = () => {

    const {open: openScoreForTask} = useHandlerExamForm(FORMODEL.task)
    const {open: openScoreForPlan} = useHandlerExamForm(FORMODEL.lessonPerWeekPlan)
    //obtener planes actuales con scores
    const {data: dataPlans, refetch: refetchPlans} = useGetPlansWithExams()
    const plans = dataPlans?.getPlansWithExams || []
    //obtener tasks actuales con scores
    const {data: dataTasks, refetch: refetchTasks} = useGetTasksWithExams()
    const tasks = dataTasks?.getTasksWithExams || []

    const openNewScoreModal = (data, entitie) => {
        if(entitie === 'plan') {
            openScoreForPlan(() => {
                refetchPlans()
            }, data.id)
        }
        else if(entitie === 'task') {
            openScoreForTask(() => {
                refetchTasks()
            }, data.taskId)
        }
    }

    return ( 
        <div className={'w-100 h-100'}>
            {
                tasks.map(
                    (task) => {
                        return <label className='w-100'>
                            {task?.taskId} @ {task?.task?.name} @ {task?.task?.selectedExamId || 'No Defined'}     
                            <span onClick={() => {openNewScoreModal(task, 'task')}} className='btn-link'>New</span>
                        </label>
                    }
                )
            }
            {
                plans.map(
                    (plan) => {
                        return <label className={`w-100 ${!plan?.selectedExam?.scores ? 'text-error' : ''}`}>
                            {plan?.id} @ {plan?.name} @ {plan?.selectedExam?.scores?.name || 'No Defined'}
                            <span onClick={() => {openNewScoreModal(plan, 'plan')}} className='btn-link'>New</span>
                        </label>
                    }
                )
            }

        </div>
    )
}

export default ScoresRefactorModule