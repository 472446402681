import {useReducer} from 'react'
import reducer from './reducer'
import {initialState, PhasesTitles, PhasesKeys, OtherPhasesTitles} from './initialState'
import {nanoid} from 'nanoid'

const useTemplateReducer = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const actionData = (
        data, 
        key, //[phase, step, task] 
        action //[add, remove]
        ) => {
        dispatch({
            type: `${action}-${key}`,
            [key]: data,
        })
        dispatch({
            type: `set-data`,
            [key]: {isInitialState: false},
        })
        
    }
    const getTaskCount = (template) => {
        return template?.phases?.reduce((total, {steps}) => {
            return total + steps?.filter(({deleteAt}) => deleteAt === null)?.reduce((totalTasks, {tasks}) => {
                return totalTasks + (tasks?.filter(({deleteAt}) => deleteAt === null).length || 0)
            }, 0)
        }, 0) || 0
    }

    const parseTimelineToMutation = (phases = []) => {
        //parsea timeline a tipo [UserTasksInput]
        return (phases || []).map(({phaseName, steps}) => {
            return {
                phaseName: PhasesKeys[phaseName] || phaseName,
                steps: steps
                    //.filter(({statusBuild}) => (statusBuild !== 'delete') || isEdit)
                    .map(({id, stepId, stepName, tasks, statusBuild, idReference}, position) => {
                    return {
                        idReference,
                        stepId: stepId || id ,
                        position,
                        stepName,
                        statusBuild,
                        tasks: tasks
                            //.filter(({statusBuild}) => (statusBuild !== 'delete') || isEdit)
                            .map((task, position) => {
                                return {
                                    //taskName: task?.taskName || task?.name || task?.title,
                                    taskId: task?.taskId || task?.id,
                                    idReference: task?.idReference,
                                    position,
                                    //id: task?.taskId || task?.id,
                                    name: task?.taskName || task?.name || task?.title,
                                    instructions: task?.instructions,
                                    type: task?.type,
                                    dueTime: task?.dueTime,
                                    buttonLabel: task?.buttonLabel,
                                    isEspecific: task?.isEspecific || false,
                                    isReviewable: task?.isReviewable || false,
                                    linkToShow: task?.linkToShow || null,
                                    statusSave: task?.statusBuild,
                                    accountsPlatform: task?.accountsPlatform || null,
                                    selectedExam: task?.selectedExam?.id || null,
                                    //statusBuild: task?.statusBuild,
                                    status: 'unassigned'
                                }
                            }
                        )  
                    }
                })
            }
        })
    }
    return {
        state, 
        dispatch,
        initialState,
        filterDelete: (templateData) => {
            //descarta elementos eliminados (statusBuild === 'delete')
            return {
                ...templateData,
                phases: templateData.phases.map((phase) => {
                    return {
                        ...phase,
                        steps: phase.steps.filter(({deleteAt}) => deleteAt === null || !deleteAt)
                            .map((step) => {
                                return {
                                    ...step,
                                    tasks: step.tasks.filter(({deleteAt}) => deleteAt === null || !deleteAt)
                                }
                            })
                    }
                })
            }
        },
        setData: ({templateId = nanoid(), templateName = 'New template',...data}) => {
            actionData({...data, templateId, id: templateId, templateName}, 'data', 'set')
        },
        changeTemplateName: (templateName = null) => {
            if(templateName) actionData({templateName}, 'data', 'set')
        }, 
        addPhase: ({phaseId = nanoid(), ...data}) => {
            actionData({...data, phaseId}, 'phase', 'add')
        },
        addStep: ({phaseId, stepId = nanoid(), ...data}) => {
            actionData({...data, stepId, phaseId}, 'step', 'add')
        },
        editStep: ({phaseId, stepId = nanoid(), ...data}) => {
            actionData({...data, stepId, phaseId}, 'step', 'edit')
        },
        addTask: ({phaseId, stepId, ...data}) => {
            //ids son usados para llegar facilmente a la zona del objeto que se debe modificar.
            //el ultimo id es de la task

            
            //taskid es generado por nanoid para evitar que hayan tasks con el mismo id
            //var isFromApi bool para cuando la tarea ya está guardada en BD (debe perdurar el ID)
            actionData({...data, 
                taskId: data?.isFromApi && (data?.taskId || data?.id) ? (data.taskId || data?.id) : nanoid(), 
                phaseId, stepId}, 'task', 'add')

        },
        removeStep: ({phaseId, stepId}) => {
            actionData({stepId, phaseId}, 'step', 'remove')
        },
        removeTask: ({phaseId, stepId, taskId}) => {
            actionData({taskId, phaseId, stepId}, 'task', 'remove')
        },
        editTask: ({phaseId = null, stepId = null, taskId, ...data}) => {
            actionData({taskId, phaseId, stepId, ...data}, 'task', 'edit')
        },
        tasksCount: () => {
            return getTaskCount(state)
        },
        getTaskCount,
        moveTask: (taskId, oldStepId, oldIndex, newStepId, newIndex) => {
            actionData({taskId, oldStepId, oldIndex, newStepId, newIndex}, 'task', 'move')
        },
        parseDataToMutation: (templateData, isEdit = false, isTemplate = false) => {
            const r = {
                templateName: templateData.templateName,
                phases: templateData.phases.map(({phaseName, steps}) => {
                    return {
                        phaseName: PhasesKeys[phaseName] || phaseName,
                        steps: steps
                            .filter(({statusBuild}) => (statusBuild !== 'delete') || isEdit)
                            .map(({id, stepId, stepName, tasks, ...step}, position) => {
                            return {
                                stepId: stepId || id ,
                                position,
                                stepName,
                                statusBuild: isEdit && step?.statusBuild ? step?.statusBuild : 'new',
                                tasks: tasks
                                    .filter(({statusBuild}) => (statusBuild !== 'delete') || isEdit)
                                    .map((task, position) => {
                                        let taskToReturn = {
                                            taskName: task?.taskName || task?.name || task?.title,
                                            taskId: task?.id || task?.taskId,
                                            position,
                                            //id: task?.taskId || task?.id,
                                            //name: task?.taskName || task?.name || task?.title,
                                            instructions: task?.instructions,
                                            type: task?.type,
                                            dueTime: task?.dueTime,
                                            buttonLabel: task?.buttonLabel,
                                            isEspecific: task?.isEspecific || false,
                                            isReviewable: task?.isReviewable || false,
                                            linkToShow: task?.linkToShow || null,
                                            selectedExam: task?.selectedExam?.id || null,
                                            accountsPlatform: task?.accountsPlatform || null,
                                            statusBuild: isEdit && task?.statusBuild ? task?.statusBuild : 'new',

                                            showInDocs: task?.showInDocs || false,
                                            uploadDocs: task?.uploadDocs || false, //Para permitir uplodear files en tasks de cualquier tipo
                                        }
                                        if (!isTemplate) taskToReturn.userMeetingId = task?.userMeetingId || null
                                        return {...taskToReturn}
                                })  
                            }
                        })
                    }
                })
            }
            if(isEdit) return {templateInput: r, templateId: templateData?.id || templateData?.templateId} 
            else return {templateInput: r}
            
        },
        parseTemplateFromApi: ({...newTemplate}) => {
            return {
                ...newTemplate,
                templateId: newTemplate?.id || newTemplate?.templateId,
                templateName: newTemplate.name,
                phases: newTemplate.phases.map(
                    (phase) => {
                        return {
                            ...phase,
                            title: PhasesTitles[phase.phaseName] || OtherPhasesTitles[phase.phaseName],
                            phaseId: phase.phaseName || nanoid(),
                            steps: phase.steps.map((step) => {
                                return {
                                    ...step,
                                    statusBuild: 'normal',
                                    idReference: step?.id,
                                    stepId: step?.id || nanoid(),
                                    title: step?.stepName,
                                    tasks: step.tasks.map((task) => {
                                        return {
                                            ...task,
                                            idReference: task?.id,
                                            taskId: task?.id || task?.taskId || nanoid(),
                                            taskName: task.name,
                                            title: task.name,
                                            statusBuild: 'normal' 
                                        }
                                    })
                                }
                            })
                        }
                    }
                )

            }
        },
        handlerTaskDragAndDrop: (env, onChange = () => {}) => {
            if(!env?.destination) return
            const newStepId = env.destination.droppableId
            const newIndex = env.destination.index
            const oldStepId = env.source.droppableId
            const oldIndex = env.source.index
            const taskId = env.draggableId
            onChange( 
                taskId,
                oldStepId,
                oldIndex,
                newStepId,
                newIndex
            )
        },
        parseTimelineToMutation,
        getCurrentStep: (phases/**recibe arreglo de phases **/) => {
            return phases.reduce((obj, phase) => {
                if(!obj) {
                    obj = phase?.steps?.find((step) => {
                        return step?.tasks?.filter(({status}) => ['unassigned', 'pending'].includes(status))?.length > 0
                    })
                    if(obj) obj = {...obj, ...phase}
                }
                return obj
            }, null)
        }
    }
}

export default useTemplateReducer