import {useLazyQuery,  gql} from '@apollo/client'
import userTaskResult from "../../Workspace/Model/queryResults/userTaskResult"
import userQueryResult from '../../../Model/Users/QueryResults/userQueryResult'
import planQueryResult from '../../CounselingPlans/Model/queryResults/planQueryResult'

const useLazyGetTask = (onCompleted = () => {}, onError = () => {}) => {
    return useLazyQuery(
        gql`
            query (
                $taskId: ID!
                ) {
                getTask (
                    taskId: $taskId
                ) {
                    planId
                    taskId
                    phaseId
                    stepId
                    user {
                        ${userQueryResult}
                    }
                    task {
                        ${userTaskResult}
                    }
                    dataPlan {
                        ${planQueryResult}
                    }
                }
            }
        `,
        {
             onCompleted,
             onError
        }
    )
}

export default useLazyGetTask