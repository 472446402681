import { gql, useLazyQuery } from '@apollo/client'

export default (props) => {
    const {fetchPolicy = 'cache-first'} = props
  return useLazyQuery(
    gql`query($roles: [Roles!], $limit: Int, $sortField: String, $state: Boolean) {
		allUsers(roles: $roles, limit: $limit, sortField: $sortField, state: $state) {
			hasNext
			hasPrevious
			next
			previous
			totalDocs
			docs {
				id
				isActive
				emails {
				address
				verified
				}
				countryId
				gender
				spokenLanguages
				address
				city
				state
				zipCode
				firstName
				lastName
				paymentMethod {
					kind
				}
				phone
				timezone
				birthdate
				studentInfo {
					institution
					graduationDate
					studentType
					defaultRate
					gpa
					servicesRate {
						defaultRate
						serviceId
						name
					}
					parents {
						parentUserId
						managePayments
						manageApprovals
						firstName
						lastName
						email
						phone
					}
					notifications {
						missedLesson
						lessonsNotesReady
						lessonReminder {
						isAvailable
						duration
						}
					}
					upcomingLesson {
						id
					}
				}
				parentInfo {
				children {
					studentUserId
					managePayments
					manageApprovals
					firstName
					lastName
					email
				}
				}
				educatorInfo {
					id
					services {
					serviceId
					name
					defaultRate
					topics {
						topicId
						teachingTypes
						name
						individualRate
						groupRate
						isWaiveIndividual
						isWaiveGroup
					}
					}
				availabilityBlocks {
					day
					hour
				}
					educatorNote
					backupLink
				}
			}
		}
	}
	`,
      {
          fetchPolicy: fetchPolicy || 'cache-first'
      }
  )
}
