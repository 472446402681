import React, {useEffect, useMemo} from 'react'
import {Input} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import moment from 'moment-timezone'
const TimeRange = (props) => {
    const {form, setForm} = props
    const {translate} = useTranslation()
    //DURACIÓN DE LA LECCIÓN PARA AUTOFILL
    const lessonDuration = useMemo(() => {
        let momentStart = moment().set({
            hour: parseInt(form?.timeStart?.split(':')[0]),
            minutes: parseInt(form?.timeStart?.split(':')[1]),
            seconds: 0
        })
        let momentEnd = moment().set({
            hour: parseInt(form?.timeEnd?.split(':')[0]),
            minutes: parseInt(form?.timeEnd?.split(':')[1]),
            seconds: 0
        })
        let duration = form?.timeEnd 
            ? moment.duration(momentEnd.diff(momentStart)).asMinutes() 
            : 50
            
        return parseInt(duration)
        // eslint-disable-next-line
    }, [form.timeEnd])

    useEffect(() => {
        if (form.timeStart) {
            // autofill timeEnd
            setForm({
            ...form,
            timeEnd: moment().set({
                hour: parseInt(form.timeStart.split(':')[0]),
                minutes: parseInt(form.timeStart.split(':')[1]),
                seconds: 0
              }).add(lessonDuration, 'minutes').format('HH:mm')
            })
        }
        // eslint-disable-next-line
    }, [form.timeStart, lessonDuration])

    return (
        <div className='mx-0 my-3 p-0 d-flex flex-row'>
            <div className='w-50 d-flex flex-column'>
              <span className='p-0 text-gray'>
                {translate('modal.newlesson.start')}
              </span>
              <Input
                className='noRight'
                type='time' name='timeStart' id='timeStart'
                value={form.timeStart}
                onChange={(e) => {
                  setForm({
                    ...form,
                    timeStart: e.target.value
                  })
                }}
              />
            </div>
            <div className='w-50 d-flex flex-column'>
              <span className='p-0 text-gray'>
                {translate('modal.newlesson.end')}
              </span>
              <Input
                className='noLeft'
                type='time' name='timeEnd' id='timeEnd'
                value={form.timeEnd}
                onChange={(e) => {
                  setForm({
                    ...form,
                    timeEnd: e.target.value
                  })
                }}
              />
            </div>
        </div>
    )

}
export default TimeRange 