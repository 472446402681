import React, {useEffect} from 'react'
import { useLocation } from 'react-router'
import {gql, useLazyQuery} from '@apollo/client'
import planQueryResult from '../Model/queryResult/planQueryResult'
import useCreateTimelineModal from '../Modal/CreateTimelineModal/useCreateTimelineModal'
import useTranslation from '../../../i18n/useTranslation'
import { showAlert } from '../../../common/Alert/util'
import { useDispatch } from 'react-redux' 


const RedirectToCreatePlan = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const {translate} = useTranslation()
    const searchParams = new URLSearchParams(location.search)
    const timelinePlanId = searchParams.get('timelinePlanId')
    const action = searchParams.get('action')
    const {createIndividualPlan} = useCreateTimelineModal()

    const [getPlan] = useLazyQuery(gql`
        query ($planId: ID!) {
            plan (planId: $planId) {
                ... on TimelinePlan {
                    ${planQueryResult}
                    timelineId {_id}
                }
            }
        }
    `)
    useEffect(
        () => {
            if(action === 'create' && timelinePlanId) {
                getPlan({variables: {planId: timelinePlanId}}).then(
                    (res) => {
                        const plan = res.data.plan
                        if(["draft", 'sent'].includes(plan.status)) createIndividualPlan(plan, () => {})
                        else {
                            showAlert({
                                text: translate("timelines.createIndividualPlan.planIsScheduled") || 'Plan is scheduled or has been deleted!', 
                                status: 'warning'}, dispatch)
                        }
                    },
                    console.error
                )
            }
            //eslint-disable-next-line
        }, [timelinePlanId, action]
    )
    return (
        <></>
    )
}

export default RedirectToCreatePlan