import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client'
import ViewInvoice from '../view'
import useViewInvoice from '../view/useViewInvoice'
const ViewInvoiceWithPaymentItem = () => {
  const modalData = useSelector((state) => state.modal.payments.viewInvoiceWithPymtItem)
  const { paymentItemId } = modalData
  const { open: openViewInvoice, setInvoiceData } = useViewInvoice()
  const [getInvoiceByPymtId, { data }] = useLazyQuery(
    gql`query($paymentItemId: String!){
      invoiceFromAdminByPaymentItemId(paymentItemId: $paymentItemId) 
    }`,
    {
      variables: { paymentItemId: paymentItemId },
      fetchPolicy: 'network-only'
    }
  )
  useEffect(() => {
    (async () => {
      if (!paymentItemId) return
      await getInvoiceByPymtId().then((resp) => {
        setInvoiceData(resp.data.invoiceFromAdminByPaymentItemId)
        
      })
    })()
    //eslint-disable-next-line
  }, [paymentItemId, getInvoiceByPymtId])

  useEffect(() => {
    if (!data) return
    openViewInvoice(data.invoiceFromAdminByPaymentItemId)
    //eslint-disable-next-line
  }, [data])


  return (
    <>
      <ViewInvoice />
    </>
  )
}

export default ViewInvoiceWithPaymentItem
