import { gql, useLazyQuery } from '@apollo/client'
import validate from './validation'
import { useEffect } from 'react'

export default (vars) => {
  const [getFreeTimeFrames, { data, error, loading }] = useLazyQuery(
    gql`
      query(
        $educatorId: ID
        $minDurationInMinutes: Int
        $to: DateTime!
        $from: DateTime!
        $involveMyself: Boolean!
      ) {
        myFreeTimeFrames(
          educatorUserId: $educatorId
          minDurationInMinutes: $minDurationInMinutes
          to: $to
          from: $from
          involveMyself: $involveMyself
        )
      }
    `
  )
  useEffect(() => {
    if (vars.educatorId) {
      getFreeTimeFrames({
        variables: {
          minDurationInMinutes: 50,
          involveMyself: false,
          educatorId: vars.educatorId,
          to: vars.to,
          from: vars.from
        }
      })
    }
  }, [vars.educatorId, vars.from, vars.to, getFreeTimeFrames])
  const errors = validate(vars)
  if (errors && Object.keys(errors).length) {
    return { loading: false, error: errors, data: {} }
  }
  return { loading, data: data ? data.myFreeTimeFrames : {}, error }
}
