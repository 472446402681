import React from 'react'
import { Avatar } from '@Knowledge-OTP/znk-ui-components'

const ServiceField = (props) => {
  const { thisService } = props
  return (
    thisService ? (
      thisService.name
    ) : 'Not Found')
}

export const ServiceHover = (props) => {
  const { recommendedEducators, thisService } = props
  return (thisService
    ? <div className='m-0 px-4 py-2 d-flex flex-column'>
      <div className='w-100 d-flex justify-content-between'>
        <label className='mr-4 font-weight-normal text-title'>Service</label> <label className='font-weight-normal text-title'>{thisService.name}</label>
      </div>
      <ul className='m-0 p-0 mt-1'>
        {recommendedEducators.map((educator) => {
          const nameTopic =
              thisService.topics.filter((top) => top.id === educator.topicId).length > 0
                ? thisService.topics.filter((top) => top.id === educator.topicId)[0].name : 'No definido'
          return (
            <li className='d-flex pt-1'>
              <Avatar
                name={`${educator.firstName} ${educator.lastName}`}
                named={false}
                uri={null}
                size='avatar-small'
              />
              <label className='ml-1 d-flex justify-content-between w-100' style={{ fontSize: '12px', lineHeight: '2.4' }}>
                <span className='font-weight-normal mr-4 text-title'>{`${educator.firstName} ${educator.lastName.substring(0, 1)}.`}</span>
                <span className='text-muted'>{`(${nameTopic})`}</span>
              </label>
            </li>
          )
        })}
      </ul>
      </div> : 'Not Found'
  )
}
export default ServiceField
