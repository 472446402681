import {gql, useLazyQuery} from '@apollo/client'

const useActivities = () => {
  return useLazyQuery(
    gql`
      query(
        $serviceIds: [String!]
        $kinds: [String!]
        $from: DateTime
        $to: DateTime
        $userIds: [String!]
      ) {
        allActivities(
          kinds: $kinds
          serviceIds: $serviceIds
          from: $from
          to: $to
          userIds: $userIds
        ) {
          ... on Test {
            id
            name
            kind
            tbd
            startDate
            endDate
            busy
            serviceId
            registrationDate
            scoreReleaseDate
            isDisabled
          }
          ... on Event {
            id
            name
            kind
            tbd
            startDate
            endDate
            busy
            description
          }
        }
      }
    `,
  )
}

export default useActivities
