import React, { useState } from 'react'
import PropTypes from 'prop-types'
import usePaginatedTopics from '../../../../../Model/Topics/List'
import { IconAdd, IconSearch, Input } from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'

const SearchBox = props => {
  const { selectedValues, addItem } = props
  const { data, loading } = usePaginatedTopics({ limit: 200 })
  const { docs: topics } = data
  const selectedObj = selectedValues.reduce((obj, topic) => ({ ...obj, [topic.id]: topic }), {})
  const [search, setSearch] = useState('')
  return (
    <div className='d-flex flex-column mt-4'>
      <Input
        type='addon'
        className='my-1'
        addOnComponent={<IconSearch className='text-gray' />}
        value={search}
        onChange={({ target: { value } }) => setSearch(value)}
      />
      <span className='d-flex flex-column my-4'>
        {
          loading
            ? <Skeleton height={20} count={4} className='my-2' />
            : topics && topics.filter((topic) => new RegExp(search, 'i').test(topic.name)).map((topic) => {
              const { id, name } = topic
              return (selectedObj[id] ? null
                : <span className='d-flex justify-content-between'>
                  <span>{topic.name}</span>
                  <span><IconAdd size={20} onClick={() => addItem({ id, name })} /></span>
                  </span>
              )
            })
        }
      </span>
    </div>
  )
}

SearchBox.propTypes = {
  selectedValues: PropTypes.array,
  addItem: PropTypes.func
}

export default SearchBox
