import { FilterBetweenDatesInner } from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../i18n/useTranslation'
import React from 'react'

const FinalizationDates = (props) => {
  const { translate } = useTranslation()
  const { setEndDateFilter } = props
  return (
      <FilterBetweenDatesInner
      className={'h45'}
        strings={{
          title: translate('filters.plans.finalizationDateTitle'),
          buttonCancel: translate('filters.plans.buttonCancel'),
          buttonApply: translate('filters.plans.buttonApply'),
          from: translate('filters.plans.fromDate'),
          to: translate('filters.plans.toDate'),
          typeFilter: 'finalization'
        }}
        setDates={setEndDateFilter}
      />
    
  )
}
export default FinalizationDates
