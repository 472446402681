import { gql, useQuery } from '@apollo/client'
import paymentResult from './queryResult/paymentResult'

export default (vars) => {
  const { data, error, loading, refetch } = useQuery(
    gql`
    query {
        allUsers (limit: 0) {
          docs {
            id
            gender
            firstName
            lastName
            paymentMethod {
              ${paymentResult}
            }
          }
        }
      }`,
    {
      variables: vars
    }
  )
  return { loading, data: data ? data.allUsers?.docs : [], error, refetch }
}



// paymentMethod {
//   ${paymentResult}
// }
// registeredPaymentMethods {
//   ${paymentResult}
// }
